import {
  EDIT_TEMP_COMP_OFF_REQUEST,
  EDIT_TEMP_COMP_OFF_SUCCESS,
  EDIT_TEMP_COMP_OFF_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { editTempCompOffByAdmin } from "../../config/services/employeeServices";
import { TEMP_DELETE, TEMP_EDIT } from "../../components/leaves/Constants";

const editTempCompOffByAdminAction = (props) => {
  let { useDispatch, params, handleClose, getData, isDelete } = props;
  return (dispatch) => {
    dispatch(editTempCompOffRequest());
    editTempCompOffByAdmin(params)
      .then((res) => {
        useDispatch({
          type: "typeCheckSet",
          payload: {
            loader: false,
          },
        });
        let { status } = ResponseFilter(res);
        if (status) {
          getData();
          useDispatch({
            type: "typeCheckSet",
            payload: {
              resMsg: true,
              successMessage: isDelete
                ? TEMP_DELETE
                : TEMP_EDIT,
            },
          });
          handleClose();
          setTimeout(
            () =>
              useDispatch({
                type: "typeCheckSet",
                payload: {
                  resMsg: false,
                },
              }),
            3000
          );
        } else {
          useDispatch({
            type: "typeCheckSet",
            payload: {
              resFailMsg: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
        }
        getData();
        handleClose();
        setTimeout(
          () =>
            useDispatch({
              type: "typeCheckSet",
              payload: {
                resFailMsg: false,
              },
            }),
          3000
        );
      })
      .catch((error) => {
        dispatch(editTempCompOffFailure(error));
      });
  };
};

const editTempCompOffRequest = () => {
  return {
    type: EDIT_TEMP_COMP_OFF_REQUEST,
  };
};

const editTempCompOffSuccess = (data) => {
  return {
    type: EDIT_TEMP_COMP_OFF_SUCCESS,
    payload: data,
  };
};

const editTempCompOffFailure = (error) => {
  return {
    type: EDIT_TEMP_COMP_OFF_FAILURE,
    payload: error,
  };
};
export {
  editTempCompOffByAdminAction,
  editTempCompOffRequest,
  editTempCompOffSuccess,
  editTempCompOffFailure,
};

import React from 'react';
import { Select } from 'antd';
import { leaveValue } from './Constants';

const { Option } = Select;

const LeaveOption = ({
  data,
  i,
  editLeaveArray,
  handleSelectChangeValue,
  options,
}) => {
  return (
    <Select
      name="leaveType"
      value={leaveValue(data.leaveType) ? leaveValue(data.leaveType) : "Leave Type"}
      placeholder="Leave Type"
      className=""
      onChange={(e) => handleSelectChangeValue(i, e, editLeaveArray, "leaveType")}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default LeaveOption;

import _ from "lodash";
import moment from "moment";
import React, { useReducer } from "react";
import {
  ActionImage,
  dateFormat,
  handleOpenPopup,
  handleSort,
  reducer,
  type,
} from "../helper/Constant";
import EditCourierModal from "./EditCourierModal";
import { handleClose } from "./CourierConstants";
import DeleteCourierModal from "./DeleteCourierModal";
import { CONSTANTS } from "../../config";
import { downloadIcon } from "../../asset";
import ViewUploadedDocument from "../helper/ViewUploadedDocument";

const initialState = {
  viewDocumentPopup: false,
  viewDocument: [],
};
const CourierList = (props) => {
  const [state, dispatchState] = useReducer(reducer, initialState);
  const { viewDocumentPopup, viewDocument } = state;
  const {
    courierList,
    pageNo,
    limit,
    dispatch,
    editPopup,
    deletePopup,
    dateSent,
    tentative_receiving_date,
    name,
    cost,
    tracking_id,
    courier_company,
    item_description,
    courier_id,
    getCourierList,
    dispatchToAction,
    sortType,
    sortField,
    dateSentErrMsg,
    courierFile,
    editCourier,
    courierFileErrMsg,
    nameErrMsg,
    tentative_receiving_dateErrMsg,
    costErrMsg,
    tracking_idErrMsg,
    courier_companyErrMsg,
    item_descriptionErrMsg,
  } = props;

  const handleOpenViewDocPopup = (viewDocument) => {
    dispatchState({
      type: type,
      payload: {
        viewDocumentPopup: true,
        viewDocument: viewDocument,
      },
    });
  };

  const closeModal = () => {
    dispatchState({
      type: type,
      payload: {
        viewDocumentPopup: false,
      },
    });
  };
  return (
    <div>
      <div className="table-responsive mt-5">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">
                Name
                <i
                  className={
                    sortType === CONSTANTS.SORT_TYPE_CONSTANTS.Asscending &&
                    sortField === "receiver_name"
                      ? "fa fa-sort-alpha-asc ml-2"
                      : "fa fa-sort-alpha-desc ml-2"
                  }
                  onClick={(e) =>
                    handleSort("receiver_name", dispatch, sortType)
                  }
                />
              </th>
              <th scope="col">
                Sent Date
                <i
                  className={
                    sortType === CONSTANTS.SORT_TYPE_CONSTANTS.Asscending &&
                    sortField === "dateSent"
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={(e) => handleSort("dateSent", dispatch, sortType)}
                />
              </th>
              <th scope="col">
                Receiving Date
                <br />
                (Tentative)
              </th>
              <th scope="col">
                Cost
                <i
                  className={
                    sortType === CONSTANTS.SORT_TYPE_CONSTANTS.Asscending &&
                    sortField === "cost"
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={(e) => handleSort("cost", dispatch, sortType)}
                />
              </th>
              <th scope="col">Tracking ID</th>
              <th scope="col">Courier Company</th>
              <th scope="col">Item Description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(courierList)
              ? courierList.map((detail, i) => (
                  <tr key={detail._id}>
                    <td>{i + 1 + pageNo * limit}</td>
                    <td>{detail?.receiver_name}</td>
                    <td>{moment(detail.dateSent).format(dateFormat)}</td>
                    <td>
                      {moment(detail.tentative_receiving_date).format(
                        dateFormat
                      )}
                    </td>
                    <td>{detail.cost}</td>
                    <td>{detail.tracking_id}</td>
                    <td>{detail.courier_company}</td>
                    <td>{detail.item_description}</td>
                    <td>
                      <div className="cm_device d-flex align-items-baseline">
                        <span
                          onClick={() =>
                            handleOpenPopup({
                              dispatch: dispatch,
                              open: "editPopup",
                              data: detail,
                            })
                          }
                        >
                          <i
                            className="fa fa-pencil"
                            aria-hidden="true"
                            title="Edit courier"
                          />
                        </span>
                        <span
                          onClick={() =>
                            handleOpenPopup({
                              dispatch: dispatch,
                              open: "deletePopup",
                              data: detail,
                            })
                          }
                        >
                          <i
                            className="fa fa-trash-o ml-4 red"
                            title="Delete courier"
                          />
                        </span>
                        {detail.courier_slip && (
                          <span
                            onClick={() =>
                              handleOpenViewDocPopup(detail.courier_slip)
                            }
                          >
                            <i
                              className="fa fa-eye blue ml-4"
                              title="View Doc"
                            />
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {courierList && courierList?.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center">No data available</h3>
        )}
      </div>
      <EditCourierModal
        dispatch={dispatch}
        show={editPopup}
        handleClose={() => handleClose(dispatch)}
        dateSent={dateSent}
        dispatchToAction={dispatchToAction}
        tentative_receiving_date={tentative_receiving_date}
        name={name}
        cost={cost}
        tracking_id={tracking_id}
        courier_company={courier_company}
        item_description={item_description}
        courier_id={courier_id}
        getCourierList={getCourierList}
        dateSentErrMsg={dateSentErrMsg}
        courierFile={courierFile}
        editCourier={editCourier}
        courierFileErrMsg={courierFileErrMsg}
        nameErrMsg={nameErrMsg}
        tentative_receiving_dateErrMsg={tentative_receiving_dateErrMsg}
        costErrMsg={costErrMsg}
        tracking_idErrMsg={tracking_idErrMsg}
        courier_companyErrMsg={courier_companyErrMsg}
        item_descriptionErrMsg={item_descriptionErrMsg}
      />
      <DeleteCourierModal
        dispatch={dispatch}
        show={deletePopup}
        handleClose={() => handleClose(dispatch)}
        dispatchToAction={dispatchToAction}
        tentative_receiving_date={tentative_receiving_date}
        courier_id={courier_id}
        getCourierList={getCourierList}
      />
      <ViewUploadedDocument
        show={viewDocumentPopup}
        data={viewDocument}
        handleClose={closeModal}
        type="courier"
      />
    </div>
  );
};

export default CourierList;

import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

import { HALF_LEAVE_ARRAY, LEAVE_ARRAY, halfTypeValue } from "../Constants";
import { format } from "../../attendance/Constants";
import { Select, TimePicker } from "antd";
import DOMPurify from "dompurify";
import TextEditor from "../../../hoc/textEditor/TextEditor";
import LeaveDatePick from "../LeaveDatePick";
import LeaveOption from "../LeaveOption";
const { Option } = Select;
export const dateFormat = "DD/MM/YYYY";

export function EditLeaveModal(props) {
  const {
    numberOfLeaves,
    numberOfPartition,
    handlePartitionChange,
    numberOfPartitionErrorMessage,
    handleSavePartiiton,
    editLeaveArray,
    handleChangeValues,
    handleSelectChangeValue,
    onStartChange,
    onEndChange,
    handleCheckInChange,
    handleCheckOutChange,
    showEditSave,
    loader,
  } = props;
  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(props.leaveMsg),
    };
  }
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="partial_approve"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Edit Leave</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-5">Number Of Leave:- {numberOfLeaves}</h4>
        <>
          <div className="mb-5">
            <div
              className={`form-group col-12 ${
                numberOfPartitionErrorMessage ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Select Partition</label>
              <input
                maxLength="75"
                className="form-control cm_box"
                type="text"
                placeholder="Enter Partition"
                name="numberOfPartition"
                value={numberOfPartition ? numberOfPartition : ""}
                onChange={handlePartitionChange}
              />

              {numberOfPartitionErrorMessage ? (
                <span className="error_msg">
                  {numberOfPartitionErrorMessage}
                </span>
              ) : null}
            </div>
            <div className=" text-center">
              <button className="btn btn-primary" onClick={handleSavePartiiton}>
                Next
              </button>
            </div>
          </div>

          <div className="row">
            {editLeaveArray.map((data, i) => (
              <div
                className="d-flex"
                style={{
                  overflowX: "scroll",
                  textWrap: "nowrap",
                  width: "100%",
                }}
              >
                <div
                  className={`form-group edit-leave-input col-md-2 ${
                    data.daysCheck ? "input_error" : ""
                  }`}
                >
                  <label className="cm_bold">Number Of Days</label>
                  <input
                    maxLength="75"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Enter days"
                    name="days"
                    value={data?.days ? data?.days : ""}
                    onChange={(e) => handleChangeValues(i, e, editLeaveArray)}
                  />
                  {data.daysCheck ? (
                    <span className="error_msg">{data.daysCheck}</span>
                  ) : null}
                </div>
                <div
                  className={`form-group edit-leave-input col-md-2 ${
                    data.leaveTypeCheck ? "input_error" : ""
                  }`}
                >
                  <label className="cm_bold">Change Leave Type</label>
                  <LeaveOption
                    data={data}
                    i={i}
                    editLeaveArray={editLeaveArray}
                    handleSelectChangeValue={handleSelectChangeValue}
                    options={
                      data.days % 1 === 0.5 ? HALF_LEAVE_ARRAY : LEAVE_ARRAY
                    }
                  />
                  {data.leaveTypeCheck ? (
                    <span className="error_msg">{data.leaveTypeCheck}</span>
                  ) : null}
                </div>
                {(data?.leaveType >= 6 && data?.leaveType <= 10) ||
                data?.leaveType === 14 ||
                data?.leaveType === 15 ? (
                  <div
                    className={`form-group edit-leave-input col-md-2 ${
                      data.halfOfTheDayCheck ? "input_error" : ""
                    }`}
                  >
                    <label className="cm_bold">Half Type</label>
                    <Select
                      value={halfTypeValue(data.halfOfTheDay)}
                      placeholder="Select Half Type"
                      onChange={(e) =>
                        handleSelectChangeValue(
                          i,
                          e,
                          editLeaveArray,
                          "halfOfTheDay"
                        )
                      }
                      name="halfOfTheDay"
                    >
                      <Option value={1}>First Half Leave</Option>
                      <Option value={2}>Second Half Leave</Option>
                    </Select>
                    {data.halfOfTheDayCheck ? (
                      <span className="error_msg">
                        {data.halfOfTheDayCheck}
                      </span>
                    ) : null}
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`form-group edit-leave-input col-md-2 ${
                    data.startDateCheck ? "input_error" : ""
                  }`}
                >
                  <label className="cm_bold">Start Date</label>
                  <br />
                  <LeaveDatePick
                    value={data?.startDate ? moment(data?.startDate) : ""}
                    name="startDate"
                    handleDateChange={(e) =>
                      onStartChange(i, e, editLeaveArray, "startDate")
                    }
                  />

                  {data.startDateCheck ? (
                    <span className="error_msg">{data.startDateCheck}</span>
                  ) : null}
                </div>
                {data?.leaveType !== 6 &&
                data?.leaveType !== 7 &&
                data?.leaveType !== 8 &&
                data?.leaveType !== 9 &&
                data?.leaveType !== 12 &&
                data?.leaveType !== 10 &&
                data?.leaveType !== 14 &&
                data?.leaveType !== 15 ? (
                  <div
                    className={`form-group edit-leave-input col-md-2 ${
                      data.endDateCheck ? "input_error" : ""
                    }`}
                  >
                    <label className="cm_bold">End Date</label>
                    <br />
                    <LeaveDatePick
                      value={data?.endDate ? moment(data?.endDate) : null}
                      name="endDate"
                      handleDateChange={(e) =>
                        onEndChange(i, e, editLeaveArray, "endDate")
                      }
                    />
                    {data.endDateCheck ? (
                      <span className="error_msg">{data.endDateCheck}</span>
                    ) : null}
                  </div>
                ) : (
                  " "
                )}

                {data?.leaveType === 12 ? (
                  <>
                    <div
                      className={`form-group edit-leave-input col-md-2 ${
                        data.fromCheck ? "input_error" : ""
                      }`}
                    >
                      <label className="cm_bold"> From</label>
                      <TimePicker
                        className="form-control"
                        allowClear={false}
                        name="from"
                        disabledHours={() => [
                          0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23,
                        ]}
                        format={format}
                        value={data?.from ? moment.utc(data?.from * 1000) : ""}
                        onChange={(checkIn, checkInString) =>
                          handleCheckInChange(
                            checkIn,
                            checkInString,
                            i,
                            "from",
                            editLeaveArray
                          )
                        }
                      />
                      {data.fromCheck ? (
                        <span className="error_msg">{data.fromCheck}</span>
                      ) : null}
                    </div>
                    <div
                      className={`form-group edit-leave-input col-md-2 ${
                        data.toCheck ? "input_error" : ""
                      }`}
                    >
                      <label className="cm_bold">To</label>
                      <TimePicker
                        className="form-control"
                        allowClear={false}
                        disabledHours={() => [
                          0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23,
                        ]}
                        format={format}
                        value={data?.to ? moment.utc(data?.to * 1000) : ""}
                        onChange={(checkOut, checkOutString) =>
                          handleCheckOutChange(
                            checkOut,
                            checkOutString,
                            i,
                            "to",
                            editLeaveArray
                          )
                        }
                        name="to"
                      />
                      {data.toCheck ? (
                        <span className="error_msg">{data.toCheck}</span>
                      ) : null}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div
                  className={`form-group edit-leave-input col-2 ${
                    data.replyCheck ? "input_error" : ""
                  }`}
                >
                  <label className="cm_bold">Reply</label>

                  <TextEditor
                    editorState={data?.approveReply}
                    onEditorStateChange={(e) =>
                      handleSelectChangeValue(
                        i,
                        e,
                        editLeaveArray,
                        "approveReply"
                      )
                    }
                    name="approveReply"
                  />
                  {data.replyCheck ? (
                    <span className="error_msg">{data.replyCheck}</span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </>
        <div className="text-center mt-2">
          {showEditSave ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={props.handleActionSubmit}
              disabled={loader ? true : false}
            >
              Save
            </button>
          ) : (
            ""
          )}
          {props.responseMessage ? (
            <h3 className=" Registration_error mt-5">
              {props.responseMessage}
            </h3>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

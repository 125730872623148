import React, { useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ResponseFilter from "../../ResponseFilter";
import { Loader } from "../svg";
import {
  getDashboardDetails,
  getBirthday,
  getWorkAnniversary,
} from "../../config/services/employeeServices";
import { reducer, type } from "../helper/Constant";
import { useSelector } from "react-redux";
import AdminDashboardFirstSection from "./DashboardUtils/AdminDashboardFirstSection";
import BirthdayWorkRender from "./DashboardUtils/BirthdayWorkRender";

const initialState = {
  resMsg: false,
  adminDashboardDetails: "",
  birthday: [],
  currentTime: new Date(),
  loader: true,
  loginResponseMessage: false,
  greeting: "",
  workAnniversary: [],
};

const AdminHome = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const profile = useSelector((state) => state.getEmpProfileByAdminReducer);
  const {
    greetings,
    adminDashboardDetails,
    currentTime,
    loader,
    birthday,
    loginResponseMessage,
    workAnniversary,
  } = state;
  const getDashboardView = async () => {
    try {
      const res = await getDashboardDetails();
      if (res.data.statusCode === 1) {
        const { status, resData } = ResponseFilter(res);
        if (resData.isAdmin === 1 || resData.isAdmin === 4) {
          if (status) {
            dispatch({
              type: type,
              payload: {
                adminDashboardDetails: resData.adminDashboardDetails,
              },
            });
            dispatch({ type: type, payload: { loader: false } });
          }
        }
      } else {
        history.push("/employee");
      }
    } catch (error) {
      console.error("Error fetching dashboard details", error);
    }
  };

  useEffect(() => {
    const message = localStorage.getItem("loginMessage");
    if (message) {
      dispatch({ type: type, payload: { loginResponseMessage: true } });
      setTimeout(() => {
        dispatch({ type: type, payload: { loginResponseMessage: false } });
        localStorage.removeItem("loginMessage");
      }, 3000);
    }
    const interval = setInterval(() => {
      dispatch({ type: type, payload: { currentTime: new Date() } });
    }, 1000);
    getDashboardView();
    getEmpBirthday();
    greet();
    getEmpWorkAnniversary();
    return () => clearInterval(interval);
  }, []);

  const getEmpWorkAnniversary = () => {
    getWorkAnniversary()
      .then((res) => {
        if (res.data.statusCode === 1) {
          let { status, resData } = ResponseFilter(res);
          if (status) {
            dispatch({
              type: type,
              payload: {
                workAnniversary: resData.workAnniversary,
                loader: false,
              },
            });
          }
        } else {
          dispatch({ type: type, payload: { loader: false } });
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  };

  const getEmpBirthday = async () => {
    try {
      const res = await getBirthday();
      if (res.data.statusCode === 1) {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch({
            type: type,
            payload: {
              birthday: resData.birthday,
            },
          });
        }
      } else {
        dispatch({ type: type, payload: { loader: false } });
      }
    } catch (error) {
      console.error("Error fetching birthday data", error);
      dispatch({ type: type, payload: { loader: false } });
    }
  };

  const greet = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    let greeting = "Good evening";

    if (currentHour < 12) {
      greeting = "Good morning";
    } else if (currentHour >= 12 && currentHour < 16) {
      greeting = "Good afternoon";
    }

    dispatch({
      type: type,
      payload: { greetings: greeting },
    });
  };

  return (
    <div
      className="body_container cm_bold dashboard_container"
      style={{ backgroundColor: "#eaeaea", paddingTop: "84px !important" }}
    >
      {loader && <Loader />}
      {loginResponseMessage && (
        <div className="alert alert-success cm_top_fix">Login Successfully</div>
      )}

      <div className="dashboard_new_heading ml-4 mb-4">
        <h4>
          {greetings}, {profile.data.employeeProfile?.firstName}
        </h4>
        <h5>
          {currentTime && (
            <div className="d-flex">
              <p>It's {moment(currentTime).format("dddd, MMMM Do ")}</p>
            </div>
          )}
        </h5>
      </div>
      <AdminDashboardFirstSection
        birthday={birthday}
        adminDashboardDetails={adminDashboardDetails}
      />
      <BirthdayWorkRender
        birthday={birthday}
        workAnniversary={workAnniversary}
      />
    </div>
  );
};

export default AdminHome;

import _ from "lodash";
import React, { useReducer } from "react";
import { officeLocationValue } from "../../config/constants/Constants";
import Pagination from "react-js-pagination";
import DeleteHoldEmployee from "./salaryModal/DeleteHoldEmployee";
import {
  employeeOnHoldValue,
  employeePFValue,
  monthValue,
} from "./salaryConstants";
import { reducer } from "../helper/Constant";
import { Select } from "antd";
import moment from "moment";
import { Loader } from "../svg";
import EditOnHoldEmployee from "./salaryModal/EditOnHoldEmployee";
// export const
const type = "typeCheckSet";
const { Option } = Select;
const initialState = {
  isHovered: false,
  indexe: "",
};
export default function OnHoldEmployeesTable(props) {
  const {
    onHoldEmployeeList,
    pageNo,
    count,
    dataCount,
    handlePageChange,
    opendeleteOnHoldModal,
    handleEditOnHoldStatus,
    handleOnHoldEditModal,
    openEditOnHoldModal,
    miniLoader,
    handleClose,
    handleSubmit,
    onHoldData,
    handleReleaseDate,
    releaseDate,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const { isHovered, index } = state;
  const handleOnMouseEnterEvent = (i) => {
    dispatch({
      type: type,
      payload: {
        isHovered: true,
        index: i,
      },
    });
  };

  const handleOnMouseOutEvent = () => {
    dispatch({
      type: type,
      payload: {
        isHovered: false,
        index: "",
      },
    });
  };
  return (
    <div>
      {miniLoader && <Loader />}
      <div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Employee ID </th>
                <th scope="col">Name</th>
                <th scope="col">E-mail</th>
                <th scope="col">Office Location</th>
                <th scope="col">Account Number</th>
                <th scope="col">UAN Number</th>
                <th scope="col">Pan Card Number</th>
                <th scope="col">IFSC Code</th>
                <th scope="col">CTC</th>
                <th scope="col">PF Opt</th>
                <th scope="col">TDS</th>
                <th scope="col">Loan</th>
                {/* <th scope='col'>CTC Variable</th> */}
                <th scope="col">Hold month</th>
                <th scope="col">Release Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(onHoldEmployeeList)
                ? onHoldEmployeeList.map((data, i) => (
                    <tr key={data.empId}>
                      <td>
                        <span className="cm_col">
                          {i + 1 + (pageNo - 1) * count}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col"> {data?.empId}</span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {data?.firstName +
                            " " +
                            data?.middleName +
                            " " +
                            data?.lastName}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">{data?.email}</span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {officeLocationValue(data?.officeLocation)}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">{data?.accountNumber}</span>
                      </td>
                      <td>
                        <span className="cm_col">{data?.UAN}</span>
                      </td>
                      <td>
                        <span className="cm_col">{data?.panCard}</span>
                      </td>
                      <td>
                        <span className="cm_col">{data?.ifscCode}</span>
                      </td>
                      <td>
                        <span
                          className="cm_col"
                          onMouseEnter={() => handleOnMouseEnterEvent(i)}
                          onMouseOut={() => handleOnMouseOutEvent()}
                        >
                          {isHovered && index === i ? data.ctc : "*******"}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {employeePFValue(data?.isAvailedPF)}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">{data?.tds}</span>
                      </td>
                      <td>
                        <span className="cm_col">{data?.loan}</span>
                      </td>
                      {/* <td><span className='cm_col'>{data?.ctcVariable}</span></td> */}
                      <td>
                        <span className="cm_col">
                          {monthValue(new Date(data?.created).getMonth())}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {moment.utc(data?.releaseDate).format("DD-MM-YYYY")}
                        </span>
                      </td>

                      <td className="d-flex align-items-center">
                        <Select
                          className={
                            data?.status === 1
                              ? "salary-status-hold"
                              : "salary-status-clear"
                          }
                          value={employeeOnHoldValue(data.status)}
                          style={{ width: 80 }}
                          onSelect={(e) => handleEditOnHoldStatus(data, e)}
                        >
                          {data?.status !== 1 ? (
                            <Option value={1} id={1}>
                              Hold
                            </Option>
                          ) : (
                            <Option value={2} id={2}>
                              Clear
                            </Option>
                          )}
                        </Select>
                        <i
                          className="fa fa-pencil mr-4 ml-2"
                          aria-hidden="true"
                          title="Edit Salary"
                          onClick={() => handleOnHoldEditModal(data)}
                        ></i>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          {onHoldEmployeeList && onHoldEmployeeList.length > 0 ? (
            ""
          ) : (
            <h3 className="text-center">No data available</h3>
          )}
        </div>
        {dataCount > 10 ? (
          <Pagination
            activePage={+pageNo}
            itemsCountPerPage={count}
            totalItemsCount={dataCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        ) : (
          ""
        )}
      </div>
      <DeleteHoldEmployee
        show={opendeleteOnHoldModal}
        handleClose={handleClose}
      />
      <EditOnHoldEmployee
        show={openEditOnHoldModal}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        data={onHoldData}
        handleReleaseDate={handleReleaseDate}
        releaseDate={releaseDate}
      />
    </div>
  );
}

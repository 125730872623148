import ResponseFilter from "../../ResponseFilter";
import { decryptResponseData } from "../../components/salaryGeneration/encryptionAndDecryption";
import { exportEmployeesReimbursement } from "../../config/services/employeeServices";
import {
    EXPORT_EMPLOYEES_REIMBURSEMENT_REQUEST,
    EXPORT_EMPLOYEES_REIMBURSEMENT_SUCCESS,
    EXPORT_EMPLOYEES_REIMBURSEMENT_FAILURE,
} from "./actionType";

const exportEmployeesReimbrsementAction = (props) => {
    let { useDispatch, params, handleClose } = props

    return (dispatch) => {
        dispatch(exportEmployeesReimbursementRequest());
        exportEmployeesReimbursement(params)
            .then((res) => {
                let { status } = ResponseFilter(res)
                if (status) {
                    dispatch(exportEmployeesReimbursementSuccess(res.data.responseData));
                    useDispatch({
                        type: "typeCheckSet", payload: {
                            resMsg: true,
                            successMessage: "Reimbursement Exported",

                        }
                    })
                    handleClose()
                    window.location.href = decryptResponseData(res?.data?.responseData?.excelLink)

                } else {
                    dispatch(exportEmployeesReimbursementFailure(res.data));
                    useDispatch({
                        type: "typeCheckSet", payload: {
                            resFailMsg: true,
                            responseMessage: res?.data?.error?.responseMessage
                        }
                    })
                }
                setTimeout(() => {
                    useDispatch({
                        type: "typeCheckSet", payload: {
                            resMsg: false,
                            resFailMsg: false,
                        }
                    })
                }, 4000)
            })
            .catch((error) => {
                dispatch(exportEmployeesReimbursementFailure(error));
            });
    };
};

const exportEmployeesReimbursementRequest = () => {
    return {
        type: EXPORT_EMPLOYEES_REIMBURSEMENT_REQUEST,
    };
};

const exportEmployeesReimbursementSuccess = (data) => {
    return {
        type: EXPORT_EMPLOYEES_REIMBURSEMENT_SUCCESS,
        payload: data,
    };
};

const exportEmployeesReimbursementFailure = (error) => {
    return {
        type: EXPORT_EMPLOYEES_REIMBURSEMENT_FAILURE,
        payload: error,
    };
};
export {
    exportEmployeesReimbrsementAction,
    exportEmployeesReimbursementRequest,
    exportEmployeesReimbursementSuccess,
    exportEmployeesReimbursementFailure,
};

import React, { useMemo } from "react";
import "../Dashboard.css";
import partyImg from "../../../asset/imgs/party.png";
import { MONTHS, WEEK_DAYS } from "../../helper/Constant";
import { generateRandomColor } from "../../organizationChart/OrganizationChartUtils/OrganizationConstants";

const Birthdaydata = (props) => {
  const { birthday } = props;
  const colors = useMemo(() => {
    return birthday.reduce((acc, employee) => {
      acc[employee.employeeId] = generateRandomColor();
      return acc;
    }, {});
  }, [birthday]);

  return (
    <div className="row dashboard_new_card_container mx-3 mb-4">
      <div className=" mt-3 ml-4 d-flex">
        <img alt="" src={partyImg} className="card_icon_gift_img mr-2" />
        <p className="emp_dashboard_card_title">Birthday</p>
        {birthday && birthday.length > 0 && (
          <p className="emp_dashboard_card_title ml-2">{`(${birthday.length})`}</p>
        )}
      </div>
      <div className="card-body card_body d-flex row mx-1">
        {birthday && birthday.map((employee) => (
          <div className="dashboard_new_card col" key={employee.employeeId}>
            {employee.image ? (
              <img className="cardItemImg" src={employee.image} alt="Profile" />
            ) : (
              <div
                style={{
                  backgroundColor: colors[employee.employeeId],
                }}
                className="cardItemImg"
              >
                <p className="cardItemText">
                  {employee.firstName.charAt(0).toUpperCase()}
                </p>
              </div>
            )}
            <div>
              <div className="dashboard_CardItem">
                <div>{employee.firstName} {employee.lastName}</div>
                <div className="dashboard_designation"> {employee.designationDetails}, {employee.departmentDetails}</div>
              </div>
              <p className="dashboard_birthdate">{`${
                MONTHS[new Date(employee.dob).getMonth()]
              } ${new Date(new Date().getFullYear(), new Date(employee.dob).getMonth(), new Date(employee.dob).getDate()).getDate()}, ${WEEK_DAYS[new Date(new Date().getFullYear(), new Date(employee.dob).getMonth(), new Date(employee.dob).getDate()).getDay()]}
              `}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Birthdaydata;

import moment from "moment";
import React from "react";
import _ from "lodash";
export default function RepairDeviceList(props) {
  const { repairList, handleOpenEditUnderRepairing } = props;
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Device Id</th>
              <th scope="col">Sending Amount</th>
              <th scope="col">Receiving Amount</th>

              <th scope="col">Sending Date</th>
              <th scope="col">Receiving Date</th>
              <th scope="col">Issue Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(repairList)
              ? repairList.map((device, i) => (
                  <tr key={device.deviceId}>
                    <td>{i + 1}</td>
                    <td>{device?.devId}</td>
                    <td>{device?.sendingAmount}</td>
                    <td>
                      <span className="cm_col">{device.receivedAmount}</span>
                    </td>
                    <td>
                      {device.sendingDate
                        ? moment
                            .unix(device.sendingDate / 1000)
                            .format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>
                      {device.receivingDate
                        ? moment
                            .unix(device.receivingDate / 1000)
                            .format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>{device.issue}</td>
                    <td>
                      {device.status === "3" && i === 0 ? (
                        <i
                          className="fa fa-pencil blue"
                          onClick={() => handleOpenEditUnderRepairing(device)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        "_"
                      )}
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {repairList && repairList?.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center">No data available</h3>
        )}
      </div>
    </div>
  );
}

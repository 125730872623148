import {
  DELETE_EMPLOYEE_RESUME_REQUEST,
  DELETE_EMPLOYEE_RESUME_SUCCESS,
  DELETE_EMPLOYEE_RESUME_FAILURE,
} from "./actionType";

import { deleteEmployeeResume } from "../../config/services/employeeServices";
import { getResumeByEmpId } from ".";
const deleteEmpResume = (resumeId, empId, closeModal) => {
  return (dispatch) => {
    let params = `employeeId=${empId}&pageNo=${0}&count=${10}`

    dispatch(deleteEmpResumeRequest());
    deleteEmployeeResume(resumeId)
      .then((res) => {
        if (res.data.statusCode !== 1) {
          dispatch(deleteEmpResumeFailure(res.data));
        } else {
          dispatch(deleteEmpResumeSuccess(res.data));
          dispatch(getResumeByEmpId(params));
          closeModal()
        }
      })
      .catch((error) => {
        dispatch(deleteEmpResumeFailure(error));
      });
  };
};

const deleteEmpResumeRequest = () => {
  return {
    type: DELETE_EMPLOYEE_RESUME_REQUEST,
  };
};
const deleteEmpResumeSuccess = (data) => {
  return {
    type: DELETE_EMPLOYEE_RESUME_SUCCESS,
    payload: data,
  };
};
const deleteEmpResumeFailure = (error) => {
  return {
    type: DELETE_EMPLOYEE_RESUME_FAILURE,
    payload: error,
  };
};
export {
  deleteEmpResume,
  deleteEmpResumeRequest,
  deleteEmpResumeSuccess,
  deleteEmpResumeFailure,
};

import React from "react";
import "../OrganizationChart.css";
import "../OrganizationChart.css";
import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { generateRandomColor } from "./OrganizationConstants";

const CustomNodeContent = ({ data }) => {
  return (
    <div className="nodeContainer">
      <div className="nodeDetails">
        <div className="nodeId">{data.id}</div>
        <div className="nodeContent"></div>
        <div className={data.image ? "nodeImgContainer" : "nodeNoProfile"}>
          {data.image ? (
            <img className="nodeImg" src={data.image} alt="Profile" />
          ) : (
            <div
              style={{ backgroundColor: generateRandomColor() }}
              className="nodeImg d-flex justify-content-center align-items-center"
            >
              <text className="cardItemText ">
                {data.name.charAt(0).toUpperCase()}
              </text>
            </div>
          )}
        </div>
        <div className="nodeName">{data.name}</div>
        <div className="nodeDepartment">
          {data.department_name?.toUpperCase()}
        </div>
        <div className="nodeInfoContainer">
          <div className="d-flex align-items-center mx-2 nodeInfo">
            <AiOutlineMail color="grey" />
            <text style={{ color: "#4189d5" }}>{data.email}</text>
          </div>
          <div className="d-flex align-items-center mx-2 nodeInfo">
            <BiPhone color="grey" />
            {data.phone_number &&
              (data.phone_number.length === 10
                ? `+91 ${data.phone_number}`
                : data.phone_number.charAt(0) !== "+"
                ? `+${data.phone_number}`
                : data.phone_number)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNodeContent;

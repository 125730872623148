import React, { useEffect, useMemo, useReducer } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { DatePicker, Select } from "antd";
import ResponseFilter from "../../ResponseFilter";
import { Loader } from "../svg";
import Pagination from "react-js-pagination";
import {
  leaveAction,
  getEmployeeList,
  leaveDeductByAdmin,
  editLeaveByAdmin,
  editApprovedShortDayLeaveByAdmin,
} from "../../config/services/employeeServices";
import _ from "lodash";
import { CONSTANTS } from "../../config";
import { STATUS_ZERO, useDebounce } from "../attendance/Constants";
import {
  currentYear,
  HALF_CASUAL_LEAVE,
  HALF_COMPENSATORY_OFF,
  HALF_EARNED_LEAVE,
  HALF_LWP,
  HALF_OTHER,
  HALF_SICK_LEAVE,
  HALF_WFH,
  LEAVE_ARRAY,
  leaveValue,
  MONTH_ARRAY,
  numberOfLeavesCount,
  SHORT_DAY_LEAVE,
  statusMon,
  validateDeleteCompOff,
} from "./Constants";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { EditLeaveModal } from "./leaveModal/EditLeaveModal";
import DeductLeaveModal from "./leaveModal/DeductLeaveModal";
import {
  createSelectItems,
  currentMonth,
  handleSort,
  reducer,
  Toastify,
  type,
} from "../helper/Constant";
import { useDispatch } from "react-redux";
import {
  calculateEditLeave,
  handleActionValidation,
  handleEditValidition,
  handleNumberOfPartitionValidation,
  handlePartialApproveValidation,
  handleleaveDeductValidation,
} from "./LeaveValidation";
import ViewLeaveReason from "./leaveModal/ViewLeaveReason";
import { ApproveRejectLeaveModal } from "./leaveModal/ApproveRejectLeaveModal";
import { getLeaveListByAdmin } from "../../redux/actions/getLeaveListByAdminAction";
import qs from "qs";
import { EditApprovedShortLeaveModal } from "./leaveModal/EditApprovedShortLeaveModal";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ViewUploadedDocument from "../helper/ViewUploadedDocument";
import { editTempCompOffByAdminAction } from "../../redux/actions/editTempCompOffByAdminAction";
import { editCompOffCountByAdminAction } from "../../redux/actions/editCompOffByAdminAction";
import DeleteCompOffTempCompModal from "./leaveModal/DeleteCompOffTempCompModal";

let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
let url = "/admin-leave/leave_view/";
let arrayOfYear = [];
for (let i = 0; i <= 3; i++) {
  arrayOfYear.push(currentYear - i);
}
const initialState = {
  leaveList: [],
  leave: "",
  searchDate: "",
  searchTimeStamp: "",
  search: "",
  sortType: SORT_TYPE_CONSTANTS.Descending,
  sortField: "createdAt",
  leaveId: "",
  dataCount: "",
  pageNo: 1,
  count: 10,
  resMsg: false,
  openLeaveApprove_RejectPopup: false,
  openLeaveLwpApprovePopup: false,
  openApprovedShortLeavePopup: false,
  loader: true,
  all: "",
  pending: "",
  approved: "",
  rejected: "",
  lateCount: "",
  leaveType: "",
  leaveType1: "",
  leaveTypeSelect: "",
  resStatus: "",
  resleaveStatus: "",
  responseMessage: "",
  totalLeave: [],
  employeeList: [],
  halfOfTheDay: "",
  startDate: "",
  endDate: "",
  startDate1: "",
  endDate1: "",
  actionStatus: "",
  shortDayTo: "",
  shortDayFrom: "",
  shortDayToErrorMsg: "",
  shortDayFromErrorMsg: "",
  searchMonth: currentMonth,
  searchYear: new Date().getFullYear(),
  openDeductLeavePopup: false,
  employeeId: "",
  employeeIdErrorMsg: "",
  start1dateErrorMsg: "",
  enddate1ErrorMsg: "",
  halfOfTheDayErrMsg: "",
  leaveType1ErrorMsg: "",
  deductLeaveType: "",
  resDeductMessage: false,
  replyErrorMsg: "",
  isApproved: "",
  leaveMsg: "",
  reply: "",
  totalLeaves: "",
  openView: false,
  reason: "",
  resMsgFail: false,
  successMessage: "",
  endDateErrMsg: "",
  dateRange: false,
  editorState: EditorState.createEmpty(),
  uniqueLate: 0,
  numberOfLeaves: 0,
  numberOfPartition: 0,
  numberOfPartitionErrorMessage: "",
  editLeaveArray: [],
  editemployeeLeaveObject: {},
  showEditSave: false,
  viewDocumentPopup: false,
  viewDocument: [],
  openDeleteCompOff: false,
  tempCompOff: [],
  compOffCount: "",
  showTempComOff: false,
  toDeleteTempCompData: "",
  deleteCompOffNo: "",
  deleteCompOffNoErrMsg: "",
  deleteEmpId: "",
  deleteLeaveId: "",
  selectedCompOffDays: "",
  showDays: false,
};
function AdminLeave() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchToAction = useDispatch();
  const {
    leaveList,
    searchDate,
    search,
    status,
    sortType,
    sortField,
    leaveId,
    dataCount,
    pageNo,
    count,
    resMsg,
    openLeaveApprove_RejectPopup,
    openLeaveLwpApprovePopup,
    openApprovedShortLeavePopup,
    loader,
    all,
    pending,
    approved,
    rejected,
    leaveType,
    leaveType1,
    leaveTypeSelect,
    responseMessage,
    totalLeave,
    employeeList,
    halfOfTheDay,
    startDate,
    endDate,
    startDate1,
    endDate1,
    tabStatus,
    shortDayTo,
    shortDayFrom,
    shortDayToErrorMsg,
    shortDayFromErrorMsg,
    searchMonth,
    searchYear,
    openDeductLeavePopup,
    employeeId,
    employeeIdErrorMsg,
    start1dateErrorMsg,
    enddate1ErrorMsg,
    halfOfTheDayErrMsg,
    leaveType1ErrorMsg,
    deductLeaveType,
    replyErrorMsg,
    isApproved,
    leaveMsg,
    reply,
    openView,
    reason,
    resMsgFail,
    successMessage,
    endDateErrMsg,
    dateRange,
    editorState,
    lateCount,
    numberOfLeaves,
    numberOfPartition,
    numberOfPartitionErrorMessage,
    editLeaveArray,
    editemployeeLeaveObject,
    showEditSave,
    viewDocumentPopup,
    viewDocument,
    openDeleteCompOff,
    compOffCount,
    showTempComOff,
    deleteCompOffNo,
    deleteCompOffNoErrMsg,
    deleteEmpId,
    deleteLeaveId,
    selectedCompOffDays,
    showDays,
  } = state;
  const onEditorStateChange = (editorState) => {
    dispatch({
      type: type,
      payload: {
        editorState: editorState,
        replyErrorMsg: "",
      },
    });
  };
  const history = useHistory();
  const debouncedSearchTerm = useDebounce(search, 2000);
  const responseAction = () => {
    setTimeout(
      () =>
        dispatch({
          type: type,
          payload: {
            resMsg: false,
            resMsgFail: false,
            responseMessage: "",
          },
        }),
      3000
    );
  };
  useEffect(() => {
    getLeave();
    // eslint-disable-next-line
  }, [
    debouncedSearchTerm,
    sortType,
    sortField,
    count,
    pageNo,
    status,
    leaveType,
    searchMonth,
    searchYear,
    searchDate,
  ]);

  useEffect(() => {
    getEmployee();
  }, []);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";
    dispatch({
      type: type,
      payload: {
        pageNo: filtersFromParams.pageNo ? filtersFromParams.pageNo : 1,
        search: filtersFromParams.search ? filtersFromParams.search : "",
        status: filtersFromParams.status ? filtersFromParams.status : "",
        tabStatus: filtersFromParams.tabStatus
          ? filtersFromParams.tabStatus
          : "",
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "createdAt",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : SORT_TYPE_CONSTANTS.Descending,
        searchDate: filtersFromParams.searchDate
          ? moment(filtersFromParams.searchDate, format)
          : "",
        searchMonth: filtersFromParams.searchDate
          ? ""
          : filtersFromParams.searchMonth
          ? filtersFromParams.searchMonth
          : currentMonth,
        searchYear: filtersFromParams.searchDate
          ? ""
          : filtersFromParams.searchYear
          ? filtersFromParams.searchYear
          : new Date().getFullYear(),
        leaveType: filtersFromParams.leaveType
          ? filtersFromParams.leaveType
          : "",
        leaveTypeSelect: filtersFromParams.leaveTypeSelect
          ? filtersFromParams.leaveTypeSelect
          : "",
        dateRange: filtersFromParams.dateRange
          ? filtersFromParams.dateRange
          : false,
      },
    });
  }, [history.location.search]);

  const getLeave = () => {
    let formattedDate1 = "";
    if (searchDate !== "") {
      formattedDate1 = new Date(searchDate);
    }
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    history.push(
      `admin-leave?search=${search.trim()}&pageNo=${pageNo}&sortField=${sortField}&sortType=${sortType}&status=${status}&searchMonth=${searchMonth}&searchYear=${searchYear}&tabStatus=${tabStatus}&searchDate=${searchDate}&leaveType=${leaveType}&leaveTypeSelect=${leaveTypeSelect}&dateRange=${dateRange}`
    );
    const params = {
      search: search?.trim(),
      searchDay:
        searchYear || searchMonth
          ? ""
          : formattedDate1
          ? formattedDate1.getFullYear() +
            "-" +
            (formattedDate1.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            formattedDate1.getDate().toString().padStart(2, "0")
          : "",
      leaveType: leaveType,
      status: status === "4" ? "" : status,
      sortType: sortType,
      sortField: sortField,
      count: 10,
      pageNo: search ? 0 : pageNo - 1,
      searchMonth: searchDate ? "" : searchMonth,
      searchYear: searchDate ? "" : searchYear,
      uniqueLate: status === "4" ? "1" : "0",
    };
    dispatchToAction(
      getLeaveListByAdmin({
        useDispatch: dispatch,
        params: params,
        dataCount: dataCount,
        tabStatus: tabStatus,
      })
    );
    responseAction();
  };
  const handleDate = (e) => {
    if (e) {
      dispatch({
        type: type,
        payload: {
          searchDate: e,
          pageNo: 1,
          searchTimeStamp: e ? new Date(e).setHours(6, 30, 0, 0) : "",
          searchMonth: "",
          searchYear: "",
          dateRange: true,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchDate: "",
          pageNo: 1,
          searchTimeStamp: e ? new Date(e).setHours(6, 30, 0, 0) : "",
          searchMonth: new Date().getMonth() + 1,
          searchYear: new Date().getFullYear(),
          dateRange: false,
        },
      });
    }
  };

  const handleMonth = (month) => {
    if (month === undefined) {
      dispatch({
        type: type,
        payload: {
          searchMonth: new Date().getMonth() + 1,
          pageNo: 1,
          month: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchMonth: month,
          searchYear: searchYear,
          searchDate: "",
          searchTimeStamp: "",
          pageNo: 1,
          month: true,
        },
      });
    }
  };

  const handleYear = (year) => {
    if (year === undefined) {
      dispatch({
        type: type,
        payload: {
          searchYear: new Date().getFullYear(),
          pageNo: 1,
          year: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchYear: year,
          pageNo: 1,
          year: true,
        },
      });
    }
  };

  const handleDeductLeave = () => {
    dispatch({
      type: type,
      payload: {
        openDeductLeavePopup: true,
      },
    });
  };

  const handleTabChange = (e) => {
    if (e !== STATUS_ZERO) {
      dispatch({
        type: type,
        payload: {
          loader: true,
          status: e,
          tabStatus: e,
          pageNo: 1,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          loader: true,
          status: "",
          tabStatus: "",
          pageNo: 1,
        },
      });
    }
  };

  const handleSearch = (e) => {
    dispatch({
      type: type,
      payload: {
        search: e.target.value,
      },
    });
    if (e.target.value === "" || e.target.value === undefined) {
      dispatch({
        type: type,
        payload: {
          search: "",
          status: "",
        },
      });
    }
  };

  const handlePageChange = (pageNo) => {
    dispatch({
      type: type,
      payload: {
        pageNo: pageNo,
        loader: true,
      },
    });
  };

  const handleLeaveFilter = (e) => {
    let s = status;
    if (e !== undefined) {
      if (s === "" || s === undefined) {
        dispatch({
          type: type,
          payload: {
            leaveType: e,
            pageNo: 1,
            loader: true,
            status: "",
            leaveTypeSelect: e,
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            leaveType: e,
            pageNo: 1,
            loader: true,
            status: s,
            leaveTypeSelect: e,
          },
        });
      }
    } else {
      if (s === undefined || s === "") {
        dispatch({
          type: type,
          payload: {
            leaveType: "",
            pageNo: 1,
            loader: true,
            status: "",
            leaveTypeSelect: "",
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            leaveType: "",
            pageNo: 1,
            loader: true,
            status: s,
            leaveTypeSelect: "",
          },
        });
      }
    }
  };

  const handleOpenLeaveApprove_RejectPopup = (leave, is_Boolean) => {
    let { _id, startDate, leaveMsg, endDate, leaveType, halfOfTheDay } = leave;
    dispatch({
      type: type,
      payload: {
        openLeaveApprove_RejectPopup: true,
        leaveId: _id,
        startDate: startDate,
        isApproved: is_Boolean,
        leaveMsg: leaveMsg,
        endDate: endDate,
        leaveType1: leaveType,
        halfOfTheDay: halfOfTheDay,
      },
    });
  };
  const handleOpenLeaveLwpPopup = (leave) => {
    let {
      _id,
      leaveType,
      startDate,
      endDate,
      leaveMsg,
      status,
      totalLeaves,
      halfOfTheDay,
      to,
      from,
      mobId,
      employeeId,
      emailId,
      employeeName,
    } = leave;
    dispatch({
      type: type,
      payload: {
        openLeaveLwpApprovePopup: true,
        leaveId: _id,
        startDate: startDate,
        isApproved: status,
        leaveMsg: leaveMsg,
        endDate: endDate,
        shortDayTo: to,
        shortDayFrom: from,
        actionStatus: status,
        totalLeave: totalLeaves,
        leaveType1: leaveType,
        halfOfTheDay: halfOfTheDay,
        startDate1: startDate,
        endDate1: endDate,
        numberOfLeaves: numberOfLeavesCount(startDate, endDate),
        editemployeeLeaveObject: {
          mobId: mobId,
          employeeId: employeeId,
          emailId: emailId,
          employeeName: employeeName,
          status: 2,
          leaveMsg: leaveMsg,
        },
      },
    });
  };
  const handleOpenApprovedShortLeavePopup = (leave) => {
    let {
      _id,
      leaveType,
      startDate,
      endDate,
      leaveMsg,
      status,
      totalLeaves,
      halfOfTheDay,
      to,
      from,
    } = leave;
    dispatch({
      type: type,
      payload: {
        openApprovedShortLeavePopup: true,
        leaveId: _id,
        startDate: startDate,
        isApproved: status,
        leaveMsg: leaveMsg,
        endDate: endDate,
        shortDayTo: to,
        shortDayFrom: from,
        actionStatus: status,
        totalLeave: totalLeaves,
        leaveType1: leaveType,
        halfOfTheDay: halfOfTheDay,
        startDate1: startDate,
        endDate1: endDate,
      },
    });
  };
  const handleChange = (e) => {
    dispatch({
      type: type,
      payload: {
        reply: e.target.value,
        responseMessage: "",
        replyErrorMsg: "",
      },
    });
  };
  const handlePartitionChange = (e) => {
    dispatch({
      type: type,
      payload: {
        numberOfPartition: e.target.value,
        numberOfPartitionErrorMessage: "",
      },
    });
  };
  const handleAction = (statusType) => {
    let leaveReason = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const params = {
      leaveId: leaveId,
      status: statusType,
      reply: leaveReason,
    };
    if (
      handleActionValidation({
        reply: reply,
        dispatch: dispatch,
        editorState: editorState,
        type: type,
        replyErrorMsg: replyErrorMsg,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      leaveAction(params).then((res) => {
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              loader: false,
              resMsg: true,
              leaveType: "",
              leaveTypeSelect: "",
              resStatus: res.data.responseData.leaveDetails.status,
              openLeaveApprove_RejectPopup: false,
              openLeaveLwpApprovePopup: false,
              successMessage:
                statusType === 2 ? "Leave Approved" : "Leave Rejected",
            },
          });
          getLeave();
        } else {
          dispatch({
            type: type,
            payload: {
              responseMessage: res.data.error.responseMessage,
              resMsgFail: true,
            },
          });
          getLeave();
        }
        handleClose();
      });
      responseAction();
    }
  };

  const handleLeaveTypeChange = (e) => {
    dispatch({
      type: type,
      payload: {
        responseMessage: "",
        leaveType1: parseInt(e),
      },
    });
  };

  const handleActionSubmit = (e) => {
    e.preventDefault();

    if (
      handlePartialApproveValidation({
        editLeaveArray: editLeaveArray,
        dispatch: dispatch,
        numberOfPartition,
        numberOfLeaves: numberOfLeaves,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      const params = {
        leaveId: leaveId,
        data: calculateEditLeave(editLeaveArray, editemployeeLeaveObject, leaveMsg),
      };
      editLeaveByAdmin(params).then((res) => {
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              loader: false,
              resMsg: true,
              leaveType: "",
              leaveTypeSelect: "",
              successMessage: "Leave edited",
            },
          });
          getLeave();
        } else {
          dispatch({
            type: type,
            payload: {
              loader: false,
              resMsgFail: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
          getLeave();
        }
        handleClose();
      });
      responseAction();
    }
  };

  const handleShortLeaveEditActionSubmit = (e) => {
    e.preventDefault();

    const params = {
      leaveId: leaveId,
      leaveType: leaveType1,
      halfOfTheDay:
        leaveType1 === HALF_CASUAL_LEAVE ||
        leaveType1 === HALF_EARNED_LEAVE ||
        leaveType1 === HALF_SICK_LEAVE ||
        leaveType1 === HALF_LWP ||
        leaveType1 === HALF_COMPENSATORY_OFF ||
        leaveType1 === HALF_OTHER ||
        leaveType1 === HALF_WFH
          ? halfOfTheDay
          : 0,
      to: leaveType1 !== SHORT_DAY_LEAVE ? "" : shortDayTo,
      from: leaveType1 !== SHORT_DAY_LEAVE ? "" : shortDayFrom,
    };

    if (
      handleEditValidition({
        shortDayFrom: shortDayFrom,
        shortDayTo: shortDayTo,
        halfOfTheDay: halfOfTheDay,
        leaveType: leaveType1,
        reply: reply,
        dispatch: dispatch,
        editorState: editorState,
        replyErrorMsg: replyErrorMsg,
      })
    ) {
      editApprovedShortDayLeaveByAdmin(params).then((res) => {
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              loader: false,
              resMsg: true,
              leaveType: "",
              leaveTypeSelect: "",
              resleaveStatus: res.data.responseData.leaveDetails.leaveType,
              successMessage: " and approved",
            },
          });
          getLeave();
        } else {
          dispatch({
            type: type,
            payload: {
              loader: false,
              resMsgFail: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
          getLeave();
        }
        handleClose();
      });
      responseAction();
    }
  };

  const handleClose = () => {
    dispatch({
      type: type,
      payload: {
        openLeaveApprove_RejectPopup: false,
        openLeaveLwpApprovePopup: false,
        openApprovedShortLeavePopup: false,
        openDeductLeavePopup: false,
        employeeId: "",
        employeeIdErrorMsg: "",
        leaveType1ErrorMsg: "",
        startDate1: "",
        start1dateErrorMsg: "",
        endDate1: "",
        enddate1ErrorMsg: "",
        loader: false,
        reply: "",
        leaveType1: "",
        deductLeaveType: "",
        resleaveStatus: false,
        resStatus: false,
        shortDayFromErrorMsg: "",
        shortDayToErrorMsg: "",
        endDateErrMsg: "",
        halfOfTheDayErrMsg: "",
        replyErrorMsg: "",
        isApproved: "",
        leaveMsg: "",
        endDate: "",
        startDate: "",
        leaveId: "",
        totalLeave: [],
        shortDayFrom: "",
        shortDayTo: "",
        openView: false,
        reason: "",
        editorState: EditorState.createEmpty(),
        numberOfLeaves: 0,
        numberOfPartition: 0,
        editLeaveArray: [],
        showEditSave: false,
        numberOfPartitionErrorMessage: "",
        viewDocumentPopup: false,
        openDeleteCompOff: false,
        tempCompOff: [],
        compOffCount: "",
        showTempComOff: false,
        deleteCompOffNo: "",
        deleteCompOffNoErrMsg: "",
        deleteEmpId: "",
        deleteLeaveId: "",
        showDays: false,
      },
    });
  };
  const handleSelectEmployeeChange = (e) => {
    if (e === undefined) {
      dispatch({
        type: type,
        payload: {
          employeeId: "",
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          employeeId: e,
          employeeIdErrorMsg: "",
          showDays: false,
        },
      });
    }
  };
  const getEmployee = () => {
    let params = ``;
    getEmployeeList(params).then((res) => {
      if (res.data.statusCode === 1) {
        dispatch({
          type: type,
          payload: {
            employeeList: res.data.responseData.employeesList,
          },
        });
      } else if (res.data.statusCode === 0) {
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
      }
    });
  };

  const handlediff = (startDate, endDate) => {
    let sd1 = moment(startDate).format("YYYY-MM-DD");
    let sd2 = moment(endDate).format("YYYY-MM-DD");
    var date1 = new Date(sd1);
    var date2 = new Date(sd2);
    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const handleLeaveDeductSubmit = (e) => {
    e.preventDefault();
    let formattedDate1 = "";
    if (startDate1 !== "") {
      formattedDate1 = new Date(startDate1);
    }
    let formattedDate2 = "";
    if (endDate1 !== "") {
      formattedDate2 = new Date(endDate1);
    }

    if (
      handleleaveDeductValidation({
        employeeId: employeeId,
        endDate: endDate1,
        startDate: startDate1,
        shortDayFrom: shortDayFrom,
        shortDayTo: shortDayTo,
        halfOfTheDay: halfOfTheDay,
        deductLeaveType: deductLeaveType,
        reply: reply,
        editorState: editorState,
        dispatch: dispatch,
      })
    ) {
      let leaveMsg = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      let params = {
        employeeId: employeeId,
        status: 2,
        leaveMsg: leaveMsg,
        leaveType: deductLeaveType, //today
        startDate:
          formattedDate1.getFullYear() +
          "-" +
          (formattedDate1.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          formattedDate1.getDate().toString().padStart(2, "0"),
        endDate:
          deductLeaveType === HALF_CASUAL_LEAVE ||
          deductLeaveType === HALF_EARNED_LEAVE ||
          deductLeaveType === HALF_SICK_LEAVE ||
          deductLeaveType === HALF_LWP ||
          deductLeaveType === HALF_COMPENSATORY_OFF ||
          deductLeaveType === HALF_OTHER ||
          deductLeaveType === HALF_WFH ||
          deductLeaveType === SHORT_DAY_LEAVE
            ? formattedDate1.getFullYear() +
              "-" +
              (formattedDate1.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              formattedDate1.getDate().toString().padStart(2, "0")
            : formattedDate2.getFullYear() +
              "-" +
              (formattedDate2.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              formattedDate2.getDate().toString().padStart(2, "0"),
        halfOfTheDay:
          deductLeaveType === HALF_CASUAL_LEAVE ||
          deductLeaveType === HALF_EARNED_LEAVE ||
          deductLeaveType === HALF_SICK_LEAVE ||
          deductLeaveType === HALF_LWP ||
          deductLeaveType === HALF_COMPENSATORY_OFF ||
          deductLeaveType === HALF_OTHER ||
          deductLeaveType === HALF_WFH
            ? halfOfTheDay
            : 0,
        to: shortDayTo,
        from: shortDayFrom,
      };

      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      leaveDeductByAdmin(params).then((res) => {
        let { status } = ResponseFilter(res);
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
        if (status) {
          dispatch({
            type: type,
            payload: {
              miniLoader: false,
              resDeductMessage: true,
              deductLeaveType: "",
              employeeId: "",
              resleaveStatus: res.data.responseData.leaveDetails.leaveType,
              openLeaveApprove_RejectPopup: false,
              openLeaveLwpApprovePopup: false,
              openDeductLeavePopup: false,
              successMessage: "Leave Deducted",
              resMsg: true,
              editorState: EditorState.createEmpty(),
            },
          });
        } else {
          dispatch({
            type: type,
            payload: {
              resMsgFail: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
        }
        responseAction();
        getLeave();
      });
      handleClose();
    }
  };

  const handleSelectHalfChange = (e) => {
    dispatch({
      type: type,
      payload: {
        halfOfTheDay: e,
        halfOfTheDayErrMsg: "",
      },
    });
  };

  const onStartChange = (e) => {
    dispatch({
      type: type,
      payload: {
        startDate1: moment(e).toISOString(),
        start1dateErrorMsg: "",
      },
    });
  };

  const onEndChange = (e) => {
    dispatch({
      type: type,
      payload: {
        endDate1: moment(e).toISOString(),
        enddate1ErrorMsg: "",
      },
    });
  };

  const handleCheckInChange = (checkIn, checkInString) => {
    dispatch({
      type: type,
      payload: {
        shortDayFrom: moment.duration(checkInString).asSeconds(),
        shortDayFromErrorMsg: "",
      },
    });
  };

  const handleCheckOutChange = (checkOut, checkOutString) => {
    dispatch({
      type: type,
      payload: {
        shortDayTo: moment.duration(checkOutString).asSeconds(),
        shortDayToErrorMsg: "",
      },
    });
  };

  const handleDeductleaveTypeChange = (e) => {
    dispatch({
      type: type,
      payload: {
        deductLeaveType: parseInt(e),
        leaveType1ErrorMsg: "",
      },
    });
  };

  const handleOpenView = (reason, viewDocument) => {
    dispatch({
      type: type,
      payload: {
        openView: true,
        reason: reason,
        viewDocument: viewDocument,
      },
    });
  };
  const handleSelectChange = () => {
    dispatch({
      type: type,
      payload: {
        search: "",
      },
    });
  };

  const handleSavePartiiton = () => {
    if (
      handleNumberOfPartitionValidation({
        numberOfPartition: numberOfPartition,
        numberOfLeaves: numberOfLeaves,
        dispatch: dispatch,
      })
    ) {
      let c = [];
      for (let index = 1; index <= numberOfPartition; index++) {
        c.push({
          days: 0,
          leaveType: 0,
          form: 0,
          to: 0,
          startDate: "",
          endDate: "",
          approveReply: EditorState.createEmpty(),
          halfOfTheDay: 0,
          endOpen: false,
        });
        dispatch({
          type: type,
          payload: {
            editLeaveArray: c,
            showEditSave: true,
          },
        });
      }
    }
  };

  const handleCheckInEditChange = (checkIn, checkInString, i, name, list) => {
    let newFormValues = [...list];
    newFormValues[i][name] = moment.duration(checkInString).asSeconds();
    dispatch({
      type: type,
      payload: {
        editLeaveArray: newFormValues,
      },
    });
  };

  const handleCheckOutEditChange = (
    checkOut,
    checkOutString,
    i,
    name,
    list
  ) => {
    let newFormValues = [...list];
    newFormValues[i][name] = moment.duration(checkOutString).asSeconds();
    dispatch({
      type: type,
      payload: {
        editLeaveArray: newFormValues,
      },
    });
  };

  const onStartEditChange = (i, e, list, name) => {
    let newFormValues = [...list];
    newFormValues[i][name] = moment(e).toISOString();
    dispatch({
      type: type,
      payload: {
        editLeaveArray: newFormValues,
      },
    });
  };

  const onEndEditChange = (i, e, list, name) => {
    let newFormValues = [...list];
    newFormValues[i][name] = moment(e).toISOString();
    dispatch({
      type: type,
      payload: {
        editLeaveArray: newFormValues,
      },
    });
  };

  const handleChangeValues = (i, e, list) => {
    let newFormValues = [...list];
    newFormValues[i][e.target.name] = e.target.value;
    dispatch({
      type: type,
      payload: {
        editLeaveArray: newFormValues,
      },
    });
  };
  const handleSelectChangeValue = (i, e, list, name) => {
    let newFormValues = [...list];
    if (name === "approveReply") {
      newFormValues[i][name] = e;
    } else {
      newFormValues[i][name] = parseInt(e);
    }
    dispatch({
      type: type,
      payload: {
        editLeaveArray: newFormValues,
      },
    });
  };

  const handleShowLeave = (e) => {
    window.location.href = `${url}${e}`;
  };

  const handleOpenViewDocPopup = (viewDocument) => {
    dispatch({
      type: type,
      payload: {
        viewDocumentPopup: true,
        viewDocument: viewDocument,
      },
    });
  };
  const handleCancel = () => {
    dispatch({
      type: type,
      payload: {
        viewDocumentPopup: false,
      },
    });
  };

  const handleOpenDeleteCompOff = () => {
    dispatch({
      type: type,
      payload: {
        openDeleteCompOff: true,
      },
    });
  };
  const handleTypeCompOffChange = (e) => {
    dispatch({
      type: type,
      payload: {
        showTempComOff: e === "1" ? true : false,
        showDays: e === "1" ? true : false,
        deleteCompOffNo: "",
        deleteCompOffNoErrMsg: "",
      },
    });
  };
  const handleDeleteCompOffChange = (e) => {
    dispatch({
      type: type,
      payload: {
        deleteCompOffNo: e.target.value,
        deleteCompOffNoErrMsg: "",
      },
    });
  };

  const handleDeleteCompOff = (e) => {
    e.preventDefault();
    if (
      validateDeleteCompOff(
        selectedCompOffDays,
        compOffCount,
        deleteCompOffNo,
        dispatch
      )
    ) {
      if (selectedCompOffDays) {
        const params = {
          employeeId: deleteEmpId,
          tempCompOffId: deleteLeaveId,
          numberOFCompOff: selectedCompOffDays - deleteCompOffNo,
        };
        dispatchToAction(
          editTempCompOffByAdminAction({
            useDispatch: dispatch,
            params: params,
            handleClose: handleClose,
            getData: getLeave,
            isDelete: true,
          })
        );
      } else {
        const params = {
          count: deleteCompOffNo,
          employeeId: deleteEmpId,
        };
        dispatchToAction(
          editCompOffCountByAdminAction({
            useDispatch: dispatch,
            params: params,
            handleClose: handleClose,
            getData: getLeave,
          })
        );
      }
    }
  };

  return (
    <div className="body_container cm_bold">
      <Toastify
        resMsg={resMsg}
        resMsgFail={resMsgFail}
        text={successMessage}
        responseMessage={responseMessage}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <h3 className="mb-5">LEAVE MANAGEMENT</h3>
          <div className="text-left cm_totalhr">
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div className="row d-flex justify-content-end ">
                <div className="col search_device_bar4">
                  <span
                    className="btn btn-primary add_empBtn"
                    onClick={handleDeductLeave}
                  >
                    Deduct Leave
                  </span>

                  <input
                    maxLength={20}
                    className="form-control mr-lg-2 cm_box"
                    type="search"
                    placeholder="Search by Name/E.ID"
                    aria-label="Search"
                    value={search}
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />
                  {search ? (
                    <span
                      className="cm_clear4"
                      onClick={(e) => handleSelectChange(e)}
                    >
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                  ) : (
                    <span className="cm_clear4">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  )}
                </div>
                <button
                  className="btn btn-danger delete-compoff-btn"
                  style={{ height: "2.4rem" }}
                  onClick={handleOpenDeleteCompOff}
                >
                  Delete CompOff
                </button>
              </div>
            </div>
          </div>
          <div className="filters justify-content-end mb-4">
            <DatePicker
              value={searchDate ? moment(searchDate) : ""}
              format={dateFormat}
              className="date-border filter-date mt-1"
              onChange={handleDate}
            />

            <Select
              className="select_year_cal"
              value={
                searchMonth
                  ? statusMon(Number(searchMonth))
                  : statusMon(new Date().getMonth() + 1)
              }
              defaultValue="Select Month"
              name="searchMonth"
              onChange={(e) => handleMonth(e)}
              allowClear={searchMonth ? true : false}
              disabled={dateRange === "true" || dateRange === true}
            >
              {MONTH_ARRAY.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>

            <Select
              className="select_year_cal"
              value={searchYear ? searchYear : new Date().getFullYear()}
              defaultValue="Select Year"
              name="searchYear"
              onChange={handleYear}
              allowClear={searchYear ? true : false}
              disabled={dateRange === "true" || dateRange === true}
            >
              {createSelectItems()}
            </Select>
            <Select
              placeholder="Other"
              className="select_year_cal"
              id="leave_tab_button"
              onChange={handleLeaveFilter}
              value={leaveTypeSelect ? leaveTypeSelect : "Other"}
              allowClear={leaveTypeSelect ? true : false}
            >
              {LEAVE_ARRAY.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            <Select
              showSearch
              name="employeeId"
              defaultValue="Select Name"
              onChange={(e) => handleShowLeave(e)}
              optionFilterProp="key"
              className="select_year_cal"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
              style={{ maxWidth: "16rem" }}
            >
              {!_.isEmpty(employeeList)
                ? employeeList
                    .sort(function (a, b) {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.firstName > b.firstName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map(
                      (employee) =>
                        employee.status === 1 && (
                          <Option
                            key={`${employee.firstName} ${employee.lastName}`}
                            value={employee.employeeId}
                          >
                            <span>
                              {employee.firstName} {employee.lastName} (
                              {employee.empId})
                            </span>
                          </Option>
                        )
                    )
                : ""}
            </Select>
          </div>
          <div className="leave-tab">
            <Tabs
              activeKey={tabStatus || "0"}
              id="uncontrolled-tab-example"
              onSelect={handleTabChange}
            >
              <Tab eventKey="0" title={`All ${all || 0}`}></Tab>
              <Tab
                eventKey="1"
                title={`Pending ${pending.length ? pending : 0}`}
              ></Tab>
              <Tab
                eventKey="2"
                title={`Approved ${approved.length ? approved : 0}`}
              ></Tab>
              <Tab
                eventKey="3"
                title={`Rejected ${rejected.length ? rejected : 0}`}
              ></Tab>
              <Tab
                eventKey="4"
                title={`Late ${lateCount ? lateCount : 0}`}
              ></Tab>
            </Tabs>
          </div>
          <div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="white bg_blue">
                  <tr>
                    <th scope="col">S.No</th>
                    <th>
                      Employee ID
                      <i
                        className={
                          sortType === 2 && sortField === "mobId"
                            ? "fa fa-sort-numeric-desc ml-2"
                            : "fa fa-sort-numeric-asc ml-2"
                        }
                        onClick={() => handleSort("mobId", dispatch, sortType)}
                      ></i>
                    </th>
                    <th scope="col" style={{ width: "170px" }}>
                      Name
                      <i
                        className={
                          sortType === 2 && sortField === "employeeName"
                            ? "fa fa-sort-alpha-desc ml-2"
                            : "fa fa-sort-alpha-asc ml-2"
                        }
                        onClick={() =>
                          handleSort("employeeName", dispatch, sortType)
                        }
                      ></i>
                    </th>

                    <th scope="col" style={{ width: "170px" }}>
                      Leave Type
                    </th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Applied On (Date)</th>
                    <th scope="col">Applied On (Time)</th>
                    <th scope="col">Reason</th>
                    <th scope="col" className="table-width">
                      Action
                    </th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {leaveList.map((leave, i) => (
                    <tr>
                      <td>{i + 1 + (pageNo - 1) * count}</td>
                      <td>{leave.mobId}</td>
                      <td style={{ width: "170px" }}>
                        <span className="cm_col">
                          <Link
                            to={{
                              pathname: `${url}${leave.employeeId}`,
                              state: { employeeName: leave.employeeName },
                            }}
                          >
                            {leave.employeeName}
                          </Link>
                        </span>
                      </td>
                      <td style={{ width: "170px" }}>
                        {leaveValue(leave.leaveType)}
                      </td>
                      <td>
                        {new Date(leave.startDate)
                          .getDate()
                          .toString()
                          .padStart(2, "0") +
                          "/" +
                          (new Date(leave.startDate).getMonth() + 1)
                            .toString()
                            .padStart(2, "0") +
                          "/" +
                          new Date(leave.startDate)
                            .getFullYear()
                            .toString()
                            .padStart(2, "0")}
                      </td>
                      <td>
                        {new Date(leave.endDate)
                          .getDate()
                          .toString()
                          .padStart(2, "0") +
                          "/" +
                          (new Date(leave.endDate).getMonth() + 1)
                            .toString()
                            .padStart(2, "0") +
                          "/" +
                          new Date(leave.endDate)
                            .getFullYear()
                            .toString()
                            .padStart(2, "0")}
                      </td>
                      <td>
                        {leave.day}/{leave.month}/{leave.year}
                      </td>
                      <td>
                        {moment.utc(leave?.createdAt).local().format("HH:mm A")}
                      </td>
                      <td>
                        <a>
                          <i
                            onClick={() =>
                              handleOpenView(leave.leaveMsg, leave.leaveDoc)
                            }
                            className="fa fa-eye blue ml-2"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </td>
                      <td className="table-width">
                        {leave.status === 1 && (
                          <>
                            <button
                              type="button"
                              className="btn btn-success rej_btn leave-reject-button btn-sm mr-1"
                              onClick={() =>
                                handleOpenLeaveApprove_RejectPopup(leave, true)
                              }
                            >
                              Approve
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm rej_btn leave-reject-button"
                              onClick={() =>
                                handleOpenLeaveApprove_RejectPopup(leave, false)
                              }
                            >
                              Reject
                            </button>
                            {leave.leaveMsg !== "UniqueLate" && (
                              <i
                                className="fa fa-pencil blue ml-4"
                                title="edit"
                                onClick={() =>
                                  handleOpenLeaveLwpPopup(leave, false)
                                }
                                aria-hidden="true"
                              ></i>
                            )}
                          </>
                        )}

                        {leave.status === 2 && (
                          <>
                            <button
                              className="btn btn-danger ml-3 btn-sm rej_btn leave-reject-button"
                              onClick={() =>
                                handleOpenLeaveApprove_RejectPopup(leave, false)
                              }
                            >
                              Reject
                            </button>
                            {leave.leaveType === 12 && (
                              <i
                                className="fa fa-pencil blue ml-4"
                                title="edit"
                                onClick={() =>
                                  handleOpenApprovedShortLeavePopup(
                                    leave,
                                    false
                                  )
                                }
                                aria-hidden="true"
                              ></i>
                            )}
                          </>
                        )}

                        {leave.status === 3 && (
                          <>
                            <button
                              className="btn btn-success ml-3 btn-sm rej_btn leave-reject-button"
                              onClick={() =>
                                handleOpenLeaveApprove_RejectPopup(leave, true)
                              }
                            >
                              Approve
                            </button>
                            <i
                              className="fa fa-pencil blue ml-4"
                              title="edisdffdfdgft"
                              onClick={() =>
                                handleOpenLeaveLwpPopup(leave, false)
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td>
                        {leave.status === 2 ? (
                          leave.leaveType === 4 ? (
                            <span className="text-danger inline-block mr-4 ml-3">
                              LWP
                            </span>
                          ) : (
                            <span className="text-success inline-block mr-2">
                              Approved
                            </span>
                          )
                        ) : leave.status === 3 ? (
                          leave.leaveType === 4 ? (
                            <span className="text-danger inline-block mr-4 ml-3">
                              LWP
                            </span>
                          ) : (
                            <span className="text-danger inline-block mr-3">
                              Rejected
                            </span>
                          )
                        ) : (
                          <span className="text-secondary inline-block mr-3">
                            Pending
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {leaveList && leaveList.length > 0 ? (
                ""
              ) : (
                <h3 className="text-center"> No data available</h3>
              )}
            </div>
          </div>
          <ViewLeaveReason
            show={openView}
            reason={reason}
            handleClose={handleClose}
            data={viewDocument}
            handleOpenViewDocPopup={handleOpenViewDocPopup}
          />

          <ApproveRejectLeaveModal
            show={openLeaveApprove_RejectPopup}
            handleClose={handleClose}
            isApproved={isApproved}
            leaveType={leaveType1}
            startDate={startDate}
            halfOfTheDay={halfOfTheDay}
            endDate={endDate}
            replyErrorMsg={replyErrorMsg}
            leaveMsg={leaveMsg}
            handleChange={handleChange}
            handleAction={handleAction}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
          <EditLeaveModal
            show={openLeaveLwpApprovePopup}
            handleClose={handleClose}
            leaveType1={leaveType1}
            startDate={startDate1}
            start1dateErrorMsg={start1dateErrorMsg}
            endDate={endDate1}
            leaveMsg={leaveMsg}
            endDateErrMsg={endDateErrMsg}
            handleChange={handleChange}
            handleActionSubmit={handleActionSubmit}
            replyErrorMsg={replyErrorMsg}
            shortDayToErrorMsg={shortDayToErrorMsg}
            shortDayFromErrorMsg={shortDayFromErrorMsg}
            totalLeave={totalLeave}
            onStartChange={onStartEditChange}
            onEndChange={onEndEditChange}
            handleCheckInChange={handleCheckInEditChange}
            handleCheckOutChange={handleCheckOutEditChange}
            shortDayFrom={shortDayFrom}
            shortDayTo={shortDayTo}
            halfOfTheDay={halfOfTheDay}
            halfOfTheDayErrMsg={halfOfTheDayErrMsg}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            numberOfLeaves={numberOfLeaves}
            numberOfPartition={numberOfPartition}
            handlePartitionChange={handlePartitionChange}
            numberOfPartitionErrorMessage={numberOfPartitionErrorMessage}
            handleSavePartiiton={handleSavePartiiton}
            editLeaveArray={editLeaveArray}
            handleChangeValues={handleChangeValues}
            handleSelectChangeValue={handleSelectChangeValue}
            showEditSave={showEditSave}
            loader={loader}
          />
          <EditApprovedShortLeaveModal
            show={openApprovedShortLeavePopup}
            handleClose={handleClose}
            handleLeaveTypeChange={handleLeaveTypeChange}
            leaveType1={leaveType1}
            startDate={startDate1}
            endDate={endDate1}
            leaveMsg={leaveMsg}
            handleChange={handleChange}
            handleShortLeaveEditActionSubmit={handleShortLeaveEditActionSubmit}
            replyErrorMsg={replyErrorMsg}
            shortDayToErrorMsg={shortDayToErrorMsg}
            shortDayFromErrorMsg={shortDayFromErrorMsg}
            totalLeave={totalLeave}
            handleSelectHalfChange={handleSelectHalfChange}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            handleCheckInChange={handleCheckInChange}
            handleCheckOutChange={handleCheckOutChange}
            shortDayFrom={shortDayFrom}
            shortDayTo={shortDayTo}
            halfOfTheDay={halfOfTheDay}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
          <ViewUploadedDocument
            show={viewDocumentPopup}
            data={viewDocument}
            handleClose={handleCancel}
          />
          <DeductLeaveModal
            show={openDeductLeavePopup}
            employeeId={employeeId}
            handleSelectEmployeeChange={handleSelectEmployeeChange}
            employeeList={employeeList}
            employeeIdErrorMsg={employeeIdErrorMsg}
            deductLeaveType={deductLeaveType}
            handleDeductleaveTypeChange={handleDeductleaveTypeChange}
            handlediff={handlediff}
            startDate={startDate1}
            endDate={endDate1}
            leaveType1ErrorMsg={leaveType1ErrorMsg}
            halfOfTheDayErrMsg={halfOfTheDayErrMsg}
            start1dateErrorMsg={start1dateErrorMsg}
            enddate1ErrorMsg={enddate1ErrorMsg}
            shortDayFromErrorMsg={shortDayFromErrorMsg}
            shortDayToErrorMsg={shortDayToErrorMsg}
            replyErrorMsg={replyErrorMsg}
            handleChange={handleChange}
            handleLeaveDeductSubmit={handleLeaveDeductSubmit}
            responseMessage={responseMessage}
            handleClose={handleClose}
            handleSelectHalfChange={handleSelectHalfChange}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            handleCheckInChange={handleCheckInChange}
            handleCheckOutChange={handleCheckOutChange}
            shortDayFrom={shortDayFrom}
            shortDayTo={shortDayTo}
            halfOfTheDay={halfOfTheDay}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
          <DeleteCompOffTempCompModal
            employeeId={employeeId}
            handleSelectEmployeeChange={handleSelectEmployeeChange}
            employeeList={employeeList}
            employeeIdErrorMsg={employeeIdErrorMsg}
            handleClose={handleClose}
            show={openDeleteCompOff}
            showTempComOff={showTempComOff}
            handleTypeCompOffChange={handleTypeCompOffChange}
            deleteCompOffNo={deleteCompOffNo}
            handleDeleteCompOffChange={handleDeleteCompOffChange}
            deleteCompOffNoErrMsg={deleteCompOffNoErrMsg}
            handleDeleteCompOff={handleDeleteCompOff}
            deleteEmpId={deleteEmpId}
            setDispatch={dispatch}
            selectedCompOffDays={selectedCompOffDays}
            showDays={showDays}
            compOffCount={compOffCount}
          />

          {dataCount > 10 ? (
            <div style={{ marginBottom: "1rem" }}>
              <Pagination
                activePage={+pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}

export default AdminLeave;

import { DatePicker, Select } from 'antd'
import moment from 'moment';
import React, { Component } from 'react'
import { CONSTANTS } from "../../config";
import { Loader } from '../svg';
import Pagination from 'react-js-pagination';
import _ from 'lodash';
import { getAdminActivityList, getAdminProfile } from '../../config/services/employeeServices';
import ResponseFilter from '../../ResponseFilter';
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const format = "HH:mm";
let arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i <= 3; i++) {
    arrayOfYear.push(currentYear - i);
}
const statusMon = (type) => {
    switch (type) {
        case 1:
            return "January";
            break;
        case 2:
            return "February";
            break;
        case 3:
            return "March";
            break;
        case 4:
            return "April";
            break;
        case 5:
            return "May";
            break;
        case 6:
            return "June";
            break;

        case 7:
            return "July";
            break;
        case 8:
            return "August";
            break;
        case 9:
            return "September";
            break;
        case 10:
            return "October";
            break;
        case 11:
            return "November";
            break;
        case 12:
            return "December";
            break;
    }
};
const statusType = (type) => {
    switch (type) {
        case 1:
            return "Created"
            break;
        case 2:
            return "Updated"
            break;
        case 3:
            return "Deleted"
            break;
    }
}
const activityByType = (type) => {
    switch (type) {
        case 1:
            return "Admin"
            break;
        case 2:
            return "User"
            break;
    }
}
export class EmployeeHrActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activityList: [],
            searchDate: "",
            searchTimeStamp: "",
            searchMonth: new Date().getMonth(),
            searchYear: new Date().getFullYear(),
            search: "",
            sortType: SORT_TYPE_CONSTANTS.Descending,
            sortField: "created",
            pageNo: 1,
            actionType: "",
            count: 10,
            dataCount: "",
            miniLoader: false,
            loader: true,
            activityBy: "",
            employeeId: ""
        }
        this.debounceSearch = null
    }


    componentDidMount() {
        let url_string = window.location.href
        let url = new URL(url_string);
        let pageNo = url.searchParams.get("pageNo");
        let searchMonth = url.searchParams.get("month")
        let search = url.searchParams.get("search");
        let searchYear = url.searchParams.get("year")
        let sortType = url.searchParams.get("sortType");
        let sortField = url.searchParams.get("sortField");
        let searchDate = url.searchParams.get("date") == null || url.searchParams.get("date") == "null" ? "" : url.searchParams.get("date");
        let searchTimeStamp = url.searchParams.get("searchTimeStamp");
        let actionType = url.searchParams.get("activityType");
        let activityBy = url.searchParams.get("activityBy");
        const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";

        this.setState({
            pageNo: pageNo ? pageNo : 1,
            search: search ? search : "",
            sortType: sortType ? sortType : SORT_TYPE_CONSTANTS.Descending,
            sortField: sortField ? sortField : "created",
            searchDate: searchMonth || searchYear ? "" : searchDate ? moment(searchDate, format) : "",
            actionType: actionType ? actionType : "",
            activityBy: activityBy ? activityBy : "",
            searchTimeStamp: searchMonth || searchYear ? "" : searchTimeStamp ? searchTimeStamp : "",
            searchYear: searchDate ? "" : searchYear ? searchYear : new Date().getFullYear(),
            searchMonth: searchDate ? "" : searchMonth ? Number(searchMonth) : new Date().getMonth(),

        }, () => {
            this.getDetail()
        })
    }
    createSelectItems() {
        let item = [];
        let currentYear = new Date().getFullYear()

        for (let i = 0; i <= 5; i++) {
            let prevYear = currentYear - 1;
            if (i === 0) {
                item.push(<option key={currentYear} value={`${currentYear}`}>{currentYear}</option>)
            } else {
                item.unshift(<option key={prevYear} value={`${prevYear}`}>{prevYear}</option>)
            }

            if (i > 0) {

                currentYear = prevYear
            }
        }
        return item.sort(function (a, b) { return b.key - a.key });

    }


    handleMonth = (e) => {
        if (e === undefined) {
            this.setState({ searchMonth: new Date().getMonth(), pageNo: 1, month: false }, () => {
                this.getAdminActivity();
            });
        }
        else {
            this.setState({ searchMonth: e, pageNo: 1, month: true, searchDate: "", }, () => {
                this.getAdminActivity();
            });
        }
    };

    handleYear = (e) => {
        if (e === undefined) {
            this.setState({ searchYear: new Date().getFullYear(), pageNo: 1, year: false }, () => {
                this.getAdminActivity();
            });
        }
        else {
            this.setState({ searchYear: e ? e : "", pageNo: 1, year: true, searchDate: "" }, () => {
                this.getAdminActivity();
            });
        }
    };
    handleActionType = (e) => {
        if (e === undefined) {
            this.setState({ actionType: "", pageNo: 1 }, () => {
                this.getAdminActivity();
            });
        }
        else {
            this.setState({ actionType: e ? e : "", pageNo: 1 }, () => {
                this.getAdminActivity();
            });
        }
    };
    handleActivityBy = (e) => {
        if (e === undefined) {
            this.setState({ activityBy: "", pageNo: 1 }, () => {
                this.getAdminActivity();
            });
        }
        else {
            this.setState({ activityBy: e ? e : "", pageNo: 1 }, () => {
                this.getAdminActivity();
            });
        }
    };
    handleSelectChange = () => {
        this.setState({ search: '' }, () => this.getAdminActivity());
    }
    handleSearch = e => {
        this.setState({ search: e.target.value, pageNo: 1, actionType: this.state.actionType });
        clearTimeout(this.debouncedSearch);
        this.debounceSearch = setTimeout(() => {
            this.getAdminActivity();
        }, 2000);
    }
    handleDate = e => {
        if (e) {
            this.setState({ searchDate: e, pageNo: 1, searchTimeStamp: e ? new Date(e).setHours(6, 30, 0, 0) : "", searchMonth: "", searchYear: "" }, () => {
                this.getAdminActivity()
            })
        }
        else {
            this.setState({
                dateRange: false, searchDate: e, pageNo: 1, searchTimeStamp: e ? new Date(e).setHours(6, 30, 0, 0) : "", dateRange: false, searchMonth: new Date().getMonth(), searchYear: new Date().getFullYear(),
            }, () => {
                this.getAdminActivity()
            })
        }
    }
    handleSort = e => {
        this.setState({ loader: false });
        this.setState({ sortField: e, sortType: this.state.sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending }, () => this.getAdminActivity());
    }
    handlePageChange = (pageNo) => {
        this.setState({ pageNo: pageNo, loader: true }, () => this.getAdminActivity());
    }

    getDetail = () => {
        getAdminProfile().then((res) => {
            let { status, resData } = ResponseFilter(res);

            if (status) {
                this.setState(
                    {
                        employeeId: resData.employeeProfile.employeeId,
                    },
                    () => this.setState({ loader: false })
                );
                this.getAdminActivity();
            }
        });
        setTimeout(() => this.setState({ resMsg: false }), 4000);
    };


    getAdminActivity = () => {
        let { search, sortType, sortField, searchTimeStamp, searchDate, pageNo, count, actionType, tabStatus, searchMonth, searchYear, activityBy, employeeId } = this.state
        let params = `search=${search.trim()}&date=${searchYear || searchMonth ? "" : searchTimeStamp}&activityType=${actionType}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}&month=${searchDate || searchTimeStamp ? searchMonth : Number(searchMonth) + 1}&year=${searchYear}&activityBy=${activityBy}&employeeId=${employeeId}`;
        this.props.history.push(`user-hrActivity?search=${search}&sortType=${sortType}&sortField=${sortField}&pageNo=${pageNo}&date=${searchDate}&searchTimeStamp=${searchMonth || searchYear ? "" : searchTimeStamp}&activityType=${actionType}&month=${searchMonth}&year=${searchYear}&activityBy=${activityBy}&employeeId=${employeeId}`)

        getAdminActivityList(params).then(res => {
            this.setState({ loader: true })
            if (res.data.statusCode === 1) {
                this.setState({

                    activityList: res.data.responseData.activityResult,
                    dataCount: res.data.responseData.dataCount
                }, () => this.setState({ loader: false }))
            }
            else {
                this.setState({ loader: false })
            }

        })


    }
    handleRoute = (e) => {
        switch (e.activity) {
            case "Profile picture edited":
            case "Education Added":
            case "Qualificaiton Added":
            case "Profile edited":
            case "Skill added":
            case "OtherSkill Added":
            case "Skill updated":
            case "OtherSkill updated":
            case "Education Updated":
            case "Qualification Updated":
            case "Education Deleted":
            case "Qualification Deleted":
            case "Document Added":
            case "Document Deleted":
            case "Employee status updated":
            case "Edit request":
            case "Employee department changed":
            case "Password changed":
            case "Password Reset":
            case "Role changed to user":
            case "Role changed to admin":
                window.location.href = "/employee-profile";
                break;
            case "Request for a device":
            case "Device request update":
                window.location.href = "/employee-device"
                break;
            case "Holiday updated":
            case "Holiday deleted":
                window.location.href = `/holiday`;
                break;
            case "Investment created":
            case "Investment updated":
            case "Investment deleted":
                window.location.href = `/investment`;
                break;
            case "Reimbursement claimed":
            case "Reimbursement updated":
            case "Action taken on Reimbursement":
                window.location.href = `/reimbursement`;
                break;
            case "Resume added by Userd":
            case "Resume added by Admin":
            case "Resume updated":
            case "Resume deleted":
                window.location.href = `/resumeDetails`;
                break;
            case "Ticket raised":
            case "Ticket resolved":
            case "Ticket status updated":
                window.location.href = `/addTicket`;
                break;
            case "Project Assigned":
            case "Project assigned by project manager":
            case "Project assigned by technical lead":
            case "Project assigned by associate technical lead":
            case "Project Details updated":
            case "Action taken on project":
            case "Action taken on project by manager":
            case "OtherSkill updatedAction taken on project by tech lead":
            case "Action taken on project by associate tech lead":
            case "Project deleted":
            case "Project deleted by manager":
            case "Project deleted by tech lead":
            case "Project deleted by tech lead":
                window.location.href = "/projects";
                break;
        }
    }
    render() {
        let {
            searchDate, searchMonth, searchYear, search, sortField, sortType, actionType, loader, activityList, dataCount, pageNo, count, activityBy, employeeId
        } = this.state
        return (
            <div className='body_container cm_bold'>
                {
                    loader && <Loader />
                }
                <h3>My Activity</h3>
                <div className="filters d-flex justify-content-end mb-2 ">
                    <Select
                        className="select_year_cal"
                        value={activityBy ? activityBy : " Activity By"}
                        name="searchYear"
                        onChange={this.handleActivityBy}
                        allowClear={activityBy ? true : false}
                    >
                        <Option value="1">Admin</Option>
                        <Option value="2">User</Option>


                    </Select>
                    <Select
                        className="select_year_cal"
                        value={actionType ? actionType : "Filter Activity"}
                        name="searchYear"
                        onChange={this.handleActionType}
                        allowClear={actionType ? true : false}
                    >
                        <Option value="1">Created</Option>
                        <Option value="2">Updated</Option>
                        <Option value="3">Deleted</Option>

                    </Select>
                    <DatePicker
                        value={searchDate ? moment(searchDate) : ""} format={dateFormat}
                        className="date-border mt-1 filter-date"
                        onChange={this.handleDate}
                    />
                    <Select
                        className="select_year_cal"
                        value={searchMonth && searchMonth != 0 ? statusMon(Number(searchMonth) + 1) : searchMonth == 0 && searchMonth !== "" ? statusMon(1) : "Search Month"}
                        name="searchMonth"
                        onChange={this.handleMonth}
                        allowClear={searchMonth ? true : false}
                    >
                        <Option value="0">January</Option>
                        <Option value="1">February</Option>
                        <Option value="2">March</Option>
                        <Option value="3">April</Option>
                        <Option value="4">May</Option>
                        <Option value="5">June</Option>
                        <Option value="6">July</Option>
                        <Option value="7">August</Option>
                        <Option value="8">September</Option>
                        <Option value="9">October</Option>
                        <Option value="10">November</Option>
                        <Option value="11">December</Option>
                    </Select>

                    <Select
                        className="select_year_cal"
                        value={searchYear ? searchYear : "Select Year"}
                        name="searchYear"
                        onChange={this.handleYear}
                        allowClear={searchYear ? true : false}
                    >
                        {this.createSelectItems()}
                    </Select>
                </div>
                {localStorage.getItem("isAdmin") == 2 ? "" :
                    <div className="row d-flex justify-content-end mb-4 mt-5">
                        <div className="col-md-6 search_device_bar select__date">

                            <input className="form-control mr-lg-2  cm_box"
                                maxLength={20}
                                type="search"
                                placeholder="Search by Name/Emp ID"
                                aria-label="Search"
                                value={search}
                                onChange={this.handleSearch}
                            // onKeyDown={(e) => { if (e.key === 'Enter' && search !== "#") this.getAdminActivity() }}
                            />
                            {search ?
                                <span className="cm_clear" onClick={this.handleSelectChange}><i className="fa fa-times-circle"
                                    aria-hidden="true"></i></span> :
                                <span className="cm_clear"><i className="fa fa-search" aria-hidden="true"></i></span>}
                        </div>
                    </div>
                }
                <div>
                    <div>
                        <div className="table-responsive ticket_table">
                            <table className="table table-bordered">
                                <thead className="white bg_blue">
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Employee ID <i
                                            className={sortType === SORT_TYPE_CONSTANTS.Descending && sortField === "empId" ? "fa fa-sort-numeric-desc ml-2" : "fa fa-sort-numeric-asc ml-2"}

                                            onClick={() => this.handleSort('empId')}
                                        ></i></th>
                                        <th>Name
                                            <i
                                                className={sortType === SORT_TYPE_CONSTANTS.Descending && sortField === "name" ? "fa fa-sort-alpha-desc ml-2" : "fa fa-sort-alpha-asc ml-2"}

                                                onClick={() => this.handleSort('name')}
                                            ></i>
                                        </th>
                                        <th scope="col">Date
                                            <i
                                                className={sortType === SORT_TYPE_CONSTANTS.Descending && sortField === "created" ? "fa fa-sort-numeric-desc ml-2" : "fa fa-sort-numeric-asc ml-2"}

                                                onClick={() => this.handleSort('created')}
                                            ></i>
                                        </th>
                                        <th scope="col">
                                            Activity Type
                                        </th>
                                        <th scope='col'> Activity By</th>
                                        <th scope="col">Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !_.isEmpty(activityList) ?
                                            activityList.map((data, i) => (
                                                <tr>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_col"> {data.empId}</span></td>
                                                    <td><span className="cm_col">{data.firstName + " " + data.lastName}</span></td>
                                                    <td><span className="cm_col"></span>{new Date(data.date).toLocaleDateString()}{"  "}{new Date(data.date).toLocaleTimeString()}</td>
                                                    <td ><span className="cm_col">{statusType(data.activityType)}</span></td>
                                                    <td><span className='cm_col'>{activityByType(data.activityBy)}</span></td>
                                                    <td style={{ width: 400 }} > {data.activity != "Wallet recharged" && "Skill added" && "Skill deleted" ? < span onClick={() => this.handleRoute(data)} className='cm_col darkblue activity-pointer' >{data.activity}</span> : <span className='cm_col activity-pointer' >{data.activity}</span>}</td>
                                                </tr>
                                            )) : ''
                                    }
                                </tbody>
                            </table>
                            {activityList.length ? '' : <h3 className="text-center">No data is available</h3>}
                        </div>


                        {dataCount > 10 ? <Pagination
                            activePage={Number(pageNo)}
                            itemsCountPerPage={count}
                            totalItemsCount={dataCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        /> : ''}
                    </div>
                </div>
                {/* {
                    loader ? null :
                        AppraisalList.length > 0 ? '' : <h3 className="text-center mt-5">No data is available</h3>

                } */}
            </div >
        )
    }
}

export default EmployeeHrActivity
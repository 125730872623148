import React, { useEffect, useMemo, useReducer } from "react";
import ApplyLeave from "./ApplyLeave";
import _ from "lodash";
import moment from "moment";
import ResponseFilter from "../../ResponseFilter";
import LeaveStatus from "./LeaveStatus";
import {
  getEmployeeLeaves,
  deleteApplyLeave,
  applyLeaveByEmployee,
  employeeAdminLeaveStatus,
  getEmployeeListByManager,
} from "../../config/services/employeeServices";
import { Select } from "antd";
import { currentYear, EndDate_Greater, handleSelectLeave } from "./Constants";
import { ApplyForClientLocationModal } from "./leaveModal/ApplyForClientSideModal";
import { ViewLeaveModal } from "./leaveModal/ViewLeaveModal";
import DeleteLeaveByEmp from "./leaveModal/DeleteLeaveByEmp";
import {
  checkLeaveArray,
  currentMonth,
  currentQuarter,
  emailClick,
  reducer,
  REQUIRED,
  Toastify,
  type,
} from "../helper/Constant";
import EmployeeLeaveData from "./EmployeeLeaveData";
import EmpLeaveDetail from "./EmpLeaveDetail";
import { useDispatch } from "react-redux";
import { getEmpProfileByAdmin } from "../../redux/actions";
import { Loader } from "../svg";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
const { Option } = Select;

const initialState = {
  status1: "",
  applyLeave: false,
  selectLeave: "",
  leaveDetails: [],
  status: "",
  pageNo: 1,
  count: 10,
  searchYear: currentYear,
  dataCount: "",
  chartView: false,
  sortField: "createdAt",
  sortType: 2,
  openLeaveApprovePopup: false,
  openDeleteLeavePopup: false,
  leaveId: "",
  ResData: {},
  totalLeavesRes: {},
  openApplyForClientOffice: false,
  leaveType1: "",
  leaveType1ErrorMsg: "",
  halfOfTheDay: "",
  halfOfTheDayErrMsg: "",
  enddate1ErrorMsg: "",
  start1dateErrorMsg: "",
  startDate1: "",
  endDate1: "",
  value: "",
  emails: [],
  emailError: "",
  inputEmail: "",
  replyErrorMsg: "",
  rejectedCount: "",
  approvedCount: "",
  pendingCount: "",
  lateCount: "",
  reply: "",
  startDate: "",
  endDate: "",
  leaveMsg: "",
  searchMonth: currentMonth - 1,
  searchQuatName: currentQuarter,
  id: localStorage.getItem("employeeId"),
  shortDayLeave: "",
  totalOtherLeaves: "",
  totalLeavesTaken: "",
  earnedLeaves: "",
  casualLeaves: "",
  sickLeaves: "",
  compOff: "",
  Lwp: "",
  Wfh: "",
  openLeaveDetail: false,
  index: "",
  leaveTitle: "",
  totalLeaves: "",
  totalCl: 0,
  totalEl: 0,
  totalSl: 0,
  totalCompOff: 0,
  totalShortLeave: 0,
  totalLWP: 0,
  totalCompOffTaken: 0,
  resMsg: false,
  resFailMsg: false,
  loader: false,
  remainingMonthlyShortLeave: "",
  employeeType: "",
  successMessage: "",
  responseMessage: "",
  assignedCl: "",
  assignedEl: "",
  assignedSl: "",
  leaveStatus: "",
  halfOfTheDayStatus: "",
  leaveType: "",
  tempCompOff: [],
  shortDayFrom: 0,
  shortDayTo: 0,
  tempCompOffCount: 0,
  assignedStatusCl: "",
  assignedStatusEl: "",
  assignedStatusSl: "",
  editorState: EditorState.createEmpty(),
  leaveTypeSelect: "",
  employeeList: [],
  isAdmin: false,
  viewDocumentPopup: false,
  viewDocument: [],
  totalClientOff: "",
  bereavementLeaves: "",
  paternalLeave: "",
  assignedClientOff: "",
  assignedBereavementLeaves: "",
  assignedPaternalLeave: "",
};
function Leaves() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchToAction = useDispatch();
  const history = useHistory();
  const dashboardApplyLeave = sessionStorage.getItem("dashboard_apply_leave");
  const {
    status1,
    applyLeave,
    selectLeave,
    status,
    pageNo,
    totalLeaves,
    count,
    searchYear,
    chartView,
    sortField,
    sortType,
    openLeaveApprovePopup,
    openDeleteLeavePopup,
    leaveId,
    ResData,
    totalLeavesRes,
    openApplyForClientOffice,
    leaveType1,
    leaveType1ErrorMsg,
    halfOfTheDay,
    halfOfTheDayErrMsg,
    enddate1ErrorMsg,
    start1dateErrorMsg,
    startDate1,
    endDate1,
    value,
    emails,
    emailError,
    inputEmail,
    replyErrorMsg,
    rejectedCount,
    approvedCount,
    pendingCount,
    lateCount,
    leaveDetails,
    assignedCl,
    assignedEl,
    assignedSl,
    reply,
    startDate,
    endDate,
    leaveMsg,
    searchMonth,
    searchQuatName,
    id,
    shortDayLeave,
    totalOtherLeaves,
    totalLeavesTaken,
    earnedLeaves,
    casualLeaves,
    sickLeaves,
    compOff,
    Lwp,
    Wfh,
    openLeaveDetail,
    index,
    leaveTitle,
    totalCl,
    totalEl,
    totalSl,
    totalLWP,
    totalCompOffTaken,
    resMsg,
    loader,
    remainingMonthlyShortLeave,
    employeeType,
    successMessage,
    resFailMsg,
    responseMessage,
    leaveStatus,
    halfOfTheDayStatus,
    leaveType,
    dataCount,
    tempCompOff,
    shortDayFrom,
    shortDayTo,
    editorState,
    tempCompOffCount,
    assignedStatusCl,
    assignedStatusEl,
    assignedStatusSl,
    leaveTypeSelect,
    employeeList,
    isAdmin,
    viewDocumentPopup,
    viewDocument,
    totalCompOff,
    totalClientOff,
    bereavementLeaves,
    paternalLeave,
    assignedClientOff,
    assignedBereavementLeaves,
    assignedPaternalLeave,
  } = state;
  const byAdmin = 0;
  const onEditorStateChange = (editorState) => {
    dispatch({
      type: type,
      payload: {
        editorState: editorState,
        replyErrorMsg: "",
      },
    });
  };
  useEffect(() => {
    getEmployeeLeavesList();
    // eslint-disable-next-line
  }, [
    sortType,
    sortField,
    count,
    leaveTypeSelect,
    pageNo,
    status,
    searchYear,
    status1,
  ]);

  useEffect(() => {
    getData();
    getEmployeeList();
    // eslint-disable-next-line
  }, []);

  const getEmployeeList = () => {
    getEmployeeListByManager("").then((res) => {
      let { status } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            employeeList: res.data.responseData.employeesList,
          },
        });
      }
    });
  };

  const getData = () => {
    dispatchToAction(
      getEmpProfileByAdmin({
        useDispatch: dispatch,
      })
    );
  };

  useEffect(() => {
    getStatus();
    // eslint-disable-next-line
  }, [
    searchMonth,
    searchQuatName,
    searchYear,
    status,
    pageNo,
    chartView,
    leaveTypeSelect,
  ]);

  const getStatus = () => {
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    const params = {
      employeeId: id,
      searchQuarter:
        searchQuatName !== "" && searchMonth == "" ? searchQuatName + 1 : "",
      searchMonth:
        searchMonth !== "" && searchQuatName == "" ? searchMonth + 1 : "",
      searchYear: searchYear,
    };
    history.push(
      `leaves?&pageNo=${pageNo}&sortField=${sortField}&sortType=${sortType}&status1=${status1}&status=${status}&searchYear=${searchYear}&searchMonth=${searchMonth}&searchQuatName=${searchQuatName}&selectLeave=${selectLeave}&chartView=${chartView}&leaveTypeSelect=${leaveTypeSelect}`
    );
    employeeAdminLeaveStatus(params).then((res) => {
      dispatch({
        type: type,
        payload: {
          loader: false,
        },
      });
      let { status, resData } = ResponseFilter(res);
      let totalLeaveDetail = resData.leaveDetails;
      if (status) {
        dispatch({
          type: type,
          payload: {
            earnedLeaves: checkLeaveArray(
              totalLeaveDetail.totalEarnedLeaves[0]?.totalEarnedLeaves,
              totalLeaveDetail?.totalHalfEarnedLeaves[0]?.totalHalfEarnedLeaves
            ),
            casualLeaves: checkLeaveArray(
              totalLeaveDetail.totalCasualLeaves[0]?.totalCasualLeaves,
              totalLeaveDetail?.totalHalfCasualLeaves[0]?.totalHalfCasualLeaves
            ),
            sickLeaves: checkLeaveArray(
              totalLeaveDetail.totalSickLeaves[0]?.totalSickLeaves,
              totalLeaveDetail?.totalHalfSickLeaves[0]?.totalHalfSickLeaves
            ),
            compOff: checkLeaveArray(
              totalLeaveDetail.totalCompOffLeaves[0]?.totalCompOffLeaves,
              totalLeaveDetail?.totalHalfCompOffLeaves[0]
                ?.totalHalfCompOffLeaves
            ),
            Lwp: checkLeaveArray(
              totalLeaveDetail.totalLWPLeaves[0]?.totalLWPLeaves,
              totalLeaveDetail?.totalHalfLWPLeaves[0]?.totalHalfLWPLeaves
            ),
            Wfh: checkLeaveArray(
              totalLeaveDetail.totalWFHLeaves[0]?.totalWFHLeaves,
              totalLeaveDetail?.totalHalfWFH[0]?.totalHalfWFHLeaves
            ),
            totalOtherLeaves: checkLeaveArray(
              totalLeaveDetail.totalOtherLeaves[0]?.totalOtherLeaves,
              totalLeaveDetail?.totalHalfOtherLeaves[0]?.totalHalfOtherLeaves
            ),
            totalLeavesTaken: totalLeaveDetail?.totalLeaves[0]
              ? totalLeaveDetail.totalLeaves[0].totalLeaves
              : 0,
            shortDayLeave: totalLeaveDetail?.totalShortDayLeaves[0]
              ? totalLeaveDetail.totalShortDayLeaves[0].totalShortDayLeaves
              : 0,
            assignedStatusCl: resData.employeeTotalLeave?.totalCl
              ? resData.employeeTotalLeave?.totalCl
              : 0,
            assignedStatusEl: resData.employeeTotalLeave?.totalEarnedLeave
              ? resData.employeeTotalLeave?.totalEarnedLeave
              : 0,
            assignedStatusSl: resData.employeeTotalLeave?.totalSl
              ? resData.employeeTotalLeave?.totalSl
              : 0,
            totalCompOff: resData.employeeTotalLeave?.totalCompOff
              ? resData.employeeTotalLeave?.totalCompOff
              : 0,
            bereavementLeaves: totalLeaveDetail?.bereavementLeave[0]
              ? totalLeaveDetail?.bereavementLeave[0].bereavementLeave
              : 0,
            paternalLeave: totalLeaveDetail?.paternalLeave[0]
              ? totalLeaveDetail?.paternalLeave[0].paternalLeave
              : 0,
            totalClientOff: totalLeaveDetail?.totalClientOff[0]
              ? totalLeaveDetail?.totalClientOff[0].totalClientOff
              : 0,
            employeeType: resData.employeeType,
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            resFailMsg: true,
            responseMessage: resData,
          },
        });
      }
    });
    setTimeout(
      () =>
        dispatch({
          type: type,
          payload: {
            resFailMsg: false,
            responseMessage: "",
          },
        }),
      3000
    );
  };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
        status1: filtersFromParams.status1 ? filtersFromParams.status1 : "",
        status: filtersFromParams.status ? filtersFromParams.status : "",
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "createdAt",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : 2,
        searchYear: filtersFromParams.searchYear
          ? Number(filtersFromParams.searchYear)
          : currentYear,
        searchMonth: filtersFromParams.searchMonth
          ? Number(filtersFromParams.searchMonth)
          : "",
        searchQuatName: filtersFromParams.searchQuatName
          ? Number(filtersFromParams.searchQuatName)
          : "",
        selectLeave: filtersFromParams.selectLeave
          ? filtersFromParams.selectLeave
          : "",
        chartView:
          filtersFromParams.chartView == "true"
            ? Boolean(filtersFromParams.chartView)
            : false,
        leaveTypeSelect: filtersFromParams.leaveTypeSelect
          ? filtersFromParams.leaveTypeSelect
          : "",
      },
    });
  }, [history.location.search]);

  const getEmployeeLeavesList = () => {
    const params = {
      sortType: sortType,
      sortField: sortField,
      count: count,
      pageNo: pageNo - 1,
      status: status == "4" ? "" : status,
      year: searchYear,
      uniqueLate: status == "4" ? "1" : "0",
      leaveType: leaveTypeSelect,
    };

    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    getEmployeeLeaves(params).then((res) => {
      dispatch({
        type: type,
        payload: {
          loader: false,
        },
      });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            ResData: resData,
          },
        });
        let leaveData = resData.leavesDetails;
        dispatch({
          type: type,
          payload: {
            rejectedCount: leaveData.rejected,
            approvedCount: leaveData.approved,
            pendingCount: leaveData.pending,
            lateCount: leaveData.lateCount,
            leaveDetails: leaveData.leaveList,
            totalLeaves: leaveData.totalLeaves,
            dataCount:
              status1 == 1
                ? leaveData.pending
                : status1 == 2
                ? leaveData.approved
                : status1 == 3
                ? leaveData.rejected
                : status1 == 4
                ? leaveData.lateCount
                : leaveData.totalLeaves,
          },
        });
      } else if (res.data.statusCode === 0) {
        dispatch({
          type: type,
          payload: {
            loader: false,
            resMsgFail: true,
            responseMessage: res.data.error.responseMessage,
          },
        });
        closeModal();
      }
    });
  };

  const handleApplyLeave = () => {
    dispatch({
      type: type,
      payload: {
        applyLeave: !applyLeave,
      },
    });
  };

  const showLeaveDetail = (index, title) => {
    dispatch({
      type: type,
      payload: {
        openLeaveDetail: true,
        index: index,
        leaveTitle: title,
        // casualLeaveDetailShow:''
      },
    });
  };
  const hideLeaveDetail = () => {
    dispatch({
      type: type,
      payload: {
        openLeaveDetail: false,
        index: "",
        leaveTitle: "",
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        openLeaveApprovePopup: false,
        openDeleteLeavePopup: false,
        openApplyForClientOffice: false,
        leaveId: "",
        loader: false,
        reply: "",
        leaveType1: "",
        leaveType1ErrorMsg: "",
        halfOfTheDay: "",
        halfOfTheDayErrMsg: "",
        startDate1: "",
        start1dateErrorMsg: "",
        endDate1: "",
        enddate1ErrorMsg: "",
        email: "",
        emails: [],
        emailInCC: "",
        inputEmail: "",
        emailError: "",
        replyErrorMsg: "",
        startDate: "",
        endDate: "",
        editorState: EditorState.createEmpty(),
        viewDocumentPopup: false,
      },
    });
  };
  const reverseView = () => {
    dispatch({
      type: type,
      payload: {
        applyLeave: !applyLeave,
      },
    });
  };

  const handleOpenApplyForClientOffice = () => {
    dispatch({
      type: type,
      payload: {
        openApplyForClientOffice: true,
      },
    });
  };

  const handleOpenShowCompOff = () => {
    history.push(`/leave/compoff-leave/${id}/${byAdmin}/${isAdmin}`);
    dispatch({
      type: type,
      payload: {
        leaveTypeSelect: "",
      },
    });
  };

  const handleYear = (e) => {
    if (e === undefined) {
      dispatch({
        type: type,
        payload: {
          searchYear: new Date().getFullYear(),
          pageNo: 1,
          year: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchYear: e ? e : "",
          pageNo: 1,
          year: true,
          searchDate: "",
        },
      });
    }
  };

  const handleFilter = (e) => {
    dispatch({
      type: type,
      payload: {
        status: e,
        pageNo: 1,
        status1: e,
      },
    });
  };

  const handleSort = (e) => {
    dispatch({
      type: type,
      payload: {
        sortField: e,
        sortType: sortType === 1 ? 2 : 1,
      },
    });
  };

  const handlePageChange = (pageNo) => {
    dispatch({
      type: type,
      payload: {
        pageNo: pageNo,
      },
    });
  };

  const handleOpenLeavePopup = (data) => {
    let {
      leaveId,
      leaveType,
      startDate,
      endDate,
      leaveMsg,
      reply,
      halfOfTheDay,
      status,
      from,
      to,
    } = data;
    dispatch({
      type: type,
      payload: {
        openLeaveApprovePopup: true,
        leaveId: leaveId,
        leaveType: leaveType,
        startDate: new Date(startDate).toLocaleDateString(),
        endDate: new Date(endDate).toLocaleDateString(),
        leaveMsg: leaveMsg,
        reply: reply,
        halfOfTheDayStatus: halfOfTheDay,
        leaveStatus: status,
        shortDayFrom: from,
        shortDayTo: to,
      },
    });
  };

  const handleOpenDeleteLeavePopup = (data) => {
    dispatch({
      type: type,
      payload: {
        openDeleteLeavePopup: true,
        leaveId: data._id,
      },
    });
  };

  const handleleaveTypeChange = (e) => {
    dispatch({
      type: type,
      payload: {
        responseMessage: "",
        leaveType1: parseInt(e),
        leaveType1ErrorMsg: "",
      },
    });
  };

  const handleSelectHalfChange = (e) => {
    dispatch({
      type: type,
      payload: {
        halfOfTheDay: e,
        halfOfTheDayErrMsg: "",
      },
    });
  };

  const onStartChange = (e) => {
    dispatch({
      type: type,
      payload: {
        startDate1: moment(e).toISOString(),
        start1dateErrorMsg: "",
      },
    });
  };

  const onEndChange = (e) => {
    dispatch({
      type: type,
      payload: {
        endDate1: moment(e).toISOString(),
        enddate1ErrorMsg: "",
      },
    });
  };

  const handleEmailChange = (e) => {
    dispatch({
      type: type,
      payload: {
        emails: e,
        emailError: null,
      },
    });
  };

  const handleChange = (e) => {
    dispatch({
      type: type,
      payload: {
        [e.target.name]: e.target.value,
        responseMessage: "",
        replyErrorMsg: "",
        compOffGivenReasonErrorMsg: "",
      },
    });
  };

  const handleApplyForClientOfficeValidition = () => {
    let validate = true;
    let k = convertToRaw(editorState.getCurrentContent());

    if (inputEmail) {
      if (emails.length === 0 || emails === undefined) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            emailError: emailClick,
          },
        });
      }
      if (inputEmail.length > 0) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            emailError: emailClick,
          },
        });
      }
    }
    if (leaveType1 == "" || leaveType1 == undefined || leaveType1 == null) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          leaveType1ErrorMsg: REQUIRED,
        },
      });
    }
    if (
      startDate1 == "" ||
      startDate1 == "undefined" ||
      startDate1 == null ||
      startDate1 == 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          start1dateErrorMsg: REQUIRED,
        },
      });
    }
    if (leaveType1 !== 14) {
      if (
        endDate1 == "" ||
        endDate1 == "undefined" ||
        endDate1 == null ||
        endDate1 == 0
      ) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            enddate1ErrorMsg: REQUIRED,
          },
        });
      } else if (endDate1 < startDate1) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            enddate1ErrorMsg: EndDate_Greater,
          },
        });
      }
    }
    if (leaveType1 == 14) {
      if (
        halfOfTheDay === "" ||
        halfOfTheDay === undefined ||
        halfOfTheDay === 0
      ) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            halfOfTheDayErrMsg: REQUIRED,
          },
        });
      }
    }
    if (k.blocks.every((b) => b.text.trim() === "")) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          replyErrorMsg: REQUIRED,
        },
      });
    }
    return validate;
  };

  const handleApplyForClientOfficeSubmit = (event) => {
    event.preventDefault();
    let startD = new Date(startDate1);
    let endD = new Date(endDate1);
    let formattedDate1 =
      startD.getFullYear() +
      "-" +
      (startD.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      startD.getDate().toString().padStart(2, "0");
    let formattedDate2 =
      endD.getFullYear() +
      "-" +
      (endD.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      endD.getDate().toString().padStart(2, "0");
    let leaveReason = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    let resObj = {
      leaveMsg: leaveReason,
      leaveType: leaveType1,
      startDate: formattedDate1,
      endDate: leaveType1 == "14" ? formattedDate1 : formattedDate2,
      emailInCC: JSON.stringify(emails),
      halfOfTheDay: leaveType1 == "14" ? halfOfTheDay : 0,
    };
    if (handleApplyForClientOfficeValidition()) {
      dispatch({
        type: type,
        payload: {
          miniLoader: true,
          loader: true,
          resApplyForClientMsg: true,
          successMessage: "Applied for Client Side",
        },
      });

      applyLeaveByEmployee(resObj).then((res) => {
        if (res.data.statusCode === 1) {
          getEmployeeLeavesList();
          closeModal();
          dispatch({
            type: type,
            payload: {
              resMsg: true,
            },
          });
        } else {
          dispatch({
            type: type,
            payload: {
              miniLoader: false,
              loader: false,
              resFailMsg: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
        }
        getEmployeeLeavesList();
        closeModal();
      });
    }
    setTimeout(() => {
      dispatch({
        type: type,
        payload: {
          resApplyForClientMsg: false,
          resFailMsg: false,
          resMsg: false,
        },
      });
    }, 3000);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    let obj = {
      leaveId: leaveId,
    };
    deleteApplyLeave(obj).then((res) => {
      let { status } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            loader: false,
            openDeleteLeavePopup: false,
            resMsg: true,
            successMessage: "Leave Deleted",
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            loader: false,
            resFailMsg: true,
            responseMessage: res.data.error.responseMessage,
          },
        });
      }
      setTimeout(() => {
        dispatch({
          type: type,
          payload: {
            resMsg: false,
            resFailMsg: false,
            responseMessage: "",
          },
        });
      }, 3000);
      getEmployeeLeavesList();
    });
  };

  const cancel = () => {
    dispatch({
      type: type,
      payload: {
        chartView: false,
        selectLeave: "",
        searchYear: currentYear,
      },
    });
  };

  const handleOpenView = (reason) => {
    dispatch({
      type: type,
      payload: {
        openView: true,
        reason: reason,
      },
    });
  };

  const handleLeaveFilter = (e) => {
    if (e !== undefined) {
      dispatch({
        type: type,
        payload: {
          pageNo: 1,
          leaveTypeSelect: e,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          pageNo: 1,
          leaveTypeSelect: "",
        },
      });
    }
  };

  const handleOpenViewDocPopup = (viewDocument) => {
    dispatch({
      type: type,
      payload: {
        viewDocumentPopup: true,
        viewDocument: viewDocument,
      },
    });
  };
  return (
    <div className="body_container cm_bold">
      {loader ? (
        <Loader />
      ) : (
        <>
          <Toastify
            resMsg={resMsg}
            text={successMessage}
            resMsgFail={resFailMsg}
            responseMessage={responseMessage}
          />
          {chartView || applyLeave || dashboardApplyLeave ? (
            ""
          ) : (
            <h3 className="ml-3">LEAVE RECORDS</h3>
          )}
          <div className="mt-3">
            <div className="col-md-9 ">
              {chartView || applyLeave || dashboardApplyLeave ? (
                ""
              ) : (
                <h3>Remaining Leaves</h3>
              )}
            </div>
            <div className="filters justify-content-end mb-3">
              {applyLeave || dashboardApplyLeave ? (
                ""
              ) : (
                <Select
                  className="select_year_cal"
                  value={
                    selectLeave == "" || selectLeave == "false"
                      ? "Leaves"
                      : selectLeave
                  }
                  onChange={(e) =>
                    handleSelectLeave({
                      e: e,
                      searchYear: searchYear,
                      searchQuatName: searchQuatName,
                      searchMonth: searchMonth,
                      selectLeave: selectLeave,
                      dispatch: dispatch,
                      chartView: chartView,
                      leaveTypeSelect: leaveTypeSelect,
                    })
                  }
                >
                  <Option value="1">Yearly</Option>
                  <Option value="2">Quarterly</Option>
                  <Option value="3">Monthly</Option>
                </Select>
              )}
              {selectLeave || applyLeave || dashboardApplyLeave ? (
                ""
              ) : (
                <button className="btn btn-primary" onClick={handleApplyLeave}>
                  Apply Leave
                </button>
              )}
            </div>
          </div>
          <div className="mb-4">
            <h3 className="leaves-provided">
              * No leaves provided if an employee joined as an intern.
            </h3>
            <h3 className="leaves-provided">
              * 1.5 sick leave provided for 3 months, when the employee joined
              as a probation.
            </h3>
            <h3 className="leaves-provided">
              * 3 sick leave provided for 6 months, when the employee joined as
              a fresher.
            </h3>
          </div>
          {chartView ? (
            <LeaveStatus
              cancel={cancel}
              selectLeave={selectLeave}
              id={id}
              totalEl={earnedLeaves}
              totalCl={casualLeaves}
              totalSl={sickLeaves}
              totalCompOffLeaves={compOff}
              totalLWPLeaves={Lwp}
              totalWFHLeaves={Wfh}
              totalOtherLeaves={totalOtherLeaves}
              totalLeavesTaken={totalLeavesTaken}
              shortLeave={shortDayLeave}
              dispatch={dispatch}
              searchYear={searchYear}
              searchQuatName={searchQuatName}
              searchMonth={searchMonth}
              employeeType={employeeType}
              assignedCl={assignedStatusCl}
              assignedEl={assignedStatusEl}
              assignedSl={assignedStatusSl}
              totalCompOff={totalCompOff}
              totalClientOff={totalClientOff}
              bereavementLeaves={bereavementLeaves}
              paternalLeave={paternalLeave}
              assignedClientOff={assignedClientOff}
              assignedBereavementLeaves={assignedBereavementLeaves}
              assignedPaternalLeave={assignedPaternalLeave}
            />
          ) : applyLeave || dashboardApplyLeave ? (
            <ApplyLeave
              applyLeave={applyLeave}
              reverseView={reverseView}
              EmployeeLeavesList={getEmployeeLeavesList}
              ResData={ResData}
              totalLeavesRes={totalLeavesRes}
              totalRemainingLeave={
                totalCl + totalEl + totalSl + totalCompOffTaken
              }
              assignedClientOff={assignedClientOff-totalClientOff}
              assignedBereavementLeaves={assignedBereavementLeaves-bereavementLeaves}
              assignedPaternalLeave={assignedPaternalLeave-paternalLeave}
            />
          ) : (
            <>
              <EmpLeaveDetail
                openLeaveDetail={openLeaveDetail}
                index={index}
                employeeType={employeeType}
                leaveTitle={leaveTitle}
                showLeaveDetail={showLeaveDetail}
                hideLeaveDetail={hideLeaveDetail}
                remainingMonthlyShortLeave={remainingMonthlyShortLeave}
                totalCl={totalCl}
                totalEl={totalEl}
                totalSl={totalSl}
                totalCompOffLeaves={compOff}
                totalCompOff={totalCompOff}
                compOff={totalCompOffTaken}
                totalLwpTaken={totalLWP}
                totalShortLeaveTaken={shortDayLeave}
                assignedCl={assignedCl}
                assignedEl={assignedEl}
                assignedSl={assignedSl}
                tempCompOff={tempCompOff}
                tempCompOffCount={tempCompOffCount}
              />
              <EmployeeLeaveData
                totalLeaves={totalLeaves}
                pendingCount={pendingCount}
                lateCount={lateCount}
                approvedCount={approvedCount}
                rejectedCount={rejectedCount}
                leaveDetails={leaveDetails}
                pageNo={pageNo}
                count={count}
                handleOpenDeleteLeavePopup={handleOpenDeleteLeavePopup}
                handlePageChange={handlePageChange}
                handleYear={handleYear}
                sortType={sortType}
                sortField={sortField}
                handleSort={handleSort}
                employeeType={employeeType}
                handleOpenLeavePopup={handleOpenLeavePopup}
                handleFilter={handleFilter}
                handleOpenShowCompOff={handleOpenShowCompOff}
                handleOpenApplyForClientOffice={handleOpenApplyForClientOffice}
                searchYear={searchYear}
                statusFilter={status1}
                status={status}
                leaveTypeSelect={leaveTypeSelect}
                handleLeaveFilter={handleLeaveFilter}
                dataCount={dataCount}
                handleOpenViewDocPopup={handleOpenViewDocPopup}
                viewDocumentPopup={viewDocumentPopup}
                viewDocument={viewDocument}
                closeModal={closeModal}
              />
            </>
          )}

          <ApplyForClientLocationModal
            show={openApplyForClientOffice}
            handleClose={closeModal}
            handleleaveTypeChange={handleleaveTypeChange}
            leaveType1={leaveType1}
            leaveType1ErrorMsg={leaveType1ErrorMsg}
            handleSelectHalfChange={handleSelectHalfChange}
            halfOfTheDay={halfOfTheDay}
            halfOfTheDayErrMsg={halfOfTheDayErrMsg}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            startDate1={startDate1}
            start1dateErrorMsg={start1dateErrorMsg}
            endDate1={endDate1}
            enddate1ErrorMsg={enddate1ErrorMsg}
            // handleEmailDelete={handleEmailDelete}
            emails={emails}
            emailError={emailError}
            value={value}
            handleEmailChange={handleEmailChange}
            // handleKeyDown={handleKeyDown}
            handleChange={handleChange}
            // handleCC={handleCC}

            handleApplyForClientOfficeSubmit={handleApplyForClientOfficeSubmit}
            reply={reply}
            replyErrorMsg={replyErrorMsg}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            employeeList={employeeList}
          />
          <ViewLeaveModal
            show={openLeaveApprovePopup}
            handleClose={closeModal}
            startDate={startDate}
            endDate={endDate}
            leaveMsg={leaveMsg}
            reply={reply}
            status={leaveStatus}
            halfOfTheDay={halfOfTheDayStatus}
            shortDayFrom={shortDayFrom}
            shortDayTo={shortDayTo}
            leaveType={leaveType}
          />
          <DeleteLeaveByEmp
            show={openDeleteLeavePopup}
            handleClose={closeModal}
            handleDelete={handleDelete}
          />
        </>
      )}
    </div>
  );
}

export default Leaves;

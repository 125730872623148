import React, { useEffect, useMemo, useReducer } from "react";
import { useDispatch } from "react-redux";
import {
  MONTH_ARRAY_NUM,
  REQUIRED,
  Toastify,
  createSelectItems,
  currentMonth,
  reducer,
} from "../helper/Constant";
import { useDebounce } from "../attendance/Constants";
import AddEmployeeSalary from "./salaryModal/AddEmployeeSalary";
import { getEmployeeList } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import { Loader } from "../svg";
import { handleAddSalaryValidation } from "./SalaryValidation";
import { CONSTANTS } from "../../config";
import { getEmployeeCtcAction } from "../../redux/actions/getEmployeeCtcAction";
import { addEmployeeCtcAction } from "../../redux/actions/addEmployeeCtcAction";
import EditEmployeeSalary from "./salaryModal/EditEmployeeSalary";
import { editEmployeeCtcAction } from "../../redux/actions/editEmployeeCtc";
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import qs from "qs";
import "./salary.css";
import EmployeeSalaryDetail from "./EmployeeSalaryDetail";
import { encryptRequestData } from "./encryptionAndDecryption";
import MonthlySalaryStatus from "./MonthlySalaryStatus";
import { currentYear } from "../leaves/Constants";
import { Tab, Tabs } from "react-bootstrap";
import { getEmployeeMonthlySalaryStatsAction } from "../../redux/actions/getEmployeeMonthlySalaryStatsAction";
import MonthlyReimbursementStats from "./MonthlyReimbursementStats";
import { getEmployeeMonthlyReimbursementStatsAction } from "../../redux/actions/getEmployeeMonthlyReimbursementStatsAction";
import OnHoldEmployeesTable from "./OnHoldEmployeesTable";
import getEmployeeOnHoldAction from "../../redux/actions/getEmployeeOnHoldAction";
import editEmployeeOnHoldAction from "../../redux/actions/editEmployeeOnHoldAction";
import GetOtp from "./GetOtp";
import getOtpAction from "../../redux/actions/getOtpAction";
import validateOtpAction from "../../redux/actions/vaidateOtpAction";
import { editOnHoldReleaseDateAction } from "../../redux/actions/editOnHoldReleaseDateAction";
import moment from "moment";
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const type = "typeCheckSet";
const { Option } = Select;
const initialState = {
  search: "",
  pageNo: 1,
  count: 10,
  dataCount: 0,
  monthlyStatsDataCount: 0,
  tabStatus: 0,
  sortType: SORT_TYPE_CONSTANTS.Asscending,
  sortField: "mobId",
  officeLocation: "",
  resMsg: false,
  resFailMsg: false,
  responseMessage: "",
  successMessage: "",
  addSalaryPopup: false,
  editSalaryPopup: false,
  employeeList: [],
  employeeName: "",
  employeeNameErrorMessage: "",
  employeeCtc: 0,
  employeeCtcErrorMessage: "",
  employeeAccountNumber: 0,
  employeeAccountNumberErrorMessage: "",
  employeeUanNumber: "",
  employeePancardNumber: "",
  employeePancardNumberErrorMessage: "",
  employeeBankIFSCCode: "",
  employeeBankIFSCCodeErrorMessage: "",
  miniLoader: false,
  employeesCtcList: [],
  employeeTds: 0,
  employeeTdsErrorMessage: "",
  employeeLoanErrorMessage: "",
  employeeLoan: 0,
  searchMonth: currentMonth - 1,
  searchYear: currentYear,
  employeesMonthlySalaryStats: [],
  employeesMonthlySalaryStatsExcelLink: "",
  employeesMonthlyReimbursementStats: [],
  employeesMonthlyReimbursementStatsExcelLink: "",
  monthlyReimbursementStatsDataCount: 0,
  isAvailedPF: 1,
  list: [],
  ctcVariable: 0,
  ctcVariableErrorMessage: "",
  opendeleteOnHoldModal: false,
  openEditOnHoldModal: false,
  onHoldEmployeeList: [],
  onHoldEmployeeDataCount: 0,
  onHoldFilter: 0,
  otp: "",
  otpMinutes: 0,
  otpSeconds: 0,
  isTimer: false,
  setTimeInterval: null,
  otpErrorMessage: "",
  getOptDisabled: false,
  employeeUanNumberErrorMessage: "",
  data: [],
  releaseDate: "",
  documentId: "",
};

export default function Salary() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const salaryToken = localStorage.getItem("salaryToken")
    ? localStorage.getItem("salaryToken")
    : "";
  const salaryAccessEmail = localStorage.getItem("salaryAccessEmail")
    ? localStorage.getItem("salaryAccessEmail")
    : "";
  const {
    search,
    pageNo,
    count,
    dataCount,
    tabStatus,
    sortType,
    sortField,
    officeLocation,
    resMsg,
    resFailMsg,
    responseMessage,
    successMessage,
    addSalaryPopup,
    editSalaryPopup,
    employeeList,
    employeeName,
    employeeNameErrorMessage,
    employeeCtc,
    employeeCtcErrorMessage,
    employeeAccountNumber,
    employeeAccountNumberErrorMessage,
    employeeUanNumber,
    employeeBankIFSCCode,
    employeeBankIFSCCodeErrorMessage,
    employeePancardNumber,
    employeePancardNumberErrorMessage,
    miniLoader,
    employeesCtcList,
    employeeTds,
    employeeTdsErrorMessage,
    employeeLoanErrorMessage,
    employeeLoan,
    searchMonth,
    searchYear,
    employeesMonthlySalaryStats,
    monthlyStatsDataCount,
    employeesMonthlyReimbursementStats,
    employeesMonthlySalaryStatsExcelLink,
    employeesMonthlyReimbursementStatsExcelLink,
    monthlyReimbursementStatsDataCount,
    isAvailedPF,
    list,
    ctcVariable,
    ctcVariableErrorMessage,
    opendeleteOnHoldModal,
    onHoldEmployeeList,
    onHoldFilter,
    onHoldEmployeeDataCount,
    otp,
    otpMinutes,
    otpSeconds,
    otpErrorMessage,
    getOptDisabled,
    employeeUanNumberErrorMessage,
    openEditOnHoldModal,
    data,
    releaseDate,
  } = state;
  const dispatchToAction = useDispatch();
  const debouncedSearchTerm = useDebounce(search, 2000);
  let paraMeters = `search=${search.trim()}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${
    pageNo - 1
  }&officeLocation=${officeLocation}`;
  let urlParams = `search=${search}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo}&officeLocation=${officeLocation}&tabStatus=${tabStatus}&searchMonth=${searchMonth}&searchYear=${searchYear}&onHoldFilter=${onHoldFilter}`;
  useEffect(() => {
    history.push(`?${urlParams}`);
    if (
      salaryToken ||
      salaryAccessEmail === "shubhi.khare@yopmail.com" ||
      salaryAccessEmail === "shubhi.khare@mobcoder.com"
    ) {
      if (tabStatus === 0) {
        getEmployeeCtc();
        getEmployee();
      } else if (tabStatus === 1) {
        getEmployeeMonthlySalaryStats();
      } else if (tabStatus === 2) {
        getEmployeeMonthlyReimbursementStats();
      } else {
        getEmployeeOnHold();
      }
    }
  }, [
    debouncedSearchTerm,
    pageNo,
    sortType,
    officeLocation,
    tabStatus,
    searchMonth,
    searchYear,
    onHoldFilter,
  ]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        pageNo: filtersFromParams.pageNo
          ? parseInt(filtersFromParams.pageNo)
          : 1,
        search: filtersFromParams.search ? filtersFromParams.search : "",
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "mobId",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : SORT_TYPE_CONSTANTS.Asscending,
        officeLocation: filtersFromParams.officeLocation
          ? filtersFromParams.officeLocation
          : "",
        tabStatus: filtersFromParams.tabStatus
          ? parseInt(filtersFromParams.tabStatus)
          : 0,
        searchMonth: filtersFromParams.searchMonth
          ? +filtersFromParams.searchMonth
          : currentMonth - 1,
        searchYear: filtersFromParams.searchYear
          ? +filtersFromParams.searchYear
          : currentYear,
        onHoldFilter: filtersFromParams.onHoldFilter
          ? +filtersFromParams.onHoldFilter
          : 0,
      },
    });
  }, [history.location.search]);

  const getEmployeeCtc = () => {
    const params = paraMeters;
    dispatchToAction(
      getEmployeeCtcAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  const getEmployeeOnHold = () => {
    const params = `${paraMeters}&month=${searchMonth}&year=${searchYear}&onHoldFilter=${onHoldFilter}`;
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
        onHoldEmployeeList: [],
      },
    });
    dispatchToAction(
      getEmployeeOnHoldAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  const getEmployeeMonthlySalaryStats = () => {
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
        employeesMonthlySalaryStats: [],
      },
    });
    const params = `${paraMeters}&month=${searchMonth}&year=${searchYear}`;
    dispatchToAction(
      getEmployeeMonthlySalaryStatsAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };
  const getEmployeeMonthlyReimbursementStats = () => {
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
        employeesMonthlyReimbursementStats: [],
      },
    });
    const params = `${paraMeters}&month=${searchMonth}&year=${searchYear}`;
    dispatchToAction(
      getEmployeeMonthlyReimbursementStatsAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };
  const handleSort = (e) => {
    dispatch({
      type: type,
      payload: {
        sortField: e,
        sortType:
          sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
    });
  };
  const getEmployee = () => {
    let params = ``;
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
      },
    });
    getEmployeeList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            employeeList: resData?.employeesList,
            miniLoader: false,
          },
        });
      }
    });
  };

  const handlePageChange = (pageNo) => {
    dispatch({
      type: type,
      payload: {
        pageNo: pageNo,
      },
    });
  };

  const handleSearch = (e) => {
    dispatch({
      type: type,
      payload: {
        pageNo: 1,
        search: e.target.value,
      },
    });
    if (e.target.value === "" || e.target.value === undefined) {
      dispatch({
        type: type,
        payload: {
          search: "",
          status: "",
          pageNo: 1,
        },
      });
    }
  };

  const handleChange = (e) => {
    dispatch({
      type: type,
      payload: {
        [e.target.name]: e.target.value.trim(),
        [e.target.name + "ErrorMessage"]: "",
      },
    });
  };
  const handleSelectChange = () => {
    dispatch({
      type: type,
      payload: {
        search: "",
      },
    });
  };

  const handleAddSalary = () => {
    dispatch({
      type: type,
      payload: {
        addSalaryPopup: true,
      },
    });
  };

  const handleClose = () => {
    dispatch({
      type: type,
      payload: {
        addSalaryPopup: false,
        editSalaryPopup: false,
        opendeleteOnHoldModal: false,
        employeeName: "",
        employeeNameErrorMessage: "",
        employeeCtc: 0,
        employeeCtcErrorMessage: "",
        employeeAccountNumber: 0,
        employeeAccountNumberErrorMessage: "",
        employeeBankIFSCCode: "",
        employeeBankIFSCCodeErrorMessage: "",
        employeePancardNumber: "",
        employeePancardNumberErrorMessage: "",
        employeeUanNumber: "",
        employeeLoan: 0,
        employeeLoanErrorMessage: "",
        employeeTds: 0,
        employeeTdsErrorMessage: "",
        isAvailedPF: 1,
        ctcVariable: 0,
        ctcVariableErrorMessage: "",
        employeeUanNumberErrorMessage: "",
        openEditOnHoldModal: false,
        releaseDate: "",
      },
    });
  };

  const handleSelectEmployeeChange = (e) => {
    dispatch({
      type: type,
      payload: {
        employeeName: e,
        employeeNameErrorMessage: "",
      },
    });
  };

  const handleAddSalarySubmit = () => {
    if (
      handleAddSalaryValidation({
        dispatch: dispatch,
        employeeName: employeeName,
        employeeCtc: employeeCtc,
        employeeAccountNumber: employeeAccountNumber,
        employeeBankIFSCCode: employeeBankIFSCCode,
        employeePancardNumber: employeePancardNumber,
        employeeUanNumber: employeeUanNumber,
        employeeTds: employeeTds,
        employeeLoan: employeeLoan,
        type: type,
        add: true,
        ctcVariable: ctcVariable,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          miniLoader: true,
        },
      });
      const params = encryptRequestData({
        employeeId: employeeName,
        ctc: parseInt(employeeCtc),
        accountNumber: parseInt(employeeAccountNumber),
        ifscCode: employeeBankIFSCCode.toUpperCase(),
        panCard: employeePancardNumber.toUpperCase(),
        UAN: employeeUanNumber,
        employeeTds: parseFloat(employeeTds),
        employeeLoan: parseFloat(employeeLoan),
        isAvailedPF: parseInt(isAvailedPF),
        ctcVariable: parseFloat(ctcVariable),
      });
      dispatchToAction(
        addEmployeeCtcAction({
          useDispatch: dispatch,
          params: params,
          handleClose: handleClose,
          getEmployeeCtc: getEmployeeCtc,
        })
      );
    }
  };

  const handleEditSalaryPopup = (data) => {
    dispatch({
      type: type,
      payload: {
        editSalaryPopup: !editSalaryPopup,
        employeeAccountNumber: data?.accountNumber,
        employeeBankIFSCCode: data?.ifscCode,
        employeePancardNumber: data?.panCard,
        employeeUanNumber: data?.UAN,
        employeeCtc: data?.ctc,
        employeeName: data?.employeeId,
        employeeTds: data?.tds,
        employeeLoan: data?.loan,
        isAvailedPF: data?.isAvailedPF,
        ctcVariable: data?.ctcVariable,
      },
    });
  };

  const handleEditSalarySubmit = () => {
    if (
      handleAddSalaryValidation({
        dispatch: dispatch,
        employeeCtc: employeeCtc,
        employeeAccountNumber: employeeAccountNumber,
        employeeBankIFSCCode: employeeBankIFSCCode,
        employeePancardNumber: employeePancardNumber,
        employeeUanNumber: employeeUanNumber,
        employeeTds: employeeTds,
        employeeLoan: employeeLoan,
        type: type,
        ctcVariable: ctcVariable,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          miniLoader: true,
        },
      });
      const params = encryptRequestData({
        employeeId: employeeName,
        ctc: parseInt(employeeCtc),
        accountNumber: parseInt(employeeAccountNumber),
        ifscCode: employeeBankIFSCCode.toUpperCase(),
        panCard: employeePancardNumber.toUpperCase(),
        UAN: employeeUanNumber,
        employeeTds: parseFloat(employeeTds),
        employeeLoan: parseFloat(employeeLoan),
        isAvailedPF: parseInt(isAvailedPF),
        ctcVariable: parseFloat(ctcVariable),
      });
      dispatchToAction(
        editEmployeeCtcAction({
          useDispatch: dispatch,
          params: params,
          handleClose: handleClose,
          getEmployeeCtc: getEmployeeCtc,
        })
      );
    }
  };

  const handleOfficeLocationChange = (location) => {
    if (location) {
      dispatch({
        type: type,
        payload: {
          officeLocation: location,
          pageNo: 1,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          officeLocation: "",
          pageNo: 1,
        },
      });
    }
  };

  const handleFilter = (e) => {
    dispatch({
      type: type,
      payload: {
        tabStatus: e,
        searchMonth: currentMonth - 1,
        searchYear: currentYear,
        pageNo: 1,
      },
    });
  };

  const handleYear = (year) => {
    if (year === undefined) {
      dispatch({
        type: type,
        payload: {
          searchYear: currentYear,
          searchMonth: currentMonth - 1,
          pageNo: 1,
          year: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchYear: year,
          searchMonth: currentMonth - 1,
          pageNo: 1,
          year: true,
        },
      });
    }
  };

  const handleMonth = (month) => {
    if (month === undefined) {
      dispatch({
        type: type,
        payload: {
          searchMonth: currentMonth - 1,
          searchYear: currentYear,
          pageNo: 1,
          month: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchMonth: month,
          searchYear: searchYear ? searchYear : currentYear,
          pageNo: 1,
          month: true,
        },
      });
    }
  };

  const handleOnHoldFilter = (e) => {
    if (e === undefined) {
      dispatch({
        type: type,
        payload: {
          searchMonth: searchMonth ? searchMonth : currentMonth - 1,
          searchYear: searchYear ? searchYear : currentYear,
          pageNo: 1,
          month: false,
          onHoldFilter: "",
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          onHoldFilter: e,
          searchMonth: searchMonth ? searchMonth : currentMonth - 1,
          searchYear: searchYear ? searchYear : currentYear,
          pageNo: 1,
          month: true,
        },
      });
    }
  };

  const handleExportMonthlyStats = () => {
    const params = `${paraMeters}&month=${searchMonth}&year=${searchYear}&isExcelDownload=${1}`;
    dispatchToAction(
      getEmployeeMonthlySalaryStatsAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };
  const handleExportMonthlyReimbursementStats = () => {
    const params = `${paraMeters}&month=${searchMonth}&year=${searchYear}&isExcelDownload=${1}`;
    dispatchToAction(
      getEmployeeMonthlyReimbursementStatsAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };
  const handlePfChange = (e) => {
    dispatch({
      type: type,
      payload: {
        isAvailedPF: e,
      },
    });
  };

  const handleOnEmployeeDeleteModal = () => {
    dispatch({
      type: type,
      payload: {
        opendeleteOnHoldModal: true,
      },
    });
  };

  const handleOnHoldEditModal = (data) => {
    dispatch({
      type: type,
      payload: {
        openEditOnHoldModal: true,
        data: data,
        documentId: data.id,
        releaseDate: moment(data.releaseDate),
      },
    });
  };

  const handleEditOnHoldStatus = (props, e) => {
    const params = encryptRequestData({
      documentId: props?.id,
      status: e,
    });

    dispatch({
      type: type,
      payload: {
        miniLoader: true,
      },
    });
    dispatchToAction(
      editEmployeeOnHoldAction({
        useDispatch: dispatch,
        params: params,
        getEmployeeOnHold: getEmployeeOnHold,
      })
    );
  };

  const handleSubmit = () => {
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
      },
    });
    const params = encryptRequestData({
      releaseDate: releaseDate,
      documentId: data.id,
    });
    if (releaseDate) {
      dispatchToAction(
        editOnHoldReleaseDateAction({
          params: params,
          useDispatch: dispatch,
          getEmployeeOnHold: getEmployeeOnHold,
          handleClose: handleClose,
        })
      );
    }
  };

  const handleOtpChange = (e) => {
    dispatch({
      type: type,
      payload: {
        otp: e,
        otpErrorMessage: "",
      },
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpSeconds > 0) {
        dispatch({
          type: type,
          payload: {
            otpSeconds: otpSeconds - 1,
          },
        });
      }
      if (otpSeconds === 0) {
        if (otpMinutes === 0) {
          clearInterval(interval);
        } else {
          dispatch({
            type: type,
            payload: {
              otpSeconds: 59,
              otpMinutes: otpMinutes - 1,
            },
          });
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [otpSeconds]);

  const handleClick = () => {
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
        getOptDisabled: true,
        otpMinutes: 0,
        otpSeconds: 60,
      },
    });
    dispatchToAction(
      getOtpAction({
        useDispatch: dispatch,
      })
    );
  };

  const handleValidateOtp = () => {
    if (otp.toString().length >= 6) {
      const params = {
        otp: otp,
      };
      dispatchToAction(
        validateOtpAction({
          params: params,
          useDispatch: dispatch,
          getEmployeeCtc: getEmployeeCtc,
          getEmployee: getEmployee,
          handleClose: handleClose,
        })
      );
    } else {
      dispatch({
        type: type,
        payload: {
          otpErrorMessage: REQUIRED,
        },
      });
    }
  };

  const handleReleaseDate = (date) => {
    dispatch({
      type: type,
      payload: {
        releaseDate: date,
      },
    });
  };

  return (
    <div
      className={`body_container cm_bold ${
        salaryToken ||
        salaryAccessEmail === "shubhi.khare@yopmail.com" ||
        salaryAccessEmail === "shubhi.khare@mobcoder.com"
          ? ""
          : "otp_background"
      }`}
    >
      {miniLoader && <Loader />}
      <Toastify
        resMsg={resMsg}
        resMsgFail={resFailMsg}
        text={successMessage}
        responseMessage={responseMessage}
      />
      {salaryToken ||
      salaryAccessEmail === "shubhi.khare@yopmail.com" ||
      salaryAccessEmail === "shubhi.khare@mobcoder.com" ? (
        <>
          <h3 className="mb-2">SALARY MANAGEMENT</h3>
          <div className="row justify-content-end mb-4">
            <div className="col-md-6 search_device_bar1">
              <button
                className="btn btn-primary add_empBtn add1"
                onClick={handleAddSalary}
              >
                Add Employee Salary
              </button>
              <input
                maxLength={20}
                className="form-control mr-lg-2 cm_box"
                type="search"
                placeholder="Search by Name/E.ID"
                aria-label="Search"
                value={search}
                onChange={(e) => handleSearch(e)}
                autoComplete="off"
              />
              {search ? (
                <span
                  className="cm_clear3"
                  onClick={(e) => handleSelectChange(e)}
                >
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </span>
              ) : (
                <span className="cm_clear3">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
              )}
            </div>
          </div>

          <div className="filters justify-content-end mb-5">
            {tabStatus == 3 ? (
              ""
            ) : (
              <Select
                onChange={handleOfficeLocationChange}
                name="officeLocation"
                className="select_year_cal"
                value={officeLocation ? officeLocation : "Office Location"}
                allowClear={officeLocation ? true : false}
              >
                <Option value="1">Noida</Option>
                <Option value="2">Lucknow</Option>
              </Select>
            )}

            {tabStatus === 0 ? (
              ""
            ) : (
              <>
                <Select
                  className="select_year_cal"
                  value={searchMonth ? searchMonth : "Month"}
                  defaultValue="Select Month"
                  name="searchMonth"
                  onChange={(e) => handleMonth(e)}
                  allowClear={searchMonth ? true : false}
                >
                  {MONTH_ARRAY_NUM.map((month) => (
                    <Option key={month.value} value={month.value}>
                      {month.label}
                    </Option>
                  ))}
                </Select>

                <Select
                  className="select_year_cal"
                  value={searchYear ? searchYear : "Select Year"}
                  defaultValue="Select Year"
                  name="searchYear"
                  onChange={handleYear}
                  allowClear={searchYear ? true : false}
                >
                  {createSelectItems()}
                </Select>
              </>
            )}
            {tabStatus == 3 ? (
              <Select
                className="select_year_cal"
                value={onHoldFilter ? onHoldFilter : "All"}
                defaultValue="Select Month"
                name="searchMonth"
                onChange={(e) => handleOnHoldFilter(e)}
                allowClear={onHoldFilter ? true : false}
              >
                <Option value={0}>All</Option>
                <Option value={1}>Hold</Option>
                <Option value={2}>Clear</Option>
              </Select>
            ) : (
              ""
            )}
          </div>
          <div className="row d-flex justify-content-start row-btn mt-3 ml-2">
            <Tabs
              activeKey={
                tabStatus == "" || tabStatus == 0 || tabStatus == undefined
                  ? 0
                  : tabStatus == 1
                  ? 1
                  : tabStatus == 2
                  ? 2
                  : 3
              }
              id="uncontrolled-tab-example"
              onSelect={(e) => handleFilter(e)}
            >
              <Tab eventKey={0} title={`Employee's Salary`}></Tab>
              <Tab eventKey={1} title={`Monthly Salary Stats`}></Tab>
              <Tab eventKey={2} title={`Monthly Reimbursement Stats`}></Tab>
              <Tab eventKey={3} title={`On Hold Employees List`}></Tab>
            </Tabs>
          </div>
          {tabStatus === 0 ? (
            <EmployeeSalaryDetail
              employeesCtcList={employeesCtcList}
              list={list}
              count={count}
              pageNo={pageNo}
              dataCount={dataCount}
              handlePageChange={handlePageChange}
              sortField={sortField}
              sortType={sortType}
              handleSort={handleSort}
              handleEditSalaryPopup={handleEditSalaryPopup}
              officeLocation={officeLocation}
            />
          ) : tabStatus === 1 ? (
            <MonthlySalaryStatus
              employeesMonthlySalaryStats={employeesMonthlySalaryStats}
              count={count}
              pageNo={pageNo}
              dataCount={monthlyStatsDataCount}
              handlePageChange={handlePageChange}
              sortField={sortField}
              sortType={sortType}
              employeesMonthlySalaryStatsExcelLink={
                employeesMonthlySalaryStatsExcelLink
              }
              handleSort={handleSort}
              handleExportMonthlyStats={handleExportMonthlyStats}
            />
          ) : tabStatus === 2 ? (
            <MonthlyReimbursementStats
              employeesMonthlyReimbursementStats={
                employeesMonthlyReimbursementStats
              }
              count={count}
              pageNo={pageNo}
              dataCount={monthlyReimbursementStatsDataCount}
              handlePageChange={handlePageChange}
              sortField={sortField}
              sortType={sortType}
              employeesMonthlyReimbursementStatsExcelLink={
                employeesMonthlyReimbursementStatsExcelLink
              }
              handleSort={handleSort}
              handleExportMonthlyStats={handleExportMonthlyReimbursementStats}
            />
          ) : (
            <OnHoldEmployeesTable
              handleOnEmployeeDeleteModal={handleOnEmployeeDeleteModal}
              opendeleteOnHoldModal={opendeleteOnHoldModal}
              onHoldEmployeeList={onHoldEmployeeList}
              handleClose={handleClose}
              count={count}
              pageNo={pageNo}
              handlePageChange={handlePageChange}
              handleSort={handleSort}
              sortField={sortField}
              sortType={sortType}
              handleEditOnHoldStatus={handleEditOnHoldStatus}
              miniLoader={miniLoader}
              dataCount={onHoldEmployeeDataCount}
              openEditOnHoldModal={openEditOnHoldModal}
              handleOnHoldEditModal={handleOnHoldEditModal}
              handleSubmit={handleSubmit}
              onHoldData={data}
              handleReleaseDate={handleReleaseDate}
              releaseDate={releaseDate}
            />
          )}

          {/* Add Employee Salary Module */}
          <AddEmployeeSalary
            show={addSalaryPopup}
            employeeList={employeeList}
            employeeName={employeeName}
            handleSelectEmployeeChange={handleSelectEmployeeChange}
            employeeNameErrorMessage={employeeNameErrorMessage}
            employeeCtc={employeeCtc}
            employeeCtcErrorMessage={employeeCtcErrorMessage}
            employeeAccountNumber={employeeAccountNumber}
            employeeAccountNumberErrorMessage={
              employeeAccountNumberErrorMessage
            }
            employeeUanNumber={employeeUanNumber}
            employeeBankIFSCCode={employeeBankIFSCCode}
            employeeBankIFSCCodeErrorMessage={employeeBankIFSCCodeErrorMessage}
            employeePancardNumber={employeePancardNumber}
            employeePancardNumberErrorMessage={
              employeePancardNumberErrorMessage
            }
            employeeTds={employeeTds}
            employeeTdsErrorMessage={employeeTdsErrorMessage}
            employeeLoan={employeeLoan}
            employeeLoanErrorMessage={employeeLoanErrorMessage}
            handleChange={handleChange}
            handleClose={handleClose}
            miniLoader={miniLoader}
            handleAddSalarySubmit={handleAddSalarySubmit}
            isAvailedPF={isAvailedPF}
            handlePfChange={handlePfChange}
            ctcVariable={ctcVariable}
            ctcVariableErrorMessage={ctcVariableErrorMessage}
            employeeUanNumberErrorMessage={employeeUanNumberErrorMessage}
          />

          {/* Edit Employee Salary Module */}
          <EditEmployeeSalary
            show={editSalaryPopup}
            employeeCtc={employeeCtc}
            employeeCtcErrorMessage={employeeCtcErrorMessage}
            employeeAccountNumber={employeeAccountNumber}
            employeeAccountNumberErrorMessage={
              employeeAccountNumberErrorMessage
            }
            employeeUanNumber={employeeUanNumber}
            employeeBankIFSCCode={employeeBankIFSCCode}
            employeeBankIFSCCodeErrorMessage={employeeBankIFSCCodeErrorMessage}
            employeePancardNumber={employeePancardNumber}
            employeePancardNumberErrorMessage={
              employeePancardNumberErrorMessage
            }
            employeeTds={employeeTds}
            employeeTdsErrorMessage={employeeTdsErrorMessage}
            employeeLoan={employeeLoan}
            employeeLoanErrorMessage={employeeLoanErrorMessage}
            handleChange={handleChange}
            handleClose={handleClose}
            miniLoader={miniLoader}
            handleEditSalarySubmit={handleEditSalarySubmit}
            isAvailedPF={isAvailedPF}
            handlePfChange={handlePfChange}
            ctcVariable={ctcVariable}
            ctcVariableErrorMessage={ctcVariableErrorMessage}
            employeeUanNumberErrorMessage={employeeUanNumberErrorMessage}
          />
        </>
      ) : (
        <GetOtp
          otp={otp}
          otpMinutes={otpMinutes}
          otpSeconds={otpSeconds}
          handleOtpChange={handleOtpChange}
          handleSetTimer={handleClick}
          otpErrorMessage={otpErrorMessage}
          handleValidateOtp={handleValidateOtp}
          getOptDisabled={getOptDisabled}
        />
      )}
    </div>
  );
}

import React, { useEffect, useMemo, useReducer } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { editUnderRepairingDevice, getDeviceDetils } from '../../config/services/employeeServices'
import { useDispatch } from 'react-redux';
import { REQUIRED, Toastify, reducer } from '../helper/Constant';
import ResponseFilter from '../../ResponseFilter';
import { Loader, PdfIcon } from '../svg';
import _ from 'lodash';
import moment from 'moment';
import { CONSTANTS } from '../../config';
import Pagination from 'react-js-pagination';
import Modal from "react-bootstrap/Modal";
import qs from 'qs'
import DeviceFullScreenImage from './DeviceFullScreenImage';
import { getFileNameFromUrl } from '../../config/constants/Constants';
import { Tabs, Tab } from "react-bootstrap";
import RepairDeviceList from './RepairDeviceList';
import EditDeviceUnderRepairingModal from './EditDeviceUnderRepairing';
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const initialState = {
  deviceDetails: [],
  loader: false,
  pageNo: 1,
  count: 50,
  sortType: SORT_TYPE_CONSTANTS.Descending,
  sortField: "created",
  devicePicture: [],
  deviceDocsImageModal: false,
  preview: false,
  fullImage: "",
  tabStatus: 0,
  repairList: [],
  repairListCount: 0,
  // 
  openEditUnderRepairingModal: false,
  editDeviceRepairingID: "",
  editDeviceRepairingObjectID: "",
  sendingAmount: 0,
  sendingAmountErrorMsg: "",
  recevingAmount: 0,
  recevingAmountErrorMsg: "",
  sendingDate: "",
  sendingDateErrorMsg: "",
  issueDescription: "",
  issueDescriptionErrorMsg: "",
  recevingDate: "",
  recevingDateErrorMsg: "",
  deviceListToAddUnderRepairing: [],
  deviceRepairingStatus: 2,
  resMsg: false,
  resFailMsg: false,
  successMessage: "",
  responseMessage: "",


}


export default function DeviceDetails(props) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory()

  const {
    deviceDetails,
    loader,
    pageNo,
    count,
    sortField,
    sortType,
    devicePicture,
    deviceDocsImageModal,
    preview,
    fullImage,
    tabStatus,
    repairList,
    repairListCount,


    // 


    openEditUnderRepairingModal,

    recevingAmount,
    recevingAmountErrorMsg,
    recevingDate,
    recevingDateErrorMsg,
    sendingAmount,
    sendingAmountErrorMsg,
    sendingDate,
    sendingDateErrorMsg,
    issueDescription,
    issueDescriptionErrorMsg,
    editDeviceRepairingID,
    editDeviceRepairingObjectID,
    deviceListToAddUnderRepairing,
    deviceRepairingStatus,
    resMsg,
    resFailMsg,
    successMessage,
    responseMessage,
  } = state

  const dispatchToAction = useDispatch()


  useEffect(() => {
    getDeviceDetails()
  }, [pageNo, sortField, sortType, tabStatus])


  const handleDeviceDocsImageModal = (e) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        deviceDocsImageModal: true,
        devicePicture: e?.deviceDetails?.devicePictures,
      }
    })
  }

  const handleFullImage = (e) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        preview: true,
        fullImage: e
      }
    })
  }
  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);

    dispatch({
      type: 'typeCheckSet', payload: {
        pageNo: filtersFromParams.pageNo ? filtersFromParams.pageNo : 1,
        sortField: filtersFromParams.sortField ? filtersFromParams.sortField : "created",
        sortType: filtersFromParams.sortType ? Number(filtersFromParams.sortType) : SORT_TYPE_CONSTANTS.Descending,
        tabStatus: filtersFromParams.tabStatus ? parseInt(filtersFromParams.tabStatus) : 0
      }
    })

  }, [history.location.search]);

  const getDeviceDetails = () => {
    let params = `deviceUniqueId=${props.match.params.id}&sortType=${sortType}&sortField=${sortField}`
    history.push(`?count=${count}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&tabStatus=${tabStatus}`)
    dispatch({
      type: 'typeCheckSet', payload: {
        loader: true,
      }
    })
    getDeviceDetils(params).then((res) => {
      let { status, resData } = ResponseFilter(res)
      if (status) {
        dispatch({
          type: 'typeCheckSet', payload: {
            loader: false,
            deviceDetails: resData?.list,
            repairList: resData?.repairList[0]?.documents,
            repairListCount: resData?.repairList[0]?.totalCounts,

          }
        })

      } else {
        dispatch({
          type: 'typeCheckSet', payload: {
            loader: false,
          }
        })
      }

    });
  }

  const handlePageChange = (pageNo) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        loader: true,
        pageNo: pageNo
      }
    })
  };

  const closeModal = () => {
    dispatch({
      type: 'typeCheckSet', payload: {
        deviceDocsImageModal: false,
        devicePicture: [],
        deviceDocs: [],
        openEditUnderRepairingModal: false,

        editDeviceRepairingID: "",
        editDeviceRepairingObjectID: "",
        sendingAmount: 0,
        sendingAmountErrorMsg: "",
        recevingAmount: 0,
        recevingAmountErrorMsg: "",
        sendingDate: "",
        sendingDateErrorMsg: "",
        issueDescription: "",
        issueDescriptionErrorMsg: "",
        recevingDate: "",
        recevingDateErrorMsg: "",
        deviceRepairingStatus: 2,
      }
    })
  }

  const handleSort = (e) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        loader: false,
        sortField: e,
        statusErrorMsg: "",
        sortType: sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending,
      }
    })
  };

  const closeFullimage = () => {
    dispatch({
      type: 'typeCheckSet', payload: {
        preview: false,
        fullImage: ""
      }
    })
  }

  const handleTabChange = (e) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        tabStatus: e
      }
    })
  }

  const handleChange = (e) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
        [e.target.name + "ErrorMsg"]: "",
      }
    });
  }


  const handleOpenEditUnderRepairing = (device) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        openEditUnderRepairingModal: true,
        sendingAmount: device?.sendingAmount,
        sendingDate: device.sendingDate,
        issueDescription: device?.issue,
        recevingAmount: device.receivedAmount,
        recevingDate: device?.receivingDate,
        editDeviceRepairingID: device?._id,
        editDeviceRepairingObjectID: device?.deviceObjectId,
        deviceRepairingStatus: device?.status
      }
    })
  }


  const handleAddUnderRepairingSendingDate = (date, dateString) =>
    dispatch({
      type: 'typeCheckSet', payload: {
        sendingDate: moment(date).toDate().getTime(),
        sendingDateErrorMsg: "",
      }
    });


  const handleAddUnderRepairingRecevingDate = (date, dateString) =>
    dispatch({
      type: 'typeCheckSet', payload: {
        recevingDate: moment(date).toDate().getTime(),
        recevingDateErrorMsg: "",
      }
    });



  const validation = (props) => {
    const { recevingAmount, recevingDate, sendingAmount, sendingDate, issueDescription } = props
    let validate = true
    if (sendingDate === "" || sendingDate == undefined || sendingDate == null || isNaN(sendingDate)) {
      validate = false;
      dispatch({
        type: 'typeCheckSet', payload: {
          sendingDateErrorMsg: "Date is required"
        }
      });
    }
    else if (recevingDate < sendingDate) {
      validate = false;
      dispatch({
        type: 'typeCheckSet', payload: {
          sendingDateErrorMsg: "Assigned date can not be greater then assigned till"
        }
      })
    }
    else {
      dispatch({
        type: 'typeCheckSet', payload: {
          sendingDateErrorMsg: ""
        }
      });
    }
    if (recevingDate === "" || recevingDate == undefined || recevingDate == null || isNaN(recevingDate)) {
      validate = false;
      dispatch({
        type: 'typeCheckSet', payload: {
          recevingDateErrorMsg: "Date is required"
        }
      });
    }
    else {
      dispatch({
        type: 'typeCheckSet', payload: {
          recevingDateErrorMsg: ""
        }
      });
    }
    if (sendingAmount == "") {
      validate = false; 
      dispatch({
        type: 'typeCheckSet', payload: { sendingAmountErrorMsg: REQUIRED }
      })
    }
    else if (isNaN(sendingAmount) || sendingAmount < 0) {
      validate = false; 
      dispatch({
        type: 'typeCheckSet', payload: { sendingAmountErrorMsg: " Sending amount should be number and greater then 0" }
      })
    }
    else {
      dispatch({
        type: 'typeCheckSet', payload: { sendingAmountErrorMsg: "" }
      })
    }
    if (recevingAmount == "") {
      validate = false; 
      dispatch({
        type: 'typeCheckSet', payload: { recevingAmountErrorMsg: REQUIRED }
      })
    }
    else if (isNaN(recevingAmount) || recevingAmount < 0) {
      validate = false; 
      dispatch({
        type: 'typeCheckSet', payload: { recevingAmountErrorMsg: " Receiving amount should be number and greater then 0" }
      })
    }
    else {
      dispatch({
        type: 'typeCheckSet', payload: { recevingAmountErrorMsg: "" }
      })
    }
    if (issueDescription == "" || issueDescription == null || issueDescription == undefined) {
      validate = false
      dispatch({
        type: 'typeCheckSet', payload: {
          issueDescriptionErrorMsg: REQUIRED
        }
      })
    }
    return validate
  }




  const handleAddUnderRepairingSubmit = () => {
    if (validation({
      recevingAmount: recevingAmount,
      recevingDate: recevingDate,
      sendingAmount: sendingAmount,
      sendingDate: sendingDate,
      issueDescription: issueDescription,
    })) {

      const obj = {
        status: deviceRepairingStatus,
        devId: editDeviceRepairingObjectID,
        repairId: editDeviceRepairingID,
        receivedAmount: parseFloat(recevingAmount),
        receivingDate: recevingDate,
        sendingAmount: parseFloat(sendingAmount),
        sendingDate: sendingDate,
        issue: issueDescription,
      }
      editUnderRepairingDevice(obj).then((res) => {
        if (res.data.statusCode === 1) {
          getDeviceDetails()
          dispatch({
            type: 'typeCheckSet', payload: {
              resMsg: true, loader: false,
              successMessage:"Device history edited"
            }
          })
          closeModal()
          setTimeout(() => dispatch({
            type: 'typeCheckSet', payload: {
              resMsg: false
            }
          }), 4000)
        }
        else {

          dispatch({
            type: 'typeCheckSet', payload: {
              resFailMsg: true, loader: false,
              responseMessage: res?.data?.error?.responseMessage
            }
          })
          setTimeout(() =>
            dispatch({
              type: 'typeCheckSet', payload: {
                resFailMsg: false
              }
            }), 4000)
        }


      });
    }
  }


  const handleEditDeviceRepairingStatus = (e) => {
    dispatch({
      type: 'typeCheckSet', payload: {
        deviceRepairingStatus: e
      }
    })
  }

  return (
    <div className="body_container cm_bold">
      <Toastify
        resMsg={resMsg}
        resMsgFail={resFailMsg}
        text={successMessage}
        responseMessage={responseMessage}
      />
      {loader && <Loader />}
      <div className="cm_breadcrumb mb-3">
        <ul>
          <li><Link to='/admin-device'>DEVICE MANAGEMENT</Link><div className="cm_breadcrumb_arrow"></div></li>
          <li>{deviceDetails[0]?.deviceDetails?.devId} Device Details</li>
        </ul>
      </div>



      <Tabs activeKey={
        tabStatus == 0 || tabStatus == undefined
          ? 0
          : 1
      }
        id="uncontrolled-tab-example"
        className="mt-5"
        onSelect={handleTabChange}
      >
        <Tab eventKey={0} title={`Device Assign History `}></Tab>
        <Tab eventKey={1} title={`Device Repair History`}></Tab>
      </Tabs>
      <>
        {tabStatus == 0 ?
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Assigned Employee Id
                    <i
                      className={
                        sortType === 2 &&
                          sortField === "empId"
                          ? "fa fa-sort-alpha-desc ml-2"
                          : "fa fa-sort-alpha-asc ml-2"
                      }
                      onClick={() => handleSort("empId")}
                    ></i>
                  </th>
                  <th scope="col">Email
                    <i
                      className={
                        sortType === 2 &&
                          sortField === "email"
                          ? "fa fa-sort-alpha-desc ml-2"
                          : "fa fa-sort-alpha-asc ml-2"
                      }
                      onClick={() => handleSort("email")}
                    ></i></th>
                  <th scope="col">Employee name
                    <i
                      className={
                        sortType === 2 &&
                          sortField === "empName"
                          ? "fa fa-sort-alpha-desc ml-2"
                          : "fa fa-sort-alpha-asc ml-2"
                      }
                      onClick={() => handleSort("empName")}
                    ></i></th>

                  <th scope="col">
                    Assigned Date
                    <i
                      className={
                        sortType === 1 &&
                          sortField === "assignedFrom"
                          ? "fa fa-sort-numeric-asc ml-2"
                          : "fa fa-sort-numeric-desc ml-2"
                      }
                      onClick={() => handleSort("assignedFrom")}
                    ></i>
                  </th>
                  <th scope="col">
                    Assigned Till
                  </th>
                  <th scope='col'>
                    view image
                  </th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(deviceDetails)
                  ? deviceDetails.map((device, i) => (
                    <tr key={device.deviceId}>
                      <td>{i + 1 + (pageNo - 1) * count}</td>
                      <td>{device?.empId}</td>
                      <td>
                        {device?.email}

                      </td>
                      <td
                      >
                        <span className="cm_col">{device?.firstName} {device?.middleName} {device?.lastName}</span>
                      </td>
                      <td>
                        {moment
                          .unix(device.assignedFrom / 1000)
                          .format("DD/MM/YYYY")}
                      </td>
                      {device.assignedTill == null || device.assignedTill == 0 ? <td className="text-center"><strong>_</strong></td> : <td>
                        {moment
                          .unix(device.assignedTill / 1000)
                          .format("DD/MM/YYYY")}
                      </td>}
                      <td>
                        <i className='fa fa-eye blue' onClick={() => handleDeviceDocsImageModal(device)} style={{ cursor: "pointer" }}>
                        </i>
                      </td>
                    </tr>
                  ))
                  : ""}
              </tbody>
            </table>
            {deviceDetails && deviceDetails?.length > 0 ? (
              ""
            ) : (
              <h3 className="text-center">No data available</h3>
            )}
          </div>
          :
          <RepairDeviceList
            repairList={repairList}
            repairListCount={repairListCount}
            handleOpenEditUnderRepairing={handleOpenEditUnderRepairing}
          />}

        {/* {deviceDetails.length > 50 ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={deviceDetails.length}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        ) : (
          ""
        )} */}
      </>

      <Modal
        className="cm_deviceInfo "
        size="md"
        show={deviceDocsImageModal}
        onHide={closeModal}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form className="assign_device">
            <h3 className="mb-5">DEVICE DETAIL</h3>
            <div className="cm_overflow_device">
              <div className="row cm_row">
                {/* <div className="col-6 cm_bold col1">Device Pictures</div>
                <div className="container">
                  <div className="customer-content-ul image-container" >
                    {devicePicture ? devicePicture.map((devicePictures) =>
                      <img className="image" src={devicePictures} onClick={() => handleFullImage(devicePictures)}></img>
                    ) : ""}
                  </div>
                </div> */}



                <div className="col-6 cm_bold col1">Device Pictures</div>
                <div className="container">
                  <div className="customer-content-ul image-container" >
                    {/* {devicePictures ? devicePictures.map((devicePictures) =>
                        // <a href={devicePictures} target="_blank">
                        <img className="image" src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>
                        // </a>

                      ) : ""} */}


                    {devicePicture ? devicePicture.map((devicePictures) => (
                      devicePictures.includes(".pdf") == false ?
                        <div
                          className="date-border"
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            padding: "10px",
                            overflow: "auto",
                            minHeight: "100px",
                            maxHeight: "500px",
                            marginBottom: "10px"
                          }}
                          onClick={() => handleFullImage(devicePictures)}
                        >

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              backgroundColor: "#edfbff",
                              borderRadius: "8px",
                              padding: "10px",
                              height: "3.5rem",
                              margin: "5px",
                            }}
                          >
                            <img
                              src={devicePictures}
                              className="image-size mr-2"
                            />
                            <label style={{ width: "80%" }}>
                              {devicePictures ? getFileNameFromUrl(devicePictures) : devicePictures}
                            </label>
                          </div>
                        </div>
                        :
                        <div onClick={() => handleFullImage(devicePictures)} >
                          {/* <PDFReader url={devicePictures}>
                            </PDFReader> */}
                          {/* <PdfIcon /> */}
                          <>
                            <div
                              className="date-border"
                              style={{
                                borderRadius: "8px",
                                borderWidth: "1px",
                                padding: "10px",
                                overflow: "auto",
                                minHeight: "100px",
                                maxHeight: "500px",
                                marginBottom: "10px"

                              }}
                            >

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#edfbff",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  height: "3.5rem",
                                  margin: "5px",
                                }}
                              >
                                <div className="image-size"><PdfIcon /></div>
                                <label style={{ width: "80%" }}>
                                  {devicePictures ? getFileNameFromUrl(devicePictures) : devicePictures}
                                </label>
                              </div>
                            </div>
                          </>
                        </div>
                    )

                    ) : ""}

                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <DeviceFullScreenImage
        preview={preview}
        fullImage={fullImage}
        closeModal={closeFullimage}
      />

      <EditDeviceUnderRepairingModal
        show={openEditUnderRepairingModal}
        handleClose={closeModal}
        sendingAmount={sendingAmount}
        sendingAmountErrorMsg={sendingAmountErrorMsg}
        recevingAmount={recevingAmount}
        recevingAmountErrorMsg={recevingAmountErrorMsg}
        sendingDate={sendingDate}
        sendingDateErrorMsg={sendingDateErrorMsg}
        issueDescription={issueDescription}
        issueDescriptionErrorMsg={issueDescriptionErrorMsg}
        recevingDate={recevingDate}
        recevingDateErrorMsg={recevingDateErrorMsg}
        handleChange={handleChange}
        handleAddUnderRepairingSendingDate={handleAddUnderRepairingSendingDate}
        handleAddUnderRepairingRecevingDate={handleAddUnderRepairingRecevingDate}
        deviceListToAddUnderRepairing={deviceListToAddUnderRepairing}
        handleSubmit={handleAddUnderRepairingSubmit}
        handleEditDeviceRepairingStatus={handleEditDeviceRepairingStatus}
        deviceRepairingStatus={deviceRepairingStatus}
      />

    </div >
  )
}

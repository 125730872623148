import React, { useEffect, useReducer } from "react";
import { getEmployeeListByManager } from "../../../config/services/employeeServices";
import ResponseFilter from "../../../ResponseFilter";
import { CONSTANTS } from "../../../config";
import { reducer, type } from "../../helper/Constant";
import moment from "moment";
import { createEditorStateFromHTML } from "../../addTicket/Constants";
import { PrintButton } from "../ProjectUtils/Constants";
import { Editor } from "react-draft-wysiwyg";
const technologyList = CONSTANTS.INTERVIEW_SECTION.TECHNICAL_SKILL;

const initialState = {
  accountManager: "",
  technologies: [],
  projectStatus: "",
  clientName: "",
};
const Template3 = (props) => {
  const {
    month,
    year,
    dailyWorks,
    projectDesc,
    handlePrint,
    goBack,
    hideIcon,
    attendanceData,
    empName,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { accountManager, technologies, projectStatus, clientName } = state;
  useEffect(() => {
    getProfile();
    dispatch({
      type: type,
      payload: {
        technologies: projectDesc.technology,
        projectStatus: projectDesc.status,
        clientName: projectDesc.clientName,
      },
    });
  }, []);

  const getProfile = async () => {
    try {
      const res = await getEmployeeListByManager();
      const { status, resData } = ResponseFilter(res);
      if (status) {
        const manager = resData.employeesList.find(
          (employee) => employee.employeeId === projectDesc.accountManager
        );
        if (manager) {
          dispatch({
            type: type,
            payload: {
              accountManager: manager.firstName + " " + manager.lastName,
            },
          });
        }
      }
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const secondsToTime = (seconds) => {
    return parseFloat(moment.utc(seconds * 1000).format("HH.mm"));
  };

  const processDailyWorks = (dailyWorks) => {
    const workData = {};
    dailyWorks.forEach((work) => {
      const date = new Date(work.dailyworkDetails.date);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const time = work.dailyworkDetails.hour ? secondsToTime(work.dailyworkDetails.hour) : 0;
      const overTime = work.dailyworkDetails.overTime ? secondsToTime(work.dailyworkDetails.overTime):0;
      const task = work.dailyworkDetails.task;
      const ticketId = work.dailyworkDetails?.ticketId ? work.dailyworkDetails?.ticketId : "" ;
      // const overtimeHours = actualTime > time ? actualTime - time : 0;
      const key = `${year}-${month}-${day}`;
      if (!workData[key]) {
        workData[key] = {
          hours: 0,
          leave: 0,
          overtime: 0,
          total: 0,
          tasks: [],
          ticketId: "",
        };
      }
      workData[key].hours += time;
      workData[key].overtime += overTime;
      workData[key].total += time + overTime;
      workData[key].ticketId += ticketId;
      workData[key].tasks.push(createEditorStateFromHTML(task));
    });
    console.log(workData,"howmuch")
    return workData;
  };

  const data = processDailyWorks(dailyWorks);

  const getDayOfWeek = (year, month, day) => {
    return new Date(year, month - 1, day).getDay();
  };

  const calculateWeeks = (year, month) => {
    const weeks = [];
    const daysInMonth = new Date(year, month, 0).getDate();
    const prevMonthDays = new Date(year, month - 1, 0).getDate();
    let startDayOfWeek = getDayOfWeek(year, month, 1);

    let currentWeek = [];
    const prevMonth = month === 1 ? 12 : month - 1;
    const prevMonthYear = month === 1 ? year - 1 : year;
    const nextMonth = month === 12 ? 1 : +month + 1;
    const nextMonthYear = month === 12 ? year + 1 : year;
    for (let i = startDayOfWeek - 1; i > 0; i--) {
      currentWeek.push({
        day: prevMonthDays - i + 1,
        month: prevMonth,
        year: prevMonthYear,
      });
    }
    for (let day = 1; day <= daysInMonth; day++) {
      currentWeek.push({
        day: day,
        month: month,
        year: year,
      });
      if (currentWeek.length === 7 || day === daysInMonth) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }
    if (currentWeek.length > 0) {
      let nextMonthDay = 1;
      while (currentWeek.length < 7) {
        currentWeek.push({
          day: nextMonthDay,
          month: nextMonth,
          year: nextMonthYear,
        });
        nextMonthDay++;
      }
      weeks.push(currentWeek);
    }
    if (weeks.length > 0 && weeks[weeks.length - 1].length < 7) {
      let nextMonthDay = 1;
      while (weeks[weeks.length - 1].length < 7) {
        weeks[weeks.length - 1].push({
          day: nextMonthDay,
          month: nextMonth,
          year: nextMonthYear,
        });
        nextMonthDay++;
      }
    }
    return weeks;
  };

  const weeks = calculateWeeks(year, month);

  const formatTime = (time) => {
    const numTime = Number(time);
    return numTime % 1 === 0 ? numTime.toString() : numTime.toFixed(2);
  };

  const formatDate = (day, month, year) => {
    return `${String(day).padStart(2, "0")}/${String(month).padStart(
      2,
      "0"
    )}/${year}`;
  };

  return (
    <>
      <PrintButton
        handlePrint={handlePrint}
        goBack={goBack}
        hideIcon={hideIcon}
      />
      <div className="template_detail">
        <div>
          <h3>{empName}</h3>
        </div>
        {weeks.map((week, index) => (
          <div className="template3_container " key={index}>
            <div className="template3_date">
              {formatDate(
                week[week.length - 1].day,
                week[week.length - 1].month,
                week[week.length - 1].year
              )}
            </div>
            <div className="template3_data">
              <div className="mb-3">
                <div className="d-flex align-items-center">
                  <h5 className="template3_status_title"> Status:</h5>
                  <p className="template3_status">
                    {projectStatus === 1
                      ? "Ongoing"
                      : projectStatus === 2
                      ? "Completed"
                      : projectStatus == 3
                      ? "Incomplete"
                      : projectStatus == 4
                      ? "Maintenance"
                      : "On Hold"}
                  </p>
                </div>
                {clientName && (
                  <div className="d-flex align-items-center">
                    <h5 className="template3_status_title"> Client Name :</h5>
                    <p className="template3_status">{clientName}</p>
                  </div>
                )}
                {technologies.length > 0 && (
                  <div className="d-flex align-items-center">
                    <h5 className="template3_status_title"> Technology:</h5>
                    <p className="template3_status">
                      {technologies.map((technology, index) => (
                        <span key={index}>
                          {technologyList[technology]}
                          {index < technologies.length - 1 && ", "}
                        </span>
                      ))}
                    </p>
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <h5 className="template3_status_title"> Manager:</h5>
                  <p className="template3_status">{accountManager}</p>
                </div>
              </div>

              <table className="w-100">
                <thead>
                  <tr className="template3_col_header">
                    <th className="template3_table_title_width pl-1">
                      Hour Types
                    </th>
                    {week.map((dayInfo) => (
                      <th
                        key={`${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`}
                        className="p-1 template3_week_header"
                      >
                        <div className="d-flex flex-column template3_table_width">
                          <div className="d-flex">
                            {
                              ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
                                getDayOfWeek(
                                  dayInfo.year,
                                  dayInfo.month,
                                  dayInfo.day
                                )
                              ]
                            }
                            <div className="ml-1">
                              {String(dayInfo.day).padStart(2, "0")}/
                              {String(dayInfo.month).padStart(2, "0")}
                            </div>
                          </div>
                          <div>(Hours)</div>
                        </div>
                      </th>
                    ))}
                    <th className="template3_table_width">
                      <div>Total Hours</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="template3_table_body">
                  <tr>
                    <td className="template3_row">Hours</td>
                    {week.map((dayInfo) => (
                      <td
                        key={`${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`}
                      >
                        <div className="template3_row_data">
                          {formatTime(
                            data[
                              `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                            ]?.hours || 0
                          )}
                        </div>
                      </td>
                    ))}
                    <td className="template3_total_hours">
                      <div className="d-flex justify-content-end">
                        {formatTime(
                          week.reduce(
                            (acc, dayInfo) =>
                              acc +
                              (data[
                                `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                              ]?.hours || 0),
                            0
                          )
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="template3_row">Leave</td>
                    {week.map((dayInfo) => (
                      <td
                        key={`${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`}
                      >
                        <div className="template3_row_data">
                          {attendanceData[
                            `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                          ] === "leave" && 8}
                          {attendanceData[
                            `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                          ] === "halfLeave" && 4}
                          {attendanceData[
                            `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                          ] !== "leave" &&
                            attendanceData[
                              `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                            ] !== "halfLeave" &&
                            0}
                        </div>
                      </td>
                    ))}
                    <td className="template3_total_hours">
                      <div className="d-flex justify-content-end">
                        {formatTime(
                          week.reduce(
                            (acc, dayInfo) =>
                              acc +
                              (data[
                                `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                              ]?.leave || 0),
                            0
                          )
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="template3_row">Overtime</td>
                    {week.map((dayInfo) => (
                      <td
                        key={`${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`}
                      >
                        <div className="template3_row_data">
                          {formatTime(
                            data[
                              `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                            ]?.overtime || 0
                          )}
                        </div>
                      </td>
                    ))}
                    <td className="template3_total_hours">
                      <div className="d-flex justify-content-end">
                        {formatTime(
                          week.reduce(
                            (acc, dayInfo) =>
                              acc +
                              (data[
                                `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                              ]?.overtime || 0),
                            0
                          )
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="template3_row">Total Hours</td>
                    {week.map((dayInfo, columnIndex) => (
                      <td
                        key={`${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`}
                        className={
                          columnIndex === week.length - 1
                            ? "template3_total_hours"
                            : "template3_total_hours"
                        }
                      >
                        <div className="d-flex justify-content-end">
                          {formatTime(
                            data[
                              `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                            ]?.total || 0
                          )}
                        </div>
                      </td>
                    ))}
                    <td className="template3_total_hours final_total_hours">
                      <div className="d-flex justify-content-end">
                        {formatTime(
                          week.reduce(
                            (acc, dayInfo) =>
                              acc +
                              (data[
                                `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                              ]?.total || 0),
                            0
                          )
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="template3_row">
                      Daily Comments
                      <br />
                      (Ticket id)
                    </td>
                    {week.map((dayInfo) => (
                      <td
                        key={`${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`}
                      >
                        <div className="template3_row_data font-weight-normal font-italic">
                          {data[
                            `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                          ]?.ticketId
                            ? `${
                                data[
                                  `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`
                                ].ticketId
                              }`
                            : "_"}
                        </div>
                      </td>
                    ))}
                    <td className="template3_total_hours"></td>
                  </tr>
                </tbody>
              </table>
              {week.some(
                (dayInfo) =>
                  data[`${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`]?.tasks
              ) && (
                <div>
                  <div className="template3_status_title mt-3">Tasks:</div>
                  {week.map((dayInfo) => {
                    const dayKey = `${dayInfo.year}-${dayInfo.month}-${dayInfo.day}`;
                    const dayData = data[dayKey];

                    return (
                      dayData?.tasks && (
                        <div
                          key={dayKey}
                          className="mb-3 d-flex align-items-center"
                        >
                          <strong>
                            {formatDate(
                              dayInfo.day,
                              dayInfo.month,
                              dayInfo.year
                            )}
                            :
                          </strong>
                          <div className="ml-3 w-100 template3_tasks">
                            <h4></h4>
                            {dayData.tasks.map((task, index) => (
                              <Editor
                                key={index}
                                wrapperClassName="wrapper-class"
                                editorClassName="p-2"
                                editorState={task}
                                toolbarHidden
                              />
                            ))}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Template3;

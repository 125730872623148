import _ from "lodash";
import moment from "moment";
import React from "react";
import Pagination from "react-js-pagination";
import { statusValue } from "../attendance/Constants";
import { handleSort } from "../helper/Constant";

export default function TeamAttendance(props) {
  const {
    attendanceList,
    pageNo,
    dataCount,
    handlePageChange,
    count,
    sortType,
    sortField,
    dispatch,
  } = props;
  return (
    <div>
      <div class="table-responsive">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">
                Employee ID
                <i
                  className={
                    sortType === 2 && sortField === "mobId"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("mobId", dispatch, sortType)}
                ></i>
              </th>
              <th>Name<i
                  className={
                    sortType === 2 && sortField === "employeeName"
                      ? "fa fa-sort-alpha-desc ml-2"
                      : "fa fa-sort-alpha-asc ml-2"
                  }
                  onClick={() => handleSort("employeeName", dispatch, sortType)}
                ></i></th>

              <th scope="col">Date
              <i
                  className={
                    sortType === 2 && sortField === "date"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("date", dispatch, sortType)}
                ></i></th>
              <th scope="col">Check In</th>
              <th scope="col">Late Check In</th>
              <th scope="col">Early Check Out</th>
              <th scope="col">Check Out</th>
              <th scope="col">Total Hours
              <i
                  className={
                    sortType === 2 && sortField === "totalHours"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("totalHours", dispatch, sortType)}
                ></i></th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(attendanceList)
              ? attendanceList.map((data, i) => (
                  <tr>
                    <td>
                      <span className="cm_col">
                        {i + 1 + (pageNo - 1) * count}
                      </span>
                    </td>
                    <td>
                      <span className="cm_col"> {data.mobId}</span>
                    </td>
                    <td>
                      <span className="cm_col">{data.employeeName}</span>
                    </td>
                    <td>
                      {new Date(data.date)
                        .getDate()
                        .toString()
                        .padStart(2, "0") +
                        "/" +
                        (new Date(data.date).getMonth() + 1)
                          .toString()
                          .padStart(2, "0") +
                        "/" +
                        new Date(data.date)
                          .getFullYear()
                          .toString()
                          .padStart(2, "0")}
                    </td>
                    <td>
                      <time>
                        {data.checkIn > "0" &&
                        (data.checkIn.includes(".")
                          ? parseInt(
                              +data.checkIn.split(".")[0] * 60 * 60 +
                                +data.checkIn.split(".")[1] * 60
                            )
                          : parseInt(data.checkIn * 60 * 60)) <=
                          data.reportingTime + 1800
                          ? data.checkIn.indexOf(".") !== -1
                            ? data.checkIn.toString().split(".")[1].length <= 1
                              ? data.checkIn.toString().split(".")[0] +
                                ":" +
                                data.checkIn.toString().split(".")[1] +
                                "0 AM"
                              : data.checkIn.toString().split(".")[0] +
                                ":" +
                                data.checkIn.toString().split(".")[1] +
                                " AM"
                            : data.checkIn + ":00 AM"
                          : "_"}
                      </time>
                    </td>
                    <td className="text-danger">
                      <time>
                        {data.checkIn > "0"
                          ? (data.checkIn.includes(".")
                              ? parseInt(
                                  +data.checkIn.split(".")[0] * 60 * 60 +
                                    +data.checkIn.split(".")[1] * 60
                                )
                              : parseInt(data.checkIn * 60 * 60)) >
                              data.reportingTime + 1800 &&
                            data.checkIn.toString().split(".")[0] < 12
                            ? data.checkIn.indexOf(".") !== -1
                              ? data.checkIn.toString().split(".")[1].length <=
                                1
                                ? data.checkIn.toString().split(".")[0] +
                                  ":" +
                                  data.checkIn.toString().split(".")[1] +
                                  "0 AM"
                                : data.checkIn.toString().split(".")[0] +
                                  ":" +
                                  data.checkIn.toString().split(".")[1] +
                                  " AM"
                              : data.checkIn + ":00 AM"
                            : (data.checkIn.includes(".")
                                ? parseInt(
                                    +data.checkIn.split(".")[0] * 60 * 60 +
                                      +data.checkIn.split(".")[1] * 60
                                  )
                                : parseInt(data.checkIn * 60 * 60)) &&
                              data.checkIn.toString().split(".")[0] >= 12
                            ? data.checkIn.indexOf(".") !== -1
                              ? data.checkIn.toString().split(".")[1].length <=
                                1
                                ? data.checkIn.toString().split(".")[0] +
                                  ":" +
                                  data.checkIn.toString().split(".")[1] +
                                  "0 PM"
                                : data.checkIn.toString().split(".")[0] +
                                  ":" +
                                  data.checkIn.toString().split(".")[1] +
                                  " PM"
                              : data.checkIn + ":00 PM"
                            : "_"
                          : "_"}
                      </time>
                    </td>
                    <td>
                      <span className="text-danger">
                        <time>
                          {data.checkOut > "0" &&
                          data.totalHours < 32400 &&
                          data.checkIn !== "0" &&
                          data.checkOut.toString().split(".")[0] <= 12
                            ? data.checkOut.indexOf(".") !== -1
                              ? data.checkOut.toString().split(".")[1].length <=
                                1
                                ? data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  "0 AM"
                                : data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  " AM"
                              : data.checkOut + ":00 AM"
                            : data.checkOut > "0" &&
                              data.totalHours < 32400 &&
                              data.checkIn !== "0" &&
                              data.checkOut.toString().split(".")[0] > 12
                            ? data.checkOut.indexOf(".") !== -1
                              ? data.checkOut.toString().split(".")[1].length <=
                                1
                                ? data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  "0 PM"
                                : data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  " PM"
                              : data.checkOut + ":00 PM"
                            : "_"}
                        </time>
                      </span>
                    </td>
                    <td>
                      {data.checkOut > "0" ? (
                        data.totalHours === 0 ? (
                          <time>
                            {data.checkOut.indexOf(".") !== -1
                              ? data.checkOut.toString().split(".")[1].length <=
                                1
                                ? data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  "0 PM"
                                : data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  " PM"
                              : data.checkOut + ":00 PM"}
                          </time>
                        ) : data.totalHours >= 32400 ? (
                          <span className="green">
                            <time>
                              {data.checkOut.toString().split(".")[0] < 12
                                ? data.checkOut.indexOf(".") !== -1
                                  ? data.checkOut.toString().split(".")[1]
                                      .length <= 1
                                    ? data.checkOut.toString().split(".")[0] +
                                      ":" +
                                      data.checkOut.toString().split(".")[1] +
                                      "0 AM"
                                    : data.checkOut.toString().split(".")[0] +
                                      ":" +
                                      data.checkOut.toString().split(".")[1] +
                                      " AM"
                                  : data.checkOut + ":00 AM"
                                : data.checkOut.indexOf(".") !== -1
                                ? data.checkOut.toString().split(".")[1]
                                    .length <= 1
                                  ? data.checkOut.toString().split(".")[0] +
                                    ":" +
                                    data.checkOut.toString().split(".")[1] +
                                    "0 PM"
                                  : data.checkOut.toString().split(".")[0] +
                                    ":" +
                                    data.checkOut.toString().split(".")[1] +
                                    " PM"
                                : data.checkOut + ":00 PM"}
                            </time>
                          </span>
                        ) : (
                          "_"
                        )
                      ) : (
                        "_"
                      )}
                    </td>

                    <td>
                      {moment.utc(data.totalHours * 1000).format("HH:mm") <
                        moment.utc(32400 * 1000).format("HH:mm") &&
                      moment.utc(data.totalHours * 1000).format("HH:mm") >=
                        moment.utc(28800 * 1000).format("HH:mm") ? (
                        <time title="View attendance record" className="yellow">
                          {data.totalHours
                            ? moment.utc(data.totalHours * 1000).format("HH.mm")
                            : "00.00"}
                        </time>
                      ) : moment.utc(data.totalHours * 1000).format("HH:mm") <
                        moment.utc(32400 * 1000).format("HH:mm") ? (
                        <time
                          title="View attendance record"
                          className="text-danger"
                        >
                          {data.totalHours
                            ? moment.utc(data.totalHours * 1000).format("HH.mm")
                            : "00.00"}
                        </time>
                      ) : (
                        <time title="View attendance record" className="green">
                          {data.totalHours
                            ? moment.utc(data.totalHours * 1000).format("HH.mm")
                            : "00.00"}
                        </time>
                      )}
                    </td>
                    <td
                      className={
                        data.status === 1 ? "text-success" : "text-danger"
                      }
                    >
                      {statusValue(data.status)}
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {attendanceList && attendanceList.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center">No data available</h3>
        )}
      </div>

      {dataCount > 10 ? (
        <Pagination
          activePage={pageNo}
          itemsCountPerPage={count}
          totalItemsCount={dataCount}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
        />
      ) : (
        ""
      )}
    </div>
  );
}

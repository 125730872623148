import React, { Component } from 'react';
import ResponseFilter from '../../ResponseFilter';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Loader } from '../svg';
import Modal from 'react-bootstrap/Modal';
import Pagination from "react-js-pagination";
import { DatePicker, Modal as AntModal, TimePicker } from 'antd';
import { Collapse } from 'antd';
import { dailyWorkByProjectId, addNewStandup, editNewStandup } from '../../config/services/employeeServices';
import { format } from '../leaves/Constants';

const { Panel } = Collapse;



const colors = ['cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue', 'cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue', 'cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue', 'cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue', 'cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue'];

class AllActivityLogEmp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            miniLoader: false,
            loader: true,
            dailyWorkDetails: [],
            openAddNewStandupPopup: false,
            openEditLogPopup: false,
            task: '',
            blocker: '',
            date: '',
            hour: '',
            projectId: '',
            taskErrorMsg: '',
            dateErrorMsg: '',
            hourErrorMsg: '',
            dataCount: '',
            sortType: 2,
            sortField: "created",
            pageNo: 1,
            count: 10,

            startTime: 0,
            startTimeErrorMsg: "",

            endTime: 0,
            endTimeErrorMsg: ""

        }
    }

    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: ''
    });
    handleOpenAddNewStandupPopup = () => this.setState({ openAddNewStandupPopup: !this.state.openAddNewStandupPopup });
    handleOpenEditLogPopup = ({ task, date, hour, blocker, dailyWorkId, projectId, startTime, endTime }) => this.setState({ openEditLogPopup: !this.state.openEditLogPopup, task, date, hour, blocker, dailyWorkId, projectId, startTime, endTime });
    closeModal = () => this.setState({
        openAddNewStandupPopup: false,
        openEditLogPopup: false,
        hourErrorMsg: '',
        taskErrorMsg: '',
        dateErrorMsg: '',
        task: '',
        date: '',
        hour: '',
        blocker: '',

        startTime: 0,
        endTime: 0,
        startTimeErrorMsg: "",
        endTimeErrorMsg: ""
    })
    handleDateChange = (date, dateString) => this.setState({ date: moment(date).toDate().getTime(), dateErrorMsg: '' });


    getAllActivityLogs = () => {

        let { sortType, sortField, pageNo, count } = this.state
        let params = `count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&projectId=${this.props.match.params.id}`;


        dailyWorkByProjectId(params).then(res => {
            if (res.data.statusCode == 1) {
                let { status, resData } = ResponseFilter(res);
                if (status) {
                    this.setState({
                        loader: false,
                        dailyWorkDetails: resData.dailyWorkDetails,
                        dataCount: resData.dataCount,
                        projectId: resData.dailyWorkDetails[0].projectId
                    }, () => this.closeModal())
                }
                else {
                    this.setState({
                        loader: false,
                    })

                }
            }
        })
    }

    componentDidMount() {
        this.getAllActivityLogs();



    }

    handleSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidetion()) {
            self.setState({ miniLoader: true })
            let obj = {
                'projectId': this.props.match.params.id,
                'task': this.state.task,
                'blocker': this.state.blocker,
                'date': this.state.date,
                'startTime': this.state.startTime,
                'endTime': this.state.endTime,
                'hour': this.state.startTime && this.state.endTime ? (this.state.endTime - this.state.startTime) + 3600 : ''

            }
            addNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getAllActivityLogs())
                    self.closeModal();
                    self.setState({ miniLoader: false })

                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        loader: false,
                        miniLoader: false

                    })
                    // self.closeModal();
                }
            })
        }
    }
    handleEditSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidetion()) {

            let obj = {
                'dailyWorkId': this.state.dailyWorkId,
                'projectId': this.props.match.params.id,
                'task': this.state.task,
                'blocker': this.state.blocker,
                'date': this.state.date,
                'hour': this.state.startTime && this.state.endTime ? (this.state.endTime - this.state.startTime) + 3600 : '',
                'startTime': this.state.startTime,
                'endTime': this.state.endTime,

            }
            editNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getAllActivityLogs())
                    self.closeModal();

                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        loader: false,

                    })
                    // self.closeModal();
                }
            })
        }
    }

    handleValidetion = () => {
        let validate = true;
        const { task, date, hour, startTime, endTime } = this.state;

        if (task === '' || task === undefined) {
            validate = false;
            this.setState({ taskErrorMsg: 'Task is required' })
        }
        else {
            this.setState({ taskErrorMsg: '' })
        }

        if (date === '' || date === undefined) {
            validate = false;
            this.setState({ dateErrorMsg: 'Date is required' })
        }
        else {
            this.setState({ dateErrorMsg: '' })
        }
        // if (hour === '' || hour === undefined) {
        //     validate = false;
        //     this.setState({ hourErrorMsg: 'Hours is required' })
        // }
        // else {
        //     this.setState({ hourErrorMsg: '' })
        // }

        if (startTime == null || startTime == 0 | startTime == undefined || startTime == "") {
            validate = false
            this.setState({ startTimeErrorMsg: "Start time is required" })
        }
        else {
            this.setState({ startTimeErrorMsg: "" })

        }

        if (endTime == null || endTime == 0 | endTime == undefined || endTime == "") {
            validate = false
            this.setState({ endTimeErrorMsg: "end time is required" })
        }
        else if (endTime > endTime) {
            this.setState({ endTimeErrorMsg: "End tims should be greater then end time" })

        }
        else {
            this.setState({ endTimeErrorMsg: "" })

        }

        return validate;
    }


    handleCheckInChange = (checkIn, checkInString) => {
        this.setState({

            startTime: moment.duration(checkInString).asSeconds(),
            startTimeErrorMsg: "",
        })

    }

    handleCheckOutChange = (checkOut, checkOutString) => {
        this.setState({

            endTime: moment.duration(checkOutString).asSeconds(),
            endTimeErrorMsg: "",
        })
    }

    render() {
        let { openEditLogPopup, loader, miniLoader, pageNo, dataCount, count, dailyWorkDetails, openAddNewStandupPopup, task, blocker, hour, date, projectId, taskErrorMsg, blockerErrorMsg, dateErrorMsg, hourErrorMsg, startTime, startTimeErrorMsg, endTime, endTimeErrorMsg } = this.state;

        return (
            <div className="body_container">
                {

                    loader && <Loader />
                }
                <div className="cm_breadcrumb cm_bold">
                    <ul>
                        <li><Link to='/projects'>PROJECT</Link><div className="cm_breadcrumb_arrow"></div></li>
                        <li><Link to={`/projects/${projectId}`}>PROJECT DETAILS</Link><div className="cm_breadcrumb_arrow"></div></li>
                        <li>ACTIVITY LOGS</li>
                    </ul>
                </div>
                <div className="text-right"><button className="btn btn-primary edit_appraisal mt-5 mb-5" type="submit" onClick={this.handleOpenAddNewStandupPopup}>New Standup</button></div>

                <div>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}

                    >
                        {
                            !_.isEmpty(dailyWorkDetails) &&
                            dailyWorkDetails.map((dailyWork, i) => (
                                localStorage.getItem('employeeId') === (dailyWork.employeeId) ?
                                    <Panel
                                        header={moment(dailyWork.date).format('DD/MM/YYYY')}
                                        // footer={dailyWork.firstName}
                                        key={dailyWork.dailyWorkId}
                                        className={colors[i]}
                                    >
                                        <div className="row">
                                            <div className="col-2">
                                                <img className="user_img" src={dailyWork.profileImage ? dailyWork.profileImage : require('../../asset/imgs/user-img.png')} alt="" />
                                            </div>

                                            <div className="col mt-4 cm_break">

                                                {/* <span className="edit_log_view"><i className="fa fa-trash-o  red" title="Delete activity log"></i></span> */}
                                                <label>What did you do today?</label>
                                                <p>{dailyWork.task}</p>
                                                <label>What problems are you facing?</label>
                                                <p>{dailyWork.blocker ? dailyWork.blocker : 'Nothing'}</p>
                                                <label>Number of hours working?</label>
                                                <p>{moment.utc(dailyWork.hour * 1000).format("HH:mm")}</p>
                                            </div>
                                            <div className="col-md-2 text-right cm_activity">

                                                <span className="edit_log_view_more mr-5" onClick={() => this.handleOpenEditLogPopup(dailyWork)}><i className="fa fa-pencil" aria-hidden="true" title="Edit activity log"></i></span>
                                                {/* <Link to={`/projects/${dailyWork.projectId}/${dailyWork.employeeId}`} className="btn btn-primary btn-sm mt-4" style={{ width: 90 }}>See More</Link> */}
                                            </div>
                                        </div>
                                    </Panel> : ''
                            ))

                        }

                    </Collapse>
                    <div className="mt-5">
                        {dataCount > 10 ? <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={dataCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        /> : ''}</div>
                </div>
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openAddNewStandupPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >

                    <Modal.Body>
                        <form onSubmit={this.handleSubmit}>
                            <h3>NEW STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false} />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours Working</label>
                                    <input
                                        className="form-control cm_box"
                                        type="number"
                                        step="any"
                                        name="hour"
                                        placeholder="Hours"
                                        value={startTime && endTime ? moment.utc(((endTime - startTime) + 3600) * 1000).format("HH:mm") : ''}
                                    // onChange={this.handleChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>


                            <div className="row">
                                <div
                                    className={`form-group col-6 ${startTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub"> From</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={startTime ? moment.utc(startTime * 1000) : ""}
                                        onChange={this.handleCheckInChange}
                                    />
                                    {startTimeErrorMsg ? (
                                        <span className="error_msg">{startTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                                <div
                                    className={`form-group col-6 ${endTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub">To</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={endTime ? moment.utc(endTime * 1000) : ""}
                                        onChange={this.handleCheckOutChange}
                                    />
                                    {endTimeErrorMsg ? (
                                        <span className="error_msg">{endTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>

                                <textarea
                                    className="form-control cm-des  cm_box"
                                    type="text"
                                    maxLength={500}
                                    placeholder="Today's task"
                                    name="task"
                                    value={task}
                                    onChange={this.handleChange}
                                >
                                </textarea>
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${blockerErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What problem are you facing?</label>
                                <textarea
                                    className="form-control cm-des cm_box"
                                    type="text"
                                    maxLength={500}
                                    name="blocker"
                                    placeholder="Today's blocker"
                                    value={blocker}
                                    onChange={this.handleChange}
                                >
                                </textarea>

                            </div>

                            <div className="text-center mt-4"><button type="submit" className="btn btn-primary mt-2 cm_btn3" disabled={miniLoader}>Submit</button>
                                {miniLoader ? <div className="mini_loader_dept"></div> : ''}
                                {miniLoader ? '' : <button type="button" className="btn btn-primary mt-2  ml-4 cm_btn2" onClick={this.closeModal}>Cancel</button>}

                            </div>
                        </form>
                    </Modal.Body>
                </AntModal>
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openEditLogPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >

                    <Modal.Body>
                        <form onSubmit={this.handleEditSubmit}>
                            <h3>EDIT STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false} />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours Working</label>
                                    <input
                                        className="form-control cm_box"
                                        type="number"
                                        step="any"
                                        name="hour"
                                        placeholder="Hours"
                                        value={startTime && endTime ? moment.utc(((endTime - startTime) + 3600) * 1000).format("HH:mm") : ''}
                                    // onChange={this.handleChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className={`form-group col-6 ${startTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub"> From</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={startTime ? moment.utc(startTime * 1000) : ""}
                                        onChange={this.handleCheckInChange}
                                    />
                                    {startTimeErrorMsg ? (
                                        <span className="error_msg">{startTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                                <div
                                    className={`form-group col-6 ${endTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub">To</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={endTime ? moment.utc(endTime * 1000) : ""}
                                        onChange={this.handleCheckOutChange}
                                    />
                                    {endTimeErrorMsg ? (
                                        <span className="error_msg">{endTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>

                                <textarea
                                    className="form-control cm-des  cm_box"
                                    type="text"
                                    maxLength={500}
                                    placeholder="Today's task"
                                    name="task"
                                    value={task}
                                    onChange={this.handleChange}
                                >
                                </textarea>
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${blockerErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What problem are you facing?</label>
                                <textarea
                                    className="form-control cm-des cm_box"
                                    type="text"
                                    maxLength={500}
                                    name="blocker"
                                    placeholder="Today's blocker"
                                    value={blocker}
                                    onChange={this.handleChange}
                                >
                                </textarea>

                            </div>
                            <div className="text-center"><button type="submit" className="btn btn-primary mt-2 cm_btn3">Submit</button>
                                <button type="button" className="btn btn-primary mt-2  ml-4 cm_btn2" onClick={this.closeModal}>Cancel</button></div>

                        </form>
                    </Modal.Body>
                </AntModal>
            </div>

        )
    }
}
export default AllActivityLogEmp;

import { Select } from "antd";
import React, { useReducer } from "react";
import {
  createSelectItems,
  dateFormat,
  reducer,
  STATUS_TWO,
  type,
} from "../helper/Constant";
import {
  colorType,
  HALF_LWP,
  leaveValue,
  LEAVE_WITHOUT_PAY,
  statusValue,
  LEAVE_ARRAY,
} from "./Constants";
import Pagination from "react-js-pagination";
import moment from "moment";
import _ from "lodash";
import ViewLeaveReason from "./leaveModal/ViewLeaveReason";
import { ApproveRejectLeaveModal } from "./leaveModal/ApproveRejectLeaveModal";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { handleActionValidation } from "./LeaveValidation";
import { leaveAction } from "../../config/services/employeeServices";
import DeleteCompOffModal from "./leaveModal/DeleteCompOffModal";
import ViewUploadedDocument from "../helper/ViewUploadedDocument";
const Option = { Select };

const initialState = {
  reason: "",
  openViewModal: false,
  viewDocument: [],
  viewDocumentPopup: false,
};
function EmployeeLeaveData(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { reason, openViewModal, viewDocument, viewDocumentPopup } = state;
  let {
    totalLeaves,
    pendingCount,
    lateCount,
    approvedCount,
    rejectedCount,
    leaveDetails,
    pageNo,
    count,
    handleOpenDeleteLeavePopup,
    handlePageChange,
    handleYear,
    sortType,
    sortField,
    handleSort,
    handleOpenLeavePopup,
    handleFilter,
    handleOpenApplyForClientOffice,
    searchYear,
    statusFilter,
    dataCount,
    handleLeaveFilter,
    leaveTypeSelect,
    openLeaveApprove_RejectPopup,
    leaveId,
    startDate,
    isApproved,
    leaveMsg,
    endDate,
    leaveType,
    halfOfTheDay,
    replyErrorMsg,
    handleChange,
    editorState,
    onEditorStateChange,
    responseAction,
    reply,
    dispatched,
    closeModal,
    handleOpenShowCompOff,
    isAdmin,
    handleOpenDeleteCompOff,
    openDeleteCompOff,
    deleteCompOffNo,
    handleDeleteCompOffChange,
    deleteCompOffNoErrMsg,
    handleDeleteCompOff,
    getEmployeeLeavesList,
  } = props;
  const handleOpenView = (data, document) => {
    dispatch({
      type: type,
      payload: {
        reason: data,
        openViewModal: true,
        viewDocument: document,
      },
    });
  };
  const handleOpenViewDocPopup = (viewDocument) => {
    dispatch({
      type: type,
      payload: {
        viewDocumentPopup: true,
        viewDocument: viewDocument,
      },
    });
  };

  const handleClose = () => {
    dispatch({
      type: type,
      payload: {
        reason: "",
        openViewModal: false,
      },
    });
  };

  const handleCancel = () => {
    dispatch({
      type: type,
      payload: {
        viewDocumentPopup: false,
      },
    });
  };

  const handleOpenLeaveApprove_RejectPopup = (leave, is_Boolean) => {
    let { _id, startDate, leaveMsg, endDate, leaveType, halfOfTheDay } = leave;
    dispatched({
      type: type,
      payload: {
        openLeaveApprove_RejectPopup: true,
        leaveId: _id,
        startDate: startDate,
        isApproved: is_Boolean,
        leaveMsg: leaveMsg,
        endDate: endDate,
        leaveType: leaveType,
        halfOfTheDay: halfOfTheDay,
      },
    });
  };

  const handleAction = (statusType) => {
    let leaveReason = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const params = {
      leaveId: leaveId,
      status: statusType,
      reply: leaveReason,
    };
    if (
      handleActionValidation({
        reply: reply,
        dispatch: dispatched,
        editorState: editorState,
        type: type,
        replyErrorMsg: replyErrorMsg,
      })
    ) {
      dispatched({
        type: type,
        payload: {
          loader: true,
        },
      });
      leaveAction(params).then((res) => {
        dispatched({
          type: type,
          payload: {
            loader: false,
          },
        });
        if (res.data.statusCode === 1) {
          dispatched({
            type: type,
            payload: {
              loader: false,
              resMsg: true,
              leaveType: "",
              leaveTypeSelect: "",
              resStatus: res.data.responseData.leaveDetails.status,
              openLeaveApprove_RejectPopup: false,
              openLeaveLwpApprovePopup: false,
              successMessage:
                statusType === 2 ? "Leave Approved" : "Leave Rejected",
            },
          });
          getEmployeeLeavesList();
        } else {
          dispatched({
            type: type,
            payload: {
              responseMessage: res.data.error.responseMessage,
              resMsgFail: true,
            },
          });
          getEmployeeLeavesList();
        }
        responseAction();
      });
      closeModal()
      handleClose();
    }
  };

  return (
    <div>
      <div className="text-left  cm_leave_btn">
        <div className="filters justify-content-end mb-2">
          <button className="btn btn-primary" onClick={handleOpenShowCompOff}>
            Temporary CompOff Leave List
          </button>
          {isAdmin && (
            <button
              className="btn btn-danger"
              onClick={handleOpenDeleteCompOff}
            >
              Delete CompOff
            </button>
          )}
          {props.leavePage && props.leavePage == 1 ? (
            ""
          ) : (
            <>
              <button
                className="btn btn-primary"
                onClick={handleOpenApplyForClientOffice}
              > 
                Apply For Client Office
              </button>
              <Select
                className="select_year_cal "
                value={searchYear ? searchYear : "Select Year"}
                name="searchYear"
                onChange={handleYear}
                allowClear={searchYear ? true : false}
              >
                {createSelectItems()}
              </Select>
            </>
          )}
          <Select
            placeholder="Other"
            className="select_year_cal"
            id="leave_tab_button"
            onChange={handleLeaveFilter}
            value={leaveTypeSelect ? leaveTypeSelect : "Select Leave"}
            allowClear={leaveTypeSelect ? true : false}
          >
            {LEAVE_ARRAY.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      {props.leavePage && props.leavePage == 1 ? (
        <h3 className="mb-4">Leave List</h3>
      ) : (
        ""
      )}
      <div className="text-left cm_ticket_btn cm_leave_btn">
        <button
          className={`btn btn-primary ${statusFilter == "" ? "active" : ""}`}
          onClick={() => handleFilter("")}
        >
          <span className="tabtext">All {totalLeaves}</span>
        </button>
        <button
          className={`btn btn-primary ${statusFilter == 1 ? "active" : ""}`}
          onClick={() => handleFilter(1)}
        >
          <span className="tabtext">Pending {pendingCount}</span>
        </button>
        <button
          className={`btn btn-primary ${statusFilter == 2 ? "active" : ""}`}
          onClick={() => handleFilter(2)}
        >
          <span className="tabtext">Approved {approvedCount}</span>
        </button>
        <button
          className={`btn btn-primary ${statusFilter == 3 ? "active" : ""}`}
          onClick={() => handleFilter(3)}
        >
          <span className="tabtext">Rejected {rejectedCount}</span>
        </button>
        <button
          className={`btn btn-primary ${statusFilter == 4 ? "active" : ""}`}
          onClick={() => handleFilter(4)}
        >
          <span className="tabtext">Late {lateCount}</span>
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th>S.No</th>
              <th scope="col">
                Start Date
                <i
                  className={
                    sortType === 1 && sortField === "startDate"
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={() => handleSort("startDate")}
                ></i>
              </th>

              <th>End Date </th>
              <th>Leave Type</th>
              <th>Reason</th>
              <th>Status </th>
              <th>Action </th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(leaveDetails)
              ? leaveDetails.map((data, i) => (
                  <tr key={data.leaveId}>
                    <td>{i + 1 + (pageNo - 1) * count}</td>
                    <td>{moment(data.startDate).format(dateFormat)}</td>
                    <td>{moment(data.endDate).format(dateFormat)}</td>
                    <td>{leaveValue(data.leaveType)}</td>
                    <td>
                      <a>
                        <i
                          onClick={() =>
                            handleOpenView(data.leaveMsg, data.leaveDoc)
                          }
                          className="fa fa-eye blue ml-2"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </td>
                    <td className={colorType(data.status)}>
                      <div className="col-4 ">
                        <span
                          className={`${
                            (data.leaveType === LEAVE_WITHOUT_PAY &&
                              data.status === STATUS_TWO) ||
                            (data.leaveType === HALF_LWP &&
                              data.status === STATUS_TWO)
                              ? "text-danger"
                              : ""
                          }`}
                          style={{ width: 170 }}
                        >
                          {data.leaveType === HALF_LWP &&
                          data.status === STATUS_TWO
                            ? "Half LWP"
                            : data.leaveType === LEAVE_WITHOUT_PAY &&
                              data.status === STATUS_TWO
                            ? "LWP"
                            : statusValue(data.status)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className=" leave_button_margin">
                        <button
                          onClick={() => handleOpenLeavePopup(data)}
                          className="btn btn-primary btn-sm"
                          style={{ width: "5rem" }}
                        >
                          view
                        </button>
                        {isAdmin && (
                          <>
                            {data.status === 1 && (
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-success ml-3 btn-sm mr-1 rej_btn"
                                  onClick={() =>
                                    handleOpenLeaveApprove_RejectPopup(
                                      data,
                                      true
                                    )
                                  }
                                >
                                  Approve
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm rej_btn "
                                  onClick={() =>
                                    handleOpenLeaveApprove_RejectPopup(
                                      data,
                                      false
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                            {data.status === 2 && (
                              <div>
                                {data.leaveType === 4 && (
                                  <span className="text-danger inline-block mr-4 ml-3">
                                    LWP
                                  </span>
                                )}
                                <button
                                  className="btn btn-danger ml-3 btn-sm rej_btn "
                                  onClick={() =>
                                    handleOpenLeaveApprove_RejectPopup(
                                      data,
                                      false
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                            {data.status === 3 && (
                              <div>
                                {data.leaveType === 4 && (
                                  <span className="text-danger inline-block mr-4 ml-3">
                                    LWP
                                  </span>
                                )}
                                <button
                                  className="btn btn-success ml-3 btn-sm rej_btn"
                                  onClick={() =>
                                    handleOpenLeaveApprove_RejectPopup(
                                      data,
                                      true
                                    )
                                  }
                                >
                                  Approve
                                </button>
                              </div>
                            )}
                          </>
                        )}

                        {data.status === 1 &&
                        Date.parse(data.startDate) >=
                          Math.floor(new Date().setHours(0, 0, 0, 0)) &&
                        props.leavePage !== 1 ? (
                          <button
                            className="btn btn-danger btn-sm"
                            style={{ width: "5rem" }}
                            onClick={() => handleOpenDeleteLeavePopup(data)}
                          >
                            Delete
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {leaveDetails && leaveDetails.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center mt-2 mb-2">No data is available</h3>
        )}
      </div>
      {dataCount > 10 ? (
        <div className="mb-5">
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={dataCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <ViewLeaveReason
        show={openViewModal}
        reason={reason}
        handleClose={handleClose}
        employeeType={props.employeeType}
        data={viewDocument}
        handleOpenViewDocPopup={handleOpenViewDocPopup}
      />
      <DeleteCompOffModal
        handleClose={closeModal}
        show={openDeleteCompOff}
        deleteCompOffNo={deleteCompOffNo}
        handleDeleteCompOffChange={handleDeleteCompOffChange}
        deleteCompOffNoErrMsg={deleteCompOffNoErrMsg}
        handleDeleteCompOff={handleDeleteCompOff}
      />
      <ViewUploadedDocument
        show={viewDocumentPopup}
        data={viewDocument}
        handleClose={handleCancel}
      />
      <ApproveRejectLeaveModal
        show={openLeaveApprove_RejectPopup}
        handleClose={closeModal}
        isApproved={isApproved}
        leaveType={leaveType}
        startDate={startDate}
        halfOfTheDay={halfOfTheDay}
        endDate={endDate}
        replyErrorMsg={replyErrorMsg}
        leaveMsg={leaveMsg}
        handleChange={handleChange}
        handleAction={handleAction}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
}

export default EmployeeLeaveData;

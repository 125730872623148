import React from "react";
import { DatePicker, Select } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import "../../components/reimbursement/reimbursement.css";
import { IoMdClose } from "react-icons/io";
import {
  claimReimbursement,
  getEmployeeListByManager,
} from "../../config/services/employeeServices";
import { DocIcon, ExcelIcon, Loader, PdfIcon } from "../svg";
import _ from "lodash";
import ResponseFilter from "../../ResponseFilter";
import { CURRENCY, handleValidation } from "./ReimbursementConstant";
let url = "/reimbursement";
const { Option } = Select;

function disabledDate(current) {
  const today = moment().endOf("day");
  const earliestDate = moment("2022-01-01").startOf("day");
  return current && (current > today || current < earliestDate);
}

class ClaimReimbursement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      title: "",
      uploadBill: "",
      expenseDescription: "",
      amount: "",
      expenseDate: "",
      billPreview: "",
      uploadFileName: "",
      uploadFileSize: "",
      resMsg: false,
      miniLoader: false,
      employeeList: [],
      projectManagerId: "",
      projectManagerNameErMsg: "",
      reimbursementType: 0,
      reimbursementTypeErrMsg: "",
      currencyType: "",
      currencyTypeErrMsg: "",
    };
  }

  handleSelectExpenseDateChange = (date, dateString) => {
    this.setState({ expenseDate: new Date(dateString) });
    this.setState({ expenseDateErrorMsg: "" });
  };

  handleChange = (e) => {
    if (e.target.name === "amount" && e.target.value > 200000) {
      if (e.target.value.length <= 6) {
        this.setState({
          amount: e.target.value,
          amountErrorMsg: "Amount cannot be more than Two Lakh",
        });
      }
    } else {
      this.setState({
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
        [e.target.name + "ErrorMsg"]: "",
      });
    }
  };

  handleFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let files = [...this.state.uploadBill];
    for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
    }
    this.setState(
        {
            uploadBill: files,
            uploadBillErrorMsg: "",
            uploadFileName: files.map((item) => item.name),
            uploadFileSize: files.map((item) => item.size),
        },
        () => {}
    );
    reader.addEventListener(
        "load",
        () => {
            this.setState({
                billPreview: reader.result,
            });
        },
        false
    );
    if (file) {
        reader.readAsDataURL(file);
    }
};

  handleClaimSubmit = (event) => {
    event.preventDefault();
    let {
      title,
      uploadBill,
      amount,
      expenseDescription,
      expenseDate,
      projectManagerId,
      reimbursementType,
      currencyType,
    } = this.state;
    if (handleValidation(this.state, this.setState.bind(this))) {
      this.setState({ miniLoader: true });
      let params = new FormData();
      params.append("title", title);
      uploadBill.map((bill) => {
        params.append("uploadBill", bill);
      });

      params.append("amount", amount);
      params.append("expenseDescription", expenseDescription);
      params.append("expenseDate", expenseDate);
      params.append("projectManager", projectManagerId);
      params.append("reimburseType", +reimbursementType);
      params.append("currency", currencyType);

      claimReimbursement(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({ miniLoader: true });
          setTimeout(() => (window.location.href = "/reimbursement"), 1000);
          localStorage.setItem("claimReimbursementMessage", true);
        }
      });
      this.setState({ resMsg: false });
    }
  };

  handleDeleteIconClick = (index) => {
    this.setState({
      uploadBill: this.state.uploadBill.filter(
        (item, itemIndex) => itemIndex !== index
      ),
      uploadFileName: this.state.uploadFileName.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    });
  };
  getEmployeeListByManager = () => {
    getEmployeeListByManager("").then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          projectManager: res.data.responseData.employeesList.employeeId,
          employeeList: res.data.responseData.employeesList,
        });
      }
    });
  };

  componentDidMount() {
    this.getEmployeeListByManager();
  }
  handleChangeAssignedToChange = (e) => {
    this.setState({ projectManagerId: e, projectManagerNameErMsg: "" });
  };

  handleChangeReimbursementType = (e) => {
    this.setState({ reimbursementType: e, reimbursementTypeErrMsg: "" });
  };

  handleChangeCurrencyType = (e) => {
    this.setState({ currencyType: e, currencyTypeErrMsg: "" });
  };

  handleEmployeeSearch = (e) => {
    console.log("e : ", e);
  };
  onChange = (date) => this.setState({ date });
  render() {
    let {
      miniLoader,
      loader,
      title,
      uploadFileName,
      expenseDescription,
      amount,
      expenseDescriptionErrorMsg,
      titleErrorMsg,
      amountErrorMsg,
      expenseDateErrorMsg,
      uploadBillErrorMsg,
      uploadBill,
      resMsg,
      employeeList,
      projectManagerId,
      projectManagerNameErMsg,
      reimbursementType,
      reimbursementTypeErrMsg,
      currencyType,
      currencyTypeErrMsg,
    } = this.state;
    return (
      <>
        {miniLoader ? <div className="cover-spin"></div> : ""}
        {resMsg ? (
          <div className="alert alert-success cm_top_fix">
            Reimbursement Claimed Successfully
          </div>
        ) : (
          ""
        )}
        <div
          className={`body_container cm_bold cm_claim ${
            miniLoader ? "claim_reimbursement" : ""
          }`}
        >
          {loader && <Loader />}

          <div className="cm_breadcrumb">
            <ul>
              <li>
                <Link to={url}>REIMBURSEMENT</Link>
                <div className="cm_breadcrumb_arrow"></div>
              </li>
              <li>CLAIMS REIMBURSEMENT</li>
            </ul>
          </div>

          <h4 className="mb-4 cm_bold">Reimbursement Claims</h4>
          <p>
            :-If Employee working late after office hrs. he or she have a
            pre-approval from there Team Leads or PMs.
          </p>
          <p>
            :- If employee working late after 8pm he or she can avail food & cab
            facility. (Food will paid by company upto Rs.250/-per person.)
          </p>
          <p>
            :- if employee comes in weekends (Sat, Sun) he or she have a
            pre-approval of there PMs & they can Avail lunch upto 250/- per
            person.
          </p>
          <p className="mb-4">
            :- You can claim your Reimbursement of previous months. Your claim
            will be credited in the next month.
          </p>

          <form onSubmit={this.handleClaimSubmit}>
            <div className="row">
              <div className="col-md-10 col-sm-12">
                <div className="row">
                  <div
                    className={`form-group col-md-6 col-sm-12${
                      reimbursementTypeErrMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="sub">Reimbursement Type</label>
                    <div className="sm_search">
                      <Select
                        value={
                          reimbursementType
                            ? reimbursementType
                            : "Select Reimbursement Type"
                        }
                        defaultValue="Select Reimbursement Type"
                        name="reimbursementType"
                        onChange={(e) => this.handleChangeReimbursementType(e)}
                        disabled={miniLoader}
                      >
                        <Option value="1">Regular </Option>
                        <Option value="2">Tax Saving</Option>
                      </Select>
                    </div>

                    {reimbursementTypeErrMsg ? (
                      <span className="error_msg">
                        {reimbursementTypeErrMsg}
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={`form-group col-md-6 col-sm-12 ${
                      titleErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="sub">Title</label>

                    <input
                      maxLength="30"
                      type="text"
                      className="form-control"
                      name="title"
                      value={title}
                      onChange={this.handleChange}
                    />

                    {titleErrorMsg ? (
                      <span className="error_msg">{titleErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-10 col-sm-12">
                <div className="row">
                  <div
                    className={`form-group col-md-6 col-sm-12${
                      reimbursementTypeErrMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="sub">Currency</label>
                    <div className="sm_search">
                      <Select
                        value={currencyType ? currencyType : "Select Currency"}
                        defaultValue="Select Currency"
                        name="currencyType"
                        onChange={(e) => this.handleChangeCurrencyType(e)}
                        disabled={miniLoader}
                      >
                        {CURRENCY.map((currency) => (
                          <Option key={currency.value} value={currency.value}>
                            {currency.label}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    {currencyTypeErrMsg ? (
                      <span className="error_msg">{currencyTypeErrMsg}</span>
                    ) : null}
                  </div>

                  <div
                    className={`form-group col-md-6 col-sm-12 ${
                      amountErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="sub">Amount</label>
                    <input
                      inputMode="numeric"
                      type="number"
                      // step="any"
                      className="form-control"
                      name="amount"
                      value={amount}
                      onChange={this.handleChange}
                    />
                    {amountErrorMsg ? (
                      <span className="error_msg">{amountErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className={`form-group col-md-10 col-sm-12${
                  expenseDescriptionErrorMsg ? "input_error" : ""
                }`}
              >
                <label className="sub">Expense Description</label>
                <textarea
                  rows="3"
                  cols="50"
                  type="text"
                  className="form-control"
                  name="expenseDescription"
                  maxLength={500}
                  value={expenseDescription}
                  onChange={this.handleChange}
                ></textarea>
                {expenseDescriptionErrorMsg ? (
                  <span className="error_msg">
                    {expenseDescriptionErrorMsg}
                  </span>
                ) : null}
              </div>
              <div className="col-md-10 col-sm-12">
                <div className="row">
                  <div
                    className={`form-group col-md-6 col-sm-12${
                      uploadBillErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="sub" for="upload-files">
                      Upload Bill
                    </label>
                    <span className="form-control cm_hide_upload">
                      <i className="fa fa-upload" aria-hidden="true"></i>
                      <span className="cm_upload_name">Upload File</span>
                      <input
                        type="File"
                        accept=".png, .jpg, .jpeg, .pdf"
                        // type="file"
                        className=""
                        // accept=".png , .jpeg"
                        title=""
                        multiple
                        id="upload-files"
                        onChange={this.handleFileChange}
                      />
                    </span>
                    {uploadBillErrorMsg ? (
                      <span className="error_msg">{uploadBillErrorMsg}</span>
                    ) : null}
                  </div>
                  <div
                    className={`form-group col-md-6 col-sm-12 ${
                      expenseDateErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="sub">Expense Date</label>
                    <DatePicker
                      disabledDate={disabledDate}
                      allowClear={false}
                      className="date-border"
                      placeholder="Select Date"
                      name="expenseDate"
                      onChange={this.handleSelectExpenseDateChange}
                    />
                    {expenseDateErrorMsg ? (
                      <span className="error_msg">{expenseDateErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-12">
                <div className="row">
                  <div className="form-group col-12">
                    {uploadBill ? (
                      <>
                        <label className="sub">Selected Files: </label>
                        <div
                          className="date-border"
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            padding: "10px",
                            overflow: "auto",
                            minHeight: "200px",
                            maxHeight: "500px",
                          }}
                        >
                          {uploadBill.length > 0 ? (
                            uploadBill.map((item, index) => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#edfbff",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  height: "3.5rem",
                                  margin: "5px",
                                }}
                              >
                                {item.name.match(
                                  /\.(jpg|jpeg|png|JPEG|JPG|PNG)$/
                                ) ? (
                                  <img
                                    src={URL.createObjectURL(item)}
                                    className="image-size"
                                    alt="upload"
                                  />
                                ) : item.name.match(
                                    /\.(doc|docx|odt|dotm|dotm|dotx|docm)$/
                                  ) ? (
                                  <div className="image-size">
                                    <DocIcon />
                                  </div>
                                ) : item.name.match(/\.(xlx|xlsx|xls)$/) ? (
                                  <div className="image-size">
                                    <ExcelIcon />
                                  </div>
                                ) : item.name.match(/\.(pdf|PDF)$/) ? (
                                  <div className="image-size">
                                    <PdfIcon />
                                  </div>
                                ) : (
                                  <div className="image-size">
                                    {" "}
                                    <DocIcon />
                                  </div>
                                )}
                                <label style={{ width: "80%" }}>
                                  {item.name}
                                </label>
                                <div
                                  onClick={() =>
                                    this.handleDeleteIconClick(index)
                                  }
                                >
                                  <IoMdClose
                                    style={{
                                      color: "#347bde",
                                      cursor: "pointer",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <label
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              No files selected !
                            </label>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 col-sm-12">
                <div className="row">
                  <div
                    className={`form-group col-md-6 col-sm-12${
                      projectManagerNameErMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="sub">Assigned Manager</label>
                    <div className="sm_search">
                      <Select
                        showSearch
                        name="employeeId"
                        value={
                          projectManagerId
                            ? projectManagerId
                            : "Select Assigned Manager"
                        }
                        onSearch={this.handleEmployeeSearch}
                        optionFilterProp="key"
                        filterOption={(input, option) =>
                          option.key.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={this.handleChangeAssignedToChange}
                      >
                        {!_.isEmpty(employeeList)
                          ? employeeList
                              .filter(
                                (data) =>
                                  data.employeeId !==
                                  localStorage.getItem("employeeId")
                              )
                              .map(
                                (employee) =>
                                  employee.status == 1 && (
                                    <Option
                                      key={`${employee.firstName} ${employee.lastName}`}
                                      value={employee.employeeId}
                                    >
                                      <span>
                                        {employee.firstName +
                                          " " +
                                          employee.lastName}
                                      </span>
                                    </Option>
                                  )
                              )
                          : ""}
                      </Select>
                    </div>

                    {projectManagerNameErMsg ? (
                      <span className="error_msg">
                        {projectManagerNameErMsg}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-11">
                <div className="submit_button_margin mt-1">
                  <button className="btn btn-primary" disabled={miniLoader}>
                    Submit
                  </button>
                  <Link to={url} className="cancel_btn btn btn-default mr-0">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
export default ClaimReimbursement;

import React from 'react'
import moment from 'moment'
import _ from "lodash";
import { dateFormat, statusMap } from "../helper/Constant";
import Pagination from "react-js-pagination";

const ActivityTable = ({ handleSort,createMarkup, sortType, sortField, dailyWorkDetails, pageNo, count, dataCount, tabStatus, handlePageChange, SORT_TYPE_CONSTANTS  }) => {
    return (
        <>
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead className="white bg_blue">
                    <tr>
                        <th>S.No</th>
                        <th>
                            Employee Name
                            <i
                                className={
                                    sortType === SORT_TYPE_CONSTANTS.Asscending &&
                                        sortField === "projectName"
                                        ? "fa fa-sort-alpha-asc ml-2"
                                        : "fa fa-sort-alpha-desc ml-2"
                                }
                                onClick={() => handleSort("empName")}
                            ></i>
                        </th>
                        <th>Ticket Id</th>
                        <th>Task </th>
                        <th>Blocker</th>
                        <th>
                            Date
                            <i
                                className={
                                    sortType === SORT_TYPE_CONSTANTS.Asscending &&
                                        sortField === "projectName"
                                        ? "fa fa-sort-numeric-asc ml-2"
                                        : "fa fa-sort-numeric-desc ml-2"
                                }
                                onClick={() => handleSort("startDate")}
                            ></i>
                        </th>
                        <th>Hour </th>
                        <th>Actual Hour</th>
                        <th>Overtime</th>
                        <th>Updated On</th>
                    </tr>
                </thead>
                {!_.isEmpty(dailyWorkDetails)
                    ? dailyWorkDetails.map((data, i) => (
                        <tbody>
                            <tr key={data.leaveId}>
                                <td>{i + 1 + (pageNo - 1) * count}</td>
                                <td>{data.firstName + " " + data.lastName}</td>
                                <td>{data.ticketId}</td>
                                <td>
                                    {
                                        <div
                                            style={{ color: "black" }}
                                            dangerouslySetInnerHTML={createMarkup(
                                                data.task
                                            )}
                                        ></div>
                                    }
                                </td>
                                <td>
                                    {
                                        <div
                                            style={{ color: "black" }}
                                            dangerouslySetInnerHTML={createMarkup(
                                                data.blocker
                                            )}
                                        ></div>
                                    }
                                </td>
                                <td>
                                    {moment(data.date)
                                        .format(dateFormat)
                                        .substring(0, 10)}
                                </td>
                                <td>
                                    {moment.utc(data.hour * 1000).format("HH.mm")}
                                </td>
                                <td>
                                    {data?.actualHours
                                        ? moment
                                            .utc(data?.actualHours * 1000)
                                            .format("HH.mm")
                                        : "_"}
                                </td>
                                <td>
                                    {data?.overTime
                                        ? moment
                                            .utc(data?.overTime * 1000)
                                            .format("HH.mm")
                                        : "_"}
                                </td>
                                <td>
                                    {moment(data.updated).format('HH:mm') + " " + moment(data.updated).format("DD/MM/YYYY")}
                                </td>
                            </tr>
                        </tbody>
                    ))
                    : ""}
            </table>
            {dailyWorkDetails && dailyWorkDetails.length > 0 ? (
                ""
            ) : (
                <h3 className="text-center">No data available</h3>
            )}
              
        </div>
        { dataCount > 10 && tabStatus == tabStatus && dailyWorkDetails?.length ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={dataCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        ) : (
          ""
        )}
        </>
    )
}

export default ActivityTable
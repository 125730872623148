import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import { addInterviewerByAdmin } from "../../redux/actions/addInterviewreByAdmin";
import DotLoader from "../../hoc/DotLoader";
import _ from "lodash";
import ResponseFilter from "../../ResponseFilter";
import { getEmployeeList } from "../../config/services/employeeServices";

const { Option } = Select;

const AddCandidateModel = ({
    showPanelModal,
    setShowPanelModal,
    addInterviewerByAdmin,
    loading,
    search,
    pageNo,
    count,
    sortType,
    sortField, error
}) => {
    const [interviewerName, setInterviewerName] = useState("");
    const [interviewRound, setInterviewRound] = useState("");
    const [employeeList, setEmployeeList] = useState([])
    const [technicalSkill, setTechnicalSkill] = useState([]);
    const [interviewerNameErrorMessage, setInterewerNameErrorMessage] = useState("")
    const [interviewRoundErrorMessage, setInterviewRoundErrorMessage] = useState("")
    const [technicalSkillErrorMessage, settechnicalSkillErrorMessage] = useState("")
    const [resMsg, setResMsg] = useState(false);
    const [resFailMsg, setFailResMsg] = useState(false);
    const [loader, setLoader] = useState(false)


    const handleCancelButtonClick = () => {
        setInterviewerName("");
        setInterewerNameErrorMessage("");
        setInterviewRound("");
        setInterviewRoundErrorMessage("");
        setTechnicalSkill([]);
        settechnicalSkillErrorMessage("");
        setShowPanelModal(false);
    };

    const handleInterviewerNameValidation = () => {
        let validate = true;
        if (interviewerName === "") {
            validate = false;
        }
        else {
        }
        return validate
    }
    const handleInterviewRoundValidation = () => {
        let validate = true;
        if (interviewRound === "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleTechnicalSkillValidation = () => {
        let validate = true;
        if (technicalSkill.length <= 0) {
            validate = false;
        }
        else {
        }
        return validate
    }
    const handleUploadButtonClick = async () => {
        if (
            handleInterviewerNameValidation(interviewerName) &&
            handleInterviewRoundValidation(interviewRound) &&
            handleTechnicalSkillValidation(technicalSkill)
        ) {
            setLoader(true)
            const data1 = {
                "employeeId": interviewerName,
                "interViewRound": interviewRound,
                "skill": technicalSkill.toString(),

            }
            const query = `search=${search}&pageNo=${pageNo - 1}&count=${count}&sortType=${sortType}&sortField=${sortField}`;
            await addInterviewerByAdmin(data1, query, setShowPanelModal, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick);
            // handleCancelButtonClick();
        }
        else {
        }

        setTimeout(() => setResMsg(false), 4000)
        setTimeout(() => setFailResMsg(false), 4000)
    };
    const handleInterviewRoundChange = (param) => {
        setInterviewRound(param);
        setInterviewRoundErrorMessage("");

    };

    const handleChangeEmp = e => {
        setInterviewerName(e)
        setInterewerNameErrorMessage("")
    }

    const handleTechnicalSkillChange = (param) => {
        setTechnicalSkill(param);
        settechnicalSkillErrorMessage("")
    };
    const onSubmitClick = () => {
        handleUploadButtonClick();
        if (handleInterviewerNameValidation(interviewerName) == "") {
            setInterewerNameErrorMessage("Please Enter Interviewer Name");
        }
        else {
            setInterewerNameErrorMessage("")
        }
        if (handleInterviewRoundValidation(interviewRound) == "") {
            setInterviewRoundErrorMessage("Please Enter Interviewer Level");
        }
        else {
            setInterviewRoundErrorMessage("")
        }
        if (handleTechnicalSkillValidation(technicalSkill) == "") {
            settechnicalSkillErrorMessage("Please Enter Technical Skill");
        }
        else {
            settechnicalSkillErrorMessage("")
        }

    };

    useEffect(() => {
        let params = `status=1`;
        getEmployeeList(params).then((res) => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                // setLoader(false);
                setEmployeeList(resData.employeesList)
            }
        })
    }, [])



    return (
        <AntModal
            centered
            className="attend_modal"
            footer={false}
            visible={showPanelModal}
            onCancel={handleCancelButtonClick}
        >
            <Modal.Body>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmitClick();
                    }}
                >
                    <h3 className="mb-4">Add Interview Panel</h3>
                    {
                        resMsg ?
                            <div className="alert alert-success cm_top_fix"> Interviewer Added Successfully </div> : ''
                    }
                    {
                        resFailMsg ?
                            <div className="alert alert-danger cm_top_fix">{error} </div> : ''
                    }
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Interviewer Name</label>
                            <Select
                                showSearch
                                value={interviewerName ? interviewerName : "Employee Name"}
                                onChange={(e) => handleChangeEmp(e)}
                                optionFilterProp="key"
                                filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                            >
                                {!_.isEmpty(employeeList)
                                    ? employeeList.sort(function (a, b) {
                                        if (a.firstName < b.firstName) { return -1; }
                                        if (a.firstName > b.firstName) { return 1; }
                                        return 0;
                                    }).map(
                                        (employee) =>
                                            employee.status == 1 && (
                                                <Option
                                                    key={`${employee.firstName} ${employee.lastName}`}
                                                    value={employee.employeeId}
                                                >
                                                    <span>
                                                        {employee.firstName} {employee.lastName} ({employee.empId})
                                                    </span>
                                                </Option>
                                            )
                                    )
                                    : ""}
                            </Select>
                            <span className="errorText">{interviewerNameErrorMessage}</span>

                        </div>

                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Interview Level</label>
                            <Select
                                value={interviewRound ? interviewRound : "Select Interview Level"}
                                onChange={(e) => handleInterviewRoundChange(e)}
                                isSearchable={true}
                            >
                                <Option value={1}>Level One</Option>
                                <Option value={2}>Level Two</Option>
                                <Option value={3}>Level Three</Option>
                            </Select>
                            <span className="errorText">{interviewRoundErrorMessage}</span>

                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label className="cm_bold">Technical Skills</label>
                            <Select
                                mode="multiple"
                                placeholder="Select Skill"
                                value={technicalSkill}
                                onChange={(e) => handleTechnicalSkillChange(e)}
                                isSearchable={true}
                            >
                                <Option value={1}>React.JS</Option>
                                <Option value={2}>Node.Js</Option>
                                <Option value={3}>React Native</Option>
                                <Option value={4}>Flutter</Option>
                                <Option value={5}>Android</Option>
                                <Option value={6}>IOS</Option>
                                <Option value={7}>UI</Option>
                                <Option value={8}>UX</Option>
                                <Option value={9}>HR Manager</Option>
                                <Option value={10}>Quality Analyst</Option>
                                <Option value={11}>Sales And Marketing</Option>
                                <Option value={12}>Python</Option>
                                <Option value={13}>R.O.R</Option>
                                <Option value={14}>Next.Js</Option>
                                <Option value={15}>Nest.js</Option>
                                <Option value={16}>Accounts</Option>
                                <Option value={17}>Devops</Option>
                                <Option value={18}>Graphic Design And Animation</Option>
                                <Option value={19}>Angular</Option>
                                <Option value={20}>MERN</Option>
                                <Option value={21}>MEAN</Option>
                                <Option value={22}>KOTLIN</Option>
                                <Option value={23}>JAVA</Option>
                                <Option value={24}>Vue.js</Option>
                                <Option value={25}>Drupal</Option>
                                <Option value={26}>Blockchain</Option>
                                <Option value={27}>AI</Option>
                                <Option value={28}>ML</Option>
                                <Option value={29}>C</Option>
                                <Option value={30}>C++</Option>
                                <Option value={31}>C#</Option>
                                <Option value={32}>.Net</Option>
                                <Option value={33}>PHP</Option>
                                <Option value={34}>Wordpress</Option>
                                <Option value={35}>SQL</Option>
                                <Option value={36}>Git</Option>
                            </Select>
                            <span className="errorText">{technicalSkillErrorMessage}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="addCandidateButtonsDiv">
                            <button
                                onClick={handleCancelButtonClick}
                                type="button"
                                className="cancel_btn btn  buttonWidth"
                            >
                                Cancel
                            </button>
                            {loader ? <DotLoader /> :
                                <button className="btn btn-primary  buttonWidth" type="submit">
                                    Submit
                                </button>}
                        </div>
                    </div>
                </form >
            </Modal.Body >
        </AntModal >
    );
};
const mapStateToProps = (state) => {
    return {
        loading: state.addCandidateByAdmin.loading,
        error: state.addInterviewerByAdmin?.error?.error?.errorMessage == "Interviewer already exists" ? state.addInterviewerByAdmin?.error?.error?.errorMessage : "Please Try Again"
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addInterviewerByAdmin: (data1, query, setShowPanelModal, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick) => {
            dispatch(addInterviewerByAdmin(data1, query, setShowPanelModal, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCandidateModel);

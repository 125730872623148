import React, { useEffect, useReducer } from "react";
import "./Privacy.css";
import {
  Toastify,
  handleTextChange,
  isAdmin,
  reducer,
  type,
} from "../helper/Constant";
import PolicyCard from "./PolicyUtils/PolicyCard";
import AddEditPolicyModal from "./PrivacyModal/AddEditPolicyModal";
import { Loader } from "../svg";
import { getPrivacyPolicy } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const initialState = {
  openAddEditPrivacyModal: false,
  openDeletePrivacyModal: false,
  uploadFile: "",
  uploadFileErrorMsg: "",
  title: "",
  titleErrorMsg: "",
  toAddOrEdit: "",
  resMsg: false,
  successMessage: "",
  resFailMsg: false,
  responseMessage: "",
  loader: "",
  policyList: [],
};

const Policies = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    openAddEditPrivacyModal,
    uploadFile,
    uploadFileErrorMsg,
    title,
    titleErrorMsg,
    toAddOrEdit,
    resMsg,
    successMessage,
    resFailMsg,
    responseMessage,
    loader,
    policyList,
  } = state;
  const handleOpenAddEditModal = (e) => {
    dispatch({
      type: type,
      payload: {
        toAddOrEdit: e,
        openAddEditPrivacyModal: true,
      },
    });
  };

  const getPolicyList = () => {
    getPrivacyPolicy()
      .then((res) => {
        dispatch({ type: type, payload: { loader: false } });
        const { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch({
            type: type,
            payload: {
              policyList: resData.list,
            },
          });
          closeModal();
        }
      })
      .catch((error) => {
        dispatch({
          type: type,
          payload: {
            resFailMsg: true,
            responseMessage: error,
            loader: false,
          },
        });
      });
  };

  useEffect(() => {
    getPolicyList();
  }, []);

  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        openAddEditPrivacyModal: false,
        openDeletePrivacyModal: false,
        uploadFile: "",
        uploadFileErrorMsg: "",
        title: "",
        titleErrorMsg: "",
        toAddOrEdit: "",
        loader: "",
      },
    });
  };
  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <Toastify
        resMsg={resMsg}
        resMsgFail={resFailMsg}
        text={successMessage}
        responseMessage={responseMessage}
      />
      <h3 className="mb-4">Policy</h3>
      {(isAdmin === "4" || isAdmin === "1") && (
        <div className="d-flex justify-content-end">
          <button
            className="organization-btn ml-4"
            onClick={() => handleOpenAddEditModal("Add")}
          >
            Add
          </button>
        </div>
      )}
      {!policyList.length && (
        <h3 className="text-center mt-4">No data available</h3>
      )}
      <PolicyCard
        policyList={policyList}
        getPolicyList={getPolicyList}
        title={title}
        titleErrorMsg={titleErrorMsg}
        handleChange={(e) => handleTextChange({ e, dispatch })}
        dispatchProps={dispatch}
        uploadFile={uploadFile}
        uploadFileErrorMsg={uploadFileErrorMsg}
      />
      <AddEditPolicyModal
        openAddEditPrivacyModal={openAddEditPrivacyModal}
        toAddOrEdit={toAddOrEdit}
        closeModal={closeModal}
        title={title}
        titleErrorMsg={titleErrorMsg}
        handleChange={(e) => handleTextChange({ e, dispatch })}
        dispatch={dispatch}
        uploadFile={uploadFile}
        uploadFileErrorMsg={uploadFileErrorMsg}
        getPolicyList={getPolicyList}
      />
    </div>
  );
};

export default Policies;

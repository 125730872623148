import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";


import AddCandidateModel from "./AddCandidateModel";
import EditCandidateModel from "./EditCandidateModel"
import DotLoader from "../../hoc/DotLoader";
import { downloadIcon } from "../../asset";
import { deleteCandidateByAdmin, deleteInterviewerByAdmin, getInterviewList, assignInterviewCandidateByAdmin } from "../../redux/actions";
import { Link, useHistory } from "react-router-dom";
import { SearchField } from "../../hoc";
import styled from "styled-components";
import "./InterviewStyles.css";
import { Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";

import AddInterviewPanelModal from "./AddInterviewPanelModal";
import { CONSTANTS } from "../../config";
import qs from "qs";
import EditInterviewer from "./EditInterviewer";
import { getInterviewerList } from "../../redux/actions/getInterviewerActionList";
import _ from "lodash";
import { exportCandidateFeedbackExcel, exportInterviewCandidateExcel, getAssignCandidate } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import AddFeedBackModal from "./AddFeedBackModal";
import EditFeedBackModal from "./EditFeedBackModal";
import { getCandidateFeedBackAction } from "../../redux/actions/getCandidateFeedBackAction";
import moment, { relativeTimeRounding } from "moment";
import { deleteCandidateFeedBackAction } from "../../redux/actions/deleteCandidateFeedBackAction";
import TextEditor from "../../hoc/textEditor/TextEditor";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { createSelectItems, currentMonth } from "../helper/Constant";
import { currentYear } from "../leaves/Constants";
import { useDebounce } from "../attendance/Constants";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const format = "HH:mm";

const InterviewList = ({ interviewList, getInterviewList, totalCandidate, deleteCandidateByAdmin, getInterviewerList, interviewerList, deleteInterviewerByAdmin, deleteInterviewer, dataCount, assignInterviewCandidateByAdmin, error, getCandidateFeedBackAction, candidateFeedBackData, totalCandidateFeedBackCount, deleteCandidateFeedBackAction }) => {
  const [showModal, setShowModal] = useState(false);
  const [showPanelModal, setShowPanelModal] = useState(false);
  const [showEditModal, setEditShowModal] = useState(false);
  const [showEditInterview, setShowEditInterView] = useState(false);
  const [updateCondidatedata, setUpdateCondidatedata] = useState({});
  const [updateInterviewer, setUpdateInterviewer] = useState({});

  const [search, setsearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [interviewStatus, setInterviewStatus] = useState("");
  const [resDeleteMsg, setResDeleteMsg] = useState(false);
  const [resFailDeleteMsg, setFailResDeleteMsg] = useState(false);
  const [tabStatus, setTabStatus] = useState("");
  const [sortType, setSortType] = useState(2);
  const [sortField, setSortField] = useState("created");
  const [assign, setAssign] = useState(false)
  const [date, setDate] = useState("")
  const [dateErrorMsg, setDateErrorMsg] = useState("")
  const [candidateId, setCandidateId] = useState("");
  const [candidateIdErrorMsg, setCandidateIdErrorMsg] = useState("");
  const [interviewRound, setInterviewRound] = useState("")
  const [interviewRoundErrorMsg, setInterviewRoundErrorMsg] = useState("")
  const [assignCandidateList, setAssignCandidateList] = useState([])
  const [interviewerId, setInterviewerId] = useState("")
  const [resMsg, setResMsg] = useState(false);
  const [failResMsg, setFailResMsg] = useState(false)
  const [loader, setLoader] = useState(false);
  const [openFeedBackPanel, setOpenFeedBackPanel] = useState(false)
  const [editOpenFeedBackPanel, setEditOpenFeedBackPanel] = useState(false)
  const [updateFeedBack, setUpdateFeedBack] = useState({})
  const [openCandidateFeedBackModal, setOpenCandidateFeedBackModal] = useState(false)
  const [showFeedBack, setShowFeedBack] = useState("")
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [jobDesriptionErrorMsg, setJobDesriptionErrorMsg] = useState("")
  const [wantSentMail, setWantSentMail] = useState('')
  const [wantSentMailErrorMsg, setWantSentMailErrorMsg] = useState('')
  const [candidateFeedBackStatus, setCandidateFeedBackStatus] = useState("")
  const [searchYear, setSearchYear] = useState("")
  const [searchMonth, setSearchMonth] = useState("")
  const [failResponseMessage, setFailResponseMessage] = useState(false)
  const history = useHistory();
  const { Option } = Select;

  const count = 10;

  const debouncedSearchTerm = useDebounce(search, 2000);


  useEffect(() => {
    getData();
  }, [pageNo, debouncedSearchTerm, interviewStatus, tabStatus, sortType, candidateFeedBackStatus, searchMonth, searchYear]);
  useEffect(() => {
    getInterviewerData();
    getCandidateFeedBackData();
  }, [pageNo, debouncedSearchTerm, interviewStatus, tabStatus, sortType, candidateFeedBackStatus, searchMonth, searchYear]);
  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    setSortType(filtersFromParams.sortType ? filtersFromParams.sortType : SORT_TYPE_CONSTANTS.Descending)
    setSortField(filtersFromParams.sortField ? filtersFromParams.sortField : "created")
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)
    setsearch(filtersFromParams.search ? filtersFromParams.search : "")
    setTabStatus(filtersFromParams.tabStatus ? Number(filtersFromParams.tabStatus) : "")
    setInterviewStatus(filtersFromParams.status ? Number(filtersFromParams.status) : "")
    setCandidateFeedBackStatus(filtersFromParams.candidateFeedBackStatus ? Number(filtersFromParams.candidateFeedBackStatus) : "")
    setSearchMonth(filtersFromParams.searchMonth ? filtersFromParams.searchMonth[0] : "")
    setSearchYear(filtersFromParams.searchYear ? filtersFromParams.searchYear[0] : "")

  }, [history.location.search]);

  const getData = async () => {
    const query = `search=${search}&pageNo=${pageNo}&count=${count}&status=${interviewStatus}&sortType=${sortType}&sortField=${sortField}&searchYear=${searchYear}&searchMonth=${searchMonth}`;
    history.push(`interview?search=${search}&pageNo=${pageNo}&count=${count}&status=${interviewStatus}&tabStatus=${tabStatus}&sortType=${sortType}&sortField=${sortField}&candidateFeedBackStatus=${candidateFeedBackStatus}&searchYear=${searchYear}&searchMonth=${searchMonth}`)
    await getInterviewList(query);
  };

  const getCandidateFeedBackData = async () => {

    const query = `search=${search}&pageNo=${pageNo}&count=${count}&sortType=${sortType}&sortField=${sortField}&status=${candidateFeedBackStatus}&searchYear=${searchYear}&searchMonth=${searchMonth}`;
    history.push(`interview?search=${search}&pageNo=${pageNo}&count=${count}&status=${interviewStatus}&tabStatus=${tabStatus}&sortType=${sortType}&sortField=${sortField}&candidateFeedBackStatus=${candidateFeedBackStatus}&searchYear=${searchYear}&searchMonth=${searchMonth}&searchYear=${searchYear}&searchMonth=${searchMonth}`)
    await getCandidateFeedBackAction(query);
  }
  const getInterviewerData = () => {
    const query = `search=${search}&pageNo=${pageNo - 1}&count=${count}&status=${interviewStatus}&sortType=${sortType}&sortField=${sortField}`;
    history.push(`interview?search=${search}&pageNo=${pageNo}&count=${count}&status=${interviewStatus}&tabStatus=${tabStatus}&sortType=${sortType}&sortField=${sortField}&candidateFeedBackStatus=${candidateFeedBackStatus}&searchYear=${searchYear}&searchMonth=${searchMonth}`)
    getInterviewerList(query);
  };

  const handleInterviewStatusChange = async (interviewSta) => {
    setInterviewStatus(interviewSta);
  };
  const handleCandidateStatusChange = async (candidateStatus) => {
    setCandidateFeedBackStatus(candidateStatus);
  };
  const handleInterviewRoundStatusChange = async (e) => {
    setInterviewRound(e);
    setInterviewRoundErrorMsg("")

  };


  const handleSentMailChange = async (e) => {
    setWantSentMail(e);
    setWantSentMailErrorMsg("")

  };

  const handleOnAddResumeClick = () => {
    setShowModal(true);
  };

  const handlePanel = () => {
    setShowPanelModal(true);
  };
  const handleOnEditResumeClick = (element, e) => {
    setEditShowModal(true);
    setUpdateCondidatedata(element)
  };

  const handleOnEditInterviewerClick = (element, e) => {
    setShowEditInterView(true);
    setUpdateInterviewer(element)
  };
  const handleDeleteCandidate = async (element) => {
    const data = {
      data: {
        interviewId: element._id,
      },
    };
    const query = `search=${search}&pageNo=${pageNo}&count=${count}&status=${interviewStatus}&sortType=${sortType}&sortField=${sortField}`;
    await deleteCandidateByAdmin(data, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage);
    // setResMsg(true)
    // setInterviewStatus("")
    setTimeout(() => setResDeleteMsg(false), 4000)
    setTimeout(() => setFailResDeleteMsg(false), 4000)
  }
  const handleDeleteInterviewer = (element) => {

    const data = {
      data: {
        interviewId: element.interviewerid,
      },
    };
    const query = `search=${search}&pageNo=${pageNo - 1}&count=${count}&status=${interviewStatus}&sortType=${sortType}&sortField=${sortField}`;
    deleteInterviewerByAdmin(data, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage);
    // setResMsg(true)
    // setInterviewStatus("")
    setTimeout(() => setResDeleteMsg(false), 4000)
    setTimeout(() => setFailResDeleteMsg(false), 4000)
  }

  const handleDeleteCandidateFeedBack = (element) => {

    const data = {
      data: {
        clientId: element._id,
      },
    };
    const query = `search=${search}&pageNo=${pageNo}&count=${count}&status=${candidateFeedBackStatus}&sortType=${sortType}&sortField=${sortField}`;
    deleteCandidateFeedBackAction(data, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage);
    setTimeout(() => setResDeleteMsg(false), 4000)
    setTimeout(() => setFailResDeleteMsg(false), 4000)
  }

  const handleOnPageChangeClick = (pageNum) => {
    setPageNo(pageNum);
  };
  const operatingSystemType = (type) => {
    switch (type) {
      case 3:
        return "Rejected";
        break;
      case 1:
        return "Selected";
        break;
      case 2:
        return "Pending";
        break;
    }
  };

  const skillType = (type) => {
    switch (type) {
      case 1:
        return "React Js";
        break;
      case 2:
        return "Node Js";
        break;
      case 3:
        return "React Native";
        break;
      case 4:
        return "Flutter";
        break;
      case 5:
        return "Android";
        break;
      case 6:
        return "IOS";
        break;
      case 7:
        return "UI";
        break;
      case 8:
        return "UX";
        break;
      case 9:
        return "HR Manager";
        break;
      case 10:
        return "Quality Analyst";
        break;
      case 11:
        return "Sales And Marketing";
        break;
      case 12:
        return "Pyhton";
        break;

      case 13:
        return "ROR";
        break;
      case 14:
        return "Next Js";
        break;
      case 15:
        return "Nest Js";
        break;
      case 16:
        return "Accounts";
        break;
      case 17:
        return "Devops";
        break;
      case 18:
        return "Graphic Designer And Animation";
        break;


      case 19:
        return "Angular";
        break;
      case 20:
        return "MERN";
        break;
      case 21:
        return "MEAN";
        break;
      case 22:
        return "KOTLIN";
        break;
      case 23:
        return "Java";
        break;
      case 24:
        return "Vue.js";
        break;


      case 25:
        return "Drupal";
        break;
      case 26:
        return "Blockchain";
        break;
      case 27:
        return "AI";
        break;
      case 28:
        return "ML";
        break;
      case 29:
        return "C";
        break;
      case 30:
        return "C++";
        break;


      case 31:
        return "C#";
        break;
      case 32:
        return ".Net";
        break;
      case 33:
        return "PHP";
        break;
      case 34:
        return "Wordpress";
        break;
      case 35:
        return "SQL";
        break;
      case 36:
        return "Git";
        break;
    }
  };



  const colorType = type => {
    switch (type) {
      case 3: return 'red';
        break;
      case 1: return 'text-success';
        break;
      case 2: return 'yellow';
        break;


    }
  }

  const candidateType = type => {
    switch (type) {
      case "": return 'All';
        break;
      case 1: return 'Selected';
        break;
      case 2: return 'Pending';
        break;
      case 3: return ' Rejected'
        break;
    }
  }

  const interviewerType = type => {
    switch (type) {
      case "": return "All"
        break;
      case 1: return 'Avaliable';
        break;
      case 2: return 'Unavaliable';
        break;
    }
  }

  const handleFilter = (e) => {
    setTabStatus(e);
    setSortField("created")
    setPageNo(1);
    setCandidateFeedBackStatus("")
    setInterviewStatus("")
    setSearchMonth("")
    setSearchYear("")
    
    
  }

  const handleSort = (e) => {
    setSortField(e);
    if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
      setSortType(SORT_TYPE_CONSTANTS.Descending);

    }
    else {
      setSortType(SORT_TYPE_CONSTANTS.Asscending)

    }
  }

  const handleAssign = (element) => {
    setInterviewerId(element.employeeId)
    setAssign(!assign)

  }
  const closeModal = () => {
    setAssign(false)
    setOpenCandidateFeedBackModal(false)
    setDate("")
    setDateErrorMsg("")
    setCandidateId("")
    setCandidateIdErrorMsg("")
    setInterviewRound("")
    setInterviewRoundErrorMsg("")
    setJobDesriptionErrorMsg("")
    setEditorState(EditorState.createEmpty())
    setWantSentMail("")
    setWantSentMailErrorMsg("")
  }


  const handleDateChange = (date, dateString) => {
    setDate(date.target.value);
    setDateErrorMsg("")
  }


  const handleSelectCandidateChange = (e) => {
    const Emp = assignCandidateList.filter((ele) => {
      return ele._id === e;
    });
    setCandidateId(e)
    setCandidateIdErrorMsg("")
  }


  const handleCandidateSearch = (e) => {
    console.log("e : ", e);
  }

  const handleDateValidation = () => {
    let validate = true;
    if (date === "") {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleSetMailValidation = () => {
    let validate = true;
    if (wantSentMail === "") {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleInterviewRoundValidation = () => {
    let validate = true;
    if (interviewRound === "") {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleCandidateValidation = () => {
    let validate = true;
    if (candidateId === "") {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleJobDescriptionValidation = () => {
    let k = convertToRaw(editorState.getCurrentContent())

    let validate = true
    if (k.blocks.every(b => b.text.trim() === '')) {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleUploadButtonClick = () => {
    if (
      handleCandidateValidation(candidateId) &&
      handleDateValidation(date) &&
      handleInterviewRoundValidation(interviewRound) &&
      handleJobDescriptionValidation(editorState) &&
      handleSetMailValidation(wantSentMail)
    ) {
      setLoader(true)
      const data1 = {
        "employeeId": interviewerId,
        "startDate": Date.parse(new Date(date)),
        "interviewRound": interviewRound,
        "interviewId": candidateId,
        "jobDescription": draftToHtml(convertToRaw(editorState.getCurrentContent())),
        "mailSent": wantSentMail
      }
      const query = `search=${search}&pageNo=${pageNo}&count=${count}`
      assignInterviewCandidateByAdmin(data1, query, setAssign, setResMsg, setFailResMsg, setLoader, closeModal)
    }
    else {
    }

    setTimeout(() => setResMsg(false), 4000)
    setTimeout(() => setFailResMsg(false), 4000)
  };

  const onSubmitClick = (e) => {
    handleUploadButtonClick();
    let k = convertToRaw(editorState.getCurrentContent())

    if (handleDateValidation(date) == "") {
      setDateErrorMsg("Please Select Date")
    }
    else {
      setDateErrorMsg("")
    }
    if (handleCandidateValidation(candidateId) == "") {
      setCandidateIdErrorMsg("Please Select Candidate ")
    }
    else {
      setCandidateIdErrorMsg("")
    }
    if (handleInterviewRoundValidation(interviewRound) == "") {
      setInterviewRoundErrorMsg("Please Select Interview Round")
    }
    else {
      setInterviewRoundErrorMsg("")
    }
    if (handleJobDescriptionValidation(k.blocks.every(b => b.text.trim())) == '') {
      setJobDesriptionErrorMsg("Please Enter Job Description")
    }
    else {

      setJobDesriptionErrorMsg("")
    }

    if (handleSetMailValidation(wantSentMail) == "") {
      setWantSentMailErrorMsg("Please Select value")
    }
    else {
      setWantSentMailErrorMsg("")
    }

  };

  useEffect(() => {
    // let params = `status=1`;
    getAssignCandidate().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        // setLoader(false);
        setAssignCandidateList(resData.result)
      }
    })
  }, [pageNo, search, interviewStatus, tabStatus, sortType])

  const handleInterviewerDetail = (e) => {
    window.location.href = `/interview/InterviewerStatus/${e.employeeId}`
    localStorage.setItem("interviewerId", e.employeeId)
    localStorage.setItem("interviewerName", e.firstName)
  }

  const handleFeedBackPanel = () => {
    setOpenFeedBackPanel(true)
  }
  const handleEditFeedBackPanel = (e) => {
    setEditOpenFeedBackPanel(true)
    setUpdateFeedBack(e)
  }

  const handleOpenFeedBackModal = (e) => {
    setOpenCandidateFeedBackModal(true)
    setShowFeedBack(e.feedBack)
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    setJobDesriptionErrorMsg("")
  }

  const handleMonth = (month) => {
    if (month === undefined) {
      setSearchMonth("")
      setSearchYear("")
      setPageNo(1)
    }
    else {
      setSearchMonth(month)
      setSearchYear(searchYear ? searchYear : currentYear)
      setPageNo(1)
    }
  }

  const handleYear = (year) => {
    if (year === undefined) {
      setSearchMonth("")
      setSearchYear("")
      setPageNo(1)
    }
    else {
      setSearchMonth(currentMonth)
      setSearchYear(year)
      setPageNo(1)
    }
  }

  const handleInterviewCandidateExcel = () => {
    const query = `search=${search}&pageNo=${pageNo}&count=${count}&status=${interviewStatus}&sortType=${sortType}&sortField=${sortField}&searchYear=${searchYear}&searchMonth=${searchMonth}`;
    exportInterviewCandidateExcel(query).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        window.location.href = resData.listLink
      }
    })
  }
  const handleCandidateFeedbackExcel = () => {
    const query = `search=${search}&pageNo=${pageNo}&count=${count}&sortType=${sortType}&sortField=${sortField}&status=${candidateFeedBackStatus}&searchYear=${searchYear}&searchMonth=${searchMonth}`;
    exportCandidateFeedbackExcel(query).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        window.location.href = resData.excelLink
      }
    })
  }
  return (
    <div className="body_container cm_bold marginTop">
      {
        resDeleteMsg ?
          <div className="alert alert-success cm_top_fix"> Candidate Deleted Successfully </div> : ''
      }

      {
        resFailDeleteMsg ?
          <div className="alert alert-danger cm_top_fix"> {failResponseMessage} </div> : ''
      }

      {
        resMsg ?
          <div className="alert alert-success cm_top_fix"> Interview Assign Successfully </div> : ''
      }

      {
        failResMsg ?
          <div className="alert alert-danger cm_top_fix">{error} </div> : ''
      }
      <AddCandidateModel
        showModal={showModal}
        setShowModal={setShowModal}
        search={search}
        pageNo={pageNo}
        count={count}
        sortType={sortType}
        sortField={sortField}
      />
      <AddInterviewPanelModal
        showPanelModal={showPanelModal}
        setShowPanelModal={setShowPanelModal}
        search={search}
        pageNo={pageNo}
        count={count}
        sortType={sortType}
        sortField={sortField}
      />
      <EditCandidateModel
        showEditModal={showEditModal}
        setEditShowModal={setEditShowModal}
        search={search}
        pageNo={pageNo}
        count={count}
        sortType={sortType}
        sortField={sortField}
        state={updateCondidatedata}
      />

      <EditInterviewer
        showEditInterview={showEditInterview}
        setShowEditInterview={setShowEditInterView}
        search={search}
        pageNo={pageNo}
        count={count}
        sortType={sortType}
        sortField={sortField}
        state={updateInterviewer}
      />

      <AddFeedBackModal
        openFeedBackPanel={openFeedBackPanel}
        search={search}
        pageNo={pageNo}
        count={count}
        setOpenFeedBackPanel={setOpenFeedBackPanel}
        sortType={sortType}
        sortField={sortField}
      />
      <EditFeedBackModal
        editOpenFeedBackPanel={editOpenFeedBackPanel}
        setEditOpenFeedBackPanel={setEditOpenFeedBackPanel}
        search={search}
        pageNo={pageNo}
        count={count}
        sortType={sortType}
        sortField={sortField}
        state={updateFeedBack}
      />

      <div className="tableDescDiv row">
        <h4> Candidate(s) List :</h4>
      </div>

        <div className="filters justify-content-end mb-3">
          {tabStatus == "" ? <button onClick={handleOnAddResumeClick} className="btn btn-primary">+ Add Candidate</button>
            : tabStatus == 1 ? <button onClick={handlePanel} className="btn btn-primary">+ Add Interview Panel</button> : <button onClick={handleFeedBackPanel} className="btn btn-primary">+ Add Feedback</button>}


          {tabStatus == "" ? <button className="btn btn-primary" onClick={handleInterviewCandidateExcel}>Export Excel</button> : tabStatus == 2 ? <button className="btn btn-primary" onClick={handleCandidateFeedbackExcel}>Export Excel</button> : ""}
        </div>
      <div className="headerDiv" mb-2>
        <SearchField
          inputValue={search.trim()}
          handleInputChange={setsearch}
          handlePageChange={setPageNo}
          handleClearSearchClick={setsearch}
        />
        {tabStatus == "" || tabStatus == 2 ? "" : <Select
          onChange={handleInterviewStatusChange}
          className="mb-2"
          style={{ width: 200 }}
          value={interviewerType(interviewStatus)}
        >
          <Option value={""}>All</Option>
          <Option value={1}>Available</Option>
          <Option value={2}>Unavailable</Option>
        </Select>}

      </div>
      <div className="filters justify-content-end mb-2">
        {tabStatus == 2 ? <Select
          onChange={handleCandidateStatusChange}
          className="select_year_cal"
          style={{ width: 200 }}
          value={candidateType(candidateFeedBackStatus)}

        >
          <Option value={""}>All</Option>
          <Option value={1}>Selected</Option>
          <Option value={2}>Pending</Option>
          <Option value={3}>Rejected</Option>

        </Select> : ""}
        {tabStatus == 1 ? "" :
          <>
            <Select
              className="select_year_cal"
              value={searchMonth ? searchMonth : "Month"}
              defaultValue="Select Month"
              name="searchMonth"
              onChange={(e) => handleMonth(e)}
              allowClear={searchMonth ? true : false}
            >
              <Option value="1">January</Option>
              <Option value="2">February</Option>
              <Option value="3">March</Option>
              <Option value="4">April</Option>
              <Option value="5">May</Option>
              <Option value="6">June</Option>
              <Option value="7">July</Option>
              <Option value="8">August</Option>
              <Option value="9">September</Option>
              <Option value="10">October</Option>
              <Option value="11">November</Option>
              <Option value="12">December</Option>
            </Select>

            <Select
              className="select_year_cal"
              value={searchYear ? searchYear : "Select Year"}
              defaultValue="Select Year"
              name="searchYear"
              onChange={handleYear}
              allowClear={searchYear ? true : false}
            >
              {createSelectItems()}
            </Select>
          </>}
      </div>
      <div className="text-left cm_ticket_btn cm_leave_btn" >
        <button className={`btn btn-primary ${tabStatus === "" ? "active" : ""}`}
          onClick={() => handleFilter("")}>
          <span className="tabtext">Candidates Panel</span></button>
        <button className={`btn btn-primary ${tabStatus === 1 ? "active" : ""}`}
          onClick={() => handleFilter(1)}>
          <span className="tabtext">Interviewers Panel</span></button>

        <button className={`btn btn-primary ${tabStatus === 2 ? "active" : ""}`}
          onClick={() => handleFilter(2)}>
          <span className="tabtext">Internal Interviews</span></button>

      </div>

      {
        interviewList && tabStatus == "" ? (
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Name <i
                    className={
                      sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'firstName'
                        ? "fa fa-sort-alpha-asc ml-2"
                        : "fa fa-sort-alpha-desc ml-2"
                    }
                    onClick={() => handleSort("firstName")}
                  ></i></th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">D.O.B </th>
                  <th scope="col">Total Experience</th>
                  <th scope="col">Technical Skill</th>
                  <th scope="col">Position Applied</th>
                  <th scope="col">Notice Period</th>
                  <th scope="col">Added By</th>
                  <th scope="col">R1 Status</th>
                  <th scope="col">R2 Status</th>
                  <th scope="col">R3 Status</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              {interviewList.map((element, index) => {
                return (
                  <tbody>
                    <tr>
                      <td>{index + 1 + (pageNo - 1) * count}</td>
                      <td>
                        <Link
                          to={{
                            pathname: `/interview/candidateDetails/${element._id}`,
                            state: { empId: element._id },
                          }}
                        >
                          {element?.FirstName + " " + element?.LastName}
                        </Link>
                      </td>
                      <td>{element.email}</td>
                      <td>{element.mobile ? element.mobile : "_"}</td>
                      <td>
                        {" "}
                        {new Date(element.dob).toLocaleDateString({
                          hour12: true,
                        })}
                      </td>
                      <td>{element.totalExperience == null || element.totalExperience == 0 ? " Fresher" : `${element.totalExperience} years`}</td>
                      <td>{element.technicalSkill}</td>
                      <td>{element.position}</td>
                      <td>{element.noticePeriod == null || element.noticePeriod == 0 ? "Immediate Joiner" : `${element.noticePeriod} Days`}</td>
                      <td>{element?.addedByFirstName + " " + " " + element?.addedByMiddleName + element?.addedByLastName}</td>
                      {element.scheduleData.map((ele, index) => (
                        <td className={colorType(ele.isClear)}>{operatingSystemType(ele.isClear)}</td>

                      ))}
                      <td>
                        <div className="d-flex justify-content-evenly cm_device">

                          <Link onClick={() => handleOnEditResumeClick(element, index)}>
                            <i className="fa fa-pencil mr-4" aria-hidden="true" title="Edit interview"></i></Link>
                          <Link onClick={() => { handleDeleteCandidate(element) }}>
                            <i className="fa fa-trash-o mr-4 red" title="Delete Candidate"></i>
                          </Link>
                          <a href={element.resume} className="mr-2">
                            <ActionImage
                              src={downloadIcon}
                              alt="downloadIcon"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
            {interviewList && interviewList.length > 0 && tabStatus == "" ? "" :
              <h3 className="text-center">No data available</h3>
            }
          </div>
        ) :
          interviewerList && tabStatus == 1 ? (
            // interviewerList?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="white bg_blue">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col"> Employee ID<i
                      className={
                        sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'empId'
                          ? "fa fa-sort-numeric-asc ml-2"
                          : "fa fa-sort-numeric-desc ml-2"
                      }
                      onClick={() => handleSort("empId")}
                    ></i></th>
                    <th scope="col">Name<i
                      className={
                        sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'firstName'
                          ? "fa fa-sort-alpha-asc ml-2"
                          : "fa fa-sort-alpha-desc ml-2"
                      }
                      onClick={() => handleSort("firstName")}
                    ></i></th>
                    <th scope="col">Email</th>
                    <th scope="col">Interviewer Level</th>
                    <th scope="col"> Technical Skills</th>
                    <th scope="col"> Availability </th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {interviewerList.map((element, index) => {
                  return (
                    <tbody>
                      <tr>
                        <td>{index + 1 + (pageNo - 1) * count}</td>
                        <td>{element.empId}</td>
                        <td className="blue" onClick={() => handleInterviewerDetail(element)}>

                          {element?.firstName + " " + element?.lastName}

                        </td>
                        <td>{element.email}</td>
                        <td>{element.round}</td>
                        <td>{
                          element.skill.map((skill, i) => (
                            <span className="cm_col_dept">
                              {skill ? `${skillType(Number(skill))}` : ''}

                            </span>
                          ))}</td>
                        <td>{element.isAvailable == true ? "Available" : "Unavailable"}</td>
                        <td>
                          <div className="cm_device d-flex justify-content-evenly">

                            <Link onClick={() => handleOnEditInterviewerClick(element, index)}>
                              <i className="fa fa-pencil" aria-hidden="true" title="Edit interviewer"></i></Link>
                            <span onClick={() => handleDeleteInterviewer(element)}>
                              <i className="fa fa-trash-o ml-4 red" title="Delete Interviewer"></i>
                            </span>
                            {element.isAvailable == true ?
                              < span onClick={() => handleAssign(element)}>
                                <i class="fa fa-hand-pointer-o ml-4" aria-hidden="true" title="Assign Candidate"></i>
                              </span> : ""}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
              {interviewerList && interviewerList.length > 0 && tabStatus == 1 ? "" :
                <h3 className="text-center">No data available</h3>
              }
            </div>
          ) :
            candidateFeedBackData && tabStatus == 2 ? (
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="white bg_blue">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col"> Employee ID<i
                        className={
                          sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'empId'
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => handleSort("empId")}
                      ></i></th>
                      <th scope="col">Name<i
                        className={
                          sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'empName'
                            ? "fa fa-sort-alpha-asc ml-2"
                            : "fa fa-sort-alpha-desc ml-2"
                        }
                        onClick={() => handleSort("empName")}
                      ></i></th>
                      <th scope="col">Client Name</th>
                      <th scope="col">Interview Date
                        <i
                          className={
                            sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'interviewDate'
                              ? "fa fa-sort-numeric-asc ml-2"
                              : "fa fa-sort-numeric-desc ml-2"
                          }
                          onClick={() => handleSort("interviewDate")}
                        ></i></th>
                      <th scope="col"> Interview Round</th>
                      <th scope="col">Interview Status</th>
                      <th scope="col">Added by</th>


                      <th scope="col"> Domain </th>
                      <th scope="col"> Feedback </th>


                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {candidateFeedBackData?.map((element, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1 + (pageNo - 1) * count}</td>
                          <td>{element.empId}</td>
                          <td className="blue" onClick={() => handleInterviewerDetail(element)}>

                            {element?.firstName + " " + element.middleName + " " + element?.lastName}

                          </td>
                          <td>{element.companyName}</td>
                          <td>{moment(element.interviewDate).format("DD/MM/YYYY")}</td>
                          <td>{element.interviewRound}</td>
                          <td className={colorType(element.status)}>{operatingSystemType(element.status)}</td>
                          <td>{
                            element?.addedByFirstName + " " + element?.addedByLastName
                          }</td>
                          <td>{element?.domain}</td>
                          <td>
                            <span onClick={() => handleOpenFeedBackModal(element)}>
                              <i className="fa fa-eye blue" title="Feedback"></i>
                            </span>
                          </td>
                          <td>
                            <div className="cm_device d-flex justify-content-evenly">

                              <Link onClick={() => handleEditFeedBackPanel(element, index)}>
                                <i className="fa fa-pencil" aria-hidden="true" title="Edit feedback data"></i></Link>

                              <span onClick={() => handleDeleteCandidateFeedBack(element)}>
                                <i className="fa fa-trash-o ml-4 red" title="Delete feedback data"></i>

                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
                {candidateFeedBackData && candidateFeedBackData.length > 0 && tabStatus == 2 ? "" :
                  <h3 className="text-center">No data available</h3>
                }
              </div>

            ) : (
              <div className="loaderDiv">
                <DotLoader />
              </div>
            )
      }
      {
        totalCandidate > 10 && tabStatus == "" ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={totalCandidate}
            pageRangeDisplayed={3}
            onChange={handleOnPageChangeClick}
          />
        ) : null
      }

      {/* Interviewer Panel */}
      {
        dataCount > 10 && tabStatus == 1 ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={dataCount}
            pageRangeDisplayed={3}
            onChange={handleOnPageChangeClick}
          />
        ) : null
      }
      {/* candidate Feedback Panel */}
      {
        totalCandidateFeedBackCount > 10 && tabStatus == 2 ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={totalCandidateFeedBackCount}
            pageRangeDisplayed={3}
            onChange={handleOnPageChangeClick}
          />
        ) : null
      }



      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={assign}
        // onOk={handleOk()}
        onCancel={closeModal}
      >
        <Modal.Body>
          <div className={`assign_device ml-2`}>
            <h3 className="mb-4">Assign Interview</h3>
            <div className="row">
              <div
                className={`form-group col-lg-6 ${candidateIdErrorMsg ? "input_error" : ""
                  }`}
              >
                <label className="cm_bold">Candidate Name</label>

                <Select
                  showSearch
                  name="candidateId"
                  value={candidateId ? candidateId : " Select candidate"}
                  // defaultValue="Select Name"
                  onChange={handleSelectCandidateChange}
                  onSearch={handleCandidateSearch}
                  optionFilterProp="key"
                  filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                >
                  {!_.isEmpty(assignCandidateList)
                    ? assignCandidateList.sort(function (a, b) {
                      if (a.FirstName < b.FirstName) { return -1; }
                      if (a.FirstName > b.FirstName) { return 1; }
                      return 0;
                    }).map(
                      (candidate) => (
                        <Option
                          key={`${candidate.FirstName} ${candidate.LastName}`}
                          value={candidate._id}
                        >
                          <span>
                            {candidate.FirstName} {candidate.LastName}
                          </span>
                        </Option>
                      )
                    )
                    : ""}
                </Select>
                {candidateIdErrorMsg ? (
                  <span className="error_msg">{candidateIdErrorMsg}</span>
                ) : null}
              </div>
              <div className={`form-group col-lg-6 ${interviewRoundErrorMsg ? "input_error" : ""
                }`}>
                <label className="cm_bold"> Select Round</label>
                <Select
                  onChange={handleInterviewRoundStatusChange}
                  // className="col-2"
                  // style={{ width: 200 }}
                  value={interviewRound ? interviewRound : "Select Round"}
                >
                  <Option value={1}>Round One</Option>
                  <Option value={2}>Round Two</Option>
                  <Option value={3}>Round Three</Option>
                </Select>
                {interviewRoundErrorMsg ? (
                  <span className="error_msg">{interviewRoundErrorMsg}</span>
                ) : null}
              </div>

              <div className={`form-group col-lg-6 ${wantSentMailErrorMsg ? "input_error" : ""
                }`}>
                <label className="cm_bold"> Want Sent Mail</label>
                <Select
                  onChange={handleSentMailChange}
                  // className="col-2"
                  // style={{ width: 200 }}
                  value={wantSentMail ? wantSentMail : "Select"}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
                {wantSentMailErrorMsg ? (
                  <span className="error_msg">{wantSentMailErrorMsg}</span>
                ) : null}
              </div>

              <div className={`form-group col-lg-6 ${dateErrorMsg ? "input_error" : ""}`}
              >
                <label className="cm_bold">Date And Time(in firefox add time manually)</label>
                <br />
                <input
                  style={{ width: "100%" }}
                  className="date-border"
                  allowClear={date ? true : false}
                  type="datetime-local"
                  min={new Date().toISOString().slice(0, 16)}
                  value={date ? date : ""}
                  onChange={(e) => handleDateChange(e)}
                // disabled={miniLoader}
                />

                {dateErrorMsg ? (
                  <span className="error_msg">
                    {dateErrorMsg}
                  </span>
                ) : null}
              </div>
              <div
                className={`form-group col-lg-12 ${jobDesriptionErrorMsg ? "input_error" : ""
                  }`}
              >
                <label className="cm_bold"> Job Description</label>
                <TextEditor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />

                {jobDesriptionErrorMsg ? (
                  <span className="error_msg cm_pos_bot">
                    {jobDesriptionErrorMsg}
                  </span>
                ) : null}
              </div>


            </div>
            {/* </div>
            </div> */}

            <div className="addCandidateButtonsDiv">
              {loader ? <DotLoader /> :
                <button
                  type="submit"
                  onClick={(e) => onSubmitClick(e)}
                  className="btn btn-primary mt-4 mr-4 cm_btn3"
                // disabled={miniLoader}
                >
                  Save
                </button>}
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-primary mt-4 cm_btn2"
              // disabled={miniLoader}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </AntModal>


      {/* feedback modal */}




      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={openCandidateFeedBackModal}
        // onOk={handleOk()}
        onCancel={closeModal}
      >
        <Modal.Body>
          <>
            <p>{showFeedBack}</p>
          </>
        </Modal.Body>
      </AntModal>

    </div >

  );
};
const mapStateToProps = (state) => {
  return {
    interviewList: state.interviewList?.data?.candidate,
    totalCandidate: state.interviewList.data?.totalCandidate,
    interviewerList: state.interviewerList.data?.interviewerList,
    candidateFeedBackData: state.getCandidateFeedBack?.data?.list,
    totalCandidateFeedBackCount: state.getCandidateFeedBack.data?.dataCount,
    dataCount: state.interviewerList.data?.dataCount,
    error: state.assignInterviewCandidate?.error?.error?.responseMessage == "Validation error." ? state.assignInterviewCandidate?.error?.error?.errors[0]?.message : state.assignInterviewCandidate?.error?.error?.responseMessage
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInterviewList: (query) => {
      dispatch(getInterviewList(query));

    },
    getInterviewerList: (query) => {
      dispatch(getInterviewerList(query));

    },
    getCandidateFeedBackAction: (query) => {
      dispatch(getCandidateFeedBackAction(query));

    },
    deleteCandidateByAdmin: (interviewId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage) => {
      dispatch(deleteCandidateByAdmin(interviewId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage));
    },

    deleteInterviewerByAdmin: (interviewId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage) => {
      dispatch(deleteInterviewerByAdmin(interviewId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage));
    },


    deleteCandidateFeedBackAction: (clientId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage) => {
      dispatch(deleteCandidateFeedBackAction(clientId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage));
    },

    assignInterviewCandidateByAdmin: (data1, query, setAssign, setResMsg, setFailResMsg, setLoader, closeModal) => {
      dispatch(assignInterviewCandidateByAdmin(data1, query, setAssign, setResMsg, setFailResMsg, setLoader, closeModal));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InterviewList);

const ActionImage = styled.img`
      width: 30px;
      height: 30px;
      cursor: pointer;
      `;

const Button = styled.button`
      background-color: rgb(52, 123, 222);
      cursor: pointer;
      width: 200px;
      border: none;
      padding: 0.4rem;
      border-radius: 10px;
      color: white;
      font-size: medium;
      `;

import _ from 'lodash'
import React from 'react'
import { Modal } from 'react-bootstrap'
export default function DeleAwardName(props) {
    const { awardNameList, show, handleClose, miniLoader, handleSubmit } = props
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >
                <Modal.Title className='text-center'><b>Delete Award Name </b></Modal.Title>
            </Modal.Header>
            <Modal.Body className={`assign_device ${miniLoader ? "admin_attendance" : ""}`}>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <div className="table-responsive mt-2">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col"> Award Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!_.isEmpty(awardNameList) ?
                                awardNameList.map((award, i) => (
                                    <tr key={award._id}>
                                        <td>{i + 1}</td>
                                        <td>{award?.name}</td>
                                        <td>
                                            <span onClick={() => handleSubmit(award._id)}>
                                                <i className="fa fa-trash-o red ml-2 mr-3 fa-2x" title="Delete Award" />
                                            </span>
                                        </td>
                                    </tr>
                                )) : ""
                            }
                        </tbody>
                    </table>
                    {awardNameList && awardNameList?.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                </div >
            </Modal.Footer>
        </Modal>
    )
}

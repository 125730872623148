import React from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../config/services/employeeServices';
import { EmailIcon, Loader } from '../svg';


class ForgetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            email: '',
            emailErrorMsg: '',
            responseMessage: '',
        }
    };
    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        responseMessage: ''
    });

    handleValidetion = () => {
        let validate = true;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

        const { email } = this.state;
        let validateEmail = regx.test(String(email).toLowerCase());

        if (!email) {
            validate = false;
            this.setState({ emailErrorMsg: 'Email is required' })
        } else if (!validateEmail) {
            validate = false;
            this.setState({ emailErrorMsg: 'Please enter a valid email address' })
        } else {
            this.setState({ emailErrorMsg: '' })
        }
        return validate
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ loader: true })

        if (this.handleValidetion()) {
            this.setState({
                loader: true
            })
            let obj = {
                'email': this.state.email
            }
            forgotPassword(obj)
                .then(res => {

                    if (res.data.statusCode == 1) {
                        window.location.href = '/success-msg';

                    } else {
                        this.setState({
                            loader: false,
                            responseMessage: res.data.error.errors[0]?.message
                        })
                    }
                })
                .catch(e => {
                    this.setState({ loader: false, responseMessage: "Something went wrong" })
                })
        }
        else {
            this.setState({ loader: false })
        }

    }
    render() {
        let { email, emailErrorMsg, loader, responseMessage } = this.state;
        return (
            <div className="login_wrapper d-flex align-items-center">
                {
                    loader && <Loader />
                }
                <div className="container cm_container bg_white d-flex align-items-center">
                    <div className="page_content text-center">
                        <img className="locked mb-5" src={require('../../asset/imgs/locked.png')} alt="" />
                        <h4 className=" cm_heading mb-5">FORGOT PASSWORD ?</h4>
                        <p className="mb-5">
                            Please enter your email id and password, a reset link will send to your email.<br />
                            Where you can reset your password to proceed further.</p>
                        <form className="fp_form" onSubmit={this.handleSubmit} >
                            <div className={`form-group ${emailErrorMsg ? 'input_error' : ''}`}>
                                <span className="cm_icon emal_icon"><EmailIcon /></span>
                                <input
                                    className={`form-control`}
                                    type="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                />
                                {emailErrorMsg ? <span className="error_msg">{emailErrorMsg}</span> : null}
                                {responseMessage ? <h3 className=" Registration_error mt-5">{responseMessage}</h3> : ''}
                            </div>
                            <button className="btn btn-primary mt-4 mr-4 cm_btn3" type="send">Send</button>
                            <Link to='/' className="btn btn-primary mt-4 cm_btn2">Back</Link>
                        </form>

                    </div>
                </div></div>
        );
    }
}
export default ForgetPassword;
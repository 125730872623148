import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import DotLoader from "../../hoc/DotLoader";
import _ from "lodash";
import ResponseFilter from "../../ResponseFilter";
import { getEmployeeList } from "../../config/services/employeeServices";
import { editInterviewerByAdmin } from "../../redux/actions/editInterviewerByAdmin";

const { Option } = Select;

const EditInterviewer = ({
    showEditInterview,
    setShowEditInterview,
    editInterviewerByAdmin,
    loading,
    search,
    pageNo,
    count,
    state,
    sortType,
    sortField,
    error

}) => {
    const [interviewerName, setInterviewerName] = useState("");
    const [interviewerId, setInterviewerId] = useState("");
    const [interviewRound, setInterviewRound] = useState("");
    const [employeeList, setEmployeeList] = useState([])
    const [technicalSkill, setTechnicalSkill] = useState([]);
    const [interviewerNameErrorMessage, setInterewerNameErrorMessage] = useState("")
    const [interviewRoundErrorMessage, setInterviewRoundErrorMessage] = useState("")
    const [technicalSkillErrorMessage, settechnicalSkillErrorMessage] = useState("")
    const [resMsg, setResMsg] = useState(false);
    const [resFailMsg, setFailResMsg] = useState(false);
    const [loader, setLoader] = useState(false);




    useEffect(() => {
        setInterviewerName(state.firstName);
        setInterviewRound(state.round);
        setInterviewerId(state.interviewerid)
        setTechnicalSkill(state.skill);

    }, [state, showEditInterview])

    const skillType = (type) => {
        switch (type) {
            case 1:
                return "React Js";
                break;
            case 2:
                return "Node Js";
                break;
            case 3:
                return "React Native";
                break;
            case 4:
                return "Flutter";
                break;
            case 5:
                return "Android";
                break;
            case 6:
                return "IOS";
                break;
            case 7:
                return "UI";
                break;
            case 8:
                return "UX";
                break;
            case 9:
                return "HR Manager";
                break;
            case 10:
                return "Quality Analyst";
                break;
            case 11:
                return "Sales And Marketing";
                break;
            case 12:
                return "Pyhton";
                break;

            case 13:
                return "ROR";
                break;
            case 14:
                return "Next Js";
                break;
            case 15:
                return "Nest Js";
                break;
            case 16:
                return "Accounts";
                break;
            case 17:
                return "Devops";
                break;
            case 18:
                return "Graphic Designer And Animation";
                break;

            case 19:
                return "Angular";
                break;
            case 20:
                return "MERN";
                break;
            case 21:
                return "MEAN";
                break;
            case 22:
                return "KOTLIN";
                break;
            case 23:
                return "Java";
                break;
            case 24:
                return "Vue.js";
                break;


            case 25:
                return "Drupal";
                break;
            case 26:
                return "Blockchain";
                break;
            case 27:
                return "AI";
                break;
            case 28:
                return "ML";
                break;
            case 29:
                return "C";
                break;
            case 30:
                return "C++";
                break;


            case 31:
                return "C#";
                break;
            case 32:
                return ".Net";
                break;
            case 33:
                return "PHP";
                break;
            case 34:
                return "Wordpress";
                break;
            case 35:
                return "SQL";
                break;
            case 36:
                return "Git";
                break;
        }
    };


    const handleCancelButtonClick = () => {
        setInterviewerName("");
        setInterewerNameErrorMessage("");
        setInterviewRound("");
        setInterviewRoundErrorMessage("");
        setTechnicalSkill([]);
        settechnicalSkillErrorMessage("");
        setShowEditInterview(false);
    };
    const handleInterviewRoundValidation = () => {
        let validate = true;
        if (interviewRound === "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleTechnicalSkillValidation = () => {
        let validate = true;
        if (technicalSkill.length == 0) {
            validate = false;
        }
        else {
        }
        return validate
    }
    const handleUploadButtonClick = () => {
        if (
            handleInterviewRoundValidation(interviewRound) &&
            handleTechnicalSkillValidation(technicalSkill)
        ) {
            setLoader(true)
            const data = new FormData();
            const data1 = {
                "interviewerid": interviewerId,
                "interviewerRound": interviewRound,
                "skill": technicalSkill.toString(),

            }
            data.append("interviewerName", interviewerName);
            data.append("interviewRound", interviewRound);
            data.append("technicalSkill", technicalSkill);
            const query = `search=${search}&pageNo=${pageNo - 1}&count=${count}&sortType=${sortType}&sortField=${sortField}`;
            editInterviewerByAdmin(data1, setLoader, query, showEditInterview, setResMsg, setFailResMsg, handleCancelButtonClick);
            ;
        }
        else {
        }

        setTimeout(() => setResMsg(false), 4000)
        setTimeout(() => setFailResMsg(false), 4000)
    };
    const handleInterviewRoundChange = (param) => {
        setInterviewRound(param);
        setInterviewRoundErrorMessage("");

    };

    const handleTechnicalSkillChange = (param) => {
        setTechnicalSkill(param);
        settechnicalSkillErrorMessage("")
    };
    const onSubmitClick = () => {
        handleUploadButtonClick();

        if (handleInterviewRoundValidation(interviewRound) == "") {
            setInterviewRoundErrorMessage("Please Enter Interviewer Level");
        }
        else {
            setInterviewRoundErrorMessage("")
        }
        if (handleTechnicalSkillValidation(technicalSkill.length) == 0) {
            settechnicalSkillErrorMessage("Please Enter Technical Skill");
        }
        else {
            settechnicalSkillErrorMessage("")
        }

    };

    useEffect(() => {
        let params = `status=1`;
        getEmployeeList(params).then((res) => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                // setLoader(false);
                setEmployeeList(resData.employeesList)
            }
        })
    }, [])



    return (
        <AntModal
            centered
            className="attend_modal"
            footer={false}
            visible={showEditInterview}
            onCancel={handleCancelButtonClick}
        >
            <Modal.Body>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmitClick();
                    }}
                >

                    <h3 className="mb-4">Edit Interviewer</h3>
                    {
                        resMsg ?
                            <div className="alert alert-success cm_top_fix"> Interviewer Edited Successfully </div> : ''
                    }
                    {
                        resFailMsg ?
                            <div className="alert alert-danger cm_top_fix"> {error} </div> : ''
                    }
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Interviewer Level</label>
                            <Select
                                value={interviewRound ? interviewRound : "Select Interview Level"}
                                onChange={(e) => handleInterviewRoundChange(e)}
                                isSearchable={true}

                            >
                                <Option value={1}>Level One</Option>
                                <Option value={2}>Level Two</Option>
                                <Option value={3}>Level Three</Option>



                            </Select>
                            <span className="errorText">{interviewRoundErrorMessage}</span>

                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label className="cm_bold">Technical Skills</label>
                            <Select
                                mode="multiple"
                                placeholder="Select Skill"
                                value={technicalSkill}
                                onChange={(e) => handleTechnicalSkillChange(e)}
                                isSearchable={true}

                            >
                                <Option value={"1"}>React.JS</Option>
                                <Option value={"2"}>Node.Js</Option>
                                <Option value={"3"}>React Native</Option>
                                <Option value={"4"}>Flutter</Option>
                                <Option value={"5"}>Android</Option>
                                <Option value={"6"}>IOS</Option>
                                <Option value={"7"}>UI</Option>
                                <Option value={"8"}>UX</Option>
                                <Option value={"9"}>HR Manager</Option>
                                <Option value={"10"}>Quality Analyst</Option>
                                <Option value={"11"}>Sales And Marketing</Option>
                                <Option value={"12"}>Python</Option>
                                <Option value={"13"}>R.O.R</Option>
                                <Option value={"14"}>Next.Js</Option>
                                <Option value={"15"}>Nest.js</Option>
                                <Option value={"16"}>Accounts</Option>
                                <Option value={"17"}>Devops</Option>
                                <Option value={"18"}>Graphic Design And Animation</Option>
                                <Option value={"19"}>Angular</Option>
                                <Option value={"20"}>MERN</Option>
                                <Option value={"21"}>MEAN</Option>
                                <Option value={"22"}>KOTLIN</Option>
                                <Option value={"23"}>JAVA</Option>
                                <Option value={"24"}>Vue.js</Option>
                                <Option value={"25"}>Drupal</Option>
                                <Option value={"26"}>Blockchain</Option>
                                <Option value={"27"}>AI</Option>
                                <Option value={"28"}>ML</Option>
                                <Option value={"29"}>C</Option>
                                <Option value={"30"}>C++</Option>
                                <Option value={"32"}>C#</Option>
                                <Option value={"33"}>.Net</Option>
                                <Option value={"34"}>PHP</Option>
                                <Option value={"35"}>Wordpress</Option>
                                <Option value={"36"}>SQL</Option>
                                <Option value={"37"}>Git</Option>
                            </Select>

                            <span className="errorText">{technicalSkillErrorMessage}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="addCandidateButtonsDiv">
                            <button
                                onClick={handleCancelButtonClick}
                                type="button"
                                className="cancel_btn btn  buttonWidth"
                            >
                                Cancel
                            </button>
                            {loader ? <DotLoader /> :
                                <button className="btn btn-primary  buttonWidth" type="submit">
                                    Submit
                                </button>}
                        </div>
                    </div>
                </form >
            </Modal.Body >
        </AntModal >
    );
};
const mapStateToProps = (state) => {
    return {
        loading: state.addCandidateByAdmin.loading,
        error: state.editInterviewerByAdmin?.error?.error?.responseMessage

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        editInterviewerByAdmin: (data1, setLoader, query, showEditInterview, setResMsg, setFailResMsg, handleCancelButtonClick) => {
            dispatch(editInterviewerByAdmin(data1, setLoader, query, showEditInterview, setResMsg, setFailResMsg, handleCancelButtonClick));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditInterviewer);

import { TimePicker } from "antd";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { format } from "../Constants";
import { ErrorMessage } from "../../helper/Constant";

export function EditAttendanceModal(props) {
  const {
    show,
    handleClose,
    checkInErrorMsg,
    checkIn,
    handleCheckInChange,
    miniLoader,
    checkOutErrorMsg,
    checkOut,
    handleCheckOutChange,
    handleEditAttendanceSubmit,
  } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Edit Attendance </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="assign_device">
          <div className="row">
            <div
              className={`form-group col-lg-6 m-0 ${
                checkInErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Check In</label>
              <br />
              <TimePicker
                className="form-control"
                allowClear={false}
                format={format}
                value={checkIn ? moment.utc(checkIn * 1000) : ""}
                onChange={handleCheckInChange}
                disabled={miniLoader}
              />
              <ErrorMessage errMsg={checkInErrorMsg} />
              <br />
            </div>
            <div
              className={`form-group col-lg-6 ${
                checkOutErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Check Out</label>
              <TimePicker
                className="form-control"
                allowClear={false}
                format={format}
                value={checkOut ? moment.utc(checkOut * 1000) : ""}
                onChange={handleCheckOutChange}
                disabled={miniLoader}
              />
              <ErrorMessage errMsg={checkOutErrorMsg} />
              <br />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          {miniLoader ? <div className="cover-spin1"></div> : ""}
          <button
            type="submit"
            onClick={handleEditAttendanceSubmit}
            className="btn btn-primary cm_btn3"
            disabled={miniLoader}
          >
            Edit
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-primary cm_btn2"
            disabled={miniLoader}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import _ from 'lodash'
import React from 'react'
import Pagination from 'react-js-pagination'

export default function AwardYearList(props) {
  const { awardNameList, handleShowAwardList, pageNo, count, handlePageChange, dataCount } = props
  return (
    <>
      <div className="table-responsive mt-5">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Year</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(awardNameList) ?
              awardNameList.map((award, i) => (
                <tr key={award._id}>
                  <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                  <td><span className="cm_col blue" style={{ cursor: 'pointer' }} onClick={() => handleShowAwardList(award?._id)}>{award?._id}</span></td>
                </tr>
              )) : ""
            }
          </tbody>
        </table>
        {awardNameList && awardNameList?.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
      </div >
      {
        dataCount > 10 ? <Pagination
          activePage={pageNo}
          itemsCountPerPage={count}
          totalItemsCount={dataCount}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
        />
          : ''
      }
    </>
  )
}

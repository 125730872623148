import { STATUS_ZERO } from "../../components/attendance/Constants";
import { dailyAttendancebyExcel } from "../../config/services/employeeServices";
import {
    UPLOAD_ATTENDANCE_REQUEST,
    UPLOAD_ATTENDANCE_SUCCESS,
    UPLOAD_ATTENDANCE_FAILURE,
} from "./actionType";

const uploadAttendanceAction = (props) => {
    let { useDispatch, rawdata, closeModal, getAttendance } = props
    return (dispatch) => {
        dispatch(uploadAttendanceRequest());
        dailyAttendancebyExcel(rawdata).then((res) => {
            if (res.data.statusCode === 1) {
                closeModal()
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        tabStatus: STATUS_ZERO,
                        status: '',
                        resMsgUpload: true,
                        resMsgLoad: false,
                        startDate: '',
                        endDate: '',
                        // date: '',
                        startDateStamp: '',
                        endDateStamp: '',
                        miniLoader: false
                    }
                })
            getAttendance()

            }
            else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        tabStatus: STATUS_ZERO,
                        status: '',
                        pageNo: 1,
                        loader: false,
                        responseMessageExcel: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resMsgExcelError: true,
                        miniLoader: false,
                        resMsgLoad: false,
                    }
                })
            }
            setTimeout(() => useDispatch({
                type: 'typeCheckSet', payload: {
                    resMsgUpload: false,
                    resMsgExcelError: false,
                }
            }), 3000);
        })
            .catch((error) => {
                dispatch(uploadAttendanceFailure(error));
            });
    };
};

const uploadAttendanceRequest = () => {
    return {
        type: UPLOAD_ATTENDANCE_REQUEST,
    };
};

const uploadAttendanceSuccess = (data) => {
    return {
        type: UPLOAD_ATTENDANCE_SUCCESS,
        payload: data,
    };
};

const uploadAttendanceFailure = (error) => {
    return {
        type: UPLOAD_ATTENDANCE_FAILURE,
        payload: error,
    };
};
export {
    uploadAttendanceAction,
    uploadAttendanceRequest,
    uploadAttendanceSuccess,
    uploadAttendanceFailure,
};

import React from "react";
import Modal from "react-bootstrap/Modal";
import { EmailIcon } from "../svg";
import { Link } from "react-router-dom";
import { Modal as AntModal } from "antd";
import {
  getAdminProfile,
  editEmployeeProfileImage,
  grantAccess,
  getAdminDetails,
  removeGrantAccess,
} from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class AdminProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deptDetails: [],
      resMsg1: false,
      resMsg2: false,
      loader: true,
      openAccessPopup: false,
      openDeleteAccessPopup: false,
      profile: "",
      imageLoader: false,
      email: "",
      responseError: "",
      emailErrorMsg: "",
      allAdminDetails: [],
      responseMessage: "",
      message: "",
      removeAdmin: "",
      profileImage: "",
      employeeProfileErrorMsg: "",
      openProfileChangePopup: false,
      resProfileUpdate: false,
      resFailProfileUpdate: false,
      miniLoader: false,
      src: null,
      crop: {
        unit: "%",
        x: 50,
        y: 50,
        width: 50,
        height: 50,
      },
    };
  }

  getProfile = () => {
    getAdminProfile().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          imageLoader: false,
          profile: resData.employeeProfile,
          deptDetails: resData.deptDetails,
          profileImagePreview: resData.employeeProfile.profileImage
            ? resData.employeeProfile.profileImage
            : require("../../asset/imgs/user-img.png"),
        });
      }
    });
    setTimeout(() => this.setState({ resMsg1: false }), 4000);
    setTimeout(() => this.setState({ resMsg2: false }), 4000);
  };

  getAdmin = () => {
    getAdminDetails().then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          allAdminDetails: resData.allAdminDetails,
        });
      }
    });
    setTimeout(() => this.setState({ resMsg1: false }), 4000);
    setTimeout(() => this.setState({ resMsg2: false }), 4000);
  };

  handleGrantAccessSubmit = (event) => {
    event.preventDefault();
    this.setState({ loader: true });

    if (this.handleValidetion()) {
      this.setState({
        loader: true,
      });
      let obj = {
        email: this.state.email,
        role: 1,
      };
      grantAccess(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({
            resMsg1: true,
            message: res.data.responseData.message,
          });
          this.closeModal();
          this.getAdmin();
        } else {
          this.setState({
            responseMessage: res.data.error.responseMessage,
          });
        }
      });
    }
  };

  handleDelete = () => {
    this.setState({ loader: true });

    let obj = {
      email: this.state.email,
      role: 2,
    };

    removeGrantAccess(obj).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState({ resMsg2: true });
        this.closeModal();
        this.getAdmin();
      } else {
        this.setState({
          responseMessage: res.data.error.responseMessage,
        });
      }
    });
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "ErrorMsg"]: "",
      responseError: "",
      responseMessage: "",
      message: "",
    });
  handleprofileValidation = () => {
    const { profileImage } = this.state;
    let validate = true;
    if (profileImage === "") {
      validate = false;
      this.setState({ employeeProfileErrorMsg: "Please Enter Image" });
    } else if (!profileImage.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg:
          "Please Enter jpg|jpeg|png|JPEG|JPG|PNG format only",
      });
    } else if (profileImage?.size >= 10485760) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg: "please insert image of less then 10 mb",
      });
    } else {
      this.setState({ employeeProfileErrorMsg: "" });
      // validate = false;
    }
    return validate;
  };

  handleProfileSubmit = (e) => {
    if (this.handleprofileValidation()) {
      this.setState({ miniLoader: true });

      let data = new FormData();
      data.append("profileImage", this.state.profileImage);

      let params = {
        employeeId: this.state.profile.employeeId,
        data: data,
      };

      editEmployeeProfileImage(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.getProfile();
          this.handleCancel();
          this.setState({
            miniLoader: false,
            openProfileChangePopup: false,
            resProfileUpdate: true,
          });
          setTimeout(
            () =>
              this.setState({
                resFailProfileUpdate: false,
                resProfileUpdate: false,
              }),
            2000
          );
        } else {
          this.setState({ resFailProfileUpdate: true });
          this.setState({ openProfileChangePopup: false, miniLoader: false });
        }
      });
    }
  };

  handleOpenAccessPopup = () =>
    this.setState({ openAccessPopup: !this.state.openAccessPopup });

  handleOpenDeleteAccessPopup(props) {
    this.setState({
      email: props,
      openDeleteAccessPopup: !this.state.openDeleteAccessPopup,
    });
  }
  closeModal = () =>
    this.setState({
      openAccessPopup: false,
      openDeleteAccessPopup: false,
      email: "",
      responseError: "",
      responseMessage: "",
      message: "",
      emailErrorMsg: "",
    });

  componentDidMount() {
    this.getAdmin();
    this.getProfile();
  }
  handleValidetion = () => {
    let validate = true;
    const regx =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    const { email } = this.state;
    let validateEmail = regx.test(String(email).toLowerCase());

    if (!email) {
      validate = false;
      this.setState({ emailErrorMsg: "Email is required" });
    } else if (!validateEmail) {
      validate = false;
      this.setState({ emailErrorMsg: "Please enter a valid email address" });
    } else {
      this.setState({ emailErrorMsg: "" });
    }

    return validate;
  };
  handleOpenProfileChangePopup = () =>
    this.setState({
      openProfileChangePopup: !this.state.openProfileChangePopup,
    });
  handleCancel = (event) => {
    this.closeModal();
    this.setState(this.getProfile());
  };

  handleCancel1 = (e) => {
    var fileInput = document.getElementById("profile");
    fileInput.value = null;
    this.setState({
      openProfileChangePopup: false,
      profileImage: "",
      employeeProfileErrorMsg: "",
      croppedImageUrl: "",
      src: "",
      crop: {
        unit: "%",
        x: 50,
        y: 50,
        width: 50,
        height: 50,
      },
    });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
        })
      );
      this.setState({
        profileImage: e.target.files && e.target.files[0],
        employeeProfileErrorMsg: "",
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    let self = this;
    let {
      miniLoader,
      deptDetails,
      resMsg1,
      resMsg2,
      openAccessPopup,
      openDeleteAccessPopup,
      profile,
      imageLoader,
      emailErrorMsg,
      email,
      allAdminDetails,
      responseMessage,
      message,
      profileImagePreview,
      employeeProfileErrorMsg,
      openProfileChangePopup,
      resFailProfileUpdate,
      resProfileUpdate,
      crop,
      croppedImageUrl,
      src,
    } = this.state;
    return (
      <div
        className={`body_container cm_bold ${
          miniLoader ? "admin_attendance" : ""
        }`}
      >
        {resMsg1 ? (
          <div className="alert alert-success cm_top_fix">
            Access granted successfully
          </div>
        ) : (
          ""
        )}
        {resMsg2 ? (
          <div className="alert alert-danger cm_top_fix">
            Access deleted successfully
          </div>
        ) : (
          ""
        )}
        {resProfileUpdate ? (
          <div className="alert alert-success cm_top_fix">
            Profile Updated Successfully
          </div>
        ) : (
          ""
        )}
        {resFailProfileUpdate ? (
          <div className="alert alert-danger cm_top_fix">Please Try Again</div>
        ) : (
          ""
        )}
        <div className="cm_breadcrumb ml-2">
          <ul>
            <li>
              <Link to="/">DASHBOARD</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li>PROFILE</li>
          </ul>
        </div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-4 cm_user_img">
                <div className="image-upload">
                  <img
                    className=""
                    src={profileImagePreview}
                    disabled={imageLoader}
                    alt=""
                  />
                  {imageLoader ? (
                    <div className="cm_img_loader">
                      <img
                        className="image_loader"
                        src={require("../../asset/svg/imageLoader.svg")}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="img-edit">
                    <span onClick={this.handleOpenProfileChangePopup}>
                      <i className="fa fa-camera pic-upload"></i>
                    </span>
                  </div>
                </div>

                {imageLoader ? <h1>test</h1> : ""}
              </div>

              <div className="col-xl-7 col-md-12 admin_emp_profile ml-3">
                <h3 className="mb-4">General Information</h3>

                <div className="row mb-4">
                  <div className="col-6">
                    <label>Full Name</label>
                    <p>
                      {profile.firstName} {profile.lastName}
                    </p>
                  </div>
                  <div className="col-6">
                    <label>Admin Email</label>
                    <p>{profile.email}</p>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-12">
                    <label>Department</label>
                    <div className="dept_show">
                      {deptDetails.map((department, i) => (
                        <p className="cm_col_dept ">
                          {department.departmentName
                            ? `${department.departmentName}`
                            : ""}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <span
                    className="btn btn-primary"
                    onClick={this.handleOpenAccessPopup}
                  >
                    Grant Access
                  </span>
                </div>
              </div>
            </div>

            <div className="row cm_row_section">
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <h3>Admin User Access</h3>
                  </div>

                  <div className="col-8">
                    <ul className="email">
                      {allAdminDetails.map((data) => (
                        <li>
                          {this.state.profile.email === data.email ? (
                            <p>
                              {data.email}{" "}
                              <span>
                                <i
                                  className="fa fa-trash-o disable"
                                  title="You can not remove yourself"
                                ></i>
                              </span>
                            </p>
                          ) : (
                            <p>
                              {data.email}{" "}
                              <span
                                onClick={() =>
                                  self.handleOpenDeleteAccessPopup(data.email)
                                }
                              >
                                <i
                                  className="fa fa-trash-o"
                                  title="Delete access"
                                ></i>
                              </span>
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              size="md"
              show={openAccessPopup}
              onHide={this.closeModal}
              aria-labelledby="example-modal-sizes-title-sm"
              centered
              className="grant_access"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="text-center">
                <form
                  className="grant_access"
                  onSubmit={this.handleGrantAccessSubmit}
                >
                  <h3 className="mb-4">GRANT ACCESS TO</h3>
                  {responseMessage && (
                    <h3 className="password_error mt-2 text-center">
                      {responseMessage}
                    </h3>
                  )}

                  {message && (
                    <h3 className="password_error mt-2 text-center">
                      {message}
                    </h3>
                  )}
                  <h4 className="mb-2 cm_bold">Email Address</h4>
                  <div
                    className={`form-group mb-4 ${
                      emailErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <span className="cm_icon emal_icon">
                      <EmailIcon />
                    </span>
                    <input
                      maxLength="100"
                      autocomplete="off"
                      className="form-control cm_box  text-center"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                    {emailErrorMsg ? (
                      <span
                        className=""
                        style={{ paddingLeft: "", color: "red", bottom: "-22" }}
                      >
                        {emailErrorMsg}
                      </span>
                    ) : null}
                  </div>

                  <button type="submit" className="btn btn-primary  mt-3">
                    Submit
                  </button>
                  <br />
                </form>
              </Modal.Body>
            </Modal>

            <Modal
              size="md"
              show={openDeleteAccessPopup}
              onHide={this.closeModal}
              aria-labelledby="example-modal-sizes-title-sm"
              centered
            >
              <Modal.Body className="text-center confirmation">
                <p className="mb-4 cm_bold cnfrm_msg">
                  Are you sure, you want to delete Access?
                </p>
                <div className="text-center mt-5 edit_appraisal">
                  <button
                    type="submit"
                    onClick={this.handleDelete}
                    className="btn btn-danger mr-2 btn-sm cm_btn3"
                  >
                    Delete
                  </button>

                  <button
                    onClick={this.closeModal}
                    className="btn btn-primary  cm_btn2 btn-sm"
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            <AntModal
              title=""
              centered
              className="sm_modal"
              footer={false}
              visible={openProfileChangePopup}
              onOk={this.handleOk}
              onCancel={this.handleCancel1}
            >
              <Modal.Body>
                <div
                  className={`assign_device ${
                    miniLoader ? "admin_attendance" : ""
                  }`}
                >
                  <h3 className="mb-4">EDIT PROFILE PICTURE</h3>
                  <div className="row">
                    <div
                      className={`form-group ${
                        employeeProfileErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Upload Picture</label>
                      <input
                        className="form-control cm_bold cm_box "
                        type="file"
                        id="profile"
                        placeholder="Upload image"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.onSelectFile}
                        disabled={miniLoader}
                      />
                      <div className="mt-2">
                        {src && (
                          <ReactCrop
                            src={src}
                            crop={crop}
                            aspect={1}
                            ruleOfThirds
                            circularCrop
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            style={{ width: "14rem", height: "14rem" }}
                          />
                        )}
                        {croppedImageUrl && src && (
                          <img
                            alt="Crop"
                            style={{
                              width: "12rem",
                              position: "absolute",
                              bottom: "5px",
                              marginLeft: "12px",
                              borderRadius: "50%",
                            }}
                            src={croppedImageUrl}
                          />
                        )}
                      </div>
                      {employeeProfileErrorMsg ? (
                        <span className="error_msg">
                          {employeeProfileErrorMsg}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="submit_button_margin mt-4">
                    {miniLoader ? <div className="cover-spin1"></div> : ""}
                    <button
                      type="submit"
                      className="btn btn-primary cm_btn3"
                      onClick={this.handleProfileSubmit}
                      disabled={miniLoader}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary cm_btn2"
                      onClick={this.handleCancel1}
                      disabled={miniLoader}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </AntModal>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminProfile;

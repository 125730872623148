import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Modal as AntModal } from "antd";
import ResponseFilter from "../../ResponseFilter";
import {
  editReimburse,
  getAdminProfile,
  reimbursementAction,
  reimburseMonthlyRecord,
  reimburseMonthlyRecordByManager,
} from "../../config/services/employeeServices";
import { Loader } from "../svg";
import { downloadIcon } from "../../asset";
import styled from "styled-components";
import { CONSTANTS } from "../../config";
import ViewUploadedDocument from "../helper/ViewUploadedDocument";
import EditReimbursementModal from "./ReimbursementModal/EditReimbursementModal";
import { colorType, statusValue } from "./ReimbursementConstant";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
function disabledDate(current) {
  return current && current > moment().endOf("day");
}

class ReimbursementView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reimbursementDetails: [],
      searchYear: "",
      searchMonth: "",
      loader: true,
      pageNo: 1,
      sortType: SORT_TYPE_CONSTANTS.Descending,
      sortField: "created",
      count: 10,
      totalReimburseCount: "",
      openRembInfoPopup: false,
      expenseDescription: "",
      reqMonth: props.location.state.reqMonth,
      reqYear: props.location.state.reqYear,
      show: false,
      month: "",
      expense: "",
      amount: "",
      expenseDate: "",
      id: "",
      resMsg: false,
      amountErrorMsg: "",
      openRembBillPopup: false,
      bill: [],
      approve: false,
      reject: false,
      pending: false,
      reId: [],
      checked: false,
      multiValue: [],
      multiCheckedValue: {},
      resMsg: false,
      resRejactMsg: false,
      resFailMsg: false,
      profile: "",
      openApprovePopup: "",
      openRejectPopup: "",
      alreadyGivenAmount: "",
      alreadyGivenAmountErrorMessage: "",
      approvedAmount: "",
      approvedAmountErrorMessage: "",
      alreadyGivenAmountDescription: "",
      claimedAmount: "",
      editReimbursementType: "",
      editTitle: "",
      editUploadFiles: [],
      uploadFileName: [],
    };
  }

  statusMon = (type) => {
    switch (type) {
      case 1:
        return "January";
        break;
      case 2:
        return "February";
        break;
      case 3:
        return "March";
        break;
      case 4:
        return "April";
        break;
      case 5:
        return "May";
        break;
      case 6:
        return "June";
        break;

      case 7:
        return "July";
        break;
      case 8:
        return "August";
        break;
      case 9:
        return "September";
        break;
      case 10:
        return "October";
        break;
      case 11:
        return "November";
        break;
      case 12:
        return "December";
        break;
    }
  };

  handleMultiSelect = (e, val, stat) => {
    let { reimbursementDetails, multiValue, reId, approve, reject, pending } =
      this.state;
    let self = this;
    this.setState({ reasonErMsg: "" });
    multiValue = [];
    let reIdval = [];
    let app = [];
    let rej = [];
    app = reimbursementDetails[0].reimburseRecord.filter(
      (eachObj) => eachObj.status === stat
    );
    if (app[0].status === 2) {
      this.setState({ approve: true });
    } else if (app[0].status === 3) {
      this.setState({ reject: true });
    } else {
      this.setState({ pending: true });
    }
    for (let j = 0; j < app.length; j++) {
      rej[j] = app[j].reimbursementId;
    }

    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1);
          if (reId.length === 0) {
            this.setState({ approve: false, reject: false, pending: false });
          }
        }
      }
    } else {
      this.state.reId.push(val);
    }
  };
  componentDidMount() {
    this.getProfile();
    this.getReimburseMonthlyRecords();
  }

  getProfile = () => {
    getAdminProfile().then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          //   imageLoader: false,
          profile: resData.employeeProfile.employeeId,
        });
      }
    });
  };

  getReimburseMonthlyRecords = () => {
    let { reqYear, reqMonth, pageNo, count, sortType, sortField } = this.state;
    let params = `searchYear=${reqYear}&searchMonth=${reqMonth}&count=${count}&pageNo=${
      pageNo - 1
    }&sortType=${sortType}&sortField=${sortField}`;

    reimburseMonthlyRecordByManager(params).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState(
          {
            reimbursementDetails: resData.reimbursementDetails,
            totalReimburseCount: resData.totalReimburseCount,
          },
          () => {
            this.setState({
              loader: false,
            });
            this.handleClose();
          }
        );
      }
    });
  };

  handleStatus = (a) =>
    this.setState({ status: a }, () => this.getReimbursement());

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () =>
      this.getReimburseMonthlyRecords()
    );
  };

  handleShow = (e) => {
    this.setState({
      show: true,
      expenseDate: "",
      amount: "",
      expenseDescription: "",
    });
  };
  handleClose = () => {
    this.setState({
      show: false,
      expenseDate: "",
      amount: "",
      expenseDescription: "",
      amountErrorMsg: "",
      titleErrorMsg: "",
      editUploadFiles: [],
      editReimbursementType: "",
      uploadFileName: "",
      uploadBillErrorMsg: "",
      expenseErrorMsg: "",
    });
  };

  handleValidation = () => {
    let validate = true;
    const { amount, editTitle, expense, editUploadFiles } = this.state;
    const regMatch = /^[^0-9][a-zA-Z0-9_ ]+$/.test(editTitle);
    // if (editUploadFiles.length === 0 || editUploadFiles === undefined) {
    //   validate = false;
    //   this.setState({ uploadBillErrorMsg: "Bill is required" });
    // }
    if (editTitle === "" || editTitle === undefined) {
      validate = false;
      this.setState({ titleErrorMsg: "Title is required" });
    } else if (!regMatch) {
      validate = false;
      this.setState({
        titleErrorMsg:
          "Title cannot start with a number and cannot have special characters",
      });
    }
    if (expense === "" || expense === undefined) {
      validate = false;
      this.setState({ expenseErrorMsg: "Expense description is required" });
    }
    if (amount === "" || amount === undefined) {
      validate = false;
      this.setState({ amountErrorMsg: "Claim amount is required" });
    } else if (amount <= 1) {
      validate = false;
      this.setState({ amountErrorMsg: "Please enter valid amount value" });
    } else if (amount > 200000) {
      validate = false;
      this.setState({ amountErrorMsg: "Amount cannot be more than 2 Lakh" });
    } else {
      this.setState({ amountErrorMsg: "" });
    }
    return validate;
  };

  handleApprovedValidation = () => {
    let validate = true;
    const { approvedAmount, alreadyGivenAmount, claimedAmount } = this.state;
    if (approvedAmount <= 1 && approvedAmount !== "") {
      validate = false;
      this.setState({
        approvedAmountErrorMessage: "Please enter valid amount value",
      });
    } else if (approvedAmount > claimedAmount) {
      validate = false;
      this.setState({
        approvedAmountErrorMessage: "Amount cannot be more than Claimed Amount",
      });
    } else {
      this.setState({ approvedAmountErrorMessage: "" });
    }

    if (alreadyGivenAmount <= 1 && alreadyGivenAmount !== "") {
      validate = false;
      this.setState({
        alreadyGivenAmountErrorMessage: "Please enter valid amount value",
      });
    } else if (alreadyGivenAmount > 200000) {
      validate = false;
      this.setState({
        alreadyGivenAmountErrorMessage: "Amount cannot be more than 2 Lakh",
      });
    } else {
      this.setState({ alreadyGivenAmountErrorMessage: "" });
    }
    return validate;
  };

  handleChange = (e) => {
    this.setState({
      approvedAmount: e.target.value,
      approvedAmountErrorMessage: "",
    });
  };

  handleChangeLessAmount = (e) => {
    this.setState({
      alreadyGivenAmount: e.target.value,
      alreadyGivenAmountErrorMessage: "",
    });
  };

  handleChangeLessAmountDescription = (e) => {
    this.setState({
      alreadyGivenAmountDescription: e.target.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      this.setState({ loader: true });
      let params = {
        reimbursementId: this.state.id,
        expenseDate: this.state.month,
        amount: this.state.amount,
        expenseDescription: this.state.expense,
      };
      editReimburse(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({ loader: false });
          this.getReimburseMonthlyRecords();
        }
      });
    }
  };

  handleOpenRembInfoPopup = (data) => {
    let { expenseDescription, reimbursementId } = data;
    this.setState({
      openRembInfoPopup: !this.state.openRembInfoPopup,
      expenseDescription,
      reimbursementId,
    });
  };

  handleEdit = (e) => {
    this.setState({
      show: true,
      amount: e.amount,
      expense: e.expenseDescription,
      month: moment(e.expenseDate).format("YYYY-MM-DD"),
      id: e.reimbursementId,
      editReimbursementType: e.reimburseType,
      editTitle: e.title,
      editUploadFiles: e.uploadBill,
    });
  };

  handleDateChange = (month) => {
    this.setState({
      month: new Date(month),
    });
  };
  closeModal = () =>
    this.setState({
      openRembInfoPopup: false,
      openRembBillPopup: false,
      bill: [],
    });

  handleDownload = (files) => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex === files.length - 1) clearInterval(intervalId);
      window.location.href = files[currentIndex];
      currentIndex++;
    }, 1000 + 1000 * currentIndex);
  };

  handleOpenReimbursementBill = (data) => {
    this.setState({
      openRembBillPopup: !this.state.openRembBillPopup,
      bill: data.uploadBill,
    });
  };
  handleActionApprove = (data, reimbursementId, e) => {
    if (this.handleApprovedValidation()) {
      this.setState({ loader: true });
      let params = {
        reimbursementId: this.state.reimbursementId,
        status: 2,
        approvedAmount: this.state.approvedAmount,
        amountAlreadyGiven: this.state.alreadyGivenAmount,
        lessAmountDescription: this.state.alreadyGivenAmountDescription,
      };
      reimbursementAction(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              loader: false,
              resMsg: true,
              reId: [],
              multiValue: "",
              reasonErMsg: "",
              approve: false,
              reject: false,
              pending: false,
              // reMsg: true
              openApprovePopup: false,
            },
            () => this.getReimburseMonthlyRecords()
          );
        } else {
          this.setState({ resFailMsg: true });
        }
      });
      // setTimeout(() => window.location.href = `/admin-reimbursement/admin-reimbursement-action/${this.state.employeeId}`, 1000)
    }
    setTimeout(() => this.setState({ resFailMsg: false }), 4000);
    setTimeout(() => this.setState({ resMsg: false }), 4000);
  };

  handleActionReject = (data, reimbursementId, e) => {
    this.setState({ loader: true });
    let params = {
      reimbursementId: this.state.reimbursementId,
      status: 3,
    };
    reimbursementAction(params).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState(
          {
            loader: false,
            // resMsg: true,
            reId: [],
            multiValue: "",
            reasonErMsg: "",
            approve: false,
            reject: false,
            pending: false,
            resRejactMsg: true,
            openRejectPopup: false,
          },
          () => this.getReimburseMonthlyRecords()
        );
      } else {
        this.setState({ resFailMsg: true });
      }
    });
    // setTimeout(() => window.location.href = `/admin-reimbursement/admin-reimbursement-action/${this.state.employeeId}`, 1000)

    setTimeout(() => this.setState({ resFailMsg: false }), 4000);
    setTimeout(() => this.setState({ resRejactMsg: false }), 4000);
  };

  handleDownload = (files) => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex === files.length - 1) clearInterval(intervalId);
      window.location.href = files[currentIndex];
      currentIndex++;
    }, 1000 + 1000 * currentIndex);
  };

  handleApprovePopup = (reimbursementId) => {
    this.setState({
      reimbursementId: reimbursementId.reimbursementId,
      claimedAmount: reimbursementId.amount,
      openApprovePopup: !this.state.openApprovePopup,
    });
  };
  handleRejectPopup = (reimbursementId) => {
    this.setState({
      openRejectPopup: !this.state.openRejectPopup,
      reimbursementId: reimbursementId.reimbursementId,
    });
  };

  closeModal = () => {
    this.setState({
      openApprovePopup: false,
      openRejectPopup: false,
      openRembInfoPopup: false,
      openRembBillPopup: false,
      approvedAmountErrorMessage: "",
      alreadyGivenAmountErrorMessage: "",
      alreadyGivenAmount: "",
      approvedAmount: "",
    });
  };

  handleEditReimbursementType = (e) => {
    if (e === undefined) {
      this.setState({ editReimbursementType: "" });
    } else {
      this.setState({ editReimbursementType: e.toString() });
    }
  };

  handleEditTitle = (e) => {
    this.setState({
      editTitle: e.target.value,
    });
  };

  handleDeleteIconClick = (index) => {
    this.setState({
      editUploadFiles: this.state.editUploadFiles.filter(
        (item, itemIndex) => itemIndex !== index
      ),
      uploadFileName: this.state.uploadFileName.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    });
  };

  handleFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let files = [...this.state.editUploadFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }
    this.setState(
      {
        editUploadFiles: files,
        editUploadFilesErrorMsg: "",
        uploadFileName: files.map((item) => item.name),
        uploadFileSize: files.map((item) => item.size),
      },
      () => {}
    );
    reader.addEventListener(
      "load",
      () => {
        this.setState({
          billPreview: reader.result,
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  render() {
    let {
      reimbursementDetails,
      loader,
      pageNo,
      count,
      totalReimburseCount,
      reqMonth,
      openRembInfoPopup,
      expenseDescription,
      show,
      amount,
      expense,
      expenseDate,
      month,
      amountErrorMsg,
      openRembBillPopup,
      bill,
      approve,
      pending,
      reject,
      multiValue,
      resMsg,
      resFailMsg,
      profile,
      resRejactMsg,
      openApprovePopup,
      openRejectPopup,
      approvedAmount,
      alreadyGivenAmount,
      alreadyGivenAmountDescription,
      alreadyGivenAmountErrorMessage,
      approvedAmountErrorMessage,
      editReimbursementType,
      editTitle,
      editUploadFiles,
      titleErrorMsg,
      expenseErrorMsg,
      uploadBillErrorMsg,
    } = this.state;

    return (
      <div className="body_container cm_bold">
        {loader && <Loader />}
        {resMsg ? (
          <div className="alert alert-success cm_top_fix">
            Reimbursement Approved Successfully
          </div>
        ) : (
          ""
        )}
        {resRejactMsg ? (
          <div className="alert alert-danger cm_top_fix">
            Reimbursement Rejected Successfully
          </div>
        ) : (
          ""
        )}
        {resFailMsg ? (
          <div className="alert alert-danger cm_top_fix">
            Please Try Again later
          </div>
        ) : (
          ""
        )}
        <div className="cm_breadcrumb">
          <ul>
            <li>
              <Link to="/reimbursement/manager-reimbursement">
                REIMBURSEMENT
              </Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li style={{ textTransform: "uppercase" }}>CLAIMS</li>
          </ul>
        </div>

        {reqMonth ? (
          <h5 className="mt-5 mb-4 cm_bold">
            {moment(reqMonth, "M/D/YYYY").format("MMMM")}'s Records
          </h5>
        ) : (
          ""
        )}

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th>S.No</th>
                <th>Employee Id</th>
                <th>Employee Name</th>
                <th>Date</th>
                <th>Title</th>
                <th>Expense Description</th>
                <th>Amount Claimed</th>
                <th>Amount Approved</th>
                <th>Bill</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(reimbursementDetails)
                ? reimbursementDetails
                    .filter(function (student) {
                      return student.projectManager === profile;
                    })
                    .map(function (student) {})
                : ""}
              {!_.isEmpty(reimbursementDetails) ? (
                reimbursementDetails
                  .filter((data) => data.projectManager == profile)
                  .map((data, i) => (
                    <tr key={data.employeeId}>
                      <td>{i + 1 + (pageNo - 1) * count}</td>
                      <td>{data.empId}</td>
                      <td>{data.employeeName}</td>
                      <td>{moment(data.expenseDate).format("DD/MM/YYYY")}</td>
                      <td>{data.title}</td>
                      <td
                        className="blue"
                        onClick={() => this.handleOpenRembInfoPopup(data)}
                      >
                        <span className="cm_col">
                          {data.expenseDescription}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {data.amount} {data.currency}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {data.status == 2
                            ? `${data.approvedAmount} ${data.currency}`
                            : "_"}
                        </span>
                      </td>
                      <td>
                        {/* <a
                                                className="downloadIcon"
                                                title="Download reimbursement bill"
                                                onClick={() =>
                                                    this.handleDownload(data.uploadBill)
                                                    // data.uploadBill.map(file => 
                                                    //   window.location.href=file)
                                                }
                                            >
                                                <DownloadIcon />
                                            </a> */}
                        <i
                          class="fa fa-eye ml-3"
                          aria-hidden="true"
                          onClick={() => this.handleOpenReimbursementBill(data)}
                        ></i>

                        {!_.isEmpty(data.uploadBill)
                          ? data.uploadBill
                              .filter((data) => data.includes(".zip") == true)
                              .map((data, i) => (
                                <a href={data} className="ml-4">
                                  <ActionImage
                                    src={downloadIcon}
                                    alt="downloadIcon"
                                  />
                                </a>
                              ))
                          : ""}
                      </td>
                      <td className={colorType(data.status)}>
                        {statusValue(data.status)}
                      </td>
                      <td>
                        {data.status !== 2 ? (
                          <button
                            type="button"
                            className="btn btn-success btn-sm rej_btn mr-3"
                            onClick={() => this.handleApprovePopup(data)}
                          >
                            Approve
                          </button>
                        ) : (
                          <span className="text-success mr-3">Approved</span>
                        )}
                        {data.status !== 3 ? (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm rej_btn reject-button"
                            onClick={() => this.handleRejectPopup(data)}
                          >
                            Reject
                          </button>
                        ) : (
                          <span className="text-danger">Rejected</span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <h3 className="text-center">No data available</h3>
              )}
            </tbody>
          </table>
        </div>
        {totalReimburseCount > 10 ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={totalReimburseCount}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
        ) : (
          ""
        )}
        {multiValue.length > 0 ? (
          approve === true ? (
            <button
              className="btn btn-danger mt-4 ml-4"
              onClick={() => this.handleRejectPopup()}
            >
              Reject
            </button>
          ) : reject === true ? (
            <button
              className="btn btn-success mt-4 ml-4"
              onClick={() => this.handleApprovePopup()}
            >
              Approve
            </button>
          ) : (
            <button
              className="btn btn-secondary mt-4 ml-4"
              onClick={() => this.handlePendingPopup()}
            >
              Verify
            </button>
          )
        ) : (
          ""
        )}

        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRembInfoPopup}
          onOk={this.handlePageChange}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="mb-2 mt-4">EXPENSE DESCRIPTION</h3>
              <div className="cm_overflow_reimbur">
                <p>{expenseDescription}</p>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        {/* {bill Show modal} */}

        <ViewUploadedDocument
          show={openRembBillPopup}
          data={bill}
          handleClose={this.closeModal}
        />

        {/*  Edit modal------------>*/}

        <EditReimbursementModal
          show={show}
          handleShow={this.handleShow}
          handleClose={this.handleClose}
          disabledDate={disabledDate}
          month={month}
          handleDateChange={this.handleDateChange}
          editReimbursementType={editReimbursementType}
          handleEditReimbursementType={this.handleEditReimbursementType}
          editTitle={editTitle}
          handleEditTitle={this.handleEditTitle}
          titleErrorMsg={titleErrorMsg}
          expense={expense}
          expenseErrorMsg={expenseErrorMsg}
          amount={amount}
          handleChange={this.handleChange}
          amountErrorMsg={amountErrorMsg}
          uploadBillErrorMsg={uploadBillErrorMsg}
          handleFileChange={this.handleFileChange}
          editUploadFiles={editUploadFiles}
          loader={loader}
          handleSubmit={this.handleSubmit}
          handleDeleteIconClick={this.handleDeleteIconClick}
          setState={this.setState.bind(this)}
        />
        {/* approve reimbursement module  */}
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openApprovePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="text-center mb-2 mt-3">
                Do you want to approve <br />
                the reimbursement ?
              </h3>
              <div className="text-center mt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div
                        className={`form-group col-6 reimbursement-action-modal${
                          approvedAmountErrorMessage ? "input_error" : ""
                        }`}
                      >
                        <label className="sub">Approved Amount</label>

                        <input
                          maxLength="30"
                          type="text"
                          className="form-control"
                          name="title"
                          value={approvedAmount}
                          onChange={this.handleChange}
                        />
                        {approvedAmountErrorMessage ? (
                          <span className="error_msg">
                            {approvedAmountErrorMessage}
                          </span>
                        ) : null}
                      </div>

                      <div
                        className={`form-group col-6${
                          alreadyGivenAmountErrorMessage ? "input_error" : ""
                        }`}
                      >
                        <label className="sub">Already given Amount</label>
                        <input
                          inputMode="numeric"
                          type="number"
                          // step="any"
                          className="form-control"
                          name="amount"
                          value={alreadyGivenAmount}
                          onChange={this.handleChangeLessAmount}
                        />
                        {alreadyGivenAmountErrorMessage ? (
                          <span className="error_msg">
                            {alreadyGivenAmountErrorMessage}
                          </span>
                        ) : null}
                      </div>
                      <div className={`form-group col-12`}>
                        <label className="sub">
                          Already Given Amount Description
                        </label>
                        <textarea
                          inputMode="numeric"
                          type="number"
                          // step="any"
                          className="form-control"
                          name="amount"
                          value={alreadyGivenAmountDescription}
                          onChange={this.handleChangeLessAmountDescription}
                        />
                        {/* {amountErrorMsg ? (
                                                    <span className="error_msg">{amountErrorMsg}</span>
                                                ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => this.handleActionApprove(2)}
                  className="btn btn-primary mr-4 cm_btn3"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        {/* Reject popup */}
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRejectPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="text-center mb-2 mt-3">
                Do you want to reject <br /> the reimbursement ?
              </h3>
              <div className="submit_button_margin mt-4">
                <button
                  type="button"
                  onClick={() => this.handleActionReject(3)}
                  className="btn btn-primary cm_btn3"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
      </div>
    );
  }
}
export default ReimbursementView;
const ActionImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

// const Button = styled.button`
//       background-color: rgb(52, 123, 222);
//       cursor: pointer;
//       width: 200px;
//       border: none;
//       padding: 0.4rem;
//       border-radius: 10px;
//       color: white;
//       font-size: medium;
//       `;

import React from 'react';
import {  Loader, PasswordIcon, EyeIcon } from '../svg';


import { resetPassword } from '../../config/services/employeeServices';


class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            password: '',
            confirmPassword: '',
            passwordErrorMsg: '',
            confirmPasswordErrorMsg: '',
            showPassword: false,
            responseError: '',
        }
    };
    handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });


    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        responseError: ''
    });

    handleValidetion = () => {
        let validate = true;

        const { password, confirmPassword } = this.state;

        if ((password === '' || password === undefined)) {
            validate = false;
            this.setState({ passwordErrorMsg: 'New password is required' })
        } else if (password.length < 6) {
            validate = false;
            this.setState({ passwordErrorMsg: 'Minimum 6 letter password required' })
        } else {
            this.setState({ passwordErrorMsg: '' })
        }

        if ((confirmPassword === '' || confirmPassword === undefined)) {
            validate = false;
            this.setState({ confirmPasswordErrorMsg: 'Confirm password is required' })
        } else if (confirmPassword.length < 6) {
            validate = false;
            this.setState({ confirmPasswordErrorMsg: 'Minimum 6 letter password required' })
        } else {
            this.setState({ confirmPasswordErrorMsg: '' })
        }

        if (password !== confirmPassword) {
            validate = false;
            this.setState({ confirmPasswordErrorMsg: 'New password and confirm password does not match' })
        }


        return validate
    }
    handleSubmit = event => {
        event.preventDefault();
        this.setState({ loader: true })

        if (this.handleValidetion()) {
            this.setState({
                loader: true
            })
            let obj = {
                'accessToken': this.props.match.params.id,
                'newPassword': this.state.password
            }
            resetPassword(obj)
                .then(res => {

                    if (res.data.statusCode == 1) {
                        window.location.href = '/reset-success-msg';

                    } else {
                        this.setState({
                            loader: false,
                            responseError: res.data.error.errorMessage
                        })
                    }
                })
                .catch(e => {
                    this.setState({ loader: false, responseError: "Something went wrong" })
                })

        }
        else {
            this.setState({ loader: false })
        }

    }
    render() {
        let { password, confirmPassword, passwordErrorMsg, showPassword, confirmPasswordErrorMsg, loader } = this.state;
        return (
            <div className="login_wrapper d-flex align-items-center">
                {
                    loader && <Loader />
                }
                <div className="container cm_container bg_white d-flex align-items-center">
                    <div className="page_content text-center">
                        <form className="fp_form cm_width_100" onSubmit={this.handleSubmit} >
                            <h4 className=" cm_heading mb-5">RESET PASSWORD </h4>


                            <div className={`form-group ${passwordErrorMsg ? 'input_error' : ''}`}>
                                <span className="cm_icon emal_icon"><PasswordIcon /></span>
                                <span onClick={this.handleShowPassword} className={`eye_icon ${this.state.showPassword ? '' : 'slesh'}`}><EyeIcon /></span>
                                <input
                                    maxLength="50"
                                    className={`form-control`}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                    placeholder="New password"
                                    name="password"
                                    value={password}
                                    onChange={this.handleChange}
                                />
                                {passwordErrorMsg ? <span className="error_msg">{passwordErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${confirmPasswordErrorMsg ? 'input_error' : ''}`}>
                                <span className="cm_icon emal_icon"><PasswordIcon /></span>
                                <span onClick={this.handleShowPassword} className={`eye_icon ${this.state.showPassword ? '' : 'slesh'}`}><EyeIcon /></span>
                                <input
                                    maxLength="50"
                                    className={`form-control`}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                    placeholder="Confirm password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={this.handleChange}
                                />
                                {confirmPasswordErrorMsg ? <span className="error_msg">{confirmPasswordErrorMsg}</span> : null}
                            </div>

                            <button className="btn btn-lg btn-primary btn-block" type="submit">Save</button>
                        </form>

                    </div>

                </div></div>

        );
    }
}
export default ResetPassword;

import { getTeam } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    GET_TEAM_REQUEST,
    GET_TEAM_SUCCESS,
    GET_TEAM_FAILURE,
} from "./actionType";


const getTeamByAdminAction = (props) => {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getTeamByAdminRequest());
        getTeam(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        loader: false,
                        teamList: res.data.responseData?.teamDetails,
                        dataCount: res.data.responseData?.teamMembersLength
                    }
                })
            }
        })
            .catch((error) => {
                dispatch(getTeamByAdminFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const getTeamByAdminRequest = () => {
    return {
        type: GET_TEAM_REQUEST,
    };
};
const getTeamByAdminSuccess = (data) => {
    return {
        type: GET_TEAM_SUCCESS,
        payload: data,
    };
};
const getTeamByAdminFailure = (error) => {
    return {
        type: GET_TEAM_FAILURE,
        payload: error,
    };
};
export {
    getTeamByAdminAction,
    getTeamByAdminRequest,
    getTeamByAdminSuccess,
    getTeamByAdminFailure,
};
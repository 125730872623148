import setting from "./settings";

export default (() => {
  return {
    EMPLOYEE_LOGIN: setting.api.url + "employee/login",
    GET_ADMIN_PROFILE: setting.api.url + "employee/getProfile",
    GRANT_ACCESS: setting.api.url + "employee/grantAccess",
    EMPLOYEE_LOGOUT: setting.api.url + "employee/logout",
    FORGOT_PASSWORD: setting.api.url + "employee/forgotPassword",
    RESET_PASSWORD: setting.api.url + "employee/resetPassword",
    CHANGE_PASSWORD: setting.api.url + "employee/changePassword",
    SEND_WISHES: setting.api.url + "employee/sendWishes",
    ADD_EMPLOYEE: setting.api.url + "employee/addEmployee",

    GET_EMPLOYEE_LIST: setting.api.url + "employee/employeeList",
    GET_EMPLOYEE_LIST_BY_MANAGER:
      setting.api.url + "employee/employeeListByManager",
    GET_EMPLOYEE_ID_LIST: setting.api.url + "employee/getEmpIdList",
    GET_EMPLOYEE_DETAIL: setting.api.url + "employee/getEmpProfileByHr",
    ADD_EMPLOYEE_EDUCATION: setting.api.url + "employee/addEducation",
    ADD_ADDITIONAL_QUALIFICATION: setting.api.url + "employee/addQualification",
    ADD_SKILL_EMP: setting.api.url + "employee/addSkill",
    ADD_OTHER_SKILL_EMP: setting.api.url + "employee/addOtherSkill",
    EDIT_SKILL_EMP: setting.api.url + "employee/editSkill",
    ADD_EMP_TO_SKILL: setting.api.url + "employee/editSkillofMultiEmp",
    ADD_EMP_TO_DESIGNATION: setting.api.url + "employee/editDesgofMultiEmp",
    ADD_EMPLOYEE_DOCUMENT: setting.api.url + "employee/addDocuments",
    DELETE_EMPLOYEE_EDUCAION: setting.api.url + "employee/deleteEducation",
    DELETE_EMPLOYEE_QUALIFICATION:
      setting.api.url + "employee/deleteQualification",
    DELETE_EMPLOYEE_DOCUMENT: setting.api.url + "employee/deleteDocument",
    EDIT_EMPLOYEE_PROFILE: setting.api.url + "employee/editEmpByHr",
    EDIT_EMPLOYEE_EDUCATION: setting.api.url + "employee/updateEducation",
    EDIT_TEMP_COMP_OFF_BY_ADMIN: setting.api.url + "employee/editTempCompOff",
    EDIT_EMPLOYEE_QUALIFICATION:
      setting.api.url + "employee/updateQualification",
    DELETE_EMPLOYEE_PROFILE: setting.api.url + "employee/deleteEmployee",
    MARK_PAID_LEAVE_EMP: setting.api.url + "employee/giveCompOff",
    TEMP_COMPOFF_LEAVE_GIVEN_BY_ADMIN:
      setting.api.url + "employee/giveTempCompOff",
    GET_PROJECT: setting.api.url + "project/projectListByEmp",
    GET_PROJECT_ID: setting.api.url + "project/getProject",
    GET_PROJECT_BY_MANAGER: setting.api.url + "project/getProjectByManager",
    ADD_PROJECT: setting.api.url + "project/addProject",
    ADD_PROJECT_BY_MANAGER: setting.api.url + "project/addProjectByManager",
    PROJECT_LIST: setting.api.url + "project/list",
    PROJECT_LIST_BY_MANAGER: setting.api.url + "project/projectListByManager",
    EDIT_PROJECT: setting.api.url + "project/update",
    EDIT_PROJECT_BY_MANAGER: setting.api.url + "project/updateByManager",
    PROJECT_ACTION: setting.api.url + "project/projectAction",
    DELETE_PROJECT: setting.api.url + "project/delete",
    DELETE_PROJECT_BY_MANAGER: setting.api.url + "project/deleteByManager",
    GET_ACTIVITY_LOGS: setting.api.url + "dailyWork/dailyWorkByProjectId",
    GET_ACTIVITY: setting.api.url + "dailyWork/dailyWorkByProjectEmpId",
    GET_ACTIVITY_EMPID: setting.api.url + "dailyWork/dailyWorkByEmpId",
    GET_PROJECT_VIEW: setting.api.url + "project/getProjectInfoByEmp",
    EDIT_NEW_STANDUP: setting.api.url + "dailyWork/editDailyWork",
    EDIT_TEMP_COMP_OFF_COUNT_BY_ADMIN:
      setting.api.url + "employee/editCompOffCount",
    ADD_DESIGNATION: setting.api.url + "designation/add",
    GET_DESIGNATION: setting.api.url + "designation/list",
    EDIT_DESIGNATION: setting.api.url + "designation/update",
    DELETE_DESIGNATION: setting.api.url + "designation/delete",
    GET_VIEW_DESIGNATION:
      setting.api.url + "employee/getEmployeeByDesignationId",

    ADD_SKILL: setting.api.url + "skill/add",
    GET_SKILL: setting.api.url + "skill/list",
    EDIT_SKILL: setting.api.url + "skill/update",
    DELETE_SKILL: setting.api.url + "skill/delete",
    GET_VIEW_SKILL: setting.api.url + "employee/getEmployeeBySkillId",

    ADD_DEPARTMENT: setting.api.url + "department/add",
    DEPARTMENT_LIST: setting.api.url + "department/list",
    EDIT_DEPARTMENT: setting.api.url + "department/update",
    DELETE_DEPARTMENT: setting.api.url + "department/delete",
    DELETE_DEPARTMENT_MEMBER: setting.api.url + "employee/editEmployeeFromDept",
    GET_VIEW_DEPARTMENT: setting.api.url + "employee/getEmployeeByDeptId",

    GET_DEVICE_LIST: setting.api.url + "device/list",
    GET_EMPLOYEE_DEVICE_LIST: setting.api.url + "device/empList",
    ADD_DEVICE: setting.api.url + "device/addDevice",
    EDIT_DEVICE: setting.api.url + "device/updateByManager",
    DELETE_DEVICE: setting.api.url + "device/delete",
    GET_DEVICE_DETAILS: setting.api.url + "device/getDeviceDetails",
    GET_ADMIN_DEVICE_LIST: setting.api.url + "device/deviceListAdmin",
    GET_DEVICE_REQUEST_LIST: setting.api.url + "device/getAssignDeviceRequest",
    ADD_DEVICE_REQUEST: setting.api.url + "device/requestDevice",
    UPDATE_DEVICE_REQUEST: setting.api.url + "device/updateDeviceRequest",

    GET_DEVICE_LIST_BY_MANAGER: setting.api.url + "device/listByManager",
    GET_DEVICE_LIST_BY_EMPLOYEE: setting.api.url + "device/listByEmp",
    GET_DEVICE_LIST_BY_EMPLOYEES: setting.api.url + "device/deviceListEmployee",
    ADD_DEVICE_MANAGER: setting.api.url + "device/addByManager",
    EDIT_DEVICE_MANAGER: setting.api.url + "device/updateByManager",
    DELETE_DEVICE_MANAGER: setting.api.url + "device/deleteByManager",

    APPLY_LEAVE: setting.api.url + "leave/newApplyLeave",
    GET_LEAVE_LIST: setting.api.url + "leave/newLeaveList",
    LEAVE_ACTION: setting.api.url + "leave/newLeaveAction",
    EDIT_LEAVE_BY_ADMIN: setting.api.url + "leave/editPendingLeave",
    EDIT_APPROVED_SHORT_LEAVE_BY_ADMIN:
      setting.api.url + "leave/editLeaveStatus",

    MARK_PAID_LEAVE: setting.api.url + "leave/addCompensatoryOffByAdmin",
    EDIT_MARK_PAID_LEAVE: setting.api.url + "leave/editTotalLeaves",
    CARRY_FORWARD: setting.api.url + "leave/carryForward",
    DELETE_LEAVE: setting.api.url + "leave/deleteApplyLeave",
    PROJECT_ACTION_BY_MANAGER:
      setting.api.url + "project/projectActionByManager",

    GET_TICKET: setting.api.url + "ticket/viewTicket",
    RESOLVE_TICKET: setting.api.url + "ticket/resolveTicket",
    DELELTE_TICKET: setting.api.url + "ticket/deleteTicket",
    GET_REIMBURSE_LIST: setting.api.url + "reimbursement/reimbursementList",
    GET_REIMBURSE_BY_EMPID:
      setting.api.url + "reimbursement/getReimburseByEmpId",
    REIMBURSEMENT_ACTION: setting.api.url + "reimbursement/reimbursementAction",
    REIMBURSE_MONTHLY_RECORD:
      setting.api.url + "reimbursement/reimburseMonthlyRecord",

    DAILY_ATTENDANCE: setting.api.url + "attendence/newDailyAttendence",
    DAILY_ATTENDANCE_BY_CSV:
      setting.api.url + "attendence/dailyAttendenceByCsv",
    DAILY_ATTENDANCE_BY_EXCEL:
      setting.api.url + "attendence/newDailyAttendenceByExcel",

    GET_ATTENDANCE_LIST: setting.api.url + "attendence/newAttendenceList",
    EDIT_ATTENDANCE: setting.api.url + "attendence/newEditAttendence",
    DELETE_ATTENDANCE: setting.api.url + "attendence/deleteAttendence",
    // ATTENDANCE_SEND_MAIL: setting.api.url + "attendence/sendMail",
    ATTENDANCE_SEND_MAIL: setting.api.url + "attendence/sendMailToAllLateEmp",

    GET_APPRAISAL_LIST: setting.api.url + "appraisal/appraisalList",
    ADD_APPRAISAL: setting.api.url + "appraisal/addAppraisal",
    EDIT_APPRAISAL: setting.api.url + "appraisal/editAppraisal",
    POSTPONE_APPRAISAL: setting.api.url + "appraisal/appraisalAction",
    GET_APPRAISAL_CYCLE: setting.api.url + "appraisal/appraisalCycle",
    GET_ATTENDENCE_EMPID: setting.api.url + "attendence/getAttendence",
    GET_ATTENDENCE_BY_EMP:
      setting.api.url + "attendence/newViewAttendenceByEmp",
    GET_ATTENDENCE_EMP:
      setting.api.url + "attendence/viewMonthlyAttendenceByEmp",
    GET_ATTENDENCE_MONTHLY_EMPID:
      setting.api.url + "attendence/newGetMonthlyAttendence",
    ADD_HOLIDAY: setting.api.url + "holidayList/add",
    GET_HOLIDAY_LIST: setting.api.url + "holidayList/list",
    HOLIDAY_LIST_EMP: setting.api.url + "holidayList/holidayListByEmp",
    GET_DASHBOARD_DETAILS: setting.api.url + "employee/adminDashboard",
    GET_BIRTHDAY: setting.api.url + "employee/getBirthdayForDash",
    GET_WORK_ANNIVERSARY: setting.api.url + "employee/getWorkAnniversary",
    SEND_WISHES_TEMPLATE: setting.api.url + "email/sendEmail",
    SEND_WISHES_DRIVE_TEMPLATE: setting.api.url + "email/sendTemplate",
    GET_ADMIN_DETAILS: setting.api.url + "employee/getAllAdmin",
    ADD_NEW_STANDUP: setting.api.url + "dailyWork/dailyWork",

    GET_EMPLOYEE_REIMBURSEMENT:
      setting.api.url + "reimbursement/reimburseHistory",
    CLAIM_REIMBURSEMENT: setting.api.url + "reimbursement/reimbursementClaims",
    EMPLOYEE_VIEW_TICKET: setting.api.url + "ticket/viewTicketByEmp",
    ADD_TICKET: setting.api.url + "ticket/addTicket",
    EDIT_TICKET: setting.api.url + "ticket/updateTicket",
    REOPEN_TICKET: setting.api.url + "ticket/ticketStatusByEmp",
    GET_EMPLOYEE_DASHBOARD: setting.api.url + "employee/newEmpDashboard",
    GET_EMPLOYEE_LEAVES: setting.api.url + "leave/getLeavesByUser",
    GET_EMPLOYEE_LEAVES_STATUS: setting.api.url + "leave/leaveStatus",
    GET_TOTAL_LEAVES: setting.api.url + "leave/getTotalLeaves",
    GET_TOTAL_LEAVES_EMP: setting.api.url + "leave/getTotalLeavesByEmp",

    INVESTEMENT_FORM_SUBMIT: setting.api.url + "investment/addInvestment",
    EDIT_INVESTMENT_FORM: setting.api.url + "investment/editInvestment",

    GET_INVESTMENT_DETAILS: setting.api.url + "investment/list",
    GET_INVESTMENT_DETAILS_EMP: setting.api.url + "investment/getById",
    DELETE_INVESTMENT_FORM: setting.api.url + "investment/deleteInvestment",
    UPLOAD_RENT_SLIP: setting.api.url + "investment/addRentSlip",
    GET_ADMIN_EMPLOYEE_LEAVES_STATUS:
      setting.api.url + "leave/newLeaveStatusByAdmin",
    EMPLOYEE_HOLIDAY: setting.api.url + "holidayList/holidayListByEmp",
    REMOVE_GRANT_ACCESS: setting.api.url + "employee/removeGrantAccess",
    EDIT_HOLIDAY: setting.api.url + "holidayList/update",
    DELETE_HOLIDAY: setting.api.url + "holidayList/delete",
    GET_HOLIDAY: setting.api.url + "holidayList/getHolidayById",
    EDIT_REQUEST: setting.api.url + "employee/editRequest",
    GET_DASHBOARD_APPRAISAL: setting.api.url + "employee/getAppraisal",
    EDIT_PROFILE_PIC: setting.api.url + "employee/editProfilePicture",
    EDIT_PROFILE_PIC_BY_EMP:
      setting.api.url + "employee/editProfilePictureByEmp",
    EMPLOYEE_ACTIVE: setting.api.url + "employee/employeeAction",
    EMPLOYEE_WALLET_UPDATE: setting.api.url + "employee/creditAmountInWallet",

    GET_EMPLOYEE_RESUME_LIST: setting.api.url + "resume/list",
    EMPLOYEE_RESUME_DELETE: setting.api.url + "resume/delete",
    ADD_EMPLOYEE_RESUME: setting.api.url + "resume/add",
    UPDATE_EMPLOYEE_RESUME: setting.api.url + "resume/update",
    GET_RESUME_BY_EMPLOYEE_ID: setting.api.url + "resume/getByEmpId",
    ADD_EMPLOYEE_RESUME_BY_ADMIN: setting.api.url + "resume/addByAdmin",

    GET_VENDORS_LIST: setting.api.url + "food/vendors",
    GET_ORDER_LIST: setting.api.url + "order/list",

    GET_TRANSACTION_LIST: setting.api.url + "transaction/list",
    GET_TRANSACTION_EXCEL_SHEET: setting.api.url + "transaction/getExcelSheet",

    GET_INTERVIEW_LIST: setting.api.url + "interview/list",
    GET_INTERVIEWER_LIST: setting.api.url + "interviewPanel/interviewerList",

    INTERVIEW_DELETE: setting.api.url + "interview/deleteCandidate",
    ADD_CANDIDATE_BY_ADMIN: setting.api.url + "interview/addCandidate",
    ADD_INTERVIEWER_BY_ADMIN: setting.api.url + "interviewPanel/addInterviewer",

    GET_INTERVIEW_BY_ID: setting.api.url + "interview/getById",
    EDIT_INTERVIEW_BY_ADMIN: setting.api.url + "interview/editCandidate",
    EDIT_INTERVIEWER_BY_ADMIN:
      setting.api.url + "interviewPanel/editInterviewer",

    GET_CANDIDATE_BY_ID: setting.api.url + "interview/getById",
    DELETE_INTERVIEWER_BY_ID:
      setting.api.url + "interviewPanel/deleteInterviewer",
    CHANGE_INTERVIEWER_STATUS_BY_ID:
      setting.api.url + "interviewPanel/interviewerStatus",

    EDIT_REIMBURSEMENT: setting.api.url + "reimbursement/editReimburse",
    DELETE_REIMBURSEMENT: setting.api.url + "reimbursement/deleteReimbursement",
    REIMBURSEMENT_PRINT:
      setting.api.url + "reimbursement/reimbursementApprovedDownload",
    GET_REIMBURSERMENT_HISTORY_BY_MANAGER:
      setting.api.url + "reimbursement/reimburseHistoryForPM",
    GET_REIMBURSERMENT_MONTHLY_BY_MANAGER:
      setting.api.url + "reimbursement/reimburseMonthlyRecordForPM",
    DEDUCT_LEAVE_BY_ADMIN: setting.api.url + "leave/newDeductLeave",
    ACTIVITY_BY_ADMIN: setting.api.url + "activity/activityList",
    GET_INTERVIEWER_STATUS:
      setting.api.url + "interviewPanel/getInterviewerStatus",
    GET_CONTACTLIST: setting.api.url + "contact/getContact",
    ADD_CONTACT: setting.api.url + "contact/addContact",
    EDIT_CONTACT: setting.api.url + "contact/editContact",
    GET_ASSIGN_CANDIDATE_LIST:
      setting.api.url + "interview/candidateListForAssign",
    GET_ASSIGN_INTERVIEWER_LIST:
      setting.api.url + "interviewPanel/interviewerListForAssign",
    ASSIGN_INTERVIEW_CANDIDATE: setting.api.url + "interview/assignCandidate",
    GET_INTERVIEWS_BY_INTERVIEWER:
      setting.api.url + "interviewPanel/getInterviewsByInterviewerId",
    EDIT_ASSIGN_CANDIDATE: setting.api.url + "interview/editAssignCandidate",
    ADD_TEAM_BY_ADMIN: setting.api.url + "teams/add",
    GET_TEAM_BY_ADMIN: setting.api.url + "teams/list",
    DELETE_TEAM_BY_ADMIN: setting.api.url + "teams/deleteTeam",
    GET_TEAM_MEMBERS_BY_ADMIN: setting.api.url + "teams/getTeamMembers",
    GET_TEAM_MEMBERS_DETAILS_BY_ADMIN:
      setting.api.url + "teams/getTeamMemberDetails",

    DELETE_TEAM_MEMBERS: setting.api.url + "teams/removeTeamMembers",
    ADD_TEAM_MEMBER_BY_ADMIN: setting.api.url + "teams/addTeamMembers",
    EDIT_TEAM_BY_ADMIN: setting.api.url + "teams/editTeam",
    GET_PROJECT_ACTIVITY_EXCEL: setting.api.url + "dailyWork/exportAsExcel",
    PROJECT_LEAVE: setting.api.url + "project/listOfLeave",
    ASSIGN_CONTACT_TO_EMPLOYEE: setting.api.url + "contact/assignContact",
    DELETE_CONTACT: setting.api.url + "contact/deleteContact",

    DEVICE_DETAILS: setting.api.url + "device/deviceTrack",
    ADD_CANDIDATE_FEEDBACK: setting.api.url + "clientInterview/add",
    EDIT_CANDIDATE_FEEDBACK: setting.api.url + "clientInterview/edit",
    GET_CANDIDATE_FEEDBACK: setting.api.url + "clientInterview/list",
    DELETE_CANDIDATE_FEEDBACK: setting.api.url + "clientInterview/delete",
    EXPORT_CONTACT_EXCEL: setting.api.url + "contact/exportExcelList",
    EXPORT_INTERVIEW_CANDIDATE_EXCEL:
      setting.api.url + "interview/excelSheetList",
    EXPORT_CANDIDATE_FEEDBACK_EXCEL:
      setting.api.url + "clientInterview/ExcelSheetList",
    EXPORT_DEVICE_DETAILS: setting.api.url + "device/deviceListExcelSheet",

    ADD_EMPLOYEE_CTC: setting.api.url + "salary/addCtc",
    GET_EMPLOYEE_CTC: setting.api.url + "salary/getList",
    GET_EMPLOYEE_MONTHLY_SALARY_STATS:
      setting.api.url + "salary/salaryListExport",
    EDIT_EMPLOYEE_CTC: setting.api.url + "salary/editCtc",
    EXPORT_EMPLOYEES_SALARY: setting.api.url + "salary/generateSalary",
    EXPORT_EMPLOYEES_REIMBURSEMENT:
      setting.api.url + "salary/generateReimbursement",
    GET_EMPLOYEES_MONTHLY_REIMBURSEMENT_STATS:
      setting.api.url + "salary/reimbursementListExport",
    GET_PROJECT_MEMBERS_PROJECT_WORKING_DURATION:
      setting.api.url + "project/duration",
    GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID:
      setting.api.url + "dailyWork/dailyWorkByEmpId",
    GET_DATA_FOR_TEMPLATES: setting.api.url + "project/exportTemplate",
    ADD_AWARD: setting.api.url + "award/addAward",
    EDIT_AWARD: setting.api.url + "award/editAward",
    GET_AWARD: setting.api.url + "award/awardList",
    DELETE_AWARD: setting.api.url + "award/deleteAward",
    GET_TEAM_COMPARE_DATA: setting.api.url + "teams/compareTeam",
    GET_TL_AND_ATL: setting.api.url + "getTLAndATL",
    GET_EMPLOYEE_ON_HOLD: setting.api.url + "salary/getOnHoldList",
    ADD_EMPLOYEE_ON_HOLD: setting.api.url + "salary/addOnHold",
    EDIT_EMPLOYEE_ON_HOLD: setting.api.url + "salary/editOnHold",

    GET_OTP: setting.api.url + "salary/genOpt",
    GET_SECRET_KEY: setting.api.url + "salary/getSecretKey",
    VALIDATE_OTP: setting.api.url + "salary/verifyOtp",
    EDIT_RELEASE_DATE: setting.api.url + "salary/editOnHoldDate",
    ADD_AWARD_NAME: setting.api.url + "award/addAwardName",
    GET_AWARD_NAME: setting.api.url + "award/awardNameList",
    DELETE_AWARD_NAME: setting.api.url + "award/deleteAwardName",
    ADD_UNDER_REPAIR_DEVICE: setting.api.url + "device/addRepairDevice",
    EDIT_UNDER_REPAIR_DEVICE: setting.api.url + "device/updateRepairDevice",
    GET_COURIER_LIST_BY_ADMIN: setting.api.url + "courier/allCouriers",
    ADD_COURIER_DETAILS_BY_ADMIN: setting.api.url + "courier/addCourierDetails",
    EDIT_COURIER_DETAILS_BY_ADMIN:
      setting.api.url + "courier/editCourierDetails",
    DELETE_COURIER_DETAILS_BY_ADMIN: setting.api.url + "courier/deleteCourier",
    ADD_EMP_IN_ORG: setting.api.url + "organization/add",
    EDIT_EMP_IN_ORG: setting.api.url + "organization/bulkUpdate",
    UPDATE_EMP_IN_ORG: setting.api.url + "organization/update",
    DELETE_EMP_IN_ORG: setting.api.url + "organization/delete",
    GET_EMP_IN_ORG: setting.api.url + "organization/list",

    GET_POLICIES: setting.api.url + "privacy/privacyList",
    ADD_POLICIES: setting.api.url + "privacy/addPrivacy",
    EDIT_POLICIES: setting.api.url + "privacy/updatePrivacy",
    DELETE_POLICIES: setting.api.url + "privacy/deletePrivacy",
    UPLOAD_FILES: setting.api.url + "employee/uploadFiles",
  };
})();

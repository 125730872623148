import {
    ADD_CANDIDATE_FEEDBACK_REQUEST,
    ADD_CANDIDATE_FEEDBACK_SUCCESS,
    ADD_CANDIDATE_FEEDBACK_FAILURE,
} from "./actionType";

import { addCandidateFeedBack, } from "../../config/services/employeeServices";
import { getCandidateFeedBackAction } from "./getCandidateFeedBackAction";

const addCandidateFeedBackAction = (data1, query, setShowPanelModal, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick) => {
    return (dispatch) => {
        dispatch(addCandidateFeedBackRequest());
        addCandidateFeedBack(data1)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(addCandidateFeedBackFailure(res.data));
                    setFailResMsg(true)
                    setLoader(false)
                } else {
                    dispatch(addCandidateFeedBackSuccess(res.data));
                    setResMsg(true)
                    setTimeout(() => (
                        handleCancelButtonClick(),
                        dispatch(getCandidateFeedBackAction(query)),
                        setLoader(false)
                    ), 3000);

                }
            })
    };
};

const addCandidateFeedBackRequest = () => {
    return {
        type: ADD_CANDIDATE_FEEDBACK_REQUEST,
    };
};
const addCandidateFeedBackSuccess = (data) => {
    return {
        type: ADD_CANDIDATE_FEEDBACK_SUCCESS,
        payload: data,
    };
};
const addCandidateFeedBackFailure = (error) => {
    return {
        type: ADD_CANDIDATE_FEEDBACK_FAILURE,
        payload: error,
    };
};
export {
    addCandidateFeedBackAction,
    addCandidateFeedBackRequest,
    addCandidateFeedBackSuccess,
    addCandidateFeedBackFailure,
};

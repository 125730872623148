import React, { useEffect, useReducer } from "react";
import ResponseFilter from "../../ResponseFilter";
import {
  getEmployeeDashboard,
  getBirthday,
  getWorkAnniversary,
} from "../../config/services/employeeServices";
import { reducer, type } from "../helper/Constant";
import { useSelector } from "react-redux";
import { leaveValidation } from "./DashboardConstant";
import DashboardFirstSection from "./DashboardUtils/DashboardFirstSection";
import BirthdayWorkRender from "./DashboardUtils/BirthdayWorkRender";

const initialState = {
  holidaysList: [],
  birthday: [],
  workAnniversary: [],
  resMsg: false,
  loginResponseMessage: false,
  greetings: "",
  leaveType: "",
  startDate: "",
  leaveTypeErr: false,
  startDateErr: false,
};

const Home = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    holidaysList,
    birthday,
    loginResponseMessage,
    greetings,
    leaveType,
    startDate,
    leaveTypeErr,
    startDateErr,
    workAnniversary,
  } = state;
  const profile = useSelector((state) => state.getEmpProfileByAdminReducer);

  useEffect(() => {
    const message = localStorage.getItem("loginMessage");
    if (message) {
      dispatch({ type: type, payload: { loginResponseMessage: true } });
      setTimeout(() => {
        dispatch({ type: type, payload: { loginResponseMessage: false } });
        localStorage.removeItem("loginMessage");
      }, 3000);
    }
    greet();
    setTimeout(() => {
      dispatch({ type: type, payload: { loginResponseMessage: "" } });
      localStorage.removeItem("loginMessage");
    }, 3000);
    getDashboardView();
    getEmpBirthday();
    getEmpWorkAnniversary();
  }, []);

  const getDashboardView = () => {
    getEmployeeDashboard().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            holidaysList: resData.empDashboardDetails.holidayList,
            loader: false,
          },
        });
      }
    });
  };

  const getEmpBirthday = () => {
    getBirthday()
      .then((res) => {
        if (res.data.statusCode == 1) {
          let { status, resData } = ResponseFilter(res);
          if (status) {
            dispatch({
              type: type,
              payload: {
                birthday: resData.birthday,
                loader: false,
              },
            });
          }
        } else {
          dispatch({ type: type, payload: { loader: false } });
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  };

  const getEmpWorkAnniversary = () => {
    getWorkAnniversary()
      .then((res) => {
        if (res.data.statusCode == 1) {
          let { status, resData } = ResponseFilter(res);
          if (status) {
            dispatch({
              type: type,
              payload: {
                workAnniversary: resData.workAnniversary,
                loader: false,
              },
            });
          }
        } else {
          dispatch({ type: type, payload: { loader: false } });
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  };

  const greet = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    if (currentHour < 12) {
      dispatch({
        type: type,
        payload: {
          greetings: "Good morning",
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          greetings: "Good afternoon",
        },
      });
    }
  };
  const clearSelection = () => {
    dispatch({
      type: type,
      payload: {
        leaveType: "",
      },
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "leaveType") {
      dispatch({
        type: type,
        payload: {
          leaveType: value,
          leaveTypeErr: false,
        },
      });
    } else if (name === "leaveDate") {
      dispatch({
        type: type,
        payload: {
          startDate: value,
          startDateErr: false,
        },
      });
    }
  };

  const handleLeaveSubmit = () => {
    if (leaveValidation({ leaveType, startDate, dispatch })) {
      sessionStorage.setItem("dashboard_apply_leave", true);
      sessionStorage.setItem("dashboard_leave_startDate", startDate);
      sessionStorage.setItem("dashboard_leaveType", leaveType);
      window.location.href = "/leaves";
    }
  };
  return (
    <div
      className="body_container cm_bold dashboard_container"
      style={{ backgroundColor: "#eaeaea", paddingTop: "84px !important" }}
    >
      {loginResponseMessage ? (
        <div className="alert alert-success cm_top_fix">Login Successfully</div>
      ) : (
        ""
      )}
      <h4 className="ml-4">
        {greetings}, {profile.data.employeeProfile?.firstName}
      </h4>
      <DashboardFirstSection
        holidaysList={holidaysList}
        leaveTypeErr={leaveTypeErr}
        leaveType={leaveType}
        handleInputChange={handleInputChange}
        clearSelection={clearSelection}
        startDateErr={startDateErr}
        handleLeaveSubmit={handleLeaveSubmit}
        startDate={startDate}
      />
      <BirthdayWorkRender
        birthday={birthday}
        workAnniversary={workAnniversary}
      />
    </div>
  );
};

export default Home;

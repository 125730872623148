import React, { useEffect, useMemo, useReducer } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getDeviceDetils } from '../../config/services/employeeServices'
import { useDispatch } from 'react-redux';
import { reducer } from '../helper/Constant';
import ResponseFilter from '../../ResponseFilter';
import { Loader } from '../svg';
import _ from 'lodash';
import moment from 'moment';
import { CONSTANTS } from '../../config';
import Pagination from 'react-js-pagination';
import qs from 'qs'

let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const initialState = {
    deviceDetails: [],
    loader: false,
    pageNo: 1,
    count: 10,
    sortType: SORT_TYPE_CONSTANTS.Descending,
    sortField: "created"
}


export default function EmployeeDeviceHistory(props) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory()

    const {
        deviceDetails,
        loader,
        pageNo,
        count,
        sortField,
        sortType


    } = state

    const dispatchToAction = useDispatch()

    const deviceValue = type => {
        switch (type) {
            case 1:
                return "Laptop";
                break;

            case 2:
                return "CPU";
                break;

            case 3:
                return "Phone";
                break;

            case 4:
                return 'Mouse';
                break;

            case 5:
                return 'Connectors';
                break;

            case 6:
                return 'Ups';
                break;

            case 7:
                return 'Router';
                break;

            case 8:
                return 'Headphone';
                break;

            case 9:
                return 'wifi-Adaptor';
                break;

            case 10:
                return 'web Camera';
                break;

            case 11:
                return 'keyboard';
                break;

            case 12:
                return 'Monitor';
                break;

            case 13:
                return 'Pendrive';
                break;

            case 14:
                return 'Hard Disk';
                break;

            case 15:
                return 'Ram';
                break;
            case 16:
                return "Mac Mini";
                break;


        }
    }

    useEffect(() => {
        let url =
            props &&
            props.match.path.substring(
                0,
                props && props.match.path.indexOf("/", 2)
            );
        getDeviceDetails()
    }, [pageNo, sortField, sortType])


    useMemo(() => {
        const filterParams = history.location.search.substr(1);
        const filtersFromParams = qs.parse(filterParams);

        dispatch({
            type: 'typeCheckSet', payload: {
                pageNo: filtersFromParams.pageNo ? filtersFromParams.pageNo : 1,
                sortField: filtersFromParams.sortField ? filtersFromParams.sortField : "created",
                sortType: filtersFromParams.sortType ? Number(filtersFromParams.sortType) : SORT_TYPE_CONSTANTS.Descending,
            }
        })

    }, [history.location.search]);

    const getDeviceDetails = () => {
        let params = `employeeId=${props?.match?.params?.id}&sortType=${sortType}&sortField=${sortField}`
        history.push(`?count=${count}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}`)
        dispatch({
            type: 'typeCheckSet', payload: {
                loader: true,
            }
        })
        getDeviceDetils(params).then((res) => {
            let { status, resData } = ResponseFilter(res)
            if (status) {
                dispatch({
                    type: 'typeCheckSet', payload: {
                        loader: false,
                        deviceDetails: resData?.list

                    }
                })

            } else {
                dispatch({
                    type: 'typeCheckSet', payload: {
                        loader: false,
                    }
                })
            }

        });
    }

    const handlePageChange = (pageNo) => {
        dispatch({
            type: 'typeCheckSet', payload: {
                loader: true,
                pageNo: pageNo
            }
        })
    };


    const handleSort = (e) => {
        dispatch({
            type: 'typeCheckSet', payload: {
                loader: false,
                sortField: e,
                statusErrorMsg: "",
                sortType: sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending,
            }
        })
    };

    return (
        <div className="body_container cm_bold">
            {loader && <Loader />}
            <div className="cm_breadcrumb">
                <ul>
                    <li><Link to={{ pathname: `/admin-device` }}> Device</Link><div className="cm_breadcrumb_arrow"></div></li>
                    <li>{deviceDetails[0]?.firstName + " " + deviceDetails[0]?.middleName + " " + deviceDetails[0]?.lastName} Device Details</li>
                </ul>
            </div>
            <>

                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Device Id
                                </th>
                                <th scope="col">Device Type</th>
                                <th scope="col">Device Modal
                                </th>
                                <th scope="col">
                                    Assigned Date
                                    <i
                                        className={
                                            sortType === 1 &&
                                                sortField === "assignedFrom"
                                                ? "fa fa-sort-numeric-asc ml-2"
                                                : "fa fa-sort-numeric-desc ml-2"
                                        }
                                        onClick={() => handleSort("assignedFrom")}
                                    ></i>
                                </th>
                                <th scope="col">
                                    Assigned Till
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!_.isEmpty(deviceDetails)
                                ? deviceDetails.map((device, i) => (
                                    <tr key={device.deviceId}>
                                        <td>{i + 1 + (pageNo - 1) * count}</td>
                                        <td>{device?.deviceDetails?.devId}</td>
                                        <td>
                                            {deviceValue(device?.deviceDetails?.deviceType)}

                                        </td>
                                        <td>
                                            {device?.deviceDetails?.deviceModel}
                                        </td>
                                        {device.assignedFrom !== null ? <td>
                                            {moment
                                                .unix(device.assignedFrom / 1000)
                                                .format("DD/MM/YYYY")}
                                        </td> : <td className="text-center"><strong>_</strong></td>}
                                        {
                                        device.assignedTill !== null  ?
                                         device.assignedTill == 0 ? <td className="text-center"><strong>_</strong></td> :
                                        <td>
                                            {moment
                                                .unix(device.assignedTill / 1000)
                                                .format("DD/MM/YYYY")}
                                        </td> : <td className="text-center"><strong>_</strong></td>}
                                    </tr>
                                ))
                                : "No data available"}
                        </tbody>
                    </table>
                </div>
                {/* {deviceDetails.length > 10 ? (
                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={deviceDetails.length}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange}
                    />
                ) : (
                    ""
                )} */}
            </>


        </div>
    )
}

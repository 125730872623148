import {
    EDIT_INTERVIEWER_BY_ADMIN_REQUEST,
    EDIT_INTERVIEWER_BY_ADMIN_SUCCESS,
    EDIT_INTERVIEWER_BY_ADMIN_FAILURE,
} from "./actionType";

import { editInterviewerByAdminService } from "../../config/services/employeeServices";
import { getInterviewerList } from "./getInterviewerActionList";

const editInterviewerByAdmin = (data1, setLoader, query, showEditInterview, setResMsg, setFailResMsg, handleCancelButtonClick) => {
    return (dispatch) => {
        dispatch(editInterviewerByAdminRequest());
        editInterviewerByAdminService(data1)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(editInterviewerByAdminFailure(res.data));
                    // setErrorText("Failed to edit");
                    setFailResMsg(true)
                    setLoader(false)

                } else {
                    dispatch(editInterviewerByAdminSuccess(res.data));
                    // setShowModal(false);
                    setResMsg(true)
                    setTimeout(() => (
                        handleCancelButtonClick(),
                        dispatch(getInterviewerList(query)),
                        setLoader(false)
                    ), 3000);

                }
            })
            .catch((error) => {
                dispatch(editInterviewerByAdminFailure(error));
                setFailResMsg(true)
                setLoader(false)
            });
    };
};

const editInterviewerByAdminRequest = () => {
    return {
        type: EDIT_INTERVIEWER_BY_ADMIN_REQUEST,
    };
};
const editInterviewerByAdminSuccess = (data) => {
    return {
        type: EDIT_INTERVIEWER_BY_ADMIN_SUCCESS,
        payload: data,
    };
};
const editInterviewerByAdminFailure = (error) => {
    return {
        type: EDIT_INTERVIEWER_BY_ADMIN_FAILURE,
        payload: error,
    };
};
export {
    editInterviewerByAdmin,
    editInterviewerByAdminRequest,
    editInterviewerByAdminSuccess,
    editInterviewerByAdminFailure,
};

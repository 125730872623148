import React from "react";
import Dropzone from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import { Select, TimePicker } from "antd";
import { DatePicker, Modal as AntModal } from "antd";
import { Collapse, Icon } from "antd";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import ResponseFilter from "../../ResponseFilter";
import pdfpng from "../../asset/imgs/pdfpng.png";
import docpng from "../../asset/imgs/docpng.png";
import {
  addOtherSkillEmp,
  editSkillEmp,
  getSkill,
  addEmployeeDocuments,
  editEmployeeProfileImage,
  getEmployeeDetail,
  addEmployeeEducation,
  addAdditionalQualification,
  editEmployeeProfile,
  getDepartmentList,
  getDesignation,
  editEmployeeEducation,
  editEmployeeQualification,
  deleteEmployeeEducation,
  deleteEmployeeQualification,
  deleteEmployeeDocument,
  markPaidLeave,
} from "../../config/services/employeeServices";
import { DocIcon, RemoveIcon, Loader } from "../svg";
import Calendar from "react-calendar";
import { REQUIRED } from "../attendance/Constants";
import { compOffRegex } from "../leaves/Constants";
import DotLoader from "../../hoc/DotLoader";
import {
  Toastify,
  alternatePhoneRegx,
  bloodGroupValue,
  degreeType,
  employeeRoleValue,
  employeeTypeValue,
  genderValue,
  linkdinIdRegx,
  nameRegex,
  officeLocationValue,
  phoneRegx,
  regx,
  regx1,
  returnFileIcon,
  skypeIdRegx,
  zipRegx,
} from "../helper/Constant";
import { clientSideValue } from "../../config/constants/Constants";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const { Panel } = Collapse;
const format = "HH:mm";

var year = [];

for (var i = 0; i <= 20; i++) {
  year.push(i - 1);
}
var month = [];

for (var i = 0; i <= 11; i++) {
  month.push(i - 1);
}
const { Option } = Select;

class EmployeeProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resAddCompOff: false,
      openCompOffPopup: false,
      assignedTo: [],
      assignedToErrorMsg: [],
      assignedSkill: [],
      assignedSkill1: [],
      assignedOtherSkill: [],
      assignedDesignation: [],
      prev: false,
      imageLoader: false,
      openDeviceInfoPopup: false,
      openEditProfilePopup: false,
      openAddEducationPopup: false,
      openAddDocumentPopup: false,
      openAddQualificationPopup: false,
      openAddSkillPopup: false,
      openEditSkillPopup: false,
      openAddOtherSkillPopup: false,
      openEditOtherSkillPopup: false,
      employeeProfile: [],
      employeeId: "",
      firstName: "",
      experienceYear: "",
      experienceMonth: "",
      lastName: "",
      empId: "",
      id: "",
      email: "",
      personalEmail: "",
      gender: "",
      phone: "",
      alternateMob: "",
      dob: "",
      maritalStatus: "",
      dateOfJoining: "",
      dueAppraisal: "",
      permanentAddressOne: "",
      permanentAddressTwo: "",
      permanentCity: "",
      permanentState: "",
      permanentZip: "",
      permanentCountry: "",
      currentAddressOne: "",
      previousAddressOne: "",
      currentAddressTwo: "",
      currentCity: "",
      currentState: "",
      currentZip: "",
      currentCountry: "",
      education: [],
      resProfileUpdate: false,
      resFailProfileUpdate: false,
      qualification: [],
      designation: [],
      middleName: "",
      firstNameErrorMsg: "",
      middleNameErrorMsg: "",
      docErrorMsg: "",
      collegeNameErrorMsg: "",
      degreeErrorMsg: "",
      documentNameErrorMsg: "",
      openDatePick: false,
      departmentName: "",
      designationName: "",
      departmentId: "",
      designationId: "",
      departmentList: [],
      designationList: [],
      skillsList: [],
      otherSkillDetails: [],
      otherSkillDetails1: [],
      degree: "",
      course: "",
      profileImage: "",
      editProfileImage: "",
      emailErrorMsg: "",
      dueAppraisalErrorMsg: "",
      dobErrorMsg: "",
      dateOfJoiningErrorMsg: "",
      personalEmailErrorMsg: "",
      passwordErrorMsg: "",
      lastNameErrorMsg: "",
      phoneErrorMsg: "",
      alternateMobErrorMsg: "",
      currentZipErrorMsg: "",
      permanentZipErrorMsg: "",
      departmentIdErrorMsg: "",
      designationIdErrorMsg: "",
      empIdErrorMsg: "",
      profileImagePreview: "",
      projectDetails: [],
      skillDetails: [],
      otherSkill: [],
      designationDetails: [],
      deptDetails: [],
      collegeName: "",
      projectManager: [],
      uploadFile: "",
      startDate: "",
      endDate: "",
      startDateError: "",
      endDateError: "",
      attachedDocs: [],
      educationId: "",
      documentId: "",
      previousAddress: "",
      documentName: "",
      titleRoute: "",
      endOpen: false,
      miniLoader: false,
      loader: true,
      flag: false,
      bredCrum: "",
      responseMessage: "",
      compOffResponseMessage: "",
      errorCode: "",
      checkFile: "",
      linkdinId: "",
      linkdinIdErrorMsg: "",
      skypeId: "",
      skypeIdErrorMsg: "",
      bloodGroup: "",
      arrivalTime: "",
      arrivalTimeErrorMsg: "",
      employeeType: "",
      employeeTypeErrorMsg: "",
      officeLocation: "",
      officeLocationErrorMsg: "",
      resMsg: false,
      employeeProfileErrorMsg: "",
      resMsgFail: false,
      wantToCarryForwardCompOff: "",
      wantToCarryForwardCompOffErrorMsg: "",
      compOffStartDateErrorMsg: "",
      compOffStartDate: "",
      compOffGivenReasonErrorMsg: "",
      compOffGivenReason: "",
      CompLeave: "",
      isAdmin: 0,
      resEmployeeProfileMessage: false,
      resFailEmployeeProfileMessage: false,
      successMessage: "",
      empoyeeProfileResponseMessage: "",
      clientSide: 0,
      clientSideErrorMsg: "",
      clientLocation: "",
      clientName: "",
      url: "/admin-employee",
      src: null,
      crop: {
        unit: "%",
        x: 50,
        y: 50,
        width: 50,
        height: 50,
      },
    };
  }

  componentDidMount() {
    let url =
      this.props &&
      this.props.match.path.substring(
        0,
        this.props && this.props.match.path.indexOf("/", 2)
      );

    if (url == "/admin-appraisal") {
      this.setState({ bredCrum: "APPRAISAL", url: "/admin-appraisal" });
    } else if (url == "/resumeSection") {
      this.setState({ bredCrum: "RESUME DETAILS", url: "/resumeSection" });
    } else if (url == "/admin-attendance") {
      this.setState({ bredCrum: "DAILY ATTENDANCE", url: "/admin-attendance" });
    } else if (url == "/admin-leave") {
      this.setState({ bredCrum: "LEAVE MANAGEMENT", url: "/admin-leave" });
    } else if (url == "/admin-device") {
      this.setState({ bredCrum: "DEVICE MANAGEMENT", url: "/admin-device" });
    } else if (url == "/admin-reimbursement") {
      this.setState({ bredCrum: "REIMBURSEMENT", url: "/admin-reimbursement" });
    } else if (url == "/admin-ticket") {
      this.setState({ bredCrum: "TICKET MANAGEMENT", url: "/admin-ticket" });
    } else if (url == "/admin-projects") {
      this.setState({ bredCrum: "PROJECT VIEW", url: "/admin-projects" });
    } else if (url == "/admin-department") {
      this.setState({
        bredCrum: "DEPARTMENT MANAGEMENT",
        url: "/admin-department",
      });
    } else if (url == "/admin-designation") {
      this.setState({
        bredCrum: "DESIGNATION MANAGEMENT",
        url: "/admin-designation",
      });
    } else if (url == "/admin-skill") {
      this.setState({ bredCrum: "SKILL MANAGEMENT", url: "/admin-skill" });
    } else {
      this.setState({ bredCrum: "EMPLOYEE LIST", url: "/admin-employee" });
    }

    this.getDetail();
    this.getSkillList();
    this.getDepartment();
    this.getDesignationList();
  }
  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  disabledStartDate = (startDate) => {
    const { endDate } = this.state;
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = (endDate) => {
    const { startDate } = this.state;
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };

  onChangeDate = (field, value) => {
    this.setState({
      [field]: value,
      [field + "Error"]: "",
    });
  };

  handleSelectChangeYear = (e) => this.setState({ experienceYear: e });
  handleSelectChangeMonth = (e) => this.setState({ experienceMonth: e });
  handleClientSideChange = (e) =>
    this.setState({ clientSide: e, clientSideErrorMsg: "" });

  onStartChange = (value) => {
    this.onChangeDate("startDate", value);
  };

  onEndChange = (value) => {
    this.onChangeDate("endDate", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  handleCompValidation = () => {
    let validate = true;
    let {
      CompLeave,
      compOffGivenReason,
      compOffStartDate,
      wantToCarryForwardCompOff,
    } = this.state;
    if (CompLeave == 0 || CompLeave === undefined || CompLeave == null) {
      validate = false;
      this.setState({
        assignedCompOffErMsg: "Please enter no. of compensatory leaves.",
      });
    } else if (!compOffRegex.test(CompLeave)) {
      validate = false;
      this.setState({
        assignedCompOffErMsg:
          "You can enter only number and max number is 5 and only .5 decimal number is allowed",
      });
    } else {
      this.setState({
        assignedCompOffErMsg: "",
      });
    }
    if (wantToCarryForwardCompOff == "1") {
      if (
        compOffStartDate == "" ||
        compOffStartDate == "undefined" ||
        compOffStartDate == null ||
        compOffStartDate == 0
      ) {
        validate = false;
        this.setState({
          compOffStartDateErrorMsg: REQUIRED,
        });
      } else {
        this.setState({
          compOffStartDateErrorMsg: "",
        });
      }
    }
    if (
      compOffGivenReason == "" ||
      compOffGivenReason == null ||
      compOffGivenReason == undefined
    ) {
      validate = false;
      this.setState({
        compOffGivenReasonErrorMsg: REQUIRED,
      });
    } else {
      this.setState({
        compOffGivenReasonErrorMsg: "",
      });
    }
    if (
      wantToCarryForwardCompOff == "" ||
      wantToCarryForwardCompOff == null ||
      wantToCarryForwardCompOff == undefined
    ) {
      validate = false;
      this.setState({
        wantToCarryForwardCompOffErrorMsg: REQUIRED,
      });
    } else {
      this.setState({
        wantToCarryForwardCompOffErrorMsg: "",
      });
    }
    return validate;
  };

  handleParticipant = (event) => {
    event.preventDefault();
    let self = this;
    let {
      CompLeave,
      compOffStartDate,
      compOffGivenReason,
      wantToCarryForwardCompOff,
    } = this.state;
    if (this.handleCompValidation()) {
      self.setState({ loader: true });
      let startD = new Date(compOffStartDate);
      let formattedDate1 =
        startD.getFullYear() +
        "-" +
        (startD.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        startD.getDate().toString().padStart(2, "0");
      let obj =
        wantToCarryForwardCompOff == "1"
          ? {
              employeeId: this.props.match.params.id,
              days: parseFloat(CompLeave),
              expireDate: formattedDate1,
              leaveMsg: compOffGivenReason,
            }
          : {
              employeeId: this.props.match.params.id,
              days: parseFloat(CompLeave),
              leaveMsg: compOffGivenReason,
            };
      markPaidLeave(obj).then((res) => {
        if (res.data.statusCode === 1) {
          self.setState({ resAddCompOff: true });
          self.setState({
            loader: false,
            openCompOffPopup: false,
            CompLeave: "",
          });
          self.closeModal();
        } else if (res.data.statusCode === 0) {
          self.setState({
            resMsgFail: true,
            loader: false,
            compOffResponseMessage: res.data.error.responseMessage,
          });
          self.closeModal();
        }

        setTimeout(() => {
          self.setState({ resAddCompOff: false, resMsgFail: false });
        }, 3000);
      });
    }
  };

  handleArrivalTime = (arrivalTime, arrivalTimeString) =>
    this.setState({
      arrivalTime: moment.duration(arrivalTimeString).asSeconds(),
      arrivalTimeErrorMsg: "",
    });

  handleChallengeImage = (droppedFile) => {
    let reader = new FileReader();
    let file = droppedFile[0];

    this.setState({
      uploadFile: droppedFile[0],
      challengeImageErrorMsg: "",
      docErrorMsg: "",
      prev: false,
    });

    reader.addEventListener(
      "load",
      () => {
        if (file.type === "application/pdf") {
          this.setState({
            docImageView: pdfpng,
            checkFile: 1,
            challengeImageErrorMsg: "",
            docErrorMsg: "",
          });
        } else if (
          file.type == "application/msword" ||
          file.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          this.setState({
            docImageView: docpng,
            checkFile: 2,
            challengeImageErrorMsg: "",
            docErrorMsg: "",
          });
        } else if (
          file.type == "image/jpeg" ||
          file.type == "image/jpg" ||
          file.type == "image/gif" ||
          file.type == "image/x-icon" ||
          file.type == "image/png" ||
          file.type == "image/svg+xml" ||
          file.type == "image/webp"
        ) {
          this.setState({
            docImageView: reader.result,
            checkFile: 3,
            challengeImageErrorMsg: "",
            docErrorMsg: "",
          });
        } else {
          this.setState({
            docImageView: docpng,
            checkFile: 2,
            challengeImageErrorMsg: "",
            docErrorMsg: "",
          });
        }
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleDueAppraisal = (dueAppraisal, date) =>
    this.setState({ dueAppraisal: moment(date).toDate().getTime() });

  handleDojChange = (date) =>
    this.setState({ dateOfJoining: new Date(date).setHours(6, 30, 0, 0) });

  handleDateChange = (date) =>
    this.setState({ dateAdded: moment(date).toDate().getTime() });

  handleDobChange = (date, dateString) => {
    let year = moment(date).toDate().getFullYear();

    var dt = new Date();

    let currYear = dt.getFullYear();

    if (currYear - year < "18") {
      this.setState({
        dob: new Date(date).setHours(6, 30, 0, 0),
        dobErrorMsg:
          "Please select valid date of birth(age of employee must be 18 or above).",
      });
    } else {
      this.setState({
        dob: new Date(date).setHours(6, 30, 0, 0),
        dobErrorMsg: "",
      });
    }
  };

  handleDateChange = (date, dateString) =>
    this.setState({ date: moment(date).toDate().getTime(), dateErrorMsg: "" });

  handleOpenDeviceInfoPopup = () =>
    this.setState({ openDeviceInfoPopup: !this.state.openDeviceInfoPopup });

  handleOpenEditProfilePopup = (employeeProfile) =>
    this.setState({
      openEditProfilePopup: !this.state.openEditProfilePopup,
      employeeProfile,
    });

  handleOpenAddEducationPopup = () =>
    this.setState({ openAddEducationPopup: !this.state.openAddEducationPopup });

  handleOpenAddQualificationPopup = () =>
    this.setState({
      openAddQualificationPopup: !this.state.openAddQualificationPopup,
    });

  handleOpenAddSkillPopup = () =>
    this.setState({ openAddSkillPopup: !this.state.openAddSkillPopup });
  handleOpenEditSkillPopup = () =>
    this.setState({ openEditSkillPopup: !this.state.openEditSkillPopup });
  handleOpenAddOtherSkillPopup = () =>
    this.setState({
      openAddOtherSkillPopup: !this.state.openAddOtherSkillPopup,
    });
  handleOpenEditOtherSkillPopup = () =>
    this.setState({
      openEditOtherSkillPopup: !this.state.openEditOtherSkillPopup,
    });

  handleOpenAddDocumentPopup = () =>
    this.setState({ openAddDocumentPopup: !this.state.openAddDocumentPopup });

  handleOpenDeleteAccessPopup = (datavalue) =>
    this.setState({
      openDeleteAccessPopup: !this.state.openDeleteAccessPopup,
      datavalue,
    });
  handleOpenProfileChangePopup = (datavalue) =>
    this.setState({
      openProfileChangePopup: !this.state.openProfileChangePopup,
      datavalue,
    });

  handleOpenDeleteEduPopup = (eduValue) =>
    this.setState({
      openDeleteEduPopup: !this.state.openDeleteEduPopup,
      eduValue,
    });

  handleOpenDeleteAddEduPopup = (edu) =>
    this.setState({
      openDeleteAddEduPopup: !this.state.openDeleteAddEduPopup,
      edu,
    });
  handleOpenEditEducationPopup = ({
    degree,
    collegeName,
    course,
    description,
    endDate,
    grade,
    startDate,
    _id,
  }) =>
    this.setState({
      openEditEducationPopup: !this.state.openEditEducationPopup,
      collegeName,
      degree,
      course,
      description,
      endDate,
      grade,
      startDate,
      educationId: _id,
    });
  handleOpenEditQualificationPopup = ({
    collegeName,
    degree,
    course,
    description,
    endDate,
    grade,
    startDate,
    _id,
  }) =>
    this.setState({
      openEditQualificationPopup: !this.state.openEditQualificationPopup,
      collegeName,
      degree,
      course,
      description,
      endDate,
      grade,
      startDate,
      qualificationId: _id,
    });

  closeModal1 = () => this.setState({ openProfileChangePopup: false });

  closeModal = () =>
    this.setState({
      otherSkillDetails1: this.state.otherSkillDetails,
      assignedSkill1: this.state.assignedSkill,
      openDeviceInfoPopup: false,
      openEditProfilePopup: false,
      openAddEducationPopup: false,
      openEditEducationPopup: false,
      openAddDocumentPopup: false,
      openDeleteAccessPopup: false,
      openDeleteEduPopup: false,
      openDeleteAddEduPopup: false,
      openAddQualificationPopup: false,
      openAddSkillPopup: false,
      openEditSkillPopup: false,
      openAddOtherSkillPopup: false,
      openEditOtherSkillPopup: false,
      openEditQualificationPopup: false,
      openCompOffPopup: false,
      firstNameErrorMsg: "",
      middleNameErrorMsg: "",
      docErrorMsg: "",
      emailErrorMsg: "",
      dueAppraisalErrorMsg: "",
      dobErrorMsg: "",
      dateOfJoiningErrorMsg: "",
      personalEmailErrorMsg: "",
      passwordErrorMsg: "",
      lastNameErrorMsg: "",
      phoneErrorMsg: "",
      permanentZipErrorMsg: "",
      currentZipErrorMsg: "",
      alternateMobErrorMsg: "",
      departmentIdErrorMsg: "",
      designationIdErrorMsg: "",
      assignedToErrorMsg: "",
      empIdErrorMsg: "",
      collegeNameErrorMsg: "",
      degreeErrorMsg: "",
      documentNameErrorMsg: "",
      skillErrorMsg: "",
      otherSkillErrorMsg: "",
      degree: "",
      collegeName: "",
      course: "",
      description: "",
      endDate: "",
      grade: "",
      startDate: "",
      uploadFile: "",
      documentName: "",
      docImageView: "",
      endOpen: false,
      miniLoader: false,
      responseMessage: "",
      errorCode: "",
      startDateError: "",
      endDateError: "",
      linkdinIdErrorMsg: "",
      skypeIdErrorMsg: "",
      arrivalTimeErrorMsg: "",
      employeeTypeErrorMsg: "",
      officeLocationErrorMsg: "",
      CompLeave: "",
      wantToCarryForwardCompOff: "",
      wantToCarryForwardCompOffErrorMsg: "",
      compOffGivenReason: "",
      compOffGivenReasonErrorMsg: "",
      compOffStartDate: "",
      compOffStartDateErrorMsg: "",
      assignedCompOffErMsg: "",
      roleErrorMsg: "",
      clientSideErrorMsg: "",
    });

  handleChange = (e) =>
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + "ErrorMsg"]: "",
      responseMessage: "",
      errorCode: "",
    });

  handleDegreeChange = (e) =>
    this.setState({ degree: parseInt(e), degreeErrorMsg: "" });

  handleSelectChange = (e) => {
    this.setState({ assignedTo: e, assignedToErrorMsg: "" });
  };
  handleSelectSkill = (e) => {
    this.setState({ assignedSkill: e, skillErrorMsg: "" });
  };

  handleSelectOtherSkill = (a) => {
    this.setState({ assignedSkill1: a, otherSkillErrorMsg: "" });
  };
  handleDesignationChange = (e) => {
    this.setState({ assignedDesignation: e, designationIdErrorMsg: "" });
  };
  handleGenderChange = (e) => {
    this.setState({ gender: parseInt(e) });
  };
  ////////////////////////////// MODIFICATION START //////////////////////////////
  handleBloodGroupChange = (e) => this.setState({ bloodGroup: e });

  handleSelectEmployeeTypeChange = (e) =>
    this.setState({ employeeType: e, employeeTypeErrorMsg: "" });

  handleOfficeLocationChange = (e) =>
    this.setState({ officeLocation: e, officeLocationErrorMsg: "" });
  ////////////////////////////// MODIFICATION ENDS //////////////////////////////

  getDetail = () => {
    let params = `employeeId=${this.props.match.params.id}`;

    getEmployeeDetail(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let {
          firstName,
          experienceYear,
          experienceMonth,
          middleName,
          empId,
          email,
          personalEmail,
          lastName,
          phone,
          alternateMob,
          dob,
          departmentId,
          currentAddress,
          permanentAddress,
          designationName,
          education,
          qualification,
          designation,
          designationId,
          departmentName,
          projectName,
          startDate,
          endDate,
          profileImage,
          attachedDocs,
          documentName,
          dateOfJoining,
          dueAppraisal,
          previousAddress,
          gender,
          //////////MODIFIED/////////
          officeLocation,
          clientSide,
          clientLocation,
          clientName,
          linkdinId,
          skypeId,
          bloodGroup,
          arrivalTime,
          employeeType,
          isAdmin,
          ///////////////////////////
        } = resData.employeeProfile;

        let {
          permanentAddressOne,
          permanentAddressTwo,
          permanentCity,
          permanentState,
          permanentZip,
          permanentCountry,
        } = resData.employeeProfile.permanentAddress || {};
        let {
          currentAddressOne,
          currentAddressTwo,
          currentCity,
          currentState,
          currentZip,
          currentCountry,
        } = resData.employeeProfile.currentAddress || {};

        let assignedTo = [];
        let assignedDesignation = [];
        let assignedSkill = [];

        let assignedOtherSkill = [];

        let userline = 0;
        let deptDetails = resData.deptDetails;
        for (var i = 0; i < deptDetails.length; i++) {
          userline = deptDetails[i]._id;

          assignedTo.push(userline);
        }

        let userlin = 0;
        let designationDetails = resData.designationDetails;
        for (var j = 0; j < designationDetails.length; j++) {
          userlin = designationDetails[j]._id;

          assignedDesignation.push(userlin);
        }

        let userl = 0;
        let skillDetails = resData.skillDetails;
        for (var l = 0; l < skillDetails.length; l++) {
          userl = skillDetails[l]._id;

          assignedSkill.push(userl);
        }
        this.setState(
          {
            assignedTo,
            assignedDesignation,
            assignedSkill,
            assignedOtherSkill,

            firstName,
            experienceYear,
            experienceMonth,
            middleName,
            empId,
            id: empId,
            email,
            personalEmail,
            lastName,
            phone,
            alternateMob,
            dob,
            ///////////MODIFIED///////////
            linkdinId,
            skypeId,
            bloodGroup,
            arrivalTime,
            employeeType,
            officeLocation,
            clientLocation,
            clientSide,
            clientName,
            /////////////////////////////
            dateOfJoining,
            dueAppraisal,
            departmentName,
            designationName,
            designationId,
            departmentId,
            education,
            qualification,
            designation,
            currentAddress,
            permanentAddress,
            projectName,
            startDate,
            endDate,
            profileImage,
            attachedDocs,
            documentName,
            permanentAddressOne,
            permanentAddressTwo,
            permanentCity,
            permanentState,
            permanentZip,
            permanentCountry,
            currentAddressOne,
            currentAddressOne1: currentAddressOne,
            currentAddressTwo,
            currentAddressTwo1: currentAddressTwo,
            currentCity,
            currentCity1: currentCity,
            currentState,
            currentState1: currentState,
            currentZip,
            currentZip1: currentZip,
            currentCountry,
            previousAddress,
            gender,

            projectDetails: resData.projectDetails,
            skillDetails: resData.skillDetails,
            otherSkillDetails: resData.otherSkillDetails,
            otherSkillDetails1: resData.otherSkillDetails,
            designationDetails: resData.designationDetails,
            deptDetails: resData.deptDetails,
            projectManager: resData.projectsOfManager,
            previousAddressOne: currentAddressOne,
            previousAddressTwo: currentAddressTwo,
            previousCity: currentCity,
            previousState: currentState,
            previousZip: currentZip,
            imageLoader: true,
            profileImagePreview: resData.employeeProfile.profileImage
              ? resData.employeeProfile.profileImage
              : require("../../asset/imgs/user-img.png"),
            isAdmin,
          },
          () => {
            this.setState({
              loader: false,
              imageLoader: false,
            });
            this.closeModal();
          }
        );
      } else {
        this.setState({ loader: false });
      }
    });
  };
  handleprofileValidation = () => {
    const { editProfileImage } = this.state;
    let validate = true;
    if (editProfileImage == "") {
      validate = false;
      this.setState({ employeeProfileErrorMsg: "Please Enter Image" });
    } else if (
      !editProfileImage?.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)
    ) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg:
          "Please Enter jpg|jpeg|png|JPEG|JPG|PNG format file only",
      });
    } else if (editProfileImage?.size >= 10485760) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg: "please insert image of less then 10 mb",
      });
    } else {
      this.setState({ employeeProfileErrorMsg: "" });
    }
    return validate;
  };

  handleProfileSubmit = (e) => {
    if (this.handleprofileValidation()) {
      this.setState({ miniLoader: true });
      let data = new FormData();
      data.append("profileImage", this.state.editProfileImage);

      let params = {
        employeeId: this.props.match.params.id,
        data: data,
      };

      editEmployeeProfileImage(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({ miniLoader: false });
          this.getDetail();
          this.handleCancel();
          this.setState({
            openProfileChangePopup: false,
            resProfileUpdate: true,
          });
          setTimeout(() => this.setState({ resProfileUpdate: false }), 2000);
        } else {
          this.setState({ resFailProfileUpdate: true });
          this.setState({ openProfileChangePopup: false, miniLoader: false });
        }
      });
    }
  };

  handleDocValidetion = () => {
    let validate = true;
    const { documentName, uploadFile } = this.state;

    if (uploadFile === "" || uploadFile === undefined) {
      validate = false;
      this.setState({ docErrorMsg: "Document is required" });
    } else if (!uploadFile?.name.match(/\.(jpg|jpeg|png|pdf|doc|docx)$/)) {
      validate = false;
      this.setState({ docErrorMsg: "Provide valid document" });
    } else {
      this.setState({ docErrorMsg: "" });
    }
    if (documentName === "" || documentName === undefined) {
      validate = false;
      this.setState({ documentNameErrorMsg: "Document name is required" });
    } else {
      this.setState({ documentNameErrorMsg: "" });
    }
    return validate;
  };
  handleEduValidetion = () => {
    let validate = true;
    const { degree, collegeName, startDate, endDate } = this.state;
    if (degree === "" || degree === undefined) {
      validate = false;
      this.setState({ degreeErrorMsg: "Education qualification is required" });
    } else {
      this.setState({ degreeErrorMsg: "" });
    }
    if (collegeName === "" || collegeName === undefined) {
      validate = false;
      this.setState({
        collegeNameErrorMsg: "Name of school/college is required",
      });
    } else {
      this.setState({ collegeNameErrorMsg: "" });
    }
    if (startDate === "" || startDate === undefined) {
      validate = false;
      this.setState({
        startDateError: "Date is required",
      });
    } else {
      this.setState({ startDateError: "" });
    }
    if (endDate === "" || endDate === undefined) {
      validate = false;
      this.setState({
        endDateError: "Date is required",
      });
    } else {
      this.setState({ endDateError: "" });
    }
    return validate;
  };

  handleQualValidetion = () => {
    let validate = true;
    const { degree, collegeName, startDate, endDate } = this.state;
    if (degree === "" || degree === undefined) {
      validate = false;
      this.setState({ degreeErrorMsg: "Additional qualification is required" });
    } else {
      this.setState({ degreeErrorMsg: "" });
    }
    if (collegeName === "" || collegeName === undefined) {
      validate = false;
      this.setState({
        collegeNameErrorMsg: "Name of school/college is required",
      });
    } else {
      this.setState({ collegeNameErrorMsg: "" });
    }
    if (startDate === "" || startDate === undefined) {
      validate = false;
      this.setState({
        startDateError: "Date is required",
      });
    } else {
      this.setState({ startDateError: "" });
    }
    if (endDate === "" || endDate === undefined) {
      validate = false;
      this.setState({
        endDateError: "Date is required",
      });
    } else {
      this.setState({ endDateError: "" });
    }
    return validate;
  };
  handleOpenCompOffPopup = () =>
    this.setState({ openCompOffPopup: !this.state.openCompOffPopup });
  handleCompOffDayChange = (e) => {
    this.setState({ CompLeave: e.target.value, assignedCompOffErMsg: "" });
  };

  onCompOffStartChange = (e) => {
    this.setState({ compOffStartDate: e, compOffStartDateErrorMsg: "" });
  };
  handleCompOffGivenReasonChange = (e) => {
    this.setState({
      compOffGivenReason: /^\s/.test(e.target.value) ? "" : e.target.value,
      compOffGivenReasonErrorMsg: "",
    });
  };
  handleTypeCompOffChange = (e) => {
    this.setState({
      wantToCarryForwardCompOff: e,
      wantToCarryForwardCompOffErrorMsg: "",
    });
  };
  handleSkillValidation = () => {
    let validate = true;
    const { assignedSkill } = this.state;
    if (
      assignedSkill === "" ||
      assignedSkill.length === 0 ||
      assignedSkill === undefined
    ) {
      validate = false;
      this.setState({ skillErrorMsg: "Skill is required" });
    } else {
      this.setState({ skillErrorMsg: "" });
    }

    return validate;
  };
  handleSkillOtherValidation = () => {
    let validate = true;
    const { otherSkill, assignedSkill } = this.state;
    if (
      otherSkill === "" ||
      otherSkill.length === 0 ||
      otherSkill === undefined
    ) {
      validate = false;
      this.setState({ otherSkillErrorMsg: "Skill is required" });
    } else {
      this.setState({ otherSkillErrorMsg: "" });
    }
    if (
      assignedSkill === "" ||
      assignedSkill.length === 0 ||
      assignedSkill === undefined
    ) {
      validate = false;
      this.setState({ otherSkillErrorMsg: "Skill is required" });
    } else {
      this.setState({ otherSkillErrorMsg: "" });
    }

    return validate;
  };
  handleValidetion = () => {
    let validate = true;
    const {
      arrivalTime,
      employeeType,
      linkdinId,
      skypeId,
      assignedDesignation,
      currentZip,
      permanentZip,
      firstName,
      lastName,
      email,
      personalEmail,
      empId,
      phone,
      alternateMob,
      officeLocation,
      dob,
      dateOfJoining,
      isAdmin,
      clientSide,
      assignedTo,
      middleName,
    } = this.state;

    let validateEmail = regx.test(String(email).toLowerCase());
    let validatePersonalEmail = regx.test(String(personalEmail).toLowerCase());
    let validateEmployeeId = regx1.test(String(empId));

    let validateCurrentZipRegx = zipRegx.test(String(currentZip));
    let validateZipRegx = zipRegx.test(String(permanentZip));
    let validateFirstName = firstName;
    let validateLastName = lastName;
    let validateMiddleName = middleName;

    let validatelinkdinId = linkdinIdRegx.test(String(linkdinId).toLowerCase());
    let validateskypeId = skypeIdRegx.test(String(skypeId).toLowerCase());

    if (linkdinId && !validatelinkdinId) {
      validate = false;
      this.setState({ linkdinIdErrorMsg: "Please enter a valid linkdinId ID" });
    } else {
      this.setState({ linkdinIdErrorMsg: "" });
    }
    if (skypeId && !validateskypeId) {
      validate = false;
      this.setState({ skypeIdErrorMsg: "Please enter a valid skypeId ID" });
    } else {
      this.setState({ skypeIdErrorMsg: "" });
    }
    if (isAdmin == 0 || isAdmin == "" || isAdmin == undefined) {
      validate = false;
      this.setState({ roleErrorMsg: "Please Select Role" });
    } else {
      this.setState({ roleErrorMsg: "" });
    }
    if (!employeeType) {
      validate = false;
      this.setState({ employeeTypeErrorMsg: "Field is required" });
    } else {
      this.setState({ employeeTypeErrorMsg: "" });
    }
    if (!arrivalTime) {
      validate = false;
      this.setState({ arrivalTimeErrorMsg: "Reporting time is required" });
    } else {
      this.setState({ arrivalTimeErrorMsg: "" });
    }

    if (!officeLocation) {
      validate = false;
      this.setState({ officeLocationErrorMsg: "Field is required" });
    } else {
      this.setState({ officeLocationErrorMsg: "" });
    }

    let validatePhone = phoneRegx.test(String(phone));
    let validateAlternatePhone = alternatePhoneRegx.test(String(alternateMob));
    if (phone === "" || phone === undefined) {
      validate = false;
      this.setState({ phoneErrorMsg: "Mobile number is required." });
    } else if (!validatePhone || phone === NaN) {
      validate = false;
      this.setState({ phoneErrorMsg: "Please enter a valid mobile number." });
    } else if (phone === "0000000000") {
      validate = false;
      this.setState({ phoneErrorMsg: "Please enter a valid mobile number." });
    } else {
      this.setState({ phoneErrorMsg: "" });
    }

    if (alternateMob) {
      if (!validateAlternatePhone) {
        validate = false;
        this.setState({
          altPhoneErrorMsg: "Please enter a valid mobile number.",
        });
      } else {
        this.setState({ altPhoneErrorMsg: "" });
      }
    }
    /////////////////// MODIFICATION ENDS ////////////////////

    if (!email) {
      validate = false;
      this.setState({ emailErrorMsg: "Official email is required" });
    } else if (!validateEmail) {
      validate = false;
      this.setState({ emailErrorMsg: "Please enter a valid email address" });
    } else {
      this.setState({ emailErrorMsg: "" });
    }
    if (!personalEmail) {
      validate = false;
      this.setState({ personalEmailErrorMsg: "Personal email is required" });
    } else if (!validatePersonalEmail) {
      validate = false;
      this.setState({
        personalEmailErrorMsg: "Please enter a valid email address",
      });
    } else {
      this.setState({ personalEmailErrorMsg: "" });
    }

    if (validateFirstName === "" || validateFirstName === undefined) {
      validate = false;
      this.setState({ firstNameErrorMsg: "First name is required" });
    } else if (!nameRegex.test(validateFirstName) == false) {
      validate = false;
      this.setState({
        firstNameErrorMsg: "Special character and number are not allowed",
      });
    } else {
      this.setState({ firstNameErrorMsg: "" });
    }

    if (validateMiddleName) {
      if (!nameRegex.test(validateMiddleName) == false) {
        validate = false;
        this.setState({
          middleNameErrorMsg: "Special character and number are not allowed",
        });
      }
    }
    if (
      assignedDesignation === "" ||
      assignedDesignation.length <= 0 ||
      assignedDesignation === null
    ) {
      validate = false;
      this.setState({ designationIdErrorMsg: "Designation is required." });
    } else {
      this.setState({ designationIdErrorMsg: "" });
    }

    if (assignedTo === "" || assignedTo.length <= 0 || assignedTo === null) {
      validate = false;
      this.setState({ assignedToErrorMsg: "Department is required." });
    } else {
      this.setState({ assignedToErrorMsg: "" });
    }
    if (validateLastName === "" || validateLastName === undefined) {
      validate = false;
      this.setState({ lastNameErrorMsg: "Last name is required" });
    } else if (!nameRegex.test(validateLastName) == false) {
      validate = false;
      this.setState({
        lastNameErrorMsg: "Special character and number are not allowed",
      });
    } else {
      this.setState({ lastNameErrorMsg: "" });
    }

    if (empId === "" || empId === undefined) {
      validate = false;
      this.setState({ empIdErrorMsg: "Employee ID is required" });
    } else if (empId && !validateEmployeeId) {
      validate = false;
      this.setState({ empIdErrorMsg: "Employee ID should be like MOB123" });
    } else {
      this.setState({ empIdErrorMsg: "" });
    }
    if (currentZip) {
      if (!validateCurrentZipRegx) {
        validate = false;
        this.setState({ currentZipErrorMsg: "Please enter a valid zip code." });
      } else {
        this.setState({ currentZipErrorMsg: "" });
      }
    }

    if (permanentZip) {
      if (!validateZipRegx) {
        validate = false;
        this.setState({
          permanentZipErrorMsg: "Please enter a valid zip code.",
        });
      } else {
        this.setState({ permanentZipErrorMsg: "" });
      }
    }

    if (dob === "" || dob === undefined) {
      validate = false;
      this.setState({ dobErrorMsg: "Date of birth is required." });
    } else if (
      this.state.dobErrorMsg ===
      "Please select valid date of birth(age of employee must be 18 or above)."
    ) {
      validate = false;
      this.setState({
        dobErrorMsg:
          "Please select valid date of birth(age of employee must be 18 or above).",
      });
    } else {
      this.setState({ dobErrorMsg: "" });
    }

    if (dateOfJoining === "" || dateOfJoining === undefined) {
      validate = false;

      this.setState({ dateOfJoiningErrorMsg: "Date of joining is required." });
    } else if (dateOfJoining === dob) {
      validate = false;
      this.setState({
        dateOfJoiningErrorMsg:
          "Date of joining is  not equal to date of birth.",
      });
    } else {
      this.setState({ dateOfJoiningErrorMsg: "" });
    }

    if (
      clientSide == "" ||
      clientSide == undefined ||
      clientSide == null ||
      clientSide == false ||
      clientSide == 0
    ) {
      validate = false;
      this.setState({ clientSideErrorMsg: "Please select client Side " });
    } else {
      this.setState({ clientSideErrorMsg: "" });
    }
    return validate;
  };

  handleEditSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidetion()) {
      this.setState({ loader: true });
      let { empId, id } = this.state;
      let empIds = empId.substring(0, 3).toUpperCase() + empId.slice(3);
      let eId = id.substring(0, 3).toUpperCase() + id.slice(3);
      let clientLocation1 = this.state.clientLocation
        ? this.state.clientLocation
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        : "";
      let obj = {
        employeeId: this.props.match.params.id,
        firstName: this.state.firstName,
        experienceYear: this.state.experienceYear,
        experienceMonth: this.state.experienceMonth,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        empId: eId === empIds ? "" : empIds,
        phone: this.state.phone,
        alternateMob: this.state.alternateMob,
        personalEmail: this.state.personalEmail.toLowerCase(),
        email: this.state.email.toLowerCase(),
        dob: this.state.dob,
        permanentAddressOne: this.state.permanentAddressOne,
        permanentAddressTwo: this.state.permanentAddressTwo,
        permanentCity: this.state.permanentCity,
        permanentState: this.state.permanentState,
        permanentZip: this.state.permanentZip,
        permanentCountry: this.state.permanentCountry,
        currentAddressOne: this.state.currentAddressOne,
        currentAddressTwo: this.state.currentAddressTwo,
        currentCity: this.state.currentCity,
        currentState: this.state.currentState,
        currentZip: this.state.currentZip,
        currentCountry: this.state.currentCountry,
        dateOfJoining: this.state.dateOfJoining,
        dueAppraisal: this.state.dueAppraisal,
        gender: this.state.gender,
        ///////////////MODIFICATION/////////////////
        officeLocation: this.state.officeLocation,
        linkdinId: this.state.linkdinId,
        skypeId: this.state.skypeId,
        bloodGroup: this.state.bloodGroup,
        arrivalTime: this.state.arrivalTime,
        employeeType: this.state.employeeType,
        //////////////////////////////////////////
        departmentId:
          this.state.assignedTo === ""
            ? null
            : this.state.assignedTo.toString(),
        designationId:
          this.state.assignedDesignation === ""
            ? null
            : this.state.assignedDesignation.toString(),
        role: this.state.isAdmin,
        clientLocation: this.state.clientSide == 2 ? "" : clientLocation1,
        clientSide: this.state.clientSide,
        clientName: this.state.clientSide == 2 ? "" : this.state.clientName,
      };

      if (
        this.state.currentAddressOne1 == this.state.previousAddressOne &&
        this.state.currentAddressTwo1 == this.state.previousAddressTwo &&
        this.state.currentCity1 == this.state.previousCity &&
        this.state.currentState1 == this.state.previousState &&
        this.state.currentZip1 == this.state.previousZip
      ) {
        obj.previousAddressOne = this.state.previousAddressOne;
        obj.previousAddressTwo = this.state.previousAddressTwo;
        obj.previousCity = this.state.previousCity;
        obj.previousState = this.state.previousState;
        obj.previousZip = this.state.previousZip;
      }

      editEmployeeProfile(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.closeModal();
          this.setState({ resMsg: true, loader: false }, () =>
            this.getDetail()
          );
          setTimeout(() => this.setState({ resMsg: false }), 2000);
        } else {
          this.getDetail();
          this.closeModal();
          this.setState({
            responseMessage: res.data.error.responseMessage,
            errorCode: res.data.error.errorCode,
            resMsgFail: true,
            loader: false,
          });
          document.querySelector(".ant-modal-wrap").scrollTo(0, 0);
          setTimeout(() => this.setState({ resMsgFail: false }), 2000);
        }
      });
    } else {
      document.querySelector(".ant-modal-wrap").scrollTo(0, 0);
    }
  };

  handleCancel = (event) => {
    this.closeModal();
    this.setState(this.getDetail());
  };

  handleCancel1 = (e) => {
    var fileInput = document.getElementById("profile");
    fileInput.value = null;
    this.closeModal1();
    this.setState({
      employeeProfileErrorMsg: "",
      editEmployeeProfile: "",
      src: "",
      crop: {
        unit: "%",
        x: 50,
        y: 50,
        width: 50,
        height: 50,
      },
    });
  };
  handleDocumentSubmit = (event) => {
    event.preventDefault();
    let { documentName, uploadFile } = this.state;

    if (this.handleDocValidetion()) {
      this.setState({ miniLoader: true });
      let params = new FormData();
      params.append("documentName", documentName);
      params.append("employeeId", this.props.match.params.id);
      params.append("uploadFile", uploadFile);
      addEmployeeDocuments(params).then((res) => {
        if (res.data.statusCode == 1) {
          this.setState(
            {
              miniLoader: false,
              resEmployeeProfileMessage: true,
              successMessage: "Document Added",
            },
            () => this.getDetail()
          );
        } else {
          this.setState({
            miniLoader: false,
            resFailEmployeeProfileMessage: true,
            empoyeeProfileResponseMessage: res.data.error.responseMessage,
          });
        }
      });
    }
    setTimeout(() => {
      this.setState({
        resFailEmployeeProfileMessage: false,
        resEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  handleAddEduSubmit = (event) => {
    event.preventDefault();
    if (this.handleEduValidetion()) {
      this.setState({ miniLoader: true });
      let obj = {
        employeeId: this.props.match.params.id,
        degree: this.state.degree,
        description: this.state.description,
        collegeName: this.state.collegeName,
        startDate: new Date(this.state.startDate).setHours(0, 0, 0, 0),
        endDate: new Date(this.state.endDate).setHours(0, 0, 0, 0),
      };

      addEmployeeEducation(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              miniLoader: false,
              resEmployeeProfileMessage: true,
              successMessage: "Education Added",
            },
            () => this.getDetail()
          );
        } else {
          this.setState(
            {
              miniLoader: false,
              resFailEmployeeProfileMessage: true,
              empoyeeProfileResponseMessage: res.data.error.responseMessage,
            },
            () => this.getDetail()
          );
        }
      });
    }
    setTimeout(() => {
      this.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  handleAddOtherSkill = (event) => {
    event.preventDefault();
    let self = this;
    if (this.handleAddSkillValidetion()) {
      this.setState({ miniLoader: true });
      let obj = {
        employeeId: this.props.match.params.id,
        skillId:
          this.state.assignedSkill.length != 0
            ? this.state.assignedSkill.toString()
            : [],
        otherSkill:
          this.state.otherSkillDetails.length != 0
            ? this.state.otherSkillDetails.toString()
            : [],
      };
      addOtherSkillEmp(obj).then((res) => {
        if (res.data.statusCode === 1) {
          self.setState(
            {
              miniLoader: false,
              resEmployeeProfileMessage: true,
              successMessage: "Skill Added ",
            },
            () => self.getDetail()
          );
          self.closeModal();
        } else if (res.data.statusCode === 0) {
          self.setState({
            resFailEmployeeProfileMessage: true,
            empoyeeProfileResponseMessage: res.data.error.responseMessage,
            miniLoader: false,
          });
        }
      });
    }
    setTimeout(() => {
      self.setState({
        resFailEmployeeProfileMessage: false,
        resEmployeeProfileMessage: false,
      });
    }, 4000);
  };
  handleEditSkillValidetion = () => {
    let validate = true;
    const { assignedSkill1 } = this.state;
    if (
      assignedSkill1 === "" ||
      assignedSkill1 === undefined ||
      assignedSkill1.length === 0
    ) {
      validate = false;
      this.setState({ otherSkillErrorMsg: "Skill is required" });
    } else {
      this.setState({ otherSkillErrorMsg: "" });
    }

    return validate;
  };

  handleAddSkillValidetion = () => {
    let validate = true;
    const { assignedSkill } = this.state;
    if (
      assignedSkill === "" ||
      assignedSkill === undefined ||
      assignedSkill.length === 0
    ) {
      validate = false;
      this.setState({ skillErrorMsg: "Skill is required" });
    } else {
      this.setState({ skillErrorMsg: "" });
    }

    return validate;
  };

  handleEditOtherSkill = (event) => {
    event.preventDefault();
    let self = this;
    if (this.handleEditSkillValidetion()) {
      this.setState({ miniLoader: true });
      let obj = {
        employeeId: this.props.match.params.id,
        skillId:
          this.state.assignedSkill1.length != 0
            ? this.state.assignedSkill1.toString()
            : [],
        otherSkill:
          this.state.otherSkillDetails1.length != 0
            ? this.state.otherSkillDetails1.toString()
            : [],
      };
      editSkillEmp(obj).then((res) => {
        if (res.data.statusCode === 1) {
          self.setState(
            {
              miniLoader: false,
              resEmployeeProfileMessage: true,
              successMessage: "Skill Edtied",
            },
            () => self.getDetail()
          );
          self.closeModal();
        } else if (res.data.statusCode === 0) {
          self.setState({
            miniLoader: false,
            resFailEmployeeProfileMessage: true,
            empoyeeProfileResponseMessage: res.data.error.responseMessage,
          });
        }
      });
    }
    setTimeout(() => {
      self.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  handleAddQualificationSubmit = (event) => {
    event.preventDefault();
    if (this.handleQualValidetion()) {
      this.setState({ miniLoader: true });
      let obj = {
        employeeId: this.props.match.params.id,
        degree: this.state.degree,
        description: this.state.description,
        collegeName: this.state.collegeName,
        startDate: new Date(this.state.startDate).setHours(0, 0, 0, 0),
        endDate: new Date(this.state.endDate).setHours(0, 0, 0, 0),
      };

      addAdditionalQualification(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              miniLoader: false,
              resEmployeeProfileMessage: true,
              successMessage: "Addiitonal Education Added",
            },
            () => this.getDetail()
          );
        } else {
          this.setState({
            miniLoader: false,
            resFailEmployeeProfileMessage: false,
            empoyeeProfileResponseMessage: res.data.error.responseMessage,
          });
        }
      });
    }
    setTimeout(() => {
      this.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };
  handlePrevAddress = () =>
    this.setState({
      previousAddressOne: this.state.currentAddressOne,
      previousAddressTwo: this.state.currentAddressTwo,
      previousState: this.state.currentState,
      previousZip: this.state.currentZip,
    });

  handleDeleteEduSubmit = (data) => {
    let self = this;
    this.setState({ miniLoader: true });
    let educationId = data._id;
    let params = {
      educationId: this.state.eduValue,
      employeeId: this.props.match.params.id,
      isDeleted: 2,
    };

    deleteEmployeeEducation(params).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState(
          {
            miniLoader: false,
            resEmployeeProfileMessage: true,
            successMessage: "Education Deleted",
          },
          () => this.getDetail()
        );
      } else {
        this.setState({
          miniLoader: false,
          resFailEmployeeProfileMessage: true,
          empoyeeProfileResponseMessage: res.data.error.responseMessage,
        });
      }
    });
    setTimeout(() => {
      this.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  handleZipcodeInputKeyDown = (e) => {
    var key = e.which ? e.which : e.keyCode;
    if (
      (e.target.value.length >= 8 &&
        key !== 8 &&
        key !== 37 &&
        key !== 38 &&
        key !== 39 &&
        key !== 40) ||
      key === 18 ||
      key === 189 ||
      key === 229
    ) {
      e.preventDefault();
    }
  };

  handleZipcodeInputKeyUp = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDeleteQualificationSubmit = (data) => {
    let self = this;
    let qualificationId = data._id;
    this.setState({ miniLoader: true });
    let params = {
      qualificationId: this.state.edu,
      employeeId: this.props.match.params.id,
      isDeleted: 2,
    };

    deleteEmployeeQualification(params).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState(
          {
            miniLoader: false,
            resEmployeeProfileMessage: true,
            successMessage: "Additional Education Deleted",
          },
          () => this.getDetail()
        );
      } else {
        this.setState({
          miniLoader: false,
          resFailEmployeeProfileMessage: true,
          empoyeeProfileResponseMessage: res.data.error.responseMessage,
        });
      }
    });
    setTimeout(() => {
      this.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  handleDocDelete = (docId) => {
    this.setState({ miniLoader: true });
    let params = {
      employeeId: this.props.match.params.id,
      documentId: this.state.datavalue,
      isDeleted: 2,
    };

    deleteEmployeeDocument(params).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState(
          {
            miniLoader: false,
            resEmployeeProfileMessage: true,
            successMessage: "Document Deleted",
          },
          () => this.getDetail()
        );
      } else {
        this.setState({
          miniLoader: false,
          resFailEmployeeProfileMessage: true,
          empoyeeProfileResponseMessage: res.data.error.responseMessage,
        });
      }
    });
    setTimeout(() => {
      this.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  handleEditEduSubmit = (event) => {
    event.preventDefault();

    if (this.handleEduValidetion()) {
      this.setState({ miniLoader: true });
      let obj = {
        employeeId: this.props.match.params.id,
        educationId: this.state.educationId,
        degree: this.state.degree,
        description: this.state.description,
        collegeName: this.state.collegeName,
        startDate: new Date(this.state.startDate).setHours(0, 0, 0, 0),
        endDate: new Date(this.state.endDate).setHours(0, 0, 0, 0),
      };
      editEmployeeEducation(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              miniLoader: false,
              resEmployeeProfileMessage: true,
              successMessage: "Education Edited",
            },
            () => this.getDetail()
          );
        } else {
          this.setState({
            miniLoader: false,
            resFailEmployeeProfileMessage: false,
            empoyeeProfileResponseMessage: res.data.error.responseMessage,
          });
        }
      });
    }
    setTimeout(() => {
      this.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  handleEditQualificationSubmit = (event) => {
    event.preventDefault();
    if (this.handleQualValidetion()) {
      this.setState({ miniLoader: true });
      let obj = {
        employeeId: this.props.match.params.id,
        qualificationId: this.state.qualificationId,
        degree: this.state.degree,
        description: this.state.description,
        collegeName: this.state.collegeName,
        startDate: new Date(this.state.startDate).setHours(0, 0, 0, 0),
        endDate: new Date(this.state.endDate).setHours(0, 0, 0, 0),
      };

      editEmployeeQualification(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              miniLoader: false,
              resEmployeeProfileMessage: true,
              successMessage: "Additional Education Edited",
            },
            () => this.getDetail()
          );
        } else {
          this.setState({
            miniLoader: false,
            resFailEmployeeProfileMessage: false,
            empoyeeProfileResponseMessage: res.data.error.responseMessage,
          });
        }
      });
    }
    setTimeout(() => {
      this.setState({
        resEmployeeProfileMessage: false,
        resFailEmployeeProfileMessage: false,
      });
    }, 4000);
  };

  hadleOpenDatePiker = () =>
    this.setState({ openDatePick: !this.state.openDatePick });

  getSkillList = () => {
    getSkill().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          skillsList: res.data.responseData.skillsList,
        });
      }
    });
  };

  getDepartment = () => {
    getDepartmentList().then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          departmentList: resData.departmentList,
        });
      }
    });
  };

  getDesignationList = () => {
    getDesignation().then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          designationList: resData.designationsList,
          designationId: resData.designationsList.designationId,
        });
      }
    });
  };

  handlePhoneChange = (e) => {
    this.setState({ phoneErrorMsg: "", phone: e });
  };
  handlePhone1Change = (e) => {
    this.setState({ alternateMobErrorMsg: "", alternateMob: e });
  };

  handleRoleChange = (e) => this.setState({ isAdmin: e });

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      this.setState({
        editProfileImage: e.target.files[0],
        employeeProfileErrorMsg: "",
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    let {
      miniLoader,
      loader,
      openEditProfilePopup,
      openAddEducationPopup,
      openEditEducationPopup,
      openAddQualificationPopup,
      assignedCompOffErMsg,
      CompLeave,
      openEditOtherSkillPopup,
      openAddOtherSkillPopup,
      openEditQualificationPopup,
      openAddDocumentPopup,
      openDeleteAccessPopup,
      openProfileChangePopup,
      openDeleteEduPopup,
      openDeleteAddEduPopup,
      profileImagePreview,
      firstName,
      experienceYear,
      experienceMonth,
      empId,
      email,
      personalEmail,
      lastName,
      phone,
      alternateMob,
      dob,
      gender,
      bloodGroup,
      linkdinId,
      linkdinIdErrorMsg,
      skypeId,
      skypeIdErrorMsg,
      arrivalTime,
      arrivalTimeErrorMsg,
      employeeType,
      employeeTypeErrorMsg,
      officeLocation,
      officeLocationErrorMsg,
      education,
      qualification,
      middleName,
      docErrorMsg,
      emailErrorMsg,
      designationIdErrorMsg,
      dobErrorMsg,
      personalEmailErrorMsg,
      lastNameErrorMsg,
      firstNameErrorMsg,
      middleNameErrorMsg,
      phoneErrorMsg,
      alternateMobErrorMsg,
      empIdErrorMsg,
      collegeNameErrorMsg,
      degreeErrorMsg,
      skillErrorMsg,
      otherSkillErrorMsg,
      documentNameErrorMsg,
      dateOfJoining,
      dueAppraisal,
      departmentList,
      designationList,
      skillsList,
      currentZipErrorMsg,
      permanentZipErrorMsg,
      projectDetails,
      skillDetails,
      otherSkillDetails,
      otherSkillDetails1,
      designationDetails,
      deptDetails,
      projectManager,
      currentAddress,
      permanentAddress,
      previousAddress,
      degree,
      collegeName,
      description,
      docImageView,
      startDate,
      endDate,
      startDateError,
      endDateError,
      attachedDocs,
      documentName,
      permanentAddressOne,
      permanentAddressTwo,
      permanentState,
      permanentZip,
      currentAddressOne,
      currentAddressTwo,
      currentState,
      currentZip,
      endOpen,
      imageLoader,
      responseMessage,
      errorCode,
      bredCrum,
      url,
      checkFile,
      employeeProfileErrorMsg,
      resMsg,
      resMsgFail,
      resProfileUpdate,
      resFailProfileUpdate,
      dateOfJoiningErrorMsg,
      openCompOffPopup,
      resAddCompOff,
      wantToCarryForwardCompOff,
      wantToCarryForwardCompOffErrorMsg,
      compOffGivenReason,
      compOffGivenReasonErrorMsg,
      compOffStartDateErrorMsg,
      compOffStartDate,
      isAdmin,
      roleErrorMsg,
      resEmployeeProfileMessage,
      resFailEmployeeProfileMessage,
      successMessage,
      empoyeeProfileResponseMessage,
      clientName,
      clientLocation,
      clientSide,
      clientSideErrorMsg,
      assignedToErrorMsg,
      src,
      crop,
      croppedImageUrl,
    } = this.state;

    return (
      <React.Fragment>
        {loader ? (
          <Loader />
        ) : (
          <div
            className={`body_container cm_bold ${
              miniLoader ? "admin_attendance" : ""
            }`}
          >
            {resMsg ? (
              <div className="alert alert-warning cm_top_fix">
                Profile edited successfully
              </div>
            ) : (
              ""
            )}
            {resAddCompOff ? (
              <div className="alert alert-success cm_top_fix">
                Comp Off Added Successfully
              </div>
            ) : (
              ""
            )}
            {resProfileUpdate ? (
              <div className="alert alert-success cm_top_fix">
                Profile Updated Successfully
              </div>
            ) : (
              ""
            )}
            {resFailProfileUpdate ? (
              <div className="alert alert-danger cm_top_fix">
                Please Try Again
              </div>
            ) : (
              ""
            )}
            {resMsgFail ? (
              <div className="alert alert-danger cm_top_fix">
                {this.state.compOffResponseMessage}
              </div>
            ) : (
              ""
            )}

            <Toastify
              resMsg={resEmployeeProfileMessage}
              resMsgFail={resFailEmployeeProfileMessage}
              text={successMessage}
              responseMessage={empoyeeProfileResponseMessage}
            />

            <div className="cm_breadcrumb ml-4">
              <ul>
                <li>
                  <Link to={url}> {bredCrum ? bredCrum : "EMPLOYEE LIST"}</Link>
                  <div className="cm_breadcrumb_arrow"></div>
                </li>
                <li>PROFILE</li>
              </ul>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-md-3 cm_user_img mr-3">
                    <div className="image-upload">
                      <img
                        className=""
                        src={
                          profileImagePreview
                            ? profileImagePreview
                            : require("../../asset/imgs/user-img.png")
                        }
                        alt=""
                      />
                      {imageLoader ? (
                        <div className="cm_img_loader">
                          <img
                            className="image_loader"
                            src={require("../../asset/svg/imageLoader.svg")}
                            alt="image_loader"
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="img-edit ">
                        <span onClick={this.handleOpenProfileChangePopup}>
                          <i className="fa fa-camera pic-upload"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-7 col-md-12 emp_profile ml-3">
                    <div className="d-flex justify-content-between">
                      <h3 className="mb-4">General Information</h3>
                      <div>
                        <span
                          className="btn mr-3 mb-2"
                          onClick={this.handleOpenEditProfilePopup}
                        >
                          Edit
                        </span>
                        <span
                          className="btn btn-right mb-2"
                          onClick={this.handleOpenCompOffPopup}
                        >
                          Add CompOff
                        </span>
                      </div>
                    </div>
                    <form>
                      <div className="row mb-4">
                        <div className="col-lg-6 col-sm-12">
                          <label>First Name</label>
                          <p>{firstName}</p>
                        </div>
                        <div className="col-lg-6 col-12">
                          <label>Middle Name</label>
                          {!_.isEmpty(middleName) ? (
                            <p>{middleName}</p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Last Name</label>
                          <p>{lastName}</p>
                        </div>
                        <div className="col-6">
                          <label>Employee ID</label>
                          {!_.isEmpty(empId) ? <p>{empId}</p> : <p>No data</p>}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Gender</label>

                          <p>
                            {
                              (gender = gender ? (
                                genderValue(gender)
                              ) : (
                                <span>No data</span>
                              ))
                            }
                          </p>
                        </div>
                        <div className="col-6">
                          <label>D.O.B</label>

                          <p>
                            {dob
                              ? moment.unix(dob / 1000).format("DD/MM/YYYY")
                              : "No data"}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Blood Group</label>
                          {_.isString(bloodGroup) ? (
                            <p>
                              {
                                (bloodGroup = bloodGroup ? (
                                  bloodGroupValue(bloodGroup)
                                ) : (
                                  <span>No data</span>
                                ))
                              }
                            </p>
                          ) : (
                            <p>
                              {
                                (bloodGroup = bloodGroup ? (
                                  bloodGroupValue(parseInt(bloodGroup))
                                ) : (
                                  <span>No data</span>
                                ))
                              }
                            </p>
                          )}
                        </div>
                        <div className="col-6">
                          <label>Reporting Time</label>
                          <p>
                            {arrivalTime
                              ? moment.utc(arrivalTime * 1000).format("HH:mm")
                              : "No data"}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Employee Status</label>
                          <p>
                            {
                              (employeeType = employeeType ? (
                                employeeTypeValue(employeeType)
                              ) : (
                                <span>No data</span>
                              ))
                            }
                          </p>
                        </div>
                        <div className="col-6">
                          <label>Office Location</label>
                          <p>
                            {
                              (officeLocation = officeLocation ? (
                                officeLocationValue(officeLocation)
                              ) : (
                                <span>No data</span>
                              ))
                            }
                          </p>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          <label>LinkedIn</label>
                          {!_.isEmpty(linkdinId) ? (
                            <p>{linkdinId}</p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                        <div className="col-6">
                          <label>Skype </label>
                          {!_.isEmpty(skypeId) ? (
                            <p>{skypeId}</p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                      </div>

                      {/* MODIFICATION ENDS */}

                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Mobile Number</label>
                          {phone && phone === "91" ? (
                            <p>No data</p>
                          ) : (
                            <p>{phone ? ` ${phone}` : ""}</p>
                          )}
                        </div>
                        <div className="col-6">
                          <label>Alternate Number</label>
                          {alternateMob === "" || alternateMob === "91" ? (
                            <p>No data</p>
                          ) : (
                            <p>{alternateMob ? ` +${alternateMob}` : ""}</p>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Official Mail</label>
                          {!_.isEmpty(email) ? <p>{email}</p> : <p>No data</p>}
                        </div>
                        <div className="col-6">
                          <label>Personal Mail</label>
                          {!_.isEmpty(personalEmail) ? (
                            <p>{personalEmail}</p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Date of Joining</label>

                          <p>
                            {dateOfJoining
                              ? moment
                                  .unix(dateOfJoining / 1000)
                                  .format("DD/MM/YYYY")
                              : "No data"}
                          </p>
                        </div>
                        <div className="col-6">
                          <label>Previous Experience</label>

                          <p>
                            {experienceYear ? experienceYear : 0}.
                            {experienceMonth ? experienceMonth : 0}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Due Appraisal</label>

                          <p>
                            {dueAppraisal
                              ? moment
                                  .unix(dueAppraisal / 1000)
                                  .format("DD/MM/YYYY")
                              : "No data"}
                          </p>
                        </div>

                        <div className="col-6">
                          <label>Permanent Address</label>
                          {permanentAddress.permanentAddressOne ||
                          permanentAddress.permanentAddressTwo ||
                          permanentAddress.permanentState ||
                          permanentAddress.permanentZip ? (
                            <p>
                              {permanentAddress.permanentAddressOne
                                ? `${permanentAddress.permanentAddressOne}`
                                : ""}
                              {permanentAddress.permanentAddressOne
                                ? permanentAddress.permanentAddressTwo ||
                                  permanentAddress.permanentState ||
                                  permanentAddress.permanentZip
                                  ? ","
                                  : ""
                                : ""}

                              {permanentAddress.permanentAddressTwo
                                ? ` ${permanentAddress.permanentAddressTwo}`
                                : ""}
                              {permanentAddress.permanentAddressTwo
                                ? permanentAddress.permanentState ||
                                  permanentAddress.permanentZip
                                  ? ","
                                  : ""
                                : ""}

                              {permanentAddress.permanentState
                                ? ` ${permanentAddress.permanentState}`
                                : ""}
                              {permanentAddress.permanentState
                                ? permanentAddress.permanentZip
                                  ? ","
                                  : ""
                                : ""}

                              {permanentAddress.permanentZip
                                ? `${permanentAddress.permanentZip}`
                                : ""}
                            </p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Current Address</label>

                          {currentAddress.currentAddressOn ||
                          currentAddress.currentAddressTwo ||
                          currentAddress.currentState ||
                          currentAddress.currentZip ? (
                            <p>
                              {currentAddress.currentAddressOne
                                ? `${currentAddress.currentAddressOne}`
                                : ""}
                              {currentAddress.currentAddressOne
                                ? currentAddress.currentAddressTwo ||
                                  currentAddress.currentState ||
                                  currentAddress.currentZip
                                  ? ","
                                  : ""
                                : ""}

                              {currentAddress.currentAddressTwo
                                ? ` ${currentAddress.currentAddressTwo}`
                                : ""}
                              {currentAddress.currentAddressTwo
                                ? currentAddress.currentState ||
                                  currentAddress.currentZip
                                  ? ","
                                  : ""
                                : ""}

                              {currentAddress.currentState
                                ? ` ${currentAddress.currentState}`
                                : ""}
                              {currentAddress.currentState
                                ? currentAddress.currentZip
                                  ? ","
                                  : ""
                                : ""}

                              {currentAddress.currentZip
                                ? `${currentAddress.currentZip}`
                                : ""}
                            </p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>

                        <div className="col-6">
                          <label>Previous Address</label>

                          {previousAddress.previousAddressOne ||
                          previousAddress.previousAddressTwo ||
                          previousAddress.previousState ||
                          previousAddress.previousZip ? (
                            <p>
                              {previousAddress.previousAddressOne
                                ? `${previousAddress.previousAddressOne}`
                                : ""}
                              {previousAddress.previousAddressOne
                                ? previousAddress.previousAddressTwo ||
                                  previousAddress.previousState ||
                                  previousAddress.previousZip
                                  ? ","
                                  : ""
                                : ""}

                              {previousAddress.previousAddressTwo
                                ? ` ${previousAddress.previousAddressTwo}`
                                : ""}
                              {previousAddress.previousAddressTwo
                                ? previousAddress.previousState ||
                                  previousAddress.previousZip
                                  ? ","
                                  : ""
                                : ""}

                              {previousAddress.previousState
                                ? ` ${previousAddress.previousState}`
                                : ""}
                              {previousAddress.previousState
                                ? previousAddress.previousZip
                                  ? ","
                                  : ""
                                : ""}

                              {previousAddress.previousZip
                                ? ` ${previousAddress.previousZip}`
                                : ""}
                            </p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Role</label>

                          {isAdmin ? (
                            <p>{employeeRoleValue(isAdmin)}</p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                        <div className="col-6">
                          <label>Client Side</label>

                          {clientSide ? (
                            <p>{clientSideValue(clientSide)}</p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Client Location</label>

                          {clientLocation ? (
                            <p>{clientLocation}</p>
                          ) : (
                            <p>No data</p>
                          )}
                        </div>
                        <div className="col-6">
                          <label>Client Name</label>

                          {clientName ? <p>{clientName}</p> : <p>No data</p>}
                        </div>
                      </div>
                    </form>

                    <div className="row mb-4">
                      <div className="col-12">
                        <label>Department</label>

                        <Collapse bordered={false} defaultActiveKey={["1"]}>
                          <Panel header="View Department">
                            {deptDetails.map((department) => (
                              <p>
                                {department.departmentName
                                  ? department.departmentName
                                  : ""}
                              </p>
                            ))}
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12">
                        <label>Designation</label>

                        <Collapse bordered={false} defaultActiveKey={["1"]}>
                          <Panel header="View Designation">
                            {designationDetails.map((department, i) => (
                              <p>
                                {department.designationName
                                  ? `${department.designationName}`
                                  : ""}
                              </p>
                            ))}
                          </Panel>
                        </Collapse>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12">
                        <label>
                          Skill
                          {!_.isEmpty(skillDetails) ? (
                            ""
                          ) : (
                            <span
                              className="add_btn add-skill mb-2"
                              onClick={this.handleOpenAddOtherSkillPopup}
                            >
                              <i
                                className="fa fa-plus-circle edit_Emp_derails"
                                aria-hidden="true"
                                title="Add skill"
                              ></i>
                            </span>
                          )}
                        </label>
                        {!_.isEmpty(skillDetails) ? (
                          <Collapse bordered={false} defaultActiveKey={["1"]}>
                            <Panel header="View Skill">
                              <span
                                className="add_btn mb-2"
                                onClick={this.handleOpenEditOtherSkillPopup}
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                  title="Edit skill"
                                ></i>
                              </span>
                              {skillDetails.map((department, i) => (
                                <p>
                                  {department.skillName
                                    ? department.skillName
                                    : ""}
                                </p>
                              ))}

                              {otherSkillDetails.length !== 0
                                ? otherSkillDetails
                                : ""}
                            </Panel>
                          </Collapse>
                        ) : (
                          <p>No data</p>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label>
                        Attached Documents
                        <span
                          className="add_btn"
                          onClick={this.handleOpenAddDocumentPopup}
                        >
                          <i
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                            title="Add document"
                          ></i>
                        </span>
                      </label>

                      {!_.isEmpty(attachedDocs) ? (
                        <ul className="cm_proj cm_doc">
                          {attachedDocs.map((data) => (
                            <li id={data._id} key={data._id}>
                              <span>
                                <a href={data.uploadFile} download>
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                    title="Download document"
                                  ></i>
                                </a>
                                <div
                                  className="cm_arrow"
                                  title="Delete document"
                                  onClick={() =>
                                    this.handleOpenDeleteAccessPopup(data._id)
                                  }
                                >
                                  <RemoveIcon />
                                </div>
                              </span>
                              {returnFileIcon(data.fileType)}
                              {data.fileType === "application/pdf" ? (
                                <DocIcon />
                              ) : (
                                ""
                              )}
                              <p className="cm_three_dots">
                                {data.documentName}.{data.fileType}
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No data</p>
                      )}
                    </div>
                    <div className="form-group mb-5 edu_details">
                      <label>
                        Education Qualification{" "}
                        <span
                          className="add_btn mb-2"
                          onClick={this.handleOpenAddEducationPopup}
                        >
                          <i
                            className="fa fa-plus-circle edit_Emp_derails"
                            aria-hidden="true"
                            title="Add education qualification"
                          ></i>
                        </span>
                      </label>

                      {!_.isEmpty(education) ? (
                        education.map((data) => (
                          <p>
                            {degreeType(data.degree)
                              ? `${degreeType(data.degree)}`
                              : ""}
                            {data.collegeName ? `, ${data.collegeName}` : ""}
                            {data.startDate
                              ? `, ${moment
                                  .unix(data.startDate / 1000)
                                  .format("DD/MM/YYYY")}`
                              : ""}
                            {data.endDate
                              ? `- ${moment
                                  .unix(data.endDate / 1000)
                                  .format("DD/MM/YYYY")}`
                              : ""}
                            {data.description ? `, ${data.description}` : ""}

                            <span
                              className="edit_btn mt-3"
                              onClick={() =>
                                this.handleOpenEditEducationPopup(data)
                              }
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                                title="Edit education qualification"
                              ></i>
                            </span>

                            <span
                              className="delete_btn mt-3"
                              onClick={() =>
                                this.handleOpenDeleteEduPopup(data._id)
                              }
                            >
                              <i
                                className="fa fa-trash"
                                aria-hidden="true"
                                title="Delete education qualification "
                              ></i>
                            </span>
                          </p>
                        ))
                      ) : (
                        <p>No data</p>
                      )}
                    </div>

                    <div className="form-group mb-5 edu_details">
                      <label>
                        Additional Qualification{" "}
                        <span
                          className="add_btn mb-2"
                          onClick={this.handleOpenAddQualificationPopup}
                        >
                          <i
                            className="fa fa-plus-circle edit_Emp_derails"
                            aria-hidden="true"
                            title="Add additional qualification"
                          ></i>
                        </span>
                      </label>

                      {!_.isEmpty(qualification) ? (
                        qualification.map((data) => (
                          <p>
                            {degreeType(data.degree)
                              ? `${degreeType(data.degree)}`
                              : ""}
                            {data.collegeName ? `, ${data.collegeName}` : ""}
                            {data.startDate
                              ? `, ${moment
                                  .unix(data.startDate / 1000)
                                  .format("DD/MM/YYYY")}`
                              : ""}
                            {data.endDate
                              ? `- ${moment
                                  .unix(data.endDate / 1000)
                                  .format("DD/MM/YYYY")}`
                              : ""}
                            {data.description ? `, ${data.description} ` : ""}

                            <span
                              className="edit_btn mt-3"
                              onClick={() =>
                                this.handleOpenEditQualificationPopup(data)
                              }
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                                title="Edit additional qualification"
                              ></i>
                            </span>
                            <span
                              className="delete_btn mt-3"
                              onClick={() =>
                                this.handleOpenDeleteAddEduPopup(data._id)
                              }
                            >
                              <i
                                className="fa fa-trash"
                                aria-hidden="true"
                                title="Delete additional qualification"
                              ></i>
                            </span>
                          </p>
                        ))
                      ) : (
                        <p>No data</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Assigned Project</label>
                      <span className="edit_btn"></span>
                      {!_.isEmpty(projectDetails) ? (
                        <ul className="cm_proj">
                          {projectDetails.map((data) => (
                            <li>
                              <div className="proj_name blue">
                                {data.projectName}
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No Data</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Assigned Project As Project Manager</label>
                      <span className="edit_btn"></span>
                      {!_.isEmpty(projectManager) ? (
                        <ul className="cm_proj">
                          {projectManager.map((data) => (
                            <li>
                              <div className="proj_name blue">
                                {data.projectName}
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No data</p>
                      )}
                    </div>
                  </div>
                </div>

                <AntModal
                  title=""
                  centered
                  className="sm_modal"
                  footer={false}
                  visible={openEditProfilePopup}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >
                  <Modal.Body>
                    <form
                      className={`assign_device ${
                        miniLoader ? "admin_attendance" : ""
                      }`}
                      onSubmit={this.handleEditSubmit}
                    >
                      <h3 className="mb-4">EDIT PROFILE</h3>
                      <div className="row cm_bold">
                        <div
                          className={`form-group col-4 ${
                            firstNameErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">First Name</label>
                          <input
                            maxLength="50"
                            className="form-control cm_box"
                            type="text"
                            placeholder="First name"
                            name="firstName"
                            value={firstName}
                            onChange={this.handleChange}
                          />
                          {firstNameErrorMsg ? (
                            <span className="error_msg">
                              {firstNameErrorMsg}
                            </span>
                          ) : null}
                        </div>

                        <div
                          className={`form-group col-4 ${
                            middleNameErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Middle Name</label>
                          <input
                            maxLength="20"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Middle name"
                            name="middleName"
                            value={middleName}
                            onChange={this.handleChange}
                          />

                          {middleNameErrorMsg ? (
                            <span className="error_msg">
                              {middleNameErrorMsg}
                            </span>
                          ) : null}
                        </div>
                        <div
                          className={`form-group col-4 ${
                            lastNameErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Last Name</label>
                          <input
                            maxLength="50"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Last name"
                            name="lastName"
                            value={lastName}
                            onChange={this.handleChange}
                          />
                          {lastNameErrorMsg ? (
                            <span className="error_msg">
                              {lastNameErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="row cm_bold mt-3">
                        <div
                          className={`form-group col-4 ${
                            empIdErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Employee ID</label>
                          <input
                            maxLength="50"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Employee ID"
                            name="empId"
                            value={empId}
                            onChange={this.handleChange}
                          />
                          {empIdErrorMsg ? (
                            <span className="error_msg">{empIdErrorMsg}</span>
                          ) : null}
                          {errorCode === 10 ? (
                            <h3 className="cm_response mt-2">
                              {responseMessage}
                            </h3>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          className={`form-group col-4 ${
                            phoneErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Mobile Number</label>
                          <input
                            maxlength="10"
                            className="form-control"
                            placeholder="Mobile no."
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={this.handleChange}
                          />
                          {phoneErrorMsg ? (
                            <span className="error_msg">{phoneErrorMsg}</span>
                          ) : null}
                        </div>
                        <div
                          className={`form-group col-4 ${
                            alternateMobErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Alternate Number</label>{" "}
                          <input
                            maxlength="10"
                            className="form-control"
                            placeholder="Alternate Mobile no."
                            type="text"
                            name="alternateMob"
                            value={alternateMob}
                            onChange={this.handleChange}
                          />
                          {alternateMobErrorMsg ? (
                            <span className="error_msg">
                              {alternateMobErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="row cm_bold mt-3">
                        <div className="col-4">
                          <label className="sub">Gender</label>
                          <Select
                            defaultValue={gender ? gender : "Select"}
                            value={gender ? gender : "Gender"}
                            onChange={(e) => this.handleGenderChange(e)}
                          >
                            <Option value="1">Male</Option>
                            <Option value="2">Female</Option>
                            <Option value="3">Other</Option>
                          </Select>
                        </div>

                        {/* ******************MODIFICATION STARTS******************** */}
                        <div
                          className={`form-group col-4 ${
                            linkdinIdErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">LinkedIn</label>
                          <input
                            maxlength="50"
                            className="form-control cm_box"
                            type="text"
                            placeholder="linkedIn ID"
                            name="linkdinId"
                            value={linkdinId}
                            onChange={this.handleChange}
                          />
                          {linkdinIdErrorMsg ? (
                            <span className="error_msg">
                              {linkdinIdErrorMsg}
                            </span>
                          ) : null}
                        </div>

                        <div
                          className={`form-group col-4 ${
                            skypeIdErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Skype</label>
                          <input
                            maxlength="50"
                            type="skypeId"
                            className="form-control"
                            placeholder="live:.cid.id"
                            name="skypeId"
                            value={skypeId}
                            onChange={this.handleChange}
                          />
                          {skypeIdErrorMsg ? (
                            <span className="error_msg">{skypeIdErrorMsg}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="row cm_bold mt-3">
                        <div className="form-group col-4">
                          <label className="sub">Blood Group</label>
                          <Select
                            defaultValue="Blood Group"
                            onChange={this.handleBloodGroupChange}
                          >
                            <Option value="1">A+</Option>
                            <Option value="2">B+</Option>
                            <Option value="3">O+</Option>
                            <Option value="4">AB+</Option>
                            <Option value="5">A-</Option>
                            <Option value="6">B-</Option>
                            <Option value="7">O-</Option>
                            <Option value="8">AB-</Option>
                          </Select>
                        </div>
                        <div
                          className={`form-group col-4 ${
                            employeeTypeErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Employee Status*</label>
                          {employeeType === "Fresher" ? (
                            <Select
                              defaultValue={
                                employeeType ? employeeType : "Select"
                              }
                              value={
                                employeeType ? employeeType : "Employee Type"
                              }
                              onChange={(e) =>
                                this.handleSelectEmployeeTypeChange(e)
                              }
                              name="employeeType"
                            >
                              <Option value="3">Experienced</Option>
                              <Option value="2">Probation</Option>
                            </Select>
                          ) : employeeType === "Experienced" ? (
                            <Select
                              defaultValue={
                                employeeType ? employeeType : "Select"
                              }
                              value={
                                employeeType ? employeeType : "Employee Type"
                              }
                              onChange={(e) =>
                                this.handleSelectEmployeeTypeChange(e)
                              }
                              name="employeeType"
                            >
                              <Option value="1">Fresher</Option>
                              <Option value="2">Probation</Option>
                            </Select>
                          ) : employeeType === "Probation" ? (
                            <Select
                              defaultValue={
                                employeeType ? employeeType : "Select"
                              }
                              value={
                                employeeType ? employeeType : "Employee Type"
                              }
                              onChange={(e) =>
                                this.handleSelectEmployeeTypeChange(e)
                              }
                              name="employeeType"
                            >
                              <Option value="3">Experienced</Option>
                              <Option value="1">Fresher</Option>
                            </Select>
                          ) : employeeType === "Intern" ? (
                            <Select
                              defaultValue={
                                employeeType ? employeeType : "Select"
                              }
                              value={
                                employeeType ? employeeType : "Employee Type"
                              }
                              onChange={(e) =>
                                this.handleSelectEmployeeTypeChange(e)
                              }
                              name="employeeType"
                            >
                              <Option value="1">Fresher</Option>
                            </Select>
                          ) : (
                            <Select
                              defaultValue={
                                employeeType ? employeeType : "Select"
                              }
                              value={
                                employeeType ? employeeType : "Employee Type"
                              }
                              onChange={(e) =>
                                this.handleSelectEmployeeTypeChange(e)
                              }
                              name="employeeType"
                            >
                              <Option value="1">Fresher</Option>
                              <Option value="2">Probation</Option>
                              <Option value="3">Experienced</Option>
                              <Option value="4">Intern</Option>
                            </Select>
                          )}
                          {employeeTypeErrorMsg ? (
                            <span className="error_msg">
                              {employeeTypeErrorMsg}
                            </span>
                          ) : null}
                        </div>

                        <div
                          className={`form-group col-4 ${
                            arrivalTimeErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Reporting Time</label>
                          <TimePicker
                            className="form-control"
                            type="time"
                            allowClear={false}
                            format={format}
                            name="arrivalTime"
                            defaultValue={
                              arrivalTime ? moment.utc(arrivalTime * 1000) : ""
                            }
                            onChange={this.handleArrivalTime}
                          />
                          {arrivalTimeErrorMsg ? (
                            <span className="error_msg">
                              {arrivalTimeErrorMsg}
                            </span>
                          ) : null}
                        </div>

                        {/*  */}
                        {/* ************************** modification ends ******************************** */}

                        <div className="col-4">
                          <label className="sub">D.O.B</label>
                          <div>
                            <DatePicker
                              allowClear={false}
                              className=" date-border"
                              disabledDate={this.disabledDate}
                              onChange={this.handleDobChange}
                              name="dob"
                              value={dob ? moment(dob) : ""}
                            />

                            {dobErrorMsg ? (
                              <span className="error_msg">{dobErrorMsg}</span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-4">
                          <label className="sub">Due Appraisal</label>
                          <div>
                            <DatePicker
                              allowClear={false}
                              className=" date-border"
                              disabledDate={this.disabledDate}
                              onChange={this.handleDueAppraisal}
                              name="dob"
                              value={dueAppraisal ? moment(dueAppraisal) : ""}
                            />

                            {dobErrorMsg ? (
                              <span className="error_msg">{dobErrorMsg}</span>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className={`form-group col-4 ${
                            officeLocationErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Office Location*</label>
                          <Select
                            defaultValue={
                              officeLocation ? officeLocation : "Select"
                            }
                            value={
                              officeLocation
                                ? officeLocation
                                : "Office Location"
                            }
                            onChange={(e) => this.handleOfficeLocationChange(e)}
                            name="officeLocation"
                          >
                            <Option value={1}>Noida</Option>
                            <Option value={2}>Lucknow</Option>
                          </Select>
                          {officeLocationErrorMsg ? (
                            <span className="error_msg">
                              {officeLocationErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="row  mt-3">
                        <div
                          className={`form-group col-md-4 ${
                            assignedToErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Department</label>
                          <div className="selectHeight selectDesig">
                            <Select
                              mode="multiple"
                              value={this.state.assignedTo}
                              placeholder="Department"
                              onChange={this.handleSelectChange}
                              style={{ width: "100%" }}
                            >
                              {departmentList.map((item) => (
                                <Select.Option
                                  key={item.departmentId}
                                  value={item.departmentId}
                                >
                                  {item.departmentName}
                                </Select.Option>
                              ))}
                            </Select>
                          </div>

                          {assignedToErrorMsg ? (
                            <span className="error_msg">
                              {assignedToErrorMsg}
                            </span>
                          ) : null}
                        </div>
                        <div
                          className={`form-group col-md-4 ${
                            designationIdErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Designation</label>
                          <div className="selectHeight selectDesig">
                            <Select
                              mode="multiple"
                              value={this.state.assignedDesignation}
                              placeholder="Designation"
                              onChange={this.handleDesignationChange}
                              style={{ width: "100%" }}
                            >
                              {designationList.map((item) => (
                                <Select.Option
                                  key={item.designationId}
                                  value={item.designationId}
                                >
                                  {item.designationName}
                                </Select.Option>
                              ))}
                            </Select>
                          </div>

                          {designationIdErrorMsg ? (
                            <span className="error_msg">
                              {designationIdErrorMsg}
                            </span>
                          ) : null}
                        </div>
                        <div
                          className={`form-group col-md-4 ${
                            dateOfJoiningErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Date of Joining</label>
                          <div>
                            <DatePicker
                              allowClear={false}
                              className=" date-border"
                              disabledDate={this.disabledDate}
                              onChange={this.handleDojChange}
                              value={dateOfJoining ? moment(dateOfJoining) : ""}
                              defaultValue={
                                moment(dateOfJoining)
                                  ? moment(dateOfJoining)
                                  : "Select date"
                              }
                            />
                          </div>
                          {dateOfJoiningErrorMsg ? (
                            <span className="error_msg">
                              {dateOfJoiningErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="row cm_bold mt-4 mb-5">
                        <div
                          className={`form-group col-4 ${
                            emailErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Official Mail</label>
                          <input
                            disabled
                            maxLength="100"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Official mail"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                          />
                          {emailErrorMsg ? (
                            <span className="error_msg">{emailErrorMsg}</span>
                          ) : null}
                          {errorCode === 11 ? (
                            <h3 className="cm_response mt-2">
                              {responseMessage}
                            </h3>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={`form-group col-4 ${
                            personalEmailErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Personal Mail</label>
                          <input
                            maxLength="100"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Personal mail"
                            name="personalEmail"
                            value={personalEmail}
                            onChange={this.handleChange}
                          />
                          {personalEmailErrorMsg ? (
                            <span className="error_msg">
                              {personalEmailErrorMsg}
                            </span>
                          ) : null}
                          {errorCode === 12 ? (
                            <h3 className="cm_response mt-2">
                              {responseMessage}
                            </h3>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-4">
                          <label className="sub">Previous Experience</label>
                          <div className="row">
                            <div className="col-6">
                              <Select
                                className="cm_exp_select"
                                value={experienceYear ? experienceYear : "Year"}
                                style={{ width: 125 }}
                                onChange={this.handleSelectChangeYear}
                              >
                                <Option key="1" disabled>
                                  Year
                                </Option>
                                {year.map((val, index) => (
                                  <Option key={index} value={val + 1}>
                                    {val + 1}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            <div className="col-6">
                              <Select
                                className="cm_exp_select"
                                value={
                                  experienceMonth ? experienceMonth : "Month"
                                }
                                style={{ width: 122 }}
                                onChange={this.handleSelectChangeMonth}
                              >
                                <Option key="1" disabled>
                                  Month
                                </Option>
                                {month.map((val, index) => (
                                  <Option key={index} value={val + 1}>
                                    {val + 1}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row cm_bold mt-4 mb-5">
                        <div
                          className={`form-group col-4 ${
                            clientSideErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Client Side*</label>
                          <Select
                            onChange={this.handleClientSideChange}
                            name="clientSide"
                            value={
                              clientSide
                                ? clientSideValue(clientSide)
                                : "Client Side"
                            }
                          >
                            <Option value={1}>Yes</Option>
                            <Option value={2}>No</Option>
                          </Select>
                          {clientSideErrorMsg ? (
                            <span className="error_msg">
                              {clientSideErrorMsg}
                            </span>
                          ) : null}
                        </div>

                        <div className={`form-group col-4`}>
                          <label className="sub">Client Location</label>
                          <input
                            maxlength="50"
                            type="text"
                            className="form-control"
                            placeholder="Client Location"
                            name="clientLocation"
                            value={clientLocation}
                            onChange={this.handleChange}
                            disabled={clientSide == 2 ? true : false}
                          />
                        </div>

                        <div className={`form-group col-4`}>
                          <label className="sub">Client Name</label>
                          <input
                            maxlength="50"
                            type="text"
                            className="form-control"
                            placeholder="Client Name"
                            name="clientName"
                            value={clientName}
                            onChange={this.handleChange}
                            disabled={clientSide == 2 ? true : false}
                          />
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <label className="sub">Current Address One</label>
                          <input
                            maxlength="50"
                            type="text"
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            className="form-control"
                            placeholder="Current address one"
                            name="currentAddressOne"
                            value={currentAddressOne}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <label className="sub">Current Address Two</label>
                          <input
                            maxlength="50"
                            type="text"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            className="form-control"
                            placeholder="Current address two"
                            name="currentAddressTwo"
                            value={currentAddressTwo}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="row mb-4 mt-4">
                        <div className="form-group col-6">
                          <label className="sub">Current Address State</label>
                          <input
                            maxlength="50"
                            type="text"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            className="form-control"
                            placeholder="Current address state"
                            name="currentState"
                            value={currentState}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group col-6">
                          <label className="sub">Current Address Zip</label>

                          <input
                            className="form-control"
                            inputmode="numeric"
                            onKeyDown={(e) => this.handleZipcodeInputKeyDown(e)}
                            onKeyUp={(e) => this.handleZipcodeInputKeyUp(e)}
                            type="number"
                            pattern="\d*"
                            placeholder="Current address zip"
                            name="currentZip"
                            value={currentZip}
                            onChange={this.handleChange}
                          />
                          {currentZipErrorMsg ? (
                            <span className="error_msg">
                              {currentZipErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-12">
                          <label className="sub">Permanent Address One</label>
                          <input
                            maxlength="50"
                            type="text"
                            className="form-control"
                            placeholder="Permanent address one"
                            name="permanentAddressOne"
                            value={permanentAddressOne}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-12">
                          <label className="sub">Permanent Address Two</label>
                          <input
                            maxlength="50"
                            type="text"
                            className="form-control"
                            placeholder="Permanent address two"
                            name="permanentAddressTwo"
                            value={permanentAddressTwo}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-6">
                          <label className="sub">Permanent Address State</label>
                          <input
                            maxlength="50"
                            type="text"
                            className="form-control"
                            placeholder="Permanent address state"
                            name="permanentState"
                            value={permanentState}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group col-6">
                          <label className="sub">Permanent Address Zip</label>

                          <input
                            pattern="\d*"
                            inputmode="numeric"
                            onKeyDown={(e) => this.handleZipcodeInputKeyDown(e)}
                            onKeyUp={(e) => this.handleZipcodeInputKeyUp(e)}
                            type="number"
                            className="form-control"
                            placeholder="Permanent address zip"
                            name="permanentZip"
                            value={permanentZip}
                            onChange={this.handleChange}
                          />
                          {permanentZipErrorMsg ? (
                            <span className="error_msg">
                              {permanentZipErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={`form-group col-md-6 mt-2 ${
                            roleErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Role</label>
                          <Select
                            defaultValue="Employee"
                            onChange={this.handleRoleChange}
                            name="role"
                            value={employeeRoleValue(isAdmin)}
                          >
                            <Option value={2}>Employee</Option>
                            <Option value={3}>Vender</Option>
                            <Option value={4}>HR</Option>
                            <Option value={5}>Accountant</Option>
                            <Option value={6}>Sales</Option>
                            <Option value={7}>IT</Option>
                            <Option value={8}>Office Admin</Option>
                          </Select>
                          {roleErrorMsg ? (
                            <span className="error_msg">{roleErrorMsg}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="text-center">
                        {miniLoader ? (
                          <div className="cover-spin mb-5"></div>
                        ) : (
                          ""
                        )}

                        <button
                          type="submit"
                          className="btn btn-primary mt-5 cm_btn3"
                          disabled={miniLoader}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary mt-5 ml-4 cm_btn2"
                          onClick={this.handleCancel}
                          disabled={miniLoader}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </AntModal>
                <AntModal
                  className="sm_modal1"
                  size="lg"
                  centered
                  title=""
                  footer={false}
                  visible={openAddEducationPopup}
                  onOk={this.handleOk}
                  onCancel={this.closeModal}
                >
                  <Modal.Body>
                    <form
                      className="assign_device"
                      onSubmit={this.handleAddEduSubmit}
                    >
                      <h3 className="mb-5">ADD EDUCATION</h3>
                      <div
                        className={`form-group mb-3 ${
                          degreeErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <label className="sub">Education Qualification</label>

                        <Select
                          placeholder="Enter education qualification"
                          onChange={this.handleDegreeChange}
                          value={
                            degree
                              ? degreeType(degree)
                              : "Enter education qualification"
                          }
                        >
                          <Option value="1">UG</Option>
                          <Option value="2">Graduation</Option>
                          <Option value="3">PG</Option>
                        </Select>
                        {degreeErrorMsg ? (
                          <span className="error_msg">{degreeErrorMsg}</span>
                        ) : null}
                      </div>

                      <label className="sub">School/College name</label>
                      <div
                        className={`form-group mb-3 ${
                          collegeNameErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <input
                          maxLength="50"
                          className="form-control cm_box"
                          type="text"
                          placeholder="Enter school/college name"
                          name="collegeName"
                          value={collegeName}
                          onChange={this.handleChange}
                        />
                        {collegeNameErrorMsg ? (
                          <span className="error_msg">
                            {collegeNameErrorMsg}
                          </span>
                        ) : null}
                      </div>
                      <div className="row">
                        <div
                          className={`col-6 form-group ${
                            startDateError ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Start Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledStartDate}
                            value={!startDate ? startDate : moment(startDate)}
                            placeholder="Start date"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                          />
                          {startDateError ? (
                            <span className="error_msg">{startDateError}</span>
                          ) : null}
                        </div>
                        <div
                          className={`col-6 form-group ${
                            endDateError ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">End Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledEndDate}
                            value={!endDate ? endDate : moment(endDate)}
                            placeholder="End date"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                          {endDateError ? (
                            <span className="error_msg">{endDateError}</span>
                          ) : null}
                        </div>
                      </div>

                      <label className="sub">Description(Optional)</label>
                      <textarea
                        type="text"
                        maxLength={500}
                        placeholder="Enter description"
                        className="form-control cm-des cm_box"
                        name="description"
                        value={description}
                        onChange={this.handleChange}
                      ></textarea>

                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary mt-2 cm_btn3"
                          disabled={miniLoader}
                        >
                          Save
                        </button>
                        {miniLoader ? (
                          <div className="mini_loader_dept"></div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-primary mt-2  ml-4 cm_btn2"
                          onClick={this.closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </AntModal>
                <AntModal
                  className="sm_modal1"
                  size="lg"
                  centered
                  title=""
                  footer={false}
                  visible={openEditEducationPopup}
                  onOk={this.handleOk}
                  onCancel={this.closeModal}
                >
                  <Modal.Body>
                    <form
                      className="assign_device"
                      onSubmit={this.handleEditEduSubmit}
                    >
                      <h3 className="mb-5">EDIT EDUCATION</h3>
                      <div
                        className={`form-group mb-3 ${
                          degreeErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <label className="sub">Education Qualification</label>

                        <Select
                          onChange={this.handleDegreeChange}
                          value={
                            degree
                              ? degreeType(degree)
                              : "Enter education qualification"
                          }
                        >
                          <Option value="1">UG</Option>
                          <Option value="2">Graduation</Option>
                          <Option value="3">PG</Option>
                        </Select>
                        {degreeErrorMsg ? (
                          <span className="error_msg">{degreeErrorMsg}</span>
                        ) : null}
                      </div>

                      <label className="sub">School/College name</label>
                      <div
                        className={`form-group mb-3 ${
                          collegeNameErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <input
                          maxLength="50"
                          className="form-control cm_box"
                          type="text"
                          placeholder="Enter school/college name"
                          name="collegeName"
                          value={collegeName}
                          onChange={this.handleChange}
                        />
                        {collegeNameErrorMsg ? (
                          <span className="error_msg">
                            {collegeNameErrorMsg}
                          </span>
                        ) : null}
                      </div>

                      <div className="row">
                        <div className="col-6 form-group">
                          <label className="sub">Start Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledStartDate}
                            value={!startDate ? startDate : moment(startDate)}
                            placeholder="Start date"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                          />
                        </div>
                        <div className="col-6 form-group">
                          <label className="sub">End Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledEndDate}
                            value={!endDate ? endDate : moment(endDate)}
                            placeholder="End date"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                        </div>
                      </div>

                      <label className="sub">Description(Optional)</label>
                      <textarea
                        type="text"
                        placeholder="Enter description"
                        className="form-control cm-des cm_box"
                        name="description"
                        maxLength={500}
                        value={description}
                        onChange={this.handleChange}
                      ></textarea>
                      <div className="text-center">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary mt-4 mr-4 cm_btn3"
                          disabled={miniLoader}
                        >
                          Save
                        </button>
                        {miniLoader ? (
                          <div className="mini_loader_dept"></div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn btn-primary mt-4 cm_btn2"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </AntModal>
                <AntModal
                  className="sm_modal1"
                  size="lg"
                  centered
                  title=""
                  footer={false}
                  visible={openAddQualificationPopup}
                  onOk={this.handleOk}
                  onCancel={this.closeModal}
                >
                  <Modal.Body>
                    <form
                      className="assign_device"
                      onSubmit={this.handleAddQualificationSubmit}
                    >
                      <h3 className="mb-5">ADD QUALIFICATION</h3>
                      <div
                        className={`form-group mb-3 ${
                          degreeErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <label className="sub">Additional Qualification</label>
                        <Select
                          placeholder="Enter additional qualification"
                          onChange={this.handleDegreeChange}
                          value={
                            degree
                              ? degreeType(degree)
                              : "Enter additional qualification"
                          }
                        >
                          <Option value="1">UG</Option>
                          <Option value="2">Graduation</Option>
                          <Option value="3">PG</Option>
                        </Select>
                        {degreeErrorMsg ? (
                          <span className="error_msg">{degreeErrorMsg}</span>
                        ) : null}
                      </div>
                      <label className="sub">School/College name</label>
                      <div
                        className={`form-group mb-3 ${
                          collegeNameErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <input
                          maxLength="50"
                          className="form-control cm_box"
                          type="text"
                          placeholder="Enter school/college name"
                          name="collegeName"
                          value={collegeName}
                          onChange={this.handleChange}
                        />
                        {collegeNameErrorMsg ? (
                          <span className="error_msg">
                            {collegeNameErrorMsg}
                          </span>
                        ) : null}
                      </div>
                      <div className="row">
                        <div
                          className={`col-6 form-group ${
                            startDateError ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Start Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledStartDate}
                            format="DD-MM-YYYY"
                            value={!startDate ? startDate : moment(startDate)}
                            placeholder="Start date"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                          />
                          {startDateError ? (
                            <span className="error_msg">{startDateError}</span>
                          ) : null}
                        </div>
                        <div
                          className={`col-6 form-group ${
                            endDateError ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">End Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledEndDate}
                            format="DD-MM-YYYY"
                            value={!endDate ? endDate : moment(endDate)}
                            placeholder="End date"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                          {endDateError ? (
                            <span className="error_msg">{endDateError}</span>
                          ) : null}
                        </div>
                      </div>

                      <label className="sub">Description(Optional)</label>
                      <textarea
                        type="text"
                        placeholder="Enter description"
                        className="form-control cm-des cm_box"
                        name="description"
                        value={description}
                        maxLength={500}
                        onChange={this.handleChange}
                      ></textarea>

                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary mt-2 cm_btn3"
                          disabled={miniLoader}
                        >
                          Save
                        </button>
                        {miniLoader ? (
                          <div className="mini_loader_dept"></div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-primary mt-2  ml-4 cm_btn2"
                          onClick={this.closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </AntModal>

                <AntModal
                  className="sm_modal1"
                  size="lg"
                  centered
                  title=""
                  footer={false}
                  visible={openEditQualificationPopup}
                  onOk={this.handleOk}
                  onCancel={this.closeModal}
                >
                  <Modal.Body>
                    <form
                      className="assign_device"
                      onSubmit={this.handleEditQualificationSubmit}
                    >
                      <h3 className="mb-5">EDIT QUALIFICATION</h3>
                      <div
                        className={`form-group mb-3 ${
                          degreeErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <label className="sub">Additional Qualification</label>

                        <Select
                          value={
                            degree
                              ? degreeType(degree)
                              : "Enter education qualification"
                          }
                          onChange={this.handleDegreeChange}
                        >
                          <Option value="1">UG</Option>
                          <Option value="2">Graduation</Option>
                          <Option value="3">PG</Option>
                        </Select>
                        {degreeErrorMsg ? (
                          <span className="error_msg">{degreeErrorMsg}</span>
                        ) : null}
                      </div>
                      <label className="sub">School/College name</label>
                      <div
                        className={`form-group mb-3 ${
                          collegeNameErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <input
                          maxLength="50"
                          className="form-control cm_box"
                          type="text"
                          placeholder="Enter school/college name"
                          name="collegeName"
                          value={collegeName}
                          onChange={this.handleChange}
                        />
                        {collegeNameErrorMsg ? (
                          <span className="error_msg">
                            {collegeNameErrorMsg}
                          </span>
                        ) : null}
                      </div>

                      <div className="row">
                        <div className="col-6 form-group">
                          <label className="sub">Start Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledStartDate}
                            format="DD-MM-YYYY"
                            value={!startDate ? startDate : moment(startDate)}
                            placeholder="Start date"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                          />
                        </div>
                        <div className="col-6 form-group">
                          <label className="sub">End Date</label>
                          <DatePicker
                            className=" date-border"
                            allowClear={false}
                            disabledDate={this.disabledEndDate}
                            format="DD-MM-YYYY"
                            value={!endDate ? endDate : moment(endDate)}
                            placeholder="End date"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                        </div>
                      </div>

                      <label className="sub">Description(Optional)</label>
                      <textarea
                        type="text"
                        placeholder="Enter description"
                        className="form-control cm-des cm_box"
                        name="description"
                        maxLength={500}
                        value={description}
                        onChange={this.handleChange}
                      ></textarea>

                      <div className="text-center">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-primary mt-4 mr-4 cm_btn3"
                        >
                          Save
                        </button>
                        {miniLoader ? (
                          <div className="mini_loader_dept"></div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn btn-primary mt-4 cm_btn2"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </AntModal>
                <Modal
                  size="md"
                  show={openAddDocumentPopup}
                  onHide={this.closeModal}
                  aria-labelledby="example-modal-sizes-title-sm"
                  centered
                  className="cm_upload"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <form onSubmit={this.handleDocumentSubmit}>
                      <h3 className="mb-3">ADD DOCUMENTS</h3>
                      <div
                        className={`form-group mb-3 ${
                          documentNameErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <label className="mb-3 cm_bold">Document Name</label>
                        <input
                          maxLength="50"
                          className="form-control cm_box"
                          type="text"
                          placeholder="Enter document name"
                          name="documentName"
                          value={documentName}
                          onChange={this.handleChange}
                        />
                        {documentNameErrorMsg ? (
                          <span className="error_msg">
                            {documentNameErrorMsg}
                          </span>
                        ) : null}
                      </div>

                      <div
                        className={`form-group ${
                          docErrorMsg ? "input_error" : ""
                        }`}
                      >
                        <label className="mb-3 cm_bold">Attach Document</label>

                        <Dropzone
                          onDrop={(acceptedFile) =>
                            this.handleChallengeImage(acceptedFile)
                          }
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                              <div
                                className={`${
                                  checkFile === 3 ? "upload_doc" : "upload_dock"
                                }`}
                                {...getRootProps()}
                                style={{ background: `url(${docImageView})` }}
                              >
                                <input {...getInputProps()} />

                                {docImageView ? (
                                  ""
                                ) : (
                                  <p>
                                    Drag & drop here
                                    <br />
                                    or
                                    <br />
                                    <span className="blue">Browse files</span>
                                  </p>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {docErrorMsg ? (
                          <span className="error_msg">{docErrorMsg}</span>
                        ) : null}
                      </div>
                      <div className="text-center mt-3">
                        <button
                          className="btn btn-primary mt-4 mr-4 cm_btn3"
                          type="submit"
                          disabled={miniLoader}
                        >
                          Save
                        </button>
                        {miniLoader ? <div className="mini_loader"></div> : ""}
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn btn-primary mt-4 cm_btn2"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
                <Modal
                  size="md"
                  show={openDeleteAccessPopup}
                  onHide={this.closeModal}
                  aria-labelledby="example-modal-sizes-title-sm"
                  centered
                >
                  <Modal.Body className="text-center confirmation">
                    <p className="mb-4 cm_bold cnfrm_msg">
                      Do you want to delete this document?
                    </p>

                    <div className="text-center mt-3 edit_appraisal ">
                      <span
                        className="btn btn-danger mt-4 mr-4 cm_btn3 btn-sm"
                        onClick={() => this.handleDocDelete(attachedDocs._id)}
                      >
                        Delete
                      </span>
                      {miniLoader ? <div className="mini_loader"></div> : ""}

                      <button
                        onClick={this.closeModal}
                        className="btn btn-primary mt-4 cm_btn2 btn-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  size="md"
                  show={openDeleteEduPopup}
                  onHide={this.closeModal}
                  aria-labelledby="example-modal-sizes-title-sm"
                  centered
                >
                  <Modal.Body className="text-center confirmation">
                    <p className="mb-4 cm_bold cnfrm_msg">
                      Do you want to delete this education qualification?
                    </p>

                    <div className="text-center mt-3 edit_appraisal ">
                      <span
                        className="btn btn-danger mt-4 mr-4 cm_btn3 btn-sm"
                        onClick={() => this.handleDeleteEduSubmit(education)}
                      >
                        Delete
                      </span>
                      {miniLoader ? <div className="mini_loader"></div> : ""}

                      <button
                        onClick={this.closeModal}
                        className="btn btn-primary mt-4 cm_btn2 btn-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  size="md"
                  show={openDeleteAddEduPopup}
                  onHide={this.closeModal}
                  aria-labelledby="example-modal-sizes-title-sm"
                  centered
                >
                  <Modal.Body className="text-center confirmation">
                    <p className="mb-4 cm_bold cnfrm_msg">
                      Do you want to delete this additional qualification?
                    </p>

                    <div className="text-center mt-3 edit_appraisal ">
                      <span
                        className="btn btn-danger mt-4 mr-4 cm_btn3 btn-sm"
                        onClick={() =>
                          this.handleDeleteQualificationSubmit(qualification)
                        }
                      >
                        Delete
                      </span>
                      {miniLoader ? <div className="mini_loader"></div> : ""}
                      <button
                        onClick={this.closeModal}
                        className="btn btn-primary mt-4 cm_btn2 btn-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  size="md"
                  show={openCompOffPopup}
                  onHide={this.closeModal}
                  aria-labelledby="example-modal-sizes-title-sm"
                  centered
                  // className="grant_access"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="text-center confirmation">
                    {/* <div className="grant_access"> */}

                    <h3 className="mb-4">Add Compensatory Off</h3>

                    <div
                      className={`form-group ${
                        assignedCompOffErMsg ? "input_error" : ""
                      }`}
                    >
                      <input
                        className="form-control cm_box"
                        type="number"
                        step="any"
                        placeholder=" Enter no. of day"
                        name="CompLeave"
                        value={CompLeave}
                        onChange={this.handleCompOffDayChange}
                      />
                      {assignedCompOffErMsg ? (
                        <span
                          className=""
                          style={{
                            paddingLeft: "",
                            color: "red",
                            bottom: "-22",
                          }}
                        >
                          {assignedCompOffErMsg}
                        </span>
                      ) : null}
                    </div>

                    <h3 className="mt-2">Temporary CompOff Leave</h3>
                    <div
                      className={`row  ${
                        wantToCarryForwardCompOffErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <div className="col-12">
                        <Select
                          className="cm_leaves "
                          name={wantToCarryForwardCompOff}
                          placeholder="Select Leave"
                          value={
                            wantToCarryForwardCompOff
                              ? wantToCarryForwardCompOff
                              : "Select Type"
                          }
                          onChange={this.handleTypeCompOffChange}
                        >
                          <Option key="1">Yes</Option>
                          <Option key="2">No</Option>
                        </Select>
                        {wantToCarryForwardCompOffErrorMsg ? (
                          <span className="error_msg">
                            {wantToCarryForwardCompOffErrorMsg}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {wantToCarryForwardCompOff == "1" ? (
                      <div className="row mt-2">
                        <div
                          className={`form-group col-12 ${
                            compOffStartDateErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Expiry Date</label>
                          <Calendar
                            onChange={this.onCompOffStartChange}
                            value={compOffStartDate}
                            minDate={new Date()}
                          />
                          {compOffStartDateErrorMsg ? (
                            <span className="error_msg">
                              {compOffStartDateErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <h3 className="mt-2">Reason</h3>
                    <div
                      className={`form-group col-12 ${
                        compOffGivenReasonErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <textarea
                        name="compOffGivenReason"
                        placeholder="Leave reason"
                        maxLength={500}
                        value={compOffGivenReason}
                        onChange={this.handleCompOffGivenReasonChange}
                        className="form-control cm_input"
                      ></textarea>
                      {compOffGivenReasonErrorMsg ? (
                        <span className="error_msg">
                          {compOffGivenReasonErrorMsg}
                        </span>
                      ) : null}
                    </div>
                    <div className="text-center mt-5">
                      <button
                        className="btn btn-primary  mr-2 btn-sm cm_btn3"
                        onClick={this.handleParticipant}
                      >
                        Add
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  size="md"
                  show={openAddOtherSkillPopup}
                  onHide={this.closeModal}
                  aria-labelledby="example-modal-sizes-title-sm"
                  centered
                  className="grant_access skill"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="text-center">
                    <h3 className="mb-4">ADD SKILLS</h3>
                    <div
                      className={`form-group mb-3 ${
                        skillErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <h6 className="mt-4 text-left cm_bold">Select Skill</h6>
                      <div className="selectHeight selectDesig">
                        <Select
                          mode="multiple"
                          value={this.state.assignedSkill}
                          placeholder="Select skill"
                          onChange={this.handleSelectSkill}
                          style={{ width: "100%" }}
                        >
                          {skillsList.map((item) => (
                            <Select.Option
                              key={item.skillId}
                              value={item.skillId}
                            >
                              {item.skillName}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      {skillErrorMsg ? (
                        <span className="error_msg">{skillErrorMsg}</span>
                      ) : null}
                    </div>

                    <div className="form-group mb-3">
                      <h6 className="mt-4 text-left cm_bold">Other Skill</h6>
                      <input
                        maxLength="50"
                        className="form-control"
                        type="text"
                        placeholder="Add other skill"
                        value={otherSkillDetails}
                        name="otherSkillDetails"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="row"></div>
                    <div className="text-center mt-2">
                      {miniLoader ? (
                        <DotLoader />
                      ) : (
                        <button
                          className="btn btn-primary  mr-2 btn-sm cm_btn3"
                          onClick={this.handleAddOtherSkill}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  size="md"
                  show={openEditOtherSkillPopup}
                  onHide={this.closeModal}
                  aria-labelledby="example-modal-sizes-title-sm"
                  centered
                  className="grant_access skill"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="text-center">
                    <h3 className="mb-4">EDIT SKILLS</h3>
                    <div
                      className={`form-group mb-3 ${
                        otherSkillErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <h6 className="mt-4 text-left cm_bold">Select Skill</h6>
                      <div className="selectHeight selectDesig">
                        <Select
                          mode="multiple"
                          value={this.state.assignedSkill1}
                          placeholder="Select skill"
                          onChange={this.handleSelectOtherSkill}
                          style={{ width: "100%" }}
                        >
                          {skillsList.map((item) => (
                            <Select.Option
                              key={item.skillId}
                              value={item.skillId}
                            >
                              {item.skillName}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      {otherSkillErrorMsg ? (
                        <span className="error_msg">{otherSkillErrorMsg}</span>
                      ) : null}
                    </div>

                    <div className="form-group mb-3">
                      <h6 className="mt-4 text-left cm_bold">Other Skill</h6>

                      <input
                        maxLength="50"
                        className="form-control"
                        type="text"
                        placeholder="Add other skill"
                        value={otherSkillDetails1}
                        name="otherSkillDetails1"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="row"></div>
                    <div className="text-center mt-2">
                      {miniLoader ? (
                        <DotLoader />
                      ) : (
                        <button
                          className="btn btn-primary  mr-2 btn-sm cm_btn3"
                          onClick={this.handleEditOtherSkill}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
                <AntModal
                  title=""
                  centered
                  className="sm_modal"
                  footer={false}
                  visible={openProfileChangePopup}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel1}
                >
                  <Modal.Body>
                    <div
                      className={`assign_device ${
                        miniLoader ? "admin_attendance" : ""
                      }`}
                    >
                      <h3 className="mb-4">EDIT PROFILE PICTURE</h3>
                      <div className="row">
                        <div
                          className={`form-group ${
                            employeeProfileErrorMsg ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Upload Picture</label>
                          <input
                            className="form-control cm_bold cm_box "
                            type="file"
                            id="profile"
                            placeholder="Upload image"
                            accept=".png, .jpg, .jpeg"
                            onChange={this.onSelectFile}
                            disabled={miniLoader}
                          />
                          <div className="mt-2">
                            {src && (
                              <ReactCrop
                                src={src}
                                crop={crop}
                                aspect={1}
                                ruleOfThirds
                                circularCrop
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                style={{ width: "14rem", height: "14rem" }}
                              />
                            )}
                            {croppedImageUrl && src && (
                              <img
                                alt="Crop"
                                style={{
                                  width: "12rem",
                                  position: "absolute",
                                  bottom: "5px",
                                  marginLeft: "12px",
                                  borderRadius: "50%",
                                }}
                                src={croppedImageUrl}
                              />
                            )}
                          </div>
                          {employeeProfileErrorMsg ? (
                            <span className="error_msg">
                              {employeeProfileErrorMsg}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="text-center">
                        {miniLoader ? <div className="cover-spin1"></div> : ""}
                        <button
                          type="submit"
                          className="btn btn-primary mt-5 cm_btn3"
                          onClick={this.handleProfileSubmit}
                          disabled={miniLoader}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary mt-5 ml-4 cm_btn2"
                          onClick={this.handleCancel1}
                          disabled={miniLoader}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </AntModal>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default EmployeeProfile;

import React from 'react';
import { Select } from 'antd';
import { Link } from 'react-router-dom';
import { DatePicker, Modal as AntModal } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import ResponseFilter from '../../ResponseFilter';
import { Loader, PdfIcon, RemoveIcon } from '../svg';
import Pagination from "react-js-pagination";
import { getDeviceListByEmp, getDeviceListByManager, getDeviceListByEmployee } from '../../config/services/employeeServices';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DeviceFullScreenImage from './DeviceFullScreenImage';
import { getFileNameFromUrl } from '../../config/constants/Constants';
const { Option } = Select;
function disabledDate(current) {

  return current && current > moment().endOf('day');
}


let settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  // nextArrow:<this.SampleNextArrow/>,

  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },

    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
class EmployeeDeviceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      openDeviceInfoPopup: false,
      openAddDevicePopup: false,
      deviceListByManager: [],
      deviceList: [],
      deviceDetails: [],
      assignedTo: '',
      // deviceManager: '',
      employeeList: [],
      employeeId: '',
      // deviceManagerId:'',
      deviceType: '',
      operatingSystem: '',
      brand: '',
      imei: '',
      acessories: [],
      ram: '',
      hdd: '',
      dateAdded: '',
      status: '',
      brandErrorMsg: '',
      ramErrorMsg: '',
      hddErrorMsg: '',
      dateErrorMsg: '',
      deviceModel: '',
      operatingSystemErrorMsg: "",
      password: '',
      editDivceObj: '',
      deviceId: '',
      totalDevices: '',
      dataCount: '',
      phones: '',
      desktops: '',
      laptops: '',
      deleteDevice: '',
      firstName: '',
      lastName: '',
      devId: '',
      search: '',
      loader: true,
      sortType: 2,
      sortField: "created",
      pageNo: 1,
      count: 50,
      mouse: "",
      connectors: "",
      ups: '',
      routers: "",
      headphone: "",
      wifiAdaptor: "",
      webCamera: "",
      keyboard: "",
      cpu: "",
      pendrive: "",
      hardDisk: "",
      ram1: "",
      comment: "",
      uploadBill: "",
      uploadFileName: "",
      uploadBillErrorMsg: "",
      billPreview: "",
      cpu: "",
      monitor: "",
      macMini: "",
      devicePictures: [],
      comment: "",
      assignedFrom: "",
      assignedTill: "",
      preview: false,
      fullImage: "",
      deviceVersion: ""
    }
  }

  handleOpenAddDevicePopup = () =>
    this.setState({ openAddDevicePopup: !this.state.openAddDevicePopup });
  handleSearch = e => {
    this.setState({ search: e.target.value, pageNo: 1 }, () => this.getDevice());
  }

  handleChange = (e) =>
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + "ErrorMsg"]: "",
    });
  handleOperatingSystemChange = (e) => this.setState({ operatingSystem: e, operatingSystemErrorMsg: "" });

  handleDeviceChange = (e) =>
    this.setState({ deviceType: e, deviceTypeErrorMsg: "" });
  submitSearch = e => {
    e.preventDefault();
    this.setState({ loader: true })
    this.getDevice()
  }


  handleValidetion = () => {
    let validate = true;
    const {
      deviceType,
      operatingSystem,
    } = this.state;
    if (deviceType === "") {
      validate = false;
      this.setState({ deviceTypeErrorMsg: "Device Type is required" });
    } else {
      this.setState({ deviceTypeErrorMsg: "" });
    }
    if (operatingSystem === "") {
      validate = false;
      this.setState({ operatingSystemErrorMsg: "Operating Sysytem is required" });
    } else {
      this.setState({ operatingSystemErrorMsg: "" });
    }
    return validate;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let {
      password,
      devId,
      brand,
      ram,
      hdd,
      dateAdded,
      assignedTo,
      // deviceManager,
      deviceType,
      operatingSystem,
      imei,
      // acessories,
      deviceModel,
    } = this.state;

    if (this.handleValidetion()) {
      let obj = {
        operatingSystem: operatingSystem,
        // acessories:
        //   acessories.length != 0 ? this.state.acessories.toString() : [],
        deviceType: deviceType,
      };

      // addDevice(obj).then((res) => {
      //   if (res.data.statusCode === 1) {
      //     // window.location.href = "/employee-device";
      //     crossOriginIsolated.log("hiiiihi")
      //   }
      // });
    }
  };

  handleOpenDeviceInfoPopup = ({
    deviceId,
    deviceModel,
    // assignedTo,
    // deviceManager,
    deviceType,
    operatingSystem,
    imei,
    acessories,
    ram,
    hdd,
    password,
    brand,
    assignedDate,
    dateAdded,
    status,
    firstName,
    lastName,
    devId,
    devicePictures,
    comment,
    assigndevicesDetails,
    assignedFrom,
    assignedTill,
    deviceVersion,

  }) => this.setState({
    openDeviceInfoPopup: !this.state.openDeviceInfoPopup,
    // editDivceObj: deviceId,
    deviceModel,
    // assignedTo,
    // // deviceManager,
    deviceType,
    operatingSystem,
    brand,
    imei,
    assignedDate,
    assignedFrom,
    assignedTill,
    // acessories,
    ram,
    hdd,
    password,
    // dateAdded,
    // status,
    // firstName,
    devId,
    devicePictures,
    comment,
    deviceVersion
    // lastName
  });




  closeModal = () => this.setState({
    openDeviceInfoPopup: false,
    deleteDevice: '',
    editDevice: '',
    deviceId: '',
    deviceModel: '',
    devId: '',
    deviceType: '',
    operatingSystem: '',
    imei: '',
    acessories: '',
    ram: '',
    hdd: '',
    assignedDate: "",
    password: '',
    brand: '',
    dateAdded: '',
    status: '',
    firstName: '',
    lastName: '',
    assignedTo: '',
    // deviceManager: '',
    brandErrorMsg: '',
    ramErrorMsg: '',
    deviceTypeErrorMsg: '',
    assignedToErrorMsg: '',
    // deviceManagerErrorMsg: '',
    devIdErrorMsg: '',
    hddErrorMsg: '',
    dateErrorMsg: '',
    comment: "",
    deviceVersion: ""
  });

  deviceValue = type => {
    switch (type) {
      case 1:
        return "Laptop";
        break;

      case 2:
        return "CPU";
        break;

      case 3:
        return "Phone";
        break;

      case 4:
        return 'Mouse';
        break;

      case 5:
        return 'Connectors';
        break;

      case 6:
        return 'Ups';
        break;

      case 7:
        return 'Router';
        break;

      case 8:
        return 'Headphone';
        break;

      case 9:
        return 'wifi-Adaptor';
        break;

      case 10:
        return 'web Camera';
        break;

      case 11:
        return 'keyboard';
        break;

      case 12:
        return 'Monitor';
        break;

      case 13:
        return 'Pendrive';
        break;

      case 14:
        return 'Hard Disk';
        break;

      case 15:
        return 'RAM';
        break;

      case 16:
        return 'Mac Mini';
        break;

    }
  }
  operatingSystemType = type => {
    switch (type) {
      case 1: return 'windows';
        break;

      case 2: return 'linux';
        break;
      case 3: return 'MacOS'
        break;
      case 4: return 'Android'

    }
  }


  getDevice = () => {

    let { search, status, sortType, sortField, pageNo, count } = this.state
    let params = `search=${search}&status=${status}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}`;
    this.props.history.push(`?pageNo=${pageNo}`)

    getDeviceListByEmployee(params).then(res => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          deviceList: resData.deviceList,
          totalDevices: resData.totalDevices,
          phones: resData.phones,
          desktops: resData.desktops,
          laptops: resData.laptops,
          cpu: resData.cpu,
          mouse: resData.mouse,
          connectors: resData.connector,
          ups: resData.ups,
          routers: resData.router,
          headphone: resData.headphone,
          wifiAdaptor: resData.wifiAdapter,
          keyboard: resData.keyboard,
          pendrive: resData.pendrive,
          ram1: resData.ram,
          hardDisk: resData.hardDisk,
          monitor: resData.monitor,
          macMini: resData.macMini,
          webCamera: resData.webCamera,
          dataCount: resData.dataCount,




        }, () => this.setState({ loader: false }, () => this.closeModal()))
      }
      else {
        this.setState({ loader: false })
        this.closeModal();
      }
    })
  }

  // getDeviceEmployee = () => {

  //   let { search, status, sortType, sortField, pageNo, count } = this.state
  //   let params = `search=${search}&status=${status}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}`;

  //   getDeviceListByEmp(params).then(res => {
  //     let { status, resData } = ResponseFilter(res);
  //     if (status) {
  //       this.setState({
  //         deviceListByManager: resData.deviceList,
  //         totalDevicesManger: resData.totalDevices,
  //         phonesManger: resData.phones,
  //         desktopsManager: resData.desktops,
  //         laptopsManger: resData.laptops,
  //         dataCountManger: resData.dataCount,


  //       }, () => this.setState({ loader: false }, () => this.closeModal()))
  //     }
  //     else {
  //       this.setState({ loader: false })
  //       this.closeModal();
  //     }
  //   })
  // }



  componentDidMount() {


    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");

    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,

    }, () => {
      this.getDevice();
      // this.getDeviceEmployee();

    })
  }
  deviceLocationType = (type) => {
    switch (type) {
      case 1:
        return "Noida";
        break;

      case 2:
        return "Lucknow";
        break;
    }
  };



  closeModal = () =>
    this.setState({
      openAddDevicePopup: false,
      openDeleteDevicePopup: false,
      openEditDevicePopup: false,
      openDeviceInfoPopup: false,
      deleteDevice: "",
      editDevice: "",
      deviceId: "",
      deviceModel: "",
      devId: "",
      deviceType: "",
      operatingSystem: "",
      imei: "",
      acessories: "",
      ram: "",
      hdd: "",
      password: "",
      brand: "",
      dateAdded: "",
      status: "",
      firstName: "",
      lastName: "",
      assignedTo: "",
      // deviceManager: "",
      brandErrorMsg: "",
      ramErrorMsg: "",
      deviceTypeErrorMsg: "",
      assignedToErrorMsg: "",
      // deviceManagerErrorMsg: "",
      operatingSystemErrorMsg: "",
      devIdErrorMsg: "",
      hddErrorMsg: "",
      dateErrorMsg: "",
      assignedDate: "",
      comment: "",
      deviceVersion: ""
    });



  handleFullImage = (e) => {
    this.setState({
      preview: true,
      fullImage: e
    })
  }
  closeFullimage = () => {
    this.setState({
      preview: false
    })
  }

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () => this.getDevice());
  };
  render() {
    let { openAddDevicePopup,
      openDeleteDevicePopup,
      openEditDevicePopup,
      openDeviceInfoPopup,
      deviceList,
      employeeList,
      brand,
      ram,
      hdd,
      assignedTo,
      // deviceManager,
      brandErrorMsg,
      ramErrorMsg,
      deviceTypeErrorMsg,
      assignedToErrorMsg,
      // deviceManagerErrorMsg,
      devIdErrorMsg,
      hddErrorMsg,
      dateErrorMsg,
      operatingSystemErrorMsg,
      imei,
      acessories,
      editDivceObj,
      deviceModel,
      deviceType,
      operatingSystem,
      password,
      firstName,
      lastName,
      dateAdded,
      devId,
      assignedDate,
      phones,
      miniLoader,
      deviceListByManager, totalDevicesManger,
      desktops, totalDevices, laptops, search, loader, pageNo, count, dataCount,
      monitor, macMini, mouse, cpu, connectors, routers, ram1, hardDisk, headphone, wifiAdaptor, webCamera, pendrive, keyboard, ups,
      devicePictures,
      comment,
      assignedFrom,
      assignedTill,
      preview,
      fullImage,
      deviceVersion,

    } = this.state;



    return (

      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        <h3>DEVICE MANAGEMENT</h3>
        {/* <div className="text-right..."><span className="btn btn-primary" onClick={this.handleOpenAddDevicePopup} > Request Device</span></div> */}
        <form className="row d-flex justify-content-end mb-4">

        </form>
        <div className="customer-content-ul-box device">
          <Slider {...settings}>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold ">
                <h3 className="total-Device">All Devices Available</h3>
                {/* <h3 className="total-Device"> Available</h3> */}
                <span className="card_num">{totalDevices}</span>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="cm_card cm_pr  dashboard_card cm_bold card1">
                <p className="skyblue">Phones</p>
                <span className="card_num">{phones}</span>
                <Link className="cm_pos_ab" to="/employee-device/phone"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr  dashboard_card cm_bold card2">
                <p className="blue">Laptops</p>
                <span className="card_num">{laptops}</span>
                <Link className="cm_pos_ab" to="/employee-device/laptop"></Link>
              </div>
            </div>

            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card3">
                <p className="red">CPU</p>
                <span className="card_num">{cpu}</span>
                <Link className="cm_pos_ab" to="/employee-device/cpu"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card4">
                <p className="orange">Mouse</p>
                <span className="card_num">{mouse}</span>
                <Link className="cm_pos_ab" to="/employee-device/mouse"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card5">
                <p className="yellow">Connectors</p>
                <span className="card_num">{connectors}</span>
                <Link className="cm_pos_ab" to="/employee-device/connectors"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card6">
                <p className="black">UPS</p>
                <span className="card_num">{ups}</span>
                <Link className="cm_pos_ab" to="/employee-device/ups"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card7">
                <p className="grey">Routers</p>
                <span className="card_num">{routers}</span>
                <Link className="cm_pos_ab" to="/employee-device/router"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card8">
                <p className="pink">Headphone</p>
                <span className="card_num">{headphone}</span>
                <Link className="cm_pos_ab" to="/employee-device/headphone"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card9">
                <p className="green">Wifi-Adaptor</p>
                <span className="card_num">{wifiAdaptor}</span>
                <Link className="cm_pos_ab" to="/employee-device/wifiAdaptor"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card10">
                <p className="lime">Web Camera</p>
                <span className="card_num">{webCamera}</span>
                <Link className="cm_pos_ab" to="/employee-device/webCamera"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card11">
                <p className="brown">Keyboard</p>
                <span className="card_num">{keyboard}</span>
                <Link className="cm_pos_ab" to="/employee-device/keyboard"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card12">
                <p className="redOrange">Monitor</p>
                <span className="card_num">{monitor}</span>
                <Link className="cm_pos_ab" to="/employee-device/monitor"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card13">
                <p className="red">Pen Drive</p>
                <span className="card_num">{pendrive}</span>
                <Link className="cm_pos_ab" to="/employee-device/pendrive"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card14">
                <p className="black">Hard Disk</p>
                <span className="card_num">{hardDisk}</span>
                <Link className="cm_pos_ab" to="/employee-device/hardDisk"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card15">
                <p className="red">RAM</p>
                <span className="card_num">{ram1}</span>
                <Link className="cm_pos_ab" to="/employee-device/ram1"></Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="cm_card cm_pr dashboard_card cm_bold card12">
                <p className="redOrange">Mac Mini</p>
                <span className="card_num">{macMini}</span>
                <Link className="cm_pos_ab" to="/employee-device/macMini"></Link>
              </div>
            </div>
          </Slider>
          {/* </div> */}
        </div>
        {/* {assigned device list module start} */}
        <div>
          <h3 className="mt-3 cm_bold">Device Assigned List</h3>
          {/* {deviceListByManager.length > 0 ? */}
          <div className="table-responsive">

            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Device Type</th>
                  <th scope="col">Device Model</th>
                  <th scope="col">Device ID</th>
                  <th scope="col">Device Location</th>

                  <th scope="col">Assigned Date
                    {/* <i
                      className={this.state.sortType === 1 && this.state.sortField === "dateAdded" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                      onClick={() => this.handleSort('dateAdded')}></i> */}
                  </th>
                  <th scope='col'> Assigned Till</th>
                  {/* <th scope="col">Device Manager</th> */}

                  {/* <th>Name<i
                      className={this.state.sortType === 2 && this.state.sortField === "firstName" ? "fa fa-sort-alpha-desc ml-2" : "fa fa-sort-alpha-asc ml-2"}
                      onClick={() => this.handleSort('firstName')}></i></th>
                    <th scope="col">Assigned</th> */}

                  {/* <th scope="col"><i className="fa fa-sort-numeric-asc ml-2" onClick={() => this.handleSort('dateAdded')}></i></th> */}

                </tr>
              </thead>
              <tbody>
                {
                  !_.isEmpty(deviceList) ?
                    deviceList.map((device, i) => (
                      <tr key={device.deviceId}>
                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                        {/* <td><span className="cm_col">{device.firstName} {device.lastName}</span></td> */}
                        <td>{this.deviceValue(device.deviceType)}</td>
                        <td><span className="cm_col">{device.deviceModel ? device.deviceModel : '_'}</span></td>
                        <td className="blue" onClick={() => this.handleOpenDeviceInfoPopup(device)}><span className="cm_col">{device.devId}</span></td>
                        <td>{this.deviceLocationType(device.officeLocation)}</td>

                        <td>{moment.unix(device.assignedFrom / 1000).format("DD/MM/YYYY")}</td>
                        {device.assignedTill == null || device.assignedTill == 0 ? <td className="text-center"><b>_</b></td> : <td>{moment.unix(device.assignedTill / 1000).format("DD/MM/YYYY")}</td>}

                        {/* <td>{device.deviceManager}</td> */}


                      </tr>

                    )) : ''
                }


              </tbody>
            </table>

            {
              loader ? null :
                deviceList.length > 0 ? '' : <h3 className="text-center">No data available</h3>

            }
          </div>
          {/* : ' '} */}
        </div>
        {/* {assigned device list module end} */}
        {
          dataCount > 50 ? <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={dataCount}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
            : ''
        }

        {/* {device detail module start} */}
        <Modal
          className="cm_deviceInfo"
          size="md"
          show={openDeviceInfoPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <form className="assign_device">

              <h3 className="mb-5">DEVICE DETAIL</h3>
              <div className="cm_overflow_device">
                <div className="row cm_row">

                  <div className="col-6 cm_bold col1">Device ID</div>
                  <div className="col-6 cm_bold col2">{devId}</div>
                  <div className="col-6 cm_bold col1">Device Type</div>
                  <div className="col-6 cm_bold col2">{this.deviceValue(deviceType)}</div>
                  <div className="col-6 cm_bold col1">Device Version</div>
                  <div className="col-6 cm_bold col2">{deviceModel ? deviceModel : '-'}</div>
                  <div className="col-6 cm_bold col1">Device Model</div>
                  <div className="col-6 cm_bold col2">{deviceVersion ? deviceVersion : '-'}</div>
                  <div className="col-6 cm_bold col1">Brand</div>
                  <div className="col-6 cm_bold col2">{brand}</div>
                  <div className="col-6 cm_bold col1">Service Tag(IMEI)</div>
                  <div className="col-6 cm_bold col2">{imei ? imei : '-'}</div>
                  {/* <div className="col-6 cm_bold col1">Acessories Name</div> */}
                  {/* <div className="col-6 cm_bold col2">{acessories.length === 0 ? '-' : ` ${acessories}`}</div> */}
                  <div className="col-6 cm_bold col1">RAM</div>
                  <div className="col-6 cm_bold col2">{ram}</div>
                  <div className="col-6 cm_bold col1">HDD/SDD</div>
                  <div className="col-6 cm_bold col2">{hdd}</div>
                  <div className="col-6 cm_bold col1">Operating System</div>
                  <div className="col-6 cm_bold col2">{operatingSystem ? this.operatingSystemType(operatingSystem) : '-'}</div>
                  <div className="col-6 cm_bold col1">Password</div>
                  <div className="col-6 cm_bold col2">{password ? password : '-'}</div>
                  {/* <div className="col-6 cm_bold col1">Assign to Employee</div> */}
                  {/* <div className="col-6 cm_bold col2">{firstName ? firstName : '-'} {lastName ? lastName : ''}</div> */}
                  <div className="col-6 cm_bold col1">Assigned Date</div>
                  <div className="col-6 cm_bold col2">{assignedFrom ? moment.unix(assignedFrom / 1000).format("DD/MM/YYYY") : ""}</div>
                  <div className="col-6 cm_bold col1">Assigned Till</div>
                  <div className="col-6 cm_bold col2">{assignedTill == null || assignedTill == 0 ? "" : moment.unix(assignedTill / 1000).format("DD/MM/YYYY")}</div>
                  <div className="col-6 cm_bold col1">Comment</div>
                  <div className="col-6 cm_bold col2">{comment ? comment : "-"}</div>

                  <div className="col-6 cm_bold col1">Device Pictures</div>
                  <div className="container">
                    <div className="customer-content-ul image-container" >
                      {/* {devicePictures ? devicePictures.map((devicePictures) =>
                        // <a href={devicePictures} target="_blank">
                        <img className="image" src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>
                        // </a>

                      ) : ""} */}


                      {devicePictures ? devicePictures.map((devicePictures) => (
                        devicePictures.includes(".pdf") == false ?
                          <div
                            className="date-border"
                            style={{
                              borderRadius: "8px",
                              borderWidth: "1px",
                              padding: "10px",
                              overflow: "auto",
                              minHeight: "100px",
                              maxHeight: "500px",
                              marginBottom: "10px"
                            }}
                            onClick={() => this.handleFullImage(devicePictures)}
                          >

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#edfbff",
                                borderRadius: "8px",
                                padding: "10px",
                                height: "3.5rem",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={devicePictures}
                                className="image-size mr-2"
                              />
                              <label style={{ width: "80%" }}>
                                {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                              </label>
                            </div>
                          </div>
                          :
                          <div onClick={() => this.handleFullImage(devicePictures)} >
                            <>
                              <div
                                className="date-border"
                                style={{
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  padding: "10px",
                                  overflow: "auto",
                                  minHeight: "100px",
                                  maxHeight: "500px",
                                  marginBottom: "10px"

                                }}
                              >

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#edfbff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "3.5rem",
                                    margin: "5px",
                                  }}
                                >
                                  <div className="image-size"><PdfIcon /></div>
                                  <label style={{ width: "80%" }}>
                                    {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                                  </label>
                                </div>
                              </div>
                            </>
                          </div>
                      )

                      ) : ""}

                    </div>
                  </div>

                </div>
              </div>



            </form>
          </Modal.Body>
        </Modal>

        {/* {device detail module end} */}

        {/* {add device module start} */}
        <AntModal
          centered
          className="sm_modal1"
          footer={false}
          visible={openAddDevicePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="assign_device" onSubmit={this.handleSubmit}>
              <h3 className="mb-4">ADD DEVICE</h3>
              <div className="row">
                <div
                  className={`form-group col-4 ${deviceTypeErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device type</label>
                  <Select
                    value={deviceType ? deviceType : "Select"}
                    onChange={this.handleDeviceChange}
                  >
                    <Option value="1">Laptop</Option>
                    <Option value="2">Desktop</Option>
                    <Option value="3">Phone</Option>
                  </Select>
                  {deviceTypeErrorMsg ? (
                    <span className="error_msg">{deviceTypeErrorMsg}</span>
                  ) : null}
                </div>
                <div className="col-lg-4 col-md-6">
                  <label className="cm_bold">Acessories Name</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Acessories name"
                    name="acessories"
                    value={acessories}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6 ${ramErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">RAM</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="RAM"
                    name="ram"
                    value={ram}
                    onChange={this.handleChange}
                  />
                  {ramErrorMsg ? (
                    <span className="error_msg">{ramErrorMsg}</span>
                  ) : null}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${hddErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">HDD/SDD</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="HDD/SDD"
                    name="hdd"
                    value={hdd}
                    onChange={this.handleChange}
                  />
                  {hddErrorMsg ? (
                    <span className="error_msg">{hddErrorMsg}</span>
                  ) : null}
                </div>

                <div className={`form-group col-4 ${deviceTypeErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Operating System</label>
                  <Select
                    value={operatingSystem ? operatingSystem : "Select"}
                    onChange={this.handleOperatingSystemChange}
                  >
                    <Option value="1">windows</Option>
                    <Option value="2">linux</Option>
                    <Option value="3">MacOS</Option>
                    <Option value="4">Android</Option>
                  </Select>
                  {operatingSystemErrorMsg ? (
                    <span className="error_msg">{operatingSystemErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="submit_button_margin mt-4">
                <button type="submit" className="btn btn-primary cm_btn3">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-primary cm_btn2"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        {/* {add device module end} */}
        {/* full screen Image */}
        <DeviceFullScreenImage
          preview={preview}
          fullImage={fullImage}
          closeModal={this.closeFullimage}
        />
      </div >
    );
  }
}
export default EmployeeDeviceList;
import {
  SET_ADMIN_REIMBURSEMENT_YEAR_SUCCESS,
} from "./actionType";

const setAdminReimbursementFilterYear = (year) => {
  return (dispatch) => {
    dispatch(setAdminReimbursementYearSuccess(year));
  };
};
const setAdminReimbursementYearSuccess = (year) => {
  return {
    type: SET_ADMIN_REIMBURSEMENT_YEAR_SUCCESS,
    payload: year,
  };
};
export {
  setAdminReimbursementFilterYear,
};

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import Pagination from "react-js-pagination";
import ResponseFilter from '../../ResponseFilter';
import { Loader } from '../svg';
import { Select } from 'antd';
import { deleteDepartmentMember, getDepartmentView, getEmployeeList } from '../../config/services/employeeServices';
import { Modal as AntModal } from "antd";
import Modal from "react-bootstrap/Modal";

const { Option } = Select;

class ViewDepartment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employeesProfile: [],
      departmentId: '',
      loader: true,
      sortType: 2,
      sortField: "created",
      pageNo: 1,
      count: 10,
      totalEmployees: '',
      deptDetails: [],
      departmentName: '',
      editEmployee: [],
      employeeList: [],
      employeeIdList: [],
      resMsg: "",
      resFailMsg: "",
      resAddMsg: "",
      resAddFailMsg: "",
      openAddMemberPopup: false,
      assignedTo: [],
      selectObj: [],
      search: "",
      employee: [],
      employeeErrorMsg: "",
      deleteEmployeeErrorMsg: "",
      reId: [],
      multiValue: [],
      miniLoader: false,
      failResponseMessage: "",
    }
    this.debounceSearch = null
  }
  // handleInputChange = async (event) => {
  //   let { editEmployee } = this.state
  //   const target = event.target;
  //   var value = target.value;
  //   console.log("value", value);
  //   if (target.checked) {
  //     this.setState.editEmployee = editEmployee.push(await value);
  //   }
  //   else {
  //     const index = this.state.editEmployee.indexOf(await value)
  //     this.state.editEmployee.splice(index, 1);
  //   }
  // }

  handleInputChange = (val) => {
    let { multiValue, reId } = this.state;
    this.setState({ deleteEmployeeErrorMsg: "" })
    multiValue = [];
    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1);
          if (reId.length === 0) {
            this.setState({ approve: false, reject: false, pending: false })
          }
        }
      }
    } else {
      this.state.reId.push(val);
    }
    this.setState({ multiValue: reId });
  };





  handleValidetion = () => {
    let validate = true;
    const { employee } = this.state;

    if (employee === '' || employee === undefined || employee.length == 0) {
      validate = false;
      this.setState({ employeeErrorMsg: 'Please Select Employee' })
    }
    else {
      this.setState({ employeeErrorMsg: '' })
    }
    return validate;
  }

  handleDeleteValidetion = () => {
    let validate = true;
    const { multiValue } = this.state;

    if (multiValue === '' || multiValue === undefined || multiValue.length == 0) {
      validate = false;
      this.setState({ deleteEmployeeErrorMsg: 'Please Select Employees to Delete' })
    }
    else {
      this.setState({ deleteEmployeeErrorMsg: '' })
    }
    return validate;
  }
  getEmployee = () => {
    getEmployeeList().then((res) => {
      if (res.data.statusCode === 1) {

        this.setState({
          employeeList: res.data.responseData.employeesList,
        });
      } else if (res.data.statusCode === 0) {
        this.setState({ loader: false });
      }

    });
  };
  status
  componentDidMount() {

    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let status = url.searchParams.get("status");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      search: search ? search : "",
      sortType: sortType ? sortType : 2,
      sortField: sortField ? sortField : "created",
    }, () => {

      this.getView();
    })

    this.getEmployee()
  }
  handleSort = e => {
    this.setState({ loader: true });
    this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getView());
  }
  handleEmployeeSearch = (e) => {
    console.log("e : ", e);
  }

  handleSave = (e) => {
    if (this.handleValidetion()) {
      let { departmentId, editEmployee, employee } = this.state;
      let obj = {
        employeesId: employee.toString(),
        departmentId: this.props.match.params.id,
      }
      this.setState({ miniLoader: true })
      deleteDepartmentMember(obj).then(res => {

        if (res.data.statusCode === 1) {

          this.setState({ miniLoader: false, resAddMsg: true, openAddMemberPopup: false, employee: [] }, () => this.getView())

        } else {
          this.setState({ miniLoader: false, resAddFailMsg: true, failResponseMessage: res.data.error.responseMessage })
        }
      })
    }
    setTimeout(() => this.setState({ resAddMsg: false }), 4000);
    setTimeout(() => this.setState({ resAddFailMsg: false, }), 4000);
  }

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true, deleteEmployeeErrorMsg: "" }, () => this.getView());
  }

  getView = () => {
    let { sortType, sortField, pageNo, count, search } = this.state
    let params = `departmentId=${this.props.match.params.id}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}&search=${search.trim()}`;
    this.props.history.push(`${this.props.match.params.id}?sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo}&search=${search}`)
    this.setState({ loader: true })
    getDepartmentView(params).then(res => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        let {
          departmentName
        } = resData.deptDetails
        this.setState({
          employeesProfile: resData.employeesProfile,
          totalEmployees: resData.totalEmployees,
          departmentName: departmentName,

        }, () => this.setState({ loader: false }))
      }
      else {
        this.setState({ loader: false })
      }
    })
  }


  deleteAllMember = event => {
    if (this.handleDeleteValidetion()) {
      let { departmentId, editEmployee, multiValue } = this.state;
      this.setState({ loader: true });
      let obj = {
        employeesId: multiValue.toString(),
        departmentId: this.props.match.params.id,
        isDelete: 1,

      }
      deleteDepartmentMember(obj).then(res => {

        if (res.data.statusCode === 1) {

          this.setState({ loader: true, checked: "", editEmployee: [], resMsg: true, reId: [], multiValue: [] })
          // window.location.href = `/admin-department/${this.props.match.params.id}`;
          this.getView();
        } else {
          this.setState({ loader: false, resFailMsg: true, failResponseMessage: res.data.error.responseMessage })
        }
      })
    }

    setTimeout(() => this.setState({ resMsg: false }), 4000);
    setTimeout(() => this.setState({ resFailMsg: false }), 4000);
  }
  deleteMember = ({ employeeId }) => {
    let obj = {
      employeesId: employeeId,
      departmentId: this.props.match.params.id,
      isDelete: 1
    }
    this.setState({ loader: true, deleteEmployeeErrorMsg: "" })
    deleteDepartmentMember(obj).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState({}, () => this.getView());
        this.setState({ loader: true, resMsg: true })
      }
      else {
        this.setState({ loader: true, resFailMsg: true, failResponseMessage: res.data.error.responseMessage })
      }

    })
    setTimeout(() => this.setState({ resMsg: false }), 4000)
    setTimeout(() => this.setState({ resFailMsg: false }), 4000)
  }
  handleOpenAddMemberPopup = () => this.setState({ openAddMemberPopup: !this.state.openAddMemberPopup });

  closeModal = () => this.setState({
    openAddMemberPopup: false,
    employee: [],
    employeeIdList: this.state.employeeIdList,
    employeeErrorMsg: "",
  })
  handleSelectChange = () => {
    this.setState({ search: "" }, () => setTimeout(() => this.getView(), 500));
  };
  handleSearch = (e) => {
    this.setState({ search: e.target.value, pageNo: 1, deleteEmployeeErrorMsg: "" });

    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.getView()
    }, 2000);
  };
  handleChangeEmp = e => this.setState({ employee: e, employeeErrorMsg: "" })
  render() {
    let { employeesProfile, loader, totalEmployees, pageNo, count, departmentName, editEmployee, departmentId, resMsg, resFailMsg, openAddMemberPopup, employeeList, assignedTo, resAddMsg, resAddFailMsg, search, employee, employeeErrorMsg, deleteEmployeeErrorMsg, miniLoader, failResponseMessage,
    } = this.state;
    return (


      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        {
          resMsg ?
            <div className="alert alert-success cm_top_fix"> Member Deleted Successfully </div> : ''
        }
        {
          resFailMsg ?
            <div className="alert alert-danger cm_top_fix">{failResponseMessage} </div> : ''
        }
        {
          resAddMsg ?
            <div className="alert alert-success cm_top_fix"> Member Added Successfully </div> : ''
        }
        {
          resAddFailMsg ?
            <div className="alert alert-danger cm_top_fix"> {failResponseMessage} </div> : ''
        }
        <div className="cm_breadcrumb">
          <ul>
            <li><Link to='/admin-department'>DEPARTMENT  MANAGEMENT</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li style={{ textTransform: 'uppercase' }}>{departmentName}</li>
          </ul>
        </div>
        <div className="row d-flex justify-content-end mb-4 mt-3">
          <div className="col-md-6 search_device_bar1">
            <span className="btn btn-primary add_empBtn add1" onClick={this.handleOpenAddMemberPopup}>Add New Employee</span>
            <input className="form-control mr-lg-2 cm_box"
              type="search"
              placeholder="Search by name/E.Id"
              aria-label="Search"
              maxLength={25}
              value={search}
              onChange={this.handleSearch}
            // onKeyDown={(e) => { if (e.key === 'Enter') this.getView() }}
            />
            {search ? <span className="cm_clear3" onClick={this.handleSelectChange}><i className="fa fa-times-circle" aria-hidden="true"></i></span> : <span className="cm_clear3"><i className="fa fa-search" aria-hidden="true" ></i></span>}

          </div>
        </div>

        <div>
          <div className="table-responsive mt-5">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Employee ID<i className="fa fa-sort-numeric-asc ml-2" onClick={() => this.handleSort('empId')}></i></th>
                  <th>Name<i className="fa fa-sort-alpha-asc ml-2" onClick={() => this.handleSort('firstName')}></i></th>
                  <th>Joined<i className="fa fa-sort-numeric-asc ml-2" onClick={() => this.handleSort('dateOfJoining')}></i></th>
                  <th scope="col">Designation</th>
                  <th scope="col" style={{ width: 150 }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  !_.isEmpty(employeesProfile) ?
                    employeesProfile.map((profile, i) => (

                      <tr key={profile.departmentId}>
                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                        <td><span className="cm_col">{profile.empId}</span></td>
                        <td><span className="cm_col"><Link to={`/admin-department/employee-detail/${profile.employeeId}`}>{profile.firstName} {profile.lastName}</Link></span></td>

                        <td>{moment.unix(profile.dateOfJoining / 1000).format("DD/MM/YYYY")}</td>

                        <td style={{ width: 400 }}><span className="cm_col_desig">
                          {
                            profile.designationName.map((department, i) => (


                              <span className="cm_col_dept">
                                {department.designationName ? `${department.designationName}` : ''}

                              </span>



                            ))}

                        </span></td>
                        <td className='leave_button_margin'>
                          <span onClick={() => this.deleteMember(profile)}>
                            <i
                              className="fa fa-trash-o red  fa-2x"
                              title="Delete Member"
                            /></span>
                          <input className="department-checkbox "
                            name='editEmployee'
                            type="checkbox"
                            checked={
                              this.state.reId.includes(
                                profile.employeeId
                              )
                                ? true
                                : false
                            }
                            value={profile.employeeId}
                            onChange={() => this.handleInputChange(profile.employeeId)}
                          />
                        </td>
                      </tr>

                    )) : ''

                }

              </tbody>
            </table>

            {
              loader ? null :
                employeesProfile.length > 0 ? '' : <h3 className="text-center">No data available</h3>

            }
          </div>
        </div>
        {
          totalEmployees > 10 ? <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={totalEmployees}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
            : ''}
        {deleteEmployeeErrorMsg ? <span className="error_msg">{deleteEmployeeErrorMsg}</span> : null}
        <div>
          {employeesProfile && employeesProfile.length > 0 ?
            <button type="submit" class="btn btn-danger mt-3" onClick={() => this.deleteAllMember()}>Delete Selected</button>
            : ""}
        </div>
        <AntModal
          centered
          className="attend_modal"
          footer={false}
          visible={openAddMemberPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <div className="assign_device">
              <h3 className="mb-4">ADD NEW EMPLOYEE</h3>
              <div className={`form-group selectHeight mb-5 ${employeeErrorMsg ? 'input_error' : ''}`}>
                <Select
                  showSearch
                  mode="multiple"
                  value={this.state.employee}
                  style={{ width: "100%", height: "auto" }}
                  className="select_emp_leave mr-4 mb-2"
                  placeholder="Select Employee"
                  name="employeeId"
                  onSearch={this.handleEmployeeSearch}
                  optionFilterProp="key"
                  showArrow
                  filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                  onChange={this.handleChangeEmp}>
                  {
                    !_.isEmpty(employeeList) ?
                      employeeList.sort(function (a, b) {
                        if (a.firstName < b.firstName) { return -1; }
                        if (a.firstName > b.firstName) { return 1; }
                        return 0;
                      }).map((emp) => emp.status == 1 && (
                        < Option key={`${emp.firstName} ${emp.lastName}`} value={emp.employeeId}>
                          ({emp.empId}){emp.firstName} {emp.lastName}</Option>)) : ""
                  }
                </Select>
                {employeeErrorMsg ? <span className="error_msg">{employeeErrorMsg}</span> : null}

              </div>
              <div className='submit_button_margin mt-4 mt-4'>
                {miniLoader ? <div className="mini_loader_dept"></div> : ""}

                <button
                  type="submit"
                  onClick={this.handleSave}
                  disabled={miniLoader}
                  className="btn btn-primary cm_btn3"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>

            </div>
          </Modal.Body>
        </AntModal>
      </div >

    );
  }
}
export default ViewDepartment;

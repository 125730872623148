import {
  GET_EMPLOYEE_CTC_REQUEST,
  GET_EMPLOYEE_CTC_SUCCESS,
  GET_EMPLOYEE_CTC_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { getEmployeeCtc } from "../../config/services/employeeServices";
import { decryptResponseData } from "../../components/salaryGeneration/encryptionAndDecryption";

const getEmployeeCtcAction = (props) => {
  let { useDispatch, params } = props;
  return (dispatch) => {
    dispatch(getEmployeeCtcActionRequest());
    getEmployeeCtc(params)
      .then(async(res) => {
        let { status } = ResponseFilter(res);
            if (status) {
               useDispatch({
                type: "typeCheckSet",
                payload: {
                  miniLoader: false,
                  employeesCtcList:  decryptResponseData(
                    res.data.responseData.result
                  ),
                  dataCount: res?.data?.responseData?.dataCount,
                  list: decryptResponseData(res?.data?.responseData?.listToShow),
                },
              });
            } else {
              useDispatch({
                type: "typeCheckSet",
                payload: {
                  miniLoader: false,
                  resFailMsg: true,
                  responseMessage: res?.data?.error?.responseMessage,
                },
              });

              setTimeout(() => {
                useDispatch({
                  type: "typeCheckSet",
                  payload: {
                    resFailMsg: false,
                  },
                });
              }, 4000);
            }

        })
      .catch((error) => {
        dispatch(getEmployeeCtcActionFailure(error));
      });
  };
};

const getEmployeeCtcActionRequest = () => {
  return {
    type: GET_EMPLOYEE_CTC_REQUEST,
  };
};

const getEmployeeCtcActionSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_CTC_SUCCESS,
    payload: data,
  };
};

const getEmployeeCtcActionFailure = (error) => {
  return {
    type: GET_EMPLOYEE_CTC_FAILURE,
    payload: error,
  };
};
export {
  getEmployeeCtcAction,
  getEmployeeCtcActionRequest,
  getEmployeeCtcActionSuccess,
  getEmployeeCtcActionFailure,
};

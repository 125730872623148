import { STATUS_VALUE_ZERO} from "../../components/attendance/Constants";
import { addAward } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    ADD_AWARD_REQUEST,
    ADD_AWARD_SUCCESS,
    ADD_AWARD_FAILURE,
} from "./actionType";


const addAwardAction = (props) => {
    let { useDispatch, getAwardList, params, closeModal } = props
    return (dispatch) => {
        dispatch(addAwardRequest());
        addAward(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardMsg: true,
                        successMessage: "Award Added",
                        miniLoader: false
                    }
                })
                getAwardList()
                closeModal()
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardMsg: false
                    }
                }), 3000);
            } else if (res.data.statusCode === STATUS_VALUE_ZERO) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        responseMessage: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resAwardFailMsg: true,
                        miniLoader: false
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardFailMsg: false
                    }
                }), 3000);
                getAwardList()
            }
        })
            .catch((error) => {
                dispatch(addAwardFailure(error));
            });
    };
};

const addAwardRequest = () => {
    return {
        type: ADD_AWARD_REQUEST,
    };
};
const addAwardSuccess = (data) => {
    return {
        type: ADD_AWARD_SUCCESS,
        payload: data,
    };
};
const addAwardFailure = (error) => {
    return {
        type: ADD_AWARD_FAILURE,
        payload: error,
    };
};
export {
    addAwardAction,
    addAwardRequest,
    addAwardSuccess,
    addAwardFailure,
};
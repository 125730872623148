import React from "react";
import { Modal as AntModal, DatePicker, Upload } from "antd";
import { Modal } from "react-bootstrap";
import moment from "moment";
import {
  ErrorMessage,
  disabledDate,
  handleChange,
  handleDate,
  type,
} from "../helper/Constant";
import { handleSubmit } from "./CourierConstants";

const AddCourierModal = (props) => {
  const {
    show,
    handleClose,
    dateSent,
    tentative_receiving_date,
    dispatch,
    cost,
    tracking_id,
    courier_company,
    item_description,
    name,
    nameErrMsg,
    dateSentErrMsg,
    tentative_receiving_dateErrMsg,
    costErrMsg,
    tracking_idErrMsg,
    courier_companyErrMsg,
    item_descriptionErrMsg,
    getCourierList,
    dispatchToAction,
    courierFile,
    courierFileErrMsg,
  } = props;
  const uploadProps = {
    onChange: (info) => {
      if (info.fileList.length) {
        dispatch({
          type: type,
          payload: {
            courierFile: info.fileList,
            courierFileErrMsg: "",
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            courierFile: [],
          },
        });
      }
    },
    beforeUpload: () => false,
    fileList: courierFile || [],
  };

  return (
    <AntModal visible={show} onCancel={handleClose} centered footer={false}>
      <Modal.Header>
        <p className="mb-4 cm_bold cnfrm_msg">ADD COURIER DETAIL</p>
      </Modal.Header>
      <Modal.Body>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Receiver's Name</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => handleChange(e, dispatch, "name")}
            />
            <ErrorMessage errMsg={nameErrMsg} />
          </div>
          <div class="form-group col-6">
            <label className="sub">Date Sent</label>
            <DatePicker
              allowClear={false}
              className=" date-border"
              onChange={(date) => handleDate(date, dispatch, "dateSent")}
              value={dateSent ? moment(dateSent) : ""}
            />
            <ErrorMessage errMsg={dateSentErrMsg} />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Cost</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="Cost"
              name="cost"
              value={cost}
              onChange={(e) => handleChange(e, dispatch, "cost")}
            />
            <ErrorMessage errMsg={costErrMsg} />
          </div>
          <div class="form-group col-6">
            <label className="sub">Receiving Date</label>
            <DatePicker
              disabledDate={(current) => disabledDate(current, dateSent)}
              disabled={!dateSent}
              allowClear={false}
              className=" date-border"
              onChange={(date) =>
                handleDate(date, dispatch, "tentative_receiving_date")
              }
              value={
                tentative_receiving_date ? moment(tentative_receiving_date) : ""
              }
            />
            <ErrorMessage errMsg={tentative_receiving_dateErrMsg} />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Tracking ID</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              name="tracking_id"
              placeholder="Tracking id"
              value={tracking_id}
              onChange={(e) => handleChange(e, dispatch, "tracking_id")}
            />
            <ErrorMessage errMsg={tracking_idErrMsg} />
          </div>
          <div class="form-group col-6 ">
            <label className="sub">Courier Company</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              name="courier_company"
              placeholder="Courier company"
              value={courier_company}
              onChange={(e) => handleChange(e, dispatch, "courier_company")}
            />
            <ErrorMessage errMsg={courier_companyErrMsg} />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-md-12 col-sm-12">
            <label className="sub">Item Description</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              name="item_description"
              placeholder="Item description"
              value={item_description}
              onChange={(e) => handleChange(e, dispatch, "item_description")}
            />
            <ErrorMessage errMsg={item_descriptionErrMsg} />
          </div>
          <div class="form-group col-12 flex-row">
            <div>
              <label className="sub">Upload File</label>
              <Upload
                multiple
                listType="picture"
                {...uploadProps}
                maxCount={1}
                showUploadList={courierFile ? true : false}
              >
                <button className="btn btn-outline-secondary ml-4">
                  Upload
                </button>
              </Upload>
            </div>
            <div>
              <ErrorMessage errMsg={courierFileErrMsg} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div className="submit_button_margin">
          <button
            className="btn btn-primary cm_btn3"
            onClick={(e) => {
              handleSubmit({
                e: e,
                dispatch: dispatch,
                actionType: "add",
                data: props,
                getCourierList: getCourierList,
                dispatchToAction: dispatchToAction,
              });
            }}
          >
            Add
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-primary cm_btn2"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </AntModal>
  );
};

export default AddCourierModal;

import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import DotLoader from "../../hoc/DotLoader";
import { downloadIcon } from "../../asset";
import { getAssignInterviews } from "../../redux/actions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SearchField } from "../../hoc";
import styled from "styled-components";
import "./InterviewStyles.css";
import { CONSTANTS } from "../../config";
import { Modal as AntModal } from "antd";
import Modal from "react-bootstrap/Modal";
import qs from "qs"
import { useDebounce } from "../attendance/Constants";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const InterviewerInterviewStatus = ({ assignInterviewList, getAssignInterviews, totalCandidate, pending, accepted, rejected, }) => {

    const [search, setsearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [tabStatus, setTabStatus] = useState("")
    const [sortType, setSortType] = useState(2);
    const [sortField, setSortField] = useState("created")
    const history = useHistory();
    const count = 10;
    const location = useLocation();
    const [empId, setEmpId] = useState("");
    const [interviewerName, setInterviewerName] = useState("");
    const [ratingInfo, setRatingInfo] = useState(false)
    const [ratingDetail, setRatingDetail] = useState([])
    const debouncedSearchTerm = useDebounce(search, 2000);

    useEffect(() => {
        setEmpId(localStorage.getItem("interviewerId"))
        setInterviewerName(localStorage.getItem("interviewerName"))
        getData();
    }, [pageNo, debouncedSearchTerm, tabStatus, sortType]);

    useMemo(() => {
        const filterParams = history.location.search.substr(1);
        const filtersFromParams = qs.parse(filterParams);
        setSortType(filtersFromParams.sortType ? filtersFromParams.sortType : SORT_TYPE_CONSTANTS.Descending)
        setSortField(filtersFromParams.sortField ? filtersFromParams.sortField : "created")
        setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)
        setsearch(filtersFromParams.search ? filtersFromParams.search : "")
        setTabStatus(filtersFromParams.statusBy ? Number(filtersFromParams.statusBy) : "")
        setEmpId(filtersFromParams.empId ? Number(filtersFromParams.empId) : localStorage.getItem("interviewerId"))
        setInterviewerName(filtersFromParams.interviewerName ? Number(filtersFromParams.interviewerName) : localStorage.getItem("interviewerName"))

    }, [history.location.search]);

    const getData = async () => {
        const query = `${localStorage.getItem("interviewerId")}&search=${search}&pageNo=${pageNo - 1}&count=${count}&sortType=${sortType}&sortField=${sortField}&statusBy=${tabStatus}`
        history.push(`${history.location.pathname}?search=${search}&pageNo=${pageNo}&count=${count}&statusBy=${tabStatus}&sortType=${sortType}&sortField=${sortField}`)
        await getAssignInterviews(query)
    };

    const handleOnPageChangeClick = (pageNum) => {
        setPageNo(pageNum);
    };
    const operatingSystemType = (type) => {
        switch (type) {
            case 1:
                return "Selected";
                break;
            case 3:
                return "Rejected";
                break;
            case 2:
                return "Pending";
                break;
        }
    };
    const colorType = type => {
        switch (type) {
            case 1: return 'text-success';
                break;

            case 3: return 'red';
                break;
            case 2: return 'yellow';
                break;


        }
    }

    const handleFilter = (e) => {
        setTabStatus(e);
        setPageNo(1);
    }

    const handleSort = (e) => {
        setSortField(e);
        if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
            setSortType(SORT_TYPE_CONSTANTS.Descending);
        }
        else {
            setSortType(SORT_TYPE_CONSTANTS.Asscending)
        }
    }

    const handleOpenRating = (e) => {
        setRatingDetail(e)
        setRatingInfo(true)
    }
    const closeRatingModal = () => {
        setRatingInfo(false)
        setRatingDetail([])
    }
    return (
        <div className="body_container cm_bold marginTop">

            <div className="cm_breadcrumb">
                <ul >
                    <li><Link to='/interview'>Interviewer Interviews List</Link><div className="cm_breadcrumb_arrow"></div></li>
                    <li>{localStorage.getItem("interviewerName") ? localStorage.getItem("interviewerName") : ""}<div className="cm_breadcrumb_arrow"></div></li>
                    <li style={{ textTransform: 'uppercase' }}>Detail</li>
                </ul>
            </div>
            <div className="headerDiv">
                <SearchField
                    inputValue={search.trim()}
                    handleInputChange={setsearch}
                    handlePageChange={setPageNo}
                    handleClearSearchClick={setsearch}
                />
            </div>
            <div className="text-left cm_ticket_btn cm_leave_btn">
                <button className={`btn btn-primary ${tabStatus === "" ? "active" : ""} `}
                    onClick={() => handleFilter("")}>
                    <span className="tabtext">All {totalCandidate ? totalCandidate : 0}</span></button>
                <button className={`btn btn-primary ${tabStatus === 2 ? "active" : ""} `}
                    onClick={() => handleFilter(2)}>
                    <span className="tabtext">Pending {pending?.[0]?.totalCountByStatus ? pending?.[0]?.totalCountByStatus : 0}</span></button>
                <button className={`btn btn-primary ${tabStatus === 1 ? "active" : ""} `}
                    onClick={() => handleFilter(1)}>
                    <span className="tabtext">Selected {accepted?.[0]?.totalCountByStatus ? accepted?.[0]?.totalCountByStatus : 0}</span></button>
                <button className={`btn btn-primary ${tabStatus === 0 ? "active" : ""} `}
                    onClick={() => handleFilter(3)}>
                    <span className="tabtext">Rejected {rejected?.[0]?.totalCountByStatus ? rejected?.[0]?.totalCountByStatus : 0}</span></button>

            </div>
            {assignInterviewList ? (
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Name
                                    <i
                                        className={
                                            sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'name'
                                                ? "fa fa-sort-alpha-asc ml-2"
                                                : "fa fa-sort-alpha-desc ml-2"
                                        }
                                        onClick={() => handleSort("firstName")}
                                    ></i>

                                </th>
                                <th scope="col">Email</th>
                                <th scope="col">D.O.B
                                </th>
                                <th scope="col">Round</th>
                                <th scope="col">Total Experience</th>
                                <th scope="col">Rating</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        {assignInterviewList.map((element, index) => {
                            return (
                                <tbody>
                                    <tr>
                                        <td>{index + 1 + (pageNo - 1) * count}</td>
                                        <td>

                                            {element?.FirstName + " " + element?.LastName}
                                        </td>
                                        <td>{element.email}</td>
                                        <td>
                                            {" "}
                                            {new Date(element.dob).toLocaleDateString({
                                                hour12: true,
                                            })}
                                        </td>
                                        <td>{element.interviewRound}</td>

                                        <td>{element.totalExperience == null ? " Fresher" : `${element.totalExperience} years`}</td>
                                        <td className="blue" onClick={() => handleOpenRating(element.skillRating)}> Click Here</td>
                                        <td className={colorType(element.isClear)}>{operatingSystemType(element.isClear ? element.isClear : "_")}</td>
                                        <td>
                                            <div className="cm_device">
                                                <a href={element.resume} className="ml-4">
                                                    <ActionImage
                                                        src={downloadIcon}
                                                        alt="downloadIcon"
                                                    />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </table>
                    {assignInterviewList && assignInterviewList.length > 0 ? "" :
                        <h3 className="text-center">No data available</h3>
                    }
                </div>

            ) : (
                <div className="loaderDiv">
                    <DotLoader />
                </div>
            )}
            {totalCandidate > 10 ? (
                <Pagination
                    activePage={pageNo}
                    itemsCountPerPage={count}
                    totalItemsCount={totalCandidate}
                    pageRangeDisplayed={3}
                    onChange={handleOnPageChangeClick}
                />
            ) : null}

            {/* Rating Detail */}
            <AntModal
                centered
                className="attend_modal"
                footer={false}
                visible={ratingInfo}
                onCancel={closeRatingModal}
            >
                <Modal.Body>
                    <h3 className="mb-4">Rating Detail</h3>
                    {ratingDetail ? (
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead className="white bg_blue">
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Technology
                                        </th>
                                        <th scope="col">Rating</th>
                                    </tr>
                                </thead>
                                {ratingDetail.map((item, index) => {
                                    return (
                                        <tbody>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {item?.tech}
                                                </td>
                                                <td>{item.rating + "/10"}</td>
                                            </tr>
                                        </tbody>
                                    );
                                })}
                            </table>
                            {ratingDetail && ratingDetail.length > 0 ? "" :
                                <h3 className="text-center">No data available</h3>
                            }
                        </div>

                    ) : (
                        <div className="loaderDiv">
                            <DotLoader />
                        </div>
                    )}
                </Modal.Body>
            </AntModal>


        </div >
    );
};
const mapStateToProps = (state) => {
    return {
        assignInterviewList: state.getAssignInterviews.data.result,
        totalCandidate: state.getAssignInterviews.data?.dataCount,
        pending: state.getAssignInterviews?.data?.countByStatus !== 0 ? state?.getAssignInterviews.data?.countByStatus?.filter(data => data._id == 2) : 0,
        accepted: state.getAssignInterviews?.data?.countByStatus !== 0 ? state?.getAssignInterviews.data?.countByStatus?.filter(data => data._id == 1) : 0,
        rejected: state.getAssignInterviews?.data?.countByStatus !== 0 ? state?.getAssignInterviews.data?.countByStatus?.filter(data => data._id == 3) : 0,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAssignInterviews: (query) => {
            dispatch(getAssignInterviews(query));

        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InterviewerInterviewStatus);

const ActionImage = styled.img`
        width: 30px;
        height: 30px;
        cursor: pointer;
        `;

const Button = styled.button`
        background - color: rgb(52, 123, 222);
        cursor: pointer;
        width: 200px;
        border: none;
        padding: 0.4rem;
        border - radius: 10px;
        color: white;
        font - size: medium;
        `;

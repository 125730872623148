import React from 'react';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Loader } from '../svg';
import { addSkill, getSkill, editSkill, deleteSkill } from '../../config/services/employeeServices';
import ResponseFilter from '../../ResponseFilter';

class AdminSkill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openEditSkillPopup: false,
            openDeleteSkillPopup: false,
            openAddSkillPopup: false,
            skillNameErrorMsg: '',
            miniLoader: false,
            skillsList: [],
            skillId: '',
            search: '',
            loader: true,
            resMsg: '',
            sortType: 2,
            sortField: "created",
            resMsgAdd: false,
            resMsgEdit: false,
            resMsgDelete: false,
            employeeList: [],
            employee: '',

        }
        this.debounceSearch = null
    }
    handleChange = e => this.setState({
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        resMsg: ''
    });
    handleSearch = e => {
        this.setState({ search: e.target.value })
        clearTimeout(this.debouncedSearch);
        this.debounceSearch = setTimeout(() => {
            this.setState({ loader: true })
            this.getSkillList();
        }, 2000);
    }
    handleSelectChange = () => {
        this.setState({ search: '' }, () => this.getSkillList())
    }
    submitSearch = e => {
        e.preventDefault();
        this.setState({ loader: true })
        this.getSkillList()
    }



    getSkillList = () => {

        let { search, sortType, sortField } = this.state
        let params = `search=${search.trim()}&sortType=${sortType}&sortField=${sortField}`;
        this.props.history.push(`admin-skill?search=${search}&sortType=${sortType}&sortField=${sortField}`)
        this.setState({ loader: true })
        getSkill(params).then(res => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({

                    skillsList: res.data.responseData.skillsList
                }, () => this.setState({ loader: false }, () => this.closeModal()))
            }

        })
    }

    componentDidMount() {
        let url_string = window.location.href
        let url = new URL(url_string);
        let search = url.searchParams.get("search");
        let sortType = url.searchParams.get("sortType");
        let sortField = url.searchParams.get("sortField");
        this.setState({
            search: search ? search : "",
            sortType: sortType ? sortType : 1,
            sortField: sortField ? sortField : "",
        }, () => {
            this.getSkillList();

        })
    }

    handleOpenEditSkillPopup = ({ skillId, skillName }) => this.setState({ openEditSkillPopup: !this.state.openEditSkillPopup, skillId, skillName });
    handleOpenDeleteSkillPopup = ({ skillId }) => this.setState({ openDeleteSkillPopup: !this.state.openDeleteSkillPopup, skillId });
    handleOpenAddSkillPopup = () => this.setState({ openAddSkillPopup: !this.state.openAddSkillPopup });

    closeModal = () => this.setState({
        openEditSkillPopup: false,
        openDeleteSkillPopup: false,
        openAddSkillPopup: false,
        skillName: '',
        skillNameErrorMsg: '',
        miniLoader: false,
        skillId: '',
        numOfEmployees: '',
        resMsg: '',
    });

    handleValidetion = () => {
        let validate = true;
        const { skillName } = this.state;

        if (skillName === '' || skillName === undefined) {
            validate = false;
            this.setState({ skillNameErrorMsg: 'Skill name is required' })
        }
        else {
            this.setState({ skillNameErrorMsg: '' })
        }



        return validate;
    }

    handleSubmit = event => {
        event.preventDefault();
        let { skillName } = this.state;
        if (this.handleValidetion()) {
            this.setState({ miniLoader: true });

            let obj = {
                'skillName': skillName,
            }
            addSkill(obj).then(res => {
                if (res.data.statusCode === 1) {
                    this.setState({
                        miniLoader: false,
                        resMsgAdd: true,
                    }, () => {
                        this.getSkillList()
                        this.closeModal();
                    }

                    )
                }
                else {
                    this.setState({ miniLoader: false, resMsg: res.data.error.responseMessage, openAddSkillPopup: true })

                }
            })
        }
        setTimeout(() => this.setState({ resMsgAdd: false }), 3000);

    }
    handleEditSubmit = event => {
        event.preventDefault();
        let { skillName, skillId } = this.state;
        if (this.handleValidetion()) {
            this.setState({ miniLoader: true });

            let obj = {
                'skillId': skillId,
                'skillName': skillName
            }
            editSkill(obj).then(res => {

                if (res.data.statusCode === 1) {
                    this.setState({
                        miniLoader: false,
                        resEdit: true,
                    }, () => {
                        this.getSkillList()
                        this.closeModal();
                    }

                    )
                }
                else {
                    this.setState({ miniLoader: false, resMsg: res.data.error.responseMessage, openEditSkillPopup: true })

                }
            })
        }
        setTimeout(() => this.setState({ resEdit: false }), 3000);

    }



    handleDelete = () => {

        let obj = {
            data: {
                skillId: this.state.skillId,
                status: 2
            }
        }
        this.setState({ miniLoader: true })
        deleteSkill(obj)
            .then(res => {
                this.setState({ miniLoader: false, resMsgDelete: true }, () => this.getSkillList())
            })
        setTimeout(() => this.setState({ resMsgDelete: false }), 4000);

    }




    render() {
        let {
            openEditSkillPopup,
            openDeleteSkillPopup,
            openAddSkillPopup,
            skillName,
            skillNameErrorMsg,
            miniLoader,
            skillsList,
            search,
            loader,
            resMsg,
            resMsgAdd,
            resMsgDelete,
            resEdit,
        } = this.state;




        return (
            <div className="body_container cm_bold">
                {
                    loader && <Loader />
                }
                {
                    resMsgAdd ?
                        <div className="alert alert-success cm_top_fix">Skill added successfully</div> : ''
                }
                {
                    resMsgDelete ?
                        <div className="alert alert-danger cm_top_fix">Skill deleted successfully</div> : ''
                }
                {
                    resEdit ?
                        <div className="alert alert-warning cm_top_fix">Skill edited successfully</div> : ''
                }
                <h3 className="mb-2">SKILL MANAGEMENT</h3>


                <div className="row d-flex justify-content-end mb-4">
                    <div className="col-md-6 search_device_bar1">
                        <span className="btn btn-primary add_empBtn add1" onClick={this.handleOpenAddSkillPopup}>Add New Skill</span>
                        <input className="form-control mr-lg-2 cm_box"
                            maxLength={25}
                            type="search"
                            placeholder="Search by name"
                            aria-label="Search"
                            value={search}
                            onChange={this.handleSearch}
                            // onKeyDown={(e) => { if (e.key === 'Enter') this.getSkillList() }}
                        />
                        {search ? <span className="cm_clear3" onClick={this.handleSelectChange}><i className="fa fa-times-circle" aria-hidden="true"></i></span> : <span className="cm_clear3"><i className="fa fa-search" aria-hidden="true" ></i></span>}

                    </div>
                </div>


                <div className={` ${skillsList.length > 0 ? "department-modal" : ""}`}>

                    {
                        !_.isEmpty(skillsList) ?
                            skillsList.map(data => (
                                <div className="col-12" key={data.skillId}>
                                    <Link title="View skill" to={`/admin-skill/${data.skillId}`}>
                                        <div className="cm_card cm_card_dept">


                                            <div className="cm_style design">

                                                <p className="mb-2 cm_dept_dots">{data.skillName}</p>
                                                <span className="emp_count cm_bold">{data.numOfEmployees ? data.numOfEmployees : 0}</span>
                                            </div>



                                        </div>
                                    </Link>
                                    <div className="text-right department_btn">
                                        <button type="button" className="btn btn-primary btn-sm mr-4" onClick={() => this.handleOpenEditSkillPopup(data)}>Edit</button>
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleOpenDeleteSkillPopup(data)}>Delete</button>
                                    </div>
                                </div>

                            )) : <h3 className="text-center ">No data  available</h3>

                    }

                </div>
                <Modal
                    size="md"
                    show={openAddSkillPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="add-new-dept" onSubmit={this.handleSubmit}>
                            <h3>ADD NEW SKILL</h3>
                            <div className={`form-group ${skillNameErrorMsg || resMsg ? 'input_error' : ''}`}>
                                <label className="cm_bold">SKILL</label>
                                <input
                                    maxLength="75"
                                    className="form-control cm_box"
                                    type="text"
                                    placeholder="Enter skill"
                                    name="skillName"
                                    value={skillName}
                                    onChange={this.handleChange}
                                />
                                {skillNameErrorMsg ? <span className="error_msg">{skillNameErrorMsg}</span> : null}
                                {<span className="res_error_msg">{resMsg ? resMsg : ''}</span>}
                            </div>

                            {miniLoader ? <div className="mini_loader_dept "></div> : ''}
                            <div className="submit_button_margin mt-4 ml-5">
                                <button type="submit" className="btn btn-primary  cm_btn3" disabled={miniLoader}>Save</button>
                                <button type="button" className="btn btn-primary  cm_btn2" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="md"
                    show={openEditSkillPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="add-new-dept" onSubmit={this.handleEditSubmit}>
                            <h3>EDIT SKILL</h3>
                            <div className={`form-group ${skillNameErrorMsg || resMsg ? 'input_error' : ''}`}>
                                <label className="cm_bold">SKILL</label>
                                <input
                                    maxLength="75"
                                    className="form-control cm_box"
                                    type="text"
                                    placeholder="Enter Skill"
                                    name="skillName"
                                    value={skillName}
                                    onChange={this.handleChange}
                                />
                                {skillNameErrorMsg ? <span className="error_msg">{skillNameErrorMsg}</span> : null}
                                {<span className="res_error_msg">{resMsg ? resMsg : ''}</span>}
                            </div>

                            {miniLoader ? <div className="mini_loader_dept"></div> : ''}
                            <div className="submit_button_margin mt-4 ml-5">
                                <button className="btn btn-primary cm_btn3">Save</button>
                                <button type="button" className="btn btn-primary  cm_btn2" onClick={this.closeModal}>Cancel</button>

                            </div>

                        </form>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="md"
                    show={openDeleteSkillPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="text-center confirmation">
                        <p className="mb-4 cm_bold cnfrm_msg">Are you sure, you want to delete?</p>
                        {miniLoader ? <div className="mini_loader_dept"></div> : ""}
                        <div className='submit_button_margin mt-4'>
                            <button className="btn btn-danger cm_btn3" onClick={this.handleDelete}>Delete</button>
                            <button type="button" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>
                        </div>


                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}
export default AdminSkill;

import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ErrorMessage } from "../../helper/Constant";
import { handleSubmit } from "./InvestmentFormConstants";
import { Select } from "antd";
const { Option } = Select;

const CreateInvestmentData = (props) => {
  const {
    id,
    handleChange,
    state,
    handlePrint,
    handleTick,
    handleOpenUpload,
    setState,
    history,
    data,
    handleOpenDownload,
    hideIcon,
    handleMetro,
  } = props;
  const {
    rentAgreement,
    landlordPanCard,
    deductionAmountFile1,
    deductionAmountFile2,
    deductionAmountFile3,
    LIC,
    PPF,
    VPF,
    NSC,
    mutual_fund,
    tutionFee,
    housingLoan,
    stampDutyPolicy,
    bankFixedPolicy,
    postOffice,
    SSAS,
    others,
    pensionFund,
    pensionScheme,
    nationalPensionScheme,
    pensionFromInsurance,
    medicalInsurance,
    medicalInsuranceParents,
    interestForEducation,
    deductionOthers,
  } = data || {};
  const toEdit = new URLSearchParams(window.location.search);
  const editFile = toEdit.has("toEdit");
  return (
    <div>
      <>
        <p className="mt-3">
          Select the Tax Scheme to be availed for Financial Year {state.year1}
        </p>
        <span className="error"> {state.schemeErr ? state.schemeErr : ""}</span>
        <div className="ml-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              name="scheme"
              value={state.scheme}
              onClick={() => handleTick(1)}
              checked={state.scheme === 1}
              disabled={(id.length > 9 && !editFile) || editFile ? true : false}
            />
            <label class="form-check-label" for="flexCheckDefault">
              New Tax Scheme
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexCheckChecked"
              name="scheme"
              value={state.scheme}
              onClick={() => handleTick(2)}
              checked={state.scheme === 2}
              disabled={id.length > 9 && !editFile ? true : false}
            />
            <label class="form-check-label" for="flexCheckChecked">
              Old Tax Scheme
            </label>
          </div>
        </div>
      </>

      <div className="mt-3">
        <h6>
          <b> Note:</b> All the tax reliefs and deductions provided under the
          Income Tax Act, 1961 can be availed under the Old Tax Scheme only.
          Employees opting for the Old Tax Scheme are required to fill the below
          Investment Declaration Form.
        </h6>
      </div>

      <div className="mt-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            name="declaration"
            value={state.declaration}
            checked={state.declaration}
            disabled
          />
          <label class="form-check-label" for="flexCheckDefault">
            {id.length > 9 ? (
              <p>
                I hereby declare that the following investment will be made by
                me during the financial year {state.year1} starting from 1st of
                April {state.year1.slice(0, 4)} to 31st of March{" "}
                {state.year1.slice(5)}
              </p>
            ) : (
              <p>
                I hereby declare that the following investment will be made by
                me during the financial year {id} starting from 1st of April{" "}
                {id.slice(0, 4)} to 31st of March {id.slice(5)}
              </p>
            )}
          </label>
          <span className="error">
            {" "}
            {state.declarationErr ? state.declarationErr : ""}
          </span>
        </div>
      </div>

      {id.length > 9 && !editFile ? (
        <Table bordered hover size="sm">
          <thead>
            <tr className="text-center">
              <th>Item Name</th>
              <th>Particulars</th>
              <th>Eligibility(Rs.)</th>
              <th>Amount</th>
            </tr>

            <tr>Exemption u/s 10</tr>
          </thead>
          <tbody>
            <tr>
              <td>House Rent Allowance</td>
              <td>
                <h6>
                  I am staying in a Rented <br></br>House and I agree to{" "}
                  <br></br>submit Rent receipts or <br></br>Rent Agreement with
                  the<br></br> Landlord when required.<br></br>
                  Rent Paid is ____x<br></br>
                  ____Months
                </h6>
              </td>
              <td>
                <div className="ml-3">
                  {state.metro === 1 ? (
                    <div class="form-check">
                      <label class="form-check-label" for="flexCheckDefault">
                        Metro
                      </label>
                    </div>
                  ) : (
                    <div class="form-check">
                      <label class="form-check-label" for="flexCheckChecked">
                        Non-Metro
                      </label>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  {!hideIcon && rentAgreement && landlordPanCard && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [rentAgreement, landlordPanCard],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    name="houseRentAmount"
                    value={state.houseRentAmount || ""}
                    disabled
                    inputMode="numeric"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Deduction u/s 24</td>
            </tr>

            <tr>
              <td>1</td>
              <td>
                <h6>
                  Interest on Housing Loan u/s 24(If the<br></br> house is Self-
                  Occupied and the loan was<br></br> taken before 1st April
                  1999) Bankers <br></br>Certificate to be Submitted
                </h6>
              </td>
              <td>
                <h6>Upto Rs.30000/- (If Self-Occupied)</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  {!hideIcon && deductionAmountFile1 && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [deductionAmountFile1],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    inputMode="numeric"
                    className="input"
                    name="deductionAmount_1"
                    value={state.deductionAmount_1 || ""}
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>2</td>
              <td>
                <h6>
                  Interest on Housing Loan u/s 24(If the<br></br> house is Self-
                  Occupied and the loan was<br></br> taken before 1st April
                  1999) Bankers <br></br>Certificate to be Submitted
                </h6>
              </td>
              <td>
                <h6>Upto Rs.200000/- (If Self-Occupied)</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && deductionAmountFile2 && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [deductionAmountFile2],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    inputMode="numeric"
                    className="input"
                    name="deductionAmount_2"
                    value={state.deductionAmount_2 || ""}
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <h6>
                  Interest on Housing Loan u/s<br></br> 24(Let-out/Deemed to be
                  Let-out)<br></br>
                  (If the property is LET-OUT-Rental income<br></br> need to be
                  specified)
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.deduction_eligibilty || ""}
                  name="deduction_eligibilty"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && deductionAmountFile3 && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [deductionAmountFile3],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    inputMode="numeric"
                    className="input"
                    name="deductionAmount_3"
                    value={state.deductionAmount_3 || ""}
                    disabled
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Table bordered hover size="sm">
          <thead>
            <tr className="text-center">
              <th>Item Name</th>
              <th>Particulars</th>
              <th>Eligibility(Rs.)</th>
              <th>Amount</th>
            </tr>

            <tr>Exemption u/s 10</tr>
          </thead>
          <tbody>
            <tr>
              <td>House Rent Allowance</td>
              <td>
                <h6>
                  I am staying in a Rented <br></br>House and I agree to{" "}
                  <br></br>submit Rent receipts or <br></br>Rent Agreement with
                  the<br></br> Landlord when required.<br></br>
                  Rent Paid is ____x<br></br>
                  ____Months
                </h6>
              </td>
              <td>
                <div className="ml-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      name="metro"
                      value={state.metro}
                      onClick={() => handleMetro(1)}
                      checked={state.metro === 1}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Metro
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      name="metro"
                      value={state.metro}
                      onClick={() => handleMetro(2)}
                      checked={state.metro === 2}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Non-Metro
                    </label>
                  </div>
                </div>
                <div className="mt-2">
                  <ErrorMessage errMsg={state.metroErr} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "houseRent",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    onChange={handleChange}
                    name="houseRentAmount"
                    value={state.houseRentAmount || ""}
                  />
                </div>
                <ErrorMessage errMsg={state.houseRentAmountErr} />
              </td>
            </tr>
            <tr>
              <td>Deduction u/s 24</td>
            </tr>

            <tr>
              <td>1</td>
              <td>
                <h6>
                  Interest on Housing Loan u/s 24(If the<br></br> house is Self-
                  Occupied and the loan was<br></br> taken before 1st April
                  1999) Bankers <br></br>Certificate to be Submitted
                </h6>
              </td>
              <td>
                <h6>Upto Rs.30000/- (If Self-Occupied)</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "housingLoan",
                        deductionText: "Upto Rs.30000",
                        deductionAmountFile: "deductionAmountFile1",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    inputMode="numeric"
                    className="input"
                    name="deductionAmount_1"
                    value={state.deductionAmount_1 || ""}
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.deductionAmountFile1Err} />
              </td>
            </tr>

            <tr>
              <td>2</td>
              <td>
                <h6>
                  Interest on Housing Loan u/s 24(If the<br></br> house is Self-
                  Occupied and the loan was<br></br> taken before 1st April
                  1999) Bankers <br></br>Certificate to be Submitted
                </h6>
              </td>
              <td>
                <h6>Upto Rs.200000/- (If Self-Occupied)</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "housingLoan",
                        deductionText: "Upto Rs.200000",
                        deductionAmountFile: "deductionAmountFile2",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    inputMode="numeric"
                    className="input"
                    name="deductionAmount_2"
                    value={state.deductionAmount_2 || ""}
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.deductionAmountFile2Err} />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <h6>
                  Interest on Housing Loan u/s<br></br> 24(Let-out/Deemed to be
                  Let-out)<br></br>
                  (If the property is LET-OUT-Rental income<br></br> need to be
                  specified)
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.deduction_eligibilty || ""}
                  name="deduction_eligibilty"
                  onChange={handleChange}
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "housingLoan",
                        deductionText: "LET-OUT Rental income",
                        deductionAmountFile: "deductionAmountFile3",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    inputMode="numeric"
                    className="input"
                    name="deductionAmount_3"
                    value={state.deductionAmount_3 || ""}
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.deductionAmountFile3Err} />
              </td>
            </tr>
          </tbody>
        </Table>
      )}

      {id.length > 9 && !editFile ? (
        <Table bordered hover size="sm mt-5">
          <tbody>
            <tr className="text">
              <td>S.No</td>
              <td> Deduction u/s 80C (Maximum Amount Upto Rs.150000/-)</td>
              <td>Policy Name</td>
              <td>Amount</td>
            </tr>

            <tr>
              <td className="set-width">
                <h6>1</h6>
              </td>
              <td>
                <h6>Life Insurance Premium(LIC)</h6>
              </td>
              <td className="width">
                <input
                  type="text"
                  className="input"
                  value={state.lifeInsurancePolicy || ""}
                  name="lifeInsurancePolicy"
                  disabled
                />
              </td>
              <td className="width">
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && LIC && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [LIC],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.lifeInsuranceAmount || ""}
                    name="lifeInsuranceAmount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>2</h6>
              </td>
              <td>
                <h6>Provident Fund(PF)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.pf_Policy || ""}
                  name="pf_Policy"
                  disabled
                />
              </td>
              <td>
                <input
                  type="number"
                  className="input"
                  inputMode="numeric"
                  value={state.pf_Amount || ""}
                  name="pf_Amount"
                  disabled
                />
              </td>
            </tr>

            <tr>
              <td>
                <h6>3</h6>
              </td>
              <td>
                <h6>Public Provident Fund(PPF)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.ppf_Policy || ""}
                  name="ppf_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && PPF && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [PPF],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.ppf_Amount || ""}
                    name="ppf_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h6>4</h6>
              </td>
              <td>
                <h6>Voluntary Provident Fund(VPF)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.vpf_Policy || ""}
                  name="vpf_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && VPF && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [VPF],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.vpf_Amount || ""}
                    name="vpf_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h6>5</h6>
              </td>
              <td>
                <h6>National Savings Certificate(NSC) + Accrued Interest</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.nsc_Policy || ""}
                  name="nsc_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && NSC && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [NSC],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.nsc_Amount || ""}
                    name="nsc_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>6</h6>
              </td>
              <td>
                <h6>
                  Tax Saving-<br></br>Mutual Fund
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.elss_Policy || ""}
                  name="elss_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && mutual_fund && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [mutual_fund],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.elss_Amount || ""}
                    name="elss_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h6>7</h6>
              </td>
              <td>
                <h6>
                  Payment for Tuition Fees for Children(Max.<br></br> 2
                  Children)
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.tuition_Policy || ""}
                  name="tuition_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && tutionFee && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [tutionFee],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.tuition_Amount || ""}
                    name="tuition_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h6>8</h6>
              </td>
              <td>
                <h6>Principal Repayment Of Housing Loan</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.housing_Loan_Policy || ""}
                  name="housing_Loan_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && housingLoan && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [housingLoan],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.housing_Loan_Amount || ""}
                    name="housing_Loan_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h6>9</h6>
              </td>
              <td>
                <h6>
                  Stamp Duty, Registration charges incurred <br></br>for Buying
                  <br></br>
                  House(1st Year Only)
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.stamp_Duty_Policy || ""}
                  name="stamp_Duty_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && stampDutyPolicy && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [stampDutyPolicy],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.stamp_Duty_Amount || ""}
                    name="stamp_Duty_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>10</h6>
              </td>
              <td>
                <h6>Bank Fixed Deposit For 5 Years & Above</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.bank_Fixed_Policy || ""}
                  name="bank_Fixed_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && bankFixedPolicy && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [bankFixedPolicy],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.bank_Fixed_Amount || ""}
                    name="bank_Fixed_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h6>11</h6>
              </td>
              <td>
                <h6>Post Office Term Deposit For 5 Years & Above</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.postOffice_Policy || ""}
                  name="postOffice_Policy "
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && postOffice && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [postOffice],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.postOffice_Amount || ""}
                    name="postOffice_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h6>12</h6>
              </td>
              <td>
                <h6>Sukanya Samriddhi Account Scheme</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.accountScheme_Policy || ""}
                  name="accountScheme_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && SSAS && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [SSAS],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.accountScheme_Amount || ""}
                    name="accountScheme_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>13</h6>
              </td>
              <td>
                <h6>Others(Please Specify)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.others_Policy || ""}
                  name="others_Policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && others && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [others],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.others_Amount || ""}
                    name="others_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr className="text">
              <td></td>
              Deduction u/s 80CCC
            </tr>
            <tr>
              <td></td>
              <td>
                <h6>Contribution To Certain Pension Funds</h6>
              </td>
              <td>
                <h6>Upto Rs.150000/-</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && pensionFund && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [pensionFund],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.d80CCC_Amount || ""}
                    name="d80CCC_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr className="text">
              <td></td>
              Deduction u/s 80CCD(1)
            </tr>
            <tr>
              <td></td>
              <td>
                <h6>Contribution To Pension Scheme of Central Government</h6>
              </td>
              <td>
                <h6>Upto Rs.150000/-</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && pensionScheme && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [pensionScheme],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.d80CCD_1_Amount || ""}
                    name="d80CCD_1_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>

            <tr className="text">
              <td></td>
              Deduction u/s 80CCD(1B)
            </tr>
            <tr>
              <td></td>
              <td>
                <h6>Contribution To National Pension Scheme</h6>
              </td>
              <td>
                <h6>Upto Rs.500000/-</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && nationalPensionScheme && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [nationalPensionScheme],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.d80CCD_1B_Amount || ""}
                    name="d80CCD_1B_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr className="text">
              <td></td>
              Deduction u/s 80
            </tr>
            <tr>
              <td>80CCC</td>
              <td>
                <h6>
                  Medical Insurance Premium-<br></br>Individual, Spouse &
                  Children
                </h6>
              </td>
              <td>
                <h6>
                  (Max. Limit-Rs.25000/-<br></br> Additional Rs.25000,if
                  <br></br> any person insured is a <br></br>Senior Citizen)
                </h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && pensionFromInsurance && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [pensionFromInsurance],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.medicalInsuranceChildren_Amount || ""}
                    name="medicalInsuranceChildren_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>80 D</td>
              <td>
                <h6>
                  Medical Insurance Premium-<br></br>Individual, Spouse &
                  Children
                </h6>
              </td>
              <td>
                <h6>
                  (Max. Limit-Rs.25000/-<br></br> Additional Rs.25000,if
                  <br></br> any person insured is a <br></br>Senior Citizen)
                </h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && medicalInsurance && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [medicalInsurance],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.medicalInsuranceChildren_Amount || ""}
                    name="medicalInsuranceChildren_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>80 D</td>
              <td>
                <h6>Medical Insurance Premium-Parents </h6>
              </td>
              <td>
                <h6>
                  (Max. Limit-Rs.25000/-<br></br> Additional Rs.25000,if
                  <br></br> any person insured is a <br></br>Senior Citizen)
                </h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && medicalInsuranceParents && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [medicalInsuranceParents],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.medicalInsuranceParent_Amount || ""}
                    name="medicalInsuranceParent_Amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>80 E</td>
              <td>
                <h6>Interest for Loan taken for Higher Education</h6>
              </td>
              <td>
                <h6>No Limit (Only Interest portion)</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && interestForEducation && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [interestForEducation],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.interest_Amount || ""}
                    disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Others (Please Specify)</td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.other_specify_policy || ""}
                  name="other_specify_policy"
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.specify_policy || ""}
                  name="specify_policy"
                  disabled
                />
              </td>
              <td>
                <div className="d-flex month_year justify-content-around">
                  {!hideIcon && deductionOthers && (
                    <span
                      onClick={() =>
                        handleOpenDownload({
                          link: [deductionOthers],
                        })
                      }
                    >
                      <i class="fa fa-download upload_investment_doc black"></i>
                    </span>
                  )}
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.specify_amount || ""}
                    name="specify_amount"
                    disabled
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Table bordered hover size="sm mt-5">
          <tbody>
            <tr className="text">
              <td>#</td>

              <td> Deduction u/s 80C (Maximum Amount Upto Rs.150000/-)</td>
              <td>Policy Name</td>
              <td>Amount</td>
            </tr>

            <tr>
              <td className="set-width">
                <h6>1</h6>
              </td>
              <td>
                <h6>Life Insurance Premium(LIC)</h6>
              </td>
              <td className="width">
                <input
                  type="text"
                  className="input"
                  value={state.lifeInsurancePolicy || ""}
                  name="lifeInsurancePolicy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.lifeInsurancePolicyErrMsg} />{" "}
                </div>
              </td>
              <td className="width">
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "LIC",
                        deductionAmountFile: "LIC",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    inputMode="numeric"
                    className="input"
                    value={state.lifeInsuranceAmount || ""}
                    name="lifeInsuranceAmount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.LICErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>2</h6>
              </td>
              <td>
                <h6>Provident Fund(PF)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.pf_Policy || ""}
                  name="pf_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.pf_PolicyErrMsg} />{" "}
                </div>
              </td>
              <td>
                <input
                  type="number"
                  className="input"
                  inputMode="numeric"
                  value={state.pf_Amount || ""}
                  name="pf_Amount"
                  onChange={handleChange}
                />
                <ErrorMessage errMsg={state.pfErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>3</h6>
              </td>
              <td>
                <h6>Public Provident Fund(PPF)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.ppf_Policy || ""}
                  name="ppf_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.ppf_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "PPF",
                        deductionAmountFile: "PPF",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.ppf_Amount || ""}
                    name="ppf_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.PPFErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>4</h6>
              </td>
              <td>
                <h6>Voluntary Provident Fund(VPF)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.vpf_Policy || ""}
                  name="vpf_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.vpf_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Voluntary Provident Fund",
                        deductionAmountFile: "VPF",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.vpf_Amount || ""}
                    name="vpf_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.VPFErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>5</h6>
              </td>
              <td>
                <h6>National Savings Certificate(NSC) + Accrued Interest</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.nsc_Policy || ""}
                  name="nsc_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.nsc_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "NSC",
                        deductionAmountFile: "NSC",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.nsc_Amount || ""}
                    name="nsc_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.NSCErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>6</h6>
              </td>
              <td>
                <h6>
                  Tax Saving-<br></br>Mutual Fund
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.elss_Policy || ""}
                  name="elss_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.elss_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Mutual fund",
                        deductionAmountFile: "mutual_fund",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.elss_Amount || ""}
                    name="elss_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.mutualFundErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>7</h6>
              </td>
              <td>
                <h6>
                  Payment for Tuition Fees for Children(Max.<br></br> 2
                  Children)
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.tuition_Policy || ""}
                  name="tuition_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.tuition_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Tution Fee",
                        deductionAmountFile: "tutionFee",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.tuition_Amount || ""}
                    name="tuition_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.tutionFeeErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>8</h6>
              </td>
              <td>
                <h6>Principal Repayment Of Housing Loan</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.housing_Loan_Policy || ""}
                  name="housing_Loan_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.housing_Loan_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Housing Loan",
                        deductionAmountFile: "housingLoan",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.housing_Loan_Amount || ""}
                    name="housing_Loan_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.housingLoanErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>9</h6>
              </td>
              <td>
                <h6>
                  Stamp Duty, Registration charges incurred <br></br>for Buying
                  <br></br>
                  House(1st Year Only)
                </h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.stamp_Duty_Policy || ""}
                  name="stamp_Duty_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.stamp_Duty_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Stamp Duty Policy",
                        deductionAmountFile: "stampDutyPolicy",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.stamp_Duty_Amount || ""}
                    name="stamp_Duty_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.stampDutyPolicyErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>10</h6>
              </td>
              <td>
                <h6>Bank Fixed Deposit For 5 Years & Above</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.bank_Fixed_Policy || ""}
                  name="bank_Fixed_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.bank_Fixed_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Bank Fixed Deposit",
                        deductionAmountFile: "bankFixedPolicy",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.bank_Fixed_Amount || ""}
                    name="bank_Fixed_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.bankFixedPolicyErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>11</h6>
              </td>
              <td>
                <h6>Post Office Term Deposit For 5 Years & Above</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.postOffice_Policy || ""}
                  name="postOffice_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.postOffice_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Post Office",
                        deductionAmountFile: "postOffice",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.postOffice_Amount || ""}
                    name="postOffice_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.postOfficeErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>12</h6>
              </td>
              <td>
                <h6>Sukanya Samriddhi Account Scheme</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.accountScheme_Policy || ""}
                  name="accountScheme_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.accountScheme_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Sukanya Samriddhi Account Scheme",
                        deductionAmountFile: "SSAS",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.accountScheme_Amount || ""}
                    name="accountScheme_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.SSASErrMsg} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>13</h6>
              </td>
              <td>
                <h6>Others(Please Specify)</h6>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.others_Policy || ""}
                  name="others_Policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.others_PolicyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Others",
                        deductionAmountFile: "others",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.others_Amount || ""}
                    name="others_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.othersErrMsg} />
              </td>
            </tr>
            <tr className="text">
              <td></td>
              Deduction u/s 80CCC
            </tr>
            <tr>
              <td></td>
              <td>
                <h6>Contribution To Certain Pension Funds</h6>
              </td>
              <td>
                <h6>Upto Rs.150000/-</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Pension Fund",
                        deductionAmountFile: "pensionFund",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.d80CCC_Amount || ""}
                    name="d80CCC_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.pensionFundErrMsg} />
              </td>
            </tr>
            <tr className="text">
              <td></td>
              Deduction u/s 80CCD(1)
            </tr>
            <tr>
              <td></td>
              <td>
                <h6>Contribution To Pension Scheme of Central Government</h6>
              </td>
              <td>
                <h6>Upto Rs.150000/-</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Pension Scheme",
                        deductionAmountFile: "pensionScheme",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.d80CCD_1_Amount || ""}
                    name="d80CCD_1_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.pensionSchemeErrMsg} />
              </td>
            </tr>

            <tr className="text">
              <td></td>
              Deduction u/s 80CCD(1B)
            </tr>
            <tr>
              <td></td>
              <td>
                <h6>Contribution To National Pension Scheme</h6>
              </td>
              <td>
                <h6>Upto Rs.500000/-</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "National Pension Scheme",
                        deductionAmountFile: "nationalPensionScheme",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.d80CCD_1B_Amount || ""}
                    name="d80CCD_1B_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.nationalPensionSchemeErrMsg} />
              </td>
            </tr>

            <tr className="text">
              <td></td>
              Deduction u/s 80
            </tr>
            <tr>
              <td>80CCC</td>
              <td>
                <h6>Pension Plan from Insurance Companies/Mutual Funds</h6>
              </td>
              <td>
                <h6>
                  Max. Rs.5000/- (Within<br></br> overall limit of <br></br>
                  Rs.25000/50000)
                </h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Pension From Insurance/Mutual Funds",
                        deductionAmountFile: "pensionFromInsurance",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.preventive_Health_Amount || ""}
                    name="preventive_Health_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.pensionFromInsuranceErrMsg} />
              </td>
            </tr>
            <tr>
              <td>80 D</td>
              <td>
                <h6>
                  Medical Insurance Premium-<br></br>Individual, Spouse &
                  Children
                </h6>
              </td>
              <td>
                <h6>
                  (Max. Limit-Rs.25000/-<br></br> Additional Rs.25000,if
                  <br></br> any person insured is a <br></br>Senior Citizen)
                </h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText:
                          "Medical Insurance(Individual, Spouse & Children)",
                        deductionAmountFile: "medicalInsurance",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.medicalInsuranceChildren_Amount || ""}
                    name="medicalInsuranceChildren_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.medicalInsuranceErrMsg} />
              </td>
            </tr>
            <tr>
              <td>80 D</td>
              <td>
                <h6>Medical Insurance Premium-Parents </h6>
              </td>
              <td>
                <h6>
                  (Max. Limit-Rs.25000/-<br></br> Additional Rs.25000,if
                  <br></br> any person insured is a <br></br>Senior Citizen)
                </h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Medical Insurance Parents",
                        deductionAmountFile: "medicalInsuranceParents",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.medicalInsuranceParent_Amount || ""}
                    name="medicalInsuranceParent_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.medicalInsuranceParentsErrMsg} />
              </td>
            </tr>

            <tr>
              <td>80 E</td>
              <td>
                <h6>Interest for Loan taken for Higher Education</h6>
              </td>
              <td>
                <h6>No Limit (Only Interest portion)</h6>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Interest For for Education",
                        deductionAmountFile: "interestForEducation",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.interest_Amount || ""}
                    name="interest_Amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.interestForEducationErrMsg} />
              </td>
            </tr>

            <tr>
              <td>Others (Please Specify)</td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.other_specify_policy || ""}
                  name="other_specify_policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.otherSpecifyPolicyErrMsg} />
                </div>
              </td>
              <td>
                <input
                  type="text"
                  className="input"
                  value={state.specify_policy || ""}
                  name="specify_policy"
                  onChange={handleChange}
                />
                <div className="mt-3">
                  <ErrorMessage errMsg={state.specify_policyErrMsg} />
                </div>
              </td>
              <td>
                <div className="d-flex month_year justify-content-around mb-3">
                  <span
                    onClick={() =>
                      handleOpenUpload({
                        state: state,
                        setState: setState,
                        fileName: "proof",
                        deductionText: "Others",
                        deductionAmountFile: "deductionOthers",
                      })
                    }
                  >
                    <i class="fa fa-upload upload_investment_doc black"></i>
                  </span>
                  <input
                    type="number"
                    className="input"
                    inputMode="numeric"
                    value={state.specify_amount || ""}
                    name="specify_amount"
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage errMsg={state.deductionOthersErrMsg} />
              </td>
            </tr>
          </tbody>
        </Table>
      )}

      <div className="float-right pb-5 mt-5">
        {!hideIcon && (
          <>
            <Link to="/investment">
              <Button className="button-style py-2 mr-3">Cancel</Button>
            </Link>
            {id.length > 9 && !editFile ? (
              <Button className="button-style px-3 py-2" onClick={handlePrint}>
                Print
              </Button>
            ) : (
              <Button
                className="button-style px-2 py-2"
                onClick={(e) =>
                  handleSubmit({ e, state, setState, history, editFile, id })
                }
              >
                Submit
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateInvestmentData;

import React from 'react';
import { Calendar, Select } from 'antd';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Loader } from '../svg';
import { Tabs, Tab } from 'react-bootstrap';
import ResponseFilter from '../../ResponseFilter';
import { holidayListByEmp } from '../../config/services/employeeServices';
const { Option } = Select;


const monthValue = type => {
  switch (type) {
    case 1:
      return 'January';
      break;
    case 2:
      return 'February';
      break;
    case 3:
      return 'March';
      break;
    case 4:
      return 'April';
      break;
    case 5:
      return 'May';
      break;
    case 6:
      return 'June';
      break;
    case 7:
      return 'July';
      break;
    case 8:
      return 'August';
      break;
    case 9:
      return 'September';
      break;
    case 10:
      return 'October';
      break;
    case 11:
      return 'November';
      break;
    case 12:
      return 'December';
      break;
  }
}

class EmpHoliday extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      holidaysList: [],
      holidayName: '',
      holidayType: '',
      date: '',
      openAddHolidayPopup: false,
      loader: true,
      tabStatus: 1

    }
  }

  getListData = (dateData) => {

    let { holidaysList } = this.state;
    let listData;


    holidaysList.map(function (data, index) {
      data.holidays.map(function (value, i) {
        let dateobj = new Date(value.date)
        let dates = dateobj.getDate();
        let month = dateobj.getMonth();

        if (dateData.month() === month) {
          switch (dateData.date()) {
            case (dates):
              let type = value.holidayType === 1 ? 'error' : 'success'
              listData = [
                { type: type, content: value.holidayName, date: value.date }
              ];
            default:
          }
        }
      })
    })
    return listData || [];
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <div>
        {listData.map(item => <div className={item.type == 'success' ? 'orange' : 'greeen'}>
          <span className="bage"><p className="holiday_date">{moment.unix(item.date / 1000).format("DD")}</p></span>
          <div className="events">
            <p key={item.content}>{item.content}</p>
          </div>
        </div>)}
      </div>
    );
  }

  onSelect = (e, month) => {
    this.setState({
      openAddHolidayPopup: !this.state.openAddHolidayPopup,
      date: moment(`${e}/${month}/${new Date().getFullYear()}`)
    })
  };
  closeModal = () => this.setState({
    openAddHolidayPopup: false

  });
  handleChange = e => this.setState({
    [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value

  });
  handleSelectChange = e => this.setState({ holidayType: e });

  getList = () => {
    const { tabStatus } = this.state
    holidayListByEmp().then(res => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          holidaysList: resData.holidaysList,
        }, () => this.setState({ loader: false }))
      }
    })
  }
  disabledDate = (current) => {
    if (!current) {
      return false;
    }
    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);

    return (current.valueOf() != date.valueOf());  // can not select days before today
  }
  componentDidMount() {
    this.setState({ tabStatus: localStorage.getItem("holidayTabStatusEmployee") ? localStorage.getItem("holidayTabStatusEmployee") : 1 })
    this.getList();

  }
  componentWillUnmount() {
    localStorage.removeItem("holidayTabStatusEmployee")
  }
  handleTabChange = e => {
    const { tabStatus } = this.state;
    this.setState({ tabStatus: e })
    localStorage.setItem("holidayTabStatusEmployee", e)
  }
  render() {
    let { holidaysList, loader, tabStatus } = this.state;
    return (
      <div className="body_container">
        {
          loader && <Loader />
        }
        <h3>HOLIDAY LIST</h3>
        <Tabs className="holiday_tab" activeKey={tabStatus ? tabStatus : "1"} id="uncontrolled-tab-example" onSelect={(e) => this.handleTabChange(e)}>
          <Tab eventKey="1" title="Grid View">
            <div className="row">
              {
                holidaysList.map((holiday, i) => <div className="col-xl-4 col-lg-6 my_calendar">
                  <Calendar
                    className="employee_cal"
                    fullscreen={false}
                    mode="month"
                    defaultValue={moment(`${i + 1}/${i + 1}/${new Date().getFullYear()}`)}
                    headerRender={() => <h5>{monthValue(holiday._id.month)}{holiday.name} {new Date().getFullYear()}</h5>}
                    onChange={(e) => this.onSelect(e, i + 1)}
                    dateCellRender={(value) => this.dateCellRender(value)}
                    disabledDate={this.disabledDate}
                  />
                </div>)
              }
            </div>
          </Tab>
          <Tab eventKey="2" title="List View">
            <div className="cm_hoilday">
              {
                holidaysList.map((holiday, i) => {

                  return (
                    <div className="mt-4">
                      {
                        <h3>{monthValue(holiday._id.month)}</h3>
                      }
                      <ul className="sm_date cm_card dashboard_info">
                        {/* <li>
                                <span>Date</span>
                                <p className='ml-3'>Event</p>
                            </li> */}
                        {
                          holiday.holidays.length ? holiday.holidays.map(item =>
                            <li className='d-flex flex-wrap justify-content-center'>
                              <div>
                                <span>{moment(item.date).format('D')}-{monthValue(holiday._id.month).slice(0, 3)} {`(${moment(item.date).format('dddd')})`}</span>
                              </div>
                              <div>
                                <p className='text-center'>{item.holidayName}</p>
                              </div>
                            </li>) :
                            <li> <p className='text-center'> NO HOLIDAY</p></li>
                        }
                      </ul>
                    </div>
                  )
                })
              }
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
export default EmpHoliday;


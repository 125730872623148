import {
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
} from "./actionType";

import { addContactBySalesService } from "../../config/services/employeeServices";
import { getContactList } from "./getContactList";

const addContactByAdmin = (data, query, setShowModal, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader, employeeId) => {
  return (dispatch) => {
    dispatch(addContactByAdminRequest());
    addContactBySalesService(data, employeeId)
      .then((res) => {
        if (res.data.statusCode !== 1) {
          dispatch(addContactByAdminFailure(res.data));
          setFailResMsg(true)
          setLoader(false)
        } else {
          dispatch(addContactByAdminSuccess(res.data));
          setResMsg(true)
          setTimeout(() => (
            handleCancelButtonClick(),
            dispatch(getContactList(query)),
            setLoader(false)
          ), 3000);

        }
      })
      .catch((error) => {
        dispatch(addContactByAdminFailure(error));
        setFailResMsg(true);
      });
  };
};

const addContactByAdminRequest = () => {
  return {
    type: ADD_CONTACT_REQUEST,
  };
};
const addContactByAdminSuccess = (data) => {
  return {
    type: ADD_CONTACT_SUCCESS,
    payload: data,
  };
};
const addContactByAdminFailure = (error) => {
  return {
    type: ADD_CONTACT_FAILURE,
    payload: error,
  };
};
export {
  addContactByAdmin,
  addContactByAdminRequest,
  addContactByAdminSuccess,
  addContactByAdminFailure,
};

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'react-phone-number-input/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ClearCache from 'react-clear-cache';


class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }
  
  render() {
    return null;
  }
}




ReactDOM.render(<React.Fragment><ScrollToTop/>
 <Suspense fallback={<></>} >
            <ClearCache auto={true} duration={60000}>
                {({ isLatestVersion }) => (
                    <>
                        <App/>
                    </>
                )}
            </ClearCache>
        </Suspense>
</React.Fragment>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

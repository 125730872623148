import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Modal as AntModal } from "antd";
import {
  ActionImage,
  ErrorMessage,
  InputField,
  MONTH_ARRAY_NUM,
  currentMonth,
} from "../../helper/Constant";
import { Select } from "antd";
import { PDFReader } from "reactjs-pdf-reader-custom";
import { downloadIcon } from "../../../asset";
import pdfpng from "../../../asset/imgs/pdfpng.png";
import { statusMon } from "../../leaves/Constants";

const { Option } = Select;

const UploadRentSlipModal = (props) => {
  const {
    openUploadRentSlip,
    closeModal,
    handleMonth,
    handleRentFile,
    rentMonth,
    handleUploadRentSlip,
    uploadErr,
    rentFileData,
    upload,
  } = props;

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fileInputValue, setFileInputValue] = useState("");

  useEffect(() => {
    if (!openUploadRentSlip) {
      setFileInputValue("");
    }
  }, [openUploadRentSlip]);

  const openDocument = (doc) => {
    setSelectedDocument(doc);
  };

  const closeDocument = () => {
    setSelectedDocument(null);
  };

  const handleFileChange = (e) => {
    setFileInputValue(e.target.value);
    handleRentFile(e);
  };

  const filteredMonths = MONTH_ARRAY_NUM.filter(
    (month) => month.value <= currentMonth
  );

  return (
    <>
      <AntModal
        title=""
        footer={false}
        centered
        className="upload-modal"
        visible={openUploadRentSlip}
        onCancel={closeModal}
      >
        <Modal.Header className="text-center confirmation">
          <p className="mb-4 cm_bold cnfrm_msg">Upload Rent Slip</p>
        </Modal.Header>
        <Modal.Body>
          {upload ? (
            <div className="d-flex flex-column month_year">
              <Select
                className=""
                value={rentMonth ? rentMonth : "Month"}
                defaultValue="Select Month"
                name="searchMonth"
                onChange={(e) => handleMonth(e)}
              >
                {filteredMonths.map((month) => (
                  <Option key={month.value} value={month.value}>
                    {month.label}
                  </Option>
                ))}
              </Select>
              <InputField
                type="file"
                id="landlord-pancard"
                accept="application/pdf,.png, .jpeg, .jpg"
                placeholder="Enter Title"
                onChange={handleFileChange}
                value={fileInputValue}
              />
            </div>
          ) : (
            <div className="document-container">
              {rentFileData &&
                rentFileData
                  .sort((a, b) => a.month - b.month)
                  .map((doc, index) => {
                    const isPdf = doc.file.toLowerCase().endsWith(".pdf");
                    return (
                      <div>
                        <h4 className="mt-1 mb-1">{statusMon(doc.month)}</h4>
                        <div
                          key={index}
                          className="d-flex justify-content-between mb-3 file-container"
                        >
                          <div className="mr-3">
                            {isPdf ? (
                              <div className="d-flex">
                                <ActionImage
                                  src={pdfpng}
                                  alt={`pdfThumbnail_${index}`}
                                  onClick={() => openDocument(doc.file)}
                                />
                                <span className="ml-3">{doc.file}</span>
                              </div>
                            ) : (
                              <div className="d-flex">
                                <img
                                  src={doc.file}
                                  alt={`imageThumbnail_${index}`}
                                  style={{ width: "1.9rem" }}
                                  onClick={() => openDocument(doc.file)}
                                />
                                <span className="ml-3">{doc.file}</span>
                              </div>
                            )}
                          </div>
                          <div className="d-flex">
                            <span onClick={() => openDocument(doc.file)}>
                              <i
                                className="fa fa-eye blue ml-4"
                                title="View Doc"
                                role="button"
                              />
                            </span>
                            <a
                              href={doc.file}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="ml-3"
                            >
                              <ActionImage
                                src={downloadIcon}
                                alt={`downloadIcon_${index}`}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className=" d-flex justify-content-center">
          <div className="d-flex flex-column ">
            {upload && (
              <div className="submit_button_margin mt-4">
                <button
                  className="btn btn-primary cm_btn3"
                  onClick={() => handleUploadRentSlip()}
                >
                  Upload
                </button>

                <button
                  type="button"
                  onClick={closeModal}
                  className="btn text-secondary border border-dark cm_btn3"
                >
                  Cancel
                </button>
              </div>
            )}
            <div className="d-flex justify-content-center mt-2">
              <ErrorMessage errMsg={uploadErr} />
            </div>
          </div>
        </Modal.Footer>
      </AntModal>
      <AntModal
        title=""
        footer={false}
        centered
        className="sm_modal1"
        visible={selectedDocument}
        onCancel={closeDocument}
      >
        <Modal.Body
          className={`view_pdf ${
            loading && " d-flex justify-content-center align-items-center"
          } `}
        >
          {loading &&
            selectedDocument &&
            (selectedDocument.endsWith(".pdf") ||
              selectedDocument.endsWith(".PDF")) && (
              <div className="snippet" data-title="dot-elastic">
                <div className="stage">
                  <div className="dot-elastic"></div>
                </div>
              </div>
            )}
          {selectedDocument && (
            <>
              {(selectedDocument.endsWith(".pdf") ||
                selectedDocument.endsWith(".PDF")) && (
                <PDFReader
                  url={selectedDocument}
                  showAllPage={true}
                  onDocumentComplete={() => setLoading(false)}
                  width="700"
                />
              )}
              {(selectedDocument.endsWith(".jpeg") ||
                selectedDocument.endsWith(".jpg") ||
                selectedDocument.endsWith(".png") ||
                selectedDocument.endsWith(".JPEG") ||
                selectedDocument.endsWith(".JPG") ||
                selectedDocument.endsWith(".PNG")) && (
                <div className="d-flex justify-content-center">
                  <img
                    src={selectedDocument}
                    alt="document"
                    style={{ width: "20rem", height: "20rem" }}
                  />
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="submit_button_margin">
          <div className="mt-4">
            <button
              type="button"
              onClick={closeDocument}
              className="btn btn-primary cm_btn2"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </AntModal>
    </>
  );
};

export default UploadRentSlipModal;

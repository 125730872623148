 import {
    GET_TRANSACTION_LIST_REQUEST,
    GET_TRANSACTION_LIST_SUCCESS,
    GET_TRANSACTION_LIST_FAILURE,
  } from "./actionType";
  
  import { getTransactionListService } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
  
 const getTransactionList = (query) => {
    return (dispatch) => {
      dispatch(getTransactionListRequest());
      getTransactionListService(query)
        .then((res) => {
      let { status, resData } = ResponseFilter(res);
          
          /////////////////////////////////////
          if(status){
            dispatch(getTransactionListSuccess(res.data.responseData));
          }
          else{
            dispatch(getTransactionListFailure(res.data));
          }
          // if(res.data.statusCode === 2){
          //   dispatch(getTransactionListRequest(res.data.responseData));
          // }
          /////////////////////////////////////
          
          // if (res.data.statusCode !== 1) {
          //   dispatch(getTransactionListFailure(res.data));
          // } else {
          //   dispatch(getTransactionListSuccess(res.data.responseData));
          // }
        })
        .catch((error) => {
          dispatch(getTransactionListFailure(error));
        });
    };
  };
  
  const getTransactionListRequest = () => {
    return {
      type: GET_TRANSACTION_LIST_REQUEST,
    };
  };
  
  const getTransactionListSuccess = (data) => {
    return {
      type: GET_TRANSACTION_LIST_SUCCESS,
      payload: data,
    };
  };
  
  const getTransactionListFailure = (error) => {
    return {
      type: GET_TRANSACTION_LIST_FAILURE,
      payload: error,
    };
  };
  
  export {
    getTransactionList,
    getTransactionListRequest,
    getTransactionListSuccess,
    getTransactionListFailure,
  };
  
import React from "react";
import { DATE_ERROR, FILE_ERROR, REQUIRED, type } from "../helper/Constant";
import { invalid } from "../helper/String";
import { addCourierDetailsByAdminAction } from "../../redux/actions/addCourierDetailByAdminAction";
import { editCourierDetailsByAdminAction } from "../../redux/actions/editCourierDetailByAdminAction";
import { deleteCourierDetailsByAdminAction } from "../../redux/actions/deleteCourierDetailByAdminAction";
import moment from "moment";

export const handleClose = (dispatch) => {
  dispatch({
    type: type,
    payload: {
      addCourierDetailPopup: false,
      editCourierDetailPopup: false,
      deleteCourierDetailPopup: false,
      addPopup: false,
      editPopup: false,
      deletePopup: false,
      name: "",
      dateSent: "",
      tentative_receiving_date: "",
      cost: "",
      tracking_id: "",
      courier_company: "",
      item_description: "",
      courierFile: null,
      nameErrMsg: "",
      dateSentErrMsg: "",
      tentative_receiving_dateErrMsg: "",
      costErrMsg: "",
      tracking_idErrMsg: "",
      courier_companyErrMsg: "",
      item_descriptionErrMsg: "",
      data: "",
      editCourier: "",
      courierFileErrMsg: "",
    },
  });
};

export const uploadFile = (e, dispatch) => {
  dispatch({
    type: type,
    payload: {
      courierFile: e.target.files[0],
    },
  });
};

export const allowedFileTypes = ["pdf", "jpg", "mp4", "jpeg", "png"];

export const handleValidation = (data) => {
  const { dispatch, props, actionType } = data;
  const {
    name,
    dateSent,
    tentative_receiving_date,
    cost,
    tracking_id,
    courier_company,
    item_description,
    courierFile,
  } = props;

  const fields = {
    name: name,
    dateSent: dateSent,
    tentative_receiving_date: tentative_receiving_date,
    cost: cost,
    tracking_id: tracking_id,
    courier_company: courier_company,
    item_description: item_description,
    courierFile: courierFile,
  };
  let isValid = true;
  Object.entries(fields).forEach(([fieldName, value]) => {
    if (moment(dateSent).isAfter(tentative_receiving_date)) {
      dispatch({
        type: type,
        payload: {
          dateSentErrMsg: DATE_ERROR,
        },
      });
      isValid = false;
    }
    if (
      fieldName === "courierFile" &&
      actionType === "add" &&
      (courierFile === null ||
        !Array.isArray(courierFile) ||
        courierFile.length === 0)
    ) {
      dispatch({
        type: type,
        payload: {
          [`${fieldName}ErrMsg`]: REQUIRED,
        },
      });
      isValid = false;
    } else if (
      (fieldName === "courierFile" && actionType === "add") ||
      (courierFile && fieldName === "courierFile" && actionType === "edit")
    ) {
      for (const file of courierFile) {
        if (file.name) {
          const fileType = file.name.split(".").pop().toLowerCase();
          if (!allowedFileTypes.includes(fileType)) {
            dispatch({
              type: type,
              payload: {
                [`${fieldName}ErrMsg`]: FILE_ERROR,
              },
            });
            isValid = false;
            break;
          }
        } else {
          const fileType = file.split(".").pop().toLowerCase();
          if (!allowedFileTypes.includes(fileType)) {
            dispatch({
              type: type,
              payload: {
                [`${fieldName}ErrMsg`]: FILE_ERROR,
              },
            });
            isValid = false;
            break;
          }
        }
      }
    }
    if (value === 0 || value === "" || value === undefined || value === null) {
      dispatch({
        type: type,
        payload: {
          [`${fieldName}ErrMsg`]: REQUIRED,
        },
      });
      isValid = false;
    } else if (fieldName === "cost" && (isNaN(value) || value < 0)) {
      dispatch({
        type: type,
        payload: {
          [`${fieldName}ErrMsg`]: invalid,
        },
      });
      isValid = false;
    } else if (fieldName === "name" && !/^[a-zA-Z\d\s.-]+$/.test(value)) {
      dispatch({
        type: type,
        payload: {
          [`${fieldName}ErrMsg`]: invalid,
        },
      });
      isValid = false;
    } else if (fieldName === "cost") {
      if (value && String(value).startsWith(".")) {
        dispatch({
          type: type,
          payload: {
            [`${fieldName}ErrMsg`]: "Cost cannot start with a decimal",
          },
        });
        isValid = false;
      }
    }
  });
  return isValid;
};

export function capitalizeName(name) {
  const words = name.toLowerCase().split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  const capitalizedName = capitalizedWords.join(" ");
  return capitalizedName;
}

export const handleSubmit = (props) => {
  const {
    e,
    dispatch,
    actionType,
    data,
    getCourierList,
    dispatchToAction,
    courier_id,
  } = props || {};
  const {
    name,
    dateSent,
    tentative_receiving_date,
    cost,
    tracking_id,
    courier_company,
    item_description,
    courierFile,
    editCourier,
  } = data || {};
  e.preventDefault();
  if (
    data &&
    handleValidation({
      dispatch: dispatch,
      props: props.data,
      actionType: actionType,
    })
  ) {
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    if (actionType === "add") {
      const params = new FormData();
      params.append("receiver_name", capitalizeName(name));
      params.append("dateSent", new Date(dateSent).getTime().toString());
      params.append(
        "tentative_receiving_date",
        new Date(tentative_receiving_date).getTime().toString()
      );
      params.append("cost", cost);
      params.append("tracking_id", tracking_id);
      params.append("courier_company", courier_company);
      params.append("item_description", item_description);
      courierFile.forEach((file) => {
        params.append("courier", file.originFileObj);
      });
      dispatchToAction(
        addCourierDetailsByAdminAction({
          useDispatch: dispatch,
          getCourierList: getCourierList,
          handleClose: handleClose(dispatch),
          params: params,
        })
      );
    }
    if (actionType === "edit") {
      const params = new FormData();
      params.append("receiver_name", capitalizeName(name));
      params.append("dateSent", new Date(dateSent).getTime().toString());
      params.append(
        "tentative_receiving_date",
        new Date(tentative_receiving_date).getTime().toString()
      );
      params.append("cost", cost);
      params.append("tracking_id", tracking_id);
      params.append("courier_company", courier_company);
      params.append("item_description", item_description);
      editCourier
        ? editCourier.forEach((file) => {
            if (file.originFileObj) {
              params.append("courier", file.originFileObj);
            } else {
              params.append("courier", file);
            }
          })
        : courierFile.forEach((file) => {
            params.append("courier", file);
          });
      dispatchToAction(
        editCourierDetailsByAdminAction({
          useDispatch: dispatch,
          getCourierList: getCourierList,
          handleClose: handleClose(dispatch),
          params: params,
          courierId: courier_id,
        })
      );
    }
  }
  if (actionType === "delete") {
    const params = {
      courierId: courier_id,
    };
    dispatchToAction(
      deleteCourierDetailsByAdminAction({
        useDispatch: dispatch,
        getCourierList: getCourierList,
        handleClose: handleClose(dispatch),
        params: params,
      })
    );
  }
};

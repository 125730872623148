import React from "react";
import { Select } from "antd";
import { Modal } from "react-bootstrap";
import { ErrorMessage, isAdmin } from "../../helper/Constant";
import _ from "lodash";
import {
  CATEGORY_ACCOUNTANT,
  CATEGORY_ARRAY,
  CATEGORY_IT,
  onEditorStateChange,
} from "../Constants";
import TextEditor from "../../../hoc/textEditor/TextEditor";
const { Option } = Select;

const AddTicketModal = (props) => {
  const {
    openAddTicketPopup,
    categoryErrorMsg,
    subject,
    subjectErrorMsg,
    emailError,
    emails,
    handleEmailChange,
    employeeList,
    descriptionErrorMsg,
    editorState,
    dispatch,
    handleChange,
    closeModal,
    handleSubmit,
    category,
    handleCategoryChange,
    admin,
    employeeId,
    handleSelectEmployeeChange,
    employeeIdErrorMsg,
  } = props;

  return (
    <Modal
      size="md"
      show={openAddTicketPopup}
      onHide={closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <form className="cm_add_ticket" onSubmit={handleSubmit}>
          <h3>ADD TICKET</h3>

          <div className={`form-group ${categoryErrorMsg && "input_error"} `}>
            <div className="cm_category">
              <Select
                defaultValue="Category"
                className=" cm_bold "
                style={{ width: 200 }}
                onChange={handleCategoryChange}
              >
                {(isAdmin === "1" ||
                  isAdmin === "4" ||
                  isAdmin === "2" || 
                  isAdmin === "6" ||
                  (isAdmin === "8" && !admin) ||
                  (isAdmin === "5" && !admin) ||
                  (isAdmin === "7" && !admin)) &&
                  CATEGORY_ARRAY.map((category) => (
                    <Option key={category.value} value={category.value}>
                      {category.label}
                    </Option>
                  ))}
                {isAdmin === "5" &&
                  admin &&
                  CATEGORY_ACCOUNTANT.map((category) => (
                    <Option key={category.value} value={category.value}>
                      {category.label}
                    </Option>
                  ))}
                {(isAdmin === "7" || isAdmin === "8") &&
                  admin &&
                  CATEGORY_IT.map((category) => (
                    <Option key={category.value} value={category.value}>
                      {category.label}
                    </Option>
                  ))}
              </Select>
              <ErrorMessage errMsg={categoryErrorMsg} />
            </div>
          </div>
          {admin && (
            <>
              <label className="sub mt-5">Employee</label>
              <div
                className={`form-group ${employeeIdErrorMsg && "input_error"}`}
              >
                <Select
                  showSearch
                  name="employeeId"
                  value={employeeId ? employeeId : " Select Employee"}
                  onChange={handleSelectEmployeeChange}
                  optionFilterProp="key"
                  filterOption={(input, option) =>
                    option.key.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {!_.isEmpty(employeeList)
                    ? employeeList
                        .sort(function (a, b) {
                          if (a.firstName < b.firstName) {
                            return -1;
                          }
                          if (a.firstName > b.firstName) {
                            return 1;
                          }
                          return 0;
                        })
                        .map(
                          (employee) =>
                            employee.status === 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}(
                                  {employee.empId})
                                </span>
                              </Option>
                            )
                        )
                    : ""}
                </Select>
                <ErrorMessage errMsg={employeeIdErrorMsg} />
              </div>
            </>
          )}
          <label className={`sub ${!admin && "mt-5"}`}>Subject</label>
          <div className={`form-group ${subjectErrorMsg && "input_error"}`}>
            <input
              className="form-control cm_box"
              maxLength="30"
              type="text"
              placeholder="Subject"
              name="subject"
              value={subject}
              onChange={handleChange}
              autoComplete="off"
            />
            <ErrorMessage errMsg={subjectErrorMsg} />
          </div>
          <div className={`form-group`}>
            <label className="sub">Send To</label>
            <input
              className="form-control cm_box"
              value={
                (category && category == 1) || category == 2
                  ? "system@mobcoder.com"
                  : "hr@mobcoder.com"
              }
              disabled
            />
          </div>
          <div className={`form-group ${emailError && "input_error"}`}>
            <label className="sub">Cc</label>
            <Select
              showSearch
              mode="multiple"
              name="employeeId"
              value={emails ? emails : "Select Cc"}
              placeholder={"Select Cc"}
              optionFilterProp="key"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleEmailChange}
            >
              {!_.isEmpty(employeeList)
                ? employeeList
                    .filter(
                      (data) =>
                        data.employeeId !== localStorage.getItem("employeeId")
                    )
                    .sort((a, b) => a.email.localeCompare(b.email))
                    .map(
                      (employee) =>
                        employee.status == 1 && (
                          <Option
                            key={`${employee.firstName} ${employee.lastName}`}
                            value={employee.email}
                          >
                            <span>{employee.email}</span>
                          </Option>
                        )
                    )
                : ""}
            </Select>
            <ErrorMessage errMsg={emailError} />
          </div>
          <div className={`form-group ${descriptionErrorMsg && "input_error"}`}>
            <label className="sub ">Description</label>
            <TextEditor
              editorState={editorState}
              onEditorStateChange={(e) => onEditorStateChange(e, dispatch)}
            />

            <ErrorMessage errMsg={descriptionErrorMsg} />
          </div>
          <div className="submit_button_margin">
            <button type="submit" className="btn btn-primary cm_btn3">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-primary cm_btn2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTicketModal;

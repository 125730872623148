import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
} from "./actionType.js";

import { loginEmployee } from "../../config/services/employeeServices";

const userLogin = (data) => {
  return (dispatch) => {
    dispatch(userLoginRequest());
    loginEmployee(data)
      .then((res) => {
        const userData = res.data.responseData;
        dispatch(userLoginSuccess(userData));
      })
      .catch((error) => {
        dispatch(userLoginFailure(error));
      });
  };
};

const userLoginRequest = () => {
  return {
    type: USER_LOGIN_REQUEST,
  };
};
const userLoginSuccess = (data) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: data,
  };
};
const userLoginFailure = (error) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error,
  };
};

export { userLogin, userLoginRequest, userLoginSuccess, userLoginFailure };

import {
    DELETE_TEAM_BY_ADMIN_REQUEST,
    DELETE_TEAM_BY_ADMIN_SUCCESS,
    DELETE_TEAM_BY_ADMIN_FAILURE,
} from "../actions";

const initialState = {
    loading: false,
    data: [],
    error: "",
};

const deleteTeamByAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_TEAM_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
                data: [],
            };

        case DELETE_TEAM_BY_ADMIN_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: "",
            };
        case DELETE_TEAM_BY_ADMIN_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.payload,
            };
        default:
            return state;
    }
};
export default deleteTeamByAdminReducer;

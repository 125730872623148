import {
  GET_EMPLOYEE_LIST_REQUEST,
  GET_EMPLOYEE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_FAILURE,
} from "./actionType";

import { getEmployeeList } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getEmpList = () => {
  return (dispatch) => {
    dispatch(getEmployeeListRequest());
    getEmployeeList()
      .then((res) => {
        let { status, resData } = ResponseFilter(res);

        if (status) {
          dispatch(getEmployeeListSuccess(res.data.responseData));
        } else {
          dispatch(getEmployeeListFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(getEmployeeListFailure(error));
      });
  };
};

const getEmployeeListRequest = () => {
  return {
    type: GET_EMPLOYEE_LIST_REQUEST,
  };
};

const getEmployeeListSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_LIST_SUCCESS,
    payload: data,
  };
};

const getEmployeeListFailure = (error) => {
  return {
    type: GET_EMPLOYEE_LIST_FAILURE,
    payload: error,
  };
};
export {
  getEmpList,
  getEmployeeListRequest,
  getEmployeeListSuccess,
  getEmployeeListFailure,
};

import React from "react";
import { Modal } from "react-bootstrap";
import { createMarkup } from "../../helper/Constant";

const ViewTicketReasonModal = (props) => {
  const { openViewTicketReason, closeModal ,description} = props;

  return (
    <Modal
      size="lg"
      className="cm_ticket_status"
      show={openViewTicketReason}
      onHide={closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header className="text-center confirmation">
        <p className="mb-4 cm_bold cnfrm_msg">Reason</p>
      </Modal.Header>
      <Modal.Body>
      <hr/>
        <h5 dangerouslySetInnerHTML={createMarkup(description)}/>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          <button
            type="button"
            onClick={closeModal}
            className="btn text-secondary border border-dark cm_btn3"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewTicketReasonModal;

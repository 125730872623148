import { STATUS_VALUE_ZERO, STATUS_ZERO } from "../../components/attendance/Constants";
import { dailyAttendance } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    ADD_ATTENDANCE_BY_ADMIN_REQUEST,
    ADD_ATTENDANCE_BY_ADMIN_SUCCESS,
    ADD_ATTENDANCE_BY_ADMIN_FAILURE,
} from "./actionType";


const addAttendanceByAdmin = (props) => {
    let { useDispatch, closeModal, getAttendance, obj } = props
    return (dispatch) => {
        dispatch(addAttendanceByAdminRequest());
        dailyAttendance(obj).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        tabStatus: STATUS_ZERO,
                        status: '',
                        pageNo: 1,
                        resMsg: true,
                        miniLoader: false
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resMsg: false
                    }
                }), 3000);
            } else if (res.data.statusCode === STATUS_VALUE_ZERO) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        tabStatus: "0",
                        status: "",
                        pageNo: 1,
                        responseMessageAdd: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resMsgFailAdd: true,
                        miniLoader: false
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resMsgFailAdd: false
                    }
                }), 3000);
            }
            closeModal()
        })
        getAttendance()
            .catch((error) => {
                dispatch(addAttendanceByAdminFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const addAttendanceByAdminRequest = () => {
    return {
        type: ADD_ATTENDANCE_BY_ADMIN_REQUEST,
    };
};
const addAttendanceByAdminSuccess = (data) => {
    return {
        type: ADD_ATTENDANCE_BY_ADMIN_SUCCESS,
        payload: data,
    };
};
const addAttendanceByAdminFailure = (error) => {
    return {
        type: ADD_ATTENDANCE_BY_ADMIN_FAILURE,
        payload: error,
    };
};
export {
    addAttendanceByAdmin,
    addAttendanceByAdminRequest,
    addAttendanceByAdminSuccess,
    addAttendanceByAdminFailure,
};

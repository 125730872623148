import React from "react";
import { Table } from "react-bootstrap";

const CreateInvestmentTable = (props) => {
  const { handleChange, state, handleGender, id } = props;
  const toEdit = new URLSearchParams(window.location.search);
  const editFile = toEdit.has("toEdit")

  return (
    <div>
      <Table bordered hover size="sm">
        <thead>
          <tr className="text-center">
            <th>Mobcoder Technologies Pvt ltd.</th>
          </tr>

          <tr className="text-center text">
            {id.length > 9 ? (
              <th>
                INVESTMENT DECLARATION FORM FOR THE <br></br>YEAR {state.year1}
              </th>
            ) : (
              <th>
                INVESTMENT DECLARATION FORM FOR THE <br></br>YEAR {id}
              </th>
            )}
          </tr>

          <tr>
            {id.length > 9 ? (
              <td>
                Employee Code -{" "}
                <input
                  type="text"
                  className="input"
                  name="employeeCode"
                  value={state.employeeCode}
                  onChange={handleChange}
                  disabled
                ></input>
                <span className="error">
                  {" "}
                  {state.employeeCodeErr ? state.employeeCodeErr : ""}
                </span>
              </td>
            ) : (
              <td>
                Employee Code -{" "}
                <input
                  type="text"
                  className="input"
                  name="employeeCode"
                  value={state.employeeCode}
                  onChange={handleChange}
                  disabled
                ></input>
                <span className="error">
                  {" "}
                  {state.employeeCodeErr ? state.employeeCodeErr : ""}
                </span>
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {id.length > 9 ? (
              <td>
                Employee Name -{" "}
                <input
                  type="text"
                  className="input"
                  value={state.employeeName}
                  name="employeeName"
                  onChange={handleChange}
                  disabled
                ></input>
                <span className="error">
                  {" "}
                  {state.employeeNameErr ? state.employeeNameErr : ""}
                </span>
              </td>
            ) : (
              <td>
                Employee Name -{" "}
                <input
                  type="text"
                  className="input"
                  value={state.employeeName}
                  name="employeeName"
                  onChange={handleChange}
                  disabled
                ></input>
                <span className="error">
                  {" "}
                  {state.employeeNameErr ? state.employeeNameErr : ""}
                </span>
              </td>
            )}
          </tr>
          <tr>
            {id.length > 9 ? (
              <td>
                Employee Designation -
                <input
                  type="text"
                  className="input"
                  value={state.employeeDesignation}
                  name="employeeDesignation"
                  onChange={handleChange}
                  disabled
                ></input>
                <span className="error">
                  {" "}
                  {state.employeeDesignationErr
                    ? state.employeeDesignationErr
                    : ""}
                </span>
              </td>
            ) : (
              <td>
                Employee Designation -
                <input
                  type="text"
                  className="input"
                  value={state.employeeDesignation}
                  name="employeeDesignation"
                  onChange={handleChange}
                  disabled
                ></input>
                <span className="error">
                  {" "}
                  {state.employeeDesignationErr
                    ? state.employeeDesignationErr
                    : ""}
                </span>
              </td>
            )}
          </tr>
          <tr>
            {id.length > 9 ? (
              <td>
                Employee PAN -
                <input
                  type="text"
                  className="input input1"
                  name="employeePAN"
                  onChange={handleChange}
                  value={state.employeePAN}
                  disabled
                ></input>
                <span className="error">
                  {" "}
                  {state.employeePANErr ? state.employeePANErr : ""}
                </span>
              </td>
            ) : (
              <td>
                Employee PAN -
                <input
                  type="text"
                  className="input input1"
                  name="employeePAN"
                  onChange={handleChange}
                  value={state.employeePAN}
                ></input>
                <span className="error">
                  {" "}
                  {state.employeePANErr ? state.employeePANErr : ""}
                </span>
              </td>
            )}
          </tr>
          <tr>
            {id.length === 9 && !editFile ? (
              <td>
                Employee DOB -{" "}
                <span className="dob-font" disabled>
                  {state.employeeDOB}
                </span>
                <span className="error">
                  {" "}
                  {state.employeeDOBErr ? state.employeeDOBErr : ""}
                </span>
              </td>
            ) : (
              <td>
                Employee DOB -
                <span className="dob-font" disabled>
                  {state.employeeDOB2}
                </span>
                <span className="error">
                  {" "}
                  {state.employeeDOBErr ? state.employeeDOBErr : ""}
                </span>
              </td>
            )}
          </tr>
          <tr>
            <td>
              Gender -
              {id.length >= 9 ? (
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      name="gender"
                      value={state.gender}
                      onClick={() => handleGender(1)}
                      checked={state.gender === 1}
                      disabled
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      male
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      name="gender"
                      value={state.gender}
                      onClick={() => handleGender(2)}
                      checked={state.gender === 2}
                      disabled
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Female
                    </label>
                  </div>
                  <span className="error">
                    {" "}
                    {state.genderErr ? state.genderErr : ""}
                  </span>
                </div>
              ) : (
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      name="gender"
                      value={state.gender}
                      onClick={() => handleGender(1)}
                      checked={state.gender === 1}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      male
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      name="gender"
                      value={state.gender}
                      onClick={() => handleGender(2)}
                      checked={state.gender === 2}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Female
                    </label>
                  </div>
                  <span className="error">
                    {" "}
                    {state.genderErr ? state.genderErr : ""}
                  </span>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default CreateInvestmentTable;

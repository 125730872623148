import {
  EDIT_CANDIDATE_BY_ADMIN_REQUEST,
  EDIT_CANDIDATE_BY_ADMIN_SUCCESS,
  EDIT_CANDIDATE_BY_ADMIN_FAILURE,
} from "./actionType";

import { editCandidateByAdminService } from "../../config/services/employeeServices";
import { getInterviewList } from "./getInterviewListAction";

const editCandidateByAdmin = (data, query, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader) => {
  return (dispatch) => {
    dispatch(editCandidateByAdminRequest());
    editCandidateByAdminService(data)
      .then((res) => {
        if (res.data.statusCode !== 1) {
          dispatch(editCandidateByAdminFailure(res.data));
          setFailResMsg(true)
          setLoader(false)

        } else {
          dispatch(editCandidateByAdminSuccess(res.data));
          setResMsg(true)
          setTimeout(() => (
            dispatch(getInterviewList(query)),
            handleCancelButtonClick(),
            setLoader(false)
          ), 3000);


        }
      })
      .catch((error) => {
        dispatch(editCandidateByAdminFailure(error));
        setErrorText("Failed to edit");
        setFailResMsg(true)
        setLoader(false)
      });
  };
};

const editCandidateByAdminRequest = () => {
  return {
    type: EDIT_CANDIDATE_BY_ADMIN_REQUEST,
  };
};
const editCandidateByAdminSuccess = (data) => {
  return {
    type: EDIT_CANDIDATE_BY_ADMIN_SUCCESS,
    payload: data,
  };
};
const editCandidateByAdminFailure = (error) => {
  return {
    type: EDIT_CANDIDATE_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  editCandidateByAdmin,
  editCandidateByAdminRequest,
  editCandidateByAdminSuccess,
  editCandidateByAdminFailure,
};

import {
  GET_EMP_PROFILE_BY_ADMIN_REQUEST,
  GET_EMP_PROFILE_BY_ADMIN_SUCCESS,
  GET_EMP_PROFILE_BY_ADMIN_FAILURE,
} from "./actionType";

import { getAdminProfile } from "../../config/services/employeeServices";
import { currentYear } from "../../components/leaves/Constants";
import ResponseFilter from "../../ResponseFilter";
import { type } from "../../components/helper/Constant";

const getEmpProfileByAdmin = (props) => {
  let { useDispatch, id, setDispatch } = props;
  return (dispatch) => {
    dispatch(getEmpProfileByAdminRequest());
    getAdminProfile(id)
      .then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch(getEmpProfileByAdminSuccess(res.data.responseData));
          let totalLeaves = res.data?.responseData.employeeProfile?.totalLeaves;
          useDispatch({
            type: type,
            payload: {
              id: res.data?.responseData.employeeProfile?.employeeId,
              remainingMonthlyShortLeave:
                res.data?.responseData.employeeProfile
                  ?.remainingMonthlyShortLeave,
              tempCompOff:
                res.data?.responseData.employeeProfile?.temporaryCompOff,
              loader: false,
              compOffCount: res.data?.responseData.employeeProfile,
              profile: resData.employeeProfile,
            },
          });
          if (setDispatch) {
            setDispatch({
              type: type,
              payload: {
                compOffCount: totalLeaves
                  .filter((data) => data.year === currentYear)
                  .map((data) => data.comOff),
              },
            });
          }
          totalLeaves.map((data, i) => {
            data.year === currentYear &&
              useDispatch({
                type: type,
                payload: {
                  id: res.data?.responseData.employeeProfile?.employeeId,
                  employeeType:
                    res.data?.responseData.employeeProfile?.employeeType,
                  assignedCl: data.totalCl,
                  assignedEl: data.totalEarnedLeave,
                  assignedSl: data.totalSl,
                  totalCl: data.cl,
                  totalEl: data.earnedLeaves,
                  totalSl: data.sl,
                  totalCompOff: data.totalCompOff,
                  totalShortLeave: data.totalShortDayLeave,
                  totalLWP: data.lwp,
                  totalCompOffTaken: data.comOff,
                  tempCompOffCount: data?.tempCompOffCount
                    ? data?.tempCompOffCount
                    : 0,
                  assignedClientOff: data?.totalClientOff
                    ? data.totalClientOff
                    : 0,
                  assignedBereavementLeaves: 4,
                  assignedPaternalLeave:
                    resData.employeeProfile.gender === 2 ? 90 : 3,
                },
              });
          });
        } else {
          dispatch(getEmpProfileByAdminFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(getEmpProfileByAdminFailure(error));
      });
  };
};

const getEmpProfileByAdminRequest = () => {
  return {
    type: GET_EMP_PROFILE_BY_ADMIN_REQUEST,
  };
};

const getEmpProfileByAdminSuccess = (data) => {
  return {
    type: GET_EMP_PROFILE_BY_ADMIN_SUCCESS,
    payload: data,
  };
};

const getEmpProfileByAdminFailure = (error) => {
  return {
    type: GET_EMP_PROFILE_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  getEmpProfileByAdmin,
  getEmpProfileByAdminRequest,
  getEmpProfileByAdminSuccess,
  getEmpProfileByAdminFailure,
};

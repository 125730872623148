import React from 'react'
import { Modal } from "react-bootstrap"
export default function DeleteHoldEmployee(props) {
    return (
        <Modal show={props.show} onHide={props.handleClose} centered>
            <Modal.Body className="text-center confirmation">
                <p className="mb-4 cm_bold cnfrm_msg">Are you sure, you want to delete?</p>
                <div className='submit_button_margin mt-4'>
                    <button className="btn btn-danger cm_btn3" onClick={props.handleDelete}>Delete</button>
                    <button type="button" onClick={props.handleClose} className="btn btn-primary cm_btn2">Cancel</button></div>
            </Modal.Body>
        </Modal>
    )
}

import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Select } from 'antd'
import { DatePicker, Modal as AntModal } from 'antd'
import _ from "lodash";
import moment from 'moment'
const { Option } = Select
function disabledDate(current) {
    return current && current < moment().startOf("day");
}

export default function EditDeviceUnderRepairingModal(props) {
    const { show, handleClose, addDeviceRepairingID, addDeviceRepairingIDErrorMsg, sendingAmount, sendingAmountErrorMsg, recevingAmount, recevingAmountErrorMsg, sendingDate, sendingDateErrorMsg, issueDescription, issueDescriptionErrorMsg, recevingDate, recevingDateErrorMsg, handleChange, handleAddUnderRepairingSendingDate, handleAddUnderRepairingRecevingDate, deviceListToAddUnderRepairing, handleAddDeviceRepairingID, handleSubmit, deviceRepairingStatus, handleEditDeviceRepairingStatus } = props
    return (
        <AntModal
            centered
            className="sm_modal1"
            footer={false}
            visible={show}
            onCancel={handleClose}>
            <Modal.Body className={`assign_device ${props.miniLoader ? "admin_attendance" : ""}`}>
                <h3 className="mb-4">Edit Under Repairing Device</h3>
                <div className="row">
                    <div
                        className={`form-group col-lg-12 col-md-6 `}
                    >
                        <label className="cm_bold">Device Status</label>
                        <Select
                            showSearch
                            name="addDeviceRepairingID"
                            value={deviceRepairingStatus ? deviceRepairingStatus : "Device Status"}
                            optionFilterProp="key"
                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                            onChange={handleEditDeviceRepairingStatus}
                        >
                            <Option value={1}>Occupied</Option>
                            <Option value={2}>Free</Option>
                            <Option value={3}> Under Repairing</Option>
                            <Option value={4}> Not Working</Option>
                            <Option value={5}>Out Dated System</Option>
                            <Option value={6}>Archive</Option>
                        </Select>
                    </div>
                </div>
                <div className='row'>
                    <div
                        className={`form-group col-lg-6 col-md-6 ${sendingAmountErrorMsg ? "input_error" : ""
                            }`}
                    >
                        <label className="cm_bold">Sending Amount</label>
                        <input
                            maxLength="50"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Sending Amount"
                            name="sendingAmount"
                            value={sendingAmount ? sendingAmount : ""}
                            onChange={handleChange}
                        />
                        {sendingAmountErrorMsg ? (
                            <span className="error_msg">{sendingAmountErrorMsg}</span>
                        ) : null}
                    </div>
                    <div className={`form-group col-lg-6 col-md-6 ${recevingAmountErrorMsg ? "input_error" : ""} `}>
                        <label className="cm_bold">Received Amount</label>
                        <input
                            maxLength="50"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Receiving Amount"
                            name="recevingAmount"
                            value={recevingAmount ? recevingAmount : ""}
                            onChange={handleChange}
                        />
                        {recevingAmountErrorMsg ? (
                            <span className="error_msg">{recevingAmountErrorMsg}</span>
                        ) : null}
                    </div>
                </div>
                <div className='row'>
                    <div
                        className={`form-group col-lg-6 col-md-6 ${sendingDateErrorMsg ? "input_error" : ""
                            }`}
                    >
                        <label className="cm_bold">Sending Date</label>

                        <DatePicker
                            value={sendingDate ? moment(sendingDate) : null}
                            allowClear={false}
                            // disabledDate={disabledDate}
                            className="date-border"
                            onChange={handleAddUnderRepairingSendingDate}
                        />
                        {sendingDateErrorMsg ? (
                            <span className="error_msg">{sendingDateErrorMsg}</span>
                        ) : null}
                    </div>
                    <div className={`form-group col-lg-6 col-md-6 ${recevingDateErrorMsg ? "input_error" : ""} `}>
                        <label className="cm_bold">Received Date</label>
                        <DatePicker
                            value={recevingDate ? moment(recevingDate) : ""}
                            allowClear={false}
                            disabledDate={disabledDate}
                            className="date-border"
                            onChange={handleAddUnderRepairingRecevingDate}
                        />
                        {recevingDateErrorMsg ? (
                            <span className="error_msg">{recevingDateErrorMsg}</span>
                        ) : null}
                    </div>
                </div>
                <div className='row'>
                    <div className={`form-group col-lg-12 col-md-12 ${issueDescriptionErrorMsg ? "input_error" : ""} `}>
                        <label className="cm_bold">Issue Description</label>
                        <textarea
                            maxLength="500"
                            className="form-control cm_box"
                            type="text"
                            placeholder="Issue Description"
                            name="issueDescription"
                            value={issueDescription}
                            onChange={handleChange}
                        />
                        {issueDescriptionErrorMsg ? (
                            <span className="error_msg">{issueDescriptionErrorMsg}</span>
                        ) : null}
                    </div>
                </div>
                <div className="submit_button_margin mt-4">
                    <button type="button" className="btn btn-primary cm_btn3" onClick={handleSubmit}>
                        Save
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary cm_btn2"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </AntModal >
    )
}

import React, { useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


export default function TextEditor(props) {
    return (
        <div className='' >
            {/* {console.log(
                convertToRaw(props.editorState.getCurrentContent()).blocks, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")}


            {console.log(draftToHtml(convertToRaw(props.editorState.getCurrentContent())), "11111111111111111111111111")} */}
            <Editor
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                editorState={props.editorState}
                onEditorStateChange={(e) => props.onEditorStateChange(e)}
                toolbarHidden
            />
        </div>
    )
}

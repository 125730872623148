import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { PDFReader } from "reactjs-pdf-reader-custom";
import { Modal as AntModal } from "antd";
import "../Privacy.css";

const ViewUploadedPdf = (props) => {
  const { show, doc, closeModal } = props;
  const [loading, setLoading] = useState(true);

  return (
    <AntModal
      title=""
      footer={false}
      centered
      className="sm_modal1"
      visible={show}
      onCancel={closeModal}
    >
      <Modal.Body
        className={`view_pdf ${
          loading && " d-flex justify-content-center align-items-center"
        } `}
      >
        {loading && (
          <div class="snippet" data-title="dot-elastic">
            <div class="stage">
              <div class="dot-elastic"></div>
            </div>
          </div>
        )}
        {doc && (
          <PDFReader
            url={doc}
            showAllPage={true}
            onDocumentComplete={() => setLoading(false)}
            width="700"
          />
        )}
      </Modal.Body>
      <Modal.Footer className="submit_button_margin">
        <div className="mt-4">
          <button
            type="button"
            onClick={closeModal}
            className="btn btn-primary cm_btn2"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </AntModal>
  );
};

export default ViewUploadedPdf;

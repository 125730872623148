import React from "react";
import { Modal } from "react-bootstrap";

export function OpenLeaveModal(props) {
  const { show, handleClose, miniLoader, resLeaveActionFail, handleLeave } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Deduct Leave</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`assign_device ${
          miniLoader ? "admin_attendance" : ""
        }`}
      >
        <p className="mb-4 cm_bold cnfrm_msg">
          The Employee is either late or has not completed 9 hours.
          <br />
          Do you want to deduct his/her leave?
        </p>

        <div className="submit_button_margin mt-4 edit_appraisal ">
          <p className="red">{resLeaveActionFail}</p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          {miniLoader && <div className="cover-spin1"></div> }
          <button
            type="button"
            className="btn btn-success btn-sm rej_btn"
            onClick={() => handleLeave(2)}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm rej_btn"
            onClick={handleClose}
          >
            No
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

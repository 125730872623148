import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { getVendorList } from "../../redux/actions";
import { Loader } from "../svg";
import { foodVendor } from "../../asset";

const FoodOrdersListAdmin = ({ vendorList, getVendorList }) => {
  useEffect(() => {
    getData();
  }, []);
  const history = useHistory();
  const getData = async () => {
    await getVendorList();
  };
  return (
    <div className="body_container cm_bold marginTop">
      <h3 className="mb-4">VENDOR LIST</h3>
      <div className="vendorCardsDiv">
        {vendorList ? (
          vendorList.length > 0 ? (
            vendorList.map((element) => {
              return (
                <div
                  className="col-xl-6 col-lg-6"
                  onClick={() => {
                    history.push(`/vendors/vendorOrders/${element.vendorId}`, {
                      vendorId: element.vendorId,
                      vendorName: element.name,
                    });
                    localStorage.setItem("vendorId", element.vendorId)
                  }}
                >
                  <div className="cm_card  card vendorCard">
                    <div>
                      <img
                        src={element.image ? element.image : foodVendor}
                        alt="vendorImage"
                        className="vendorImage"
                      />
                    </div>

                    <div className="vendor-card-details-div">
                      <p className="blue">{element.name}</p>
                      <div>
                        <p className="green">Phone no : {element.phoneNo}</p>
                        <p className="purple">Email : {element.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            "No data available"
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    vendorList: state.vendorList.data?.vendorList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorList: () => {
      dispatch(getVendorList());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoodOrdersListAdmin);

import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { addEmpResumeByAdmin } from "../../redux/actions";
import { Loader } from "../svg";
import { Select } from "antd";
import _ from "lodash";
import { Toastify } from "../helper/Constant";
const { Option } = Select

const AdminAddResModal = ({
  showModal,
  setShowModal,
  addEmpResumeByAdmin,
  addEmployeeResume,
  employeeList,
  empResumeId,
  getData,
}) => {
  const [title, setTitle] = useState("");
  const [resume, setResume] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [state, setState] = useState({ resMsg: false, miniLoader: false, resFailMessage: false, responseMessage: "" })


  const location = useLocation();
  const empId = empResumeId ? empResumeId : "";
  const [employeeId, setEmployeeId] = useState("")
  const handleCancelButtonClick = () => {
    setShowModal(false);
    setTitle(null);
    setResume(null);
    setErrorText(null);
    setEmployeeId("")
  };

  const handleResumeChange = (param) => {
    setResume(param.target.files[0]);
    setErrorText(null)
    fileValidation();
  }

  const handleUploadButtonClick = async () => {
    if (title && resume && (employeeId || empId)) {
      setState({ miniLoader: true })
      const data = new FormData();
      data.append("title", title.trim());
      data.append("resume", resume);
      data.append("employeeId", employeeId ? employeeId : empId);
      await addEmpResumeByAdmin(data, empId, setState, handleCancelButtonClick, getData);
    } else {
      setErrorText("All fields are required");
    }
  };

  const fileValidation = () => {
    var fileInput = document.getElementById("file");

    var filePath = fileInput.value;

    var allowedExtensions = /(\.doc|\.docx|\.pdf|\.txt)$/i;

    if (!allowedExtensions.exec(filePath)) {
      setResume(null);
      setErrorText("Invalid file !");
      fileInput.value = "";
      return false;
    } else if (fileInput.files[0].size >= 10485760) {
      setErrorText("please insert image of less then 10 mb");
      setResume(null)
      fileInput.value = "";
      return false
    }
  };
  if (addEmployeeResume.loading) return (
    //  <Loader />
    state.resMsg ?
      <div className="alert alert-success cm_top_fix">Resume added successfully</div> : ''

  );
  const handleSelectEmployeeChange = (e) => {
    setEmployeeId(e)
    setErrorText(null)
  }
  return (
    <>
      <Toastify
        resMsg={state.resMsg}
        resMsgFail={state.resFailMessage}
        text={"Resume Upload"}
        responseMessage={state.responseMessage}
      />
      <Modal show={showModal} onHide={handleCancelButtonClick}>
        <Modal.Header closeButton>
          <Modal.Title>Enter the details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mr-4">Add Resume</p>
          <div>
            <Select
              showSearch
              name="employeeId"
              value={employeeId ? employeeId : empId ? empId : " Select Employee"}
              defaultValue="Select Name"
              onChange={handleSelectEmployeeChange}
              optionFilterProp="key"
              filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
              className="mb-2"
              disabled={empId}
            >
              {!_.isEmpty(employeeList)
                ? employeeList.sort(function (a, b) {
                  if (a.firstName < b.firstName) { return -1; }
                  if (a.firstName > b.firstName) { return 1; }
                  return 0;
                }).map(
                  (employee) =>
                    employee.status === 1 && (
                      <Option
                        key={`${employee.firstName} ${employee.lastName}`}
                        value={employee.employeeId}
                      >
                        <span>
                          {employee.firstName} {employee.lastName} ({employee.empId})
                        </span>
                      </Option>
                    )
                )
                : ""}
            </Select>
            <InputField
              placeholder="Enter Title"
              value={title}
              onChange={(param) => {
                setTitle(/^\s/.test(param.target.value) ? "" : param.target.value); setErrorText(null)
              }}
            />
            <InputField
              type="file"
              id="file"
              accept="application/pdf,.doc, .docx"
              placeholder="Enter Title"
              onChange={handleResumeChange}
            />
          </div>
          <ErrorText>{errorText}</ErrorText>
          <div className="modalButtonsDiv">
            {state.miniLoader ? <div className="mini_loader_dept"></div> : ""}
            <Button
              backgroundColor="grey"
              className="mr-2"
              color="black"
              onClick={handleCancelButtonClick}
            >
              Cancel
            </Button>
            <Button onClick={handleUploadButtonClick}
              disabled={state.miniLoader}
            >Upload</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    addEmployeeResume: state.addEmployeeResume,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addEmpResumeByAdmin: (data, empId, setState, handleCancelButtonClick, getData) => {
      dispatch(addEmpResumeByAdmin(data, empId, setState, handleCancelButtonClick, getData));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminAddResModal);

const InputField = styled.input`
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 0.5rem;
  border: 1px solid gray;
`;
const Button = styled.button`
  background-color: ${(props) => props.backgroundColor || "rgb(52, 123, 222)"};
  cursor: pointer;
  width: 100px;
  border: none;
  padding: 0.4rem;
  border-radius: 10px;
  color: ${(props) => props.color || "white"};
  font-size: medium;
`;
const ErrorText = styled.p`
  color: red;
  text-align: center;
`;

import { isRequired } from "../helper/String";

export const handleValidation = (state, setState) => {
  let validate = true;
  const {
    expenseDescription,
    amount,
    expenseDate,
    uploadBill,
    uploadFileName,
    title,
    uploadFileSize,
    projectManagerId,
    reimbursementType,
    currencyType,
  } = state;
  const regMatch = /^[^0-9][a-zA-Z0-9_ ]+$/.test(title);
  const RegExp = /^\d*\.?\d{0,2}$/;
  let neg = [];
  for (let i in uploadFileName) {
    if (
      uploadFileName[i].match(
        /\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|doc|docs|docx|.odt|dox|.ppt|xls|xlxs|xlsx)$/
      )
    )
      neg.push(uploadFileName[i]);
  }
  if (
    reimbursementType === 0 ||
    reimbursementType === "" ||
    reimbursementType === undefined
  ) {
    validate = false;
    setState({
      reimbursementTypeErrMsg: `Reimbursement type ${isRequired}`,
    });
  }
  if (currencyType === "" || currencyType === undefined) {
    validate = false;
    setState({
      currencyTypeErrMsg: `Currency ${isRequired}`,
    });
  }
  if (title === "" || title === undefined) {
    validate = false;
    setState({
      titleErrorMsg: `Title${isRequired}`,
    });
  } else if (!regMatch) {
    validate = false;
    setState({
      titleErrorMsg:
        "Title cannot start with a number and cannot have special characters",
    });
  } else {
    setState({ titleErrorMsg: "" });
  }
  if (expenseDescription === "" || expenseDescription === undefined) {
    validate = false;
    setState({
      expenseDescriptionErrorMsg: `Expense description${isRequired}`,
    });
  } else {
    setState({ expenseDescriptionErrorMsg: "" });
  }

  if (amount === "" || amount === undefined) {
    validate = false;
    setState({ amountErrorMsg: `Claim amount${isRequired}` });
  } else if (amount < 1) {
    validate = false;
    setState({ amountErrorMsg: "Please enter valid amount value" });
  } else if (RegExp.test(amount) == false) {
    validate = false;
    setState({
      amountErrorMsg: "you can enter two digit after Decimal",
    });
  } else if (amount > 200000) {
    validate = false;
    setState({ amountErrorMsg: "Amount cannot be more than 2 Lakh" });
  } else {
    setState({ amountErrorMsg: "" });
  }

  if (expenseDate === "" || expenseDate === undefined) {
    validate = false;
    setState({ expenseDateErrorMsg: `Expense date${isRequired}` });
  } else {
    setState({ expenseDateErrorMsg: "" });
  }

  if (
    uploadBill === "" ||
    uploadBill === undefined ||
    uploadBill.length === 0
  ) {
    validate = false;
    setState({ uploadBillErrorMsg: `Bill${isRequired}` });
  } else if (neg.length !== 0) {
    validate = false;
    setState({ uploadBillErrorMsg: "Please Provide Valid File" });
  } else if (uploadBill.length > 10) {
    validate = false;
    setState({ uploadBillErrorMsg: "Please Add Only 10 Files" });
  } else if (uploadFileSize >= 10485760) {
    validate = false;
    setState({
      uploadBillErrorMsg: "please insert file of less then 10 mb",
    });
  } else {
    setState({ uploadBillErrorMsg: "" });
  }
  if (projectManagerId === "" || projectManagerId === undefined) {
    validate = false;
    setState({
      projectManagerNameErMsg: `Assigned manager${isRequired}`,
    });
  } else {
    setState({
      projectManagerNameErMsg: "",
    });
  }
  return validate;
};

export const colorType = (type) => {
  switch (type) {
    case 1:
      return "yellow";
    case 2:
      return "text-success";

    default:
      return "text-danger ";
  }
};

export const statusValue = (type) => {
  switch (type) {
    case 1:
      return "Pending";
      break;
    case 2:
      return "Approved";
      break;
    case 3:
      return "Rejected";
  }
};

export const CURRENCY = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
  { value: "SAR", label: "SAR" },
];

export const ReimbursementTypeConstant = (type) => {
  switch (type) {
    case 1:
      return "Regular";
    case 2:
      return "Tax Saving";
  }
};

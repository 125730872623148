const CONSTANTS = {
  TRANSACTION_LIST_CONSTANTS: {
    ARRAY_OF_MONTHS: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    AMOUNT_TYPE: ["", "Credited", "Debited"],
    TRANSACTION_TYPE: [
      "",
      "Auto credit daily coupon code",
      "Added by admin",
      "Order debit",
      "Auto debit (daily coupon amount)",
      "Amount debit by admin",
      "Order credit amount on cancel",
      "Order Accepted & Amount credited to vendor",
    ],
  },
  VENDOR_ORDER_LIST_CONSTANTS: {
    ORDER_STATUS: [
      "All Orders",
      "Pending",
      "Accepted",
      "Completed",
      "Rejected",
      "Cancelled",
    ],
  },
  SORT_TYPE_CONSTANTS: {
    "Asscending": 1,
    "Descending": 2,
  },
  TAB_STATUS_CONSTANTS: {
    STATUS: {
      "Empty": "",
      "zero": 0,
      "one": 1,
      "two": 2,
      "three": 3,
      "four": 4,
      "five": 5,
      "six": 6,

    }
  },


  // SORT_FIELD_CONSTANTS:{
  //   {

  //   }
  // },
  INTERVIEW_SECTION: {
    SKILL_RATING: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    INTERVIEW_STATUS: ["Selected", "Rejected", "Pending"],
    TECHNICAL_SKILL: ["React.js", "Node.js", "Flutter", "React Native", "ios", "Android", "Java", " HR Manager", "UI", "UX", "Quality Analyst", "Sales and Marketing", "Python", "R.O.R", "Next.js", "Nest.js", "Accounts", "Devops", "Graphic Design And Animation", "Angular", "MERN", "MEAN", 'Kotlin', "Vue.js", "Drupal", "BlockChain", "AI", "ML", "C", "C++", "C#", ".Net", "PHP", "Wordpress", "SQL", "Git", "Javscript", "Swift", "Ruby", "GoLang", "Visual Basic", "Project Manager", "Recruiting Manager", "Salesforce", "Bootstrap", "Material Ui", "Tailwind CSS", "CSS", "Selenium", "Cypress", "EsLint", "AWS", "Azure", "Google Cloud Platfrom", "My SQL", "Mongo DB", "PostgreSQL", "Oracle", "Redis", "Dynamo DB", "Typescript", "Javascript", "Wordpress", "Drupal", "Devops", "Django"]
  },
};

export const officeLocationValue = (type) => {
  switch (type) {
    case 1:
      return "Noida";
      break;
    case 2:
      return "Lucknow";
      break;
  }
};

export const clientSideValue = (type) => {
  switch (type) {
    case 1:
      return "Yes";
      break;
    case 2:
      return "No";
      break;
  }
};

export const projectTypeValue = (type) => {
  switch (type) {
    case 1:
      return "ManMonth";
      break;
    case 2:
      return "Hourly";
      break;
    case 3:
      return "Delivery Baed";
      break;
  }
}

export const getFileNameFromUrl = (url) => {
  const urlObject = new URL(url);
  const pathname = urlObject.pathname;
  const pathSegments = pathname.split('/');
  const fileName = pathSegments.pop();
  return fileName;
}

export const deviceWarrentyStatus = (type) => {
  switch (type) {
    case 1:
      return "Yes";
      break;
    case 2:
      return "No";
      break;
  }
};

export default CONSTANTS;


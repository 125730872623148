import React from "react";
import { Modal } from "react-bootstrap";
import {
  ErrorMessage,
  handleFileUpload,
  handleTextChange,
} from "../../helper/Constant";
import { handleAddEdit } from "../PolicyUtils/HandleSubmit";
import { extractFilename } from "../PolicyUtils/PolicyConstant";

const AddEditPolicyModal = (props) => {
  const {
    openAddEditPrivacyModal,
    toAddOrEdit,
    closeModal,
    titleErrorMsg,
    uploadFile,
    uploadFileErrorMsg,
    title,
    dispatch,
    getPolicyList,
    privacyId,
    editTitle,
    docLink,
  } = props;
  return (
    <Modal
      className="cm_ticket_status"
      show={openAddEditPrivacyModal}
      onHide={closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header className="text-center confirmation">
        <p className="mb-4 cm_bold cnfrm_msg">{toAddOrEdit} Policy</p>
      </Modal.Header>
      <Modal.Body>
        <label className={`sub mt-5"}`}>Title</label>
        <div className={`form-group ${titleErrorMsg && "input_error"}`}>
          <input
            className="form-control cm_box"
            maxLength="30"
            type="text"
            placeholder="Title"
            name="title"
            value={title}
            onChange={(e) => handleTextChange({e, dispatch, toAddOrEdit})}
            autoComplete="off"
          />
          <ErrorMessage errMsg={titleErrorMsg} />
        </div>
        <label className={`sub mt-5"}`}>Upload Policy</label>
        <div className={`form-group ${uploadFileErrorMsg && "input_error"}`}>
          <span className="form-control cm_hide_upload">
            <i className="fa fa-upload" aria-hidden="true"></i>
            <span className="cm_upload_name">
              {docLink && !uploadFile
                ? extractFilename(docLink)
                : uploadFile && uploadFile.name
                ? uploadFile.name
                : "Upload Policy"}
            </span>
            <input
              type="File"
              accept=" .pdf"
              className=""
              title="uploadFile"
              name="uploadFile"
              id="upload-files"
              onChange={(e) => handleFileUpload(e, dispatch)}
            />
          </span>
          <ErrorMessage errMsg={uploadFileErrorMsg} />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          <button
            className="btn btn-secondary cm_btn3"
            onClick={(e) =>
              handleAddEdit({
                e,
                dispatch,
                toAddOrEdit,
                title,
                uploadFile,
                closeModal,
                getPolicyList,
                privacyId,
                editTitle,
                docLink,
              })
            }
          >
            {toAddOrEdit}
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="btn text-secondary border border-dark cm_btn3"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditPolicyModal;

import React from "react";
import { monthNames } from "../../helper/Constant";
import { LEAVE_ARRAY } from "../../leaves/Constants";
import { Link } from "react-router-dom";
import dashboardImg from "../../../asset/imgs/office1.png";
import "../Dashboard.css";
const DashboardFirstSection = (props) => {
  const {
    holidaysList,
    leaveTypeErr,
    leaveType,
    handleInputChange,
    clearSelection,
    startDateErr,
    handleLeaveSubmit,
    startDate,
  } = props;
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <div>
      <div className="d-flex row">
        <div className="row mx-1">
          <div class="emp_dashboard_card ">
            <div className="emp_dashboard_card_header d-flex align-items-center">
              <i class="fa fa-calendar fa-2x" aria-hidden="true" />
              <p class="emp_dashboard_card_title">Upcoming Holidays</p>
            </div>
            <div class="emp_dashboard_card_body mt-4">
              {holidaysList
                .flatMap((item) => item.holidays)
                .slice(0, 4)
                .sort((a, b) => a.date - b.date)
                .map((holiday, holidayIndex) => (
                  <React.Fragment key={holidayIndex}>
                    <div className="d-flex justify-content-between">
                      <p className="emp_dashboard_card_text">
                        {holiday.holidayName}
                      </p>
                      <p className="emp_dashboard_card_text">
                        {`${
                          monthNames[new Date(holiday.date).getMonth()]
                        } ${new Date(holiday.date).getDate()}, ${new Date(
                          holiday.date
                        ).getFullYear()}`}
                      </p>
                    </div>
                    <span className="card-separator mt-3 mb-3"></span>
                  </React.Fragment>
                ))}
              <Link className="card-link" to={{ pathname: "/holiday" }}>
                View Holiday Calendar
              </Link>
            </div>
          </div>
          <div class="emp_dashboard_card">
            <div className="emp_dashboard_card_header d-flex align-items-center">
              <i class="fa fa-clock-o fa-2x" aria-hidden="true" />
              <p class="emp_dashboard_card_title">Apply Leave</p>
            </div>
            <div class="emp_dashboard_card_body mt-3">
              <div className="leave_main">
                <p>Apply for</p>
                <select
                  className={`dashboard_leave_input ${
                    leaveTypeErr && "border-danger"
                  } `}
                  aria-label="Select Leave"
                  name="leaveType"
                  value={leaveType || ""}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select Leave
                  </option>
                  {LEAVE_ARRAY.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {leaveType && (
                  <span className=" position-relative" onClick={clearSelection}>
                    <i class="fa fa-times icon_clear" aria-hidden="true" />
                  </span>
                )}
              </div>
              <div className="leave_main">
                <p>Apply on</p>
                <input
                  type="date"
                  name="leaveDate"
                  value={startDate ? startDate : ""}
                  className={`dashboard_leave_input ${
                    startDateErr && "border-danger"
                  } `}
                  min={currentDate}
                  onChange={handleInputChange}
                />
                <button
                  className="dashboard_leave_btn"
                  onClick={handleLeaveSubmit}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        <img alt="" src={dashboardImg} className="dashboard_icon" />
      </div>
    </div>
  );
};

export default DashboardFirstSection;

import { STATUS_VALUE_ZERO, STATUS_ZERO } from "../../components/attendance/Constants";
import { addMembers } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    ADD_TEAM_MEMBERS_REQUEST,
    ADD_TEAM_MEMBERS_SUCCESS,
    ADD_TEAM_MEMBERS_FAILURE,
} from "./actionType";


const addTeamMembersByAdminAction = (props) => {
    let { useDispatch, getTeamMembers, params, closeModal } = props
    return (dispatch) => {
        dispatch(addTeamMembersByAdminRequest());
        addMembers(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamMsg: true,
                        successMessage: "Team Members Added",
                        loader: false,
                        multiValue: [],
                        reId: [],
                    }
                })
                getTeamMembers()
                closeModal()
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamMsg: false
                    }
                }), 3000);
            } else if (res.data.statusCode === STATUS_VALUE_ZERO) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        responseMessage: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resTeamFailMsg: true,
                        loader: false
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamFailMsg: false
                    }
                }), 3000);
                getTeamMembers()
            }
        })
            .catch((error) => {
                dispatch(addTeamMembersByAdminFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const addTeamMembersByAdminRequest = () => {
    return {
        type: ADD_TEAM_MEMBERS_REQUEST,
    };
};
const addTeamMembersByAdminSuccess = (data) => {
    return {
        type: ADD_TEAM_MEMBERS_SUCCESS,
        payload: data,
    };
};
const addTeamMembersByAdminFailure = (error) => {
    return {
        type: ADD_TEAM_MEMBERS_FAILURE,
        payload: error,
    };
};
export {
    addTeamMembersByAdminAction,
    addTeamMembersByAdminRequest,
    addTeamMembersByAdminSuccess,
    addTeamMembersByAdminFailure,
};
import { getTransactionExcelSheetService } from "../../config/services/employeeServices";
import { GET_TRANSACTION_EXCEL_FAILURE,  GET_TRANSACTION_EXCEL_REQUEST , GET_TRANSACTION_EXCEL_SUCCESS} from "./actionType";

const getTransactionExcelList = (excelSheetData) => {
    return (dispatch) => {
      dispatch(getTransactionExcelSheetRequest());
      getTransactionExcelSheetService(excelSheetData)
        .then((res) => {
         
          // if (res.data.statusCode !== 1) {
          //   dispatch(getTransactionExcelSheetFailure(res.data));

          // } else {
            
          //   dispatch(getTransactionExcelSheetSuccess(res.data.responseData));
          // }
          //////////////////////////
          if(res.data.statusCode === 1){
            dispatch(getTransactionExcelSheetSuccess(res.data.responseData));
          }
          if(res.data.statusCode === 0){
            dispatch(getTransactionExcelSheetFailure(res.data.responseData));
          }
          if(res.data.statusCode === 2){
            dispatch(getTransactionExcelSheetRequest(res.data));
          }
///////////////////////////////////////////////////////
        })
        .catch((error) => {
          
          dispatch(getTransactionExcelSheetFailure(error));
        });
    };
  };

const getTransactionExcelSheetRequest = () => {
return {
    type: GET_TRANSACTION_EXCEL_REQUEST,
};
};

const getTransactionExcelSheetSuccess = (data) => {
 
    return {
      type: GET_TRANSACTION_EXCEL_SUCCESS,
      payload: data,
    };
  };

const getTransactionExcelSheetFailure = (error) => {
    return {
      type: GET_TRANSACTION_EXCEL_FAILURE,
      payload: error,
    };
  };

export {
    getTransactionExcelList,
    getTransactionExcelSheetRequest,
    getTransactionExcelSheetSuccess,
    getTransactionExcelSheetFailure,

};
  
import { SET_ADMIN_REIMBURSEMENT_YEAR_SUCCESS } from "../actions";

const initialState = {
  loading: false,
  data: new Date().getFullYear(),
  error: "",
};

const setAdminReimbursementYearReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_REIMBURSEMENT_YEAR_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };

    default:
      return state;
  }
};
export default setAdminReimbursementYearReducer;

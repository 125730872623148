import React, { useEffect, useMemo, useReducer } from "react";
import moment from "moment";
import _ from "lodash";
import Pagination from "react-js-pagination";
import ResponseFilter from "../../ResponseFilter";
import {
  employeeViewTicket,
  reopenTicket,
  getEmployeeListByManager,
} from "../../config/services/employeeServices";
import { Loader } from "../svg";
import { CONSTANTS } from "../../config";
import {
  categoryType,
  colorType,
  createEditorStateFromHTML,
  handleSubmit,
  statusValue,
} from "./Constants";
import StatusInfoModal from "./Modal/StatusInfoModal";
import AddTicketModal from "./Modal/AddTicketModal";
import { Toastify, reducer, type } from "../helper/Constant";
import DeleteTicketModal from "./Modal/DeleteTicketModal";
import ViewTicketReasonModal from "./Modal/ViewTicketReasonModal";
import "./Ticket.css";
import { EditorState } from "draft-js";
import EditTicketModal from "./Modal/EditTicketModal";
import qs from "qs";
import { useHistory } from "react-router-dom";
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;

const initialState = {
  openAddTicketPopup: false,
  openStatusInfoPopup: false,
  ticketDetails: [],
  status: "",
  loader: true,
  totalTickets: "",
  pageNo: 1,
  count: 10,
  ticketId: "",
  reply: "",
  emailError: "",
  emails: [],
  descriptionErrorMsg: "",
  subjectErrorMsg: "",
  categoryErrorMsg: "",
  sortType: SORT_TYPE_CONSTANTS.Descending,
  sortField: "created",
  resMsg: false,
  resFailMsg: false,
  inputEmail: "",
  responseMessage: "",
  employeeList: [],
  subject: "",
  description: "",
  editorState: EditorState.createEmpty(),
  category: "",
  openDeleteTicket: false,
  delData: "",
  successMessage: "",
  openViewTicketReason: false,
  openEditModal: false,
};

const AddTicket = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    openAddTicketPopup,
    openStatusInfoPopup,
    ticketDetails,
    status,
    loader,
    totalTickets,
    pageNo,
    count,
    ticketId,
    reply,
    emailError,
    emails,
    descriptionErrorMsg,
    subjectErrorMsg,
    categoryErrorMsg,
    sortType,
    sortField,
    resMsg,
    resFailMsg,
    responseMessage,
    employeeList,
    subject,
    description,
    category,
    openDeleteTicket,
    delData,
    successMessage,
    openViewTicketReason,
    editorState,
    openEditModal,
  } = state;
  const handlePageChange = (pageNo) => {
    dispatch({ type: type, payload: { pageNo, loader: true } });
  };

  const history = useHistory();

  const handleOpenAddTicketPopup = () => {
    dispatch({
      type: type,
      payload: {
        openAddTicketPopup: true,
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        value: [],
        openAddTicketPopup: false,
        openStatusInfoPopup: false,
        description: "",
        subject: "",
        category: "",
        reply: "",
        emails: [],
        descriptionErrorMsg: "",
        subjectErrorMsg: "",
        categoryErrorMsg: "",
        emailError: "",
        openDeleteTicket: false,
        delData: "",
        openViewTicketReason: false,
        editorState: EditorState.createEmpty(),
        openEditModal: false,
      },
    });
  };

  const handleEmailChange = (e) => {
    dispatch({ type: type, payload: { emailError: "", emails: e } });
  };

  const handleChange = (e) => {
    dispatch({
      type: type,
      payload: {
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
        [e.target.name + "ErrorMsg"]: "",
      },
    });
  };

  const handleCategoryChange = (e) => {
    dispatch({
      type: type,
      payload: { category: e, categoryErrorMsg: "" },
    });
  };

  const getViewTicket = () => {
    const { pageNo, count, sortType, sortField } = state;
    const params = `count=${count}&pageNo=${
      pageNo - 1
    }&sortField=${sortField}&sortType=${sortType}`;
    props.history.push(
      `addTicket?&pageNo=${pageNo}&sortField=${sortField}&sortType=${sortType}`
    );
    employeeViewTicket(params)
      .then((res) => {
        const { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch({
            type: type,
            payload: {
              ticketDetails: resData.ticketDetails,
              totalTickets: resData.totalTickets,
              loader: false,
            },
          });
          closeModal();
        } else {
          dispatch({ type: type, payload: { loader: false } });
        }
      })
      .catch((error) => {
        dispatch({
          type: type,
          payload: {
            resFailMsg: true,
            responseMessage: error,
            loader: false,
          },
        });
      });
    setTimeout(() => {
      dispatch({ type: type, payload: { resFailMsg: false } });
    }, 3000);
  };

  const getEmployeeList = () => {
    getEmployeeListByManager("").then((res) => {
      const { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: { employeeList: res.data.responseData.employeesList },
        });
      }
    });
  };

  const handleReopenSubmit = (event) => {
    event.preventDefault();
    const obj = { ticketId: state.ticketId, status: 3 };

    reopenTicket(obj).then((res) => {
      if (res.data.statusCode === 1) {
        getViewTicket();
      }
    });
  };

  const handleOpenStatusInfoPopup = (data) => {
    const { description, subject, status, reply, ticketId } = data;
    dispatch({
      type: type,
      payload: {
        openStatusInfoPopup: false,
        subject,
        description,
        status,
        reply,
        ticketId,
      },
    });
  };

  const handleSort = (e) => {
    dispatch({
      type: type,
      payload: {
        loader: true,
        sortField: e,
        sortType:
          state.sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
        pageNo: 1,
      },
    });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    getViewTicket();
  }, [pageNo, sortType, sortField]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : "",
      },
    });
  }, [history.location.search]);

  const handleSelectEmployeeChange = (e) => {
    dispatch({
      type: type,
      payload: { employeeId: e, employeeIdErrorMsg: "" },
    });
  };
  const handleOpenDeleteTicketPopup = (data) => {
    dispatch({
      type: type,
      payload: { openDeleteTicket: true, delData: data.ticketId },
    });
  };

  const handleOpenEditTicketPopup = (data) => {
    if (data) {
      dispatch({
        type: type,
        payload: {
          openEditModal: true,
          subject: data.subject,
          employeeId: data.employeeId,
          editorState: createEditorStateFromHTML(data.description),
          category: data.category,
          ticketId: data.ticketId,
        },
      });
    }
  };
  const handleOpenViewReason = (data) => {
    dispatch({
      type: type,
      payload: { openViewTicketReason: true, description: data },
    });
  };

  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <Toastify
        resMsg={resMsg}
        resMsgFail={resFailMsg}
        text={successMessage}
        responseMessage={responseMessage}
      />
      <h3>VIEW TICKET</h3>
      <div className="text-right">
        <button
          className="btn btn-primary mb-3"
          onClick={handleOpenAddTicketPopup}
        >
          Create Ticket
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th>S.No</th>
              <th>
                Date
                <i
                  className={
                    sortType === SORT_TYPE_CONSTANTS.Descending &&
                    sortField === "date"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("date")}
                ></i>
              </th>
              <th>Category</th>
              <th>Subject</th>
              <th className="table_width6">Reason</th>
              <th className="table_width1">Status</th>
              <th className="table_width1">Action</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(ticketDetails)
              ? ticketDetails.map((data, i) => (
                  <tr key={data.ticketId}>
                    <td>{i + 1 + (pageNo - 1) * count}</td>
                    <td>{moment(data.created).format("DD/MM/YYYY")}</td>
                    <td>{categoryType(data.category)}</td>
                    <td>
                      <span className="cm_col">{data.subject}</span>
                    </td>
                    <td className="d-flex justify-content-center table_width6">
                      <button
                        class=" badge-info view_ticket_reason"
                        onClick={() => handleOpenViewReason(data.description)}
                      >
                        View
                      </button>
                    </td>
                    <td className={`${colorType(data.status)} table_width6`}>
                      <div
                        className="cm_ticketAction"
                        onClick={() => handleOpenStatusInfoPopup(data)}
                      >
                        {statusValue(data.status)}
                      </div>
                    </td>
                    <td className="table_width1">
                      {data.status === 1 ? (
                        <div className="d-flex justify-content-around">
                          <span
                            onClick={() => handleOpenDeleteTicketPopup(data)}
                            className="d-flex justify-content-center mt-2"
                          >
                            <i
                              className="fa fa-trash-o red"
                              title="Delete ticket"
                            ></i>
                          </span>
                          <span
                            onClick={() => handleOpenEditTicketPopup(data)}
                            className="d-flex justify-content-center mt-2"
                          >
                            <i
                              className="fa fa-pencil green"
                              title="Edit ticket"
                            ></i>
                          </span>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">_</div>
                      )}
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {ticketDetails.length ? (
          ""
        ) : (
          <h3 className="text-center">No data available</h3>
        )}
      </div>
      {(totalTickets > 10 ||
        (totalTickets < 11 && totalTickets > 0 && pageNo === 2)) && (
        <Pagination
          activePage={pageNo}
          itemsCountPerPage={count}
          totalItemsCount={totalTickets}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
        />
      )}
      <AddTicketModal
        openAddTicketPopup={openAddTicketPopup}
        categoryErrorMsg={categoryErrorMsg}
        subject={subject}
        subjectErrorMsg={subjectErrorMsg}
        emailError={emailError}
        emails={emails}
        handleEmailChange={handleEmailChange}
        employeeList={employeeList}
        descriptionErrorMsg={descriptionErrorMsg}
        description={description}
        handleChange={handleChange}
        closeModal={closeModal}
        handleSubmit={(e) =>
          handleSubmit(e, state, getViewTicket, closeModal, dispatch, false)
        }
        editorState={editorState}
        dispatch={dispatch}
        category={category}
        handleCategoryChange={handleCategoryChange}
        admin={false}
      />
      <StatusInfoModal
        openStatusInfoPopup={openStatusInfoPopup}
        closeModal={closeModal}
        handleReopenSubmit={handleReopenSubmit}
        subject={subject}
        description={description}
        reply={reply}
        status={status}
      />
      <DeleteTicketModal
        openDeleteTicket={openDeleteTicket}
        closeModal={closeModal}
        ticketId={delData}
        dispatch={dispatch}
        getViewTicket={getViewTicket}
      />
      <ViewTicketReasonModal
        openViewTicketReason={openViewTicketReason}
        closeModal={closeModal}
        description={description}
      />
      <EditTicketModal
        openEditModal={openEditModal}
        closeModal={closeModal}
        subject={subject}
        emailError={emailError}
        emails={emails}
        handleEmailChange={handleEmailChange}
        employeeList={employeeList}
        description={description}
        handleChange={handleChange}
        editorState={editorState}
        dispatch={dispatch}
        category={category}
        handleCategoryChange={handleCategoryChange}
        isAdmin={true}
        // employeeId={a}
        categoryErrorMsg={categoryErrorMsg}
        subjectErrorMsg={subjectErrorMsg}
        descriptionErrorMsg={descriptionErrorMsg}
        getTicketList={getViewTicket}
        state={state}
        ticketId={ticketId}
      />
    </div>
  );
};
export default AddTicket;

import {
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
} from "./actionType.js";

const userLogout = () => {
  return (dispatch) => {
    dispatch(userLogoutSuccess());
  };
};

const userLogoutSuccess = () => {
  return {
    type: USER_LOGOUT_SUCCESS,
  };
};

export { userLogout, userLogoutSuccess };

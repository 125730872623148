import React, { useEffect, useRef, useState } from 'react'
import { Loader } from '../svg';
import ribbon from '../emailTemplate/img/ribbon.svg'
import '../emailTemplate/style.css'
import { Link } from 'react-router-dom';
import image1 from '../emailTemplate/img/1Anniversary.png'
import image2 from '../emailTemplate/img/2Anniversary.png'
import image3 from '../emailTemplate/img/3Anniversary.png'
import image4 from '../emailTemplate/img/4Anniversary.png'
import image5 from '../emailTemplate/img/5Anniversary.png'
import image6 from '../emailTemplate/img/6Anniversary.png'
import image7 from '../emailTemplate/img/7Anniversary.png'


import { Descriptions, Select } from 'antd';
import { sendWishesTemplate } from '../../config/services/employeeServices';
const { Option } = Select;


let arr = [6,7,8,9,10,11,12]
const AnniversaryTemplate = (props) => {
  const [loader, setLoader] = useState(true);
  const [empDetail, setEmpDetail] = useState([])
  const [resmessage, setResmessage] = useState(false)
  const [resMsgError, setResMsgError] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [sumbit, setSumbit] = useState(false)
  const refIndex = useRef([])
  const data = props.location.state;
  const countRef = useRef(0)
  let count = countRef.current
  const refEmp = useRef('')
  const [welcomeError, setWelcomeError] = useState('')
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 5000);
    
  }, [loader]);
  
  const employeeWelcome = () => {
    for (let i = 0; i < data.length; i++) {
      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
          .then(function (res) { return res.arrayBuffer(); })
          .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
      }
      urltoFile(data[i].file, 'image.jpeg', 'image/jpeg')
        .then(function (file) {
          let obj = {
            templateNumber: data[i].Year == "1" ? 6 : data[i].Year == '2' ?7 : data[i].Year == '3'?8 : data[i].Year == '4'?9 : data[i].Year =='5'? 10 :data[i].Year == '6'? 11: 12,
            employeeId: data[i].empId,
            image: file,
            showImage: data[i].file,
            empName: data[i].empName,
            empLastName: data[i].empLastName,
            designation: data[i].designation.trim(),
            description: data[i].description.trim(),
            Year: data[i].Year
          }
          empDetail.push(obj)
          console.log("aaaaaaa" , empDetail)
        });
    }
  }
  useEffect(() => {
    employeeWelcome()
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault()
    
    let promises = [];
    setLoader(true)
    for (let i = 0; i < empDetail.length; i++) {
      let params = new FormData();
      params.append("templateNumber", empDetail[i].templateNumber)
      params.append("employeeId", empDetail[i].employeeId)
      params.append("image", empDetail[i].image)
      params.append("empName", empDetail[i].empName)
      params.append("empLastName", empDetail[i].empLastName)
      params.append("description", empDetail[i].description)
      params.append("designation", empDetail[i].designation)
      promises.push(await sendWishesTemplate(params))
    }
    return Promise.all(promises)
      .then(responses => {
        let data = [];
        for (let i = 0; i < responses.length; i++) {
          data.push(responses[i].data);
        }
        return data;
      })
      .then(data => {
        if (data[0].statusCode === 1) {
          setLoader(true)
          window.location.href = '/templates';
          localStorage.setItem("resMssg", true)
        }
        else {
          setResMsgError(true)
          setResponseMessage(data?.error?.responseMessage)
        }
       

      })
      .catch(error => {
      })
    
  }
  return (
    <>
      <div className="body_container cm_bold">
        {loader && <Loader />}
        {
         resmessage?
            <div className="alert alert-success cm_top_fix">Mail Sent SuccessFully</div> : ''
        }
        {resMsgError ?
          <div className="alert alert-danger cm_top_fix">{responseMessage}</div> : ''
        }
        <h4 className="mb-4 cm_bold">ANNIVERSARY  TEMPLATE</h4>

        {empDetail.map((element, index) => (
          element.templateNumber === 6 ?
            <div className="template-container mt-3">
              <div className="card-container">
                <div className="card-box">
                  <img className="card-bg" src={image1} alt="" />
                  <div className="ribbon-box">
                    <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                  </div>
                  {image1 ?
                    <div className="center-content">
                      <div className="hidden-box-1"></div>
                      <div className="person-img-box" style={{ paddingTop: "30%" }} >
                        <img className="person-img" src={element.showImage} alt="" style={{
                          height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
                          border: '7px solid  #fec92e'
                        }} />
                        <img className="round-shadow" alt="" />
                        <img className="cake" alt="" />
                        <div className="person-name-box11" style={{ color: 'black' }} >
                          <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
                        </div>
                        <div className="person-name-box2" >
                          <span className="person-name" style={{ color: 'black', fontWeight: '800', textTransform: 'uppercase' }}>{element.designation}</span>
                        </div>
                        <div className="person-name-box2" >
                          <span className="person-name" style={{ color: 'black', fontStyle: 'oblique', fontWeight: '300' }}>{element.description}</span>
                        </div>

                      </div>
                      <div className="hidden-box-2"></div>
                    </div>
                    : ""}
                </div>
              </div>

              <div className="row">
                <div className="col-11">
                  <div className="text-center mt-3">
                  </div>
                </div>
              </div>
            </div> :
            element.templateNumber === 7 ?
              <div className="template-container mt-3">
                <div className="card-container">
                  <div className="card-box">
                    <img className="card-bg" src={image2}  alt="" />
                    <div className="ribbon-box">
                      <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                    </div>
                    {image2 ?
                      <div className="center-content">
                        <div className="hidden-box-1"></div>
                        <div className="person-img-box" style={{ paddingTop: "30%" }} >
                          <img className="person-img" src={element.showImage} alt="" style={{
                            height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
                            border: '7px solid  #fec92e'
                          }} />
                          <img className="round-shadow" alt="" />
                          <img className="cake" alt="" />
                          <div className="person-name-box11" style={{ color: 'black' }} >
                            <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
                          </div>
                          <div className="person-name-box2" >
                            <span className="person-name" style={{ color: 'black', fontWeight: '800', textTransform: 'uppercase' }}>{element.designation}</span>
                          </div>
                          <div className="person-name-box2" >
                            <span className="person-name" style={{ color: 'black', fontStyle: 'oblique', fontWeight: '300' }}>{element.description}</span>
                          </div>

                        </div>
                        <div className="hidden-box-2"></div>
                      </div>
                      : ""}
                  </div>
                </div>

                <div className="row">
                  <div className="col-11">
                    <div className="text-center mt-3">
                    </div>
                  </div>
                </div>
              </div>

              : 
              element.templateNumber === 8 ?
              <div className="template-container mt-3">
                <div className="card-container">
                  <div className="card-box">
                    <img className="card-bg" src={image3}  alt="" />
                    <div className="ribbon-box">
                      <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                    </div>
                    {image3 ?
                      <div className="center-content">
                        <div className="hidden-box-1"></div>
                        <div className="person-img-box" style={{ paddingTop: "30%" }} >
                          <img className="person-img" src={element.showImage} alt="" style={{
                            height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
                            border: '7px solid  #fec92e'
                          }} />
                          <img className="round-shadow" alt="" />
                          <img className="cake" alt="" />
                          <div className="person-name-box11" style={{ color: 'black' }} >
                            <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
                          </div>
                          <div className="person-name-box2" >
                            <span className="person-name" style={{ color: 'black', fontWeight: '800', textTransform: 'uppercase' }}>{element.designation}</span>
                          </div>
                          <div className="person-name-box2" >
                            <span className="person-name" style={{ color: 'black', fontStyle: 'oblique', fontWeight: '300' }}>{element.description}</span>
                          </div>

                        </div>
                        <div className="hidden-box-2"></div>
                      </div>
                      : ""}
                  </div>
                </div>

                <div className="row">
                  <div className="col-11">
                    <div className="text-center mt-3">
                    </div>
                  </div>
                </div>
              </div>
              :
              element.templateNumber === 9?
              <div className="template-container mt-3">
              <div className="card-container">
                <div className="card-box">
                  <img className="card-bg" src={image4}  alt="" />
                  <div className="ribbon-box">
                    <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                  </div>
                  {image4?
                    <div className="center-content">
                      <div className="hidden-box-1"></div>
                      <div className="person-img-box" style={{ paddingTop: "30%" }} >
                        <img className="person-img" src={element.showImage} alt="" style={{
                          height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
                          border: '7px solid  #fec92e'
                        }} />
                        <img className="round-shadow" alt="" />
                        <img className="cake" alt="" />
                        <div className="person-name-box11" style={{ color: 'black' }} >
                          <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
                        </div>
                        <div className="person-name-box2" >
                          <span className="person-name" style={{ color: 'black', fontWeight: '800', textTransform: 'uppercase' }}>{element.designation}</span>
                        </div>
                        <div className="person-name-box2" >
                          <span className="person-name" style={{ color: 'black', fontStyle: 'oblique', fontWeight: '300' }}>{element.description}</span>
                        </div>

                      </div>
                      <div className="hidden-box-2"></div>
                    </div>
                    :
                     ''}
                </div>
              </div>

              <div className="row">
                <div className="col-11">
                  <div className="text-center mt-3">
                  </div>
                </div>
              </div>
            </div>
            :
            element.templateNumber === 10?
            <div className="template-container mt-3">
            <div className="card-container">
              <div className="card-box">
                <img className="card-bg" src={image5}  alt="" />
                <div className="ribbon-box">
                  <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                </div>
                {image5?
                  <div className="center-content">
                    <div className="hidden-box-1"></div>
                    <div className="person-img-box" style={{ paddingTop: "30%" }} >
                      <img className="person-img" src={element.showImage} alt="" style={{
                        height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
                        border: '7px solid  #fec92e'
                      }} />
                      <img className="round-shadow" alt="" />
                      <img className="cake" alt="" />
                      <div className="person-name-box11" style={{ color: 'black' }} >
                        <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
                      </div>
                      <div className="person-name-box2" >
                        <span className="person-name" style={{ color: 'black', fontWeight: '800', textTransform: 'uppercase' }}>{element.designation}</span>
                      </div>
                      <div className="person-name-box2" >
                        <span className="person-name" style={{ color: 'black', fontStyle: 'oblique', fontWeight: '300' }}>{element.description}</span>
                      </div>

                    </div>
                    <div className="hidden-box-2"></div>
                  </div>
                  :
                   ''}
              </div>
            </div>

            <div className="row">
              <div className="col-11">
                <div className="text-center mt-3">
                </div>
              </div>
            </div>
          </div>
:
element.templateNumber === 11?
<div className="template-container mt-3">
<div className="card-container">
  <div className="card-box">
    <img className="card-bg" src={image6}  alt="" />
    <div className="ribbon-box">
      <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
    </div>
    {image6?
      <div className="center-content">
        <div className="hidden-box-1"></div>
        <div className="person-img-box" style={{ paddingTop: "30%" }} >
          <img className="person-img" src={element.showImage} alt="" style={{
            height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
            border: '7px solid  #fec92e'
          }} />
          <img className="round-shadow" alt="" />
          <img className="cake" alt="" />
          <div className="person-name-box11" style={{ color: 'black' }} >
            <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
          </div>
          <div className="person-name-box2" >
            <span className="person-name" style={{ color: 'black', fontWeight: '800', textTransform: 'uppercase' }}>{element.designation}</span>
          </div>
          <div className="person-name-box2" >
            <span className="person-name" style={{ color: 'black', fontStyle: 'oblique', fontWeight: '300' }}>{element.description}</span>
          </div>

        </div>
        <div className="hidden-box-2"></div>
      </div>
      :
       ''}
  </div>
</div>

<div className="row">
  <div className="col-11">
    <div className="text-center mt-3">
    </div>
  </div>
</div>
</div>
:
            <div className="template-container mt-3">
              <div className="card-container">
                <div className="card-box">
                  <img className="card-bg" src={image7}  alt="" />
                  <div className="ribbon-box">
                    <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                  </div>
                  {image7?
                    <div className="center-content">
                      <div className="hidden-box-1"></div>
                      <div className="person-img-box" style={{ paddingTop: "30%" }} >
                        <img className="person-img" src={element.showImage} alt="" style={{
                          height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
                          border: '7px solid  #fec92e'
                        }} />
                        <img className="round-shadow" alt="" />
                        <img className="cake" alt="" />
                        <div className="person-name-box11" style={{ color: 'black' }} >
                          <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
                        </div>
                        <div className="person-name-box2" >
                          <span className="person-name" style={{ color: 'black', fontWeight: '800', textTransform: 'uppercase' }}>{element.designation}</span>
                        </div>
                        <div className="person-name-box2" >
                          <span className="person-name" style={{ color: 'black', fontStyle: 'oblique', fontWeight: '300' }}>{element.description}</span>
                        </div>

                      </div>
                      <div className="hidden-box-2"></div>
                    </div>
                    :
                     ''}
                </div>
              </div>

              <div className="row">
                <div className="col-11">
                  <div className="text-center mt-3">
                  </div>
                </div>
              </div>
            </div>
        ))}
        <div className="row">
          <div className="col-11">
            <div className="text-center mt-3">
              {
                sumbit ? <h4 className='sent_bday_btn'>SENT</h4> :
                  <button className="btn btn-primary mr-3 cm_btn3" onClick={(e) => handleSubmit(e)} >Submit</button>
              }
              <Link to="/templates" className="cancel_btn btn btn-default mr-0 cm_btn2">
                Cancel
              </Link>

            </div>

          </div>

        </div>
      </div>

    </>
  )
}

export default AnniversaryTemplate
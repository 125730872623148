import React from "react";
import { Link } from "react-router-dom";
import {
  getSecretKey,
  loginEmployee,
} from "../../config/services/employeeServices";
import { EmailIcon, Loader, PasswordIcon, EyeIcon } from "../svg";
import crypto from "crypto";
import ResponseFilter from "../../ResponseFilter";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      email: "",
      password: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      showPassword: false,
      responseError: "",
      responseMessage: "",
      rememberMe: true,
      touchedEmail: false,
      touchedPassword: false,
      emailErrorMsgTooltip: false,
      passwordErrorMsgTooltip: false,
      checkBoxValue: 0,
      resMsg: false,
      isAdmin: 0,
    };

    this.converthash = this.converthash.bind(this);
  }

  preventCopyPaste = (e) => {
    e.preventDefault();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "ErrorMsg"]: "",
      responseMessage: "",
      responseError: "",
      [e.target.name + "ErrorMsgTooltip"]: false,
    });

    if (e.target.name == "email") {
      this.setState({
        touchedEmail: true,
      });
    }

    if (e.target.name == "password") {
      this.setState({
        touchedPassword: true,
      });
    }
  };

  handleEmailTooltip = () => this.setState({ emailErrorMsgTooltip: true });
  handlePasswordTooltip = () =>
    this.setState({ passwordErrorMsgTooltip: true });

  ownValidation = (e) => {
    let validate = true;
    // const regx = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
    const regx =
      /^([^\S\.]{0,})+(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})+([^\S\.]{0,})$/;
    const { email, password } = this.state;
    let validateEmail = regx.test(String(email).toLowerCase());
    let validatePassword = password;

    if (e == "email") {
      if (!email) {
        validate = false;
        this.setState({ emailErrorMsg: "Email is required" });
      } else if (!validateEmail) {
        validate = false;
        this.setState({ emailErrorMsg: "Please enter a valid email address" });
      } else {
        this.setState({ emailErrorMsg: "" });
      }
    }

    if (e == "password") {
      if (validatePassword === "" || validatePassword === undefined) {
        validate = false;
        this.setState({ passwordErrorMsg: "Password is required" });
      } else if (validatePassword.length < 6) {
        validate = false;
        this.setState({
          passwordErrorMsg: "Minimum 6 letter password required",
        });
      } else {
        this.setState({ passwordErrorMsg: "" });
      }
    }

    return validate;
  };

  handleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  handleValidetion = () => {
    let validate = true;
    // const regx = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
    const regx =
      /^([^\S\.]{0,})+(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})+([^\S\.]{0,})$/;
    const { email, password } = this.state;
    let validateEmail = regx.test(String(email).toLowerCase());
    let validatePassword = password;

    if (!email) {
      validate = false;
      this.setState({ emailErrorMsg: "Email is required" });
    } else if (!validateEmail) {
      validate = false;
      this.setState({ emailErrorMsg: "Please enter a valid email address" });
    } else {
      this.setState({ emailErrorMsg: "" });
    }

    if (validatePassword === "" || validatePassword === undefined) {
      validate = false;
      this.setState({ passwordErrorMsg: "Password is required" });
    } else if (validatePassword.length < 6) {
      validate = false;
      this.setState({ passwordErrorMsg: "Please enter valid password" });
    } else {
      this.setState({ passwordErrorMsg: "" });
    }
    return validate;
  };

  setSecretKey = (res) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      getSecretKey()
        .then((result) => {
          let { status } = ResponseFilter(result);
          if (status) {
            localStorage.setItem(
              "secretKey",
              result?.data?.responseData.secretKey
            );
            this.setLocalStorageData(res);
          } else {
            this.setState({
              loader: false,
              responseError: result.data.error.responseMessage,
            });
          }
        })
        .catch((e) => {
          this.setState({
            loader: false,
            responseError: res.data.error.responseMessage,
          });
        });
    } else {
      this.setState({ loader: false });
    }
  };

  setLocalStorageData = (res) => {
    localStorage.setItem(
      "teamHead",
      JSON.stringify(res?.data?.responseData?.teamDetails)
    );
    localStorage.setItem("isAdmin", res.data.responseData.isAdmin);
    localStorage.setItem(
      "employeeId",
      res.data.responseData.employeeProfile.employeeId
    );
    localStorage.setItem(
      "isProjectManager",
      res.data.responseData.isProjectManager
    );
    localStorage.setItem("loginMessage", true);
    localStorage.setItem(
      "salaryAccessEmail",
      res.data.responseData?.employeeProfile?.email
    );
    this.setState(
      { isAdmin: res.data.responseData.isAdmin },
      () =>
        (window.location.href = `/${
          this.state.isAdmin === 1 || this.state.isAdmin === 4 ? "" : "employee"
        }`)
    );
    this.setState({ resMsg: true });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidetion()) {
      this.setState({
        loader: true,
      });
      let obj = {
        email: this.state.email?.trim(),
        password: this.state.password,
        rememberMe: this.state.rememberMe,
      };
      loginEmployee(obj)
        .then(async (res) => {
          if (res.data.statusCode === 1) {
            let email = res.data.responseData?.employeeProfile?.email;
            // this.checkToast(1)
            localStorage.setItem(
              "accessToken",
              res.data.responseData.employeeProfile.acessToken
            );
            if (
              (email === "shubhi.khare@yopmail.com" ||
                email === "shubhi.khare@mobcoder.com") &&
              localStorage.getItem("accessToken")
            ) {
              this.setSecretKey(res);
            } else {
              this.setLocalStorageData(res);
            }
          } else {
            this.setState({
              loader: false,
              responseError: res.data.responseData,
              responseMessage: res.data.error.responseMessage,
            });
          }
        })
        .catch((e) => {
          this.setState({
            loader: false,
            responseError: "Something went wrong",
          });
        });
    } else {
      this.setState({ loader: false });
    }
    // setTimeout(() => this.setState({ resMsg: false }), 3000);
  };

  converthash(e) {
    let { email, password } = this.state;
    if (e == 0) {
      var myPass = crypto.createCipher("aes-128-cbc", "mypassword");
      var pass = myPass.update(password, "utf8", "hex");
      pass += myPass.final("hex");

      var myemail = crypto.createCipher("aes-128-cbc", "mypassword");
      var myEml = myemail.update(email, "utf8", "hex");
      myEml += myemail.final("hex");

      sessionStorage.setItem("hash_e", myEml);
      sessionStorage.setItem("hash_p", pass);

      localStorage.setItem("hash_e", myEml);
      localStorage.setItem("hash_p", pass);
      this.setState({ checkBoxValue: 1, rememberMe: true });
    } else if (e == 1) {
      sessionStorage.removeItem("hash_e");
      sessionStorage.removeItem("hash_p");
      localStorage.removeItem("hash_e");
      localStorage.removeItem("hash_p");
      this.setState({ checkBoxValue: 0, rememberMe: false });
    }
  }

  render() {
    let {
      resMsg,
      emailErrorMsg,
      passwordErrorMsg,
      passwordErrorMsgTooltip,
      showPassword,
      loader,
      responseError,
      responseMessage,
      emailErrorMsgTooltip,
    } = this.state;
    return (
      <div className="login_wrapper d-flex align-items-center">
        {
          loader && <Loader />
          // &&
          // resMsg ?
          // <div className="alert alert-success cm_top_fix">LOGIN SUCCESSFUL</div> : ''
        }
        <div className="container cm_container bg_white">
          <div className="row">
            <div className="col-xl-6">
              <img
                className="login"
                src={require("../../asset/imgs/login-img.png")}
                alt=""
              />
            </div>
            <div className="col-xl-6 text-center">
              <img
                className="logo"
                src={require("../../asset/imgs/logo.png")}
                alt=""
              />

              <form className="form-login" onSubmit={this.handleSubmit}>
                {/* <ToastContainer autoClose={3000} />   */}
                <h4 className="cm_heading mb-5">WELCOME TO MOBCODER PORTAL</h4>
                {/* {/ {responseMessage && <h3 className="password_error mt-3 text-center">{responseMessage}</h3 >} /} */}

                <div
                  className={`form-group ${
                    emailErrorMsg ||
                    responseMessage == "Email is not registered"
                      ? "input_error"
                      : ""
                  }`}
                >
                  <span className="cm_icon emal_icon">
                    <EmailIcon />
                  </span>
                  <input
                    maxLength="50"
                    className={`form-control`}
                    id="login_email"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    value={this.state.email.trim()}
                    onChange={this.handleChange}
                    onBlur={() => this.ownValidation("email")}
                  />
                  {emailErrorMsgTooltip ||
                  responseMessage == "Email is not registered" ? (
                    <span className="errorMsgTooltip">{emailErrorMsg}</span>
                  ) : null}
                  {responseMessage == "Email is not registered" && (
                    <span className="errorMsgTooltip">{responseMessage}</span>
                  )}
                  {emailErrorMsgTooltip ||
                  responseMessage == "Employee not active" ? (
                    <span className="errorMsgTooltip">{emailErrorMsg}</span>
                  ) : null}
                  {responseMessage == "Employee not active" && (
                    <span className="errorMsgTooltip">
                      {"Employee is not active"}
                    </span>
                  )}
                  {emailErrorMsg ? (
                    <i
                      className="fa fa-exclamation-circle"
                      onClick={this.handleEmailTooltip}
                    ></i>
                  ) : (
                    ""
                  )}
                </div>

                {/* (passwordErrorMsg || passwordErrorMsgTooltip) ? '' : */}
                <div
                  className={`form-group ${
                    passwordErrorMsg ? "input_error" : ""
                  }`}
                >
                  <span className="cm_icon emal_icon">
                    <PasswordIcon />
                  </span>
                  {
                    <span
                      onClick={this.handleShowPassword}
                      className={`eye_icon ${
                        this.state.showPassword ? "" : "slesh"
                      } ${passwordErrorMsg ? "error_eye" : ""}`}
                    >
                      <EyeIcon />
                    </span>
                  }
                  <input
                    maxLength="50"
                    id="password"
                    onPaste={(e) => this.preventCopyPaste(e)}
                    className={`form-control`}
                    type={`${showPassword ? "text" : "password"}`}
                    data-tip="Enter password"
                    placeholder="Enter password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    onBlur={() => this.ownValidation("password")}
                  />

                  {passwordErrorMsgTooltip ? (
                    <span className="errorMsgTooltip">{passwordErrorMsg}</span>
                  ) : null}
                  {passwordErrorMsg ? (
                    <i
                      className="fa fa-exclamation-circle"
                      onClick={this.handlePasswordTooltip}
                    ></i>
                  ) : (
                    ""
                  )}
                  {responseMessage == "Enter valid password" && (
                    <span className="errorMsgTooltip">{responseMessage}</span>
                  )}
                </div>
                <button
                  className="btn btn-lg btn-primary btn-block"
                  type="submit"
                >
                  Login
                </button>
                {responseError ? (
                  <h3 className=" Registration_error mt-5">
                    **{responseError}**
                  </h3>
                ) : (
                  ""
                )}
              </form>
              <Link className="fp_link" to="/forgot-password">
                Forgot your Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;

import React, { Component } from 'react';
import ResponseFilter from '../../ResponseFilter';
import _ from 'lodash';
import { Loader } from '../svg';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { Collapse, Icon } from 'antd';
import { dailyWorkByEmpId } from '../../config/services/employeeServices';

const { Panel } = Collapse;


const colors = ['red', 'green', 'yellow', 'black', 'red', 'green', 'yellow', 'black', 'red', 'green', 'yellow', 'black'];



class ManagerActivityLog extends Component {
  constructor(props) {

    super(props);

    this.state = {
      loader:true,
      dailyWorkDetails: [],
      profileImage: '',
      firstName: '',
      dataCount: '',
      sortType: 2,
      sortField: "created",
      pageNo: 1,
      count: 10,
    }

  }


  componentDidMount() {
    this.getActivityLogsByEmp();

  }
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () => this.getActivityLogsByEmp());
  }
  getActivityLogsByEmp() {

    let { sortType, sortField, pageNo, count } = this.state
    let params = `count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&employeeId=${this.props.match.params.empId}`;

    dailyWorkByEmpId(params).then(res => {

      let { status, resData } = ResponseFilter(res);
      if (status) {
        let {
          firstName,
          lastName,
          profileImage,
          empId,
          departmentName,
          designationName,
        } = resData.employeeDetails;

        this.setState({
          loader:false,
          firstName: firstName,
          lastName: lastName,
          empId: empId,
          profileImage: profileImage,
          departmentName: departmentName,
          designationName: designationName,
          dailyWorkDetails: resData.dailyWorkDetails,
          dataCount: resData.dataCount,
          projectId: resData.dailyWorkDetails[0].projectId,

        }
        )
      }
      else{
        this.setState({
            loader:false,
         })

    }
    })
  }


  render() {
 
    let { projectId, pageNo,dataCount, count, loader,dailyWorkDetails, profileImage, firstName, lastName, empId, departmentName, designationName } = this.state;
    return (
      <div className="body_container">
                            {

loader && <Loader />
}
        <div className="cm_breadcrumb cm_bold">
          <ul>
            <li><Link to='/projects/manager-projects'>PROJECT</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li><Link to={`/projects/manager-projects/project-detail/${projectId}`}>PROJECT DETAILS</Link><div className="cm_breadcrumb_arrow"></div></li>
            {/* <li><Link to={`/manager-projects/project-detail/all-activity-log/${projectId}`}>ACTIVITY LOGS</Link><div className="cm_breadcrumb_arrow"></div></li> */}
            {/* http://localhost:3000/manager-projects/project-detail/5e5e30d76373976e025e8988/all-activity-log/5e5e30d76373976e025e8988 */}
             <li><Link to={`/projects/manager-projects/project-detail/${projectId}/all-activity-log/${projectId}`}>ACTIVITY LOGS</Link><div className="cm_breadcrumb_arrow"></div></li>
          
            <li>{firstName.toUpperCase()}'S LOGS</li>
          </ul>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="row">
              <div className="col-3 cm_user_img">
                <img className="" src={`${profileImage ? profileImage : require('../../asset/imgs/user-img.png')}`} alt="" />
                
              </div>


              <div className="col-4 activity_user">
                <h1 className="blue">{firstName} {lastName}</h1>
                <h4>{departmentName}</h4>
                <h3>{empId}</h3>
                <h3>{designationName}</h3>

              </div>
            </div>
            <div>
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
               
              >

                {
                  !_.isEmpty(dailyWorkDetails) &&
                  dailyWorkDetails.map((dailyWork, i) => (
                    <Panel
                      className={colors[i]}
                      header={moment(dailyWork.date).format('DD/MM/YYYY')}
                      key={dailyWork.dailyWorkId}>
                      <div className="col mt-4 cm_break">


                      
                        <label>Project</label>
                        <p>{dailyWork.ProjectName}</p>
                        <label>What did you do today?</label>
                        <p>{dailyWork.task}</p>
                        <label>What problems are you facing?</label>
                        <p>{dailyWork.blocker ? dailyWork.blocker : 'Nothing'}</p>
                        <label>Number of hours working?</label>
                        <p>{moment.utc(dailyWork.hour * 1000).format("HH:mm")}</p>
                      </div>
                    </Panel>
                  ))

                }
               
              </Collapse>
              <div className="mt-5">
              {dataCount > 10 ? <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              /> : ''}</div>
            </div>
          </div>
        </div>

      </div>

    )
  }
}
export default ManagerActivityLog;

import {
    DELETE_EMPLOYEE_RESUME_REQUEST,
  DELETE_EMPLOYEE_RESUME_SUCCESS,
  DELETE_EMPLOYEE_RESUME_FAILURE,
  } from "../actions";
  
  const initialState = {
    loading: false,
    error: "",
    data: [],
  };
  
  const deleteEmpResumeReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_EMPLOYEE_RESUME_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
        };
      case DELETE_EMPLOYEE_RESUME_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case DELETE_EMPLOYEE_RESUME_FAILURE:
        return {
          loading: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default deleteEmpResumeReducer;
  
import { REQUIRED } from "../helper/Constant";
import { numberRegex } from "./salaryConstants";
const panCardRegex = new RegExp(`[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}`);
const accountNumberRegex = new RegExp(`^[0-9]{9,16}$`)
const ifscRegex = new RegExp(`^[A-Za-z]{4}0[A-Za-z0-9]{6}$`)

export const employeeListValidation = (props) => {
    let validate = true
    let { type, dispatch, addEmployeeList, assignList } = props
    if ((addEmployeeList == [] || addEmployeeList == undefined || addEmployeeList == null || addEmployeeList.length <= 0) && (assignList == [] || assignList == undefined || assignList == null || assignList.length <= 0)) {
        validate = false;
        dispatch({
            type: type, payload: {
                addEmployeeErrorMessage: REQUIRED
            }
        })
    }
    return validate
}

export const handleAddSalaryValidation = (props) => {
    let { employeeName, employeeCtc, dispatch, employeeAccountNumber, employeeBankIFSCCode, employeePancardNumber, add, employeeTds, employeeLoan, type, ctcVariable, employeeUanNumber } = props
    let validate = true
    if (add) {
        if (employeeName == '' || employeeName == null || employeeName == undefined) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeNameErrorMessage: REQUIRED
                }
            })
        }
    }
    if (employeeCtc == '' || employeeCtc == null || employeeCtc == undefined || employeeCtc == 0) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeeCtcErrorMessage: REQUIRED
            }
        })
    }
    // else if (isNaN(employeeCtc) || employeeCtc < 0) {
    //     validate = false;
    //     dispatch({
    //         type: type, payload: {
    //             employeeCtcErrorMessage: "Please Enter Number and greater then 0"
    //         }
    //     })
    // }
    else if (isNaN(employeeCtc) || employeeCtc < 120000) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeeCtcErrorMessage: "CTC should be a Number and greater then 120000"
            }
        })
    }



    if (employeeUanNumber) {
        if (isNaN(employeeUanNumber)) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeUanNumberErrorMessage: "UAN number should be Number"
                }
            })
        } else if (employeeUanNumber.toString().length != 12) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeUanNumberErrorMessage: "UAN number should be  equal to 12 digit"
                }
            })
        }
    }

    if (employeeAccountNumber == '' || employeeAccountNumber == null || employeeAccountNumber == undefined || employeeAccountNumber == 0) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeeAccountNumberErrorMessage: REQUIRED
            }
        })
    }
    else if (accountNumberRegex.test(employeeAccountNumber) == false) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeeAccountNumberErrorMessage: "Please Enter Valid Account Number and it should be less then 16"
            }
        })
    }

    if (employeePancardNumber == '' || employeePancardNumber == null || employeePancardNumber == undefined || employeePancardNumber == 0) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeePancardNumberErrorMessage: REQUIRED
            }
        })
    }
    else if (panCardRegex.test(employeePancardNumber) == false) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeePancardNumberErrorMessage: "Please Enter Valid Pan Card Number"
            }
        })
    }

    if (employeeBankIFSCCode == '' || employeeBankIFSCCode == null || employeeBankIFSCCode == undefined || employeeBankIFSCCode == 0) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeeBankIFSCCodeErrorMessage: REQUIRED
            }
        })
    }

    else if (ifscRegex.test(employeeBankIFSCCode) == false) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeeBankIFSCCodeErrorMessage: "Please Enter Valid IFSC Code"
            }
        })
    }

    if (employeeTds) {
        if (isNaN(employeeTds)) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeTdsErrorMessage: "Please Enter Number Only"
                }
            })
        }
        else if (employeeTds < 1) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeTdsErrorMessage: "Please Enter Positive Number Only"
                }
            })
        }
        else if (isNaN(employeeTds)) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeTdsErrorMessage: "Please Enter Number Only"
                }
            })
        }
    }

    if (employeeLoan) {
        if (isNaN(employeeLoan)) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeLoanErrorMessage: "Please Enter Number Only"
                }
            })
        }
        else if (employeeLoan < 1) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeLoanErrorMessage: "Please Enter Positive Number Only"
                }
            })
        }

        else if (isNaN(employeeLoan)) {
            validate = false;
            dispatch({
                type: type, payload: {
                    employeeLoanErrorMessage: "Please Enter Number Only"
                }
            })
        }
    }



    if (ctcVariable) {
        if (isNaN(ctcVariable)) {
            validate = false;
            dispatch({
                type: type, payload: {
                    ctcVariableErrorMessage: "Please Enter Number Only"
                }
            })
        }
        else if (ctcVariable < 1) {
            validate = false;
            dispatch({
                type: type, payload: {
                    ctcVariableErrorMessage: "Please Enter Positive Number Only"
                }
            })
        }
        else if (isNaN(ctcVariable)) {
            validate = false;
            dispatch({
                type: type, payload: {
                    ctcVariableErrorMessage: "Please Enter Number Only"
                }
            })
        }
    }


    return validate
}
export const formValidation = (props) => {
    const { lwpEmployee, dispatch, type } = props
    const data = [...lwpEmployee]
    let validate = true
    const pattern = /^(?=.*[1-9])\d+(?:\.[05]0?)?$/;
    for (let index = 0; index < data.length; index++) {


        if (data[index].numberOfLwp == "") {
            data[index].numberOfLwpCheck = "Number Of LWP Required"
            validate = false
        }
        else if (data[index].numberOfLwp <= 0) {
            data[index].numberOfLwpCheck = "Number Of LWP can not be Negative OR 0"
            validate = false
        }
        else if (pattern.test(data[index].numberOfLwp) == false) {
            data[index].numberOfLwpCheck = "Please Enter Valid Number"
            validate = false
        }
        else if (data[index].numberOfLwp > 31) {
            data[index].numberOfLwpCheck = "Number Of LWP can not be Greater then 31"
            validate = false
        }
        else {
            data[index].numberOfLwpCheck = ""
        }
    }

    dispatch({
        type: type, payload: {
            lwpEmployee: data
        }
    })
    return validate

}


export const arrearFormValidation = (props) => {
    const { arrearEmployees, dispatch, type } = props
    const data = [...arrearEmployees]
    let validate = true
    for (let index = 0; index < data.length; index++) {


        if (data[index].arrear == "") {
            data[index].arrearCheck = "Arrear Required"
            validate = false
        }

        else if (isNaN(data[index].arrear)) {
            data[index].arrearCheck = "Please Enter Number"
            validate = false
        }
        else if (data[index].arrear == 0 || data[index].arrear <= 0) {
            data[index].arrearCheck = "Please Enter Valid Amount"
            validate = false
        }
        else {
            data[index].arrearCheck = ""
        }
    }

    dispatch({
        type: type, payload: {
            lwpEmployee: data
        }
    })
    return validate

}


export const bonusFormValidation = (props) => {
    const { bonusEmployees, dispatch, type } = props
    const data = [...bonusEmployees]
    let validate = true
    for (let index = 0; index < data.length; index++) {


        if (data[index].bonus == "") {
            data[index].bonusCheck = "Bonus Required"
            validate = false
        }

        else if (isNaN(data[index].bonus)) {
            data[index].bonusCheck = "Please Enter Number"
            validate = false
        }
        else if (data[index].bonus == 0 || data[index].bonus <= 0) {
            data[index].bonusCheck = "Please Enter Valid Amount"
            validate = false
        }
        else {
            data[index].bonusCheck = ""
        }
    }

    dispatch({
        type: type, payload: {
            bonusEmployees: data
        }
    })
    return validate

}

export const reimbursementFormValidation = (props) => {
    const { reimbursementEmployees, dispatch, type } = props
    const data = [...reimbursementEmployees]
    let validate = true
    for (let index = 0; index < data.length; index++) {
        if (data[index].reimbursement == "") {
            data[index].reimbursementCheck = "Reimbursement Required"
            validate = false
        }
        else if (isNaN(data[index].reimbursement)) {
            data[index].reimbursementCheck = "Please Enter Number"
            validate = false
        }
        else if (data[index].reimbursement == 0 || data[index].reimbursement <= 0) {
            data[index].reimbursementCheck = "Please Enter Valid Amount"
            validate = false
        }
        else {
            data[index].reimbursementCheck = ""
        }
    }

    dispatch({
        type: type, payload: {
            reimbursementEmployees: data
        }
    })
    return validate


}

export const onHoldEmployeesFormValidation = (props) => {
    const { onHoldEmployees, dispatch, type } = props
    const data = [...onHoldEmployees]
    let validate = true
    for (let index = 0; index < data.length; index++) {
        if (data[index].releaseDate == "" || data[index].releaseDate == undefined || data[index].releaseDate == NaN || data[index].releaseDate == null) {
            data[index].releaseDateCheck = "Release date is required"
            validate = false
        }
        else {
            data[index].releaseDateCheck = ""
        }
    }
    dispatch({
        type: type, payload: {
            onHoldEmployees: data
        }
    })
    return validate

}
export const headingAddCompOff = 'Add Compensatory Off'
export const headingEditCompOff = 'Edit Compensatory Off'

export const emptyField = 'Please enter no. of compensatory leaves'
export const compOffLimit = 'You cannot Edit more than 5 comp off'
export const compOffMax = 'You cannot add more than 5 Comp Off'
export const invalid = 'Invalid Input'

export const YEAR = 'YEARLY RECORDS'
export const QUARTER = 'QUARTERLY RECORDS'
export const MONTH = 'MONTHLY RECORDS'

export const CompOffAdd = 'Compensatory Off added'
export const EditCompOff = 'Compensatory Off edited successfully'

export const EmpAddSuccess = "Employee Added";
export const EmpDelSuccess = "Employee Deleted";

export const isRequired = " is required"
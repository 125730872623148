import React, { useEffect, useState } from "react";
import { Select } from "antd";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { Modal as AntModal } from "antd";
import { MONTH_ARRAY } from "../../leaves/Constants";
import { ErrorMessage } from "../../helper/Constant";
import { Loader } from "../../svg";

const { Option } = Select;
const DownloadEmpDataModal = (props) => {
  const {
    openDownloadEmpData,
    closeModal,
    membersDetails,
    handleDownloadEmployee,
    handleSelectTemplate,
    handleDownload,
    employeeErrMsg,
    handleMonth,
    handleYear,
    state,
    modalLoader,
  } = props;

  const {
    downloadEmployee,
    selectTemplate,
    month,
    year,
    monthErr,
    yearErr,
    downloadEmployeeErr,
    selectTemplateErr,
  } = state;

  const createSelectItems = () => {
    let items = [];
    let currentYear = new Date().getFullYear();
    let startYear = 2022;
    for (let year = currentYear; year >= startYear; year--) {
      items.push(
        <Option key={year} value={`${year}`}>
          {year}
        </Option>
      );
    }
    return items;
  };
  
  return (
    <AntModal
      centered
      className="sm_modal1"
      footer={false}
      visible={openDownloadEmpData}
      onCancel={closeModal}
    >
      {modalLoader && <Loader/>} 
      <Modal.Header className="text-center confirmation">
        <p className="mb-4 cm_bold cnfrm_msg">Download Employee Data</p>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div
            className={`form-group col-md-6 ${monthErr ? "input_error" : ""}`}
          >
            <Select
              value={month ? month : "Month"}
              defaultValue="Select Month"
              name="month"
              onChange={(e) => handleMonth(e)}
              allowClear={month ? true : false}
            >
              {MONTH_ARRAY.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            <ErrorMessage errMsg={monthErr} />
          </div>
          <div
            className={`form-group col-md-6 ${yearErr ? "input_error" : ""}`}
          >
            <Select
              value={year ? year : "Select Year"}
              defaultValue="Select Year"
              name="year"
              onChange={handleYear}
              allowClear={year ? true : false}
            >
              {createSelectItems()}
            </Select>
            <ErrorMessage errMsg={yearErr} />
          </div>
        </div>
        <div className="row">
          <div
            className={`form-group col-md-6 ${
              downloadEmployeeErr ? "input_error" : ""
            }`}
          >
            <Select
              showSearch
              name="employeeId"
              value={downloadEmployee ? downloadEmployee : "Select Employee "}
              allowClear={downloadEmployee ? true : false}
              optionFilterProp="key"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => handleDownloadEmployee(e)}
            >
              {!_.isEmpty(membersDetails)
                ? membersDetails.map((employee) => (
                    <Option
                      key={`${employee.firstName} ${employee.lastName}`}
                      value={employee.employeeId}
                    >
                      <span>
                        {employee.firstName + " " + employee.lastName}
                      </span>
                    </Option>
                  ))
                : ""}
            </Select>
            <ErrorMessage errMsg={downloadEmployeeErr} />
          </div>
          <div
            className={`form-group col-md-6 ${
              selectTemplateErr ? "input_error" : ""
            }`}
          >
            <Select
              value={selectTemplate ? selectTemplate : "Select Template"}
              allowClear={selectTemplate ? true : false}
              onChange={(e) => handleSelectTemplate(e)}
            >
              <Option value="1"> Template 1</Option>
              <Option value="2"> Template 2</Option>
              <Option value="3"> Template 3</Option>
            </Select>
            <ErrorMessage errMsg={selectTemplateErr} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          <button
            className="btn btn-primary cm_btn3"
            onClick={() => handleDownload()}
          >
            Download
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="btn text-secondary border border-dark cm_btn3"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </AntModal>
  );
};

export default DownloadEmpDataModal;

import { DatePicker, Select } from "antd";
import React, { useEffect, useMemo, useReducer } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  MONTH_ARRAY_NUM,
  createSelectItems,
  currentMonth,
  dateFormat,
  reducer,
} from "../helper/Constant";
import moment from "moment";
import { currentYear, statusMon, statusQuarter } from "../leaves/Constants";
import { useDispatch } from "react-redux";
import { getTeamMemberDetailsByAdminAction } from "../../redux/actions/getTeamMemberDetailsByAdminAction";
import { getEmpProfileByAdmin } from "../../redux/actions";
import { EarlyLeaveIcon, LateComingIcon, Loader } from "../svg";
import { Tab, Tabs } from "react-bootstrap";
import qs from "qs";
import EmployeeProjectsActivity from "./EmployeeProjectsActivity";
import { getProjectActivityByEmplyeeIdAction } from "../../redux/actions/getProjectActivityByEmployeeIdAction";
import { disabledDate } from "../attendance/Constants";
import _ from "lodash";
import { CONSTANTS } from "../../config";
const { Option } = Select;
const { RangePicker } = DatePicker;

const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const initialState = {
  selectLeave: "1",
  totalpaidComOff: 0,
  totalpaidEarnedLeaves: 0,
  carryfwCompOff: 0,
  carryfwEarnedLeaves: 0,
  mesg: "",
  resMsg: false,
  totalComp: 0,
  yearlyEl: 0,
  yearlyCl: 0,
  yearlySl: 0,
  totalCompOffLeaves: 0,
  totalLWPLeaves: 0,
  totalWFHLeaves: 0,
  totalOtherLeaves: 0,
  totalLeavesTaken: 0,
  totalShortDayLeave: 0,
  searchYear: currentYear,
  searchMonth: currentMonth,
  searchQuatName: "",
  openModal: false,
  modalValue: "",
  modalValueErrorMsg: "",
  modalName: "",
  index: "",
  heading: "",
  loader: false,
  isAdmin: true,
  resMsgFail: false,
  responseMessage: "",
  employeeType: "",
  assignedCl: "",
  assignedEl: "",
  assignedSl: "",
  employeeName: "",
  replyErrorMsg: "",
  compOff: "",

  compOffStartDate: "",
  compOffStartDateErrorMsg: "",
  wantToCarryForwardCompOff: "",
  wantToCarryForwardCompOffErrorMsg: "",
  endOpen: false,
  compOffGivenReason: "",
  compOffGivenReasonErrorMsg: "",
  searchQuarter: "",
  dateRange: false,
  attendanceDetails: [],
  memberName: "",
  tabStatus: 0,
  projectActivity: [],
  activityCount: 0,
  sortField: "date",
  sortType: 1,
  pageNo: 1,
  count: 10,
  startDateRange: "",
  endDateRange: "",
  startDateStamp: "",
  endDateStamp: "",
  teamId: "",
};

export default function TeamPersonDetail(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const {
    searchYear,
    searchMonth,
    yearlyEl,
    yearlyCl,
    yearlySl,
    totalCompOffLeaves,
    totalLWPLeaves,
    totalWFHLeaves,
    totalOtherLeaves,
    totalShortDayLeave,
    searchQuarter,
    dateRange,
    attendanceDetails,
    memberName,
    loader,
    tabStatus,
    projectActivity,
    activityCount,
    sortField,
    sortType,
    pageNo,
    count,
    startDateRange,
    endDateRange,
    startDateStamp,
    endDateStamp,
    teamId,
  } = state;
  const dispatchToAction = useDispatch();

  useEffect(() => {
    history.push(
      `?searchYear=${searchYear}&searchMonth=${searchMonth}&searchQuarter=${searchQuarter}&dateRange=${dateRange}&tabStatus=${tabStatus}&startDateRange=${startDateRange}&endDateRange=${endDateRange}&startDateStamp=${startDateStamp}&endDateStamp=${endDateStamp}&pageNo=${pageNo}`
    );
    getTeamMembersDetails();
    getData();
    getProjectActivity();
    // const teamId = localStorage.getItem("teamId")
    dispatch({
      type: "typeCheckSet",
      payload: {
        teamId: localStorage.getItem("teamId"),
      },
    });
    dispatch({});
  }, [
    searchMonth,
    searchYear,
    searchQuarter,
    tabStatus,
    sortType,
    endDateRange,
    pageNo,
  ]);
  const getData = () => {
    dispatchToAction(
      getEmpProfileByAdmin({
        useDispatch: dispatch,
      })
    );
  };
  const getTeamMembersDetails = () => {
    let params = `employeeId=${
      props.match.params.id
    }&searchYear=${searchYear}&searchMonth=${searchMonth}&searchQuarter=${
      searchMonth ? "" : searchQuarter
    }&tabStatus=${tabStatus}`;
    dispatch({
      type: "typeCheckSet",
      payload: {
        loader: true,
      },
    });
    dispatchToAction(
      getTeamMemberDetailsByAdminAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  const getProjectActivity = () => {
    let params = `employeeId=${
      props.match.params.id
    }&tabStatus=${tabStatus}&sortType=${sortType}&sortField=${sortField}&startDate=${startDateRange}&endDate=${endDateRange}&pageNo=${
      pageNo - 1
    }&count=${count}`;
    dispatch({
      type: "typeCheckSet",
      payload: {
        loader: true,
      },
    });
    dispatchToAction(
      getProjectActivityByEmplyeeIdAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  const handleQuarter = (e) => {
    if (e) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchQuarter: e,
          pageNo: 1,
          searchMonth: "",
          searchYear: searchYear ? searchYear : currentYear,
          dateRange: true,
        },
      });
    } else {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchQuarter: "",
          searchYear: currentYear,
          searchMonth: "",
          pageNo: 1,
          dateRange: false,
        },
      });
    }
  };

  const handleMonth = (month) => {
    if (month === undefined) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchMonth: currentMonth,
          searchYear: searchYear ? searchYear : currentYear,
          pageNo: 1,
        },
      });
    } else {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchMonth: month,
          searchYear: searchYear ? searchYear : currentYear,
          pageNo: 1,
        },
      });
    }
  };

  const handleYear = (year) => {
    if (year === undefined) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchYear: currentYear,
          searchMonth: searchMonth ? searchMonth : currentMonth,
          pageNo: 1,
        },
      });
    } else {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchYear: year,
          searchMonth: currentMonth,
          pageNo: 1,
        },
      });
    }
  };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";

    dispatch({
      type: "typeCheckSet",
      payload: {
        searchMonth:
          filtersFromParams.searchQuarter == ""
            ? filtersFromParams.searchMonth
              ? +filtersFromParams.searchMonth
              : currentMonth
            : "",
        searchYear: filtersFromParams.searchYear
          ? filtersFromParams.searchYear
          : currentYear,
        searchQuarter: filtersFromParams.searchQuarter
          ? +filtersFromParams.searchQuarter
          : "",
        dateRange: filtersFromParams.dateRange
          ? filtersFromParams.dateRange
          : false,
        tabStatus: filtersFromParams.tabStatus
          ? filtersFromParams.tabStatus
          : 0,
        startDateRange: filtersFromParams.startDateRange
          ? filtersFromParams.startDateRange
          : "",
        endDateRange: filtersFromParams.endDateRange
          ? filtersFromParams.endDateRange
          : "",
        startDateStamp: filtersFromParams.startDateStamp
          ? moment(filtersFromParams.startDateStamp, format)
          : "",
        endDateStamp: filtersFromParams.endDateStamp
          ? moment(filtersFromParams.endDateStamp, format)
          : "",
        pageNo: filtersFromParams.pageNo
          ? parseInt(filtersFromParams.pageNo)
          : 1,
      },
    });
  }, [history.location.search]);
  const handleFilter = (e) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        tabStatus: e,
        searchMonth: currentMonth,
        searchYear: currentYear,
        searchQuarter: "",
        pageNo: 1,
      },
    });
  };

  const handlePageChange = (pageNo) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        pageNo: pageNo,
      },
    });
  };

  const handleRangePicker = (date) => {
    if (date.length === 0) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          pageNo: 1,
          startDateStamp: "",
          endDateStamp: "",
          startDateRange: !_.isEmpty(date)
            ? new Date(date[0]).setHours(6, 30, 0, 0)
            : "",
          endDateRange: !_.isEmpty(date)
            ? new Date(date[1]).setHours(6, 30, 0, 0)
            : "",
        },
      });
    } else {
      dispatch({
        type: "typeCheckSet",
        payload: {
          pageNo: 1,
          startDateStamp: date[0],
          endDateStamp: date[1],
          startDateRange: !_.isEmpty(date)
            ? new Date(date[0]).setHours(6, 30, 0, 0)
            : "",
          endDateRange: !_.isEmpty(date)
            ? new Date(date[1]).setHours(6, 30, 0, 0)
            : "",
        },
      });
    }
  };

  const handleSort = (e) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        sortField: e,
        sortType:
          sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
    });
  };
  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <div className="cm_breadcrumb">
        <ul>
          <li>
            <Link to={`/admin-team/${teamId}`}>Team Member Detail</Link>
            <div className="cm_breadcrumb_arrow"></div>
          </li>
          <li style={{ textTransform: "uppercase" }}>{memberName}</li>
        </ul>
      </div>
      <Tabs
        activeKey={
          tabStatus == "" || tabStatus == 0 || tabStatus == undefined
            ? 0
            : tabStatus == 1
            ? 1
            : 2
        }
        className=" mt-5 mb-3"
        id="uncontrolled-tab-example"
        onSelect={(e) => handleFilter(e)}
      >
        <Tab eventKey={0} title={`Gereral Info`}></Tab>
        <Tab eventKey={1} title={`Project Activity`}></Tab>
      </Tabs>
      <div className="filters justify-content-end">
        {tabStatus == 0 ? (
          <>
            <Select
              className="select_year_cal"
              defaultValue="Select Quarter"
              name="searchQuarter"
              allowClear={searchQuarter ? true : false}
              value={searchQuarter ? statusQuarter(searchQuarter) : "Quarter"}
              onChange={(e) => handleQuarter(e)}
            >
              <Option value={1}>January - March </Option>
              <Option value={2}>April - June</Option>
              <Option value={3}>July - September</Option>
              <Option value={4}>October - December</Option>
            </Select>
            <Select
              className="select_year_cal"
              value={searchMonth ? statusMon(searchMonth) : "Month"}
              defaultValue="Select Month"
              name="searchMonth"
              onChange={(e) => handleMonth(e)}
              allowClear={searchMonth ? true : false}
              disabled={dateRange === "true" || dateRange === true}
            >
              {MONTH_ARRAY_NUM.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>

            <Select
              className="select_year_cal"
              value={searchYear ? searchYear : "Select Year"}
              defaultValue="Select Year"
              name="searchYear"
              onChange={handleYear}
              allowClear={searchYear ? true : false}
            >
              {createSelectItems()}
            </Select>
          </>
        ) : (
          <div className="mb-4">
            <RangePicker
              id="daterangepicker"
              onChange={handleRangePicker}
              disabledDate={disabledDate}
              className="rang select_year_cal rang_pick mr-3"
              value={[
                startDateStamp ? moment(startDateStamp) : "",
                endDateStamp ? moment(endDateStamp) : "",
              ]}
            />
          </div>
        )}
      </div>

      {tabStatus == 0 ? (
        <div>
          <h3>Leave Details</h3>
          <div className="row leave_cm_card card_mt">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card1 cm_cardDashbord">
                    <p className="skyblue">Casual Leaves</p>

                    <span className="card_leave_no">
                      <span>{yearlyCl ? yearlyCl : 0}</span>
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card2 cm_cardDashbord">
                    <p className="purple">Earned Leaves</p>
                    <span className="card_leave_no">
                      <span> {yearlyEl ? yearlyEl : 0}</span>
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card3 cm_cardDashbord">
                    <p className="blue">Sick Leaves</p>
                    <span className="card_leave_no">
                      <span>{yearlySl ? yearlySl : 0}</span>
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card4 cm_cardDashbord">
                    <p className="yellow">Compensatory Off</p>
                    <span className="card_leave_no">
                      {totalCompOffLeaves ? totalCompOffLeaves : 0}
                    </span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card5 cm_cardDashbord">
                    <p className="red">LWP</p>
                    <span className="card_leave_no">
                      {totalLWPLeaves ? totalLWPLeaves : 0}
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card7 cm_cardDashbord">
                    <p className="grey">Work From Home</p>
                    <span className="card_leave_no">
                      {totalWFHLeaves ? totalWFHLeaves : 0}
                    </span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card6 cm_cardDashbord">
                    <p className="black">Other's Leaves</p>
                    <span className="card_leave_no">
                      {totalOtherLeaves ? totalOtherLeaves : 0}
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="cm_card cm_pr cm_bold card11 cm_cardDashbord">
                    <p className="brown">Short Leave</p>
                    <span className="card_leave_no">
                      {totalShortDayLeave ? totalShortDayLeave : 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3>Attendance Details</h3>

          <div className="row attendance_status mt-4">
            <div className="col-md-3 ">
              <div className="cm_card">
                <span className="late_comings">
                  <LateComingIcon />
                </span>
                <div className="cm_style">
                  <span className="emp_count cm_bold">
                    {Array.isArray(attendanceDetails?.late) &&
                    attendanceDetails?.late[0]?.lateCount
                      ? attendanceDetails?.late[0]?.lateCount
                      : attendanceDetails?.late > 0
                      ? attendanceDetails?.late
                      : "0"}
                  </span>
                  <p className="mb-4">Late Check In</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card">
                <span className="late_comings early_leave">
                  <EarlyLeaveIcon />
                </span>
                <div className="cm_style">
                  <span className="emp_count cm_bold">
                    {Array.isArray(attendanceDetails?.early) &&
                    attendanceDetails?.early[0]?.earlyCount
                      ? attendanceDetails?.early[0]?.earlyCount
                      : attendanceDetails?.early > 0
                      ? attendanceDetails?.early
                      : "0"}
                  </span>
                  <p className="mb-4">Early Check Out</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className={`${
                  attendanceDetails?.averageHours?.[0]?.avgTotalHours
                    ? moment
                        .utc(
                          attendanceDetails?.averageHours?.[0].avgTotalHours *
                            1000
                        )
                        .format("HH:mm") <
                        moment.utc(32400 * 1000).format("HH:mm") &&
                      moment
                        .utc(
                          attendanceDetails?.averageHours?.[0].avgTotalHours *
                            1000
                        )
                        .format("HH:mm") >=
                        moment.utc(28800 * 1000).format("HH:mm")
                      ? "cm_card cm_avghryellow"
                      : moment
                          .utc(
                            attendanceDetails?.averageHours?.[0].avgTotalHours *
                              1000
                          )
                          .format("HH:mm") <
                        moment.utc(32400 * 1000).format("HH:mm")
                      ? "cm_card cm_avghours"
                      : "cm_card cm_avghourgreen"
                    : "cm_card cm_avghours"
                }`}
              >
                <div className="text-center">
                  <p className="sm_hours">Avg/Hours</p>
                  <span className="emp_count cm_bold">
                    {attendanceDetails?.averageHours?.[0]?.avgTotalHours
                      ? moment
                          .utc(
                            attendanceDetails?.averageHours?.[0].avgTotalHours *
                              1000
                          )
                          .format("H.mm")
                      : "00.00"}
                    /09.00
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmployeeProjectsActivity
          dataCount={activityCount}
          projectActivity={projectActivity}
          sortType={sortType}
          sortField={sortField}
          pageNo={pageNo}
          count={count}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
        />
      )}
    </div>
  );
}

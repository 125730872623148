import {
  GET_CANDIDATE_BY_ID_REQUEST,
  GET_CANDIDATE_BY_ID_SUCCESS,
  GET_CANDIDATE_BY_ID_FAILURE,
} from "../actions";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const getCandidateByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATE_BY_ID_REQUEST:
      return {
        error: "",
        loading: true,
        data: [],
      };

    case GET_CANDIDATE_BY_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case GET_CANDIDATE_BY_ID_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default getCandidateByIdReducer;

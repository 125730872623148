import React, { useEffect, useRef, useState } from 'react'
import { Loader } from '../svg';
import card_main2 from '../emailTemplate/img/card-main2.png'
import card_main3 from '../emailTemplate/img/card-main3.png'
import card_main4 from '../emailTemplate/img/card-main4.png'
import ribbon from '../emailTemplate/img/ribbon.svg'
import '../emailTemplate/style.css'
import { Link } from 'react-router-dom';
import { Select } from 'antd';
import { sendWishesTemplate } from '../../config/services/employeeServices';
const { Option } = Select;


let arr = [1, 2, 3]
const BirthdayTemplate = (props) => {
  var image1 = 'https://hrm-mob-dev.s3.amazonaws.com/1663049136148.png'
  var image2 = 'https://hrm-mob-dev.s3.amazonaws.com/20230124T071637676Z0.13707777779820152.png'
  var image3 = 'https://hrm-mob-dev.s3.amazonaws.com/20230123T093650041Z0.680806337121084.png'
  const [loader, setLoader] = useState(true);
  const [empDetail, setEmpDetail] = useState([])
  const [resmessage, setResmessage] = useState(false)
  const [resMsgError, setResMsgError] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [sumbit, setSumbit] = useState(false)
  const refIndex = useRef([])
  const data = props.location.state;
  const countRef = useRef(0)
  let count = countRef.current
  const refEmp = useRef('')
  const [birthdayError, setBirthdayError] = useState('')
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, [loader]);
  const loadImage = () => {

  }
  const employeeBirthday = () => {
    for (let i = 0; i < data.length; i++) {
      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
          .then(function (res) { return res.arrayBuffer(); })
          .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
      }
      urltoFile(data[i].file, 'image.jpeg', 'image/jpeg')
        .then(function (file) {
          let obj = {
            templateNumber: arr[Math.floor(Math.random() * arr.length)],
            employeeId: data[i].empId,
            image: file,
            showImage: data[i].file,
            empName: data[i].empName
          }
          empDetail.push(obj)
        });
    }
  }
  useEffect(() => {
    employeeBirthday()
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoader(true)
    let promises = [];
    for (let i = 0; i < empDetail.length; i++) {
      let params = new FormData();
      params.append("templateNumber", empDetail[i].templateNumber)
      params.append("employeeId", empDetail[i].employeeId)
      params.append("image", empDetail[i].image)
      params.append("empName", empDetail[i].empName)
      promises.push(await sendWishesTemplate(params))
    }
    return Promise.all(promises)
      .then(responses => {
        let data = [];
        for (let i = 0; i < responses.length; i++) {
          data.push(responses[i].data);
        }
        return data;
      })
      .then(data => {
        if (data[0].statusCode === 1) {
          setLoader(true)
          window.location.href = '/templates';
          localStorage.setItem("resMsg", true)

        }
        else {
          setResMsgError(true)
          setResponseMessage(data?.error?.responseMessage)
        }
      })
      .catch(error => {
      }).finally(() => console.log(false),
      );

  }
  return (
    <>
      <div className="body_container cm_bold">
        {loader && <Loader />}
        {
          resmessage ?
            <div className="alert alert-success cm_top_fix">Mail Sent SuccessFully</div> : ''
        }
        {resMsgError ?
          <div className="alert alert-danger cm_top_fix">{responseMessage}</div> : ''
        }
        <h4 className="mb-4 cm_bold">BIRTHDAY TEMPLATE</h4>
        <div className="back_btn">

        </div>
        {birthdayError ? <span className="error_msg">{birthdayError}</span> : null}
        {empDetail.map((element, index) => (
          element.templateNumber === 1 ?
            <div className="template-container mt-3">
              <div className="card-container">
                <div className="card-box" style={{ backgroundColor: "black" }}>
                  <img className="card-bg" src={image1} alt="" onLoad={loadImage} />
                  <div className="ribbon-box">
                    <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                  </div>
                  {image1 ?
                    <div className="center-content">
                      <div className="hidden-box-1"></div>
                      <div className="person-img-box">
                        <img className="person-img" src={element.showImage} alt="" style={{ height: "300px", width: "300px", marginTop: "158px", top: "109" }} />
                        <img className="round-shadow" alt="" />
                        <img className="cake" alt="" />
                        <div className="person-name-box" style={{ top: "109%" }}>
                          <span className="person-name" style={{ color: "black" }}>{element.empName}</span>
                        </div>
                      </div>
                      <div className="hidden-box-2"></div>
                    </div>

                    : ""}
                </div>
              </div>
              <div className="row">
                <div className="col-11">
                  <div className="text-center mt-3">
                  </div>
                </div>
              </div>
            </div> :
            element.templateNumber === 2 ? <div className="template-container mt-3">
              <div className="card-container">
                <div className="card-box" >
                  <img className="card-bg" src={image2} alt="" />
                  <div className="ribbon-box">
                    <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                  </div>
                  {image2 ?
                    <div className="center-content">
                      <div className="hidden-box-1"></div>
                      <div className="person-img-box">
                        <img className="person-img" src={element.showImage} alt="" style={{ height: "198px", width: "190px" }} />
                        <img className="round-shadow" alt="" />
                        <img className="cake" alt="" />
                        <div className="person-name-box" style={{ top: "109%" }}>
                          <span className="person-name" style={{ color: "#d39733" }}> {element.empName}</span>
                        </div>
                      </div>
                      <div className="hidden-box-2"></div>
                    </div>
                    : ""}
                </div>
              </div>
              <div className="row">
                <div className="col-11">
                  <div className="text-center mt-3">

                  </div>
                </div>
              </div>
            </div>
              :
              <div className="template-container mt-3">
                <div className="card-container">
                  <div className="card-box" style={{ backgroundColor: "black" }}>
                    <img className="card-bg" src={image3} alt="" />
                    <div className="ribbon-box">
                      <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                    </div>
                    {image3 ?
                      <div className="center-content">
                        <div className="hidden-box-1"></div>
                        <div className="person-img-box">
                          <img className="person-img" src={element.showImage} alt="" style={{ height: "300px", width: "300px", marginTop: "158px", top: "109" }} />
                          <img className="round-shadow" alt="" />
                          <img className="cake" alt="" />
                          <div className="person-name-box" style={{ top: "109%" }}>
                            <span className="person-name" style={{ color: "white" }}>
                              {element.empName}
                            </span>
                          </div>
                        </div>
                        <div className="hidden-box-2"></div>
                      </div>
                      : ""}
                  </div>
                </div>
              </div>

        ))}
        <div className="row">
          <div className="col-11">
            <div className="submit_button_margin mt-4">
              {

                <button className="btn btn-primary cm_btn3" onClick={(e) => handleSubmit(e)} >Submit</button>
              }
              <Link to="/templates" className="cancel_btn btn btn-default cm_btn2">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default BirthdayTemplate
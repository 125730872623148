export const monthValue = (type) => {
    switch (type) {
        case 1:
            return "January";
            break;

        case 2:
            return "February";
            break;

        case 3:
            return "March";
            break;

        case 4:
            return 'April';
            break;

        case 5:
            return 'May';
            break;

        case 6:
            return 'June';
            break;

        case 7:
            return 'July';
            break;

        case 8:
            return 'August';
            break;

        case 9:
            return 'September';
            break;

        case 10:
            return 'October';
            break;

        case 11:
            return 'November';
            break;

        case 12:
            return 'December';
            break;

    }
};

export const employeePFValue = (type) => {
    switch (type) {

        case 1:
            return 'Yes';
            break;

        case 2:
            return 'No';
            break;
    }
}

export const employeeOnHoldValue = (type) => {
    switch (type) {

        case 1:
            return 'Hold';
            break;

        case 2:
            return 'Clear';
            break;
    }
}

export const employeeOnHoldColor = (type) => {
    switch (type) {

        case 1:
            return 'red';
            break;

        case 2:
            return 'green';
            break;
    }
}
export const numberRegex = new RegExp(`^(\d)*(\.)?([0-9])?$`)
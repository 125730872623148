import {
    EDIT_ON_HOLD_RELEASE_DATE_REQUEST,
    EDIT_ON_HOLD_RELEASE_DATE_SUCCESS,
    EDIT_ON_HOLD_RELEASE_DATE_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import {  editOnHoldReleaseDate } from "../../config/services/employeeServices";

const editOnHoldReleaseDateAction = (props) => {
    let { useDispatch, params, handleClose, getEmployeeOnHold } = props
    return (dispatch) => {
        dispatch(editOnHoldReleaseDateActionRequest());
        editOnHoldReleaseDate(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resMsg: true,
                        successMessage: "Release Date Edited",
                    }
                })
                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resMsg: false,
                        }
                    })
                }, 3000)
            } else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resFailMsg: true,
                        responseMessage: res?.data?.error?.responseMessage
                    }
                })

                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resFailMsg: false,
                        }
                    })
                }, 3000)
            }
            handleClose();
            getEmployeeOnHold();
        })
            .catch((error) => {
                dispatch(editOnHoldReleaseDateActionFailure(error));
            });
    };
};

const editOnHoldReleaseDateActionRequest = () => {
    return {
        type: EDIT_ON_HOLD_RELEASE_DATE_REQUEST,
    };
};

const editOnHoldReleaseDateActionSuccess = (data) => {
    return {
        type: EDIT_ON_HOLD_RELEASE_DATE_SUCCESS,
        payload: data,
    };
};

const editOnHoldReleaseDateActionFailure = (error) => {
    return {
        type: EDIT_ON_HOLD_RELEASE_DATE_FAILURE,
        payload: error,
    };
};
export {
    editOnHoldReleaseDateAction,
    editOnHoldReleaseDateActionRequest,
    editOnHoldReleaseDateActionSuccess,
    editOnHoldReleaseDateActionFailure,
};

import React from "react";
import Birthdaydata from "./Birthdaydata";
import WorkAnniversaryData from "./WorkAnniversaryData";

const BirthdayWorkRender = (props) => {
  const { birthday, workAnniversary } = props;
  return (
    <div>
      {birthday && birthday.length ? <Birthdaydata birthday={birthday} /> : ""}
      {workAnniversary && workAnniversary.length ? (
        <WorkAnniversaryData workAnniversary={workAnniversary} />
      ) : (
        ""
      )}
    </div>
  );
};

export default BirthdayWorkRender;

import React, { useReducer } from 'react'
import SalarySlipModal from './SalarySlipModal'
import { reducer } from '../helper/Constant';
import { currentYear } from '../leaves/Constants';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { downloadIcon } from '../../asset';
import styled from 'styled-components';

const initialState = {
    salarySlipList: [{
        "month": "12345",
        "slip": "Hello"

    }],
    year: currentYear,
    loader: false,
    pageNo: 1,
    count: 10,
    dataCount: 0,
    viewSalarySlip: false,


}



export default function Salaryslip() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const dispatchToAction = useDispatch()
    const {

        salarySlipList,
        year,
        loader,
        count,
        dataCount,
        pageNo,
        viewSalarySlip
    } = state
    const handleOpenSalaryView = (e) => {
        dispatch({
            type: 'typeCheckSet', payload: {
                viewSalarySlip: true
            }
        })
    }
    const closeModal = () => {
        dispatch({
            type: 'typeCheckSet', payload: {
                viewSalarySlip: false
            }
        })
    }
    return (
        <div className="body_container cm_bold ">
            <h3>Salary Slip</h3>
            <div className="table-responsive mt-5">
                <table className="table table-bordered">
                    <thead className="white bg_blue">
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Month</th>
                            <th scope="col" style={{width:"150px"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !_.isEmpty(salarySlipList) ?
                                salarySlipList.map((salarySlip, i) => (

                                    <tr key={salarySlip.departmentId}>
                                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                        <td><span className="cm_col">{salarySlip.month}</span></td>
                                        <td className='d-flex justify-content-between'>
                                            <a href={salarySlip.slip} className="ml-4">
                                                <ActionImage
                                                    src={downloadIcon}
                                                    alt="downloadIcon"
                                                />
                                            </a>
                                            <a>
                                                <i
                                                    onClick={() => handleOpenSalaryView()}
                                                    className="fa fa-eye fa-2x blue ml-2"
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                        </td>
                                    </tr>
                                )) : ''
                        }
                    </tbody>
                </table>
                {
                    loader ? null :
                        salarySlipList.length > 0 ? '' : <h3 className="text-center">No data available</h3>
                }
            </div>
            <SalarySlipModal
                show={viewSalarySlip}
                handleClose={closeModal}
            />
        </div>
    )
}
const ActionImage = styled.img`
      width: 30px;
      height: 30px;
      cursor: pointer;
      `;
import { STATUS_VALUE_ZERO, STATUS_ZERO } from "../../components/attendance/Constants";
import { deleteTeam } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    DELETE_TEAM_REQUEST,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAILURE,
} from "./actionType";


const deleteTeamByAdminAction = (props) => {
    let { useDispatch, getTeam, params } = props
    return (dispatch) => {
        dispatch(deleteTeamByAdminRequest());
        deleteTeam(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamMsg: true,
                        successMessage: "Team Deleted",
                        loader: false,
                        openDeleteTeamModal: false
                    }
                })
                getTeam()
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamMsg: false
                    }
                }), 3000);
            } else if (res.data.statusCode === STATUS_VALUE_ZERO) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        responseMessage: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resTeamFailMsg: true,
                        loader: false
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamFailMsg: false
                    }
                }), 3000);
                getTeam()
            }
        })
            .catch((error) => {
                dispatch(deleteTeamByAdminFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const deleteTeamByAdminRequest = () => {
    return {
        type: DELETE_TEAM_REQUEST,
    };
};
const deleteTeamByAdminSuccess = (data) => {
    return {
        type: DELETE_TEAM_SUCCESS,
        payload: data,
    };
};
const deleteTeamByAdminFailure = (error) => {
    return {
        type: DELETE_TEAM_FAILURE,
        payload: error,
    };
};
export {
    deleteTeamByAdminAction,
    deleteTeamByAdminRequest,
    deleteTeamByAdminSuccess,
    deleteTeamByAdminFailure,
};
import { Select } from "antd";
import _ from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getEmpProfileByAdmin } from "../../../redux/actions";
import { formattedDate, reducer, type } from "../../helper/Constant";
import "../ApplyLeave.css";

const initialState = {
  tempCompOff: [],
  empId: "",
};

const DeleteCompOffTempCompModal = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { tempCompOff } = state;
  const {
    show,
    handleClose,
    deleteCompOffNo,
    handleDeleteCompOffChange,
    deleteCompOffNoErrMsg,
    handleDeleteCompOff,
    employeeId,
    handleSelectEmployeeChange,
    employeeList,
    showTempComOff,
    handleTypeCompOffChange,
    setDispatch,
    showDays,
    compOffCount,
  } = props;
  const { Option } = Select;
  const dispatchToAction = useDispatch();
  const [checkedIndex, setCheckedIndex] = useState(null);

  const handleCheckboxChange = (index, selectedCompOffDays, deleteLeaveId) => {
    setDispatch({
      type: type,
      payload: {
        selectedCompOffDays: selectedCompOffDays,
        deleteLeaveId: deleteLeaveId,
        deleteEmpId: employeeId,
      },
    });
    if (index !== checkedIndex) {
      setCheckedIndex(index);
      setDispatch({
        type: type,
        payload: {
          showDays: false,
        },
      });
    } else {
      setCheckedIndex(null);
      setDispatch({
        type: type,
        payload: {
          deleteCompOffNoErrMsg: "",
          deleteCompOffNo: "",
          showDays: true,
        },
      });
    }
  };
  useEffect(() => {
    setDispatch({
      type: type,
      payload: {
        showDays: true,
      },
    });
  }, []);

  useEffect(() => {
    getData();
    setDispatch({
      type: type,
      payload: {
        deleteEmpId: employeeId,
      },
    });
    // eslint-disable-next-line
  }, [employeeId]);

  const getData = () => {
    dispatchToAction(
      getEmpProfileByAdmin({
        useDispatch: dispatch,
        id: employeeId,
        setDispatch: setDispatch,
      })
    );
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>DELETE COMP OFF</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="confirmation">
          {employeeId && !showTempComOff && (
            <h4>Remaining Comp Off: {compOffCount}</h4>
          )}
          <br />
          <div className="d-flex flex-row justify-content-between">
            <div className="form-group" style={{ width: "14rem" }}>
              <Select
                allowClear={employeeId !== "" ? true : false}
                showSearch
                name="employeeId"
                value={employeeId ? employeeId : " Select Employee"}
                defaultValue="Select Name"
                onChange={handleSelectEmployeeChange}
                optionFilterProp="key"
                filterOption={(input, option) =>
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
              >
                {!_.isEmpty(employeeList) &&
                  employeeList
                    .sort(function (a, b) {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.firstName > b.firstName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map(
                      (employee) =>
                        employee.status == 1 && (
                          <Option
                            key={`${employee.firstName} ${employee.lastName}`}
                            value={employee.employeeId}
                          >
                            <span>
                              {employee.firstName} {employee.lastName} (
                              {employee.empId})
                            </span>
                          </Option>
                        )
                    )}
              </Select>
            </div>
            <div className="form-group" style={{ width: "14rem" }}>
              <Select
                disabled={!employeeId}
                className="cm_leaves"
                placeholder="Deduct Temp Comp Off"
                onChange={handleTypeCompOffChange}
              >
                <Option key="1">Yes</Option>
                <Option key="2">No</Option>
              </Select>
            </div>
          </div>
          {showTempComOff && tempCompOff.length > 0 && (
            <div>
              <h4>Temporary Comp Off Details:</h4>
              {Array.isArray(tempCompOff) &&
                tempCompOff
                  .filter((comp) => new Date(comp.expireDate) > new Date())
                  .map(
                    (comp, index) =>
                      comp.numberOFCompOff > 0 && (
                        <div key={index} className="d-flex mt-3 mb-3">
                          <input
                            type="checkbox"
                            checked={index === checkedIndex}
                            onChange={() =>
                              handleCheckboxChange(
                                index,
                                comp.numberOFCompOff,
                                comp._id
                              )
                            }
                            disabled={
                              checkedIndex !== null && index !== checkedIndex
                            }
                          />
                          <div className="pl-3">
                            <p className="temp-compoff-details">{`Number of Comp Off: ${comp.numberOFCompOff}`}</p>
                            <p className="temp-compoff-details text-secondary">
                              {`Given Date: ${formattedDate(comp.givenDate)}`}{" "}
                              {`Expire Date: ${formattedDate(comp.expireDate)}`}
                            </p>
                          </div>
                        </div>
                      )
                  )}
            </div>
          )}
          <input
            disabled={showDays}
            className="form-control cm_box"
            type="number"
            step="any"
            placeholder=" Enter no. of day"
            name="CompLeave"
            value={deleteCompOffNo}
            onChange={handleDeleteCompOffChange}
          />
          {deleteCompOffNoErrMsg ? (
            <span className="error_msg ml-2">{deleteCompOffNoErrMsg}</span>
          ) : null}
          <div className="text-center mt-5">
            <button
              className="btn btn-danger mr-2 btn-sm cm_btn3"
              onClick={handleDeleteCompOff}
            >
              Delete
            </button>
            <button
              className="btn btn-primary cm_btn2  mr-2 btn-sm cm_btn3"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteCompOffTempCompModal;

import { STATUS_VALUE_ZERO} from "../../components/attendance/Constants";
import { editAward } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    EDIT_AWARD_REQUEST,
    EDIT_AWARD_SUCCESS,
    EDIT_AWARD_FAILURE,
} from "./actionType";


const editAwardAction = (props) => {
    let { useDispatch, getAwardList, params, closeModal } = props
    return (dispatch) => {
        dispatch(editAwardRequest());
        editAward(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardMsg: true,
                        successMessage: "Award Edited",
                        loader: false
                    }
                })
                getAwardList()
                closeModal()
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardMsg: false
                    }
                }), 3000);
            } else if (res.data.statusCode === STATUS_VALUE_ZERO) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        responseMessage: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resAwardFailMsg: true,
                        loader: false
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardFailMsg: false
                    }
                }), 3000);
                getAwardList()
            }
        })
            .catch((error) => {
                dispatch(editAwardFailure(error));
            });
    };
};

const editAwardRequest = () => {
    return {
        type: EDIT_AWARD_REQUEST,
    };
};
const editAwardSuccess = (data) => {
    return {
        type: EDIT_AWARD_SUCCESS,
        payload: data,
    };
};
const editAwardFailure = (error) => {
    return {
        type: EDIT_AWARD_FAILURE,
        payload: error,
    };
};
export {
    editAwardAction,
    editAwardRequest,
    editAwardSuccess,
    editAwardFailure,
};
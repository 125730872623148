import {
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAILURE,
} from "./actionType";

import { deleteContact } from "../../config/services/employeeServices";
import { getContactList } from "./getContactList";

const deleteContactByAdmin = (data, setResDeleteMsg, setResFailMsg, getData, setMiniLoader, closeModal1) => {
    return (dispatch) => {
        dispatch(deleteContactByAdminRequest());
        deleteContact(data)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(deleteContactByAdminFailure(res.data));
                    setResFailMsg(true)
                    getData()
                    setMiniLoader(false)
                } else {
                    dispatch(deleteContactByAdminSuccess(res.data));
                    setResDeleteMsg(true)
                    getData()
                    setMiniLoader(false)
                    closeModal1()
                }
            })
            .catch((error) => {
                dispatch(deleteContactByAdminFailure(error));
                setResFailMsg(true);
            });
    };
};

const deleteContactByAdminRequest = () => {
    return {
        type: DELETE_CONTACT_REQUEST,
    };
};
const deleteContactByAdminSuccess = (data) => {
    return {
        type: DELETE_CONTACT_SUCCESS,
        payload: data,
    };
};
const deleteContactByAdminFailure = (error) => {
    return {
        type: DELETE_CONTACT_FAILURE,
        payload: error,
    };
};
export {
    deleteContactByAdmin,
    deleteContactByAdminRequest,
    deleteContactByAdminSuccess,
    deleteContactByAdminFailure,
};

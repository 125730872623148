import React, { useReducer, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import ResponseFilter from "../../ResponseFilter";
import moment from "moment";
import _ from "lodash";
import { Loader } from "../svg";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Select } from "antd";
import {
  getTicket,
  resolveTicket,
} from "../../config/services/employeeServices";
import { CONSTANTS } from "../../config";
import { Toastify, reducer, setResponseAction, type } from "../helper/Constant";
import {
  categoryType,
  colorType,
  createEditorStateFromHTML,
  getEmployee,
  handleSubmit,
  statusValue,
} from "./Constants";
import AddTicketModal from "./Modal/AddTicketModal";
import PendingTicketModal from "./Modal/PendingTicketModal";
import { EditorState } from "draft-js";
import "./Ticket.css";
import DeleteTicketModal from "./Modal/DeleteTicketModal";
import ViewTicketReasonModal from "./Modal/ViewTicketReasonModal";
import { useDebounce } from "../attendance/Constants";
import qs from "qs";
import EditTicketModal from "./Modal/EditTicketModal";

const { Option } = Select;
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;

const initialState = {
  loader: true,
  openPendingPopup: false,
  ticketDetails: [],
  category: "",
  date: "",
  subject: "",
  description: "",
  status: "1",
  reply: "",
  sortType: SORT_TYPE_CONSTANTS.Descending,
  sortField: "created",
  pageNo: 1,
  count: 10,
  totalTickets: "",
  ticketId: "",
  search: "",
  dataCount: "",
  resMsg: false,
  resFailMsg: false,
  openAddTicketPopup: false,
  emailError: "",
  emails: [],
  descriptionErrorMsg: "",
  subjectErrorMsg: "",
  categoryErrorMsg: "",
  employeeList: [],
  employeeId: "",
  employeeIdErrorMsg: "",
  reOpenCount: 0,
  pendingCount: 0,
  resolveCount: 0,
  successMessage: "",
  responseMessage: "",
  isResolve: false,
  editorState: EditorState.createEmpty(),
  openViewTicketReason: false,
  openDeleteTicket: false,
  delData: "",
  deletedCount: 0,
  openEditModal: false,
  datStatus: "",
};

const AdminTicket = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    search,
    dataCount,
    openPendingPopup,
    ticketDetails,
    sortType,
    sortField,
    loader,
    subject,
    description,
    reply,
    pageNo,
    count,
    status,
    resolveCount,
    pendingCount,
    reOpenCount,
    resMsg,
    resFailMsg,
    successMessage,
    responseMessage,
    openAddTicketPopup,
    emailError,
    emails,
    descriptionErrorMsg,
    subjectErrorMsg,
    categoryErrorMsg,
    employeeList,
    category,
    employeeId,
    employeeIdErrorMsg,
    editorState,
    openViewTicketReason,
    openDeleteTicket,
    delData,
    deletedCount,
    openEditModal,
    datStatus,
    ticketId,
  } = state;
  const history = useHistory();

  const debouncedSearchTerm = useDebounce(search, 1000);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : "",
        status: filtersFromParams.status ? filtersFromParams.status : "1",
        search: filtersFromParams.search ? filtersFromParams.search : "",
      },
    });
  }, [history.location.search]);
  const handleSelectEmployeeChange = (e) => {
    dispatch({
      type: type,
      payload: { employeeId: e, employeeIdErrorMsg: "" },
    });
  };

  const handleSort = (e) => {
    dispatch({ type: type, payload: { loader: true } });
    dispatch({
      type: type,
      payload: {
        sortField: e,
        sortType:
          sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
        pageNo: 1,
      },
    });
  };

  const handlePageChange = (pageNo) => {
    dispatch({ type: type, payload: { pageNo, loader: true } });
  };

  const handleEmailChange = (e) => {
    dispatch({ type: type, payload: { emailError: "", emails: e } });
  };

  const handleChange = (e) => {
    dispatch({
      type: type,
      payload: {
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
        [`${e.target.name}ErrorMsg`]: "",
      },
    });
  };

  const handleCategoryChange = (e) => {
    dispatch({ type: type, payload: { category: e, categoryErrorMsg: "" } });
  };

  const getTicketList = () => {
    const params = `count=${count}&pageNo=${
      pageNo - 1
    }&search=${search.trim()}&status=${status}&sortType=${sortType}&sortField=${sortField}`;

    history.push(
      `admin-ticket?&pageNo=${pageNo}&sortField=${sortField}&sortType=${sortType}&search=${search}&status=${status}`
    );
    getTicket(params)
      .then((res) => {
        const { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch({
            type: type,
            payload: {
              ticketDetails: resData.ticketDetails,
              totalTickets: resData.totalTickets,
              dataCount: resData.dataCount,
              reOpenCount: resData.reOpenCount,
              pendingCount: resData.pendingCount,
              resolveCount: resData.resolveCount,
              deletedCount: resData.deletedCount,
              loader: false,
            },
          });
        } else if (res.data.statusCode === 0) {
          dispatch({ type: type, payload: { loader: false } });
        }
      })
      .catch((error) => {
        dispatch({
          type: type,
          payload: { resFailMsg: true, responseMessage: error, loader: false },
        });
      });

    setTimeout(() => {
      dispatch({ type: type, payload: { resFailMsg: false } });
    }, 3000);
  };
  useEffect(() => {
    getTicketList();
  }, [pageNo, debouncedSearchTerm, sortType, sortField, status]);

  const handleStatusSubmit = (event) => {
    event.preventDefault();
    const obj = {
      ticketId: state.ticketId,
      reply: state.reply,
      status: 2,
    };
    dispatch({ type: type, payload: { loader: true } });
    resolveTicket(obj)
      .then((res) => {
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              resMsg: true,
              successMessage: "Ticket Resolve",
              loader: false,
            },
          });
          getTicketList();
          closeModal();
          setResponseAction({ dispatch });
        } else {
          dispatch({
            type: type,
            payload: {
              resFailMsg: true,
              responseMessage: res?.data?.error?.responseMessage,
              loader: false,
            },
          });
          getTicketList();
          closeModal();
          setResponseAction({ dispatch });
        }
      })
      .catch((error) => {
        dispatch({
          type: type,
          payload: { resFailMsg: true, responseMessage: error, loader: false },
        });
        getTicketList();
        closeModal();
        setResponseAction({ dispatch });
      });
  };

  const handleAction = (e, data) => {
    dispatch({ type: type, payload: { loader: true } });
    const obj = {
      ticketId: data.ticketId,
      reply: state.reply,
      status: e,
    };

    resolveTicket(obj)
      .then((res) => {
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              resMsg: true,
              successMessage: "Status Change ",
            },
          });
          getTicketList();
        } else {
          dispatch({
            type: type,
            payload: {
              resFailMsg: true,
              responseMessage: res?.data?.error.responseMessage,
              loader: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: type,
          payload: { resFailMsg: true, responseMessage: error },
        });
      });

    setTimeout(() => {
      dispatch({ type: type, payload: { resMsg: false, resFailMsg: false } });
    }, 3000);
  };

  const handleOpenPendingPopup = (data) => {
    dispatch({
      type: type,
      payload: {
        openPendingPopup: true,
        subject: data.subject,
        ticketId: data.ticketId,
        description: data.description,
        datStatus: data.status,
      },
    });
  };
  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        openPendingPopup: false,
        openStatusInfoPopup: false,
        openViewTicketReason: false,
        openDeleteTicket: false,
        openAddTicketPopup: false,
        openEditModal: false,
        reply: "",
        emailError: "",
        emails: [],
        descriptionErrorMsg: "",
        subjectErrorMsg: "",
        categoryErrorMsg: "",
        employeeId: "",
        subject: "",
        employeeIdErrorMsg: "",
        editorState: EditorState.createEmpty(),
        delData: "",
        description: "",
        category: "",
        ticketId: "",
      },
    });
  };

  const handleOpenEditTicketPopup = (data) => {
    if (data) {
      dispatch({
        type: type,
        payload: {
          openEditModal: true,
          subject: data.subject,
          employeeId: data.employeeId,
          editorState: createEditorStateFromHTML(data.description),
          category: data.category,
          ticketId: data.ticketId,
        },
      });
    }
  };

  const handleSearch = (e) => {
    dispatch({ type: type, payload: { search: e.target.value, pageNo: 1 } });
  };

  useEffect(() => {
    getEmployee({ dispatch: dispatch });
  }, []);

  const handleFilter = (e) => {
    dispatch({ type: type, payload: { status: e, pageNo: 1, loader: true } });
  };
  const handleSelectChange = () => {
    dispatch({ type: type, payload: { search: "", pageNo: 1 } });
  };
  const handleOpenAddTicketPopup = () => {
    dispatch({
      type: type,
      payload: { openAddTicketPopup: true },
    });
    if (openAddTicketPopup) {
      getEmployee({ dispatch: dispatch });
    }
  };
  const handleOpenViewReason = (data) => {
    dispatch({
      type: type,
      payload: { openViewTicketReason: true, description: data },
    });
  };

  const handleOpenDeleteTicketPopup = (data) => {
    dispatch({
      type: type,
      payload: { openDeleteTicket: true, delData: data.ticketId },
    });
  };

  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <Toastify
        resMsg={resMsg}
        resMsgFail={resFailMsg}
        text={successMessage}
        responseMessage={responseMessage}
      />
      <h3>VIEW TICKET</h3>
      <div className="text-left cm_ticket_btn cm_leave_btn mt-5">
        <button
          className={`btn btn-primary ${status === "1" ? "active" : ""}`}
          onClick={() => handleFilter("1")}
        >
          <span className="tabtext">Pending {pendingCount}</span>
        </button>
        <button
          className={`btn btn-primary ${status === "2" ? "active" : ""}`}
          onClick={() => handleFilter("2")}
        >
          <span className="tabtext"> Resolved {resolveCount}</span>
        </button>
        <button
          className={`btn btn-primary ${status === "3" ? "active" : ""}`}
          onClick={() => handleFilter("3")}
        >
          <span className="tabtext">Reopen {reOpenCount}</span>
        </button>
        <button
          className={`btn btn-primary ${status === "4" ? "active" : ""}`}
          onClick={() => handleFilter("4")}
        >
          <span className="tabtext">Deleted {deletedCount}</span>
        </button>
      </div>

      <div className="row d-flex justify-content-end ">
        <div className="col-md-6 search_device_bar4">
          <span
            className="btn btn-primary add_empBtn"
            onClick={handleOpenAddTicketPopup}
          >
            Create Ticket
          </span>
          <input
            className="form-control mr-lg-2 cm_box"
            type="search"
            maxLength={25}
            placeholder="Search by name/E.Id"
            aria-label="Search"
            value={search}
            onChange={handleSearch}
          />
          {search ? (
            <span className="cm_clear4" onClick={handleSelectChange}>
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </span>
          ) : (
            <span className="cm_clear4">
              <i className="fa fa-search" aria-hidden="true"></i>
            </span>
          )}
        </div>
      </div>
      <div>
        <div className="table-responsive ticket_table">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">
                  Name
                  <i
                    className={
                      sortType === SORT_TYPE_CONSTANTS.Descending &&
                      sortField === "firstName"
                        ? "fa fa-sort-alpha-desc ml-2"
                        : "fa fa-sort-alpha-asc ml-2"
                    }
                    onClick={() => handleSort("firstName")}
                  ></i>
                </th>
                <th scope="col">
                  Employee ID
                  <i
                    className={
                      sortType === SORT_TYPE_CONSTANTS.Descending &&
                      sortField === "empId"
                        ? "fa fa-sort-numeric-desc ml-2"
                        : "fa fa-sort-numeric-asc ml-2"
                    }
                    onClick={() => handleSort("empId")}
                  ></i>
                </th>
                <th scope="col">
                  Date
                  <i
                    className={
                      sortType === SORT_TYPE_CONSTANTS.Descending &&
                      sortField === "date"
                        ? "fa fa-sort-numeric-desc ml-2"
                        : "fa fa-sort-numeric-asc ml-2"
                    }
                    onClick={() => handleSort("date")}
                  ></i>
                </th>
                <th scope="col">Category</th>
                <th scope="col">Subject</th>
                {status === "2" && <th scope="col">Resolved By</th>}
                {status === "4" && <th scope="col">Deleted By</th>}
                <th className="table_width6">Reason</th>
                <th className="table_width1">Status</th>
                {(status === "1" || status === "3") && (
                  <th className="table_width1">Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(ticketDetails)
                ? ticketDetails.map((data, i) => (
                    <tr key={data.ticketId}>
                      <td>{i + 1 + (pageNo - 1) * count}</td>
                      <td>
                        <span className="cm_col">
                          <Link onClick={() => handleOpenPendingPopup(data)}>
                            {data.firstName} {data.lastName}
                          </Link>
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">{data.empId}</span>
                      </td>
                      <td>{moment(data.created).format("DD/MM/YYYY")}</td>
                      <td>{categoryType(data.category)}</td>
                      <td>
                        <span className="cm_col">{data.subject}</span>
                      </td>
                      {(status === "2" ||
                        status === "4") && (
                          <td>
                            <span className="d-flex justify-content-center align-items-center">
                              {data.reslovedByName ? (
                                <>
                                  {data.reslovedByName && data.reslovedByName} {data.reslovedById && (`(${data.reslovedById})`)}
                                </>
                              ) : (
                                "_"
                              )}
                            </span>
                          </td>
                        )}
                      <td>
                        <span className="cm_col ml-2 mt-2">
                          <button
                            className="badge-info view_ticket_reason"
                            onClick={() =>
                              handleOpenViewReason(data.description)
                            }
                          >
                            View
                          </button>
                        </span>
                      </td>
                      {data.status === 1 &&
                        status === "4" &&
                        data.isDeleted && (
                          <td
                            className={`${colorType(data.status)} table_width6`}
                          >
                            <div className="mt-2">
                              {statusValue(data.status)}
                            </div>
                          </td>
                        )}
                      {data.status === 1 && status === "1" && (
                        <td>
                          <div className="cm_ongoing">
                            <Select
                              value={statusValue(data.status)}
                              style={{ width: "107px" }}
                              onSelect={(e) => handleAction(e, data)}
                            >
                              <Option value="2" id="2">
                                Resolve
                              </Option>
                            </Select>
                          </div>
                        </td>
                      )}

                      {data.status === 2 && status === "2" && (
                        <td>
                          <div className="cm_approve">
                            <Select
                              value={statusValue(data.status)}
                              onSelect={(e) => handleAction(e, data)}
                              style={{ width: "107px" }}
                            >
                              <Option value="3" id="3">
                                Reopen
                              </Option>
                            </Select>
                          </div>
                        </td>
                      )}

                      {data.status === 3 && status === "3" && (
                        <td>
                          <div className="cm_reject">
                            <Select
                              value={statusValue(data.status)}
                              style={{ width: "107px" }}
                              onSelect={(e) => handleAction(e, data)}
                            >
                              <Option value="2" id="2">
                                Resolve
                              </Option>
                            </Select>
                          </div>
                        </td>
                      )}

                      {(status === "1" || status === "3") && (
                        <td>
                          <div className="d-flex justify-content-around">
                            {status === "1" && (
                              <span
                                onClick={() =>
                                  handleOpenDeleteTicketPopup(data)
                                }
                                className="d-flex justify-content-center mt-3"
                              >
                                <i
                                  className="fa fa-trash-o red"
                                  title="Delete ticket"
                                ></i>
                              </span>
                            )}
                            {(status === "1" || status === "3") && (
                              <span
                                onClick={() => handleOpenEditTicketPopup(data)}
                                className="d-flex justify-content-center mt-3"
                              >
                                <i
                                  className="fa fa-pencil green"
                                  title="Edit ticket"
                                ></i>
                              </span>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          {ticketDetails.length <= 0 ? (
            <h3 className="text-center">No data available</h3>
          ) : (
            ""
          )}
        </div>

        {(dataCount > 10 ||
          (dataCount < 11 && dataCount > 0 && pageNo === 2)) && (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={dataCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        )}
      </div>
      <PendingTicketModal
        openPendingPopup={openPendingPopup}
        closeModal={closeModal}
        handleStatusSubmit={handleStatusSubmit}
        subject={subject}
        description={description}
        status={datStatus}
        reply={reply}
        handleChange={handleChange}
      />
      <AddTicketModal
        openAddTicketPopup={openAddTicketPopup}
        categoryErrorMsg={categoryErrorMsg}
        subject={subject}
        subjectErrorMsg={subjectErrorMsg}
        emailError={emailError}
        emails={emails}
        handleEmailChange={handleEmailChange}
        employeeList={employeeList}
        descriptionErrorMsg={descriptionErrorMsg}
        description={description}
        handleChange={handleChange}
        editorState={editorState}
        dispatch={dispatch}
        closeModal={closeModal}
        handleSubmit={(e) =>
          handleSubmit(e, state, getTicketList, closeModal, dispatch, true)
        }
        category={category}
        handleCategoryChange={handleCategoryChange}
        admin={true}
        employeeId={employeeId}
        handleSelectEmployeeChange={handleSelectEmployeeChange}
        employeeIdErrorMsg={employeeIdErrorMsg}
      />
      <DeleteTicketModal
        openDeleteTicket={openDeleteTicket}
        closeModal={closeModal}
        ticketId={delData}
        dispatch={dispatch}
        getViewTicket={getTicketList}
      />

      <EditTicketModal
        openEditModal={openEditModal}
        closeModal={closeModal}
        subject={subject}
        emailError={emailError}
        emails={emails}
        handleEmailChange={handleEmailChange}
        employeeList={employeeList}
        description={description}
        handleChange={handleChange}
        editorState={editorState}
        dispatch={dispatch}
        category={category}
        handleCategoryChange={handleCategoryChange}
        isAdmin={true}
        employeeId={employeeId}
        handleSelectEmployeeChange={handleSelectEmployeeChange}
        employeeIdErrorMsg={employeeIdErrorMsg}
        categoryErrorMsg={categoryErrorMsg}
        subjectErrorMsg={subjectErrorMsg}
        descriptionErrorMsg={descriptionErrorMsg}
        getTicketList={getTicketList}
        state={state}
        ticketId={ticketId}
      />
      <ViewTicketReasonModal
        openViewTicketReason={openViewTicketReason}
        closeModal={closeModal}
        description={description}
      />
    </div>
  );
};

export default AdminTicket;

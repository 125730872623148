import React from "react";
import { NavLink } from "react-router-dom";
import {
  DashboardIcon,
  EmployeeIcon,
  AttendanceIcon,
  LeaveIcon,
  ProjectIcon,
  DeviceIcon,
  DepartmentIcon,
  ReimbursementIcon,
  HolidayIcon,
  TicketIcon,
  AppraisalIcon,
  FoodOrdersIcon,
  ResumeIcon,
  TransactionIcon,
  InterviewIcon,
  EmailIcon,
  PrivacyIcon,
  CourierIcon,
  OrganizationIcon,
} from "../svg";
import { Accordion, Button } from "react-bootstrap";
import { isAdmin } from "../helper/Constant";

const teamHead = JSON.parse(localStorage.getItem("teamHead"));

const removeMenuClass = () => document.body.classList.remove("menu_open");
const email = localStorage.getItem("salaryAccessEmail");
const id = localStorage.getItem("employeeId");
const SideBarMenu = (props) =>
  isAdmin === "2" ? ( //Employee access
    <ul>
      <li>
        <NavLink onClick={removeMenuClass()} exact to="/employee">
          <span className="menu_icon">
            <DashboardIcon />
          </span>{" "}
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/attendance">
          <span className="menu_icon">
            <AttendanceIcon />
          </span>{" "}
          Daily Attendance
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/leaves">
          <span className="menu_icon">
            <LeaveIcon />
          </span>{" "}
          Leave Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/projects">
          <span className="menu_icon">
            <ProjectIcon />
          </span>{" "}
          Projects Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/organization-chart">
          <span className="menu_icon">
            <OrganizationIcon />
          </span>
          Organization Chart
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/reimbursement">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>{" "}
          Reimbursement
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/addTicket">
          <span className="menu_icon">
            <TicketIcon />
          </span>{" "}
          Add Ticket
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/resumeDetails">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          My Resume
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/employee-device">
          <span className="menu_icon">
            <DeviceIcon />
          </span>
          Device Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/investment">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>
          Investment Form
        </NavLink>
      </li>
      {props?.teamHead && props?.teamHead.length > 0 ? (
        <li>
          <NavLink
            onClick={removeMenuClass()}
            to={
              props?.teamHead.length === 1
                ? `/admin-team/${teamHead[0]?.teamId}`
                : "/admin-team"
            }
          >
            <span className="menu_icon">
              <img
                className="image_skill"
                src={require("../../asset/imgs/team.png")}
                alt="team_icon"
              />
            </span>
            Team Management
          </NavLink>
        </li>
      ) : (
        ""
      )}
      <li>
        <NavLink onClick={removeMenuClass()} to="/user-hrActivity">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ActivityIcon.png")}
              alt="activity_icon"
            />
          </span>
          My Activity
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/interviewer">
          <span className="menu_icon">
            <InterviewIcon />
          </span>
          Interviews
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/awards">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/award.png")}
              alt="skill_icon"
            />
          </span>
          Award Managemant
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/holiday">
          <span className="menu_icon">
            <HolidayIcon />
          </span>{" "}
          Holiday List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  ) : isAdmin === "6" ? ( //Sales access
    <ul>
      <li>
        <NavLink onClick={removeMenuClass()} exact to="/employee">
          <span className="menu_icon">
            <DashboardIcon />
          </span>{" "}
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/attendance">
          <span className="menu_icon">
            <AttendanceIcon />
          </span>{" "}
          Daily Attendance
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/leaves">
          <span className="menu_icon">
            <LeaveIcon />
          </span>{" "}
          Leave Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/organization-chart">
          <span className="menu_icon">
            <OrganizationIcon />
          </span>
          Organization Chart
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/projects">
          <span className="menu_icon">
            <ProjectIcon />
          </span>{" "}
          Projects Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/reimbursement">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>{" "}
          Reimbursement
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/addTicket">
          <span className="menu_icon">
            <TicketIcon />
          </span>{" "}
          Add Ticket
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/employee-device">
          <span className="menu_icon">
            <DeviceIcon />
          </span>
          Device Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/investment">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>
          Investment Form
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/user-hrActivity">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ActivityIcon.png")}
              alt="activity_icon"
            />
          </span>
          My Activity
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/interviewer">
          <span className="menu_icon">
            <InterviewIcon />
          </span>
          Interviews
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/contact">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ContactIcon.png")}
              alt="contact_icon"
            />
          </span>
          Contact
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/resumeDetails">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          My Resume
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/holiday">
          <span className="menu_icon">
            <HolidayIcon />
          </span>{" "}
          Holiday List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  ) : isAdmin === "3" ? ( //Vendor access
    <ul>
      <li>
        <NavLink onClick={removeMenuClass()} exact to="/">
          <span className="menu_icon">
            <DashboardIcon />
          </span>{" "}
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/vendors">
          <span className="menu_icon">
            <FoodOrdersIcon />
          </span>
          Food Order List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  ) : isAdmin === "4" ? ( //HR(admin) access
    <ul>
      <Accordion>
        <Accordion.Toggle as={Button} eventKey="7" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <DashboardIcon />
                </span>{" "}
                Dashboard
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="7">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/employee">
                <span className="menu_icon">
                  <DashboardIcon />
                </span>{" "}
                My Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} exact to="/">
                <span className="menu_icon">
                  <DashboardIcon />
                </span>{" "}
                Admin Dashboard
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
        <li>
          <NavLink onClick={removeMenuClass()} to="/admin-employee">
            <span className="menu_icon">
              <EmployeeIcon />
            </span>
            Employee Management
          </NavLink>
        </li>
        <Accordion.Toggle as={Button} eventKey="0" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <AttendanceIcon />
                </span>{" "}
                Daily Attendance
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/attendance">
                <span className="menu_icon">
                  <AttendanceIcon />
                </span>{" "}
                My Attendance
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-attendance">
                <span className="menu_icon">
                  <AttendanceIcon />
                </span>{" "}
                Admin Attendance
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>

        <Accordion.Toggle as={Button} eventKey="1" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <LeaveIcon />
                </span>{" "}
                Leave Management
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/leaves">
                <span className="menu_icon">
                  <LeaveIcon />
                </span>{" "}
                My Leave
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-leave">
                <span className="menu_icon">
                  <LeaveIcon />
                </span>{" "}
                Admin Leave
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
        <li>
          <NavLink onClick={removeMenuClass()} to="/organization-chart">
            <span className="menu_icon">
              <OrganizationIcon />
            </span>
            Organization Chart
          </NavLink>
        </li>
        <li>
          <NavLink onClick={removeMenuClass()} to="/investment">
            <span className="menu_icon">
              <ReimbursementIcon />
            </span>
            Investment Form
          </NavLink>
        </li>
        <li>
          <NavLink onClick={removeMenuClass()} to="/salary">
            <span className="menu_icon">
              <img
                className="image_skill"
                src={require("../../asset/imgs/salary.png")}
                alt="salary"
              />
            </span>
            Salary Management
          </NavLink>
        </li>
        <Accordion.Toggle as={Button} eventKey="2" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <ReimbursementIcon />
                </span>{" "}
                Reimbursement
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/reimbursement">
                <span className="menu_icon">
                  <ReimbursementIcon />
                </span>{" "}
                My Reimbursement
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-reimbursement">
                <span className="menu_icon">
                  <ReimbursementIcon />
                </span>{" "}
                Employees Reimbursement
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} eventKey="5" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon ">
                  <TicketIcon />
                </span>{" "}
                Tickets
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="5">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/addTicket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>
                My Ticket
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-ticket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>{" "}
                Admin Ticket
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} eventKey="3" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <ResumeIcon />
                </span>{" "}
                Resume Section
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/resumeDetails">
                <span className="menu_icon">
                  <ResumeIcon />
                </span>{" "}
                My Resume
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/resumeSection">
                <span className="menu_icon">
                  <ResumeIcon />
                </span>{" "}
                Employees Resume
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>

        <Accordion.Toggle as={Button} eventKey="4" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable safari">
                <span className="menu_icon">
                  <InterviewIcon />
                </span>{" "}
                Interviews
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/interviewer">
                <span className="menu_icon">
                  <InterviewIcon />
                </span>{" "}
                My Interviews
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/interview">
                <span className="menu_icon">
                  <InterviewIcon />
                </span>{" "}
                All Interviews
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>

        <Accordion.Toggle as={Button} eventKey="6" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                Device Management
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/employee-device">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                My Device
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-device">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                Admin Device
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
      </Accordion>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-projects">
          <span className="menu_icon">
            <ProjectIcon />
          </span>
          Projects Management
        </NavLink>
      </li>

      <Accordion on></Accordion>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-department">
          <span className="menu_icon">
            <DepartmentIcon />
          </span>
          Department Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-designation">
          <span className="menu_icon">
            <i className="fa fa-id-badge" aria-hidden="true"></i>
          </span>
          Designation Management
        </NavLink>
      </li>

      <Accordion on></Accordion>

      <li>
        <NavLink onClick={removeMenuClass()} to="/transactions">
          <span className="menu_icon">
            <TransactionIcon />
          </span>
          Transactions
        </NavLink>
      </li>
      <Accordion on></Accordion>

      <Accordion on></Accordion>
      {(email === "shubhi.khare@mobcoder.com" ||
        email === "shubhi.khare@yopmail.com") && (
        <li>
          <NavLink onClick={removeMenuClass()} to="/salary">
            <span className="menu_icon">
              <img
                className="image_skill"
                src={require("../../asset/imgs/salary.png")}
                alt="salary"
              />
            </span>
            Salary Management
          </NavLink>
        </li>
      )}
      <li>
        <NavLink onClick={removeMenuClass()} to="/courier">
          <span className="menu_icon">
            <CourierIcon />
          </span>
          Courier Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/organization-chart">
          <span className="menu_icon">
            <OrganizationIcon />
          </span>
          Organization Chart
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/templates">
          <span className="menu_icon">
            <EmailIcon />
          </span>
          Email Template
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-holiday">
          <span className="menu_icon">
            <HolidayIcon />
          </span>
          Holiday List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-appraisal">
          <span className="menu_icon">
            <AppraisalIcon />
          </span>
          Appraisals
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-skill">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/svg/skill.svg")}
              alt="skill_icon"
            />
          </span>
          Skill Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-team">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/team.png")}
              alt="team_icon"
            />
          </span>
          Team Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/awards">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/award.png")}
              alt="skill_icon"
            />
          </span>
          Award Managemant
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-hrActivity">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ActivityIcon.png")}
              alt="activity_icon"
            />
          </span>
          HR Activity
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  ) : isAdmin === "5" ? ( //Accountant access
    <ul>
      <li>
        <NavLink onClick={removeMenuClass()} exact to="/employee">
          <span className="menu_icon">
            <DashboardIcon />
          </span>{" "}
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/attendance">
          <span className="menu_icon">
            <AttendanceIcon />
          </span>{" "}
          Daily Attendance
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/leaves">
          <span className="menu_icon">
            <LeaveIcon />
          </span>{" "}
          Leave Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/organization-chart">
          <span className="menu_icon">
            <OrganizationIcon />
          </span>
          Organization Chart
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/projects">
          <span className="menu_icon">
            <ProjectIcon />
          </span>{" "}
          Projects Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/resumeDetails">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          My Resume
        </NavLink>
      </li>
      <Accordion>
        <Accordion.Toggle as={Button} eventKey="0" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <ReimbursementIcon />
                </span>{" "}
                Reimbursement
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/reimbursement">
                <span className="menu_icon">
                  <ReimbursementIcon />
                </span>{" "}
                My Reimbursement
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-reimbursement">
                <span className="menu_icon">
                  <ReimbursementIcon />
                </span>{" "}
                Emplyoees Reimbursement
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} eventKey="1" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <TicketIcon />
                </span>{" "}
                Tickets
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/addTicket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>
                My Ticket
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-ticket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>{" "}
                Admin Ticket
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
      </Accordion>
      <li>
        <NavLink onClick={removeMenuClass()} to="/employee-device">
          <span className="menu_icon">
            <DeviceIcon />
          </span>
          Device Records
        </NavLink>
      </li>

      <Accordion>
        <Accordion.Toggle as={Button} eventKey="1" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <LeaveIcon />
                </span>{" "}
                Investment Management
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass} to="/investment">
                <span className="menu_icon">
                  <LeaveIcon />
                </span>{" "}
                My Investment
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-investment">
                <span className="menu_icon">
                  <LeaveIcon />
                </span>{" "}
                Admin Investment
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
      </Accordion>
      <li>
        <NavLink onClick={removeMenuClass()} to="/transactions">
          <span className="menu_icon">
            <TransactionIcon />
          </span>
          Transactions
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/vendors">
          <span className="menu_icon">
            <FoodOrdersIcon />
          </span>
          Food Order List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/holiday">
          <span className="menu_icon">
            <HolidayIcon />
          </span>{" "}
          Holiday List
        </NavLink>
      </li>

      <li>
        <NavLink onClick={removeMenuClass()} to="/user-hrActivity">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ActivityIcon.png")}
              alt="activity_icon"
            />
          </span>
          My Activity
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  ) : isAdmin === "7" ? ( //IT access
    <ul>
      <li>
        <NavLink onClick={removeMenuClass()} exact to="/employee">
          <span className="menu_icon">
            <DashboardIcon />
          </span>{" "}
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/attendance">
          <span className="menu_icon">
            <AttendanceIcon />
          </span>{" "}
          Daily Attendance
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/leaves">
          <span className="menu_icon">
            <LeaveIcon />
          </span>{" "}
          Leave Records
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/organization-chart">
          <span className="menu_icon">
            <OrganizationIcon />
          </span>
          Organization Chart
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/resumeDetails">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          My Resume
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/reimbursement">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>{" "}
          Reimbursement
        </NavLink>
      </li>
      <Accordion on>
        <Accordion.Toggle as={Button} eventKey="0" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                Device Management
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/employee-device">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                My Device
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-device">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                Admin Device
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} eventKey="1" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <TicketIcon />
                </span>{" "}
                Tickets
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/addTicket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>
                My Ticket
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-ticket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>{" "}
                Admin Ticket
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
      </Accordion>
      <li>
        <NavLink onClick={removeMenuClass()} to="/holiday">
          <span className="menu_icon">
            <HolidayIcon />
          </span>{" "}
          Holiday List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/investment">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>
          Investment Form
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/user-hrActivity">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ActivityIcon.png")}
              alt="activity_icon"
            />
          </span>
          My Activity
        </NavLink>
      </li>{" "}
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  ) : isAdmin === "8" ? (
    <ul>
      <li>
        <NavLink onClick={removeMenuClass()} exact to="/employee">
          <span className="menu_icon">
            <DashboardIcon />
          </span>{" "}
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-employee">
          <span className="menu_icon">
            <EmployeeIcon />
          </span>
          Employee Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/attendance">
          <span className="menu_icon">
            <AttendanceIcon />
          </span>{" "}
          Daily Attendance
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/leaves">
          <span className="menu_icon">
            <LeaveIcon />
          </span>{" "}
          Leave Records
        </NavLink>
      </li>
      <Accordion on>
        <Accordion.Toggle as={Button} eventKey="0" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                Device Management
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/employee-device">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                My Device
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-device">
                <span className="menu_icon">
                  <DeviceIcon />
                </span>{" "}
                Admin Device
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} eventKey="1" className="toogle_btn">
          <ul>
            <li>
              <div className="expandable">
                <span className="menu_icon">
                  <TicketIcon />
                </span>{" "}
                Tickets
              </div>
            </li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <ul>
            <li>
              <NavLink onClick={removeMenuClass()} to="/addTicket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>
                My Ticket
              </NavLink>
            </li>
            <li>
              <NavLink onClick={removeMenuClass()} to="/admin-ticket">
                <span className="menu_icon">
                  <TicketIcon />
                </span>{" "}
                Admin Ticket
              </NavLink>
            </li>
          </ul>
        </Accordion.Collapse>
      </Accordion>
      <li>
        <NavLink onClick={removeMenuClass()} to="/transactions">
          <span className="menu_icon">
            <TransactionIcon />
          </span>
          Transactions
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/vendors">
          <span className="menu_icon">
            <FoodOrdersIcon />
          </span>
          Food Order List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/courier">
          <span className="menu_icon">
            <CourierIcon />˝
          </span>
          Courier Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/reimbursement">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>{" "}
          Reimbursement
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/user-hrActivity">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ActivityIcon.png")}
              alt="activity_icon"
            />
          </span>
          My Activity
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/awards">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/award.png")}
              alt="skill_icon"
            />
          </span>
          Award Managemant
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/holiday">
          <span className="menu_icon">
            <HolidayIcon />
          </span>{" "}
          Holiday List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/organization-chart">
          <span className="menu_icon">
            <OrganizationIcon />
          </span>
          Organization Chart
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/investment">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>
          Investment Form
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  ) : (
    //superAdmin access
    <ul>
      <li>
        <NavLink onClick={removeMenuClass()} exact to="/">
          <span className="menu_icon">
            <DashboardIcon />
          </span>{" "}
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-employee">
          <span className="menu_icon">
            <EmployeeIcon />
          </span>
          Employee Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-attendance">
          <span className="menu_icon">
            <AttendanceIcon />
          </span>
          Daily Attendance
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-leave">
          <span className="menu_icon">
            <LeaveIcon />
          </span>
          Leave Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-projects">
          <span className="menu_icon">
            <ProjectIcon />
          </span>
          Projects Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-device">
          <span className="menu_icon">
            <DeviceIcon />
          </span>
          Device Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-department">
          <span className="menu_icon">
            <DepartmentIcon />
          </span>
          Department Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-designation">
          <span className="menu_icon">
            <i className="fa fa-id-badge" aria-hidden="true"></i>
          </span>
          Designation Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/resumeSection">
          <span className="menu_icon">
            <ResumeIcon />
          </span>
          Resume Section
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/vendors">
          <span className="menu_icon">
            <FoodOrdersIcon />
          </span>
          Food Order List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/transactions">
          <span className="menu_icon">
            <TransactionIcon />
          </span>
          Transactions
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/interview">
          <span className="menu_icon">
            <InterviewIcon />
          </span>
          Interviews
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-reimbursement">
          <span className="menu_icon">
            <ReimbursementIcon />
          </span>
          Reimbursement
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/templates">
          <span className="menu_icon">
            <EmailIcon />
          </span>
          Email Template
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-holiday">
          <span className="menu_icon">
            <HolidayIcon />
          </span>
          Holiday List
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-ticket">
          <span className="menu_icon">
            <TicketIcon />
          </span>
          Ticket
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-appraisal">
          <span className="menu_icon">
            <AppraisalIcon />
          </span>
          Appraisals
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-skill">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/svg/skill.svg")}
              alt="skill_icon"
            />
          </span>
          Skill Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-hrActivity">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ActivityIcon.png")}
              alt="activity_icon"
            />
          </span>
          Admin Activity
        </NavLink>
      </li>

      <li>
        <NavLink onClick={removeMenuClass()} to="/courier">
          <span className="menu_icon">
            <CourierIcon />˝
          </span>
          Courier Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/organization-chart">
          <span className="menu_icon">
            <OrganizationIcon />
          </span>
          Organization Chart
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/contact">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/ContactIcon.png")}
              alt="contact_icon"
            />
          </span>
          Contact
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/admin-team">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/team.png")}
              alt="team_icon"
            />
          </span>
          Team Management
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/awards">
          <span className="menu_icon">
            <img
              className="image_skill"
              src={require("../../asset/imgs/award.png")}
              alt="skill_icon"
            />
          </span>
          Award Managemant
        </NavLink>
      </li>
      <li>
        <NavLink onClick={removeMenuClass()} to="/policies">
          <span className="menu_icon">
            {" "}
            <PrivacyIcon />
          </span>
          Policies
        </NavLink>
      </li>
    </ul>
  );

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamHead: [],
    };
  }
  componentDidMount() {
    this.setState(JSON.parse(localStorage.getItem("teamHead")));
  }
  render() {
    return (
      <div className="sidebar">
        <div className="cm_close" onClick={() => removeMenuClass()}>
          <i className="fa fa-times" />
        </div>
        <img
          className="logo-white"
          src={require("../../asset/imgs/logo-white.png")}
          alt=""
        />
        <SideBarMenu teamHead={teamHead} />
      </div>
    );
  }
}
export default SideBar;

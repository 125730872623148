import React, { Component } from 'react';
import ResponseFilter from '../../ResponseFilter';
import _ from 'lodash';
import { Loader } from '../svg';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { Collapse, Icon } from 'antd';
import { dailyWorkByProjectId } from '../../config/services/employeeServices';
const { Panel } = Collapse;


const colors = ['cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue', 'cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue', 'cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue', 'cm_red', 'cm_green', 'cm_yellow', 'cm_black', 'cm_blue'];

class AllActivityLog extends Component {
  constructor(props) {

    super(props);

    this.state = {
      loader: true,
      dailyWorkDetails: [],
      dataCount: '',
      sortType: 2,
      sortField: "created",
      pageNo: 1,
      count: 10,
    }

  }

  getAllActivityLogs = () => {
    let { sortType, sortField, pageNo, count } = this.state
    let params = `count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&projectId=${this.props.match.params.id}`;

    dailyWorkByProjectId(params).then(res => {
      if (res.data.statusCode == 1) {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          this.setState({
            loader:false,
            dailyWorkDetails: resData.dailyWorkDetails,
            dataCount: resData.dataCount,
            projectId: resData.dailyWorkDetails[0].projectId
          })
        }
        else{
          this.setState({
              loader:false,
           })

      }
      }
    })
  }

  componentDidMount() {

    this.getAllActivityLogs();
  }
  render() {
    let { dailyWorkDetails,loader, projectId ,pageNo,dataCount, count} = this.state;

    return (
      <div className="body_container">
           {

loader && <Loader />
}
        <div className="cm_breadcrumb cm_bold">
          <ul>
            <li><Link to='/admin-projects'>PROJECT</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li><Link to={`/admin-projects/project-detail/${projectId}`}>PROJECT DETAILS</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li>ACTIVITY LOGS</li>
          </ul>
        </div>

        <div className="mt-5">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
           
          >
            {
              !_.isEmpty(dailyWorkDetails) &&
              dailyWorkDetails.map((dailyWork, i) => (
                <Panel
                  header={dailyWork.firstName +" "+ dailyWork.lastName+"  "+moment(dailyWork.date).format('DD/MM/YYYY')}
                  key={dailyWork.dailyWorkId}
                  className={colors[i]}
                >
                  <div className="row">
                    <div className="col-2">
                      <img className="user_img" src={dailyWork.profileImage ? dailyWork.profileImage : require('../../asset/imgs/user-img.png')} alt="" /> 
                    </div>

                    <div className="col mt-4 cm_break">

                      <label>What did you do today?</label>
                      <p>{dailyWork.task}</p>
                      <label>What problems are you facing?</label>
                      <p>{dailyWork.blocker ? dailyWork.blocker : 'Nothing'}</p>
                      <label>Number of hours working?</label>
                      <p>{moment.utc(dailyWork.hour * 1000).format("HH:mm")}</p>
                    </div>
                    
                  </div>
                </Panel>
              ))

            }
          </Collapse>
          <div className="mt-5">
          {dataCount > 10 ? <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              /> : ''}</div>
        </div>
      </div>

    )
  }
}
export default AllActivityLog;

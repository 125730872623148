import React, { useEffect, useMemo, useReducer, useState } from "react";
import "./OrganizationChart.css";
import {
  Toastify,
  createSelectItems,
  currentMonth,
  isAdmin,
  reducer,
  type,
} from "../helper/Constant";
import { getEmployeeDataInOrganization } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import { Loader } from "../svg";
import OrgChartEmployee from "./OrganizationChartUtils/OrgChart";
import AddEditEmployeeModal from "./OrganizationChartModals/AddEditEmployeeModal";
import {
  getEmployeeInOrg,
  handleOpenAddEditModal,
} from "./OrganizationChartUtils/OrganizationConstants";
import { Select } from "antd";
import { MONTH_ARRAY, statusMon } from "../leaves/Constants";
import qs from "qs";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const initialState = {
  searchMonth: currentMonth,
  searchYear: new Date().getFullYear(),
  rootId: "",
  leadsArray: [],
  employee: [],
};

const OrganizationChart = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { searchMonth, searchYear, rootId, leadsArray, employee } = state;
  const [openModal, setOpenModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const history = useHistory();

  const fetchData = async () => {
    setLoader(true);
    try {
      const params = {
        searchMonth: searchMonth,
        searchYear: searchYear,
      };
      history.push(
        `organization-chart?&searchMonth=${searchMonth}&searchYear=${searchYear}`
      );
      const res = await getEmployeeDataInOrganization(params);
      const { status } = ResponseFilter(res);
      if (status) {
        setLoader(false);
        setResponse(false);
        setResponseMessage("");
        setData(res.data.responseData.list);
        dispatch({
          type: type,
          payload: {
            rootId: res.data.responseData.list.length && res.data.responseData.list[0].id,
          },
        });
      }
    } catch (error) {
      setLoader(false);
      setResponse(true);
      setResponseMessage(String(error));
      console.error("Error fetching data:::", error);
    }
  };

  useEffect(() => {
    fetchData();
    if (isAdmin === "4" || isAdmin === "1") {
      getEmployeeInOrg(setEmployeeList);
    }
  }, [searchMonth, searchYear, data.length]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        searchMonth: filtersFromParams.searchDate
          ? ""
          : filtersFromParams.searchMonth
          ? filtersFromParams.searchMonth
          : currentMonth,
        searchYear: filtersFromParams.searchDate
          ? ""
          : filtersFromParams.searchYear
          ? filtersFromParams.searchYear
          : new Date().getFullYear(),
      },
    });
  }, [history.location.search]);

  const handleMonth = (month) => {
    if (month === undefined) {
      dispatch({
        type: type,
        payload: {
          searchMonth: new Date().getMonth() + 1,
          pageNo: 1,
          month: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchMonth: month,
          searchYear: searchYear,
          searchDate: "",
          searchTimeStamp: "",
          pageNo: 1,
          month: true,
        },
      });
    }
  };

  const handleYear = (year) => {
    if (year === undefined) {
      dispatch({
        type: type,
        payload: {
          searchYear: new Date().getFullYear(),
          pageNo: 1,
          year: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchYear: year,
          pageNo: 1,
          year: true,
        },
      });
    }
  };

  return (
    <div>
      <div className="body_container cm_bold">
        {loader && <Loader />}
        <Toastify
          resMsg={success}
          text={successMessage}
          resMsgFail={response}
          responseMessage={responseMessage}
        />
        <h3 className="mb-4">ORGANIZATION CHART </h3>
        {(isAdmin === "4" || isAdmin === "1") && (
          <div className="d-flex justify-content-between">
            <div>
              <button
                className="organization-btn"
                onClick={() => handleOpenAddEditModal(setOpenModal)}
              >
                Add
              </button>
            </div>
            <div className="d-flex month_year">
              <Select
                className="select_year_cal"
                value={
                  searchMonth
                    ? statusMon(Number(searchMonth))
                    : statusMon(new Date().getMonth() + 1)
                }
                defaultValue="Select Month"
                name="searchMonth"
                onChange={(e) => handleMonth(e)}
                allowClear={searchMonth ? true : false}
              >
                {MONTH_ARRAY.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>

              <Select
                className="select_year_cal"
                value={searchYear ? searchYear : new Date().getFullYear()}
                defaultValue="Select Year"
                name="searchYear"
                onChange={handleYear}
                allowClear={searchYear ? true : false}
              >
                {createSelectItems()}
              </Select>
            </div>
          </div>
        )}
        {data.length > 0 ? (
          <OrgChartEmployee
            rootId={rootId}
            setLoader={setLoader}
            data={data}
            setResponse={setResponse}
            setResponseMessage={setResponseMessage}
            setSuccess={setSuccess}
            setSuccessMessage={setSuccessMessage}
            fetchData={fetchData}
            employeeList={employeeList}
          />
        ) : (
          <div className="mt-5 d-flex justify-content-center">
            <h3>No data available</h3>
          </div>
        )}
      </div>
      <AddEditEmployeeModal
        setLoader={setLoader}
        show={openModal}
        action="Add"
        setOpenModal={setOpenModal}
        openModal={openModal}
        fetchData={fetchData}
        setResponse={setResponse}
        setResponseMessage={setResponseMessage}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        data={data}
        employeeList={employeeList}
        leadsArray={leadsArray}
        employee={employee}
      />
    </div>
  );
};

export default OrganizationChart;

import { Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { MONTH_ARRAY_NUM } from '../../helper/Constant';
const { Option } = Select;

export default function AddAward(props) {
  return (

    <Modal centered show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton >
        <Modal.Title><b>Add Award </b></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={`assign_device ${props.miniLoader ? "admin_attendance" : ""}`}>
          <div className="row">
            <div
              className={`form-group col-12 ${props.awardEmployeeNameErrorMessage ? "input_error" : ""
                }`}
            >
              <label className="cm_bold">Award Employees Name</label>

              <Select
                showSearch
                name="awardEmployeeName"
                value={props.awardEmployeeName ? props.awardEmployeeName : " Select Employee"}
                placeholder="Select Name"
                onChange={props.handleSelectEmployeeChange}
                optionFilterProp="key"
                filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                disabled={props.miniLoader}
              >
                {!_.isEmpty(props.employeeList)
                  ? props.employeeList.sort(function (a, b) {
                    if (a.firstName < b.firstName) { return -1; }
                    if (a.firstName > b.firstName) { return 1; }
                    return 0;
                  }).map(
                    (employee) =>
                      employee.status === 1 && (
                        <Option
                          key={`${employee.firstName} ${employee.lastName}`}
                          value={employee.employeeId}
                        >
                          <span>
                            {employee.firstName} {employee.lastName} ({employee.empId})
                          </span>
                        </Option>
                      )
                  )
                  : ""}
              </Select>
              {props.awardEmployeeNameErrorMessage ? (
                <span className="error_msg">{props.awardEmployeeNameErrorMessage}</span>
              ) : null}
            </div>
          </div>
          <div className='row'>
            <div className={`form-group col-12 ${props.awardEmployeeImageErrorMessage ? "input_error" : ""
              }`}>
              <label className="cm_bold">Image</label>
              <input
                className="form-control cm_box"
                type="file"
                id='file'
                name="awardEmployeeImage"
                // value={props?.awardEmployeeImage}
                onChange={props.handleAwardEmployeeImageChange}
              />
              {props.awardEmployeeImageErrorMessage ? (
                <span className="error_msg">{props.awardEmployeeImageErrorMessage}</span>
              ) : null}
            </div>
          </div>
          <div className='row'>
            <div
              className={`form-group col-12`}
            >
              <label className="cm_bold">Nomniee Employees Name</label>

              <Select
                mode='multiple'
                showSearch
                name="nomnieeEmployees"
                value={props.nomnieeEmployees ? props.nomnieeEmployees : " Select Employee"}
                placeholder="Select Name"
                onChange={props.handleSelectNomnieeEmployeeChange}
                optionFilterProp="key"
                filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                disabled={props.miniLoader}
              >
                {!_.isEmpty(props.employeeList)
                  ? props.employeeList.sort(function (a, b) {
                    if (a.firstName < b.firstName) { return -1; }
                    if (a.firstName > b.firstName) { return 1; }
                    return 0;
                  }).map(
                    (employee) =>
                      employee.status === 1 && (
                        <Option
                          key={`${employee.firstName} ${employee.lastName}`}
                          value={employee.employeeId}
                        >
                          <span>
                            {employee.firstName} {employee.lastName} ({employee.empId})
                          </span>
                        </Option>
                      )
                  )
                  : ""}
              </Select>
            </div>
          </div>

          <div className='row'>
            <div className={`form-group col-12 ${props.awardNameErrorMessage ? "input_error" : ""
              }`}>
              <label className="cm_bold">Award Name</label>
              <Select
                showSearch
                name="awardName"
                value={props.awardName ? props.awardName : " Select Name"}
                placeholder="Select Name"
                onChange={(e) => props.handleChange(e, "awardName")}
                optionFilterProp="key"
                filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                disabled={props.miniLoader}
              >
                {!_.isEmpty(props.awardNameList)
                  ? props.awardNameList.map(
                    (awardName) =>
                      <Option
                        key={`${awardName.name}`}
                        value={awardName.name}
                      >
                        <span>
                          {awardName.name}
                        </span>
                      </Option>

                  )
                  : ""}
              </Select>

              {props.awardNameErrorMessage ? (
                <span className="error_msg">{props.awardNameErrorMessage}</span>
              ) : null}
            </div>
          </div>
          <div className='row'>
            <div className={`form-group col-12`}>
              <label className="cm_bold">Award Description</label>
              <textarea
                rows={3}
                className="form-control cm_box"
                type="text"
                placeholder="Description"
                name="awardDescription"
                value={props?.awardDescription ? props?.awardDescription : ""}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className='row'>
            <div
              className={`form-group col-6 ${props.awardTypeErrorMessage ? "input_error" : ""
                }`}
            >
              <label className="cm_bold">Award Type</label>

              <Select
                name="awardType"
                value={props?.awardType ? props?.awardType : "Award Type"}
                placeholder="Award Type"
                onChange={props.handleAwardType}
                disabled={props.miniLoader}
              >
                <Option value={1}>Office</Option>
                <Option value={2}>Client Side</Option>
              </Select>
              {props.awardTypeErrorMessage ? (
                <span className="error_msg">{props.awardTypeErrorMessage}</span>
              ) : null}
            </div>
            <div className={`form-group col-6 ${props.rewardErrorMessage ? "input_error" : ""
              }`}>
              <label className="cm_bold">Reward</label>
              <input
                maxLength="50"
                className="form-control  cm_box"
                type="text"
                placeholder="Reward"
                name="reward"
                value={props?.reward ? props?.reward : ""}
                onChange={props.handleChange}
              />
              {props.rewardErrorMessage ? (
                <span className="error_msg">{props.rewardErrorMessage}</span>
              ) : null}
            </div>
          </div>


          <div className='row'>
            <div className={`form-group col-6  ${props.awardYearErrorMessage ? "input_error" : ""
              }`}>
              <label className="cm_bold">Year</label>
              <input
                maxLength="5"
                className="form-control  cm_box"
                type="number"
                placeholder="Year"
                name="awardYear"
                value={props?.awardYear ? props?.awardYear : ""}
                onChange={props.handleChange}
              />

              {props.awardYearErrorMessage ? (
                <span className="error_msg">{props.awardYearErrorMessage}</span>
              ) : null}
            </div>

            <div className={`form-group col-6 `}>
              <label className="cm_bold">Month</label>
              <Select
                allowClear={props?.awardMonth ? true : false}
                value={props?.awardMonth ? props?.awardMonth : "Month"}
                defaultValue="Select Month"
                name="awardMonth"
                onChange={props?.handleMonthChange}
              >
                {MONTH_ARRAY_NUM.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
              </Select>
            </div>
          </div>
          <div className="submit_button_margin mt-4">
            {props.miniLoader ?
              <div className="cover-spin1"></div>
              : ''}
            <button
              type="button"
              onClick={props.handleSubmit}
              className="btn btn-primary  cm_btn3"
              disabled={props.miniLoader}
            >
              Save
            </button>
            <button
              type="button"
              onClick={props.handleClose}
              className="btn btn-primary cm_btn2"
              disabled={props.miniLoader}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal >
  )
}

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const StyledInput = styled.input.attrs({ readOnly: true })`
  height: 3rem;
  border-radius: 10px;
  border-color: #e0dede;
  cursor: pointer;
  width:100%;
`;

const CustomHeader = ({
    date,
    changeMonth,
    changeYear,
    decrementMonth,
    incrementMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
}) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const years = Array.from({ length: 20 }, (_, i) => date.getFullYear() - 10 + i);

    return (
        <div className="custom-header">
            <select
                value={date.getMonth()}
                onChange={e => changeMonth(e.target.value)}
            >
                {months.map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select value={date.getFullYear()} onChange={e => changeYear(e.target.value)}>
                {years.map(year => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

const LeaveDatePick = (props) => {
    const [selectedDate, setSelectedDate] = useState("");
    return (
        <div>
            <DatePicker
                customInput={<StyledInput />}
                selected={selectedDate}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="Select Date"
                // maxDate={new Date()}
                onChange={date => {
                    props.handleDateChange(date)
                    setSelectedDate(date)
                }}
                renderCustomHeader={({
                    date,
                    changeMonth,
                    changeYear,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                    <CustomHeader
                        date={date}
                        changeMonth={changeMonth}
                        changeYear={changeYear}
                        decrementMonth={decreaseMonth}
                        incrementMonth={increaseMonth}
                        prevMonthButtonDisabled={prevMonthButtonDisabled}
                        nextMonthButtonDisabled={nextMonthButtonDisabled}
                    />
                )}
            />
        </div>
    );
};

export default LeaveDatePick;

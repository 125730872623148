import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import Calendar from "react-calendar";
import moment from "moment";

import { LEAVE_ARRAY, halfTypeValue, leaveValue } from "../Constants";
import { format } from "../../attendance/Constants";
import { Select, TimePicker } from "antd";
import TextEditor from "../../../hoc/textEditor/TextEditor";
import DOMPurify from "dompurify";
const { Option } = Select;

export function EditApprovedShortLeaveModal(props) {
  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(props.leaveMsg),
    };
  }
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Edit Short Day Leave</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="cm_overflow_reason">
            <label>Change Leave Type</label>
            <Select
              name="editleaveType"
              value={leaveValue(props.leaveType1)}
              placeholder="Leave Type"
              onChange={props.handleLeaveTypeChange}
              className="select_emp_leave select_type "
            >
              {LEAVE_ARRAY.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}

              {!_.isEmpty(props.totalLeave) ? (
                props.handlediff(props.startDate, props.endDate) &&
                props.handlediff(props.startDate, props.endDate) <=
                  (props.totalLeave && props.totalLeave[0].comOff) ? (
                  <Option value="5">Compensatory off</Option>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <Option value="9">Half LWP</Option>
              {!_.isEmpty(props.totalLeave) ? (
                props.handlediff(props.startDate, props.endDate) &&
                props.handlediff(props.startDate, props.endDate) <=
                  (props.totalLeave && props.totalLeave[0].comOff) ? (
                  <Option value="10">Half Compensatory off</Option>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Select>
            {props.leaveType1 >= 6 && props.leaveType1 <= 10 ? (
              <div
                className={`row  ${
                  props.halfOfTheDayErrMsg ? "input_error" : ""
                }`}
              >
                <div className="col-6">
                  <label className="mt-4">Half Type</label>
                  <Select
                    style={{ width: "80%" }}
                    value={halfTypeValue(props.halfOfTheDay)}
                    placeholder="Select Half Type"
                    onChange={props.handleSelectHalfChange}
                  >
                    <Option value={1}>First Half Leave</Option>
                    <Option value={2}>Second Half Leave</Option>
                  </Select>

                  {props.halfOfTheDayErrMsg ? (
                    <span className="error_msg">
                      {props.halfOfTheDayErrMsg}
                    </span>
                  ) : null}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              {/* <div className="col-12">
                                <label className="mt-4">Start Date</label><br />
                                <Calendar onChange={props.onStartChange}
                                    value={props.startDate ? new Date(props.startDate) : ''}
                                    minDate={new Date()}
                                />

                            </div> */}
              {/* {props.leaveType1 !== 6 && props.leaveType1 !== 7 && props.leaveType1 !== 8 && props.leaveType1 !== 9 && props.leaveType1 !== 12 && props.leaveType1 !== 10 && props.leaveType1 !== 14 ?

                                <div className="col-12">
                                    <label className="mt-4">End Date</label><br />
                                    <Calendar onChange={props.onEndChange}
                                        value={props.endDate ? new Date(props.endDate) : ''}
                                        minDate={new Date()}
                                    />
                                    {props.endDateErrMsg ? (
                                        <span className="error_msg">{props.endDateErrMsg}</span>
                                    ) : null}
                                </div> : " "} */}

              {props.leaveType1 === 12 ? (
                <div className="d-flex">
                  <div
                    className={`form-group col-5 ${
                      props.shortDayFromErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="mt-4"> From</label>
                    <TimePicker
                      className="form-control"
                      allowClear={false}
                      disabledHours={() => [
                        0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23,
                      ]}
                      format={format}
                      value={
                        props.shortDayFrom
                          ? moment.utc(props.shortDayFrom * 1000)
                          : ""
                      }
                      onChange={props.handleCheckInChange}
                    />
                    {props.shortDayFromErrorMsg ? (
                      <span className="error_msg">
                        {props.shortDayFromErrorMsg}
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={`form-group col-5 ${
                      props.shortDayToErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="mt-4">To</label>
                    <TimePicker
                      className="form-control"
                      allowClear={false}
                      disabledHours={() => [
                        0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23,
                      ]}
                      format={format}
                      value={
                        props.shortDayTo
                          ? moment.utc(props.shortDayTo * 1000)
                          : ""
                      }
                      onChange={props.handleCheckOutChange}
                    />
                    {props.shortDayToErrorMsg ? (
                      <span className="error_msg">
                        {props.shortDayToErrorMsg}
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <label className="mt-4 mb-4">Reason</label>
              <div dangerouslySetInnerHTML={createMarkup()}></div>
            </div>
            <div
              className={`form-group col-12 ${
                props.replyErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="mt-4">Reply</label>
              <TextEditor
                editorState={props.editorState}
                onEditorStateChange={props.onEditorStateChange}
              />
              {props.replyErrorMsg ? (
                <span className="error_msg">{props.replyErrorMsg}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="submit_button_margin mt-4">
          <button
            type="button"
            className="btn btn-success"
            onClick={props.handleShortLeaveEditActionSubmit}
          >
            Save
          </button>
          {props.responseMessage ? (
            <h3 className=" Registration_error mt-5">
              {props.responseMessage}
            </h3>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import Calendar from "react-calendar";
import moment from "moment";

import {
  halfTypeValue,
  HALF_CASUAL_LEAVE,
  HALF_COMPENSATORY_OFF,
  HALF_EARNED_LEAVE,
  HALF_LWP,
  HALF_OTHER,
  HALF_SICK_LEAVE,
  leaveValue,
  SHORT_DAY_LEAVE,
  LEAVE_ARRAY,
  HALF_WFH,
} from "../Constants";
import { format } from "../../attendance/Constants";
import { Select, TimePicker } from "antd";
import TextEditor from "../../../hoc/textEditor/TextEditor";
const { Option } = Select;

function DeductLeaveModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Deduct Leave</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="cm_overflow_reason">
            <div
              className={` mb-4 ${
                props.employeeIdErrorMsg ? "input_error" : ""
              }`}
            >
              <label>Employee Name</label>

              <Select
                disabled={props.disabled}
                allowClear={props.employeeId !== "" ? true : false}
                showSearch
                name="employeeId"
                value={props.employeeId ? props.employeeId : " Select Employee"}
                defaultValue="Select Name"
                onChange={props.handleSelectEmployeeChange}
                onSearch={props.handleEmployeeSearch}
                optionFilterProp="key"
                filterOption={(input, option) =>
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
                style={{ maxWidth: "16rem" }}
                // disabled={miniLoader}
              >
                {!_.isEmpty(props.employeeList)
                  ? props.employeeList
                      .sort(function (a, b) {
                        if (a.firstName < b.firstName) {
                          return -1;
                        }
                        if (a.firstName > b.firstName) {
                          return 1;
                        }
                        return 0;
                      })
                      .map(
                        (employee) =>
                          employee.status == 1 && (
                            <Option
                              key={`${employee.firstName} ${employee.lastName}`}
                              value={employee.employeeId}
                            >
                              <span>
                                {employee.firstName} {employee.lastName} (
                                {employee.empId})
                              </span>
                            </Option>
                          )
                      )
                  : ""}
              </Select>
              {props.employeeIdErrorMsg ? (
                <span className="error_msg">{props.employeeIdErrorMsg}</span>
              ) : null}
            </div>
            <div>
              <div>
                <label>Leave Type</label>
                <Select
                  name="editleaveType"
                  value={props.deductLeaveType?leaveValue(props.deductLeaveType):"Leave Type"}
                  placeholder="Leave Type"
                  onChange={props.handleDeductleaveTypeChange}
                  className="select_emp_leave select_type mb-2 "
                >
                  {LEAVE_ARRAY.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              {props.leaveType1ErrorMsg ? (
                <span className="error_msg">{props.leaveType1ErrorMsg}</span>
              ) : null}
            </div>
            {(props.deductLeaveType >= HALF_CASUAL_LEAVE &&
              props.deductLeaveType <= HALF_COMPENSATORY_OFF) ||
              props.deductLeaveType === HALF_OTHER ||
              props.deductLeaveType === HALF_WFH ? (
              <div
                className={`row  ${
                  props.halfOfTheDayErrMsg ? "input_error" : ""
                }`}
              >
                <div className="col-6">
                  <label className="mt-4">Half Type</label>
                  <Select
                    style={{ width: "80%" }}
                    value={props.halfOfTheDay?halfTypeValue(props.halfOfTheDay):"Select Half Type"}
                    placeholder="Select Half Type"
                    onChange={props.handleSelectHalfChange}
                  >
                    <Option value={1}>First Half Leave</Option>
                    <Option value={2}>Second Half Leave</Option>
                  </Select>

                  {props.halfOfTheDayErrMsg ? (
                    <span className="error_msg">
                      {props.halfOfTheDayErrMsg}
                    </span>
                  ) : null}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div
                className={`form-group col-12 ${
                  props.start1dateErrorMsg ? "input_error" : ""
                }`}
              >
                <label className="mt-4">Start Date</label>
                <br />
                <Calendar
                  onChange={props.onStartChange}
                  value={props.startDate1}
                  maxDate={new Date()}
                />

                {props.start1dateErrorMsg ? (
                  <span className="error_msg">{props.start1dateErrorMsg}</span>
                ) : null}
              </div>
              {props.deductLeaveType !== HALF_CASUAL_LEAVE &&
              props.deductLeaveType !== HALF_EARNED_LEAVE &&
              props.deductLeaveType !== HALF_SICK_LEAVE &&
              props.deductLeaveType !== HALF_LWP &&
              props.deductLeaveType !== SHORT_DAY_LEAVE &&
              props.deductLeaveType !== HALF_COMPENSATORY_OFF &&
              props.deductLeaveType !== HALF_OTHER &&
              props.deductLeaveType !== HALF_WFH ? (
                <div
                  className={`form-group col-12 ${
                    props.enddate1ErrorMsg ? "input_error" : ""
                  }`}
                >
                  <label className="mt-4">End Date</label>
                  <br />
                  <Calendar
                    onChange={props.onEndChange}
                    value={props.endDate1}
                    maxDate={new Date()}
                  />

                  {props.enddate1ErrorMsg ? (
                    <span className="error_msg">{props.enddate1ErrorMsg}</span>
                  ) : null}
                </div>
              ) : (
                " "
              )}

              {props.deductLeaveType === SHORT_DAY_LEAVE ? (
                <div className="d-flex">
                  <div
                    className={`form-group col-5 ${
                      props.shortDayFromErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="mt-4"> From</label>
                    <TimePicker
                      className="form-control"
                      allowClear={false}
                      disabledHours={() => [
                        0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23,
                      ]}
                      format={format}
                      value={
                        props.shortDayFrom
                          ? moment.utc(props.shortDayFrom * 1000)
                          : ""
                      }
                      onChange={props.handleCheckInChange}
                    />
                    {props.shortDayFromErrorMsg ? (
                      <span className="error_msg">
                        {props.shortDayFromErrorMsg}
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={`form-group col-5 ${
                      props.shortDayToErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="mt-4">To</label>
                    <TimePicker
                      className="form-control"
                      allowClear={false}
                      disabledHours={() => [
                        0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23,
                      ]}
                      format={format}
                      value={
                        props.shortDayTo
                          ? moment.utc(props.shortDayTo * 1000)
                          : ""
                      }
                      onChange={props.handleCheckOutChange}
                    />
                    {props.shortDayToErrorMsg ? (
                      <span className="error_msg">
                        {props.shortDayToErrorMsg}
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className={`form-group col-12 ${
              props.replyErrorMsg ? "input_error" : ""
            }`}
          >
            <label className="mt-4">Reason</label>
            <TextEditor
              editorState={props.editorState}
              onEditorStateChange={props.onEditorStateChange}
            />
            {props.replyErrorMsg ? (
              <span className="error_msg">{props.replyErrorMsg}</span>
            ) : null}
          </div>
        </div>
        <div className="text-center mt-2">
          <button
            type="button"
            className="btn btn-danger"
            onClick={props.handleLeaveDeductSubmit}
          >
            Deduct
          </button>
          {props.responseMessage ? (
            <h3 className=" Registration_error mt-5">
              {props.responseMessage}
            </h3>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeductLeaveModal;

import React, { Component } from 'react'
import { Select } from "antd";
import { Link } from "react-router-dom";
import { DatePicker, Modal as AntModal } from "antd";
import moment from "moment";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import ResponseFilter from "../../ResponseFilter";
import { Loader, PdfIcon, RemoveIcon } from "../svg";
import Pagination from "react-js-pagination";
import { getEmployeeList, getDeviceRequestList, updateDeviceRequest } from '../../config/services/employeeServices'
import { previewImage } from 'antd/lib/upload/utils';
import DeviceFullScreenImage from './DeviceFullScreenImage';
import { getFileNameFromUrl } from '../../config/constants/Constants';
function disabledDate(current) {
  return current && current < moment().startOf("day");
}
export default class AdminPendinRequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeviceAction: false,
      openDeviceInfoPopup: false,
      deviceRequestDetails: [],
      // employeeList:[],
      resMsg: false,
      resRejectMsg: false,
      resFailMsg: false,
      pageNo: "",
      loader: '',
      dataCount: "",
      deviceModel: '',
      deviceType: "",
      deviceUniqueId: "",
      _id: "",
      hdd: "",
      operatingSystem: "",
      password: "",
      firstName: "",
      lastName: "",
      dateAdded: "",
      devId: "",
      brand: "",
      imei: "",
      acessories: "",
      ram: "",
      assignedTill: 0,
      device: [],
      status: 0,
      sortType: 2,
      sortField: "created",
      search: "",
      pageNo: 1,
      count: 50,
      comment: "",
      devicePictures: [],
      responseMessage: "",
      preview: false,
      fullImage: "",

    }
  }
  handleTillDateChange = (date, dateString) => {
    this.setState({
      assignedTill: date.toDate().getTime(),
      dateErrorMsg: "",
    }, () => { });
  }
  deviceValue = (type) => {
    switch (type) {
      case 1:
        return "Laptop";
        break;

      case 2:
        return "CPU";
        break;

      case 3:
        return "Phone";
        break;

      case 4:
        return 'Mouse';
        break;

      case 5:
        return 'Connectors';
        break;

      case 6:
        return 'UPS';
        break;

      case 7:
        return 'Router';
        break;

      case 8:
        return 'Headphone';
        break;

      case 9:
        return 'wifi-Adaptor';
        break;

      case 10:
        return 'web Camera';
        break;

      case 11:
        return 'keyboard';
        break;

      case 12:
        return 'Monitor';
        break;

      case 13:
        return 'Pendrive';
        break;

      case 14:
        return 'Hard Disk';
        break;

      case 15:
        return 'RAM';
        break;

      case 16:
        return 'Mac Mini';
        break;
    }
  };
  operatingSystemType = (type) => {
    switch (type) {
      case 1:
        return "windows";
        break;

      case 2:
        return "linux";
        break;
      case 3:
        return "MacOS";
        break;
      case 4:
        return "Android";
    }
  };

  handleOpenDeviceInfoPopup = ({
    deviceId,
    deviceModel,
    assignedTo,
    // deviceDeatails,
    // deviceManager,
    deviceType,
    operatingSystem,
    imei,
    // acessories,
    ram,
    hdd,
    password,
    brand,
    // dateAdded,
    // status,
    // firstName,
    // lastName,
    devId,
    purchasedDate,
    assignedDate,
    deviceDetails,
    comment,
    devicePictures

  }) =>
    this.setState({
      openDeviceInfoPopup: !this.state.openDeviceInfoPopup,
      editDivceObj: deviceId,
      deviceModel: deviceDetails[0]?.deviceModel,
      // assignedTo:assignedTo[0]?._id,
      // // deviceManager,
      deviceType: deviceDetails[0]?.deviceType,
      operatingSystem: deviceDetails[0]?.operatingSystem,
      brand: deviceDetails[0]?.brand,
      imei: deviceDetails[0]?.imei,
      // acessories,
      ram: deviceDetails[0]?.ram,
      hdd: deviceDetails[0]?.hdd,
      password: deviceDetails[0]?.password,
      comment: deviceDetails[0]?.comment,
      devicePictures: deviceDetails[0]?.devicePictures,
      // dateAdded,
      // status,
      firstName: assignedTo[0]?.firstName,
      devId: deviceDetails[0]?.devId,
      lastName: assignedTo[0]?.lastName,
      purchasedDate,
      assignedDate
    });


  closeModal = () =>
    this.setState({
      openAddDevicePopup: false,
      openDeleteDevicePopup: false,
      openEditDevicePopup: false,
      openDeviceInfoPopup: false,
      openDeviceAction: false,
      deleteDevice: "",
      editDevice: "",
      deviceId: "",
      deviceModel: "",
      devId: "",
      deviceType: "",
      deviceStatus: "",
      operatingSystem: "",
      imei: "",
      acessories: "",
      ram: "",
      hdd: "",
      password: "",
      brand: "",
      dateAdded: "",
      status: 0,
      firstName: "",
      lastName: "",
      assignedTo: "",
      // deviceManager: "",
      brandErrorMsg: "",
      ramErrorMsg: "",
      deviceTypeErrorMsg: "",
      deviceStatusErrorMsg: "",
      // assignedToErrorMsg: "",
      // deviceManagerErrorMsg: "",
      devIdErrorMsg: "",
      hddErrorMsg: "",
      dateErrorMsg: "",
      comment: "",
    });

  getRequestList = () => {
    let { search, status, sortType, sortField, pageNo, count } = this.state;
    let params = `search=${search}&status=${status}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1
      }`;
    this.props.history.push(`?status=${status}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo}`)
    this.setState({ loader: true })
    getDeviceRequestList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState(
          {
            deviceRequestDetails: resData.deviceDetails,
            dataCount: resData.datacount,
            loader: false
          },
          // () => this.closeModal()
        );
      } else {
        this.setState({ loader: false });
        // this.closeModal();
      }
    })
  }
  handleApproveDeviceRequest = ({ deviceRequestDetails, assignedTo, deviceUniqueId, _id, assignedTill }) => {
    let obj = {
      assignDeviceRequestId: _id,
      empUniqueId: assignedTo,
      deviceUniqueId: deviceUniqueId,
      assignedTill: this.state.assignedTill == 0 ? "" : this.state.assignedTill,
      assignedFrom: moment().toDate().getTime(),
      status: 1,


    }
    this.setState({ loader: true })
    updateDeviceRequest(obj).then((res) => {
      if (res.data.statusCode === 1) {
        // this.setState({});
        this.setState({ resMsg: true, loader: false }, () => this.getRequestList())
        // window.location.href = "/admin-device";
      }
      else {
        this.setState({
          resFailMsg: true, loader: false,
          responseMessage: res?.data?.error?.responseMessage
        })
      }

    })
    setTimeout(() => this.setState({ resMsg: false }), 4000)
    setTimeout(() => this.setState({ resFailMsg: false }), 4000)
  }
  handleRejectDeviceRequest = ({ deviceRequestDetails, assignedTo, deviceUniqueId, _id }) => {
    let obj = {
      assignDeviceRequestId: _id,
      empUniqueId: assignedTo,
      deviceUniqueId: deviceUniqueId,
      assignedFrom: '',
      assignedTill: "",
      status: 2,


    }
    this.setState({ loader: true })
    updateDeviceRequest(obj).then((res) => {
      if (res.data.statusCode === 1) {
        // this.setState({}, () => this.getRequestList());
        this.setState({ resRejectMsg: true, loader: false }, () => this.getRequestList())
        // window.location.href = "/admin-device";
      }
      else {
        this.setState({
          resFailMsg: true, loader: false,
          responseMessage: res?.data?.error?.responseMessage
        })
      }

    })
    setTimeout(() => this.setState({ resRejectMsg: false }), 4000)
    setTimeout(() => this.setState({ resFailMsg: false }), 4000)
  }

  handleSort = (e) => {
    this.setState({ loader: true });
    this.setState(
      { sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 },
      () => this.getRequestList()
    );
  };
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () => this.getRequestList());
  };

  // componentDidMount() {
  //   this.getRequestList();
  // }

  componentDidMount() {

    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      sortType: sortType ? sortType : 2,
      sortField: sortField ? sortField : "created",
    }, () => {

      this.getRequestList();
    })

  }
  handleFullImage = (e) => {
    this.setState({
      preview: true,
      fullImage: e,
    })
  }

  closeFullimage = () => {
    this.setState({
      preview: false
    })
  }
  render() {
    let {
      deviceRequestDetails,
      // employeeList,
      openDeviceAction,
      openDeviceInfoPopup,
      pageNo,
      count,
      loader,
      dataCount,
      deviceModel,
      deviceType,
      deviceUniqueId,
      _id,
      hdd,
      operatingSystem,
      password,
      firstName,
      lastName,
      dateAdded,
      devId,
      brand,
      imei,
      acessories,
      ram,
      resMsg,
      resRejectMsg,
      resFailMsg,
      assignedTill,
      device,
      status,
      comment,
      devicePictures,
      responseMessage,
      preview,
      fullImage,
    } = this.state
    return (
      <div className='body_container cm_bold'>
        {loader && <Loader />}
        {
          resMsg ?
            <div className="alert alert-success cm_top_fix"> Device Assign Successfully </div> : ''
        }
        {
          resRejectMsg ?
            <div className="alert alert-success cm_top_fix"> Request Rejected </div> : ''
        }
        {
          resFailMsg ?
            <div className="alert alert-danger cm_top_fix"> {responseMessage}</div> : ''
        }
        <div className="cm_breadcrumb">
          <ul>
            <li><Link to='/admin-device'>DEVICE MANAGEMENT</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li>DEVICE REQUEST LIST</li>

          </ul>
        </div>
      
        {/* {device request detail  module start} */}
        <div>
          <h3 className="mt-3 cm_bold">Device Request List</h3>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Device Type</th>
                  {/* <th scope="col">Device Model</th> */}
                  <th scope="col">Device ID</th>
                  <th scope="col">
                    Requested Date
                    <i
                      className={
                        this.state.sortType === 1 &&
                          this.state.sortField === "updated"
                          ? "fa fa-sort-numeric-asc ml-2"
                          : "fa fa-sort-numeric-desc ml-2"
                      }
                      onClick={() => this.handleSort("updated")}
                    ></i>
                  </th>
                  <th scope="col">Assigned Till</th>

                  <th>
                    Employee Name
                    <i
                      className={
                        this.state.sortType === 2 &&
                          this.state.sortField === "firstName"
                          ? "fa fa-sort-alpha-desc ml-2"
                          : "fa fa-sort-alpha-asc ml-2"
                      }
                      onClick={() => this.handleSort("firstName")}
                    ></i>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(deviceRequestDetails)
                  ? deviceRequestDetails.map((device, i) => (
                    device.status === 0 ?
                      <tr key={device.deviceId}>
                        <td>{i + 1 + (pageNo - 1) * count}</td>

                        <td>
                          {device.deviceDetails ? device.deviceDetails.map((deviceDetail) => {
                            return <>
                              {this.deviceValue(deviceDetail.deviceType)}
                            </>
                          }) : ""}
                        </td>
                        <td
                          className="blue"
                          onClick={() =>
                            this.handleOpenDeviceInfoPopup(device)
                          }
                        >
                          <span className="cm_col">{device.deviceDetails.map((devId) => {
                            return <>
                              {devId.devId}
                            </>
                          })
                          }</span>
                        </td>
                        <td>
                          {new Date(device.updated).getDate() + "/" + (new Date(device.updated).getMonth()+1) + "/" + new Date(device.updated).getFullYear()}
                        </td>
                        <td>
                          <DatePicker
                            // value={assignedTill ? moment(assignedTill) : ""}
                            allowClear={false}
                            // minDate={moment().toDate()}
                            disabledDate={disabledDate}
                            // defaultValue={moment(assignedTill)}
                            className="date-border"
                            onChange={this.handleTillDateChange}
                          />
                        </td>
                        <td>
                          {device.empDetails ? device.empDetails.map((emp) => {
                            return <>
                              {emp.firstName} {emp.lastName}
                            </>

                          }) : ""}
                        </td>

                        <td>
                          <div className="cm_device">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm mr-2 mb-3 cm_rem_dot"
                              disabled
                            >
                              Pending
                            </button>
                            <button
                              type="button"
                              className="btn btn-success btn-sm mr-2 mb-3 cm_rem_dot"
                              onClick={() => this.handleApproveDeviceRequest(device)}
                            >
                              { } Approve
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm mr-2 mb-3 cm_rem_dot"
                              onClick={() => this.handleRejectDeviceRequest(device)}
                            >
                              { } Reject
                            </button>
                          </div>
                        </td>
                      </tr> : ''
                  ))
                  : ""}
              </tbody>
            </table>
            {loader ? null : deviceRequestDetails.length > 0 ? (
              ""
            ) : (
              <h3 className="text-center">No data available</h3>
            )}
          </div>
        </div>
        {/* {device request detail module end} */}
        {dataCount > 50 ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={dataCount}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
        ) : (
          ""
        )}
        {/* {device detail module start} */}
        <Modal
          className="cm_deviceInfo"
          size="md"
          show={openDeviceInfoPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <form className="assign_device">

              <h3 className="mb-5">DEVICE DETAIL</h3>
              <div className="cm_overflow_device">
                <div className="row cm_row">
                  <div className="col-6 cm_bold col1">Device ID</div>
                  <div className="col-6 cm_bold col2">{devId ? devId : "-"}</div>
                  <div className="col-6 cm_bold col1">Device Type</div>
                  <div className="col-6 cm_bold col2">{this.deviceValue(deviceType)}</div>
                  <div className="col-6 cm_bold col1">Device Model</div>
                  <div className="col-6 cm_bold col2">{deviceModel ? deviceModel : '-'}</div>
                  <div className="col-6 cm_bold col1">Brand</div>
                  <div className="col-6 cm_bold col2">{brand}</div>
                  <div className="col-6 cm_bold col1">Service Tag(IMEI)</div>
                  <div className="col-6 cm_bold col2">{imei ? imei : '-'}</div>
                  {/* <div className="col-6 cm_bold col1">Acessories Name</div>
                  <div className="col-6 cm_bold col2">{acessories.length === 0 ? '-' : ` ${acessories}`}</div> */}
                  <div className="col-6 cm_bold col1">RAM</div>
                  <div className="col-6 cm_bold col2">{ram}</div>
                  <div className="col-6 cm_bold col1">HDD/SDD</div>
                  <div className="col-6 cm_bold col2">{hdd}</div>
                  <div className="col-6 cm_bold col1">Operating System</div>
                  <div className="col-6 cm_bold col2">{operatingSystem ? this.operatingSystemType(operatingSystem) : '-'}</div>
                  <div className="col-6 cm_bold col1">Password</div>
                  <div className="col-6 cm_bold col2">{password ? password : '-'}</div>
                  <div className="col-6 cm_bold col1">Comment</div>
                  <div className="col-6 cm_bold col2">{comment ? comment : '-'}</div>
                  <div className="col-6 cm_bold col1">Device Pictures</div>
                  <div className="container">
                    <div className="customer-content-ul image-container full-image" >
                      {/* {devicePictures ? devicePictures.map((devicePicture) =>
                        // <a href={devicePictures} target="_blank">
                        <img className="image" src={devicePicture} onClick={() => this.handleFullImage(devicePicture)}>

                        </img>
                        // </a>

                      ) : ""} */}

                      <div className="col-6 cm_bold col1">Device Pictures</div>
                      <div className="container">
                        <div className="customer-content-ul image-container" >
                          {/* {devicePictures ? devicePictures.map((devicePictures) =>
                        // <a href={devicePictures} target="_blank">
                        <img className="image" src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>
                        // </a>

                      ) : ""} */}


                          {devicePictures ? devicePictures.map((devicePictures) => (
                            devicePictures.includes(".pdf") == false ?
                              <div
                                className="date-border"
                                style={{
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  padding: "10px",
                                  overflow: "auto",
                                  minHeight: "100px",
                                  maxHeight: "500px",
                                  marginBottom: "10px"
                                }}
                                onClick={() => this.handleFullImage(devicePictures)}
                              >

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#edfbff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "3.5rem",
                                    margin: "5px",
                                  }}
                                >
                                  <img
                                    src={devicePictures}
                                    className="image-size mr-2"
                                  />
                                  <label style={{ width: "80%" }}>
                                    {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                                  </label>
                                </div>
                              </div>
                              :
                              <div onClick={() => this.handleFullImage(devicePictures)} >
                                {/* <PDFReader url={devicePictures}>
                            </PDFReader> */}
                                {/* <PdfIcon /> */}
                                <>
                                  <div
                                    className="date-border"
                                    style={{
                                      borderRadius: "8px",
                                      borderWidth: "1px",
                                      padding: "10px",
                                      overflow: "auto",
                                      minHeight: "100px",
                                      maxHeight: "500px",
                                      marginBottom: "10px"

                                    }}
                                  >

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        backgroundColor: "#edfbff",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        height: "3.5rem",
                                        margin: "5px",
                                      }}
                                    >
                                      <div className="image-size"><PdfIcon /></div>
                                      <label style={{ width: "80%" }}>
                                        {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              </div>
                          )

                          ) : ""}

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>



            </form>
          </Modal.Body>
        </Modal>
        {/* {device detail modeule end} */}

        {/* full screen Image */}
        <DeviceFullScreenImage
          preview={preview}
          fullImage={fullImage}
          closeModal={this.closeFullimage}
        />
      </div>
    )
  }
}

export const handleRentUploadValidation = (month, file, setUploadErr) => {
  let valid = true;
  if (month === "" && file === "") {
    valid = false;
    setUploadErr("*Please fill both fields");
  } else if (!month && file) {
    valid = false;
    setUploadErr("*Please select month");
  } else if (month && !file) {
    valid = false;
    setUploadErr("*Please upload file");
  }
  return valid
};

import {
  GET_VENDORS_LIST_REQUEST,
  GET_VENDORS_LIST_SUCCESS,
  GET_VENDORS_LIST_FAILURE,
} from "./actionType";

import { getVendorListService } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getVendorList = (empId) => {
  return (dispatch) => {
    dispatch(getVendorListRequest());
    getVendorListService()
      .then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch(getVendorListSuccess(res.data.responseData));
        } else {
          dispatch(getVendorListFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(getVendorListFailure(error));
      });
  };
};

const getVendorListRequest = () => {
  return {
    type: GET_VENDORS_LIST_REQUEST,
  };
};

const getVendorListSuccess = (data) => {
  return {
    type: GET_VENDORS_LIST_SUCCESS,
    payload: data,
  };
};

const getVendorListFailure = (error) => {
  return {
    type: GET_VENDORS_LIST_FAILURE,
    payload: error,
  };
};
export {
  getVendorList,
  getVendorListRequest,
  getVendorListSuccess,
  getVendorListFailure,
};

import React from "react";
import styled from "styled-components";

import { AiFillCloseCircle, AiOutlineSearch } from "react-icons/ai";

const SearchField = ({
  inputValue,
  handleInputChange,
  handlePageChange,
  handleClearSearchClick,
}) => {
  return (
    <SearchDiv>
      <InputField
        maxLength={25}
        placeholder="Search By Name/E.ID"
        value={inputValue}
        onChange={(param) => { handleInputChange(param.target.value == "#" ? "" : param.target.value); handlePageChange(1) }}
      />
      {inputValue === "" ? (
        <SearchIcon />
      ) : (
        <ClearSearchIcon onClick={() => handleClearSearchClick("")} />
      )}
    </SearchDiv>
  );
};

export default SearchField;

const SearchDiv = styled.div`
  width: 30%;
  height: 3.1rem;
  border: 1px solid grey;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 1rem;
  @media (max-width: 598px) {
    width:100%
  }
`;
const InputField = styled.input`
  border: none;
  width: 85%;
  font-size: medium;
  &:focus {
    border: none;
    outline: none;
  }
`;
const SearchIcon = styled(AiOutlineSearch)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: grey;
`;
const ClearSearchIcon = styled(AiFillCloseCircle)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: red;
`;

import moment from "moment";
import React from "react";
import {
  COMP_OFF_EXCEED,
  REMAINING_COMP_EXCEED,
  REQUIRED,
  currentMonth,
  currentQuarter,
  type,
} from "../helper/Constant";
import {
  CompOffAdd,
  compOffMax,
  emptyField,
  invalid,
  MONTH,
  QUARTER,
  YEAR,
} from "../helper/String";

export const currentYear = new Date().getFullYear();
const graphColor = [
  "#4ec6da",
  "#7460ee",
  "#2d6bce",
  "#f6b12b",
  "#ef534f",
  "#214cb0",
  "#46ba7f",
  "#000000",
];
export const statusMon = (type) => {
  switch (type) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "";
  }
};

export const holidayValue = (type) => {
  switch (type) {
    case 1:
      return "Event Holiday";
    case 2:
      return "Special Holiday";
    default:
      return "";
  }
};
export const leaveValue = (type) => {
  switch (type) {
    case 1:
      return "Casual Leave";
    case 2:
      return "Earned Leave";
    case 3:
      return "Sick Leave";
    case 4:
      return "LWP";
    case 5:
      return "Compensatory Off";
    case 6:
      return "Half Casual Leave";
    case 7:
      return "Half Earned Leave";
    case 8:
      return "Half Sick Leave";
    case 9:
      return "Half LWP";
    case 10:
      return "Half Compensatory off";
    case 11:
      return "Work From Home";
    case 12:
      return "Short Day Leave";
    case 13:
      return "Other's Leave";
    case 14:
      return "Half Other's";
    case 15:
      return "Half Work From Home";
    case 16:
      return "Client-side Off";
    case 17:
      return "Parenting Leave";
    case 18:
      return "Bereavement Leave";
    default:
      return "";
  }
};

export const halfTypeValue = (type) => {
  switch (type) {
    case 1:
      return "First Half Leave";
    case 2:
      return "Second Half Leave";
    default:
      return "";
  }
};

export const statusValue = (type) => {
  switch (type) {
    case 1:
      return "Pending";
    case 2:
      return "Approved";
    case 3:
      return "Rejected";
    default:
      return "";
  }
};

export const statusQuarter = (type) => {
  switch (type) {
    case 1:
      return "January - March";
    case 2:
      return "April - June";
    case 3:
      return "July - September";
    case 4:
      return "October - December";
    default:
      return "";
  }
};

export const colorType = (type) => {
  switch (type) {
    case 1:
      return "text-primary";

    case 2:
      return "text-success";

    default:
      return "text-danger ";
  }
};

export const Casual_Leave = "1";
export const Earned_Leave = "2";
export const Sick_Leave = "3";
export const LWP = "4";
export const Compensatory_Off = "5";
export const Half_Casual_Leave = "6";
export const Half_Earned_Leave = "7";
export const Half_Sick_Leave = "8";
export const Half_LWP = "9";
export const Half_Compensatory_Off = "10";
export const Work_From_Home = "11";
export const Short_Day_Leave = "12";
export const Other_Leave = "13";
export const Half_Other = "14";
export const Half_WFH = "15";
export const Client_off = "16";
export const Paternal_Leave = "17";
export const Bereavement_Leave = "18";

export const CASUAL_LEAVE = 1;
export const EARNED_LEAVE = 2;
export const SICK_LEAVE = 3;
export const LEAVE_WITHOUT_PAY = 4;
export const COMPENSATORY_OFF = 5;
export const HALF_CASUAL_LEAVE = 6;
export const HALF_EARNED_LEAVE = 7;
export const HALF_SICK_LEAVE = 8;
export const HALF_LWP = 9;
export const HALF_COMPENSATORY_OFF = 10;
export const WORK_FROM_HOME = 11;
export const SHORT_DAY_LEAVE = 12;
export const OTHER_LEAVE = 13;
export const HALF_OTHER = 14;
export const HALF_WFH = 15;
export const CLIENT_OFF = 16;
export const PATERNAL_LEAVE = 17;
export const BEREAVEMENT_LEAVE = 18;

export const TEMP_DELETE = "Temporary Comp Off Deleted";
export const TEMP_EDIT = "Temporary Comp Off Edited";
export const EndDate_Greater = "End date should be greater than Start date";
export const Max_Short_Day = "Maximum shortday hours is 2.5 hours";
export const Min_Time = "You can not select less then 30 min";
export const Max_Short_Day_Timestamp = 9000;
export const Min_Time_Timestamp = 1800;
export const format = "HH:mm";
export const compOffRegex = /^(?=.*[1-9])\d+(?:\.[05]0?)?$/;
export const arrOfQuarters = [
  "Jan to Mar",
  "Apr to June",
  "July to Sep",
  "Oct to Dec",
];
export const arrOfMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const arrOfYears = [
  currentYear,
  currentYear - 1,
  currentYear - 2,
  currentYear - 3,
  currentYear - 4,
  currentYear - 5,
];
export const graphCSS = (props) => {
  let {
    leaveDate,
    carryfwCompOff,
    carryfwEarnedLeaves,
    paidEarnedLeaves,
    paidComOff,
  } = props;
  return [
    {
      borderColor: graphColor,
      borderWidth: 10,
      hoverBackgroundColor: graphColor,
      hoverBorderColor: graphColor,
      data: [
        leaveDate[0] + leaveDate[5],
        leaveDate[1] + leaveDate[6],
        leaveDate[2] + leaveDate[7],
        leaveDate[4] + leaveDate[9],
        leaveDate[3] + leaveDate[8],
        carryfwCompOff + carryfwEarnedLeaves,
        paidEarnedLeaves + paidComOff,
        leaveDate[10] === undefined ? 0 : leaveDate[10],
      ],
      backgroundColor: graphColor,
    },
  ];
};

export const labels = ["Taken Leaves", "Remaining Leaves", "Total Leaves"];

export const disabledStartDate = (current) => {
  return current && current < moment().startOf("day");
};

export const disabledEndDate = (current) => {
  return current && current > moment().endOf("day");
};

export const disabledLeaveEndDate = (
  current,
  leaveType,
  startDate,
  totalRemainingLeave,
  assignedClientOff,
  assignedBereavementLeaves,
  assignedPaternalLeave,
) => {
  const totalRemainingLeave1 = parseInt(totalRemainingLeave);
  const quarter = currentQuarter + 1;
  const restrictedLeave = 4.5 * (4 - quarter);
  const today = new Date(startDate);
  const startDate1 = new Date(today);
  const threeDaysLater = new Date(today);
  const remainingLeaveLater = new Date(today);
  threeDaysLater.setDate(today.getDate() + 3);
  remainingLeaveLater.setDate(
    today.getDate() +
      (parseInt(totalRemainingLeave1 - restrictedLeave) == 1
        ? parseInt(totalRemainingLeave1 - restrictedLeave) + 1
        : parseInt(totalRemainingLeave1 - restrictedLeave))
  );
  if (leaveType === "16") {
    return current && current < startDate1;
  }
  if (leaveType === "17") {
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + assignedPaternalLeave);
    return current && (current < startDate1 || current > endDate);
  }
  if (leaveType === "18") {
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + assignedBereavementLeaves);
    return current && (current < startDate1 || current > endDate);
  }
  return current && leaveType == 1
    ? current < startDate1 || current > threeDaysLater
    : leaveType == 4 || leaveType == 12 || leaveType == 13 || leaveType == 11
    ? current < moment().startOf("day")
    : current < startDate1 || current >= remainingLeaveLater;
};
export const chartValue = (type) => {
  switch (type) {
    case "1":
      return YEAR;
    case "2":
      return QUARTER;
    case "3":
      return MONTH;
  }
};

export const leaveArray = [
  "C L",
  "E L",
  "S L",
  "Comp Off",
  "LWP",
  "C F",
  "P L",
  "SD L",
];
export const options = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  cutoutPercentage: 80,
  responsive: true,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

export const options2 = {
  maintainAspectRatio: false,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  legend: {
    display: false,
    reverse: true,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },

        ticks: {
          beginAtZero: true,
          steps: 10,
          stepValue: 5,
          max: 400,
        },
      },
    ],
  },
};

export const options3 = {
  maintainAspectRatio: false,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  legend: {
    display: false,
    reverse: true,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },

        ticks: {
          beginAtZero: true,
          steps: 10,
          stepValue: 5,
          max: 50,
        },
      },
    ],
  },
};

export const filterLeaveMonthLi = (searchMonth, updateMonth) => {
  return (
    <ul className="cm_monthly leave_monthly_status month_filter">
      {arrOfMonths.map((monthName, i) => (
        <li
          className={`${i === searchMonth ? "activeMon" : ""}`}
          onClick={() => updateMonth(i)}
        >
          <p>{monthName}</p>
          <span className={`${i === searchMonth ? "activeMon" : ""}`}></span>
        </li>
      ))}
    </ul>
  );
};

export const filterLeaveQuarterLi = (searchQuatName, updateQuater) => {
  return (
    <ul className="cm_quarterly year_filter">
      {arrOfQuarters.map((quarterName, i) => (
        <li
          className={`${i === searchQuatName ? "activeMon" : " "}`}
          onClick={() => updateQuater(i)}
        >
          <p>{quarterName}</p>
          <span className={`${i === searchQuatName ? "activeMon" : ""}`}></span>
        </li>
      ))}
    </ul>
  );
};

export const filterLeaveYearLi = (searchYear, updateYear) => {
  return (
    <ul className="cm_calender year_filter">
      {arrOfYears.map((yearName, i) => (
        <li
          className={`${
            yearName === (searchYear ? searchYear : currentYear)
              ? "activeMon"
              : ""
          }`}
          onClick={() => updateYear(yearName)}
        >
          <p>{yearName}</p>
          <span
            className={`${
              yearName === (searchYear ? searchYear : currentYear)
                ? "activeMon"
                : ""
            }`}
          ></span>
        </li>
      ))}
    </ul>
  );
};

export const handleValidation = (
  index,
  state,
  dispatch,
  type,
  error,
  total,
  remaining
) => {
  let validate = true;

  if (state === "" || state === undefined || state === 0) {
    dispatch({
      type: type,
      payload: {
        error: emptyField,
      },
    });
  }
  if (state < 0 || !Number.isInteger(+state)) {
    dispatch({
      type: type,
      payload: {
        error: invalid,
      },
    });
  } else if (index === 1) {
    if (state > 5) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error: invalid,
        },
      });
    }
    if (state < total) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error: "You cannot Edit less than" + " " + total + " " + " leaves.",
        },
      });
    }
  } else if (index === 2) {
    if (state > remaining) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error: compOffMax,
        },
      });
    }
    if (state > 5) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error: invalid,
        },
      });
    }
  } else if (index === 3) {
    if (state > total + remaining) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error:
            " Paid earned leave must be less than or equal to" +
            " " +
            (total + remaining),
        },
      });
    }
  } else if (index === 4) {
    if (state > total) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error:
            "Carry Forward Earned Leave must be less than or equal to" +
            " " +
            total,
        },
      });
    }
  } else if (index === 5) {
    if (parseInt(state) > remaining) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error: "You have " + " " + remaining + " " + "Earned Leaves",
        },
      });
    }
  } else if (index === 6) {
    if (state > total) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error:
            "Paid compensatory off must be less than or equal to " +
            " " +
            total,
        },
      });
    }
  } else if (index === 7) {
    if (state > total) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          error:
            " Carry Forward Compensatory Off must be less or than equal to" +
            " " +
            total,
        },
      });
    }
  } else if (index === 8) {
    if (state > remaining) {
      validate = false;
      this.setState({
        error: "You have only" + " " + remaining + " " + "Compensatory Off",
      });
    }
  }
  return validate;
};

export const Toast = (props) => {
  let { resMsg, index, deductLeave } = props;
  return (
    <div>
      {resMsg ? (
        deductLeave !== "" ? (
          <div className="alert alert-success cm_top_fix">{deductLeave}</div>
        ) : (
          <div className="alert alert-success cm_top_fix">{CompOffAdd}</div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export const LeaveInfo = (props) => {
  let { openLeaveDetail, index, leaveTitle } = props;
  return (
    openLeaveDetail && (
      <div>
        <div
          className={
            index === 6 || 5
              ? "card marked-leave-detail"
              : index === 7
              ? "card compOff-admin-detail"
              : "card leave-detail"
          }
        >
          <div className="card-body">
            <h4
              className={`card-title ${
                index == 7 && "d-flex justify-content-center"
              }`}
            >
              {leaveTitle}
            </h4>
            {index !== 7 && <h4 className="card-subtitle mb-2">Detail's</h4>}
            {index === 7 ? (
              <>
                <p>Taken/Assigned/Temp Comp Off</p>
              </>
            ) : index === 5 ? (
              <>
                <p className="card-content card-text">
                  * Marked Leave Without Pay
                </p>
                <p className="card-content card-text">
                  * An unpaid leave of absence
                </p>
              </>
            ) : index === 6 ? (
              <>
                <p className="card-content d-flex justify-content-center">
                  * You have only 2 short day leaves in a month{" "}
                </p>
                <p className="card-content card-text">
                  *Minimum time for Short Leave is 30 min and Maximumm is 2.5
                  hours
                </p>
                <p className="card-content">
                  * At the end of the month short day leave will end
                </p>
              </>
            ) : index === 4 ? (
              <>
                <p className="card-content d-flex justify-content-center">
                  1 Compensatory off leave will be we given you by your project
                  manager
                </p>
                <p className="card-content card-text">
                  2 There are two types of compensatory leave permanent and
                  temporary
                </p>
                <p className="card-content">
                  3 Temporary compensatory off leave will expire on expiry date
                </p>
                <p className="card-content">
                  4 Permanent compensatory off leave will not expire and
                  carryforward to next year{" "}
                </p>
              </>
            ) : (
              <>
                <p className="card-content">
                  <b>1-</b>You have 6 yearly Leave
                </p>
                <p className="card-content">2-You have 1.5 Quaterly Leave</p>
                <p className="card-content">3-You have 0.5 Monthly Leave</p>
                <p className="card-content">
                  * {leaveTitle}{" "}
                  {index === 2
                    ? " will be carry forwarded to next year"
                    : " will not be carry forwarded to next year"}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export const handleSelectLeave = (props) => {
  let { e, dispatch } = props;
  dispatch({
    type: "typeCheckSet",
    payload: {
      selectLeave: e,
      chartView: true,
      leaveTypeSelect: "",
    },
  });
  if (e === "1") {
    dispatch({
      type: "typeCheckSet",
      payload: {
        searchYear: currentYear,
        searchQuatName: "",
        searchMonth: "",
      },
    });
  }
  if (e === "2") {
    dispatch({
      type: "typeCheckSet",
      payload: {
        searchQuatName: currentQuarter,
        searchMonth: "",
      },
    });
  }
  if (e === "3") {
    dispatch({
      type: "typeCheckSet",
      payload: {
        searchMonth: currentMonth - 1,
        searchQuatName: "",
      },
    });
  }
};

export const numberOfLeavesCount = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const timeDifference = date2.getTime() - date1.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  return daysDifference + 1;
};

export const HALF_LEAVE_ARRAY = [
  { value: "6", label: "Half Casual Leave" },
  { value: "7", label: "Half Earned Leave" },
  { value: "8", label: "Half Sick Leave" },
  { value: "9", label: "Half LWP" },
  { value: "10", label: "Half Compensatory off" },
  { value: "14", label: "Half Other's" },
  { value: "15", label: "Half Work From Home" },
];
export const halfLeaveValues = HALF_LEAVE_ARRAY.map((leave) => leave.value);
export const excludedLeaveTypes = ["6", "7", "8", "9", "10", "12", "14", "15", "11"];

export const LEAVE_ARRAY = [
  { value: "1", label: "Casual Leave" },
  { value: "2", label: "Earned Leave" },
  { value: "3", label: "Sick Leave" },
  { value: "6", label: "Half Casual Leave" },
  { value: "7", label: "Half Earned Leave" },
  { value: "8", label: "Half Sick Leave" },
  { value: "4", label: "LWP" },
  { value: "9", label: "Half LWP" },
  { value: "5", label: "Compensatory off" },
  { value: "10", label: "Half Compensatory off" },
  { value: "11", label: "Work From Home" },
  { value: "12", label: "Short Day Leave" },
  { value: "13", label: "Other's" },
  { value: "14", label: "Half Other's" },
  { value: "15", label: "Half Work From Home" },
  { value: "16", label: "Client-side Off" },
  { value: "17", label: "Parenting Leave" },
  { value: "18", label: "Bereavement Leave" },
];

export const MONTH_ARRAY = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April " },
  { value: "5", label: "May " },
  { value: "6", label: "June " },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October " },
  { value: "11", label: "November " },
  { value: "12", label: "December " },
];

export const validateDeleteCompOff = (
  selectedCompOffDays,
  compOffCount,
  deleteCompOffNo,
  dispatch
) => {
  dispatch({
    type: type,
    payload: {
      deleteCompOffNoErrMsg: "",
    },
  });
  if (
    typeof selectedCompOffDays !== "undefined" &&
    typeof compOffCount !== "undefined"
  ) {
    if (!deleteCompOffNo && selectedCompOffDays === "") {
      dispatch({
        type: type,
        payload: {
          deleteCompOffNoErrMsg: REQUIRED,
        },
      });
      return false;
    }
    if (selectedCompOffDays && deleteCompOffNo > selectedCompOffDays) {
      dispatch({
        type: type,
        payload: {
          deleteCompOffNoErrMsg: REMAINING_COMP_EXCEED,
        },
      });
      return false;
    }
    if (!selectedCompOffDays && deleteCompOffNo > compOffCount) {
      dispatch({
        type: type,
        payload: {
          deleteCompOffNoErrMsg: COMP_OFF_EXCEED,
        },
      });
      return false;
    }
  }
  if (deleteCompOffNo % 0.5 !== 0 || deleteCompOffNo <= 0) {
    dispatch({
      type: type,
      payload: {
        deleteCompOffNoErrMsg: invalid,
      },
    });
    return false;
  }

  return true;
};

export const removeSessionStorage = () => {
  sessionStorage.removeItem("dashboard_apply_leave");
  sessionStorage.removeItem("dashboard_leave_startDate");
  sessionStorage.removeItem("dashboard_leaveType");
};

export const Half_Leave_Short_Leave = [
  Half_Casual_Leave,
  Half_Earned_Leave,
  Half_Sick_Leave,
  Half_LWP,
  Half_Compensatory_Off,
  Half_Other,
  Short_Day_Leave,
  Half_WFH,
];

import React from "react";
import { Toastify, handleOpenPopup, searchEmployee } from "./Constant";

const ComponentHeader = (props) => {
  const {
    resMsg,
    resMsgFail,
    successMessage,
    responseMessage,
    dispatch,
    search,
  } = props;
  return (
    <div>
      <Toastify
        resMsg={resMsg}
        resMsgFail={resMsgFail}
        text={successMessage}
        responseMessage={responseMessage}
      />
      <h3 className="mb-4">COURIER MANAGEMENT</h3>
      <div className="row d-flex justify-content-end mb-4">
        <div
          className="col-md-6 search_device_bar2"
          style={{ marginBottom: 10 }}
        >
          <span
            className="btn btn-primary add_empBtn"
            onClick={() =>
              handleOpenPopup({
                dispatch: dispatch,
                open: "addPopup",
                data:"",
              })
            }
          >
            Add Courier Detail
          </span>
          {searchEmployee(search, dispatch)}
        </div>
      </div>
    </div>
  );
};

export default ComponentHeader;

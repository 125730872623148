import React from 'react'
import { Modal } from 'react-bootstrap'
import { Select } from 'antd';
import _ from 'lodash';
import { handleEmployeeName } from '../SalaryFunctions';
import { createSelectItems } from '../../helper/Constant';
const { Option } = Select
export default function AddBonusModal(props) {
    return (

        <Modal centered show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title className='text-center'><b>ADD Bonus </b></Modal.Title>
            </Modal.Header>
            <Modal.Body className={`assign_device ${props.miniLoader ? "admin_attendance" : ""}`}>
                <div className='row'>
                    <div
                        className={`form-group col-6`}>
                        <label className="cm_bold">Select Month</label>

                        <Select
                            value={props?.listgenerationMonth ? props?.listgenerationMonth : "Month"}
                            defaultValue="Select Month"
                            name="listgenerationMonth"
                            onChange={props?.handleMonthSelect}
                        >
                            <Option value={1}>January</Option>
                            <Option value={2}>February</Option>
                            <Option value={3}>March</Option>
                            <Option value={4}>April</Option>
                            <Option value={5}>May</Option>
                            <Option value={6}>June</Option>
                            <Option value={7}>July</Option>
                            <Option value={8}>August</Option>
                            <Option value={9}>September</Option>
                            <Option value={10}>October</Option>
                            <Option value={11}>November</Option>
                            <Option value={12}>December</Option>
                        </Select>
                    </div>
                    <div className={`form-group col-6`}>
                        <label> Select Year</label>
                        <Select
                            value={props?.listgenerationYear ? props.listgenerationYear : "Select Year"}
                            defaultValue="Select Year"
                            name="listgenerationYear"
                            onChange={props?.handleYearSelect}
                            allowClear={props.listgenerationYear ? true : false}
                        >
                            {createSelectItems()}
                        </Select>
                    </div>
                </div>
                <div className="row">

                    <span className='red  ml-2'>* Please select employee to add</span>
                    <div
                        className={`form-group col-12 ${props.addEmployeeErrorMessage ? "input_error" : ""}`}>
                        <label className="cm_bold">Employee Name</label>
                        <Select
                            showSearch
                            mode='multiple'
                            name="employeeName"
                            value={props.addEmployeeList ? props.addEmployeeList : " Select Employee"}
                            placeholder="Select Name"
                            onChange={(e) => props.handleSelectEmployeeChange(e, "addBo")}
                            optionFilterProp="key"
                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                            disabled={props.miniLoader}
                        >
                            {!_.isEmpty(props.employeeList)
                                ? props.employeeList.sort(function (a, b) {
                                    if (a.firstName < b.firstName) { return -1; }
                                    if (a.firstName > b.firstName) { return 1; }
                                    return 0;
                                }).map(
                                    (employee) =>
                                        employee?.status == 1 && (
                                            <Option
                                                key={`${employee.firstName} ${employee.lastName}`}
                                                value={employee.employeeId}
                                            >
                                                <span>
                                                    {employee.firstName} {employee.lastName} ({employee.empId})
                                                </span>
                                            </Option>
                                        )
                                )
                                : ""}
                        </Select>
                        {props.addEmployeeErrorMessage ? (
                            <span className="error_msg">{props.addEmployeeErrorMessage}</span>
                        ) : null}
                    </div>
                    {/* <div className={`form-group col-6 `}>
                        <button className='btn btn-primary' onClick={() => props.handlegenerateBonusEmployeeList()}>Add</button>
                    </div> */}
                </div>
                <div className='row' style={{ maxHeight: "500px", overflowY: "auto" }}>
                    {props?.bonusEmployees.map((element, index) => (
                        <div className="form-inline col-lg-12" key={index}>

                            <div className={`form-group col-lg-6 div_form_label`}>
                                <label className="cm_bold">Employee Name</label>
                                <input type="text" name="tech" className="form_control cm_box" value={handleEmployeeName(element?.employeeId, props?.employeeList)} disabled />
                            </div>

                            <div className={`form-group col-lg-6 div_form_label ${element.numberOfLwpCheck ? "input_error" : ""
                                }`}>
                                <label className="cm_bold">Bonus</label>
                                <input type="number" name="bonus" className="form_control cm_box" value={element?.bonus ? element?.bonus : ""} placeholder="Enter Bonus" onChange={e => props.handleBonusChange(index, e, props?.bonusEmployees)} />
                            </div>
                            <div style={{ color: 'red' }} className='salary_margin'>{element.bonusCheck}<br />{element.ratingFormat}</div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <div className="submit_button_margin mt-4">
                    {props.miniLoader ? <div className="mini_loader_dept"></div> : ""}
                    <button
                        className="btn btn-success cm_btn3 btn-sm"
                        onClick={props.handleSubmit}
                        disabled={props.miniLoader}
                    >
                        Generate
                    </button>
                    <button
                        onClick={props.handleClose}
                        className="btn btn-primary cm_btn2 btn-sm"
                        disabled={props.miniLoader}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

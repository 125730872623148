import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import DotLoader from "../../hoc/DotLoader";
import { downloadIcon } from "../../asset";
import { getAssignInterviews } from "../../redux/actions";
import { Link, useHistory } from "react-router-dom";
import { SearchField } from "../../hoc";
import styled from "styled-components";
import "./InterviewStyles.css";
import { Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import { CONSTANTS } from "../../config";
import qs from "qs"
import ResponseFilter from "../../ResponseFilter";
import { editInterviewerStatus, getInterviewerStatus } from "../../config/services/employeeServices";
import { editAssignCandidateByInterviewer } from "../../redux/actions/editAssignCandidateByInterviewer";
import { useDebounce } from "../attendance/Constants";
let TAB_STATUS_CONSTANTS = CONSTANTS.TAB_STATUS_CONSTANTS
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const InterviewerModule = ({ assignInterviewList, getAssignInterviews, totalCandidate, pending, accepted, rejected, editAssignCandidateByInterviewer, error }) => {
    const [showModal, setShowModal] = useState(false);
    const [search, setsearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [interviewStatus, setInterviewStatus] = useState("");
    const [resMsg, setResMsg] = useState(false);
    const [resFailMsg, setFailResMsg] = useState(false);
    const [tabStatus, setTabStatus] = useState("")
    const [status, setStatus] = useState("")
    const [statusErrorMessage, setStatusErrorMessage] = useState("")

    const [sortType, setSortType] = useState(2);
    const [sortField, setSortField] = useState("created")
    const [interviewerStatus, setInterviewerStatus] = useState("")
    const [formValues, setFormValues] = useState([{ tech: "", rating: "" }])
    const [feedBackErrorMessage, setFeedBackErrorMessage] = useState("")
    const [rating, setRating] = useState("")
    const [ratingErrorMessage, setRatingErrorMessage] = useState("")
    const [interviewId, setInterviewId] = useState("")
    const [interviewRound, setInterviewRound] = useState("")
    const [loader, setLoader] = useState(false);
    const [resInterviewerStatus, setResInterviewerStatus] = useState(false)
    const [inPanel, setInPanel] = useState()
    const [meetingDetail, setMeetingDetail] = useState(false)
    const [ratingInfo, setRatingInfo] = useState(false)
    const [ratingDetail, setRatingDetail] = useState([])
    const [meetingInfo, setMeetingInfo] = useState({})
    const history = useHistory();
    const employeeId = localStorage.getItem("employeeId")
    const { Option } = Select;
    const debouncedSearchTerm = useDebounce(search, 2000);

    const count = 10;




    useEffect(() => {
        getData();
        getInterviewerStatusAction();
    }, [pageNo, debouncedSearchTerm, tabStatus, sortType]);

    useMemo(() => {
        const filterParams = history.location.search.substr(1);
        const filtersFromParams = qs.parse(filterParams);
        setSortType(filtersFromParams.sortType ? filtersFromParams.sortType : SORT_TYPE_CONSTANTS.Descending)
        setSortField(filtersFromParams.sortField ? filtersFromParams.sortField : "created")
        setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)
        setsearch(filtersFromParams.search ? filtersFromParams.search : "")
        setTabStatus(filtersFromParams.statusBy ? Number(filtersFromParams.statusBy) : "")

    }, [history.location.search]);

    const getInterviewerStatusAction = () => {
        getInterviewerStatus().then(res => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                setInterviewerStatus(resData.result)
                setInPanel(resData.isPanel)
            } else {

            }
        })
    }
    const getData = async () => {
        const query = `${employeeId}&search=${search}&pageNo=${pageNo - 1}&count=${count}&status=${interviewStatus}&sortType=${sortType}&sortField=${sortField}&statusBy=${tabStatus}`
        history.push(`?employeeId=${employeeId}&search=${search}&pageNo=${pageNo}&count=${count}&status=${interviewStatus}&statusBy=${tabStatus}&sortType=${sortType}&sortField=${sortField}`)
        await getAssignInterviews(query)
    };
    const handleOnEditInterviewClick = (e) => {
        setStatus(e.isClear != 2 ? e.isClear : "")
        setFormValues(e.skillRating == [] || e.skillRating.length === 0 || e.skillRating.length === null ? [{ tech: "", rating: "" }] : e.skillRating)
        setInterviewId(e.interviewId)
        setInterviewRound(e.interviewRound)
        setShowModal(true);
    };

    const handleOnPageChangeClick = (pageNum) => {
        setPageNo(pageNum);
    };
    const operatingSystemType = (type) => {
        switch (type) {
            case 3:
                return "Rejected";
                break;
            case 1:
                return "Selected";
                break;
            case 2:
                return "Pending";
                break;
        }
    };


    const colorType = type => {
        switch (type) {
            case 3: return 'red';
                break;
            case 1: return 'text-success';
                break;
            case 2: return 'yellow';
                break;


        }
    }


    const handleFilter = (e) => {
        setTabStatus(e);
        setPageNo(1);
    }

    const handleEditSubmit = (e) => {
        e.preventDefault();
        let obj = {
            status: e.target.checked ? TAB_STATUS_CONSTANTS.STATUS.one : TAB_STATUS_CONSTANTS.STATUS.zero,
        };
        editInterviewerStatus(obj).then((res) => {
            if (res.data.statusCode === 1) {
                setResInterviewerStatus(true)
                getInterviewerStatusAction();
                getData();
            } else {
                setFailResMsg(true)
            }
        });

        setTimeout(() => setResInterviewerStatus(false), 4000)
        setTimeout(() => setFailResMsg(false), 4000)
    };
    const closeModal = () => {
        // setFeedBack("")
        setFeedBackErrorMessage("")
        setRating("")
        setRatingErrorMessage("")
        setStatus("")
        setStatusErrorMessage("")
        setShowModal(!showModal)
        setFormValues([{ tech: "", rating: "" }])
    }
    const handleAssignRating = (e) => {
        setRating(e.target.value)
        setRatingErrorMessage("")
    }

    // const handleAssignFeedback = (e) => {
    //     setFeedBack(e.target.value)
    //     setFeedBackErrorMessage("")
    // }
    const handleAssignStatus = (e) => {
        setStatus(e)
        setStatusErrorMessage("")
    }

    let handleTechChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = /^\s/.test(e.target.value) ? "" : e.target.value;
        setFormValues(newFormValues);
        for (let index = 0; index < newFormValues.length; index++) {
            newFormValues[index].techCheck = ""
        }
    }

    let handleRatingChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = /^\s/.test(e.target.value) ? "" : e.target.value;
        setFormValues(newFormValues);
        for (let index = 0; index < newFormValues.length; index++) {
            newFormValues[index].ratingCheck = ""
        }

    }
    let addFormFields = () => {
        setFormValues([...formValues, { tech: "", rating: "" }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }


    const handleStatusValidation = () => {
        let validate = true;
        if (status == "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleSort = (e) => {
        setSortField(e);
        if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
            setSortType(SORT_TYPE_CONSTANTS.Descending);
        }
        else {
            setSortType(SORT_TYPE_CONSTANTS.Asscending)
        }
    }

    const formValidation = (formValues) => {
        const data = [...formValues]
        let validate = true
        for (let index = 0; index < data.length; index++) {
            if (data[index].tech == "") {
                data[index].techCheck = "Tech Required"
                validate = false
                break
            }
            else {
                data[index].techCheck = ""
                validate = true
            }

            if (data[index].rating == "") {
                data[index].ratingCheck = "Rating Required"
                validate = false
                break;
            }
            else if (data[index].rating < 0) {
                data[index].ratingCheck = "Rating can not be Negative"
                validate = false
                break;
            }
            else if (data[index].rating > 10) {
                data[index].ratingCheck = "Rating can not be Greater then 10"
                validate = false
                break;
            }
            else {
                data[index].ratingCheck = ""
                validate = true

            }
        }
        setFormValues(data)
        return validate

    }



    const handleUploadButtonClick = (e) => {
        if (
            handleStatusValidation(status) &&
            formValidation(formValues)
        ) {
            setLoader(true)
            const data = {
                "employeeId": employeeId,
                "isClear": status,
                "skillRating": formValues,
                "interviewId": interviewId,
                "interviewRound": interviewRound,

            }
            const query = `${employeeId}&search=${search}&pageNo=${pageNo - 1}&count=${count}&status=${interviewStatus}&sortType=${sortType}&sortField=${sortField}&statusBy=${tabStatus}`
            editAssignCandidateByInterviewer(data, query, setShowModal, setResMsg, setFailResMsg, setLoader, closeModal)
        }
        else {
        }

        setTimeout(() => setResMsg(false), 4000)
        setTimeout(() => setFailResMsg(false), 4000)
    };

    const onSubmitClick = (e) => {
        handleUploadButtonClick();
        const data = [...formValues]
        for (let index = 0; index < data.length; index++) {
            if (data[index].tech == "") {
                data[index].techCheck = "Tech Required"
            }
            else {
                data[index].techCheck = ""
            }
            if (data[index].rating == "") {
                data[index].ratingCheck = "Rating Required"
            }
            else if (data[index].rating < 0) {
                data[index].ratingCheck = "Rating can not be Negative"
            }
            else if (data[index].rating > 10) {
                data[index].ratingCheck = "Rating can not be Greater then 10"
            }
            else {
                data[index].ratingCheck = ""
            }
        }
        if (handleStatusValidation(status) == "") {
            setStatusErrorMessage("Please Select Status")
        }
        else {
            setStatusErrorMessage("")
        }
    };

    const handleMeetingDetail = (element) => {
        setMeetingDetail(true)
        setMeetingInfo(element)
    }
    const closeModal1 = () => {
        setMeetingDetail(false)
        setMeetingInfo({})
    }
    const handleOpenRating = (e) => {
        setRatingDetail(e)
        setRatingInfo(true)
    }
    const closeRatingModal = () => {
        setRatingInfo(false)
        setRatingDetail([])
    }
    return (
        <div className="body_container cm_bold marginTop">
            {
                resMsg ?
                    <div className="alert alert-success cm_top_fix"> Interview updated Successfully </div> : ''
            }
            {
                resInterviewerStatus ?
                    <div className="alert alert-success cm_top_fix"> Status Updated Successfully </div> : ''
            }

            {
                resFailMsg ?
                    <div className="alert alert-danger cm_top_fix"> {error} </div> : ''
            }

            <div className="tableDescDiv">
                <h4> Candidate(s) List :</h4>
            </div>
            <div className="headerDiv">
                <SearchField
                    inputValue={search.trim()}
                    handleInputChange={setsearch}
                    handlePageChange={setPageNo}
                    handleClearSearchClick={setsearch}
                />
            </div>

            {inPanel == true ?
                <div className="d-flex justify-content-end mb-3">
                    <span htmlFor="">Available for interview</span>
                    <div className="cm_swich_wrapper mt-1 ml-2">
                        <input
                            type="checkbox"
                            value={interviewerStatus}
                            checked={interviewerStatus == TAB_STATUS_CONSTANTS.STATUS.one ? true : false}
                            onChange={(e) =>
                                handleEditSubmit(e)
                            }
                        />
                        <span className="cm_swich"></span>

                    </div>
                </div> : ""}
            <div className="text-left cm_ticket_btn cm_leave_btn">
                <button className={`btn btn-primary ${tabStatus === "" ? "active" : ""} `}
                    onClick={() => handleFilter("")}>
                    <span className="tabtext">All {totalCandidate ? totalCandidate : 0}</span></button>
                <button className={`btn btn-primary ${tabStatus === 2 ? "active" : ""} `}
                    onClick={() => handleFilter(2)}>
                    <span className="tabtext">Pending {pending?.[0]?.totalCountByStatus ? pending?.[0]?.totalCountByStatus : 0}</span></button>
                <button className={`btn btn-primary ${tabStatus === 1 ? "active" : ""} `}
                    onClick={() => handleFilter(1)}>
                    <span className="tabtext">Selected {accepted?.[0]?.totalCountByStatus ? accepted?.[0]?.totalCountByStatus : 0}</span></button>
                <button className={`btn btn-primary ${tabStatus === 0 ? "active" : ""} `}
                    onClick={() => handleFilter(3)}>
                    <span className="tabtext">Rejected {rejected?.[0]?.totalCountByStatus ? rejected?.[0]?.totalCountByStatus : 0}</span></button>

            </div>
            {assignInterviewList ? (
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Name
                                    <i
                                        className={
                                            sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'name'
                                                ? "fa fa-sort-alpha-asc ml-2"
                                                : "fa fa-sort-alpha-desc ml-2"
                                        }
                                        onClick={() => handleSort("firstName")}
                                    ></i>

                                </th>
                                <th scope="col">Email</th>
                                <th scope="col">Contact Number</th>

                                <th scope="col">D.O.B
                                </th>
                                <th scope="col">Total Experience</th>
                                <th scope="col">Technical Skill</th>
                                <th scope="col">Position Applied</th>
                                <th scope="col">Round</th>
                                <th scope="col">Rating</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        {assignInterviewList.map((item, index) => {
                            return (
                                <tbody>
                                    <tr>
                                        <td>{index + 1 + (pageNo - 1) * count}</td>
                                        <td className="blue" onClick={() => handleMeetingDetail(item.meetingInfo)}>
                                            {item?.FirstName + " " + item?.LastName}
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile ? item.mobile : ""}</td>
                                        <td>
                                            {" "}
                                            {new Date(item.dob).toLocaleDateString({
                                                hour12: true,
                                            })}
                                        </td>


                                        <td>{item.totalExperience == null ? " Fresher" : `${item.totalExperience} years`}</td>
                                        <td>{item.technicalSkill}</td>
                                        <td>{item.position}</td>
                                        <td>{item.interviewRound}</td>
                                        <td className="blue" onClick={() => handleOpenRating(item.skillRating)}> Click Here</td>
                                        <td className={colorType(item.isClear)}>{operatingSystemType(item.isClear ? item.isClear : "_")}</td>
                                        <td>
                                            <div className="cm_device d-flex justify-content-evenly">

                                                <Link onClick={() => handleOnEditInterviewClick(item, index)}>
                                                    <i className="fa fa-pencil" aria-hidden="true" title="Edit interview"></i></Link>
                                                <a href={item.resume} className="ml-4">
                                                    <ActionImage
                                                        src={downloadIcon}
                                                        alt="downloadIcon"
                                                    />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </table>
                    {assignInterviewList && assignInterviewList.length > 0 ? "" : <h3 className="text-center"> No data available</h3>}
                </div>
            ) : (
                <div className="loaderDiv">
                    <DotLoader />
                </div>
            )}
            {
                totalCandidate > 10 ? (
                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalCandidate}
                        pageRangeDisplayed={3}
                        onChange={handleOnPageChangeClick}
                    />
                ) : null
            }

            <AntModal
                centered
                className="attend_modal"
                footer={false}
                visible={showModal}
                onCancel={closeModal}
            >
                <Modal.Body>
                    <div className={`assign_device ml-2`}>
                        <h3 className="mb-2">Candidate Status Form</h3>
                        <span className="red">* Please give rating according to 10</span>
                        <div className="row mt-4">
                            <div className={`form-group col-lg-6 ${statusErrorMessage ? "input_error" : ""
                                }`}>
                                <label className="cm_bold"> Select Status</label>
                                <Select
                                    className="cm_bold"
                                    onChange={(e) => handleAssignStatus(e)}
                                    value={status ? status : "Select Status"}
                                >
                                    <Option value={1}>Selected</Option>
                                    <Option value={3}>Rejected</Option>
                                </Select>
                                {statusErrorMessage ? (
                                    <span className="error_msg mb-5">{statusErrorMessage}</span>
                                ) : null}
                            </div>
                            <div>
                            </div>
                            {formValues.map((element, index) => (
                                <div className="form-inline col-lg-12" key={index}>

                                    <div className={`form-group col-lg-5 div_form_label ${statusErrorMessage ? "input_error" : ""
                                        }`}>
                                        <label className="cm_bold">Technology</label>

                                        <input type="text" name="tech" className="form_control cm_box" value={element?.tech} onChange={e => handleTechChange(index, e)} placeholder="Enter Tech" maxLength={25} />
                                        <div style={{ color: 'red' }}>{element.techCheck}<br />{element.techLengthCheck}</div>
                                    </div>

                                    <div className={`form-group col-lg-5 div_form_label ${statusErrorMessage ? "input_error" : ""
                                        }`}>
                                        <label className="cm_bold"> Skill Rating</label>
                                        <input type="number" name="rating" className="form_control cm_box" value={element?.rating} onChange={e => handleRatingChange(index, e)} placeholder="Enter Rating" />
                                        <div style={{ color: 'red' }}>{element.ratingCheck}<br />{element.ratingFormat}</div>
                                    </div>
                                    {
                                        index ?
                                            <i className="fa fa-times fa-2x mt-4" aria-hidden="true" onClick={() => removeFormFields(index)}></i>
                                            : null
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="addCandidateButtonsDiv">
                            {loader ? <DotLoader /> : <button
                                type="submit"
                                onClick={onSubmitClick}
                                className="btn btn-primary mt-4 mr-4 cm_btn3"
                            // disabled={miniLoader}
                            >
                                Save
                            </button>}
                            <button
                                className="btn btn-primary mt-4 mr-4 cm_btn3"
                                type="button"
                                onClick={() => addFormFields()}>
                                Add
                            </button>
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-primary mt-4 cm_btn2"
                            // disabled={miniLoader}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </AntModal>



            {/* Meeting Detail Modal */}


            <AntModal
                centered
                className="attend_modal"
                footer={false}
                visible={meetingDetail}
                onCancel={closeModal1}
            >
                <Modal.Body>
                    <div className={`assign_device ml-2`}>
                        <h3 className="mb-4">Meeting Detail</h3>
                        <div className="cm_overflow_device">
                            <div className="row cm_row">
                                <div className="col-6 cm_bold col1">Candidate Name</div>
                                <div className="col-6 cm_bold col2">
                                    {meetingInfo.candidateName}
                                </div>
                                <div className="col-6 cm_bold col1">Interview Round</div>
                                <div className="col-6 cm_bold col2">{meetingInfo.InterviewRound ? meetingInfo.InterviewRound : "_"}</div>
                                <div className="col-6 cm_bold col1">Join By Phone</div>
                                <div className="col-6 cm_bold col2">
                                    {meetingInfo.JoinByPhone ? meetingInfo.JoinByPhone : "_"}
                                </div>
                                <div className="col-6 cm_bold col1">Meeting Date and Time</div>
                                <div className="col-6 cm_bold col2">
                                    {meetingInfo.meetingDataAndTime ? meetingInfo.meetingDataAndTime : "_"}
                                </div>
                                <div className="col-6 cm_bold col1">Meeting Link</div>
                                <div className="col-6 cm_bold col2"><a href={meetingInfo.meetingLink} target="_blank">{meetingInfo.meetingLink ? meetingInfo.meetingLink : "_"}</a></div>
                                <div className="col-6 cm_bold col1">Meeting Platform</div>
                                <div className="col-6 cm_bold col2">{meetingInfo.meetingPlatform ? meetingInfo.meetingPlatform : "_"}</div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </AntModal>


            {/* Rating Detail */}
            <AntModal
                centered
                className="attend_modal"
                footer={false}
                visible={ratingInfo}
                onCancel={closeRatingModal}
            >
                <Modal.Body>
                    <h3 className="mb-4">Rating Detail</h3>

                    {ratingDetail ? (
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead className="white bg_blue">
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Technology
                                        </th>
                                        <th scope="col">Rating</th>
                                    </tr>
                                </thead>
                                {ratingDetail.map((item, index) => {
                                    return (
                                        <tbody>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {item?.tech}
                                                </td>
                                                <td>{item.rating + "/10"}</td>
                                            </tr>
                                        </tbody>
                                    );
                                })}
                            </table>
                            {ratingDetail && ratingDetail.length > 0 ? "" :
                                <h3 className="text-center">No data available</h3>
                            }
                        </div>

                    ) : (
                        <div className="loaderDiv">
                            <DotLoader />
                        </div>
                    )}
                </Modal.Body>
            </AntModal>


        </div >
    );
};
const mapStateToProps = (state) => {
    return {
        assignInterviewList: state.getAssignInterviews.data.result,
        totalCandidate: state.getAssignInterviews.data?.dataCount,
        pending: state.getAssignInterviews?.data?.countByStatus !== 0 ? state?.getAssignInterviews.data?.countByStatus?.filter(data => data._id == 2) : 0,
        accepted: state.getAssignInterviews?.data?.countByStatus !== 0 ? state?.getAssignInterviews.data?.countByStatus?.filter(data => data._id == 1) : 0,
        rejected: state.getAssignInterviews?.data?.countByStatus !== 0 ? state?.getAssignInterviews.data?.countByStatus?.filter(data => data._id == 3) : 0,
        error: state.editAssignCandidate?.error?.error?.responseMessage

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAssignInterviews: (query) => {
            dispatch(getAssignInterviews(query));

        },
        editAssignCandidateByInterviewer: (data, query, setShowModal, setResMsg, setFailResMsg, setLoader, closeModal) => {
            dispatch(editAssignCandidateByInterviewer(data, query, setShowModal, setResMsg, setFailResMsg, setLoader, closeModal));

        }

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InterviewerModule);

const ActionImage = styled.img`
        width: 30px;
        height: 30px;
        cursor: pointer;
        `;

const Button = styled.button`
        background - color: rgb(52, 123, 222);
        cursor: pointer;
        width: 200px;
        border: none;
        padding: 0.4rem;
        border - radius: 10px;
        color: white;
        font - size: medium;
        `;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, DatePicker, Checkbox, TimePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import ResponseFilter from "../../ResponseFilter";
import { Modal as AntModal } from "antd";
import { Modal } from "react-bootstrap";
import {
  addEmployee,
  getDepartmentList,
  getDesignation,
} from "../../config/services/employeeServices";
import styled from "styled-components";
import countries from "../../utils/countries.json";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import _ from "lodash";
let url = "/admin-employee";
let city = countries;

const { Option } = Select;
const format = "HH:mm";
var year = [];

for (var i = 0; i <= 20; i++) {
  year.push(i - 1);
}
var month = [];

for (var i = 0; i <= 11; i++) {
  month.push(i - 1);
}

class CreateEmp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      miniLoader: false,
      imageLoader: false,
      profileImage: "",
      profileImagePreview: "",
      firstName: "",
      middleName: "",
      lastName: "",
      empId: "",
      phone: "",
      alternateMob: "",
      currentZipErrorMsg: "",
      permanentZipErrorMsg: "",
      departmentId: [],
      designationId: [],
      personalEmail: "",
      dob: "",
      dateOfJoining: "",
      gender: "",
      genderErrorMsg: "",
      officeLocation: "",
      officeLocationErrorMsg: "",
      email: "",
      password: "",
      departmentName: "",
      departmentList: [],
      designationList: [],
      openAddPopup: false,
      loader: false,
      emailErrorMsg: "",
      phoneErrorMsg: "",
      personalEmailErrorMsg: "",
      passwordErrorMsg: "",
      lastNameErrorMsg: "",
      middleNameErrorMsg: "",
      firstNameErrorMsg: "",
      altPhoneErrorMsg: "",
      departmentIdErrorMsg: "",
      dateOfJoiningErrorMsg: "",
      dueAppraisalErrorMsg: "",
      dobErrorMsg: "",
      designationIdErrorMsg: "",
      empIdErrorMsg: "",
      experienceYear: "",
      experienceMonth: "",
      permanentAddressOne: "",
      permanentAddressTwo: "",
      permanentCity: "",
      permanentState: "",
      permanentZip: "",
      dueAppraisal: "",
      permanentCountry: "",
      currentAddressOne: "",
      currentAddressTwo: "",
      currentCity: "",
      currentState: "",
      currentZip: "",
      currentCountry: "",
      responseMessage: "",
      errorCode: "",
      isSame: false,
      role: 2,
      linkdinId: "",
      linkdinIdErrorMsg: "",
      skypeId: "",
      skypeIdErrorMsg: "",
      bloodGroup: "",
      arrivalTime: "",
      arrivalTimeErrorMsg: "",
      employeeType: "",
      employeeTypeErrorMsg: "",
      resMsg: false,
      employeeProfileErrorMsg: "",
      openProfileAddPopup: false,
      clientSide: 0,
      clientSideErrorMsg: "",
      clientLocation: "",
      clientName: "",
      src: null,
      crop: {
        unit: "%",
        x: 50,
        y: 50,
        width: 50,
        height: 50,
      },
    };
  }

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  handleArrivalTime = (arrivalTime, arrivalTimeString) =>
    this.setState({
      arrivalTime: moment.duration(arrivalTimeString).asSeconds(),
      arrivalTimeErrorMsg: "",
    });

  disabledAppraisal = (current) => {
    return current && current < moment().endOf("day");
  };
  handleZipcodeInputKeyDown = (e) => {
    var key = e.which ? e.which : e.keyCode;
    if (
      (e.target.value.length >= 8 &&
        key !== 8 &&
        key !== 37 &&
        key !== 38 &&
        key !== 39 &&
        key !== 40) ||
      key === 18 ||
      key === 189 ||
      key === 229
    ) {
      e.preventDefault();
    }
  };

  handleZipcodeInputKeyUp = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCopyAddress = () => {
    this.setState({ isSame: !this.state.isSame }, () => {
      if (this.state.isSame) {
        this.setState({
          permanentAddressOne: this.state.currentAddressOne,
          permanentAddressTwo: this.state.currentAddressTwo,
          permanentState: this.state.currentState,
          permanentZip: this.state.currentZip,
        });
      } else {
        this.setState({
          permanentAddressOne: "",
          permanentAddressTwo: "",
          permanentState: "",
          permanentZip: "",
        });
      }
    });
  };

  handleSelectChangeYear = (e) => this.setState({ experienceYear: e });
  handleSelectChangeMonth = (e) => this.setState({ experienceMonth: e });

  handleprofileValidation = () => {
    const { profileImage } = this.state;
    let validate = true;
    if (profileImage == "") {
      validate = false;
      this.setState({ employeeProfileErrorMsg: "Please Enter Image" });
    } else if (!profileImage?.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg:
          "Please Enter jpg|jpeg|png|JPEG|JPG|PNG format only",
      });
    } else if (profileImage?.size >= 10485760) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg: "please insert image of less then 10 mb",
      });
    } else {
      this.setState({ employeeProfileErrorMsg: "" });
    }
    return validate;
  };

  handleProfileSubmit = (e) => {
    const { profileImage, openAddPopup } = this.state;
    if (this.handleprofileValidation()) {
      let reader = new FileReader();
      let file = profileImage;
      this.setState(
        { openProfileAddPopup: false, imageLoader: true },
        () => {}
      );
      reader.addEventListener(
        "load",
        () => {
          this.setState({
            profileImagePreview: reader.result,
            imageLoader: false,
          });
        },
        false
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + "ErrorMsg"]: "",
      responseError: "",
      responseMessage: "",
      errorCode: "",
    });

  handlePostponeChange = (dueAppraisal, date) =>
    this.setState({
      dueAppraisal: moment(date).toDate().getTime(),
      dueAppraisalErrorMsg: "",
    });

  handleSelectChange = (e) =>
    this.setState({ departmentId: e, departmentIdErrorMsg: "" });

  handleDesignationChange = (e) => {
    this.setState({ designationId: e, designationIdErrorMsg: "" });
  };

  handleGenderChange = (e) => this.setState({ gender: e, genderErrorMsg: "" });

  handleOfficeLocationChange = (e) =>
    this.setState({ officeLocation: e, officeLocationErrorMsg: "" });

  handleClientSideChange = (e) =>
    this.setState({ clientSide: e, clientSideErrorMsg: "" });

  handleRoleChange = (e) => this.setState({ role: e });

  handleBloodGroupChange = (e) => this.setState({ bloodGroup: e });

  handleSelectEmployeeTypeChange = (e) =>
    this.setState({ employeeType: e, employeeTypeErrorMsg: "" });

  handleSelectDobChange = (date, dob) => {
    let year = moment(date).toDate().getFullYear();

    var dt = new Date();

    let currYear = dt.getFullYear();

    if (currYear - year < "18") {
      this.setState({
        dob: new Date(date).setHours(6, 30, 0, 0),
        dobErrorMsg:
          "Please select valid date of birth(age of employee must be 18 or above).",
      });
    } else {
      this.setState({
        dob: new Date(date).setHours(6, 30, 0, 0),
        dobErrorMsg: "",
      });
    }
  };

  handleSelectDojChange = (date) => {
    this.setState({
      dateOfJoining: new Date(date).setHours(6, 30, 0, 0),
      dateOfJoiningErrorMsg: "",
    });
  };

  handleValidetion = () => {
    let validate = true;
    const regx1 = /^(MOB)((?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[eE]([+-]?\d+))?$/;
    const regx = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,20})$/;
    const phoneRegx =
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[7896543210]\d{9}$/;
    const alternatePhoneRegx =
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[7896543210]\d{9}$/;
    const zipRegx = /^[a-zA-Z0-9]{4,8}$/;
    const nameRegex = /[^A-Za-z]/;
    const {
      employeeType,
      arrivalTime,
      gender,
      linkdinId,
      officeLocation,
      skypeId,
      permanentZip,
      dueAppraisal,
      underEighteenError,
      dateOfJoining,
      firstName,
      lastName,
      email,
      personalEmail,
      currentZip,
      password,
      empId,
      phone,
      alternateMob,
      designationId,
      departmentId,
      dob,
      clientSide,
      middleName,
    } = this.state;

    let validateEmail = regx.test(String(email).toLowerCase());
    let validatePersonalEmail = regx.test(String(personalEmail).toLowerCase());
    let validateEmployeeId = regx1.test(String(empId));

    let validateCurrentZipRegx = zipRegx.test(String(currentZip));
    let validateZipRegx = zipRegx.test(String(permanentZip));

    let validatePassword = password;
    let validateFirstName = firstName;
    let validateLastName = lastName;
    let validateMiddleName = middleName;
    const linkdinIdRegx =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const skypeIdRegx = /^live:\.cid\.[a-zA-Z][a-zA-Z0-9\.,\-_]{5,15}/;
    let validatelinkdinId = linkdinIdRegx.test(String(linkdinId).toLowerCase());
    let validateskypeId = skypeIdRegx.test(String(skypeId).toLowerCase());

    if (linkdinId && !validatelinkdinId) {
      validate = false;
      this.setState({ linkdinIdErrorMsg: "Please enter a valid LinkedIn ID" });
    } else {
      this.setState({ linkdinIdErrorMsg: "" });
    }

    if (skypeId && !validateskypeId) {
      validate = false;
      this.setState({ skypeIdErrorMsg: "Please enter a valid Skype ID" });
    } else {
      this.setState({ skypeIdErrorMsg: "" });
    }

    if (!arrivalTime) {
      validate = false;
      this.setState({ arrivalTimeErrorMsg: "Reporting time is required" });
    } else {
      this.setState({ arrivalTimeErrorMsg: "" });
    }

    if (!employeeType) {
      validate = false;
      this.setState({ employeeTypeErrorMsg: "Field is required" });
    } else {
      this.setState({ employeeTypeErrorMsg: "" });
    }

    if (!officeLocation) {
      validate = false;
      this.setState({ officeLocationErrorMsg: "Field is required" });
    } else {
      this.setState({ officeLocationErrorMsg: "" });
    }

    if (!gender) {
      validate = false;
      this.setState({ genderErrorMsg: "Field is required" });
    } else {
      this.setState({ genderErrorMsg: "" });
    }
    /////////////////// MODIFICATION ENDS ////////////////////

    if (!email) {
      validate = false;
      this.setState({ emailErrorMsg: "Official email is required" });
    } else if (!validateEmail) {
      validate = false;
      this.setState({ emailErrorMsg: "Please enter a valid email address" });
    } else {
      this.setState({ emailErrorMsg: "" });
    }
    if (currentZip) {
      if (!validateCurrentZipRegx) {
        validate = false;
        this.setState({ currentZipErrorMsg: "Please enter a valid zip code." });
      } else {
        this.setState({ currentZipErrorMsg: "" });
      }
    }

    if (permanentZip) {
      if (!validateZipRegx) {
        validate = false;
        this.setState({
          permanentZipErrorMsg: "Please enter a valid zip code.",
        });
      } else {
        this.setState({ permanentZipErrorMsg: "" });
      }
    }

    if (!personalEmail) {
      validate = false;
      this.setState({ personalEmailErrorMsg: "Personal email is required" });
    } else if (!validatePersonalEmail) {
      validate = false;
      this.setState({
        personalEmailErrorMsg: "Please enter a valid email address",
      });
    } else {
      this.setState({ personalEmailErrorMsg: "" });
    }

    if (validatePassword === "" || validatePassword === undefined) {
      validate = false;
      this.setState({ passwordErrorMsg: "Password is required" });
    } else if (validatePassword.length < 6) {
      validate = false;
      this.setState({ passwordErrorMsg: "Minimum 6 letter password required" });
    } else {
      this.setState({ passwordErrorMsg: "" });
    }
    if (validateFirstName === "" || validateFirstName === undefined) {
      validate = false;
      this.setState({ firstNameErrorMsg: "First name is required" });
    } else if (!nameRegex.test(validateFirstName) == false) {
      validate = false;
      this.setState({
        firstNameErrorMsg: "Special character and number are not allowed",
      });
    } else {
      this.setState({ firstNameErrorMsg: "" });
    }

    if (validateLastName === "" || validateLastName === undefined) {
      validate = false;
      this.setState({ lastNameErrorMsg: "Last name is required." });
    } else if (!nameRegex.test(validateLastName) == false) {
      validate = false;
      this.setState({
        lastNameErrorMsg: "Special character and number are not allowed",
      });
    } else {
      this.setState({ lastNameErrorMsg: "" });
    }

    if (validateMiddleName) {
      if (!nameRegex.test(validateMiddleName) == false) {
        validate = false;
        this.setState({
          middleNameErrorMsg: "Special character and number are not allowed",
        });
      }
    }

    if (empId === "" || empId === undefined) {
      validate = false;
      this.setState({ empIdErrorMsg: "Employee ID is required." });
    } else if (empId && !validateEmployeeId) {
      validate = false;
      this.setState({ empIdErrorMsg: "Employee ID should be like MOB123" });
    } else {
      this.setState({ empIdErrorMsg: "" });
    }

    ////////////////////////////MODIFICATION END////////////////////////////

    let validatePhone = phoneRegx.test(String(phone));
    let validateAlternatePhone = alternatePhoneRegx.test(String(alternateMob));
    if (phone === "" || phone === undefined) {
      validate = false;
      this.setState({ phoneErrorMsg: "Mobile number is required." });
    } else if (!validatePhone || phone === NaN) {
      validate = false;
      this.setState({ phoneErrorMsg: "Please enter a valid mobile number." });
    } else if (phone === "0000000000") {
      validate = false;
      this.setState({ phoneErrorMsg: "Please enter a valid mobile number." });
    } else {
      this.setState({ phoneErrorMsg: "" });
    }

    if (alternateMob) {
      if (!validateAlternatePhone) {
        validate = false;
        this.setState({
          altPhoneErrorMsg: "Please enter a valid mobile number.",
        });
      } else {
        this.setState({ altPhoneErrorMsg: "" });
      }
    }

    //////////////////////////MODIFICATION ENDS////////////////////////

    if (
      departmentId === "" ||
      departmentId === undefined ||
      departmentId.length === 0
    ) {
      validate = false;
      this.setState({ departmentIdErrorMsg: "Department name is required." });
    } else {
      this.setState({ departmentIdErrorMsg: "" });
    }

    if (
      designationId === "" ||
      designationId === undefined ||
      designationId.length === 0
    ) {
      validate = false;
      this.setState({ designationIdErrorMsg: "Designation is required." });
    } else {
      this.setState({ designationIdErrorMsg: "" });
    }
    if (dob === "" || dob === undefined) {
      validate = false;
      this.setState({ dobErrorMsg: "Date of birth is required." });
    } else if (
      this.state.dobErrorMsg ===
      "Please select valid date of birth(age of employee must be 18 or above)."
    ) {
      validate = false;
      this.setState({
        dobErrorMsg:
          "Please select valid date of birth(age of employee must be 18 or above).",
      });
    } else if (dob === dateOfJoining) {
      this.setState({
        dobErrorMsg: "Date of birth is  not equal to date of joining.",
      });
    } else {
      this.setState({ dobErrorMsg: "" });
    }

    if (dateOfJoining === "" || dateOfJoining === undefined) {
      validate = false;

      this.setState({ dateOfJoiningErrorMsg: "Date of joining is required." });
    } else if (dateOfJoining === dob) {
      validate = false;
      this.setState({
        dateOfJoiningErrorMsg:
          "Date of joining is  not equal to date of birth.",
      });
    } else {
      this.setState({ dateOfJoiningErrorMsg: "" });
    }

    if (
      clientSide == "" ||
      clientSide == undefined ||
      clientSide == null ||
      clientSide == false ||
      clientSide == 0
    ) {
      validate = false;
      this.setState({ clientSideErrorMsg: "Please select client Side " });
    } else {
      this.setState({ clientSideErrorMsg: "" });
    }
    return validate;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let {
      linkdinId,
      skypeId,
      bloodGroup,
      arrivalTime,
      employeeType,

      firstName,
      lastName,
      middleName,
      empId,
      email,
      dob,
      experienceYear,
      experienceMonth,
      password,
      phone,
      gender,
      officeLocation,
      departmentId,
      designationId,
      profileImage,
      dateOfJoining,
      permanentAddressOne,
      permanentAddressTwo,
      permanentCity,
      permanentState,
      permanentZip,
      permanentCountry,
      currentAddressOne,
      currentAddressTwo,
      currentCity,
      currentState,
      currentZip,
      currentCountry,
      personalEmail,
      alternateMob,
      dueAppraisal,
      role,

      clientSide,
      clientLocation,
      clientName,
    } = this.state;

    let empIds = empId.substring(0, 3).toUpperCase() + empId.slice(3);

    if (this.handleValidetion()) {
      this.setState({ miniLoader: true });
      let clientLocation1 = clientLocation
        ? clientLocation
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        : "";
      let params = new FormData();
      params.append("firstName", firstName);
      params.append("profileImage", profileImage);
      params.append("middleName", middleName);
      params.append("lastName", lastName);
      params.append("empId", empIds);
      params.append("departmentId", departmentId);
      params.append("designationId", designationId);
      params.append("email", email.toLowerCase());
      params.append("personalEmail", personalEmail.toLowerCase());
      params.append("password", password);
      params.append("phone", phone);
      params.append("alternateMob", alternateMob);
      params.append("dateOfJoining", dateOfJoining);
      params.append("dob", dob);
      params.append("gender", gender);
      params.append("officeLocation", officeLocation);
      params.append("experienceYear", experienceYear);
      params.append("experienceMonth", experienceMonth);
      params.append("dueAppraisal", dueAppraisal);
      params.append("permanentAddressOne", permanentAddressOne);
      params.append("permanentAddressTwo", permanentAddressTwo);
      params.append("permanentCity", permanentCity);
      params.append("permanentState", permanentState);
      params.append("permanentZip", permanentZip);
      params.append("permanentCountry", permanentCountry);
      params.append("currentAddressOne", currentAddressOne);
      params.append("currentAddressTwo", currentAddressTwo);
      params.append("currentCity", currentCity);
      params.append("currentState", currentState);
      params.append("currentZip", currentZip);
      params.append("currentCountry", currentCountry);
      params.append("role", role);
      ////////////////// MODIFICATION ///////////////////
      params.append("linkdinId", linkdinId);
      params.append("skypeId", skypeId);
      params.append("bloodGroup", bloodGroup);
      params.append("arrivalTime", arrivalTime);
      params.append("employeeType", employeeType);
      params.append("clientSide", clientSide);
      params.append("clientLocation", clientSide == 2 ? "" : clientLocation1);
      params.append("clientName", clientSide == 2 ? "" : clientName);

      //////////////////////////////////////
      addEmployee(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({ resMsg: true });
          setTimeout(() => this.setState({ miniLoader: false }), 3000);
          setTimeout(() => (window.location.href = "/admin-employee"), 3000);
        } else {
          this.setState({
            miniLoader: false,
            responseMessage: res.data.error.responseMessage,
            errorCode: res.data.error.errorCode,
          });
          window.scrollTo(0, 0);
        }
      });
    } else {
      window.scrollTo(0, 0);
    }
    setTimeout(() => this.setState({ resMsg: false }), 3000);
  };
  handlePhoneChange = (e) => {
    this.setState({ phoneErrorMsg: "", phone: e });
  };
  handlePhone1Change = (e) => {
    this.setState({ altPhoneErrorMsg: "", alternateMob: e });
  };

  handleZipChange = (e) => {
    this.setState({ currentZip: e });
  };
  getDepartment = () => {
    getDepartmentList().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          departmentList: resData.departmentList,
        });
      }
    });
  };

  getDesignationList = () => {
    getDesignation().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          designationList: resData.designationsList,
        });
      }
    });
  };

  componentDidMount() {
    this.getDepartment();
    this.getDesignationList();
  }

  handleOpenProfileChangePopup = () =>
    this.setState({
      openProfileAddPopup: !this.state.openProfileAddPopup,
    });

  handleCancel = (event) => {
    var fileInput = document.getElementById("profile");
    fileInput.value = null;
    // console.log("fileInput",fileInput)
    this.setState(this.getDepartment());
    this.setState(this.getDesignationList());
    this.setState({
      openProfileAddPopup: false,
      profileImage: "",
      employeeProfileErrorMsg: "",
    });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
        })
      );
      this.setState({
        profileImage: e.target.files[0],
        employeeProfileErrorMsg: "",
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    let {
      miniLoader,
      departmentList,
      profileImagePreview,
      email,
      firstName,
      middleName,
      lastName,
      empId,
      phone,
      currentZipErrorMsg,
      permanentZipErrorMsg,
      alternateMob,
      personalEmail,
      gender,
      genderErrorMsg,
      password,
      emailErrorMsg,

      linkdinId,
      skypeId,
      linkdinIdErrorMsg,
      skypeIdErrorMsg,
      bloodGroup,
      arrivalTime,
      arrivalTimeErrorMsg,
      employeeTypeErrorMsg,
      officeLocation,
      officeLocationErrorMsg,
      resMsg,
      personalEmailErrorMsg,
      passwordErrorMsg,
      lastNameErrorMsg,
      middleNameErrorMsg,
      firstNameErrorMsg,
      departmentIdErrorMsg,
      dateOfJoiningErrorMsg,
      dobErrorMsg,
      designationIdErrorMsg,
      phoneErrorMsg,
      altPhoneErrorMsg,
      empIdErrorMsg,
      designationList,
      permanentAddressOne,
      permanentAddressTwo,
      permanentState,
      permanentZip,
      currentAddressOne,
      currentAddressTwo,
      currentState,
      responseMessage,
      errorCode,
      imageLoader,
      employeeProfileErrorMsg,
      openProfileAddPopup,
      clientSide,
      clientSideErrorMsg,
      clientLocation,
      clientName,
      src,
      crop,
      croppedImageUrl,
    } = this.state;
    return (
      <div className="body_container cm_bold">
        {resMsg ? (
          <div className="alert alert-success cm_top_fix">
            New Employee Added Successfully
          </div>
        ) : (
          ""
        )}
        <div className="cm_breadcrumb">
          <ul>
            <li>
              <Link to={url}>EMPLOYEE LIST</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li>ADD NEW EMPLOYEE</li>
          </ul>
        </div>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <h4 className="cm_bold mt-4">ADD NEW EMPLOYEE</h4>

          <div className="row">
            <div className="col-10 cm_res_sapce">
              <div className="row">
                <div className="col-xl-4 cm_user_img">
                  <div className="image-upload">
                    <img
                      className=""
                      src={
                        profileImagePreview
                          ? profileImagePreview
                          : require("../../asset/imgs/user-img.png")
                      }
                      disabled={imageLoader}
                      alt=""
                    />
                    {imageLoader ? (
                      <div className="cm_img_loader">
                        <img
                          className="image_loader"
                          src={require("../../asset/svg/imageLoader.svg")}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="img-edit">
                      <span onClick={this.handleOpenProfileChangePopup}>
                        <i className="fa fa-camera pic-upload"></i>
                      </span>
                    </div>
                  </div>

                  {imageLoader ? <h1>test</h1> : ""}
                </div>

                <div className="col-xl-8">
                  {/* {dateOfJoiningErrorMsg ? <h3 className="require_error_msg">Please fill all required field</h3> : null} */}
                  <div className="row">
                    <div
                      className={`form-group col-md-6 ${
                        firstNameErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">First Name*</label>
                      <input
                        autofocus="autofocus"
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        name="firstName"
                        value={firstName}
                        onChange={this.handleChange}
                      />
                      {firstNameErrorMsg ? (
                        <span className="error_msg">{firstNameErrorMsg}</span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        middleNameErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Middle Name</label>
                      <input
                        autofocus
                        maxlength="20"
                        type="middleName"
                        className="form-control"
                        placeholder="Middle name"
                        name="middleName"
                        value={middleName}
                        onChange={this.handleChange}
                      />
                      {middleNameErrorMsg ? (
                        <span className="error_msg">{middleNameErrorMsg}</span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        lastNameErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Last Name*</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        name="lastName"
                        value={lastName}
                        onChange={this.handleChange}
                      />
                      {lastNameErrorMsg ? (
                        <span className="error_msg">{lastNameErrorMsg}</span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        empIdErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Employee ID*</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="Employee ID"
                        name="empId"
                        value={empId}
                        onChange={this.handleChange}
                      />
                      {empIdErrorMsg ? (
                        <span className="error_msg">{empIdErrorMsg}</span>
                      ) : null}
                      {errorCode === 10 ? (
                        <h3 className="cm_response mt-2">{responseMessage}</h3>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* ******************MODIFICATION START******************** */}
                    <div
                      className={`form-group col-md-6 ${
                        phoneErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Mobile Number*</label>

                      <input
                        maxlength="10"
                        className="form-control"
                        placeholder="Mobile no."
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={this.handleChange}
                      />
                      {phoneErrorMsg ? (
                        <span className="error_msg">{phoneErrorMsg}</span>
                      ) : null}
                      {errorCode === 6 ? (
                        <h3 className="cm_response mt-2">{responseMessage}</h3>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="sub">Alternate Mobile Number</label>
                      <input
                        maxlength="10"
                        className="form-control"
                        placeholder="Alternate Mobile no."
                        type="text"
                        name="alternateMob"
                        value={alternateMob}
                        onChange={this.handleChange}
                      />

                      {altPhoneErrorMsg ? (
                        <span className="error_msg">{altPhoneErrorMsg}</span>
                      ) : null}
                    </div>
                    {/* ******************MODIFICATION END******************** */}

                    <div
                      className={`form-group col-md-6 ${
                        departmentIdErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Department*</label>
                      <div className="selectHeight selectDesig">
                        <Select
                          placeholder="Department"
                          onChange={this.handleSelectChange}
                          style={{ width: "100%" }}
                        >
                          {departmentList.map((item) => (
                            <Select.Option
                              key={item.departmentId}
                              value={item.departmentId}
                            >
                              {item.departmentName}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>

                      {departmentIdErrorMsg ? (
                        <span className="error_msg">
                          {departmentIdErrorMsg}
                        </span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        designationIdErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Designation*</label>
                      <div className="selectHeight selectDesig">
                        <Select
                          placeholder="Designation"
                          onChange={this.handleDesignationChange}
                          style={{ width: "100%" }}
                        >
                          {designationList.map((item) => (
                            <Select.Option
                              key={item.designationId}
                              value={item.designationId}
                            >
                              {item.designationName}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>

                      {designationIdErrorMsg ? (
                        <span className="error_msg">
                          {designationIdErrorMsg}
                        </span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        emailErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Official Email ID*</label>
                      <input
                        maxlength="50"
                        className="form-control cm_box"
                        type="text"
                        placeholder="Official email ID"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                      {emailErrorMsg ? (
                        <span className="error_msg">{emailErrorMsg}</span>
                      ) : null}
                      {errorCode === 11 ? (
                        <h3 className="cm_response mt-2">{responseMessage}</h3>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        personalEmailErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Personal Email ID*</label>
                      <input
                        maxlength="50"
                        type="email"
                        className="form-control"
                        placeholder="Personal email ID"
                        name="personalEmail"
                        value={personalEmail}
                        onChange={this.handleChange}
                      />
                      {personalEmailErrorMsg ? (
                        <span className="error_msg">
                          {personalEmailErrorMsg}
                        </span>
                      ) : null}
                      {errorCode === 12 ? (
                        <h3 className="cm_response mt-2">{responseMessage}</h3>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* ************************* modification start ************************** */}
                    {/*  */}
                    <div
                      className={`form-group col-md-6 ${
                        linkdinIdErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">LinkedIn</label>
                      <input
                        maxlength="50"
                        className="form-control cm_box"
                        type="text"
                        placeholder="https://www.linkedin.com/in/abc-xyz-12a345678/"
                        name="linkdinId"
                        value={linkdinId}
                        onChange={this.handleChange}
                      />
                      {linkdinIdErrorMsg ? (
                        <span className="error_msg">{linkdinIdErrorMsg}</span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        skypeIdErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Skype</label>
                      <input
                        maxlength="50"
                        type="skypeId"
                        className="form-control"
                        placeholder="live:.cid.a0123bcde45fgh6i"
                        name="skypeId"
                        value={skypeId}
                        onChange={this.handleChange}
                      />
                      {skypeIdErrorMsg ? (
                        <span className="error_msg">{skypeIdErrorMsg}</span>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6 mt-2">
                      <label className="sub">Blood Group</label>
                      <Select
                        defaultValue="bloodGroup"
                        onChange={this.handleBloodGroupChange}
                        name="bloodGroup"
                        value={bloodGroup ? bloodGroup : "Blood Group"}
                      >
                        <Option value="1">A+</Option>
                        <Option value="2">B+</Option>
                        <Option value="3">O+</Option>
                        <Option value="4">AB+</Option>
                        <Option value="5">A-</Option>
                        <Option value="6">B-</Option>
                        <Option value="7">O-</Option>
                        <Option value="8">AB-</Option>
                      </Select>
                    </div>

                    {/* ************************** modification ends ******************************** */}

                    <div
                      className={`form-group col-md-6 ${
                        dobErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">D.O.B*</label>
                      <DatePicker
                        allowClear={false}
                        disabledDate={this.disabledDate}
                        className="date-border"
                        name="dob"
                        onChange={this.handleSelectDobChange}
                      />
                      {dobErrorMsg ? (
                        <span className="error_msg">{dobErrorMsg}</span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        genderErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Gender*</label>
                      <Select
                        defaultValue="Female"
                        onChange={this.handleGenderChange}
                        name="gender"
                        value={gender ? gender : "Gender"}
                      >
                        <Option value="1">Male</Option>
                        <Option value="2">Female</Option>
                        <Option value="3">Other</Option>
                      </Select>
                      {genderErrorMsg ? (
                        <span className="error_msg">{genderErrorMsg}</span>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="sub">Previous Experience</label>
                      <div className="row">
                        <div className="col-6">
                          <Select
                            defaultValue="Year"
                            onChange={this.handleSelectChangeYear}
                          >
                            {year.map((val, index) => (
                              <Option key={index} value={val + 1}>
                                {val + 1}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="col-6">
                          <Select
                            defaultValue="Month"
                            onChange={this.handleSelectChangeMonth}
                          >
                            {month.map((val, index) => (
                              <Option key={index} value={val + 1}>
                                {val + 1}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        passwordErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Password*</label>
                      <input
                        maxlength="50"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                      {passwordErrorMsg ? (
                        <span className="error_msg">{passwordErrorMsg}</span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        employeeTypeErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Employee Status*</label>
                      <Select
                        placeholder="Employee Type"
                        onChange={this.handleSelectEmployeeTypeChange}
                        name="employeeType"
                      >
                        <Option value="1">Fresher</Option>
                        <Option value="2">Probation</Option>
                        <Option value="4">Intern</Option>
                      </Select>
                      {employeeTypeErrorMsg ? (
                        <span className="error_msg">
                          {employeeTypeErrorMsg}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12">
                      <label className="sub">Current Address One</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control mb-3"
                        placeholder="Current address one"
                        name="currentAddressOne"
                        value={currentAddressOne}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="sub">Current Address Two</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control mb-3"
                        placeholder="Current address two"
                        name="currentAddressTwo"
                        value={currentAddressTwo}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6 mt-2">
                      <label className="sub">Current Address State</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control mb-3"
                        placeholder="Current address state"
                        name="currentState"
                        value={currentState}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-6 mt-2">
                      <label className="sub">Current Address Zip</label>

                      <input
                        className="form-control"
                        inputmode="numeric"
                        onKeyDown={(e) => this.handleZipcodeInputKeyDown(e)}
                        onKeyUp={(e) => this.handleZipcodeInputKeyUp(e)}
                        pattern="\d*"
                        placeholder="Current address zip"
                        type="number"
                        name="currentZip"
                        onChange={this.handleChange}
                      />

                      {currentZipErrorMsg ? (
                        <span className="error_msg">{currentZipErrorMsg}</span>
                      ) : null}
                    </div>
                  </div>

                  <Checkbox onChange={this.handleCopyAddress}>
                    <label className="sub">Same as current address</label>
                  </Checkbox>
                  <div className="row mt-4">
                    <div className="form-group col-12">
                      <label className="sub">Permanent Address One</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="Permanent address one"
                        name="permanentAddressOne"
                        value={permanentAddressOne}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12">
                      <label className="sub">Permanent Address Two</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="Permanent address two"
                        name="permanentAddressTwo"
                        value={permanentAddressTwo}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="sub">Permanent Address State</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="Permanent address state"
                        name="permanentState"
                        value={permanentState}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="sub">Permanent Address Zip</label>
                      <input
                        className="form-control"
                        inputmode="numeric"
                        onKeyDown={(e) => this.handleZipcodeInputKeyDown(e)}
                        onKeyUp={(e) => this.handleZipcodeInputKeyUp(e)}
                        // onPaste={e => this.handleZipcodeInputPaste(e)}
                        pattern="\d*"
                        value={permanentZip}
                        placeholder="Permanent address zip"
                        type="number"
                        name="permanentZip"
                        onChange={this.handleChange}
                      />

                      {permanentZipErrorMsg ? (
                        <span className="error_msg">
                          {permanentZipErrorMsg}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div
                      className={`form-group col-md-6 ${
                        dateOfJoiningErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">D.O.J*</label>
                      <DatePicker
                        disabledDate={this.disabledDate}
                        allowClear={false}
                        className=" date-border"
                        name="dateOfJoining"
                        id="dateOfJoining"
                        onChange={this.handleSelectDojChange}
                      />
                      {dateOfJoiningErrorMsg ? (
                        <span className="error_msg">
                          {dateOfJoiningErrorMsg}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="sub">Due Appraisal</label>

                      <DatePicker
                        disabledDate={this.disabledAppraisal}
                        className=" date-border"
                        allowClear={false}
                        onChange={this.handlePostponeChange}
                      />
                    </div>
                  </div>

                  {/* *****************MODIFICATION START******************* */}
                  <div className="row">
                    <div className="form-group col-md-6 mt-2">
                      <label className="sub">Role</label>
                      <Select
                        defaultValue="Employee"
                        onChange={this.handleRoleChange}
                        name="role"
                        //value={role}
                      >
                        <Option value="2">Employee</Option>
                        <Option value="3">Vender</Option>
                        <Option value="4">HR</Option>
                        <Option value="5">Accountant</Option>
                        <Option value="6">Sales</Option>
                        <Option value="7">IT</Option>
                        <Option value="8">Office Admin</Option>
                      </Select>
                    </div>

                    <div
                      className={`form-group col-md-6 mt-2 ${
                        arrivalTimeErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Reporting Time*</label>

                      <TimePicker
                        className="form-control"
                        type="time"
                        allowClear={false}
                        format={format}
                        name="arrivalTime"
                        value={
                          arrivalTime ? moment.utc(arrivalTime * 1000) : ""
                        }
                        onChange={this.handleArrivalTime}
                      />
                      {arrivalTimeErrorMsg ? (
                        <span className="error_msg">{arrivalTimeErrorMsg}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`form-group col-md-6 ${
                        officeLocationErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Office Location*</label>
                      <Select
                        onChange={this.handleOfficeLocationChange}
                        name="officeLocation"
                        value={
                          officeLocation ? officeLocation : "Office Location"
                        }
                      >
                        <Option value="1">Noida</Option>
                        <Option value="2">Lucknow</Option>
                      </Select>
                      {officeLocationErrorMsg ? (
                        <span className="error_msg">
                          {officeLocationErrorMsg}
                        </span>
                      ) : null}
                    </div>

                    <div
                      className={`form-group col-md-6 ${
                        clientSideErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="sub">Client Side*</label>
                      <Select
                        onChange={this.handleClientSideChange}
                        name="clientSide"
                        value={clientSide ? clientSide : "Client Side"}
                      >
                        <Option value={1}>Yes</Option>
                        <Option value={2}>No</Option>
                      </Select>
                      {clientSideErrorMsg ? (
                        <span className="error_msg">{clientSideErrorMsg}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className={`form-group col-md-6`}>
                      <label className="sub">Client Location</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="Client Location"
                        name="clientLocation"
                        value={clientLocation}
                        onChange={this.handleChange}
                        disabled={clientSide == 1 ? false : true}
                      />
                    </div>

                    <div className={`form-group col-md-6`}>
                      <label className="sub">Client Name</label>
                      <input
                        maxlength="50"
                        type="text"
                        className="form-control"
                        placeholder="Client Name"
                        name="clientName"
                        value={clientName}
                        onChange={this.handleChange}
                        disabled={clientSide == 1 ? false : true}
                      />
                    </div>
                  </div>

                  {/* *******************MODIFICATION ENDS******************** */}

                  <div className="col">
                    <div className="row">
                      <div className="col-4">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={miniLoader}
                        >
                          Save
                        </button>
                      </div>
                      {miniLoader ? (
                        <div className="mini_loader_dept emp_loader mt-5"></div>
                      ) : (
                        ""
                      )}

                      {miniLoader ? (
                        ""
                      ) : (
                        <div className="col-4">
                          <Link
                            to={url}
                            className="cancel_btn btn btn-default mr-0 btn-block"
                          >
                            Cancel
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
        </form>
        <AntModal
          title=""
          centered
          className="sm_modal"
          footer={false}
          visible={openProfileAddPopup}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Modal.Body>
            <div
              className="assign_device"
              // onSubmit={this.handleEditSubmit}
            >
              <h3 className="mb-4">ADD PROFILE PICTURE</h3>
              <div className="row">
                <div
                  className={`form-group col-4 ${
                    employeeProfileErrorMsg ? "input_error" : ""
                  }`}
                >
                  <InputField
                    className="form-control cm_bold cm_box "
                    type="file"
                    id="profile"
                    placeholder="Upload image"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.onSelectFile}
                  />
                  <div className="mt-2">
                    {src && (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        aspect={1}
                        ruleOfThirds
                        circularCrop
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        style={{ width: "14rem", height: "14rem" }}
                      />
                    )}
                    {src && croppedImageUrl && (
                      <img
                        alt="Crop"
                        style={{
                          width: "12rem",
                          position: "absolute",
                          bottom: "5px",
                          marginLeft: "12px",
                          borderRadius: "50%",
                        }}
                        src={croppedImageUrl}
                      />
                    )}
                  </div>
                  {employeeProfileErrorMsg ? (
                    <span className="error_msg">{employeeProfileErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="submit_button_margin mt-4">
                <button
                  type="submit"
                  className="btn btn-primary cm_btn3"
                  onClick={this.handleProfileSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-primary cm_btn2"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </AntModal>
      </div>
    );
  }
}
export default CreateEmp;
const InputField = styled.input`
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 0.5rem;
  border: 1px solid gray;
`;

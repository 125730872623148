import { ContentState, EditorState, convertToRaw } from "draft-js";
import ResponseFilter from "../../ResponseFilter";
import {
  addNewTicket,
  deleteTicket,
  getEmployeeList,
} from "../../config/services/employeeServices";
import { REQUIRED, setResponseAction, type } from "../helper/Constant";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export const statusValue = (type) => {
  switch (type) {
    case 1:
      return "Pending";
    case 2:
      return "Resolved";
    case 3:
      return "Reopen";
    default:
      return "";
  }
};

export const colorType = (type) => {
  switch (type) {
    case 1:
      return "text-secondary";
    case 2:
      return "text-success";
    case 3:
      return "text-danger";
    default:
      return "";
  }
};

export const categoryType = (type) => {
  switch (type) {
    case 1:
      return "Software";
    case 2:
      return "Hardware";
    case 3:
      return "HR related";
    case 5:
      return "Account";
    default:
      return "others";
  }
};

export const CATEGORY_ARRAY = [
  { value: "1", label: "Software" },
  { value: "2", label: "Hardware" },
  { value: "3", label: "HR related" },
  { value: "5", label: "Accountant" },
  { value: "4", label: "Others" },
];
export const CATEGORY_ACCOUNTANT = [{ value: "5", label: "Accountant" }];
export const CATEGORY_IT = [
  { value: "1", label: "Software" },
  { value: "2", label: "Hardware" },
];
export const handleSubmit = (
  event,
  state,
  getTicketList,
  closeModal,
  dispatch,
  isAdmin
) => {
  event.preventDefault();
  let { category, subject, editorState, emails, employeeId } = state;
  if (handleValidation({ state, dispatch, isAdmin })) {
    dispatch({
      type: type,
      payload: { loader: true },
    });
    let obj = {
      userId: employeeId ? employeeId : "",
      category: category,
      subject: subject,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      emailInCC: emails.length !== 0 ? emails.toString() : [],
    };
    addNewTicket(obj)
      .then((res) => {
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              successMessage: "Ticket Added",
              resMsg: true,
            },
          });
          getTicketList();
          closeModal();
          setResponseAction({ dispatch });
        } else {
          dispatch({
            type: type,
            payload: {
              resFailMsg: true,
              responseMessage: res?.data?.error?.responseMessage,
            },
          });
          getTicketList();
          closeModal();
          setResponseAction({ dispatch });
        }
      })
      .catch((error) => {
        console.error("Error during adding ticket:", error);
      });
  }
};

export const handleValidation = (props) => {
  const { state, dispatch, isAdmin } = props;
  let validate = true;
  const { subject, editorState, category, emails, employeeId } = state;
  const regMatch = /^[a-zA-Z ]*$/.test(subject);
  if (isAdmin && (employeeId === "" || employeeId === undefined)) {
    validate = false;
    dispatch({
      type: type,
      payload: { employeeIdErrorMsg: "Employee Id is required" },
    });
  }
  if (subject === "" || subject === undefined) {
    validate = false;
    dispatch({
      type: type,
      payload: { subjectErrorMsg: "Subject is required" },
    });
  } else if (!regMatch) {
    validate = false;
    dispatch({
      type: type,
      payload: { subjectErrorMsg: "Subject should only contain letters" },
    });
  } else {
    dispatch({
      type: type,
      payload: { subjectErrorMsg: "" },
    });
  }
  let newEditorState = convertToRaw(editorState.getCurrentContent()).blocks[0]
    .text;
  if (newEditorState === "" || newEditorState === undefined) {
    validate = false;
    dispatch({
      type: type,
      payload: { descriptionErrorMsg: "Description is required" },
    });
  } else {
    dispatch({
      type: type,
      payload: { descriptionErrorMsg: "" },
    });
  }

  if (category === "" || category === undefined) {
    validate = false;
    dispatch({
      type: type,
      payload: { categoryErrorMsg: "Category is required" },
    });
  } else {
    dispatch({
      type: type,
      payload: { categoryErrorMsg: "" },
    });
  }
  return validate;
};

export const getEmployee = (props) => {
  const { dispatch } = props;
  getEmployeeList()
    .then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: { employeeList: resData.employeesList },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: type,
        payload: {
          resFailMsg: true,
          responseMessage: error,
          loader: false,
        },
      });
    });
  setTimeout(() => {
    dispatch({
      type: type,
      payload: { resFailMsg: false },
    });
  }, 3000);
};

export const onEditorStateChange = (e, dispatch) => {
  dispatch({
    type: type,
    payload: {
      editorState: e,
      descriptionErrorMsg: "",
    },
  });
};

export const handleDelete = (props) => {
  const { e, ticketId, dispatch, getViewTicket, closeModal } = props;
  dispatch({
    type: type,
    payload: { loader: true },
  });

  e.preventDefault();
  let params = { ticketId: ticketId };
  deleteTicket(params)
    .then((res) => {
      dispatch({
        type: type,
        payload: { loader: false },
      });

      let { status } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: { resMsg: true, successMessage: "Data Deleted" },
        });
        getViewTicket();
        closeModal();
        setResponseAction({ dispatch });
      } else {
        dispatch({
          type: type,
          payload: {
            resFailMsg: true,
            responseMessage: res.data.error.responseMessage,
          },
        });
        getViewTicket();
        closeModal();
        setResponseAction({ dispatch });
      }
    })
    .catch((error) => {
      dispatch({
        type: type,
        payload: {
          resFailMsg: true,
          responseMessage: error,
          loader: false,
        },
      });
      getViewTicket();
      closeModal();
      setResponseAction({ dispatch });
    });
};

export const createEditorStateFromHTML = (html) => {
  if (html) {
    let wrappedHtmlContent = html.includes("<p>")
      ? html
      : `<p>${html.replaceAll("<br />", "")}</p>`;
    const contentBlock = htmlToDraft(wrappedHtmlContent);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const _editorState = EditorState.createWithContent(contentState);
      return _editorState;
    }
  }
  return EditorState.createEmpty();
};

export const htmlToText = (html) => {
  let wrappedHtmlContent = html.toString().includes("<p>")
    ? html
    : `<p>${html}</p>`;
  const contentBlock = htmlToDraft(wrappedHtmlContent);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const description = convertToRaw(
    EditorState.createWithContent(contentState).getCurrentContent()
  ).blocks[0].text;
  return description;
};

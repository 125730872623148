import React from "react";
import moment from "moment";
import { Select, Modal as AntModal } from "antd";
import { getReimburseByEmpId } from "../../config/services/employeeServices";
import { Link } from "react-router-dom";
import { Loader } from "../svg";
import Modal from "react-bootstrap/Modal";
import ResponseFilter from "../../ResponseFilter";
import _ from "lodash";

const colorType = (type) => {
  switch (type) {
    case 1:
      return "yellow";
      break;

    case 2:
      return "text-success";
      break;

    case 3:
      return "text-danger";
  }
};
let arrayOfYear = [];

const currentYear = new Date().getFullYear();
for (let i = 0; i <= 3; i++) {
  arrayOfYear.push(currentYear - i);
}

class GetReimbursementByEmp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseDescription: "",
      reimbursementDetails: [],
      employeeDetails: "",
      employeeId: "",
      sortType: 2,
      sortField: "",
      openRembInfoPopup: false,
      status: props.location.state ? props.location.state.status : "",
      searchDate: "",
      searchYear: this.props.location.state.searchYear,
    };
  }
  componentDidMount() {
    this.getReimburseByEmp();
  }
  closeModal = () =>
    this.setState({
      openRembInfoPopup: false,
    });

  handleDate = (e) => {
    this.setState(
      { searchDate: e ? new Date(e).setHours(6, 30, 0, 0) : "" },
      () => {
        this.getReimburseByEmp();
      }
    );
  };

  getReimburseByEmp = () => {
    this.setState({ loader: true });
    let params = `employeeId=${this.props.match.params.id}&status=${this.state.status}&searchYear=${this.state.searchYear}`;

    getReimburseByEmpId(params).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      let reimbursementDetails;

      if (status) {
        reimbursementDetails = resData.reimbursementDetails;
        this.setState({
          reimbursementDetails: reimbursementDetails,
          employeeDetails: resData.employeeDetails,
        });
      } else if (res.data.statusCode === 0) {
        this.setState({ loader: false });
      }
    });
  };

  handleOpenRembInfoPopup = (reimbursement) => {
    let { expenseDescription, reimbursementId } = reimbursement;
    this.setState(
      {
        openRembInfoPopup: !this.state.openRembInfoPopup,
        expenseDescription,
        reimbursementId,
      });
  };
  render() {
    let {
      loader,
      reimbursementDetails,
      employeeDetails,
      openRembInfoPopup,
      expenseDescription,
    } = this.state;

    return (
      <div className="body_container cm_bold">
        <div className="cm_breadcrumb cm_bold">
          <ul>
            <li>
              <Link to="/admin-reimbursement">REIMBURSEMENT</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>

            <li style={{ textTransform: "uppercase" }}>
              {employeeDetails.firstName} {employeeDetails.lastName}'S
              REIMBURSEMENTS ({this.state.searchYear})
            </li>
          </ul>
        </div>
        {loader && <Loader />}
        <h3 className="mb-5 mt-4">REIMBURSEMENT</h3>
        <h5 className="mb-3 cm_bold">Monthly Records</h5>
        {!_.isEmpty(reimbursementDetails) &&
          reimbursementDetails.map((reimbursements, i) => (
            <div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="white bg_blue">
                    <tr>
                      <th scope="col" style={{ width: 300 }}>
                        Month
                      </th>

                      <th scope="col" style={{ width: 300 }}>
                        Reimbursement Claimed
                      </th>
                      <th scope="col">status</th>
                    </tr>
                  </thead>
                  <tbody>

                    {!_.isEmpty(reimbursements.reimburseRecord) &&
                      reimbursements.reimburseRecord.map(
                        (reimbursement, i) =>
                          i === 0 && (
                            <tr>
                              <td style={{ width: 200 }}>
                                {" "}
                                <Link
                                  to={{
                                    pathname: `/admin-reimbursement/admin-reimbursement-action/${reimbursement.employeeId}`,
                                    state: {
                                      reqMonth: moment(
                                        reimbursement.expenseDate
                                      ).format("MM"),
                                      status: this.state.status,
                                      firstName: employeeDetails.firstName,
                                      lastName: employeeDetails.lastName,
                                      searchYear: this.state.searchYear,
                                      id: reimbursement.employeeId
                                    },
                                  }}
                                >
                                  {moment(reimbursement.expenseDate).format(
                                    "MMMM"
                                  )}
                                </Link>
                              </td>

                              <td>
                                {" "}
                                <span className="cm_col">
                                  {reimbursements.reimburseRecord.length}
                                </span>
                              </td>
                              <td
                                className={colorType(reimbursements.status)}
                                style={{ width: "350px" }}
                              >
                                <div className="cm_btn_status">
                                  <button
                                    type="button"
                                    style={{ cursor: 'default' }}
                                    className="btn btn-success btn-sm mr-2  mb-2 cm_rem_dot"
                                  >
                                    {reimbursements.approved} Approved
                                  </button>
                                  <button
                                    style={{ cursor: 'default' }}
                                    type="button"
                                    className="btn btn-primary btn-sm mr-2 mb-2  cm_rem_dot"
                                  >
                                    {reimbursements.pending} Pending
                                  </button>
                                  <button
                                    type="button"
                                    style={{ cursor: 'default' }}
                                    className="btn btn-danger btn-sm  mb-2 cm_rem_dot"
                                  >
                                    {reimbursements.rejected} Rejected
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                      )}
                    {!_.isEmpty(reimbursementDetails) &&
                      reimbursementDetails.filter(
                        (reimbursement) =>
                          reimbursement.status === this.state.status
                      ) ? (
                      ""
                    ) : (
                      <h3 className="text-center">No data available</h3>
                    )}
                  </tbody>
                </table>
                {!_.isEmpty(reimbursementDetails) ? (
                  ""
                ) : (
                  <h3 className="text-center">No data available</h3>
                )}
              </div>
            </div>
          ))}{" "}
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRembInfoPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="mb-2 mt-4">EXPENSE DESCRIPTION</h3>
              <div className="cm_overflow_reimbur">
                <p>{expenseDescription}</p>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
      </div>
    );
  }
}

export default GetReimbursementByEmp;

import { REQUIRED, } from '../attendance/Constants'
export const teamEditValidation = (props) => {
    let { teamName, teamLead, teamIcon, associateTeamLead, dispatch, previousTeamIcon, editTeamIcon } = props
    let validate = true
    if (teamName == "" || teamName == null || teamName == undefined) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamNameErrorMsg: REQUIRED,
            }
        })
    }
    else {
        dispatch({
            type: 'typeCheckSet', payload: {
                teamNameErrorMsg: "",
            }
        })
    }

    if (teamLead == "" || teamLead == undefined || teamLead == 0) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamLeadErrorMessage: REQUIRED,
            }
        })
    }
    else {
        dispatch({
            type: 'typeCheckSet', payload: {
                teamLeadErrorMessage: "",
            }
        })
    }


    // if (associateTeamLead == "" || associateTeamLead == undefined || associateTeamLead == 0) {
    //     validate = false;
    //     dispatch({
    //         type: 'typeCheckSet', payload: {
    //             associateTeamLeadErrorMessage: REQUIRED,
    //         }
    //     })
    // }
    // else {
    //     dispatch({
    //         type: 'typeCheckSet', payload: {
    //             associateTeamLeadErrorMessage: "",
    //         }
    //     })

    // }
    if (previousTeamIcon && teamIcon === "") {
        dispatch({
            type: 'typeCheckSet', payload: {
                teamIconErrorMessage: "",
            }
        })
    }
    else {
        if (teamIcon == '' || teamIcon == undefined) {
            validate = false;
            dispatch({
                type: 'typeCheckSet', payload: {
                    teamIconErrorMessage: REQUIRED,
                }
            })
        }
        else if (!teamIcon?.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
            validate = false;
            dispatch({
                type: 'typeCheckSet', payload: {
                    teamIconErrorMessage: "Please enter image only",
                }
            })
        }
        else if (teamIcon?.size >= 10485760) {
            validate = false;
            dispatch({
                type: 'typeCheckSet', payload: {
                    teamIconErrorMessage: "Please insert image of less then 10 mb",
                }
            })
        }
        else {
            dispatch({
                type: 'typeCheckSet', payload: {
                    teamIconErrorMessage: "",
                }
            })
        }
    }
    return validate
}

export const teamAddValidation = (props) => {
    let { teamName, teamLead, teamIcon, teamMembers, associateTeamLead, dispatch } = props
    let validate = true
    if (teamName == "") {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamNameErrorMsg: REQUIRED,
            }
        })
    }
    else {
        dispatch({
            type: 'typeCheckSet', payload: {
                teamNameErrorMsg: "",
            }
        })
    }

    if (teamLead == "" || teamLead == undefined || teamLead == 0) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamLeadErrorMessage: REQUIRED,
            }
        })
    }
    else {
        dispatch({
            type: 'typeCheckSet', payload: {
                teamLeadErrorMessage: "",
            }
        })
    }


    // if (associateTeamLead == "" || associateTeamLead == undefined || associateTeamLead == 0) {
    //     validate = false;
    //     dispatch({
    //         type: 'typeCheckSet', payload: {
    //             associateTeamLeadErrorMessage: REQUIRED,
    //         }
    //     })
    // }
    // else {
    //     dispatch({
    //         type: 'typeCheckSet', payload: {
    //             associateTeamLeadErrorMessage: "",
    //         }
    //     })

    // }

    if (teamMembers == "" || teamMembers == undefined || teamMembers.length == 0) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamMembersErrorMessage: REQUIRED,
            }
        })
    }
    else {
        dispatch({
            type: 'typeCheckSet', payload: {
                teamMembersErrorMessage: "",
            }
        })
    }

    if (teamIcon == '' || teamIcon == undefined) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamIconErrorMessage: REQUIRED,
            }
        })
    }
    else if (!teamIcon?.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamIconErrorMessage: "Please enter image only",
            }
        })
    }
    else if (teamIcon?.size >= 10485760) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                teamIconErrorMessage: "Please insert image of less then 10 mb",
            }
        })
    }
    else {
        dispatch({
            type: 'typeCheckSet', payload: {
                teamIconErrorMessage: "",
            }
        })
    }
    return validate
}

export const handleValidetion = (props) => {
    let validate = true;
    const { employee, dispatch } = props;

    if (employee === '' || employee === undefined || employee.length == 0) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                employeeErrorMsg: "Please Select Employees",
            }
        })
    }
    else {

        dispatch({
            type: 'typeCheckSet', payload: {
                employeeErrorMsg: "",
            }
        })
    }
    return validate;
}

export const handleDeleteValidetion = (props) => {
    let validate = true;
    const { multiValue, dispatch } = props;

    if (multiValue === '' || multiValue === undefined || multiValue.length == 0) {
        validate = false;
        dispatch({
            type: 'typeCheckSet', payload: {
                deleteEmployeeErrorMsg: "Please Select Employees to Delete",
            }
        })
    }
    else {
        dispatch({
            type: 'typeCheckSet', payload: {
                deleteEmployeeErrorMsg: "",
            }
        })
    }
    return validate;
}
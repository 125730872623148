import { type } from "../../components/helper/Constant";
import { addCourierDetailsByAdmin } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
  ADD_COURIER_BY_ADMIN_REQUEST,
  ADD_COURIER_BY_ADMIN_SUCCESS,
  ADD_COURIER_BY_ADMIN_FAILURE,
} from "./actionType";

const addCourierDetailsByAdminAction = (props) => {
  let { useDispatch, params, getCourierList, handleClose } = props;
  return (dispatch) => {
    dispatch(addCourierDetailsByAdminRequest());
    addCourierDetailsByAdmin(params)
      .then((res) => {
        let { status } = ResponseFilter(res);
        if (status) {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              resMsg: true,
              successMessage: "Courier Added",
              courierFile: "",
            },
          });
          setTimeout(() => {
            useDispatch({
              type: type,
              payload: {
                resMsg: false,
              },
            });
          }, 3000);
          getCourierList();
        } else {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              resMsgFail: true,
              responseMessage:
                res?.data?.error.errors.length > 0
                  ? res?.data?.error.errors[0].message
                  : res?.data?.error?.responseMessage,
            },
          });
          setTimeout(() => {
            useDispatch({
              type: type,
              payload: {
                resMsgFail: false,
              },
            });
          }, 3000);
          handleClose();
          getCourierList();
        }
      })
      .catch((error) => {
        dispatch(addCourierDetailsByAdminFailure(error));
      });
  };
};

const addCourierDetailsByAdminRequest = () => {
  return {
    type: ADD_COURIER_BY_ADMIN_REQUEST,
  };
};
const addCourierDetailsByAdminSuccess = (data) => {
  return {
    type: ADD_COURIER_BY_ADMIN_SUCCESS,
    payload: data,
  };
};
const addCourierDetailsByAdminFailure = (error) => {
  return {
    type: ADD_COURIER_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  addCourierDetailsByAdminAction,
  addCourierDetailsByAdminRequest,
  addCourierDetailsByAdminSuccess,
  addCourierDetailsByAdminFailure,
};

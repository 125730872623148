import React from 'react';

import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import ResponseFilter from '../../ResponseFilter';
import Pagination from "react-js-pagination";
import { DatePicker, Modal as AntModal } from 'antd';
import moment from 'moment';
import { Loader } from '../svg';
import { getAppraisalList, addAppraisal, getEmployeeList, editAppraisal, postponeAppraisal } from '../../config/services/employeeServices';
import { Select } from 'antd';
import { CONSTANTS } from '../../config';
let TAB_STATUS_CONSTANTS = CONSTANTS.TAB_STATUS_CONSTANTS


const { Option } = Select;

class Appraisal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status1: '',
      openAddAppraisalPopup: false,
      openEditAppraisalPopup: false,
      openDueAppraisalPopup: false,
      openPostponeAppraisalPopup: false,
      AppraisalList: [],
      employeeList: [],
      status: '',
      search: '',
      loader: true,
      sortType: 2,
      sortField: 'created',
      pageNo: 1,
      count: 10,
      totalAppraisal: '',
      employeeId: '',
      date: '',
      currentCtc: '',
      appraisalId: '',
      dueAppraisal: '',
      variables: '',
      bonus: '',
      hike: '',
      month: '',
      year: '',
      empId: '',
      dateErrorMsg: '',
      currentCtcErrorMsg: '',
      dueAppraisalErrorMsg: '',
      dataCount: '',
      pendingCount: '',
      approvedCount: '',
      rejectedCount: '',
      postPoneCount: '',
      active: TAB_STATUS_CONSTANTS.STATUS.one,

    }
    this.debounceSearch = null

  }


  statusType = type => {
    switch (type) {
      case 1: return 'Upcoming';
        break;

      case 2: return 'Done';
        break;

      case 3: return 'Postpone';
        break;

      case 4: return 'Reject';

    }
  }
  colorType = type => {
    switch (type) {
      case 1: return 'text-danger';
        break;

      case 2: return 'text-success';
        break;

      default: return 'text-primary';

    }
  }

  handleValidetion = () => {
    let validate = true;
    const { dueAppraisal, currentCtc, employeeId } = this.state;
    if (dueAppraisal === '') {
      validate = false;
      this.setState({ dateErrorMsg: 'Date is required' })
    }
    else {
      this.setState({ dateErrorMsg: '' })
    }
    if (currentCtc === '') {
      validate = false;
      this.setState({ currentCtcErrorMsg: 'Current CTC is required' })
    }
    else {
      this.setState({ currentCtcErrorMsg: '' })
    }


    if (employeeId === '') {
      validate = false;
      this.setState({ employeeNameErrorMsg: 'Employee Name is required' })
    }
    else {
      this.setState({ employeeNameErrorMsg: '' })
    }
    return validate;
  }


  handlePageChange = (pageNo) => {

    this.setState({ pageNo: pageNo, loader: true }, () => this.getAppraisal());
  }


  handleSort = e => {
    this.setState({ loader: false });
    this.setState({ sortField: e, pageNo: 1, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getAppraisal());
  }

  disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }
  handleChange = e => this.setState({
    [e.target.name]: e.target.value,
    [e.target.name + 'ErrorMsg']: '',


  });
  handlePostponeChange = (dueAppraisal, date) => this.setState({ dueAppraisal: moment(date).toDate().getTime(), dateErrorMsg: '' });

  handleDateChange = (date, dateString) => this.setState({ date: moment(date).toDate().getTime(), dateErrorMsg: '' })

  handleOpenAddAppraisalPopup = () => this.setState({ openAddAppraisalPopup: !this.state.openAddAppraisalPopup });

  handleOpenPostponeAppraisalPopup = ({ data, employeeId, appraisalId, dueAppraisal, status }) => {

    this.setState({ openPostponeAppraisalPopup: !this.state.openPostponeAppraisalPopup, dueAppraisal: dueAppraisal, appraisalId: appraisalId, status, employeeId: employeeId });
  }

  handleOpenEditAppraisalPopup = ({ employeeId, appraisalId, dueAppraisal, date, bonus, hike, month, year, empId, currentCtc, firstName, lastName, variables }) => this.setState({ openEditAppraisalPopup: !this.state.openEditAppraisalPopup, employeeId, appraisalId, dueAppraisal, date, bonus, hike, month, year, empId, currentCtc, firstName, lastName, variables });

  handleDueAppraisalPopup = ({ currentCtc, dueAppraisal }) => this.setState({ openDueAppraisalPopup: !this.state.openDueAppraisalPopup, dueAppraisal, currentCtc, });

  handleOpenDonePopup = ({ data, status, appraisalId, employeeId, dueAppraisal }) => this.setState({ openDonePopup: !this.state.openDonePopup, data, appraisalId: appraisalId, status, employeeId: employeeId, dueAppraisal });

  handleDate = e => {
    this.setState({ searchDate: e ? moment(e).format("YYYY-MM-DD") : '', pageNo: 1 }, () => {
      this.getAppraisal()
    })
  }

  closeModal = () => this.setState({
    openAddAppraisalPopup: false,
    openEditAppraisalPopup: false,
    openDueAppraisalPopup: false,
    openDonePopup: false,
    openPostponeAppraisalPopup: false,
    dueAppraisal: '',
    date: '',
    empId: '',
    hike: '',
    variables: '',
    currentCtc: '',
    employeeId: '',
    bonus: '',
    dateErrorMsg: '',
    currentCtcErrorMsg: '',
    employeeIdErrorMsg: '',
    employeeNameErrorMsg: ''

  });



  handleEditAppraisalSubmit = event => {
    event.preventDefault();
    let self = this;

    {
      let obj = {
        'appraisalId': this.state.appraisalId,
        'employeeId': this.state.employeeId,
        'date': this.state.date,
        'month': this.state.month,
        'bonus': this.state.bonus,
        'hike': this.state.hike,
        'year': this.state.year,
        'empId': this.state.empId,
        'currentCtc': this.state.currentCtc,
        'firstName': this.state.firstName,
        'lastName': this.state.lastName,
        'dueAppraisal': this.state.dueAppraisal,
        'variables': this.state.variables

      }
      editAppraisal(obj).then(res => {
        if (res.data.statusCode === 1) {
          self.getAppraisal();
          self.closeModal();
        }
        else if (res.data.statusCode === 0) {
          self.setState({ loader: false })
          self.closeModal();
        }
      })
    }
  }
  handlePostponeAppraisalSubmit = () => {

    let self = this;
    {
      let obj = {
        'appraisalId': this.state.appraisalId,
        'employeeId': this.state.employeeId,
        'dueAppraisal': this.state.dueAppraisal,
        'status': 3

      }
      postponeAppraisal(obj).then(res => {

        if (res.data.statusCode === 1) {
          // self.setState({ status: 3, status1: 3 }, () => self.getAppraisal())
          self.setState({ status: "", status1: "", openPostponeAppraisalPopup: false }, () => self.getAppraisal())
          self.closeModal();
        }
        else if (res.data.statusCode === 0) {
          self.setState({ loader: false })
          self.closeModal();
        }
      })
    }
  }

  handleFilter = (e) => {

    this.setState({ status: e, status1: e, pageNo: 1, loader: true }, () => this.getAppraisal());
  }

  handleAction = (e, data) => {

    let self = this;

    let obj = {
      'appraisalId': data.appraisalId,
      'employeeId': data.employeeId,
      'status': e

    }
    postponeAppraisal(obj).then(res => {

      if (res.data.statusCode === 1) {
        // self.setState({ status: 1, status1: 1 }, () => self.getAppraisal())
        self.setState({ status: "", status1: "" }, () => self.getAppraisal())
        self.closeModal();
      }
      else if (res.data.statusCode === 0) {
        self.setState({ loader: false })
        self.closeModal();
      }
    })
  }

  handleAction1 = () => {

    let self = this;

    let obj = {
      'appraisalId': this.state.appraisalId,
      'employeeId': this.state.employeeId,
      'dueAppraisal': this.state.dueAppraisal,
      'status': 2

    }
    postponeAppraisal(obj).then(res => {


      if (res.data.statusCode === 1) {
        // self.setState({ status: 2, status1: 2 }, () => self.getAppraisal())
        self.setState({ status: "", status1: "", openDonePopup: false, }, () => self.getAppraisal())
        self.closeModal();
      }
      else if (res.data.statusCode === 0) {
        self.setState({ loader: false })
        self.closeModal();
      }
    })
  }

  getAppraisal = () => {
    let { status, search, sortType, sortField, pageNo, count, active } = this.state

    let params = `search=${search}&status=${status}&count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&employeeStatus=${active}`;

    this.props.history.push(`?search=${search}&status=${status}&count=${count}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&active=${active}`)
    getAppraisalList(params).then(res => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          AppraisalList: resData.AppraisalList,
          totalAppraisal: resData.totalAppraisal,
          dataCount: resData.dataCount,
          approvedCount: resData.approvedCount,
          pendingCount: resData.pendingCount,
          rejectedCount: resData.rejectedCount,
          postPoneCount: resData.postPoneCount,
        }, () => this.setState({ loader: false, dueAppraisal: '' }), () => this.closeModal())
      }
      else if (res.data.statusCode === 0) {
        this.setState({ loader: false })
        this.closeModal();
      }
    })
  }


  getEmployee = () => {
    getEmployeeList().then(res => {
      let { status, resData } = ResponseFilter(res);


      if (status) {
        this.setState({
          employeeList: resData.employeesList
        })
      }
    })
  }

  componentDidMount() {

    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let status = url.searchParams.get("status");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    let active = url.searchParams.get("active");
    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      search: search ? search : "",
      sortType: sortType ? sortType : 2,
      sortField: sortField ? sortField : "created",
      active: active ? active : TAB_STATUS_CONSTANTS.STATUS.one,
      status: status ? status : "",
      status1: status ? status : "",
    }, () => {
      this.getAppraisal();
    })
    this.getEmployee()
  }



  handleSearch = e => {
    // this.setState({ search: e.target.value, pageNo: 1 }, () => this.getAppraisal());
    this.setState({ search: e.target.value });
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.setState({ loader: true })
      this.getAppraisal();
    }, 2000);
  }
  handleSelectChange = () => {
    this.setState({ search: '', pageNo: 1 }, () => this.getAppraisal());
  }

  handleSelectEmployeeChange = e => this.setState({ employeeId: e, employeeNameErrorMsg: '', })

  handleSelectEmployeeIDChange = e => this.setState({ employeeId: e, employeeIdErrorMsg: '', })
  handleAddAppraisalSubmit = event => {
    event.preventDefault();
    let self = this;
    if (this.handleValidetion()) {

      let obj = {
        'employeeId': this.state.employeeId,
        'date': this.state.date,
        'month': this.state.month,
        'bonus': this.state.bonus,
        'hike': this.state.hike,
        'year': this.state.year,
        'empId': this.state.empId,
        'currentCtc': this.state.currentCtc,
        'firstName': this.state.firstName,
        'lastName': this.state.lastName,
        'dueAppraisal': this.state.dueAppraisal,
        'variables': this.state.variables
      }
      addAppraisal(obj).then(res => {
        if (res.data.statusCode === 1) {
          self.getAppraisal();
          self.closeModal();
        }
        else if (res.data.statusCode === 0) {
          self.setState({ loader: false, dueAppraisal: '' })
          self.closeModal();
        }
      })
    }
  }


  handleEmployeeSearch = e => console.log("Search : ", e)

  handleEditSubmit = (e) => {
    this.setState({ active: e.target.checked ? TAB_STATUS_CONSTANTS.STATUS.one : TAB_STATUS_CONSTANTS.STATUS.zero ,loader:true}, () => this.getAppraisal())
  };
  render() {
    let { status1, search, pendingCount, approvedCount, postPoneCount, dataCount, dateErrorMsg, currentCtcErrorMsg, sortField, sortType, employeeIdErrorMsg, status, employeeNameErrorMsg, currentCtc, bonus, hike, date, variables, dueAppraisal, employeeId, employeeList, openAddAppraisalPopup, openEditAppraisalPopup, openPostponeAppraisalPopup, openDueAppraisalPopup, openDonePopup, AppraisalList, loader, totalAppraisal, pageNo, count, active } = this.state;

    return (
      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        <h3>APPRAISALS</h3>
        <div className=''>
          <div className="row d-flex justify-content-end mb-4">
            <div className="col-md-6 search_device_bar2">
              <span className="btn btn-primary add_empBtn" onClick={this.handleOpenAddAppraisalPopup}>Add Appraisal</span>
              <input className="form-control mr-lg-2 cm_box"
                maxLength={25}
                type="search"
                placeholder="Search by name"
                aria-label="Search"
                value={search}
                onChange={this.handleSearch}
              // onKeyDown={(e) => { if (e.key === 'Enter') this.getAppraisal() }}
              />
              {search ? <span className="cm_clear1" onClick={this.handleSelectChange}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </span>
                : <span className="cm_clear1">
                  <i className="fa fa-search" aria-hidden="true" ></i></span>}
            </div>
          </div>
          <div className="text-left cm_ticket_btn cm_leave_btn mt-4">
            <button className={`btn btn-primary ${status1 == '' ? "active" : ""}`} onClick={() => this.handleFilter('')} ><span className="tabtext">All {totalAppraisal}</span></button>
            <button className={`btn btn-primary ${status1 == 2 ? "active" : ""}`} onClick={() => this.handleFilter(2)} ><span className="tabtext">Done {approvedCount}</span></button>
            <button className={`btn btn-primary ${status1 == 3 ? "active" : ""}`} onClick={() => this.handleFilter(3)}><span className="tabtext">Postpone {postPoneCount}</span></button>
            <button className={`btn btn-primary ${status1 == 1 ? "active" : ""}`} onClick={() => this.handleFilter(1)}><span className="tabtext">Upcoming {pendingCount}</span></button></div>

        </div>
        <div className="d-flex justify-content-end mb-3">
          <span htmlFor="">Active Employees Resume</span>
          <div className="cm_swich_wrapper mt-1 ml-2">
            <input
              type="checkbox"
              value={active}
              checked={active == TAB_STATUS_CONSTANTS.STATUS.one ? true : false}
              onChange={(e) =>
                this.handleEditSubmit(e)
              }
            />
            <span className="cm_swich"></span>

          </div>
        </div>
        <div>
          {AppraisalList.length > 0 ?
            <div>

              <div className="table-responsive ticket_table">
                <table className="table table-bordered">
                  <thead className="white bg_blue">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Employee ID</th>
                      <th>Name<i
                        className={sortType === 2 && sortField === "firstName" ? "fa fa-sort-alpha-desc ml-2" : "fa fa-sort-alpha-asc ml-2"}

                        onClick={() => this.handleSort('firstName')}
                      ></i></th>
                      <th scope="col">Department</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Joined</th>
                      <th scope="col">{status === '2' ? 'Done' : (status === '1' ? 'Upcoming' : (status === '3' ? 'Due Appraisal' : 'All Appraisal'))}</th>
                      <th scope="col" style={{ width: 270 }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !_.isEmpty(AppraisalList) ?
                        AppraisalList.map((data, i) => (
                          <tr>

                            <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                            <td><span className="cm_col">{data.empId}</span></td>
                            <td><span className="cm_col"><Link className="blue" to={`/admin-appraisal/${data.employeeId}`}>{data.firstName} {data.lastName}</Link></span></td>
                            <td><span className="cm_col_desig">
                              {
                                data.departmentName.map((department, i) => (


                                  <span className="cm_col_dept">
                                    {department.departmentName ? `${department.departmentName}` : ''}

                                  </span>



                                ))}

                            </span></td>
                            <td ><span className="cm_col_desig">
                              {
                                data.designationName.map((department, i) => (


                                  <span className="cm_col_dept">
                                    {department.designationName ? `${department.designationName}` : ''}

                                  </span>



                                ))}

                            </span></td>

                            <td><Link className="blue" to={`/admin-appraisal/${data.employeeId}`}>{data.dateOfJoining ? moment(data.dateOfJoining).format('DD/MM/YYYY') : '_'}</Link></td>
                            <td><time className={`remb_select cm ml-3  ${status === '2' ? 'green' : (status === '1' ? 'skyblue' : (status === '3' ? ' red' : ''))}`} value={this.statusType(data.status)} onClick={() => this.handleDueAppraisalPopup(data)}>{data.dueAppraisal ? moment(data.dueAppraisal).format('DD/MM/YYYY') : '_'}</time></td>


                            {
                              data.status === 1 ?
                                <td style={{ width: 270 }}>
                                  <div className="cm_ongoing">
                                    <span onClick={() => this.handleOpenEditAppraisalPopup(data)}><i className="fa fa-pencil mr-4 ml-2" aria-hidden="true" title="Edit appraisal"></i></span>

                                    <Select value={this.statusType(data.status)} style={{ width: 120 }}

                                    >
                                      <Option value="1" id="1" onClick={(e) => this.handleAction(1, data)}>Upcoming</Option>
                                      <Option value="2" id="2" onClick={(e) => this.handleOpenDonePopup(data, data.employeeId, data.appraisalId, data.status)}>Done</Option>
                                      <Option value="3" id="3" onClick={(e) => this.handleOpenPostponeAppraisalPopup(data, data.employeeId, data.appraisalId, data.dueAppraisal, data.status)}>Postpone</Option>

                                    </Select>
                                  </div>
                                </td>
                                : ''
                            }

                            {
                              data.status === 2 ?
                                <td style={{ width: 270 }}>
                                  <div className="cm_approve">
                                    <span onClick={() => this.handleOpenEditAppraisalPopup(data)}><i className="fa fa-pencil mr-4 ml-2" aria-hidden="true" title="Edit appraisal"></i></span>

                                    <Select value={this.statusType(data.status)} style={{ width: 120 }}

                                    >
                                      <Option value="1" id="1" onClick={(e) => this.handleAction(1, data)}>Upcoming</Option>
                                      <Option value="2" id="2" onClick={(e) => this.handleOpenDonePopup(data, data.employeeId, data.appraisalId, data.status)}>Done</Option>
                                      <Option value="3" id="3" onClick={(e) => this.handleOpenPostponeAppraisalPopup(data, data.employeeId, data.appraisalId, data.dueAppraisal, data.status)}>Postpone</Option>

                                    </Select>
                                  </div>
                                </td>
                                : ''
                            }

                            {
                              data.status === 3 ?
                                <td style={{ width: 270 }}>
                                  <div className="cm_reject">
                                    <span onClick={() => this.handleOpenEditAppraisalPopup(data)}><i className="fa fa-pencil mr-4 ml-2" aria-hidden="true" title="Edit appraisal"></i></span>

                                    <Select value={this.statusType(data.status)} style={{ width: 120 }}

                                    >
                                      <Option value="1" id="1" onClick={(e) => this.handleAction(1, data)}>Upcoming</Option>
                                      <Option value="2" id="2" onClick={(e) => this.handleOpenDonePopup(data, data.employeeId, data.appraisalId, data.status)}>Done</Option>
                                      <Option value="3" id="3" onClick={(e) => this.handleOpenPostponeAppraisalPopup(data, data.employeeId, data.appraisalId, data.dueAppraisal, data.status)}>Postpone</Option>

                                    </Select>
                                  </div>
                                </td>
                                : ''
                            }

                          </tr>
                        )) : ''
                    }
                  </tbody>
                </table>
                {AppraisalList.length ? '' : <h3 className="text-center">No data available</h3>}
              </div>


              {dataCount > 10 ? <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              /> : ''}
            </div>
            : ''}
        </div>
        {
          loader ? null :
            AppraisalList.length > 0 ? '' : <h3 className="text-center mt-5">No data available</h3>

        }
        <AntModal
          title=""
          footer={false}
          centered
          className="sm_modal1"
          visible={openEditAppraisalPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>

            <div className="edit_appraisal" >
              <h3 className="">EDIT APPRAISAL</h3>

              <div className="row">
                <div className="col-6 form-group">
                  <label className="cm_bold">Due Appraisal</label>
                  <DatePicker
                    allowClear={false}
                    value={dueAppraisal ? moment(dueAppraisal) : ''}
                    defaultValue={moment(dueAppraisal) ? moment(dueAppraisal) : 'Select date'}
                    className=" date-border"
                    onChange={this.handlePostponeChange}

                  />
                </div>
                <div className="col-6 form-group">
                  <label className="cm_bold">Bonus</label>
                  <input
                    placeholder="Bonus"
                    type="number"
                    step="any"
                    className="form-control cm_box"
                    name="bonus"
                    value={bonus}
                    onChange={this.handleChange}
                  />
                </div>

              </div>
              <div className="row">

                <div className="col-6 form-group">
                  <label className="cm_bold">Hike</label>
                  <input
                    placeholder="Hike"
                    type="number"
                    step="any"
                    className="form-control cm_box"
                    name="hike"
                    value={hike}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-6 form-group">
                  <label className="cm_bold">Current CTC</label>
                  <input
                    placeholder="Current CTC"
                    type="number"
                    step="any"
                    className="form-control cm_box"
                    name="currentCtc"
                    value={currentCtc}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">

                <div className="col-6 form-group">
                  <label className="cm_bold">Variables</label>
                  <input
                    placeholder="Variables"
                    type="number"
                    className="form-control cm_box"
                    name="variables"
                    value={variables}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="text-center">
                <button type="submit" onClick={this.handleEditAppraisalSubmit} className="btn btn-primary mt-4 mr-4 cm_btn3">Save</button>
                <button type="submit" onClick={this.closeModal} className="btn btn-primary mt-4 cm_btn2">Cancel</button>
              </div>
            </div>
          </Modal.Body>
        </AntModal>
        <AntModal
          title=""
          centered
          className="sm_modal1"
          footer={false}
          visible={openAddAppraisalPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <div className="edit_appraisal">
              <h3 className="">ADD APPRAISAL</h3>
              <div className="row">
                <div className={`form-group col-6 ${employeeNameErrorMsg ? 'input_error' : ''}`}>
                  <label className="cm_bold">Employee Name</label>

                  <Select
                    showSearch
                    name="employeeId"
                    value={employeeId ? employeeId : ' Select Employee'}
                    //  defaultValue="Select Name" 
                    onChange={this.handleSelectEmployeeChange}
                    onSearch={this.handleEmployeeSearch}
                    optionFilterProp="key"
                    filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                  >
                    {
                      !_.isEmpty(employeeList) ?

                        employeeList.sort(function (a, b) {
                          if (a.firstName < b.firstName) { return -1; }
                          if (a.firstName > b.firstName) { return 1; }
                          return 0;
                        }).map(employee => (employee.status === 1 &&
                          <Option key={`${employee.firstName} ${employee.lastName}`} value={employee.employeeId}>
                            <span>{employee.firstName} {employee.lastName}({employee.empId})</span>
                          </Option>
                        )) : ''
                    }
                  </Select>
                  {employeeNameErrorMsg ? <span className="error_msg">{employeeNameErrorMsg}</span> : null}
                </div>
                <div className={`form-group col-6 ${employeeIdErrorMsg ? 'input_error' : ''}`}>
                  <label className="cm_bold">Employee ID</label>
                  <Select name="employeeId" value={employeeId ? employeeId : ' Select Employee ID'} defaultValue="Select Name" onChange={this.handleSelectEmployeeIDChange}>

                    {
                      !_.isEmpty(employeeList) ?

                        employeeList.map(employee => (employee.status === 1 &&
                          <Option key={employee.employeeId} value={employee.employeeId}><span>{employee.empId}</span>
                          </Option>
                        )) : ''
                    }
                  </Select>
                  {employeeIdErrorMsg ? <span className="error_msg">{employeeIdErrorMsg}</span> : null}
                </div>
              </div><div className="row">

                <div className="col-6 form-group">
                  <label className="cm_bold">Bonus</label>
                  <input
                    placeholder="Bonus"
                    type="number"
                    step="any"
                    className="form-control cm_box"
                    name="bonus"
                    value={bonus}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-6 form-group">
                  <label className="cm_bold">Hike</label>
                  <input
                    placeholder="Hike"
                    type="number"
                    step="any"
                    className="form-control cm_box"
                    name="hike"
                    value={hike}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className={`form-group col-6 ${currentCtcErrorMsg ? 'input_error' : ''}`}>
                  <label className="cm_bold">Current CTC</label>
                  <input
                    placeholder="Current CTC"
                    type="number"
                    step="any"
                    className="form-control cm_box"
                    name="currentCtc"
                    value={currentCtc}
                    onChange={this.handleChange}
                  />
                  {currentCtcErrorMsg ? <span className="error_msg">{currentCtcErrorMsg}</span> : null}
                </div>
                <div className="col-6 form-group">
                  <label className="cm_bold">Variables</label>
                  <input
                    placeholder="Variables"
                    type="number"
                    step="any"
                    className="form-control cm_box"
                    name="variables"
                    value={variables}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                  <label className="cm_bold">Date</label>
                  <DatePicker
                    allowClear={false}
                    value={dueAppraisal ? moment(dueAppraisal) : ''}
                    className=" date-border"
                    onChange={this.handlePostponeChange}
                  />
                  {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                </div>
              </div>
              <div className="text-center">
                <button type="submit" onClick={this.handleAddAppraisalSubmit} className="btn btn-primary mt-4 mr-4 cm_btn3">Save</button>
                <button type="button" onClick={this.closeModal} className="btn btn-primary mt-4 cm_btn2">Cancel</button>
              </div>
            </div>
          </Modal.Body>
        </AntModal>
        <AntModal
          className="appraisal_edit"
          title=""
          footer={false}
          visible={openPostponeAppraisalPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <div className="edit_appraisal" onClick={() => this.handlePostponeAppraisalSubmit()}>
              <h3 className="mb-4">POSTPONE APPRAISAL</h3>
              <div>
                <label className="cm_bold">Date</label>
                <DatePicker
                  allowClear={false}
                  value={dueAppraisal ? moment(dueAppraisal) : ''}
                  className=" date-border"
                  onChange={this.handlePostponeChange}

                />
              </div>
              <div className="text-center mt-5">
                <span className="btn btn-primary  mr-2 btn-sm cm_btn3" >Confirm</span>

                <button
                  onClick={this.closeModal}
                  className="btn btn-primary  cm_btn2 btn-sm"
                >
                  Cancel</button>
              </div>

            </div>
          </Modal.Body>
        </AntModal>




        <AntModal

          title=""
          footer={false}
          visible={openDonePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <div className="edit_appraisal">
              <h3 className="mb-4">DONE APPRAISAL</h3>

              <p className="mb-4 cm_bold cnfrm_msg">Are you sure, you want to done this Appraisal?</p>
              <div>
                <label className="cm_bold">Date</label>
                <DatePicker
                  allowClear={false}
                  disabledDate={this.disabledDate}
                  value={dueAppraisal ? moment(dueAppraisal) : ''}
                  className=" date-border"
                  onChange={this.handlePostponeChange}

                />
              </div>
              <div className="text-center mt-5">
                <span className="btn btn-primary  mr-4 cm_btn3 btn-sm" onClick={(appraisalId, status) => this.handleAction1(appraisalId, status)}>Done</span>

                <button
                  onClick={this.closeModal}
                  className="btn btn-primary  cm_btn2 btn-sm"
                >
                  Cancel</button>
              </div>

            </div>
          </Modal.Body>
        </AntModal>
      </div >

    );
  }
}
export default Appraisal;



import React, { useEffect, useReducer } from "react";
import { connect, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Loader, MenuIcon } from "../svg";
import Modal from "react-bootstrap/Modal";
import {
  getAdminProfile,
  logoutEmployee,
} from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import { Link } from "react-router-dom";
import { getEmpProfileByAdmin, userLogout } from "../../redux/actions";
import local from "../../config/settings/local";
import { reducer, type } from "../helper/Constant";

const initialState = {
  loader: false,
  openLogoutPopup: false,
  profile: "",
};

const Header = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { profile, openLogoutPopup, loader } = state;
  const dispatchToAction = useDispatch();

  useEffect(() => {
      dispatchToAction(
        getEmpProfileByAdmin({
          useDispatch: dispatch,
        })
      );
  }, []);

  const handleOpenLogoutPopup = () => {
    dispatch({
      type: type,
      payload: {
        openLogoutPopup: true,
      },
    });
  };

  const handleLogout = async() => {
    closeModal()
    logoutEmployee().then((res) => {
      dispatch({ type: type, payload: { loader: true } });
      localStorage.removeItem("accessToken", res.data.acessToken);
      localStorage.removeItem("isAdmin", res.data.isAdmin);
      localStorage.removeItem("teamHead");
      localStorage.removeItem("teamId");
      localStorage.removeItem("employeeId");
      localStorage.removeItem("isProjectManager");
      localStorage.removeItem("salaryToken");
      localStorage.removeItem("salaryAccessEmail");
      localStorage.removeItem("secretKey");
      window.location.href = "/login";
    })
    .catch((error)=>{
      console.log("Error: ", error)
    })
    dispatch({ type: type, payload: { loader: false } });
  };

  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        openLogoutPopup: false,
      },
    });
  };

  const handleOpenAdminProfile = () => {
    window.location.href = "/profile";
  };

  const handleOpenEmployeeProfile = () => {
    window.location.href = "/employee-profile";
  };

  const toggleMenuClass = () => {
    document.body.classList.toggle("menu_open");
  };

  return (
    <div>
      <header>
        <div className="mobile_trigger">
          <MenuIcon />
          <span onClick={toggleMenuClass} />
        </div>
        {loader && <Loader />}
        <Dropdown className="user_profile">
          <Dropdown.Toggle className="profile_user">
            <img
              className="user_img"
              src={
                profile.profileImage
                  ? profile.profileImage
                  : require("../../asset/imgs/user-img.png")
              }
              alt=""
            />
            <span>
              {profile.firstName ? profile.firstName : ""}
              <div className="cm_drop"></div>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {localStorage.getItem("isAdmin") == 1 ? (
              <Dropdown.Item onClick={handleOpenAdminProfile}>
                Profile
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={handleOpenEmployeeProfile}>
                Profile
              </Dropdown.Item>
            )}
            {localStorage.getItem("isAdmin") == 1 ? (
              <Dropdown.Item>
                <Link to="/change-password">Change Password</Link>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item>
                <Link to="/change-password-emp">Change Password</Link>
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={handleOpenLogoutPopup}>
              <span>Logout</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </header>
      <Modal
        size="md"
        show={openLogoutPopup}
        onHide={closeModal}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Body className="text-center confirmation">
          <p className="mb-4 cm_bold cnfrm_msg">
            Are you sure, you want to logout?
          </p>
          <div className="submit_button_margin mt-4 edit_appraisal">
            <button
              onClick={handleLogout}
              className="btn btn-primary btn-sm cm_btn3"
            >
              Yes
            </button>
            <button
              type="submit"
              onClick={closeModal}
              className="btn btn-primary cm_btn2 btn-sm"
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;


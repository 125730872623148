import React from 'react';

import { Link } from 'react-router-dom';


class resetSuccessPassword extends React.Component {
    render() {
        return (
            <div className="login_wrapper d-flex align-items-center">
                <div className="container cm_container bg_white d-flex align-items-center">
                    <div className="page_content text-center">
                        <h4 className=" cm_heading mb-5">
                            Your password has been reset successfully!</h4>


                        <p>You can now use your new password to log in to your account.</p>

                        <div className="cm_check"></div>
                        <Link to="/login" className=" cm_btn btn btn-outline-primary">GO BACK TO LOGIN</Link>
                    </div>
                </div></div>
        );
    }
}
export default resetSuccessPassword;
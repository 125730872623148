import { Select } from 'antd';
import React, { useEffect, useRef, useState, } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import { sendWishesDriveTemplate } from '../../config/services/employeeServices';
import { Loader } from '../svg';
import { getEmployeeList } from '../../config/services/employeeServices';
import ResponseFilter from '../../ResponseFilter';
import _, { result } from 'lodash';
const { Option } = Select;
const Templates = () => {
    const refImg = useRef('')
    const refCrop = useRef('')
    const refClick = useRef('')
    const refEmpName = useRef('')
    const refYear = useRef('')
    const refEmployeeName = useRef('')
    const refDesignation = useRef('')
    const refDescription = useRef('')
    const refDetail = useRef('')
    const [employeeName, setEmployeeName] = useState([])
    const [empName, setEmpName] = useState([])
    const [click, setClick] = useState(-1)
    const [designationArray, setDesignationArray] = useState([])
    const [descriptionArray, setDescriptionArray] = useState([])
    const [YearArray, setYearArray] = useState([])
    const [detailArray, setDetailArray] = useState([])
    const [loader, setLoader] = useState(true);
    const history = useHistory();
    const [employeeList, setEmployeeList] = useState([]);
    const [listData, setListData] = useState([]);
    const [cropArray, setCropArray] = useState([])
    const [formDataArray, setFormDataArray] = useState([]);
    const [templateSelect, setTemplateSelect] = useState([])
    const [addEmpError, setAddEmpError] = useState([])
    const [empId, setEmpId] = useState([])
    const [resmessage, setResmessage] = useState(false)
    const [resMsgError, setResMsgError] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [name, setName] = useState('')
    const [sumbit, setSumbit] = useState(false)
    const [id, setId] = useState("")
    const [file, setFile] = useState(null)
    const [subject, setSubject] = useState("")
    const [initial, setInitial] = useState(false)
    const [erroredit, setErrorEdit] = useState({});
    const [resMsg, setResMsg] = useState(false)
    const [resMssg, setResMssg] = useState(false)
    const [responseMsg, setResponseMsg] = useState(false)
    const [NameErrorMessage, setNameErrorMessage] = useState("")
    const [fileErrorMessage, setFileErrorMessage] = useState("")
    const [subjectErrorMessage, setsubjectErrorMessage] = useState("")
    const [remove, setRemove] = useState(false)

    const [formData, setFormData] = useState({
        template: '',
        empId: '',
        empName: '',
        file: '',
        backendFile: "",
        designation: '',
        description: '',
        Year: ''
    });
    const [error, setError] = useState({
        templateError: '',
        empNameError: '',
        fileError: '',
        designationError: '',
        subjectError: '',
        descripationError: '',
        YearError: ''
    });

    const [crop, setCrop] = useState({
        aspect: 1 / 1,
        unit: '%',
        width: 50,
    });
    const [srcImg, setSrcImg] = useState([]);
    const [image, setImage] = useState(null);
    const [profile, setProfile] = useState([]);
    const [errorCheck, setErrorCheck] = useState([]);
    const [errorCheck1, setErrorCheck1] = useState([]);
    const [errorCheck2, setErrorCheck2] = useState([]);
    const [errorCheck3, setErrorCheck3] = useState([]);
    const [errorCheck4, setErrorCheck4] = useState([]);
    const[emperror , setEmperror] = useState('')

    useEffect(() => {
        let params = `status=1`;
        getEmployeeList(params).then((res) => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                setLoader(false);
                setEmployeeList(resData.employeesList)
            }
        })
        setResMsg(localStorage.getItem("resMsg"))
        setResMssg(localStorage.getItem('resMssg'))
        setResponseMsg(localStorage.getItem("responseMsg"))

    }, []);
    const handlemailopen = () => {
        setInitial(true)
    }
    const handleSubjectValidation = () => {
        let validate = true;
        if (subject === "") {
            validate = false;
        }
        else {
        }
        return validate;
    }
    

    const handlefileValidation = () => {
        let validate = true;
        if (file === "") {
            validate = false;
        }
        else if (!file?.name?.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
            validate = false;

        }
        else {

        }
        return validate
    }
    const handleTemplateChange = (e) => {

        let { template } = e;
        if (template === '') {
            setTemplateSelect('* Please Select A Template')
        }
        else if (template !== '' & click === -1) {
            setAddEmpError("*Employee not selected")
        }
        else if (template === '1') {
            setTemplateSelect('')
            if (formDataArray.length === 0) {
                if (employeeName[click] === undefined ) {
                    let input_error = [...errorCheck]
                    refClick.current = "* Name field are required";
                    input_error[click] = refClick.current;
                    setErrorCheck1(input_error)
                }
               else if (srcImg.length !== 0 && srcImg[click] !== '' && cropArray.length === 0) {
                    let cropErr = [...errorCheck]
                    refClick.current = "*Please select CROP";
                    cropErr[click] = refClick.current;
                    setErrorCheck(cropErr)
                }
                
              
                else if (designationArray[click] === undefined) {
                    let input_error = [...errorCheck]
                    refClick.current = "*Desgination field  are required";
                    input_error[click] = refClick.current;
                    setErrorCheck2(input_error)
                }
                else if (descriptionArray[click] === undefined ) {
                    let input_error2 = [...errorCheck]
                    refClick.current = "*  Description field  are required";
                    input_error2[click] = refClick.current;
                    setErrorCheck3(input_error2)
                }
                else if (YearArray[click] === undefined) {
                    let input_error_year = [...errorCheck]
                    refClick.current = "*Year field  are required";
                    input_error_year[click] = refClick.current;
                    setErrorCheck4(input_error_year)
                }

                else {
                    let input_error = [...errorCheck]
                    refClick.current = " * image field are required";
                    input_error[click] = refClick.current;
                    setErrorCheck(input_error)
                }
            }
            else {
                if (formDataArray[click] !== undefined) {
                    history.push({
                        pathname: '/templates/anniversary-template',
                        state: formDataArray,
                    })
                    let templateError = [...errorCheck]
                    refClick.current = "";
                    templateError[click] = refClick.current;
                    setErrorCheck(templateError)
                }
               else if (employeeName[click] === undefined ) {
                    let input_error = [...errorCheck]
                    refClick.current = "* Name field are required";
                    input_error[click] = refClick.current;
                    setErrorCheck1(input_error)
                }
                else if (YearArray[click] === undefined) {
                    let input_error_year = [...errorCheck]
                    refClick.current = "*Year fields are required";
                    input_error_year[click] = refClick.current;
                    setErrorCheck4(input_error_year)
                }
                else if (descriptionArray[click] === undefined ) {
                    let input_error = [...errorCheck]
                    refClick.current = "* Description fields are required";
                    input_error[click] = refClick.current;
                    setErrorCheck3(input_error)
                }
                else if (designationArray[click] === undefined) {
                    let input_error = [...errorCheck]
                    refClick.current = "*  Designation field  are required";
                    input_error[click] = refClick.current;
                    setErrorCheck2(input_error)
                }
               
                

                else if (srcImg[click] !== undefined && cropArray[click] === undefined) {
                    let cropErr = [...errorCheck]
                    refClick.current = "*Please select CROP";
                    cropErr[click] = refClick.current;
                    setErrorCheck(cropErr)
                }
                else {
                    let input_error = [...errorCheck]
                    refClick.current = "* image field are required";
                    input_error[click] = refClick.current;
                    setErrorCheck(input_error)
                }
            }
        }
        else if (template === '2') {
            setTemplateSelect('')

            if (formDataArray.length === 0) {
             if (employeeName[click] === undefined ) {
                    let input_error = [...errorCheck]
                    refClick.current = "* Name  fields are required";
                    input_error[click] = refClick.current;
                    setErrorCheck1(input_error)
                }
              else  if (srcImg.length !== 0 && srcImg[click] !== '' && cropArray.length === 0) {
                    let cropErr = [...errorCheck]
                    refClick.current = "*Please select CROP";
                    cropErr[click] = refClick.current;
                    setErrorCheck(cropErr)
                } 
             
                else {
                    let cropErr = [...errorCheck]
                    refClick.current = "*image fields are required";
                    cropErr[click] = refClick.current;
                    setErrorCheck(cropErr)
                }
             


            }
            else {
                if (formDataArray[click] !== undefined) {
                    history.push({
                        pathname: '/templates/birthday-template',
                        state: formDataArray,
                    })
                    let templateError = [...errorCheck]
                    refClick.current = "";
                    templateError[click] = refClick.current;
                    setErrorCheck(templateError)
                }
               else if (employeeName[click] === undefined ) {
                    let input_error = [...errorCheck]
                    refClick.current = "* name  fields are required";
                    input_error[click] = refClick.current;
                    setErrorCheck1(input_error)
                }
                else if (srcImg[click] !== undefined && cropArray[click] === undefined) {
                    let cropErr = [...errorCheck]
                    refClick.current = "*Please select CROP**";
                    cropErr[click] = refClick.current;
                    setErrorCheck(cropErr)
                }
                else {
                    let input_error = [...errorCheck]
                    refClick.current = "* All fields are required";
                    input_error[click] = refClick.current;
                    setErrorCheck(input_error)
                }

            }
        }
        else if (template === '3') {
            setTemplateSelect('')
            if (formDataArray.length === 0) {
                if (employeeName[click] === undefined ) {
                    let input_error = [...errorCheck]
                    refClick.current = "* Name field are required";
                    input_error[click] = refClick.current;
                    setErrorCheck1(input_error)
                }
              else   if (srcImg.length !== 0 && srcImg[click] !== '' && cropArray.length === 0) {
                    let cropErr = [...errorCheck]
                    refClick.current = "*Please select CROP";
                    cropErr[click] = refClick.current;
                    setErrorCheck(cropErr)
                }  
                else if (designationArray[click] === undefined) {
                    let input_error = [...errorCheck]
                    refClick.current = "*Desgination field  are required";
                    input_error[click] = refClick.current;
                    setErrorCheck2(input_error)
                }
                else if (descriptionArray[click] === undefined ) {
                    let input_error2 = [...errorCheck]
                    refClick.current = "*  Description field  are required";
                    input_error2[click] = refClick.current;
                    setErrorCheck3(input_error2)
                }
                else {
                    let input_error = [...errorCheck]
                    refClick.current = "*image fields are required";
                    input_error[click] = refClick.current;
                    setErrorCheck(input_error)
                }
            }
            else {
                if (formDataArray[click] !== undefined) {
                    history.push({
                        pathname: '/templates/welcome-template',
                        state: formDataArray,
                    })
                    let templateError = [...errorCheck]
                    refClick.current = "";
                    templateError[click] = refClick.current;
                    setErrorCheck(templateError)
                }
               else if (employeeName[click] === undefined ) {
                    let input_error = [...errorCheck]
                    refClick.current = "* Name field are required";
                    input_error[click] = refClick.current;
                    setErrorCheck1(input_error)
                }
                else if (srcImg[click] !== undefined && cropArray[click] === undefined) {
                    let cropErr = [...errorCheck]
                    refClick.current = "*Please select CROP";
                    cropErr[click] = refClick.current;
                    setErrorCheck(cropErr)
                }
                else if (designationArray[click] === undefined) {
                    let input_error = [...errorCheck]
                    refClick.current = "*Desgination field  are required";
                    input_error[click] = refClick.current;
                    setErrorCheck2(input_error)
                }
                else if (descriptionArray[click] === undefined ) {
                    let input_error2 = [...errorCheck]
                    refClick.current = "*  Description field  are required";
                    input_error2[click] = refClick.current;
                    setErrorCheck3(input_error2)
                }


                else {
                    let input_error = [...errorCheck]
                    refClick.current = "* image field are required";
                    input_error[click] = refClick.current;
                    setErrorCheck(input_error)
                }
            }
        }
        else {

        }
    }
    const handlecancel = () => {
        setInitial(false)
        setFileErrorMessage('')
        setName('')
        setFile('')
        setSubject('')
        setsubjectErrorMessage("")
        setId("")
    }
    const changeTemplate = (e) => {
        setTemplateSelect('')
        setProfile('')
        setSrcImg('')
        setErrorCheck('')
        setErrorCheck1('')
        setErrorCheck2('')
        setErrorCheck3('')
        setErrorCheck4('')
        setDescriptionArray([])
        setDesignationArray([])
        setFormDataArray([])
        setEmployeeName([])
        setFormData({ ...formData ,  template: e });

    }
    const changeEmpName = e => {
        setEmpId([...empId, e])
        const name = employeeList.filter((employee) => employee.employeeId === e)
        setFormData({
            ...formData,
            empId: e,
            empName: name[0].firstName,
            empLastName: name[0].lastName

        });
        let empName = [...employeeName]
        refEmpName.current = name[0].employeeId
        empName[click] = refEmpName.current
        setEmployeeName(empName)
        let empErr = [...errorCheck]
        refClick.current = "";
        empErr[click] = refClick.current;
        setErrorCheck1(empErr)
    }
    const changeYear = e => {
        setFormData({ ...formData, Year: e })
        let Year = [...YearArray]
        let YearError = [...errorCheck]
        refYear.current = e
        Year[click] = refYear.current
        setYearArray(Year)
        refClick.current = "";
        YearError[click] = refClick.current;
        setErrorCheck4(YearError)

    }
    const changeDesignation = e => {
        setFormData({ ...formData, designation: e.target.value.trim() })
        let designation = [...designationArray]
        let designationError = [...errorCheck]
        refDesignation.current = e.target.value
        designation[click] = refDesignation.current
        setDesignationArray(designation)
        refClick.current = "";
        designationError[click] = refClick.current;
        setErrorCheck2(designationError)

    }
    const changeDescription = e => {
        setFormData({ ...formData, description: e.target.value })
        let description = [...descriptionArray]
        let descripationError = [...errorCheck]
        refDescription.current = e.target.value
        description[click] = refDescription.current
        setDescriptionArray(description)

        refClick.current = "";
        descripationError[click] = refClick.current;
        setErrorCheck3(descripationError)
    }

    const changeFile = async (e, index) => {
        let image = [...srcImg]
        let image1 = [...srcImg]
        let imageErr = [...errorCheck]
        if (e.target.files.length !== 0 && e.target.files[0]?.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
            refImg.current = URL.createObjectURL(e.target.files[0])
            image[index] = refImg.current;
            setSrcImg(image);
            refClick.current = "";
            imageErr[index] = refClick.current;
            setErrorCheck(imageErr)

        } else {
            refImg.current = ''
            image[index] = refImg.current
            setSrcImg(image);
            refClick.current = "Please Provide Valid Image";
            imageErr[index] = refClick.current;
            setErrorCheck(imageErr)
        }
    }
    const getCroppedImg = () => {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
            const base64Image = canvas.toDataURL("image/jpeg", 1);
            setFormData({ ...formData, backendFile: base64Image });
            setFormData({ ...formData, file: base64Image });
            refCrop.current = base64Image
            let cropArr = [...cropArray];
            cropArr[click] = refCrop.current;
            setCropArray(cropArr)


            let profileErr = [...errorCheck];
            refClick.current = "";
            profileErr[click] = refClick.current;
            setErrorCheck(profileErr)

        } catch (e) {

        }

        let profileImg = [...profile]
        profileImg[click] = refCrop.current
        setProfile(profileImg)
        formDataArray.push({ ...formData, file: refCrop.current })

    };
    
    const changedriveFile = (e, index) => {
        setFileErrorMessage('')
        setFile(e.target.files[0])
    }

    const changesubject = (e) => {
        setsubjectErrorMessage('')
        setSubject(e.target.value)
    }
    const changeName = (e) => {
        setName(e.target.value)
    }
    const changeId = (e) => {
        setNameErrorMessage("")
        const name1 = employeeList.filter((employee) => employee.employeeId === e)
        setId(name1[0]?.employeeId)

    }
    const handledriveSumbit = () => {
        handleUploadButtonClick();
        if (handleSubjectValidation(subject) == "") {
            setsubjectErrorMessage("Enter  the subject")
        }
        else {
            setsubjectErrorMessage('')
        }
        if (handlefileValidation(file) === "") {
            setFileErrorMessage("Please Upload image")
        }
        else if (!file?.name?.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
            setFileErrorMessage("please Enter Valid File")

        }

        else {
            setFileErrorMessage("")

        }

    };
    const handleUploadButtonClick = async (e) => {

        if (
            handleSubjectValidation(subject)
            &&
            handlefileValidation(file)
        ) {

            let promises = [];
            setLoader(true)
            let params = new FormData();
            params.append("image", file)
            params.append("empName", name.charAt(0).toUpperCase() + name.slice(1))
            params.append("employeeId", name ? "" : id)
            params.append("subject", subject)
            promises.push(await sendWishesDriveTemplate(params))

            return Promise.all(promises)
                .then(responses => {
                    let data = [];
                    for (let i = 0; i < responses.length; i++) {
                        data.push(responses[i].data);
                    }
                    return data;
                })
                .then(data => {
                    if (data[0].statusCode === 1) {
                        window.location.href = '/templates';
                        localStorage.setItem("resMsg", true)
                    }
                    else {
                        setResMsgError(true)
                        setResponseMessage(data?.error?.responseMessage)
                    }


                })
                .catch(error => {
                }).finally(() => console.log(false),
                );


        }
    }
    let handleAdd = (e) => {
        var access = document.getElementById("lower_btn");
        window.scrollTo(access.offsetLeft, access.offsetTop + 200);
        setAddEmpError('')

        if (formData.template === '2') {

            let name1 = employeeName
            let employeeeName = [...employeeName]
            refEmployeeName.current = name1
            employeeeName[click] = refEmpName.current
            setEmpName(employeeeName)

            let result = employeeList.filter(item => !employeeName.includes(item.employeeId
            ))
            setEmployeeList(result)

            if (listData.length === 0) {
                setClick(click + 1)
                let data = [...listData];
                data.push(formData);
                setListData(data)
            }
           else  if (employeeName[click] === undefined ) {
                let input_error = [...errorCheck]
                refClick.current = "* Name fields are required";
                input_error[click] = refClick.current;
                setErrorCheck1(input_error)
            }
            else if (profile[click] !== undefined && employeeName[click] !== undefined) {
                setClick(click + 1)
                let data = [...listData];
                data.push(formData);
                setListData(data)
                let dataErr = [...errorCheck]
                refClick.current = "";
                dataErr[click] = refClick.current;
                setErrorCheck(dataErr)
            }
            else if (srcImg.length !== 0 && srcImg[click] !== '' && cropArray.length === 0) {
                let cropErr = [...errorCheck]
                refClick.current = "*Please select CROP";
                cropErr[click] = refClick.current;
                setErrorCheck(cropErr)
            }
            else {
                let input_error = [...errorCheck]
                refClick.current = "* fields are required";
                input_error[click] = refClick.current;
                setErrorCheck(input_error)
            }
        }
        else if (formData.template === '1') {
            let name1 = employeeName
            let employeeeName = [...employeeName]
            refEmployeeName.current = name1
            employeeeName[click] = refEmpName.current
            setEmpName(employeeeName)

            let result = employeeList.filter(item => !employeeName.includes(item.employeeId
            ))
            setEmployeeList(result)
            if (listData.length === 0) {
                setClick(click + 1)
                let data = [...listData];
                data.push(data);
                setListData(data)
            }
            else if (profile[click] !== undefined && employeeName[click] && designationArray[click] !== undefined && descriptionArray[click] !== undefined) {
                setClick(click + 1)
                let data = [...listData];
                data.push(formData);
                setListData(data)
                let templateError = [...errorCheck]
                refClick.current = "";
                templateError[click] = refClick.current;
                setErrorCheck(templateError)
            }
            else if (employeeName[click] === undefined ) {
                let input_error = [...errorCheck]
                refClick.current = "* Name field are required";
                input_error[click] = refClick.current;
                setErrorCheck1(input_error)
            }
            else if (YearArray[click] === undefined) {
                let input_error_year = [...errorCheck]
                refClick.current = "*Year fields are required";
                input_error_year[click] = refClick.current;
                setErrorCheck4(input_error_year)
            }
            else if (srcImg.length !== 0 && srcImg[click] !== '' && cropArray.length === 0) {
                let cropErr = [...errorCheck]
                refClick.current = "*Please select CROP";
                cropErr[click] = refClick.current;
                setErrorCheck(cropErr)
            }
            else if (designationArray.length < 1) {
                let designationError = [...errorCheck]
                refClick.current = "*  Desgination fields are required";
                designationError[click] = refClick.current;
                setErrorCheck2(designationError)
            }
            else if (descriptionArray.length < 1) {
                let descripationError = [...errorCheck]
                refClick.current = "* Description fields are required";
                descripationError[click] = refClick.current;
                setErrorCheck3(descripationError)
            }
            else {
                let input_error = [...errorCheck]
                refClick.current = "* image field are required";
                input_error[click] = refClick.current;
                setErrorCheck(input_error)
            }  
            
        }
        else if (formData.template === '3') {
            let name1 = employeeName
            let employeeeName = [...employeeName]
            refEmployeeName.current = name1
            employeeeName[click] = refEmpName.current
            setEmpName(employeeeName)

            let result = employeeList.filter(item => !employeeName.includes(item.employeeId
            ))
            setEmployeeList(result)

            if (listData.length === 0) {

                setClick(click + 1)
                let data = [...listData];
                data.push(data);
                setListData(data)
            }
           else if (employeeName[click] === undefined ) {
                let input_error = [...errorCheck]
                refClick.current = "* Name field are required";
                input_error[click] = refClick.current;
                setErrorCheck1(input_error)
            }
            else if (profile[click] !== undefined && employeeName[click] !== undefined && designationArray[click] !== undefined && descriptionArray[click] !== undefined) {
                setClick(click + 1)
                let data = [...listData];
                data.push(formData);
                setListData(data)
                let templateError = [...errorCheck]
                refClick.current = "";
                templateError[click] = refClick.current;
                setErrorCheck(templateError)
            }

            else if (srcImg.length !== 0 && srcImg[click] !== '' && cropArray.length === 0) {
                let cropErr = [...errorCheck]
                refClick.current = "*Please select CROP";
                cropErr[click] = refClick.current;
                setErrorCheck(cropErr)
            }
            else if (designationArray.length < 1) {
                let designationError = [...errorCheck]
                refClick.current = "*  Desgination fields are required";
                designationError[click] = refClick.current;
                setErrorCheck2(designationError)
            }
            else if (descriptionArray.length < 1) {
                let descripationError = [...errorCheck]
                refClick.current = "* Description fields are required";
                descripationError[click] = refClick.current;
                setErrorCheck3(descripationError)
            }

            else {
                let input_error = [...errorCheck]
                refClick.current = "* image field are required";
                input_error[click] = refClick.current;
                setErrorCheck(input_error)
            }
        }
        else {

        }
    };

    useEffect(() => {
        setTimeout(() => {
            setResMsg(false);
            setResMssg(false)
            setResponseMsg(false)
            localStorage.removeItem("resMsg")
            localStorage.removeItem("resMssg")
            localStorage.removeItem("responseMsg")
        }, 3000)
    }, [])
    return (
        <div className="body_container cm_bold">

            {loader && <Loader />}

            {
                resMsg ?
                    <div className="alert alert-success cm_top_fix">Mail Sent Successfully</div> : ''
            }
            {
                resMssg ?
                    <div className="alert alert-success cm_top_fix">Mail Sent Successfully</div> : ''
            }
            {
                responseMsg ?
                    <div className="alert alert-success cm_top_fix">Mail Sent Successfully</div> : ''
            }
            <h4 className="mb-4 cm_bold">EMAIL TEMPLATE</h4>
            <div className="row" style={{ gap: "50px", alignItems: "center" }}>
                {initial ? "" : <div className={`col-5  ${error.templateError ? "input_error" : ""}`}>
                    <label className='sub'>Template Type</label>
                    <Select
                        style={{ width: "80%" }}
                        className="cm_leaves "
                        placeholder="Template Type"
                        onChange={changeTemplate}
                        disabled={formData.template}
                    >
                        <Option key="1">Anniversary Template</Option>
                        <Option key="2">Birthday Template</Option>
                        <Option key="3">Welcome Template</Option>
                    </Select>
                    {templateSelect ? (
                        <span className="error_msg ml-2">{templateSelect}</span>
                    ) : null}

                </div>}

                <div className="col-5 mt-3">{formData.template ? <button className='btn btn-primary mt-3' onClick={handleAdd}  >+ ADD EMPLOYEE</button> : ""}
                    <div className="" > {addEmpError ? (
                        <span className="error_msg ml-2">{addEmpError}</span>
                    ) : null}
                        {initial || formData.template ? "" : <button className='btn btn-primary' onClick={handlemailopen} style={{ height: "fit-content", padding: "10px" }} >CUSTOMIZED MAIL TO ALL</button>}
                    </div>
                </div>

            </div>

            {initial ? <div>
                <div style={{ width: '40%', paddingTop: '24px' }}>
                    <label className="sub">Upload Template</label>
                    <input
                        type="file"
                        className="form-control"
                        placeholder='Upload the file'
                        accept="image/x-png,image/jpg,image/jpeg"
                        onChange={e => changedriveFile(e)}
                    />
                    {file ? <img style={{ height: "30vh", margin: "27px" }} src={URL.createObjectURL(file)} /> : ""}
                    <span className="errorText">{fileErrorMessage}</span>
                </div>
                <div style={{ width: "40%", marginTop: "20px" }}>
                    <label className="sub">Subject</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='Please write the subject'
                        onChange={changesubject}
                    />
                    <span className="errorText">{subjectErrorMessage}</span>

                </div>
                <div style={{ width: "40%", marginTop: "20px" }}>
                    <label className="sub">New Employee Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='New Joinig Name'
                        disabled={id ? true : false}
                        maxlength="50"
                        onChange={changeName}
                    />
                </div>
                <div className={`form-group col-5 `} style={{ paddingTop: '24px', paddingLeft: '0px' }}>
                    <label className="sub">Employee Name</label>

                    <Select
                        showSearch
                        placeholder="Employee Name"
                        onChange={changeId}
                        optionFilterProp="key"
                        allowClear={id ? true : false}
                        disabled={name ? true : false}
                        filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                    >
                        {!_.isEmpty(employeeList)
                            ? employeeList.sort(function (a, b) {
                                if (a.firstName < b.firstName) { return -1; }
                                if (a.firstName > b.firstName) { return 1; }
                                return 0;
                            })
                                .map(
                                    (employee) =>
                                        employee.status == 1 && (
                                            <Option
                                                key={`${employee.firstName} ${employee.lastName}`}
                                                value={employee.employeeId}
                                            >
                                                <span>
                                                    {employee.firstName} {employee.lastName} ({employee.empId})
                                                </span>
                                            </Option>
                                        )
                                )
                            : ""}
                    </Select>
                    <span className="errorText">{NameErrorMessage}</span>
                </div>
            </div> : ""}
            {listData && listData.length > 0 && listData.map((item, index) => {
                if (formData.template === "1") {
                    return (

                        <>
                            <h5 className='mt-5'>Employee {index + 1}</h5>
                            
                            <div className="row mt-3">
                                <div className={`form-group col-6 ${error.empNameError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Name</label>
                                    <Select
                                        showSearch
                                        placeholder="Employee Name"
                                        onChange={changeEmpName}
                                        onSearch={() => { }}
                                        optionFilterProp="key"
                                        disabled={formDataArray[index] ? true : false}
                                        filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}

                                    >
                                        {!_.isEmpty(employeeList)
                                            ? employeeList.sort(function (a, b) {
                                                if (a.firstName < b.firstName) { return -1; }
                                                if (a.firstName > b.firstName) { return 1; }
                                                return 0;
                                            })
                                                .map(
                                                    (employee) =>
                                                        employee.status == 1 && (
                                                            <Option
                                                                key={`${employee.firstName} ${employee.lastName}`}
                                                                value={employee.employeeId}
                                                            >
                                                                <span>
                                                                    {employee.firstName} {employee.lastName} ({employee.empId})
                                                                </span>
                                                            </Option>
                                                        )
                                                )
                                            : ""}
                                    </Select>
                                    {errorCheck1[index] ? (
                                        <span className="error_msg ml-4">{errorCheck1[index]}</span>
                                    ) : null}
                                </div>
                                <div className={`form-group col-6 ${error.descripationError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Employee Description'
                                        onChange={changeDescription}
                                        disabled={formDataArray[index] ? true : false}
                                        maxLength='300'
                                    />
                                    {errorCheck3[index] ? (
                                        <span className="error_msg ml-4">{errorCheck3[index]}</span>
                                    ) : null}
                                </div>

                            </div>
                            <div className='row'>
                                <div className={`form-group col-6 ${error.designationError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Designation</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Employee Designation'
                                        onChange={changeDesignation}
                                        disabled={formDataArray[index] ? true : false}
                                        maxlength='50'

                                    />
                                   {errorCheck2[index] ? (
                                        <span className="error_msg ml-4">{errorCheck2[index]}</span>
                                    ) : null}
                                </div>
                                <div className={`form-group col-6 ${error.YearError ? "input_error" : ""}`}>
                                    <label className="sub">Year Anniversary </label>
                                    <Select
                                        style={{ width: "80%" }}
                                        className="cm_leaves "
                                        placeholder="Enter the Year"
                                        onChange={changeYear}
                                        disabled={formDataArray[index] ? true : false}

                                    >
                                        <Option key="1">1</Option>
                                        <Option key="2">2</Option>
                                        <Option key="3">3</Option>
                                        <Option key="4">4</Option>
                                        <Option key="5">5</Option>
                                        <Option key="6">6</Option>
                                        <Option key="7">7</Option>
                                    </Select>
                                    {errorCheck4[index] ? (
                                        <span className="error_msg ml-4">{errorCheck4[index]}</span>
                                    ) : null}
                                </div>
                                <div className={`form-group col-6 ${error.fileError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Picture</label>
                                    {profile[index] ? <div>
                                        {profile[index] ?
                                            <img src={profile[index]} alt="Final Result" style={{ borderRadius: '50%', boxShadow: '0 0 0 3px #000' }} /> :
                                            ''
                                        }
                                    </div> :
                                        <>
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder='Employee Picture'
                                                accept="image/x-png,image/jpg,image/jpeg"
                                                onChange={e => changeFile(e, index)}
                                                disabled={employeeName[index] && descriptionArray[index] && YearArray[index]&& designationArray[index] ? false : true}
                                            />
                                            <div> <ReactCrop
                                                src={srcImg[index]}
                                                crop={crop}
                                                circularCrop
                                                onImageLoaded={setImage}
                                                onChange={setCrop} />
                                                <br />
                                                {srcImg[index] ?
                                                    <button className='btn btn-primary' onClick={getCroppedImg}>CROP</button>
                                                    : ''
                                                }

                                            </div>
                                            {errorCheck[index] ? (
                                        <span className="error_msg ml-4">{errorCheck[index]}</span>
                                    ) : null}

                                        </>}


                                </div>
                            </div>

                            <div class="row d-flex justify-content-center">
                                <div className='col-md-6'>
                                  
                                </div>
                            </div>

                        </>
                    )
                }
                if (formData.template === "2") {
                    return (
                        <>
                            <div>
                                <h5 className='mt-5'>Employee {index + 1}</h5>
                                <div className="row mt-3" id='lower_btn_div' >

                                    <div className={`form-group col-5 `}>
                                        <label className="sub">Employee Name</label>
                                        <Select
                                            showSearch
                                            placeholder="Employee Name"
                                            onChange={changeEmpName}
                                            onSearch={() => { }}
                                            optionFilterProp="key"
                                            disabled={formDataArray[index] ? true : false}
                                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {!_.isEmpty(employeeList)
                                                ? employeeList.sort(function (a, b) {
                                                    if (a.firstName < b.firstName) { return -1; }
                                                    if (a.firstName > b.firstName) { return 1; }
                                                    return 0;
                                                })
                                                    .map(
                                                        (employee) =>
                                                            employee.status == 1 && (

                                                                <Option
                                                                    key={`${employee.firstName} ${employee.lastName}`}
                                                                    value={employee.employeeId}
                                                                >
                                                                    <span>
                                                                        {employee.firstName} {employee.lastName} ({employee.empId})
                                                                    </span>
                                                                </Option>
                                                            )
                                                    )

                                                : ""}
                                        </Select>
                                        {errorCheck1[index] ? (
                                            <span className="error_msg ml-4">{errorCheck1[index]}</span>
                                        ) : null}
                                      
                                    </div>


                                    <div className={`form-group col-5 ${error.fileError ? "input_error" : ""}`}>
                                        <label className="sub">Employee Picture</label>

                                        {profile[index] ? <div>

                                            {/* <p>Preview of Canvas Crop</p> */}
                                            {profile[index] ?
                                                <img src={profile[index]} alt="Final Result" style={{ borderRadius: '50%', boxShadow: '0 0 0 3px #000' }} /> :
                                                ''
                                            }
                                        </div> :


                                            <>

                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder='Employee Picture'
                                                    accept="image/x-png,image/jpg,image/jpeg"
                                                    onChange={e => changeFile(e, index)}
                                                    disabled={employeeName[index] ? false : true}

                                                />
                                                <div> <ReactCrop
                                                    src={srcImg[index]}
                                                    crop={crop}
                                                    circularCrop
                                                    onImageLoaded={setImage}
                                                    onChange={setCrop} />
                                                    <br />
                                                    {srcImg[index] ?
                                                        <button className='btn btn-primary' onClick={(e) => { getCroppedImg(); setRemove(true) }}>CROP</button>
                                                        : ''
                                                    }
                                                     
                                                </div>
                                
                                                {errorCheck[index] ? (
                                            <span className="error_msg ml-4">{errorCheck[index]}</span>
                                        ) : null}
                                               
                                            </>}
                                           
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className='col-md-6'>
                                       
                                    </div>

                                </div>


                            </div>
                        </>
                    )
                }

                else {

                    return (
                        <>
                            <h5 className='mt-5'>Employee {index + 1}</h5>
                            {console.log(designationArray)}
                            <div className="row mt-3">
                                <div className={`form-group col-6 ${error.empNameError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Name</label>
                                    <Select
                                        showSearch
                                        placeholder="Employee Name"
                                        onChange={changeEmpName}
                                        onSearch={() => { }}
                                        optionFilterProp="key"
                                        disabled={formDataArray[index] ? true : false}
                                        filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {!_.isEmpty(employeeList)
                                            ? employeeList.sort(function (a, b) {
                                                if (a.firstName < b.firstName) { return -1; }
                                                if (a.firstName > b.firstName) { return 1; }
                                                return 0;
                                            })
                                                .map(
                                                    (employee) =>
                                                        employee.status == 1 && (
                                                            <Option
                                                                key={`${employee.firstName} ${employee.lastName}`}
                                                                value={employee.employeeId}
                                                            >
                                                                <span>
                                                                    {employee.firstName} {employee.lastName} ({employee.empId})
                                                                </span>
                                                            </Option>
                                                        )
                                                )
                                            : ""}
                                    </Select>
                                    {errorCheck1[index] ? (
                                        <span className="error_msg ml-4">{errorCheck1[index]}</span>
                                    ) : null}
                                </div>
                                <div className={`form-group col-6 ${error.descripationError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Employee Description'
                                        onChange={changeDescription}
                                        maxLength='150'
                                        disabled={formDataArray[index] ? true : false}
                                    />
                                      {errorCheck3[index] ? (
                                        <span className="error_msg ml-4">{errorCheck3[index]}</span>
                                    ) : null}
                                </div>

                            </div>
                            <div className='row'>
                                <div className={`form-group col-6 ${error.designationError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Designation</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Employee Designation'
                                        onChange={changeDesignation}
                                        maxLength='50'
                                        disabled={formDataArray[index] ? true : false}

                                    />
                                    {errorCheck2[index] ? (
                                        <span className="error_msg ml-4">{errorCheck2[index]}</span>
                                    ) : null}
                                </div>
                                <div className={`form-group col-6 ${error.fileError ? "input_error" : ""}`}>
                                    <label className="sub">Employee Picture</label>
                                    {profile[index] ? <div>
                                        {profile[index] ?
                                            <img src={profile[index]} alt="Final Result" style={{ borderRadius: '50%', boxShadow: '0 0 0 3px #000' }} /> :
                                            ''
                                        }
                                    </div> :
                                        <>
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder='Employee Picture'
                                                accept="image/x-png,image/jpg,image/jpeg"
                                                onChange={e => changeFile(e, index)}
                                                disabled={employeeName[index] && descriptionArray[index] && designationArray[index] ? false : true}
                                            />
                                            <div> <ReactCrop
                                                src={srcImg[index]}
                                                crop={crop}
                                                circularCrop
                                                onImageLoaded={setImage}
                                                onChange={setCrop} />
                                                <br />
                                                {srcImg[index] ?
                                                    <button className='btn btn-primary' onClick={getCroppedImg}>CROP</button>
                                                    : ''
                                                }
                                            </div>
                                            {errorCheck[index] ? (
                                        <span className="error_msg ml-4">{errorCheck[index]}</span>
                                    ) : null}
                                        </>}
                                </div>
                            </div>

                            <div class="row d-flex justify-content-center">
                                <div className='col-md-6'>
                                 
                                </div>
                            </div>

                        </>
                    )
                }
            })}
            <div className="row mt-3" id='lower_btn'>
                <div className="col-11" >
                    <div>
                        {initial ?
                            sumbit ? <h4 className='sent_bday_btn'>SENT</h4> :
                                <button className="btn btn-primary mr-3 cm_btn3" onClick={(e) => handledriveSumbit(e)}>Sumbit</button> : ""}
                        {formData.template ?
                            <button className="btn btn-primary mr-3 cm_btn3" style={{ marginTop: "2.5%" }} onClick={() => handleTemplateChange(formData)}>Next</button>
                            : ""}
                        {formData.template ?
                            <button className="cancel_btn btn btn-default mr-0 cm_btn2"
                                style={{ marginTop: "2.5%" }}
                                onClick={() => window.location.reload(false)}
                            >Reset
                            </button>
                            : <button className='cancel_btn btn btn-default mr-0 cm_btn2 ' onClick={handlecancel}>
                                Cancel
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Templates


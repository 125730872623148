import React, { useEffect, useMemo, useReducer } from "react";
import { Loader } from "../svg";
import { useDispatch } from "react-redux";
import {
  Toastify,
  createSelectItems,
  currentMonth,
  dateFormat,
  isAdmin,
  reducer,
} from "../helper/Constant";
import { Link } from "react-router-dom";
import _ from "lodash";
import { getTeamMembersByAdminAction } from "../../redux/actions/getTeamMembersByAdmin";
import { handleDeleteValidetion, handleValidetion } from "./TeamValidation";
import { deleteTeamMembersByAdminAction } from "../../redux/actions/deleteTeamMembersByAdminAction";
import AddTeamMembers from "./teamManagementModal/AddTeamMembers";
import { getEmployeeListByManager } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import { addTeamMembersByAdminAction } from "../../redux/actions/addTeamMemberByAdminAction";
import { useDebounce } from "../attendance/Constants";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { CONSTANTS } from "../../config";
import Team from "./Team";
import { DatePicker, Select } from "antd";
import { statusMon } from "../leaves/Constants";
import { Tab, Tabs } from "react-bootstrap";
import TeamAttendance from "./TeamAttendance";
import moment from "moment";
import TeamLeave from "./TeamLeave";
import { TeamMemberLeaveReason } from "./teamManagementModal/TeamMemberLeaveReason";
import "./team.css";
import { findNameById } from "./teamFunction";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const { Option } = Select;
const teamHead = JSON.parse(localStorage.getItem("teamHead"));
const initialState = {
  loader: false,
  search: "",
  pageNo: 1,
  count: 10,
  employeeList: [],
  teamMembersList: [],
  sortType: SORT_TYPE_CONSTANTS.Asscending,
  sortField: "empId",
  deleteEmployeeErrorMsg: "",
  reId: [],
  multiValue: [],
  resTeamMsg: false,
  resTeamFailMsg: false,
  successMessage: "",
  responseMessage: "",
  OpenAddMemberPopup: false,
  emloyee: [],
  employeeErrorMsg: "",
  dataCount: 0,
  searchMonth: "",
  searchYear: "",
  tabStatus: 0,
  attendanceData: [],
  leaveData: [],
  projectData: [],
  avgHours: 0,
  totalAttendanceCount: 0,
  leaveCount: 0,
  searchDate: "",
  attendanceList: [],
  leaveList: [],
  dateRange: false,
  attendanceListCount: 0,
  leaveListCount: 0,
  leaveReasonData: {},
  openLeaveReasonModal: false,
  leadsArray: [],
  associateLeadsArray: [],
  teamName: "",
};

export default function TeamDetail(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const {
    loader,
    search,
    pageNo,
    count,
    teamMembersList,
    sortType,
    sortField,
    deleteEmployeeErrorMsg,
    reId,
    multiValue,
    resTeamFailMsg,
    resTeamMsg,
    responseMessage,
    successMessage,
    OpenAddMemberPopup,
    employeeList,
    employee,
    employeeErrorMsg,
    dataCount,
    searchMonth,
    searchYear,
    tabStatus,
    attendanceData,
    projectData,
    avgHours,
    totalAttendanceCount,
    leaveData,
    leaveCount,
    searchDate,
    attendanceList,
    dateRange,
    attendanceListCount,
    leaveListCount,
    leaveList,
    leaveReasonData,
    openLeaveReasonModal,
    leadsArray,
    associateLeadsArray,
    teamName,
  } = state;
  const dispatchToAction = useDispatch();
  const debouncedSearchTerm = useDebounce(search, 2000);

  useEffect(() => {
    getTeamMembers();
    getEmployee();
  }, [
    pageNo,
    debouncedSearchTerm,
    sortType,
    tabStatus,
    searchMonth,
    searchYear,
    searchDate,
  ]);

  const getEmployee = () => {
    let params = ``;
    getEmployeeListByManager(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: "typeCheckSet",
          payload: {
            employeeList: resData.employeesList,
            loader: false,
          },
        });
      }
    });
  };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";
    dispatch({
      type: "typeCheckSet",
      payload: {
        pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
        search: filtersFromParams.search ? filtersFromParams.search : "",
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "empId",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : SORT_TYPE_CONSTANTS.Asscending,
        searchMonth: filtersFromParams.searchDate
          ? ""
          : filtersFromParams.searchMonth
          ? filtersFromParams.searchMonth
          : "",
        searchYear: filtersFromParams.searchDate
          ? ""
          : filtersFromParams.searchYear
          ? filtersFromParams.searchYear
          : "",
        tabStatus: filtersFromParams.tabStatus
          ? filtersFromParams.tabStatus
          : "0",
        dateRange: filtersFromParams.dateRange
          ? filtersFromParams.dateRange
          : false,
        searchDate: filtersFromParams.searchDate
          ? moment(filtersFromParams.searchDate, format)
          : "",
      },
    });
  }, [history.location.search]);

  const getTeamMembers = () => {
    let formattedDate1 = "";
    if (searchDate !== "") {
      formattedDate1 = new Date(searchDate);
    }
    let params = `teamId=${
      teamHead && teamHead.length > 0 && teamHead[0]?.teamid
        ? teamHead[0]?.teamid
        : props.match.params.id
    }&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${
      pageNo - 1
    }&search=${search.trim()}&searchMonth=${
      searchDate ? "" : searchMonth
    }&searchYear=${searchDate ? "" : searchYear}&searchDate=${
      searchYear || searchMonth
        ? ""
        : formattedDate1
        ? formattedDate1.getFullYear() +
          "-" +
          (formattedDate1.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          formattedDate1.getDate().toString().padStart(2, "0")
        : ""
    }`;
    history.push(
      `?search=${search}&sortField=${sortField}&sortType=${sortType}&searchMonth=${searchMonth}&searchYear=${searchYear}&tabStatus=${tabStatus}&dateRange=${dateRange}&searchDate=${searchDate}&pageNo=${pageNo}`
    );

    dispatch({
      type: "typeCheckSet",
      payload: {
        loader: true,
      },
    });

    dispatchToAction(
      getTeamMembersByAdminAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  const handleInputChange = (val) => {
    let { multiValue, reId } = state;
    dispatch({
      type: "typeCheckSet",
      payload: {
        deleteEmployeeErrorMsg: "",
      },
    });
    multiValue = [];
    if (reId.includes(val)) {
      for (var i = 0; i < reId.length; i++) {
        if (reId[i] === val) {
          reId.splice(i, 1);
          if (reId.length === 0) {
            dispatch({
              type: "typeCheckSet",
              payload: {
                approve: false,
                reject: false,
                pending: false,
              },
            });
          }
        }
      }
    } else {
      reId.push(val);
    }
    dispatch({
      type: "typeCheckSet",
      payload: {
        multiValue: reId,
      },
    });
  };

  const handleSearch = (e) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        search: e.target.value,
        pageNo: 1,
        deleteEmployeeErrorMsg: "",
      },
    });
    if (e.target.value === "" || e.target.value === undefined) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          search: "",
          pageNo: 1,
          status: "",
        },
      });
    }
  };

  const handleSelectChange = () => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        search: "",
      },
    });
  };

  const deleteAllMember = () => {
    if (
      handleDeleteValidetion({
        multiValue: multiValue,
        dispatch: dispatch,
      })
    ) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          loader: true,
        },
      });
      let params = {
        teamId: props.match.params.id,
        teamMembersId: JSON.stringify(multiValue),
      };
      dispatchToAction(
        deleteTeamMembersByAdminAction({
          useDispatch: dispatch,
          params: params,
          getTeamMembers: getTeamMembers,
        })
      );
    }
  };
  const deleteMember = (id) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        loader: true,
        deleteEmployeeErrorMsg: "",
      },
    });
    let params = {
      teamId: props.match.params.id,
      teamMembersId: JSON.stringify(id),
    };
    dispatchToAction(
      deleteTeamMembersByAdminAction({
        useDispatch: dispatch,
        params: params,
        getTeamMembers: getTeamMembers,
      })
    );
  };

  const handleOpenAddMemberPopup = () => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        OpenAddMemberPopup: true,
        deleteEmployeeErrorMsg: "",
      },
    });
  };
  const closeModal = () => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        OpenAddMemberPopup: false,
        employeeErrorMsg: "",
        employee: [],
        openLeaveReasonModal: false,
        leaveReasonData: {},
      },
    });
  };

  const handleChangeEmp = (e) => {
    const Emp = state.employeeList.filter((ele) => {
      return ele.employeeId === e;
    });
    dispatch({
      type: "typeCheckSet",
      payload: {
        employee: e,
        employeeErrorMsg: "",
      },
    });
  };
  const handleSave = () => {
    if (
      handleValidetion({
        employee: employee,
        dispatch: dispatch,
      })
    ) {
      let params = {
        teamMembersId: JSON.stringify(employee),
        teamId: props.match.params.id,
      };
      dispatchToAction(
        addTeamMembersByAdminAction({
          useDispatch: dispatch,
          params: params,
          getTeamMembers: getTeamMembers,
          closeModal: closeModal,
        })
      );
    }
  };
  const handlePageChange = (pageNo) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        pageNo: pageNo,
        deleteEmployeeErrorMsg: "",
      },
    });
  };
  const handleSort = (e) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        loader: false,
        sortField: e,
        statusErrorMsg: "",
        sortType:
          sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
    });
  };

  const handleMonth = (month) => {
    if (month === undefined) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchMonth: "",
          pageNo: 1,
          month: false,
        },
      });
    } else {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchMonth: month,
          searchYear: searchYear,
          searchDate: "",
          searchTimeStamp: "",
          pageNo: 1,
          month: true,
        },
      });
    }
  };

  const handleYear = (year) => {
    if (year === undefined) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchYear: "",
          pageNo: 1,
          year: false,
        },
      });
    } else {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchYear: year,
          pageNo: 1,
          year: true,
        },
      });
    }
  };

  const handleDate = (e) => {
    if (e) {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchDate: e,
          pageNo: 1,
          searchMonth: "",
          searchYear: "",
          dateRange: true,
        },
      });
    } else {
      dispatch({
        type: "typeCheckSet",
        payload: {
          searchDate: "",
          pageNo: 1,
          dateRange: false,
        },
      });
    }
  };

  const handleFilter = (e) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        tabStatus: e,
        searchMonth: "",
        searchYear: "",
        searchDate: "",
        searchQuarter: "",
        pageNo: 1,
        search: "",
        multiValue: [],
        reId: [],
        deleteEmployeeErrorMsg: "",
      },
    });
  };
  const handleOpenView = (data) => {
    dispatch({
      type: "typeCheckSet",
      payload: {
        openLeaveReasonModal: true,
        leaveReasonData: data,
      },
    });
  };
  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <>
        <div>
          <Toastify
            resMsg={resTeamMsg}
            text={successMessage}
            resMsgFail={resTeamFailMsg}
            responseMessage={responseMessage}
          />
        </div>
        <div className="cm_breadcrumb">
          <ul>
            {isAdmin === "1" ||
            isAdmin === "4" ||
            (isAdmin === "2" && teamHead.length > 1) ? (
              <li>
                {" "}
                <Link to="/admin-team">Team Management</Link>{" "}
                <div className="cm_breadcrumb_arrow"></div>
              </li>
            ) : (
              "TEAM__"
            )}
            <li style={{ textTransform: "uppercase" }}>{teamName}</li>
          </ul>
        </div>
        <div className="filters justify-content-end mb-5 mt-3">
          {tabStatus === "0" || tabStatus === "2" || tabStatus === "3" ? (
            <>
              <div className="col-md-4 team_search_bar">
                <input
                  maxLength={20}
                  className="form-control cm_box"
                  type="search"
                  placeholder="Search by Name/E.ID"
                  aria-label="Search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  autoComplete="off"
                />
                {search ? (
                  <span
                    className="team_clear"
                    onClick={(e) => handleSelectChange(e)}
                  >
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span className="team_clear">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                )}
              </div>

              {isAdmin === "1" ? (
                tabStatus === "2" || tabStatus === "3" ? (
                  ""
                ) : (
                  <button
                    className="btn btn-primary "
                    onClick={handleOpenAddMemberPopup}
                  >
                    Add New Employee
                  </button>
                )
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {tabStatus === "1" || tabStatus === "2" || tabStatus === "3" ? (
            <>
              <Select
                className="select_year_cal"
                value={
                  searchMonth ? statusMon(Number(searchMonth)) : "Select Month"
                }
                defaultValue="Select Month"
                name="searchMonth"
                onChange={(e) => handleMonth(e)}
                allowClear={searchMonth ? true : false}
                disabled={dateRange === "true" || dateRange === true}
              >
                <Option value="1">January</Option>
                <Option value="2">February</Option>
                <Option value="3">March</Option>
                <Option value="4">April</Option>
                <Option value="5">May</Option>
                <Option value="6">June</Option>
                <Option value="7">July</Option>
                <Option value="8">August</Option>
                <Option value="9">September</Option>
                <Option value="10">October</Option>
                <Option value="11">November</Option>
                <Option value="12">December</Option>
              </Select>

              <Select
                className="select_year_cal"
                value={searchYear ? searchYear : "Select Year"}
                defaultValue="Select Year"
                name="searchYear"
                onChange={handleYear}
                allowClear={searchYear ? true : false}
                disabled={dateRange === "true" || dateRange === true}
              >
                {createSelectItems()}
              </Select>
            </>
          ) : (
            ""
          )}
          {tabStatus === "2" || tabStatus === "3" ? (
            <DatePicker
              value={searchDate ? moment(searchDate) : ""}
              format={dateFormat}
              className="date-border filter-date mt-1"
              onChange={handleDate}
              disabledDate={(current) => current.isAfter(moment())}
            />
          ) : (
            ""
          )}
        </div>
        <div className="mb-5">
          <h3>
            Team Head:-{" "}
            {leadsArray.map((data, index) => (
              <span key={index} style={{ fontSize: "18px" }}>
                {index > 0 && ", "}
                {findNameById(employeeList, data)}
              </span>
            ))}{" "}
          </h3>
        </div>
        <Tabs
          activeKey={
            tabStatus === "" || tabStatus === "0" || tabStatus === undefined
              ? "0"
              : tabStatus === "1"
              ? "1"
              : tabStatus === "2"
              ? "2"
              : "3"
          }
          id="uncontrolled-tab-example"
          onSelect={(e) => handleFilter(e)}
        >
          <Tab eventKey={0} title={`Members Info`}></Tab>
          <Tab eventKey={1} title={`Team Stats`}></Tab>
          <Tab eventKey={2} title={`Team Attendance`}></Tab>
          <Tab eventKey={3} title={`Team Leaves`}></Tab>
        </Tabs>
        {tabStatus === "0" ? (
          <div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="white bg_blue">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">
                      Employee ID
                      <i
                        className={
                          sortType === SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "empId"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => handleSort("empId")}
                      ></i>
                    </th>
                    <th>
                      Name
                      <i
                        className={
                          sortType === SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "name"
                            ? "fa fa-sort-alpha-asc ml-2"
                            : "fa fa-sort-alpha-desc ml-2"
                        }
                        onClick={() => handleSort("name")}
                      ></i>
                    </th>
                    <th>E-Mail</th>
                    <th scope="col">Phone No</th>
                    <th>Designtaion</th>
                    <th scope="col" style={{ width: 150 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(teamMembersList[0]?.teamMembers)
                    ? teamMembersList[0]?.teamMembers.map((profile, i) => (
                        <tr key={profile.departmentId}>
                          <td>{i + 1}</td>
                          <td>
                            <span className="cm_col">{profile.empId}</span>
                          </td>
                          <td>
                            <span
                              className="cm_col"
                              onClick={localStorage.setItem(
                                "teamId",
                                teamMembersList[0]?._id?.teamId
                              )}
                            >
                              <Link
                                to={`/admin-team/person-detail/${profile._id}`}
                              >
                                {profile.name}
                              </Link>
                            </span>
                          </td>

                          <td>{profile.email}</td>
                          <td>{profile.phone}</td>
                          <td style={{ width: 400 }}>
                            <span className="cm_col_desig">
                              {profile.designationId.map((department, i) => (
                                <span className="cm_col_dept">
                                  {department.designationName
                                    ? `${department.designationName}`
                                    : ""}
                                </span>
                              ))}
                            </span>
                          </td>
                          <td className="leave_button_margin">
                            <span onClick={() => deleteMember(profile._id)}>
                              <i
                                className="fa fa-trash-o red  fa-2x"
                                title="Delete Member"
                              />
                            </span>
                            <input
                              className="department-checkbox "
                              name="editEmployee"
                              type="checkbox"
                              checked={
                                reId.includes(profile._id) ? true : false
                              }
                              value={profile._id}
                              onChange={() => handleInputChange(profile._id)}
                            />
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
              {teamMembersList[0]?.teamMembers &&
              teamMembersList[0]?.teamMembers?.length > 0 ? (
                ""
              ) : (
                <h3 className="text-center"> No data available</h3>
              )}
            </div>
            {deleteEmployeeErrorMsg ? (
              <span className="error_msg">{deleteEmployeeErrorMsg}</span>
            ) : null}
            <div>
              {teamMembersList[0]?.teamMembers &&
              teamMembersList[0]?.teamMembers.length > 0 ? (
                <button
                  type="submit"
                  class="btn btn-danger mt-3"
                  onClick={() => deleteAllMember()}
                >
                  Delete Selected
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : tabStatus === "1" ? (
          <Team
            attendance={attendanceData}
            leave={leaveData}
            project={projectData}
            avgHours={avgHours}
            totalAttendanceCount={totalAttendanceCount}
            leaveCount={leaveCount}
          />
        ) : tabStatus === "2" ? (
          <TeamAttendance
            attendanceList={attendanceList}
            pageNo={pageNo}
            count={count}
            dataCount={attendanceListCount}
            handlePageChange={handlePageChange}
            sortType={sortType}
            sortField={sortField}
            dispatch={dispatch}
          />
        ) : (
          <TeamLeave
            leaveList={leaveList}
            dataCount={leaveListCount}
            pageNo={pageNo}
            count={count}
            handlePageChange={handlePageChange}
            handleOpenView={handleOpenView}
            sortType={sortType}
            sortField={sortField}
            dispatch={dispatch}
          />
        )}
      </>

      <AddTeamMembers
        OpenAddMemberPopup={OpenAddMemberPopup}
        employeeList={employeeList}
        closeModal={closeModal}
        employee={employee}
        teamMembers={teamMembersList[0]?.teamMembers}
        employeeErrorMsg={employeeErrorMsg}
        handleChangeEmp={handleChangeEmp}
        handleSave={handleSave}
        leadsArray={[...leadsArray, ...associateLeadsArray]}
      />
      <TeamMemberLeaveReason
        show={openLeaveReasonModal}
        leaveReasonData={leaveReasonData}
        handleClose={closeModal}
      />
    </div>
  );
}

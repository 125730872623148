import React from "react";
import { MdClose } from "react-icons/md";
import "../OrganizationChart.css";
import profileImg from "../../../asset/imgs/user-img.png";
import DeleteEmployeeFromOrgModal from "../OrganizationChartModals/DeleteEmployeeFromOrgModal";
import { generateRandomColor } from "./OrganizationConstants";

const EmployeeDetailsCard = ({
  handleClose,
  employee,
  employees,
  handleOpenDeleteModal,
  handleCloseDeleteModal,
  empIdDelete,
  openDeleteModal,
  employeeDetail,
  openModal,
  setOpenModal,
  setResponse,
  setResponseMessage,
  setSuccess,
  setSuccessMessage,
  fetchData,
  data,
  employeeList,
  rootId,
  setLoader,
}) => {
  const isAdmin = localStorage.getItem("isAdmin");
  return (
    <>
      <div className="backgroundOverlay" onClick={handleClose}></div>
      <div className="empDetailCard">
        <button className="cardCloseBtn" onClick={handleClose}>
          <MdClose />
        </button>
        {employee && (
          <>
            <div>
              <div className="cardHeader">
                <img
                  className="cardImg"
                  src={employee.image ? employee.image : profileImg}
                  alt="Profile"
                />
                <h2 className="cardName">{employee.name}</h2>
                <div className="nodeDepartment ml-1">
                  {employee.designation_name?.toUpperCase()}
                </div>
                <p className="cardRole">{employee.department}</p>
              </div>
              <div className="cardBody">
                <div className="cardItem">
                  <p className="cardItemLabel">Phone:</p>
                  <p className="cardItemValue mx-2">{employee.phone_number}</p>
                </div>
                <div className="cardItem">
                  <p className="cardItemLabel">Email:</p>
                  <p className="cardItemValue mx-2">{employee.email}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="cardBodyTeamMembers">
                {employees.filter(
                  (emp) => emp.parentId === employee?.id.toString()
                ).length > 0 && (
                  <>
                    <div className="cardHeader mt-2">
                      <h4>{employee?.team} Team</h4>
                    </div>
                    <h4>Team Members:</h4>
                    <div>
                      {employees
                        .filter(
                          (emp) => emp.parentId === employee?.id.toString()
                        )
                        .map((emp) => (
                          <div className="cardItemTeam" key={emp.id}>
                            {emp.image ? (
                              <img
                                className="cardItemImg"
                                src={emp.image}
                                alt="Profile"
                              />
                            ) : (
                              <div
                                style={{
                                  backgroundColor: generateRandomColor(),
                                }}
                                className="cardItemImg"
                              >
                                <text className="cardItemText">
                                  {employee.name.charAt(0).toUpperCase()}
                                </text>
                              </div>
                            )}
                            <div className="cardItemTeamDetails">
                              <p className="cardItemTeamInfo">
                                {emp.name}
                                {`(${emp.department_name?.toUpperCase()})`}
                              </p>
                              {(isAdmin === "4" || isAdmin === "1") && (
                                <div>
                                  <span
                                    onClick={() =>
                                      handleOpenDeleteModal(emp.id, emp)
                                    }
                                  >
                                    <i
                                      className="fa fa-trash-o ml-2 red"
                                      title="Remove Employee"
                                    />
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <DeleteEmployeeFromOrgModal
        rootId={rootId}
        handleCloseDeleteModal={handleCloseDeleteModal}
        empIdDelete={empIdDelete}
        openDeleteModal={openDeleteModal}
        employeeDetail={employeeDetail}
        openModal={openModal}
        setOpenModal={setOpenModal}
        delEmpId={employeeDetail?.id.toString()}
        delEmpParentId={employeeDetail?.parentId?.toString()}
        handleClose={handleClose}
        setResponse={setResponse}
        setResponseMessage={setResponseMessage}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        fetchData={fetchData}
        data={data}
        employeeList={employeeList}
        setLoader={setLoader}
      />
    </>
  );
};

export default EmployeeDetailsCard;

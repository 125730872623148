
import ResponseFilter from "../../ResponseFilter";
import { getOtp } from "../../config/services/employeeServices";
import {
    GET_OTP_REQUEST,
    GET_OTP_SUCCESS,
    GET_OTP_FAILURE,
} from "./actionType";

export default function getOtpAction(props) {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getOtpActionRequest());
        getOtp(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resMsg: true,
                        successMessage: "OTP sent ",
                    }
                })
                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resMsg: false,
                        }
                    })
                }, 4000)
            } else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resFailMsg: true,
                        responseMessage: res?.data?.error?.responseMessage
                    }
                })

                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resFailMsg: false,
                        }
                    })
                }, 4000)
            }
        })
            .catch((error) => {
                dispatch(getOtpActionFailure(error));
            });
    };
};

const getOtpActionRequest = () => {
    return {
        type: GET_OTP_REQUEST,
    };
};

const getOtpActionSuccess = (data) => {
    return {
        type: GET_OTP_SUCCESS,
        payload: data,
    };
};

const getOtpActionFailure = (error) => {
    return {
        type: GET_OTP_FAILURE,
        payload: error,
    };
};
export {
    getOtpAction,
    getOtpActionRequest,
    getOtpActionSuccess,
    getOtpActionFailure,
};
import React from "react";

export const ExcelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="#303c42"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M13.5.5.5 3v18l13 2.5zM13.5 3.5h10v17h-10"
    />
    <path
      fill="none"
      stroke="#303c42"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M13.5 5.5h3v1h-3zM18.5 5.5h3v1h-3zM13.5 8.5h3v1h-3zM18.5 8.5h3v1h-3zM13.5 11.5h3v1h-3zM18.5 11.5h3v1h-3zM13.5 14.5h3v1h-3zM18.5 14.5h3v1h-3zM13.5 17.5h3v1h-3zM18.5 17.5h3v1h-3zM4.5 8.5l4 8M4.5 16.5l4-8"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg viewBox="0 0 10.701 7.644" fill="grey">
    <g transform="translate(0 -4.482)">
      <g transform="translate(1.595 8.059)">
        <path
          d="M21.18,17.49a.184.184,0,0,1-.127-.051l-2.139-2.007a.186.186,0,1,1,.255-.271l2.139,2.007a.186.186,0,0,1-.128.321Z"
          transform="translate(-13.854 -15.111)"
        />
        <path
          d="M5.588,17.491a.186.186,0,0,1-.128-.321L7.6,15.163a.186.186,0,1,1,.255.271L5.715,17.44A.184.184,0,0,1,5.588,17.491Z"
          transform="translate(-5.402 -15.112)"
        />
      </g>
      <path
        d="M9.839,12.126H.863A.866.866,0,0,1,0,11.26V5.349a.866.866,0,0,1,.863-.867H9.839a.866.866,0,0,1,.863.867V11.26A.866.866,0,0,1,9.839,12.126ZM.863,4.829a.52.52,0,0,0-.518.52V11.26a.52.52,0,0,0,.518.52H9.839a.52.52,0,0,0,.518-.52V5.349a.52.52,0,0,0-.518-.52Z"
        transform="translate(0 0)"
      />
      <path
        d="M5.764,10.015a.919.919,0,0,1-.631-.246L.717,5.6A.2.2,0,0,1,.7,5.342a.164.164,0,0,1,.243-.018L5.359,9.488a.606.606,0,0,0,.811,0l4.41-4.157a.163.163,0,0,1,.243.018.2.2,0,0,1-.017.262L6.4,9.768A.918.918,0,0,1,5.764,10.015Z"
        transform="translate(-0.414 -0.501)"
      />
    </g>
  </svg>
);

export const DashboardIcon = () => (
  <svg viewBox="0 0 21.6 17.3">
    <path d="M19.9,0H1.7C0.6,0.2-0.1,1.2,0,2.3V15c-0.1,1.1,0.6,2.1,1.7,2.3h18.1c1.1-0.2,1.9-1.2,1.7-2.3V2.3C21.7,1.2,21,0.2,19.9,0z M0.7,2.3C0.6,1.6,1.1,1,1.7,0.9h18.1c0.7,0.1,1.1,0.7,1,1.4v0.5H0.7V2.3z M20.9,15c0.1,0.7-0.4,1.3-1,1.4H1.7c-0.7-0.1-1.1-0.7-1-1.4V3.6h20.2L20.9,15z" />
    <path d="M3.2,1.4H2.3C2,1.4,1.8,1.6,1.8,1.8c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5C3.6,1.6,3.4,1.4,3.2,1.4z" />
    <path d="M6.5,1.4H5.5c0,0,0,0,0,0c0,0,0,0,0,0H4.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h0.9c0,0,0,0,0,0c0,0,0,0,0,0h0.9c0.3,0,0.5-0.2,0.5-0.5S6.7,1.4,6.5,1.4z" />
    <path d="M15.2,7.7h3.6c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-3.6c-0.3,0-0.5,0.2-0.5,0.5C14.7,7.5,14.9,7.7,15.2,7.7z" />
    <path d="M13.4,9.5h5.4c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-5.4c-0.3,0-0.5,0.2-0.5,0.5S13.1,9.5,13.4,9.5L13.4,9.5z" />
    <path d="M13.4,11.3h5.4c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.2-0.5-0.5-0.5h-5.4c-0.3,0-0.5,0.2-0.5,0.5C12.9,11.1,13.1,11.3,13.4,11.3L13.4,11.3z" />
    <path d="M14.7,13.2h2.7c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.2-0.5-0.5-0.5h-2.7c-0.3,0-0.5,0.2-0.5,0.5C14.3,13,14.5,13.2,14.7,13.2z" />
    <path d="M18.8,12.3h-0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.2,0.2,0.5,0.5,0.5h0.5c0.3,0,0.5-0.2,0.5-0.5C19.2,12.5,19,12.3,18.8,12.3z" />
    <path d="M15.1,7.7h0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-0.5c-0.3,0-0.5,0.2-0.5,0.5C14.6,7.5,14.8,7.7,15.1,7.7z" />
    <path d="M1.7,13.4h10.8c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H11V9.5c0-0.2-0.2-0.4-0.4-0.4l0,0H9c-0.2,0-0.4,0.2-0.4,0.4l0,0v3.1H8.3V6.1c0-0.2-0.2-0.4-0.4-0.4H6.3c-0.2,0-0.4,0.2-0.4,0.4l0,0v6.6H5.6v-5c0-0.2-0.2-0.4-0.4-0.4h0H3.6c-0.2,0-0.4,0.2-0.4,0.4l0,0v5H1.7c-0.2,0-0.4,0.2-0.4,0.4S1.5,13.4,1.7,13.4z M9.4,9.9h0.8v2.7H9.4L9.4,9.9z M6.7,6.4h0.8v6.2H6.7L6.7,6.4z M4,8h0.8v4.6H4L4,8z" />
  </svg>
);

export const PasswordIcon = () => (
  <svg viewBox="0 0 9.8 13.1" fill="grey">
    <path d="M4.3,9.1l-0.2,1.6c0,0.2,0.1,0.3,0.2,0.3c0,0,0,0,0,0h1.1c0.2,0,0.3-0.1,0.3-0.3c0,0,0,0,0,0L5.6,9.1C5.9,8.9,6,8.6,6,8.2c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C3.8,8.6,4,8.9,4.3,9.1z M4.9,7.7c0.3,0,0.5,0.2,0.5,0.5c0,0.2-0.1,0.4-0.3,0.5C5.1,8.7,5,8.9,5,9l0.2,1.4H4.7L4.8,9c0-0.1,0-0.2-0.2-0.3C4.4,8.6,4.3,8.2,4.4,8C4.5,7.8,4.7,7.7,4.9,7.7L4.9,7.7z" />
    <path d="M9.6,4.9H8.8V3.8C8.7,1.7,7,0,4.9,0.1c-2.1,0-3.7,1.7-3.8,3.8v1.1H0.3C0.1,4.9,0,5,0,5.2c0,0,0,0,0,0V12c0,0.6,0.5,1.1,1.1,1.1h7.7c0.6,0,1.1-0.5,1.1-1.1V5.2C9.8,5,9.7,4.9,9.6,4.9C9.6,4.9,9.6,4.9,9.6,4.9z M1.6,3.8c0-1.8,1.4-3.3,3.2-3.3s3.3,1.4,3.3,3.2c0,0,0,0.1,0,0.1v1.1H7.7V3.8c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7v1.1H1.6L1.6,3.8z M7.1,3.8v1.1H2.7V3.8c0-1.2,1-2.2,2.2-2.2S7.1,2.6,7.1,3.8z M9.3,12c0,0.3-0.2,0.5-0.5,0.5H1.1c-0.3,0-0.5-0.2-0.5-0.5V5.5h8.8V12z" />
  </svg>
);

export const EyeIcon = () => (
  <svg viewBox="0 0 16.1 9.6">
    <path d="M8.1,0C4.9,0.1,1.9,1.8,0.1,4.4C0,4.6,0,5,0.1,5.2c1.8,2.7,4.7,4.3,7.9,4.4c3.2-0.1,6.1-1.8,7.9-4.4c0.2-0.2,0.2-0.5,0-0.8C14.2,1.8,11.2,0.1,8.1,0z M8.3,8.2C6.4,8.3,4.8,6.9,4.7,5C4.6,3.2,6,1.5,7.8,1.4s3.5,1.3,3.6,3.2c0,0.1,0,0.3,0,0.4C11.3,6.7,10,8.1,8.3,8.2z M8.2,6.6c-1,0.1-1.9-0.7-1.9-1.7C6.2,3.9,6.9,3,7.9,3s1.9,0.7,1.9,1.7c0,0.1,0,0.2,0,0.2C9.8,5.8,9.1,6.6,8.2,6.6L8.2,6.6z" />
  </svg>
);

export const EmployeeIcon = () => (
  <svg viewBox="0 0 19.8 19.8">
    <path d="M16.8,12.7c-1-1-2.3-1.8-3.7-2.3c2.6-1.8,3.2-5.2,1.5-7.8c-1.8-2.6-5.2-3.2-7.8-1.5S3.5,6.4,5.3,9c0.4,0.6,0.9,1.1,1.5,1.5c-3.9,1.4-6.5,5-6.5,9.2h1.5c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2h1.5C19.6,17,18.6,14.6,16.8,12.7z M9.9,9.9c-2.3,0-4.1-1.8-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1C14,8.1,12.2,9.9,9.9,9.9z" />
  </svg>
);

export const AttendanceIcon = () => (
  <svg viewBox="0 0 20.6 21.3">
    <path d="M10.9,15.5v-3c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v3.3c0,0.2,0.1,0.3,0.2,0.4l1.4,1.4c0.2,0.2,0.6,0.2,0.9,0c0.2-0.2,0.2-0.6,0-0.9L10.9,15.5z" />
    <path d="M19.9,1.2h-4.1V0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v0.6h-3.7V0.6c0-0.3-0.3-0.6-0.6-0.6C9.9,0,9.7,0.3,9.7,0.6v0.6H6.1V0.6c0-0.3-0.2-0.6-0.6-0.7c-0.3,0-0.6,0.2-0.7,0.6c0,0,0,0.1,0,0.1v0.6H0.6C0.3,1.2,0,1.5,0,1.9V16c0,0.3,0.3,0.6,0.6,0.6h3.8c0.8,3.2,4,5.2,7.2,4.4c2.2-0.5,3.9-2.2,4.4-4.4h3.8c0.3,0,0.6-0.3,0.6-0.6V1.9C20.6,1.5,20.3,1.2,19.9,1.2z M10.3,20c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7C15,17.9,12.9,20,10.3,20z M19.3,15.4h-3.1c0,0,0-0.1,0-0.1c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6c0,0,0,0,0,0c0,0,0,0.1,0,0.1H1.3V6.3h18.1V15.4z M19.3,5.1H1.3V2.5h3.6v0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6V2.5h3.5v0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0V2.5h3.7v0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6V2.5h3.5V5.1z" />
  </svg>
);

export const LeaveIcon = () => (
  <svg viewBox="0 0 18.7 20">
    <path d="M15.6,1.3V2v0.7v0.4c0,1-0.8,1.9-1.8,1.9S12,4.2,11.9,3.2c0,0,0-0.1,0-0.1V1.3H6.7v1.8c0,1-0.8,1.9-1.8,1.9S3.1,4.2,3,3.2c0,0,0-0.1,0-0.1V2V1.3c-1.6,0-3,1.3-3,2.9v12.6C0,18.6,1.4,20,3.2,20h12.3c1.8,0,3.2-1.4,3.2-3.2V7.2v-3C18.6,2.6,17.3,1.3,15.6,1.3z M15.5,18.7H3.2c-1,0-1.8-0.8-1.8-1.8V7.2h15.9l0,9.6C17.3,17.8,16.5,18.7,15.5,18.7z" />
    <path d="M4.9,3.9c0.4,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0V0.8C5.7,0.4,5.3,0,4.9,0S4.1,0.4,4.1,0.8c0,0,0,0,0,0v2.3C4.1,3.6,4.4,3.9,4.9,3.9z" />
    <path d="M13.8,3.9c0.4,0,0.8-0.4,0.8-0.8V0.8c0-0.4-0.4-0.8-0.8-0.8c0,0,0,0,0,0C13.3,0,13,0.4,13,0.8v2.3C13,3.6,13.3,3.9,13.8,3.9z" />
    <path d="M8.9,12.6c-0.2-0.1-0.2-0.2,0-0.3C9,12.2,9,12.1,9.1,12c0.3-0.3,0.4-0.7,0.4-1.2c0-0.6-0.2-1.2-0.7-1.6C8.3,8.9,7.7,8.7,7,8.7c-0.3,0-0.7,0-1,0.1C5.7,8.9,5.5,9.1,5.3,9.3C5,9.5,4.8,9.8,4.7,10.1c-0.1,0.2-0.1,0.5-0.2,0.8c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0h0.8c0.2,0,0.3-0.2,0.4-0.3c0-0.2,0.1-0.4,0.2-0.6C6.4,10.1,6.7,9.9,7,10c0.3,0,0.5,0.1,0.7,0.3C7.9,10.4,8,10.7,8,11c0,0.4-0.2,0.8-0.5,0.9C7.2,12,7,12,6.8,12c-0.2,0-0.3,0.2-0.3,0.4v0.4c0,0.2,0.2,0.3,0.3,0.4c0.3,0,0.5,0.1,0.8,0.1c0.4,0.2,0.7,0.6,0.6,1c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.2-0.5,0.3-0.8,0.3c-0.4,0-0.8-0.2-1-0.6c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2-0.2-0.3-0.4-0.3H4.7c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0,0,0c0,0.4,0.2,0.8,0.4,1.2c0.5,0.8,1.4,1.2,2.3,1.1c0.8,0.1,1.5-0.2,2.1-0.8c0.4-0.5,0.7-1.1,0.7-1.8c0-0.6-0.2-1.1-0.6-1.5C9.1,12.7,9,12.6,8.9,12.6z" />
    <path d="M14.1,8.7h-0.6c-0.2,0-0.3,0.1-0.4,0.2c0,0,0,0.1,0,0.2c-0.1,0.2-0.2,0.4-0.3,0.6C12.6,9.8,12.3,10,12,10c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.3,0.2-0.3,0.4v0.4c0,0.2,0.2,0.3,0.3,0.3h1.2c0.2,0,0.3,0.2,0.3,0.3c0,0,0,0,0,0v4.7c0,0.2,0.2,0.3,0.3,0.3h0.9c0.2,0,0.3-0.2,0.3-0.3V9.1C14.4,8.9,14.3,8.7,14.1,8.7z" />
  </svg>
);

export const ProjectIcon = () => (
  <svg viewBox="0 0 20.9 20.5">
    <path d="M10.4,0C4.7-0.1,0.1,4.5,0,10.2c0.1,5.7,4.7,10.3,10.4,10.2c5.7,0.1,10.4-4.5,10.4-10.2C20.8,4.5,16.1-0.1,10.4,0z M10.4,19.6c-5.2,0-9.5-4.1-9.6-9.4C0.9,5,5.2,0.8,10.4,0.9c5.2,0,9.5,4.1,9.6,9.4C20,15.5,15.7,19.6,10.4,19.6z" />
    <path d="M18.2,8.2C18.2,8.2,18.2,8.2,18.2,8.2H17c-0.1-0.4-0.3-0.7-0.5-1.1l0.9-0.9c0.3-0.3,0.4-0.9,0-1.2c0,0,0,0,0,0l-1.6-1.6c-0.4-0.3-0.9-0.3-1.3,0l-0.9,0.9c-0.4-0.2-0.7-0.3-1.1-0.4V2.7c0-0.5-0.4-0.9-0.9-0.9c0,0,0,0,0,0H9.3c-0.5,0-0.9,0.4-0.9,0.9c0,0,0,0,0,0v1.2C8,4,7.6,4.1,7.3,4.3L6.4,3.5C6,3.1,5.5,3.1,5.1,3.5L3.5,5c-0.3,0.3-0.4,0.9,0,1.2c0,0,0,0,0,0l0.9,0.9C4.2,7.5,4,7.9,3.9,8.2H2.7c-0.5,0-0.9,0.4-0.9,0.9c0,0,0,0,0,0v2.2c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0,0h1.2C4,12.6,4.2,13,4.4,13.3l-0.9,0.9c-0.3,0.3-0.4,0.9,0,1.2c0,0,0,0,0,0L5.1,17c0.4,0.3,0.9,0.3,1.3,0l0.9-0.9c0.4,0.2,0.7,0.3,1.1,0.4v1.2c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0,0h2.3c0.5,0,0.9-0.4,0.9-0.9c0,0,0,0,0,0v-1.2c0.4-0.1,0.8-0.3,1.1-0.4l0.9,0.9c0.4,0.3,0.9,0.3,1.3,0l1.6-1.6c0.3-0.3,0.4-0.9,0-1.2c0,0,0,0,0,0l-0.9-0.9c0.2-0.3,0.3-0.7,0.5-1.1h1.2c0.5,0,0.9-0.4,0.9-0.9c0,0,0,0,0,0V9.1C19.1,8.6,18.7,8.2,18.2,8.2z M16.6,11.3c-0.2,0-0.4,0.1-0.4,0.3c-0.1,0.5-0.3,1-0.6,1.5c-0.1,0.2-0.1,0.4,0.1,0.5l1.1,1.1l-1.6,1.6L14,15.3c-0.1-0.1-0.4-0.2-0.6-0.1c-0.5,0.3-1,0.5-1.5,0.6c-0.2,0-0.3,0.2-0.3,0.4v1.5H9.3v-1.5c0-0.2-0.1-0.4-0.3-0.4c-0.5-0.1-1-0.3-1.5-0.6c-0.2-0.1-0.4-0.1-0.6,0.1l-1.1,1.1l-1.6-1.6l1.1-1.1c0.1-0.1,0.2-0.4,0.1-0.5c-0.3-0.5-0.5-1-0.6-1.5c-0.1-0.2-0.2-0.3-0.4-0.3H2.7V9.1h1.6c0.2,0,0.4-0.1,0.4-0.3c0.1-0.5,0.3-1,0.6-1.5c0.1-0.2,0.1-0.4-0.1-0.5L4.1,5.7l1.6-1.6l1.1,1.1C7,5.3,7.3,5.4,7.4,5.2C7.9,5,8.4,4.8,9,4.6c0.2,0,0.3-0.2,0.3-0.4V2.7h2.3v1.5c0,0.2,0.1,0.4,0.3,0.4c0.5,0.1,1,0.3,1.5,0.6c0.2,0.1,0.4,0.1,0.6-0.1l1.1-1.1l1.6,1.6l-1.1,1.1c-0.1,0.1-0.2,0.4-0.1,0.5c0.3,0.5,0.5,1,0.6,1.5c0.1,0.2,0.2,0.3,0.4,0.3h1.6l0,2.2H16.6z" />
    <path d="M13.8,12.6L13.8,12.6l-1.4-1.2L12,11.9l0.7,0.6h-2.1c-1,0-1.8-0.8-1.8-1.8H8.1c0,1.4,1.1,2.5,2.5,2.5h2.1L12,13.8l0.5,0.5l1.4-1.2C14,13,14,12.7,13.8,12.6C13.9,12.6,13.8,12.6,13.8,12.6z" />
    <path d="M8.9,8.6L8.2,8h2.1c1,0,1.8,0.8,1.8,1.8h0.7c0-1.4-1.1-2.5-2.5-2.5H8.2l0.7-0.6L8.5,6.1L7.1,7.4C6.9,7.5,6.9,7.7,7,7.9c0,0,0,0,0,0l1.4,1.2L8.9,8.6z" />
  </svg>
);

export const DeviceIcon = () => (
  <svg viewBox="0 0 21.3 19.6">
    <path d="M4.9,4.1C6,4,6.8,3,6.8,1.9C6.8,0.8,6,0,4.9,0S3,0.8,3,1.9C3,3,3.8,4,4.9,4.1z" />
    <path d="M20.1,2.4h-4.6V1.5C15.5,1.2,15.3,1,15,1c-0.2,0-0.5,0.2-0.5,0.4v0.9H10c-0.7,0-1.2,0.5-1.2,1.2v1.6L8.1,4.9c-1-0.3-1.9-0.5-2.9-0.6L5,4.7l0.5,3.9l-0.6,1l-0.6-1l0.5-3.9h0L4.6,4.3c-1,0.1-2,0.3-3,0.6c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1L0,10.8c-0.1,0.4,0.2,0.8,0.6,0.9c0.4,0.1,0.8-0.2,0.9-0.6l1.1-5c0.1,0,0.2,0,0.3-0.1v5.1l-0.6,7.4c0,0.5,0.3,0.9,0.8,1c0,0,0,0,0,0h0.1c0.5,0,0.9-0.4,0.9-0.8l0.5-6.7c0.2,0.1,0.5,0.1,0.7,0l0.5,6.7c0,0.5,0.4,0.8,0.9,0.8c0,0,0,0,0.1,0c0.5,0,0.9-0.5,0.8-1L7,11.2V6.1c0.4,0.1,0.6,0.2,0.6,0.2h0h0l1.1,0.4v4c0,0.7,0.5,1.2,1.2,1.2h2.2L10.2,19c-0.1,0.2,0.1,0.5,0.3,0.5s0.5-0.1,0.5-0.3l2-7.3h1.5v5.2c0,0.2,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-5.2H17l2,7.3c0.1,0.2,0.3,0.4,0.5,0.3c0.2-0.1,0.4-0.3,0.3-0.5c0,0,0,0,0,0l-1.9-7.1h2.2c0.7,0,1.2-0.5,1.2-1.2V3.6C21.3,2.9,20.8,2.4,20.1,2.4z M20.7,10.7L20.7,10.7c0,0.3-0.3,0.6-0.6,0.6H10c-0.3,0-0.6-0.3-0.6-0.6V6.9l1.9,0.6c0.1,0,0.2,0,0.2,0c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0-0.1,0-0.2l4.5-2.1c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1l-4.4,2c-0.1-0.2-0.2-0.4-0.5-0.5L9.4,5.3V3.6C9.4,3.2,9.6,3,10,3h10.1c0.3,0,0.6,0.3,0.6,0.6V10.7z" />
    <path d="M15.9,7.7l0.3-0.2L15.9,7l-0.3,0.2c-0.1-0.1-0.3-0.3-0.5-0.4l0.1-0.4l-0.6-0.1l-0.1,0.4c-0.2,0-0.4,0-0.6,0.1l-0.2-0.3l-0.5,0.3l0.2,0.3c-0.1,0.1-0.3,0.3-0.4,0.5l-0.4-0.1l-0.1,0.6L13,8.2c0,0.2,0,0.4,0.1,0.6l-0.3,0.2l0.3,0.5l0.3-0.2c0.1,0.1,0.3,0.3,0.5,0.3l-0.1,0.4l0.6,0.1l0.1-0.4c0.2,0,0.4,0,0.6-0.1l0.2,0.3l0.5-0.3l-0.2-0.3c0.1-0.1,0.3-0.3,0.3-0.5l0.4,0.1l0.1-0.6L16,8.3C16,8.1,16,7.9,15.9,7.7z M15.1,9.1c-0.5,0.3-1.1,0.2-1.4-0.3c-0.3-0.5-0.2-1.1,0.3-1.4c0.5-0.3,1.1-0.2,1.4,0.3c0,0,0,0,0,0C15.7,8.2,15.5,8.8,15.1,9.1z" />
    <path d="M19.2,7.2L19,6.8l-0.3,0.1c-0.1-0.1-0.2-0.3-0.3-0.4l0.1-0.3L18.1,6L18,6.3c-0.2,0-0.3-0.1-0.5,0L17.4,6L17,6.1l0.1,0.3c-0.1,0.1-0.3,0.2-0.4,0.3l-0.3-0.1L16.3,7l0.3,0.1c0,0.2-0.1,0.3,0,0.5l-0.3,0.1l0.1,0.4L16.7,8c0.1,0.1,0.2,0.3,0.3,0.4l-0.1,0.3l0.4,0.2l0.1-0.3c0.2,0,0.3,0.1,0.5,0L18,8.9l0.4-0.1l-0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.3l0.3,0.1l0.2-0.4l-0.3-0.1c0-0.2,0.1-0.3,0-0.5L19.2,7.2z M18,8.2c-0.4,0.1-0.9-0.1-1-0.5c-0.1-0.4,0.1-0.9,0.5-1c0.4-0.1,0.9,0.1,1,0.5c0,0,0,0,0,0C18.6,7.6,18.4,8.1,18,8.2z" />
  </svg>
);

export const DepartmentIcon = () => (
  <svg viewBox="0 0 22.5 19.6">
    <path d="M18.5,9.1c0.9-1.1,1.3-2.4,1.3-3.7c0-2.9-2-5.3-4.6-5.3c-1.8,0.1-3.4,1.2-4,2.8C10.6,1.2,9,0.1,7.2,0C4.7,0,2.6,2.4,2.6,5.3c0,1.4,0.4,2.7,1.3,3.7C2.5,9.7,0,11,0,14.8v1.4h4.3c-0.2,0.6-0.3,1.3-0.3,2v1.4h14.4v-1.4c0-0.7-0.1-1.3-0.3-2h4.3v-1.4C22.5,11,19.9,9.7,18.5,9.1z M5,14.8H1.4c0-4.2,3.7-4.2,4.5-5.3L6,9C4.7,8.2,4,6.8,4,5.3c0-2.2,1.4-3.9,3.2-3.9C8.5,1.5,9.6,2.3,10,3.6C7.9,4.4,6.6,6.5,6.7,8.7c0,1.4,0.4,2.7,1.3,3.7C6.8,12.9,5.7,13.7,5,14.8z M11.3,18.2H5.4c0-4.2,3.7-4.2,4.5-5.3l0.1-0.5c-1.3-0.8-2-2.2-2-3.6c0-2.2,1.4-3.9,3.2-3.9s3.2,1.8,3.2,3.9c0.1,1.5-0.7,2.9-1.9,3.6l0.1,0.6c0.9,1,4.5,1.1,4.5,5.3L11.3,18.2z M17.5,14.8c-0.8-1-1.8-1.9-3-2.3c0.9-1.1,1.3-2.4,1.3-3.7c0.1-2.3-1.2-4.3-3.3-5.1c0.4-1.2,1.5-2.1,2.8-2.2c1.7,0,3.2,1.8,3.2,3.9c0.1,1.5-0.7,2.9-1.9,3.6l0.1,0.6c0.9,1,4.5,1.1,4.5,5.3L17.5,14.8L17.5,14.8z" />
  </svg>
);

export const ReimbursementIcon = () => (
  <svg viewBox="0 0 20.3 20.3">
    <path d="M19.8,6.4C19.8,6.4,19.8,6.4,19.8,6.4c-0.7-0.7-1.8-0.7-2.5,0l-1.8,1.8V3.5c0-0.5-0.2-0.9-0.5-1.3l-1.7-1.7C12.9,0.2,12.5,0,12,0H1.8C0.8,0,0,0.8,0,1.8v16.7c0,1,0.8,1.8,1.8,1.8h11.9c1,0,1.8-0.8,1.8-1.8v-5.3l4.3-4.3C20.5,8.2,20.5,7.1,19.8,6.4z M11.9,1.2c0.2,0,0.4,0,0.5,0.2L14.1,3c0.1,0.1,0.2,0.3,0.2,0.5h-2.4V1.2z M13.7,19.1H1.8c-0.3,0-0.6-0.3-0.6-0.6V1.8c0-0.3,0.3-0.6,0.6-0.6h8.9v3c0,0.3,0.3,0.6,0.6,0.6h3v4.6l-1.8,1.8L11.7,12c-0.1,0.1-0.1,0.1-0.1,0.2l-0.8,2.5c-0.1,0.3,0.1,0.6,0.4,0.8c0.1,0,0.3,0,0.4,0l2.5-0.8c0.1,0,0.2-0.1,0.2-0.1l0.1-0.1v4.1l0,0C14.3,18.8,14,19.1,13.7,19.1z M13.8,13.2l-0.3,0.3L12.2,14l0.4-1.3l0.3-0.3L13.8,13.2z M14.6,12.4l-0.8-0.8l2.9-2.9l0.8,0.8L14.6,12.4z M19,8C18.9,8,18.9,8.1,19,8L19,8l-0.6,0.6l-0.8-0.8l0.6-0.6c0.2-0.2,0.6-0.3,0.8,0C19.2,7.4,19.2,7.8,19,8z" />
    <path d="M2.9,7.1C2.9,7.1,3,7.1,2.9,7.1h8.4c0.3,0,0.6-0.2,0.6-0.6c0-0.3-0.2-0.6-0.6-0.6c0,0,0,0,0,0h0H3c-0.3,0-0.6,0.2-0.6,0.6C2.3,6.8,2.6,7.1,2.9,7.1z" />
    <path d="M2.9,9.5C2.9,9.5,3,9.5,2.9,9.5h6c0.3,0,0.6-0.2,0.6-0.6c0-0.3-0.2-0.6-0.6-0.6c0,0,0,0,0,0H3c-0.3,0-0.6,0.2-0.6,0.6C2.3,9.2,2.6,9.5,2.9,9.5z" />
    <path d="M8.9,10.7H3c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h5.9c0.3,0,0.6-0.3,0.6-0.6C9.5,11,9.2,10.7,8.9,10.7z" />
    <path d="M9.5,13.7c0-0.3-0.2-0.6-0.6-0.6c0,0,0,0,0,0H3c-0.3,0-0.6,0.2-0.6,0.6c0,0.3,0.2,0.6,0.6,0.6c0,0,0,0,0,0h5.9C9.2,14.3,9.5,14,9.5,13.7z" />
    <path d="M11.3,16.7H7.7c-0.3,0-0.6,0.2-0.6,0.6c0,0.3,0.2,0.6,0.6,0.6c0,0,0,0,0,0h3.6c0.3,0,0.6-0.3,0.6-0.6C11.9,17,11.6,16.7,11.3,16.7z" />
  </svg>
);

export const HolidayIcon = () => (
  <svg viewBox="0 0 18.5 18.5">
    <path d="M17.6,22.5" />
    <path d="M17.6,22.5" />
    <path d="M15.1,13.4h-0.3v-0.8c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v1.5c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h1c0.4,0,0.7-0.3,0.7-0.7C15.9,13.7,15.5,13.4,15.1,13.4C15.1,13.4,15.1,13.4,15.1,13.4L15.1,13.4z" />
    <circle cx="14" cy="7.8" r="1" />
    <path d="M15.6,1.4h-0.9V0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.7H9.9V0.7C9.9,0.3,9.6,0,9.2,0C8.8,0,8.5,0.3,8.5,0.7v0.7H5.2V0.7C5.2,0.3,4.9,0,4.5,0C4.1,0,3.8,0.3,3.8,0.7v0.7H2.9C1.3,1.4,0,2.7,0,4.3v11.3c0,1.6,1.3,2.9,2.9,2.9h5.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H2.9c-0.8,0-1.4-0.6-1.4-1.4V4.3c0-0.8,0.6-1.4,1.4-1.4h0.9v0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V2.9h3.2v0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V2.9h3.3v0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V2.9h0.9c0.8,0,1.4,0.6,1.4,1.4v4.1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V4.3C18.5,2.7,17.2,1.4,15.6,1.4z" />
    <path d="M14.1,9.7c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4C18.5,11.7,16.5,9.7,14.1,9.7z M14.1,17c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9c0,0,0,0,0,0C17,15.7,15.7,17,14.1,17z" />
    <circle cx="11" cy="7.8" r="1" />
    <circle cx="8" cy="10.8" r="1" />
    <circle cx="5" cy="7.8" r="1" />
    <circle cx="5" cy="10.8" r="1" />
    <circle cx="5" cy="13.8" r="1" />
    <circle cx="8" cy="13.8" r="1" />
    <circle cx="8" cy="7.8" r="1" />
  </svg>
);

export const TicketIcon = () => (
  <svg viewBox="0 0 22.1 18.8">
    <path d="M13.2,1.2C12.5,0,10.9-0.4,9.7,0.4C9.4,0.6,9.1,0.9,8.9,1.2L0.4,14.9C0.1,15.3,0,15.7,0,16.2c0,1.4,1.1,2.5,2.5,2.5h17.1c1.4,0,2.5-1.1,2.5-2.5c0-0.5-0.1-0.9-0.4-1.3L13.2,1.2z M19.6,17.1H2.5c-0.5,0-0.8-0.4-0.8-0.8c0-0.2,0-0.3,0.1-0.4l8.5-13.7c0.2-0.4,0.8-0.5,1.2-0.3c0.1,0.1,0.2,0.2,0.3,0.3l8.5,13.7c0.1,0.1,0.1,0.3,0.1,0.4C20.4,16.7,20.1,17.1,19.6,17.1z" />
    <path d="M10.2,5.9h1.7v6h-1.7V5.9z" />
    <path d="M10.2,13.6h1.7v1.7h-1.7V13.6z" />
  </svg>
);

export const AppraisalIcon = () => (
  <svg viewBox="0 0 20.6 19.4">
    <path d="M0.4,18.1c0,0.5-0.1,0.9-0.4,1.3h2.5v-7.6l-2.1,2.1V18.1z" />
    <path d="M5,10.8v8.6h2.1V9.6H6.3L5,10.8z" />
    <path d="M9.5,9.6h2.1v9.9H9.5V9.6z" />
    <path d="M18.5,2.7v16.7h2.1v-19c-0.1,0-0.2,0-0.3,0L18.5,2.7z" />
    <path d="M17,0l-5.1,3.6H5.2L0.4,6.9v1.6l5.7-3.9h6.7l6.1-4.3C18.3,0.3,17.6,0.2,17,0L17,0z" />
    <path d="M14,19.4h2.1V7.2L14,9.3V19.4z" />
  </svg>
);

// export const Loader = () => <div className="cm_loader"><svg viewBox="0 0 100 100"><circle fill="none" stroke="#FF6C00" strokeWidth="4" cx="50" cy="50" r="44" style={{opacity: '0.5'}}></circle><circle fill="#D6230C" stroke="#D6230C" strokeWidth="3" cx="8" cy="54" r="6" transform="rotate(345.119 50 51.8346)"><animateTransform attributeName="transform" dur="1s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite"></animateTransform></circle></svg></div>;
export const Loader = ({small}) => (
  <div className={!small ? "cm_loader" : 's_cm_loader'}>
    <svg viewBox="0 0 100 100">
      <circle fill="#347BDE" stroke="none" cx="6" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#347BDE" stroke="none" cx="26" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="#347BDE" stroke="none" cx="46" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  </div>
);

export const PdfIcon = () => (
  <svg viewBox="0 0 31.6 42.1">
    <path d="M31.3,9l-8.8-8.8C22.4,0.1,22.2,0,21.9,0H3.5C1.6,0,0,1.6,0,3.5v35.1c0,1.9,1.6,3.5,3.5,3.5h24.6c1.9,0,3.5-1.6,3.5-3.5v-29C31.6,9.4,31.5,9.2,31.3,9z M22.8,3l5.8,5.8h-4c-1,0-1.8-0.8-1.8-1.8V3z M29.8,38.6c0,1-0.8,1.8-1.8,1.8H3.5c-1,0-1.8-0.8-1.8-1.8V3.5c0-1,0.8-1.8,1.8-1.8h17.5V7c0,1.9,1.6,3.5,3.5,3.5h5.3V38.6z" />
    <path d="M20.3,25.8c-0.7-0.6-1.4-1.2-2.1-1.8c-0.7-0.7-1.3-1.3-1.8-1.9c0.6-1.4,1-2.9,1.2-4.5c0-2.9-1.1-3.5-2.6-3.5c-1.2,0-2.6,0.6-2.6,3.6c0,1.3,0.7,2.9,2.1,4.7c-0.3,1.1-0.8,2.3-1.2,3.7c-0.2,0.7-0.5,1.3-0.7,1.8c-0.2,0.1-0.4,0.2-0.6,0.3c-0.7,0.3-1.4,0.7-2,1c-2.8,1.3-4.7,2.2-4.7,3.9c0,1.3,1.4,2,2.6,2c1.6,0,4.1-2.2,5.9-5.9c2-0.7,4-1.3,6-1.6c1.5,1.1,3.1,2.2,3.9,2.2c2.2,0,2.6-1.3,2.6-2.3c0-2.1-2.4-2.1-3.5-2.1C22,25.5,21.1,25.6,20.3,25.8z M7.9,33.3c-0.3,0-0.6-0.1-0.9-0.3c0-0.6,1.9-1.5,3.6-2.4l0.3-0.2C9.7,32.5,8.4,33.3,7.9,33.3L7.9,33.3z M14,17.6c0-1.8,0.6-1.8,0.9-1.8c0.6,0,0.9,0,0.9,1.8c-0.1,0.9-0.4,1.9-0.7,2.7C14.5,19.5,14.1,18.6,14,17.6L14,17.6z M14.7,27.1c0.1-0.2,0.1-0.3,0.2-0.5c0.3-1,0.6-1.9,0.9-2.6c0.4,0.4,0.8,0.8,1.2,1.2c0.2,0.2,0.6,0.5,1.1,1C17,26.4,15.8,26.7,14.7,27.1L14.7,27.1z M24.6,27.5c0,0.4,0,0.6-0.8,0.6c-0.5-0.2-1-0.5-1.5-0.8c0.2,0,0.4,0,0.5,0C24.1,27.2,24.5,27.3,24.6,27.5L24.6,27.5z" />
  </svg>
);

export const ImageIcon = () => (
  <svg viewBox="0 0 39.8 42.2">
    <path d="M39.5,11.5L28.8,0.3c-0.2-0.2-0.4-0.2-0.6-0.2h-16C10.4,0.1,9,1.6,9,3.3v11.3H2.5c-1.3,0-2.4,1.1-2.4,2.4v12.1c0,1.3,1.1,2.4,2.4,2.4H9v7.3c0,1.8,1.5,3.2,3.2,3.2h24.2c1.8,0,3.2-1.4,3.2-3.2V12C39.7,11.8,39.6,11.6,39.5,11.5z M28.4,2.3l8.8,9.1h-8.8V2.3z M2.5,30c-0.4,0-0.8-0.4-0.8-0.8V17.1c0-0.4,0.4-0.8,0.8-0.8h21.8c0.4,0,0.8,0.4,0.8,0.8v12.1c0,0.4-0.4,0.8-0.8,0.8L2.5,30z M38.1,38.9c0,0.9-0.7,1.6-1.6,1.6H12.2c-0.9,0-1.6-0.7-1.6-1.6v-7.3h13.7c1.3,0,2.4-1.1,2.4-2.4V17.1c0-1.3-1.1-2.4-2.4-2.4H10.6V3.3c0-0.9,0.7-1.6,1.6-1.6h14.5v10.5c0,0.4,0.4,0.8,0.8,0.8h10.5V38.9z" />
    <path d="M19.1,23.1v1.2h1.6v0.9c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.5,0-1.1-0.2-1.4-0.6c-0.4-0.5-0.6-1.2-0.5-1.9c0-0.6,0.1-1.2,0.5-1.7c0.4-0.4,0.9-0.6,1.5-0.6c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.5,0.5,0.6,0.8l1.4-0.3c-0.1-0.6-0.4-1.1-0.9-1.5c-0.6-0.4-1.3-0.6-2-0.5c-0.6,0-1.1,0.1-1.7,0.3c-0.6,0.3-1,0.7-1.3,1.3c-0.3,0.6-0.5,1.3-0.5,1.9c0,0.6,0.1,1.3,0.4,1.9c0.3,0.6,0.7,1,1.2,1.3c0.6,0.3,1.2,0.5,1.9,0.5c0.6,0,1.2-0.1,1.7-0.3c0.5-0.2,0.9-0.4,1.3-0.8v-2.8L19.1,23.1L19.1,23.1z" />
    <path d="M14.4,20.6c-0.2-0.3-0.6-0.6-1-0.7c-0.6-0.1-1.1-0.1-1.7-0.1H9.4v6.9h1.4v-2.6h0.9c0.5,0,1,0,1.5-0.1c0.3-0.1,0.5-0.2,0.7-0.3c0.2-0.2,0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.7,0.2-1.1C14.8,21.5,14.6,21,14.4,20.6z M13.2,22.5c-0.1,0.2-0.3,0.3-0.4,0.3C12.4,23,12,23,11.6,23h-0.8v-2h0.7c0.3,0,0.7,0,1,0c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.6C13.3,22.2,13.3,22.4,13.2,22.5L13.2,22.5z" />
    <path d="M6.5,19.8v4.5c0,0.4,0,0.8-0.2,1.1c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.4c-0.1-0.3-0.2-0.6-0.2-0.8l-1.3,0.2c0,0.6,0.2,1.2,0.6,1.7c0.4,0.4,1,0.6,1.6,0.6c0.5,0,1-0.1,1.4-0.4c0.3-0.2,0.6-0.6,0.7-1c0.1-0.4,0.2-0.9,0.2-1.3v-4.4L6.5,19.8z" />
  </svg>
);

export const DocIcon = () => (
  <svg viewBox="0 0 39.8 42.2">
    <path d="M39.5,11.5L28.8,0.3c-0.2-0.2-0.4-0.2-0.6-0.2h-16C10.4,0.1,9,1.6,9,3.3v11.3H2.5c-1.3,0-2.4,1.1-2.4,2.4v12.1c0,1.3,1.1,2.4,2.4,2.4H9v7.3c0,1.8,1.5,3.2,3.2,3.2h24.2c1.8,0,3.2-1.4,3.2-3.2V12C39.7,11.8,39.6,11.6,39.5,11.5z M28.4,2.3l8.8,9.1h-8.8V2.3z M2.5,30c-0.4,0-0.8-0.4-0.8-0.8V17.1c0-0.4,0.4-0.8,0.8-0.8h21.8c0.4,0,0.8,0.4,0.8,0.8v12.1c0,0.4-0.4,0.8-0.8,0.8L2.5,30z M38.1,38.9c0,0.9-0.7,1.6-1.6,1.6H12.2c-0.9,0-1.6-0.7-1.6-1.6v-7.3h13.7c1.3,0,2.4-1.1,2.4-2.4V17.1c0-1.3-1.1-2.4-2.4-2.4H10.6V3.3c0-0.9,0.7-1.6,1.6-1.6h14.5v10.5c0,0.4,0.4,0.8,0.8,0.8h10.5V38.9z" />
    <path d="M4.3,26.2c-0.3,0-0.6-0.2-0.6-0.5v-5c0-0.3,0.3-0.5,0.6-0.5h2c4,0,3.9,6.1,0.1,6.1H4.3z M4.9,21.2v3.9h1.5c2.4,0,2.5-3.9,0-3.9H4.9z" />
    <path d="M13.6,26.3c-1.6,0.1-3.3-1-3.3-3.2c0-2.2,1.7-3.2,3.3-3.2c1.5,0.1,3.1,1.1,3.1,3.2C16.8,25.3,15.1,26.3,13.6,26.3z M13.5,21.1c-1,0-2.1,0.7-2.1,2.1c0,1.4,1.1,2.1,2.1,2.1c1,0,2.1-0.7,2.1-2.1C15.6,21.8,14.5,21.1,13.5,21.1z" />
    <path d="M17.8,23.2c0-1.7,1.1-3.2,3.1-3.2c0.8,0,1.4,0.2,2,0.8c0.2,0.2,0.3,0.6,0,0.9c-0.2,0.2-0.6,0.2-0.8,0c-0.4-0.4-0.7-0.5-1.2-0.5c-1.4,0-2,1-2,2c0,1.1,0.6,2.1,2,2.1c0.5,0,1-0.2,1.4-0.6c0.3-0.2,0.6-0.1,0.8,0.1c0.1,0.2,0.2,0.5-0.1,0.8c-0.6,0.6-1.4,0.7-2.1,0.7C18.8,26.3,17.8,24.9,17.8,23.2z" />
  </svg>
);

export const RemoveIcon = () => (
  <svg viewBox="0 0 5.7 5.8">
    <path d="M5.6,4.8L3.7,2.9L5.6,1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.3-0.2-0.4C5.3,0,5,0,4.8,0.2L2.9,2.1L1,0.2C0.7,0,0.3,0,0.1,0.3C0,0.5,0,0.8,0.2,1l1.9,1.9L0.2,4.8c-0.2,0.2-0.3,0.6,0,0.8c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l1.9-1.9l1.9,1.9C4.9,5.7,5,5.8,5.2,5.8c0.2,0,0.3-0.1,0.4-0.2C5.8,5.3,5.8,5,5.6,4.8z" />
  </svg>
);

export const DownloadIcon = () => (
  <svg viewBox="0 0 14.8 13.8">
    <path d="M14.5,6.5c-0.2-0.2-0.4-0.2-0.6-0.2h0c-0.4,0-0.8,0.4-0.8,0.8v3.6c0,0.8-0.6,1.4-1.4,1.4H3c-0.8,0-1.4-0.6-1.4-1.4V7.1c0-0.4-0.4-0.8-0.8-0.8C0.4,6.3,0,6.6,0,7.1v3.7c0,1.6,1.3,3,3,3h8.8c1.6,0,3-1.3,3-3V7.1C14.8,6.9,14.7,6.7,14.5,6.5z" />
    <path d="M6.8,10.5c0.1,0.2,0.4,0.2,0.6,0.2c0,0,0,0,0,0c0.2,0,0.4-0.1,0.6-0.2l2.5-2.5c0.2-0.2,0.2-0.4,0.2-0.6s-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2L8.2,8V0.8C8.2,0.4,7.8,0,7.4,0C6.9,0,6.6,0.4,6.6,0.8V8L5.4,6.8c-0.3-0.3-0.8-0.3-1.1,0C4.2,6.9,4.1,7.1,4.1,7.3s0.1,0.4,0.2,0.6L6.8,10.5z" />
  </svg>
);

export const RemainingLeaveIcon = () => (
  <svg viewBox="0 0 18.8 19.4">
    <circle fill="none" cx="9" cy="9.9" r="9" />
    <path
      fill="#D6D6D6"
      d="M9,18.9c-5,0-9-4-9-9s4-9,9-9c5,0,9,4,9,9S14,18.9,9,18.9z M9,1.9c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8s8-3.6,8-8C17,5.5,13.4,1.9,9,1.9z"
    />
    <path
      fill="#FBFBFB"
      d="M9.1,19.4l0-2c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.3-3.4,2.3-5.5s-0.8-4-2.3-5.5C13,2.8,11.1,2,9.1,2c0,0,0,0,0,0h0c-0.6,0-1-0.4-1-1s0.4-1,1-1c0,0,0,0,0,0c2.6,0,5,1,6.9,2.8c1.8,1.8,2.8,4.3,2.8,6.9s-1,5-2.8,6.9S11.7,19.4,9.1,19.4z"
    />
  </svg>
);

export const LateComingIcon = () => (
  <svg viewBox="0 0 17.9 17.7">
    <path
      fill="#fff"
      d="M9,0.5C4.4,0.6,0.7,4.4,0.8,9s3.9,8.3,8.5,8.2c4.6-0.1,8.2-3.8,8.2-8.3C17.4,4.2,13.6,0.5,9,0.5z M12.5,9.9H8.9c-0.5,0-1-0.4-1-1V5.3c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1v2.6h2.6c0.5,0,1,0.4,1,0.9C13.5,9.4,13.1,9.8,12.5,9.9C12.6,9.9,12.6,9.9,12.5,9.9L12.5,9.9z"
    />
  </svg>
);

export const PngIcon = () => (
  <svg viewBox="0 0 39.9 42.3">
    <path
      fill="#007BDE"
      d="M39.6,11.4L28.9,0.3C28.8,0.1,28.5,0,28.2,0h-16c-1.9,0-3.4,1.5-3.4,3.4v11.2H2.6c-1.4,0-2.6,1.2-2.6,2.6v12.1c0,1.4,1.2,2.6,2.6,2.6h6.3V39c0,1.9,1.5,3.4,3.4,3.4h24.2c0,0,0,0,0,0c1.9,0,3.4-1.5,3.4-3.4V12.1C39.9,11.8,39.8,11.6,39.6,11.4z M28.6,11.3V2.7l8.3,8.6H28.6z M10.8,31.8h13.6c1.4,0,2.6-1.2,2.6-2.6V17.1c0-1.4-1.2-2.6-2.6-2.6H10.8V3.4c0-0.8,0.7-1.5,1.5-1.5h14.4v10.4c0,0.5,0.4,1,1,1H38v25.7c0,0.8-0.7,1.5-1.5,1.5c0,0,0,0,0,0H12.3c-0.8,0-1.5-0.7-1.5-1.5V31.8z M25.1,29.3c0,0.4-0.3,0.7-0.7,0.7l-21.8,0c-0.4,0-0.7-0.3-0.7-0.7V17.1c0-0.4,0.3-0.7,0.7-0.7h21.8c0.4,0,0.7,0.3,0.7,0.7V29.3z"
    />
    <polygon
      fill="#007BDE"
      points="14.2,24.7 10.7,20.3 9.7,20.3 9.7,27.2 11,27.2 11,22.8 14.5,27.2 15.5,27.2 15.5,20.3 14.2,20.3 "
    />
    <path
      fill="#007BDE"
      d="M19.4,23.4v1.3h1.7v0.8c-0.4,0.3-0.8,0.4-1.3,0.4c-0.5,0-1.1-0.2-1.5-0.5s-0.6-0.9-0.6-1.4c0-0.1,0-0.2,0-0.3c-0.1-1.1,0.7-2.1,1.8-2.2c0.1,0,0.2,0,0.2,0c0.5,0,1,0.1,1.4,0.4l0.3,0.2l0.6-1.1l-0.2-0.2c-0.6-0.5-1.4-0.7-2.2-0.7c-0.9,0-1.7,0.3-2.4,0.9c-0.6,0.6-1,1.5-1,2.4c0,0.1,0,0.2,0,0.2c-0.1,0.9,0.2,1.7,0.8,2.4c0.6,0.7,1.4,1.1,2.3,1.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.9,0,1.8-0.3,2.5-0.9l0.1-0.1v-2.9H19.4z"
    />
    <path
      fill="#007BDE"
      d="M6.2,20.3c-0.2,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.8,0H4.3v6.9h1.4v-2.4c0.2,0,0.5,0,0.6,0c0.6,0.1,1.2-0.1,1.7-0.5s0.8-0.9,0.9-1.5c0-0.1,0-0.2,0-0.3C8.9,21.9,8.6,20.3,6.2,20.3z M7.5,22.5c0,0.8-0.7,1-1.2,1c-0.2,0-0.4,0-0.6,0v-1.9c0.2,0,0.5,0,0.7,0C7.5,21.6,7.5,22.3,7.5,22.5z"
    />
  </svg>
);

export const MenuIcon = () => (
  <svg viewBox="0 0 469.333 469.333">
    <path d="M53.333,106.667H416c29.417,0,53.333-23.927,53.333-53.333S445.417,0,416,0H53.333C23.917,0,0,23.927,0,53.333S23.917,106.667,53.333,106.667z" />
    <path d="M416,181.333H53.333C23.917,181.333,0,205.26,0,234.667S23.917,288,53.333,288H416c29.417,0,53.333-23.927,53.333-53.333S445.417,181.333,416,181.333z" />
    <path d="M416,362.667H53.333C23.917,362.667,0,386.594,0,416s23.917,53.333,53.333,53.333H416c29.417,0,53.333-23.927,53.333-53.333S445.417,362.667,416,362.667z" />
  </svg>
);

export const EarlyLeaveIcon = () => (
  <svg viewBox="0 0 17.8 14.8">
    <g transform="translate(661 280.598)">
      <g transform="translate(2.606 2)">
        <path
          fill="#fff"
          d="M-657.6-282.6c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9c2.1,0,3.9-1.7,3.9-3.9C-653.8-280.9-655.5-282.6-657.6-282.6z"
        />
      </g>
    </g>
    <path
      fill="#fff"
      d="M13.3,5.9c-2.5,0-4.4,2.1-4.4,4.5c0,2.5,2.1,4.4,4.5,4.4c2.4,0,4.4-2,4.4-4.4C17.8,7.8,15.7,5.8,13.3,5.9z M15.2,10.9h-2c-0.3,0-0.5-0.2-0.5-0.5V8.4c0-0.3,0.3-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v1.4h1.4c0.3,0,0.5,0.2,0.5,0.5C15.7,10.6,15.5,10.9,15.2,10.9L15.2,10.9z"
    />
    <path
      fill="#fff"
      d="M0,14.6c0.2-1,0.5-1.9,0.9-2.8c0.5-1,1.2-1.8,2.1-2.5c2.1-1.4,5.4-0.4,5.4-0.4c-0.7,2.2,0.3,4.6,2.3,5.7C10.9,14.7,0,14.6,0,14.6z"
    />
  </svg>
);

export const InnerLoader = () => (
  <div className="loader_wrapper_2">
    <div className="spinner_border">
      <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </div>
);
export const FoodOrdersIcon = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M14.209 22c.09 1.185-.293 1.99-2.468 2-4.083.019-4.441 0-8.524 0-2.207 0-2.521-.793-2.435-2h13.427zm6.791 2h-5.717c.423-.691.541-1.665.38-2.365l.004-.004c1.098-1.187 1.089-2.974.107-4.142.513-1.464-.89-5.941-6.443-6.844l-.331-2.645h14l-2 16zm-7.291-3c-.006 0-12.482-.002-12.506-.006-.674-.068-1.203-.711-1.203-1.494 0-.782.529-1.426 1.203-1.494.024-.004 1.318-.008 1.318-.008 2.088.018 2.227 1.639 3.256 1.633 1.028-.006 1.298-1.607 3.223-1.631h4.709c.715.019 1.291.684 1.291 1.5 0 .817-.576 1.482-1.291 1.5zm.647-4h-13.734c0-2.493 2.636-5 6.878-5s6.856 2.507 6.856 5zm9.644-10h-16v-1.382c.87 0 2-.697 2-1.618h6.185l2.072-4 1.359.634-1.776 3.366h4.199c0 .922 1.092 1.618 1.961 1.618v1.382z" />
  </svg>
);
export const ResumeIcon = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M3 24h19v-23h-1v22h-18v1zm17-24h-18v22h18v-22zm-1 1h-16v20h16v-20zm-2 16h-12v1h12v-1zm0-3h-12v1h12v-1zm0-3h-12v1h12v-1zm-7.348-3.863l.948.3c-.145.529-.387.922-.725 1.178-.338.257-.767.385-1.287.385-.643 0-1.171-.22-1.585-.659-.414-.439-.621-1.04-.621-1.802 0-.806.208-1.432.624-1.878.416-.446.963-.669 1.642-.669.592 0 1.073.175 1.443.525.221.207.386.505.496.892l-.968.231c-.057-.251-.177-.449-.358-.594-.182-.146-.403-.218-.663-.218-.359 0-.65.129-.874.386-.223.258-.335.675-.335 1.252 0 .613.11 1.049.331 1.308.22.26.506.39.858.39.26 0 .484-.082.671-.248.187-.165.322-.425.403-.779zm3.023 1.78l-1.731-4.842h1.06l1.226 3.584 1.186-3.584h1.037l-1.734 4.842h-1.044z" />
  </svg>
);
export const TransactionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M24 3v10h-4.809l-2.134-2h4.943v-6h-12v-.384l-3.17 2.972 2.27 2.412h-2.747l-2.353-2.5 8-7.5v3h12zm-6.712 6.42c-.581 0-.936-.41-1.073-.92h1.119l.055-.334h-1.232l-.002-.332h1.291l.054-.334h-1.292c.131-.518.476-.938 1.025-.938.188 0 .398.033.64.104l.127-.527c-.309-.092-.603-.139-.873-.139-.994 0-1.591.691-1.766 1.5h-.361l-.055.334h.367l-.002.332h-.422l-.054.334h.523c.173.809.777 1.5 1.848 1.5.25 0 .501-.047.746-.137l-.147-.539c-.175.064-.348.096-.516.096zm2.712 7.08l-8-7.5v3h-12v9h12v3l8-7.5zm-13.332 2.062v.438h-.418v-.416c-.431-.008-.878-.11-1.25-.303l.19-.686c.398.155.928.319 1.343.226.479-.108.577-.601.048-.838-.389-.181-1.573-.336-1.573-1.352 0-.567.432-1.075 1.242-1.188v-.443h.418v.424c.301.008.64.061 1.017.176l-.15.687c-.319-.112-.674-.215-1.018-.193-.62.036-.676.573-.242.798.713.335 1.644.584 1.644 1.478 0 .714-.562 1.095-1.251 1.192z" />
  </svg>
);
export const InterviewIcon = () => (
  <svg
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    fill="#ffffff"
    viewBox="0 0 943.118 943.118"
  >
    <g>
      <g>
        <g>
          <path d="M54.182,670.915v189.128c0,11.047,8.955,20,20,20h362.347c-3.137-6.688-4.899-14.143-4.899-22.006V670.915H54.182z" />
          <path
            d="M30,639.904h24.182h377.446V622.67v-24.418c0-0.229,0.007-0.456,0.009-0.685c0.107-15.218,3.8-29.6,10.277-42.337
           c2.796-5.496,6.107-10.688,9.873-15.506c4.478-5.729,9.597-10.934,15.245-15.507c16.361-13.248,37.182-21.197,59.827-21.197
           h22.555v-43.313c0-32.846-26.627-59.473-59.473-59.473h-53.809c-10.504,0-19.628,7.229-22.029,17.455l-25.013,106.529
           l-3.642,15.507l-2.578,10.977c-0.36,1.538-0.785,3.049-1.271,4.528h-16.584c-0.183-5.188-0.711-10.367-1.577-15.506
           c-0.148-0.892-0.306-1.779-0.476-2.666l-3.326-12.841l-19.571-75.542l15.62-34.473c2.965-6.545-1.82-13.968-9.006-13.968h-33.525
           c-7.186,0-11.972,7.423-9.006,13.968l15.62,34.473l-20.313,75.542l-3.086,11.478c-0.268,1.339-0.506,2.683-0.728,4.029
           c-0.848,5.14-1.36,10.317-1.527,15.506h-15.88c-0.484-1.48-0.909-2.99-1.271-4.528l-2.578-10.977l-3.641-15.508l-25.013-106.525
           c-2.401-10.227-11.524-17.455-22.029-17.455h-53.808c-32.846,0-59.473,26.627-59.473,59.473v64.513v15.506v15.506H30
           c-16.568,0-30,13.431-30,30v24.674C0,626.474,13.432,639.904,30,639.904z"
          />
          <path
            d="M329.919,368.094c73.717,0,133.477-59.76,133.477-133.477V92.744c0-18.391-16.561-32.347-34.686-29.233
           c-39.276,6.747-128.839,24.62-184.565,35.864c-27.752,5.599-47.704,29.986-47.704,58.297v76.946
           C196.442,308.335,256.202,368.094,329.919,368.094z"
          />
          <path
            d="M526.859,533.021c-10.345,0-20.121,2.418-28.812,6.703c-7.723,3.809-14.576,9.102-20.201,15.506
           c-9.95,11.325-16.036,26.118-16.204,42.337c-0.002,0.229-0.017,0.455-0.017,0.685v24.418v17.234v15.505v15.506v187.122
           c0,12.154,9.853,22.006,22.005,22.006h334.086h103.396c12.153,0,22.006-9.852,22.006-22.006V598.252
           c0-31.565-22.422-57.893-52.209-63.928c-4.207-0.852-8.562-1.303-13.021-1.303H549.414H526.859L526.859,533.021z"
          />
          <path
            d="M702.375,497.769c80.854,0,146.4-65.546,146.4-146.4v-84.396c0-31.052-21.886-57.8-52.322-63.941
           c-61.123-12.332-159.355-31.935-202.434-39.336c-1.879-0.323-3.743-0.478-5.577-0.478c-17.574,0-32.468,14.276-32.468,32.542
           v155.609C555.975,432.223,621.52,497.769,702.375,497.769z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
export const PrivacyIcon = () => (
  <svg
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z" />
  </svg>
);
export const CourierIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    fill="#000000"
  >
    <path d="m20.85,16.143c.192.199.187.515-.012.707l-2.703,2.614c-.36.355-.835.534-1.311.534s-.949-.177-1.312-.532l-1.364-1.345c-.197-.194-.199-.51-.005-.707.194-.197.511-.199.707-.005l1.363,1.344c.337.33.885.329,1.223-.004l2.706-2.617c.2-.191.516-.187.707.012Zm3.15,1.357c0,3.584-2.916,6.5-6.5,6.5s-6.5-2.916-6.5-6.5,2.916-6.5,6.5-6.5,6.5,2.916,6.5,6.5Zm-1,0c0-3.033-2.467-5.5-5.5-5.5s-5.5,2.467-5.5,5.5,2.467,5.5,5.5,5.5,5.5-2.467,5.5-5.5Zm1-13v1c0,.816-.393,1.542-1,1.999,0,0,0,0,0,.001v2c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-1.55c-.162.033-.329.05-.5.05H2.5c-.171,0-.338-.017-.5-.05v11.55c0,1.93,1.57,3.5,3.5,3.5h5c.276,0,.5.224.5.5s-.224.5-.5.5h-5c-2.481,0-4.5-2.019-4.5-4.5V7.5s0,0,0-.001c-.607-.457-1-1.183-1-1.999v-1C0,2.019,2.019,0,4.5,0h15c2.481,0,4.5,2.019,4.5,4.5Zm-1,0c0-1.93-1.57-3.5-3.5-3.5H4.5c-1.93,0-3.5,1.57-3.5,3.5v1c0,.827.673,1.5,1.5,1.5h19c.827,0,1.5-.673,1.5-1.5v-1Z" />
  </svg>
);

export const OrganizationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    viewBox="0 0 297 297"
  >
    <g>
      <path d="m167.666,250.543c-0.053-0.018-4.671-1.435-4.671-1.435-1.687-0.518-3.507-0.385-5.101,0.372l-9.394,4.467-9.394-4.467c-1.594-0.757-3.414-0.889-5.101-0.372 0,0-4.617,1.418-4.671,1.435-8.051,2.683-13.459,10.186-13.459,18.671v20.729c0,3.898 3.159,7.057 7.057,7.057h51.135c3.898,0 7.057-3.159 7.057-7.057v-20.729c0-8.485-5.408-15.988-13.458-18.671z" />
      <path d="m126.335,222.662c0,12.222 9.943,22.165 22.165,22.165s22.165-9.943 22.165-22.165-9.943-22.165-22.165-22.165-22.165,9.943-22.165,22.165z" />
      <path d="m122.933,97.384h51.135c3.898,0 7.057-3.159 7.057-7.057v-20.729c0-8.485-5.408-15.988-13.459-18.671-0.053-0.018-4.671-1.435-4.671-1.435-1.687-0.519-3.507-0.386-5.101,0.372l-9.394,4.466-9.394-4.467c-1.594-0.757-3.414-0.89-5.101-0.372 0,0-4.617,1.418-4.671,1.435-8.051,2.683-13.459,10.186-13.459,18.671v20.729c0.001,3.899 3.16,7.058 7.058,7.058z" />
      <path d="M148.5,44.33c12.222,0,22.165-9.943,22.165-22.165S160.722,0,148.5,0s-22.165,9.943-22.165,22.165   S136.278,44.33,148.5,44.33z" />
      <path d="m60.156,250.543c-0.053-0.018-4.672-1.436-4.672-1.436-1.687-0.516-3.507-0.385-5.101,0.374l-9.393,4.466-9.394-4.467c-1.594-0.757-3.413-0.889-5.101-0.372 0,0-4.617,1.418-4.671,1.435-8.051,2.683-13.459,10.186-13.459,18.671v20.729c0,3.898 3.159,7.057 7.057,7.057h51.134c3.898,0 7.057-3.159 7.057-7.057v-20.729c0.001-8.485-5.408-15.989-13.457-18.671z" />
      <path d="m40.99,244.827c12.222,0 22.165-9.943 22.165-22.165s-9.943-22.165-22.165-22.165-22.165,9.943-22.165,22.165 9.943,22.165 22.165,22.165z" />
      <path d="m275.176,250.543c-0.053-0.018-4.671-1.435-4.671-1.435-1.688-0.518-3.507-0.385-5.101,0.372l-9.394,4.467-9.393-4.466c-1.594-0.758-3.414-0.889-5.101-0.374 0,0-4.618,1.419-4.672,1.436-8.05,2.682-13.458,10.186-13.458,18.671v20.729c0,3.898 3.159,7.057 7.057,7.057h51.134c3.898,0 7.057-3.159 7.057-7.057v-20.729c0-8.485-5.408-15.988-13.458-18.671z" />
      <path d="m233.845,222.662c0,12.222 9.943,22.165 22.165,22.165 12.222,0 22.165-9.943 22.165-22.165s-9.943-22.165-22.165-22.165c-12.222,0-22.165,9.943-22.165,22.165z" />
      <path d="m40.99,182.07c4.733,0 8.569-3.837 8.569-8.569v-17.152h90.371v17.151c0,4.733 3.837,8.569 8.569,8.569s8.569-3.837 8.569-8.569v-17.152h90.371v17.152c0,4.733 3.837,8.569 8.569,8.569 4.733,0 8.569-3.837 8.569-8.569v-25.721c0-4.733-3.837-8.569-8.569-8.569h-98.941v-23.679c0-4.733-3.837-8.569-8.569-8.569s-8.569,3.837-8.569,8.569v23.679h-98.939c-4.733,0-8.569,3.837-8.569,8.569v25.721c0,4.733 3.836,8.57 8.569,8.57z" />
    </g>
  </svg>
);

import React from "react";
import { Modal } from "react-bootstrap";

const DeleteInvestmentModal = (props) => {
  const { openDeleteInvestment, closeModal, investmentId, dispatch, getViewInvestment,handleDelete } =
    props;

    return (
    <Modal
      className="cm_ticket_status"
      show={openDeleteInvestment}
      onHide={closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header className="text-center confirmation">
        <p className="mb-4 cm_bold cnfrm_msg">
          Are you sure you want to delete the investment?
        </p>
      </Modal.Header>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          <button
            className="btn btn-danger cm_btn3"
            onClick={(e) =>
              handleDelete({
                e,
                investmentId,
                dispatch,
                getViewInvestment,
                closeModal,
              })
            }
          >
            Delete
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="btn text-secondary border border-dark cm_btn3"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteInvestmentModal;

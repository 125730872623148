import React from "react";
import { Modal as AntModal } from "antd";
import { Modal } from "react-bootstrap";
import { handleSubmit } from "./CourierConstants";

const DeleteCourierModal = (props) => {
  const {
    show,
    handleClose,
    dispatch,
    getCourierList,
    dispatchToAction,
    courier_id,
  } = props;

  return (
    <AntModal visible={show} onCancel={handleClose} centered footer={false}>
      <Modal.Header>
        <p className="mb-4 cm_bold cnfrm_msg">DELETE COURIER DETAIL</p>
      </Modal.Header>
      <Modal.Body className="text-center confirmation">
        <div className="text-center confirmation">
          <p className="mb-4 cm_bold cnfrm_msg">
            Are you sure you want to delete the data?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div className="submit_button_margin">
          <button
            className="btn btn-danger cm_btn3"
            onClick={(e) => {
              handleSubmit({
                e: e,
                dispatch: dispatch,
                actionType: "delete",
                getCourierList: getCourierList,
                dispatchToAction: dispatchToAction,
                courier_id: courier_id,
              });
            }}
          >
            Delete
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-primary cm_btn2"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </AntModal>
  );
};

export default DeleteCourierModal;

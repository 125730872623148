import React, { useEffect, useMemo, useReducer, useState } from 'react'
import Pagination from 'react-js-pagination'
import CONSTANTS, { officeLocationValue } from '../../config/constants/Constants'
import { Toastify, currentMonth, reducer } from '../helper/Constant'
import GenerateSalaryModal from './salaryModal/GenerateSalaryModal'
import AddArrearModal from './salaryModal/AddArrearModal'
import AddBonusModal from './salaryModal/AddBonusModal'
import { arrearFormValidation, bonusFormValidation, employeeListValidation, formValidation, onHoldEmployeesFormValidation, reimbursementFormValidation } from './SalaryValidation'
import { combineArraysByKey } from './SalaryFunctions'
import CombineList from './CombineList'
import { exportEmployeesSalaryAction } from '../../redux/actions/exportEmployeesSalaryAction'
import { useDispatch } from 'react-redux'
import { encryptRequestData } from './encryptionAndDecryption'
import AddReimbursement from './salaryModal/AddReimbursement'
import { exportEmployeesReimbrsementAction } from '../../redux/actions/exportEmployeesReimbursementAction'
import { currentYear } from '../leaves/Constants'
import "./salary.css"
import { employeePFValue } from './salaryConstants'
import _ from 'lodash'
import EmployeeOnHoldModal from './salaryModal/EmployeeOnHoldModal'
import addEmployeeOnHoldAction from '../../redux/actions/addEmployeeOnHoldAction'
import moment from 'moment'
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const type = "typeCheckSet"
const initialState = {
    isHovered: false,
    index: "",
    openMarkLwpModal: false,
    openAddArrerModal: false,
    openBonusModal: false,
    miniLoader: false,
    addEmployeeList: [],
    lwpEmployee: [],
    arrearEmployees: [],
    bonusEmployees: [],
    reimbursementEmployees: [],
    showCombineList: false,
    resMsg: false,
    successMessage: "",
    resFailMsg: false,
    responseMessage: "",
    openReimbursementModal: false,
    listgenerationMonth: currentMonth,
    listgenerationYear: currentYear,
    addEmployeeErrorMessage: "",
    openEmployeeOnHoldModal: false,
    onHoldEmployees: [],
    opendeleteOnHoldModal: 0,
    executeFunctionName: ""
}
export default function EmployeeSalaryDetail(props) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const [data, setData] = useState([])
    const dispatchToAction = useDispatch()
    const {
        isHovered,
        index,
        openMarkLwpModal,
        miniLoader,
        openAddArrerModal,
        openBonusModal,
        addEmployeeList,
        lwpEmployee,
        arrearEmployees,
        bonusEmployees,
        showCombineList,
        resMsg,
        resFailMsg,
        responseMessage,
        successMessage,
        openReimbursementModal,
        reimbursementEmployees,
        listgenerationMonth,
        listgenerationYear,
        addEmployeeErrorMessage,
        openEmployeeOnHoldModal,
        onHoldEmployees,
        opendeleteOnHoldModal,
        executeFunctionName,
    } = state

    let { employeesCtcList, pageNo, count, dataCount, handlePageChange, handleSort, sortType, sortField, handleEditSalaryPopup, list } = props

    const handleOnMouseEnterEvent = (i) => {
        dispatch({
            type: type, payload: {
                isHovered: true,
                index: i
            }
        })
    }

    const handleOnMouseOutEvent = () => {
        dispatch({
            type: type, payload: {
                isHovered: false,
                index: ""
            }
        })
    }
    const handleMarkLwpModal = () => {
        dispatch({
            type: type, payload: {
                openMarkLwpModal: true
            }
        })
    }
    const handleReimbursementModal = () => {
        dispatch({
            type: type, payload: {
                openReimbursementModal: true
            }
        })
    }

    const handleEmployeeOnHoldModal = () => {
        dispatch({
            type: type, payload: {
                openEmployeeOnHoldModal: true
            }
        })
    }
    const handleClose = () => {
        dispatch({
            type: type, payload: {
                openMarkLwpModal: false,
                openReimbursementModal: false,
                openAddArrerModal: true,
                miniLoader: false,
                addEmployeeList: [],
                addEmployeeErrorMessage: ""
            }
        })
    }
    const handleArrearClose = () => {

        dispatch({
            type: type, payload: {
                openAddArrerModal: false,
                miniLoader: false,
                openBonusModal: true,
                addEmployeeList: [],
                addEmployeeErrorMessage: ""
            }
        })
    }

    const handleBonusClose = () => {
        dispatch({
            type: type, payload: {
                openAddArrerModal: false,
                miniLoader: false,
                openBonusModal: false,
                addEmployeeList: [],
                bonusEmployees: [],
                addEmployeeErrorMessage: "",
                lwpEmployee: [],
                arrearEmployees: [],
                showCombineList: false,
            }
        })
    }

    const handleEmployeeOnHoldClose = () => {
        dispatch({
            type: type, payload: {
                openEmployeeOnHoldModal: false,
                miniLoader: false,
                openBonusModal: false,
                addEmployeeList: [],
                onHoldEmployees: [],
                addEmployeeErrorMessage: "",
            }
        })
    }
    const handleReimbursementClose = () => {
        dispatch({
            type: type, payload: {
                openReimbursementModal: false,
                miniLoader: false,
                openBonusModal: false,
                addEmployeeList: [],
                reimbursementEmployees: [],
                addEmployeeErrorMessage: ""
            }
        })
    }

    const handleSubmit = () => {
        if (
            formValidation({
                lwpEmployee: lwpEmployee,
                type: type,
                dispatch: dispatch,
            })
        ) {
            dispatch({
                type: type, payload: {
                    openAddArrerModal: true,
                    openMarkLwpModal: false,
                    addEmployeeList: [],
                    addEmployeeErrorMessage: ""
                }
            })
        }
    }

    const handleArrearSubmit = () => {
        if (
            arrearFormValidation({
                arrearEmployees: arrearEmployees,
                type: type,
                dispatch: dispatch,
            })
        ) {
            const combinedArray = combineArraysByKey(lwpEmployee, arrearEmployees, bonusEmployees, "employeeId");
            dispatch({
                type: type, payload: {
                    openAddArrerModal: false,
                    openMarkLwpModal: false,
                    openBonusModal: true,
                    addEmployeeList: [],
                    arrearEmployees: combinedArray,
                    addEmployeeErrorMessage: ""
                }
            })
        }
    }
    const handleBonusSubmit = () => {
        if (
            bonusFormValidation({
                bonusEmployees: bonusEmployees,
                type: type,
                dispatch: dispatch,
            })
        ) {
            dispatch({
                type: type, payload: {
                    miniLoader: true
                }
            })
            const combinedArray = combineArraysByKey(lwpEmployee, arrearEmployees, bonusEmployees, "employeeId");
            if (combinedArray.length <= 0) {
                handleExportSalary();
            }
            else {
                dispatch({
                    type: type, payload: {
                        openAddArrerModal: false,
                        openMarkLwpModal: false,
                        openBonusModal: false,
                        addEmployeeList: [],
                        bonusEmployees: combinedArray,
                        showCombineList: true,
                        month: listgenerationMonth,
                        year: listgenerationYear,
                        addEmployeeErrorMessage: "",
                    }
                })
            }
        }
    }
    const handleReimbursementSubmit = () => {
        if (
            reimbursementFormValidation({
                reimbursementEmployees: reimbursementEmployees,
                type: type,
                dispatch: dispatch,
            })
        ) {
            dispatch({
                type: type, payload: {
                    miniLoader: true
                }
            })
            const combinedArray = combineArraysByKey(reimbursementEmployees, [], [], "employeeId");
            const params = encryptRequestData({
                reimbursementEmployees: combinedArray,
                month: listgenerationMonth,
                year: listgenerationYear,
                officeLocation: parseInt(props?.officeLocation)
            })
            dispatchToAction(exportEmployeesReimbrsementAction({
                useDispatch: dispatch,
                params: params,
                handleClose: handleReimbursementClose,
            }))
        }
    }


    const handleOnHoldSubmitSubmit = () => {
        if (
            onHoldEmployeesFormValidation({
                onHoldEmployees: onHoldEmployees,
                type: type,
                dispatch: dispatch,
            })
        ) {
            dispatch({
                type: type, payload: {
                    miniLoader: true
                }
            })
            const combinedArray = combineArraysByKey(onHoldEmployees, [], [], "employeeId");
            const params =
                encryptRequestData({
                    genData: combinedArray
                })
            dispatchToAction(addEmployeeOnHoldAction({
                useDispatch: dispatch,
                params: params,
                handleClose: handleEmployeeOnHoldClose,
            }))
        }
    }



    const handleSelectEmployeeChange = async (e, name) => {
        dispatch({
            type: type, payload: {
                addEmployeeList: e,
                addEmployeeErrorMessage: "",
                executeFunctionName: name
            }
        })
        setData(e)

    }


    const handlegenerateLwpEmployeeList = () => {
        // if (employeeListValidation({
        //     type: type,
        //     dispatch: dispatch,
        //     addEmployeeList: addEmployeeList,
        //     assignList: lwpEmployee
        // })) {
        // let c = []
        // if (lwpEmployee.length <= 0) {
        //     c = addEmployeeList.map((data) => ({ employeeId: data, numberOfLwp: 0 }))
        //     dispatch({
        //         type: type, payload: {
        //             lwpEmployee: c
        //         },
        //     })
        // }
        // else {
        //     let filteredEmployees = lwpEmployee.filter(obj => addEmployeeList.includes(obj.value));
        //     addEmployeeList.forEach(empId => {
        //         if (!filteredEmployees.some(obj => obj.employeeId == empId)) {
        //             filteredEmployees.push({ employeeId: empId, numberOfLwp: 0 })
        //         }
        //     })
        //     dispatch({
        //         type: type, payload: {
        //             lwpEmployee: filteredEmployees
        //         },
        //     })
        // }
        let updatedEmployees = [];
        lwpEmployee.forEach(employee => {
            const matchingAddEmployee = addEmployeeList.find(empId => empId === employee.employeeId);
            if (matchingAddEmployee) {
                updatedEmployees.push(employee);
            }
        });
        addEmployeeList.forEach(empId => {
            const existingEmployee = updatedEmployees.find(employee => employee.employeeId === empId);
            if (!existingEmployee) {
                updatedEmployees.push({ employeeId: empId, numberOfLwp: 0 });
            }
        });

        dispatch({
            type: type,
            payload: {
                lwpEmployee: updatedEmployees
            },
        });
        // }
    }

    const handlegenerateArrearEmployeeList = () => {
        // if (employeeListValidation({
        //     type: type,
        //     dispatch: dispatch,
        //     addEmployeeList: addEmployeeList,
        //     assignList: arrearEmployees
        // })) {
        // let c = []
        // if (arrearEmployees.length <= 0) {
        //     c = addEmployeeList.map((data) => ({ employeeId: data, arrear: 0, }))
        //     dispatch({
        //         type: type, payload: {
        //             arrearEmployees: c
        //         },
        //     })
        // }
        // else {
        //     let filteredEmployees = arrearEmployees.filter(obj => addEmployeeList.includes(obj.value));
        //     addEmployeeList.forEach(empId => {
        //         if (!filteredEmployees.some(obj => obj.employeeId == empId)) {
        //             filteredEmployees.push({ employeeId: empId, arrear: 0 })
        //         }
        //     })
        //     dispatch({
        //         type: type, payload: {
        //             arrearEmployees: filteredEmployees
        //         },
        //     })
        // }
        let updatedEmployees = [];
        arrearEmployees.forEach(employee => {
            const matchingAddEmployee = addEmployeeList.find(empId => empId === employee.employeeId);
            if (matchingAddEmployee) {
                updatedEmployees.push(employee);
            }
        });
        addEmployeeList.forEach(empId => {
            const existingEmployee = updatedEmployees.find(employee => employee.employeeId === empId);
            if (!existingEmployee) {
                updatedEmployees.push({ employeeId: empId, arrear: 0 });
            }
        });

        dispatch({
            type: type,
            payload: {
                arrearEmployees: updatedEmployees
            },
        });
        // }
    }

    const handlegenerateBonusEmployeeList = () => {
        // if (employeeListValidation({
        //     type: type,
        //     dispatch: dispatch,
        //     addEmployeeList: addEmployeeList,
        //     assignList: bonusEmployees
        // })) {
        // let c = []
        // if (bonusEmployees.length <= 0) {
        //     c = addEmployeeList.map((data) => ({ employeeId: data, bonus: 0 }))
        //     dispatch({
        //         type: type, payload: {
        //             bonusEmployees: c
        //         },
        //     })
        // }
        // else {
        //     let filteredEmployees = bonusEmployees.filter(obj => addEmployeeList.includes(obj.value));
        //     addEmployeeList.forEach(empId => {
        //         if (!filteredEmployees.some(obj => obj.employeeId == empId)) {
        //             filteredEmployees.push({ employeeId: empId, bonus: 0 })
        //         }
        //     })
        //     dispatch({
        //         type: type, payload: {
        //             bonusEmployees: filteredEmployees
        //         },
        //     })
        // }
        let updatedEmployees = [];
        bonusEmployees.forEach(employee => {
            const matchingAddEmployee = addEmployeeList.find(empId => empId === employee.employeeId);
            if (matchingAddEmployee) {
                updatedEmployees.push(employee);
            }
        });
        addEmployeeList.forEach(empId => {
            const existingEmployee = updatedEmployees.find(employee => employee.employeeId === empId);
            if (!existingEmployee) {
                updatedEmployees.push({ employeeId: empId, bonus: 0 });
            }
        });

        dispatch({
            type: type,
            payload: {
                bonusEmployees: updatedEmployees
            },
        });
        // }
    }

    // const handlegenerateReimbursementEmployeeList = () => {
    //     if (employeeListValidation({
    //         type: type,
    //         dispatch: dispatch,
    //         addEmployeeList: addEmployeeList,
    //         assignList: reimbursementEmployees
    //     })) {
    //         let c = []
    //         if (addEmployeeList.length > 0) {
    //             if (reimbursementEmployees.length <= 0) {
    //                 c = addEmployeeList.map((data) => ({ employeeId: data, reimbursement: 0, expenseDescription: "" }))
    //                 dispatch({
    //                     type: type, payload: {
    //                         reimbursementEmployees: c
    //                     },
    //                 })
    //             }
    //             else {
    //                 console.log(reimbursementEmployees,"reimbursement")
    //                 let filteredEmployees = reimbursementEmployees.filter(obj => addEmployeeList.includes(obj.value));
    //                 console.log("filteredEmploe", filteredEmployees)
    //                 addEmployeeList.forEach(empId => {
    //                     if (!filteredEmployees.some(obj => obj.employeeId == empId)) {
    //                         filteredEmployees.push({ employeeId: empId, reimbursement: 0, expenseDescription: "" })
    //                     }
    //                 })
    //                 dispatch({
    //                     type: type, payload: {
    //                         reimbursementEmployees: filteredEmployees
    //                     },
    //                 })
    //             }
    //         }
    //         else {
    //             dispatch({
    //                 type: type, payload: {
    //                     reimbursementEmployees: []
    //                 },
    //             })
    //         }
    //     }
    // }
    const handlegenerateReimbursementEmployeeList = () => {
        // if (employeeListValidation({
        //     type: type,
        //     dispatch: dispatch,
        //     addEmployeeList: addEmployeeList,
        //     assignList: reimbursementEmployees
        // })) {
        let updatedEmployees = [];
        reimbursementEmployees.forEach(employee => {
            const matchingAddEmployee = addEmployeeList.find(empId => empId === employee.employeeId);
            if (matchingAddEmployee) {
                updatedEmployees.push(employee);
            }
        });
        addEmployeeList.forEach(empId => {
            const existingEmployee = updatedEmployees.find(employee => employee.employeeId === empId);
            if (!existingEmployee) {
                updatedEmployees.push({ employeeId: empId, reimbursement: 0, expenseDescription: "" });
            }
        });

        dispatch({
            type: type,
            payload: {
                reimbursementEmployees: updatedEmployees
            },
        });
        // }
    }



    const handlegenerateOnHoldEmployeeList = () => {
        // if (employeeListValidation({
        //     type: type,
        //     dispatch: dispatch,
        //     addEmployeeList: addEmployeeList,
        //     assignList: onHoldEmployees

        // })) {
        // let c = []
        // if (addEmployeeList.length > 0) {
        //     if (onHoldEmployees.length <= 0) {
        //         c = addEmployeeList.map((data) => ({ employeeId: data, releaseDate: 0 }))
        //         dispatch({
        //             type: type, payload: {
        //                 onHoldEmployees: c
        //             },
        //         })
        //     }
        //     else {
        //         let filteredEmployees = onHoldEmployees.filter(obj => addEmployeeList.includes(obj.value));
        //         addEmployeeList.forEach(empId => {
        //             if (!filteredEmployees.some(obj => obj.employeeId == empId)) {
        //                 filteredEmployees.push({ employeeId: empId, releaseDate: 0 })
        //             }
        //         })
        //         dispatch({
        //             type: type, payload: {
        //                 onHoldEmployees: filteredEmployees
        //             },
        //         })
        //     }
        // }
        // else {
        //     dispatch({
        //         type: type, payload: {
        //             onHoldEmployees: []
        //         },
        //     })
        // }


        let updatedEmployees = [];
        onHoldEmployees.forEach(employee => {
            const matchingAddEmployee = addEmployeeList.find(empId => empId === employee.employeeId);
            if (matchingAddEmployee) {
                updatedEmployees.push(employee);
            }
        });
        addEmployeeList.forEach(empId => {
            const existingEmployee = updatedEmployees.find(employee => employee.employeeId === empId);
            if (!existingEmployee) {
                updatedEmployees.push({ employeeId: empId, releaseDate: 0 });
            }
        });

        dispatch({
            type: type,
            payload: {
                onHoldEmployees: updatedEmployees
            },
        });
        // }
    }



    let handleValueChange = (i, e, list, name) => {
        let newFormValues = [...list];
        if (name == "releaseDate") {
            const inputDate = moment(e, 'ddd MMM DD YYYY HH:mm:ss Z');
            const convertedDate = inputDate.utcOffset('+0530').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            newFormValues[i][name] = convertedDate;
        }
        else {
            if (e.target.value == "-" || e.target.value == "+") { }
            else {
                newFormValues[i][e.target.name] = e.target.value;
            }
        }
        dispatch({
            type: type, payload: {
                list: newFormValues
            },
        })
        for (let index = 0; index < newFormValues.length; index++) {
            newFormValues[index].reimbursementCheck = ""
            newFormValues[index].bonusCheck = ""
            newFormValues[index].arrearCheck = ""
            newFormValues[index].numberOfLwpCheck = ""
            newFormValues[index].onHoldDaysCheck = ""
            newFormValues[index].releaseDateCheck = ""
        }
    }
    const handleExportSalary = () => {
        const params = encryptRequestData({
            bonusEmployees,
            month: listgenerationMonth,
            year: listgenerationYear,
            officeLocation: parseInt(props?.officeLocation)
        })
        dispatchToAction(exportEmployeesSalaryAction({
            useDispatch: dispatch,
            params: params,
            handleBonusClose: handleBonusClose,
        }))
    }
    const handleMonthSelect = (e) => {
        dispatch({
            type: type, payload: {
                listgenerationMonth: e
            }
        })
    }

    const handleYearSelect = (e) => {
        dispatch({
            type: type, payload: {
                listgenerationYear: e
            }
        })
    }

    useMemo(() => {
        if (executeFunctionName == "addRe") {
            handlegenerateReimbursementEmployeeList()
        }
        else if (executeFunctionName == "lwp") {
            handlegenerateLwpEmployeeList()
        }
        else if (executeFunctionName == "addAr") {
            handlegenerateArrearEmployeeList()
        }
        else if (executeFunctionName == "addBo") {
            handlegenerateBonusEmployeeList()
        }
        else if (executeFunctionName == "addOh") {
            handlegenerateOnHoldEmployeeList()
        }
    }, [data]);
    return (
        <div>
            <Toastify
                resMsg={resMsg}
                resMsgFail={resFailMsg}
                text={successMessage}
                responseMessage={responseMessage}
            />

            {showCombineList ? "" :
                <div>
                    {/* {employeesCtcList && employeesCtcList.length > 0 ? ( */}
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="white bg_blue">
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Employee ID
                                        <i className={
                                            sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "mobId"
                                                ? "fa fa-sort-numeric-asc ml-2"
                                                : "fa fa-sort-numeric-desc ml-2"
                                        } onClick={() => handleSort("mobId")}></i>
                                    </th>
                                    <th scope='col'>
                                        Name
                                        <i className={
                                            sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "employeeName"
                                                ? "fa fa-sort-alpha-asc ml-2"
                                                : "fa fa-sort-alpha-desc ml-2"
                                        } onClick={() => handleSort("employeeName")}></i>
                                    </th>
                                    <th scope="col">E-mail</th>
                                    <th scope="col">Office Location</th>
                                    <th scope="col">Account Number</th>
                                    <th scope="col">UAN Number</th>
                                    <th scope="col">Pan Card Number</th>
                                    <th scope="col">IFSC Code</th>
                                    <th scope='col'>CTC</th>
                                    <th scope='col'>PF Opt</th>
                                    <th scope='col'>TDS</th>
                                    <th scope='col'>Loan</th>
                                    <th scope='col'>CTC Variable</th>
                                    <th scope='col'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!_.isEmpty(employeesCtcList) ? employeesCtcList.map((data, i) => (
                                    <tr key={data.empId}>
                                        <td><span className="cm_col">{i + 1 + ((pageNo - 1) * 10)}</span></td>
                                        <td><span className="cm_col"> {data?.empId}</span></td>
                                        <td><span className="cm_col">{data?.firstName + " " + data?.middleName + " " + data?.lastName}</span></td>
                                        <td><span className="cm_col">{data?.email}</span></td>
                                        <td><span className="cm_col">{officeLocationValue(data?.officeLocation)}</span></td>
                                        <td><span className="cm_col">{data?.accountNumber}</span></td>
                                        <td><span className="cm_col">{data?.UAN}</span></td>
                                        <td><span className="cm_col">{data?.panCard}</span></td>
                                        <td><span className="cm_col">{data?.ifscCode}</span></td>
                                        <td>
                                            <span className="cm_col" onMouseEnter={() => handleOnMouseEnterEvent(i)} onMouseOut={() => handleOnMouseOutEvent()}>{isHovered && index == i ? data.ctc : "*******"}</span>
                                        </td>
                                        <td><span className="cm_col">{employeePFValue(data?.isAvailedPF)}</span></td>
                                        <td><span className="cm_col">{data?.tds}</span></td>
                                        <td><span className="cm_col">{data?.loan}</span></td>
                                        <td><span className='cm_col'>{data?.ctcVariable}</span></td>
                                        <td>
                                            <i className="fa fa-pencil mr-4 ml-2" aria-hidden="true" title="Edit Salary" onClick={() => handleEditSalaryPopup(data)}></i>
                                        </td>
                                    </tr>
                                )) : ""}
                            </tbody>
                        </table>
                        {employeesCtcList && employeesCtcList.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                    </div>

                    {dataCount > 10 ? (
                        <Pagination
                            activePage={+(pageNo)}
                            itemsCountPerPage={count}
                            totalItemsCount={dataCount}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange}
                        />
                    ) : (
                        ""
                    )}{employeesCtcList.length <= 0 ? "" :
                        <div className='salary-generation-button mt-4 mb-2'>
                            <button className='btn btn-primary add1' onClick={() => handleMarkLwpModal()}> Generate Salary Slip</button>
                            <button className='btn btn-primary add1' onClick={() => handleReimbursementModal()}> Generate Reimbursement Excel</button>
                            <button className='btn btn-primary add1' onClick={() => handleEmployeeOnHoldModal()}> On Hold Employee</button>
                        </div>}
                </div>}
            <GenerateSalaryModal
                addEmployeeErrorMessage={addEmployeeErrorMessage}
                employeeList={list}
                addEmployeeList={addEmployeeList}
                lwpEmployee={lwpEmployee}
                handleClose={handleClose}
                miniLoader={miniLoader}
                show={openMarkLwpModal}
                handleSubmit={handleSubmit}
                handleLwpChange={handleValueChange}
                handleSelectEmployeeChange={handleSelectEmployeeChange}
                handlegenerateLwpEmployeeList={handlegenerateLwpEmployeeList}
            />
            <AddArrearModal
                addEmployeeErrorMessage={addEmployeeErrorMessage}
                employeeList={list}
                addEmployeeList={addEmployeeList}
                arrearEmployees={arrearEmployees}
                show={openAddArrerModal}
                miniLoader={miniLoader}
                handleSubmit={handleArrearSubmit}
                handleArrearChange={handleValueChange}
                handleSelectEmployeeChange={handleSelectEmployeeChange}
                handlegenerateArrearEmployeeList={handlegenerateArrearEmployeeList}
                handleClose={handleArrearClose}
            />
            <AddBonusModal
                addEmployeeErrorMessage={addEmployeeErrorMessage}
                show={openBonusModal}
                employeeList={list}
                addEmployeeList={addEmployeeList}
                miniLoader={miniLoader}
                bonusEmployees={bonusEmployees}
                handleSelectEmployeeChange={handleSelectEmployeeChange}
                handleSubmit={handleBonusSubmit}
                handleBonusChange={handleValueChange}
                handleClose={handleBonusClose}
                handlegenerateBonusEmployeeList={handlegenerateBonusEmployeeList}
                listgenerationMonth={listgenerationMonth}
                listgenerationYear={listgenerationYear}
                handleMonthSelect={handleMonthSelect}
                handleYearSelect={handleYearSelect}
            />
            <AddReimbursement
                show={openReimbursementModal}
                addEmployeeErrorMessage={addEmployeeErrorMessage}
                employeeList={list}
                addEmployeeList={addEmployeeList}
                miniLoader={miniLoader}
                reimbursementEmployees={reimbursementEmployees}
                handleSelectEmployeeChange={handleSelectEmployeeChange}
                handleClose={handleReimbursementClose}
                handleReimbursementChange={handleValueChange}
                handleSubmit={handleReimbursementSubmit}
                handlegenerateReimbursementEmployeeList={handlegenerateReimbursementEmployeeList}

                listgenerationMonth={listgenerationMonth}
                listgenerationYear={listgenerationYear}
                handleMonthSelect={handleMonthSelect}
                handleYearSelect={handleYearSelect}
            />
            <EmployeeOnHoldModal
                show={openEmployeeOnHoldModal}
                addEmployeeErrorMessage={addEmployeeErrorMessage}
                employeeList={list}
                addEmployeeList={addEmployeeList}
                miniLoader={miniLoader}
                onHoldEmployees={onHoldEmployees}
                handleSelectEmployeeChange={handleSelectEmployeeChange}
                handleClose={handleEmployeeOnHoldClose}
                handleOnholdValueChange={handleValueChange}
                handleSubmit={handleOnHoldSubmitSubmit}
                listgenerationMonth={listgenerationMonth}
                listgenerationYear={listgenerationYear}
                handleMonthSelect={handleMonthSelect}
                handlegenerateOnHoldEmployeeList={handlegenerateOnHoldEmployeeList}
                handleYearSelect={handleYearSelect}
            />
            {showCombineList ?
                <CombineList
                    employeeList={list}
                    bonusEmployees={bonusEmployees}
                    reimbursementEmployees={reimbursementEmployees}
                    handleExportSalary={handleExportSalary}
                /> : ""}
        </div>
    )
}

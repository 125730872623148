import {
  DELETE_EMPLOYEE_RESUME_REQUEST,
  DELETE_EMPLOYEE_RESUME_SUCCESS,
  DELETE_EMPLOYEE_RESUME_FAILURE,
} from "./actionType";

import { deleteEmployeeResume } from "../../config/services/employeeServices";
import { getResumeByEmpId } from ".";
const deleteEmpResumeByAdmin = (resumeId, empId, closeModal) => {
  return (dispatch) => {
    let params = `employeeId=${empId}&pageNo=${0}&count=${10}`

    dispatch(deleteEmpResumeByAdminRequest());
    deleteEmployeeResume(resumeId)
      .then((res) => {
        if (res.data.statusCode !== 1) {
          dispatch(deleteEmpResumeByAdminFailure(res.data));
        } else {
          dispatch(deleteEmpResumeByAdminSuccess(res.data));
          dispatch(getResumeByEmpId(params))
          closeModal()
        }
      })
      .catch((error) => {
        dispatch(deleteEmpResumeByAdminFailure(error));
      });
  };
};

const deleteEmpResumeByAdminRequest = () => {
  return {
    type: DELETE_EMPLOYEE_RESUME_REQUEST,
  };
};
const deleteEmpResumeByAdminSuccess = (data) => {
  return {
    type: DELETE_EMPLOYEE_RESUME_SUCCESS,
    payload: data,
  };
};
const deleteEmpResumeByAdminFailure = (error) => {
  return {
    type: DELETE_EMPLOYEE_RESUME_FAILURE,
    payload: error,
  };
};
export {
  deleteEmpResumeByAdmin,
  deleteEmpResumeByAdminRequest,
  deleteEmpResumeByAdminSuccess,
  deleteEmpResumeByAdminFailure,
};

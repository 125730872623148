import React from "react";
import { Modal } from "react-bootstrap";

const EditTempCompOff = (props) => {
  const {
    show,
    handleClose,
    numberOfCompOff,
    handleCompOffDayChange,
    numberOfCompOffErrMsg,
    handleSubmit,
    isDelete,
  } = props;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>{isDelete ? "DELETE" : "EDIT"} TEMPORARY COMP OFF</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirmation">
        {isDelete && (
          <h5 className="mb-4 cm_bold cnfrm_msg d-flex justify-content-center">
            Enter number of days you want to delete
          </h5>
        )}
        <input
          className="form-control cm_box"
          type="number"
          step="any"
          placeholder=" Enter no. of day"
          name="CompLeave"
          value={numberOfCompOff}
          onChange={handleCompOffDayChange}
        />

        {numberOfCompOffErrMsg ? (
          <span className="error_msg ml-2">{numberOfCompOffErrMsg}</span>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="text-center mt-5">
          <button
            className={`btn mr-2 btn-sm cm_btn3 ${
              isDelete ? "btn-danger" : "btn-primary"
            }`}
            onClick={handleSubmit}
          >
            {isDelete ? "Delete" : "Edit"}
          </button>
          <button
            className="btn btn-primary cm_btn2  mr-2 btn-sm cm_btn3"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTempCompOff;

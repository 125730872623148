import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Select, Modal as AntModal } from "antd";
import { CONSTANTS } from "../../config";
import ResponseFilter from "../../ResponseFilter";
import {
  getEmployeeReimbursement,
  reimburseHistoryRecordByManager,
} from "../../config/services/employeeServices";
import { Loader } from "../svg";
import { colorType } from "./ReimbursementConstant";
const { Option } = Select;

const appConstants = CONSTANTS.TRANSACTION_LIST_CONSTANTS.ARRAY_OF_MONTHS;

const arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i < 4; i++) {
  arrayOfYear.push(currentYear - i);
}

class Reimbursement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expenseDescription: "",
      reimbursementList: [],
      searchYear: currentYear,
      loader: true,
      rejected: "",
      pending: "",
      approved: "",
      month: "",
      year: "",
      pageNo: 1,
      count: 10,
      openRembInfoPopup: false,
      claimReimbursementMessage: false,
      reimbursementListAsManager: [],
      resMessage: localStorage.getItem("claimReimbursementMessage"),
      reimbursementType: "",
    };
  }

  componentDidMount() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var pageNo = url.searchParams.get("pageNo");
    var searchYear = url.searchParams.get("searchYear");
    let reimbursementType = url.searchParams.get("reimbursementType");
    this.setState(
      {
        pageNo: pageNo ? +pageNo : 1,
        searchYear: searchYear ? searchYear : currentYear,
        reimbursementType: reimbursementType ? reimbursementType : "",
      },
      () => {
        this.getReimbursement();
        this.getReimbursementAsManager();
      }
    );

    setTimeout(() => {
      this.setState({ resMessage: false });
      localStorage.removeItem("claimReimbursementMessage");
    }, 4000);
  }

  getReimbursementAsManager = (year) => {
    let { searchYear, pageNo, count } = this.state;
    if (typeof year !== "undefined") {
      searchYear = year;
    }
    let params = `searchYear=${searchYear}&count=${count}&pageNo=${pageNo - 1}`;

    reimburseHistoryRecordByManager(params).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState(
          {
            reimbursementListAsManager: resData.reimbursementList,
          },
          () => {
            this.setState({ loader: false });
          }
        );
      } else if (res.data.statusCode === 0) {
        this.setState({ loader: false });
        this.closeModal();
      }
    });
  };

  getReimbursement = (year) => {
    let { searchYear, pageNo, count } = this.state;
    if (typeof year !== "undefined") {
      searchYear = year;
    }
    let params = `searchYear=${searchYear}&count=${count}&pageNo=${pageNo - 1}`;
    this.props.history.push(
      `reimbursement?searchYear=${searchYear}&pageNo=${pageNo}`
    );
    getEmployeeReimbursement(params).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState(
          {
            reimbursementList: resData.reimbursementList,
          },
          () => {
            this.setState({ loader: false });
          }
        );
      } else if (res.data.statusCode === 0) {
        this.setState({ loader: false });
        this.closeModal();
      }
    });
  };
  handleSelectChange = (e) =>
    this.setState({ year: e, searchYear: e }, () => {
      this.getReimbursement(this.state.year);
      this.getReimbursementAsManager(this.state.year);
    });
  handleOpenRembInfoPopup = (data) => {
    let { expenseDescription, reimbursementId } = data;
    this.setState({
      openRembInfoPopup: !this.state.openRembInfoPopup,
      expenseDescription,
      reimbursementId,
    });
  };
  closeModal = () =>
    this.setState({
      openRembInfoPopup: false,
    });
  calcAmountClaimed = (data) => {
    let amountClaimed = 0;
    data.map((item) => console.log(item.amount));
    return amountClaimed;
  };
  calcAmountApproved = (data) => {
    let amountApproved = 0;
    data.map((item) =>
      item.status === 2 ? (amountApproved += item.amount) : null
    );
    return amountApproved;
  };
  compensentoryLeaveDetailShow = (value) => {
    this.setState({ claimReimbursementMessage: true });
  };

  compensentoryLeaveDetailHide = (value) => {
    this.setState({ claimReimbursementMessage: false });
  };
  handleManagerPage = () => {
    window.location.href = "/reimbursement/manager-reimbursement";
  };

  handleChangeReimbursementType = (e) => {
    if (e === undefined) {
      this.setState({ reimbursementType: "" }, () => this.getReimbursement());
    } else {
      this.setState({ reimbursementType: e }, () => this.getReimbursement());
    }
  };
  render() {
    let {
      reimbursementList,
      loader,
      openRembInfoPopup,
      expenseDescription,
      reimbursementListAsManager,
      resMessage,
      searchYear,
    } = this.state;

    return (
      <div className="body_container cm_bold">
        {loader && <Loader />}
        {resMessage ? (
          <div className="alert alert-success cm_top_fix">
            Reimbursement Claimed Successfully
          </div>
        ) : (
          ""
        )}
        <h3 className="">REIMBURSEMENT CLAIMS</h3>
        <div className="filters justify-content-end mb-2 align-items-center">
          <Select
            className="select_year_cal "
            value={searchYear ? searchYear : currentYear}
            onChange={this.handleSelectChange}
          >
            {arrayOfYear.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          {/* <div>
            {reimbursementListAsManager.length > 0 ? <button className="btn btn-primary" onClick={() => this.handleManagerPage()}> Request Reimbursement</button> : ""}
          </div> */}
          <div className="re-claim-button">
            <Link
              className="btn btn-primary"
              to="/reimbursement/reimbursement-claim"
            >
              Claim Reimbursement
            </Link>
          </div>
        </div>
        <h5 className="cm_bold">Reimbursement History</h5>{" "}
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th>S.No</th>
                <th>Month</th>
                <th>Reimbursement Claimed</th>
                <th style={{ width: "350px" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(reimbursementList)
                ? reimbursementList.map((data, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/reimbursement/reimbursement-view",
                            state: {
                              reqMonth: data.month.month.month,
                              reqYear: data.month.year.year,
                            },
                          }}
                        >
                          {appConstants[data?.month?.month?.month - 1]}
                        </Link>
                      </td>
                      <td>{data.monthlyReimburseList.length}</td>
                      <td
                        className={colorType(data.status)}
                        style={{ width: "350px" }}
                      >
                        <div className="cm_btn_status">
                          <button
                            type="button"
                            style={{ cursor: "default" }}
                            className="btn btn-success btn-sm mr-2 mb-2 cm_rem_dot"
                          >
                            {data.approved} Approved
                          </button>
                          <button
                            type="button"
                            style={{ cursor: "default" }}
                            className="btn btn-primary btn-sm mr-2 mb-2 cm_rem_dot"
                          >
                            {data.pending} Pending
                          </button>
                          <button
                            type="button"
                            style={{ cursor: "default" }}
                            className="btn btn-danger btn-sm mb-2  cm_rem_dot"
                          >
                            {data.rejected} Rejected
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          {reimbursementList && reimbursementList.length > 0 ? (
            ""
          ) : (
            <h3 className="text-center">No data available</h3>
          )}
        </div>
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRembInfoPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="mb-2 mt-4">EXPENSE DESCRIPTION</h3>
              <div className="cm_overflow_reimbur">
                <p>{expenseDescription}</p>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
      </div>
    );
  }
}
export default Reimbursement;

import _ from "lodash";
const ResponseFilter = response => {
  let path = window.location.href.includes("admin");

  let isAdmin = 1; //1 admin
  //2 emp
  if (response.data.statusCode) {
    isAdmin = response.data.responseData.isAdminz
    if (path && isAdmin === 2) {
      localStorage.clear();
      window.location.href = '/login';
    }
  }
  var status = response.data.statusCode;
  var resData;

  if (status) {
    resData = response.data?.responseData
  }

  else if (response.data?.error?.errorCode === 17 || response.data?.error?.errorCode === 10) {
    localStorage.clear();
    window.location.href = '/login';
  }
  else if (response.data.error.errorCode === 22) {
    localStorage.clear();
    window.location.href = '/login';
  }
  else if (response.data.error.errorCode === 2) {
    localStorage.clear();
    window.location.href = '/login';
  }
  else if (response.data.error.errorCode === 89 || response.data.error.errorCode === 90 || response.data.error.errorCode === 91 || response.data.error.errorCode === 17) {
    localStorage.removeItem('salaryToken');
    localStorage.removeItem('salaryAccessEmail');
  }
  else {
    resData = response.data.error.responseMessage
  }
  return {
    status,
    resData
  }
}
export default ResponseFilter;
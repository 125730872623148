import React from "react";
import { connect } from "react-redux";
import { Loader } from "../svg";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { Select, Modal as AntModal } from "antd";
import ResponseFilter from "../../ResponseFilter";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-js-pagination";

import moment from "moment";

import {
  reimbursementList,
  reimbursementAction,
} from "../../config/services/employeeServices";
import { CONSTANTS } from "../../config";
import { setAdminReimbursementFilterYear } from "../../redux/actions";

let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const { Option } = Select;

let arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i <= 3; i++) {
  arrayOfYear.push(currentYear - i);
}
const arrayOfMonths = CONSTANTS.TRANSACTION_LIST_CONSTANTS.ARRAY_OF_MONTHS;

const ReimbursementTable = ({
  reimbursementList,
  loader,
  sortField,
  sortType,
  handleChange,
  status,
  handleAction,
  count,
  pageNo,
  handleSort,
  year,
}) => (
  <React.Fragment>
    <div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">
                Employee ID
                <i
                  className={
                    sortType === SORT_TYPE_CONSTANTS.Descending &&
                    sortField === "empId"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("empId")}
                ></i>
              </th>
              <th scope="col">
                Name
                <i
                  className={
                    sortType === SORT_TYPE_CONSTANTS.Descending &&
                    sortField === "firstName"
                      ? "fa fa-sort-alpha-desc ml-2"
                      : "fa fa-sort-alpha-asc ml-2"
                  }
                  onClick={() => handleSort("firstName")}
                ></i>
              </th>
              <th>Reimbursement Claimed</th>
              <th scope="col" style={{ width: "260px" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {reimbursementList?.map((reimbursement, i) => (
              <tr>
                <td>{i + 1 + (pageNo - 1) * count}</td>
                <td>
                  <span className="cm_col">{reimbursement.empId}</span>
                </td>

                {status == 4 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      {reimbursement.firstName} {reimbursement.lastName}
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}

                {status == 1 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      {reimbursement.firstName} {reimbursement.lastName}
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}

                {status == 2 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      {reimbursement.firstName} {reimbursement.lastName}
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}

                {status == 3 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      {reimbursement.firstName} {reimbursement.lastName}
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}
                <td>{reimbursement.totalReimbursementForEmployee}</td>
                {status == 4 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      <Link
                        to={{
                          pathname: `/admin-reimbursement/admin-reimbursement-action/${reimbursement.employeeId}`,
                          state: {
                            reqMonth: moment(reimbursement.expenseDate).format(
                              "MM"
                            ),
                            status: "",
                            firstName: reimbursement.firstName,
                            lastName: reimbursement.lastName,
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View
                      </Link>

                      <Link
                        to={{
                          pathname: `/admin-reimbursement/${reimbursement.employeeId}`,
                          state: {
                            status: "",
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View All
                      </Link>
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}

                {/* {status == 1 ? (
                    <td style={{ width: "280px" }}>
                      <div>
                        <span className="yellow inline-block mr-3">
                          Pending
                        </span>

                        <Link
                          to={{
                            pathname: `/admin-reimbursement/${reimbursement.employeeId}`,
                            state: { status: 1, searchYear: year },
                          }}
                          className="btn btn-primary btn-sm ml-2"
                          style={{ width: 110 }}
                        >
                          view
                        </Link>
                      </div>{" "}
                    </td>
                  ) : (
                    ""
                  )} */}
                {status == 1 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      <Link
                        to={{
                          pathname: `/admin-reimbursement/admin-reimbursement-action/${reimbursement.employeeId}`,
                          state: {
                            reqMonth: moment(reimbursement.expenseDate).format(
                              "MM"
                            ),
                            status: 1,
                            firstName: reimbursement.firstName,
                            lastName: reimbursement.lastName,
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View
                      </Link>

                      <Link
                        to={{
                          pathname: `/admin-reimbursement/${reimbursement.employeeId}`,
                          state: {
                            status: 1,
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View All
                      </Link>
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}
                {status == 2 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      <Link
                        to={{
                          pathname: `/admin-reimbursement/admin-reimbursement-action/${reimbursement.employeeId}`,
                          state: {
                            reqMonth: moment(reimbursement.expenseDate).format(
                              "MM"
                            ),
                            status: 2,
                            firstName: reimbursement.firstName,
                            lastName: reimbursement.lastName,
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View
                      </Link>

                      <Link
                        to={{
                          pathname: `/admin-reimbursement/${reimbursement.employeeId}`,
                          state: {
                            status: 2,
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View All
                      </Link>
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}

                {status == 3 ? (
                  <td style={{ width: "280px" }}>
                    <div>
                      <Link
                        to={{
                          pathname: `/admin-reimbursement/admin-reimbursement-action/${reimbursement.employeeId}`,
                          state: {
                            reqMonth: moment(reimbursement.expenseDate).format(
                              "MM"
                            ),
                            status: 3,
                            firstName: reimbursement.firstName,
                            lastName: reimbursement.lastName,
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View
                      </Link>

                      <Link
                        to={{
                          pathname: `/admin-reimbursement/${reimbursement.employeeId}`,
                          state: {
                            status: 3,
                            searchYear: year,
                          },
                        }}
                        className="btn btn-primary btn-sm ml-2"
                        style={{ width: 110, marginBottom: 10 }}
                      >
                        View All
                      </Link>
                    </div>{" "}
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {}
        {reimbursementList && reimbursementList?.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center"> No data available</h3>
        )}
      </div>
    </div>
  </React.Fragment>
);

class AdminReimbursement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseDescription: "",
      reimbursementList: [],
      reimbursementId: "",
      searchDate: "",
      year: new Date().getFullYear(),
      searchMonth: new Date().getMonth(),
      search: "",
      status: props.location.state ? props.location.state : 4,
      sortType: "",
      dataCount: "",
      sortField: "created",
      pending: "",
      rejected: "",
      approved: "",
      openViewPopup: false,
      openRembInfoPopup: false,
      loader: true,
      pageNo: 1,
      count: 10,
      reimbursementType: "",
    };
    this.debounceSearch = null;
  }
  handleDate = (e) => {
    this.setState(
      { searchDate: e ? new Date(e).setHours(6, 30, 0, 0) : "" },
      () => {
        this.getReimbursement();
      }
    );
  };
  handleSearch = (e) => {
    this.setState(
      { search: e.target.value, pageNo: 1 }
      // () =>
      // this.getReimbursement()
    );
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.getReimbursement();
    }, 2000);
  };
  handleSelectChange = () => {
    this.setState({ search: "", pageNo: 1 }, () => this.getReimbursement());
  };
  submitSearch = (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    this.getReimbursement();
  };
  handleSort = (e) => {
    this.setState({ loader: false });
    this.setState(
      {
        sortField: e,
        sortType:
          this.state.sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
      () => this.getReimbursement()
    );
  };
  handleOpenViewPopup = () =>
    this.setState({ openViewPopup: !this.state.openViewPopup });

  handleOpenRembInfoPopup = (reimbursement) => {
    let { expenseDescription, reimbursementId } = reimbursement;
    this.setState({
      openRembInfoPopup: !this.state.openRembInfoPopup,
      expenseDescription,
      reimbursementId,
    });
  };

  handleTabChange = (e) =>
    this.setState({ loader: true, status: e, pageNo: 1 }, () =>
      this.getReimbursement()
    );

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () =>
      this.getReimbursement()
    );
  };

  closeModal = () =>
    this.setState({
      openViewPopup: false,
      openRembInfoPopup: false,
      loader: false,
    });

  downloadExcel = () => {
    let {
      search,
      status,
      sortType,
      sortField,
      year,
      count,
      pageNo,
      searchMonth,
      reimbursementType
    } = this.state;
    let params = `downloadExcel=1&search=${search}&searchYear=${year}&status=${
      status == 4 ? "" : status
    }&sortType=${sortType}&searchMonth=${
      searchMonth + 1
    }&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}&reimburseType=${reimbursementType}`;
    reimbursementList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        window.location.href = resData.excelSheet;
      }
    });
  };

  getReimbursement = () => {
    let {
      search,
      status,
      sortType,
      sortField,
      year,
      count,
      pageNo,
      searchMonth,
      reimbursementType,
    } = this.state;
    let params = `search=${search.trim()}&searchYear=${year}&status=${
      status == 4 ? "" : status
    }&sortType=${sortType}&searchMonth=${
      searchMonth + 1
    }&sortField=${sortField}&count=${count}&pageNo=${
      pageNo - 1
    }&reimburseType=${reimbursementType}`;
    this.props.history.push(
      `admin-reimbursement?search=${search}&sortType=${sortType}&sortField=${sortField}&pageNo=${pageNo}&searchMonth=${searchMonth}&searchYear=${year}&status=${status}&reimburseType=${reimbursementType}`
    );
    reimbursementList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      this.setState(
        {
          reimbursementList: resData.reimbursementList,
          totalReimburse: resData.totalReimburse,
          dataCount: resData.dataCount,
          pending:
            resData.statusByCount !== 0
              ? resData?.statusByCount?.filter((data) => data._id == 1)
              : 0,
          approved:
            resData.statusByCount !== 0
              ? resData?.statusByCount?.filter((data) => data._id == 2)
              : 0,
          rejected:
            resData.statusByCount !== 0
              ? resData?.statusByCount?.filter((data) => data._id == 3)
              : 0,
        },
        () => this.closeModal()
      );
    });
  };
  handleAction = (e, reimbursement) => {
    let params = {
      reimbursementId: reimbursement.reimbursementId,
      status: parseInt(e),
    };

    reimbursementAction(params).then((res) => {
      this.setState({ miniLoader: false }, () => this.getReimbursement());
    });
  };
  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let status1 = url.searchParams.get("status");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    let searchMonth = url.searchParams.get("searchMonth");
    let searchYear = url.searchParams.get("searchYear");
    let reimbursementType = url.searchParams.get("reimbursementType");
    this.setState(
      {
        pageNo: pageNo ? Number(pageNo) : 1,
        search: search ? search : "",
        sortType: sortType ? sortType : "",
        sortField: sortField ? sortField : "",
        searchMonth: searchMonth ? Number(searchMonth) : new Date().getMonth(),
        year: searchYear ? searchYear : new Date().getFullYear(),
        status: status1 ? status1 : this.state.status,
        reimbursementType: reimbursementType ? reimbursementType : "",
      },
      () => {
        this.getReimbursement();
      }
    );
  }

  handleOnSelectChange = (year) => {
    this.props.setAdminReimbursementFilterYear(year);
    this.setState({ year: year ? year : new Date().getFullYear() }, () =>
      this.getReimbursement()
    );
  };
  handleMonthChange = (month, index) => {
    this.setState(
      { searchMonth: index ? parseInt(index.key) : new Date().getMonth() },
      () => this.getReimbursement()
    );
  };

  handleChangeReimbursementType = (e) => {
    if (e === undefined) {
      this.setState({ reimbursementType: "" }, () => this.getReimbursement());
    } else {
      this.setState({ reimbursementType: e }, () => this.getReimbursement());
    }
  };
  render() {
    let {
      loader,
      search,
      expenseDescription,
      dataCount,
      openViewPopup,
      openRembInfoPopup,
      pending,
      approved,
      rejected,
      pageNo,
      count,
      totalReimburse,
      searchMonth,
      searchYear,
      reimbursementList,
      status,
      reimbursementType,
    } = this.state;

    return (
      <div className="body_container cm_bold">
        {loader && <Loader />}
        <h3 className="mb-5">REIMBURSEMENT</h3>

        <div className="row d-flex justify-content-between mb-4">
          <div className="col-md-4 search_device_bar justify-content-end select__date">
            <input
              maxLength={25}
              className="form-control mr-lg-2  cm_box"
              type="search"
              placeholder="Search by name/E.ID"
              aria-label="Search"
              value={search}
              onChange={this.handleSearch}
              // onKeyDown={(e) => { if (e.key === 'Enter' && search !== "#") this.getReimbursement() }}
            />
            {search ? (
              <span className="cm_clear" onClick={this.handleSelectChange}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span className="cm_clear">
                <i className="fa fa-search" aria-hidden="true"></i>
              </span>
            )}
          </div>
        </div>
        <div className="filters justify-content-end mb-5">
          <Select
            style={{ width: "13rem" }}
            value={reimbursementType ? reimbursementType : "Reimbursement Type"}
            defaultValue="<Select Reimbursement Typ>e"
            className="select_year_cal"
            name="reimbursementType"
            allowClear={reimbursementType ? true : false}
            onChange={(e) => this.handleChangeReimbursementType(e)}
          >
            <Option value="1">Regular </Option>
            <Option value="2">Tax Saving</Option>
          </Select>
          <Select
            className="select_year_cal"
            // id="searchByMonthFilter"
            value={
              searchMonth === 0
                ? "January"
                : searchMonth !== 0
                ? arrayOfMonths[searchMonth]
                : searchMonth
            }
            onChange={this.handleMonthChange}
            allowClear={
              searchMonth === 0 ? true : searchMonth !== 0 ? searchMonth : false
            }
          >
            {arrayOfMonths.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>

          <Select
            className="select_year_cal"
            // id="searchByYearFilter"
            value={this.state.year}
            onChange={this.handleOnSelectChange}
            allowClear={this.state.year}
          >
            {arrayOfYear.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          {reimbursementList && reimbursementList?.length > 0 ? (
            <button
              className="btn btn-primary"
              onClick={() => this.downloadExcel()}
            >
              Export Excel
            </button>
          ) : (
            ""
          )}
        </div>

        <Tabs
          activeKey={
            this.props.location?.state
              ? this.props.location.state
              : status
              ? status
              : "4"
          }
          id="uncontrolled-tab-example"
          onSelect={(e) => this.handleTabChange(e)}
        >
          <Tab eventKey="4" title={`All ${totalReimburse}`}>
            <ReimbursementTable
              {...this.state}
              handleSort={this.handleSort}
              handleOpenRembInfoPopup={this.handleOpenRembInfoPopup}
              handleAction={this.handleAction}
            />

            {dataCount > 10 ? (
              <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </Tab>
          <Tab
            eventKey="1"
            title={`Pending ${
              pending[0]?.totalCountByStatus
                ? pending[0]?.totalCountByStatus
                : 0
            }`}
          >
            <ReimbursementTable
              {...this.state}
              handleSort={this.handleSort}
              handleOpenRembInfoPopup={this.handleOpenRembInfoPopup}
              handleAction={this.handleAction}
            />

            {dataCount > 10 ? (
              <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </Tab>

          <Tab
            eventKey="3"
            title={`Rejected ${
              rejected[0]?.totalCountByStatus
                ? rejected[0]?.totalCountByStatus
                : 0
            }`}
          >
            <ReimbursementTable
              {...this.state}
              handleOpenRembInfoPopup={this.handleOpenRembInfoPopup}
              handleSort={this.handleSort}
            />
            {dataCount > 10 ? (
              <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </Tab>

          <Tab
            eventKey="2"
            title={`Approved ${
              approved[0]?.totalCountByStatus
                ? approved[0]?.totalCountByStatus
                : 0
            }`}
          >
            <ReimbursementTable
              {...this.state}
              handleOpenRembInfoPopup={this.handleOpenRembInfoPopup}
              handleSort={this.handleSort}
            />
            {dataCount > 10 ? (
              <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </Tab>
        </Tabs>
        <Modal
          size="md"
          show={openViewPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <form className="view">
              <h3 className="mb-4">JYOTSANA KASHYAP</h3>
              <div className="form-group">
                <label>Date Added</label>
                <input
                  maxLength="50"
                  type="text"
                  className="form-control cm_box mb-4"
                  placeholder="07/10/2019"
                />
              </div>

              <div className="form-group">
                <label>Amount</label>
                <input
                  maxLength="50"
                  type="text"
                  className="form-control cm_box mb-4"
                  placeholder="1000"
                />
              </div>

              <div className="form-group">
                <label>Download Bill</label>
                <div className="cm_box mb-4 text-center cm_bill">
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                  <i className="fa fa-download" aria-hidden="true"></i>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-6">
                  <button type="button" className="btn btn-success">
                    Approve
                  </button>
                </div>

                <div className="col-6">
                  <button className="btn btn-danger">Reject</button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRembInfoPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="mb-2 mt-4">EXPENSE DESCRIPTION</h3>
              <div className="cm_overflow_reimbur">
                <p>{expenseDescription}</p>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    adminReimbursementFilterYear: state.adminReimbursementFilterYear.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAdminReimbursementFilterYear: (year) => {
      dispatch(setAdminReimbursementFilterYear(year));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminReimbursement);

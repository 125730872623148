import React from 'react'
import { Modal } from "react-bootstrap"
import { halfTypeValue, leaveValue } from '../Constants';
import TextEditor from '../../../hoc/textEditor/TextEditor';
import DOMPurify from 'dompurify';
import { ErrorMessage } from '../../helper/Constant';

export function ApproveRejectLeaveModal(props) {
    const {
        leaveMsg,
        show,
        handleClose,
        isApproved,
        leaveType,
        startDate,
        halfOfTheDay,
        endDate,
        replyErrorMsg,
        editorState,
        onEditorStateChange,
        handleAction,
        responseMessage} = props
    function createMarkup() {
        return {
            __html: DOMPurify.sanitize(leaveMsg)
        }
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >
                <Modal.Title><b>LEAVE {isApproved ? "APPROVAL" : "REJECT"}</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="cm_overflow_reason">
                        <div><label><b>Leave Type</b> : {leaveValue(leaveType)}</label></div>

                        <div className="row">
                            <div className="col-6">
                                <label className="mt-4"><b>Start Date</b></label><br />
                                <time>
                                    {new Date(startDate).getDate().toString().padStart(2, '0') + '/' +
                                        (new Date(startDate).getMonth() + 1).toString().padStart(2, '0') + '/' +
                                        new Date(startDate).getFullYear().toString().padStart(2, '0')}
                                </time>

                            </div>
                            {leaveType >= 6 && leaveType <= 10 ?
                                <div className="col-6">
                                    <label className="mt-4"><b>Half Type</b></label><br />
                                    <span>{halfTypeValue(halfOfTheDay)}</span>


                                </div> :
                                <div className="col-6">
                                    <label className="mt-4"><b>End Date</b></label><br />
                                    <time> {new Date(endDate).getDate().toString().padStart(2, '0') + '/' +
                                        (new Date(endDate).getMonth() + 1).toString().padStart(2, '0') + '/' +
                                        new Date(endDate).getFullYear().toString().padStart(2, '0')}</time>

                                </div>}
                            <div className="col-12">
                                <label className="mt-4"><b>Reason</b></label>

                                <div className='leave-reason'
                                    dangerouslySetInnerHTML={createMarkup()}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`form-group col-12 ${replyErrorMsg ? "input_error" : ""
                        }`}>
                        <label className="mt-4"><b>Reply</b></label>
                        <TextEditor
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                        />
                        <ErrorMessage errMsg={replyErrorMsg} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
                        {isApproved ?
                            <button type="button" className="btn btn-success"
                                onClick={() => handleAction(2)}>Approve
                            </button> :
                            <button type="button" className="btn btn-danger"
                                onClick={() => handleAction(3)}>Reject
                            </button>
                        }
                        <button type="button" className='btn btn-primary'
                            onClick={handleClose}>Cancel
                        </button>
                    </div>
                    {responseMessage && <h3 className=" Registration_error mt-5">{responseMessage}</h3> }
                </div>
            </Modal.Body>
        </Modal>

    )
}
import {
    DELETE_CANDIDATE_BY_ADMIN_REQUEST,
    DELETE_CANDIDATE_BY_ADMIN_SUCCESS,
    DELETE_CANDIDATE_BY_ADMIN_FAILURE,
} from "./actionType";

import { deleteCandidateByAdminService } from "../../config/services/employeeServices";
import { getInterviewList } from "./getInterviewListAction";

const deleteCandidateByAdmin = (interviewId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage) => {
    return (dispatch) => {
        dispatch(deleteCandidateByAdminRequest());
        deleteCandidateByAdminService(interviewId)
            .then((res) => {

                if (res.data.statusCode == 1) {
                    dispatch(deleteCandidateByAdminSuccess(res.data));
                    setResDeleteMsg(true);
                    dispatch(getInterviewList(query));

                } else {
                    dispatch(deleteCandidateByAdminFailure(res.data));
                    setFailResDeleteMsg(true);
                    dispatch(getInterviewList(query));
                    setFailResponseMessage(res?.data?.error?.responseMessage)
                }
            })
        // .catch((error) => {
        //     dispatch(deleteCandidateByAdminFailure(error));
        //     // setErrorText("Failed to delete");
        //     setFailResDeleteMsg(true);
        // });
    };
};

const deleteCandidateByAdminRequest = () => {
    return {
        type: DELETE_CANDIDATE_BY_ADMIN_REQUEST,
    };
};
const deleteCandidateByAdminSuccess = (data) => {
    return {
        type: DELETE_CANDIDATE_BY_ADMIN_SUCCESS,
        payload: data,
    };
};
const deleteCandidateByAdminFailure = (error) => {
    return {
        type: DELETE_CANDIDATE_BY_ADMIN_FAILURE,
        payload: error,
    };
};
export {
    deleteCandidateByAdmin,
    deleteCandidateByAdminRequest,
    deleteCandidateByAdminSuccess,
    deleteCandidateByAdminFailure,
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Select, Modal as AntModal } from "antd";
import { CONSTANTS } from "../../config";
import ResponseFilter from "../../ResponseFilter";
import { getEmployeeReimbursement, reimburseHistoryRecordByManager } from "../../config/services/employeeServices";
import { Loader } from "../svg";
import { data } from "jquery";
const { Option } = Select;

const appConstants = CONSTANTS.TRANSACTION_LIST_CONSTANTS.ARRAY_OF_MONTHS;
const colorType = (type) => {
  switch (type) {
    case 1:
      return "yellow";

    case 2:
      return "text-success";

    default:
      return "text-danger ";
  }
};

const arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i < 4; i++) {
  arrayOfYear.push(currentYear - i);
}

class Reimbursement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expenseDescription: "",
      reimbursementList: [],
      searchYear: currentYear,
      loader: true,
      rejected: "",
      pending: "",
      approved: "",
      month: "",
      year: "",
      pageNo: 1,
      count: 10,
      openRembInfoPopup: false,
      claimReimbursementMessage: false,
    };
  }

  componentDidMount() {
    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let searchYear = url.searchParams.get("searchYear");
    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      searchYear: searchYear ? searchYear : currentYear,
    }, () => {
      this.getReimbursement();
    })
  }

  getReimbursement = (year) => {
    let { searchYear, pageNo, count } = this.state;
    if (typeof year !== "undefined") {
      searchYear = year;
    }
    let params = `searchYear=${searchYear}&count=${count}&pageNo=${pageNo - 1}`;
    this.props.history.push(`manager-reimbursement?searchYear=${searchYear}&pageNo=${pageNo}`)
    reimburseHistoryRecordByManager(params).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState(
          {
            reimbursementList: resData.reimbursementList,
          },
          () => {
            this.setState({ loader: false });
          }
        );
      } else if (res.data.statusCode === 0) {
        this.setState({ loader: false });
        this.closeModal();
      }
    });
  };
  handleSelectChange = (e) =>
    this.setState({ year: e, searchYear: e }, () => this.getReimbursement(this.state.year));
  handleOpenRembInfoPopup = (data) => {
    let { expenseDescription, reimbursementId } = data;
    this.setState({
      openRembInfoPopup: !this.state.openRembInfoPopup,
      expenseDescription,
      reimbursementId,
    });
  };
  closeModal = () =>
    this.setState({
      openRembInfoPopup: false,
    });
  calcAmountClaimed = (data) => {
    let amountClaimed = 0;
    data.map((item) => console.log(item.amount));
    return amountClaimed;
  };
  calcAmountApproved = (data) => {
    let amountApproved = 0;
    data.map((item) =>
      item.status === 2 ? (amountApproved += item.amount) : null
    );
    return amountApproved;
  };
  compensentoryLeaveDetailShow = (value) => {
    this.setState({ claimReimbursementMessage: true })
  }

  compensentoryLeaveDetailHide = (value) => {
    this.setState({ claimReimbursementMessage: false })
  }



  render() {
    let {
      reimbursementList,
      loader,
      year,
      openRembInfoPopup,
      expenseDescription,
      claimReimbursementMessage,
      searchYear,
    } = this.state;

    return (
      <div className="body_container cm_bold">
        {loader && <Loader />}
        <div className="cm_breadcrumb">
          <ul>
            <li>
              <Link to="/reimbursement">REIMBURSEMENT</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li style={{ textTransform: "uppercase" }}>Reimbursement Requests</li>
          </ul>
        </div>
        <h3 className="mt-2">REIMBURSEMENT REQUESTS</h3>

        <div className="row d-flex justify-content-end">
          <div className="col-6 mb-5">
            <Select
              className="cm_select_year "
              value={searchYear ? searchYear : currentYear}
              onChange={this.handleSelectChange}
            >
              {arrayOfYear.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="">
          {" "}
          <Link to="/">
            <h5 className="cm_bold">Reimbursement History By Manager</h5>{" "}</Link>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th>S.No</th>
                <th>Month</th>
                <th>Reimbursement Claimed</th>
                <th style={{ width: "350px" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(reimbursementList)
                ? reimbursementList.map((data, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Link
                        to={{
                          pathname: "/reimbursement/manager-reimbursement-action",
                          state: {
                            reqMonth: data.month.month.month,
                            reqYear: data.month.year.year,
                          },
                        }}
                      >
                        {appConstants[data?.month?.month?.month - 1]}
                      </Link>
                    </td>
                    <td>
                      {data.monthlyReimburseList.length}
                    </td>
                    <td
                      className={colorType(data.status)}
                      style={{ width: "350px" }}
                    >
                      <div className="cm_btn_status">
                        <button
                          type="button"
                          style={{ cursor: 'default' }}
                          className="btn btn-success btn-sm mr-2  mb-2 cm_rem_dot"
                        >
                          {data.approved} Approved
                        </button>
                        <button
                          type="button"
                          style={{ cursor: 'default' }}
                          className="btn btn-primary btn-sm mr-2  mb-2 cm_rem_dot"
                        >
                          {data.pending} Pending
                        </button>
                        <button
                          type="button"
                          style={{ cursor: 'default' }}
                          className="btn btn-danger btn-sm  mb-2 cm_rem_dot"
                        >
                          {data.rejected} Rejected
                        </button>
                      </div>
                    </td>



                  </tr>
                ))
                : ""}
            </tbody>
          </table>
          {reimbursementList && reimbursementList.length > 0 ? (
            ""
          ) : (
            <h3 className="text-center">No data available</h3>
          )}
        </div>
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRembInfoPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="mb-2 mt-4">EXPENSE DESCRIPTION</h3>
              <div className="cm_overflow_reimbur">
                <p>{expenseDescription}</p>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
      </div>
    );
  }
}
export default Reimbursement;

import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import templatePdf from "../../asset/pdf/timesheet.pdf";
import { PDFReader } from "reactjs-pdf-reader-custom";
import { Modal as AntModal } from "antd";
import "./templateModal.css";

function ViewTemplateModal(props) {
    const { viewModal, handleClose } = props
    const [loading, setLoading] = useState(true);
    return (
        <AntModal
            visible={viewModal}
            onCancel={handleClose}
            className="sm_modal1"
            footer={false}
        >
            <Modal.Body className={`view_pdf template_pdf ${loading && " d-flex justify-content-center align-items-center"
                } `} >
                <PDFReader
                    url={templatePdf}
                    showAllPage={true}
                    onDocumentComplete={() => setLoading(false)}
                    width="1000"
                />

            </Modal.Body>
        </AntModal>
    )
}

export default ViewTemplateModal;
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import { updateEmpResume } from "../../redux/actions/updateEmpResumeAction";
import { getResumeByEmployeeId } from "../../config/services/employeeServices";
import { Loader } from "../svg";
import { Toastify } from "../helper/Constant";
const empId = window.localStorage.getItem("employeeId");
const UpdateResumeModal = ({
  showModal,
  setShowModal,
  updateEmpResume,
  updateEmployeeResume,
  resumeId
}) => {
  const [title, setTitle] = useState("");
  const [resume, setResume] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [state, setState] = useState({ resMsg: false, miniLoader: false, resFailMessage: false, responseMessage: "" })
  const handleCancelButtonClick = () => {
    setShowModal(false);
    setTitle(null);
    setResume(null);
    setErrorText(null);
  };

  const handleUploadButtonClick = async () => {
    if (title && resume) {
      setState({ miniLoader: true })
      const data = new FormData();
      data.append("title", title.trim());
      data.append("resume", resume);
      data.append("resumeId", resumeId);
      await updateEmpResume(data, empId, setState, handleCancelButtonClick);
    } else {
      setErrorText("Both fields are required");
    }

  };

  const handleFileChange = (param) => {
    setResume(param.target.files[0]);
    setErrorText(null)
    fileValidation();
  }

  const handleTitleChange = (param) => {
    setTitle(/^\s/.test(param.target.value) ? "" : param.target.value);
    setErrorText(null)

  }


  const fileValidation = () => {
    var fileInput = document.getElementById("file");

    var filePath = fileInput.value;

    var allowedExtensions = /(\.doc|\.docx|\.pdf|\.txt)$/i;

    if (!allowedExtensions.exec(filePath)) {
      setResume(null);
      setErrorText("Invalid file !");
      fileInput.value = "";
      return false;
    }
    else if (fileInput.files[0].size >= 10485760) {
      setErrorText("please insert image of less then 10 mb");
      setResume(null)
      fileInput.value = "";
      return false
    }
    else {
      setErrorText("");
    }
  };
  return (
    <>

      <Toastify
        resMsg={state.resMsg}
        resMsgFail={state.resFailMessage}
        text={"Resume Updated"}
        responseMessage={state.responseMessage}
      />
      <Modal show={showModal} onHide={handleCancelButtonClick}>
        <Modal.Header closeButton>
          <Modal.Title>Update Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputField
              maxLength="30"
              value={title}
              placeholder="Enter Title"
              onChange={handleTitleChange}
            />
            <InputField
              type="file"
              id="file"
              accept="application/pdf,.doc, .docx"
              placeholder="Enter Title"
              onChange={handleFileChange}
            />
          </div>
          <ErrorText>{errorText}</ErrorText>
          <div className="modalButtonsDiv">
            {state.miniLoader ? <div className="mini_loader_dept"></div> : ""}

            <Button
              backgroundColor="grey"
              className="mr-2"
              color="black"
              onClick={handleCancelButtonClick}

            >
              Cancel
            </Button>
            <Button onClick={handleUploadButtonClick}
              disabled={state.miniLoader}
            >Upload</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    updateEmployeeResume: state.updateEmployeeResume,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateEmpResume: (data, empId, setState, handleCancelButtonClick) => {
      dispatch(updateEmpResume(data, empId, setState, handleCancelButtonClick));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateResumeModal);

const InputField = styled.input`
  border-radius: 10px;
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 0.5rem;
  border: 1px solid gray;
`;
const Button = styled.button`
  background-color: ${(props) => props.backgroundColor || "rgb(52, 123, 222)"};
  cursor: pointer;
  width: 100px;
  border: none;
  padding: 0.4rem;
  border-radius: 10px;
  color: ${(props) => props.color || "white"};
  font-size: medium;
`;
const ErrorText = styled.p`
  color: red;
  text-align: center;
`;

import { Select, TimePicker } from "antd";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { format } from "../Constants";
import "react-datepicker/dist/react-datepicker.css";
import DatePick from "../datePicker/DatePicker";
import { ErrorMessage } from "../../helper/Constant";

const { Option } = Select;
export function AddAttendanceModal(props) {
  const {
    show,
    handleClose,
    miniLoader,
    employeeId,
    handleSelectEmployeeChange,
    employeeList,
    employeeIdErrorMsg,
    handleDateChange,
    dateErrorMsg,
    responseMessage,
    checkIn,
    handleCheckInChange,
    checkInErrorMsg,
    checkOutErrorMsg,
    checkOut,
    handleCheckOutChange,
    wantSendMailErrorMsg,
    wantSendMail,
    handleWantSendmail,
    errorCode,
    handleAddAttendanceSubmit,
  } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>ADD ATTENDANCE</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className={`assign_device ${miniLoader ? "admin_attendance" : ""}`}
        >
          <div className="row">
            <div
              className={`form-group col-12 ${
                employeeIdErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Employee Name</label>

              <Select
                showSearch
                name="employeeId"
                value={employeeId ? employeeId : " Select Employee"}
                defaultValue="Select Name"
                onChange={handleSelectEmployeeChange}
                optionFilterProp="key"
                filterOption={(input, option) =>
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
                disabled={miniLoader}
              >
                {!_.isEmpty(employeeList)
                  ? employeeList
                      .sort(function (a, b) {
                        if (a.firstName < b.firstName) {
                          return -1;
                        }
                        if (a.firstName > b.firstName) {
                          return 1;
                        }
                        return 0;
                      })
                      .map(
                        (employee) =>
                          employee.status === 1 && (
                            <Option
                              key={`${employee.firstName} ${employee.lastName}`}
                              value={employee.employeeId}
                            >
                              <span>
                                {employee.firstName} {employee.lastName} (
                                {employee.empId})
                              </span>
                            </Option>
                          )
                      )
                  : ""}
              </Select>
              <ErrorMessage errMsg={employeeIdErrorMsg} />
            </div>
          </div>
          <div className="row">
            <div
              className={`form-group col-12 ${
                dateErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Date</label>
              <DatePick handleDateChange={handleDateChange} />
              <ErrorMessage errMsg={dateErrorMsg} />
              {errorCode === 24 ? (
                <h3 className="cm_response mt-2">{responseMessage}</h3>
              ) : (
                ""
              )}
            </div>
            <div
              className={`form-group col-lg-6 m-0 ${
                checkInErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Check In</label>
              <br />
              <TimePicker
                className="form-control"
                allowClear={false}
                format={format}
                disabledHours={() => [0, 1, 2, 3, 4, 5]}
                value={checkIn ? moment.utc(checkIn * 1000) : ""}
                onChange={handleCheckInChange}
                disabled={miniLoader}
              />
              <ErrorMessage errMsg={checkInErrorMsg} />
              <br />
            </div>
            <div
              className={`form-group col-lg-6 ${
                checkOutErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Check Out</label>
              <TimePicker
                className="form-control"
                allowClear={false}
                disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 8]}
                format={format}
                value={checkOut ? moment.utc(checkOut * 1000) : ""}
                onChange={handleCheckOutChange}
                disabled={miniLoader}
              />
              <ErrorMessage errMsg={checkOutErrorMsg} />
              <br />
            </div>
            <div
              className={`form-group col-8 ${
                wantSendMailErrorMsg ? "input_error" : ""
              }`}
            >
              <label className="cm_bold mt-2"> Want to Send Mail </label>
              <Select
                value={wantSendMail ? wantSendMail : "Select status"}
                defaultValue="Select Suggestion"
                name="wantSendMail"
                onChange={(e) => handleWantSendmail(e)}
                disabled={miniLoader}
              >
                <Option value="1">Yes</Option>
                <Option value="0">No</Option>
              </Select>
              <ErrorMessage errMsg={wantSendMailErrorMsg} />
              {errorCode === 24 ? (
                <h3 className="cm_response mt-2">{responseMessage}</h3>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="submit_button_margin mt-4">
            {miniLoader && <div className="cover-spin1"></div>}
            <button
              type="submit"
              onClick={handleAddAttendanceSubmit}
              className="btn btn-primary cm_btn3"
              disabled={miniLoader}
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-primary cm_btn2"
              disabled={miniLoader}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import { CONSTANTS } from "../../config";
import { Select } from "antd";
import { getEmployeeList } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { DocIcon, ImageIcon, PdfIcon, PngIcon } from "../svg";
import DOMPurify from "dompurify";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const { Option } = Select;
export const currentMonth = new Date().getMonth() + 1;
export const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3) - 1;

export const reducer = (state, action) => {
  switch (action.type) {
    case "typeCheckSet":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const type = "typeCheckSet";

export const handlePageChange = (dispatch, pageNo) => {
  dispatch({
    type: type,
    payload: {
      pageNo: pageNo,
    },
  });
};

export const createSelectItems = () => {
  let item = [];
  let currentYear = new Date().getFullYear();
  for (let i = 0; i <= 5; i++) {
    let prevYear = currentYear - 1;
    if (i === 0) {
      item.push(
        <Option key={currentYear} value={`${currentYear}`}>
          {currentYear}
        </Option>
      );
    } else {
      item.unshift(
        <Option key={prevYear} value={`${prevYear}`}>
          {prevYear}
        </Option>
      );
    }
    if (i > 0) {
      currentYear = prevYear;
    }
  }
  return item.sort(function (a, b) {
    return b.key - a.key;
  });
};

export const STATUS_ONE = 1;
export const STATUS_TWO = 2;
export const STATUS_THREE = 3;
export const STATUS_FOUR = 4;
export const STATUS_FIVE = 5;
export const STATUS_SIX = 6;
export const STATUS_SEVEN = 7;
export const STATUS_EIGHT = 8;
export const STATUS_NINE = 9;
export const STATUS_TEN = 10;

export const emailValidation =
  /^(?!.{41})[a-z0-9]+(?:\.[a-z0-9]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-zA-Z]{2,6}$/;
export const isValid = (email, emails, dispatch) => {
  let error = null;
  if (!emailValidation.test(email)) {
    error = `This is not a valid email address`;
  }
  if (emails && emails.includes(email)) {
    error = `This email has already been added`;
  }
  if (error) {
    dispatch({
      type: type,
      payload: {
        emailError: error,
      },
    });
    return false;
  }
  return true;
};

export const Toastify = (props) => {
  let { resMsg, text, responseMessage, resMsgFail } = props;
  return (
    <div>
      {resMsg ? (
        <div className="alert alert-success cm_top_fix">
          {text} successfully
        </div>
      ) : (
        ""
      )}
      {resMsgFail ? (
        <div className="alert alert-danger cm_top_fix">{responseMessage}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export const handleSort = (e, dispatch, sortType) => {
  dispatch({
    type: type,
    payload: {
      loader: false,
      sortField: e,
      sortType:
        sortType === SORT_TYPE_CONSTANTS.Asscending
          ? SORT_TYPE_CONSTANTS.Descending
          : SORT_TYPE_CONSTANTS.Asscending,
    },
  });
};

export const dateFormat = "DD/MM/YYYY";

export const checkLeaveArray = (leave, halfLeave) => {
  let a = 0;
  let b = 0;
  if (leave) {
    a = leave;
  }
  if (halfLeave) {
    b = halfLeave / 2;
  }
  return a + b;
};

export const isEmail = (email) => {
  return /^(?!.{41})[a-z0-9]+(?:\.[a-z0-9]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-zA-Z]{2,6}$/.test(
    email
  );
};

export const isInList = (email, emails) => {
  return emails.includes(email);
};

export const invalidEmail = `This is not a valid email address.`;
export const emailAlreadyPresent = `This email has already been added.`;
export const emailClick = "After Writing Email Press Enter/Tab to Continue";
export const REQUIRED = "Field Required";

export const handleIsoDate = (inputDate) => {
  const inputDateTime = inputDate;

  const year = inputDateTime.getUTCFullYear();
  const month = inputDateTime.getUTCMonth() + 1;
  const day = inputDateTime.getUTCDate();
  const formattedDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));

  const formattedDateString = formattedDate.toISOString();

  return formattedDateString;
};
export const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

export const MONTH_ARRAY_NUM = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April " },
  { value: 5, label: "May " },
  { value: 6, label: "June " },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October " },
  { value: 11, label: "November " },
  { value: 12, label: "December " },
];

export const searchEmployee = (search, dispatch) => {
  return (
    <>
      <input
        maxLength={20}
        className="form-control mr-lg-2 cm_box"
        type="search"
        placeholder="Search by name/ courier-details"
        aria-label="Search"
        value={search}
        onChange={(e) => handleSearch(e, dispatch)}
        autoComplete="off"
      />
      {search ? (
        <span
          className="cm_clear1"
          onClick={() => handleSelectChange(dispatch)}
        >
          <i className="fa fa-times-circle" aria-hidden="true"></i>
        </span>
      ) : (
        <span className="cm_clear1">
          <i className="fa fa-search" aria-hidden="true"></i>
        </span>
      )}
    </>
  );
};

export const handleSearch = (e, dispatch) => {
  dispatch({
    type: type,
    payload: {
      search: e.target.value,
    },
  });
  if (e.target.value === "" || e.target.value === undefined) {
    dispatch({
      type: type,
      payload: {
        search: "",
        // pageNo: 1,
        status: "",
      },
    });
  }
};

export const handleSelectChange = (dispatch) => {
  dispatch({
    type: type,
    payload: {
      search: "",
    },
  });
};

export const handleOpenPopup = (props) => {
  const { dispatch, open, data } = props;

  if (data) {
    dispatch({
      type: type,
      payload: {
        courier_id: data._id,
        [open]: true,
        name: data.receiver_name,
        dateSent: data.dateSent,
        tentative_receiving_date: data.tentative_receiving_date,
        cost: data.cost,
        tracking_id: data.tracking_id,
        courier_company: data.courier_company,
        item_description: data.item_description,
        courierFile: open === "editPopup" && data.courier_slip,
      },
    });
  } else {
    dispatch({
      type: type,
      payload: {
        [open]: true,
      },
    });
  }
};

export const handleDate = (date, dispatch, dateType) => {
  dispatch({
    type: type,
    payload: {
      [dateType]: date,
      [`${dateType}ErrMsg`]: "",
    },
  });
};

export const letterNumberRegex = /[^a-zA-Z0-9\s,-]/g;
export const numberRegex = /[^0-9.]/g;
export const alphanumericRegex = /^[a-zA-Z .-\d]+$/;
export const alphaRegex = /^[a-zA-Z.-]+$/;

export const handleChange = (e, dispatch, fieldName) => {
  let value = e.target.value;
  if (fieldName === "name") {
    value = value.replace(/[^a-zA-Z0-9 .-]/g, "");
  } else if (fieldName === "tracking_id") {
    value = value.toUpperCase().replace(letterNumberRegex, "");
  } else if (fieldName === "cost") {
    if (value === "." || value === "") {
      value = "";
    } else {
      value = value.replace(numberRegex, "");
      const parts = value.split(".");
      value = parts[0] + (parts.length > 1 ? "." + parts[1].slice(0, 3) : "");
    }
  } else {
    value = value.replace(letterNumberRegex, "");
  }
  dispatch({
    type: type,
    payload: {
      [fieldName]: value,
      [`${fieldName}ErrMsg`]: "",
    },
  });
};

export const getEmployee = (dispatch) => {
  let params = ``;
  getEmployeeList(params).then((res) => {
    let { status, resData } = ResponseFilter(res);
    if (status) {
      dispatch({
        type: type,
        payload: {
          employeeList: resData.employeesList,
        },
      });
    }
  });
};

export const allEmployees = (employeeList, employeeId, dispatch) => {
  return (
    <Select
      showSearch
      name="employeeId"
      value={employeeId ? employeeId : " Select Employee"}
      defaultValue="Select Name"
      onChange={(e) => handleSelectEmployeeChange(e, employeeList, dispatch)}
      optionFilterProp="key"
      filterOption={(input, option) =>
        option.key.toLowerCase().includes(input.toLowerCase())
      }
      // disabled={miniLoader}
    >
      {!_.isEmpty(employeeList) &&
        employeeList
          .sort(function (a, b) {
            if (a.firstName < b.firstName) {
              return -1;
            }
            if (a.firstName > b.firstName) {
              return 1;
            }
            return 0;
          })
          .map(
            (employee) =>
              employee.status === 1 && (
                <Option
                  key={`${employee.firstName} ${employee.lastName}`}
                  value={employee.employeeId}
                >
                  <span>
                    {employee.firstName} {employee.lastName} ({employee.empId})
                  </span>
                </Option>
              )
          )}
    </Select>
  );
};

export const handleSelectEmployeeChange = (e, employeeList, dispatch) => {
  const selectedEmployee = employeeList.find(
    (employee) => employee.employeeId === e
  );
  if (selectedEmployee) {
    dispatch({
      type: type,
      payload: {
        employeeId: e,
        name: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
        nameErrMsg: "",
      },
    });
  }
};

export const disabledDate = (current, start) => {
  return current && current < moment(start).startOf("day");
};

export const DATE_ERROR =
  "Date sent cannot be greater tentative receiving date";

export const FILE_ERROR = "Valid File Type: mp4, pdf, png, jpg, jpeg";

export const ActionImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const InputField = styled.input`
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 0.5rem;
  border: 1px solid gray;
`;

export const LEAVE_UPLOAD_DOC_FILE = "Valid File Type: pdf, png, jpg, jpeg";
export const LEAVE_UPLOAD_DOC_MAX = "Exceeded maximum number of files (5)";
export const leaveUploadDocTypeImgRegex = /\.(jpg|jpeg|png|JPEG|JPG|PNG)$/;
export const leaveUploadDocTypePdfRegex = /\.(pdf|PDF)$/;
export const leaveUploadDocTypeImgArray = ["png", "jpg", "jpeg", "pdf"];

export const arrOfMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];
export const DAYS_OF_WEEK = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
export const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
export const statusMap = {
  present: [1, 2, 4, 6, 7, 8, 10, 11, 15, 16],
  absent: [3],
  holiday: [19],
  leave: [5, 20, 21],
  halfLeave: [9, 12, 13, 14, 17, 18],
};

export const template_emp_status = (type) => {
  switch (type) {
    case 1:
    case 2:
    case 4:
    case 6:
    case 7:
    case 8:
    case 10:
    case 11:
    case 15:
    case 16:
      return "Present";
    case 5:
    case 20:
    case 21:
      return "Leave";
    case 3:
      return "Absent";
    case 9:
    case 12:
    case 13:
    case 14:
    case 17:
    case 18:
      return "Half-Day";
    case 19:
      return "Holiday";
    default:
      return "Unknown";
  }
};

export const TicketCategory = [
  "Software",
  "Hardware",
  "HR related",
  "Others",
  "Account",
];

export const ErrorMessage = (props) => {
  const { errMsg } = props;
  return (
    <div>
      {errMsg && <span className="error_msg cm_pos_bot ml-1">{errMsg}</span>}
    </div>
  );
};

export const arrOfQuarters = [
  "Jan to Mar",
  "Apr to June",
  "July to Sep",
  "Oct to Dec",
];

export const arrOfYears = () => {
  const currentYear = new Date().getFullYear();
  const arrOfYears = [];

  for (let i = 0; i <= 3; i++) {
    arrOfYears.push(currentYear - i);
  }
  return arrOfYears;
};

export const colorType = (type) => {
  if (type === 1) {
    return "text-success";
  } else {
    return "text-danger";
  }
};

export const returnFileIcon = (type) => {
  switch (type) {
    case "docx":
    case "odt":
    case "dotm":
    case "dotx":
    case "docm":
    case "dot":
    case "doc":
      return <DocIcon />;
    case "png":
      return <PngIcon />;
    case "pdf":
      return <PdfIcon />;
    default:
      return <ImageIcon />;
  }
};

export const genderValue = (type) => {
  switch (type) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    case 3:
      return "Other";
    default:
      return "";
  }
};

export const officeLocationValue = (type) => {
  switch (type) {
    case 1:
      return "Noida";
    case 2:
      return "Lucknow";
    default:
      return "";
  }
};

export const employeeRoleValue = (type) => {
  switch (type) {
    case 1:
      return "Admin";
    case 2:
      return "Employee";
    case 3:
      return "Vendor";
    case 4:
      return "HR";
    case 5:
      return "Accountant";
    case 6:
      return "Sales";
    case 7:
      return "IT";
    case 8:
      return "Office Admin";
    default:
      return "";
  }
};

export const bloodGroupValue = (type) => {
  switch (true) {
    case type === 1 || type === "A+":
      return "A+";
    case type === 2 || type === "B+":
      return "B+";
    case type === 3 || type === "O+":
      return "O+";
    case type === 4 || type === "AB+":
      return "AB+";
    case type === 5 || type === "A-":
      return "A-";
    case type === 6 || type === "B-":
      return "B-";
    case type === 7 || type === "O-":
      return "O-";
    case type === 8 || type === "AB-":
      return "AB-";
    default:
      return "";
  }
};

export const employeeTypeValue = (type) => {
  switch (type) {
    case "1":
      return "Fresher";
    case "2":
      return "Probation";
    case "3":
      return "Experienced";
    case "4":
      return "Intern";
    default:
      return "";
  }
};

export const degreeType = (type) => {
  switch (type) {
    case 1:
      return "UG";
    case 2:
      return "Graduation";
    case 3:
      return "PG";
    default:
      return "";
  }
};

export const linkdinIdRegx =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const skypeIdRegx = /^live:\.cid\.[a-zA-Z][a-zA-Z0-9\.,\-_]{5,15}/;
export const regx1 =
  /^(MOB)((?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[eE]([+-]?\d+))?$/;
export const regx =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
export const phoneRegx =
  /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[7896543210]\d{9}$/;
export const alternatePhoneRegx =
  /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[7896543210]\d{9}$/;
export const zipRegx = /^[a-zA-Z0-9]{4,8}$/;
export const nameRegex = /[^A-Za-z]/;

export const formattedDate = (dateString) => {
  const dateObject = new Date(dateString);
  const day = String(dateObject.getDate()).padStart(2, "0");
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const year = dateObject.getFullYear();
  return `${day}/${month}/${year}`;
};

export const COMP_OFF_EXCEED = "Input exceeds total Comp Off";
export const REMAINING_COMP_EXCEED = "Input exceeded Remaining Comp Off";

export function createMarkup(reason) {
  return {
    __html: DOMPurify.sanitize(reason),
  };
}

export const isAdmin = localStorage.getItem("isAdmin");

export const handleTextChange = ({ e, dispatch, toAddOrEdit }) => {
  if (toAddOrEdit === "Edit") {
    dispatch({
      type: type,
      payload: {
        uploadFileErrorMsg: "",
        titleErrorMsg: "",
      },
    });
  }
  dispatch({
    type: type,
    payload: {
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + "ErrorMsg"]: "",
    },
  });
};

export const handleFileUpload = (e, dispatch) => {
  dispatch({
    type: type,
    payload: {
      [e.target.name]: e.target.files[0],
      statusErrorMsg: "",
      [e.target.name + "ErrorMsg"]: "",
    },
  });
};

export const setResponseAction = (props) => {
  const { dispatch } = props;
  setTimeout(
    () =>
      dispatch({
        type: type,
        payload: {
          resMsg: false,
          resFailMsg: false,
          successMessage: "",
          responseMessage: "",
        },
      }),
    3000
  );
};

export const setResponseActionState = (props) => {
  const { setState } = props;
  setTimeout(
    () =>
      setState((prevState) => ({
        ...prevState,
        resMsg: false,
        resFailMsg: false,
        successMessage: "",
        responseMessage: "",
      })),
    2000
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Select } from 'antd';
import ResponseFilter from '../../ResponseFilter';
import { Tabs, Tab } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { Loader, RemoveIcon } from '../svg';
import { projectList, projectAction } from '../../config/services/employeeServices';
import { CONSTANTS } from '../../config';
import _ from 'lodash';
const { Option } = Select;
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS



const statusValue = type => {
  switch (type) {
    case 1:
      return 'Ongoing';
      break;
    case 2:
      return 'Completed';
      break;
    case 3:
      return 'Incomplete';
    case 4:
      return 'Maintenance';
    case 5:
      return 'On Hold'
  }
}
const colorType = type => {
  switch (type) {
    case 1: return 'text-primary';
      break;

    case 2: return 'text-success';
      break;

    default: return 'text-danger';

  }
}
const handleDelayTime = (data) => {
  var delayedStatus;
  var date = new Date().setHours(0, 0, 0, 0);

  if (data >= new Date(date).setHours(0, 0, 0, 0)) {
    delayedStatus = `On Track`
  }

  else {
    var delay = (new Date(date).setHours(0, 0, 0, 0) - data) / 86400000
    delayedStatus = `${Math.floor(delay)} days`
  }

  return delayedStatus;
}

const ProjectTable = ({ projectList, loader, handleAction, pageNo, count, handleSort, sortType, sortField, date = new Date() }) => (
  <React.Fragment>
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="white bg_blue">
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Project Name<i
              className={sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "projectName" ? "fa fa-sort-alpha-asc ml-2" : "fa fa-sort-alpha-desc ml-2"}
              onClick={() => handleSort('projectName')}></i></th>
            {/* <th scope="col"> Project Name</th> */}
            <th scope="col">Start Date<i
              className={sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "startDate" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
              onClick={() => handleSort('startDate')}></i></th>
            <th scope="col">End Date</th>
            <th scope="col">Project Manager</th>
            <th scope="scope"> Account Manager</th>
            <th scope="col">Client</th>
            <th>Delayed Status</th>
            <th scope="col" style={{ width: 130 }}>Status</th>

          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(projectList) ?
            projectList.map((project, i) => (
              <tr>
                <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                <td><span className="cm_col"><Link to={`/admin-projects/project-detail/${project.projectId}`}>{project.projectName.charAt(0).toUpperCase() + project.projectName.slice(1)}</Link></span></td>
                <td>{moment.unix(project.startDate / 1000).format("DD/MM/YYYY")}</td>
                <td>{moment.unix(project.endDate / 1000).format("DD/MM/YYYY")}</td>
                <td><span className="cm_col">{project.projectManager.charAt(0).toUpperCase() + project.projectManager.slice(1)}</span></td>
                <td>{project?.accountManagerFirst + " " + project?.accountManagerLast}</td>
                <td style={{ textTransform: 'capitalize' }}><span className="cm_col">{project.clientName}</span></td>
                <td>{
                  project.status == 2 ? <span className="green">Completed</span> : (project.status == 3 ? <span className="red">Incomplete</span> :
                    <span className={project.endDate >= new Date(date).setHours(0, 0, 0, 0) ? "ontrack" : "yellow"}>{handleDelayTime(project.endDate)}</span>)
                }
                </td>

                {
                  project.status == 1 ?
                    <td>
                      <div className="cm_ongoing">
                        <Select value={statusValue(project.status)} style={{ width: 130 }} onSelect={(e) => handleAction(project, e)}>
                          {/* <Option value="1" id="1">Ongoing</Option> */}
                          <Option value="2" id="2">Completed</Option>
                          <Option value="3" id="3">Incomplete</Option>
                          <Option value="5" id="5">On Hold</Option>

                        </Select></div>
                    </td>
                    : ''
                }

                {
                  project.status == 2 ?
                    <td>
                      <div className="cm_approve">
                        <Select value={statusValue(project.status)} style={{ width: 130 }} onSelect={(e) => handleAction(project, e)}>
                          <Option value="4" id="4">Maintenance</Option>
                          <Option value="5" id="5">On Hold</Option>

                          {/* <Option value="2" id="2">Completed</Option> */}
                          {/* <Option value="3" id="3">Incomplete</Option> */}
                        </Select>
                      </div>
                    </td>
                    : ''
                }

                {
                  project.status == 3 ?
                    <td>
                      <div className="cm_reject">
                        {/* <span style={{ width: 130 }}>{statusValue(project.status)} </span> */}
                        <Select value={statusValue(project.status)} style={{ width: 130 }} onSelect={(e) => handleAction(project, e)} disabled>
                          <Option value="1" id="1">Ongoing</Option>
                          <Option value="2" id="2">Completed</Option>
                          <Option value="3" id="3">Incomplete</Option>
                          <Option value="5" id="5">On Hold</Option>

                        </Select>
                      </div>
                    </td>
                    : ''
                }
                {
                  project.status == 4 ?
                    <td>
                      <div className="cm_maintenance">
                        {/* <span style={{ width: 130 }}>{statusValue(project.status)} </span> */}
                        <Select value={statusValue(project.status)} style={{ width: 130 }} onSelect={(e) => handleAction(project, e)} >
                          {/* <Option value="1" id="1">Ongoing</Option> */}
                          <Option value="2" id="2">Completed</Option>
                          <Option value="3" id="3">Incomplete</Option>
                          <Option value="5" id="5">On Hold</Option>
                        </Select>
                      </div>
                    </td>
                    : ''
                }
                {
                  project.status == 5 ?
                    <td>
                      <div className="cm_onHold">
                        {/* <span style={{ width: 130 }}>{statusValue(project.status)} </span> */}
                        <Select value={statusValue(project.status)} style={{ width: 130 }} onSelect={(e) => handleAction(project, e)} >
                          <Option value="1" id="1">Ongoing</Option>
                          <Option value="2" id="2">Completed</Option>
                          <Option value="3" id="3">Incomplete</Option>
                          <Option value="4" id="4">Maintenance</Option>

                        </Select>
                      </div>
                    </td>
                    : ''
                }
              </tr>
            ))
            : ""}
          {/* <Link className="addemp" to="/add-project"><i className="fa fa-plus-circle" title="Add project"></i></Link> */}
        </tbody>
      </table>
      {projectList && projectList.length > 0 ? '' : <h3 className="text-center ">No data is available</h3>}
    </div>

  </React.Fragment>
)



class ProjectList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectList: [],
      search: '',
      status: props.location?.state ? props.location.state : '',
      projectId: '',
      projectStatus: '',
      totalProjects: '',
      totalOnGoingProjects: '',
      totalCompletedProjects: '',
      totalInCompleteProjects: '',
      totalMaintenanceProjects: "",
      totalHoldProjects: "",
      pageNo: 1,
      dataCount: '',
      count: 10,
      loader: true,
      sortType: SORT_TYPE_CONSTANTS.Descending,
      sortField: 'created',
      resMsg: false,
      resFailMsg: false,
      responseMessage: "",
    }
    this.debounceSearch = null
  }
  handleSort = e => {
    this.setState({ loader: false, sortField: e, sortType: this.state.sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending }, () => this.getProject());
  }
  handleSearch = e => {
    this.setState({ search: e.target.value, pageNo: 1 })
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.setState({ loader: true })

      this.getProject()
    }, 2000);
  }
  handleSelectChange = () => {
    this.setState({ search: '', }, () => this.getProject())
  }
  submitSearch = e => {
    e.preventDefault();
    this.setState({ loader: true })
    this.getProject()
  }

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () => this.getProject());
  }

  handleTabChange = e => {
    this.setState({ loader: true, status: e == 0 ? "" : parseInt(e), pageNo: 1 }, () => this.getProject())
  }


  getProject = () => {
    let { search, status, sortType, sortField, pageNo, count } = this.state;

    let params = `search=${search.trim()}&status=${status}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}`;
    this.props.history.push(`admin-projects?search=${search}&sortType=${sortType}&sortField=${sortField}&pageNo=${pageNo}&status=${status}`)
    projectList(params).then(res => {
      this.setState({ loader: true })

      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          projectList: resData.projectList,
          totalProjects: resData.totalProjects,
          totalOnGoingProjects: resData.totalOnGoingProjects,
          totalCompletedProjects: resData.totalCompletedProjects,
          totalInCompleteProjects: resData.totalInCompleteProjects,
          totalMaintenanceProjects: resData.totalMaintenanceProjects,
          totalHoldProjects: resData.onHoldProjects,
          dataCount: resData.dataCount,

        }, () => this.setState({ loader: false }))
      }
      else {
        this.setState({ loader: false })
      }
    })
  }
  handleAction = (project, e) => {
    let params = {
      'projectId': project.projectId,
      'status': e

    }
    this.setState({ loader: true })
    projectAction(params)
      .then(res => {
        if (res.data.statusCode == 1) {
          this.setState({
            loader: false,
            resMsg: true,
          }, () => this.getProject())
        }
        else {
          this.setState({
            loader: false,
            resFailMsg: true
            , responseMessage: res.data.console.error.responseMessage,
          }, () => this.getProject())

        }
      })
    setTimeout(() => {
      this.setState({ resMsg: false, resFailMsg: false })
    }, 4000)
  }

  componentDidMount() {
    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let status1 = url.searchParams.get("status");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      search: search ? search : "",
      sortType: sortType ? sortType : SORT_TYPE_CONSTANTS.Descending,
      sortField: sortField ? sortField : "created",
      status: status1 ? status1 : this.state.status,
    }, () => {
      this.getProject();
    })
  }
  render() {
    let { projectList, dataCount, search, loader, totalProjects, pageNo, count, totalOnGoingProjects, totalCompletedProjects, totalInCompleteProjects, totalMaintenanceProjects, status, totalHoldProjects, resMsg, resFailMsg, responseMessage } = this.state;
    return (
      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        {
          resMsg ?
            <div className="alert alert-success cm_top_fix">Project Status Change Successfully</div> : ''
        }
        {
          resFailMsg ?
            <div className="alert alert-danger cm_top_fix">{responseMessage}</div> : ''
        }
        <div className="row d-flex align-items-center  mb-4">
          <div className='col-md-6'>
            <h3 className="mb-4undefined mb-0">PROJECT LIST</h3>
          </div>
          <div className="col-md-6 search_device_bar3">
            <Link className="btn btn-primary add_empBtn" style={{ width: 140 }} to="/admin-projects/add">Add project</Link>
            <input className="form-control mr-lg-2 cm_box"
              type="search"
              maxLength={40}
              placeholder="Search by Project Name"
              aria-label="Search"
              value={search}
              onChange={this.handleSearch}
              // onKeyDown={(e) => { if (e.key === 'Enter') this.getProject() }}
            />
            {search ? <span className="cm_clear4" onClick={this.handleSelectChange}><i className="fa fa-times-circle" aria-hidden="true"></i></span> : <span className="cm_clear4"><i className="fa fa-search" aria-hidden="true" ></i></span>}

          </div>
        </div>
        <Tabs className="tabsNew" activeKey={this.props.location?.state ? this.props.location.state : status ? status.toString() : "0"} id="uncontrolled-tab-example" onSelect={(e) => this.handleTabChange(e)}  >
          <Tab eventKey="0" title={`All ${totalProjects ? totalProjects : 0}`}>
            <ProjectTable {...this.state}
              handleSort={this.handleSort}
              handleAction={this.handleAction} />
            {dataCount > 10 ? <Pagination
              className="mb-1"
              activePage={pageNo}
              pageNo={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={totalProjects}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            /> : ''}
          </Tab>

          <Tab eventKey="1" title={`Ongoing ${totalOnGoingProjects ? totalOnGoingProjects : 0}`}>
            <ProjectTable {...this.state}
              handleSort={this.handleSort}
              handleAction={this.handleAction} />
            {dataCount > 10 ? <Pagination
              className="mb-1"

              activePage={pageNo}
              pageNo={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={dataCount}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            /> : ''}
          </Tab>

          <Tab eventKey="2" title={`Completed ${totalCompletedProjects ? totalCompletedProjects : 0}`}>
            <ProjectTable {...this.state}
              handleSort={this.handleSort}
              handleAction={this.handleAction} />
            {dataCount > 10 ? <Pagination
              className="mb-1"

              activePage={pageNo}
              pageNo={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={dataCount}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            /> : ""}
          </Tab>

          <Tab eventKey="3" title={`Incomplete ${totalInCompleteProjects ? totalInCompleteProjects : 0}`}>
            <ProjectTable {...this.state}
              handleSort={this.handleSort}
              handleAction={this.handleAction} />
            {dataCount > 10 ?
              <Pagination
                className="mb-1"

                activePage={pageNo}
                pageNo={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              /> : ""}

          </Tab>
          <Tab eventKey="4" title={`Maintenance ${totalMaintenanceProjects ? totalMaintenanceProjects : 0}`}>
            <ProjectTable {...this.state}
              handleSort={this.handleSort}
              handleAction={this.handleAction} />
            {dataCount > 10 ?
              <Pagination
                className="mb-1"

                activePage={pageNo}
                pageNo={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              /> : ""}
          </Tab>
          <Tab eventKey="5" title={`On Hold ${totalHoldProjects ? totalHoldProjects : 0}`}>
            <ProjectTable {...this.state}
              handleSort={this.handleSort}
              handleAction={this.handleAction} />
            {dataCount > 10 ?
              <Pagination
                className="mb-1"

                activePage={pageNo}
                pageNo={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              /> : ""}

          </Tab>
        </Tabs>


      </div>
    );
  }
}
export default ProjectList;
import React from "react";
import { Link } from "react-router-dom";
import { DatePicker, Select, Modal as AntModal } from "antd";
import Modal from "react-bootstrap/Modal";
import ResponseFilter from "../../ResponseFilter";
import moment from "moment";
import _ from "lodash";
import {
  getEmployeeListByManager,
  addProjectByManager,
} from "../../config/services/employeeServices";
const { Option } = Select;

function disabledDate(current) {
  return current && current < moment().endOf("day");
}

class AddProjectByManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectName: "",
      startDate: "",
      assignedTo: [],
      endDate: "",
      search: "",
      members: [],
      clientName: "",
      projectManager: "",
      mailId: "",
      projectDescription: "",
      projectNameErMsg: "",
      projectManagerNameErMsg: "",
      assignedToErMsg: "",
      dateErMsg: "",
      endDateErMsg: "",
      openAddParticipentPopup: false,
      employeeList: [],
      selectObj: [],
      deSelect: "",
      responseError: "",
      responseMessage: "",
      endOpen: false,
    };
  }

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  disabledStartDate = (startDate) => {
    const { endDate } = this.state;
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = (endDate) => {
    const { startDate } = this.state;
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };

  onChangeDate = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handleSearch = (e) => {
    this.setState({ search: e.target.value }, () =>
      this.getEmployeeListByManager()
    );
  };

  getEmployeeListByManager = () => {
    let { search } = this.state;
    let params = `search=${search}`;
    getEmployeeListByManager(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState(
          {
            employeeList: res.data.responseData.employeesList,
          },
          () => this.closeModal()
        );
      }
    });
  };

  componentDidMount() {
    this.getEmployeeListByManager();
  }

  handleOpenAddParticipentPopup = ({ employeesList }) =>
    this.setState({
      openAddParticipentPopup: !this.state.openAddParticipentPopup,
      employeesList,
    });

  closeModal = () =>
    this.setState({
      assignedToErMsg: "",
      openAddParticipentPopup: false,
      endOpen: false,
      assignedTo: [],
    });

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      responseError: "",
      responseMessage: "",
      projectNameErMsg: "",
    });
  };
  handleSelectChange = (e) => this.setState({ category: e });

  handleParticipant = () => {
    if (this.handleAssignedToValidation()) {
      this.setState({
        members: this.state.selectObj,
        openAddParticipentPopup: false,
        membersErMsg: "",
      });
    }
  };

  handleOnSelect = (e) => {
    try {
      if (this.state.selectObj.indexOf(e) == -1) {
        this.setState({
          selectObj: [...this.state.selectObj, e],
          assignedToErMsg: "",
          assignedTo: [...this.state.assignedTo, e.employeeId],
        });
      }
    } catch (err) { }
  };
  handleOnDSelect = (e) => {
    let self = this;

    try {
      this.setState(
        {
          selectObj: this.state.selectObj.filter((item) => item.email != e),
          assignedTo: this.state.assignedTo.filter((item) => item != e),
        },
        () => {
          let arr = [];
          self.state.selectObj.map((lists) => {
            arr.push(lists.employeeId);
          });
          self.setState({ assignedTo: arr });
        }
      );
    } catch (err) { }
  };

  handleOnDSelectEmp = (e) => {
    try {
      this.setState(
        {
          selectObj: this.state.selectObj.filter(
            (item) => item.employeeId != e
          ),
          assignedToErMsg: "",
          assignedTo: this.state.assignedTo.filter((item) => item != e),
        },
        () => {
          this.setState({ members: this.state.assignedTo });
        }
      );
    } catch (err) { }
  };

  handleValidation = () => {
    let validate = true;
    const { projectName, startDate, endDate, projectManager, members } =
      this.state;

    if (projectName === "" || projectName === undefined) {
      validate = false;
      this.setState({
        projectNameErMsg: "Project name is required",
      });
    } else {
      this.setState({
        projectNameErMsg: "",
      });
    }
    if (members.length === 0 || members === undefined) {
      validate = false;
      this.setState({
        membersErMsg: "Participants is required",
      });
    } else {
      this.setState({
        membersErMsg: "",
      });
    }

    if (startDate === "" || startDate === undefined) {
      validate = false;
      this.setState({
        dateErMsg: "Start date is required",
      });
    } else {
      this.setState({
        dateErMsg: "",
      });
    }
    if (endDate === "" || endDate === undefined) {
      validate = false;
      this.setState({
        endDateErMsg: "End date is required",
      });
    } else {
      this.setState({
        endDateErMsg: "",
      });
    }
    if (projectManager === "" || projectManager === undefined) {
      validate = false;
      this.setState({
        projectManagerNameErMsg: "Project manager is required",
      });
    } else {
      this.setState({
        projectManagerNameErMsg: "",
      });
    }

    return validate;
  };
  handleAssignedToValidation = () => {
    let validate = true;
    const { assignedTo } = this.state;

    if (assignedTo.length == 0 || assignedTo === undefined) {
      validate = false;
      this.setState({
        assignedToErMsg: "Please select email",
      });
    } else {
      this.setState({
        assignedToErMsg: "",
      });
    }

    return validate;
  };
  onStartChange = (value) => {
    this.onChangeDate("startDate", value);
  };

  onEndChange = (value) => {
    this.onChangeDate("endDate", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true, dateErMsg: "" });
    }
  };
  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open, endDateErMsg: "" });
  };
  handleChangeAssignedToChange = (e) =>
    this.setState({ projectManager: e, projectManagerNameErMsg: "" });

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      let resObj = {
        projectName: this.state.projectName,
        category: this.state.category,
        startDate: new Date(this.state.startDate).setHours(0, 0, 0, 0),
        endDate: new Date(this.state.endDate).setHours(0, 0, 0, 0),
        projectManager: this.state.projectManager,
        assignedTo:
          this.state.assignedTo.length != 0
            ? this.state.assignedTo.toString()
            : [],
        clientName: this.state.clientName,
        mailId: this.state.mailId,
        projectDescription: this.state.projectDescription,
      };

      addProjectByManager(resObj).then((res) => {
        let { status, resData } = ResponseFilter(res);

        if (status) {
          this.setState(
            {
              projectName: "",
              category: "",
              startDate: "",
              endDate: "",
              projectManager: "",
              assignedTo: [],
              clientName: "",
              mailId: "",
              projectDescription: "",
              projectNameErMsg: "",
              membersErMsg: "",
              projectManagerNameErMsg: "",
              dateErMsg: "",
              endDateErMsg: "",
            },
            () => {
              window.location.href = "/projects/manager-projects";
            }
          );
        } else {
          this.setState({
            resErrorMsg: resData,
            projectNameErMsg: "",
            membersErMsg: "",
            projectManagerNameErMsg: "",
            dateErMsg: "",
            endDateErMsg: "",
            responseError: res.data.responseData,
            responseMessage: res.data.error.responseMessage,
          });
        }
      });
    } else {
      this.setState({ loader: false });
    }
  };

  render() {
    const { Option } = Select;
    const Children = [];
    let {
      projectName,
      endOpen,
      startDate,
      endDate,
      category,
      members,
      openAddParticipentPopup,
      employeeList,
      dateErMsg,
      endDateErMsg,
      clientName,
      mailId,
      projectManager,
      projectDescription,
      projectNameErMsg,
      membersErMsg,
      projectManagerNameErMsg,
      assignedToErMsg,
      responseError,
      responseMessage,
    } = this.state;

    if (employeeList) {
      employeeList.map(
        (employee) =>
          employee.status == 1 &&
          Children.push(
            <Option
              className=""
              onClick={() => this.handleOnSelect(employee)}
              key={employee.email}
            >
              <span className="">{employee.email} </span>
            </Option>
          )
      );
    }

    return (
      <form className="" onSubmit={this.handleSubmit}>
        <div className="body_container cm_bold">
          <h4 className="cm_bold mb-5">ADD NEW PROJECT</h4>

          <div className="form-group add_project">
            <div className="row">
              <div className="col-10">
                <div className="row">
                  <div
                    className={`form-group col-6 ${projectNameErMsg ? "input_error" : ""
                      }`}
                  >
                    <label className="sub">Project Name</label>
                    <input
                      maxLength="50"
                      type="text"
                      className="form-control cm_box"
                      placeholder="Enter project name"
                      name="projectName"
                      value={projectName}
                      onChange={this.handleChange}
                    />
                    {responseMessage && (
                      <p className="password_error mt-3 text-danger">
                        {responseMessage}
                      </p>
                    )}
                    {projectNameErMsg ? (
                      <span className="error_msg">{projectNameErMsg}</span>
                    ) : null}
                  </div>

                  <div className="col-6">
                    <label className="sub">Category</label>

                    <Select
                      style={{ width: "100%" }}
                      placeholder="Category"
                      value={category}
                      onChange={this.handleSelectChange}
                    >
                      <Option key="1">Web</Option>
                      <Option key="2">Android</Option>
                      <Option key="3">iOS</Option>
                      <Option key="4">all</Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <div className="row">
                <div
                  className={`form-group col-3 ${dateErMsg ? "input_error" : ""
                    }`}
                >
                  <label className="sub">Start Date</label>
                  <DatePicker
                    allowClear={false}
                    className="date-border"
                    disabledDate={this.disabledStartDate}
                    //showTime
                    //format="DD-MM-YYYY"
                    value={!startDate ? startDate : moment(startDate)}
                    placeholder="Start date"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                  />
                  {dateErMsg ? (
                    <span className="error_msg">{dateErMsg}</span>
                  ) : null}
                </div>
                <div
                  className={`form-group col-3 ${endDateErMsg ? "input_error" : ""
                    }`}
                >
                  <label className="sub">End Date</label>
                  <DatePicker
                    allowClear={false}
                    className="date-border"
                    disabledDate={this.disabledEndDate}
                    //showTime
                    //format="DD-MM-YYYY"
                    value={!endDate ? endDate : moment(endDate)}
                    placeholder="End date"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                  />
                  {endDateErMsg ? (
                    <span className="error_msg">{endDateErMsg}</span>
                  ) : null}
                  {/* <input className="form-control cm_bold cm_box" type="number" placeholder="End year" name="endDate" value={endDate} onChange={this.handleChange} /> */}
                </div>
                <div
                  className={`form-group col-6 ${projectManagerNameErMsg ? "input_error" : ""
                    }`}
                >
                  <label className="sub">Project Manager</label>
                  <div className=" sm_search">
                    <Select
                      name="employeeId"
                      value={
                        projectManager
                          ? projectManager
                          : "Select by employee email id"
                      }
                      onChange={this.handleChangeAssignedToChange}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={employee.employeeId}
                                value={employee.employeeId}
                              >
                                <span>{employee.email}</span>
                              </Option>
                            )
                        )
                        : ""}
                    </Select>
                  </div>

                  {projectManagerNameErMsg ? (
                    <span className="error_msg">{projectManagerNameErMsg}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-2 mb-5">
              <h5 className="cm_bold">Project Members</h5>
            </div>
            <div className="col-10 mb-5">
              <div
                className={`form-group col-6 ${membersErMsg ? "input_error" : ""
                  }`}
              >
                <ul className="proj_member">
                  <li>
                    <span className="addproj">
                      <i
                        className="fa fa-plus-circle"
                        onClick={this.handleOpenAddParticipentPopup}
                      ></i>
                    </span>

                    <p className="text-center">
                      Add <br />
                      Participant
                    </p>
                  </li>

                  {members.map((member) => (
                    <li className="cm_bor_right" key={member.employeeId} style={{ position: "relative" }}>
                      <img
                        className="user_img1"
                        src={
                          member.profileImage
                            ? member.profileImage
                            : require("../../asset/imgs/user-img.png")
                        }
                        alt=""
                      />
                      <p className="text-center">
                        {member.firstName} <br />
                        {member.lastName}
                      </p>
                      <span className="deselect_emp">
                        <i
                          className="fa fa-window-close-o"
                          aria-hidden="true"
                          onClick={() =>
                            this.handleOnDSelectEmp(member.employeeId)
                          }
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              {membersErMsg ? (
                <span className="error_msg2">{membersErMsg}</span>
              ) : null}
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-10">
                <div className="row">
                  <div className="col-6">
                    <label className="sub">Client Name</label>
                    <input
                      maxLength="50"
                      type="text"
                      className="form-control cm_box"
                      placeholder="Client name"
                      name="clientName"
                      value={clientName}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="col-6">
                    <label className="sub">Mail ID</label>
                    <input
                      maxLength="50"
                      type="text"
                      className="form-control cm_box"
                      placeholder="Enter mail Id"
                      name="mailId"
                      value={mailId}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <label className="sub mt-4 mb-4">Project Description</label>
          <textarea
            type="text"
            className="form-control cm-des cm_box"
            placeholder="Project description"
            name="projectDescription"
            maxLength={500}
            value={projectDescription}
            onChange={this.handleChange}
          ></textarea>
          <div className="text-left mt-5 mb-5 grant_access">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <Link
              to="/projects/manager-projects"
              className="cancel_btn btn btn-default ml-4 cm_btn2"
            >
              Cancel
            </Link>
            {responseError ? (
              <h3 className=" Registration_error mt-5">**{responseError}**</h3>
            ) : (
              ""
            )}
          </div>

          <Modal
            size="md"
            show={openAddParticipentPopup}
            onHide={this.closeModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
            className="grant_access"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="text-center">
              <div className="grant_access">
                <h3 className="mb-4">ADD PARTICIPENT</h3>

                <h4 className="mb-3 cm_bold text-left">Select Email</h4>
                <div
                  className={`form-group ${assignedToErMsg ? "input_error" : ""
                    }`}
                >
                  <div className="selectHeight selectDesig">
                    <Select
                      mode="multiple"
                      style={{ width: "100%", height: "auto" }}
                      className="selectHeight"
                      placeholder="Select/Search by Employee Email ID"
                      onDeselect={(i) => this.handleOnDSelect(i)}
                    // defaultValue={this.state.assignedTo}
                    >
                      {Children}
                    </Select>
                    {assignedToErMsg ? (
                      <span
                        className=""
                        style={{ paddingLeft: "", color: "red", bottom: "-22" }}
                      >
                        {assignedToErMsg}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button
                    className="btn btn-primary  mr-2 btn-sm cm_btn3"
                    onClick={this.handleParticipant}
                  >
                    Add
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </form>
    );
  }
}
export default AddProjectByManager;

import React from "react";
import { DocIcon, PdfIcon } from "../svg";
import { IoMdClose } from "react-icons/io";
import "./ApplyLeave.css";
import { LEAVE_UPLOAD_DOC_FILE, LEAVE_UPLOAD_DOC_MAX, leaveUploadDocTypeImgArray, leaveUploadDocTypeImgRegex, leaveUploadDocTypePdfRegex, type } from "../helper/Constant";

const UploadDocument = (props) => {
  const { dispatch, uploadDocument, uploadDocumentName, uploadDocumentErrMsg } =
    props;
    
    const handleDocumentChange = (e) => {
        let reader = new FileReader();
        let files = [];
        let errorMessage = "";
      
        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files[i];
          const extension = file.name.split(".").pop().toLowerCase();
          if (files.length >= 5) {
            errorMessage = LEAVE_UPLOAD_DOC_MAX;
            break;
          } else if (!leaveUploadDocTypeImgArray.includes(extension)) {
            errorMessage = LEAVE_UPLOAD_DOC_FILE;
            continue;
          }
          files.push(file);
        }      
        if (!errorMessage) {
          dispatch({
            type: type,
            payload: {
              uploadDocument: [...uploadDocument, ...files],
              uploadDocumentName: [...uploadDocumentName, ...files.map((item) => item.name)],
              uploadDocumentErrMsg: "" 
            },
          });
          if (files.length > 0) {
            reader.readAsDataURL(files[0]);
          }
        } else {
          dispatch({
            type: type,
            payload: {
              uploadDocumentErrMsg: errorMessage
            },
          });
        }
      };
            
  const handleDeleteDocumentIconClick = (index) => {
    const updatedUploadDocument = uploadDocument.filter(
      (_, itemIndex) => itemIndex !== index
    );
    const updatedUploadDocumentName = uploadDocumentName.filter(
      (_, itemIndex) => itemIndex !== index
    );

    dispatch({
      type: type,
      payload: {
        uploadDocument: updatedUploadDocument,
        uploadDocumentName: updatedUploadDocumentName,
      },
    });
  };

  return (
    <div>
      <div className="row">
        <div className={`form-group col-md-4 col-sm-12 ${
                    uploadDocumentErrMsg ? "input_error" : ""
                  }`}>
          <label className="sub mt-4" for="upload-files">
            Upload Document
          </label>
          <span className="form-control cm_hide_upload">
            <i className="fa fa-upload" aria-hidden="true"></i>
            <span className="cm_upload_name">Upload File</span>
            <input
              type="File"
              className=""
              title=""
              multiple
              id="upload-files"
              onChange={(e) => handleDocumentChange(e)}
            />
          </span>
          {uploadDocumentErrMsg && (
            <span className="error_msg">{uploadDocumentErrMsg}</span>
          )}
        </div>
      </div>
      {uploadDocument.length > 0 && (
        <>
          <label className="sub">Selected Document: </label>
          <div
            className="date-border doc-upload "
          >
            {uploadDocument.length > 0 &&
              uploadDocument.map((item, index) => (
                <div className="upload_doc_container">
                  {item.name.match(leaveUploadDocTypeImgRegex) ? (
                    <img
                      src={URL.createObjectURL(item)}
                      className="image-size"
                      alt="img"
                    />
                  ) : item.name.match(leaveUploadDocTypePdfRegex) ? (
                    <div className="image-size">
                      <PdfIcon />
                    </div>
                  ) : (
                    <div className="image-size">
                      <DocIcon />
                    </div>
                  )}
                  <label style={{ width: "80%" }}>{item.name}</label>
                  <div onClick={() => handleDeleteDocumentIconClick(index)}>
                    <IoMdClose className="mdClose" />
                  </div>
                </div>
              ))}
          </div>
          
        </>
      )}
    </div>
  );
};

export default UploadDocument;

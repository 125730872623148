import React from 'react';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Loader } from '../svg';

import { addDesignation, getDesignation, editDesignation, deleteDesignation } from '../../config/services/employeeServices';
import ResponseFilter from '../../ResponseFilter';

class AdminDesignation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openEditDesignationPopup: false,
            openDeleteDesignationPopup: false,
            openAddDesignationPopup: false,
            designationNameErrorMsg: '',
            miniLoader: false,
            designationsList: [],
            designationId: '',
            search: '',
            loader: true,
            resMsg: '',
            sortType: 2,
            sortField: "created",
            resAdd: false,
            resEdit: false,
            resDelete: false,
        }
        this.debounceSearch = null
    }
    handleChange = e => this.setState({
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        resMsg: ''
    });
    handleSearch = e => {
        this.setState({ search: e.target.value })
        clearTimeout(this.debouncedSearch);
        this.debounceSearch = setTimeout(() => {
            this.setState({ loader: true })
            this.getDesignationList();
        }, 2000);
    }
    handleSelectChange = () => {
        this.setState({ search: '' }, () => this.getDesignationList())
    }
    submitSearch = e => {
        e.preventDefault();
        this.setState({ loader: true })
        this.getDesignationList()
    }



    getDesignationList = () => {

        let { search, sortType, sortField } = this.state
        let params = `search=${search.trim()}&sortType=${sortType}&sortField=${sortField}`;
        this.props.history.push(`admin-designation?search=${search}&sortType=${sortType}&sortField=${sortField}`)

        getDesignation(params).then(res => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({
                    designationsList: res.data.responseData.designationsList
                }, () => this.setState({ loader: false }, () => this.closeModal()))
            }

        })
    }

    componentDidMount() {
        let url_string = window.location.href
        let url = new URL(url_string);
        let pageNo = url.searchParams.get("pageNo");
        let status = url.searchParams.get("status");
        let search = url.searchParams.get("search");
        let sortType = url.searchParams.get("sortType");
        let sortField = url.searchParams.get("sortField");
        this.setState({
            search: search ? search : "",
            sortType: sortType ? sortType : 1,
            sortField: sortField ? sortField : "",
        }, () => {
            this.getDesignationList();
        })

    }

    handleOpenEditDesignationPopup = ({ designationId, designationName }) => this.setState({ openEditDesignationPopup: !this.state.openEditDesignationPopup, designationId, designationName });
    handleOpenDeleteDesignationPopup = ({ designationId }) => this.setState({ openDeleteDesignationPopup: !this.state.openDeleteDesignationPopup, designationId });
    handleOpenAddDesignationPopup = () => this.setState({ openAddDesignationPopup: !this.state.openAddDesignationPopup });

    closeModal = () => this.setState({
        openEditDesignationPopup: false,
        openDeleteDesignationPopup: false,
        openAddDesignationPopup: false,
        designationName: '',
        designationNameErrorMsg: '',
        miniLoader: false,
        designationId: '',
        numOfEmployees: '',
        resMsg: '',
    });

    handleValidetion = () => {
        let validate = true;
        const { designationName } = this.state;

        if (designationName === '' || designationName === undefined) {
            validate = false;
            this.setState({ designationNameErrorMsg: 'Designation name is required' })
        }
        else {
            this.setState({ designationNameErrorMsg: '' })
        }



        return validate;
    }

    handleSubmit = event => {
        event.preventDefault();
        let { designationName } = this.state;
        if (this.handleValidetion()) {
            this.setState({ miniLoader: true });

            let obj = {
                'designationName': designationName,
            }
            addDesignation(obj).then(res => {
                if (res.data.statusCode === 1) {
                    this.setState({
                        miniLoader: false,
                        resAdd: true,
                    }, () => {
                        this.getDesignationList()
                        this.closeModal();
                    }

                    )
                }
                else {
                    this.setState({ miniLoader: false, resMsg: res.data.error.responseMessage, openAddDesignationPopup: true })
                }
            })
        }
        setTimeout(() => this.setState({ resAdd: false }), 3000);
    }
    handleEditSubmit = event => {
        event.preventDefault();
        let { designationName, designationId } = this.state;
        if (this.handleValidetion()) {
            this.setState({ miniLoader: true });

            let obj = {
                'designationId': designationId,
                'designationName': designationName
            }
            editDesignation(obj).then(res => {

                if (res.data.statusCode === 1) {
                    this.setState({
                        miniLoader: false,
                        resEdit: true,
                    }, () => {
                        this.getDesignationList()
                        this.closeModal();
                    }

                    )
                }
                else {
                    this.setState({ miniLoader: false, resMsg: res.data.error.responseMessage, openEditDesignationPopup: true })

                }
            })
        }
        setTimeout(() => this.setState({ resEdit: false }), 3000);

    }




    handleDelete = () => {

        let obj = {
            data: {
                designationId: this.state.designationId,
                status: 2
            }
        }
        this.setState({ miniLoader: true })
        deleteDesignation(obj)
            .then(res => {
                this.setState({ miniLoader: false, resDelete: true }, () => this.getDesignationList())
            })
        setTimeout(() => this.setState({ resDelete: false }), 3000);
    }




    render() {
        let {
            openEditDesignationPopup,
            openDeleteDesignationPopup,
            openAddDesignationPopup,
            designationName,
            designationNameErrorMsg,
            miniLoader,
            designationsList,
            search,
            loader,
            resMsg,
            resAdd,
            resDelete,
            resEdit,
        } = this.state;




        return (
            <div className="body_container cm_bold">
                {
                    loader && <Loader />
                }
                {
                    resAdd ?
                        <div className="alert alert-success cm_top_fix">New Designation Added Successfully</div> : ''
                }
                {
                    resDelete ?
                        <div className="alert alert-danger cm_top_fix">Designation Deleted Successfully</div> : ''
                }
                {
                    resEdit ?
                        <div className="alert alert-warning cm_top_fix">Designation Edited Successfully</div> : ''
                }
                <h3 className="mb-2">DESIGNATION MANAGEMENT</h3>


                <form className="row d-flex justify-content-end mb-4">
                    <div className="col-md-6 search_device_bar1">
                        <span className="btn btn-primary add_empBtn add1" onClick={this.handleOpenAddDesignationPopup}>Add New Designation</span>
                        <input className="form-control mr-lg-2 cm_box"
                            maxLength={25}
                            type="search"
                            placeholder="Search by name"
                            aria-label="Search"
                            value={search}
                            onChange={this.handleSearch}
                        />
                        {search ? <span className="cm_clear3" onClick={this.handleSelectChange}><i className="fa fa-times-circle" aria-hidden="true"></i></span> : <span className="cm_clear3"><i className="fa fa-search" aria-hidden="true" ></i></span>}

                    </div>
                </form>


                <div className={` ${designationsList.length > 0 ? "department-modal" : ""}`}>

                    {
                        !_.isEmpty(designationsList) ?
                            designationsList.map(designations => (
                                <div className="col-12" key={designations.designationId}>
                                    <Link title="View designation" to={`/admin-designation/${designations.designationId}`}>
                                        <div className="cm_card cm_card_dept">


                                            <div className="cm_style design">

                                                <p className="mb-2 cm_dept_dots">{designations.designationName}</p>
                                                <span className="emp_count cm_bold">{designations.numOfEmployees ? designations.numOfEmployees : 0}</span>
                                            </div>



                                        </div>
                                    </Link>
                                    <div className="text-right department_btn">
                                        <button type="button" className="btn btn-primary btn-sm mr-4" onClick={() => this.handleOpenEditDesignationPopup(designations)}>Edit</button>
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleOpenDeleteDesignationPopup(designations)}>Delete</button>

                                    </div>
                                </div>

                            )) : <h3 className="text-center ">No data available</h3>

                    }

                </div>
                <Modal
                    size="md"
                    show={openAddDesignationPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="add-new-dept" onSubmit={this.handleSubmit}>
                            <h3>ADD NEW DESIGNATION</h3>



                            <div className={`form-group ${designationNameErrorMsg || resMsg ? 'input_error' : ''}`}>
                                <label className="cm_bold">DESIGNATION</label>
                                <input
                                    maxLength="75"
                                    className="form-control cm_box"
                                    type="text"
                                    placeholder="Enter Designation"
                                    name="designationName"
                                    value={designationName}
                                    onChange={this.handleChange}
                                />
                                {designationNameErrorMsg ? <span className="error_msg">{designationNameErrorMsg}</span> : null}
                                {<span className="res_error_msg">{resMsg ? resMsg : ''}</span>}
                            </div>

                            {miniLoader ? <div className="mini_loader_dept"></div> : ''}
                            <div className="submit_button_margin mt-4 ml-5">
                                <button type="submit" className="btn btn-primary  cm_btn3" disabled={miniLoader}>Save</button>
                                <button type="button" className="btn btn-primary  cm_btn2" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>



                <Modal
                    size="md"
                    show={openEditDesignationPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="add-new-dept" onSubmit={this.handleEditSubmit}>

                            <div className={`form-group ${designationNameErrorMsg || resMsg ? 'input_error' : ''}`}>
                                <h3>EDIT DESIGNATION</h3>
                                <label className="cm_bold">DESIGNATION</label>
                                <input
                                    maxLength="75"
                                    className="form-control cm_box"
                                    type="text"
                                    placeholder="Enter Designation"
                                    name="designationName"
                                    value={designationName}
                                    onChange={this.handleChange}
                                />
                                {designationNameErrorMsg ? <span className="error_msg">{designationNameErrorMsg}</span> : null}
                                {<span className="res_error_msg">{resMsg ? resMsg : ''}</span>}
                            </div>

                            {miniLoader ? <div className="mini_loader_dept"></div> : ''}
                            <div className="submit_button_margin mt-4 ml-5">
                                <button className="btn btn-primary cm_btn3">Save</button>
                                <button type="button" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>

                            </div>

                        </form>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="md"
                    show={openDeleteDesignationPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="text-center confirmation">
                        <p className="mb-4 cm_bold cnfrm_msg">Are you sure, you want to delete?</p>
                        {miniLoader ? <div className="mini_loader_dept"></div> : ""}
                        <div className='submit_button_margin mt-4'>
                            <button className="btn btn-danger cm_btn3" onClick={this.handleDelete}>Delete</button>
                            <button type="button" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}
export default AdminDesignation;

import { type } from "../../components/helper/Constant";
import { editCourierDetailsByAdmin } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
  EDIT_COURIER_BY_ADMIN_REQUEST,
  EDIT_COURIER_BY_ADMIN_SUCCESS,
  EDIT_COURIER_BY_ADMIN_FAILURE,
} from "./actionType";

const editCourierDetailsByAdminAction = (props) => {
  let { useDispatch, params, getCourierList, courierId } = props;
  return (dispatch) => {
    dispatch(editCourierDetailsByAdminRequest());
    editCourierDetailsByAdmin(params,courierId)
      .then((res) => {
        let { status } = ResponseFilter(res);
        if (status) {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              resMsg: true,
              successMessage: "Courier Edited",
            },
          });
          setTimeout(() => {
            useDispatch({
              type: type,
              payload: {
                resMsg: false,
              },
            });
          }, 3000);
          // handleClose();
          getCourierList();
        } else {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              resFailMsg: true,
              responseMessage: res?.data?.error?.responseMessage,
            },
          });
          setTimeout(() => {
            useDispatch({
              type: type,
              payload: {
                resFailMsg: false,
              },
            });
          }, 3000);
          // handleClose();
          getCourierList();
        }
      })
      .catch((error) => {
        dispatch(editCourierDetailsByAdminFailure(error));
      });
  };
};

const editCourierDetailsByAdminRequest = () => {
  return {
    type: EDIT_COURIER_BY_ADMIN_REQUEST,
  };
};
const editCourierDetailsByAdminSuccess = (data) => {
  return {
    type: EDIT_COURIER_BY_ADMIN_SUCCESS,
    payload: data,
  };
};
const editCourierDetailsByAdminFailure = (error) => {
  return {
    type: EDIT_COURIER_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  editCourierDetailsByAdminAction,
  editCourierDetailsByAdminRequest,
  editCourierDetailsByAdminSuccess,
  editCourierDetailsByAdminFailure,
};

export const awardType = (type) => {
    switch (type) {
        case 1:
            return "Office"
            break;
        case 2:
            return "Client Side"
            break;
    }
}

export const changetoUpperCase = (inputString) => {
    return inputString.replace(/(^\w|\.\s+\w)/gm, function (sentence) {
        return sentence.toUpperCase();
    });
}

import React from 'react'
import { Modal } from 'react-bootstrap'
import { Select } from 'antd';
import _ from 'lodash';
import { handleEmployeeName } from '../SalaryFunctions';
const { Option } = Select
export default function GenerateSalaryModal(props) {
    return (
        <Modal centered show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title className='text-center'><b>Mark LWP </b></Modal.Title>
            </Modal.Header>
            <Modal.Body className={`assign_device ${props.miniLoader ? "admin_attendance" : ""}`}>
                <div className="row">
                    <span className='red  ml-2'>* Please select employee to add</span>
                    <div
                        className={`form-group col-12 ${props.addEmployeeErrorMessage ? "input_error" : ""}`}>
                        <label className="cm_bold">Employee Name</label>
                        <Select
                            showSearch
                            mode='multiple'
                            name="employeeName"
                            value={props.addEmployeeList ? props.addEmployeeList : " Select Employee"}
                            placeholder="Select Name"
                            onChange={(e) => props.handleSelectEmployeeChange(e, "lwp")}
                            optionFilterProp="key"
                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                            disabled={props.miniLoader}
                        >
                            {!_.isEmpty(props.employeeList)
                                ? props.employeeList.sort(function (a, b) {
                                    if (a.firstName < b.firstName) { return -1; }
                                    if (a.firstName > b.firstName) { return 1; }
                                    return 0;
                                }).map(
                                    (employee) =>
                                        employee?.status == 1 && (
                                            <Option
                                                key={`${employee.firstName} ${employee.lastName}`}
                                                value={employee.employeeId}
                                            >
                                                <span>
                                                    {employee.firstName} {employee.lastName} ({employee.empId})
                                                </span>
                                            </Option>
                                        )
                                )
                                : ""}
                        </Select>
                        {props.addEmployeeErrorMessage ? (
                            <span className="error_msg">{props.addEmployeeErrorMessage}</span>
                        ) : null}
                    </div>
                    {/* <div className={`form-group col-6 `}>
                        <button className='btn btn-primary' onClick={() => props.handlegenerateLwpEmployeeList()}>Add</button>
                    </div> */}
                </div>
                <div className='row' style={{ maxHeight: "500px", overflowY: "auto" }}>
                    {props?.lwpEmployee.map((element, index) => (
                        <div className="form-inline col-lg-12" key={index}>

                            <div className={`form-group col-lg-6 div_form_label`}>
                                <label className="cm_bold">Employee Name</label>
                                <input type="text" name="employeeName" className="form_control cm_box" value={handleEmployeeName(element?.employeeId, props?.employeeList)} disabled />
                            </div>

                            <div className={`form-group col-lg-6 div_form_label ${element.numberOfLwpCheck ? "input_error" : ""
                                }`}>
                                <label className="cm_bold"> Number Of LWP</label>
                                <input type="number" name="numberOfLwp" className="form_control cm_box" value={element?.numberOfLwp ? element?.numberOfLwp : ""} placeholder="Enter Number of Lwp" onChange={e => props.handleLwpChange(index, e, props?.lwpEmployee)} />
                                {/* <span style={{ color: 'red' }}>{element.numberOfLwpCheck}<br />{element.ratingFormat}</span> */}
                            </div>
                            <div style={{ color: 'red' }} className='salary_margin'>{element.numberOfLwpCheck}<br />{element.ratingFormat}</div>
                        </div>
                    ))}

                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <div className="submit_button_margin mt-4">
                    {props.miniLoader ? <div className="mini_loader_dept"></div> : ""}
                    <button
                        className="btn btn-danger cm_btn3 btn-sm"
                        onClick={props.handleSubmit}
                        disabled={props.miniLoader || props?.lwpEmployee.length <= 0}
                    >
                        Yes
                    </button>
                    <button
                        onClick={props.handleClose}
                        className="btn btn-primary cm_btn2 btn-sm"
                        disabled={props.miniLoader || props?.lwpEmployee.length > 0 || (props?.lwpEmployee.length >= 0 && props.addEmployeeList.length > 0)}
                    >
                        Next
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

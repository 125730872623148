import React from "react";
import { Modal } from "react-bootstrap";

const DeleteCompOffModal = (props) => {
  const {
    show,
    handleClose,
    deleteCompOffNo,
    handleDeleteCompOffChange,
    deleteCompOffNoErrMsg,
    handleDeleteCompOff,
  } = props;
  
  return (
    <div>
      {" "}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>DELETE COMP OFF</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="confirmation">
          <input
            className="form-control cm_box"
            type="number"
            step="any"
            placeholder=" Enter no. of day"
            name="CompLeave"
            value={deleteCompOffNo}
            onChange={handleDeleteCompOffChange}
          />
          {deleteCompOffNoErrMsg ? (
            <span className="error_msg ml-2">{deleteCompOffNoErrMsg}</span>
          ) : null}
          <div className="text-center mt-5">
            <button
              className="btn btn-danger mr-2 btn-sm cm_btn3"
              onClick={handleDeleteCompOff}
            >
              Delete
            </button>
            <button
              className="btn btn-primary cm_btn2  mr-2 btn-sm cm_btn3"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteCompOffModal;

import React, { useReducer } from "react";
import { handleIsoDate, reducer, type } from "../helper/Constant";
import { LeaveInfo } from "./Constants";

const initialState = {
  openLeaveDetaÏil: false,
  index: "",
  leaveTitle: "",
};
function EmpLeaveDetail(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { openLeaveDetail, index, leaveTitle } = state;

  let {
    totalCl,
    totalEl,
    totalSl,
    totalLwpTaken,
    compOff,
    remainingMonthlyShortLeave,
    assignedCl,
    assignedEl,
    assignedSl,
    tempCompOff,
    employeeType,
    totalCompOffLeaves,
    totalCompOff,
  } = props;
  const temporaryCompOff = tempCompOff
    ? tempCompOff
        .filter((record) => record.expireDate >= handleIsoDate(new Date()))
        .map((record) => record.numberOFCompOff)
        .reduce((acc, curr) => acc + curr, 0)
    : 0;

  const showLeaveDetail = (index, title) => {
    dispatch({
      type: type,
      payload: {
        openLeaveDetail: true,
        index: index,
        leaveTitle: title,
      },
    });
  };
  const hideLeaveDetail = () => {
    dispatch({
      type: type,
      payload: {
        openLeaveDetail: false,
        index: "",
        leaveTitle: "",
      },
    });
  };
  return (
    <div className="row sm_card cm_bold text-center">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4">
            <div className="cm_card card1 card_padd">
              <p className="d-flex justify-content-end">
                <i
                  class="fa fa-info-circle fa-lg"
                  aria-hidden="true"
                  onMouseEnter={() => showLeaveDetail(1, "Casual Leave")}
                  onMouseOut={hideLeaveDetail}
                ></i>
              </p>
              <p>Casual Leaves</p>
              <span
                className={`${
                  totalCl === 0 ? "cm_no text-danger" : "cm_no skyblue"
                }`}
              >
                {isNaN(totalCl) ? "-" : totalCl < 0 ? 0 : totalCl}
              </span>
              <p>
                {isNaN(totalCl) ? "-" : assignedCl - totalCl}/
                {assignedCl ? assignedCl : 0}
              </p>
              <p>Taken/Assigned</p>
              {index === 1 && (
                <LeaveInfo
                  openLeaveDetail={openLeaveDetail}
                  index={index}
                  leaveTitle={leaveTitle}
                />
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="cm_card card2 card_padd">
              <p className="d-flex justify-content-end">
                <i
                  class="fa fa-info-circle fa-lg"
                  aria-hidden="true"
                  onMouseEnter={() => showLeaveDetail(2, "Earned Leave")}
                  onMouseOut={hideLeaveDetail}
                ></i>
              </p>
              <p>Earned Leaves</p>
              <span
                className={`${
                  totalEl === 0 ? "cm_no text-danger" : "cm_no purple"
                }`}
              >
                {isNaN(totalEl) ? "-" : totalEl < 0 ? 0 : totalEl}
              </span>
              <p>
                {isNaN(totalEl) ? "-" : assignedEl - totalEl}/
                {assignedEl ? assignedEl : 0}
              </p>
              <p>Taken/Assigned</p>
              {index === 2 && (
                <LeaveInfo
                  openLeaveDetail={openLeaveDetail}
                  index={index}
                  leaveTitle={leaveTitle}
                />
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="cm_card card3 card_padd">
              <p className="d-flex justify-content-end">
                <i
                  class="fa fa-info-circle fa-lg"
                  aria-hidden="true"
                  onMouseEnter={() => showLeaveDetail(3, "Sick Leave")}
                  onMouseOut={hideLeaveDetail}
                ></i>
              </p>
              <p>Sick Leaves</p>
              <span
                className={`${
                  totalSl === 0 ? "cm_no text-danger" : "cm_no blue"
                }`}
              >
                {isNaN(totalSl) ? "-" : totalSl < 0 ? 0 : totalSl}
              </span>
              <p>
                {isNaN(totalSl) ? "-" : assignedSl - totalSl}/
                {assignedSl ? assignedSl : 0}
              </p>
              <p>Taken/Assigned</p>
              {index === 3 && (
                <LeaveInfo
                  openLeaveDetail={openLeaveDetail}
                  index={index}
                  leaveTitle={leaveTitle}
                />
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="cm_card card4 card_padd">
              <p className="d-flex justify-content-end">
                <i
                  class="fa fa-info-circle fa-lg"
                  aria-hidden="true"
                  onMouseEnter={() => showLeaveDetail(4, "Compensatory Off")}
                  onMouseOut={hideLeaveDetail}
                ></i>
              </p>
              <p> Comp Off </p>
              <span className="cm_no yellow">
                {isNaN(compOff)
                  ? "0"
                  : compOff < 0
                  ? 0 + temporaryCompOff
                  : compOff + temporaryCompOff}
              </span>
              <p>
                {isNaN(totalCompOffLeaves) ? 0 : totalCompOffLeaves}/
                {totalCompOff ? totalCompOff : 0}/{temporaryCompOff}
              </p>
              <p>Taken/Assigned/Temp CompOff </p>
              {index === 4 && (
                <LeaveInfo
                  openLeaveDetail={openLeaveDetail}
                  index={index}
                  leaveTitle={leaveTitle}
                />
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="cm_card card5 marked-card_padd">
              <p className="d-flex justify-content-end">
                <i
                  class="fa fa-info-circle fa-lg"
                  aria-hidden="true"
                  onMouseEnter={() => showLeaveDetail(5, "LWP")}
                  onMouseOut={hideLeaveDetail}
                ></i>
              </p>
              <p>Marked LWP</p>
              <span className="cm_no red">
                {isNaN(totalLwpTaken)
                  ? "-"
                  : totalLwpTaken === 0
                  ? 0
                  : totalLwpTaken}
              </span>
              <p>
                {isNaN(totalLwpTaken)
                  ? "0"
                  : totalLwpTaken === 0
                  ? 0
                  : totalLwpTaken}
              </p>
              <p>Taken</p>
              {index === 5 && (
                <LeaveInfo
                  openLeaveDetail={openLeaveDetail}
                  index={index}
                  leaveTitle={leaveTitle}
                />
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="cm_card card6 card_padd">
              <p className="d-flex justify-content-end">
                <i
                  class="fa fa-info-circle fa-lg"
                  aria-hidden="true"
                  onMouseEnter={() => showLeaveDetail(6, "Short Leave")}
                  onMouseOut={hideLeaveDetail}
                ></i>
              </p>
              <p> Short Leave</p>
              {employeeType == 4 ? (
                <>
                  <span className="cm_no red">0</span>
                  <p>0/0</p>
                </>
              ) : (
                <>
                  <span className="cm_no red">
                    {isNaN(remainingMonthlyShortLeave)
                      ? "-"
                      : remainingMonthlyShortLeave}
                  </span>
                  <p>{2 - remainingMonthlyShortLeave}/2</p>
                </>
              )}
              <p>Taken/Assigned </p>
              {index === 6 && (
                <LeaveInfo
                  openLeaveDetail={openLeaveDetail}
                  index={index}
                  leaveTitle={leaveTitle}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmpLeaveDetail;

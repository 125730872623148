const CryptoJS = require("crypto-js");

export const encryptRequestData = (requestData) => {
  const secretKey = localStorage.getItem("secretKey");
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(requestData), decryptSecretKey(secretKey)).toString();
  return { data: encryptedData};
};

export const decryptResponseData = (response) => {
  const secretKey = localStorage.getItem("secretKey");
  let bytes  = CryptoJS.AES.decrypt(response,decryptSecretKey(secretKey));
  let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return originalText;
};

export const decryptSecretKey = (key) => {
  let bytes  = CryptoJS.AES.decrypt(key,'7934812009K@#E#Rhkdjs');
  return bytes.toString(CryptoJS.enc.Utf8).replace(/^"(.*)"$/, '$1');
};

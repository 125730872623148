import React from "react";
import { Modal } from "react-bootstrap";
import DOMPurify from "dompurify";
import { Modal as AntModal } from "antd";

export default function ViewLeaveReason(props) {
  const { reason, handleClose, show, data, handleOpenViewDocPopup } = props;
  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(reason),
    };
  }

  return (
    <AntModal
      title=""
      footer={false}
      centered
      className="upload-modal"
      visible={show}
      onCancel={handleClose}
    >
      <Modal.Header>
        <Modal.Title>
          <b>REASON FOR LEAVE</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirmation">
        <div>
          <div dangerouslySetInnerHTML={createMarkup()} />
          {data && data.length > 0 && (
            <div>
              <hr />
              <button
                onClick={() => handleOpenViewDocPopup(data)}
                className="btn btn-primary btn-sm mr-1 mb-1"
              >
                View Uploaded File
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
    </AntModal>
  );
}

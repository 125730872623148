import React from 'react'
import { CONSTANTS } from '../../config'
import moment from 'moment'
import _ from 'lodash'
import { officeLocationValue } from '../../config/constants/Constants'
import Pagination from 'react-js-pagination'
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS

export default function MonthlyReimbursementStats(props) {
    let { employeesMonthlyReimbursementStats, pageNo, count, dataCount, handlePageChange, handleSort, sortType, sortField, } = props

    return (
        <div>
            <div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>
                                <th>Employee Id
                                    <i className={
                                        sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "mobId"
                                            ? "fa fa-sort-numeric-asc ml-2"
                                            : "fa fa-sort-numeric-desc ml-2"
                                    } onClick={() => handleSort("mobId")}></i>
                                </th>
                                <th>
                                    Name
                                    <i className={
                                        sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "firstName"
                                            ? "fa fa-sort-alpha-asc ml-2"
                                            : "fa fa-sort-alpha-desc ml-2"
                                    } onClick={() => handleSort("firstName")}></i>
                                </th>
                                <th scope='col'>Office Location</th>
                                <th scope="col">Pan Number</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Department</th>
                                <th scope="col">D.O.J</th>
                                <th scope="col">UAN</th>
                                <th scope="col">IFSC</th>
                                <th scope="col">Account Number</th>
                                <th scope="col">Reiumbursement Amount</th>
                                <th scope="col">Expense Detail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!_.isEmpty(employeesMonthlyReimbursementStats) ? employeesMonthlyReimbursementStats.map((data, i) => (
                                <tr key={data.employeeId}>
                                    <td>
                                        <span className="cm_col">
                                            {i + 1 + ((pageNo - 1) * 10)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.empId}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.firstName + " " + data?.middleName + " " + data?.lastName}
                                        </span>
                                    </td>

                                    <td>
                                        <span className="cm_col">
                                            {officeLocationValue(data?.decryptedData?.officeLocation)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.panCard}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.designationName?.map((item, index) => (
                                                <span key={index}>
                                                    {item}
                                                    {index < data?.designationName?.length - 1 && ', '}
                                                </span>
                                            ))}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.departmentName?.map((item, index) => (
                                                <span key={index}>
                                                    {item}
                                                    {index < data?.departmentName?.length - 1 && ', '}
                                                </span>
                                            ))}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {moment.utc(data?.dateOfJoining).format("DD/MM/YYYY")}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.UAN}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.ifscCode}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.accountNumber}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {parseFloat(data?.decryptedData?.reimbursement).toFixed(2)}
                                        </span>
                                    </td>

                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.expenseDescription}
                                        </span>
                                    </td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </table>

                    {employeesMonthlyReimbursementStats && employeesMonthlyReimbursementStats.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                </div>

                {dataCount > 10 ? (
                    <Pagination
                        activePage={+(pageNo)}
                        itemsCountPerPage={count}
                        totalItemsCount={dataCount}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange}
                    />
                ) : (
                    ""
                )}
            </div>
            {
                employeesMonthlyReimbursementStats.length > 0 ?
                    <button className='btn btn-primary mt-4 mb-3' onClick={props?.handleExportMonthlyStats}>Export Monthly Reimbursement Stats</button> : ""
            }
        </div>

    )
}

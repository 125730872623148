import {
    GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_REQUEST,
    GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_SUCCESS,
    GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { STATUS_ZERO } from "../../components/attendance/Constants";
import { getProjectActivityByEmployeeId } from "../../config/services/employeeServices";

const getProjectActivityByEmplyeeIdAction = (props) => {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getProjectActivityByEmplyeeIdActionRequest());
        getProjectActivityByEmployeeId(params).then(res => {
            useDispatch({
                type: 'typeCheckSet', payload: {
                    loader: false
                }
            })
            let { status, resData } = ResponseFilter(res);
            let statusCount = resData.statusCount
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        projectActivity: resData?.dailyWorkDetails,
                        activityCount: resData?.dataCount,
                    }
                })
            }
            else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        resMsgFail: true,
                        responseMessage: res.data.error.responseMessage,
                    }
                })
            }
        })
            .catch((error) => {
                dispatch(getProjectActivityByEmplyeeIdActionFailure(error));
            });
    };
};

const getProjectActivityByEmplyeeIdActionRequest = () => {
    return {
        type: GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_REQUEST,
    };
};

const getProjectActivityByEmplyeeIdActionSuccess = (data) => {
    return {
        type: GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_SUCCESS,
        payload: data,
    };
};

const getProjectActivityByEmplyeeIdActionFailure = (error) => {
    return {
        type: GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_FAILURE,
        payload: error,
    };
};
export {
    getProjectActivityByEmplyeeIdAction,
    getProjectActivityByEmplyeeIdActionRequest,
    getProjectActivityByEmplyeeIdActionSuccess,
    getProjectActivityByEmplyeeIdActionFailure,
};
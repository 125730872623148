import React from 'react'
import Pagination from 'react-js-pagination'
import { CONSTANTS } from '../../config'
import moment from 'moment'
import _ from 'lodash'
import { officeLocationValue } from '../../config/constants/Constants'
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS

export default function MonthlySalaryStatus(props) {

    let { employeesMonthlySalaryStats, pageNo, count, dataCount, handlePageChange, handleSort, sortType, sortField, } = props

    return (

        <div>
            <div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>
                                <th>Empolyee Id
                                    <i className={
                                        sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "mobId"
                                            ? "fa fa-sort-numeric-asc ml-2"
                                            : "fa fa-sort-numeric-desc ml-2"
                                    } onClick={() => handleSort("mobId")}></i>
                                </th>
                                <th>
                                    Name
                                    <i className={
                                        sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "employeeName"
                                            ? "fa fa-sort-alpha-asc ml-2"
                                            : "fa fa-sort-alpha-desc ml-2"
                                    } onClick={() => handleSort("employeeName")}></i>
                                </th>
                                <th scope="col">Office Location</th>
                                <th scope="col">Pan Number</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Department</th>
                                <th scope="col">D.O.J</th>
                                <th scope="col">UAN</th>
                                <th scope="col">IFSC</th>
                                <th scope="col">Account Number</th>
                                <th scope="col">Monthly CTC</th>
                                <th scope="col">Arrear</th>
                                <th scope="col">LWP</th>
                                <th scope="col">Gross</th>
                                <th scope="col">Basic</th>
                                <th scope="col">EPF Basic</th>
                                <th scope="col">DA</th>
                                <th scope="col">HRA</th>
                                <th scope="col">Conveyance Allowance</th>
                                <th scope="col">Special Allowance</th>
                                <th scope="col">TDS</th>
                                <th scope="col">EPF 12%</th>
                                <th scope="col">CPF 12%</th>
                                <th scope="col">Total PF</th>
                                <th scope="col">Final Total Salary</th>
                                <th scope="col">Bonus Actual</th>
                                <th scope="col">Loan & Other Dedution</th>
                                {/* <th scope="col">Ctc Variable</th> */}

                                <th scope="col">Net Payment after Deductions</th>
                                <th scope="col">Leaves</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!_.isEmpty(employeesMonthlySalaryStats) ? employeesMonthlySalaryStats.map((data, i) => (
                                <tr key={data.employeeId} className={data?.decryptedData?.salaryStatus ? `bg-red` : ""}>
                                    <td>
                                        <span className="cm_col">
                                            {i + 1 + ((pageNo - 1) * 10)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.empId}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.firstName + " " + data?.middleName + " " + data?.lastName}
                                        </span>
                                    </td>

                                    <td>
                                        <span className="cm_col">
                                            {officeLocationValue(data?.decryptedData?.officeLocation)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.panCard}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.designationName?.map((item, index) => (
                                                <span key={index}>
                                                    {item}
                                                    {index < data?.designationName?.length - 1 && ', '}
                                                </span>
                                            ))}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.departmentName?.map((item, index) => (
                                                <span key={index}>
                                                    {item}
                                                    {index < data?.departmentName?.length - 1 && ', '}
                                                </span>
                                            ))}

                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {moment.utc(data?.dateOfJoining).format("DD/MM/YYYY")}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.UAN}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.ifscCode}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.accountData?.accountNumber}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.monthlyCtc}
                                        </span>
                                    </td>

                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.arrear}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.lwp}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.gross}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.basic}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.epfBasic}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.DA}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.HRA}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.conveyanceAllowance}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.specialAllowance}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.TDS}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.EPF}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.CPF}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.totalPF}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.finalTotalSalary}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.bonus}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.loanAndOtherDeduction}
                                        </span>
                                    </td>
                                    {/* <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.ctcVariable}
                                        </span>
                                    </td> */}
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.netPaymentAfterDeduction}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.decryptedData?.numberOfLeaves}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="cm_col">
                                            {data?.email}
                                        </span>
                                    </td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </table>
                    {employeesMonthlySalaryStats && employeesMonthlySalaryStats.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                </div>

                {dataCount > 10 ? (
                    <Pagination
                        activePage={+(pageNo)}
                        itemsCountPerPage={count}
                        totalItemsCount={dataCount}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange}
                    />
                ) : (
                    ""
                )}
            </div>
            {
                employeesMonthlySalaryStats.length > 0 ?
                    <button className='btn btn-primary mt-4 mb-3' onClick={props?.handleExportMonthlyStats}>Export Monthly Salary Stats</button> : ""
            }
        </div >
    )
}

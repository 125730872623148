import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import CONSTANTS from "../../config/constants/Constants";
import { SearchField } from "../../hoc";
import { getTransactionList } from "../../redux/actions";
import { Select } from "antd";
import DotLoader from "../../hoc/DotLoader";
import "./TransactionListStyles.css";
import { useDispatch } from "react-redux";
import { getTransactionExcelList } from "../../redux/actions/getTransactionExcelSheetAction";
import { GET_TRANSACTION_EXCEL_SUCCESS } from "../../redux/actions/actionType";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs"
import { useMemo } from "react";
import _ from "lodash";
import { useDebounce } from "../attendance/Constants";
const { Option } = Select;
let arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i <= 3; i++) {
  arrayOfYear.push(currentYear - i);
}
const arrayOfMonths = CONSTANTS.TRANSACTION_LIST_CONSTANTS.ARRAY_OF_MONTHS;
const amountTypeArray = CONSTANTS.TRANSACTION_LIST_CONSTANTS.AMOUNT_TYPE;
const transactionTypeArray = CONSTANTS.TRANSACTION_LIST_CONSTANTS.TRANSACTION_TYPE;
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
let excelSheetData = '?';
const TransactionList = ({
  transactionList,
  getTransactionList,
  totalTransactions,
  transactionsExcelSheetlink,
}) => {

  const currentMonth = new Date().getMonth() + 1;
  const [searchName, setSearchName] = useState("");
  const [searchYear, setSearchYear] = useState(new Date().getFullYear());
  const [searchMonth, setSearchMonth] = useState(new Date().getMonth() + 1);
  const [amountType, setAmountType] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [status, setStatus] = useState("");
  const [sortType, setSortType] = useState(2);
  const [sortField, setSortField] = useState("created");
  const [startPrint, setStartPrint] = useState(false);

  const debouncedSearchTerm = useDebounce(searchName, 2000);
  const count = 10;
  const dispatch = useDispatch();
  const sheetData = useSelector((state) => state.transactionExcelSheet);
  const history = useHistory();
  const location = useLocation();
  const handleClick = async () => {
    setStartPrint(true)
  }
  const deviceValue = (type) => {
    switch (type) {
      case 1:
        return "January";
        break;

      case 2:
        return "February";
        break;

      case 3:
        return "March";
        break;

      case 4:
        return 'April';
        break;

      case 5:
        return 'May';
        break;

      case 6:
        return 'June';
        break;

      case 7:
        return 'July';
        break;

      case 8:
        return 'August';
        break;

      case 9:
        return 'September';
        break;

      case 10:
        return 'October';
        break;

      case 11:
        return 'November';
        break;

      case 12:
        return 'December';
        break;

    }
  };



  useEffect(() => {
    dispatch({
      type: GET_TRANSACTION_EXCEL_SUCCESS,
      payload: [],
    })
    getData();
  }, []);

  useMemo(() => {
    if (startPrint === true) {
      const query = `amountType=${amountType}&transactionType=${transactionType}&search=${searchName.trim()}&pageNo=${pageNo - 1}&searchYear=${searchYear}&searchMonth=${searchMonth}&count=${count}&sortType=${sortType}&sortField=${sortField}`;
      dispatch(getTransactionExcelList(query))
    }
  }, [startPrint])

  useEffect(() => {
    if (sheetData.data && startPrint === true) {

      if (sheetData.data.transactionSheet) {
        window.open(sheetData.data.transactionSheet)
        setStartPrint(false)
      }

    }
  }, [sheetData]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    setAmountType(filtersFromParams.amountType ? filtersFromParams.amountType : "")
    setSearchYear(filtersFromParams.searchYear ? filtersFromParams.searchYear : new Date().getFullYear())
    setSearchMonth(filtersFromParams.searchMonth ? Number(filtersFromParams.searchMonth) : currentMonth)
    setTransactionType(filtersFromParams.transactionType ? filtersFromParams.transactionType : "")
    setSortType(filtersFromParams.sortType ? filtersFromParams.sortType : "")
    setSortField(filtersFromParams.sortField ? filtersFromParams.sortField : "")
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)
    setSearchName(filtersFromParams.search ? filtersFromParams.search : "")
  }, [history.location.search]);

  useEffect(() => {
    history.push(`transactions?amountType=${amountType}&transactionType=${transactionType}&search=${searchName}&pageNo=${pageNo}&searchYear=${searchYear}&searchMonth=${searchMonth}&count=${count}&sortType=${sortType}&sortField=${sortField}`);
    getData();
  }, [pageNo, debouncedSearchTerm, transactionType, amountType, searchYear, searchMonth, sortType]);


  const getData = async () => {
    const query = `amountType=${amountType}&transactionType=${transactionType}&search=${searchName.trim()}&pageNo=${pageNo - 1}&searchYear=${searchYear}&searchMonth=${searchMonth}&count=${count}&sortType=${sortType}&sortField=${sortField}`;
    await getTransactionList(query);
  };


  const transactionStatus = (status) => {
    switch (status) {
      case 0:
        return <span id="pendingStatusColor">Pending</span>;
      case 1:
        return <span id="completedStatusColor">Completed</span>;
      case 2:
        return <span id="rejectedStatusColor">Failed</span>;
    }
  };
  const handleOnPageChangeclick = (pageNum) => {
    setPageNo(pageNum);
  };

  const handleAmountTypeChange = (val) => {
    val != undefined ? setAmountType(val) : setAmountType("")
    setPageNo(1)
  }

  const handleTypeChange = (val) => {
    val != undefined ? setTransactionType(val) : setTransactionType("")
    setPageNo(1)
  }

  const handleChangeYear = (year) => {
    year != undefined ? setSearchYear(year) : setSearchYear(new Date().getFullYear());
    setPageNo(1)

  }
  const handleChangeMonth = (element) => {
    element != undefined ? setSearchMonth(parseInt(element.key) + 1) : setSearchMonth(currentMonth);
    setPageNo(1)
  }


  const handleSort = (e) => {
    setSortField(e);
    if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
      setSortType(SORT_TYPE_CONSTANTS.Descending);
    }
    else {
      setSortType(SORT_TYPE_CONSTANTS.Asscending)
    }
  }
  return (
    <div className="body_container cm_bold marginTop">
      <h3 className="mb-4">TRANSACTIONS</h3>
      <div className="headerDiv">

        <SearchField
          inputValue={searchName.trim()}
          handleInputChange={setSearchName}
          handlePageChange={setPageNo}
          handleClearSearchClick={setSearchName}
        />
        <div className="vendorOrder-filterDiv">
          <Select
            className="vendorOrder-filters"
            allowClear={amountType ? true : false}
            placeholder="Amount Type"
            value={amountType ? amountTypeArray[amountType] : "Amount Type"}
            onChange={(val) => handleAmountTypeChange(val)}
          >
            <Option value={1} >Credit</Option>
            <Option value={2}>Debit</Option>
          </Select>
          <Select
            className="vendorOrder-filters"

            placeholder="Transaction Type"
            value={transactionType ? transactionTypeArray[transactionType] : "Transaction Type"}
            allowClear={transactionType ? true : false}
            onChange={(val) => handleTypeChange(val)}
          >
            {/* <Option value="">All Transactions</Option> */}
            <Option value={2}>Added by admin</Option>
            <Option value={3}>Order debit</Option>
            <Option value={5}>Amount debit by admin</Option>
            <Option value={6}>Order credit amount on cancel</Option>
            <Option value={7}>Order Accepted & Amount credited to vendor</Option>

          </Select>
          <Select
            className="vendorOrder-filters"
            value={searchYear ? searchYear : "Select Year"}
            allowClear={searchYear ? true : false}
            onChange={(year) => handleChangeYear(year)}
          >
            {arrayOfYear.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <Select
            className="vendorOrder-filters"
            value={searchMonth ? deviceValue(searchMonth) : "Select Month"}
            allowClear={searchMonth ? true : false}
            onChange={(year, element) => handleChangeMonth(element)
            }
          >
            {arrayOfMonths.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      {
        transactionList ? (
          <div className="table-responsive mt-3">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Employee Name<i
                    className={
                      sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === 'firstName'
                        ? "fa fa-sort-alpha-asc ml-2"
                        : "fa fa-sort-alpha-desc ml-2"
                    }
                    onClick={() => handleSort("firstName")}
                  ></i></th>
                  <th scope="col" style={{ width: 150 }}>Employee ID<i
                    className={
                      sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === 'empId'
                        ? "fa fa-sort-numeric-asc ml-2"
                        : "fa fa-sort-numeric-desc ml-2"
                    }
                    onClick={() => handleSort("empId")}
                  ></i></th>
                  <th scope="col">Email</th>
                  <th scope="col">Wallet Balance</th>
                  <th scope="col">Transaction Amount</th>
                  <th scope="col">Time</th>
                  <th scope="col">Date<i
                    className={
                      sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === 'created'
                        ? "fa fa-sort-numeric-asc ml-2"
                        : "fa fa-sort-numeric-desc ml-2"
                    }
                    onClick={() => handleSort("created")}
                  ></i></th>
                  <th scope="col">Amount Type</th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              {!_.isEmpty(transactionList) ? transactionList.map((element, index) => {
                return (
                  <tbody>
                    <tr>
                      <td>{index + 1 + (pageNo - 1) * count}</td>
                      <td>
                        {element.userName?.firstName}
                        {element.userName?.middleName === null || element.userName?.middleName === "" ? ' ' : element.userName?.middleName}
                        {element.userName?.lastName}
                      </td>
                      <td>{element.userMobId}</td>
                      <td>{element.userEmail}</td>
                      <td>Rs. {element.walletAmount}</td>
                      <td>Rs. {element.transactionAmount}</td>
                      <td>
                        {moment(element.created).format('HH:mm')}
                      </td>
                      <td>
                        {moment(element.created).format("DD/MM/YYYY")}

                      </td>
                      <td>{amountTypeArray[element.amountType]}</td>
                      <td>{transactionTypeArray[element.transactionType]}</td>
                      <td>{transactionStatus(element.status)}</td>
                    </tr>
                  </tbody>
                );
              }) : ""}
            </table>
            {transactionList && transactionList.length > 0 ? "" : <h3 className="text-center"> No data available</h3>}
          </div>

        ) : (
          <div className="loaderDiv">
            <DotLoader />
          </div>
        )
      }



      {
        parseInt(totalTransactions) >= 10 ? (
          <div className="paginationDiv">

            <Pagination
              activePage={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={parseInt(totalTransactions)}
              pageRangeDisplayed={3}
              onChange={handleOnPageChangeclick}
            />
            {totalTransactions && totalTransactions > 0 ?
              <a id="exportButton" href={transactionsExcelSheetlink} onClick={() => handleClick()}>
                Export
              </a>
              : ""}
          </div>
        ) : (
          ""
        )
      }
      {
        parseInt(totalTransactions) < 10 ? (
          <div className="paginationDiv float-right">
            {totalTransactions && totalTransactions > 0 ?
              <a id="exportButton" href={transactionsExcelSheetlink} onClick={() => handleClick()}>
                Export
              </a>
              : ""}
          </div>
        ) : (
          ""
        )
      }
    </div >
  );
};

const mapStateToProps = (state) => {

  return {
    transactionList: state.transactionList.data?.transactionList,
    totalTransactions: state.transactionList.data?.totalTransations,
    transactionsExcelSheetlink: state.transactionExcelSheet.data?.transactionSheet,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionList: (query) => {
      dispatch(getTransactionList(query));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);

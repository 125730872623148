import { REQUIRED } from "../helper/Constant"
import { currentYear } from "../leaves/Constants"

export const AwardValidation = (props) => {
    const { awardEmployeeName, awardName, awardType, reward, awardYear, type, dispatch, awardEmployeeImage } = props
    let validate = true
    if (awardEmployeeName.length == "" || awardEmployeeName == undefined) {
        validate = false
        dispatch({
            type: type, payload: {
                awardEmployeeNameErrorMessage: REQUIRED
            }
        })
    }
    else {
        dispatch({
            type: type, payload: {
                awardEmployeeNameErrorMessage: ""
            }
        })
    }

    if (awardName == "") {
        validate = false
        dispatch({
            type: type, payload: {
                awardNameErrorMessage: REQUIRED
            }
        })
    }
    else {
        dispatch({
            type: type, payload: {
                awardNameErrorMessage: ""
            }
        })
    }

    if (awardYear <= 0) {
        validate = false
        dispatch({
            type: type, payload: {
                awardYearErrorMessage: REQUIRED
            }
        })
    }
    else if (isNaN(awardYear) || awardYear.toString().length < 4 || awardYear.toString().length > 4 || awardYear > currentYear) {
        validate = false
        dispatch({
            type: type, payload: {
                awardYearErrorMessage: "Please Enter Valid Year"
            }
        })
    }
    else {
        dispatch({
            type: type, payload: {
                awardYearErrorMessage: ""
            }
        })
    }
    if (reward) {
        if (isNaN(reward) || reward <= 0) {
            validate = false
            dispatch({
                type: type, payload: {
                    rewardErrorMessage: "Please Enter Valid reward"
                }
            })
        }
        else {
            dispatch({
                type: type, payload: {
                    rewardErrorMessage: ""
                }
            })
        }
    }

    if (awardType == 0 || awardType == "") {
        validate = false
        dispatch({
            type: type, payload: {
                awardTypeErrorMessage: REQUIRED
            }
        })
    }
    else {
        dispatch({
            type: type, payload: {
                awardTypeErrorMessage: ""
            }
        })
    }

    let neg = []
    if (awardEmployeeImage && awardEmployeeImage?.name?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|pdf|xls|xlsx|doc|docs|docx|odt|dox|ppt|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|PDF)$/)) {
        neg.push(awardEmployeeImage);
    }

    if (awardEmployeeImage == "" || awardEmployeeImage == undefined || awardEmployeeImage.length === 0 || awardEmployeeImage == null || awardEmployeeImage == "null") {
        validate = false;
        dispatch({
            type: type, payload: {
                awardEmployeeImageErrorMessage: "Image is required"
            }
        })
    } else if (neg.length !== 0) {
        validate = false;
        dispatch({
            type: type, payload: {
                awardEmployeeImageErrorMessage: "Please enter Images only"
            }
        })
    }
    else {
        dispatch({
            type: type, payload: {
                awardEmployeeImageErrorMessage: ""
            }
        })
    }
    return validate;
}

export const addAwardNameVallidation = (props) => {
    const { awardNameType, type, dispatch } = props
    let validate = true
    if (awardNameType == "") {
        validate = false
        dispatch({
            type: type, payload: {
                awardNameTypeErrorMessage: REQUIRED
            }
        })
    }
    else {
        dispatch({
            type: type, payload: {
                awardNameTypeErrorMessage: ""
            }
        })
    }
    return validate

}
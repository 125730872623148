import React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "../OrganizationChart.css"

const CustomExpandButton = ({
  node,
  children,
}) => {
  return (
    <>
      {node && (
        <div className="expandBtn">
          <span>{node.memberCounts}</span>
          <span className="d-flex">
            {children ? <FaAngleUp /> : <FaAngleDown />}
          </span>
        </div>
      )}
    </>
  );
};

export default CustomExpandButton;

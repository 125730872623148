import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import addEmpResumeReducer from "./addEmpResumeReducer";
import addEmpResumeByAdminReducer from "./addEmpResumeByAdminReducer";
import deleteEmpResumeReducer from "./deleteEmpResumeReducer";
import deleteEmpResumeByAdminReducer from "./deleteEmpResumeByAdminReducer";
import getEmployeeListReducer from "./getEmployeeListReducer";
import getEmpResumeListReducer from "./getEmpResumeListReducer";
import getResumeByEmpIdReducer from "./getResumeByEmpIdReducer";
import setAdminReimbursementYearReducer from "./setAdminReimbursementYearReducer";

import loginReducer from "./loginReducer";
import getOrderListReducer from "./getOrderListReducer";
import getVendorListReducer from "./getVendorListReducer";
import getTransactionListReducer from "./getTransactionListReducer";
import getInetrviewListReducer from "./getInterviewListReducer";
import getInetrviewerListReducer from "./getInterviewerListReducer";

import addCandidateByAdminReducer from "./addCandidateByAdminReducer";
import addInterviewerByAdminReducer from "./addInterviewerByAdminReducer";

import getCandidateByIdReducer from "./getCandidateByIdReducer";
import getTransactionExcelSheetReducer from "./getTransactionExcelSheetReducer";
import deleteCandidateByAdminReducer from "./deleteCandidateByAdminReducer";
import deleteInterviewerByAdminReducer from "./deleteInterviewerByAdminReducer";

import editCandidateByAdminReducer from "./editCandidateByAdminReducer";
import addAttendanceByAdminReducer from "./addAttendanceByAdminRedcuer";
import editAttendanceByAdminReducer from "./editAttendanceReducer";
import getAttendanceByAdminReducer from "./getAttendanceByAdminReducer";
import uploadAttendanceReducer from "./uploadAttendanceReducer";
import editInterviewerByAdminReducer from "./editInterviewerByAdminReducer";
import editInterviewerStatusReducer from "./editInterviewerStatusReducer";
import getContactListReducer from "./getContactListReducer";
import addContactReducer from "./addContactReducer";
// import assignInterviewCandidateByAdminReducer from "./assignInterviewCandidateByAdminReducer";
import getAssignInterviewsReducer from "./editAssignInterviewsReducer";
import editAssignCandidateByInterviewerRducer from "./editAssignCandidateByInterviewerReducer";
import assignInterviewCandidateByAdminReducer from "./asignInterviewCandidateByAdminReducer";
import addTeamByAdminReducer from "./addTeamByAdminReducer";
import getTeamByAdminReducer from "./getTeamByAdminReducer";
import deleteTeamByAdminReducer from "./deleteTeamByAdminReducer";
import getTeamMembersByAdminReducer from "./getTeamMembersByAdminReducer";
import deleteTeamMembersByAdminReducer from "./deleteTeamMembersByAdminReducer";
import addTeamMembersByAdminReducer from "./addMembersByAdminReducer";
import getTeamMemberDetailsByAdminReducer from "./getTeamMemberDetailsByAdminReducer";
import deleteContactReducer from "./deleteContactReducer";
import addCandidateFeedBackReducer from "./addCandidateFeedBackReducer";
import editCandidateFeedBackReducer from "./editCandidateFeedBackReducer";
import getCandidateFeedBackReducer from "./getCandidateFeedBackReducer";
import deleteCandidateFeedBackReducer from "./deleteCandidateFeedBackReducer";
import editAssignCandidateByHrRducer from "./editAssignCandidateByHrReducer";
import getEmployeeCtcReducer from "./getEmployeeCtcReducer";
import addEmployeeCtcReducer from "./addEmployeeReducer";
import editEmployeeCtcReducer from "./editEmployeeCtcReducer";
import exportEmployeesSalaryReducer from "./exportEmployeesSalaryReducer";
import getEmployeeMonthlySalaryStatsReducer from "./getEmployeeMonthlySalaryStatsReducer";
import exportEmployeesReimbursementReducer from "./exportEmployeesReimbursementReducer";
import getEmployeeMonthlyReimbursementStatsReducer from "./getEmployeeMonthlyReimbursementStatsReducer.js";
import getProjectActivityByEmployeeIdReducer from "./getProjectActivityByEmployeeIdReducer";
import getAwardReducer from "./getAwardReducer";
import addAwardReducer from "./addAwardReducer";
import editAwardReducer from "./editAwardReducer";
import deleteAwardReducer from "./DeleteAwardReducer";
import getTeamCompareDataByAdminReducer from "./getTeamCompareDataReducer";
import getEmployeeOnHoldReducer from "./getEmployeeOnHoldReducer.js";
import addEmployeeOnHoldReducer from "./addEmployeeOnHoldReducer.js";
import editEmployeeOnHoldReducer from "./editEmployeeOnHoldReducer.js";
import getOtpReducer from "./getOtpReducer.js";
import validateOtpReducer from "./validateOtpReducer.js";
import editOnHoldReleaseDateReducer from "./editOnHoldRelaseDateReducer.js";
import editCompOffByAdminReducer from "./editCompOffByAdminReducer";
import editTempCompOffByAdminReducer from "./editTempCompOffByAdminReducer";
import getCourierListByAdminReducer from "./getCourierListByAdminReducer";
import addCourierListByAdminReducer from "./addCourierListByAdminReducer";
import editCourierListByAdminReducer from "./editCourierListByAdminReducer";
import deleteCourierListByAdminReducer from "./deleteCourierListByAdminReducer";
import getEmpProfileByAdminReducer from "./getEmpProfileByAdminReducer";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  addEmployeeResume: addEmpResumeReducer,
  addEmpResumeByAdmin: addEmpResumeByAdminReducer,
  employeeList: getEmployeeListReducer,
  empResumeList: getEmpResumeListReducer,
  resumeByEmpId: getResumeByEmpIdReducer,
  deleteResume: deleteEmpResumeReducer,
  deleteResumeByAdmin: deleteEmpResumeByAdminReducer,
  login: loginReducer,
  adminReimbursementFilterYear: setAdminReimbursementYearReducer,
  vendorList: getVendorListReducer,
  orderList: getOrderListReducer,
  transactionList: getTransactionListReducer,
  interviewList: getInetrviewListReducer,
  interviewerList: getInetrviewerListReducer,
  contactList: getContactListReducer,
  addContact: addContactReducer,

  addCandidateByAdmin: addCandidateByAdminReducer,
  editCandidateByAdmin: editCandidateByAdminReducer,
  addInterviewerByAdmin: addInterviewerByAdminReducer,

  editInterviewerByAdmin: editInterviewerByAdminReducer,
  editInterviewerStatus: editInterviewerStatusReducer,

  deleteCandidateByAdmin: deleteCandidateByAdminReducer,
  deleteInterviewerByAdmin: deleteInterviewerByAdminReducer,

  candidateById: getCandidateByIdReducer,
  transactionExcelSheet: getTransactionExcelSheetReducer,
  addAttendanceByAdmin: addAttendanceByAdminReducer,
  editAttendanceByAdmin: editAttendanceByAdminReducer,
  getAttendanceByAdmin: getAttendanceByAdminReducer,
  uploadAttendance: uploadAttendanceReducer,
  assignInterviewCandidate: assignInterviewCandidateByAdminReducer,
  // contactList: getContactListReducer,
  getAssignInterviews: getAssignInterviewsReducer,
  editAssignCandidate: editAssignCandidateByInterviewerRducer,
  editAssignCandidateByHr: editAssignCandidateByHrRducer,
  addTeam: addTeamByAdminReducer,
  getTeam: getTeamByAdminReducer,
  deleteTeam: deleteTeamByAdminReducer,
  getTeamMembers: getTeamMembersByAdminReducer,
  deleteTeamMember: deleteTeamMembersByAdminReducer,
  addTeamMember: addTeamMembersByAdminReducer,
  getTeamMembersDetails: getTeamMemberDetailsByAdminReducer,
  deleteContact: deleteContactReducer,
  addCandidateFeedBack: addCandidateFeedBackReducer,
  editCandidateFeedBack: editCandidateFeedBackReducer,
  getCandidateFeedBack: getCandidateFeedBackReducer,
  deleteCandidateFeedBack: deleteCandidateFeedBackReducer,

  getEmployeeCtc: getEmployeeCtcReducer,
  addEmployeeCtc: addEmployeeCtcReducer,
  editEmployeeCtc: editEmployeeCtcReducer,
  exportEmployeesSalary: exportEmployeesSalaryReducer,
  getEmployeeMonthlySalaryStats: getEmployeeMonthlySalaryStatsReducer,
  exportEmployeesReimbursement: exportEmployeesReimbursementReducer,
  getEmployeeMonthlyReimbursementStats:
    getEmployeeMonthlyReimbursementStatsReducer,
  getProjectActivityByEmployeeId: getProjectActivityByEmployeeIdReducer,
  getAward: getAwardReducer,
  addAward: addAwardReducer,
  editAward: editAwardReducer,
  deleteAward: deleteAwardReducer,
  getTeamCompareData: getTeamCompareDataByAdminReducer,
  getEmployeeOnHold: getEmployeeOnHoldReducer,
  addEmployeeOnHold: addEmployeeOnHoldReducer,
  editEmployeeOnHold: editEmployeeOnHoldReducer,
  getOtp: getOtpReducer,
  validateOtp: validateOtpReducer,
  editOnHoldReleaseDateReducer: editOnHoldReleaseDateReducer,
  editCompOffByAdmin: editCompOffByAdminReducer,
  editTempCompOffByAdmin: editTempCompOffByAdminReducer,
  getCourierListByAdminReducer: getCourierListByAdminReducer,
  addCourierListByAdminReducer: addCourierListByAdminReducer,
  editCourierListByAdminReducer: editCourierListByAdminReducer,
  deleteCourierListByAdminReducer: deleteCourierListByAdminReducer,
  getEmpProfileByAdminReducer: getEmpProfileByAdminReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT_SUCCESS") {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return appReducer(state, action);
};
export default persistReducer(persistConfig, rootReducer);

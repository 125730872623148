import React from "react";

import _ from "lodash";
import Dropzone from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
const { Option } = Select;

export default function EditTeamModal(props) {
  return (
    <Modal
      size="md"
      show={props.openEditTeamModal}
      onHide={props.closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="add-new-dept">
          <h3>Edit Team</h3>
          <div
            className={`form-group ${
              props.teamNameErrorMsg ? "input_error" : ""
            }`}
          >
            <label className="cm_bold">Team Name</label>
            <input
              maxLength="20"
              className="form-control cm_box"
              type="text"
              placeholder="Enter Team Name"
              name="departmentName"
              value={props.teamName}
              onChange={props.handleTeamNameChange}
            />
            {props.teamNameErrorMsg ? (
              <span className="error_msg">{props.teamNameErrorMsg}</span>
            ) : null}
          </div>
          <div className="row">
            <div
              className={`form-group col-12 ${
                props.teamLeadErrorMessage ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Team Head</label>

              <Select
                showSearch
                name="employeeId"
                value={
                  props.teamLead && !props.teamLeadEdit.length
                    ? props.teamLead
                    : props.teamLeadEdit.map((lead) => lead._id)
                }
                placeholder="Select Lead"
                defaultValue="Select Name"
                onChange={props.handleSelectTeamLeadChange}
                optionFilterProp="key"
                filterOption={(input, option) =>
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
                disabled={props.miniLoader}
                allowClear={props.teamLead ? true : false}
                mode="multiple"
              >
                {!_.isEmpty(props.employeeList)
                  ? props.employeeList
                      .sort(function (a, b) {
                        if (a.firstName < b.firstName) {
                          return -1;
                        }
                        if (a.firstName > b.firstName) {
                          return 1;
                        }
                        return 0;
                      })
                      .filter(
                        (employee) =>
                          ![...props?.teamMembers].includes(employee.employeeId)
                      )
                      .map(
                        (employee) =>
                          employee.status === 1 && (
                            <Option
                              key={`${employee.firstName} ${employee.lastName}`}
                              value={employee.employeeId}
                            >
                              <span>
                                {employee.firstName} {employee.lastName} (
                                {employee.empId})
                              </span>
                            </Option>
                          )
                      )
                  : ""}
              </Select>
              {props.teamLeadErrorMessage ? (
                <span className="error_msg">{props.teamLeadErrorMessage}</span>
              ) : null}
            </div>
          </div>
          <div
            className={`form-group ${
              props.teamIconErrorMessage ? "input_error" : ""
            }`}
          >
            <label className="mb-3 cm_bold">Team Icon</label>
            <Dropzone
              onDrop={(acceptedFile) => props.handleFileChange(acceptedFile)}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <section>
                  <div
                    className="upload_doc upload_doc_1"
                    {...getRootProps()}
                    style={{
                      background: `url(${props.previousTeamIconPreview})`,
                    }}
                  >
                    <input {...getInputProps()} accept=".png, .jpg, .jpeg" />

                    {props.previousTeamIconPreview ? (
                      ""
                    ) : (
                      <p>
                        <span className="blue">Upload Icon</span>
                      </p>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            {props.teamIconErrorMessage ? (
              <span className="error_msg">{props.teamIconErrorMessage}</span>
            ) : null}
          </div>

          <div className="submit_button_margin mt-4">
            <button
              type="submit"
              className="btn btn-primary cm_btn3"
              onClick={props.handleEditSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-primary  cm_btn2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

import { getEmpResumeList, getEmpList } from "../../redux/actions";
import { SearchField } from "../../hoc";
import DotLoader from "../../hoc/DotLoader";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "../../config";
import qs from "qs"
import { useDebounce } from "../attendance/Constants";
import AdminAddResModal from "./AdminAddResModal";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
let TAB_STATUS_CONSTANTS = CONSTANTS.TAB_STATUS_CONSTANTS

const ResumeList = ({
  getEmpResumeList,
  getEmpList,
  empResumeList,
  empResumeListTotalCount,
  availableResumeListCount,
  employeeList,
}) => {
  const [inputValue, setInputValue] = useState("");
  const visibleDataCount = 10;
  const [sortField, setSortField] = useState('created');
  const [sortType, setSortType] = useState(SORT_TYPE_CONSTANTS.Descending);
  const [pageNo, setPageNo] = useState(1);
  const [showModal, setShowModal] = useState(false)
  const [active, setActive] = useState(TAB_STATUS_CONSTANTS.STATUS.one)

  const history = useHistory();
  const url = "/resumeSection/employeeResumeDetails/"
  const debouncedSearchTerm = useDebounce(inputValue, 2000);

  useEffect(() => {
    getEmplist();
  }, []);
  useEffect(() => {
    getData();
  }, [pageNo, debouncedSearchTerm, sortType, active]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    setSortType(filtersFromParams.sortType ? filtersFromParams.sortType : SORT_TYPE_CONSTANTS.Descending)
    setSortField(filtersFromParams.sortField ? filtersFromParams.sortField : "created")
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)
    setInputValue(filtersFromParams.search ? filtersFromParams.search : "")
    setActive(filtersFromParams.active ? filtersFromParams.active : TAB_STATUS_CONSTANTS.STATUS.one)

  }, [history.location.search]);

  const getData = async () => {
    const query = `search=${inputValue}&pageNo=${pageNo - 1}&count=${visibleDataCount}&sortField=${sortField}&sortType=${sortType}&status=${active}`;
    history.push(`resumeSection?search=${inputValue}&pageNo=${pageNo}&sortField=${sortField}&sortType=${sortType}&active=${active}`)

    await getEmpResumeList(query);
  };
  const getEmplist = async () => {
    await getEmpList();
  };

  const handleOnPageChangeclick = (pageNum) => {
    setPageNo(pageNum);
  };

  const handleSort = (e) => {
    setSortField(e);
    if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
      setSortType(SORT_TYPE_CONSTANTS.Descending);
    }
    else {
      setSortType(SORT_TYPE_CONSTANTS.Asscending)
    }
  }
  const handleOnAddResumeClick = () => {
    setShowModal(true);
  };

  const handleEditSubmit = (e) => {
    setActive(e.target.checked ? TAB_STATUS_CONSTANTS.STATUS.one : TAB_STATUS_CONSTANTS.STATUS.zero)
  };

  return (
    <div className="body_container cm_bold marginTop">
      <h3 className="mb-4">RESUME SECTION</h3>
      <div className=" filters headerSection justify-content-end">
        <SearchField
          inputValue={inputValue.trim()}
          handleInputChange={setInputValue}
          handlePageChange={setPageNo}
          handleClearSearchClick={setInputValue}
        />
        <div>
          <button className="btn btn-primary" onClick={handleOnAddResumeClick}>Add Resume</button>
        </div>
      </div>

      <div className="d-flex justify-content-end mb-3">
        <span htmlFor="">Active Employees Resume</span>
        <div className="cm_swich_wrapper mt-1 ml-2">
          <input
            type="checkbox"
            value={active}
            checked={active == TAB_STATUS_CONSTANTS.STATUS.one ? true : false}
            onChange={(e) =>
              handleEditSubmit(e)
            }
          />
          <span className="cm_swich"></span>

        </div>
      </div>
      {empResumeList ? (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Employee ID<i
                  className={
                    sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'empId'
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={() => handleSort("empId")}
                ></i></th>
                <th scope="col">Name<i
                  className={
                    sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'firstName'
                      ? "fa fa-sort-alpha-asc ml-2"
                      : "fa fa-sort-alpha-desc ml-2"
                  }
                  onClick={() => handleSort("firstName")}
                ></i></th>
                <th scope="col">Department</th>
                <th scope="col">Last Updated</th>
              </tr>
            </thead>

            <tbody>
              {empResumeList.map((element, index) => {
                return (
                  <tr>
                    <td>{index + 1 + (pageNo - 1) * visibleDataCount}</td>
                    <td>{element.empId}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `${url}${element._id}`,
                          state: { empId: element._id },
                        }}
                      >
                        {element.firstName + " " + element.lastName}
                      </Link>
                    </td>
                    <td>{element?.departmentName?.map((item, index) => (
                      <span key={index}>
                        {item}
                        {index < element?.departmentName?.length - 1 && ', '}
                      </span>
                    ))}</td>
                    <td>
                      {moment(
                        element.resumeInfo[
                          element.resumeInfo.length - 1
                        ].updated
                      ).format("HH:mm") +
                        " on " +
                        new Date(
                          element.resumeInfo[
                            element.resumeInfo.length - 1
                          ].updated
                        ).getDate() +
                        "/" +
                        (new Date(
                          element.resumeInfo[
                            element.resumeInfo.length - 1
                          ].updated
                        ).getMonth() +
                          1) +
                        "/" +
                        new Date(
                          element.resumeInfo[
                            element.resumeInfo.length - 1
                          ].updated
                        ).getFullYear()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {empResumeList?.length === 0 ? (

            <h3 className="text-center">No data available</h3>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="loaderDiv">
          <DotLoader />
        </div>
      )}

      {empResumeListTotalCount > 10 && inputValue === "" ? (
        <Pagination
          activePage={pageNo}
          itemsCountPerPage={visibleDataCount}
          totalItemsCount={empResumeListTotalCount}
          pageRangeDisplayed={3}
          onChange={handleOnPageChangeclick}
        />
      ) : (
        ""
      )}
      {availableResumeListCount > 10 && inputValue !== "" ? (
        <Pagination
          activePage={pageNo}
          itemsCountPerPage={visibleDataCount}
          totalItemsCount={empResumeListTotalCount}
          pageRangeDisplayed={3}
          onChange={handleOnPageChangeclick}
        />
      ) : (
        ""
      )}
      <AdminAddResModal showModal={showModal} setShowModal={setShowModal} employeeList={employeeList} empId={""} getData={getData} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    empResumeList: state.empResumeList.data.resumeList?.employeeProfile,
    dataCount: state.empResumeList.data.resumeList?.totalEmployee,
    empResumeListTotalCount:
      state.empResumeList.data.resumeList?.totalEmployeeCount,
    availableResumeListCount:
      state.empResumeList.data.resumeList?.totalEmployee,
    employeeList: state?.employeeList?.data?.employeesList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmpResumeList: (query) => {
      dispatch(getEmpResumeList(query));
    },
    getEmpList: () => {
      dispatch(getEmpList());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeList);


import React from "react";
import "./Template.css";
import { daysOfWeek } from "../../helper/Constant";
import { PrintButton } from "../ProjectUtils/Constants";

const Template1 = (props) => {
  const {
    attendanceData,
    month,
    year,
    handlePrint,
    goBack,
    hideIcon,
    empName,
  } = props;

  const adjustedMonth = month - 1;

  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();

  const generateCalendar = () => {
    const daysInMonth = getDaysInMonth(year, adjustedMonth);
    const firstDayOfMonth = new Date(year, adjustedMonth, 1).getDay();
    const lastDayOfMonth = new Date(year, adjustedMonth, daysInMonth).getDay();
    const daysArray = [];
    const prevMonthDays = getDaysInMonth(year, adjustedMonth - 1);
    for (let i = firstDayOfMonth; i > 0; i--) {
      daysArray.push({
        day: prevMonthDays - i + 1,
        currentMonth: false,
      });
    }

    for (let day = 1; day <= daysInMonth; day++) {
      daysArray.push({
        day,
        currentMonth: true,
      });
    }

    const remainingDays = 6 - lastDayOfMonth;
    for (let i = 1; i <= remainingDays; i++) {
      daysArray.push({
        day: i,
        currentMonth: false,
      });
    }

    return daysArray;
  };

  const calendarDays = generateCalendar();

  const getStatusClass = (day, currentMonth) => {
    if (!currentMonth) return "prev-next-month";
    const dateKey = `${year}-${adjustedMonth + 1}-${day}`;
    return attendanceData[dateKey] || "";
  };

  return (
    <>
      <PrintButton
        handlePrint={handlePrint}
        goBack={goBack}
        hideIcon={hideIcon}
      />
      <div className=" template_detail">
        <h3>{empName}</h3>
        <div className="d-flex justify-content-center mt-5">
          <div className="calendar_container">
            <div className="calendar">
              <div className="calendar-header">
                <span className="font-weight-normal">
                  {new Date(year, adjustedMonth).toLocaleDateString("default", {
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>
              <div className="calendar-grid">
                {daysOfWeek.map((day) => (
                  <div
                    key={day}
                    className="calendar-day-name font-weight-normal"
                  >
                    {day}
                  </div>
                ))}
                {calendarDays.map((dayObj, index) => (
                  <div key={index} className="d-flex justify-content-center">
                    <div
                      className={`calendar-day ${getStatusClass(
                        dayObj.day,
                        dayObj.currentMonth
                      )}`}
                    >
                      {dayObj.day}
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between color_desc mt-3">
                <div className="d-flex align-items-center">
                  <span className="circle mr-1 present"></span>
                  <p className="font-weight-normal">Present</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="circle mr-1 leave"></span>
                  <p className="font-weight-normal">Leave</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="circle mr-1 absent"></span>
                  <p className="font-weight-normal">Absent</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="circle mr-1 holiday"></span>
                  <p className="font-weight-normal">Holiday</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="circle mr-1 halfLeave"></span>
                  <p className="font-weight-normal">Half Leave</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="circle mr-1 no_attendance"></span>
                  <p className="font-weight-normal">No Attendance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template1;

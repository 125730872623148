import {
  GET_RESUME_BY_EMP_ID_REQUEST,
  GET_RESUME_BY_EMP_ID_SUCCESS,
  GET_RESUME_BY_EMP_ID_FAILURE,
} from "./actionType";

import { getResumeByEmployeeId } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getResumeByEmpId = (params) => {
  return (dispatch) => {
    dispatch(getResumeByEmpIdRequest());
    getResumeByEmployeeId(params)
      .then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch(getResumeByEmpIdSuccess(res.data.responseData));
        } else {
          dispatch(getResumeByEmpIdFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(getResumeByEmpIdFailure(error));
      });
  };
};

const getResumeByEmpIdRequest = () => {
  return {
    type: GET_RESUME_BY_EMP_ID_REQUEST,
  };
};

const getResumeByEmpIdSuccess = (data) => {
  return {
    type: GET_RESUME_BY_EMP_ID_SUCCESS,
    payload: data,
  };
};

const getResumeByEmpIdFailure = (error) => {
  return {
    type: GET_RESUME_BY_EMP_ID_FAILURE,
    payload: error,
  };
};
export {
  getResumeByEmpId,
  getResumeByEmpIdRequest,
  getResumeByEmpIdSuccess,
  getResumeByEmpIdFailure,
};

import { getAwardList } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    GET_AWARD_REQUEST,
    GET_AWARD_SUCCESS,
    GET_AWARD_FAILURE,
} from "./actionType";


const getAwardAction = (props) => {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getAwardRequest());
        getAwardList(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        awardList: res.data.responseData?.list,
                        dataCount: res.data.responseData?.dataCount
                    }
                })
            }
        })
            .catch((error) => {
                dispatch(getAwardFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const getAwardRequest = () => {
    return {
        type: GET_AWARD_REQUEST,
    };
};
const getAwardSuccess = (data) => {
    return {
        type: GET_AWARD_SUCCESS,
        payload: data,
    };
};
const getAwardFailure = (error) => {
    return {
        type: GET_AWARD_FAILURE,
        payload: error,
    };
};
export {
    getAwardAction,
    getAwardRequest,
    getAwardSuccess,
    getAwardFailure,
};
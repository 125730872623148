import React from 'react'
import Modal from "react-bootstrap/Modal";
import { PDFReader } from 'reactjs-pdf-reader-custom';
import styled from "styled-components";
import { DownloadIcon } from '../svg';

export default function DeviceFullScreenImage(props) {
    return (
        <>
            <Modal
                className="cm_deviceInfo full-image"
                // size="md"
                show={props.preview}
                onHide={props.closeModal}
                aria-labelledby="example-modal-sizes-title-sm"
                style={{ background: "transparent" }}
                centered
            >
                <Modal.Header closeButton style={{ position: "relative" }}>
                    <div style={{ position: "absolute", right: "75px", top: "-32px" }}>
                        <a href={props.fullImage} className="ml-4">
                            <DownloadIcon />
                        </a>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>{
                        props?.fullImage?.includes(".pdf") == false ?
                            <>
                                <img
                                    src={props?.fullImage}
                                />
                            </> :
                            <PDFReader url={props?.fullImage}
                                showAllPage={true}
                            >
                            </PDFReader>}

                    </>
                </Modal.Body>
            </Modal>
            {/* <div>
            </div> */}
        </>
    )
}
const ActionImage = styled.img`
      width: 30px;
      height: 30px;
      cursor: pointer;
      `;

import {
    DELETE_CANDIDATE_FEEDBACK_REQUEST,
    DELETE_CANDIDATE_FEEDBACK_SUCCESS,
    DELETE_CANDIDATE_FEEDBACK_FAILURE,
} from "./actionType";

import { deleteCandidateFeedBack } from "../../config/services/employeeServices";
import { getCandidateFeedBackAction } from "./getCandidateFeedBackAction";

const deleteCandidateFeedBackAction = (clientId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage) => {
    return (dispatch) => {
        dispatch(deleteCandidateFeedbackRequest());
        deleteCandidateFeedBack(clientId)
            .then((res) => {

                if (res.data.statusCode == 1) {
                    dispatch(deleteCandidateFeedbackSuccess(res.data));
                    setResDeleteMsg(true);
                    dispatch(getCandidateFeedBackAction(query));

                } else {
                    dispatch(deleteCandidateFeedbackFailure(res.data));
                    setFailResDeleteMsg(true);
                    setFailResponseMessage(res?.data?.error?.responseMessage)
                    dispatch(getCandidateFeedBackAction(query));
                }
            })
        // .catch((error) => {
        //     dispatch(deleteCandidateFeedbackFailure(error));
        //     // setErrorText("Failed to delete");
        //     setFailResDeleteMsg(true);
        // });
    };
};

const deleteCandidateFeedbackRequest = () => {
    return {
        type: DELETE_CANDIDATE_FEEDBACK_REQUEST,
    };
};
const deleteCandidateFeedbackSuccess = (data) => {
    return {
        type: DELETE_CANDIDATE_FEEDBACK_SUCCESS,
        payload: data,
    };
};
const deleteCandidateFeedbackFailure = (error) => {
    return {
        type: DELETE_CANDIDATE_FEEDBACK_FAILURE,
        payload: error,
    };
};
export {
    deleteCandidateFeedBackAction,
    deleteCandidateFeedbackRequest,
    deleteCandidateFeedbackSuccess,
    deleteCandidateFeedbackFailure,
};

import React from "react";
import {
  colorType,
  leaveValue,
  statusValue,
  HALF_LWP,
  LEAVE_WITHOUT_PAY,
} from "../leaves/Constants";
import Pagination from "react-js-pagination";
import { STATUS_TWO, handleSort } from "../helper/Constant";

export default function TeamLeave(props) {
  const {
    leaveList,
    pageNo,
    dataCount,
    handlePageChange,
    count,
    handleOpenView,
    sortType,
    sortField,
    dispatch,
  } = props;

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th>
                Employee ID
                <i
                  className={
                    sortType === 2 && sortField === "mobId"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("mobId", dispatch, sortType)}
                ></i>
              </th>
              <th scope="col" style={{ width: "170px" }}>
                Name
                <i
                  className={
                    sortType === 2 && sortField === "employeeName"
                      ? "fa fa-sort-alpha-desc ml-2"
                      : "fa fa-sort-alpha-asc ml-2"
                  }
                  onClick={() => handleSort("employeeName", dispatch, sortType)}
                ></i>
              </th>

              <th scope="col" style={{ width: "170px" }}>
                Leave Type
              </th>
              <th scope="col">
                Start Date
                <i
                  className={
                    sortType === 2 && sortField === "startDate"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("startDate", dispatch, sortType)}
                ></i>
              </th>
              <th scope="col">End Date</th>
              <th scope="col">
                Applied On
                <i
                  className={
                    sortType === 2 && sortField === "createdAt"
                      ? "fa fa-sort-numeric-desc ml-2"
                      : "fa fa-sort-numeric-asc ml-2"
                  }
                  onClick={() => handleSort("createdAt", dispatch, sortType)}
                ></i>
              </th>
              <th scope="col">Status</th>
              <th scope="col">Reason</th>
            </tr>
          </thead>
          <tbody>
            {leaveList.map((leave, i) => (
              <tr>
                <td>{i + 1 + (pageNo - 1) * count}</td>
                <td>{leave.mobId}</td>
                <td style={{ width: "170px" }}>
                  <span className="cm_col">{leave.employeeName}</span>
                </td>
                <td style={{ width: "170px" }}>
                  {leaveValue(leave.leaveType)}
                </td>
                <td>
                  {new Date(leave.startDate)
                    .getDate()
                    .toString()
                    .padStart(2, "0") +
                    "/" +
                    (new Date(leave.startDate).getMonth() + 1)
                      .toString()
                      .padStart(2, "0") +
                    "/" +
                    new Date(leave.startDate)
                      .getFullYear()
                      .toString()
                      .padStart(2, "0")}
                </td>
                <td>
                  {new Date(leave.endDate)
                    .getDate()
                    .toString()
                    .padStart(2, "0") +
                    "/" +
                    (new Date(leave.endDate).getMonth() + 1)
                      .toString()
                      .padStart(2, "0") +
                    "/" +
                    new Date(leave.endDate)
                      .getFullYear()
                      .toString()
                      .padStart(2, "0")}
                </td>
                <td>
                  {leave.day}/{leave.month}/{leave.year}
                </td>
                <td className={colorType(leave.status)}>
                  <div className="col-4 ">
                    <span
                      className={`${
                        (leave.leaveType === LEAVE_WITHOUT_PAY &&
                          leave.status === STATUS_TWO) ||
                        (leave.leaveType === HALF_LWP &&
                          leave.status === STATUS_TWO)
                          ? "text-danger"
                          : ""
                      }`}
                      style={{ width: 170 }}
                    >
                      {leave.leaveType === HALF_LWP &&
                      leave.status === STATUS_TWO
                        ? "Half LWP"
                        : leave.leaveType === LEAVE_WITHOUT_PAY &&
                          leave.status === STATUS_TWO
                        ? "LWP"
                        : statusValue(leave.status)}
                    </span>
                  </div>
                </td>
                <td>
                  <a>
                    <i
                      onClick={(e) => handleOpenView(leave)}
                      className="fa fa-eye blue ml-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {leaveList && leaveList.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center"> No data available</h3>
        )}
      </div>

      {dataCount > 10 ? (
        <Pagination
          activePage={pageNo}
          itemsCountPerPage={count}
          totalItemsCount={dataCount}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
        />
      ) : (
        ""
      )}
    </div>
  );
}

import {
    ADD_INTERVIEWER_BY_ADMIN_REQUEST,
    ADD_INTERVIEWER_BY_ADMIN_SUCCESS,
    ADD_INTERVIEWER_BY_ADMIN_FAILURE,
} from "./actionType";

import { addInterviewerByAdminService } from "../../config/services/employeeServices";
import { getInterviewList } from "./getInterviewListAction";
import { getInterviewerList } from "./getInterviewerActionList";

const addInterviewerByAdmin = (data1, query, setShowPanelModal, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick) => {
    return (dispatch) => {
        dispatch(addInterviewerByAdminRequest());
        addInterviewerByAdminService(data1)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(addInterviewerByAdminFailure(res.data));
                    setFailResMsg(true)
                    setLoader(false)
                } else {
                    dispatch(addInterviewerByAdminSuccess(res.data));
                    setResMsg(true)
                    setTimeout(() => (
                        handleCancelButtonClick(),
                        dispatch(getInterviewerList(query)),
                        setLoader(false)
                    ), 3000);

                }
            })
    };
};

const addInterviewerByAdminRequest = () => {
    return {
        type: ADD_INTERVIEWER_BY_ADMIN_REQUEST,
    };
};
const addInterviewerByAdminSuccess = (data) => {
    return {
        type: ADD_INTERVIEWER_BY_ADMIN_SUCCESS,
        payload: data,
    };
};
const addInterviewerByAdminFailure = (error) => {
    return {
        type: ADD_INTERVIEWER_BY_ADMIN_FAILURE,
        payload: error,
    };
};
export {
    addInterviewerByAdmin,
    addInterviewerByAdminRequest,
    addInterviewerByAdminSuccess,
    addInterviewerByAdminFailure,
};

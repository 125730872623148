import {
    EDIT_CANDIDATE_FEEDBACK_REQUEST,
    EDIT_CANDIDATE_FEEDBACK_SUCCESS,
    EDIT_CANDIDATE_FEEDBACK_FAILURE,
} from "./actionType";

import { editCandidateFeedBack, } from "../../config/services/employeeServices";
import { getCandidateFeedBackAction } from "./getCandidateFeedBackAction";

const editCandidateFeedBackAction = (data1, query, setShowPanelModal, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick) => {
    return (dispatch) => {
        dispatch(editCandidateFeedBackRequest());
        editCandidateFeedBack(data1)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(editCandidateFeedBackFailure(res.data));
                    setFailResMsg(true)
                    setLoader(false)
                } else {
                    dispatch(editCandidateFeedBackSuccess(res.data));
                    setResMsg(true)
                    setTimeout(() => (
                        handleCancelButtonClick(),
                        dispatch(getCandidateFeedBackAction(query)),
                        setLoader(false)
                    ), 3000);

                }
            })
    };
};

const editCandidateFeedBackRequest = () => {
    return {
        type: EDIT_CANDIDATE_FEEDBACK_REQUEST,
    };
};
const editCandidateFeedBackSuccess = (data) => {
    return {
        type: EDIT_CANDIDATE_FEEDBACK_SUCCESS,
        payload: data,
    };
};
const editCandidateFeedBackFailure = (error) => {
    return {
        type: EDIT_CANDIDATE_FEEDBACK_FAILURE,
        payload: error,
    };
};
export {
    editCandidateFeedBackAction,
    editCandidateFeedBackRequest,
    editCandidateFeedBackSuccess,
    editCandidateFeedBackFailure,
};

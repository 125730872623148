import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { useLocation, useHistory, Link } from "react-router-dom";

import CONSTANTS from "../../config/constants/Constants";
import { getOrderList } from "../../redux/actions";
import { SearchField } from "../../hoc";
import { Select } from "antd";
import DotLoader from "../../hoc/DotLoader";
import moment from "moment";
import qs from 'qs'
import _ from "lodash";
import { useDebounce } from "../attendance/Constants";
const { Option } = Select;
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
let arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i <= 3; i++) {
  arrayOfYear.push(currentYear - i);
}
const ARRAY_OF_MONTHS = CONSTANTS.TRANSACTION_LIST_CONSTANTS.ARRAY_OF_MONTHS;
const ORDER_STATUS = CONSTANTS.VENDOR_ORDER_LIST_CONSTANTS.ORDER_STATUS;


// function tConvert(time) {
//   // Check correct time format and split into components
//   time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

//   if (time.length > 1) { // If time format correct
//     time = time.slice(1);  // Remove full string match value
//     time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
//     time[0] = +time[0] % 12 || 12; // Adjust hours
//   }
//   return time.join(''); // return adjusted time or original string
// }


const OrderListByVendor = ({
  orderList,
  getOrderList,
  totalOrders,
  ordersExcelSheetlink,
}) => {
  const [searchName, setSearchName] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [searchYear, setSearchYear] = useState(new Date().getFullYear());
  const [searchMonth, setSearchMonth] = useState(new Date().getMonth() + 1);
  const [orderStatus, setOrderStatus] = useState("");
  const [sortField, setSortField] = useState("created")
  const [sortType, setSortType] = useState(SORT_TYPE_CONSTANTS.Descending)
  const visibleDataCount = 10;
  const history = useHistory()
  const debouncedSearchTerm = useDebounce(searchName, 2000);

  const orderStatusFunc = (status) => {
    if (status === 1) return <span id="pendingStatusColor">Pending</span>;
    else if (status === 2) return <span id="acceptedStatusColor">Accepted</span>;
    else if (status === 3) return <span id="completedStatusColor">Completed</span>;
    else if (status === 4) return <span id="rejectedStatusColor">Cancelled by Vender</span>;
    else if (status === 5) return <span id="cancelledStatusColor">Cancelled by User</span>;
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {

    history.push(`?count=${visibleDataCount}&pageNo=${pageNo}&status=${orderStatus === "0" ? "" : orderStatus
      }&search=${searchName}&platform=1&searchMonth=${searchMonth}&searchYear=${searchYear}&vendorId=${localStorage.getItem("vendorId")
      }&sortType=${sortType}&sortField=${sortField}`)
    setTimeout(() => {
      getData();
    }, 250);
  }, [debouncedSearchTerm, searchYear, searchMonth, pageNo, orderStatus, sortField, sortType]);
  const location = useLocation();
  const getData = async () => {
    const query = `count=${visibleDataCount}&pageNo=${pageNo - 1}&status=${orderStatus === "0" ? "" : orderStatus
      }&search=${searchName}&platform=1&searchMonth=${searchMonth}&searchYear=${searchYear}&vendorId=${localStorage.getItem("vendorId")
      }&sortType=${sortType}&sortField=${sortField}`;
    await getOrderList(query);
  };


  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    setSortType(filtersFromParams.sortType ? filtersFromParams.sortType : SORT_TYPE_CONSTANTS.Descending)
    setSortField(filtersFromParams.sortField ? filtersFromParams.sortField : "created")
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)
    setSearchName(filtersFromParams.search ? filtersFromParams.search : "")
    setSearchMonth(filtersFromParams.searchMonth ? filtersFromParams.searchMonth : new Date().getMonth() + 1)
    setSearchYear(filtersFromParams.searchYear ? filtersFromParams.searchYear : new Date().getFullYear())
    setOrderStatus(filtersFromParams.status ? filtersFromParams.status : "")

  }, [history.location.search]);
  const handleOnPageChangeclick = (pageNum) => {
    setPageNo(pageNum);
  };

  const handleSort = (e) => {
    setSortField(e);
    if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
      setSortType(SORT_TYPE_CONSTANTS.Descending);
    }
    else {
      setSortType(SORT_TYPE_CONSTANTS.Asscending)
    }
  }
  return (
    <div className="body_container cm_bold marginTop">
      <div className="cm_breadcrumb">
        <ul>
          <li><Link to='/vendors'>Vendor Order List</Link><div className="cm_breadcrumb_arrow"></div></li>
          <li style={{ textTransform: 'uppercase' }}>{orderList?.[0]?.vendorDetail?.name}</li>
        </ul>
      </div>
      <h3 className="mb-3 mt-2">{orderList?.[0]?.vendorDetail?.name} Order's List</h3>
      <div className="headerDiv">
        <SearchField
          inputValue={searchName.trim()}
          handlePageChange={setPageNo}
          handleInputChange={setSearchName}
          handleClearSearchClick={setSearchName}
        />
        <div className="vendorOrder-filterDiv">
          <Select
            className="vendorOrder-filters"
            // defaultValue="Select Status"
            value={orderStatus ? ORDER_STATUS[orderStatus] : "Select Status"}
            onChange={(order, element) => {
              setOrderStatus(element.key);
            }}
          >
            {ORDER_STATUS.map((element, index) => (
              <Option key={index} value={element}>
                {element}
              </Option>
            ))}
          </Select>
          <Select
            className="vendorOrder-filters"
            defaultValue={searchYear}
            onChange={(year) => {
              setSearchYear(year);
            }}
          >
            {arrayOfYear.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <Select
            className="vendorOrder-filters"
            defaultValue={ARRAY_OF_MONTHS[searchMonth - 1]}
            onChange={(year, element) => {
              setSearchMonth(parseInt(element.key) + 1);
            }}
          >
            {ARRAY_OF_MONTHS.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      {orderList ? (
        <div className="table-responsive mt-3">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Employee Name<i
                  className={
                    sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'firstName'
                      ? "fa fa-sort-alpha-asc ml-2"
                      : "fa fa-sort-alpha-desc ml-2"
                  }
                  onClick={() => handleSort("firstName")}
                ></i></th>
                <th scope="col">Employee Id<i
                  className={
                    sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'empId'
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={() => handleSort("empId")}
                ></i></th>
                <th scope="col">Email</th>
                <th scope="col">Order</th>
                <th scope="col">Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total Price</th>
                <th scope="col">Time</th>
                <th scope="col">Date<i
                  className={
                    sortType == SORT_TYPE_CONSTANTS.Asscending && sortField == 'orderDate'
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={() => handleSort("orderDate")}
                ></i></th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            {!_.isEmpty(orderList) ? orderList.map((element, index) => {
              return (
                <tbody>
                  <tr>
                    <td>{index + 1 + (pageNo - 1) * visibleDataCount}</td>
                    <td>
                      {element.employeeName?.firstName}
                      {element.employeeName?.middleName === null || element.employeeName?.middleName === "" ? ' ' : element.employeeName?.middleName}
                      {element.employeeName?.lastName}
                    </td>
                    <td>{element.employeeEmpId}</td>
                    <td>{element.employeeEmail}</td>
                    <td>
                      {element.foods.name}
                    </td>

                    <td>{element.foods.price}</td>
                    <td>{element.foods.quantity}</td>
                    <td>{element.foods.total}</td>
                    <td>
                      {moment(element.created).format('HH:mm')}
                      {/* {tConvert(element.created.substring(11, 16))} */}


                    </td>
                    <td>
                      {moment(element.created).format("DD/MM/YYYY")}
                      {/* 
                        {new Date(element.created).getDate() +
                          "-" +
                          (new Date(element.created).getMonth() + 1) +
                          "-" +
                          new Date(element.created).getFullYear()} */}
                    </td>
                    <td>{orderStatusFunc(element.status)}</td>
                  </tr>
                </tbody>
              );
            })
              : ""}
          </table>
          {
            orderList && orderList.length > 0 ? "" : <h3 className="text-center">No data available</h3>
          }
        </div>
      ) : (
        <div className="loaderDiv">
          <DotLoader />
        </div>
      )}
      {parseInt(totalOrders) > 10 ? (
        <div className="paginationDiv">
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={visibleDataCount}
            totalItemsCount={parseInt(totalOrders)}
            pageRangeDisplayed={3}
            onChange={handleOnPageChangeclick}
          />
          {totalOrders && totalOrders > 0 ?
            <a id="exportButton" href={ordersExcelSheetlink}>
              Export
            </a>
            : ""}
        </div>
      ) : (
        ""
      )}

      {parseInt(totalOrders) <= 10 ? (
        <div className="paginationDiv float-right">
          {totalOrders && totalOrders > 0 ?
            <a id="exportButton" href={ordersExcelSheetlink}>
              Export
            </a>
            : ""}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    orderList: state.orderList.data?.orderList,
    totalOrders: state.orderList.data?.totalOrders,
    ordersExcelSheetlink: state.orderList.data?.orderSheet,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrderList: (query) => {
      dispatch(getOrderList(query));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderListByVendor);

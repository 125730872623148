import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import ResponseFilter from "../../../ResponseFilter";
import { deleteEmployeeDataInOrganization } from "../../../config/services/employeeServices";
import AddEditEmployeeModal from "./AddEditEmployeeModal";
import { EmpDelSuccess } from "../../helper/String";
import { handleResponse } from "../OrganizationChartUtils/OrganizationConstants";

function DeleteEmployeeFromOrgModal(props) {
  const {
    handleCloseDeleteModal,
    empIdDelete,
    openDeleteModal,
    employeeDetail,
    setOpenModal,
    openModal,
    delEmpParentId,
    delEmpId,
    handleClose,
    setResponse,
    setResponseMessage,
    setSuccess,
    setSuccessMessage,
    fetchData,
    data,
    employeeList,
    rootId,
    setLoader,
  } = props;

  const [toDelId, setToDelId] = useState("");

  const handleDelete = async (employeeId) => {
    setLoader(true);
    try {
      const obj = {
        params: {
          id: employeeId?.toString(),
        },
      };
      const res = await deleteEmployeeDataInOrganization(obj);
      const { status } = ResponseFilter(res);
      if (status) {
        setToDelId("");
        setSuccess(true);
        setSuccessMessage(EmpDelSuccess);
        fetchData();
      } else {
        setResponse(true);
        setResponseMessage(res.data.error.responseMessage);
        fetchData();
      }
      handleResponse({
        setResponse,
        setResponseMessage,
        setSuccess,
        setSuccessMessage,
      });
    } catch (error) {
      console.log("Error: ", error);
      setResponse(true);
      setResponseMessage(String(error));
    } finally {
      setLoader(false);
      handleClose();
      handleCloseDeleteModal();
    }
  };

  return (
    <>
      {!toDelId && employeeDetail && employeeDetail._directSubordinates ? (
        <AddEditEmployeeModal
          setLoader={setLoader}
          rootId={rootId}
          show={openModal}
          action="Edit"
          setOpenModal={setOpenModal}
          openModal={openModal}
          fetchData={fetchData}
          delEmpId={delEmpId}
          delEmpParentId={delEmpParentId}
          handleClose={handleClose}
          setResponse={setResponse}
          setResponseMessage={setResponseMessage}
          setToDelId={setToDelId}
          setSuccess={setSuccess}
          setSuccessMessage={setSuccessMessage}
          data={data}
          employeeList={employeeList}
        />
      ) : (
        <Modal show={openDeleteModal} onHide={handleCloseDeleteModal} centered>
          <Modal.Header className="text-center confirmation">
            <p className="mb-4 cm_bold cnfrm_msg">
              Are you sure you want to remove the employee?
            </p>
          </Modal.Header>
          <Modal.Footer className="d-flex justify-content-center">
            <div className="submit_button_margin mt-4">
              <button
                className="btn btn-danger cm_btn3"
                onClick={() => handleDelete(empIdDelete)}
              >
                Delete
              </button>
              <button
                type="button"
                onClick={handleCloseDeleteModal}
                className="btn text-secondary border border-dark"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default DeleteEmployeeFromOrgModal;

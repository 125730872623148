import React, { useEffect, useMemo, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { CONSTANTS } from '../../config'
import { currentYear } from '../leaves/Constants'
import { Toastify, createSelectItems, reducer } from '../helper/Constant'
import { useDispatch } from 'react-redux'
import { useDebounce } from '../attendance/Constants'
import AddAward from './awardManagementModal/AddAward'
import { addAwardName, deleteAwardName, getAwardNameList, getEmployeeList } from '../../config/services/employeeServices'
import ResponseFilter from '../../ResponseFilter'
import EditAward from './awardManagementModal/EditAward'
import { AwardValidation, addAwardNameVallidation } from './AwardValidation'
import { getAwardAction } from '../../redux/actions/getAwardAction'
import { Loader } from '../svg'
import { addAwardAction } from '../../redux/actions/addAwardAction'
import AwardListTable from './AwardListTable'
import { editAwardAction } from '../../redux/actions/editAwardAction'
import { deleteAwardAction } from '../../redux/actions/deleteAwardAction'
import { Select } from 'antd'
import qs from "qs"
import AddAwardName from './awardManagementModal/AddAwardName'
import AwardYearList from './AwardYearList'
import { changetoUpperCase } from './constants'
import './award.css'
import DeleAwardName from './awardManagementModal/DeleAwardName'
const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const type = 'typeCheckSet'
const { Option } = Select;
const initialState = {
    search: "",
    pageNo: 1,
    count: 10,
    sortType: SORT_TYPE_CONSTANTS.Descending,
    sortField: "created",
    searchYear: "",
    awardList: [],
    dataCount: 0,
    employelist: [],
    openAddAwardPopup: false,
    openEditAwardPopup: false,
    awardEmployeeName: "",
    awardEmployeeNameErrorMessage: "",
    awardName: "",
    awardNameErrorMessage: "",
    awardYear: 0,
    awardYearErrorMessage: "",
    reward: 0,
    rewardErrorMessage: "",
    awardMonth: 0,
    awardMonthErrorMessage: "",
    awardType: 0,
    awardTypeErrorMessage: "",
    nomnieeEmployees: [],
    resAwardMsg: false,
    resAwardFailMsg: false,
    successMessage: "",
    responseMessage: "",
    miniLoader: false,
    searchMonth: 0,
    filterAwardType: 0,
    awardId: "",
    showAwardList: false,
    awardNameList: [],
    openAddAwardNameTypePopup: false,
    awardNameType: "",
    awardNameTypeErrorMessage: "",
    awardDescription: "",
    awardEmployeeImage: null,
    awardEmployeeImageErrorMessage: "",
    tabStatus: 0,
    openAwardDetail: false,
    index: '',
    awardDetail: {},
    awardTitle: "",
    fullImage: "",
    openFullImage: false,
    openDeleteAwardNamePopup: false,
    openDeleteAwardPopup: false,
    deleteAwardId: ""

}

export default function AwardList() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory()
    const {
        search,
        pageNo,
        count,
        sortType,
        sortField,
        searchYear,
        awardList,
        dataCount,
        employeeList,
        openAddAwardPopup,
        openEditAwardPopup,
        awardEmployeeName,
        awardEmployeeNameErrorMessage,
        awardName,
        awardNameErrorMessage,
        awardYear,
        awardYearErrorMessage,
        reward,
        rewardErrorMessage,
        awardMonth,
        awardMonthErrorMessage,
        awardType,
        awardTypeErrorMessage,
        nomnieeEmployees,
        miniLoader,
        resAwardMsg,
        resAwardFailMsg,
        successMessage,
        responseMessage,
        searchMonth,
        filterAwardType,
        awardId,
        awardNameList,
        awardNameType,
        awardNameTypeErrorMessage,
        openAddAwardNameTypePopup,
        showAwardList,
        awardDescription,
        awardEmployeeImage,
        awardEmployeeImageErrorMessage,
        tabStatus,
        openAwardDetail,
        index,
        awardDetail,
        awardTitle,
        fullImage,
        openFullImage,
        openDeleteAwardNamePopup,
        openDeleteAwardPopup,
        deleteAwardId,

    } = state
    const debouncedSearchTerm = useDebounce(search, 2000);
    const dispatchToAction = useDispatch()
    let urlParams = `search=${search}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo}&month=${searchMonth}&year=${searchYear}&awardType=${filterAwardType}&showAwardList=${showAwardList}&tabStatus=${tabStatus}`

    let paraMeters = `search=${search.trim()}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}&awardType=${filterAwardType ? filterAwardType : ""}&year=${searchYear}`

    useEffect(() => {
        if (localStorage.getItem("isAdmin") == 1 || localStorage.getItem("isAdmin") == 4) {
            getAwardList();
            getEmployee();
            getAwardNameListFunction()
        }
        else {
            getAwardList();
        }
    }, [debouncedSearchTerm, pageNo, sortType, searchYear, searchMonth, filterAwardType, showAwardList, tabStatus])
    const getEmployee = () => {
        let params = ``
        getEmployeeList(params).then((res) => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                dispatch({
                    type: 'typeCheckSet', payload: {
                        employeeList: resData.employeesList,
                    }
                })
            }
        });
    };
    const getAwardNameListFunction = () => {
        let params = ``
        getAwardNameList(params).then((res) => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                dispatch({
                    type: 'typeCheckSet', payload: {
                        awardNameList: resData.list,
                    }
                })
            }
        });
    };
    const handleShowAwardList = (e) => {
        dispatch({
            type: type, payload: {
                showAwardList: true,
                searchYear: e
            }
        })
    }
    const handleFilter = (e) => {
        dispatch({
            type: type, payload: {
                tabStatus: e,
                pageNo: 1
            }
        })
    }

    useMemo(() => {
        const filterParams = history.location.search.substr(1);
        const filtersFromParams = qs.parse(filterParams);
        dispatch({
            type: 'typeCheckSet', payload: {
                pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
                search: filtersFromParams.search ? filtersFromParams.search : "",
                sortField: filtersFromParams.sortField ? filtersFromParams.sortField : "created",
                sortType: filtersFromParams.sortType ? Number(filtersFromParams.sortType) : SORT_TYPE_CONSTANTS.Asscending,
                searchMonth: filtersFromParams.month ? parseInt(filtersFromParams.month) : 0,
                searchYear: filtersFromParams.year ? parseInt(filtersFromParams.year) : "",
                filterAwardType: filtersFromParams.awardType ? parseInt(filtersFromParams.awardType) : 0,
                showAwardList: filtersFromParams.showAwardList == "true" ? Boolean(filtersFromParams.showAwardList) : false,
                tabStatus: filtersFromParams.tabStatus ? parseInt(filtersFromParams.tabStatus) : 0


            }
        })

    }, [history.location.search]);

    const handlePageChange = (pageNo) => {
        dispatch({
            type: type, payload: {
                pageNo: pageNo
            }
        })
    }
    const reverseView = () => {
        dispatch({
            type: type, payload: {
                showAwardList: false,
                searchYear: "",
                tabStatus: 0
            }
        })
    }
    const getAwardList = () => {
        history.push(`?${urlParams}`)
        const params = paraMeters
        dispatch({
            type: type, payload: {
                miniLoader: true
            }
        })
        dispatchToAction(getAwardAction({
            useDispatch: dispatch,
            params: params,
        }))
    }
    const handleSearch = (e) => {
        dispatch({
            type: 'typeCheckSet', payload: {
                pageNo: 1,
                search: e.target.value,
            }
        })
        if (e.target.value === "" || e.target.value === undefined) {
            dispatch({
                type: 'typeCheckSet', payload: {
                    search: '',
                    pageNo: 1,

                }
            })
        }
    };

    const handleSelectChange = () => {
        dispatch({
            type: 'typeCheckSet', payload: {
                search: ''
            }
        })
    };
    const handleAddAwardPopup = () => {
        dispatch({
            type: type, payload: {
                openAddAwardPopup: true
            }
        })
    }

    const handleAwardEmployeeImageChange = (e) => {
        dispatch({
            type: type, payload: {
                awardEmployeeImage: e.target.files[0],
                awardEmployeeImageErrorMessage: ""
            }
        })
    }
    const handleChange = (e, name) => {
        if (name == "awardName") {
            dispatch({
                type: type, payload: {
                    awardName: e,
                    awardNameErrorMessage: ""
                }
            })
        }
        else {
            dispatch({
                type: type, payload: {
                    [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
                    [e.target.name + 'ErrorMessage']: '',
                }
            })
        }
    }
    const handleSelectEmployeeChange = (e) => {
        dispatch({
            type: type, payload: {
                awardEmployeeName: e,
                awardEmployeeNameErrorMessage: ""
            }
        })
    }
    const handleSelectNomnieeEmployeeChange = (e) => {
        dispatch({
            type: type, payload: {
                nomnieeEmployees: e
            }
        })
    }

    const handleMonthChange = (e) => {
        dispatch({
            type: type, payload: {
                awardMonth: e
            }
        })
    }
    const handleAwardType = (e) => {
        dispatch({
            type: type, payload: {
                awardType: e,
                awardTypeErrorMessage: ""
            }
        })
    }

    const handleClose = () => {
        dispatch({
            type: type, payload: {
                openAddAwardPopup: false,
                openEditAwardPopup: false,
                awardEmployeeName: "",
                awardEmployeeNameErrorMessage: "",
                awardName: "",
                awardNameErrorMessage: "",
                awardYear: 0,
                awardYearErrorMessage: "",
                reward: 0,
                rewardErrorMessage: "",
                awardMonth: 0,
                awardMonthErrorMessage: "",
                awardType: 0,
                awardTypeErrorMessage: "",
                nomnieeEmployees: [],
                awardNameType: "",
                awardNameTypeErrorMessage: "",
                openAddAwardNameTypePopup: false,
                awardEmployeeImageErrorMessage: "",
                awardDescription: "",
                awardEmployeeImage: null,
                openFullImage: false,
                fullImage: "",
                openDeleteAwardNamePopup: false,
                openDeleteAwardPopup: false,
                openDeleteAwardPopup: ""
            }
        })
    }
    const handleAddSubmit = () => {
        if (AwardValidation({
            awardEmployeeName: awardEmployeeName,
            nomnieeEmployees: nomnieeEmployees,
            awardName: awardName,
            awardType: awardType,
            reward: reward,
            awardEmployeeImage: awardEmployeeImage,
            awardYear: awardYear,
            awardEmployeeImage: awardEmployeeImage,
            type: type,
            dispatch: dispatch,
        })) {
            dispatch({
                type: type, payload: {
                    miniLoader: true
                }
            })
            const params = new FormData()
            params.append("givenTo", awardEmployeeName)
            params.append("nominee", JSON.stringify(nomnieeEmployees))
            params.append("awardName", awardName)
            params.append("amount", reward ? parseInt(reward) : reward)
            params.append("year", parseInt(awardYear))
            params.append("month", awardMonth == undefined ? "" : parseInt(awardMonth))
            params.append("awardType", parseInt(awardType))
            params.append("awardEmployeeImage", awardEmployeeImage)
            params.append("awardDescription", awardDescription)

            dispatchToAction(addAwardAction({
                useDispatch: dispatch,
                params: params,
                closeModal: handleClose,
                getAwardList: getAwardList,
            }))
        }
    }

    const handleEditSubmit = () => {
        if (AwardValidation({
            awardEmployeeName: awardEmployeeName,
            nomnieeEmployees: nomnieeEmployees,
            awardName: awardName,
            awardType: awardType,
            reward: reward,
            awardYear: awardYear,
            type: type,
            dispatch: dispatch,
        })) {
            dispatch({
                type: type, payload: {
                    miniLoader: true
                }
            })
            const params = {
                awardId: awardId,
                givenTo: awardEmployeeName,
                nominee: nomnieeEmployees,
                awardName: awardName,
                amount: parseInt(reward),
                year: parseInt(awardYear),
                month: parseInt(awardMonth),
                awardType: parseInt(awardType),
            }
            dispatchToAction(editAwardAction({
                useDispatch: dispatch,
                params: params,
                closeModal: handleClose,
                getAwardList: getAwardList,
            }))
        }
    }
    const handleSort = (e) => {
        dispatch({
            type: type, payload: {
                sortField: e,
                sortType: sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending,
            }
        })
    }
    const editAward = (e) => {
        dispatch({
            type: type, payload: {
                openEditAwardPopup: true,
                awardMonth: e.month,
                awardYear: e.year,
                awardName: e.awardName,
                reward: e.amount,
                awardType: e.awardType,
                // awardEmployeeName: e?.givenTo?.length > 0 ? e?.givenTo.map((data) => { return data?.employeeId }) : [],
                nomnieeEmployees: e?.nominee?.length > 0 ? e?.nominee.map((data) => { return data?.employeeId }) : [],
                awardId: e._id
            }
        })
    }
    const deleteAward = (e) => {
        const params = deleteAwardId
        dispatch({
            type: type, payload: {
                miniLoader: true
            }
        })
        dispatchToAction(deleteAwardAction({
            useDispatch: dispatch,
            params: params,
            getAwardList: getAwardList,
            handleClose: handleClose
        }))
    }
    const handleYear = (year) => {
        if (year === undefined) {
            dispatch({
                type: 'typeCheckSet', payload: {
                    searchYear: "",
                    searchMonth: 0,
                    pageNo: 1,
                    year: false,
                }
            })
        }
        else {
            dispatch({
                type: 'typeCheckSet', payload: {
                    searchYear: year,
                    searchMonth: 0,
                    pageNo: 1,
                    year: true,
                }
            })
        }
    }
    const handleFilterAwardType = (e) => {
        dispatch({
            type: type, payload: {
                filterAwardType: e
            }
        })
    }

    const handleAddAwardName = (e) => {
        dispatch({
            type: type, payload: {
                awardNameType: e.target.value,
                awardNameTypeErrorMessage: ""
            }
        })
    }

    const handleAddAwardNamePopup = () => {
        dispatch({
            type: type, payload: {
                openAddAwardNameTypePopup: true
            }
        })
    }

    const handleAddAwardNameSubmit = () => {
        if (addAwardNameVallidation({
            dispatch: dispatch,
            awardNameType: awardNameType,
            type: type
        })) {
            const params = {
                name: changetoUpperCase(awardNameType),
            }
            addAwardName(params).then((res) => {
                let { status, resData } = ResponseFilter(res);
                if (status) {
                    dispatch({
                        type: 'typeCheckSet', payload: {
                            resAwardMsg: true,
                            successMessage: "Award Name Added",
                            loader: false
                        }
                    })
                    handleClose();
                    getAwardNameListFunction()
                    setTimeout(() => dispatch({
                        type: 'typeCheckSet', payload: {
                            resAwardMsg: false
                        }
                    }), 3000);
                }
                else {
                    dispatch({
                        type: 'typeCheckSet', payload: {
                            resAwardFailMsg: true,
                            responseMessage: res.data.error.responseMessage,
                            loader: false
                        }
                    })
                    setTimeout(() => dispatch({
                        type: 'typeCheckSet', payload: {
                            resAwardFailMsg: false
                        }
                    }), 3000);
                }
            });
        }

    }

    const showAwardDetail = (index, title, awardName) => {
        dispatch({
            type: 'typeCheckSet', payload: {
                openAwardDetail: true,
                index: index,
                awardDetail: title,
                awardTitle: awardName
            }
        })
    }
    const hideAwardDetail = () => {
        dispatch({
            type: 'typeCheckSet', payload: {
                openAwardDetail: false,
                index: '',
                awardDetail: '',
                awardTitle: ""
            }
        })
    }

    const handleFullImage = (image) => {
        dispatch({
            type: 'typeCheckSet', payload: {
                fullImage: image,
                openFullImage: true
            }
        })
    }

    const handleDeleteAwardName = () => {
        dispatch({
            type: 'typeCheckSet', payload: {
                openDeleteAwardNamePopup: true,
            }
        })
    }

    const handleAwardNameDeleteSubmit = (e) => {
        const params = e
        dispatch({
            type: type, payload: {
                miniLoader: true
            }
        })
        deleteAwardName(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                dispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardMsg: true,
                        successMessage: "Award Name Deleted",
                        miniLoader: false
                    }
                })
                handleClose()
                getAwardNameList()
                setTimeout(() => dispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardMsg: false
                    }
                }), 3000);
            } else {
                dispatch({
                    type: 'typeCheckSet', payload: {
                        responseMessage: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resAwardFailMsg: true,
                        miniLoader: false
                    }
                })
                setTimeout(() => dispatch({
                    type: 'typeCheckSet', payload: {
                        resAwardFailMsg: false
                    }
                }), 3000);
                getAwardNameList()

            }
        })
    }
    const handleOpenDeleteAward = (e) => {
        dispatch({
            type: "typeCheckSet", payload: {
                openDeleteAwardPopup: true,
                deleteAwardId: e
            }
        })

    }
    return (
        <div className="body_container cm_bold">
            {miniLoader && <Loader />}
            <Toastify
                resMsg={resAwardMsg}
                resMsgFail={resAwardFailMsg}
                text={successMessage}
                responseMessage={responseMessage}
            />
            <h3>Award Management</h3>
            <div className="filters justify-content-end mt-2">
                <Select
                    className="select_year_cal"
                    value={searchYear ? searchYear : "Select Year"}
                    defaultValue="Select Year"
                    name="searchYear"
                    onChange={handleYear}
                    allowClear={searchYear ? true : false}
                >
                    {createSelectItems()}
                </Select>
                {showAwardList == false && (localStorage.getItem("isAdmin") == 1 || localStorage.getItem("isAdmin") == 4) ? <>
                    <button className='btn btn-primary' onClick={handleAddAwardPopup}>
                        Add Award
                    </button>
                    <button className='btn btn-primary' onClick={handleAddAwardNamePopup}>
                        Add Award Name
                    </button>
                    <button className='btn btn-danger' onClick={handleDeleteAwardName}> Delete Award Name</button>
                </> : ""}
            </div>
            {showAwardList == true ? <AwardListTable
                awardList={awardList}
                dataCount={dataCount}
                miniLoader={miniLoader}
                count={count}
                handlePageChange={handlePageChange}
                pageNo={pageNo}
                sortField={sortField}
                sortType={sortType}
                handleSort={handleSort}
                editAward={editAward}
                deleteAward={deleteAward}
                handleOpenDeleteAward={handleOpenDeleteAward}
                openDeleteAwardPopup={openDeleteAwardPopup}
                tabStatus={tabStatus}
                handleFilter={handleFilter}
                reverseView={reverseView}
                searchYear={searchYear}
                openAwardDetail={openAwardDetail}
                index={index}
                awardDetail={awardDetail}
                showAwardDetail={showAwardDetail}
                hideAwardDetail={hideAwardDetail}
                awardTitle={awardTitle}
                handleFullImage={handleFullImage}
                fullImage={fullImage}
                handleClose={handleClose}
                openFullImage={openFullImage}
            />
                :
                <AwardYearList
                    awardNameList={awardList}
                    pageNo={pageNo}
                    count={count}
                    dataCount={dataCount}
                    handlePageChange={handlePageChange}
                    handleShowAwardList={handleShowAwardList}


                />}

            < AddAwardName
                show={openAddAwardNameTypePopup}
                handleChange={handleAddAwardName}
                awardNameType={awardNameType}
                awardNameTypeErrorMessage={awardNameTypeErrorMessage}
                handleClose={handleClose}
                handleSubmit={handleAddAwardNameSubmit}
            />
            <AddAward
                show={openAddAwardPopup}
                employeeList={employeeList}
                handleClose={handleClose}
                awardEmployeeName={awardEmployeeName}
                awardEmployeeNameErrorMessage={awardEmployeeNameErrorMessage}
                awardName={awardName}
                awardNameErrorMessage={awardNameErrorMessage}
                awardMonth={awardMonth}
                awardMonthErrorMessage={awardMonthErrorMessage}
                awardYear={awardYear}
                awardYearErrorMessage={awardYearErrorMessage}
                reward={reward}
                rewardErrorMessage={rewardErrorMessage}
                awardType={awardType}
                awardTypeErrorMessage={awardTypeErrorMessage}
                nomnieeEmployees={nomnieeEmployees}
                handleChange={handleChange}
                handleSelectEmployeeChange={handleSelectEmployeeChange}
                handleSelectNomnieeEmployeeChange={handleSelectNomnieeEmployeeChange}
                handleAwardType={handleAwardType}
                handleMonthChange={handleMonthChange}
                handleSubmit={handleAddSubmit}
                awardNameList={awardNameList}
                awardDescription={awardDescription}
                awardEmployeeImage={awardEmployeeImage}
                awardEmployeeImageErrorMessage={awardEmployeeImageErrorMessage}
                handleAwardEmployeeImageChange={handleAwardEmployeeImageChange}
            />

            <EditAward
                show={openEditAwardPopup}
                employeeList={employeeList}
                handleClose={handleClose}
                awardEmployeeName={awardEmployeeName}
                awardEmployeeNameErrorMessage={awardEmployeeNameErrorMessage}
                awardName={awardName}
                awardNameErrorMessage={awardNameErrorMessage}
                awardMonth={awardMonth}
                awardMonthErrorMessage={awardMonthErrorMessage}
                awardYear={awardYear}
                awardYearErrorMessage={awardYearErrorMessage}
                reward={reward}
                rewardErrorMessage={rewardErrorMessage}
                awardType={awardType}
                awardTypeErrorMessage={awardTypeErrorMessage}
                nomnieeEmployees={nomnieeEmployees}
                handleChange={handleChange}
                handleSelectEmployeeChange={handleSelectEmployeeChange}
                handleSelectNomnieeEmployeeChange={handleSelectNomnieeEmployeeChange}
                handleAwardType={handleAwardType}
                handleMonthChange={handleMonthChange}
                handleSubmit={handleEditSubmit}
            />
            <DeleAwardName
                show={openDeleteAwardNamePopup}
                handleClose={handleClose}
                miniLoader={miniLoader}
                handleSubmit={handleAwardNameDeleteSubmit}
                awardNameList={awardNameList}
            />
        </div>
    )
}

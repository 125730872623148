import React from "react";
import { Modal } from "react-bootstrap";
import { createMarkup } from "../../helper/Constant";

const PendingTicketModal = (props) => {
  const {
    openPendingPopup,
    closeModal,
    handleStatusSubmit,
    subject,
    description,
    status,
    reply,
    handleChange,
  } = props;
  return (
    <Modal
      size="lg"
      show={openPendingPopup}
      onHide={closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <form className="approve_leave" onSubmit={handleStatusSubmit}>
          <h3 className="">STATUS</h3>
          <div className="">
            <label>Subject</label>
            <p>{subject}</p>
            <label className="mt-4">Reason</label>
            <br />
            <h5 dangerouslySetInnerHTML={createMarkup(description)}/>
          </div>
          {status === 1 || status === 3 ? (
            <label className="mt-4">Reply</label>
          ) : (
            ""
          )}
          {status === 1 || status === 3 ? (
            <textarea
              className="form-control"
              placeholder="Type here"
              name="reply"
              maxLength={150}
              value={reply}
              onChange={handleChange}
            ></textarea>
          ) : (
            ""
          )}
          {status === 1 || status === 3 ? (
            <div className="submit_button_margin mt-4">
              <button type="submit" className="btn btn-primary cm_btn3">
                Resolve
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-primary  cm_btn2"
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="text-center">
              <h5 className="green cm_bold mt-3 ">Resolved</h5>
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PendingTicketModal;

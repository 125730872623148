import React from "react";
import { CONSTANTS } from "../../config";
import { handleSort, type } from "../helper/Constant";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import _ from "lodash";

let url = "/admin-attendance/";

const ViewAdminAttendance = (props) => {
  const {
    sortType,
    sortField,
    AttendanceDetails,
    pageNo,
    handleOpenEditAttendancePopup,
    handleOpenDeleteAttendancePopup,
    handleOpenLeavePopup,
    checkBox,
    dispatch,
  } = props;
  return (
    <div class="table-responsive">
      <table className="table table-bordered">
        <thead className="white bg_blue">
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">
              Employee ID
              <i
                className={
                  sortType === CONSTANTS.SORT_TYPE_CONSTANTS.Asscending &&
                  sortField === "mobId"
                    ? "fa fa-sort-numeric-asc ml-2"
                    : "fa fa-sort-numeric-desc ml-2"
                }
                onClick={() => handleSort("mobId", dispatch, sortType)}
              ></i>
            </th>
            <th>
              Name
              <i
                className={
                  sortType === CONSTANTS.SORT_TYPE_CONSTANTS.Asscending &&
                  sortField === "employeeName"
                    ? "fa fa-sort-alpha-asc ml-2"
                    : "fa fa-sort-alpha-desc ml-2"
                }
                onClick={() => handleSort("employeeName", dispatch, sortType)}
              ></i>
            </th>

            <th scope="col">Date</th>
            <th scope="col">Check In</th>
            <th scope="col">Late Check In</th>
            <th scope="col">Early Check Out</th>
            <th scope="col">Check Out</th>
            <th scope="col">
              Total Hours
              <i
                className={
                  sortType === CONSTANTS.SORT_TYPE_CONSTANTS.Descending &&
                  sortField === "totalHours"
                    ? "fa fa-sort-numeric-desc ml-2"
                    : "fa fa-sort-numeric-asc ml-2"
                }
                onClick={() => handleSort("totalHours", dispatch, sortType)}
              ></i>
            </th>
            <th scope="col" style={{ width: 120 }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(AttendanceDetails)
            ? AttendanceDetails.map((data, i) => (
                <tr>
                  <td>
                    <span className="cm_col">{i + 1 + (pageNo - 1) * 10}</span>
                  </td>
                  <td>
                    <span className="cm_col"> {data.mobId}</span>
                  </td>
                  <td>
                    <span className="cm_col">
                      <Link
                        to={{
                          pathname: `${url}${data.employeeId}`,
                          state: { empName: data.employeeName },
                        }}
                      >
                        {data.employeeName}
                      </Link>
                    </span>
                  </td>
                  <td>
                    {new Date(data.date).getDate().toString().padStart(2, "0") +
                      "/" +
                      (new Date(data.date).getMonth() + 1)
                        .toString()
                        .padStart(2, "0") +
                      "/" +
                      new Date(data.date)
                        .getFullYear()
                        .toString()
                        .padStart(2, "0")}
                  </td>
                  <td>
                    <time>
                      {data.checkIn > "0" &&
                      (data.checkIn.includes(".")
                        ? parseInt(
                            +data.checkIn.split(".")[0] * 60 * 60 +
                              +data.checkIn.split(".")[1] * 60
                          )
                        : parseInt(data.checkIn * 60 * 60)) <=
                        data.reportingTime + 1800
                        ? data.checkIn.indexOf(".") !== -1
                          ? data.checkIn.toString().split(".")[1].length <= 1
                            ? data.checkIn.toString().split(".")[0] +
                              ":" +
                              data.checkIn.toString().split(".")[1] +
                              "0 AM"
                            : data.checkIn.toString().split(".")[0] +
                              ":" +
                              data.checkIn.toString().split(".")[1] +
                              " AM"
                          : data.checkIn + ":00 AM"
                        : "_"}
                    </time>
                  </td>
                  <td className="text-danger">
                    <time>
                      {data.checkIn > "0"
                        ? (data.checkIn.includes(".")
                            ? parseInt(
                                +data.checkIn.split(".")[0] * 60 * 60 +
                                  +data.checkIn.split(".")[1] * 60
                              )
                            : parseInt(data.checkIn * 60 * 60)) >
                            data.reportingTime + 1800 &&
                          data.checkIn.toString().split(".")[0] < 12
                          ? data.checkIn.indexOf(".") !== -1
                            ? data.checkIn.toString().split(".")[1].length <= 1
                              ? data.checkIn.toString().split(".")[0] +
                                ":" +
                                data.checkIn.toString().split(".")[1] +
                                "0 AM"
                              : data.checkIn.toString().split(".")[0] +
                                ":" +
                                data.checkIn.toString().split(".")[1] +
                                " AM"
                            : data.checkIn + ":00 AM"
                          : (data.checkIn.includes(".")
                              ? parseInt(
                                  +data.checkIn.split(".")[0] * 60 * 60 +
                                    +data.checkIn.split(".")[1] * 60
                                )
                              : parseInt(data.checkIn * 60 * 60)) &&
                            data.checkIn.toString().split(".")[0] >= 12
                          ? data.checkIn.indexOf(".") !== -1
                            ? data.checkIn.toString().split(".")[1].length <= 1
                              ? data.checkIn.toString().split(".")[0] +
                                ":" +
                                data.checkIn.toString().split(".")[1] +
                                "0 PM"
                              : data.checkIn.toString().split(".")[0] +
                                ":" +
                                data.checkIn.toString().split(".")[1] +
                                " PM"
                            : data.checkIn + ":00 PM"
                          : "_"
                        : "_"}
                    </time>
                  </td>
                  <td>
                    <span className="text-danger">
                      <time>
                        {data.checkOut > "0" &&
                        data.totalHours < 32400 &&
                        data.checkIn !== "0" &&
                        data.checkOut.toString().split(".")[0] <= 12
                          ? data.checkOut.indexOf(".") !== -1
                            ? data.checkOut.toString().split(".")[1].length <= 1
                              ? data.checkOut.toString().split(".")[0] +
                                ":" +
                                data.checkOut.toString().split(".")[1] +
                                "0 AM"
                              : data.checkOut.toString().split(".")[0] +
                                ":" +
                                data.checkOut.toString().split(".")[1] +
                                " AM"
                            : data.checkOut + ":00 AM"
                          : data.checkOut > "0" &&
                            data.totalHours < 32400 &&
                            data.checkIn !== "0" &&
                            data.checkOut.toString().split(".")[0] > 12
                          ? data.checkOut.indexOf(".") !== -1
                            ? data.checkOut.toString().split(".")[1].length <= 1
                              ? data.checkOut.toString().split(".")[0] +
                                ":" +
                                data.checkOut.toString().split(".")[1] +
                                "0 PM"
                              : data.checkOut.toString().split(".")[0] +
                                ":" +
                                data.checkOut.toString().split(".")[1] +
                                " PM"
                            : data.checkOut + ":00 PM"
                          : "_"}
                      </time>
                    </span>
                  </td>
                  <td>
                    {data.checkOut > "0" ? (
                      data.totalHours === 0 ? (
                        <time>
                          {data.checkOut.indexOf(".") !== -1
                            ? data.checkOut.toString().split(".")[1].length <= 1
                              ? data.checkOut.toString().split(".")[0] +
                                ":" +
                                data.checkOut.toString().split(".")[1] +
                                "0 PM"
                              : data.checkOut.toString().split(".")[0] +
                                ":" +
                                data.checkOut.toString().split(".")[1] +
                                " PM"
                            : data.checkOut + ":00 PM"}
                        </time>
                      ) : data.totalHours >= 32400 ? (
                        <span className="green">
                          <time>
                            {data.checkOut.toString().split(".")[0] < 12
                              ? data.checkOut.indexOf(".") !== -1
                                ? data.checkOut.toString().split(".")[1]
                                    .length <= 1
                                  ? data.checkOut.toString().split(".")[0] +
                                    ":" +
                                    data.checkOut.toString().split(".")[1] +
                                    "0 AM"
                                  : data.checkOut.toString().split(".")[0] +
                                    ":" +
                                    data.checkOut.toString().split(".")[1] +
                                    " AM"
                                : data.checkOut + ":00 AM"
                              : data.checkOut.indexOf(".") !== -1
                              ? data.checkOut.toString().split(".")[1].length <=
                                1
                                ? data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  "0 PM"
                                : data.checkOut.toString().split(".")[0] +
                                  ":" +
                                  data.checkOut.toString().split(".")[1] +
                                  " PM"
                              : data.checkOut + ":00 PM"}
                          </time>
                        </span>
                      ) : (
                        "_"
                      )
                    ) : (
                      "_"
                    )}
                  </td>

                  <td>
                    {moment.utc(data.totalHours * 1000).format("HH:mm") <
                      moment.utc(32400 * 1000).format("HH:mm") &&
                    moment.utc(data.totalHours * 1000).format("HH:mm") >=
                      moment.utc(28800 * 1000).format("HH:mm") ? (
                      <time title="View attendance record" className="yellow">
                        {data.totalHours
                          ? moment.utc(data.totalHours * 1000).format("HH.mm")
                          : "00.00"}
                      </time>
                    ) : moment.utc(data.totalHours * 1000).format("HH:mm") <
                      moment.utc(32400 * 1000).format("HH:mm") ? (
                      <time
                        title="View attendance record"
                        className="text-danger"
                      >
                        {data.totalHours
                          ? moment.utc(data.totalHours * 1000).format("HH.mm")
                          : "00.00"}
                      </time>
                    ) : (
                      <time title="View attendance record" className="green">
                        {data.totalHours
                          ? moment.utc(data.totalHours * 1000).format("HH.mm")
                          : "00.00"}
                      </time>
                    )}
                  </td>
                  <td style={{ width: 160 }}>
                    <div className="cm_device">
                      <span onClick={() => handleOpenEditAttendancePopup(data)}>
                        <i
                          className="fa fa-pencil"
                          aria-hidden="true"
                          title="Edit attendance"
                        ></i>
                      </span>
                      <span
                        onClick={() => handleOpenDeleteAttendancePopup(data)}
                      >
                        <i
                          className="fa fa-trash-o ml-4 red"
                          title="Delete attendance"
                        ></i>
                      </span>
                      {data.leaveId != "" ? (
                        <span onClick={() => handleOpenLeavePopup(data)}>
                          <i
                            className="fa fa-clock-o red ml-4"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      <span>
                        {checkBox !== "" && data.leaveId !== "" ? (
                          <input
                            className=" ml-3 "
                            name="editEmployee"
                            type="checkbox"
                            value={data.employeeId}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            : ""}
        </tbody>
      </table>
      {AttendanceDetails && AttendanceDetails.length > 0 ? (
        ""
      ) : (
        <h3 className="text-center">No data available</h3>
      )}
    </div>
  );
};

export default ViewAdminAttendance;

import { REQUIRED, setResponseActionState, type } from "../../helper/Constant";
import ResponseFilter from "../../../ResponseFilter";
import {
  editInvestmentForm,
  getInvestmentDetails,
  investmentform,
  uploadFilesInvestment,
} from "../../../config/services/employeeServices";
import { stat } from "fs";

const count = 10;

export const initialState = {
  show: false,
  editFile: false,
  option1: "",
  data: "",
  pageNo: 1,
  totalInvest: "",
  selectYear: "",
  selectYearErr: "",
};

export const handleClose = (dispatch) => {
  dispatch({
    type: type,
    payload: {
      show: false,
    },
  });
};
export const handleShow = (dispatch) => {
  dispatch({
    type: type,
    payload: {
      show: true,
    },
  });
};
export const handleCloseEditFilePopup = (dispatch) => {
  dispatch({
    type: type,
    payload: {
      editFile: false,
    },
  });
};
export const handleShowEditFilePopup = (dispatch) => {
  dispatch({
    type: type,
    payload: {
      editFile: true,
    },
  });
};

export const getTableData = (dispatch) => {
  getInvestmentDetails()
    .then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        const details = resData.responseData.investments.investments;
        dispatch({
          type: type,
          payload: {
            data: details,
            totalInvest: resData.responseData.investments.totalInvestments,
          },
        });
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getData = (props) => {
  const { searchName, pageNo } = props;
  const query = `&search=${searchName.trim()}&pageNo=${
    pageNo - 1
  }&count=${count}`;

  getTableData(query);
};

export const UPLOAD_FILE_ERROR = "*Upload file for proof";
export const AMOUNT_ERROR = "Please fill the amount";
export const validation = (props) => {
  const { setState, state } = props;
  const {
    houseRentAmount,
    rentAgreement,
    landlordPanCard,
    deductionAmount_1,
    deductionAmountFile1,
    deductionAmount_2,
    deductionAmountFile2,
    deductionAmount_3,
    deductionAmountFile3,
    LIC,
    PPF,
    VPF,
    NSC,
    others,
    tutionFee,
    housingLoan,
    stampDutyPolicy,
    bankFixedPolicy,
    postOffice,
    SSAS,
    pensionFund,
    pensionScheme,
    nationalPensionScheme,
    pensionFromInsurance,
    medicalInsurance,
    medicalInsuranceParents,
    interestForEducation,
    deductionOthers,
    lifeInsuranceAmount,
    ppf_Amount,
    vpf_Amount,
    nsc_Amount,
    elss_Amount,
    mutual_fund,
    tuition_Amount,
    housing_Loan_Amount,
    stamp_Duty_Amount,
    bank_Fixed_Amount,
    postOffice_Amount,
    accountScheme_Amount,
    others_Amount,
    d80CCC_Amount,
    d80CCD_1_Amount,
    d80CCD_1B_Amount,
    preventive_Health_Amount,
    medicalInsuranceChildren_Amount,
    medicalInsuranceParent_Amount,
    specify_amount,
    interest_Amount,
    employeePAN,
    scheme,
    lifeInsurancePolicy,
    pf_Amount,
    pf_Policy,
    ppf_Policy,
    vpf_Policy,
    nsc_Policy,
    elss_Policy,
    tuition_Policy,
    housing_Loan_Policy,
    stamp_Duty_Policy,
    bank_Fixed_Policy,
    postOffice_Policy,
    accountScheme_Policy,
    others_Policy,
    specify_policy,
    other_specify_policy,
    metro,
  } = state;
  let validate = true;
  if (metro && (!houseRentAmount || !rentAgreement || !landlordPanCard)) {
    setState((prevState) => ({
      ...prevState,
      houseRentAmountErr: REQUIRED,
    }));
    validate = false;
  }
  if ((houseRentAmount || rentAgreement || landlordPanCard) && !metro) {
    setState((prevState) => ({
      ...prevState,
      metroErr: REQUIRED,
    }));
    validate = false;
  }
  if (houseRentAmount && !rentAgreement && !landlordPanCard) {
    setState((prevState) => ({
      ...prevState,
      houseRentAmountErr: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (deductionAmount_1 && !deductionAmountFile1) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile1Err: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (deductionAmount_1 && deductionAmount_1 > 30000) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile1Err: "*Amount cannot be greater 30000",
    }));
    validate = false;
  }
  if (deductionAmount_2 && !deductionAmountFile2) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile2Err: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (deductionAmount_2 && deductionAmount_2 > 200000) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile2Err: "*Amount cannot be greater 200000",
    }));
    validate = false;
  }
  if (deductionAmount_3 && !deductionAmountFile3) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile3Err: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (rentAgreement && landlordPanCard && !houseRentAmount) {
    setState((prevState) => ({
      ...prevState,
      houseRentAmountErr: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (deductionAmountFile1 && !deductionAmount_1) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile1Err: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (deductionAmountFile2 && !deductionAmount_2) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile2Err: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (deductionAmountFile3 && !deductionAmount_3) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile3Err: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (lifeInsuranceAmount && !LIC) {
    setState((prevState) => ({
      ...prevState,
      LICErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!lifeInsuranceAmount && LIC) {
    setState((prevState) => ({
      ...prevState,
      LICErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (lifeInsurancePolicy && !lifeInsuranceAmount) {
    setState((prevState) => ({
      ...prevState,
      LICErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!lifeInsurancePolicy && lifeInsuranceAmount) {
    setState((prevState) => ({
      ...prevState,
      lifeInsurancePolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (pf_Policy && !pf_Amount) {
    setState((prevState) => ({
      ...prevState,
      pfErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!pf_Policy && pf_Amount) {
    setState((prevState) => ({
      ...prevState,
      pf_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (ppf_Amount && !PPF) {
    setState((prevState) => ({
      ...prevState,
      PPFErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!ppf_Amount && PPF) {
    setState((prevState) => ({
      ...prevState,
      PPFErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (ppf_Policy && !ppf_Amount) {
    setState((prevState) => ({
      ...prevState,
      PPFErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!ppf_Policy && ppf_Amount) {
    setState((prevState) => ({
      ...prevState,
      ppf_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (vpf_Amount && !VPF) {
    setState((prevState) => ({
      ...prevState,
      VPFErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!vpf_Amount && VPF) {
    setState((prevState) => ({
      ...prevState,
      VPFErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (vpf_Policy && !vpf_Amount) {
    setState((prevState) => ({
      ...prevState,
      VPFErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!vpf_Policy && vpf_Amount) {
    setState((prevState) => ({
      ...prevState,
      vpf_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (nsc_Amount && !NSC) {
    setState((prevState) => ({
      ...prevState,
      NSCErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!nsc_Amount && NSC) {
    setState((prevState) => ({
      ...prevState,
      NSCErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (nsc_Policy && !nsc_Amount) {
    setState((prevState) => ({
      ...prevState,
      NSCErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!nsc_Policy && nsc_Amount) {
    setState((prevState) => ({
      ...prevState,
      nsc_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (elss_Amount && !mutual_fund) {
    setState((prevState) => ({
      ...prevState,
      mutualFundErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!elss_Amount && mutual_fund) {
    setState((prevState) => ({
      ...prevState,
      mutualFundErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (elss_Policy && !elss_Amount) {
    setState((prevState) => ({
      ...prevState,
      mutualFundErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!elss_Policy && elss_Amount) {
    setState((prevState) => ({
      ...prevState,
      elss_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (tuition_Amount && !tutionFee) {
    setState((prevState) => ({
      ...prevState,
      tutionFeeErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!tuition_Amount && tutionFee) {
    setState((prevState) => ({
      ...prevState,
      tutionFeeErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (tuition_Policy && !tuition_Amount) {
    setState((prevState) => ({
      ...prevState,
      tutionFeeErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!tuition_Policy && tuition_Amount) {
    setState((prevState) => ({
      ...prevState,
      tuition_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (housing_Loan_Amount && !housingLoan) {
    setState((prevState) => ({
      ...prevState,
      housingLoanErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!housing_Loan_Amount && housingLoan) {
    setState((prevState) => ({
      ...prevState,
      housingLoanErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (housing_Loan_Policy && !housing_Loan_Amount) {
    setState((prevState) => ({
      ...prevState,
      housingLoanErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!housing_Loan_Policy && housing_Loan_Amount) {
    setState((prevState) => ({
      ...prevState,
      housing_Loan_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (stamp_Duty_Amount && !stampDutyPolicy) {
    setState((prevState) => ({
      ...prevState,
      stampDutyPolicyErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!stamp_Duty_Amount && stampDutyPolicy) {
    setState((prevState) => ({
      ...prevState,
      stampDutyPolicyErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (stamp_Duty_Policy && !stamp_Duty_Amount) {
    setState((prevState) => ({
      ...prevState,
      stampDutyPolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!stamp_Duty_Policy && stamp_Duty_Amount) {
    setState((prevState) => ({
      ...prevState,
      stamp_Duty_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (bank_Fixed_Amount && !bankFixedPolicy) {
    setState((prevState) => ({
      ...prevState,
      bankFixedPolicyErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!bank_Fixed_Amount && bankFixedPolicy) {
    setState((prevState) => ({
      ...prevState,
      bankFixedPolicyErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (bank_Fixed_Policy && !bank_Fixed_Amount) {
    setState((prevState) => ({
      ...prevState,
      bankFixedPolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!bank_Fixed_Policy && bank_Fixed_Amount) {
    setState((prevState) => ({
      ...prevState,
      bank_Fixed_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (postOffice_Amount && !postOffice) {
    setState((prevState) => ({
      ...prevState,
      postOfficeErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!postOffice_Amount && postOffice) {
    setState((prevState) => ({
      ...prevState,
      postOfficeErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (postOffice_Policy && !postOffice_Amount) {
    setState((prevState) => ({
      ...prevState,
      postOfficeErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!postOffice_Policy && postOffice_Amount) {
    setState((prevState) => ({
      ...prevState,
      postOffice_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (accountScheme_Amount && !SSAS) {
    setState((prevState) => ({
      ...prevState,
      SSASErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!accountScheme_Amount && SSAS) {
    setState((prevState) => ({
      ...prevState,
      SSASErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (accountScheme_Policy && !accountScheme_Amount) {
    setState((prevState) => ({
      ...prevState,
      SSASErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!accountScheme_Policy && accountScheme_Amount) {
    setState((prevState) => ({
      ...prevState,
      accountScheme_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (others_Amount && !others) {
    setState((prevState) => ({
      ...prevState,
      othersErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!others_Amount && others) {
    setState((prevState) => ({
      ...prevState,
      othersErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (others_Policy && !others_Amount) {
    setState((prevState) => ({
      ...prevState,
      othersErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!others_Policy && others_Amount) {
    setState((prevState) => ({
      ...prevState,
      others_PolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (d80CCC_Amount && !pensionFund) {
    setState((prevState) => ({
      ...prevState,
      pensionFundErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (d80CCC_Amount && d80CCC_Amount > 150000) {
    setState((prevState) => ({
      ...prevState,
      pensionFundErrMsg: "*Amount cannot be greater 150000",
    }));
    validate = false;
  }
  if (!d80CCC_Amount && pensionFund) {
    setState((prevState) => ({
      ...prevState,
      pensionFundErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (d80CCD_1_Amount && !pensionScheme) {
    setState((prevState) => ({
      ...prevState,
      pensionSchemeErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (d80CCD_1_Amount && d80CCD_1_Amount > 150000) {
    setState((prevState) => ({
      ...prevState,
      pensionSchemeErrMsg: "*Amount cannot be greater 150000",
    }));
    validate = false;
  }
  if (!d80CCD_1_Amount && pensionScheme) {
    setState((prevState) => ({
      ...prevState,
      pensionSchemeErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (d80CCD_1B_Amount && !nationalPensionScheme) {
    setState((prevState) => ({
      ...prevState,
      nationalPensionSchemeErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!d80CCD_1B_Amount && nationalPensionScheme) {
    setState((prevState) => ({
      ...prevState,
      nationalPensionSchemeErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (d80CCD_1B_Amount && d80CCD_1B_Amount > 500000) {
    setState((prevState) => ({
      ...prevState,
      nationalPensionSchemeErrMsg: "*Amount cannot be greater 500000",
    }));
    validate = false;
  }
  if (preventive_Health_Amount && !pensionFromInsurance) {
    setState((prevState) => ({
      ...prevState,
      pensionFromInsuranceErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!preventive_Health_Amount && pensionFromInsurance) {
    setState((prevState) => ({
      ...prevState,
      pensionFromInsuranceErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (medicalInsuranceChildren_Amount && !medicalInsurance) {
    setState((prevState) => ({
      ...prevState,
      medicalInsuranceErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!medicalInsuranceChildren_Amount && medicalInsurance) {
    setState((prevState) => ({
      ...prevState,
      medicalInsuranceErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (medicalInsuranceParent_Amount && !medicalInsuranceParents) {
    setState((prevState) => ({
      ...prevState,
      medicalInsuranceParentsErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!medicalInsuranceParent_Amount && medicalInsuranceParents) {
    setState((prevState) => ({
      ...prevState,
      medicalInsuranceParentsErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (specify_amount && !deductionOthers) {
    setState((prevState) => ({
      ...prevState,
      deductionOthersErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!specify_amount && deductionOthers) {
    setState((prevState) => ({
      ...prevState,
      deductionOthersErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (!specify_amount && (specify_policy || other_specify_policy)) {
    setState((prevState) => ({
      ...prevState,
      deductionOthersErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!specify_policy && (specify_amount || other_specify_policy)) {
    setState((prevState) => ({
      ...prevState,
      specify_policyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (!other_specify_policy && (specify_amount || specify_policy)) {
    setState((prevState) => ({
      ...prevState,
      otherSpecifyPolicyErrMsg: REQUIRED,
    }));
    validate = false;
  }
  if (interest_Amount && !interestForEducation) {
    setState((prevState) => ({
      ...prevState,
      interestForEducationErrMsg: UPLOAD_FILE_ERROR,
    }));
    validate = false;
  }
  if (!interest_Amount && interestForEducation) {
    setState((prevState) => ({
      ...prevState,
      interestForEducationErrMsg: AMOUNT_ERROR,
    }));
    validate = false;
  }
  if (employeePAN === undefined) {
    setState((prevState) => ({
      ...prevState,
      employeePANErr: "*This Field is required",
    }));
    validate = false;
  }
  if (employeePAN && !employeePAN.match(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)) {
    setState((prevState) => ({
      ...prevState,
      employeePANErr: "*Invalid Pan Number",
    }));
    validate = false;
  }
  if (employeePAN && employeePAN.length > 10) {
    setState((prevState) => ({
      ...prevState,
      employeePANErr: "*length should be 10",
    }));

    validate = false;
  }
  if (scheme === undefined) {
    setState((prevState) => ({
      ...prevState,
      schemeErr: "*This Field is required",
    }));

    validate = false;
  }
  return validate;
};

export const handleOpenUpload = (props) => {
  const { fileName, deductionText, deductionAmountFile, setState, state } =
    props;
  const {
    houseRentAmount,
    deductionAmount_1,
    deductionAmount_2,
    deductionAmount_3,
    lifeInsuranceAmount,
    ppf_Amount,
    vpf_Amount,
  } = state;
  if (houseRentAmount) {
    setState((prevState) => ({
      ...prevState,
      houseRentAmountErr: "",
    }));
  }
  if (deductionAmount_1) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile1Err: "",
    }));
  }
  if (deductionAmount_2) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile2Err: "",
    }));
  }
  if (deductionAmount_3) {
    setState((prevState) => ({
      ...prevState,
      deductionAmountFile3Err: "",
    }));
  }
  if (lifeInsuranceAmount) {
    setState((prevState) => ({
      ...prevState,
      LICErrMsg: "",
    }));
  }
  if (ppf_Amount) {
    setState((prevState) => ({
      ...prevState,
      LICErrMsg: "",
    }));
  }
  if (vpf_Amount) {
    setState((prevState) => ({
      ...prevState,
      VPFErrMsg: "",
    }));
  }
  setState({
    ...state,
    openUploadFilesModal: true,
    uploadFileKeyName: fileName,
    deductionText: deductionText,
    deductionAmountFile: deductionAmountFile,
    uploadFileErr: "",
  });
};

export const uploadFileValidation = (props) => {
  const { state, setState } = props;
  const {
    uploadFileKeyName,
    rentAgreement,
    landlordPanCard,
    deductionAmountFile,
    deductionAmountFile1,
    deductionAmountFile2,
    deductionAmountFile3,
    LIC,
    PPF,
    VPF,
    NSC,
    others,
    tutionFee,
    housingLoan,
    stampDutyPolicy,
    postOffice,
    SSAS,
    pensionFund,
    pensionScheme,
    nationalPensionScheme,
    pensionFromInsurance,
    medicalInsurance,
    medicalInsuranceParents,
    interestForEducation,
    deductionOthers,
    lifeInsuranceAmount,
    ppf_Amount,
    vpf_Policy,
    nsc_Amount,
    elss_Amount,
    mutual_fund,
    tuition_Amount,
    housing_Loan_Amount,
    stamp_Duty_Amount,
    bank_Fixed_Amount,
    postOffice_Amount,
    accountScheme_Amount,
    others_Amount,
    d80CCC_Amount,
    d80CCD_1_Amount,
    d80CCD_1B_Amount,
    preventive_Health_Amount,
    medicalInsuranceChildren_Amount,
    medicalInsuranceParent_Amount,
    interest_Amount,
  } = state;
  let valid = true;
  if (
    ((uploadFileKeyName === "housingLoan" || uploadFileKeyName === "proof") &&
      state[deductionAmountFile] &&
      typeof state[deductionAmountFile] === "string") ||
    (uploadFileKeyName === "houseRent" &&
      rentAgreement &&
      landlordPanCard &&
      typeof rentAgreement === "string" &&
      typeof landlordPanCard === "string")
  ) {
    setState((prevState) => ({
      ...prevState,
      uploadFileErr: "Please upload a new file to proceed",
    }));
    valid = false;
  }
  if (
    uploadFileKeyName === "houseRent" &&
    (!rentAgreement || !landlordPanCard)
  ) {
    setState((prevState) => ({
      ...prevState,
      uploadFileErr: "Please upload both files",
    }));
    valid = false;
  }
  if (uploadFileKeyName === "housingLoan" || uploadFileKeyName === "proof") {
    if (
      (deductionAmountFile === "deductionAmountFile1" &&
        !deductionAmountFile1) ||
      (deductionAmountFile === "deductionAmountFile2" &&
        !deductionAmountFile2) ||
      (deductionAmountFile === "deductionAmountFile3" &&
        !deductionAmountFile3) ||
      (deductionAmountFile === "LIC" && !LIC) ||
      (deductionAmountFile === "PPF" && !PPF) ||
      (deductionAmountFile === "VPF" && !VPF) ||
      (deductionAmountFile === "NSC" && !NSC) ||
      (deductionAmountFile === "mutual_fund" && !mutual_fund) ||
      (deductionAmountFile === "others" && !others) ||
      (deductionAmountFile === "tutionFee" && !tutionFee) ||
      (deductionAmountFile === "housingLoan" && !housingLoan) ||
      (deductionAmountFile === "stampDutyPolicy" && !stampDutyPolicy) ||
      (deductionAmountFile === "bankFixedPolicy" && !bank_Fixed_Amount) ||
      (deductionAmountFile === "postOffice" && !postOffice) ||
      (deductionAmountFile === "SSAS" && !SSAS) ||
      (deductionAmountFile === "pensionFund" && !pensionFund) ||
      (deductionAmountFile === "pensionScheme" && !pensionScheme) ||
      (deductionAmountFile === "nationalPensionScheme" &&
        !nationalPensionScheme) ||
      (deductionAmountFile === "pensionFromInsurance" &&
        !pensionFromInsurance) ||
      (deductionAmountFile === "medicalInsurance" && !medicalInsurance) ||
      (deductionAmountFile === "medicalInsuranceParents" &&
        !medicalInsuranceParents) ||
      (deductionAmountFile === "interestForEducation" &&
        !interestForEducation) ||
      (deductionAmountFile === "deductionOthers" && !deductionOthers) ||
      (deductionAmountFile === "lifeInsuranceAmount" && !lifeInsuranceAmount) ||
      (deductionAmountFile === "ppf_Amount" && !ppf_Amount) ||
      (deductionAmountFile === "vpf_Policy" && !vpf_Policy) ||
      (deductionAmountFile === "nsc_Amount" && !nsc_Amount) ||
      (deductionAmountFile === "elss_Amount" && !elss_Amount) ||
      (deductionAmountFile === "mutual_fund" && !mutual_fund) ||
      (deductionAmountFile === "tuition_Amount" && !tuition_Amount) ||
      (deductionAmountFile === "housing_Loan_Amount" && !housing_Loan_Amount) ||
      (deductionAmountFile === "stamp_Duty_Amount" && !stamp_Duty_Amount) ||
      (deductionAmountFile === "bank_Fixed_Amount" && !bank_Fixed_Amount) ||
      (deductionAmountFile === "postOffice_Amount" && !postOffice_Amount) ||
      (deductionAmountFile === "accountScheme_Amount" &&
        !accountScheme_Amount) ||
      (deductionAmountFile === "others_Amount" && !others_Amount) ||
      (deductionAmountFile === "d80CCC_Amount" && !d80CCC_Amount) ||
      (deductionAmountFile === "d80CCD_1_Amount" && !d80CCD_1_Amount) ||
      (deductionAmountFile === "d80CCD_1B_Amount" && !d80CCD_1B_Amount) ||
      (deductionAmountFile === "preventive_Health_Amount" &&
        !preventive_Health_Amount) ||
      (deductionAmountFile === "medicalInsuranceChildren_Amount" &&
        !medicalInsuranceChildren_Amount) ||
      (deductionAmountFile === "medicalInsuranceParent_Amount" &&
        !medicalInsuranceParent_Amount) ||
      (deductionAmountFile === "interest_Amount" && !interest_Amount)
    ) {
      setState((prevState) => ({
        ...prevState,
        uploadFileErr: "Please upload file",
      }));
      valid = false;
    }
  }
  return valid;
};

export const handleUploadFiles = ({
  state,
  setState,
  closeModal,
  setLoadImg,
  newUpload,
}) => {
  const {
    uploadFileKeyName,
    rentAgreement,
    landlordPanCard,
    deductionAmountFile,
    deductionAmountFile1,
    deductionAmountFile2,
    deductionAmountFile3,
    LIC,
    NSC,
    PPF,
    VPF,
    mutual_fund,
    tutionFee,
    housingLoan,
    stampDutyPolicy,
    bankFixedPolicy,
    postOffice,
    SSAS,
    others,
    pensionFund,
    pensionScheme,
    nationalPensionScheme,
    pensionFromInsurance,
    medicalInsurance,
    medicalInsuranceParents,
    interestForEducation,
    deductionOthers,
  } = state;
  const toEdit = new URLSearchParams(window.location.search);
  const editFile = toEdit.has("toEdit");
  if (uploadFileValidation({ state, setState })) {
    setLoadImg(true);
    let params = new FormData();
    let fileLinks = {};
    if (uploadFileKeyName === "houseRent") {
      if (editFile) {
        if (typeof rentAgreement === "object") {
          params.append("files", rentAgreement);
          fileLinks.rentAgreement = rentAgreement;
        }
        if (typeof landlordPanCard === "object") {
          params.append("files", landlordPanCard);
          fileLinks.landlordPanCard = landlordPanCard;
        }
      } else {
        params.append("files", rentAgreement);
        params.append("files", landlordPanCard);
        fileLinks = {
          rentAgreement,
          landlordPanCard,
        };
      }
    }
    if (uploadFileKeyName === "housingLoan") {
      if (deductionAmountFile === "deductionAmountFile1") {
        if (typeof deductionAmountFile1 === "object") {
          params.append("files", deductionAmountFile1);
        }
        fileLinks = {
          deductionAmountFile1,
        };
      }
      if (deductionAmountFile === "deductionAmountFile2") {
        if (typeof deductionAmountFile2 === "object") {
          params.append("files", deductionAmountFile2);
        }
        fileLinks = {
          deductionAmountFile2,
        };
      }
      if (deductionAmountFile === "deductionAmountFile3") {
        if (typeof deductionAmountFile3 === "object") {
          params.append("files", deductionAmountFile3);
        }
        fileLinks = {
          deductionAmountFile3,
        };
      }
    }
    if (uploadFileKeyName === "proof") {
      if (deductionAmountFile === "LIC") {
        if (typeof LIC === "object") {
          params.append("files", LIC);
        }
        fileLinks = {
          LIC,
        };
      }
      if (deductionAmountFile === "PPF") {
        if (typeof PPF === "object") {
          params.append("files", PPF);
        }
        fileLinks = {
          PPF,
        };
      }
      if (deductionAmountFile === "VPF") {
        if (typeof VPF === "object") {
          params.append("files", VPF);
        }
        fileLinks = {
          VPF,
        };
      }
      if (deductionAmountFile === "NSC") {
        if (typeof NSC === "object") {
          params.append("files", NSC);
        }
        fileLinks = {
          NSC,
        };
      }
      if (deductionAmountFile === "mutual_fund") {
        if (typeof mutual_fund === "object") {
          params.append("files", mutual_fund);
        }
        fileLinks = {
          mutual_fund,
        };
      }
      if (deductionAmountFile === "tutionFee") {
        if (typeof tutionFee === "object") {
          params.append("files", tutionFee);
        }
        fileLinks = {
          tutionFee,
        };
      }
      if (deductionAmountFile === "housingLoan") {
        if (typeof housingLoan === "object") {
          params.append("files", housingLoan);
        }
        fileLinks = {
          housingLoan,
        };
      }
      if (deductionAmountFile === "stampDutyPolicy") {
        if (typeof stampDutyPolicy === "object") {
          params.append("files", stampDutyPolicy);
        }
        fileLinks = {
          stampDutyPolicy,
        };
      }
      if (deductionAmountFile === "bankFixedPolicy") {
        if (typeof bankFixedPolicy === "object") {
          params.append("files", bankFixedPolicy);
        }
        fileLinks = {
          bankFixedPolicy,
        };
      }
      if (deductionAmountFile === "postOffice") {
        if (typeof postOffice === "object") {
          params.append("files", postOffice);
        }
        fileLinks = {
          postOffice,
        };
      }
      if (deductionAmountFile === "SSAS") {
        if (typeof SSAS === "object") {
          params.append("files", SSAS);
        }
        fileLinks = {
          SSAS,
        };
      }
      if (deductionAmountFile === "others") {
        if (typeof others === "object") {
          params.append("files", others);
        }
        fileLinks = {
          others,
        };
      }
      if (deductionAmountFile === "pensionFund") {
        if (typeof pensionFund === "object") {
          params.append("files", pensionFund);
        }
        fileLinks = {
          pensionFund,
        };
      }
      if (deductionAmountFile === "pensionScheme") {
        if (typeof pensionScheme === "object") {
          params.append("files", pensionScheme);
        }
        fileLinks = {
          pensionScheme,
        };
      }
      if (deductionAmountFile === "nationalPensionScheme") {
        if (typeof nationalPensionScheme === "object") {
          params.append("files", nationalPensionScheme);
        }
        fileLinks = {
          nationalPensionScheme,
        };
      }
      if (deductionAmountFile === "pensionFromInsurance") {
        if (typeof pensionFromInsurance === "object") {
          params.append("files", pensionFromInsurance);
        }
        fileLinks = {
          pensionFromInsurance,
        };
      }
      if (deductionAmountFile === "medicalInsurance") {
        if (typeof medicalInsurance === "object") {
          params.append("files", medicalInsurance);
        }
        fileLinks = {
          medicalInsurance,
        };
      }
      if (deductionAmountFile === "medicalInsuranceParents") {
        if (typeof medicalInsuranceParents === "object") {
          params.append("files", medicalInsuranceParents);
        }
        fileLinks = {
          medicalInsuranceParents,
        };
      }
      if (deductionAmountFile === "interestForEducation") {
        if (typeof interestForEducation === "object") {
          params.append("files", interestForEducation);
        }
        fileLinks = {
          interestForEducation,
        };
      }
      if (deductionAmountFile === "deductionOthers") {
        if (typeof deductionOthers === "object") {
          params.append("files", deductionOthers);
        }
        fileLinks = {
          deductionOthers,
        };
      }
    }
    uploadFilesInvestment(params)
      .then((res) => {
        setLoadImg(false);
        let { status } = ResponseFilter(res);
        if (status) {
          closeModal();
          const links = res.data.responseData.links;
          Object.keys(fileLinks).forEach((key, index) => {
            setState((prevState) => ({
              ...prevState,
              [key]: links[index],
              resMsg: true,
              successMessage: "File Uploaded",
            }));
          });
          setResponseActionState({ setState });
        } else {
          closeModal();
          setState((prevState) => ({
            ...prevState,
            resFailMsg: true,
            responseMessage: res?.data?.error?.responseMessage,
          }));
          setResponseActionState({ setState });
        }
      })
      .catch((error) => {
        closeModal();
        setState((prevState) => ({
          ...prevState,
          resFailMsg: true,
          responseMessage: error,
        }));
        setResponseActionState({ setState });
      });
  }
};

export const handleSubmit = ({ e, state, setState, history, editFile, id }) => {
  const {
    employeeCode,
    year1,
    employeeName,
    employeeDesignation,
    employeePAN,
    employeeDOB,
    gender,
    scheme,
    employeeDOB2,
  } = state;

  e.preventDefault();
  let dob = JSON.stringify(employeeDOB);
  let dob2 = JSON.stringify(employeeDOB2);
  const d1 = new Date(dob).getTime();
  const d2 = new Date(dob2).getTime();

  let params = new FormData();

  if (validation({ state, setState })) {
    params.append("employee_Code", employeeCode);
    params.append("employee_Name", employeeName);
    params.append("employee_Pan", employeePAN);
    params.append("employee_Designation", employeeDesignation);
    params.append("gender", gender);
    params.append("scheme", scheme);

    params.append("employee_Dob", editFile ? d2 : d1);

    if (!editFile) {
      params.append("investementYear", year1);
    }

    const relevantKeys = [
      "rentAgreement",
      "landlordPanCard",
      "deductionAmountFile1",
      "deductionAmountFile2",
      "deductionAmountFile3",
      "LIC",
      "PPF",
      "VPF",
      "NSC",
      "mutual_fund",
      "tutionFee",
      "housingLoan",
      "stampDutyPolicy",
      "bankFixedPolicy",
      "postOffice",
      "SSAS",
      "others",
      "pensionFund",
      "pensionScheme",
      "nationalPensionScheme",
      "pensionFromInsurance",
      "medicalInsurance",
      "medicalInsuranceParents",
      "interestForEducation",
      "deductionOthers",
    ];

    const excludedKeys = [
      "openUploadFilesModal",
      "uploadFileKeyName",
      "deductionText",
      "deductionAmountFile",
    ];

    const filteredState = Object.keys(state)
      .filter(
        (key) =>
          (!relevantKeys.includes(key) || typeof state[key] === "string") &&
          !excludedKeys.includes(key) &&
          !key.endsWith("Err")
      )
      .reduce((obj, key) => {
        obj[key] = state[key];
        return obj;
      }, {});

    params.append("info", JSON.stringify(filteredState));
    setState((prevState) => ({
      ...prevState,
      loader: true,
    }));
    if (editFile) {
      params.append("investmentId", id);
      setState((prevState) => ({
        ...prevState,
        loader: false,
      }));
      editInvestmentForm(Object.fromEntries(params))
        .then((res) => {
          const { status } = ResponseFilter(res);
          setState((prevState) => ({
            ...prevState,
            loader: false,
          }));
          if (status) {
            setState((prevState) => ({
              ...prevState,
              resMsg: true,
              successMessage: "Form Edited",
            }));
            setResponseActionState({ setState });
            setTimeout(() => {
              history.push("/investment");
            }, 2500);
          } else {
            setState((prevState) => ({
              ...prevState,
              resFailMsg: true,
              responseMessage: res?.data?.error?.responseMessage,
            }));
            setResponseActionState({ setState });
          }
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            resFailMsg: true,
            responseMessage: error,
          }));
          setResponseActionState({ setState });
        });
    } else {
      investmentform(params)
        .then((res) => {
          const { status } = ResponseFilter(res);
          setState((prevState) => ({
            ...prevState,
            loader: false,
          }));
          if (status) {
            setState((prevState) => ({
              ...prevState,
              resMsg: true,
              successMessage: "Form Submitted",
            }));
            setResponseActionState({ setState });
            setTimeout(() => {
              history.push("/investment");
            }, 2500);
          } else {
            setState((prevState) => ({
              ...prevState,
              resFailMsg: true,
              responseMessage: res?.data?.error?.responseMessage,
            }));
            setResponseActionState({ setState });
          }
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            resFailMsg: true,
            responseMessage: error,
          }));
          setResponseActionState({ setState });
        });
    }
  }
};

export const handleDownloadDocument = ({ link }) => {
  link.forEach((url) => {
    if (url) {
      window.open(url, "_blank");
    }
  });
};

export const schemeValue = (type) => {
  switch (type) {
    case 1:
      return "New Tax Scheme";
    case 2:
      return "Old Tax Scheme";
    default:
      return "";
  }
};

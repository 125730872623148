import {
    GET_VENDORS_LIST_REQUEST,
    GET_VENDORS_LIST_SUCCESS,
    GET_VENDORS_LIST_FAILURE,
  } from "../actions";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
  };
  
  const getVendorListReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_VENDORS_LIST_REQUEST:
        return {
          error:"",
          loading: true,
           data:[],
        };
  
      case GET_VENDORS_LIST_SUCCESS:
        return {
          loading: false,
          data:action.payload,
          error: "",
        };
      case GET_VENDORS_LIST_FAILURE:
        return {
          loading: false,
          data:[],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default getVendorListReducer;
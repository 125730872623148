import React from "react";
import moment from "moment";
import { DatePicker, Modal as AntModal } from "antd";
import { Modal } from "react-bootstrap";
export default function EditOnHoldEmployee(props) {
  const {
    data,
    show,
    handleClose,
    handleSubmit,
    handleReleaseDate,
    releaseDate,
  } = props;
  return (
    <AntModal visible={show} onCancel={handleClose} centered footer={false}>
      <Modal.Body className="text-center confirmation">
        <p className="mb-4 cm_bold cnfrm_msg">
          Edit Release Date
        </p>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Name</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="Name"
              disabled
              name="firstName"
              value={
                data.firstName + " " + data.middleName + " " + data.lastName
              }
            />
          </div>
          <div class="form-group col-6 ">
            <label className="sub">Account Number</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="Account Number"
              name="accountNumber"
              disabled
              value={data.accountNumber}
            />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">UAN Number</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              disabled
              placeholder="UAN"
              name="UAN"
              value={data.UAN}
            />
          </div>
          <div class="form-group col-6 ">
            <label className="sub">Pan Card Number</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="Pan Card"
              name="panCard"
              disabled
              value={data.panCard}
            />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">IFSC Code</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              disabled
              placeholder="IFSC Code"
              name="ifscCode"
              value={data.ifscCode}
            />
          </div>
          <div class="form-group col-6 ">
            <label className="sub">CTC</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="CTC"
              name="ctc"
              disabled
              value={data.ctc}
            />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">PF Opt</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              disabled
              placeholder="PF"
              name="isAvailedPF"
              value={data.isAvailedPF}
            />
          </div>
          <div class="form-group col-6 ">
            <label className="sub">TDS</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="TDS"
              name="tds"
              disabled
              value={data.tds}
            />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Loan</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="Loan"
              disabled
              name="loan"
              value={data.loan}
            />
          </div>
          <div class="form-group col-6">
            <label className="sub">Release Date</label>
            <DatePicker
              allowClear={false}
              className=" date-border"
              onChange={(e) => handleReleaseDate(e)}
              value={
                releaseDate ? moment(releaseDate) : moment(data.releaseDate)
              }
              defaultValue={
                moment(data.releaseDate) ? moment(data.releaseDate) : "null"
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center"> 
        <div className="submit_button_margin">
          <button className="btn btn-primary cm_btn3" onClick={handleSubmit}>
            Edit
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-primary cm_btn2"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </AntModal>
  );
}

import React from 'react'

export default function AwardInfo(props) {
    return (
        props?.openAwardDetail && (< div >
            < div className={'card award-info-detail'}  >
                <div className="arrow-left"></div>
                <div className="card-body">
                    <h4 className="card-title">{props?.awardDetail?.awardName}</h4>
                    <h4 className="card-subtitle mb-2">Reward:-{props?.awardDetail?.amount}</h4>
                    <>
                        {props?.awardDetail?.nominee?.length > 0 ? <p className="card-content"><b>Nominees:-</b>{
                            props?.awardDetail?.nominee?.map((nomniee, i) => (
                                <span className="cm_col_dept">
                                    {nomniee?.firstName} {nomniee?.lastName} {nomniee?.middleName}
                                </span>
                            ))}</p> : ""}
                        <p className="card-content">{props?.awardDetail?.awardDescription}</p>
                    </>
                </div>
            </div >
        </div >)

    )
}

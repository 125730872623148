import React from 'react'
import _ from 'lodash'
import AwardInfo from './AwardInfo'
import DeviceFullScreenImage from '../device/DeviceFullScreenImage'
import DeleteAward from './awardManagementModal/DeleteAward'
export default function YearlyAward(props) {
    const { yearlyAward, showAwardDetail, hideAwardDetail, awardIndex, awardDetail, openAwardDetail, awardTitle, handleFullImage, fullImage, handleClose, openFullImage, deleteAward, handleOpenDeleteAward, miniLoader, openDeleteAwardPopup } = props
    return (
        <div>
            <div className='year_award'>
                {yearlyAward ? !_.isEmpty(yearlyAward) ?
                    yearlyAward?.map((award, index) => (
                        <div className='award_main_div'>
                            {award?.map((award, i) => (
                                <div className='yearly_award_content' >
                                    <div className='awarded_empolyee_image_container'>
                                        <div className='awarded_empolyee_image' onClick={() => handleFullImage(award?.awardEmployeeImage)}>
                                            <img src={award?.awardEmployeeImage} alt="" />
                                        </div>
                                        <i className='fa fa-info-circle fa-lg award_descriptonIcon' onMouseEnter={() => showAwardDetail(i, award, award?.awardName)}
                                            onMouseOut={hideAwardDetail}
                                        ></i>
                                        {awardIndex == i && awardTitle == award?.awardName ?
                                            < AwardInfo
                                                index={index}
                                                awardDetail={awardDetail}
                                                openAwardDetail={openAwardDetail} /> : ""}
                                    </div>
                                    <h3>{award?.awardName}   {localStorage.getItem("isAdmin") == 1 || localStorage.getItem("isAdmin") == 4 ? <i className='fa fa-trash-o red' onClick={() => handleOpenDeleteAward(award?._id)}></i> : ""}</h3>
                                    <h2 className='giventoname'>{award.givenTo[0]?.firstName} {award.givenTo[0]?.lastName}</h2>
                                    <h2 className='giventoname'>
                                        {`(${award.givenTo[0]?.empId})`}
                                    </h2>
                                </div>
                            ))}
                        </div>)
                    ) : "" : " "
                }
                {yearlyAward && yearlyAward[0].length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
            </div>
            <DeviceFullScreenImage
                preview={openFullImage}
                fullImage={fullImage}
                closeModal={handleClose}
            />
            <DeleteAward
                show={openDeleteAwardPopup}
                handleSubmit={deleteAward}
                miniLoader={miniLoader}
                handleClose={handleClose}
            />
        </div >
    )
}

import React from "react";
import "./Template2.css";
import { DAYS_OF_WEEK, template_emp_status } from "../../helper/Constant";
import { PrintButton } from "../ProjectUtils/Constants";

const Template2 = (props) => {
  const {
    month,
    year,
    data,
    handlePrint,
    goBack,
    hideIcon,
    attendanceData,
    empName,
  } = props;

  const getEmployeeTime = (reportingTime) => {
    const hours = Math.floor(reportingTime / 3600);
    const minutes = Math.floor((reportingTime % 3600) / 60);
    const reportingTimeStr = `${hours}:${minutes.toString().padStart(2, "0")}`;
    const signOffTimeStr = `${hours + 9}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return `${reportingTimeStr} : ${signOffTimeStr}`;
  };

  const adjustedMonth = month - 1;
  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();

  const generateCalendar = () => {
    const daysInMonth = getDaysInMonth(year, adjustedMonth);
    const firstDayOfMonth = new Date(year, adjustedMonth, 1).getDay();
    const lastDayOfMonth = new Date(year, adjustedMonth, daysInMonth).getDay();

    const daysArray = [];

    const prevMonthDays = getDaysInMonth(year, adjustedMonth - 1);
    for (let i = firstDayOfMonth; i > 0; i--) {
      daysArray.push({
        day: prevMonthDays - i + 1,
        currentMonth: false,
      });
    }

    for (let day = 1; day <= daysInMonth; day++) {
      daysArray.push({
        day,
        currentMonth: true,
      });
    }

    const remainingDays = 6 - lastDayOfMonth;
    for (let i = 1; i <= remainingDays; i++) {
      daysArray.push({
        day: i,
        currentMonth: false,
      });
    }

    return daysArray;
  };

  const calendarDays = generateCalendar();

  const getStatusClass = (status) => {
    if (status === "Half-Day") return "H-D";
    return status.charAt(0);
  };

  const getStatusContainerClass = (currentMonth) => {
    if (!currentMonth) {
      return "prev_next_month";
    } else {
      return "";
    }
  };

  const isWeekend = (day, month, year) => {
    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  return (
    <>
      <PrintButton
        handlePrint={handlePrint}
        goBack={goBack}
        hideIcon={hideIcon}
      />
      <div className="template_detail">
        <h3>{empName}</h3>
        <div className="d-flex justify-content-center mt-5 pb-3">
          <div className="calendar_container2">
            <div className="calendar2">
              <div className="calendar-header2">
                <h4 className="font-weight-normal">
                  {new Date(year, adjustedMonth).toLocaleDateString("default", {
                    month: "long",
                    year: "numeric",
                  })}
                </h4>
              </div>
              <div className="calendar-grid2">
                {DAYS_OF_WEEK.map((day) => (
                  <div key={day} className="calendar-day-name2">
                    {day}
                  </div>
                ))}
                {calendarDays.map((dayObj, index) => {
                  const currentData =
                    data &&
                    data.find(
                      (entry) =>
                        entry.day === dayObj.day &&
                        entry.month === adjustedMonth + 1
                    );
                  const dateKey = `${year}-${adjustedMonth + 1}-${dayObj.day}`;
                  const statusFromAttendanceData = attendanceData[dateKey];
                  const employeeTime =
                    data && getEmployeeTime(data[0].reportingTime);
                  const statusClass =
                    statusFromAttendanceData === "holiday"
                      ? "H"
                      : currentData
                      ? getStatusClass(template_emp_status(currentData.status))
                      : dayObj.currentMonth &&
                        !isWeekend(dayObj.day, adjustedMonth, year)
                      ? "_"
                      : "";
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column align-items-center border calendar_day ${getStatusContainerClass(
                        dayObj.currentMonth
                      )}`}
                    >
                      <div className="d-flex justify-content-between time_day">
                        {dayObj.currentMonth && (
                          <div className="emp_time">{employeeTime}</div>
                        )}
                        <div className=" days font-weight-normal">
                          {dayObj.day}
                        </div>
                      </div>
                      <div className="d-flex justify-content-start statusClass">
                        {dayObj.currentMonth && (
                          <div
                            className={`emp_status ${`status_${statusClass}`}`}
                          >
                            {statusClass}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-between color_desc mt-3 pl-3 pr-3 pb-3">
                <div className="d-flex align-items-center">
                  <span className="template2_status_detail mr-1 present">
                    P
                  </span>
                  <p className="font-weight-normal">: Present</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="template2_status_detail mr-1 leave">L</span>
                  <p className="font-weight-normal">: Leave</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="template2_status_detail mr-1 absent">A</span>
                  <p className="font-weight-normal">: Absent</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="template2_status_detail mr-1 holiday">
                    H
                  </span>
                  <p className="font-weight-normal">: Holiday</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="template2_status_detail mr-1 halfLeave">
                    H-D
                  </span>
                  <p className="font-weight-normal">: Working Half Day</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="template2_status_detail mr-1 no_attendance">
                    _
                  </span>
                  <p className="font-weight-normal">: No Attendance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template2;

import React from 'react'
import { Modal } from "react-bootstrap"
import _ from "lodash";
import { colorType, halfTypeValue, leaveValue, statusValue } from '../../leaves/Constants';
import moment from 'moment';
import DOMPurify from 'dompurify';

export function TeamMemberLeaveReason(props) {

    function createMarkup(props) {
        return {
            __html: DOMPurify.sanitize(props)
        }
    }
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title><b>LEAVE VIEW</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="approve_leave">
                    <div>
                        <div className="cm_overflow_reason">
                            <div className="row">
                                <div className="col-5">
                                    <label className="mt-4">Leave Status</label>
                                    <br />
                                    <p className={colorType(props.leaveReasonData.status)}>
                                        {" "}
                                        <span
                                            className={`${(props.leaveReasonData.leaveType === 4 && props.leaveReasonData.status === 2) ||
                                                (props.leaveReasonData.leaveType === 9 && props.leaveReasonData.status === 2)
                                                ? "text-danger"
                                                : ""
                                                }`}
                                            style={{ width: 170 }}
                                        >
                                            {" "}
                                            {props.leaveReasonData.leaveType === 9 && props.leaveReasonData.status === 2
                                                ? "Half LWP"
                                                : props.leaveReasonData.leaveType === 4 && props.leaveReasonData.status === 2
                                                    ? "LWP"
                                                    : statusValue(props.leaveReasonData.status)}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-5">
                                    <label className="mt-4">Leave Type</label>
                                    <br />
                                    <p>{leaveValue(props.leaveReasonData.leaveType)}</p>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-5">
                                    <label className="mt-4">Start Date</label>
                                    <br />
                                    <time>
                                        {new Date(props.leaveReasonData.startDate).toLocaleDateString()}
                                    </time>
                                </div>
                                <div className="col-5">
                                    <label className="mt-4">End Date</label>
                                    <br />
                                    <time>
                                        {new Date(props.leaveReasonData.endDate).toLocaleDateString()}
                                    </time>
                                </div>
                            </div>
                            <div className='row'>
                                {props.leaveReasonData.halfOfTheDay ?
                                    <div className="col-5">
                                        <label className="mt-4">Half Type</label>
                                        <br />
                                        <p>{halfTypeValue(props.leaveReasonData.halfOfTheDay)}</p>
                                    </div>
                                    : "-"}
                            </div>

                            {props.leaveReasonData.shortDayFrom ? <div className="row">
                                <div className="col-5">
                                    <label className="mt-4">From</label>
                                    <br />
                                    <time>
                                        {new Date(props.leaveReasonData.shortDayFrom * 1000).toISOString().substr(11, 5)}
                                    </time>
                                </div>
                                <div className="col-5">
                                    <label className="mt-4">To</label>
                                    <br />
                                    <time>
                                        {new Date(props.leaveReasonData.shortDayTo * 1000).toISOString().substr(11, 5)}
                                    </time>
                                </div>
                            </div> : ""}

                            <div className="row">
                                <div className="col-11">
                                    <label className="mt-4">Reason</label>
                                    <br /><div
                                        dangerouslySetInnerHTML={createMarkup(props.leaveReasonData.leaveMsg)}>
                                    </div>
                                </div>
                            </div>
                            {props.leaveReasonData.reply ? <div className="row">
                                <div className="col-11">
                                    <label className="mt-4">Reply</label>
                                    <br /><div
                                        dangerouslySetInnerHTML={createMarkup(props.leaveReasonData.reply)}>
                                    </div>
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </Modal.Body>

        </Modal>


    )
}

import ResponseFilter from "../../../ResponseFilter";
import { getEmployeeList } from "../../../config/services/employeeServices";
import { REQUIRED } from "../../helper/Constant";

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  do {
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (/^#(?:f{4,7}|ffffff)$/i.test(color));
  return color;
};

export const handleOrgChartActionValidation = (props) => {
  const {
    employeeId,
    setErrorMessage,
    setError,
    setReportingMangerError,
    parentId,
    data,
    setReportingMangerErrMsg,
    delEmpId,
  } = props;
  let valid = true;
  // if (!employeeId) {
  //   setErrorMessage(REQUIRED);
  //   setError(true);
  //   valid = false;
  // }
  if (!parentId && data.length > 0 && !delEmpId) {
    setReportingMangerErrMsg(REQUIRED);
    setReportingMangerError(true);
    valid = false;
  }
  return valid;
};

export const handleOpenAddEditModal = (open) => {
  open(true);
};

export const handleResponse = (props) => {
  const { setResponse, setResponseMessage, setSuccess, setSuccessMessage } =
    props;
  setTimeout(() => {
    setResponse(false);
    setResponseMessage("");
    setSuccess(false);
    setSuccessMessage("");
  }, 3000);
};

export const getEmployeeInOrg = (setEmployeeList) => {
  let params = `status=1`;
  getEmployeeList(params).then((res) => {
    let { status, resData } = ResponseFilter(res);
    if (status) {
      setEmployeeList(resData.employeesList);
    }
  });
};
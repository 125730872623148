import React from "react";
import { Link } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import Login from "./components/login/Login";
import ForgetPassword from "./components/login/ForgetPassword";
import SuccessMsg from "./components/login/SuccessMsg";
import resetSuccessMsg from "./components/login/resetSuccess";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "./components/Layout";
import ResetPassword from "./components/login/ResetPassword";
import investment from "./components/investmentForm/investmentForm";
import CreateInvestment from "./components/investmentForm/createInvestment"
import Privacy from "./components/privacy/Privacy";
function Page() {
  return (
    <div className="login_wrapper d-flex align-items-center pagenot">
      <div className="container cm_container  d-flex align-items-center">
        <div className="page_content text-center">
          <h1 className="cm_404">404</h1>
          <p className="notFound">Page not found</p>

          <Link to="/" className=" cm_btn btn btn-outline-primary btnPage404">
            GO BACK
          </Link>
        </div>
      </div>
    </div>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("accessToken") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !localStorage.getItem("accessToken") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Switch>

              <PublicRoute path="/login" component={Login} />
              <PublicRoute path="/forgot-password" component={ForgetPassword} />

              <PublicRoute
                path="/reset-password/:id"
                component={ResetPassword}
              />
              <PublicRoute path="/success-msg" component={SuccessMsg} />
              <PublicRoute
                path="/reset-success-msg"
                component={resetSuccessMsg}
              />
              {/* <PublicRoute
                path="/investment-Form"
                component={investment}
              /> */}


              <PrivateRoute path="/change-password" component={Layout} />
              <PrivateRoute path="/change-password-emp" component={Layout} />
              <PrivateRoute exact path="/projects" component={Layout} />
              <PrivateRoute exact path="/policies" component={Layout} />
              <PrivateRoute
                exact
                path="/projects/admin-projects"
                component={Layout}
              />
              {/* <PrivateRoute
                exact
                path="/investment-Form"
                component={Layout}
              /> */}


              <PrivateRoute
                exact
                path="/projects/manager-projects"
                component={Layout}
              />

              <PrivateRoute exact path="/projects/:id" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-projects/project-detail/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/projects/manager-projects/project-detail/:id"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/admin-projects/employee-detail/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/projects/manager-projects/employee-detail/:id"
                component={Layout}
              />
              <PrivateRoute exact path="/admin-projects" component={Layout} />
              <PrivateRoute exact path="/manager-projects" component={Layout} />
              <PrivateRoute exact path="/attendance" component={Layout} />
              <PrivateRoute exact path="/reimbursement" component={Layout} />
              <PrivateRoute
                exact
                path="/reimbursement/reimbursement-view"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/reimbursement/reimbursement-claim"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/reimbursement/manager-reimbursement"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/reimbursement/manager-reimbursement-action"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/reimbursement/reimbursement-form-print"
                component={Layout}
              />
              <PrivateRoute exact path="/leaves" component={Layout} />
              <PrivateRoute exact path="/leave-status" component={Layout} />
              <PrivateRoute exact path="/addTicket" component={Layout} />
              <PrivateRoute exact path="/holiday" component={Layout} />
              <PrivateRoute exact path="/admin-holiday" component={Layout} />
              <PrivateRoute exact path="/profile" component={Layout} />





              <PrivateRoute exact path="/admin-employee" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-employee/employee-detail/:id"
                component={Layout}
              />

              <Route path="/investment-form/:id" component={Layout} />
              <PrivateRoute path="/templates" component={Layout} />
              <PrivateRoute path="/templates/anniversary-template" component={Layout} />
              <PrivateRoute path="/templates/birthday-template" component={Layout} />
              <PrivateRoute path="/templates/welcome-template" component={Layout} />






              <PrivateRoute exact path="/employee-device" component={Layout} />
              <PrivateRoute
                exact
                path="/employee-device/manager-device"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/employee-device/manager-device/:deviceType"
                component={Layout}
              />
              <PrivateRoute exact path="/admin-device" component={Layout} />
              <PrivateRoute exact path="/admin-device/request-list" component={Layout} />

              <PrivateRoute
                exact
                path="/admin-device/employee-detail/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-device/device-detail/:id"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/employee-profile/device-detail/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-device/:deviceType"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/employee-device/:deviceType"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-employee/create"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/admin-reimbursement"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-reimbursement/employee-detail/:id"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/admin-reimbursement/:id"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/admin-reimbursement/admin-reimbursement-action/:id"
                component={Layout}
              />

              <PrivateRoute exact path="/admin-appraisal" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-appraisal/employee-detail/:id"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/admin-appraisal/:id"
                component={Layout}
              />

              <PrivateRoute exact path="/admin-ticket" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-ticket/employee-detail/:id"
                component={Layout}
              />

              <PrivateRoute exact path="/admin-leave" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-leave/leave_view/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/leave/compoff-leave/:id?/:byAdmin/:isAdmin"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-leave/employee-detail/:id"
                component={Layout}
              />

              <PrivateRoute exact path="/admin-attendance" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-attendance/employee-detail/:id"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/admin-attendance/:id"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/admin-designation"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-designation/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-designation/employee-detail/:id"
                component={Layout}
              />

              <PrivateRoute exact path="/admin-skill" component={Layout} />
              <PrivateRoute exact path="/admin-hrActivity" component={Layout} />
              <PrivateRoute exact path="/user-hrActivity" component={Layout} />

              <PrivateRoute exact path="/admin-skill/:id" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-skill/employee-detail/:id"
                component={Layout}
              />

              <PrivateRoute exact path="/admin-department" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-department/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-department/employee-detail/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-projects/add"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/projects/manager-projects/add"
                component={Layout}
              />
              <PrivateRoute exact path="/project-members" component={Layout} />
              <PrivateRoute exact path="/" component={Layout} />
              <PrivateRoute exact path="/employee" component={Layout} />
              <PrivateRoute
                exact
                path="/admin-projects/:id/:empId"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/projects/manager-projects/:id/:empId"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/admin-projects/project-detail/:id/all-activity-log/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/projects/manager-projects/project-detail/:id/all-activity-log/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/projects/:id/:empId"
                component={Layout}
              />

              <PrivateRoute
                exact
                path="/projects/:id/all-activity-log-emp/:id"
                component={Layout}
              />
              <Route path="/employee-profile" component={Layout} />

              <PrivateRoute exact path="/resumeSection" component={Layout} />
              <PrivateRoute
                exact
                path="/resumeSection/employeeResumeDetails/:id"
                component={Layout}
              />
              <PrivateRoute exact path="/resumeDetails" component={Layout} />
              <PrivateRoute exact path="/investment" component={Layout} />
              <PrivateRoute exact path="/investment-form" component={Layout} />

              <PrivateRoute exact path="/admin-investment" component={Layout} />

              <PrivateRoute
                exact
                path="/resumeSection/employee-detail/:id"
                component={Layout}
              />
              <PrivateRoute exact path="/vendors" component={Layout} />
              <PrivateRoute
                exact
                path="/vendors/vendorOrders/:id"
                component={Layout}
              />
              <PrivateRoute exact path="/transactions" component={Layout} />
              <PrivateRoute exact path="/interview" component={Layout} />
              <PrivateRoute exact path="/contact" component={Layout} />
              <PrivateRoute exact path="/team" component={Layout} />
              <PrivateRoute exact path="/admin-team" component={Layout} />
              <PrivateRoute exact path="/salary" component={Layout} />
              <PrivateRoute exact path="/employee-salary-slip" component={Layout} />




              <PrivateRoute exact path="/admin-team/:id" component={Layout} />
              <PrivateRoute exact path="/admin-team/person-detail/:id" component={Layout} />


              <PrivateRoute exact path="/interviewer" component={Layout} />

              {/* <PrivateRoute exact path="/editCandidateModel" component={Layout}/> */}
              <PrivateRoute
                exact
                path="/interview/candidateDetails/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/interview/InterviewerStatus/:id"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/awards"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/courier"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/organization-chart"
                component={Layout}
              />
              <PrivateRoute
                exact
                path="/project/template"
                component={Layout}
              />
              <Route path="/404">
                <Page />
              </Route>
              <Redirect from="*" to="/404" />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;

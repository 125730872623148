import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import { assignInterviewCandidateByAdmin, getCandidateById } from "../../redux/actions";
import _ from "lodash";
import { getAssignInterviewer } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import DotLoader from "../../hoc/DotLoader";
import TextEditor from "../../hoc/textEditor/TextEditor";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { editAssignCandidateByHr } from "../../redux/actions/editAssignCandidateByHr";
import { Loader } from "../svg";
const format = "HH:mm";

const CandidateDetails = ({ getCandidateById, state, search, pageNo, count, assignInterviewCandidateByAdmin, error, editAssignCandidateByHr, ratingError }) => {
  const location = useLocation();
  const empId = location?.state?.empId;
  const [assign, setAssign] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [date, setDate] = useState("")
  const [dateErrorMsg, setDateErrorMsg] = useState("")
  const [candidateId, setCandidateId] = useState("");
  const [candidateIdErrorMsg, setCandidateIdErrorMsg] = useState("");
  const [assignInterviewerList, setAssignInterviewerList] = useState([]);
  const [interviewRound, setInterviewRound] = useState("")
  const [interviewRoundErrorMsg, setInterviewRoundErrorMsg] = useState("")
  const [interviewerId, setInterviewerId] = useState("")
  const [resMsg, setResMsg] = useState(false)
  const [resFailMsg, setFailResMsg] = useState(false)
  const [meetingDetail, setMeetingDetail] = useState(false)
  const [meetingInfo, setMeetingInfo] = useState({})
  const [loader, setLoader] = useState(false)

  const [ratingInfo, setRatingInfo] = useState(false)
  const [ratingDetail, setRatingDetail] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [jobDesriptionErrorMsg, setJobDesriptionErrorMsg] = useState("")
  const [wantSentMail, setWantSentMail] = useState('')
  const [wantSentMailErrorMsg, setWantSentMailErrorMsg] = useState('')
  const [status, setStatus] = useState("")
  const [statusErrorMessage, setStatusErrorMessage] = useState("")
  const [formValues, setFormValues] = useState([{ tech: "", rating: "" }])
  const [interviewId, setInterviewId] = useState("")
  const [assignInterviewerId, setAssignInterviewerId] = useState("")
  const [resHrRating, setResHrRating] = useState(false)
  const [resHrFailRating, setResHrFailRating] = useState(false)

  const { Option } = Select;

  useEffect(() => {
    getData();
  }, []);

  const operatingSystemType = (type) => {
    switch (type) {
      case 3:
        return "Rejected";
        break;
      case 1:
        return "Selected";
        break;
      case 2:
        return "Pending";
        break;
    }
  };


  const colorType = type => {
    switch (type) {
      case 3: return 'red';
        break;
      case 1: return 'text-success';
        break;
      case 2: return 'yellow';
        break;


    }
  }

  const getData = async () => {
    const query = `interviewId=${empId}`;
    await getCandidateById(query);
  };
  const handleDateChange = (date, dateString) => {
    setDate(date.target.value);
    setDateErrorMsg("")
  }

  const handleSelectCandidateChange = (e) => {
    const Emp = state.filter((ele) => {
      return ele.interviewerId === e;
    });
    setCandidateId(e)
    setCandidateIdErrorMsg("")
  }
  const handleCandidateSearch = (e) => {
    console.log("e : ", e);
  }


  const handleAssign = (element) => {
    setInterviewerId(element._id)
    setAssign(!assign)

  }

  const handleSentMailChange = async (e) => {
    setWantSentMail(e);
    setWantSentMailErrorMsg("")

  };

  const closeModal = () => {
    setAssign(false)
    setShowModal(false)
    setDate("")
    setDateErrorMsg("")
    setCandidateId("")
    setCandidateIdErrorMsg("")
    setInterviewRound("")
    setInterviewRoundErrorMsg("")
    setJobDesriptionErrorMsg("")
    setEditorState(EditorState.createEmpty())
    setWantSentMail("")
    setWantSentMailErrorMsg("")
    setStatus("")
    setFormValues([{ tech: "", rating: "" }])
  }

  const handleDateValidation = () => {
    let validate = true;
    if (date === "") {
      validate = false;
    }
    else {
    }
    return validate
  }
  const handleSetMailValidation = () => {
    let validate = true;
    if (wantSentMail === "") {
      validate = false;
    }
    else {
    }
    return validate
  }
  const handleCandidateValidation = () => {
    let validate = true;
    if (candidateId === "") {
      validate = false;
    }
    else {
    }
    return validate
  }
  const handleInterviewRoundValidation = () => {
    let validate = true;
    if (interviewRound === "") {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleJobDescriptionValidation = () => {
    let k = convertToRaw(editorState.getCurrentContent())

    let validate = true
    if (k.blocks.every(b => b.text.trim() === '')) {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleUploadButtonClick = async () => {
    if (
      handleCandidateValidation(candidateId) &&
      handleDateValidation(date) &&
      handleInterviewRoundValidation(interviewRound) &&
      handleJobDescriptionValidation(editorState) &&
      handleSetMailValidation(wantSentMail)
    ) {
      setLoader(true)
      const data1 = {
        "employeeId": candidateId,
        "startDate": Date.parse(new Date(date)),
        "interviewRound": interviewRound,
        "interviewId": interviewerId,
        "jobDescription": draftToHtml(convertToRaw(editorState.getCurrentContent())),
        "mailSent": wantSentMail

      }
      const query = `search=${search}&pageNo=${pageNo}&count=${count}&interviewId=${interviewerId}`
      assignInterviewCandidateByAdmin(data1, query, setAssign, setResMsg, setFailResMsg, setLoader, closeModal)

    }
    else {
    }
    setTimeout(() => setResMsg(false), 4000)
    setTimeout(() => setFailResMsg(false), 4000)
  };

  const onSubmitClick = () => {
    handleUploadButtonClick();
    let k = convertToRaw(editorState.getCurrentContent())

    if (handleDateValidation(date) == "") {
      setDateErrorMsg("Please Select Date")
    }
    else {
      setDateErrorMsg("")
    }
    if (handleCandidateValidation(candidateId) == "") {
      setCandidateIdErrorMsg("please Select Interviewer ")
    }
    else {
      setCandidateIdErrorMsg("")
    }
    if (handleInterviewRoundValidation(interviewRound) == "") {
      setInterviewRoundErrorMsg("please Select Interview Round")
    }
    else {
      setInterviewRoundErrorMsg("")
    }
    if (handleJobDescriptionValidation(k.blocks.every(b => b.text.trim())) == '') {

      setJobDesriptionErrorMsg("Please Enter Job Description")
    }
    else {

      setJobDesriptionErrorMsg("")
    }
    if (handleSetMailValidation(wantSentMail) == "") {
      setWantSentMailErrorMsg("Please Select value")
    }
    else {
      setWantSentMailErrorMsg("")
    }
  };


  useEffect(() => {
    // let params = `status = 1`;
    getAssignInterviewer().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        // setLoader(false);
        setAssignInterviewerList(resData.result)
      }
    })
  }, [])
  const handleInterviewRoundStatusChange = async (e) => {
    setInterviewRound(e);
    setInterviewRoundErrorMsg("")
  };



  const handleMeetingDetail = (element) => {
    setMeetingDetail(true)
    setMeetingInfo(element)
  }
  const closeModal1 = () => {
    setMeetingDetail(false)
    setMeetingInfo({})
  }


  const handleOpenRating = (e) => {
    setRatingDetail(e)
    setRatingInfo(true)
  }
  const closeRatingModal = () => {
    setRatingInfo(false)
    setRatingDetail([])
  }


  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    setJobDesriptionErrorMsg("")
  }

  const handleCandidateRating = (e) => {
    setShowModal(true)
    setStatus(e.status != 2 ? e.status : "")
    setFormValues(e.skillRating == [] || e.skillRating.length === 0 || e.skillRating.length === null ? [{ tech: "", rating: "" }] : e.skillRating)
    setInterviewId(e._id)
    setInterviewRound(e.round)
    setAssignInterviewerId(e.interviewerIdForHr)
  }
  const handleAssignStatus = (e) => {
    setStatus(e)
    setStatusErrorMessage("")
  }
  let handleTechChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = /^\s/.test(e.target.value) ? "" : e.target.value;
    setFormValues(newFormValues);
    for (let index = 0; index < newFormValues.length; index++) {
      newFormValues[index].techCheck = ""
    }
  }

  let handleRatingChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = /^\s/.test(e.target.value) ? "" : e.target.value;
    setFormValues(newFormValues);
    for (let index = 0; index < newFormValues.length; index++) {
      newFormValues[index].ratingCheck = ""
    }

  }
  let addFormFields = () => {
    setFormValues([...formValues, { tech: "", rating: "" }])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }



  const handleStatusValidation = () => {
    let validate = true;
    if (status == "") {
      validate = false;
    }
    else {
    }
    return validate
  }

  const formValidation = (formValues) => {
    const data = [...formValues]
    let validate = true
    for (let index = 0; index < data.length; index++) {
      if (data[index].tech == "") {
        data[index].techCheck = "Tech Required"
        validate = false
        break
      }
      else {
        data[index].techCheck = ""
        validate = true
      }

      if (data[index].rating == "") {
        data[index].ratingCheck = "Rating Required"
        validate = false
        break;
      }
      else if (data[index].rating < 0) {
        data[index].ratingCheck = "Rating can not be Negative"
        validate = false
        break;
      }
      else if (data[index].rating > 10) {
        data[index].ratingCheck = "Rating can not be Greater then 10"
        validate = false
        break;
      }
      else {
        data[index].ratingCheck = ""
        validate = true

      }
    }
    setFormValues(data)
    return validate

  }



  const handleRatingUploadButtonClick = (e) => {
    if (
      handleStatusValidation(status) &&
      formValidation(formValues)
    ) {
      setLoader(true)
      const data = {
        "employeeId": assignInterviewerId,
        "isClear": status,
        "skillRating": formValues,
        "interviewId": interviewId,
        "interviewRound": interviewRound,

      }
      editAssignCandidateByHr(data, setShowModal, setResHrRating, setResHrFailRating, setLoader, closeModal, getData)
    }
    else {
    }

    setTimeout(() => setResHrRating(false), 4000)
    setTimeout(() => setResHrFailRating(false), 4000)
  };

  const onRatingSubmitClick = (e) => {
    handleRatingUploadButtonClick();
    const data = [...formValues]
    for (let index = 0; index < data.length; index++) {
      if (data[index].tech == "") {
        data[index].techCheck = "Tech Required"
      }
      else {
        data[index].techCheck = ""
      }
      if (data[index].rating == "") {
        data[index].ratingCheck = "Rating Required"
      }
      else if (data[index].rating < 0) {
        data[index].ratingCheck = "Rating can not be Negative"
      }
      else if (data[index].rating > 10) {
        data[index].ratingCheck = "Rating can not be Greater then 10"
      }
      else {
        data[index].ratingCheck = ""
      }
    }
    if (handleStatusValidation(status) == "") {
      setStatusErrorMessage("Please Select Status")
    }
    else {
      setStatusErrorMessage("")
    }
  };



  return (
    <div className="body_container cm_bold marginTop">
      {state ? "" : <div className="loaderDiv">
        <Loader />
      </div>}
      {
        resMsg ?
          <div className="alert alert-success cm_top_fix"> Interview Assign Successfully </div> : ''
      }
      {
        resFailMsg ?
          <div className="alert alert-danger cm_top_fix">{error} </div> : ''
      }


      {
        resHrRating ?
          <div className="alert alert-success cm_top_fix"> Interview Updated Successfully </div> : ''
      }
      {
        resHrFailRating ?
          <div className="alert alert-danger cm_top_fix">{ratingError} </div> : ''
      }

      <div className="cm_breadcrumb">
        <ul >
          <li><Link to='/interview'>Candiates List</Link><div className="cm_breadcrumb_arrow"></div></li>
          <li>{state ? state[0]?.FirstName : ""}<div className="cm_breadcrumb_arrow"></div></li>
          <li style={{ textTransform: 'uppercase' }}>Detail</li>
        </ul>
      </div>
      <div className="col-8 emp_profile">
        <h3 className="mb-4">Candidate Information</h3>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name </th>
              <th scope="col">Email</th>
              <th scope="col">Interviewer</th>
              <th scope="col">Round</th>
              <th scope="col">Total Experience</th>
              <th scope="col">Rating</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          {state ? state?.map((element, index) => {
            return (
              <tbody>
                <tr>
                  <td>{index + 1}</td>
                  <td className="blue" onClick={() => handleMeetingDetail(element.meetingInfo)}>
                    {element?.FirstName + " " + element?.LastName}
                  </td>
                  <td>{element.email}</td>
                  <td>{element.interviewerFirstName ? element.interviewerFirstName : "_"}{" "}{element.interviewerLastName ? element.interviewerLastName : "_"}</td>

                  <td>{element.round}</td>

                  <td>{element.totalExperience == null ? "Fresher" : `${element.totalExperience} years`}</td>
                  <td className="blue" onClick={() => handleOpenRating(element.skillRating)}> Click Here</td>
                  <td className={colorType(element.status)}>{operatingSystemType(element.status)}</td>
                  <td>
                    <div className="cm_device">
                      <span onClick={() => handleAssign(element)}>
                        <i class="fa fa-hand-pointer-o ml-4" aria-hidden="true" title="Assign interviewer"></i>
                      </span>

                      <span onClick={() => handleCandidateRating(element)}>
                        <i class="fa fa-pencil ml-4" aria-hidden="true" title="Edit interview"></i>
                      </span>


                    </div>
                  </td>
                </tr>
              </tbody>
            );
          }) :
            ""}
        </table>
        {state && state.length > 0 ? "" :
          <h3 className="text-center"> No data available</h3>}
      </div>

      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={assign}
        onCancel={closeModal}
      >
        <Modal.Body>
          <div className={`assign_device ml-2`}>
            <h3 className="mb-4">Assign Interview</h3>
            <div className="row">
              <div
                className={`form-group col-lg-6 ${candidateIdErrorMsg ? "input_error" : ""
                  }`}
              >
                <label className="cm_bold">Interviewer Name</label>

                <Select
                  showSearch
                  name="candidateId"
                  value={candidateId ? candidateId : " Select candidate"}
                  defaultValue="Select Name"
                  onChange={handleSelectCandidateChange}
                  onSearch={handleCandidateSearch}
                  optionFilterProp="key"
                  filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                // disabled={miniLoader}
                >
                  {!_.isEmpty(assignInterviewerList)
                    ? assignInterviewerList.sort(function (a, b) {
                      if (a.firstName < b.firstName) { return -1; }
                      if (a.firstName > b.firstName) { return 1; }
                      return 0;
                    }).map(
                      (candidate) => (
                        <Option
                          key={`${candidate.firstName} ${candidate.lastName}`}
                          value={candidate.interviewerId}
                        >
                          <span>
                            {candidate.firstName} {candidate.lastName}
                          </span>
                        </Option>
                      )
                    )
                    : ""}
                </Select>
                {candidateIdErrorMsg ? (
                  <span className="error_msg">{candidateIdErrorMsg}</span>
                ) : null}
              </div>
              <div className={`form-group col-lg-6 ${interviewRoundErrorMsg ? "input_error" : ""
                }`}>
                <label htmlFor="" className="cm_bold"> Select Round</label>
                <Select
                  onChange={handleInterviewRoundStatusChange}
                  // className="col-2"
                  placeholder="Select Round"
                  value={interviewRound ? interviewRound : "Select Round"}
                >
                  <Option value={1}>Round One</Option>
                  <Option value={2}>Round Two</Option>
                  <Option value={3}>Round Three</Option>
                </Select>
                {interviewRoundErrorMsg ? (
                  <span className="error_msg">{interviewRoundErrorMsg}</span>
                ) : null}
              </div>


              <div className={`form-group col-lg-6 ${wantSentMailErrorMsg ? "input_error" : ""
                }`}>
                <label className="cm_bold"> Want Sent Mail</label>
                <Select
                  onChange={handleSentMailChange}
                  // className="col-2"
                  // style={{ width: 200 }}
                  value={wantSentMail ? wantSentMail : "Select"}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
                {wantSentMailErrorMsg ? (
                  <span className="error_msg">{wantSentMailErrorMsg}</span>
                ) : null}
              </div>


              <div
                className={`form-group col-lg-6 ${dateErrorMsg ? "input_error" : ""
                  }`}
              >
                <label className="cm_bold">Date And Time(in firefox add time manually)</label>
                <br />
                <input
                  style={{ width: "100%" }}
                  className="date-border"
                  type="datetime-local"
                  allowClear={false}
                  min={new Date().toISOString().slice(0, 16)}
                  value={date ? date : ""}
                  onChange={(e) => handleDateChange(e)}
                // disabled={miniLoader}
                />

                {dateErrorMsg ? (
                  <span className="error_msg cm_pos_bot">
                    {dateErrorMsg}
                  </span>
                ) : null}
              </div>
              <div
                className={`form-group col-lg-12 ${jobDesriptionErrorMsg ? "input_error" : ""
                  }`}
              >
                <label className="cm_bold"> Job Description</label>
                <TextEditor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />

                {jobDesriptionErrorMsg ? (
                  <span className="error_msg cm_pos_bot">
                    {jobDesriptionErrorMsg}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="addCandidateButtonsDiv">
              {loader ? <DotLoader /> :
                <button
                  type="submit"
                  onClick={onSubmitClick}
                  className="btn btn-primary mt-4 mr-4 cm_btn3"
                // disabled={miniLoader}
                >
                  Save
                </button>}
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-primary mt-4 cm_btn2"
              // disabled={miniLoader}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </AntModal>


      {/* Meeting Detail Modal */}



      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={meetingDetail}
        onCancel={closeModal1}
      >
        <Modal.Body>
          <div className={`assign_device ml-2`}>
            <h3 className="mb-4">Meeting Detail</h3>
            <div className="cm_overflow_device">
              <div className="row cm_row">
                <div className="col-6 cm_bold col1">Candidate Name</div>
                <div className="col-6 cm_bold col2">
                  {meetingInfo.candidateName}
                </div>
                <div className="col-6 cm_bold col1">Interview Round</div>
                <div className="col-6 cm_bold col2">{meetingInfo.InterviewRound ? meetingInfo.InterviewRound : "_"}</div>
                <div className="col-6 cm_bold col1">Join By Phone</div>
                <div className="col-6 cm_bold col2">
                  {meetingInfo.JoinByPhone ? meetingInfo.JoinByPhone : "_"}
                </div>
                <div className="col-6 cm_bold col1">Meeting Date and Time</div>
                <div className="col-6 cm_bold col2">
                  {meetingInfo.meetingDataAndTime ? meetingInfo.meetingDataAndTime : "_"}
                </div>
                <div className="col-6 cm_bold col1">Meeting Link</div>
                <div className="col-6 cm_bold col2"><a href={meetingInfo.meetingLink} target="_blank">{meetingInfo.meetingLink ? meetingInfo.meetingLink : "_"}</a></div>
                <div className="col-6 cm_bold col1">Meeting Platform</div>
                <div className="col-6 cm_bold col2">{meetingInfo.meetingPlatform ? meetingInfo.meetingPlatform : "_"}</div>

              </div>
            </div>
          </div>
        </Modal.Body>
      </AntModal>

      {/* Rating Detail */}
      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={ratingInfo}
        onCancel={closeRatingModal}
      >
        <Modal.Body>
          <h3 className="mb-4">Rating Detail</h3>
          {ratingDetail ? (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="white bg_blue">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Technology
                    </th>
                    <th scope="col">Rating</th>
                  </tr>
                </thead>
                {ratingDetail.map((item, index) => {
                  return (
                    <tbody>
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {item?.tech}
                        </td>
                        <td>{item.rating + "/10"}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
              {ratingDetail && ratingDetail.length > 0 ? "" :
                <h3 className="text-center">No data available</h3>
              }
            </div>
          ) : (
            <div className="loaderDiv">
              <DotLoader />
            </div>
          )}
        </Modal.Body>
      </AntModal>


      {/* candidate status and rating */}

      <AntModal
        centered
        className="attend_modal"
        footer={false}
        visible={showModal}
        onCancel={closeModal}
      >
        <Modal.Body>
          <div className={`assign_device ml-2`}>
            <h3 className="mb-2">Candidate Status Form</h3>
            <span className="red">* Please give rating according to 10</span>
            <div className="row mt-4">
              <div className={`form-group col-lg-6 ${statusErrorMessage ? "input_error" : ""
                }`}>
                <label className="cm_bold"> Select Status</label>
                <Select
                  className="cm_bold"
                  onChange={(e) => handleAssignStatus(e)}
                  value={status ? status : "Select Status"}
                >
                  <Option value={1}>Selected</Option>
                  <Option value={3}>Rejected</Option>
                </Select>
                {statusErrorMessage ? (
                  <span className="error_msg mb-5">{statusErrorMessage}</span>
                ) : null}
              </div>
              <div>
              </div>
              {formValues.map((element, index) => (
                <div className="form-inline col-lg-12" key={index}>

                  <div className={`form-group col-lg-5 div_form_label ${statusErrorMessage ? "input_error" : ""
                    }`}>
                    <label className="cm_bold">Technology</label>

                    <input type="text" name="tech" className="form_control cm_box" value={element?.tech} onChange={e => handleTechChange(index, e)} placeholder="Enter Tech" maxLength={25} />
                    <div style={{ color: 'red' }}>{element.techCheck}<br />{element.techLengthCheck}</div>
                  </div>

                  <div className={`form-group col-lg-5 div_form_label ${statusErrorMessage ? "input_error" : ""
                    }`}>
                    <label className="cm_bold"> Skill Rating</label>
                    <input type="number" name="rating" className="form_control cm_box" value={element?.rating} onChange={e => handleRatingChange(index, e)} placeholder="Enter Rating" />
                    <div style={{ color: 'red' }}>{element.ratingCheck}<br />{element.ratingFormat}</div>
                  </div>
                  {
                    index ?
                      <i className="fa fa-times fa-2x mt-4" aria-hidden="true" onClick={() => removeFormFields(index)}></i>
                      : null
                  }
                </div>
              ))}
            </div>
            <div className="addCandidateButtonsDiv">
              {loader ? <DotLoader /> : <button
                type="submit"
                onClick={onRatingSubmitClick}
                className="btn btn-primary mt-4 mr-4 cm_btn3"
              // disabled={miniLoader}
              >
                Save
              </button>}
              <button
                className="btn btn-primary mt-4 mr-4 cm_btn3"
                type="button"
                onClick={() => addFormFields()}>
                Add
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-primary mt-4 cm_btn2"
              // disabled={miniLoader}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </AntModal>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    state: state.candidateById?.data.candidate,
    error: state.assignInterviewCandidate?.error?.error?.responseMessage == "Validation error." ? state.assignInterviewCandidate?.error?.error?.errors[0]?.message : state.assignInterviewCandidate?.error?.error?.responseMessage,
    ratingError: state.editAssignCandidateByHr?.error?.error?.responseMessage == "Validation error." ? state.editAssignCandidateByHr?.error?.error?.errors[0]?.message : state.editAssignCandidateByHr?.error?.error?.responseMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateById: (query) => {
      dispatch(getCandidateById(query));
    },
    assignInterviewCandidateByAdmin: (data1, query, setAssign, setResMsg, setFailResMsg, setLoader, closeModal) => {
      dispatch(assignInterviewCandidateByAdmin(data1, query, setAssign, setResMsg, setFailResMsg, setLoader, closeModal));
    },

    editAssignCandidateByHr: (data, setShowModal, setResHrRating, setResHrFailRating, setLoader, closeModal, getData) => {
      dispatch(editAssignCandidateByHr(data, setShowModal, setResHrRating, setResHrFailRating, setLoader, closeModal, getData))
    }
  };
};
export default connect(mapStatetoProps, mapDispatchToProps)(CandidateDetails);
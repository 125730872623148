import { checkLeaveArray } from "../../components/helper/Constant";
import { getTeamMemberDetails } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    GET_TEAM_MEMBERS_DETAILS_REQUEST,
    GET_TEAM_MEMBERS_DETAILS_SUCCESS,
    GET_TEAM_MEMBERS_DETAILS_FAILURE,
} from "./actionType";


const getTeamMemberDetailsByAdminAction = (props) => {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getTeamMemberDetailsByAdminRequest());
        getTeamMemberDetails(params).then((res) => {
            let { status, resData } = ResponseFilter(res)
            let totalLeaveDetail = resData.leaveDetails[0]
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        loader: false,

                        yearlyEl: checkLeaveArray(totalLeaveDetail.totalEarnedLeaves[0]?.totalEarnedLeaves, totalLeaveDetail?.totalHalfEarnedLeaves[0]?.totalHalfEarnedLeaves),
                        yearlyCl: checkLeaveArray(totalLeaveDetail.totalCasualLeaves[0]?.totalCasualLeaves, totalLeaveDetail?.totalHalfCasualLeaves[0]?.totalHalfCasualLeaves),
                        yearlySl: checkLeaveArray(totalLeaveDetail.totalSickLeaves[0]?.totalSickLeaves, totalLeaveDetail?.totalHalfSickLeaves[0]?.totalHalfSickLeaves),
                        totalCompOffLeaves: checkLeaveArray(totalLeaveDetail.totalCompOffLeaves[0]?.totalCompOffLeaves, totalLeaveDetail?.totalHalfCompOffLeaves[0]?.totalHalfCompOffLeaves),
                        totalLWPLeaves: checkLeaveArray(totalLeaveDetail.totalLWPLeaves[0]?.totalLWPLeaves, totalLeaveDetail?.totalHalfLWPLeaves[0]?.totalHalfLWPLeaves),
                        totalWFHLeaves: checkLeaveArray(totalLeaveDetail.totalWFHLeaves[0]?.totalWFHLeaves, totalLeaveDetail?.totalHalfWFH[0]?.totalHalfWFH),
                        totalOtherLeaves: checkLeaveArray(totalLeaveDetail.totalOtherLeaves[0]?.totalOtherLeaves, totalLeaveDetail?.totalHalfOtherLeaves[0]?.totalHalfOtherLeaves),
                        totalLeavesTaken: totalLeaveDetail?.totalLeaves[0] ? totalLeaveDetail.totalLeaves[0].totalLeaves : 0,
                        totalShortDayLeave: totalLeaveDetail?.totalShortDayLeaves[0] ? totalLeaveDetail.totalShortDayLeaves[0].totalShortDayLeaves : 0,
                        employeeType: resData.employeeType,
                        assignedCl: resData.employeeTotalLeave?.totalCl,
                        assignedEl: resData.employeeTotalLeave?.totalEarnedLeave,
                        assignedSl: resData.employeeTotalLeave?.totalSl,
                        employeeName: resData.employeeName,

                        attendanceDetails: resData.attendanceDetails[0],
                        memberName: resData?.employeeDetails?.firstName + " " + resData?.employeeDetails?.lastName,
                        loader: false

                    }
                })
            }
        })
            .catch((error) => {
                dispatch(getTeamMemberDetailsByAdminFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const getTeamMemberDetailsByAdminRequest = () => {
    return {
        type: GET_TEAM_MEMBERS_DETAILS_REQUEST,
    };
};
const getTeamMemberDetailsByAdminSuccess = (data) => {
    return {
        type: GET_TEAM_MEMBERS_DETAILS_SUCCESS,
        payload: data,
    };
};
const getTeamMemberDetailsByAdminFailure = (error) => {
    return {
        type: GET_TEAM_MEMBERS_DETAILS_FAILURE,
        payload: error,
    };
};
export {
    getTeamMemberDetailsByAdminAction,
    getTeamMemberDetailsByAdminRequest,
    getTeamMemberDetailsByAdminSuccess,
    getTeamMemberDetailsByAdminFailure,
};
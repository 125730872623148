import { useState } from "react";

const useEmployeeData = (setOpenModal, openModal) => {
  const [employeeId, setEmployeeId] = useState("");
  const [parentId, setParentId] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [reportingManagerErrMsg, setReportingMangerErrMsg] = useState("");
  const [reportingManagerError, setReportingMangerError] = useState(false);
  const onHide = () => {
    setError(false);
    setErrorMessage("");
    setEmployeeId("");
    setReportingMangerError(false);
    setParentId("");
    setEmployeeData([]);
    setReportingMangerErrMsg("");
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  const handleSelectMultipleEmployeeChange = (
    selectedValues,
    setErrorMessage
  ) => {
    setEmployeeData(selectedValues);
    if (setErrorMessage) {
      setErrorMessage("");
    }
  };

  const handleSelectEmployeeChange = (e, setData, setErrorMessage) => {
    setData(e);
    if (setErrorMessage) {
      setErrorMessage("");
    }
  };

  return {
    employeeId,
    setEmployeeId,
    parentId,
    setParentId,
    error,
    setError,
    errorMessage,
    setErrorMessage,
    employeeData,
    setEmployeeData,
    handleSelectEmployeeChange,
    onHide,
    reportingManagerError,
    setReportingMangerError,
    reportingManagerErrMsg,
    setReportingMangerErrMsg,
    handleSelectMultipleEmployeeChange,
    data,
    setData,
  };
};

export default useEmployeeData;

import {
  GET_EMPLOYEE_RESUME_LIST_REQUEST,
  GET_EMPLOYEE_RESUME_LIST_SUCCESS,
  GET_EMPLOYEE_RESUME_LIST_FAILURE,
} from "./actionType";

import { getEmployeeResume } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getEmpResumeList = (query) => {
  return (dispatch) => {
    dispatch(getEmpResumeRequest());
    getEmployeeResume(query)
      .then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch(getEmpResumeSuccess(res.data.responseData));
        } else {
          dispatch(getEmpResumeFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(getEmpResumeFailure(error));
      });
  };
};

const getEmpResumeRequest = () => {
  return {
    type: GET_EMPLOYEE_RESUME_LIST_REQUEST,
  };
};

const getEmpResumeSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_RESUME_LIST_SUCCESS,
    payload: data,
  };
};

const getEmpResumeFailure = (error) => {
  return {
    type: GET_EMPLOYEE_RESUME_LIST_FAILURE,
    payload: error,
  };
};
export {
  getEmpResumeList,
  getEmpResumeRequest,
  getEmpResumeSuccess,
  getEmpResumeFailure,
};

export   const addedByValue = (type) => {
    switch (type) {
      case "1":
        return "Automatic";
        break;

      case "2":
        return "Manual";
        break;
    }
  };

  export const contactStatusValue = (type) => {
    switch (type) {
      case 1:
        return "Hot";
        break;

      case 2:
        return "Warm";
        break;

      case 3:
        return "Cold";
        break;
      case 4:
        return "Not Relevant";
        break;
    }
  };
  
  export const contactResultValue = (type) => {
    switch (type) {
      case 1:
        return "Win";
        break;

      case 2:
        return "Loss";
        break;

      case 3:
        return "Contact In Future";
        break;
      case 4:
        return "Connection Not Establised";
        break;
    }
  };
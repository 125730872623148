import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import { isAdmin } from "../../helper/Constant";

const Template = () => {
  const location = useLocation();
  const {
    attendanceData,
    selectTemplate,
    month,
    year,
    projectId,
    data,
    dailyWorks,
    projectDesc,
    empName,
  } = location.state || {};

  const [hideIcon, setHideIcon] = useState(false);
  const handlePrint = () => {
    setHideIcon(true);
    setTimeout(() => {
      window.print();
      setHideIcon(false);
    }, 500);
  };

  const goBack = () => {
    if(isAdmin === "1" || isAdmin === "4"){
      window.location.href = `/admin-projects/project-detail/${projectId}?tabStatus=${1}`;
    }else{
      window.location.href = `/projects/manager-projects/project-detail/${projectId}?tabStatus=${1}`;
    }
  };

  return (
    <div className="body_container cm_bold">
      {!hideIcon && <h3>Template {selectTemplate}</h3>}
      {selectTemplate === "1" ? (
        <Template1
          attendanceData={attendanceData}
          month={month}
          year={year}
          projectId={projectId}
          handlePrint={handlePrint}
          goBack={goBack}
          hideIcon={hideIcon}
          empName={empName}
        />
      ) : selectTemplate === "2" ? (
        <Template2
          attendanceData={attendanceData}
          month={month}
          year={year}
          projectId={projectId}
          data={data}
          handlePrint={handlePrint}
          goBack={goBack}
          hideIcon={hideIcon}
          empName={empName}
        />
      ) : (
        <Template3
          attendanceData={attendanceData}
          month={month}
          year={year}
          data={data}
          handlePrint={handlePrint}
          goBack={goBack}
          hideIcon={hideIcon}
          dailyWorks={dailyWorks}
          projectDesc={projectDesc}
          empName={empName}
        />
      )}
    </div>
  );
};

export default Template;

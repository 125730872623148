import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AddResumeModal from "./AddResumeModal";
import { getResumeByEmpId, deleteEmpResume } from "../../redux/actions";
import { updateResumeByEmpId } from "../../redux/actions/getResumeByEmpIdActionUpdate";
import { Loader } from "../svg";
import Pagination from "react-js-pagination";
import { deleteIcon, downloadIcon } from "../../asset";
import "./ResumeSectionStyles.css";
import UpdateResumeModal from "./UpdateResumeModal";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import qs from 'qs'
import ResumeDeleteModal from "./ResumeDeleteModal";
import _ from "lodash";
const empId = window.localStorage.getItem("employeeId");


const EmployeeResumeDetails = ({
  getResumeByEmpId,
  resumeList,
  deleteEmpResume,
  dataCount,
}) => {
  const count = 10;
  const history = useHistory()
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updateResume, setUpdateResume] = useState(false);
  const [resumeId, setResumeId] = useState("")
  const [resumeIdForDelete, setResumeIdForDelete] = useState("")
  const [resumeDeleteModalOpen, setResumeDeleteModalOpen] = useState("")
  const [miniLoader, setMiniLoader] = useState(false)

  const handleCloseUpdate = () => setUpdateResume(!updateResume);
  const [pageNo, setPageNo] = useState(1);
  const [state, setState] = useState({
    resMsg: false,
    resMsgDownload: false,
  })
  let urlParams = `?pageNo=${pageNo}`
  const params = `employeeId=${empId}&count=${count}&pageNo=${pageNo - 1}`

  useEffect(() => {
    getData();
  }, [pageNo]);
  const getData = async () => {
    history.push(urlParams)
    await getResumeByEmpId(params);
  };
  // useEffect(() => {
  //   getDataUpdate();
  // }, [pageNo]);
  // const getDataUpdate = async () => {
  //   const params = `employeeId=${empId}&count=${count}&pageNo=${pageNo - 1}`
  //   await updateResumeByEmpId(params);
  // };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)

  }, [history.location.search]);

  const handleOpenDeleteModal = (e) => {
    setResumeDeleteModalOpen(true)
    setResumeIdForDelete(e._id)
  }

  const handleDeleteOnClick = async (element) => {
    setMiniLoader(true)
    const data = {
      data: {
        resumeId: resumeIdForDelete,
      },
    };
    await deleteEmpResume(data, empId, closeModal);
    setState({ resMsg: true })
    setTimeout(() => setState({ resMsg: false }), 3000);
  };
  // __________________________________________________________________________________________________ //
  const handleOnUpdateResumeClick = (e) => {
    setUpdateResume(true);
    setResumeId(e)
  };

  // const View = (id) => {
  //   ViewFile()
  //   let param = `investmentId=${id}`
  //   if (id.length > 9) { }
  //   if (id.length > 9) {
  //     getInvestmentDetailsEmp(param).then((res) => {
  //       afterOpenModal(id)

  //     });
  //   }
  // }
  // const afterOpenModal = (id) => {
  //   let param = `investmentId=${id}`
  //   getInvestmentDetailsEmp(param).then((res) => {
  //     let doc = res.data.responseData?.investment?.uploadFile;
  //     let docType = doc?.split('.').pop();
  //     setFile({ ...file, document: doc, type: docType })
  //   })
  // }

  // const ViewFile = () => {
  //   handleShowEditFilePopup()
  // }

  // __________________________________________________________________________________________________ //
  const handleOnAddResumeClick = () => {
    setShowModal(true);
  };
  const handlePageChange = (pageNo) => {
    setPageNo(pageNo);
  };

  const closeModal = () => {
    setResumeDeleteModalOpen(false)
    setResumeIdForDelete("")
    setMiniLoader(false)

  }
  return (
    <div className="body_container cm_bold">
      {
        state.resMsg ?
          <div className="alert alert-danger cm_top_fix">Resume deleted successfully</div> : ''
      }
      <AddResumeModal showModal={showModal} setShowModal={setShowModal} />
      <UpdateResumeModal showModal={updateResume} setShowModal={setUpdateResume} resumeId={resumeId} />
      <div className="employeeDetails-container">
        <div className="contentDiv">
          <div className="tableDescDiv">
            <h3> RESUME </h3>
            <Button onClick={handleOnAddResumeClick}>+ Add Resume</Button>
          </div>
          {resumeList ? (
            <>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="white bg_blue">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Last Updated</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!_.isEmpty(resumeList) ? resumeList.map((element, i) => {
                      return (
                        <tr>
                          <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                          <td>{element.title}</td>
                          <td>
                            {moment(element.updated).format("HH:mm") +
                              " on " +
                              new Date(element.updated).getDate() +
                              "/" +
                              (new Date(element.updated).getMonth() + 1) +
                              "/" +
                              new Date(element.updated).getFullYear()}
                          </td>
                          <td>
                            <div className="actionDiv">
                              <a href={element.resume} download={element.title}>
                                <ActionImage
                                  src={downloadIcon}
                                  alt="downloadIcon"
                                />
                              </a>
                              <span onClick={() => handleOnUpdateResumeClick(element._id)}><i className="fa fa-pencil mr-4 ml-2" aria-hidden="true" title="Edit appraisal"></i></span>

                              <ActionImage
                                src={deleteIcon}
                                alt="deleteIcon"
                                onClick={() => {
                                  handleOpenDeleteModal(element);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    }) : ""}
                  </tbody>
                </table>
                {resumeList.length === 0 ? (
                  <div className="text-center">
                    <h3>No data available</h3>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <Loader />
            // ''
          )}
          {dataCount > 10 ? (
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={dataCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <ResumeDeleteModal
        handleClose={closeModal}
        miniLoader={miniLoader}
        show={resumeDeleteModalOpen}
        handleDelete={handleDeleteOnClick}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    resumeList: state.resumeByEmpId.data.resumeInfo?.allResume,
    dataCount: state.resumeByEmpId.data.resumeInfo?.totalResume
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getResumeByEmpId: (params) => {
      dispatch(getResumeByEmpId(params));
    },
    // updateResumeByEmpId: (params) => {
    //   dispatch(updateResumeByEmpId(params));
    // },
    deleteEmpResume: (resumeId, empId, closeModal) => {
      dispatch(deleteEmpResume(resumeId, empId, closeModal));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeResumeDetails);

const ActionImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
const Button = styled.button`
  background-color: rgb(52, 123, 222);
  cursor: pointer;
  width: 200px;
  border: none;
  padding: 0.4rem;
  border-radius: 10px;
  color: white;
  font-size: medium;
`;

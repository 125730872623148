import {
  GET_CANDIDATE_BY_ID_REQUEST,
  GET_CANDIDATE_BY_ID_SUCCESS,
  GET_CANDIDATE_BY_ID_FAILURE,
} from "./actionType";

import { getCandidateByIdService } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getCandidateById = (query) => {
  return (dispatch) => {
    dispatch(getCandidateByIdRequest());
    getCandidateByIdService(query)
      .then((res) => {
        let { status, resData } = ResponseFilter(res);

        if (status) {
          dispatch(getCandidateByIdSuccess(res.data.responseData));
        } else {
          dispatch(getCandidateByIdFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(getCandidateByIdFailure(error));
      });
  };
};
const getCandidateByIdRequest = () => {
  return {
    type: GET_CANDIDATE_BY_ID_REQUEST,
  };
};
const getCandidateByIdSuccess = (data) => {
  return {
    type: GET_CANDIDATE_BY_ID_SUCCESS,
    payload: data,
  };
};

const getCandidateByIdFailure = (error) => {
  return {
    type: GET_CANDIDATE_BY_ID_FAILURE,
    payload: error,
  };
};

export {
  getCandidateById,
  getCandidateByIdRequest,
  getCandidateByIdSuccess,
  getCandidateByIdFailure,
};

import React from "react";
import _ from "lodash";
import Dropzone from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
const { Option } = Select;

export default function AddTeam(props) {
  return (
    <Modal
      size="md"
      show={props.openAddTeamModal}
      onHide={props.closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="add-new-dept">
          <h3>Add New Team</h3>
          <div
            className={`form-group ${
              props.teamNameErrorMsg ? "input_error" : ""
            }`}
          >
            <label className="cm_bold">Team Name</label>
            <input
              maxLength="20"
              className="form-control cm_box"
              type="text"
              placeholder="Enter Team Name"
              name="departmentName"
              value={props.teamName}
              onChange={props.handleTeamNameChange}
            />
            {props.teamNameErrorMsg ? (
              <span className="error_msg">{props.teamNameErrorMsg}</span>
            ) : null}
          </div>
          <div className="row">
            <div
              className={`form-group col-12 ${
                props.teamLeadErrorMessage ? "input_error" : ""
              }`}
            >
              <label className="cm_bold">Team Head</label>

              <Select
                showSearch
                name="employeeId"
                value={props?.teamLead ? props?.teamLead : "Select Lead"}
                placeholder="Select Lead"
                mode="multiple"
                onChange={props.handleSelectTeamLeadChange}
                optionFilterProp="key"
                filterOption={(input, option) =>
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
                disabled={props.miniLoader}
                allowClear={props.teamLead ? true : false}
              >
                {!_.isEmpty(props.employeeList)
                  ? props.employeeList
                      .sort((a, b) =>
                        a.firstName < b.firstName
                          ? -1
                          : a.firstName > b.firstName
                          ? 1
                          : 0
                      )
                      .filter(
                        (employee) =>
                          !props.teamMembers.includes(employee.employeeId)
                      )
                      .map(
                        (employee) =>
                          employee.status === 1 && (
                            <Option
                              key={`${employee.firstName} ${employee.lastName}`}
                              value={employee.employeeId}
                            >
                              <span>
                                {employee.firstName} {employee.lastName} (
                                {employee.empId})
                              </span>
                            </Option>
                          )
                      )
                  : ""}
              </Select>
              {props.teamLeadErrorMessage ? (
                <span className="error_msg">{props.teamLeadErrorMessage}</span>
              ) : null}
            </div>
          </div>
          <div
            className={`form-group ${
              props.teamMembersErrorMessage ? "input_error" : ""
            }`}
          >
            <label className="cm_bold">Add Team Members</label>
            <Select
              showSearch
              name="employeeId"
              mode="multiple"
              value={props.teamMembers}
              placeholder="Select Team Members"
              onChange={props.handleSelectTeamMembersChange}
              optionFilterProp="key"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
              disabled={props.miniLoader}
            >
              {!_.isEmpty(props.employeeList)
                ? props.employeeList
                    .sort((a, b) =>
                      a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
                    )
                    .filter(
                      (employee) => !props.teamLead.includes(employee.employeeId)
                    )
                    .map(
                      (employee) =>
                        employee.status === 1 && (
                          <Option
                            key={`${employee.firstName} ${employee.lastName}`}
                            value={employee.employeeId}
                          >
                            <span>
                              {employee.firstName} {employee.lastName} (
                              {employee.empId})
                            </span>
                          </Option>
                        )
                    )
                : ""}
            </Select>
            {props.teamMembersErrorMessage ? (
              <span className="error_msg">{props.teamMembersErrorMessage}</span>
            ) : null}
          </div>
          <div
            className={`form-group ${
              props.teamIconErrorMessage ? "input_error" : ""
            }`}
          >
            <label className="mb-3 cm_bold">Team Icon</label>
            <Dropzone
              onDrop={(acceptedFile) => props.handleFileChange(acceptedFile)}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <section>
                  <div
                    className="upload_doc upload_doc_1"
                    {...getRootProps()}
                    style={{ background: `url(${props.teamIconPreview})` }}
                  >
                    <input {...getInputProps()} accept=".png, .jpg, .jpeg" />

                    {props.teamIconPreview ? (
                      ""
                    ) : (
                      <p>
                        <span className="blue">Upload Icon</span>
                      </p>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            {props.teamIconErrorMessage ? (
              <span className="error_msg">{props.teamIconErrorMessage}</span>
            ) : null}
          </div>

          <div className="submit_button_margin mt-4">
            <button
              type="submit"
              className="btn btn-primary cm_btn3"
              onClick={props.handleSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-primary  cm_btn2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

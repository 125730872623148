import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Loader } from "../svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-js-pagination";
import ResponseFilter from "../../ResponseFilter";
import _, { isArray } from "lodash";
import {
  getProjectId,
  editProject,
  getEmployeeList,
  deleteProject,
  dailyWorkByProjectId,
  getProjectLeave,
  getProjectMembersProjectDuration,
  getDataForTemplates,
  getEmployeeListByManager,
} from "../../config/services/employeeServices";
import { DatePicker, Select, Modal as AntModal } from "antd";
import moment from "moment";
import { leaveValue } from "../leaves/Constants";
import { CONSTANTS } from "../../config";
import { dateFormat, statusMap } from "../helper/Constant";
import { projectTypeValue } from "../../config/constants/Constants";
import DOMPurify from "dompurify";
import DownloadEmpDataModal from "./ProjectModal/DownloadEmpDataModal";
import { handleDownloadValidation } from "./ProjectUtils/Constants";
import ActivityTable from "./ActivityTable";
import ViewTemplateModal from "./ViewTemplateModal";
const { Option } = Select;
const { RangePicker } = DatePicker;
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const technologyList = CONSTANTS.INTERVIEW_SECTION.TECHNICAL_SKILL;
function disabledDate(current) {
  return current && current > moment().endOf("day");
}
class ViewProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewProject: [],
      openEditProjectPopup: false,
      openAddParticipentPopup: false,
      openCompleteProjectPopup: false,
      openDeleteProjectPopup: false,
      projectDetails: "",
      members: [],
      category: [],
      errorrMsg: "",
      assignedTo: [],
      projectName: "",
      mailId1: "",
      clientName1: "",
      projectName1: "",
      projectManager1: "",
      accountManagerFirstName: "",
      accountManagerLastName: "",
      category1: [],
      endDate1: "",
      startDate1: "",
      projectDescription1: "",
      startDate: "",
      endDate: "",
      clientName: "",
      projectManager: "",
      mailId: "",
      projectDescription: "",
      projectNameErMsg: "",
      dateErMsg: "",
      endDateErMsg: "",
      projectManagerNameErMsg: "",
      blocker: "",
      hour: "",
      employeeList: [],
      dailyWorkByProjectId: [],
      membersDetails: [],
      exMembersDetails: [],
      status: 1,
      loader: false,
      projectManagerId: "",
      accountManagerId: "",
      dataCount: "",
      pageNo: 1,
      count: 10,
      resMsg: false,
      resMsgEdit: false,
      resFailMsg: "",
      clientNameErrorMesaage: "",
      tabStatus: "",
      projectCategoryErrorMessage: "",
      startDateStamp: "",
      endDateStamp: "",
      startDateRange: "",
      endDateRange: "",
      filterEmployee: "",
      projectMemberLeave: [],
      memberLeaveCount: "",
      sortField: "created",
      sortType: SORT_TYPE_CONSTANTS.Descending,
      miniLoader: false,
      projectHours: 0,
      projectHoursErMsg: "",
      employeeTotalHours: 0,
      projectType: 0,
      technology: [],
      technologyErrorMessage: "",
      exMember: [],
      exProjectMembersDataCount: 0,
      search: "",
      openDownloadEmpData: false,
      downloadEmployee: "",
      selectTemplate: "",
      attendanceData: "",
      viewTemplate: false,
      month: "",
      year: "",
      monthErr: "",
      yearErr: "",
      downloadEmployeeErr: "",
      selectTemplateErr: "",
      modalLoader: false,
      viewModal: false,
    };
    this.debounceSearch = null;
  }

  catValue = (type) => {
    switch (type) {
      case 1:
        return "Web";
        break;
      case 2:
        return "Android";
        break;
      case 3:
        return "iOS";
        break;
      default:
        return "all";
    }
  };

  getProjectMemberLeave = () => {
    let { pageNo } = this.state;
    let params = `${this.props.match.params.id}&sortType=${this.state.sortType
      }&sortField=${this.state.sortField}&pageNo=${this.state.pageNo - 1}&count=${this.state.count
      }`;
    this.props.history.push(
      `?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`
    );
    this.setState({ loader: true });
    getProjectLeave(params).then((res) => {
      if (res.data.statusCode == 1) {
        let { status, resData } = ResponseFilter(res);
        this.setState({ loader: false });
        if (status) {
          this.setState({
            loader: false,
            projectMemberLeave: resData?.leaveList?.result,
            memberLeaveCount: resData.dataCount,
          });
        } else {
          this.setState({ loader: false });
        }
      }
    });
  };

  getViewProject = () => {
    let params = this.props.match.params.id;
    this.setState({ loader: true });
    this.props.history.push(
      `?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`
    );
    getProjectId(params).then((res) => {
      if (res.data.statusCode == 1) {
        let { status, resData } = ResponseFilter(res);
        this.setState({ loader: false });
        if (status) {
          let {
            projectId,
            projectName,
            category,
            startDate,
            endDate,
            clientName,
            projectManager,
            mailId,
            projectDescription,
            blocker,
            hour,
            status,
            projectManagerId,
            accountManagerId,
            accountManagerFirst,
            accountManagerLast,
            projectHours,
            projectType,
            technology,
          } = resData.projectDetails;
          let userline = [];

          let membersDetails = resData.membersDetails;
          for (var i = 0; i < membersDetails.length; i++) {
            userline.push(membersDetails[i].employeeId);
          }
          this.setState({ assignedTo: userline });
          let categories = [];
          categories = isArray(category) ? category : categories.push(category);
          this.setState({
            projectId,
            mailId1: mailId,
            clientName1: clientName,
            projectManager1: projectManager,
            accountManagerFirstName: accountManagerFirst,
            accountManagerLastName: accountManagerLast,
            endDate1: endDate,
            projectName1: projectName,
            category1: categories,
            startDate1: startDate,
            projectDescription1: projectDescription,
            projectName,
            category: categories,
            startDate,
            endDate,
            clientName,
            projectManager,
            mailId,
            projectManagerId,
            accountManagerId,
            projectDescription,
            blocker,
            hour,
            // assignedTo,
            status,
            projectHours,
            projectType,
            technology,
            membersDetails: resData.membersDetails,
            exMembersDetails: resData?.exMembersDetails,
          });
        } else {
          this.setState({ loader: false });
        }
      }
    });
  };

  handleChangeAssignedToChange = (e) => {
    this.setState({ projectManagerId: e });
  };

  handleChangeAccountManager = (e) => {
    this.setState({ accountManagerId: e });
  };

  // handleChange = (e) =>
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });

  handleSelectChange = (e) =>
    this.setState({ category: e, projectCategoryErrorMessage: "" });

  handleOpenEditProjectPopup = () =>
    this.setState({ openEditProjectPopup: !this.state.openEditProjectPopup });

  handleOpenCompleteProjectPopup = () =>
    this.setState({
      openCompleteProjectPopup: !this.state.openCompleteProjectPopup,
    });

  handleMembersChange = (e) => this.setState({ assignedTo: e });

  handleOpenAddParticipentPopup = ({ employeesList }) =>
    this.setState({
      openAddParticipentPopup: !this.state.openAddParticipentPopup,
      employeesList,
    });

  handleOpenDeleteProjectPopup = ({ projectId }) =>
    this.setState({
      openDeleteProjectPopup: !this.state.openDeleteProjectPopup,
      projectId,
    });

  onChangeDate = (date, dateString) => {
    this.setState({
      startDate: date[0],
      endDate: date[1],
    });
  };

  closeModal = () => {
    this.setState({
      openEditProjectPopup: false,
      openAddParticipentPopup: false,
      openCompleteProjectPopup: false,
      openDeleteProjectPopup: false,
      errorrMsg: "",
      projectManagerNameErMsg: "",
      projectNameErMsg: "",
      dateErMsg: "",
      endDateErMsg: "",
      projectName: "",
      mailId: "",
      clientName: "",
      category: [],
      projectCategoryErrorMessage: "",
      startDate: "",
      endDate: "",
      projectManagerId: "",
      accountManagerId: "",
      projectDescription: "",
      assignedTo: [],
      projectHours: 0,
      projectType: 0,
      category1: [],
      technology: [],
      technologyErrorMessage: "",
      openDownloadEmpData: false,
      downloadEmployee: "",
      year: "",
      modalLoader: false,
      viewModal: false
    });
  };
  handleEditSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      // this.setState({ loader: true })
      let obj = {
        projectId: this.props.match.params.id,
        projectName: this.state.projectName,
        category: this.state.category,
        assignedTo:
          this.state.assignedTo === ""
            ? null
            : this.state.assignedTo.toString(),
        startDate: new Date(this.state.startDate).setHours(0, 0, 0, 0),
        endDate: new Date(this.state.endDate).setHours(0, 0, 0, 0),
        clientName: this.state.clientName,
        projectManager: this.state.projectManagerId,
        accountManager: this.state.accountManagerId
          ? this.state.accountManagerId
          : this.state.projectManagerId,
        mailId: this.state.mailId,
        projectDescription: this.state.projectDescription,
        projectHours: this.state.projectHours,
        projectType: parseInt(this.state.projectType),
        technology: this.state.technology,
      };

      editProject(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            { resMsgEdit: true, loader: false },
            () => this.getViewProject(),
            this.closeModal()
          );
        } else {
          this.setState({
            errorrMsg: res.data.error.responseMessage,
            resFailMsg: true,
            loader: false,
          });
        }
      });
    }
    setTimeout(() => this.setState({ resMsgEdit: false }), 3000);
    setTimeout(() => this.setState({ resFailMsg: false }), 3000);
  };

  getEmployeeList = () => {
    getEmployeeList("").then((res) => {
      if (res.data.statusCode === 1) {
        this.setState({
          projectManager: res.data.responseData.employeesList.employeeId,
          employeeList: res.data.responseData.employeesList,
        });
      }
    });
  };

  handleComplete = () => {
    let params = {
      projectId: this.props.match.params.id,
      status: 2,
    };

    editProject(params).then((res) => {
      this.setState({ miniLoader: false }, () => {
        window.location.href = "/admin-projects";
      });
    });
  };

  handleDelete = () => {
    let obj = {
      data: {
        projectId: this.props.match.params.id,
        isDeleted: 2,
      },
    };
    // deleteProject(obj).then((res) => {
    //   this.setState({ resMsg: true }, () => {
    //     setTimeout(() => window.location.href = `/admin-projects`, 1000)
    //   });
    // });
    this.setState({ miniLoader: true });
    deleteProject(obj).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState({ resMsg: true, miniLoader: false });
      } else {
        this.setState({ resFailMsg: true, miniLoader: false });
        this.setState({ errorrMsg: res.data.error.responseMessage });
      }
      setTimeout(() => (window.location.href = `/admin-projects`), 2000);
    });
    setTimeout(() => this.setState({ resMsg: false }), 3000);
    setTimeout(() => this.setState({ resFailMsg: false }), 3000);
  };
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo }, () => {
      this.getActivityLogs();
      this.getViewProject();
      this.getProjectMemberLeave();
      this.getExProjectMambers();
    });
  };
  getActivityLogs = () => {
    let {
      sortType,
      sortField,
      pageNo,
      count,
      startDateRange,
      endDateRange,
      filterEmployee,
    } = this.state;
    let params = `count=${count}&pageNo=${pageNo - 1
      }&sortType=${sortType}&sortField=${sortField}&projectId=${this.props.match.params.id
      }&startDate=${startDateRange}&endDate=${endDateRange}&employeeId=${filterEmployee == "undefined" ? "" : filterEmployee
      }`;
    this.props.history.push(
      `?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`
    );
    this.setState({ loader: true });
    dailyWorkByProjectId(params).then((res) => {
      if (res.data.statusCode == 1) {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          let { projectId } = resData.dailyWorkDetails;
          this.setState({
            projectId: projectId,
            dailyWorkDetails: resData.dailyWorkDetails,
            dataCount: resData.dataCount,
            employeeTotalHours: resData?.totalHours,
            loader: false,
          });
        }
      }
    });
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  disabledStartDate = (startDate) => {
    const { endDate } = this.state;
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = (endDate) => {
    const { startDate } = this.state;
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };

  onChangeDate = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChangeDate("startDate", value);
  };

  onEndChange = (value) => {
    this.onChangeDate("endDate", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  handleChange = (e) =>
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      clientNameErrorMessage: "",
      clientMailErrorMeaasge: "",
    });
  handleValidation = () => {
    let validate = true;
    const {
      projectName,
      startDate,
      endDate,
      projectManagerId,
      clientName,
      mailId,
      projectHours,
      technology,
      category,
    } = this.state;

    if (projectName === "" || projectName === undefined) {
      validate = false;
      this.setState({
        projectNameErMsg: "Project name is required",
      });
    } else {
      this.setState({
        projectNameErMsg: "",
      });
    }
    // if (clientName === "" || clientName === undefined) {
    //   validate = false;
    //   this.setState({
    //     clientNameErrorMessage: "Client Name is required",
    //   });
    // } else {
    //   this.setState({
    //     clientNameErrorMessage: "",
    //   });
    // }
    // if (mailId === "" || mailId === undefined) {
    //   validate = false;
    //   this.setState({
    //     clientMailErrorMeaasge: "Client Mail-Id is required",
    //   });
    // } else
    if (mailId) {
      if (
        !/^(?!.{41})[a-z0-9]+(?:\.[a-z0-9]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-zA-Z]{2,6}$/.test(
          mailId
        )
      ) {
        validate = false;
        this.setState({
          clientMailErrorMeaasge: "Client Mail-Id should be Valid",
        });
      } else {
        this.setState({
          clientMailErrorMeaasge: "",
        });
      }
    }
    if (startDate === "" || startDate === undefined) {
      validate = false;
      this.setState({
        dateErMsg: "Start date and end date is required",
      });
    } else {
      this.setState({
        dateErMsg: "",
      });
    }
    if (endDate === "" || endDate === undefined) {
      validate = false;
      this.setState({
        endDateErMsg: "Start date and end date is required",
      });
    } else {
      this.setState({
        endDateErMsg: "",
      });
    }
    if (projectManagerId === "" || projectManagerId === undefined) {
      validate = false;
      this.setState({
        projectManagerNameErMsg: "Project manager is required",
      });
    } else {
      this.setState({
        projectManagerNameErMsg: "",
      });
    }

    if (category === "" || category === undefined || category.length <= 0) {
      validate = false;
      this.setState({
        projectCategoryErrorMessage: "Please Select Project Category",
      });
    } else {
      this.setState({
        projectCategoryErrorMessage: "",
      });
    }
    if (projectHours) {
      if (isNaN(projectHours)) {
        validate = false;
        this.setState({
          projectHoursErMsg: "Please Enter Valid Total Hours",
        });
      } else if (projectHours < 1) {
        validate = false;
        this.setState({
          projectHoursErMsg: "Please Enter Hours Greater then 0",
        });
      } else {
        this.setState({
          projectHoursErMsg: "",
        });
      }
    }
    if (
      technology == [] ||
      technology === undefined ||
      technology.length <= 0
    ) {
      validate = false;
      this.setState({
        technologyErrorMessage: "Please Select Technology",
      });
    } else {
      this.setState({
        technologyErrorMessage: "",
      });
    }
    return validate;
  };
  handleEmployeeSearch = (e) => {
    console.log("e : ", e);
  };
  exportExcel = () => {
    this.setState({ openDownloadEmpData: true });
  };
  handleFilter = (e) => {
    this.setState(
      {
        pageNo: 1,
        loader: true,
        tabStatus: e == 0 ? "" : parseInt(e),
        sortField: "created",
        sortType: 2,
      },
      () => {
        this.getViewProject();
        this.getActivityLogs();
        this.getProjectMemberLeave();
        this.getExProjectMambers();
      }
    );
  };

  getExProjectMambers = () => {
    let params = `projectId=${this.props.match.params.id}&sortType=${this.state.sortType
      }&sortField=${this.state.sortField == "created" ? "startDate" : this.state.sortField
      }&pageNo=${this.state.pageNo - 1}&count=${this.state.count
      }&search=${this.state.search.trim()}`;
    this.props.history.push(
      `?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`
    );
    this.setState({ loader: true, exMember: [] });
    getProjectMembersProjectDuration(params).then((res) => {
      if (res.data.statusCode == 1) {
        let { status, resData } = ResponseFilter(res);
        this.setState({ loader: false });
        if (status) {
          this.setState({
            loader: false,
            exMember: resData?.durationByMember,
            exProjectMembersDataCount: resData?.dataCount,
          });
        } else {
          this.setState({ loader: false });
        }
      }
    });
  };

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let tabStatus = url.searchParams.get("tabStatus");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    let startDateRange = url.searchParams.get("startDateRange");
    let endDateRange = url.searchParams.get("endDateRange");
    let startDateStamp = url.searchParams.get("startDateStamp");
    let endDateStamp = url.searchParams.get("endDateStamp");
    let filterEmployee = url.searchParams.get("filterEmployee");
    const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";
    let data = sessionStorage.getItem("activityData")
    this.setState(
      {
        pageNo: pageNo ? +pageNo : 1,
        search: search ? search : "",
        sortType: sortType
          ? parseInt(sortType)
          : SORT_TYPE_CONSTANTS.Descending,
        sortField: sortField ? sortField : "created",

        startDateStamp: startDateStamp ? moment(startDateStamp, format) : "",
        endDateStamp: endDateStamp ? moment(endDateStamp, format) : "",
        startDateRange: startDateRange ? startDateRange : "",
        endDateRange: endDateRange ? endDateRange : "",
        tabStatus: tabStatus ? tabStatus : this.state.tabStatus,
        filterEmployee: filterEmployee ? filterEmployee : "",
      },
      () => {
        this.getViewProject();
        this.getEmployeeList();
        this.getProjectMemberLeave();
        this.getExProjectMambers();
        if (data) {
          let activityData = JSON.parse(data)
          this.setState({
            projectId: activityData?.projectId,
            dailyWorkDetails: activityData?.dailyWorkDetails,
            dataCount: activityData?.dataCount,
            employeeTotalHours: activityData?.employeeTotalHours,
            loader: activityData?.false,
          })
          sessionStorage.removeItem("activityData")
        }
        else {
          this.getActivityLogs();
        }
      }
    );
  }

  handleRangePicker = (date) => {
    if (date.length === 0) {
      this.setState(
        {
          pageNo: 1,
          startDateStamp: "",
          endDateStamp: "",
          startDateRange: !_.isEmpty(date)
            ? new Date(date[0]).setHours(6, 30, 0, 0)
            : "",
          endDateRange: !_.isEmpty(date)
            ? new Date(date[1]).setHours(6, 30, 0, 0)
            : "",
          dateRange: false,
        },
        () => {
          this.getActivityLogs();
          this.getViewProject();
        }
      );
    } else {
      this.setState(
        {
          pageNo: 1,
          startDateStamp: date[0],
          endDateStamp: date[1],
          startDateRange: !_.isEmpty(date)
            ? new Date(date[0]).setHours(6, 30, 0, 0)
            : "",
          endDateRange: !_.isEmpty(date)
            ? new Date(date[1]).setHours(6, 30, 0, 0)
            : "",
          dateRange: true,
        },
        () => {
          this.getActivityLogs();
          this.getViewProject();
        }
      );
    }
  };

  handleTab = (e) => {
    if (e == undefined) {
      this.setState({ tabStatus: 1, filterEmployee: "", pageNo: 1 }, () => {
        this.getActivityLogs();
        this.getViewProject();
        this.getProjectMemberLeave();
      });
    } else {
      this.setState({ tabStatus: 1, filterEmployee: e, pageNo: 1 }, () => {
        this.getActivityLogs();
        this.getViewProject();
        this.getProjectMemberLeave();
      });
    }
  };
  clear = () => {
    this.setState({ filterEmployee: "" });
  };
  handleSort = (e) => {
    this.setState(
      {
        loader: false,
        sortField: e,
        sortType:
          parseInt(this.state.sortType) == SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
      () => this.getActivityLogs(),
      this.getViewProject(),
      this.getProjectMemberLeave(),
      this.getExProjectMambers()
    );
  };
  millisecondsToHoursAndMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const paddedHours = hours < 10 ? `0${hours}` : hours;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${paddedHours}:${paddedMinutes}`;
  };

  handleActualHours = (membersDetails) => {
    const totalHoursArray = Object.values(membersDetails).map(
      (employee) => employee.totalHours
    );

    const sumTotalHours = this.millisecondsToHoursAndMinutes(
      totalHoursArray.reduce((sum, hours) => sum + hours, 0)
    );

    return sumTotalHours;
  };
  handleProjectHoursChange = (e) => {
    this.setState({
      projectHours: /^\s/.test(e.target.value) ? "" : e.target.value,
      projectHoursErMsg: "",
    });
  };
  handleProjectType = (e) => {
    this.setState({
      projectType: e,
    });
  };
  handleTechnologyChange = (e) => {
    this.setState({
      technology: e,
      technologyErrorMessage: "",
    });
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value, pageNo: 1 });
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.getExProjectMambers();
    }, 2000);
  };

  handleClearSearchChange = () => {
    this.setState({ search: "" }, () => this.getExProjectMambers());
  };

  createMarkup = (props) => {
    return {
      __html: DOMPurify.sanitize(props),
    };
  };

  handleDownload = () => {
    const { downloadEmployee, month, year, selectTemplate, empName } =
      this.state;
    const projectId = this.props.match.params.id;
    this.setState({ modalLoader: true });
    if (
      handleDownloadValidation({
        state: this.state,
        setState: this.setState.bind(this),
      })
    ) {
      let employeeId = downloadEmployee.trim();
      const params = `projectId=${this.props.match.params.id}&employeeId=${employeeId}&searchMonth=${month}&searchYear=${year}`;
      getDataForTemplates(params).then((res) => {
        this.setState({ loader: false });
        const { status, resData } = ResponseFilter(res);
        if (status) {
          const data = resData.attendenceData.length && resData.attendenceData;
          const projectDesc = resData.projectDesc;
          const dailyWorks = resData.dailyWorks;
          const getStatus = (status) => {
            if (statusMap.present.includes(status)) return "present";
            if (statusMap.absent.includes(status)) return "absent";
            if (statusMap.holiday.includes(status)) return "holiday";
            if (statusMap.leave.includes(status)) return "leave";
            if (statusMap.halfLeave.includes(status)) return "halfLeave";
            return "unknown";
          };

          const attendanceData =
            resData.attendenceData.length > 0
              ? resData.attendenceData.reduce((acc, entry) => {
                const dateKey = `${entry.year}-${entry.month}-${entry.day}`;
                acc[dateKey] = getStatus(entry.status);
                return acc;
              }, {})
              : {};

          const holidays = resData.holidayList || [];
          const filteredHolidays = holidays.filter(
            (holiday) =>
              holiday.year.toString() === year &&
              holiday.month.toString() === month
          );
          filteredHolidays.forEach((holiday) => {
            const holidayDate = new Date(holiday.date);
            const year = holidayDate.getFullYear();
            const month = holidayDate.getMonth() + 1;
            const day = holidayDate.getDate();
            const dateKey = `${year}-${month}-${day}`;
            attendanceData[dateKey] = "holiday";
          });
          const params = {
            projectId: this.state.projectId,
            dailyWorkDetails: this.state.dailyWorkDetails,
            dataCount: this.state.dataCount,
            employeeTotalHours: this.state.employeeTotalHours,
            loader: false,
          }
          sessionStorage.setItem("activityData", JSON.stringify(params))
          this.props.history.push("/project/template", {
            attendanceData,
            selectTemplate,
            month,
            year,
            projectId,
            data,
            dailyWorks,
            projectDesc,
            empName,
          });
        }
      });
    }
  };

  handleMonth = (e) => {
    this.setState({ monthErr: "" });
    if (e === undefined) {
      this.setState({ month: "" });
    } else {
      this.setState({ month: e });
    }
  };

  handleYear = (e) => {
    this.setState({ yearErr: "" });
    if (e === undefined) {
      this.setState({ year: "" });
    } else {
      this.setState({ year: e })
    }
  };

  handleDownloadEmployee = async (e) => {
    this.setState({ downloadEmployeeErr: "" });
    if (e === undefined) {
      this.setState({ downloadEmployee: "" });
    } else {
      try {
        const res = await getEmployeeListByManager();
        const { status, resData } = ResponseFilter(res);
        if (status) {
          const employee = resData.employeesList.find(
            (employee) => employee.employeeId === e
          );
          if (employee) {
            const employeeName = `${employee.firstName} ${employee.lastName}`;
            this.setState({ empName: employeeName });
          } else {
            this.setState({ downloadEmployeeErr: "Employee not found" });
          }
        } else {
          this.setState({
            downloadEmployeeErr: "Failed to fetch employee list",
          });
        }
      } catch (error) {
        console.error("Error fetching employee list:", error);
        this.setState({ downloadEmployeeErr: "Error fetching employee list" });
      }
      this.setState({ downloadEmployee: e });
    }
  };

  handleSelectTemplate = (e) => {
    this.setState({ selectTemplateErr: "" });
    if (e === undefined) {
      this.setState({ selectTemplate: "" });
    } else {
      this.setState({ selectTemplate: e });
    }
  };
  handleViewModal = () => {
    this.setState({ viewModal: true })
  }
  render() {
    let {
      pageNo,
      dataCount,
      count,
      loader,
      membersDetails,
      members,
      status,
      endOpen,
      employeeList,
      openEditProjectPopup,
      openAddParticipentPopup,
      openCompleteProjectPopup,
      openDeleteProjectPopup,
      projectId,
      projectName,
      clientName1,
      projectManager1,
      accountManager1,
      projectName1,
      mailId1,
      category1,
      endDate1,
      startDate1,
      projectDescription1,
      category,
      startDate,
      endDate,
      assignedTo,
      clientName,
      projectManager,
      mailId,
      errorrMsg,
      projectDescription,
      dateErMsg,
      projectNameErMsg,
      projectManagerNameErMsg,
      endDateErMsg,
      // dateFormat,
      dailyWorkDetails,
      blocker,
      hour,
      projectManagerId,
      accountManagerId,
      resMsg,
      resMsgEdit,
      resFailMsg,
      clientNameErrorMessage,
      clientMailErrorMeaasge,
      tabStatus,
      startDateStamp,
      endDateStamp,
      filterEmployee,
      projectMemberLeave,
      sortField,
      sortType,
      miniLoader,
      memberLeaveCount,
      accountManagerFirstName,
      accountManagerLastName,
      projectHours,
      projectHoursErMsg,
      employeeTotalHours,
      projectType,
      technology,
      technologyErrorMessage,
      exMember,
      exProjectMembersDataCount,
      search,
      exMembersDetails,
      projectCategoryErrorMessage,
      openDownloadEmpData,
      downloadEmployee,
      selectTemplate,
      attendanceData,
      month,
      year,
      modalLoader,
      viewModal
    } = this.state;
    return (
      <div className="body_container cm_bold proj_detail">
        {/* {loader && <Loader />} */}
        {resMsg ? (
          <div className="alert alert-danger cm_top_fix">
            Project Deleted Successfully
          </div>
        ) : (
          ""
        )}
        {resMsgEdit ? (
          <div className="alert alert-warning cm_top_fix">
            Project Edited Successfully
          </div>
        ) : (
          ""
        )}
        {resFailMsg ? (
          <div className="alert alert-warning cm_top_fix">{errorrMsg}</div>
        ) : (
          ""
        )}
        <div className="cm_breadcrumb">
          <ul>
            <li>
              <Link to="/admin-projects">PROJECT LIST</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li>{projectName1.toUpperCase()}</li>
          </ul>
        </div>
        <div className="text-right mt-4"></div>
        {tabStatus == 1 ? (
          <>
            <div className=" filters justify-content-end align-items-center mb-2">
              <div className={`select_year_cal`}>
                <Select
                  showSearch
                  name="employeeId"
                  value={filterEmployee ? filterEmployee : "Select employee "}
                  allowClear={filterEmployee ? true : false}
                  onSearch={this.handleEmployeeSearch}
                  optionFilterProp="key"
                  filterOption={(input, option) =>
                    option.key.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => this.handleTab(e)}
                >
                  {!_.isEmpty(membersDetails)
                    ? membersDetails.map((employee) => (
                      <Option
                        key={`${employee.firstName} ${employee.lastName}`}
                        value={employee.employeeId}
                      >
                        <span>
                          {employee.firstName + " " + employee.lastName}
                        </span>
                      </Option>
                    ))
                    : ""}
                </Select>
              </div>
              <div>
                <RangePicker
                  id="daterangepicker"
                  onChange={this.handleRangePicker}
                  disabledDate={this.disabledDate}
                  className="rang select_year_cal rang_pick mr-3"
                  value={[
                    startDateStamp ? moment(startDateStamp) : "",
                    endDateStamp ? moment(endDateStamp) : "",
                  ]}
                />
              </div>
              <button className="btn btn-primary" onClick={this.exportExcel}>
              Download Employee Timesheet
              </button>
              <button className="btn btn-primary" onClick={this.handleViewModal}> <i
                className="fa fa-eye mr-2"
                aria-hidden="true"
              ></i>Sample Template</button>
            </div>
          </>
        ) : (
          ""
        )}
        {tabStatus == 3 ? (
          <div className="row d-flex justify-content-end mb-4">
            <div className="col-md-6 search_device_bar select__date">
              <input
                className="form-control mr-lg-2 cm_box"
                type="search"
                maxLength={25}
                placeholder="Search by name/E.Id"
                aria-label="Search"
                value={search}
                onChange={this.handleSearch}
              // onKeyDown={(e) => { if (e.key === 'Enter') this.getExProjectMambers() }}
              />
              {search ? (
                <span
                  className="cm_clear"
                  onClick={this.handleClearSearchChange}
                >
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </span>
              ) : (
                <span className="cm_clear">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="text-left cm_ticket_btn cm_leave_btn mt-4">
          <button
            className={`btn btn-primary ${tabStatus == "" ? "active" : ""}`}
            // style={{ width: 140 }}
            onClick={() => this.handleFilter("")}
          >
            <span className="tabtext">General Info</span>
          </button>
          <button
            className={`btn btn-primary ${tabStatus == 1 ? "active" : ""}`}
            // style={{ width: 140 }}
            onClick={() => this.handleFilter(1)}
          >
            <span className="tabtext">Activity</span>
          </button>

          <button
            className={`btn btn-primary ${tabStatus == 2 ? "active" : ""}`}
            // style={{ width: 140 }}
            onClick={() => this.handleFilter(2)}
          >
            <span className="tabtext">Leave</span>
          </button>

          <button
            className={`btn btn-primary ${tabStatus == 3 ? "active" : ""}`}
            // style={{ width: 140 }}
            onClick={() => this.handleFilter(3)}
          >
            <span className="tabtext">Duration</span>
          </button>
        </div>
        {tabStatus == "" ? (
          !loader ? <>
            <div className="project-information">
              <div className="project-summary">
                <h4 className="cm_bold mb-2">Project Summary</h4>
                <form className="project-summary-detail">
                  <div className="cm_overflow_project">
                    <div className="row cm_row">
                      <div className="col-6 cm_bold col2">Name</div>
                      <div className="col-6 cm_bold col2">{projectName1}</div>

                      <div className="col-6 cm_bold col2">Category</div>
                      <div className="col-6 cm_bold col2">
                        {isArray(category1)
                          ? category1.map((category, index) =>
                            category1 ? (
                              <span>
                                {" "}
                                {this.catValue(category)}
                                {index < category1?.length - 1 && ", "}
                              </span>
                            ) : (
                              ""
                            )
                          )
                          : category1
                            ? this.catValue(category1)
                            : ""}
                      </div>

                      <div className="col-6 cm_bold col2">Technology</div>
                      <div className="col-6 cm_bold col2">
                        {technology
                          ? technology.map((technologies, index) => (
                            <span>
                              {" "}
                              {technologyList[technologies]}
                              {index < technology?.length - 1 && ", "}
                            </span>
                          ))
                          : ""}
                      </div>
                      <div className="col-6 cm_bold col2">Project Type</div>
                      <div className="col-6 cm_bold col2">
                        {projectType ? projectTypeValue(projectType) : ""}
                      </div>
                      <div className="col-6 cm_bold col2">Status</div>
                      <div className="col-6 cm_bold col2">
                        {status === 1
                          ? "Ongoing"
                          : status === 2
                            ? "Completed"
                            : status == 3
                              ? "Incomplete"
                              : status == 4
                                ? "Maintenance"
                                : "On Hold"}
                      </div>
                      <div className="col-6 cm_bold col2">Start Date</div>
                      <div className="col-6 cm_bold col2">
                        {moment.unix(startDate1 / 1000).format("DD/MM/YYYY")}
                      </div>
                      <div className="col-6 cm_bold col2">End Date</div>
                      <div className="col-6 cm_bold col2">
                        {moment.unix(endDate1 / 1000).format("DD/MM/YYYY")}
                      </div>
                      <div className="col-6 cm_bold col2">Client Name</div>
                      <div className="col-6 cm_bold col2">
                        {clientName1 ? clientName1 : "-"}
                      </div>
                      <div className="col-6 cm_bold col2">Mail Id</div>
                      <div className="col-6 cm_bold col2">
                        {mailId1 ? mailId1 : "-"}
                      </div>
                      <div className="col-6 cm_bold col2">Project Manager</div>
                      <div className="col-6 cm_bold col2">
                        {projectManager1}
                      </div>
                      <div className="col-6 cm_bold col2">Account Manager</div>
                      <div className="col-6 cm_bold col2">
                        {accountManagerFirstName + " " + accountManagerLastName}
                      </div>
                      <div className="col-6 cm_bold col2">Project Hours</div>
                      <div className="col-6 cm_bold col2">{projectHours}</div>
                      <div className="col-6 cm_bold col2">Actual Hours</div>
                      <div className="col-6 cm_bold col2">
                        {this.handleActualHours(membersDetails)}
                      </div>
                      <div className="col-6 cm_bold col2">
                        Project Description
                      </div>
                      <div className="col-12 cm_bold col2">
                        {projectDescription1}
                      </div>
                    </div>
                  </div>
                  <div className="row cm_row">
                    <div className="col-6 cm_bold col3">Actions</div>
                    <div>
                      <div className="col-12 cm_bold col3">
                        <button
                          className="mr-5 btn btn-primary"
                          type="button"
                          onClick={this.handleOpenEditProjectPopup}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={this.handleOpenDeleteProjectPopup}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="project-summary">
                <h4 className="cm_bold mb-2">Team Members</h4>
                <form className="project-summary-detail">
                  <div className="cm_overflow_project_members">
                    <div className="row cm_row">
                      <div className="col-6 cm_bold col2">Name</div>
                      <div className="col-6 cm_bold col2">Total Hours</div>
                      {membersDetails.length
                        ? membersDetails.map((membersDetails) => (
                          <>
                            <div className="col-6 cm_bold col2">
                              {membersDetails.firstName}{" "}
                              {membersDetails.lastName}
                            </div>
                            <div className="col-6 cm_bold col2">
                              {this.millisecondsToHoursAndMinutes(
                                membersDetails?.totalHours
                              )}
                            </div>
                          </>
                        ))
                        : ""}
                    </div>
                    {membersDetails.length <= 0 ? (
                      <div
                        className="col-10 cm_bold col2 text-center"
                        style={{
                          fontSize: "20px",
                          color: "rgba(80, 77, 77, 0.747)",
                        }}
                      >
                        No data available
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="project-information mt-4 mb-4">
              <div className="project-summary">
                <h4 className="cm_bold mb-2">Ex Project Members</h4>
                <form className="project-summary-detail">
                  <div className="cm_overflow_project_members">
                    <div className="row cm_row">
                      <div className="col-6 cm_bold col2">Name</div>
                      <div className="col-6 cm_bold col2">Total Hours</div>
                      {exMembersDetails.length > 0
                        ? exMembersDetails.map((membersDetails) => (
                          <>
                            <div className="col-6 cm_bold col2">
                              {membersDetails.firstName}{" "}
                              {membersDetails.lastName}
                            </div>
                            <div className="col-6 cm_bold col2">
                              {this.millisecondsToHoursAndMinutes(
                                membersDetails?.totalHours
                              )}
                            </div>
                          </>
                        ))
                        : ""}
                    </div>
                    {exMembersDetails.length <= 0 ? (
                      <div
                        className="col-10 cm_bold col2 text-center"
                        style={{
                          fontSize: "20px",
                          color: "rgba(80, 77, 77, 0.747)",
                        }}
                      >
                        No data available
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </> : <Loader small={true} />
        ) : tabStatus == 1 ? (
          !loader ? <>
            <ActivityTable handleSort={this.handleSort} createMarkup={this.createMarkup} sortType={sortType} sortField={sortField} dailyWorkDetails={dailyWorkDetails} pageNo={pageNo} count={count} dataCount={dataCount} tabStatus={tabStatus} handlePageChange={this.handlePageChange} SORT_TYPE_CONSTANTS={SORT_TYPE_CONSTANTS} />
            <div className="text-right">
              <h3>
                {" "}
                Total Hours :-{" "}
                {this.millisecondsToHoursAndMinutes(employeeTotalHours)}
              </h3>
            </div>
          </> : <Loader small={true} />
        ) : tabStatus == 2 ? (
          !loader ? <>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="white bg_blue">
                  <tr>
                    <th>S.No</th>
                    <th>
                      Employee Name
                      <i
                        className={
                          sortType === 1 && sortField === "empName"
                            ? "fa fa-sort-alpha-asc ml-2"
                            : "fa fa-sort-alpha-desc ml-2"
                        }
                        onClick={() => this.handleSort("empName")}
                      ></i>
                    </th>

                    <th>
                      Start Date
                      <i
                        className={
                          sortType === 1 && sortField === "startDate"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => this.handleSort("startDate")}
                      ></i>
                    </th>
                    <th>End Date</th>
                    <th>Leave Type</th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(projectMemberLeave)
                    ? projectMemberLeave.map((data, i) => (
                      <tr key={data.leaveId}>
                        <td>{i + 1 + (pageNo - 1) * count}</td>
                        <td>{data.empName}</td>
                        <td>
                          {moment(data?.startDate)
                            .format(dateFormat)
                            .substring(0, 10)}
                        </td>
                        <td>
                          {moment(data?.endDate)
                            .format(dateFormat)
                            .substring(0, 10)}
                        </td>
                        <td>{leaveValue(data.leaveType)}</td>
                      </tr>
                    ))
                    : ""}
                </tbody>
              </table>
              {projectMemberLeave && projectMemberLeave.length > 0 ? (
                ""
              ) : (
                <h3 className="text-center">No data available</h3>
              )}
            </div>
          </> : <Loader small={true} />
        ) : (
          !loader ? <>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="white bg_blue">
                  <tr>
                    <th>S.No</th>
                    <th>
                      Employee Id
                      <i
                        className={
                          sortType === 1 && sortField === "empId"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => this.handleSort("empId")}
                      ></i>
                    </th>
                    <th>
                      Employee Name
                      <i
                        className={
                          sortType === 1 && sortField === "empName"
                            ? "fa fa-sort-alpha-asc ml-2"
                            : "fa fa-sort-alpha-desc ml-2"
                        }
                        onClick={() => this.handleSort("firstName")}
                      ></i>
                    </th>

                    <th>
                      Start Date
                      <i
                        className={
                          sortType === 1 && sortField === "startDate"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => this.handleSort("startDate")}
                      ></i>
                    </th>
                    <th>End Date</th>
                    <th>Days</th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(exMember)
                    ? exMember.map((data, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (pageNo - 1) * count}</td>
                        <td>{data.empId}</td>
                        <td>
                          {data?.firstName +
                            " " +
                            data?.middleName +
                            " " +
                            data?.lastName}
                        </td>
                        <td>
                          {moment(data?.startDate)
                            .format(dateFormat)
                            .substring(0, 10)}
                        </td>
                        <td>
                          {data?.endDate
                            ? moment(data?.endDate)
                              .format(dateFormat)
                              .substring(0, 10)
                            : "-"}
                        </td>
                        <td>
                          {data?.endDate
                            ? (new Date(
                              moment(data?.endDate)
                                .format("YYYY-MM-DD")
                                .substring(0, 10)
                            ) -
                              new Date(
                                moment(data?.startDate)
                                  .format("YYYY-MM-DD")
                                  .substring(0, 10)
                              )) /
                            (1000 * 60 * 60 * 24)
                            : "-"}
                        </td>
                      </tr>
                    ))
                    : ""}
                </tbody>
              </table>

              {exMember && exMember.length > 0 ? (
                ""
              ) : (
                <h3 className="text-center">No data available</h3>
              )}
            </div>
          </> : <Loader small={true} />
        )}


        {memberLeaveCount > 10 && tabStatus == 2 && loader == false ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={memberLeaveCount}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
        ) : (
          ""
        )}
        {exProjectMembersDataCount > 10 && tabStatus == 3 && loader == false ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={exProjectMembersDataCount}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
        ) : (
          ""
        )}
        <DownloadEmpDataModal
          openDownloadEmpData={openDownloadEmpData}
          closeModal={this.closeModal}
          membersDetails={membersDetails}
          handleDownloadEmployee={this.handleDownloadEmployee}
          handleSelectTemplate={this.handleSelectTemplate}
          handleDownload={this.handleDownload}
          handleMonth={this.handleMonth}
          handleYear={this.handleYear}
          state={this.state}
          modalLoader={modalLoader}
        />
        <AntModal
          className="sm_modal1"
          size="lg"
          centered
          title=""
          footer={false}
          visible={openEditProjectPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="assign_device" onSubmit={this.handleEditSubmit}>
              <h3 className="mb-4">EDIT PROJECT</h3>
              <div className="row">
                <div className="col-6">
                  <div
                    className={`form-group ${projectNameErMsg ? "input_error" : ""
                      }`}
                  >
                    <label className="sub">Project Name</label>
                    <input
                      maxLength="50"
                      className="form-control cm_box"
                      type="text"
                      placeholder="Project Name"
                      name="projectName"
                      value={projectName}
                      onChange={this.handleChange}
                    />
                    {projectNameErMsg ? (
                      <span className="error_msg">{projectNameErMsg}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`form-group col-6 ${projectCategoryErrorMessage ? "input_error" : ""
                    }`}
                >
                  <label className="sub">Category</label>

                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={category}
                    onChange={this.handleSelectChange}
                  >
                    <Option value={1}>Web</Option>
                    <Option value={2}>Android</Option>
                    <Option value={3}>iOS</Option>
                    <Option value={4}>all</Option>
                  </Select>

                  {projectCategoryErrorMessage ? (
                    <span className="error_msg">
                      {projectCategoryErrorMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div
                  className={`form-group col-3 ${dateErMsg ? "input_error" : ""
                    }`}
                >
                  <label className="sub">Start Date</label>
                  <DatePicker
                    allowClear={false}
                    className="date-border"
                    disabledDate={this.disabledStartDate}
                    //showTime
                    //format="DD-MM-YYYY"
                    value={!startDate ? startDate : moment(startDate)}
                    placeholder="Start date"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                  />
                  {dateErMsg ? (
                    <span className="error_msg">{dateErMsg}</span>
                  ) : null}
                </div>
                <div
                  className={`form-group col-3 ${endDateErMsg ? "input_error" : ""
                    }`}
                >
                  <label className="sub">End Date</label>
                  <DatePicker
                    allowClear={false}
                    className="date-border"
                    disabledDate={this.disabledEndDate}
                    //showTime
                    //format="DD-MM-YYYY"
                    value={!endDate ? endDate : moment(endDate)}
                    placeholder="End date"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                  />
                  {endDateErMsg ? (
                    <span className="error_msg">{endDateErMsg}</span>
                  ) : null}
                  {/* <input className="form-control cm_bold cm_box" type="number" placeholder="End year" name="endDate" value={endDate} onChange={this.handleChange} /> */}
                </div>
                <div
                  className={`form-group col-6 ${projectManagerNameErMsg ? "input_error" : ""
                    }`}
                >
                  <label className="sub">Project Manager</label>
                  <div className="sm_search">
                    <Select
                      showSearch
                      name="employeeId"
                      value={
                        projectManagerId
                          ? projectManagerId
                          : "Select by employee name"
                      }
                      onSearch={this.handleEmployeeSearch}
                      optionFilterProp="key"
                      filterOption={(input, option) =>
                        option.key.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={this.handleChangeAssignedToChange}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}{" "}
                                  {employee.empId}
                                </span>
                              </Option>
                            )
                        )
                        : ""}
                    </Select>
                  </div>

                  {projectManagerNameErMsg ? (
                    <span className="error_msg">{projectManagerNameErMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className={`form-group col-6`}>
                  <label className="sub">Account Manager</label>
                  <div className="sm_search">
                    <Select
                      showSearch
                      name="employeeId"
                      value={
                        accountManagerId
                          ? accountManagerId
                          : "Select by employee name"
                      }
                      onSearch={this.handleEmployeeSearch}
                      optionFilterProp="key"
                      filterOption={(input, option) =>
                        option.key.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={this.handleChangeAccountManager}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}{" "}
                                  {employee.empId}
                                </span>
                              </Option>
                            )
                        )
                        : ""}
                    </Select>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`form-group ${projectHoursErMsg ? "input_error" : ""
                      }`}
                  >
                    <label className="sub">Project Hours</label>
                    <input
                      maxLength="50"
                      className="form-control cm_box"
                      type="text"
                      placeholder="Project Hours"
                      name="projectHours"
                      value={projectHours ? projectHours : ""}
                      onChange={this.handleProjectHoursChange}
                    />
                    {projectHoursErMsg ? (
                      <span className="error_msg">{projectHoursErMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`form-group col-6 `}>
                  <label className="sub">Project Type</label>

                  <Select
                    style={{ width: "100%" }}
                    name="projectType"
                    placeholder="Project Type"
                    value={
                      projectType
                        ? projectTypeValue(projectType)
                        : "Project Type"
                    }
                    onChange={this.handleProjectType}
                  >
                    <Option value={1}>ManMonth</Option>
                    <Option value={2}>Hourly</Option>
                    <Option value={3}>Delivery Based</Option>
                  </Select>
                </div>

                <div
                  className={`form-group col-6 ${technologyErrorMessage ? "input_error" : ""
                    }`}
                >
                  <label className="sub">Technology</label>
                  <Select
                    mode="multiple"
                    placeholder="Select Technologies"
                    value={technology ? technology : "Select Technologies"}
                    onChange={this.handleTechnologyChange}
                    isSearchable={true}
                    optionFilterProp="key"
                    filterOption={(input, option) =>
                      option.key.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {!_.isEmpty(technologyList)
                      ? technologyList.map((technology, i) => (
                        <Option key={`${technology}`} value={i}>
                          <span>{technology}</span>
                        </Option>
                      ))
                      : ""}
                  </Select>

                  {technologyErrorMessage ? (
                    <span className="error_msg">{technologyErrorMessage}</span>
                  ) : null}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <div className="row">
                    <div
                      className={`form-group col-12 ${clientNameErrorMessage ? "input_error" : ""
                        }`}
                    >
                      <label className="sub">Client Name</label>
                      <input
                        maxLength="50"
                        className="form-control  cm_box"
                        type="text"
                        placeholder="Client Name"
                        name="clientName"
                        value={clientName}
                        onChange={this.handleChange}
                      />
                      {clientNameErrorMessage ? (
                        <span className="error_msg">
                          {clientNameErrorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div
                      className={`form-group col-12 ${clientNameErrorMessage ? "input_error" : ""
                        }`}
                    >
                      <label className="sub">Mail Id</label>
                      <input
                        maxLength="50"
                        className="form-control  cm_box"
                        type="text"
                        placeholder="Mail Id"
                        name="mailId"
                        value={mailId}
                        onChange={this.handleChange}
                      />
                      {clientMailErrorMeaasge ? (
                        <span className="error_msg">
                          {clientMailErrorMeaasge}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <label className="sub">Project Description</label>
                  <textarea
                    className="form-control  cm_box cm_descrip"
                    type="text"
                    placeholder="Project Description"
                    name="projectDescription"
                    maxLength={500}
                    value={projectDescription}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="col-6">
                  <label className="sub mt-4">Project Members</label>
                  <div className="form-group selectHeight">
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select employee"
                      value={this.state.assignedTo}
                      onChange={this.handleMembersChange}
                      filterOption={(input, option) =>
                        option.key.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}{" "}
                                  {employee.empId}
                                </span>
                              </Option>
                            )
                        )
                        : ""}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="submit_button_margin mt-4">
                <button type="submit" className="btn btn-primary cm_btn3">
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>

        <Modal
          size="md"
          show={openAddParticipentPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
          className="grant_access"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="text-center">
            <form className="grant_access">
              <h3 className="mb-4">ADD PARTICIPENT</h3>
              <h4 className="mb-3 cm_bold">Select Employee</h4>
              <div>
                <div className="sm_search">
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Select by employee name"
                    value={this.state.assignedTo}
                    onDeselect={(i) => this.handleOnDSelect(i)}
                  >
                    {employeeList.map((employee) => {
                      if (employee.status == 1)
                        return (
                          <Option
                            key={employee.employeeId}
                            onClick={() => this.handleOnSelect(employee)}
                          >
                            <span className="">{employee.email}</span>
                          </Option>
                        );
                    })}
                  </Select>
                </div>
              </div>
              <div className="text-center mt-5">
                <button
                  className="btn btn-primary  mr-2 btn-sm cm_btn3"
                  onClick={this.handleParticipant}
                >
                  Add
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          show={openCompleteProjectPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Body className="text-center confirmation">
            <p className="mb-4 cm_bold cnfrm_msg">
              Are you sure, that this project is Completed?
            </p>
            <div className="submit_button_margin mt-4">
              <button
                type="submit"
                onClick={this.handleComplete}
                className="btn btn-primary cm_btn3"
              >
                Yes
              </button>
              <button
                type="submit"
                onClick={this.closeModal}
                className="btn btn-primary cm_btn2"
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          show={openDeleteProjectPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Body className="text-center confirmation">
            <p className="mb-4 cm_bold cnfrm_msg">
              Are you sure, you want to delete this Project?
            </p>
            {miniLoader ? <div className="mini_loader_dept"></div> : ""}
            <div className="submit_button_margin mt-4">
              <button
                type="submit"
                onClick={this.handleDelete}
                className="btn btn-danger cm_btn3"
              >
                Delete
              </button>
              <button
                type="submit"
                onClick={this.closeModal}
                className="btn btn-primary cm_btn2"
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <ViewTemplateModal
          viewModal={viewModal}
          handleClose={this.closeModal}
        />
      </div>
    );
  }
}
export default ViewProject;

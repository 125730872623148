import {
    EDIT_CONTACT_REQUEST,
    EDIT_CONTACT_SUCCESS,
    EDIT_CONTACT_FAILURE,
} from "./actionType";

import { editContactBySalesService } from "../../config/services/employeeServices";
import { getContactList } from "./getContactList";

const editContactByAdmin = (data, query, setEditShowModal, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader) => {
    return (dispatch) => {
        dispatch(editContactByAdminRequest());
        editContactBySalesService(data)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(editContactByAdminFailure(res.data));
                    setLoader(false)
                    setFailResMsg(true)
                } else {
                    dispatch(editContactByAdminSuccess(res.data));
                    setResMsg(true)
                    setTimeout(() => (
                        dispatch(getContactList(query)),
                        handleCancelButtonClick()
                    ), 3000)
                }
            })
            .catch((error) => {
                dispatch(editContactByAdminFailure(error));
                setFailResMsg(true);
            });
    };
};

const editContactByAdminRequest = () => {
    return {
        type: EDIT_CONTACT_REQUEST,
    };
};
const editContactByAdminSuccess = (data) => {
    return {
        type: EDIT_CONTACT_SUCCESS,
        payload: data,
    };
};
const editContactByAdminFailure = (error) => {
    return {
        type: EDIT_CONTACT_FAILURE,
        payload: error,
    };
};
export {
    editContactByAdmin,
    editContactByAdminRequest,
    editContactByAdminSuccess,
    editContactByAdminFailure,
};

import React, { useReducer, useState } from "react";
import "../Privacy.css";
import _ from "lodash";
import ViewUploadedPdf from "../PrivacyModal/ViewUploadedPdf";
import { PDFReader } from "reactjs-pdf-reader-custom";
import { isAdmin, reducer, type } from "../../helper/Constant";
import AddEditPolicyModal from "../PrivacyModal/AddEditPolicyModal";
import "./PolicyCard.css";
import DeletePolicyModal from "../PrivacyModal/DeletePolicyModal";
import { Dropdown } from "react-bootstrap";

const initialState = {
  doc: "",
  openDropDown: false,
  openEditModal: false,
  privacyId: "",
  openDeleteModal: false,
  editTitle: "",
  docLink: "",
};

const PolicyCard = (props) => {
  const {
    getPolicyList,
    titleErrorMsg,
    uploadFile,
    uploadFileErrorMsg,
    title,
    handleChange,
    dispatchProps,
    policyList,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const {
    openViewDoc,
    doc,
    openEditModal,
    privacyId,
    openDeleteModal,
    editTitle,
    docLink,
  } = state;

  const handleViewDoc = (data) => {
    dispatch({
      type: type,
      payload: {
        doc: data,
        openViewDoc: true,
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        doc: "",
        openViewDoc: false,
        openEditModal: false,
        privacyId: "",
        openDeleteModal: false,
        editTitle: "",
        docLink: "",
      },
    });
    dispatchProps({
      type: type,
      payload: {
        uploadFile: "",
        uploadFileErrorMsg: "",
        title: "",
        titleErrorMsg: "",
        toAddOrEdit: "",
      },
    });
  };

  const handleOpenModal = (props) => {
    const { openModal, id, editTitle, docLink } = props;
    if (editTitle) {
      dispatchProps({
        type: type,
        payload: {
          title: editTitle,
        },
      });
    }
    dispatch({
      type: type,
      payload: {
        [openModal]: true,
        privacyId: id,
        editTitle: editTitle,
        docLink: docLink,
      },
    });
  };

  const handleDownload = (link) => {
    window.open(link);
  };

  // Sort policyList by title in ascending order
  const sortedPolicyList = policyList.sort((a, b) => {
    const titleA = a.title.charAt(0).toUpperCase();
    const titleB = b.title.charAt(0).toUpperCase();
    if (titleA < titleB) return -1;
    if (titleA > titleB) return 1;
    return 0;
  });

  return (
    <div className="row privacy_card_container">
      {!_.isEmpty(sortedPolicyList) &&
        sortedPolicyList.map((data, i) => (
          <div className="privacy_card position-relative" key={i}>
            <Dropdown className="privacy_dropdown_menu">
              <Dropdown.Toggle className="privacy_dropdown">
                <span>
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile_user">
                {(isAdmin === "4" || isAdmin === "1") && (
                  <>
                    <Dropdown.Item
                      onClick={() =>
                        handleOpenModal({
                          openModal: "openEditModal",
                          id: data._id,
                          editTitle: data.title,
                          docLink: data.docLink,
                        })
                      }
                    >
                      Update
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleOpenModal({
                          openModal: "openDeleteModal",
                          id: data._id,
                        })
                      }
                    >
                      Delete
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item onClick={() => handleDownload(data.docLink)}>
                  Download
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div
              className="d-flex position-relative flex-column privacy_img_container mb-5"
              onClick={() => handleViewDoc(data.docLink)}
            >
              {loading && (
                <div class="snippet" data-title="dot-elastic">
                  <div class="stage">
                    <div class="dot-elastic"></div>
                  </div>
                </div>
              )}
              <PDFReader
                url={data.docLink}
                page={1}
                showAllPage={false}
                width="240"
                onDocumentComplete={() => setLoading(false)}
              />
              <div className="pt-3 pl-3 privacy_card_title">
                <h5 className="card-title">
                  {data.title.charAt(0).toUpperCase() + data.title.slice(1)}
                </h5>
              </div>
            </div>
          </div>
        ))}
      <ViewUploadedPdf show={openViewDoc} doc={doc} closeModal={closeModal} />
      <AddEditPolicyModal
        openAddEditPrivacyModal={openEditModal}
        toAddOrEdit="Edit"
        closeModal={closeModal}
        title={title}
        titleErrorMsg={titleErrorMsg}
        handleChange={handleChange}
        dispatch={dispatchProps}
        uploadFile={uploadFile}
        uploadFileErrorMsg={uploadFileErrorMsg}
        getPolicyList={getPolicyList}
        privacyId={privacyId}
        editTitle={editTitle}
        docLink={docLink}
      />
      <DeletePolicyModal
        openDeleteModal={openDeleteModal}
        privacyId={privacyId}
        dispatch={dispatchProps}
        closeModal={closeModal}
        getPolicyList={getPolicyList}
      />
    </div>
  );
};

export default PolicyCard;

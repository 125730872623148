const ADD_CANDIDATE_BY_ADMIN_REQUEST = "ADD_CANDIDATE_BY_ADMIN_REQUEST";
const ADD_CANDIDATE_BY_ADMIN_SUCCESS = "ADD_CANDIDATE_BY_ADMIN_SUCCESS";
const ADD_CANDIDATE_BY_ADMIN_FAILURE = "ADD_CANDIDATE_BY_ADMIN_FAILURE";

const ADD_INTERVIEWER_BY_ADMIN_REQUEST = "ADD_INTERVIEWER_BY_ADMIN_REQUEST";
const ADD_INTERVIEWER_BY_ADMIN_SUCCESS = "ADD_INTERVIEWER_BY_ADMIN_SUCCESS";
const ADD_INTERVIEWER_BY_ADMIN_FAILURE = "ADD_INTERVIEWER_BY_ADMIN_FAILURE";

const EDIT_CANDIDATE_BY_ADMIN_REQUEST = "EDIT_CANDIDATE_BY_ADMIN_REQUEST";
const EDIT_CANDIDATE_BY_ADMIN_SUCCESS = "EDIT_CANDIDATE_BY_ADMIN_SUCCESS";
const EDIT_CANDIDATE_BY_ADMIN_FAILURE = "EDIT_CANDIDATE_BY_ADMIN_FAILURE";

const EDIT_INTERVIEWER_BY_ADMIN_REQUEST = "EDIT_INTERVIEWER_BY_ADMIN_REQUEST";
const EDIT_INTERVIEWER_BY_ADMIN_SUCCESS = "EDIT_INTERVIEWER_BY_ADMIN_SUCCESS";
const EDIT_INTERVIEWER_BY_ADMIN_FAILURE = "EDIT_INTERVIEWER_BY_ADMIN_FAILURE";

const DELETE_CANDIDATE_BY_ADMIN_REQUEST = "DELETE_CANDIDATE_BY_ADMIN_REQUEST";
const DELETE_CANDIDATE_BY_ADMIN_SUCCESS = "DELETE_CANDIDATE_BY_ADMIN_SUCCESS";
const DELETE_CANDIDATE_BY_ADMIN_FAILURE = "DELETE_CANDIDATE_BY_ADMIN_FAILURE";

const DELETE_INTERVIEWER_BY_ADMIN_REQUEST =
  "DELETE_INTERVIEWER_BY_ADMIN_REQUEST";
const DELETE_INTERVIEWER_BY_ADMIN_SUCCESS =
  "DELETE_INTERVIEWER_BY_ADMIN_SUCCESS";
const DELETE_INTERVIEWER_BY_ADMIN_FAILURE =
  "DELETE_INTERVIEWER_BY_ADMIN_FAILURE";

const ADD_EMP_RESUME_REQUEST = "ADD_EMP_RESUME_REQUEST";
const ADD_EMP_RESUME_SUCCESS = "ADD_EMP_RESUME_SUCCESS";
const ADD_EMP_RESUME_FAILURE = "ADD_EMP_RESUME_FAILURE";

const UPDATE_EMP_RESUME_REQUEST = "UPDATE_EMP_RESUME_REQUEST";
const UPDATE_EMP_RESUME_SUCCESS = "UPDATE_EMP_RESUME_SUCCESS";
const UPDATE_EMP_RESUME_FAILURE = "UPDATE_EMP_RESUME_FAILURE";

const ADD_EMP_RESUME_BY_ADMIN_REQUEST = "ADD_EMP_RESUME_BY_ADMIN_REQUEST";
const ADD_EMP_RESUME_BY_ADMIN_SUCCESS = "ADD_EMP_RESUME_BY_ADMIN_SUCCESS";
const ADD_EMP_RESUME_BY_ADMIN_FAILURE = "ADD_EMP_RESUME_BY_ADMIN_FAILURE";

const UPDATE_EMP_RESUME_BY_ADMIN_REQUEST = "UPDATE_EMP_RESUME_BY_ADMIN_REQUEST";
const UPDATE_EMP_RESUME_BY_ADMIN_SUCCESS = "UPDATE_EMP_RESUME_BY_ADMIN_SUCCESS";
const UPDATE_EMP_RESUME_BY_ADMIN_FAILURE = "UPDATE_EMP_RESUME_BY_ADMIN_FAILURE";

const DELETE_EMPLOYEE_RESUME_REQUEST = "DELETE_EMPLOYEE_RESUME_REQUEST";
const DELETE_EMPLOYEE_RESUME_SUCCESS = "DELETE_EMPLOYEE_RESUME_SUCCESS";
const DELETE_EMPLOYEE_RESUME_FAILURE = "DELETE_EMPLOYEE_RESUME_FAILURE";

const GET_CANDIDATE_BY_ID_REQUEST = "GET_CANDIDATE_BY_ID_REQUEST";
const GET_CANDIDATE_BY_ID_SUCCESS = "GET_CANDIDATE_BY_ID_SUCCESS";
const GET_CANDIDATE_BY_ID_FAILURE = "GET_CANDIDATE_BY_ID_FAILURE";

const GET_EMPLOYEE_LIST_REQUEST = "GET_EMPLOYEE_LIST_REQUEST";
const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS";
const GET_EMPLOYEE_LIST_FAILURE = "GET_EMPLOYEE_LIST_FAILURE";

const GET_EMPLOYEE_RESUME_LIST_REQUEST = "GET_EMPLOYEE_RESUME_LIST_REQUEST";
const GET_EMPLOYEE_RESUME_LIST_SUCCESS = "GET_EMPLOYEE_RESUME_LIST_SUCCESS";
const GET_EMPLOYEE_RESUME_LIST_FAILURE = "GET_EMPLOYEE_RESUME_LIST_FAILURE";

const GET_RESUME_BY_EMP_ID_REQUEST = "GET_RESUME_BY_EMP_ID_REQUEST";
const GET_RESUME_BY_EMP_ID_SUCCESS = "GET_RESUME_BY_EMP_ID_SUCCESS";
const GET_RESUME_BY_EMP_ID_FAILURE = "GET_RESUME_BY_EMP_ID_FAILURE";

const UPDATE_RESUME_BY_EMP_ID_REQUEST = "UPDATE_RESUME_BY_EMP_ID_REQUEST";
const UPDATE_RESUME_BY_EMP_ID_SUCCESS = "UPDATE_RESUME_BY_EMP_ID_SUCCESS";
const UPDATE_RESUME_BY_EMP_ID_FAILURE = "UPDATE_RESUME_BY_EMP_ID_FAILURE";

const GET_ORDERS_LIST_REQUEST = "GET_ORDERS_LIST_REQUEST";
const GET_ORDERS_LIST_SUCCESS = "GET_ORDERS_LIST_SUCCESS";
const GET_ORDERS_LIST_FAILURE = "GET_ORDERS_LIST_FAILURE";

const GET_TRANSACTION_LIST_REQUEST = "GET_TRANSACTION_LIST_REQUEST";
const GET_TRANSACTION_LIST_SUCCESS = "GET_TRANSACTION_LIST_SUCCESS";
const GET_TRANSACTION_LIST_FAILURE = "GET_TRANSACTION_LIST_FAILURE";

const GET_TRANSACTION_EXCEL_REQUEST = "GET_TRANSACTION_EXCEL_REQUEST";
const GET_TRANSACTION_EXCEL_SUCCESS = "GET_TRANSACTION_EXCEL_SUCCESS";
const GET_TRANSACTION_EXCEL_FAILURE = "GET_TRANSACTION_EXCEL_FAILURE";

const GET_REIMBURSEMENT_LIST_REQUEST = "GET_REIMBURSEMENT_LIST_REQUEST";
const GET_REIMBURSEMENT_LIST_SUCCESS = "GET_REIMBURSEMENT_LIST_SUCCESS";
const GET_REIMBURSEMENT_LIST_FAILURE = "GET_REIMBURSEMENT_LIST_FAILURE";

const GET_VENDORS_LIST_REQUEST = "GET_VENDORS_LIST_REQUEST";
const GET_VENDORS_LIST_SUCCESS = "GET_VENDORS_LIST_SUCCESS";
const GET_VENDORS_LIST_FAILURE = "GET_VENDORS_LIST_FAILURE";

const GET_INTERVIEW_LIST_REQUEST = "GET_INTERVIEW_LIST_REQUEST";
const GET_INTERVIEW_LIST_SUCCESS = "GET_INTERVIEW_LIST_SUCCESS";
const GET_INTERVIEW_LIST_FAILURE = "GET_TRANSACTION_LIST_FAILURE";

const GET_INTERVIEWER_LIST_REQUEST = "GET_INTERVIEWER_LIST_REQUEST";
const GET_INTERVIEWER_LIST_SUCCESS = "GET_INTERVIEWER_LIST_SUCCESS";
const GET_INTERVIEWER_LIST_FAILURE = "GET_INTERVIEWER_LIST_FAILURE";

const SET_ADMIN_REIMBURSEMENT_YEAR_SUCCESS =
  "SET_ADMIN_REIMBURSEMENT_YEAR_SUCCESS";

const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

const GET_EMP_PROFILE_BY_ADMIN_REQUEST = "GET_EMP_PROFILE_BY_ADMIN_REQUEST";
const GET_EMP_PROFILE_BY_ADMIN_SUCCESS = "GET_EMP_PROFILE_BY_ADMIN_SUCCESS";
const GET_EMP_PROFILE_BY_ADMIN_FAILURE = "GET_EMP_PROFILE_BY_ADMIN_FAILURE";

const UPLOAD_ATTENDANCE_REQUEST = "UPLOAD_ATTENDANCE_REQUEST";
const UPLOAD_ATTENDANCE_SUCCESS = "UPLOAD_ATTENDANCE_SUCCESS";
const UPLOAD_ATTENDANCE_FAILURE = "UPLOAD_ATTENDANCE_FAILURE";

const GET_ATTENDANCE_LIST_BY_ADMIN_REQUEST =
  "GET_ATTENDANCE_LIST_BY_ADMIN_REQUEST";
const GET_ATTENDANCE_LIST_BY_ADMIN_SUCCESS =
  "GET_ATTENDANCE_LIST_BY_ADMIN_SUCCESS";
const GET_ATTENDANCE_LIST_BY_ADMIN_FAILURE =
  "GET_ATTENDANCE_LIST_BY_ADMIN_FAILURE";

const GET_LEAVE_LIST_BY_ADMIN_REQUEST = "GET_LEAVE_LIST_BY_ADMIN_REQUEST";
const GET_LEAVE_LIST_BY_ADMIN_SUCCESS = "GET_LEAVE_LIST_BY_ADMIN_SUCCESS";
const GET_LEAVE_LIST_BY_ADMIN_FAILURE = "GET_LEAVE_LIST_BY_ADMIN_FAILURE";

const EDIT_ATTENDANCE_BY_ADMIN_REQUEST = "EDIT_ATTENDANCE_BY_ADMIN_REQUEST";
const EDIT_ATTENDANCE_BY_ADMIN_SUCCESS = "EDIT_ATTENDANCE_BY_ADMIN_SUCCESS";
const EDIT_ATTENDANCE_BY_ADMIN_FAILURE = "EDIT_ATTENDANCE_BY_ADMIN_FAILURE";

const EDIT_TEMP_COMP_OFF_REQUEST = "EDIT_TEMP_COMP_OFF_REQUEST";
const EDIT_TEMP_COMP_OFF_SUCCESS = "EDIT_TEMP_COMP_OFF_SUCCESS";
const EDIT_TEMP_COMP_OFF_FAILURE = "EDIT_TEMP_COMP_OFF_FAILURE";

const EDIT_COMP_OFF_BY_ADMIN_REQUEST = "EDIT_COMP_OFF_BY_ADMIN_REQUEST";
const EDIT_COMP_OFF_BY_ADMIN_SUCCESS = "EDIT_COMP_OFF_BY_ADMIN_SUCCESS";
const EDIT_COMP_OFF_BY_ADMIN_FAILURE = "EDIT_COMP_OFF_BY_ADMIN_FAILURE";

const ADD_ATTENDANCE_BY_ADMIN_REQUEST = "ADD_ATTENDANCE_BY_ADMIN_REQUEST";
const ADD_ATTENDANCE_BY_ADMIN_SUCCESS = "ADD_ATTENDANCE_BY_ADMIN_SUCCESS";
const ADD_ATTENDANCE_BY_ADMIN_FAILURE = "ADD_ATTENDANCE_BY_ADMIN_FAILURE";

const EDIT_INTERVIEWER_STATUS_REQUEST = "EDIT_INTERVIEWER_STATUS_REQUEST";
const EDIT_INTERVIEWER_STATUS_SUCCESS = "EDIT_INTERVIEWER_STATUS_SUCCESS";
const EDIT_INTERVIEWER_STATUS_FAILURE = "EDIT_INTERVIEWER_STATUS_FAILURE";

const GET_CONTACT_LIST_REQUEST = "GET_CONTACT_LIST_REQUEST";
const GET_CONTACT_LIST_SUCCESS = "GET_CONTACT_LIST_SUCCESS";
const GET_CONTACT_LIST_FAILURE = "GET_CONTACT_LIST_FAILURE";

const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";

const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

const EDIT_CONTACT_REQUEST = "EDIT_CONTACT_REQUEST";
const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
const EDIT_CONTACT_FAILURE = "EDIT_CONTACT_FAILURE";

const ASSIGN_INTERVIEW_CANDIDATE_REQUEST = "ASSIGN_INTERVIEW_CANDIDATE_REQUEST";
const ASSIGN_INTERVIEW_CANDIDATE_SUCCESS = "ASSIGN_INTERVIEW_CANDIDATE_SUCCESS";
const ASSIGN_INTERVIEW_CANDIDATE_FAILURE = "ASSIGN_INTERVIEW_CANDIDATE_FAILURE";

const ASSIGN_INTERVIEW_REQUEST = "ASSIGN_INTERVIEW_REQUEST";
const ASSIGN_INTERVIEW_SUCCESS = "ASSIGN_INTERVIEW_SUCCESS";
const ASSIGN_INTERVIEW_FAILURE = "ASSIGN_INTERVIEW_FAILURE";

const EDIT_ASSIGN_CANDIDATE_REQUEST = "EDIT_ASSIGN_CANDIDATE_REQUEST";
const EDIT_ASSIGN_CANDIDATE_SUCCESS = "EDIT_ASSIGN_CANDIDATE_SUCCESS";
const EDIT_ASSIGN_CANDIDATE_FAILURE = "EDIT_ASSIGN_CANDIDATE_FAILURE";

const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST";
const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
const ADD_TEAM_FAILURE = "ADD_TEAM_FAILURE";

const EDIT_TEAM_REQUEST = "EDIT_TEAM_REQUEST";
const EDIT_TEAM_SUCCESS = "EDIT_TEAM_SUCCESS";
const EDIT_TEAM_FAILURE = "EDIT_TEAM_FAILURE";

const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
const GET_TEAM_FAILURE = "GET_TEAM_FAILURE";

const GET_TEAM_MEMBERS_REQUEST = "GET_TEAM_MEMBERS_REQUEST";
const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS";
const GET_TEAM_MEMBERS_FAILURE = "GET_TEAM_MEMBERS_FAILURE";

const GET_TEAM_MEMBERS_DETAILS_REQUEST = "GET_TEAM_MEMBERS_DETAILS_REQUEST";
const GET_TEAM_MEMBERS_DETAILS_SUCCESS = "GET_TEAM_MEMBERS_DETAILS_SUCCESS";
const GET_TEAM_MEMBERS_DETAILS_FAILURE = "GET_TEAM_MEMBERS_DETAILS_FAILURE";

const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";

const DELETE_TEAM_MEMBERS_REQUEST = "DELETE_TEAM_MEMBERS_REQUEST";
const DELETE_TEAM_MEMBERS_SUCCESS = "DELETE_TEAM_MEMBERS_SUCCESS";
const DELETE_TEAM_MEMBERS_FAILURE = "DELETE_TEAM_MEMBERS_FAILURE";

const ADD_TEAM_MEMBERS_REQUEST = "ADD_TEAM_MEMBERS_REQUEST";
const ADD_TEAM_MEMBERS_SUCCESS = "ADD_TEAM_MEMBERS_SUCCESS";
const ADD_TEAM_MEMBERS_FAILURE = "ADD_TEAM_MEMBERS_FAILURE";

const ADD_CANDIDATE_FEEDBACK_REQUEST = "ADD_CANDIDATE_FEEDBACK_REQUEST";
const ADD_CANDIDATE_FEEDBACK_SUCCESS = "ADD_CANDIDATE_FEEDBACK_SUCCESS";
const ADD_CANDIDATE_FEEDBACK_FAILURE = "ADD_CANDIDATE_FEEDBACK_FAILURE";

const EDIT_CANDIDATE_FEEDBACK_REQUEST = "EDIT_CANDIDATE_FEEDBACK_REQUEST";
const EDIT_CANDIDATE_FEEDBACK_SUCCESS = "EDIT_CANDIDATE_FEEDBACK_SUCCESS";
const EDIT_CANDIDATE_FEEDBACK_FAILURE = "EDIT_CANDIDATE_FEEDBACK_FAILURE";

const GET_CANDIDATE_FEEDBACK_REQUEST = "GET_CANDIDATE_FEEDBACK_REQUEST";
const GET_CANDIDATE_FEEDBACK_SUCCESS = "GET_CANDIDATE_FEEDBACK_SUCCESS";
const GET_CANDIDATE_FEEDBACK_FAILURE = "GET_CANDIDATE_FEEDBACK_FAILURE";

const DELETE_CANDIDATE_FEEDBACK_REQUEST = "DELETE_CANDIDATE_FEEDBACK_REQUEST";
const DELETE_CANDIDATE_FEEDBACK_SUCCESS = "DELETE_CANDIDATE_FEEDBACK_SUCCESS";
const DELETE_CANDIDATE_FEEDBACK_FAILURE = "DELETE_CANDIDATE_FEEDBACK_FAILURE";

const GET_EMPLOYEE_CTC_REQUEST = "GET_EMPLOYEE_CTC_REQUEST";
const GET_EMPLOYEE_CTC_SUCCESS = "GET_EMPLOYEE_CTC_SUCCESS";
const GET_EMPLOYEE_CTC_FAILURE = "GET_EMPLOYEE_CTC_FAILURE";

const GET_EMPLOYEE_ON_HOLD_REQUEST = "GET_EMPLOYEE_ON_HOLD_REQUEST";
const GET_EMPLOYEE_ON_HOLD_SUCCESS = "GET_EMPLOYEE_ON_HOLD_SUCCESS";
const GET_EMPLOYEE_ON_HOLD_FAILURE = "GET_EMPLOYEE_ON_HOLD_FAILURE";

const ADD_EMPLOYEE_ON_HOLD_REQUEST = "GET_EMPLOYEE_ON_HOLD_REQUEST";
const ADD_EMPLOYEE_ON_HOLD_SUCCESS = "GET_EMPLOYEE_ON_HOLD_SUCCESS";
const ADD_EMPLOYEE_ON_HOLD_FAILURE = "GET_EMPLOYEE_ON_HOLD_FAILURE";

const EDIT_EMPLOYEE_ON_HOLD_REQUEST = "EDIT_EMPLOYEE_ON_HOLD_REQUEST";
const EDIT_EMPLOYEE_ON_HOLD_SUCCESS = "EDIT_EMPLOYEE_ON_HOLD_SUCCESS";
const EDIT_EMPLOYEE_ON_HOLD_FAILURE = "EDIT_EMPLOYEE_ON_HOLD_FAILURE";

const GET_EMPLOYEE_MONTHLY_SALARY_STATS_REQUEST =
  "GET_EMPLOYEE_MONTHLY_SALARY_STATS_REQUEST";
const GET_EMPLOYEE_MONTHLY_SALARY_STATS_SUCCESS =
  "GET_EMPLOYEE_MONTHLY_SALARY_STATS_SUCCESS";
const GET_EMPLOYEE_MONTHLY_SALARY_STATS_FAILURE =
  "GET_EMPLOYEE_MONTHLY_SALARY_STATS_FAILURE";

const ADD_EMPLOYEE_CTC_REQUEST = "ADD_EMPLOYEE_CTC_REQUEST";
const ADD_EMPLOYEE_CTC_SUCCESS = "ADD_EMPLOYEE_CTC_SECCESS";
const ADD_EMPLOYEE_CTC_FAILURE = "ADD_EMPLOYEE_CTC_FAILURE";

const EDIT_EMPLOYEE_CTC_REQUEST = "EDIT_EMPLOYEE_CTC_REQUEST";
const EDIT_EMPLOYEE_CTC_SUCCESS = "EDIT_EMPLOYEE_CTC_SECCESS";
const EDIT_EMPLOYEE_CTC_FAILURE = "EDIT_EMPLOYEE_CTC_FAILURE";

const EXPORT_EMPLOYEES_SALARY_REQUEST = "EXPORT_EMPLOYEES_SALARY_REQUEST";
const EXPORT_EMPLOYEES_SALARY_SUCCESS = "EXPORT_EMPLOYEES_SALARY_SUCCESS";
const EXPORT_EMPLOYEES_SALARY_FAILURE = "EXPORT_EMPLOYEES_SALARY_FAILURE";

const EXPORT_EMPLOYEES_REIMBURSEMENT_REQUEST =
  "EXPORT_EMPLOYEES_REIMBURSEMENT_REQUEST";
const EXPORT_EMPLOYEES_REIMBURSEMENT_SUCCESS =
  "EXPORT_EMPLOYEES_REIMBURSEMENT_SUCCESS";
const EXPORT_EMPLOYEES_REIMBURSEMENT_FAILURE =
  "EXPORT_EMPLOYEES_REIMBURSEMENT_FAILURE";

const GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_REQUEST =
  "GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_REQUEST";
const GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_SUCCESS =
  "GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_SUCCESS";
const GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_FAILURE =
  "GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_FAILURE";

const GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_REQUEST =
  "GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_REQUEST";
const GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_SUCCESS =
  "GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_SUCCESS";
const GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_FAILURE =
  "GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_FAILURE";

const GET_AWARD_REQUEST = "GET_AWARD_REQUEST";
const GET_AWARD_SUCCESS = "GET_AWARD_SUCCESS";
const GET_AWARD_FAILURE = "GET_AWARD_FAILURE";

const ADD_AWARD_REQUEST = "ADD_AWARD_REQUEST";
const ADD_AWARD_SUCCESS = "ADD_AWARD_SUCCESS";
const ADD_AWARD_FAILURE = "ADD_AWARD_FAILURE";

const EDIT_AWARD_REQUEST = "EDIT_AWARD_REQUEST";
const EDIT_AWARD_SUCCESS = "EDIT_AWARD_SUCCESS";
const EDIT_AWARD_FAILURE = "EDIT_AWARD_FAILURE";

const DELETE_AWARD_REQUEST = "DELETE_AWARD_REQUEST";
const DELETE_AWARD_SUCCESS = "DELETE_AWARD_SUCCESS";
const DELETE_AWARD_FAILURE = "DELETE_AWARD_FAILURE";

const GET_TEAM_COMPARE_DATA_REQUEST = "GET_TEAM_COMPARE_DATA_REQUEST";
const GET_TEAM_COMPARE_DATA_SUCCESS = "GET_TEAM_COMPARE_DATA_SUCCESS";
const GET_TEAM_COMPARE_DATA_FAILURE = "GET_TEAM_COMPARE_DATA_FAILURE";

const GET_OTP_REQUEST = "GET_OTP_REQUEST";
const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
const GET_OTP_FAILURE = "GET_OTP_FAILURE";

const VALIDATE_OTP_REQUEST = "VALIADTE_OTP_REQUEST";
const VALIDATE_OTP_SUCCESS = "VALIADTE_OTP_SUCCESS";
const VALIDATE_OTP_FAILURE = "VALIADTE_OTP_FAILURE";

const EDIT_ON_HOLD_RELEASE_DATE_REQUEST = "EDIT_ON_HOLD_RELEASE_DATE_REQUEST";
const EDIT_ON_HOLD_RELEASE_DATE_SUCCESS = "EDIT_ON_HOLD_RELEASE_DATE_SUCCESS";
const EDIT_ON_HOLD_RELEASE_DATE_FAILURE = "EDIT_ON_HOLD_RELEASE_DATE_FAILURE";

const GET_COURIER_BY_ADMIN_REQUEST = "GET_COURIER_BY_ADMIN_REQUEST";
const GET_COURIER_BY_ADMIN_SUCCESS = "GET_COURIER_BY_ADMIN_SUCCESS";
const GET_COURIER_BY_ADMIN_FAILURE = "GET_COURIER_BY_ADMIN_FAILURE";

const ADD_COURIER_BY_ADMIN_REQUEST = "ADD_COURIER_BY_ADMIN_REQUEST";
const ADD_COURIER_BY_ADMIN_SUCCESS = "ADD_COURIER_BY_ADMIN_SUCCESS";
const ADD_COURIER_BY_ADMIN_FAILURE = "ADD_COURIER_BY_ADMIN_FAILURE";

const EDIT_COURIER_BY_ADMIN_REQUEST = "EDIT_COURIER_BY_ADMIN_REQUEST";
const EDIT_COURIER_BY_ADMIN_SUCCESS = "EDIT_COURIER_BY_ADMIN_SUCCESS";
const EDIT_COURIER_BY_ADMIN_FAILURE = "EDIT_COURIER_BY_ADMIN_FAILURE";

const DELETE_COURIER_BY_ADMIN_REQUEST = "DELETE_COURIER_BY_ADMIN_REQUEST";
const DELETE_COURIER_BY_ADMIN_SUCCESS = "DELETE_COURIER_BY_ADMIN_SUCCESS";
const DELETE_COURIER_BY_ADMIN_FAILURE = "DELETE_COURIER_BY_ADMIN_FAILURE";

export {
  ADD_EMP_RESUME_BY_ADMIN_REQUEST,
  ADD_EMP_RESUME_BY_ADMIN_SUCCESS,
  ADD_EMP_RESUME_BY_ADMIN_FAILURE,
  // ********************************************** //
  UPDATE_EMP_RESUME_BY_ADMIN_REQUEST,
  UPDATE_EMP_RESUME_BY_ADMIN_SUCCESS,
  UPDATE_EMP_RESUME_BY_ADMIN_FAILURE,
  // ********************************************** //
  ADD_EMP_RESUME_REQUEST,
  ADD_EMP_RESUME_SUCCESS,
  ADD_EMP_RESUME_FAILURE,
  // ********************************************** //
  UPDATE_EMP_RESUME_REQUEST,
  UPDATE_EMP_RESUME_SUCCESS,
  UPDATE_EMP_RESUME_FAILURE,
  UPDATE_RESUME_BY_EMP_ID_REQUEST,
  UPDATE_RESUME_BY_EMP_ID_SUCCESS,
  UPDATE_RESUME_BY_EMP_ID_FAILURE,
  GET_EMP_PROFILE_BY_ADMIN_REQUEST,
  GET_EMP_PROFILE_BY_ADMIN_SUCCESS,
  GET_EMP_PROFILE_BY_ADMIN_FAILURE,
  UPLOAD_ATTENDANCE_REQUEST,
  UPLOAD_ATTENDANCE_SUCCESS,
  UPLOAD_ATTENDANCE_FAILURE,
  GET_ATTENDANCE_LIST_BY_ADMIN_REQUEST,
  GET_ATTENDANCE_LIST_BY_ADMIN_SUCCESS,
  GET_ATTENDANCE_LIST_BY_ADMIN_FAILURE,
  GET_LEAVE_LIST_BY_ADMIN_REQUEST,
  GET_LEAVE_LIST_BY_ADMIN_SUCCESS,
  GET_LEAVE_LIST_BY_ADMIN_FAILURE,
  EDIT_ATTENDANCE_BY_ADMIN_REQUEST,
  EDIT_ATTENDANCE_BY_ADMIN_SUCCESS,
  EDIT_ATTENDANCE_BY_ADMIN_FAILURE,
  ADD_ATTENDANCE_BY_ADMIN_REQUEST,
  ADD_ATTENDANCE_BY_ADMIN_SUCCESS,
  ADD_ATTENDANCE_BY_ADMIN_FAILURE,

  // ********************************************** //
  DELETE_EMPLOYEE_RESUME_REQUEST,
  DELETE_EMPLOYEE_RESUME_SUCCESS,
  DELETE_EMPLOYEE_RESUME_FAILURE,
  GET_CANDIDATE_BY_ID_REQUEST,
  GET_CANDIDATE_BY_ID_SUCCESS,
  GET_CANDIDATE_BY_ID_FAILURE,
  GET_EMPLOYEE_LIST_REQUEST,
  GET_EMPLOYEE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_FAILURE,
  GET_EMPLOYEE_RESUME_LIST_REQUEST,
  GET_EMPLOYEE_RESUME_LIST_SUCCESS,
  GET_EMPLOYEE_RESUME_LIST_FAILURE,
  GET_RESUME_BY_EMP_ID_REQUEST,
  GET_RESUME_BY_EMP_ID_SUCCESS,
  GET_RESUME_BY_EMP_ID_FAILURE,
  GET_ORDERS_LIST_REQUEST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  GET_TRANSACTION_LIST_REQUEST,
  GET_TRANSACTION_LIST_SUCCESS,
  GET_TRANSACTION_LIST_FAILURE,
  GET_VENDORS_LIST_REQUEST,
  GET_VENDORS_LIST_SUCCESS,
  GET_VENDORS_LIST_FAILURE,
  SET_ADMIN_REIMBURSEMENT_YEAR_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  GET_INTERVIEW_LIST_REQUEST,
  GET_INTERVIEW_LIST_SUCCESS,
  GET_INTERVIEW_LIST_FAILURE,
  GET_INTERVIEWER_LIST_REQUEST,
  GET_INTERVIEWER_LIST_SUCCESS,
  GET_INTERVIEWER_LIST_FAILURE,
  ADD_CANDIDATE_BY_ADMIN_REQUEST,
  ADD_CANDIDATE_BY_ADMIN_SUCCESS,
  ADD_CANDIDATE_BY_ADMIN_FAILURE,
  ADD_INTERVIEWER_BY_ADMIN_REQUEST,
  ADD_INTERVIEWER_BY_ADMIN_SUCCESS,
  ADD_INTERVIEWER_BY_ADMIN_FAILURE,
  GET_TRANSACTION_EXCEL_REQUEST,
  GET_TRANSACTION_EXCEL_SUCCESS,
  GET_TRANSACTION_EXCEL_FAILURE,
  GET_REIMBURSEMENT_LIST_REQUEST,
  GET_REIMBURSEMENT_LIST_SUCCESS,
  GET_REIMBURSEMENT_LIST_FAILURE,
  DELETE_CANDIDATE_BY_ADMIN_REQUEST,
  DELETE_CANDIDATE_BY_ADMIN_SUCCESS,
  DELETE_CANDIDATE_BY_ADMIN_FAILURE,
  DELETE_INTERVIEWER_BY_ADMIN_REQUEST,
  DELETE_INTERVIEWER_BY_ADMIN_SUCCESS,
  DELETE_INTERVIEWER_BY_ADMIN_FAILURE,
  EDIT_CANDIDATE_BY_ADMIN_REQUEST,
  EDIT_CANDIDATE_BY_ADMIN_SUCCESS,
  EDIT_CANDIDATE_BY_ADMIN_FAILURE,
  EDIT_INTERVIEWER_BY_ADMIN_REQUEST,
  EDIT_INTERVIEWER_BY_ADMIN_SUCCESS,
  EDIT_INTERVIEWER_BY_ADMIN_FAILURE,
  EDIT_INTERVIEWER_STATUS_REQUEST,
  EDIT_INTERVIEWER_STATUS_SUCCESS,
  EDIT_INTERVIEWER_STATUS_FAILURE,
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  GET_CONTACT_LIST_FAILURE,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
  EDIT_CONTACT_REQUEST,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
  ASSIGN_INTERVIEW_CANDIDATE_REQUEST,
  ASSIGN_INTERVIEW_CANDIDATE_SUCCESS,
  ASSIGN_INTERVIEW_CANDIDATE_FAILURE,
  ASSIGN_INTERVIEW_REQUEST,
  ASSIGN_INTERVIEW_SUCCESS,
  ASSIGN_INTERVIEW_FAILURE,
  EDIT_ASSIGN_CANDIDATE_REQUEST,
  EDIT_ASSIGN_CANDIDATE_SUCCESS,
  EDIT_ASSIGN_CANDIDATE_FAILURE,
  ADD_TEAM_FAILURE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  DELETE_CONTACT_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  EDIT_TEAM_FAILURE,
  EDIT_TEAM_REQUEST,
  EDIT_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
  GET_TEAM_MEMBERS_REQUEST,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_DETAILS_FAILURE,
  GET_TEAM_MEMBERS_DETAILS_REQUEST,
  GET_TEAM_MEMBERS_DETAILS_SUCCESS,
  DELETE_TEAM_MEMBERS_FAILURE,
  DELETE_TEAM_MEMBERS_REQUEST,
  DELETE_TEAM_MEMBERS_SUCCESS,
  ADD_TEAM_MEMBERS_FAILURE,
  ADD_TEAM_MEMBERS_REQUEST,
  ADD_TEAM_MEMBERS_SUCCESS,
  ADD_CANDIDATE_FEEDBACK_FAILURE,
  ADD_CANDIDATE_FEEDBACK_REQUEST,
  ADD_CANDIDATE_FEEDBACK_SUCCESS,
  EDIT_CANDIDATE_FEEDBACK_FAILURE,
  EDIT_CANDIDATE_FEEDBACK_REQUEST,
  EDIT_CANDIDATE_FEEDBACK_SUCCESS,
  GET_CANDIDATE_FEEDBACK_FAILURE,
  GET_CANDIDATE_FEEDBACK_REQUEST,
  GET_CANDIDATE_FEEDBACK_SUCCESS,
  DELETE_CANDIDATE_FEEDBACK_FAILURE,
  DELETE_CANDIDATE_FEEDBACK_REQUEST,
  DELETE_CANDIDATE_FEEDBACK_SUCCESS,
  GET_EMPLOYEE_CTC_REQUEST,
  GET_EMPLOYEE_CTC_SUCCESS,
  GET_EMPLOYEE_CTC_FAILURE,
  ADD_EMPLOYEE_CTC_REQUEST,
  ADD_EMPLOYEE_CTC_SUCCESS,
  ADD_EMPLOYEE_CTC_FAILURE,
  EDIT_EMPLOYEE_CTC_REQUEST,
  EDIT_EMPLOYEE_CTC_SUCCESS,
  EDIT_EMPLOYEE_CTC_FAILURE,
  EXPORT_EMPLOYEES_SALARY_REQUEST,
  EXPORT_EMPLOYEES_SALARY_SUCCESS,
  EXPORT_EMPLOYEES_SALARY_FAILURE,
  EXPORT_EMPLOYEES_REIMBURSEMENT_REQUEST,
  EXPORT_EMPLOYEES_REIMBURSEMENT_SUCCESS,
  EXPORT_EMPLOYEES_REIMBURSEMENT_FAILURE,
  GET_EMPLOYEE_MONTHLY_SALARY_STATS_REQUEST,
  GET_EMPLOYEE_MONTHLY_SALARY_STATS_SUCCESS,
  GET_EMPLOYEE_MONTHLY_SALARY_STATS_FAILURE,
  GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_REQUEST,
  GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_SUCCESS,
  GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_FAILURE,
  GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_REQUEST,
  GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_SUCCESS,
  GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID_FAILURE,
  GET_AWARD_REQUEST,
  GET_AWARD_SUCCESS,
  GET_AWARD_FAILURE,
  EDIT_AWARD_REQUEST,
  EDIT_AWARD_SUCCESS,
  EDIT_AWARD_FAILURE,
  ADD_AWARD_REQUEST,
  ADD_AWARD_SUCCESS,
  ADD_AWARD_FAILURE,
  DELETE_AWARD_REQUEST,
  DELETE_AWARD_SUCCESS,
  DELETE_AWARD_FAILURE,
  GET_TEAM_COMPARE_DATA_REQUEST,
  GET_TEAM_COMPARE_DATA_SUCCESS,
  GET_TEAM_COMPARE_DATA_FAILURE,
  GET_EMPLOYEE_ON_HOLD_REQUEST,
  GET_EMPLOYEE_ON_HOLD_SUCCESS,
  GET_EMPLOYEE_ON_HOLD_FAILURE,
  ADD_EMPLOYEE_ON_HOLD_REQUEST,
  ADD_EMPLOYEE_ON_HOLD_SUCCESS,
  ADD_EMPLOYEE_ON_HOLD_FAILURE,
  EDIT_EMPLOYEE_ON_HOLD_REQUEST,
  EDIT_EMPLOYEE_ON_HOLD_SUCCESS,
  EDIT_EMPLOYEE_ON_HOLD_FAILURE,
  GET_OTP_REQUEST,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILURE,
  EDIT_TEMP_COMP_OFF_REQUEST,
  EDIT_TEMP_COMP_OFF_SUCCESS,
  EDIT_TEMP_COMP_OFF_FAILURE,
  EDIT_COMP_OFF_BY_ADMIN_REQUEST,
  EDIT_COMP_OFF_BY_ADMIN_SUCCESS,
  EDIT_COMP_OFF_BY_ADMIN_FAILURE,
  EDIT_ON_HOLD_RELEASE_DATE_REQUEST,
  EDIT_ON_HOLD_RELEASE_DATE_SUCCESS,
  EDIT_ON_HOLD_RELEASE_DATE_FAILURE,
  GET_COURIER_BY_ADMIN_REQUEST,
  GET_COURIER_BY_ADMIN_SUCCESS,
  GET_COURIER_BY_ADMIN_FAILURE,
  ADD_COURIER_BY_ADMIN_REQUEST,
  ADD_COURIER_BY_ADMIN_SUCCESS,
  ADD_COURIER_BY_ADMIN_FAILURE,
  EDIT_COURIER_BY_ADMIN_REQUEST,
  EDIT_COURIER_BY_ADMIN_SUCCESS,
  EDIT_COURIER_BY_ADMIN_FAILURE,
  DELETE_COURIER_BY_ADMIN_REQUEST,
  DELETE_COURIER_BY_ADMIN_SUCCESS,
  DELETE_COURIER_BY_ADMIN_FAILURE,
};

import _ from 'lodash'
import React from 'react'
import { awardType } from './constants'
import { arrOfMonths } from '../leaves/Constants'
import Pagination from 'react-js-pagination'
import { Tab, Tabs } from "react-bootstrap";
import YearlyAward from './YearlyAward'
import DeleteAward from './awardManagementModal/DeleteAward'
import DeviceFullScreenImage from '../device/DeviceFullScreenImage'

export default function AwardListTable(props) {
    const { awardList, dataCount, count, handlePageChange, pageNo, handleSort, deleteAward, editAward, handleFilter, tabStatus, reverseView, searchYear, openAwardDetail, index, awardDetail, showAwardDetail, hideAwardDetail, awardTitle, handleFullImage, handleClose, fullImage, openFullImage, handleOpenDeleteAward, miniLoader, openDeleteAwardPopup } = props
    return (
        <>
            <div className="cm_breadcrumb">
                <ul>
                    <li style={{ cursor: "pointer", color: 'black' }} className="cm_bold" onClick={reverseView}>Award  MANAGEMENT<div className="cm_breadcrumb_arrow"></div></li>
                    <li style={{ textTransform: 'uppercase' }}>{`${searchYear} year's Award`}</li>
                </ul>
            </div>
            <Tabs activeKey={
                tabStatus == "" || tabStatus == 0 || tabStatus == undefined
                    ? 0
                    : 1
            }
                id="uncontrolled-tab-example"
                onSelect={(e) => handleFilter(e)}
                className='mt-5'
            >
                <Tab eventKey={0} title={`Yearly Award`}></Tab>
                <Tab eventKey={1} title={`Monthly Award`}></Tab>

            </Tabs>
            {tabStatus == 0 ? <YearlyAward
                yearlyAward={awardList?.[0]?.data?.yearArray}
                awardIndex={index}
                openAwardDetail={openAwardDetail}
                showAwardDetail={showAwardDetail}
                hideAwardDetail={hideAwardDetail}
                awardDetail={awardDetail}
                awardTitle={awardTitle}
                handleFullImage={handleFullImage}
                handleClose={handleClose}
                fullImage={fullImage}
                openFullImage={openFullImage}
                deleteAward={deleteAward}
                miniLoader={miniLoader}
                handleOpenDeleteAward={handleOpenDeleteAward}
                openDeleteAwardPopup={openDeleteAwardPopup}
            /> :
                <div className="table-responsive mt-2">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col"> Award Taker Employee</th>
                                <th>Nominees</th>
                                <th>Award Name</th>
                                <th scope="col">Award Type</th>
                                <th scope="col">Year</th>
                                <th scope="col">Month</th>
                                <th scope="col">Reward</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!_.isEmpty(awardList?.[0]?.data?.monthArray) ?
                                awardList?.[0]?.data?.monthArray.map((award, i) => (
                                    <tr key={award._id}>
                                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                        <td style={{ width: 300 }}><span>{
                                            award.givenTo.map((givenTo, i) => (
                                                <span className="cm_col_dept" key={i}>
                                                    {givenTo.firstName + " " + givenTo.middleName + " " + givenTo.lastName}
                                                </span>
                                            ))}</span></td>
                                        <td style={{ width: 300 }}><span>{
                                            award.nominee.length > 0 ? award.nominee.map((nominee, i) => (
                                                <span className="cm_col_dept" key={i}>
                                                    {nominee.firstName + " " + nominee.middleName + " " + nominee.lastName}
                                                </span>
                                            )) : "No Nominee"}</span></td>
                                        <td>{award.awardName}</td>
                                        <td><span className="cm_col_desig">
                                            {awardType(award.awardType)}
                                        </span></td>
                                        <td><span className="cm_col">{award.year}</span></td>
                                        <td><span className="cm_col">{arrOfMonths[parseInt(award.month) - 1]}</span></td>
                                        <td><span className="cm_col">{award.amount}</span></td>
                                        <td className='leave_button_margin'>
                                            <span onClick={() => handleFullImage(award?.awardEmployeeImage)}>
                                                <i className="fa fa-eye blue fa-2x" title="Delete Award" />
                                            </span>
                                            {localStorage.getItem("isAdmin") == 1 || localStorage.getItem("isAdmin") == 4 ?
                                                <span onClick={() => handleOpenDeleteAward(award._id)}>
                                                    <i className="fa fa-trash-o red fa-2x" title="Delete Award" />
                                                </span>
                                                : ""}
                                        </td>
                                    </tr>
                                )) : ""
                            }
                        </tbody>
                    </table>
                    {awardList?.[0]?.data?.monthArray && awardList?.[0]?.data?.monthArray?.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                </div >
            }
            {/* {
                dataCount > 10 ? <Pagination
                    activePage={pageNo}
                    itemsCountPerPage={count}
                    totalItemsCount={dataCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
                    : ''
            } */}
            <DeviceFullScreenImage
                preview={openFullImage}
                fullImage={fullImage}
                closeModal={handleClose}
            />
            <DeleteAward
                show={openDeleteAwardPopup}
                handleSubmit={deleteAward}
                miniLoader={miniLoader}
                handleClose={handleClose}
            />
        </>
    )
}

import React from 'react'
import { dateFormat } from '../helper/Constant'
import moment from 'moment'
import _ from 'lodash'
import { CONSTANTS } from '../../config'
import Pagination from 'react-js-pagination'
import DOMPurify from 'dompurify'
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
export default function EmployeeProjectsActivity(props) {
    const { sortField, sortType, pageNo, count, projectActivity, dataCount, handlePageChange, handleSort } = props
    function createMarkup(props) {
        return {
            __html: DOMPurify.sanitize(props)
        }
    }
    return (
        <>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead className="white bg_blue">
                        <tr>
                            <th>S.No</th>
                            <th>Project Name</th>
                            <th>Task </th>
                            <th>Blocker</th>
                            <th>Date<i
                                className={sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "projectName" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                                onClick={() => handleSort('date')}></i></th>
                            <th>Hour </th>
                        </tr>
                    </thead>
                    {!_.isEmpty(projectActivity)
                        ? projectActivity.map((data, i) => (
                            <tbody>
                                <tr key={data.leaveId}>
                                    <td>{i + 1 + (pageNo - 1) * count}</td>
                                    <td>{data?.projectDetails?.projectName}</td>
                                    <td>{<div className='proj-activity'
                                        dangerouslySetInnerHTML={createMarkup(data.task)}>
                                    </div>}</td>
                                    <td>{<div style={{ color: "black" }}
                                        dangerouslySetInnerHTML={createMarkup(data.blocker)}>
                                    </div>}</td>
                                    <td>
                                        {moment(data.date).format(dateFormat).substring(0, 10)}
                                    </td>
                                    <td>{moment.utc(data.hour * 1000).format("HH:mm")}</td>

                                </tr>
                            </tbody>
                        ))
                        : ""
                    }
                </table >
                {
                    projectActivity && projectActivity?.length > 0 ? "" : <h3 className='text-center'>No data available</h3>
                }
            </div >
            {
                dataCount > 10 ? <Pagination
                    activePage={+(pageNo)}
                    itemsCountPerPage={count}
                    totalItemsCount={dataCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
                    : ''}
        </>
    )
}

import merge from 'lodash/merge';
import development from './development';
import stag from './stag';
import prod from './prod';
import local from './local';

const settings = {}
export default (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
    case 'dev':
      return merge(development, settings)
    break;

    case 'staging':
    case 'stag':
      return merge(stag, settings)
      break;

    case 'production':
    case 'prod':
      return merge(prod, settings)
      break;

    case 'localhost':
    case 'local':
      return merge(local, settings)
      break;
      
    default:
      return merge(development, settings)
  }
})()

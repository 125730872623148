import { type } from "../helper/Constant";

export const leaveValidation = (props) => {
  const { leaveType, startDate, dispatch } = props;
  let valid = true;
  if (leaveType === "" || leaveType === undefined) {
    valid = false;
    dispatch({
        type: type,
        payload: {
          leaveTypeErr: true,
        },
      });
  }
  if (startDate === "" || startDate === undefined) {
    valid = false;
    dispatch({
        type: type,
        payload: {
          startDateErr: true,
        },
      });
  }
  return valid
};

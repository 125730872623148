import React from 'react'
import OtpInput from 'react-otp-input';
import './getOtp.css'
export default function GetOtp(props) {
    const { otp, handleOtpChange, otpSeconds, otpMinutes, handleSetTimer, otpErrorMessage, handleValidateOtp, getOptDisabled } = props
    return (
        <div className='opt_contanier'>
            <div className='d-flex flex-column align-items-center '>
                <h2>Please Enter One-Time Password to Verify Your Account</h2>
                <OtpInput
                    containerStyle={"get_otp_container"}
                    inputStyle={"get_otp_input_container"}
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={6}
                    inputType='number'
                    renderInput={(props) => <input {...props} />}
                />
                <div className='get_otp'>
                    {otpSeconds > 0 || otpMinutes > 0 ? (
                        <p>
                            <span style={{ fontWeight: 600 }}>
                                {otpMinutes < 10 ? `0${otpMinutes}` : otpMinutes}:
                                {otpSeconds < 10 ? `0${otpSeconds}` : otpSeconds}
                            </span>
                        </p>
                    ) : (
                        <p onClick={handleSetTimer}>Get OTP</p>
                    )}
                </div>
                <span className='error_msg'>{otpErrorMessage}</span>
                <div className='text-center mt-2'>
                    <button className={`btn text-center validate_button ${otp.toString().length < 6 ? "btn-secondary" : "btn-success"}`}
                        onClick={handleValidateOtp}>Validate</button>
                </div>
            </div>
        </div>
    )
}

export const combineArraysByKey = (arr1, arr2, arr3, key) => {
    const combinedMap = new Map();
    const keysToRemove = ["numberOfLwpCheck", "arrearCheck", "bonusCheck", "reimbursementCheck"];

    arr1.forEach((obj) => {
        const keyValue = obj[key];
        if (!combinedMap.has(keyValue)) {
            combinedMap.set(keyValue, obj);
        } else {
            const combinedObj = { ...combinedMap.get(keyValue), ...obj };
            combinedMap.set(keyValue, combinedObj);
        }
    });

    arr2.forEach((obj) => {
        const keyValue = obj[key];
        if (!combinedMap.has(keyValue)) {
            combinedMap.set(keyValue, obj);
        } else {
            const combinedObj = { ...combinedMap.get(keyValue), ...obj };
            combinedMap.set(keyValue, combinedObj);
        }
    });

    arr3.forEach((obj) => {
        const keyValue = obj[key];
        if (!combinedMap.has(keyValue)) {
            combinedMap.set(keyValue, obj);
        } else {
            const combinedObj = { ...combinedMap.get(keyValue), ...obj };
            combinedMap.set(keyValue, combinedObj);
        }
    });
    const combinedArray = Array.from(combinedMap.values());
    return combinedArray.map(obj => {
        const newObj = { ...obj };

        keysToRemove.forEach(key => {
            if (newObj.hasOwnProperty(key)) {
                delete newObj[key];
            }
        });
        return newObj;
    });

}

export const handleEmployeeName = (employeeId, employeeList) => {
    const employee = employeeList.sort(function (a, b) {
        if (a.firstName < b.firstName) { return -1; }
        if (a.firstName > b.firstName) { return 1; }
        return 0;
    })
        .find((employee) => employee.employeeId === employeeId);

    if (employee) {
        return employee.firstName + " " + employee.lastName + " " + employee.empId;
    }

    return "";
}

import React, { useReducer, useEffect, useMemo } from "react";
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import ResponseFilter from "../../ResponseFilter";
import { Loader, LateComingIcon, EarlyLeaveIcon } from "../svg";
import {
  viewAttendanceByEmp,
} from "../../config/services/employeeServices";
import { CONSTANTS } from "../../config";
import { statusValue, tabs } from "./Constants";
import { arrOfQuarters } from "../leaves/Constants";
import { useHistory } from "react-router-dom"
import qs from "qs"
import { arrOfMonths, monthNames, reducer, type } from "../helper/Constant";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;

var arrOfYears = [];
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const d = new Date().toLocaleString("default", { month: "long" }).slice(0, 3);
for (let i = 0; i <= 3; i++) {
  arrOfYears.push(currentYear - i);
}

const initialState = {
  activeTab: "3",
  AttendenceDetails: [],
  late: "",
  earlyCheckout: "",
  attendanceListData: [],
  pageNo: 1,
  loader: true,
  count: 10,
  empDetails: "",
  currentDay: "",
  searchDay: "",
  searchMonth: currentMonth,
  searchYear: currentYear,
  searchQuatName: "",
  month: "",
  year: "",
  yearUserDataSet: [],
  employeeId: "",
  sortType: "",
  sortField: "",
  dataCount: "",
  avgHours: "",
  currentMonth: new Date().getMonth() + 1,
  currentQuarter: Math.ceil((new Date().getMonth() + 1) / 3),
  selectLeave: "",
};

const Attendance = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: 'typeCheckSet', payload: {
        pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
        sortField: filtersFromParams.sortField ? filtersFromParams.sortField : "",
        sortType: filtersFromParams.sortType ? Number(filtersFromParams.sortType) : "",
        searchMonth: filtersFromParams.searchMonth ? Number(filtersFromParams.searchMonth) : currentMonth,
        searchYear: filtersFromParams.searchYear ? Number(filtersFromParams.searchYear) : currentYear,
        searchQuatName: filtersFromParams.searchQuatName ? filtersFromParams.searchQuatName : "",
        activeTab: filtersFromParams.activeTab ? filtersFromParams.activeTab : "3",
      }
    })

  }, [history.location.search]);


  const viewAttendanceByEmployee = () => {
    let {
      pageNo,
      count,
      searchDay,
      searchMonth,
      searchYear,
      sortField,
      sortType,
      searchQuatName,
      day,
    } = state;
    let params = ``;

    searchYear !== ""
      ? (params += `searchYear=${searchYear}&`)
      : (params += ``);
    searchDay !== "" ? (params += `searchDay=${searchDay}&`) : (params += ``);
    sortType !== "" ? (params += `sortType=${sortType}&`) : (params += ``);
    sortField !== "" ? (params += `sortField=${sortField}&`) : (params += ``);
    count !== "" ? (params += `count=${count}&`) : (params += ``);
    pageNo !== "" ? (params += `pageNo=${pageNo - 1}&`) : (params += ``);
    searchMonth !== "" && state.activeTab == 3
      ? (params += `searchMonth=${searchMonth}&`)
      : (params += ``);
    searchQuatName !== "" && state.activeTab == 2
      ? (params += `searchQuarter=${searchQuatName}&`)
      : (params += ``);

    params = params.slice(0, -1);

    dispatch({ type: type, payload: { loader: true } });
    viewAttendanceByEmp(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      history.push(`attendance?&searchYear=${searchYear}&searchMonth=${searchMonth}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo}&activeTab=${state.activeTab}&searchQuatName=${searchQuatName}`);
      let currentDay = day;
      if (status) {
        dispatch({
          type: type,
          payload: {
            AttendenceDetails: resData.AttendenceDetails,
            totalAttendence: resData.totalAttendence,
            late: resData.late === 0 ? 0 : resData.late,
            dataCount:
              resData.totalAttendence === 0 ? 0 : resData.totalAttendence,
            earlyCheckout:
              resData.earlyCheckoutCount === 0 ? 0 : resData.earlyCheckoutCount,
            avgHours: resData.avgHours === 0 ? 0 : resData.avgHours,
            day: currentDay,
            loader: false,
            currentDay: currentDay,
          },
        });
      }
    });
  };

  useEffect(() => {
    viewAttendanceByEmployee();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.sortType,
    state.sortField,
    state.searchDay,
    state.searchMonth,
    state.searchYear,
    state.searchQuatName,
    state.activeTab,
    state.pageNo,
    state.count,
  ]);

  const setYear = (yearName, i) => {
    dispatch({
      type: type,
      payload: { searchYear: yearName, searchMonth: "", searchQuatName: "" },
    });
  };

  const setQuat = (i, quarterName) => {
    dispatch({
      type: type,
      payload: { searchQuatName: quarterName, searchMonth: "" },
    });
  };

  const setMonth = (i, monthName) => {
    dispatch({
      type: type,
      payload: { searchMonth: i, searchQuatName: "" },
    });
  };

  const handleTabChange = (e) => {
    localStorage.setItem("activeTab", parseInt(e));
    dispatch({
      type: type,
      payload: { activeTab: e, pageNo: 1, selectLeave: e },
    });

    switch (e) {
      case "1":
        setYear(new Date().getFullYear(), 0);
        break;
      case "2":
        setQuat("", state.currentQuarter);
        break;
      case "3":
        setMonth(state.currentMonth, currentMonth);
        break;
      default:
        break;
    }
  };

  const handleSort = (e) => {
    dispatch({ type: type, payload: { loader: false } });
    dispatch({
      type: type,
      payload: {
        sortField: e,
        sortType:
          state.sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
    });
  };

  const filterAttendanceYearLi = () => {
    return (
      <ul className="cm_calender">
        {arrOfYears.map((yearName, i) => (
          <li
            className={`${yearName === (state.searchYear ? Number(state.searchYear) : currentYear)
              ? "activeMon"
              : ""
              }`}
            onClick={() => {
              dispatch({
                type: type,
                payload: { searchYear: yearName, i },
              });
            }}
          >
            <p>{yearName}</p>
            <span
              className={`${yearName === (state.searchYear ? Number(state.searchYear) : currentYear)
                ? "activeMon"
                : ""
                }`}
            ></span>
          </li>
        ))}
      </ul>
    );
  };

  const filterAttendanceQuarterLi = () => {
    return (
      <ul className="cm_quarterly">
        {arrOfQuarters.map((quartrName, i) => (
          <li
            className={`${i === state.searchQuatName - 1 ? "activeMon" : " "}`}
            onClick={() =>
              dispatch({
                type: type,
                payload: { searchQuatName: i + 1 },
              })
            }
          >
            <p>{quartrName}</p>
            <span
              className={`${i === state.searchQuatName - 1 ? "activeMon" : ""}`}
            ></span>
          </li>
        ))}
      </ul>
    );
  };

  const filterAttendanceMonthLi = () => {
    return (
      <ul className="cm_monthly">
        {arrOfMonths.map((monthName, i) => (
          <li
            className={`${i === state.searchMonth - 1 ? "activeMon" : ""}`}
            onClick={() =>
              dispatch({
                type: type,
                payload: { searchMonth: +(i + 1) },
              })
            }
          >
            <p>{monthName}</p>
            <span
              className={`${i === state.searchMonth - 1 ? "activeMon" : ""}`}
            ></span>
          </li>
        ))}
      </ul>
    );
  };

  const attendance = () => {
    let {
      activeTab,
      AttendenceDetails,
      count,
      earlyCheckout,
      avgHours,
      pageNo,
      late,
      sortType,
      sortField,
      getMonthlyAttendance,
      getQuarterlyAttendance,
      searchYear,
    } = state;
    let flag = false;

    if (AttendenceDetails) {
      AttendenceDetails.length === 0 ? (flag = true) : (flag = false);
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-9 cm_w_100">
            {activeTab === "1" && filterAttendanceYearLi(searchYear)}
            {activeTab === "2" &&
              filterAttendanceQuarterLi(getQuarterlyAttendance)}
            {activeTab === "3" &&
              filterAttendanceMonthLi(AttendenceDetails, getMonthlyAttendance)}
          </div>
          <div className="col-3 cm_w_100"></div>
        </div>

        <div className="row attendance_status mt-4">
          <div className="col-md-3 ">
            <div className="cm_card">
              <span className="late_comings">
                <LateComingIcon />
              </span>
              <div className="cm_style">
                <span className="emp_count cm_bold">
                  {Array.isArray(late) ? late[0].lateCount : late}
                </span>
                <p className="mb-4">Late Check In</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="cm_card">
              <span className="late_comings early_leave">
                <EarlyLeaveIcon />
              </span>
              <div className="cm_style">
                <span className="emp_count cm_bold">
                  {Array.isArray(earlyCheckout)
                    ? earlyCheckout[0].earlyCount
                    : earlyCheckout}
                </span>
                <p className="mb-4">Early Check Out</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`${moment.utc(avgHours * 1000).format("HH:mm") <
                moment.utc(32400 * 1000).format("HH:mm") &&
                moment.utc(avgHours * 1000).format("HH:mm") >=
                moment.utc(28800 * 1000).format("HH:mm")
                ? "cm_card cm_avghryellow"
                : moment.utc(avgHours * 1000).format("HH:mm") <
                  moment.utc(32400 * 1000).format("HH:mm")
                  ? "cm_card cm_avghours"
                  : "cm_card cm_avghourgreen"
                }`}
            >
              <div className="text-center">
                <p className="sm_hours">Avg/Hours</p>
                <span className="emp_count cm_bold">
                  {moment.utc(avgHours * 1000).format("HH:mm") !== 0
                    ? moment.utc(avgHours * 1000).format("H.mm")
                    : "00.00"}
                  /09.00
                </span>
              </div>
            </div>
          </div>
        </div>

        {!_.isEmpty(AttendenceDetails) ? AttendenceDetails.map((month, i) => {
          return (
            <div>
              <div>{monthNames[month._id.month - 1]}</div>
              <div class="table-responsive">
                <table className="table table-bordered">
                  <thead className="white bg_blue">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Date</th>
                      <th scope="col">Check IN</th>
                      <th scope="col">Late Check IN</th>
                      <th scope="col">Early Check Out</th>
                      <th scope="col">Check Out</th>
                      <th scope="col">
                        Total Hours
                        <i
                          className={
                            sortType === SORT_TYPE_CONSTANTS.Descending &&
                              sortField === "totalHours"
                              ? "fa fa-sort-numeric-desc ml-2"
                              : "fa fa-sort-numeric-asc ml-2"
                          }
                          onClick={() => handleSort("totalHours")}
                        ></i>
                      </th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!_.isEmpty(month.attendanceList) ? month.attendanceList?.map((attendance, i) => (
                      <tr>
                        <td>{i + 1 + (pageNo - 1) * count}</td>
                        <td>{moment(attendance.date).format("DD/MM/YYYY")}</td>
                        <td>
                          {attendance.checkIn > '0' && (attendance.checkIn.includes(".") ? parseInt((+attendance.checkIn.split(".")[0]) * 60 * 60 + (+attendance.checkIn.split(".")[1]) * 60) : parseInt(attendance.checkIn * 60 * 60)) <= (attendance.reportingTime + 1800)
                            ? attendance.checkIn.indexOf('.') !== -1 ?
                              attendance.checkIn.toString().split(".")[1].length <= 1 ?
                                attendance.checkIn.toString().split(".")[0] + ':' + attendance.checkIn.toString().split(".")[1] + "0 AM"
                                :
                                attendance.checkIn.toString().split(".")[0] + ':' + attendance.checkIn.toString().split(".")[1] + ' AM' : attendance.checkIn + ':00 AM'
                            : "_"}
                        </td>
                        <td className="text-danger">
                          <time>
                            {attendance.checkIn > '0' ?
                              (attendance.checkIn.includes(".") ? parseInt((+attendance.checkIn.split(".")[0]) * 60 * 60 + (+attendance.checkIn.split(".")[1]) * 60) : parseInt(attendance.checkIn * 60 * 60)) > (attendance.reportingTime + 1800)
                                && attendance.checkIn.toString().split(".")[0] < 12
                                ? attendance.checkIn.indexOf('.') !== -1 ?
                                  attendance.checkIn.toString().split(".")[1].length <= 1 ?
                                    attendance.checkIn.toString().split(".")[0] + ':' + attendance.checkIn.toString().split(".")[1] + "0 AM"
                                    :
                                    attendance.checkIn.toString().split(".")[0] + ':' + attendance.checkIn.toString().split(".")[1] + " AM" : attendance.checkIn + ':00 AM'
                                :
                                (attendance.checkIn.includes(".") ? parseInt((+attendance.checkIn.split(".")[0]) * 60 * 60 + (+attendance.checkIn.split(".")[1]) * 60) : parseInt(attendance.checkIn * 60 * 60)) > (attendance.reportingTime + 1800)
                                  && attendance.checkIn.toString().split(".")[0] >= 12
                                  ? attendance.checkIn.indexOf('.') !== -1 ?
                                    attendance.checkIn.toString().split(".")[1].length <= 1 ?
                                      attendance.checkIn.toString().split(".")[0] + ':' + attendance.checkIn.toString().split(".")[1] + "0 PM"
                                      :
                                      attendance.checkIn.toString().split(".")[0] + ':' + attendance.checkIn.toString().split(".")[1] + " PM" : attendance.checkIn + ':00 PM'
                                  : "_"
                              : '_'}
                          </time>
                        </td>
                        <td>
                          <span className="text-danger">
                            <time>
                              {attendance.checkOut > '0' && attendance.checkIn !== "0" && attendance.totalHours < 32400 && attendance.checkOut.toString().split(".")[0] <= 12 ?
                                attendance.checkOut.indexOf('.') !== -1 ?
                                  attendance.checkOut.toString().split(".")[1].length <= 1 ?
                                    attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + "0 AM"
                                    :
                                    attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + " AM" : attendance.checkOut + ':00 AM'
                                :
                                attendance.checkOut > '0' && attendance.totalHours < 32400 && attendance.checkIn !== "0" && attendance.checkOut.toString().split(".")[0] > 12 ?
                                  attendance.checkOut.indexOf('.') !== -1 ?
                                    attendance.checkOut.toString().split(".")[1].length <= 1 ?
                                      attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + "0 PM"
                                      :
                                      attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + " PM" : attendance.checkOut + ':00 PM'
                                  : '_'
                              }
                            </time>
                          </span>
                        </td>
                        <td>
                          {attendance.checkOut > '0' ?
                            attendance.totalHours === 0 ?
                              <time>
                                {attendance.checkOut.indexOf('.') !== -1 ?
                                  attendance.checkOut.toString().split(".")[1].length <= 1 ?
                                    attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + "0 PM"
                                    :
                                    attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + " PM" : attendance.checkOut + ':00 PM'}
                              </time> :
                              attendance.totalHours >= 32400 ?
                                <span className="green">
                                  <time>
                                    {attendance.checkOut.toString().split(".")[0] < 12
                                      ?
                                      attendance.checkOut.indexOf('.') !== -1 ?
                                        attendance.checkOut.toString().split(".")[1].length <= 1 ?
                                          attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + "0 AM"
                                          :
                                          attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + " AM" : attendance.checkOut + ':00 AM'
                                      :
                                      attendance.checkOut.indexOf('.') !== -1 ?
                                        attendance.checkOut.toString().split(".")[1].length <= 1 ?
                                          attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + "0 PM"
                                          :
                                          attendance.checkOut.toString().split(".")[0] + ':' + attendance.checkOut.toString().split(".")[1] + " PM" : attendance.checkOut + ':00 PM'}
                                  </time>
                                </span>
                                : '_' : '_'
                          }
                        </td>
                        <td>
                          {moment
                            .utc(attendance.totalHours * 1000)
                            .format("HH:mm") <
                            moment.utc(32400 * 1000).format("HH:mm") &&
                            moment
                              .utc(attendance.totalHours * 1000)
                              .format("HH:mm") >=
                            moment.utc(28800 * 1000).format("HH:mm") ? (
                            <span
                              className="yellow"
                              to={`/admin-attendance/${attendance.employeeId}`}
                            >
                              {attendance.totalHours
                                ? moment
                                  .utc(attendance.totalHours * 1000)
                                  .format("HH.mm")
                                : "00.00"}
                            </span>
                          ) : moment
                            .utc(attendance.totalHours * 1000)
                            .format("HH:mm") <
                            moment.utc(32400 * 1000).format("HH:mm") ? (
                            <span
                              className="text-danger"
                              to={`/admin-attendance/${attendance.employeeId}`}
                            >
                              {attendance.totalHours
                                ? moment
                                  .utc(attendance.totalHours * 1000)
                                  .format("HH.mm")
                                : "00.00"}
                            </span>
                          ) : (
                            <span
                              className="green"
                              to={`/admin-attendance/${attendance.employeeId}`}
                            >
                              {attendance.totalHours
                                ? moment
                                  .utc(attendance.totalHours * 1000)
                                  .format("HH.mm")
                                : "00.00"}
                            </span>
                          )}
                        </td>
                        <td
                          className={
                            attendance.status === 1
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          { statusValue(attendance.status)}
                        </td>
                      </tr>
                    ))
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })
          : " "}
        {!_.isEmpty(AttendenceDetails) ? (
          ""
        ) : (
          <h3 className="text-center no-dta">No data available</h3>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="body_container cm_bold">
      {state.loader && <Loader />}
      <h3 className="mb-5">ATTENDANCE RECORD</h3>
      <div className="attendance_tab">
        <Tabs activeKey={state.activeTab} id=" " onSelect={(e) => handleTabChange(e)}>
          {tabs.map((tab) => (
            <Tab eventKey={tab.eventKey} title={tab.title} key={tab.eventKey}>
              {attendance()}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Attendance;

import {
  EDIT_ATTENDANCE_BY_ADMIN_REQUEST,
  EDIT_ATTENDANCE_BY_ADMIN_SUCCESS,
  EDIT_ATTENDANCE_BY_ADMIN_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { editAttendance } from "../../config/services/employeeServices";

const editAttendanceByAdminAction = (props) => {
  let { useDispatch, closeModal, getAttendance, obj } = props
  return (dispatch) => {
    dispatch(editAttendanceByAdminActionRequest());
    editAttendance(obj).then((res) => {
      let { status } = ResponseFilter(res)
      if (status) {
        useDispatch({
          type: 'typeCheckSet', payload: {
            tabStatus: '',
            status: '',
            resMsgEdit: true,
            miniLoader: false,
          }
        })
        setTimeout(() => useDispatch({
          type: 'typeCheckSet', payload: {
            resMsgEdit: false,
          }
        }), 2000);
      } else {
        useDispatch({
          type: 'typeCheckSet', payload: {
            tabStatus: "",
            status: "",
            loader: false,
            miniLoader: "",
            responseMessageEdit: res.data.error.responseMessage,
            errorCode: res.data.error.errorCode,
            resMsgFailEdit: true,
          }
        })
        setTimeout(() => useDispatch({
          type: 'typeCheckSet', payload: {
            resMsgFailEdit: false,
          }
        }), 2000);
      }
      closeModal();
      getAttendance()
    })
      .catch((error) => {
        dispatch(editAttendanceByAdminActionFailure(error));
      });
  };
};

const editAttendanceByAdminActionRequest = () => {
  return {
    type: EDIT_ATTENDANCE_BY_ADMIN_REQUEST,
  };
};

const editAttendanceByAdminActionSuccess = (data) => {
  return {
    type: EDIT_ATTENDANCE_BY_ADMIN_SUCCESS,
    payload: data,
  };
};

const editAttendanceByAdminActionFailure = (error) => {
  return {
    type: EDIT_ATTENDANCE_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  editAttendanceByAdminAction,
  editAttendanceByAdminActionRequest,
  editAttendanceByAdminActionSuccess,
  editAttendanceByAdminActionFailure,
};

import {
  UPDATE_EMP_RESUME_REQUEST,
  UPDATE_EMP_RESUME_SUCCESS,
  UPDATE_EMP_RESUME_FAILURE,
} from "./actionType";

import { updateEmployeeResume } from "../../config/services/employeeServices";
import { updateResumeByEmpId } from "./getResumeByEmpIdActionUpdate";
import ResponseFilter from "../../ResponseFilter";


const updateEmpResume = (data, empId, setState, handleCancelButtonClick) => {
  return (dispatch) => {
    let params = `employeeId=${empId}&pageNo=${0}&count=${10}`
    dispatch(updateEmpResumeRequest());
    updateEmployeeResume(data)
      .then((res) => {
        let { status } = ResponseFilter(res)
        if (status) {
          dispatch(updateEmpResumeSuccess(res.data));
          handleCancelButtonClick()
          setState({ resMsg: true, miniLoader: false })
          setTimeout(() => setState({ resMsg: false }), 3000);
          dispatch(updateResumeByEmpId(params));
          window.location.href = `/resumeDetails?pageNo=${1}`

        }
        else {
          setState({ loader: false, resFailMessage: true, responseMessage: res?.data?.error?.responseMessage })
          setTimeout(() => setState({ resFailMessage: false }), 3000);
        }
      })
      .catch((error) => {
        dispatch(updateEmpResumeFailure(error));
      });
  };
};

const updateEmpResumeRequest = () => {
  return {
    type: UPDATE_EMP_RESUME_REQUEST,
  };
};
const updateEmpResumeSuccess = (data) => {
  return {
    type: UPDATE_EMP_RESUME_SUCCESS,
    payload: data,
  };
};
const updateEmpResumeFailure = (error) => {
  return {
    type: UPDATE_EMP_RESUME_FAILURE,
    payload: error,
  };
};
export {
  updateEmpResume,
  updateEmpResumeRequest,
  updateEmpResumeSuccess,
  updateEmpResumeFailure,
};

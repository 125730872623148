import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Select } from "antd";
import _ from "lodash";
import Pagination from "react-js-pagination";
import ResponseFilter from "../../ResponseFilter";
import { Tab, Tabs } from "react-bootstrap";
import {
  creditAmountInWallet,
  employeeActive,
  getAdminProfile,
  getEmployeeList,
} from "../../config/services/employeeServices";
import { Loader } from "../svg";
import Modal from "react-bootstrap/Modal";
import local from "../../config/settings/local";
import { CONSTANTS } from "../../config";
import { Toastify, isAdmin } from "../helper/Constant";
import {
  clientSideValue,
  officeLocationValue,
} from "../../config/constants/Constants";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
let TAB_STATUS_CONSTANTS = CONSTANTS.TAB_STATUS_CONSTANTS;
const { Option } = Select;
let url = "/admin-employee/employee-detail/";
let createEmplyeeUrl = "/admin-employee/create";
const EmployeeTable = ({
  employeeProfile,
  profile,
  loader,
  employeeList,
  handleSort,
  sortType,
  sortField,
  handleEditSubmit,
  status,
  pageNo,
  count,
  editWallet,
  handleOpenEditWalletBalance,
}) => {
  if (!_.isEmpty(employeeList)) {
    employeeList.map(function (data, index) {});
  }

  return (
    <React.Fragment>
      <div className="table-responsive">
        {/* {employeeList.length > 0 ? ( */}
        <table className="table table-bordered">
          <thead className="white bg_blue">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">
                Employee ID
                <i
                  className={
                    sortType === SORT_TYPE_CONSTANTS.Asscending &&
                    sortField === "empId"
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={() => handleSort("empId")}
                ></i>
              </th>
              <th scope="col">
                Name
                <i
                  className={
                    sortType === SORT_TYPE_CONSTANTS.Asscending &&
                    sortField === "firstName"
                      ? "fa fa-sort-alpha-asc ml-2"
                      : "fa fa-sort-alpha-desc ml-2"
                  }
                  onClick={() => handleSort("firstName")}
                ></i>
              </th>

              <th scope="col">
                Joined
                <i
                  className={
                    sortType === SORT_TYPE_CONSTANTS.Asscending &&
                    sortField === "dateOfJoining"
                      ? "fa fa-sort-numeric-asc ml-2"
                      : "fa fa-sort-numeric-desc ml-2"
                  }
                  onClick={() => handleSort("dateOfJoining")}
                ></i>
              </th>
              <th scope="col">Experience</th>
              <th scope="col">Gender</th>
              <th scope="col">Location</th>
              <th scope="col">Client Side</th>
              <th scope="col">Client Location</th>
              <th scope="col">Client Name</th>

              <th scope="col">Wallet</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(employeeList)
              ? employeeList.map((employee, i) => (
                  <tr key={employee.employeeId}>
                    <td>
                      <span>{i + 1 + (pageNo - 1) * count}</span>
                    </td>
                    <td>
                      <span className="cm_col">{employee.empId}</span>
                    </td>
                    <td>
                      <span className="cm_col">
                      {isAdmin === "8" ? (
                          <>
                            {employee.firstName} {employee.middleName}
                            {employee.lastName}
                          </>
                        ) : (
                          <Link
                            to={{ pathname: `${url}${employee.employeeId}` }}
                          >
                            {employee.firstName} {employee.middleName}
                            {employee.lastName}
                          </Link>
                        )}
                      </span>
                    </td>

                    <td>
                      <span>
                        {moment.unix(employee.doj / 1000).format("DD/MM/YYYY")}
                      </span>
                    </td>
                    <td>
                      <span>
                        {employee.experienceYear ? employee.experienceYear : 0}.
                        {employee.experienceMonth
                          ? employee.experienceMonth
                          : 0}
                      </span>
                    </td>
                    <td>
                      {employee.gender == 1 ? "Male" : ""}
                      {employee.gender == 2 ? "Female" : ""}
                      {employee.gender == 3 ? "Other" : ""}
                    </td>
                    <td>
                      <span>
                        {employee?.officeLocation
                          ? officeLocationValue(employee?.officeLocation)
                          : ""}
                      </span>
                    </td>
                    <td>
                      <span>
                        {employee?.clientSide
                          ? clientSideValue(employee?.clientSide)
                          : ""}
                      </span>
                    </td>
                    <td>
                      <span>
                        {employee?.clientLocation
                          ? employee?.clientLocation
                          : ""}
                      </span>
                    </td>
                    <td>
                      <span>
                        {employee?.clientName ? employee?.clientName : ""}
                      </span>
                    </td>
                    <td>
                      <span>
                        {employee.walletBalance ? employee.walletBalance : 0}
                      </span>
                    </td>
                    <td>
                      {employee.status === TAB_STATUS_CONSTANTS.STATUS.one ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => editWallet(employee)}
                          >
                            Wallet
                          </button>
                          <i
                            className="fa fa-pencil blue ml-3 fa-lg"
                            title="edit"
                            onClick={(e) =>
                              handleOpenEditWalletBalance(employee)
                            }
                            aria-hidden="true"
                          ></i>
                        </>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          disabled
                        >
                          Wallet
                        </button>
                      )}
                      {profile === employee.email ? (
                        <div
                          className="cm_swich_wrapper mt-1"
                          title="You can not deactivate yourself"
                        >
                          <span className="cm_swich1"></span>
                        </div>
                      ) : (
                        <div className="cm_swich_wrapper mt-1">
                          <input
                            type="checkbox"
                            value={status}
                            checked={
                              employee.status == TAB_STATUS_CONSTANTS.STATUS.one
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleEditSubmit(e, employee.employeeId)
                            }
                          />
                          <span className="cm_swich"></span>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {employeeList.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center">No data available</h3>
        )}
      </div>
    </React.Fragment>
  );
};

class EmployeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeList: [],
      departmentName: [],
      search: "",
      status: TAB_STATUS_CONSTANTS.STATUS.one,
      employeeId: "",
      employeeStatus: "",
      loader: true,
      sortType: SORT_TYPE_CONSTANTS.Descending,
      sortField: "empId",
      pageNo: 1,
      count: 10,
      profile: "",
      totalEmployees: "",
      activeEmployees: "",
      dataCount: "",
      inactiveEmployees: "",
      addAmountPopup: false,
      updateBalanceErrorMsg: "",
      resMsg: "",
      walletBalance: 0,
      updateBalance: "",
      miniLoader: false,
      selectedEmpId: "",
      editresMsg: "",
      editWalletBalance: "",
      openEditWalletBalance: false,
      pageChange: false,
      resFailMsg: false,
      successMessage: "",
      responseMessage: "",
      officeLocation: "",
      clientLocationArray: [],
      clientOfficeLocationfilterValue: "",
      isClientSide: 0,
    };
    this.debounceSearch = null;
  }

  handleSelectChange = () => {
    this.setState({ search: "" }, () => this.getEmployee());
  };
  handleEditSubmit = (e, employeeId) => {
    let obj = {
      employeeId: employeeId,
      status: e.target.checked
        ? TAB_STATUS_CONSTANTS.STATUS.one
        : TAB_STATUS_CONSTANTS.STATUS.two,
    };
    this.setState({ loader: true });
    employeeActive(obj).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState(
          {
            resMsg: true,
            successMessage: "Employee Status Edited",
          },
          () => this.getEmployee()
        );
      } else {
        this.setState(
          {
            resFailMsg: true,
            responseMessage: res.data.error.responseMessage,
          },
          () => this.getEmployee()
        );
      }
    });
    setTimeout(() => {
      this.setState({
        resMsg: false,
        resFailMsg: false,
      });
    }, 4000);
  };
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () => this.getEmployee());
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value, pageNo: 1 });
    // if (e.target.value === "") {
    //   this.getEmployee()
    // }
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.setState({ loader: true });
      this.getEmployee();
    }, 2000);
  };

  handleSort = (e) => {
    this.setState(
      {
        loader: false,
        pageNo: 1,
        sortField: e,
        sortType:
          this.state.sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
      () => this.getEmployee()
    );
  };

  handleTabChange = (e) => {
    this.setState(
      {
        status: e,
        loader: true,
        pageNo: 1,
      },
      () => this.getEmployee()
    );
  };
  getProfile = () => {
    getAdminProfile().then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          imageLoader: false,
          profile: resData.employeeProfile.email,
        });
      }
    });
  };

  getEmployee = () => {
    let {
      search,
      status,
      sortType,
      sortField,
      pageNo,
      count,
      officeLocation,
      clientOfficeLocationfilterValue,
      isClientSide,
    } = this.state;
    let params = `search=${search.trim()}&status=${
      status == "0" ? "" : parseInt(status)
    }&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${
      pageNo - 1
    }&officeLocation=${officeLocation}&clientLocation=${clientOfficeLocationfilterValue}&clientSide=${
      isClientSide ? isClientSide : ""
    }`;
    this.props.history.push(
      `admin-employee?search=${search}&sortType=${sortType}&sortField=${sortField}&pageNo=${pageNo}&status=${status}&officeLocation=${officeLocation}&clientOfficeLocationfilterValue=${
        isClientSide == 1 ? clientOfficeLocationfilterValue : ""
      }&isClientSide=${isClientSide}`
    );
    getEmployeeList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState(
          {
            employeeList: resData.employeesList,
            departmentName: resData.employeesList.departmentName,
            totalEmployees: resData.totalEmployees,
            activeEmployees: resData.activeEmployees,
            inactiveEmployees: resData.inactiveEmployees,
            dataCount: resData.dataCount,
            clientLocationArray: resData.clientLocation,
          },
          () => this.setState({ loader: false })
        );
      } else {
        this.setState({ loader: false });
      }
    });
  };

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    let status = url.searchParams.get("status");
    let officeLocation = url.searchParams.get("officeLocation");
    let clientOfficeLocationfilterValue = url.searchParams.get(
      "clientOfficeLocationfilterValue"
    );
    let isClientSide = url.searchParams.get("isClientSide");

    this.setState(
      {
        pageNo: pageNo ? pageNo : 1,
        search: search ? search : "",
        sortType: sortType ? sortType : SORT_TYPE_CONSTANTS.Asscending,
        sortField: sortField ? sortField : "empId",
        status: status ? status : TAB_STATUS_CONSTANTS.STATUS.one,
        officeLocation: officeLocation ? officeLocation : "",
        clientOfficeLocationfilterValue:
          clientOfficeLocationfilterValue && parseInt(isClientSide) == 1
            ? clientOfficeLocationfilterValue
            : "",
        isClientSide: isClientSide ? parseInt(isClientSide) : 0,
      },
      () => {
        this.getEmployee();
        this.getProfile();
      }
    );
  }

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "ErrorMsg"]: "",
      resMsg: "",
    });

  editWallet = (employee) => {
    this.setState({
      addAmountPopup: !this.state.addAmountPopup,
      walletBalance: employee.walletBalance,
      updateBalance: "",
      selectedEmpId: employee.employeeId,
    });
  };
  // editWallet=(employee)=>{
  //   this.setState({

  //     walletBalance:employee.walletBalance,
  //     updateBalance:"",
  //     selectedEmpId:employee.employeeId,

  //   })
  // }

  closeModal = () => {
    this.setState({
      openEditWalletBalance: false,
      addAmountPopup: false,
      updateBalance: "",
      updateBalanceErrorMsg: "",
      editresMsg: "",
    });
  };

  handleOpenEditWalletBalance = (employee) => {
    this.setState({
      openEditWalletBalance: !this.state.openEditWalletBalance,
      walletBalance: employee.walletBalance,
      updateBalance: "",
      selectedEmpId: employee.employeeId,
    });
  };
  handleWalletRecharge = (event) => {
    event.preventDefault();

    if (this.handleValidetion()) {
      this.setState({ miniLoader: true });
      let params = {
        amount: this.state.updateBalance,
        employeeId: this.state.selectedEmpId,
        isEditAmount: 0,
      };

      creditAmountInWallet(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              miniLoader: false,
              addAmountPopup: !this.state.addAmountPopup,
              resMsg: true,
              successMessage: "Amount Added ",
            },
            () => this.getEmployee(),
            setTimeout(() => {
              this.setState({ resMsg: false });
            }, 4000)
          );
        } else {
          this.setState({
            miniLoader: false,
            editresMsg: res.data.error?.errors[0].message,
            openEditDepartmentPopup: true,
          });
        }
      });
    }
  };

  handleEditWalletRecharge = (event) => {
    event.preventDefault();

    if (this.handleValidetion()) {
      this.setState({ miniLoader: true });
      let params = {
        amount: this.state.updateBalance,
        employeeId: this.state.selectedEmpId,
        isEditAmount: 1,
      };

      creditAmountInWallet(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              miniLoader: false,
              openEditWalletBalance: false,
              resMsg: true,
              successMessage: "Amount Edited",
            },
            () => this.getEmployee(),
            setTimeout(() => {
              this.setState({ resMsg: false });
            }, 4000)
          );
        } else {
          this.setState({
            miniLoader: false,
            editresMsg: res.data.error?.errors[0].message,
            openEditDepartmentPopup: true,
          });
        }
      });
    }
  };
  handleValidetion = () => {
    let validate = true;
    const { updateBalance } = this.state;
    var reg = new RegExp("^[1-9][0-9]*$");
    if (updateBalance === "" || updateBalance === undefined) {
      validate = false;
      this.setState({ updateBalanceErrorMsg: "Amount can not be Empty" });
    } else if (updateBalance < 0) {
      validate = false;
      this.setState({
        updateBalanceErrorMsg: "Amount can not be less than zero",
      });
    } else if (updateBalance > 5000) {
      validate = false;
      this.setState({ updateBalanceErrorMsg: "Amount cannot exceed 5000" });
    } else if (reg.test(updateBalance) === false) {
      validate = false;
      this.setState({
        updateBalanceErrorMsg:
          "Amount can only be Number and can not be start from 0",
      });
    } else {
      this.setState({ updateBalanceErrorMsg: "" });
    }

    return validate;
  };

  handleOfficeLocationChange = (e) => {
    if (e) {
      this.setState({ officeLocation: e, loader: true, pageNo: 1 }, () =>
        this.getEmployee()
      );
    } else {
      this.setState({ officeLocation: "", loader: true, pageNo: 1 }, () =>
        this.getEmployee()
      );
    }
  };
  handleClientOfficeLocationChange = (e) => {
    if (e) {
      this.setState(
        { clientOfficeLocationfilterValue: e, loader: true, pageNo: 1 },
        () => this.getEmployee()
      );
    } else {
      this.setState(
        { clientOfficeLocationfilterValue: "", loader: true, pageNo: 1 },
        () => this.getEmployee()
      );
    }
  };

  handleIsClientLocationChange = (e) => {
    if (e == 1) {
      this.setState({ isClientSide: e, loader: true, pageNo: 1 }, () =>
        this.getEmployee()
      );
    } else if (e == 2) {
      this.setState(
        {
          isClientSide: e,
          loader: true,
          pageNo: 1,
          clientOfficeLocationfilterValue: "",
        },
        () => this.getEmployee()
      );
    } else {
      this.setState({ isClientSide: "", loader: true, pageNo: 1 }, () =>
        this.getEmployee()
      );
    }
  };

  render() {
    let {
      departmentName,
      search,
      loader,
      totalEmployees,
      dataCount,
      employeeList,
      activeEmployees,
      inactiveEmployees,
      pageNo,
      count,
      addAmountPopup,
      updateBalanceErrorMsg,
      resMsg,
      walletBalance,
      miniLoader,
      updateBalance,
      editresMsg,
      openEditWalletBalance,
      editWalletBalance,
      pageChange,
      resFailMsg,
      responseMessage,
      successMessage,
      officeLocation,
      clientOfficeLocationfilterValue,
      clientLocationArray,
      isClientSide,
      status,
    } = this.state;

    return (
      <div className="body_container cm_bold">
        {loader && <Loader />}
        <Toastify
          resMsg={resMsg}
          resMsgFail={resFailMsg}
          text={successMessage}
          responseMessage={responseMessage}
        />
        <h3>EMPLOYEE LIST</h3>
        <div className="row d-flex justify-content-end mb-4 mt-5">
          <div className={isAdmin !== "8" ?"col-md-6 search_device_bar1" :"margin_right"}>
            {isAdmin !== "8" && (
              <Link
                className="btn btn-primary add_empBtn add1"
                to={createEmplyeeUrl}
              >
                Add New Employee
              </Link>
            )}
            <input
              maxLength={35}
              className="form-control mr-lg-3 cm_box"
              type="search"
              placeholder="Search by Name/E.ID"
              aria-label="Search"
              value={search}
              onChange={this.handleSearch}
              style={isAdmin === "8" ? { width: "14rem" } : {}}
            />
            {search ? (
              <span className={isAdmin === "8" ? "cm_clearNew" : "cm_clear3"} onClick={this.handleSelectChange}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span className={isAdmin === "8" ? "cm_clearNew" :"cm_clear3"}>
                <i className="fa fa-search" aria-hidden="true"></i>
              </span>
            )}
          </div>
        </div>

        <div className="filters justify-content-end  mb-5">
          <Select
            onChange={this.handleOfficeLocationChange}
            name="officeLocation"
            className="select_year_cal"
            value={officeLocation ? officeLocation : "Office Location"}
            allowClear={officeLocation ? true : false}
          >
            <Option value="1">Noida</Option>
            <Option value="2">Lucknow</Option>
          </Select>
          <Select
            onChange={this.handleIsClientLocationChange}
            name="officeLocation"
            className="select_year_cal"
            value={isClientSide ? clientSideValue(isClientSide) : "Client Side"}
            allowClear={isClientSide ? true : false}
          >
            <Option value={1}>Yes</Option>
            <Option value={2}>No</Option>
          </Select>

          <Select
            showSearch
            onChange={this.handleClientOfficeLocationChange}
            name="clientOfficeLocationfilterValue"
            className="select_year_cal"
            value={
              clientOfficeLocationfilterValue
                ? clientOfficeLocationfilterValue
                : "Client Office Location"
            }
            optionFilterProp="key"
            filterOption={(input, option) =>
              option.key.toLowerCase().includes(input.toLowerCase())
            }
            allowClear={clientOfficeLocationfilterValue ? true : false}
            disabled={isClientSide == 1 ? false : true}
          >
            {!_.isEmpty(clientLocationArray)
              ? clientLocationArray.map((clientLocation) => (
                  <Option key={clientLocation} value={clientLocation}>
                    <span>{clientLocation}</span>
                  </Option>
                ))
              : ""}
          </Select>
        </div>
        <Tabs
          activeKey={status ? status : "0"}
          id="uncontrolled-tab-example"
          onSelect={(e) => this.handleTabChange(e)}
        >
          <Tab eventKey="0" title={`All ${totalEmployees}`}>
            <EmployeeTable
              {...this.state}
              handleAction={this.handleAction}
              handleSort={this.handleSort}
              handleEditSubmit={this.handleEditSubmit}
              editWallet={this.editWallet}
              handleOpenEditWalletBalance={this.handleOpenEditWalletBalance}
              handleOfficeLocationChange={this.handleOfficeLocationChange}
              handleClientOfficeLocationChange={
                this.handleClientOfficeLocationChange
              }
            />
            {dataCount > 10 ? (
              <Pagination
                activePage={+pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </Tab>

          <Tab eventKey="1" title={`Active ${activeEmployees}`}>
            <EmployeeTable
              {...this.state}
              handleAction={this.handleAction}
              handleSort={this.handleSort}
              handleEditSubmit={this.handleEditSubmit}
              editWallet={this.editWallet}
              handleOpenEditWalletBalance={this.handleOpenEditWalletBalance}
              handleOfficeLocationChange={this.handleOfficeLocationChange}
              handleClientOfficeLocationChange={
                this.handleClientOfficeLocationChange
              }
            />
            {dataCount > 10 ? (
              <Pagination
                activePage={+pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </Tab>

          <Tab eventKey="2" title={`Inactive ${inactiveEmployees}`}>
            <EmployeeTable
              {...this.state}
              handleAction={this.handleAction}
              handleSort={this.handleSort}
              handleEditSubmit={this.handleEditSubmit}
              editWallet={this.editWallet}
              handleOpenEditWalletBalance={this.handleOpenEditWalletBalance}
              handleOfficeLocationChange={this.handleOfficeLocationChange}
              handleClientOfficeLocationChange={
                this.handleClientOfficeLocationChange
              }
            />
            {dataCount > 10 ? (
              <Pagination
                activePage={+pageNo}
                itemsCountPerPage={count}
                totalItemsCount={dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </Tab>
        </Tabs>
        <Modal
          size="md"
          show={addAmountPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <form className="add-new-dept" onSubmit={this.handleWalletRecharge}>
              <h3>ADD Amount</h3>
              <div className="form-group">
                <label className="cm_bold">Current Wallet Amount</label>
                <input
                  maxLength="75"
                  className="form-control cm_box"
                  type="text"
                  placeholder="Enter amount"
                  name="walletBalance"
                  value={walletBalance}
                  disabled
                />
              </div>
              <div
                className={`form-group ${
                  updateBalanceErrorMsg || editresMsg ? "input_error" : ""
                }`}
              >
                <label className="cm_bold">Recharge Amount</label>
                <input
                  maxLength="75"
                  className="form-control cm_box"
                  type="text"
                  placeholder="Enter amount"
                  name="updateBalance"
                  value={updateBalance}
                  onChange={this.handleChange}
                />
                {updateBalanceErrorMsg ? (
                  <span className="error_msg">{updateBalanceErrorMsg}</span>
                ) : null}
                {
                  <span className="res_error_msg">
                    {editresMsg ? editresMsg : ""}
                  </span>
                }
              </div>

              <div className="submit_button_margin mt-4">
                <button
                  type="submit"
                  className="btn btn-primary cm_btn3"
                  disabled={miniLoader}
                >
                  Save
                </button>
                {miniLoader ? <div className="mini_loader_dept"></div> : ""}
                <button
                  type="button"
                  className="btn btn-primary  cm_btn2"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          show={openEditWalletBalance}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <form
              className="add-new-dept"
              onSubmit={this.handleEditWalletRecharge}
            >
              <h3>Edit Amount</h3>
              <div className="form-group">
                <label className="cm_bold">Current Wallet Amount</label>
                <input
                  maxLength="75"
                  className="form-control cm_box"
                  type="text"
                  placeholder="Enter amount"
                  name="walletBalance"
                  value={walletBalance}
                  disabled
                />
              </div>
              <div
                className={`form-group ${
                  updateBalanceErrorMsg || editresMsg ? "input_error" : ""
                }`}
              >
                <label className="cm_bold">Edit Amount</label>
                <input
                  maxLength="75"
                  className="form-control cm_box"
                  type="text"
                  placeholder="Enter amount"
                  name="updateBalance"
                  value={updateBalance}
                  onChange={this.handleChange}
                />
                {updateBalanceErrorMsg ? (
                  <span className="error_msg">{updateBalanceErrorMsg}</span>
                ) : null}
                {
                  <span className="res_error_msg">
                    {editresMsg ? editresMsg : ""}
                  </span>
                }
              </div>

              <div className="submit_button_margin mt-4">
                <button
                  type="submit"
                  className="btn btn-primary cm_btn3"
                  disabled={miniLoader}
                >
                  Save
                </button>
                {miniLoader ? <div className="mini_loader_dept"></div> : ""}
                <button
                  type="button"
                  className="btn btn-primary  cm_btn2"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EmployeeList;

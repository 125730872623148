import { type } from "../helper/Constant";
import { regxExcel, REQUIRED, STATUS_VALUE_ZERO, VALID_EXCEL } from "./Constants";

export const handleUploadValidation = (props) => {
    let {
        excelSheet,
        dispatch,
        date,
        attendanceLocation,
        wantSendMail,
    } = props
    let validation = true;
    if (excelSheet === null) {
        validation = false
        dispatch({
            type: type, payload: {
                excelSheetUploadError: REQUIRED
            }
        })

    } else if (!excelSheet?.name.match(regxExcel)) {
        validation = false;
        dispatch({
            type: type, payload: {
                excelSheetUploadError: VALID_EXCEL
            }
        });
    } else {
        dispatch({
            type: type, payload: {
                excelSheetUploadError: ""
            }
        })
    }
    if (date === "" || date === undefined) {
        validation = false
        dispatch({
            type: type, payload: {
                dateErrorMsg: REQUIRED
            }
        })
    } else {
        dispatch({
            type: type, payload: {
                dateErrorMsg: ""
            }
        })
    }
    if (attendanceLocation === "" || attendanceLocation == null || attendanceLocation == undefined) {
        validation = false;
        dispatch({
            type: type, payload: {
                attendanceLocationErrorMsg: REQUIRED
            }
        })
    }
    else {
        dispatch({
            type: type, payload: {
                attendanceLocationErrorMsg: ""
            }
        })

    }
    if (wantSendMail === "" || wantSendMail == undefined) {
        validation = false
        dispatch({
            type: type, payload: {
                wantSendMailErrorMsg: REQUIRED
            }
        })
    } else {
        dispatch({
            type: type, payload: {
                wantSendMailErrorMsg: ""
            }
        })
    }
    return validation;
}

export const handleEditValidetion = (props) => {
    let { setStatus, dispatch, checkIn, checkOut } = props
    let validate = true
    if (setStatus === '' || setStatus === undefined) {
        dispatch({
            type: type, payload: {
                statusErrorMsg: REQUIRED
            }
        })
        validate = false
    }
    if (checkIn == "" || checkIn == undefined || checkIn == null || checkIn == STATUS_VALUE_ZERO) {
        validate = false;
        dispatch({
            type: type, payload: {
                checkInErrorMsg: REQUIRED
            }
        })
    }
    if (checkOut == "" || checkOut == undefined || checkOut == null || checkOut == STATUS_VALUE_ZERO) {
        validate = false;
        dispatch({
            type: type, payload: {
                checkOutErrorMsg: REQUIRED
            }
        })
    }
    return validate
}

export const handleValidation = (props) => {
    let {
        date,
        dispatch,
        employeeId,
        checkIn,
        checkOut,
        wantSendMail,
    } = props
    let validate = true;
    if (date === "" || date === undefined) {
        dispatch({
            type: type, payload: {
                dateErrorMsg: REQUIRED
            }
        })
        validate = false;
    } else {
        dispatch({
            type: type, payload: {
                dateErrorMsg: ''
            }
        })
    }
    if (employeeId === "" || employeeId === undefined) {
        validate = false;
        dispatch({
            type: type, payload: {
                employeeIdErrorMsg: REQUIRED
            }
        })
    } else {
        dispatch({
            type: type, payload: {
                employeeIdErrorMsg: ''
            }
        })
    }
    if (checkIn === "" || checkIn === undefined || checkIn === null || checkIn === STATUS_VALUE_ZERO) {
        validate = false;
        dispatch({
            type: type, payload: {
                checkInErrorMsg: REQUIRED
            }
        })
    }
    if (checkOut === "" || checkOut === undefined || checkOut === null || checkOut === STATUS_VALUE_ZERO) {
        validate = false;
        dispatch({
            type: type, payload: {
                checkOutErrorMsg: REQUIRED
            }
        })
    }
    if (wantSendMail === "" || wantSendMail === undefined || wantSendMail === null || wantSendMail === STATUS_VALUE_ZERO) {
        validate = false;
        dispatch({
            type: type, payload: {
                wantSendMailErrorMsg: REQUIRED
            }
        })
    }
    return validate;
}
import {
  ADD_EMP_RESUME_REQUEST,
  ADD_EMP_RESUME_SUCCESS,
  ADD_EMP_RESUME_FAILURE,
} from "./actionType";

import { addEmployeeResume } from "../../config/services/employeeServices";
import { getResumeByEmpId } from "./getResumeByEmpIdAction";
import ResponseFilter from "../../ResponseFilter";
const addEmpResume = (data, empId, setState, handleCancelButtonClick) => {
  return (dispatch) => {
    let params = `employeeId=${empId}&pageNo=${0}&count=${10}`
    dispatch(addEmpResumeRequest());
    addEmployeeResume(data)
      .then((res) => {
        let { status } = ResponseFilter(res)
        if (status) {
          dispatch(addEmpResumeSuccess(res.data));
          handleCancelButtonClick()
          setState({ resMsg: true, miniLoader: false })
          setTimeout(() => setState({ resMsg: false }), 3000);

          dispatch(getResumeByEmpId(params));
        }
        else {
          setState({ loader: false, resFailMessage: true, responseMessage: res?.data?.error?.responseMessage })
          setTimeout(() => setState({ resFailMessage: false }), 3000);

        }
      })
      .catch((error) => {
        dispatch(addEmpResumeFailure(error));
      });
  };
};

const addEmpResumeRequest = () => {
  return {
    type: ADD_EMP_RESUME_REQUEST,
  };
};
const addEmpResumeSuccess = (data) => {
  return {
    type: ADD_EMP_RESUME_SUCCESS,
    payload: data,
  };
};
const addEmpResumeFailure = (error) => {
  return {
    type: ADD_EMP_RESUME_FAILURE,
    payload: error,
  };
};
export {
  addEmpResume,
  addEmpResumeRequest,
  addEmpResumeSuccess,
  addEmpResumeFailure,
};

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ErrorMessage, InputField } from "../../helper/Constant";
import { PdfIcon } from "../../svg";
import { getFileNameFromUrl } from "../../../config/constants/Constants";

const formatKeyName = (keyName) => {
  if (!keyName) return "";
  return keyName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};
const UploadDeduction = (props) => {
  const {
    openUploadModal,
    closeModal,
    uploadFileKeyName,
    handleUploadFileChange,
    uploadFileErr,
    handleUploadFiles,
    deductionText,
    deductionAmountFile,
    state,
    setState,
  } = props;
  const formattedKeyName = formatKeyName(uploadFileKeyName);
  const [loadImg, setLoadImg] = useState(false);
  return (
    <Modal show={openUploadModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload {formattedKeyName} Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="mb-3">
            <h5 className="mb-2">{deductionText}</h5>
            <InputField
              type="file"
              id="deductionAmount"
              accept="application/pdf,.png, .jpeg, .jpg"
              placeholder="Enter Title"
              onChange={(e) => handleUploadFileChange(e, deductionAmountFile)}
            />
            {state[deductionAmountFile] && (
              <div className="upload_container">
                <span className="mt-2 mb-2">Previous Uploaded File:</span>

                <div className="ml-1">
                  {(typeof state[deductionAmountFile] === "string" &&
                    state[deductionAmountFile]
                      .toLowerCase()
                      .endsWith(".pdf")) ||
                  (typeof state[deductionAmountFile] !== "string" &&
                    state[deductionAmountFile].name
                      .toLowerCase()
                      .endsWith(".pdf")) ? (
                    <div className="d-flex align-items-center mt-2">
                      <div
                        style={{ width: "59px", height: "50px" }}
                        className="d-flex justify-content-center"
                      >
                        <PdfIcon />
                      </div>
                      <span>
                        {typeof state[deductionAmountFile] === "string"
                          ? getFileNameFromUrl(state[deductionAmountFile])
                          : state[deductionAmountFile].name}
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center mt-2">
                      <img
                        alt={
                          typeof state[deductionAmountFile] === "string"
                            ? state[deductionAmountFile]
                            : state[deductionAmountFile].name
                        }
                        src={
                          typeof state[deductionAmountFile] === "string"
                            ? state[deductionAmountFile]
                            : URL.createObjectURL(state[deductionAmountFile])
                        }
                        className="mr-2"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <span>
                        {typeof state[deductionAmountFile] === "string"
                          ? getFileNameFromUrl(state[deductionAmountFile])
                          : state[deductionAmountFile].name}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center flex-column">
        {loadImg ? (
          <div className="snippet" data-title="dot-elastic">
            <div className="stage position-relative">
              <div className="dot-elastic"></div>
            </div>
          </div>
        ) : (
          <div className="submit_button_margin mt-4">
            <button
              type="submit"
              onClick={() =>
                handleUploadFiles({
                  state,
                  setState,
                  closeModal,
                  setLoadImg,
                  newUpload:
                    state[deductionAmountFile] &&
                    !state[deductionAmountFile].name,
                })
              }
              className="btn btn-primary cm_btn3"
              disabled={loadImg || uploadFileErr}
            >
              Upload
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-primary cm_btn2"
              disabled={loadImg}
            >
              Cancel
            </button>
          </div>
        )}
        <div>{uploadFileErr && <ErrorMessage errMsg={uploadFileErr} />}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDeduction;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import pdfpng from "../../asset/imgs/pdfpng.png";
import { PDFReader } from "reactjs-pdf-reader-custom";
import { Modal as AntModal } from "antd";
import "../leaves/ApplyLeave.css";
import { ActionImage } from "./Constant";
import { downloadIcon } from "../../asset";
import videopng from "../../asset/imgs/play.png";
import "./Helper.css";

const ViewUploadedDocument = (props) => {
  const { show, data, handleClose, type } = props;
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const openDocument = (doc) => {
    setSelectedDocument(doc);
  };

  const closeDocument = () => {
    setSelectedDocument(null);
  };

  return (
    <>
      <AntModal
        title=""
        footer={false}
        centered
        className="upload-modal"
        visible={show}
        onCancel={handleClose}
      >
        <Modal.Header>
          <h3>Uploaded Document</h3>
        </Modal.Header>
        <Modal.Body className="text-center confirmation">
          {data && data.length > 0 && (
            <div className="document-container">
              {data.map((doc, index) => {
                const isPdf = doc.toLowerCase().endsWith(".pdf");
                const isImage =
                  doc.toLowerCase().endsWith(".jpeg") ||
                  doc.toLowerCase().endsWith(".jpg") ||
                  doc.toLowerCase().endsWith(".png");
                const isVideo = doc.toLowerCase().endsWith(".mp4");
                return (
                  ((type === "courier" && isVideo) || isPdf || isImage) && (
                    <div
                      key={index}
                      className="d-flex justify-content-between mb-3 file-container"
                    >
                      <div className="mr-3">
                        {isPdf ? (
                          <div className="d-flex">
                            <ActionImage
                              src={pdfpng}
                              alt={`pdfThumbnail_${index}`}
                              onClick={() => openDocument(doc)}
                            />
                            <span>{doc}</span>
                          </div>
                        ) : type === "courier" && isVideo ? (
                          <div className="d-flex">
                            <ActionImage
                              src={videopng}
                              alt={`pdfThumbnail_${index}`}
                              onClick={() => openDocument(doc)}
                            />
                            <span>{doc}</span>
                          </div>
                        ) : (
                          <div className="d-flex">
                            <img
                              src={doc}
                              alt={`imageThumbnail_${index}`}
                              style={{ width: "1.9rem" }}
                              onClick={() => openDocument(doc)}
                            />
                            <span>{doc}</span>
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        <span onClick={() => openDocument(doc)}>
                          <i className="fa fa-eye blue ml-4" title="View Doc" />
                        </span>
                        <a
                          href={doc}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-3"
                        >
                          <ActionImage
                            src={downloadIcon}
                            alt={`downloadIcon_${index}`}
                          />
                        </a>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          )}
        </Modal.Body>
      </AntModal>

      <AntModal
        title=""
        footer={false}
        centered
        className="sm_modal1"
        visible={selectedDocument}
        onCancel={closeDocument}
      >
        <Modal.Body
          className={`view_pdf ${
            loading && " d-flex justify-content-center align-items-center"
          } `}
        >
          {loading &&
            selectedDocument &&
            (selectedDocument.endsWith(".pdf") ||
              selectedDocument.endsWith(".PDF")) && (
              <div class="snippet" data-title="dot-elastic">
                <div class="stage">
                  <div class="dot-elastic"></div>
                </div>
              </div>
            )}
          {selectedDocument && (
            <>
              {(selectedDocument.endsWith(".pdf") ||
                selectedDocument.endsWith(".PDF")) && (
                <PDFReader
                  url={selectedDocument}
                  showAllPage={true}
                  onDocumentComplete={() => setLoading(false)}
                  width="700"
                />
              )}
              {(selectedDocument.endsWith(".jpeg") ||
                selectedDocument.endsWith(".jpg") ||
                selectedDocument.endsWith(".png") ||
                selectedDocument.endsWith(".JPEG") ||
                selectedDocument.endsWith(".JPG") ||
                selectedDocument.endsWith(".PNG")) && (
                <div className="d-flex justify-content-center">
                  <img
                    src={selectedDocument}
                    alt="document"
                    style={{ width: "20rem", height: "20rem" }}
                  />
                </div>
              )}
              {selectedDocument.endsWith(".mp4") && (
                <div className="d-flex justify-content-center">
                  <video controls style={{ width: "20rem", height: "20rem" }}>
                    <source src={selectedDocument} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="submit_button_margin">
          <div className="mt-4">
            <button
              type="button"
              onClick={closeDocument}
              className="btn btn-primary cm_btn2"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </AntModal>
    </>
  );
};

export default ViewUploadedDocument;

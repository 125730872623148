import React from "react";
import { CONSTANTS } from "../../config";
import _ from "lodash";
import Pagination from "react-js-pagination";
import "./team.css";
import { calculatePercentage } from "./teamFunction";
import { createSelectItems } from "../helper/Constant";
import { Select } from "antd";
import { MONTH_ARRAY, statusMon } from "../leaves/Constants";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const { Option } = Select;
export default function CompareTeam(props) {
  const {
    count,
    dataCount,
    sortType,
    sortField,
    pageNo,
    handlePageChange,
    handleSort,
    teamCompareData,
    searchMonth,
    searchYear,
    handleMonth,
    handleYear,
  } = props;
  const handleCancel = () => {
    props.reverseView();
  };
  return (
    <div>
      <div className="cm_breadcrumb cm_bold mb-5">
        <ul>
          <li
            style={{
              textTransform: "uppercase",
              color: "black",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            TEAM MANAGEMENT
            <div className="cm_breadcrumb_arrow"></div>
          </li>

          <li>COMPARE TEAM</li>
        </ul>
      </div>
      <div className="filters justify-content-end mb-5">
        <Select
          className="select_year_cal"
          value={
            searchMonth
              ? statusMon(Number(searchMonth))
              : statusMon(new Date().getMonth() + 1)
          }
          defaultValue="Select Month"
          name="searchMonth"
          onChange={(e) => handleMonth(e)}
          allowClear={searchMonth ? true : false}
        >
          {MONTH_ARRAY.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>

        <Select
          className="select_year_cal"
          value={searchYear ? searchYear : new Date().getFullYear()}
          defaultValue="Select Year"
          name="searchYear"
          onChange={handleYear}
          allowClear={searchYear ? true : false}
        >
          {createSelectItems()}
        </Select>
      </div>
      <div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Team Name</th>
                <th>Attendance</th>
                <th>Project</th>
                <th scope="col">Leave</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  borderBottom: "1px solid #dee2e6",
                  backgroundColor: "aliceblue",
                }}
              >
                <td></td>
                <td></td>
                <td>
                  <tr className="nested_table">
                    <td>
                      Present
                      <i
                        className={
                          sortType == SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "presentAttendance"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => handleSort("presentAttendance")}
                      ></i>
                    </td>
                    <td>
                      Late
                      <i
                        className={
                          sortType == SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "lateAttendance"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => handleSort("lateAttendance")}
                      ></i>
                    </td>
                    <td>
                      Other
                      <i
                        className={
                          sortType == SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "otherAttendance"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => handleSort("otherAttendance")}
                      ></i>
                    </td>
                  </tr>
                </td>
                <td>
                  <tr className="nested_table">
                    <td>
                      In Project
                      <i
                        className={
                          sortType == SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "notInProjectCount"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => handleSort("notInProjectCount")}
                      ></i>
                    </td>
                    <td>
                      Not In Project
                      <i
                        className={
                          sortType == SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "inProjectCount"
                            ? "fa fa-sort-numeric-asc ml-2"
                            : "fa fa-sort-numeric-desc ml-2"
                        }
                        onClick={() => handleSort("inProjectCount")}
                      ></i>
                    </td>
                  </tr>
                </td>
                <td className="text-center">
                  On leave
                  <i
                    className={
                      sortType == SORT_TYPE_CONSTANTS.Asscending &&
                      sortField === "updatedLeave"
                        ? "fa fa-sort-numeric-asc ml-2"
                        : "fa fa-sort-numeric-desc ml-2"
                    }
                    onClick={() => handleSort("updatedLeave")}
                  ></i>
                </td>
              </tr>
              {!_.isEmpty(teamCompareData)
                ? teamCompareData.map((team, i) => (
                    <tr key="">
                      <td>{i + 1 + (pageNo - 1) * count}</td>
                      <td>
                        <span className="cm_col">
                          {`${team.teamName} (${team?.project[0]?.totalMembersInTeam})`}
                        </span>
                      </td>
                      <td>
                        <tr className="nested_table">
                          <td>
                            {team?.attendance?.presentAttendance > 0
                              ? `${team?.attendance?.presentAttendance} %`
                              : 0}
                          </td>
                          <td>
                            {team?.attendance?.lateAttendance > 0
                              ? `${team?.attendance?.lateAttendance} %`
                              : 0}
                          </td>
                          <td>
                            {team?.attendance?.otherAttendance > 0
                              ? `${team?.attendance?.otherAttendance} %`
                              : 0}
                          </td>
                        </tr>
                      </td>
                      <td>
                        <tr className="nested_table">
                          <td>
                            {team?.project[0]?.inProjectCount > 0
                              ? `${team?.project[0]?.inProjectCount} %`
                              : 0}
                          </td>
                          <td>
                            {team?.project[0]?.notInProjectCount > 0
                              ? `${team?.project[0]?.notInProjectCount} %`
                              : 0}
                          </td>
                        </tr>
                      </td>
                      <td className="text-center">
                        {team?.updatedLeave?.totalLeaveCount}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          {teamCompareData.length > 0 ? (
            ""
          ) : (
            <h3 className="text-center"> No data available</h3>
          )}
        </div>
      </div>
    </div>
  );
}

import _ from "lodash";
import React, { useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  REQUIRED,
  Toastify,
  emailClick,
  handleIsoDate,
  reducer,
  type,
} from "../../helper/Constant";
import { getEmpProfileByAdmin } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import ApplyTempCompOffModel from "./ApplyTempCompOffModel";
import {
  applyLeaveByEmployee,
  getEmployeeListByManager,
} from "../../../config/services/employeeServices";
import { EndDate_Greater } from "../Constants";
import { Loader } from "../../svg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ResponseFilter from "../../../ResponseFilter";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import EditTempCompOff from "./EditTempCompOff";
import { editTempCompOffByAdminAction } from "../../../redux/actions/editTempCompOffByAdminAction";
import { tempCompDeleteValdiation } from "../LeaveValidation";
const initialState = {
  tempCompOff: [],
  tempCompOffDetail: [],
  openApplyTempCompOff: false,
  leaveType1: "",
  leaveType1ErrorMsg: "",
  halfOfTheDay: "",
  halfOfTheDayErrMsg: "",
  startDate1: "",
  start1dateErrorMsg: "",
  endDate1: "",
  enddate1ErrorMsg: "",
  emails: "",
  emailError: "",
  reply: "",
  replyErrorMsg: "",
  value: "",
  inputEmail: "",
  emails: [],
  id: "",
  resMsg: false,
  resFailMsg: false,
  responseMessage: "",
  successMessage: "",
  loader: false,
  editorState: EditorState.createEmpty(),
  employeeList: [],
  openEditTempCompOff: false,
  numberOfCompOff: "",
  numberOfCompOffErrMsg: "",
  tempCompOffId: "",
  isDelete: false,
  toalTempCompOff: "",
};
export default function CompOffLeaveModel() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchToAction = useDispatch();
  const {
    tempCompOff,
    openApplyTempCompOff,
    tempCompOffDetail,
    leaveType1,
    leaveType1ErrorMsg,
    halfOfTheDay,
    halfOfTheDayErrMsg,
    start1dateErrorMsg,
    startDate1,
    endDate1,
    enddate1ErrorMsg,
    emails,
    emailError,
    value,
    reply,
    replyErrorMsg,
    inputEmail,
    resMsg,
    resFailMsg,
    responseMessage,
    successMessage,
    loader,
    editorState,
    employeeList,
    openEditTempCompOff,
    numberOfCompOff,
    numberOfCompOffErrMsg,
    tempCompOffId,
    isDelete,
    toalTempCompOff,
  } = state;
  let { id, byAdmin, isAdmin } = useParams();
  const url = byAdmin === "1" ? `/admin-leave/leave_view/${id}` : "/leaves";

  const onEditorStateChange = (editorState) => {
    dispatch({
      type: type,
      payload: {
        editorState: editorState,
        reasonErMsg: "",
      },
    });
  };

  useEffect(() => {
    getData();
    getEmployeeList();
    // eslint-disable-next-line
  }, []);

  const getEmployeeList = () => {
    getEmployeeListByManager("").then((res) => {
      let { status } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            employeeList: res.data.responseData.employeesList,
          },
        });
      }
    });
  };

  const handleEditTempCompOff = (props) => {
    const { tempCompOffId, isDelete, toalTempCompOff } = props;
    dispatch({
      type: type,
      payload: {
        openEditTempCompOff: true,
        tempCompOffId: tempCompOffId,
        isDelete: isDelete,
        toalTempCompOff: toalTempCompOff,
      },
    });
  };

  const getData = () => {
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    dispatchToAction(
      getEmpProfileByAdmin({
        useDispatch: dispatch,
        id: id,
      })
    );
  };

  const handleOpenApplyTempCompOff = (e) => {
    dispatch({
      type: type,
      payload: {
        openApplyTempCompOff: !openApplyTempCompOff,
        tempCompOffDetail: e,
        tempCompOffId: e._id,
      },
    });
  };
  const handleleaveTypeChange = (e) => {
    dispatch({
      type: type,
      payload: {
        responseMessage: "",
        leaveType1: parseInt(e),
        leaveType1ErrorMsg: "",
      },
    });
  };

  const handleSelectHalfChange = (e) => {
    dispatch({
      type: type,
      payload: {
        halfOfTheDay: e,
        halfOfTheDayErrMsg: "",
      },
    });
  };

  const onStartChange = (e) => {
    dispatch({
      type: type,
      payload: {
        startDate1: moment(e).toISOString(),
        start1dateErrorMsg: "",
      },
    });
  };

  const onEndChange = (e) => {
    dispatch({
      type: type,
      payload: {
        endDate1: moment(e).toISOString(),
        enddate1ErrorMsg: "",
      },
    });
  };

  const handleEmailChange = (e) => {
    dispatch({
      type: type,
      payload: {
        emails: e,
        emailError: null,
      },
    });
  };

  const handleChange = (e) => {
    dispatch({
      type: type,
      payload: {
        [e.target.name]: e.target.value,
        responseMessage: "",
        replyErrorMsg: "",
        compOffGivenReasonErrorMsg: "",
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        openApplyTempCompOff: false,
        leaveId: "",
        loader: false,
        reply: "",
        leaveType1: "",
        leaveType1ErrorMsg: "",
        halfOfTheDay: "",
        halfOfTheDayErrMsg: "",
        startDate1: "",
        start1dateErrorMsg: "",
        endDate1: "",
        enddate1ErrorMsg: "",
        email: "",
        emails: [],
        emailInCC: "",
        inputEmail: "",
        emailError: "",
        replyErrorMsg: "",
        startDate: "",
        endDate: "",
        id: "",
        editorState: EditorState.createEmpty(),
        openEditTempCompOff: false,
        numberOfCompOff: "",
        tempCompOffId: "",
        numberOfCompOffErrMsg: "",
      },
    });
  };

  const handleApplyForClientOfficeValidition = () => {
    let validate = true;
    let k = convertToRaw(editorState.getCurrentContent());

    if (inputEmail) {
      if (emails.length === 0 || emails === undefined) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            emailError: emailClick,
          },
        });
      }
      if (inputEmail.length > 0) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            emailError: emailClick,
          },
        });
      }
    }
    if (leaveType1 == "" || leaveType1 == undefined || leaveType1 == null) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          leaveType1ErrorMsg: REQUIRED,
        },
      });
    }
    if (
      startDate1 == "" ||
      startDate1 == "undefined" ||
      startDate1 == null ||
      startDate1 == 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          start1dateErrorMsg: REQUIRED,
        },
      });
    }
    if (leaveType1 !== 10) {
      if (
        endDate1 == "" ||
        endDate1 == "undefined" ||
        endDate1 == null ||
        endDate1 == 0
      ) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            enddate1ErrorMsg: REQUIRED,
          },
        });
      } else if (endDate1 < startDate1) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            enddate1ErrorMsg: EndDate_Greater,
          },
        });
      }
    }
    if (leaveType1 == 10) {
      if (
        halfOfTheDay === "" ||
        halfOfTheDay === undefined ||
        halfOfTheDay === 0
      ) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            halfOfTheDayErrMsg: REQUIRED,
          },
        });
      }
    }
    if (k.blocks.every((b) => b.text.trim() === "")) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          replyErrorMsg: REQUIRED,
        },
      });
    }
    return validate;
  };

  const handleApplyForCompOffSubmit = (event) => {
    event.preventDefault();
    let startD = new Date(startDate1);
    let endD = new Date(endDate1);
    let formattedDate1 =
      startD.getFullYear() +
      "-" +
      (startD.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      startD.getDate().toString().padStart(2, "0");
    let formattedDate2 =
      endD.getFullYear() +
      "-" +
      (endD.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      endD.getDate().toString().padStart(2, "0");
    let leaveReason = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    if (handleApplyForClientOfficeValidition()) {
      let resObj = {
        leaveMsg: leaveReason,
        leaveType: leaveType1,
        startDate: formattedDate1,
        endDate: leaveType1 == "10" ? formattedDate1 : formattedDate2,
        emailInCC: JSON.stringify(emails),
        halfOfTheDay: leaveType1 == "10" ? halfOfTheDay : 0,
        tempCompOffId: tempCompOffId,
      };
      dispatch({
        type: type,
        payload: {
          loader: true,
          resApplyForClientMsg: true,
          successMessage: "CompOff Applied",
        },
      });
      applyLeaveByEmployee(resObj).then((res) => {
        if (res.data.statusCode === 1) {
          getData();
          closeModal();
          dispatch({
            type: type,
            payload: {
              resMsg: true,
            },
          });
        } else {
          dispatch({
            type: type,
            payload: {
              miniLoader: false,
              loader: false,
              resFailMsg: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
        }
        getData();
        closeModal();
      });
    }
    setTimeout(() => {
      dispatch({
        type: type,
        payload: {
          resApplyForClientMsg: false,
          resFailMsg: false,
          resMsg: false,
        },
      });
    }, 3000);
  };

  const handleCompOffDayChange = (e) => {
    dispatch({
      type: type,
      payload: {
        numberOfCompOff: e.target.value,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      tempCompDeleteValdiation({
        isDelete: isDelete,
        numberOfCompOff: numberOfCompOff,
        dispatch: dispatch,
        toalTempCompOff: toalTempCompOff,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      const params = {
        employeeId: id,
        tempCompOffId: tempCompOffId,
        numberOFCompOff: isDelete
          ? toalTempCompOff - numberOfCompOff
          : numberOfCompOff,
      };
      dispatchToAction(
        editTempCompOffByAdminAction({
          useDispatch: dispatch,
          params: params,
          handleClose: closeModal,
          getData: getData,
          isDelete: isDelete,
        })
      );
    }
  };

  return (
    <div className="body_container cm_bold">
      {loader ? (
        <Loader />
      ) : (
        <>
          <Toastify
            resMsg={resMsg}
            text={successMessage}
            resMsgFail={resFailMsg}
            responseMessage={responseMessage}
          />

          <div className="cm_breadcrumb cm_bold">
            <ul>
              <li>
                <Link to={url}>LEAVES RECORDS</Link>
                <div className="cm_breadcrumb_arrow"></div>
              </li>
              <li>Compensatroy Off Leave List</li>
            </ul>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th>S.No</th>
                  <th>Number Of CompOff</th>
                  <th>Given Date</th>
                  <th>Expiry Date </th>
                  <th>Action </th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(tempCompOff)
                  ? tempCompOff
                      .filter((data) => {
                        return (
                          data.numberOFCompOff > 0 &&
                          data.expireDate >= handleIsoDate(new Date())
                        );
                      })
                      .map((data, i) => (
                        <tr key={data._id}>
                          <td>{i + 1}</td>
                          <td>{data.numberOFCompOff}</td>
                          <td>{moment(data.givenDate).format("DD-MM-YYYY")}</td>
                          <td>
                            {moment(data.expireDate).format("DD-MM-YYYY")}
                          </td>
                          {isAdmin === "false" ? (
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => handleOpenApplyTempCompOff(data)}
                              >
                                Apply
                              </button>
                            </td>
                          ) : (
                            <td>
                              <span
                                onClick={() =>
                                  handleEditTempCompOff({
                                    tempCompOffId: data._id,
                                    isDelete: false,
                                  })
                                }
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                  title="Edit Temp Comp Off"
                                ></i>
                              </span>
                              <span
                                onClick={() =>
                                  handleEditTempCompOff({
                                    tempCompOffId: data._id,
                                    isDelete: true,
                                    toalTempCompOff: data.numberOFCompOff,
                                  })
                                }
                              >
                                <i
                                  className="fa fa-trash red ml-4"
                                  aria-hidden="true"
                                  title="Delete Temp Comp Off"
                                ></i>
                              </span>
                            </td>
                          )}
                        </tr>
                      ))
                  : ""}
              </tbody>
            </table>
            {tempCompOff &&
            tempCompOff.filter((data) => {
              return (
                data.numberOFCompOff > 0 &&
                data.expireDate >= handleIsoDate(new Date())
              );
            }).length > 0 ? (
              ""
            ) : (
              <h3 className="text-center">No data available</h3>
            )}
          </div>
          <ApplyTempCompOffModel
            openApplyTempCompOff={openApplyTempCompOff}
            tempCompOffDetail={tempCompOffDetail}
            handleClose={closeModal}
            handleleaveTypeChange={handleleaveTypeChange}
            leaveType1={leaveType1}
            leaveType1ErrorMsg={leaveType1ErrorMsg}
            handleSelectHalfChange={handleSelectHalfChange}
            halfOfTheDay={halfOfTheDay}
            halfOfTheDayErrMsg={halfOfTheDayErrMsg}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            startDate1={startDate1}
            start1dateErrorMsg={start1dateErrorMsg}
            endDate1={endDate1}
            enddate1ErrorMsg={enddate1ErrorMsg}
            emails={emails}
            emailError={emailError}
            value={value}
            handleEmailChange={handleEmailChange}
            handleChange={handleChange}
            reply={reply}
            replyErrorMsg={replyErrorMsg}
            handleApplyForCompOffSubmit={handleApplyForCompOffSubmit}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            employeeList={employeeList}
          />
          <EditTempCompOff
            show={openEditTempCompOff}
            numberOfCompOff={numberOfCompOff}
            employeeId={id}
            handleClose={closeModal}
            handleCompOffDayChange={handleCompOffDayChange}
            numberOfCompOffErrMsg={numberOfCompOffErrMsg}
            handleSubmit={handleSubmit}
            isDelete={isDelete}
            toalTempCompOff={toalTempCompOff}
          />
        </>
      )}
    </div>
  );
}

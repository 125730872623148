import {
    GET_CANDIDATE_FEEDBACK_REQUEST,
    GET_CANDIDATE_FEEDBACK_SUCCESS,
    GET_CANDIDATE_FEEDBACK_FAILURE,
} from "./actionType";

import { getCandidateFeedBack } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getCandidateFeedBackAction = (query) => {
    return (dispatch) => {
        dispatch(getCandidateFeedBackRequest());
        getCandidateFeedBack(query)
            .then((res) => {
                let { status, resData } = ResponseFilter(res);
                if (status) {
                    dispatch(getCandidateFeedBackSuccess(res.data.responseData));
                } else {
                    dispatch(getCandidateFeedBackFailure(res.data));
                }
            })
            .catch((error) => {
                dispatch(getCandidateFeedBackFailure(error));
            });
    };
};

const getCandidateFeedBackRequest = () => {
    return {
        type: GET_CANDIDATE_FEEDBACK_REQUEST,
    };
};

const getCandidateFeedBackSuccess = (data) => {
    return {
        type: GET_CANDIDATE_FEEDBACK_SUCCESS,
        payload: data,
    };
};

const getCandidateFeedBackFailure = (error) => {
    return {
        type: GET_CANDIDATE_FEEDBACK_FAILURE,
        payload: error,
    };
};
export {
    getCandidateFeedBackAction,
    getCandidateFeedBackRequest,
    getCandidateFeedBackSuccess,
    getCandidateFeedBackFailure,
};

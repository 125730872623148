const mailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const verifyMail = (email) => {
  if (email.trim() === "") return 1;
  else if (!email.match(mailRegex)) return 2;
  else return 0;
};

export { verifyMail };

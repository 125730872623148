import React from "react";
import _ from "lodash";
import {
  addEmployeeDataInOrganization,
  deleteEmployeeDataInOrganization,
  editEmployeeDataInOrganization,
  updateEmployeeInOrganization,
} from "../../../config/services/employeeServices";
import ResponseFilter from "../../../ResponseFilter";
import { ErrorMessage } from "../../helper/Constant";
import useEmployeeData from "../OrganizationChartUtils/EmployeeData";
import { EmpAddSuccess } from "../../helper/String";
import { Form, Modal } from "react-bootstrap";
import {
  handleOrgChartActionValidation,
  handleResponse,
} from "../OrganizationChartUtils/OrganizationConstants";
import { Select } from "antd";

const { Option } = Select;

const AddEditEmployeeModal = (props) => {
  const {
    fetchData,
    show,
    action,
    openModal,
    setOpenModal,
    delEmpId,
    handleClose,
    setResponse,
    setResponseMessage,
    setToDelId,
    setSuccess,
    setSuccessMessage,
    delEmpParentId,
    data,
    employeeList,
    rootId,
    setLoader,
    leadsArray,
    employee,
  } = props;

  const {
    employeeId,
    setEmployeeId,
    parentId,
    setParentId,
    setError,
    errorMessage,
    setErrorMessage,
    handleSelectEmployeeChange,
    onHide,
    reportingManagerError,
    setReportingMangerError,
    reportingManagerErrMsg,
    setReportingMangerErrMsg,
    employeeData,
    handleSelectMultipleEmployeeChange,
  } = useEmployeeData(setOpenModal, openModal);

  const handleSubmit = async () => {
    if (
      handleOrgChartActionValidation({
        employeeId,
        setErrorMessage,
        setError,
        setReportingMangerError,
        parentId,
        data,
        setReportingMangerErrMsg,
        delEmpId,
      })
    ) {
      setLoader(true);
      try {
        if (action === "Add") {
          for (let id of employeeData) {
            const params = {
              id: id,
              parentId: parentId,
            };
            const res = await addEmployeeDataInOrganization(params);
            let { status } = ResponseFilter(res);

            if (status) {
              setSuccess(true);
              setSuccessMessage(EmpAddSuccess);
              onHide();
              fetchData();
              handleResponse({
                setSuccess,
                setSuccessMessage,
                setResponse,
                setResponseMessage,
              });
            } else {
              setResponse(true);
              setResponseMessage(res.data.error.responseMessage);
              onHide();
              fetchData();
            }
          }
        } else {
          const params = {
            oldId: delEmpId,
            newId: employeeId,
          };
          const foundEmployee = data.find(
            (employee) => employee.id === employeeId
          );
          const newParentId = foundEmployee
            ? foundEmployee.parentId
            : delEmpParentId;

          const res = await editEmployeeDataInOrganization(params);
          let { status } = ResponseFilter(res);
          if (status) {
            let updateParams = {
              id: employeeId,
              parentId:
                rootId === employeeId
                  ? ""
                  : delEmpId === newParentId
                  ? data.find((employee) => employee.id === delEmpId).parentId
                  : newParentId,
            };

            const updateRes = await updateEmployeeInOrganization(updateParams);
            let updateStatus = ResponseFilter(updateRes).status;

            if (updateStatus) {
              const deleteParams = {
                params: {
                  id: delEmpId?.toString(),
                },
              };
              const deleteRes = await deleteEmployeeDataInOrganization(
                deleteParams
              );
              let deleteStatus = ResponseFilter(deleteRes).status;

              if (deleteStatus) {
                setSuccess(true);
                setSuccessMessage("Member deleted");
                onHide();
                fetchData();
              } else {
                setResponse(true);
                setResponseMessage(deleteRes.data.error.responseMessage);
                fetchData();
              }
            }
          } else {
            onHide();
            fetchData();
            setResponse(true);
            setResponseMessage(res.data.error.responseMessage);
          }
        }
      } catch (error) {
        setResponse(true);
        setResponseMessage(String(error));
      } finally {
        setLoader(false);
        handleResponse({
          setSuccess,
          setSuccessMessage,
          setResponse,
          setResponseMessage,
        });
        if (handleClose) {
          if (setToDelId) {
            setToDelId(employeeId);
          }
          handleClose();
        }
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header>
        <p className="cm_bold">{action} Employee</p>
      </Modal.Header>
      {action === "Edit" && (
        <strong className="text-muted ml-3 mr-2">
          *This employee has subordinate/s. Kindly assign new reporting manager
          to the subordinate/s before deleting this employee.
        </strong>
      )}
      <Modal.Body className="text-center confirmation">
        <Form.Group controlId="employeeId">
          <Form.Label className="d-flex justify-content-start text-dark">
            Select Employee
          </Form.Label>
          {action === "Add" ? (
            <Select
              showSearch
              mode="multiple"
              value={employeeData ? employeeData : "Select Employee"}
              style={{ width: "100%", height: "auto" }}
              className="select_emp_leave mr-4 mb-2"
              placeholder="Select Employee"
              name="employeeId"
              optionFilterProp="key"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) =>
                handleSelectMultipleEmployeeChange(e, setErrorMessage)
              }
            >
              {!_.isEmpty(employeeList)
                ? employeeList
                    .sort((a, b) => {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.firstName > b.firstName) {
                        return 1;
                      }
                      return 0;
                    })
                    .filter((emp) => {
                      return (
                        !leadsArray.includes(emp.empId) &&
                        !data.some((item) => item.id === emp.empId)
                      );
                    })
                    .map(
                      (emp) =>
                        emp.status === 1 && (
                          <Option
                            key={`${emp.firstName} ${emp.lastName}`}
                            value={emp.empId}
                          >
                            ({emp.empId}) {emp.firstName} {emp.lastName}
                          </Option>
                        )
                    )
                : ""}
            </Select>
          ) : (
            <Form.Control
              className={`mt-3 ${errorMessage && "border-danger "}`}
              as="select"
              name="employeeId"
              value={employeeId}
              onChange={(e) =>
                handleSelectEmployeeChange(
                  e.target.value,
                  setEmployeeId,
                  setErrorMessage
                )
              }
            >
              <option value="">Select Name</option>
              {!_.isEmpty(data) &&
                data
                  .filter((employee) => employee.id !== delEmpId)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((employee) => (
                    <option key={`${employee.name} `} value={employee.id}>
                      {`${employee.name} (${employee.id})`}
                    </option>
                  ))}
            </Form.Control>
          )}
          <div className="d-flex justify-content-start pl-2">
            <ErrorMessage errMsg={errorMessage} />
          </div>
        </Form.Group>
        {!delEmpId && data.length > 0 && (
          <Form.Group controlId="parentId">
            <Form.Label className="d-flex justify-content-start text-dark">
              Select Reporting Manager
            </Form.Label>
            <Form.Control
              as="select"
              name="parentId"
              className={`mt-3 ${reportingManagerError && "border-danger "}`}
              value={parentId ? parentId : "Reporting Manager"}
              onChange={(e) =>
                handleSelectEmployeeChange(
                  e.target.value,
                  setParentId,
                  setReportingMangerError
                )
              }
            >
              <option value="Select Name">Select Name</option>
              {!_.isEmpty(data)
                ? data
                    .filter((employee) => employee.id !== employeeId)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((employee) => (
                      <option key={`${employee.name}`} value={employee.id}>
                        {`${employee.name} (${employee.id})`}
                      </option>
                    ))
                : ""}
            </Form.Control>
            <div className="d-flex justify-content-start pl-2">
              <ErrorMessage errMsg={reportingManagerErrMsg} />
            </div>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          <button className="btn btn-secondary cm_btn3" onClick={handleSubmit}>
            {action}
          </button>
          <button
            type="button"
            onClick={onHide}
            className="btn text-secondary border border-dark cm_btn3"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditEmployeeModal;

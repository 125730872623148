import React from 'react'
import { Select } from 'antd'
import moment from 'moment'
import { Modal } from "react-bootstrap"
import _ from 'lodash'
const { Option } = Select
export default function EditEmployeeSalary(props) {
    return (

        <Modal centered show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title><b>EDIT EMPLOYEE SALARY</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className={`assign_device ${props.miniLoader ? "admin_attendance" : ""}`}>
                    <div className="row">
                        <div className={`form-group col-12 ${props.employeeCtcErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">CTC</label>
                            <input
                                maxLength="50"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="Current CTC"
                                name="employeeCtc"
                                value={props?.employeeCtc ? props?.employeeCtc : ""}
                                onChange={props.handleChange}
                            />
                            {props.employeeCtcErrorMessage ? (
                                <span className="error_msg">{props.employeeCtcErrorMessage}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className='row'>
                        <div className={`form-group col-6 ${props.employeeAccountNumberErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">Account Number</label>
                            <input
                                maxLength="16"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="Account Number"
                                name="employeeAccountNumber"
                                value={props?.employeeAccountNumber ? props?.employeeAccountNumber : ""}
                                onChange={props.handleChange}
                            />
                            {props.employeeAccountNumberErrorMessage ? (
                                <span className="error_msg">{props.employeeAccountNumberErrorMessage}</span>
                            ) : null}
                        </div>

                        <div className={`form-group col-6 ${props.employeeUanNumberErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">UAN Number</label>
                            <input
                                maxLength="12"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="UAN Number"
                                name="employeeUanNumber"
                                value={props?.employeeUanNumber ? props?.employeeUanNumber : ""}
                                onChange={props.handleChange}
                            />

                            {props.employeeUanNumberErrorMessage ? (
                                <span className="error_msg">{props.employeeUanNumberErrorMessage}</span>
                            ) : null}
                        </div>

                    </div>


                    <div className='row'>
                        <div className={`form-group col-6 ${props.employeePancardNumberErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">Pancard Number</label>
                            <input
                                maxLength="10"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="Pan Card Number"
                                name="employeePancardNumber"
                                value={props?.employeePancardNumber ? props?.employeePancardNumber : ""}
                                onChange={props.handleChange}
                            />
                            {props.employeePancardNumberErrorMessage ? (
                                <span className="error_msg">{props.employeePancardNumberErrorMessage}</span>
                            ) : null}
                        </div>


                        <div className={`form-group col-6  ${props.employeeBankIFSCCodeErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">IFSC Code</label>
                            <input
                                maxLength="11"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="IFSC Code"
                                name="employeeBankIFSCCode"
                                value={props?.employeeBankIFSCCode ? props?.employeeBankIFSCCode : ""}
                                onChange={props.handleChange}
                            />

                            {props.employeeBankIFSCCodeErrorMessage ? (
                                <span className="error_msg">{props.employeeBankIFSCCodeErrorMessage}</span>
                            ) : null}
                        </div>

                    </div>
                    <div className='row'>
                        <div className={`form-group col-6 ${props.employeeTdsErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">TDS</label>
                            <input
                                maxLength="50"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="TDS"
                                name="employeeTds"
                                value={props?.employeeTds ? props?.employeeTds : ""}
                                onChange={props.handleChange}
                            />
                            {props.employeeTdsErrorMessage ? (
                                <span className="error_msg">{props.employeeTdsErrorMessage}</span>
                            ) : null}
                        </div>
                        <div className={`form-group col-6  ${props.employeeLoanErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">Loan Amount</label>
                            <input
                                maxLength="50"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="Loan Amount"
                                name="employeeLoan"
                                value={props?.employeeLoan ? props?.employeeLoan : ""}
                                onChange={props.handleChange}
                            />

                            {props.employeeLoanErrorMessage ? (
                                <span className="error_msg">{props.employeeLoanErrorMessage}</span>
                            ) : null}
                        </div>

                    </div>
                    <div className="row">
                        <div
                            className={`form-group col-6 `}
                        >
                            <label className="cm_bold">PF</label>

                            <Select
                                name="isAvailedPF"
                                value={props?.isAvailedPF}
                                onChange={props.handlePfChange}
                                disabled={props.miniLoader}
                            >
                                <Option value={1}>Yes</Option>
                                <Option value={2}>No</Option>
                            </Select>
                        </div>

                        <div className={`form-group col-6 ${props.ctcVariableErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">CTC Variable</label>
                            <input
                                maxLength="50"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="CTC Variable"
                                name="ctcVariable"
                                value={props?.ctcVariable ? props?.ctcVariable : ""}
                                onChange={props.handleChange}
                            />
                            {props.ctcVariableErrorMessage ? (
                                <span className="error_msg">{props.ctcVariableErrorMessage}</span>
                            ) : null}
                        </div>
                    </div>

                    <div className="submit_button_margin mt-4">
                        {props.miniLoader ?
                            <div className="cover-spin1"></div>
                            : ''}
                        <button
                            type="button"
                            onClick={props.handleEditSalarySubmit}
                            className="btn btn-primary cm_btn3"
                            disabled={props.miniLoader}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={props.handleClose}
                            className="btn btn-primary cm_btn2"
                            disabled={props.miniLoader}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

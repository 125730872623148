import ResponseFilter from "../../ResponseFilter";
import { addEmployeeOnHold } from "../../config/services/employeeServices";
import {
    ADD_EMPLOYEE_ON_HOLD_REQUEST,
    ADD_EMPLOYEE_ON_HOLD_SUCCESS,
    ADD_EMPLOYEE_ON_HOLD_FAILURE,
} from "./actionType";

export default function addEmployeeOnHoldAction(props) {
    let { useDispatch, params, handleClose } = props
    return (dispatch) => {
        dispatch(addEmployeeOnHoldActionRequest());
        addEmployeeOnHold(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resMsg: true,
                        successMessage: "Employee On Hold Added",
                    }
                })
                handleClose();
                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resMsg: false,
                        }
                    })
                }, 4000)
            } else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false
                    }
                })
            }
        })
            .catch((error) => {
                dispatch(addEmployeeOnHoldActionFailure(error));
            });
    };
};

const addEmployeeOnHoldActionRequest = () => {
    return {
        type: ADD_EMPLOYEE_ON_HOLD_REQUEST,
    };
};

const addEmployeeOnHoldActionSuccess = (data) => {
    return {
        type: ADD_EMPLOYEE_ON_HOLD_SUCCESS,
        payload: data,
    };
};

const addEmployeeOnHoldActionFailure = (error) => {
    return {
        type: ADD_EMPLOYEE_ON_HOLD_FAILURE,
        payload: error,
    };
};
export {
    addEmployeeOnHoldAction,
    addEmployeeOnHoldActionRequest,
    addEmployeeOnHoldActionSuccess,
    addEmployeeOnHoldActionFailure,
};
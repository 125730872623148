import React from 'react'
import { Modal } from "react-bootstrap"
import _ from "lodash";
import { halfTypeValue, leaveValue } from '../Constants';
import { Select } from 'antd';
import Calendar from "react-calendar";
import TextEditor from '../../../hoc/textEditor/TextEditor';
const { Option } = Select;


export function ApplyForClientLocationModal(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title><b>APPLY FOR CLIENT LOCATION</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="approve_leave">
                    <div>
                        <div className="cm_overflow_reason">
                            <div className='row' >
                                <div className='col-12'>
                                    <label>Type</label>
                                    <Select name="editleaveType"
                                        value={leaveValue(props.leaveType1)}
                                        placeholder="Select Type" onChange={props.handleleaveTypeChange}
                                        className="select_type mb-2 "
                                        style={{ width: "80%" }}

                                    >
                                        <Option value='13'>Other's Leave</Option>
                                        <Option value='14'>Other's Half Day</Option>
                                    </Select>
                                </div>
                                {props.leaveType1ErrorMsg ? (
                                    <span className="error_msg">{props.leaveType1ErrorMsg}</span>
                                ) : null}
                            </div>
                            {props.leaveType1 == 14 ?
                                <div className={`row  ${props.halfOfTheDayErrMsg ? "input_error" : ""}`}>
                                    <div className='col-12' >
                                        <label className='mt-4'>Half Type</label>
                                        <Select
                                            style={{ width: "80%" }}
                                            value={halfTypeValue(props.halfOfTheDay)}
                                            placeholder="Select Half Type"
                                            onChange={props.handleSelectHalfChange}
                                        >
                                            <Option value={1}>First Half Leave</Option>
                                            <Option value={2}>Second Half Leave</Option>
                                        </Select>

                                        {props.halfOfTheDayErrMsg ? <span className="error_msg">{props.halfOfTheDayErrMsg}</span> : null}
                                    </div>
                                </div> : ''}
                            <div className="row">

                                <div className={`form-group col-12 ${props.start1dateErrorMsg ? "input_error" : ""
                                    }`}>
                                    <label className="mt-4">Start Date</label><br />
                                    <Calendar onChange={props.onStartChange}
                                        value={props.startDate1 ? new Date(props.startDate1) : ""}
                                        minDate={new Date()} />

                                    {props.start1dateErrorMsg ? (
                                        <span className="error_msg">{props.start1dateErrorMsg}</span>
                                    ) : null}
                                </div>
                                {props.leaveType1 == "14" ? "" :

                                    <div className={`form-group col-12 ${props.enddate1ErrorMsg ? "input_error" : ""
                                        }`}>
                                        <label className="mt-4">End Date</label><br />
                                        <Calendar onChange={props.onEndChange}
                                            value={props.endDate1 ? new Date(props.endDate1) : ""}
                                            minDate={new Date()}
                                        />

                                        {props.enddate1ErrorMsg ? (
                                            <span className="error_msg">{props.enddate1ErrorMsg}</span>
                                        ) : null}
                                    </div>}
                                <div className={`form-group col-12 `}>
                                    <h3 className="mt-5"> Send To</h3>
                                    <main className="wrapper">
                                        <input
                                            className={`email-input`}
                                            value={"hr@mobcoder.com"}
                                            style={{ width: "90%" }}

                                            disabled
                                        />
                                    </main>
                                </div>
                                <div className={`form-group col-12 ${props.emailError ? "input_error" : ""
                                    }`}>
                                    <h3 className="mt-5">Cc</h3>
                                    <span className="red">* Please mention your project manager and teamlead in Cc</span>
                                    {/* <main className="wrapper">
                                        {props.emails?.map(email => <div className="tag-item" key={email}>{email}
                                            <button
                                                type="button"
                                                className="button"
                                                onClick={() => props.handleEmailDelete(email)}
                                            >
                                                &times;
                                            </button>
                                        </div>)}
                                        <input
                                            className={`email-input ${props.emailError ? "input_error" : ""}`}
                                            placeholder="Enter email"
                                            style={{ width: "90%" }}

                                            value={props.value}
                                            onChange={props.handleEmailChange}
                                            onKeyDown={props.handleKeyDown}
                                        />
                                        <button className="btn btn-primary mt-2 cc-field" type="button" onClick={props.handleCC}>Add Email</button>

                                        {props.emailError && <p className="error_msg" style={{ width: "80%" }}>{props.emailError}</p>}
                                    </main> */}
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        name="employeeId"
                                        value={props?.emails ? props?.emails : 'Select Cc'}
                                        placeholder={"Select Cc"}
                                        optionFilterProp="key"
                                        filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                                        onChange={props?.handleEmailChange}>
                                        {
                                            !_.isEmpty(props?.employeeList) ?
                                                props?.employeeList.filter(data => data.employeeId !== localStorage.getItem("employeeId")).map(employee => (employee.status == 1 &&
                                                    <Option key={`${employee.firstName} ${employee.lastName}`} value={employee.email}><span>{employee.email}</span>
                                                    </Option>
                                                )) : ''
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className={`form-group col-12 ${props.replyErrorMsg ? "input_error" : ""
                            }`}>
                            <label className="mt-4">Reason</label>
                            {/* <textarea
                                className="form-control"
                                maxLength={500}
                                placeholder="Type here"
                                name="reply"
                                onChange={props.handleChange}
                            ></textarea> */}
                            <TextEditor
                                editorState={props.editorState}
                                onEditorStateChange={props.onEditorStateChange}
                            />
                            {props.replyErrorMsg ? (
                                <span className="error_msg">{props.replyErrorMsg}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="text-center mt-2">
                        <button type="button" className="btn btn-success"
                            onClick={props.handleApplyForClientOfficeSubmit}>Apply
                        </button>
                        {props.responseMessage ? <h3 className=" Registration_error mt-5">{props.responseMessage}</h3> : ''}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
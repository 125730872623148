import ResponseFilter from "../../ResponseFilter";
import { editEmployeeOnHold } from "../../config/services/employeeServices";
import {
    EDIT_EMPLOYEE_ON_HOLD_REQUEST,
    EDIT_EMPLOYEE_ON_HOLD_SUCCESS,
    EDIT_EMPLOYEE_ON_HOLD_FAILURE,
} from "./actionType";

export default function editEmployeeOnHoldAction(props) {
    let { useDispatch, params, getEmployeeOnHold } = props
    return (dispatch) => {
        dispatch(editEmployeeOnHoldActionRequest());
        editEmployeeOnHold(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resMsg: true,
                        successMessage: "Employee On Hold Edited",
                    }
                })
                getEmployeeOnHold()
                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resMsg: false,
                        }
                    })
                }, 4000)
            } else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false
                    }
                })
            }
        })
            .catch((error) => {
                dispatch(editEmployeeOnHoldActionFailure(error));
            });
    };
};

const editEmployeeOnHoldActionRequest = () => {
    return {
        type: EDIT_EMPLOYEE_ON_HOLD_REQUEST,
    };
};

const editEmployeeOnHoldActionSuccess = (data) => {
    return {
        type: EDIT_EMPLOYEE_ON_HOLD_SUCCESS,
        payload: data,
    };
};

const editEmployeeOnHoldActionFailure = (error) => {
    return {
        type: EDIT_EMPLOYEE_ON_HOLD_FAILURE,
        payload: error,
    };
};
export {
    editEmployeeOnHoldAction,
    editEmployeeOnHoldActionRequest,
    editEmployeeOnHoldActionSuccess,
    editEmployeeOnHoldActionFailure,
};
import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import ResponseFilter from "../../ResponseFilter";
import {
  getReimburseByEmpId,
  reimbursementAction,
} from "../../config/services/employeeServices";
import { Loader } from "../svg";
import { DatePicker, Modal as AntModal, Select } from "antd";
import Pagination from "react-js-pagination";
import { downloadIcon } from "../../asset/index";
import styled from "styled-components";
import { PDFReader } from "reactjs-pdf-reader-custom";
import { ReimbursementTypeConstant, colorType } from "./ReimbursementConstant";
import ViewUploadedDocument from "../helper/ViewUploadedDocument";
const { Option } = Select;

class reimbursementAdminAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resMsg: false,
      reimbursementDetails: [],
      reimbursementDetailss: [],
      loader: true,
      pageNo: 1,
      count: 10,
      totalReimburseCount: "",
      firstName: props.location.state.firstName,
      lastName: props.location.state.lastName,
      reqMonth: props.location.state.reqMonth,
      status: props.location.state.status,
      searchYear: props.location.state.searchYear,
      id: props.location.state?.id,
      multiValue: [],
      multiCheckedValue: {},
      empId: "",
      searchDate: "",
      search: "",
      expenseDescription: "",
      pp: false,
      openRembInfoPopup: false,
      checked: false,
      reId: [],
      mesg: "",
      reasonErMsg: "",
      excelFile: "",
      openApprovePopup: false,
      openRejectPopup: false,
      openPendingPopup: false,
      approve: false,
      reject: false,
      pending: false,
      employeeId: "",
      openPrintReimbursementFrom: false,
      id: props.location.state.id,
      alreadyGivenAmount: "",
      alreadyGivenAmountErrorMessage: "",
      approvedAmount: "",
      approvedAmountErrorMessage: "",
      alreadyGivenAmountDescription: "",
      openRembBillPopup: "",
      bill: [],
      claimedAmount: "",
      responseFailMessage: false,
      responseMessage: "",
      reimbursementType: "",
      tabStatus: "",
      approvedCounts: 0,
      pendingCounts: 0,
      rejectedCounts: 0,
    };
    this.GoBack = this.GoBack.bind(this);
  }
  handleDate = (e, dateString) => {
    this.setState(
      { searchDate: e ? dateString.substring(8) : "", pageNo: 1 },
      () => {
        this.getReimburseByEmp();
      }
    );
  };

  handleMultiSelect = (e, val, stat, amount) => {
    let { reimbursementDetails, multiValue, reId, approve, reject, pending } =
      this.state;
    let self = this;
    this.setState({ reasonErMsg: "" });
    multiValue = [];
    let reIdval = [];
    let app = [];
    let rej = [];
    app = reimbursementDetails[0]?.reimburseRecord.filter(
      (eachObj) => eachObj.status === stat
    );
    if (app[0].status === 2) {
      this.setState({ approve: true, claimedAmount: amount });
    } else if (app[0].status === 3) {
      this.setState({ reject: true, claimedAmount: amount });
    } else {
      this.setState({ pending: true, claimedAmount: amount });
    }
    for (let j = 0; j < app.length; j++) {
      rej[j] = app[j].reimbursementId;
    }

    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1);
          if (reId.length === 0) {
            this.setState({ approve: false, reject: false, pending: false });
          }
        }
      }
    } else {
      this.state.reId.push(val);
    }
    this.setState({ multiValue: reId });
  };

  handleApprovePopup = () => {
    this.setState({
      openApprovePopup: !this.state.openApprovePopup,
    });
  };
  handleRejectPopup = () => {
    this.setState({
      openRejectPopup: !this.state.openRejectPopup,
    });
  };
  handlePendingPopup = () => {
    this.setState({
      openPendingPopup: !this.state.openPendingPopup,
    });
  };

  handleValidate = () => {
    let validate = true;
    const { approvedAmount, alreadyGivenAmount, claimedAmount } = this.state;
    if (
      this.state.multiValue === "" ||
      this.state.multiValue.length < 0 ||
      this.state.multiValue.length === 0 ||
      this.state.multiValue === undefined
    ) {
      validate = false;
      this.setState({
        reasonErMsg: "Please select Reimbursement to approve or reject.",
      });
    } else {
      this.setState({
        reasonErMsg: "",
      });
    }
    if (approvedAmount <= 1 && approvedAmount !== "") {
      validate = false;
      this.setState({
        approvedAmountErrorMessage: "Please enter valid amount value",
      });
    } else if (approvedAmount > claimedAmount) {
      validate = false;
      this.setState({
        approvedAmountErrorMessage: "Amount cannot be more then Claimed Amount",
      });
    } else {
      this.setState({ approvedAmountErrorMessage: "" });
    }

    if (alreadyGivenAmount <= 1 && alreadyGivenAmount !== "") {
      validate = false;
      this.setState({
        alreadyGivenAmountErrorMessage: "Please enter valid amount value",
      });
    } else if (alreadyGivenAmount > 200000) {
      validate = false;
      this.setState({
        alreadyGivenAmountErrorMessage: "Amount cannot be more than 2 Lakh",
      });
    } else {
      this.setState({ alreadyGivenAmountErrorMessage: "" });
    }

    return validate;
  };

  handleAction = (e) => {
    let params = {
      reimbursementId: this.state.multiValue.toString(),
      status: e,
      approvedAmount: this.state.approvedAmount,
      amountAlreadyGiven: this.state.alreadyGivenAmount,
      lessAmountDescription: this.state.alreadyGivenAmountDescription,
    };
    if (this.handleValidate()) {
      this.setState({ loader: true });
      reimbursementAction(params).then((res) => {
        if (res.data.statusCode == 1) {
          if (e == "2") {
            this.setState({
              mesg: "2",
              openApprovePopup: false,
              openPendingPopup: false,
            });
          } else {
            this.setState({
              mesg: "3",
              openRejectPopup: false,
              openPendingPopup: false,
            });
          }
          this.setState(
            {
              miniLoader: false,
              resMsg: true,
              reId: [],
              multiValue: "",
              reasonErMsg: "",
              approve: false,
              reject: false,
              pending: false,
            },
            () => this.getReimburseByEmp()
          );
        } else {
          this.setState({
            responseFailMessage: true,
            responseMessage: res?.data?.error?.responseMessage,
            miniLoader: false,
            loader: false,
          });
          setTimeout(() => {
            this.setState({ responseFailMessage: false });
          }, 4000);
        }
      });
    } else {
    }
  };

  componentDidMount() {
    let lastSlash = this.props && this.props.match.url.lastIndexOf("/");
    this.setState({
      empId: this.props && this.props.match.url.substring(lastSlash + 1),
    });

    let url_string = window.location.href;
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let searchDate = url.searchParams.get("searchDate");
    let reimbursementType = url.searchParams.get("reimbursementType");
    let searchMonth = url.searchParams.get("searchMonth");
    let searchYear = url.searchParams.get("searchYear");

    // Log URL search parameters
    console.log("URL Search Params:");
    url.searchParams.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    this.setState(
      {
        pageNo: pageNo ? Number(pageNo) : 1,
        reimbursementType: reimbursementType ? reimbursementType : "",
        searchDate: searchDate ? searchDate : "",
        searchYear: searchYear ? searchYear : "",
        searchMonth: searchMonth ? searchMonth : "",
      },
      () => {
        this.getReimburseByEmp();
      }
    );
  }
  getReimburseByEmp = () => {
    let {
      reqMonth,
      searchDate,
      count,
      pageNo,
      reimbursementType,
      searchYear,
      status,
      searchMonth,
      tabStatus,
    } = this.state;
    let self = this;
    let params = `searchMonth=${reqMonth}&searchYear=${searchYear}&employeeId=${
      this.props.match.params.id
    }&searchDate=${searchDate}&status=${
      status ? status : tabStatus
    }&count=${count}&pageNo=${pageNo - 1}&reimburseType=${reimbursementType}`;
    // this.props.history.push(
    //   `searchMonth=${searchMonth}&searchYear=${searchYear}&searchDate=${searchDate}&pageNo=${pageNo}&reimburseType=${reimbursementType}`
    // );
    getReimburseByEmpId(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState(
          {
            reimbursementDetails: resData.reimbursementDetails,
            reimbursementDetailss: resData.reimbursementDetails,
            totalReimburseCount: resData.totalReimburseCount,
            pendingCounts: resData.reimbursementDetails[0]?.pending,
            rejectedCounts: resData.reimbursementDetails[0]?.rejected,
            approvedCounts: resData.reimbursementDetails[0]?.approved,
            employeeId:
              resData.reimbursementDetails[0]?.reimburseRecord[0]?.employeeId,
          },
          () => {
            let pendingCount = 0;
            resData.reimbursementDetails.forEach((data) => {
              if (data.status) {
                ++pendingCount;
              }
            });
            self.setState({ pendingCount });
            this.setState({ loader: false });
          }
        );
      } else if (res.data.statusCode === 0) {
        this.setState({ loader: false });
        this.closeModal();
      }
    });
    setTimeout(() => this.setState({ resMsg: false }), 4000);
  };
  closeModal = () =>
    this.setState({
      openApprovePopup: false,
      openRejectPopup: false,
      openRembInfoPopup: false,
      openPendingPopup: false,
      openPrintReimbursementFrom: false,
      openRembBillPopup: false,
      approvedAmount: "",
      alreadyGivenAmount: "",
      alreadyGivenAmountErrorMessage: "",
      approvedAmountErrorMessage: "",
      bill: [],
    });
  handleOpenRembInfoPopup = (data) => {
    let { expenseDescription, reimbursementId } = data;
    this.setState({
      openRembInfoPopup: !this.state.openRembInfoPopup,
      expenseDescription,
      reimbursementId,
    });
  };
  handleStatus = (a) => {
    this.setState({ status: a, reasonErMsg: "" }, () => this.handleAction());
  };

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () =>
      this.getReimburseByEmp()
    );
  };

  GoBack() {
    let self = this;
    this.props.history.goBack(); // self.props.history.push(`/user-strategy-list/${localStorage.getItem('userId')}`);
  }

  downloadExcel = () => {
    let {
      reqMonth,
      searchDate,
      count,
      pageNo,
      status,
      searchYear,
      reimbursementType,
      tabStatus,
    } = this.state;
    let params = `downloadExcel=1&searchMonth=${reqMonth}&searchYear=${searchYear}&employeeId=${
      this.props.match.params.id
    }&searchDate=${searchDate}&status=${
      status ? status : tabStatus
    }&count=${count}&pageNo=${
      pageNo - 1
    }&reimbursementType=${reimbursementType}`;
    getReimburseByEmpId(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        window.location.href = resData.excelFile;
        // window.open(resData.excelFile)
      }
    });
  };

  handleDownload = (files) => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex === files.length - 1) clearInterval(intervalId);
      window.location.href = files[currentIndex];
      currentIndex++;
    }, 1000 + 1000 * currentIndex);
  };

  handleOpenReimbursementBill = (data) => {
    window.location.href = "/reimbursement/reimbursement-form-print";
  };
  handlePrint = () => {
    const { employeeId } = this.state;
    window.print();
  };

  handleChangeReimbursementType = (e) => {
    if (e === undefined) {
      this.setState({ reimbursementType: "" }, () => this.getReimburseByEmp());
    } else {
      this.setState({ reimbursementType: e, pageNo: 1 }, () =>
        this.getReimburseByEmp()
      );
    }
  };

  handleChangeAmountApproved = (e) => {
    this.setState({
      approvedAmount: /^\s/.test(e.target.value) ? "" : e.target.value,
      approvedAmountErrorMessage: "",
    });
  };

  handleChangeLessAmount = (e) => {
    this.setState({
      alreadyGivenAmount: /^\s/.test(e.target.value) ? "" : e.target.value,
      alreadyGivenAmountErrorMessage: "",
    });
  };

  handleChangeLessAmountDescription = (e) => {
    this.setState({
      alreadyGivenAmountDescription: /^\s/.test(e.target.value)
        ? ""
        : e.target.value,
    });
  };

  handleOpenReimbursementBillView = (data) => {
    this.setState({
      openRembBillPopup: !this.state.openRembBillPopup,
      bill: data.uploadBill,
    });
  };

  handleTabChange = (e) => {
    if (e === undefined) {
      this.setState(
        {
          tabStatus: "",
        },
        () => this.getReimburseByEmp()
      );
    } else {
      this.setState(
        {
          tabStatus: e,
        },
        () => this.getReimburseByEmp()
      );
    }
  };
  render() {
    let {
      reimbursementDetails,
      resMsg,
      expenseDescription,
      mesg,
      openRembInfoPopup,
      loader,
      status,
      pageNo,
      count,
      totalReimburseCount,
      reqMonth,
      multiCheckedValue,
      multiValue,
      openApprovePopup,
      openRejectPopup,
      openPendingPopup,
      approve,
      reject,
      pending,
      openPrintReimbursementFrom,
      approvedAmount,
      alreadyGivenAmount,
      alreadyGivenAmountDescription,
      openRembBillPopup,
      bill,
      alreadyGivenAmountErrorMessage,
      approvedAmountErrorMessage,
      claimedAmount,
      responseFailMessage,
      responseMessage,
      reimbursementType,
      tabStatus,
      pendingCounts,
      rejectedCounts,
      approvedCounts,
    } = this.state;

    return (
      <div className="body_container cm_bold">
        {loader && <Loader />}
        {resMsg && mesg === "2" ? (
          <div className="alert alert-success cm_top_fix">
            Reimbursement has been approved.
          </div>
        ) : (
          ""
        )}
        {resMsg && mesg === "3" ? (
          <div className="alert alert-danger cm_top_fix">
            Reimbursement has been rejected.
          </div>
        ) : (
          ""
        )}
        {responseFailMessage ? (
          <div className="alert alert-danger cm_top_fix">{responseMessage}</div>
        ) : (
          ""
        )}
        <div className="cm_breadcrumb">
          <ul>
            <li>
              <Link to="/admin-reimbursement">REIMBURSEMENT</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li style={{ textTransform: "uppercase" }}>
              <Link onClick={this.GoBack.bind(this)}>
                {this.state.firstName} {this.state.lastName}
              </Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li style={{ textTransform: "uppercase" }}>
              {moment(reqMonth, "M/D/YYYY").format("MMMM")}
            </li>
          </ul>
        </div>
        <div className="filters justify-content-end mt-3">
          <div className="sm_search d-flex month_year">
            <Select
              value={
                reimbursementType
                  ? reimbursementType
                  : "Select Reimbursement Type"
              }
              allowClear={reimbursementType ? true : false}
              defaultValue="Select Type"
              name="reimbursementType"
              className="select_emp_leave ml-4"
              onChange={(e) => this.handleChangeReimbursementType(e)}
            >
              <Option value="1">Regular </Option>
              <Option value="2">Tax Saving</Option>
            </Select>
            <Select
              value={tabStatus ? tabStatus : "Select Status"}
              allowClear={tabStatus ? true : false}
              defaultValue="Select Type"
              name="reimbursementType"
              className="select_emp_leave"
              onChange={(e) => this.handleTabChange(e)}
            >
              <Option value="1">Pending </Option>
              <Option value="2">Approved</Option>
              <Option value="3">Rejected</Option>
            </Select>
          </div>

          <DatePicker
            className=" date-border filter-date "
            onChange={this.handleDate}
          />
        </div>
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openPendingPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="text-center mb-2 mt-3">
                Do you want to continue with the process ?
              </h3>
              <div className="text-center mt-4">
                {multiValue.length > 1 ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div
                          className={`form-group col-6 reimbursement-action-modal${
                            approvedAmountErrorMessage ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Approved Amount</label>

                          <input
                            maxLength="30"
                            type="text"
                            className="form-control"
                            name="title"
                            value={approvedAmount}
                            onChange={this.handleChangeAmountApproved}
                          />
                          {approvedAmountErrorMessage ? (
                            <span className="error_msg">
                              {approvedAmountErrorMessage}
                            </span>
                          ) : null}
                        </div>

                        <div
                          className={`form-group col-6${
                            alreadyGivenAmountErrorMessage ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Already Given Amount</label>
                          <input
                            inputMode="numeric"
                            type="number"
                            // step="any"
                            className="form-control"
                            name="amount"
                            value={alreadyGivenAmount}
                            onChange={this.handleChangeLessAmount}
                          />
                          {alreadyGivenAmountErrorMessage ? (
                            <span className="error_msg">
                              {alreadyGivenAmountErrorMessage}
                            </span>
                          ) : null}
                        </div>
                        <div className={`form-group col-12`}>
                          <label className="sub">
                            Already Given Amount Description
                          </label>
                          <textarea
                            inputMode="numeric"
                            type="number"
                            // step="any"
                            className="form-control"
                            name="amount"
                            value={alreadyGivenAmountDescription}
                            onChange={this.handleChangeLessAmountDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  onClick={() => this.handleAction(2)}
                  className="btn btn-success mt-4"
                >
                  Approve
                </button>
                <button
                  type="button"
                  onClick={() => this.handleAction(3)}
                  className="btn btn-danger mt-4  ml-4"
                >
                  Reject
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openApprovePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="text-center mb-2 mt-3">
                Do you want to approve <br />
                the reimbursement ?
              </h3>
              <div className="text-center mt-4">
                {multiValue.length > 1 ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div
                          className={`form-group col-6 reimbursement-action-modal${
                            approvedAmountErrorMessage ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Approved Amount</label>

                          <input
                            maxLength="30"
                            type="text"
                            className="form-control"
                            name="title"
                            value={approvedAmount}
                            onChange={this.handleChangeAmountApproved}
                          />

                          {approvedAmountErrorMessage ? (
                            <span className="error_msg">
                              {approvedAmountErrorMessage}
                            </span>
                          ) : null}
                        </div>
                        <div
                          className={`form-group col-6${
                            alreadyGivenAmountErrorMessage ? "input_error" : ""
                          }`}
                        >
                          <label className="sub">Already Given Amount</label>
                          <input
                            inputMode="numeric"
                            type="number"
                            // step="any"
                            className="form-control"
                            name="amount"
                            value={alreadyGivenAmount}
                            onChange={this.handleChangeLessAmount}
                          />

                          {alreadyGivenAmountErrorMessage ? (
                            <span className="error_msg">
                              {alreadyGivenAmountErrorMessage}
                            </span>
                          ) : null}
                        </div>
                        <div className={`form-group col-12`}>
                          <label className="sub">
                            {" "}
                            Already Given Amount Description
                          </label>
                          <textarea
                            inputMode="numeric"
                            type="number"
                            // step="any"
                            className="form-control"
                            name="amount"
                            value={alreadyGivenAmountDescription}
                            onChange={this.handleChangeLessAmountDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  onClick={() => this.handleAction(2)}
                  className="btn btn-primary mr-4 cm_btn3"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRejectPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="text-center mb-2 mt-3">
                Do you want to reject <br /> the reimbursement ?
              </h3>
              <div className="submit_button_margin mt-4">
                <button
                  type="button"
                  onClick={() => this.handleAction(3)}
                  className="btn btn-primary cm_btn3"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>

        <h3 className="mt-5 mb-4">REIMBURSEMENT CLAIMS</h3>
        {!_.isEmpty(reimbursementDetails) &&
          reimbursementDetails.map((reimbursements, i) => (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="white bg_blue">
                  <tr>
                    <th>S.No</th>
                    <th>Title</th>
                    <th>Expense Description</th>
                    <th>Date</th>
                    <th>Amount Claimed</th>
                    <th>Amount Approved</th>
                    <th>Reimbursement Type</th>
                    <th>Bill</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(reimbursements.reimburseRecord)
                    ? reimbursements.reimburseRecord &&
                      reimbursements.reimburseRecord.map((data, i) => (
                        <tr key={data.employeeId}>
                          <td>{i + 1 + (pageNo - 1) * count}</td>
                          <td>
                            <span className="cm_col">{data.title}</span>
                          </td>

                          <td
                            className="blue"
                            onClick={() => this.handleOpenRembInfoPopup(data)}
                          >
                            <span className="cm_col">
                              {data.expenseDescription}
                            </span>
                          </td>

                          <td>
                            {moment(data.expenseDate).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            <span className="cm_col">
                              {data.amount} {data.currency}
                            </span>
                          </td>
                          <td>
                            {data.approvedAmount == null &&
                            data.approvedAmount == undefined
                              ? ""
                              : data.approvedAmount}{" "}
                            {data.approvedAmount && data.currency}
                          </td>
                          <td>
                            {ReimbursementTypeConstant(data.reimburseType)}
                          </td>
                          <td>
                            <i
                              class="fa fa-eye ml-3"
                              aria-hidden="true"
                              onClick={() =>
                                this.handleOpenReimbursementBillView(data)
                              }
                            ></i>
                            {!_.isEmpty(data.uploadBill)
                              ? data.uploadBill
                                  .filter(
                                    (data) => data.includes(".zip") == true
                                  )
                                  .map((data, i) => (
                                    <a href={data} className="ml-4">
                                      <ActionImage
                                        src={downloadIcon}
                                        alt="downloadIcon"
                                      />
                                    </a>
                                  ))
                              : ""}
                          </td>
                          <td className={colorType(data.status)}>
                            {data.status == 1
                              ? "Pending"
                              : data.status == 2
                              ? "Approved"
                              : "Rejected"}
                          </td>

                          <td>
                            {approve === true ? (
                              <input
                                type="checkbox"
                                checked={
                                  this.state.reId.includes(data.reimbursementId)
                                    ? true
                                    : false
                                }
                                indeterminate
                                disabled={data.status != 2}
                                value={data.status}
                                onChange={(e) =>
                                  this.handleMultiSelect(
                                    e,
                                    data.reimbursementId &&
                                      data.reimbursementId,
                                    data.status && data.status,
                                    data.amount && data.amount
                                  )
                                }
                              />
                            ) : reject === true ? (
                              <input
                                type="checkbox"
                                checked={
                                  this.state.reId.includes(data.reimbursementId)
                                    ? true
                                    : false
                                }
                                indeterminate
                                disabled={data.status != 3}
                                value={data.status}
                                onChange={(e) =>
                                  this.handleMultiSelect(
                                    e,
                                    data.reimbursementId &&
                                      data.reimbursementId,
                                    data.status && data.status,
                                    data.amount && data.amount
                                  )
                                }
                              />
                            ) : pending === true ? (
                              <input
                                type="checkbox"
                                checked={
                                  this.state.reId.includes(data.reimbursementId)
                                    ? true
                                    : false
                                }
                                indeterminate
                                disabled={data.status != 1}
                                value={data.status}
                                onChange={(e) =>
                                  this.handleMultiSelect(
                                    e,
                                    data.reimbursementId &&
                                      data.reimbursementId,
                                    data.status && data.status,
                                    data.amount && data.amount
                                  )
                                }
                              />
                            ) : (
                              <input
                                type="checkbox"
                                checked={
                                  this.state.reId.includes(data.reimbursementId)
                                    ? true
                                    : false
                                }
                                indeterminate
                                value={data.status}
                                onChange={(e) =>
                                  this.handleMultiSelect(
                                    e,
                                    data.reimbursementId &&
                                      data.reimbursementId,
                                    data.status && data.status,
                                    data.amount && data.amount
                                  )
                                }
                              />
                            )}
                            {/* <span className="cm_radio_3"></span> */}
                            {data.status == 2 ? (
                              <Link
                                to={{
                                  pathname:
                                    "/reimbursement/reimbursement-form-print",
                                  state: {
                                    reimbursementId: data.reimbursementId,
                                    employeeId: data.employeeId,
                                    month: data.month,
                                    year: data.year,
                                  },
                                }}
                              >
                                <i
                                  class="fa fa-eye ml-4"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            ) : (
                              ""
                            )}
                            {/* </div> */}
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          ))}
        {totalReimburseCount > 10 ||
        (totalReimburseCount < 11 &&
          totalReimburseCount > 0 &&
          pageNo === 2) ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={totalReimburseCount}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
        ) : (
          ""
        )}
        {!_.isEmpty(reimbursementDetails) ? (
          ""
        ) : (
          <h3 className="text-center no-dta">No data available</h3>
        )}

        {_.isEmpty(reimbursementDetails) ? (
          ""
        ) : (
          <div>
            <div className="text-right mb-1">
              {this.state.reasonErMsg ? (
                <span
                  className="text-cen"
                  style={{ align: "center", color: "red" }}
                >
                  {this.state.reasonErMsg}
                </span>
              ) : null}
            </div>
            <div className="text-right mb-3">
              <button
                className="btn btn-primary mt-4"
                onClick={() => this.downloadExcel()}
              >
                Export
              </button>
              {multiValue.length > 0 ? (
                approve === true ? (
                  <button
                    className="btn btn-danger mt-4 ml-4"
                    onClick={() => this.handleRejectPopup()}
                    // onClick={() => this.handleAction(3)}
                  >
                    Reject
                  </button>
                ) : reject === true ? (
                  <button
                    className="btn btn-success mt-4 ml-4"
                    onClick={() => this.handleApprovePopup()}
                  >
                    Approve
                  </button>
                ) : (
                  <button
                    className="btn btn-secondary mt-4 ml-4"
                    onClick={() => this.handlePendingPopup()}
                  >
                    Verify
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        <AntModal
          centered
          className="reimbur_ino"
          title=""
          footer={false}
          visible={openRembInfoPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="mb-2 mt-4">EXPENSE DESCRIPTION</h3>
              <div className="cm_overflow_reimbur">
                <p>{expenseDescription}</p>
              </div>
            </form>
          </Modal.Body>
        </AntModal>

        <ViewUploadedDocument
          show={openRembBillPopup}
          data={bill}
          handleClose={this.closeModal}
        />
      </div>
    );
  }
}
export default reimbursementAdminAction;
const ActionImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

import React from 'react'
import { Modal } from 'react-bootstrap'
export default function DeleteAward(props) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title className='text-center'><b>Delete Award </b></Modal.Title>
            </Modal.Header>
            <Modal.Body className={`assign_device ${props.miniLoader ? "admin_attendance" : ""}`}>
                <div className="text-center confirmation">
                    <p className="mb-4 cm_bold cnfrm_msg">
                        Are you sure you want to delete Award?
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <div className="submit_button_margin mt-4">
                    {props.miniLoader ?
                        <div className="cover-spin1"></div>
                        : ''}
                    <span
                        className="btn btn-danger cm_btn3 btn-sm"
                        onClick={props.handleSubmit}
                        disabled={props.miniLoader}
                    >
                        Delete
                    </span>
                    <button
                        onClick={props.handleClose}
                        className="btn btn-primary cm_btn2 btn-sm"
                        disabled={props.miniLoader}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

import { STATUS_VALUE_ZERO, STATUS_ZERO } from "../../components/attendance/Constants";
import { deleteMembers, deleteTeam } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    DELETE_TEAM_MEMBERS_REQUEST,
    DELETE_TEAM_MEMBERS_SUCCESS,
    DELETE_TEAM_MEMBERS_FAILURE,
} from "./actionType";


const deleteTeamMembersByAdminAction = (props) => {
    let { useDispatch, getTeamMembers, params } = props
    return (dispatch) => {
        dispatch(deleteTeamMembersByAdminRequest());
        deleteMembers(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamMsg: true,
                        successMessage: "Team Members Deleted",
                        loader: false,
                        multiValue: [],
                        reId: [],
                    }
                })
                getTeamMembers()
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamMsg: false
                    }
                }), 3000);
            } else if (res.data.statusCode === STATUS_VALUE_ZERO) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        responseMessage: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resTeamFailMsg: true,
                        loader: false
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamFailMsg: false
                    }
                }), 3000);
                getTeamMembers()
            }
        })
            .catch((error) => {
                dispatch(deleteTeamMembersByAdminFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const deleteTeamMembersByAdminRequest = () => {
    return {
        type: DELETE_TEAM_MEMBERS_REQUEST,
    };
};
const deleteTeamMembersByAdminSuccess = (data) => {
    return {
        type: DELETE_TEAM_MEMBERS_SUCCESS,
        payload: data,
    };
};
const deleteTeamMembersByAdminFailure = (error) => {
    return {
        type: DELETE_TEAM_MEMBERS_FAILURE,
        payload: error,
    };
};
export {
    deleteTeamMembersByAdminAction,
    deleteTeamMembersByAdminRequest,
    deleteTeamMembersByAdminSuccess,
    deleteTeamMembersByAdminFailure,
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal as AntModal, DatePicker, Select } from "antd";
import Modal from "react-bootstrap/Modal"
import DotLoader from "../../hoc/DotLoader";
import _ from "lodash";
import ResponseFilter from "../../ResponseFilter";
import { getEmployeeList } from "../../config/services/employeeServices";
import moment from "moment";
import { disabledEndDate } from "../leaves/Constants";
import { addCandidateFeedBackAction } from "../../redux/actions/addCandidateFeedBackAction";

const { Option } = Select;

const AddFeedBackModel = ({
    openFeedBackPanel,
    setOpenFeedBackPanel,
    addCandidateFeedBackAction,
    loading,
    search,
    pageNo,
    count,
    sortType,
    sortField, error
}) => {
    const [employeeList, setEmployeeList] = useState([])
    const [candidate, setcandidate] = useState("");
    const [candidateErrorMessage, setcandidateErrorMessage] = useState("")
    const [interviewRound, setInterviewRound] = useState("");
    const [interviewRoundErrorMessage, setInterviewRoundErrorMessage] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("")
    const [interviewDate, setInterviewDate] = useState("")
    const [interviewDateErrorMessage, setInterviewDateErrorMessage] = useState("")
    const [interviewStatus, setInterviewStatus] = useState("")
    const [interviewStatusErrorMessage, setInterviewStatusErrorMessage] = useState("")
    const [domain, setDomain] = useState("")
    const [domainErrorMessage, setDomainErrorMessage] = useState("")
    const [interviewFeedBack, setInterviewFeedBack] = useState("")
    const [interviewFeedBackErrorMessage, setInterviewFeedBackErrorMessage] = useState("")

    const [resMsg, setResMsg] = useState(false);
    const [resFailMsg, setFailResMsg] = useState(false);
    const [loader, setLoader] = useState(false)


    const handleCancelButtonClick = () => {
        setOpenFeedBackPanel(false);
        setLoader(false)
        setcandidate("");
        setcandidateErrorMessage("");
        setInterviewRound("");
        setInterviewRoundErrorMessage("");
        setCompanyName("")
        setCompanyNameErrorMessage("")
        setInterviewDate("")
        setInterviewDateErrorMessage("")
        setInterviewStatus("")
        setInterviewStatusErrorMessage("")
        setInterviewFeedBack("")
        setInterviewFeedBackErrorMessage("")
        setDomain("")
        setDomainErrorMessage("")
    };

    const handlecandidateValidation = () => {
        let validate = true;
        if (candidate === "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleDomainValidation = () => {
        let validate = true;
        if (domain === "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleInterviewRoundValidation = () => {
        let validate = true;
        if (interviewRound === "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleInterviewStatusValidation = () => {
        let validate = true;
        if (interviewStatus === "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleCompanyNameValidation = () => {
        let validate = true;
        if (companyName === "") {
            validate = false;
        }
        else {
        }
        return validate
    }


    const handleInterviewDateValidation = () => {
        let validate = true;
        if (interviewDate === "") {
            validate = false;
        }
        else {
        }
        return validate;
    }

    const handleInterviewFeedBackValidation = () => {
        let validate = true;
        if (interviewFeedBack === "") {
            validate = false;
        }
        else {
        }
        return validate;
    }

    const handleUploadButtonClick = async () => {
        if (
            handlecandidateValidation(candidate) &&
            handleInterviewRoundValidation(interviewRound) &&
            handleInterviewStatusValidation(interviewStatus) &&
            handleCompanyNameValidation(companyName) &&
            handleInterviewDateValidation(interviewDate) &&
            handleInterviewFeedBackValidation(interviewFeedBack) &&
            handleDomainValidation(domain)

        ) {
            setLoader(true)
            const data1 = {
                "employeeId": candidate,
                "interviewRound": interviewRound,
                "interviewDate": interviewDate,
                "status": interviewStatus,
                "feedBack": interviewFeedBack,
                "companyName": companyName,
                "domain": domain,


            }
            const query = `search=${search}&pageNo=${pageNo}&count=${count}&sortType=${sortType}&sortField=${sortField}`;
            await addCandidateFeedBackAction(data1, query, setOpenFeedBackPanel, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick);
        }
        else {
        }

        setTimeout(() => setResMsg(false), 4000)
        setTimeout(() => setFailResMsg(false), 4000)
    };
    const handleInterviewRoundChange = (param) => {
        setInterviewRound(param);
        setInterviewRoundErrorMessage("");

    };
    const handleInterviewStatusChange = (param) => {
        setInterviewStatus(param);
        setInterviewStatusErrorMessage("");

    };
    const handleCompanyNameChange = (param) => {
        setCompanyName(/^\s/.test(param.target.value) ? "" :param.target.value)
        setCompanyNameErrorMessage("")
    }

    const handleInterviewFeedBackChange = (param) => {
        setInterviewFeedBack(/^\s/.test(param.target.value) ? "" :param.target.value)
        setInterviewFeedBackErrorMessage("")
    }
    const handleChangeEmp = e => {
        setcandidate(e)
        setcandidateErrorMessage("")
    }
    const handleDomainChange = (param) => {
        setDomain(/^\s/.test(param.target.value) ? "" :param.target.value)
        setDomainErrorMessage("")
    }

    const handleInterviewDateChange = (date) => {
        setInterviewDate(new Date(date).getTime());
        setInterviewDateErrorMessage("");

    };

    const onSubmitClick = () => {
        handleUploadButtonClick();
        if (handlecandidateValidation(candidate) == "") {
            setcandidateErrorMessage("Please Enter Candidate Name");
        }
        else {
            setcandidateErrorMessage("")
        }
        if (handleInterviewRoundValidation(interviewRound) == "") {
            setInterviewRoundErrorMessage("Please Enter Interview Round");
        }
        else {
            setInterviewRoundErrorMessage("")
        }

        if (handleInterviewStatusValidation(interviewStatus) == "") {
            setInterviewStatusErrorMessage("Please Enter Interview Status");
        }
        else {
            setInterviewStatusErrorMessage("")
        }

        if (handleCompanyNameValidation(companyName) == "") {
            setCompanyNameErrorMessage("Please Enter Company Name");
        }
        else {
            setCompanyNameErrorMessage("")
        }
        if (handleInterviewDateValidation(interviewDate) == "") {
            setInterviewDateErrorMessage("Please Select Interview Date");
        }
        else {
            setInterviewDateErrorMessage("")
        }

        if (handleInterviewFeedBackValidation(interviewDate) == "") {
            setInterviewFeedBackErrorMessage("Please Enter FeedBack");
        }
        else {
            setInterviewFeedBackErrorMessage("")
        }

        if (handleDomainValidation(domain) == "") {
            setDomainErrorMessage("Please Enter Domain");
        }
        else {
            setDomainErrorMessage("")
        }
    };

    useEffect(() => {
        let params = `status=1`;
        getEmployeeList(params).then((res) => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                // setLoader(false);
                setEmployeeList(resData.employeesList)
            }
        })
    }, [])



    return (
        <AntModal
            centered
            className="attend_modal"
            footer={false}
            visible={openFeedBackPanel}
            onCancel={handleCancelButtonClick}
        >
            <Modal.Body>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmitClick();
                    }}
                >
                    <h3 className="mb-4">Add Candidate Feedback Panel</h3>
                    {
                        resMsg ?
                            <div className="alert alert-success cm_top_fix"> FeedBack Added Successfully </div> : ''
                    }
                    {
                        resFailMsg ?
                            <div className="alert alert-danger cm_top_fix">{error} </div> : ''
                    }
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Candidate Name</label>
                            <Select
                                showSearch
                                value={candidate ? candidate : "Employee Name"}
                                onChange={(e) => handleChangeEmp(e)}
                                optionFilterProp="key"
                                filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                            >
                                {!_.isEmpty(employeeList)
                                    ? employeeList.sort(function (a, b) {
                                        if (a.firstName < b.firstName) { return -1; }
                                        if (a.firstName > b.firstName) { return 1; }
                                        return 0;
                                    }).map(
                                        (employee) =>
                                            employee.status == 1 && (
                                                <Option
                                                    key={`${employee.firstName} ${employee.lastName}`}
                                                    value={employee.employeeId}
                                                >
                                                    <span>
                                                        {employee.firstName} {employee.lastName} ({employee.empId})
                                                    </span>
                                                </Option>
                                            )
                                    )
                                    : ""}
                            </Select>
                            <span className="errorText">{candidateErrorMessage}</span>

                        </div>

                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Candidate Round</label>
                            <Select
                                value={interviewRound ? interviewRound : "Select Interview Round"}
                                onChange={(e) => handleInterviewRoundChange(e)}
                                isSearchable={true}
                            >
                                <Option value={1}>Round One</Option>
                                <Option value={2}>Round Two</Option>
                                <Option value={3}>Round Three</Option>
                            </Select>
                            <span className="errorText">{interviewRoundErrorMessage}</span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold"> Client Name</label>
                            <input
                                maxLength="20"
                                className="form-control cm_box"
                                placeholder="Enter Company Name"
                                type="text"
                                value={companyName}
                                onChange={handleCompanyNameChange}
                            />
                            <span className="errorText">{companyNameErrorMessage}</span>

                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Interview Status</label>
                            <Select
                                value={interviewStatus ? interviewStatus : "Select Interview Status"}
                                onChange={(e) => handleInterviewStatusChange(e)}
                                isSearchable={true}
                            >
                                <Option value={1}>Selected</Option>
                                <Option value={2}>Pending</Option>
                                <Option value={3}>Rejected</Option>
                            </Select>
                            <span className="errorText">{interviewStatusErrorMessage}</span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Interview Date</label>
                            <DatePicker
                                className="date-border"
                                allowClear={false}
                                disabledDate={disabledEndDate}
                                value={interviewDate ? moment(interviewDate) : ""}
                                onChange={handleInterviewDateChange}
                            />
                            <span className="errorText">{interviewDateErrorMessage}</span>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Domain</label>
                            <input
                                maxLength="20"
                                className="form-control cm_box"
                                placeholder="Enter Domain Name"
                                type="text"
                                value={domain}
                                onChange={handleDomainChange}
                            />
                            <span className="errorText">{domainErrorMessage}</span>
                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label className="cm_bold">Feedback</label>
                            <textarea
                                className="form-control cm_box"
                                placeholder="Enter Feedback"
                                type="text"
                                maxLength={250}
                                rows={4}
                                value={interviewFeedBack}
                                onChange={handleInterviewFeedBackChange}
                            />
                            <span className="errorText">{interviewFeedBackErrorMessage}</span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="addCandidateButtonsDiv">
                            <button
                                onClick={handleCancelButtonClick}
                                type="button"
                                className="cancel_btn btn  buttonWidth"
                            >
                                Cancel
                            </button>
                            {loader ? <DotLoader /> :
                                <button className="btn btn-primary  buttonWidth" type="submit">
                                    Submit
                                </button>}
                        </div>
                    </div>
                </form >
            </Modal.Body >
        </AntModal >
    );
};
const mapStateToProps = (state) => {
    return {
        // loading: state.addCandidateFeedBackAction.loading,
        error: state.editCandidateFeedBack?.error?.error?.responseMessage
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addCandidateFeedBackAction: (data1, query, setOpenFeedBackPanel, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick) => {
            dispatch(addCandidateFeedBackAction(data1, query, setOpenFeedBackPanel, setResMsg, setFailResMsg, setLoader, handleCancelButtonClick));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddFeedBackModel);

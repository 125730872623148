import {
  ADD_CANDIDATE_BY_ADMIN_REQUEST,
  ADD_CANDIDATE_BY_ADMIN_SUCCESS,
  ADD_CANDIDATE_BY_ADMIN_FAILURE,
} from "../actions";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const addCandidateByAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CANDIDATE_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case ADD_CANDIDATE_BY_ADMIN_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case ADD_CANDIDATE_BY_ADMIN_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default addCandidateByAdminReducer;

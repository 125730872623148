import {
    ADD_EMPLOYEE_CTC_REQUEST,
    ADD_EMPLOYEE_CTC_SUCCESS,
    ADD_EMPLOYEE_CTC_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { addEmployeeCtc } from "../../config/services/employeeServices";

const addEmployeeCtcAction = (props) => {
    let { useDispatch, params, handleClose, getEmployeeCtc } = props
    return (dispatch) => {
        dispatch(addEmployeeCtcActionRequest());
        addEmployeeCtc(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resMsg: true,
                        successMessage: "CTC Added",
                    }
                })
                handleClose();
                getEmployeeCtc();
                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resMsg: false,
                        }
                    })
                }, 4000)
            } else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resFailMsg: true,
                        responseMessage: res?.data?.error?.responseMessage
                    }
                })

                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resFailMsg: false,
                        }
                    })
                }, 4000)
            }
        })
            .catch((error) => {
                dispatch(addEmployeeCtcActionFailure(error));
            });
    };
};

const addEmployeeCtcActionRequest = () => {
    return {
        type: ADD_EMPLOYEE_CTC_REQUEST,
    };
};

const addEmployeeCtcActionSuccess = (data) => {
    return {
        type: ADD_EMPLOYEE_CTC_SUCCESS,
        payload: data,
    };
};

const addEmployeeCtcActionFailure = (error) => {
    return {
        type: ADD_EMPLOYEE_CTC_FAILURE,
        payload: error,
    };
};
export {
    addEmployeeCtcAction,
    addEmployeeCtcActionRequest,
    addEmployeeCtcActionSuccess,
    addEmployeeCtcActionFailure,
};

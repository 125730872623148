import React, { useEffect, useReducer, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { DatePicker, Select } from "antd";
import moment from "moment";
import _ from "lodash";
import ResponseFilter from "../../ResponseFilter";
import Pagination from "react-js-pagination";
import {
  deleteAttendance,
  getEmployeeList,
  leaveAction,
} from "../../config/services/employeeServices";
import "./Attendance.css";
import { CONSTANTS } from "../../config";
import {
  STATUS_VALUE_ZERO,
  STATUS_ZERO,
  TabRender,
  Toast,
  useDebounce,
} from "./Constants";
import { OpenUploadAttendanceExcel } from "./attendanceModal/UploadAttendanceExcel";
import { AddAttendanceModal } from "./attendanceModal/AddAttendanceModal";
import { EditAttendanceModal } from "./attendanceModal/EditAttendanceModal";
import { DeleteAttendanceModal } from "./attendanceModal/DeleteAttendanceModal";
import { OpenLeaveModal } from "./attendanceModal/OpenLeaveModal";
import { Loader } from "../svg";
import {
  createSelectItems,
  currentMonth,
  dateFormat,
  reducer,
  type,
} from "../helper/Constant";
import {
  handleEditValidetion,
  handleUploadValidation,
  handleValidation,
} from "./AttendanceValidation";
import { useDispatch } from "react-redux";
import { uploadAttendanceAction } from "../../redux/actions/UploadAttendanceAction";
import { getAttendanceByAdmin } from "../../redux/actions/getAttendanceByAdmin";
import {
  addAttendanceByAdmin,
  editAttendanceByAdminAction,
} from "../../redux/actions";
import { MONTH_ARRAY, currentYear } from "../leaves/Constants";
import qs from "qs";
import ViewAdminAttendance from "./ViewAdminAttendance";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const { Option } = Select;

const initialState = {
  AttendanceDetails: [],
  date: new Date(),
  dateErrorMsg: "",
  employeeList: [],
  addAttendance: [],
  employeeIdDetail: [],
  statusByType: [],
  dataCount: "",
  search: "",
  totalAttendance: "",
  present: "",
  late: "",
  absent: "",
  missedCheckOut: "",
  lateAndTotalHoursNotComplete: "",
  missedCheckIn: "",
  onLeave: "",
  workFromHome: "",
  Others: "",
  halfOthers: "",
  earlyCheckOutTab: "",
  status: "",
  setStatus: "",
  searchDate: "",
  subject: "",
  description: "",
  employeeId: "",
  checkIn: "",
  checkOut: "",
  earlyCheckIn: "",
  lateCheckIn: "",
  totalHours: "",
  tabStatus: "",
  searchMonth: "",
  searchYear: "",
  startDate: "",
  endDate: "",
  reportingTime: "",
  responseMessageMail: "",
  responseMessageExcel: "",
  responseMessageAdd: "",
  responseMessageEdit: "",
  responseMessage: "",
  fileName: "",
  statusCheck: "",
  leaveType: "",
  checkBox: "",
  statusErrorMsg: "",
  startDateStamp: "",
  endDateStamp: "",
  openSendMailPopup: false,
  openAddAttendancePopup: false,
  openEditAttendancePopup: false,
  openDeleteAttendancePopup: false,
  openUploadAttendancePopup: false,
  openLeavePopup: false,
  loader: false,
  showUploadButton: false,
  resMsgLoad: false,
  resMsgMail: false,
  resMsgMailError: false,
  resMsg: false,
  resMsgEdit: false,
  resMsgDelete: false,
  resMsgUpload: false,
  miniLoader: false,
  resMsgFailAdd: false,
  resMsgFail: false,
  resMsgFailEdit: false,
  resMsgExcelError: false,
  resLeaveMsg: false,
  attendanceLocationErrorMsg: "",
  month: false,
  year: false,
  dateRange: false,
  openConfirmPopup: false,
  resProcessMessage: false,
  sortType: SORT_TYPE_CONSTANTS.Asscending,
  sortField: "mobId",
  pageNo: 1,
  count: 10,
  mailStatus: 1,
  attendanceDate: new Date(),
  avgOfficeHours: 0,
  excelSheet: null,
  prevMonth: new Date().getMonth() - 1,
  nextMonth: new Date().getMonth() - 1,
  leaveId: "",
  successMessage: "",
  halfWfh: "",
  bereavementLeaves: "",
  parentingLeave: "",
  clientOff: "",
};

function AdminAttendance() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const {
    employeeIdErrorMsg,
    setStatus,
    checkIn,
    checkInErrorMsg,
    checkOut,
    checkOutErrorMsg,
    employeeId,
    openAddAttendancePopup,
    search,
    excelSheet,
    excelSheetUploadError,
    statusErrorMsg,
    avgOfficeHours,
    searchMonth,
    searchYear,
    dateRange,
    startDate,
    endDate,
    openUploadAttendancePopup,
    miniLoader,
    dateErrorMsg,
    errorCode,
    responseMessage,
    attendanceLocationErrorMsg,
    attendanceLocation,
    wantSendMail,
    date,
    status,
    wantSendMailErrorMsg,
    tabStatus,
    AttendanceDetails,
    sortType,
    sortField,
    pageNo,
    count,
    checkBox,
    openEditAttendancePopup,
    openLeavePopup,
    openDeleteAttendancePopup,
    employeeList,
    attendenceId,
    dataCount,
    late,
    absent,
    missedCheckOut,
    missedCheckIn,
    lateAndTotalHoursNotComplete,
    earlyCheckOutTab,
    onLeave,
    workFromHome,
    Others,
    halfOthers,
    present,
    statusByType,
    totalAttendance,
    resMsg,
    resMsgEdit,
    resMsgDelete,
    resMsgUpload,
    resMsgFail,
    resMsgFailEdit,
    resMsgFailAdd,
    resMsgExcelError,
    resMsgMail,
    resMsgMailError,
    responseMessageEdit,
    responseMessageAdd,
    responseMessageExcel,
    responseMessageMail,
    leaveId,
    successMessage,
    searchDate,
    resLeaveActionFail,
    resLeaveMsg,
    halfWfh,
    bereavementLeaves,
    parentingLeave,
    clientOff,
  } = state;

  const debouncedSearchTerm = useDebounce(search, 2000);
  const dispatchToAction = useDispatch();

  useEffect(() => {
    getAttendance();
    // eslint-disable-next-line
  }, [
    debouncedSearchTerm,
    sortType,
    sortField,
    count,
    pageNo,
    status,
    searchMonth,
    searchYear,
    startDate,
    endDate,
    searchDate,
  ]);

  useEffect(() => {
    getEmployee();
    // eslint-disable-next-line
  }, []);

  const getEmployee = () => {
    let params = ``;
    getEmployeeList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            employeeList: resData.employeesList,
          },
        });
      }
    });
  };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";
    dispatch({
      type: type,
      payload: {
        pageNo: filtersFromParams.pageNo ? filtersFromParams.pageNo : 1,
        search: filtersFromParams.search ? filtersFromParams.search : "",
        status: filtersFromParams.status ? filtersFromParams.status : "",
        tabStatus: filtersFromParams.tabStatus
          ? filtersFromParams.tabStatus
          : "",
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "mobId",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : SORT_TYPE_CONSTANTS.Asscending,
        searchDate: filtersFromParams.searchDate
          ? moment(filtersFromParams.searchDate, format)
          : "",
        searchMonth: filtersFromParams.searchMonth
          ? filtersFromParams.searchMonth
          : "",
        searchYear: filtersFromParams.searchYear
          ? filtersFromParams.searchYear
          : "",
        dateRange: filtersFromParams.dateRange
          ? filtersFromParams.dateRange
          : false,
        dataCount: filtersFromParams.dataCount
          ? filtersFromParams.dataCount
          : 0,
      },
    });
  }, [history.location.search]);

  const getAttendance = async () => {
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
      },
    });
    let formattedDate1 = "";
    if (searchDate !== "") {
      formattedDate1 = new Date(searchDate);
    }
    history.push(
      `admin-attendance?search=${search.trim()}&pageNo=${pageNo}&sortField=${sortField}&sortType=${sortType}&status=${status}&searchMonth=${searchMonth}&searchYear=${searchYear}&tabStatus=${tabStatus}&searchDate=${searchDate}&dateRange=${dateRange}&dataCount=${dataCount}`
    );
    const params = {
      search: search?.trim(),
      sortType: sortType,
      sortField: sortField,
      count: 10,
      pageNo: pageNo - 1,
      status: status,
      searchMonth: searchMonth,
      searchYear: searchYear,
      searchDate:
        searchYear || searchMonth
          ? ""
          : formattedDate1
          ? formattedDate1.getFullYear() +
            "-" +
            (formattedDate1.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            formattedDate1.getDate().toString().padStart(2, "0")
          : "",
    };
    dispatchToAction(
      getAttendanceByAdmin({
        useDispatch: dispatch,
        tabStatus: tabStatus,
        params: params,
        dataCount: dataCount,
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch({
      type: type,
      payload: {
        date: date,
        dateErrorMsg: "",
      },
    });
  };

  const getExcelSheet = (e) => {
    dispatch({
      type: type,
      payload: {
        excelSheet: e.target.files[0],
        statusErrorMsg: "",
        excelSheetUploadError: "",
      },
    });
  };

  const handleOpenUploadAttendancePopup = () => {
    dispatch({
      type: type,
      payload: {
        openUploadAttendancePopup: true,
      },
    });
  };
  const handleYear = (year) => {
    if (year === undefined) {
      dispatch({
        type: type,
        payload: {
          searchYear: "",
          searchMonth: "",
          pageNo: 1,
          year: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchYear: year,
          searchMonth: currentMonth,
          pageNo: 1,
          year: true,
        },
      });
    }
  };

  const handleAttendanceLocation = (e) => {
    dispatch({
      type: type,
      payload: {
        attendanceLocation: e,
        attendanceLocationErrorMsg: "",
      },
    });
  };

  const handleMonth = (month) => {
    if (month === undefined) {
      dispatch({
        type: type,
        payload: {
          searchMonth: "",
          searchYear: "",
          pageNo: 1,
          month: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchMonth: month,
          searchYear: searchYear ? searchYear : currentYear,
          pageNo: 1,
          month: true,
        },
      });
    }
  };

  const handleWantSendmail = (status) => {
    dispatch({
      type: type,
      payload: {
        wantSendMailErrorMsg: "",
        wantSendMail: status,
      },
    });
  };

  const handleUploadAttendanceSubmit = (e) => {
    e.preventDefault();
    let d = new Date();
    let formattedDate =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      d.getDate().toString().padStart(2, "0");
    let formattedDate1 =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    const rawdata = new FormData();
    rawdata.append("excelSheet", excelSheet);
    rawdata.append("uploadingDate", formattedDate);
    rawdata.append("date", formattedDate1);
    rawdata.append("isSendMail", +wantSendMail);
    rawdata.append("officeLocation", +attendanceLocation);

    if (
      handleUploadValidation({
        excelSheet: excelSheet,
        dispatch: dispatch,
        date: date,
        attendanceLocation: attendanceLocation,
        wantSendMail: wantSendMail,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          miniLoader: true,
        },
      });
      dispatchToAction(
        uploadAttendanceAction({
          useDispatch: dispatch,
          rawdata: rawdata,
          closeModal: closeModal,
          getAttendance: getAttendance,
        })
      );
    }
  };

  const handleEditAttendanceSubmit = (event) => {
    event.preventDefault();
    let obj = {
      attendenceId: attendenceId,
      checkIn: checkIn ? moment.utc(checkIn * 1000).format("HH.mm") : "",
      checkOut: checkOut ? moment.utc(checkOut * 1000).format("HH.mm") : "",
    };
    if (
      handleEditValidetion({
        setStatus: setStatus,
        checkIn: checkIn,
        checkOut: checkOut,
        dispatch: dispatch,
        closeModal: closeModal,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          miniLoader: true,
          loader: true,
        },
      });
      dispatchToAction(
        editAttendanceByAdminAction({
          useDispatch: dispatch,
          getAttendance: getAttendance,
          closeModal: closeModal,
          obj: obj,
        })
      );
    }
  };

  const handleDelete = () => {
    let params = {
      data: {
        attendenceId: attendenceId,
        isDeleted: 2,
      },
    };
    dispatch({
      type: type,
      payload: {
        miniLoader: true,
      },
    });
    deleteAttendance(params).then((res) => {
      dispatch({
        type: type,
        payload: {
          tabStatus: 0,
          status: "",
          resMsgDelete: true,
          miniLoader: false,
        },
      });
      setTimeout(
        () =>
          dispatch({
            type: type,
            payload: {
              resMsgDelete: false,
            },
          }),
        2000
      );
      closeModal();
      getAttendance();
    });
  };

  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        attendanceDateErrorMsg: "",
        dateErrorMsg: "",
        statusErrorMsg: "",
        date: new Date(),
        attendanceDate: "",
        wantSendMail: "",
        wantSendMailErrorMsg: "",
        attendanceLocation: "",
        attendanceLocationErrorMsg: "",
        openAddAttendancePopup: false,
        openUploadAttendancePopup: false,
        openDeleteAttendancePopup: false,
        openEditAttendancePopup: false,
        openLeavePopup: false,
        excelSheetUploadError: "",
        employeeIdErrorMsg: "",
        checkInErrorMsg: "",
        checkOutErrorMsg: "",
        employeeId: "",
        checkIn: "",
        totalHours: "",
        checkOut: "",
        setStatus: "",
        resLeaveActionFail: "",
      },
    });
  };

  const handlePageChange = (pageNo) => {
    dispatch({
      type: type,
      payload: {
        pageNo: pageNo,
      },
    });
  };
  const handleSearch = (e) => {
    dispatch({
      type: type,
      payload: {
        search: e.target.value,
      },
    });
    if (e.target.value === "" || e.target.value === undefined) {
      dispatch({
        type: type,
        payload: {
          search: "",
          status: "",
        },
      });
    }
  };

  const handleSelectChange = () => {
    dispatch({
      type: type,
      payload: {
        search: "",
      },
    });
  };

  const handleSelectEmployeeChange = (e) => {
    const Emp = state.employeeList.filter((ele) => {
      return ele.employeeId === e;
    });
    dispatch({
      type: type,
      payload: {
        employeeId: e,
        reportingTime: +Emp[0].arrivalTime,
        employeeIdErrorMsg: "",
      },
    });
  };

  const handleStatusChange = (e) => {
    dispatch({
      type: type,
      payload: {
        setStatus: +e,
        statusErrorMsg: "",
      },
    });
  };

  const handleCheckInChange = (checkIn, checkInString) => {
    dispatch({
      type: type,
      payload: {
        checkIn: moment.duration(checkInString).asSeconds(),
        checkInErrorMsg: "",
      },
    });
  };

  const handleCheckOutChange = (checkOut, checkOutString) => {
    dispatch({
      type: type,
      payload: {
        checkOut: moment.duration(checkOutString).asSeconds(),
        checkOutErrorMsg: "",
      },
    });
  };

  const handleOpenAddAttendancePopup = () => {
    dispatch({
      type: type,
      payload: {
        openAddAttendancePopup: true,
      },
    });
  };

  const handleAddAttendanceSubmit = (e) => {
    e.preventDefault();
    let formattedDate =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    let obj = {
      employeeId: employeeId,
      checkIn: checkIn ? moment.utc(checkIn * 1000).format("HH.mm") : "",
      checkOut: checkOut ? moment.utc(checkOut * 1000).format("HH.mm") : "",
      date: formattedDate,
      sendMail: +wantSendMail,
    };

    if (
      handleValidation({
        date: date,
        dispatch: dispatch,
        employeeId: employeeId,
        checkIn: checkIn,
        checkOut: checkOut,
        wantSendMail: wantSendMail,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          miniLoader: true,
        },
      });
      dispatchToAction(
        addAttendanceByAdmin({
          useDispatch: dispatch,
          getAttendance: getAttendance,
          closeModal: closeModal,
          obj: obj,
        })
      );
    }
  };

  const handleTabChange = (e) => {
    let totalCountByStatus;
    totalCountByStatus = statusByType.filter((data) => {
      return data._id === e;
    });

    if (e !== STATUS_ZERO) {
      dispatch({
        type: type,
        payload: {
          loader: true,
          status: e,
          tabStatus: e,
          pageNo: 1,
          dataCount: totalCountByStatus[0]?.totalCountByStatus,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          loader: true,
          status: "",
          tabStatus: "",
          pageNo: 1,
          dataCount: totalAttendance,
        },
      });
    }
  };

  const handleOpenEditAttendancePopup = (data) => {
    dispatch({
      type: type,
      payload: {
        openEditAttendancePopup: true,
        attendenceId: data._id,
        checkIn: data.checkIn,
        checkOut: data.checkOut,
        totalHours: data.totalHours,
        date: data.date,
        setStatus: data.status,
        reportingTime: data.reportingTime,
      },
    });
  };

  const handleOpenDeleteAttendancePopup = (data) => {
    dispatch({
      type: type,
      payload: {
        openDeleteAttendancePopup: true,
        attendenceId: data._id,
      },
    });
  };
  const handleOpenLeavePopup = (data) => {
    dispatch({
      type: type,
      payload: {
        openLeavePopup: true,
        attendenceId: data._id,
        leaveId: data.leaveId,
      },
    });
  };

  const handleLeave = (leaveType) => {
    let params = {
      leaveId: leaveId,
      status: leaveType,
    };
    leaveAction(params).then((res) => {
      dispatch({
        type: type,
        payload: {
          resLeaveMsg: true,
          successMessage: "Leave Detected successfully",
        },
      });
      setTimeout(
        () =>
          dispatch({
            type: type,
            payload: {
              resLeaveMsg: false,
            },
          }),
        4000
      );
      if (res.data.statusCode === 1) {
        closeModal();
      } else {
        dispatch({
          type: type,
          payload: {
            resLeaveActionFail: res.data.error.responseMessage,
          },
        });
      }
    });
  };

  const handleDate = (e) => {
    if (e) {
      dispatch({
        type: type,
        payload: {
          searchDate: e,
          pageNo: 1,
          searchMonth: "",
          searchYear: "",
          dateRange: true,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchDate: "",
          pageNo: 1,
          dateRange: false,
        },
      });
    }
  };

  return (
    <div className="body_container cm_bold">
      {miniLoader && <Loader />}
      <Toast
        resMsg={resMsg}
        resMsgEdit={resMsgEdit}
        resMsgDelete={resMsgDelete}
        resMsgUpload={resMsgUpload}
        resMsgFail={resMsgFail}
        resMsgFailEdit={resMsgFailEdit}
        resMsgFailAdd={resMsgFailAdd}
        resMsgExcelError={resMsgExcelError}
        resMsgMail={resMsgMail}
        resMsgMailError={resMsgMailError}
        resLeaveMsg={resLeaveMsg}
        responseMessage={responseMessage}
        responseMessageEdit={responseMessageEdit}
        responseMessageAdd={responseMessageAdd}
        responseMessageExcel={responseMessageExcel}
        responseMessageMail={responseMessageMail}
        text={successMessage}
      />
      <h3 className="mb-4">DAILY ATTENDANCE</h3>
      <div className="text-left cm_totalhr">
        <p className="sm_totalhr">Avg/Hours</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <div
            className={`${
              moment.utc(avgOfficeHours * 1000).format("HH:mm") <
                moment.utc(32400 * 1000).format("HH:mm") &&
              moment.utc(avgOfficeHours * 1000).format("HH:mm") >=
                moment.utc(28800 * 1000).format("HH:mm")
                ? "cm_bold sm_totalhr1"
                : moment.utc(avgOfficeHours * 1000).format("HH:mm") <
                  moment.utc(32400 * 1000).format("HH:mm")
                ? "cm_bold sm_totalhr"
                : "cm_bold sm_totalhr2"
            }`}
          >
            <span>
              {avgOfficeHours === STATUS_VALUE_ZERO ||
              avgOfficeHours === undefined
                ? "00.00"
                : moment.utc(avgOfficeHours * 1000).format("HH.mm")}
              /09.00
            </span>
          </div>
          <div className="row d-flex justify-content-end ">
            <div className="col search_device_bar4">
              <span
                className="btn btn-primary add_empBtn"
                onClick={handleOpenAddAttendancePopup}
              >
                Add Attendance
              </span>

              <input
                maxLength={20}
                className="form-control mr-lg-2 cm_box"
                type="search"
                placeholder="Search by Name/E.ID"
                aria-label="Search"
                value={search}
                onChange={(e) => handleSearch(e)}
                autoComplete="off"
              />
              {search ? (
                <span
                  className="cm_clear4"
                  onClick={(e) => handleSelectChange(e)}
                >
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </span>
              ) : (
                <span className="cm_clear4">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="filters justify-content-end mb-4">
        <DatePicker
          value={searchDate ? moment(searchDate) : ""}
          format={dateFormat}
          className="date-border mr-3 mt-1 filter-date"
          onChange={handleDate}
          disabledDate={(current) => current.isAfter(moment())}
        />
        <Select
          className="select_year_cal  mr-3"
          value={searchMonth ? searchMonth : "Month"}
          defaultValue="Select Month"
          name="searchMonth"
          onChange={(e) => handleMonth(e)}
          allowClear={searchMonth ? true : false}
          disabled={dateRange === "true" || dateRange === true}
        >
          {MONTH_ARRAY.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>

        <Select
          className="select_year_cal mr-3"
          value={searchYear ? searchYear : "Select Year"}
          defaultValue="Select Year"
          name="searchYear"
          onChange={handleYear}
          allowClear={searchYear ? true : false}
          disabled={dateRange === "true" || dateRange === true}
        >
          {createSelectItems()}
        </Select>
        <div className="select_year_cal mr-2 mt-2">
          <div>
            <span
              className="btn btn-primary btn_csv"
              onClick={handleOpenUploadAttendancePopup}
            >
              Upload Excel
            </span>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "3rem" }}>
        <TabRender
          present={present}
          late={late}
          absent={absent}
          Others={Others}
          halfOthers={halfOthers}
          earlyCheckOutTab={earlyCheckOutTab}
          workFromHome={workFromHome}
          halfWfh={halfWfh}
          missedCheckOut={missedCheckOut}
          missedCheckIn={missedCheckIn}
          lateAndTotalHoursNotComplete={lateAndTotalHoursNotComplete}
          onLeave={onLeave}
          tabStatus={tabStatus}
          totalAttendance={totalAttendance}
          handleTabChange={handleTabChange}
          bereavementLeaves={bereavementLeaves}
          parentingLeave={parentingLeave}
          clientOff={clientOff}
        />
      </div>
      <ViewAdminAttendance
        sortType={sortType}
        sortField={sortField}
        AttendanceDetails={AttendanceDetails}
        pageNo={pageNo}
        handleOpenEditAttendancePopup={handleOpenEditAttendancePopup}
        handleOpenDeleteAttendancePopup={handleOpenDeleteAttendancePopup}
        handleOpenLeavePopup={handleOpenLeavePopup}
        checkBox={checkBox}
        dispatch={dispatch}
      />
      <OpenUploadAttendanceExcel
        show={openUploadAttendancePopup}
        excelSheetUploadError={excelSheetUploadError}
        handleDateChange={handleDateChange}
        miniLoader={miniLoader}
        dateErrorMsg={dateErrorMsg}
        errorCode={errorCode}
        handleAttendanceLocation={handleAttendanceLocation}
        responseMessage={responseMessage}
        attendanceLocation={attendanceLocation}
        attendanceLocationErrorMsg={attendanceLocationErrorMsg}
        wantSendMail={wantSendMail}
        handleWantSendmail={handleWantSendmail}
        handleUploadAttendanceSubmit={handleUploadAttendanceSubmit}
        handleClose={closeModal}
        date={date}
        getExcelSheet={getExcelSheet}
        wantSendMailErrorMsg={wantSendMailErrorMsg}
      />
      <AddAttendanceModal
        show={openAddAttendancePopup}
        handleClose={closeModal}
        miniLoader={miniLoader}
        employeeId={employeeId}
        employeeIdErrorMsg={employeeIdErrorMsg}
        handleStatusChange={handleStatusChange}
        statusErrorMsg={statusErrorMsg}
        handleSelectEmployeeChange={handleSelectEmployeeChange}
        handleDateChange={handleDateChange}
        date={date}
        dateErrorMsg={dateErrorMsg}
        setStatus={setStatus}
        checkIn={checkIn}
        checkInErrorMsg={checkInErrorMsg}
        handleCheckInChange={handleCheckInChange}
        checkOut={checkOut}
        checkOutErrorMsg={checkOutErrorMsg}
        handleCheckOutChange={handleCheckOutChange}
        handleAddAttendanceSubmit={handleAddAttendanceSubmit}
        status={status}
        employeeList={employeeList}
        wantSendMail={wantSendMail}
        wantSendMailErrorMsg={wantSendMailErrorMsg}
        handleWantSendmail={handleWantSendmail}
      />
      <EditAttendanceModal
        show={openEditAttendancePopup}
        statusErrorMsg={statusErrorMsg}
        handleDateChange={handleDateChange}
        miniLoader={miniLoader}
        dateErrorMsg={dateErrorMsg}
        handleStatusChange={handleStatusChange}
        errorCode={errorCode}
        handleAttendanceLocation={handleAttendanceLocation}
        responseMessage={responseMessage}
        attendanceLocation={attendanceLocation}
        attendanceLocationErrorMsg={attendanceLocationErrorMsg}
        wantSendMail={wantSendMail}
        handleWantSendmail={handleWantSendmail}
        handleEditAttendanceSubmit={handleEditAttendanceSubmit}
        handleClose={closeModal}
        date={date}
        setStatus={setStatus}
        checkIn={checkIn}
        checkInErrorMsg={checkInErrorMsg}
        handleCheckInChange={handleCheckInChange}
        checkOut={checkOut}
        checkOutErrorMsg={checkOutErrorMsg}
        handleCheckOutChange={handleCheckOutChange}
        wantSendMailErrorMsg={wantSendMailErrorMsg}
      />
      <DeleteAttendanceModal
        handleClose={closeModal}
        miniLoader={miniLoader}
        show={openDeleteAttendancePopup}
        handleDelete={handleDelete}
      />
      <OpenLeaveModal
        handleClose={closeModal}
        show={openLeavePopup}
        handleLeave={handleLeave}
        resLeaveActionFail={resLeaveActionFail}
      />
      {dataCount > 10 ? (
        <div style={{ marginBottom: "1rem" }}>
          <Pagination
            activePage={+pageNo}
            itemsCountPerPage={count}
            totalItemsCount={dataCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AdminAttendance;

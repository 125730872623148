import React from 'react';
import ResponseFilter from '../../ResponseFilter';
import { Link } from 'react-router-dom';
import { EyeIcon } from '../svg';

import { getAdminProfile, changePassword } from '../../config/services/employeeServices';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordErrorMsg: '',
      confirmPasswordErrorMsg: '',
      newPasswordErrorMsg: '',
      resMsg: '',
      resErrorMsg: '',
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
    }
  }


  componentDidMount() {
    getAdminProfile().then(res => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({ profile: resData.employeeProfile })
      }
    })
  }
  handleShowPassword1 = () => this.setState({ showPassword1: !this.state.showPassword1 });
  handleShowPassword2 = () => this.setState({ showPassword2: !this.state.showPassword2 });
  handleShowPassword3 = () => this.setState({ showPassword3: !this.state.showPassword3 });

  handleChange = e => this.setState({
    [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
    [e.target.name + 'ErrorMsg']: '',
    resMsg: '',
    resErrorMsg: ''
  });

  handleValidetion = () => {
    let validate = true;
    let { newPassword, currentPassword, confirmPassword } = this.state;

    this.setState({ resMsg: '' })

    if ((currentPassword === '' || currentPassword === undefined)) {
      validate = false;
      this.setState({ currentPasswordErrorMsg: 'Current password can not be empty' })
    } else if (currentPassword.length < 6) {
      validate = false;
      this.setState({ currentPasswordErrorMsg: 'Minimum 6 letter password required.' })
    } else {
      this.setState({ currentPasswordErrorMsg: '', })
    }


    if ((newPassword === '' || newPassword === undefined)) {
      validate = false;
      this.setState({ newPasswordErrorMsg: 'New password can not be empty' })
    } else if (newPassword.length < 6) {
      validate = false;
      this.setState({ newPasswordErrorMsg: 'Minimum 6 letter password required.' })
    }
    else if ((newPassword === currentPassword)) {
      validate = false;
      this.setState({ newPasswordErrorMsg: 'Current password and new password can not be same.' })
    } else {
      this.setState({ newPasswordErrorMsg: '' })
    }


    if (confirmPassword === '' || confirmPassword === undefined) {
      validate = false;
      this.setState({ confirmPasswordErrorMsg: 'Confirm password can not be empty' })
    }
    else if (confirmPassword !== newPassword) {
      validate = false;
      this.setState({ confirmPasswordErrorMsg: 'New password and confirm password must be same.' })
    }
    else {
      this.setState({ confirmPasswordErrorMsg: '' })
    }

    return validate
  }


  handleSubmit = event => {
    event.preventDefault();

    let { newPassword, currentPassword, confirmPassword } = this.state;
    if (this.handleValidetion()) {
      this.setState({ miniLoader: true, resMsg: '' });

      let obj = {
        'newPassword': newPassword,
        'currentPassword': currentPassword,
        'confirmPassword': confirmPassword
      }


      changePassword(obj).then(res => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          this.setState({
            newPassword: '',
            currentPassword: '',
            confirmPassword: '',
            currentPasswordErrorMsg: '',
            confirmPasswordErrorMsg: '',
            newPasswordErrorMsg: '',
            resMsg: resData.message,

          })
        } else {
          this.setState({
            resErrorMsg: resData,
            currentPasswordErrorMsg: '',
            confirmPasswordErrorMsg: '',
            newPasswordErrorMsg: ''
          })
        }

      })
    }

  }


  render() {

    let { showPassword1, showPassword2, showPassword3, profile, newPasswordErrorMsg, newPassword, currentPassword, confirmPassword, currentPasswordErrorMsg, confirmPasswordErrorMsg, resMsg, resErrorMsg } = this.state;



    return (
      <div className="body_container cm_bold">
        <div className="cm_breadcrumb">
          <ul>
            <li><Link to='/'>DASHBOARD</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li>CHANGE PASSWORD</li>

          </ul>
        </div>
        <form className="change_password" onSubmit={this.handleSubmit}>

          <div className="row">
            <div className="col-10">
              <div className="row">
                <div className="col-2 cm_user_img">
                  {/* <img className="" src={`${profile.profileImage ? profile.profileImage : require('../../asset/imgs/user-img.png')}`} alt="" /> */}

                </div>



                <div className="col-xl-8 col-md-12">
                  <h3 className="mb-4 mt-4">Change Password</h3>

                  {resMsg && <span className=" mt-5 text-center mt-2 changePassSuccess">{resMsg}</span>}

                  <form>

                    <div className="row">
                      <div className={`col-xl-8 col-md-12 mb-5 form-group" ${currentPasswordErrorMsg ? 'input_error' : ''}`}>
                        <lable>Current Password</lable>

                        <span onClick={this.handleShowPassword1} className={`eye_icon1 ${this.state.showPassword1 ? '' : 'slesh'}`}><EyeIcon /></span>
                        <input
                          maxlength="50"
                          type={`${showPassword1 ? 'text' : 'password'}`}
                          className="form-control cm_box"
                          placeholder="Enter current password"
                          name="currentPassword"
                          value={currentPassword}
                          onChange={this.handleChange}
                        />
                        {currentPasswordErrorMsg ? <span className="error_msg mt-2">{currentPasswordErrorMsg}</span> : null}
                        {/* {resMsg && <span className="error_msg mt-5 text-center mt-2">{resMsg}</span>} */}
                        {resErrorMsg && <span className="error_msg mt-5 text-center mt-2">{resErrorMsg}</span>}

                      </div>


                      <div className={`col-xl-8 col-md-12 mb-5 ${newPasswordErrorMsg ? 'input_error' : ''}`}>
                        <lable>New Password</lable>
                        <span onClick={this.handleShowPassword2} className={`eye_icon2 ${this.state.showPassword2 ? '' : 'slesh'}`}><EyeIcon /></span>
                        <input
                          maxlength="50"
                          type={`${showPassword2 ? 'text' : 'password'}`}
                          className="form-control cm_box"
                          placeholder="Enter new password"
                          name="newPassword"
                          value={newPassword}
                          onChange={this.handleChange}
                        />
                        {newPasswordErrorMsg ? <span className="error_msg">{newPasswordErrorMsg}</span> : null}
                      </div>



                      <div className={`col-xl-8 col-md-12 mb-5 ${confirmPasswordErrorMsg ? 'input_error' : ''}`}>
                        <lable>Confirm Password</lable>

                        <span onClick={this.handleShowPassword3} className={`eye_icon3 ${this.state.showPassword3 ? '' : 'slesh'}`}><EyeIcon /></span>
                        <input
                          maxlength="50"
                          type={`${showPassword3 ? 'text' : 'password'}`}
                          className="form-control cm_box"
                          placeholder="Enter confirm password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={this.handleChange}

                        />
                        {confirmPasswordErrorMsg ? <span className="error_msg">{confirmPasswordErrorMsg}</span> : null}
                      </div>


                    </div>

                    <div>
                      <button className="btn btn-primary mt-4 mr-4 cm_btn3" type="submit">Save</button>
                      <Link to='/' className="btn btn-primary mt-4 cm_btn2">Cancel</Link>
                    </div>

                    <div className="row">
                      <div className="col-8">

                      </div>
                    </div>


                  </form>

                </div>


              </div>

            </div></div>
        </form>
      </div>
    );
  }
}
export default ChangePassword;
import React from 'react'
import { Modal } from "react-bootstrap"
export default function SalarySlipModal(props) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title><b>APPLY FOR CLIENT LOCATION</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum voluptatum doloribus ea. Tempore ea corporis omnis, vitae perferendis, veniam assumenda numquam officiis quos quas accusantium repudiandae asperiores optio quia accusamus?
            </Modal.Body>
        </Modal>
    )
}

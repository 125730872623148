import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { halfTypeValue, leaveValue } from "../Constants";
import { Select } from "antd";
import Calendar from "react-calendar";
import TextEditor from "../../../hoc/textEditor/TextEditor";
import { ErrorMessage } from "../../helper/Constant";
const { Option } = Select;

export default function ApplyTempCompOffModel(props) {
  const {
    openApplyTempCompOff,
    handleClose,
    handleleaveTypeChange,
    leaveType1ErrorMsg,
    leaveType1,
    halfOfTheDayErrMsg,
    halfOfTheDay,
    handleSelectHalfChange,
    start1dateErrorMsg,
    onStartChange,
    startDate1,
    tempCompOffDetail,
    enddate1ErrorMsg,
    onEndChange,
    endDate1,
    emailError,
    employeeList,
    replyErrorMsg,
    editorState,
    onEditorStateChange,
    handleApplyForCompOffSubmit,
    responseMessage,
  } = props;
  return (
    <div>
      <Modal show={openApplyTempCompOff} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Apply For Temporary CompOff</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approve_leave">
            <div>
              <div className="cm_overflow_reason">
                <div>
                  <div>
                    <label>Type</label>
                    <Select
                      name="editleaveType"
                      value={leaveValue(leaveType1)}
                      placeholder="Select Type"
                      onChange={handleleaveTypeChange}
                      className="select_emp_leave select_type mb-2 "
                    >
                      <Option value="5">CompOff Leave</Option>
                      <Option value="10">CompOff Half Day</Option>
                    </Select>
                  </div>
                  <ErrorMessage errMsg={leaveType1ErrorMsg} />
                </div>
                {leaveType1 == 10 ? (
                  <div
                    className={`row  ${
                      halfOfTheDayErrMsg ? "input_error" : ""
                    }`}
                  >
                    <div className="col-6">
                      <label className="mt-4">Half Type</label>
                      <Select
                        style={{ width: "80%" }}
                        value={halfTypeValue(halfOfTheDay)}
                        placeholder="Select Half Type"
                        onChange={handleSelectHalfChange}
                      >
                        <Option value={1}>First Half Leave</Option>
                        <Option value={2}>Second Half Leave</Option>
                      </Select>
                      <ErrorMessage errMsg={halfOfTheDayErrMsg} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div
                    className={`form-group col-12 ${
                      start1dateErrorMsg ? "input_error" : ""
                    }`}
                  >
                    <label className="mt-4">Start Date</label>
                    <br />
                    <Calendar
                      onChange={onStartChange}
                      value={startDate1 ? new Date(startDate1) : ""}
                      minDate={new Date()}
                      maxDate={new Date(tempCompOffDetail.expireDate)}
                    />
                    <ErrorMessage errMsg={start1dateErrorMsg} />
                  </div>
                  {leaveType1 == "10" ? (
                    ""
                  ) : (
                    <div
                      className={`form-group col-12 ${
                        enddate1ErrorMsg ? "input_error" : ""
                      }`}
                    >
                      <label className="mt-4">End Date</label>
                      <br />
                      <Calendar
                        onChange={onEndChange}
                        value={endDate1 ? new Date(endDate1) : ""}
                        minDate={new Date()}
                        maxDate={new Date(tempCompOffDetail.expireDate)}
                      />
                      <ErrorMessage errMsg={enddate1ErrorMsg} />
                    </div>
                  )}
                  <div className={`form-group col-12 `}>
                    <h3 className="mt-5"> Send To</h3>
                    <main className="wrapper">
                      <input
                        className={`email-input`}
                        value={"hr@mobcoder.com"}
                        style={{ width: "80%" }}
                        disabled
                      />
                    </main>
                  </div>
                  <div
                    className={`form-group col-12 ${
                      emailError ? "input_error" : ""
                    }`}
                  >
                    <h3 className="mt-5">Cc</h3>
                    <span className="red">
                      * Please mention your project manager and teamlead in Cc
                    </span>
                    <Select
                      showSearch
                      mode="multiple"
                      name="employeeId"
                      value={props?.emails ? props?.emails : "Select Cc"}
                      placeholder={"Select Cc"}
                      optionFilterProp="key"
                      filterOption={(input, option) =>
                        option.key.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={props?.handleEmailChange}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList
                            .filter(
                              (data) =>
                                data.employeeId !==
                                localStorage.getItem("employeeId")
                            )
                            .map(
                              (employee) =>
                                employee.status == 1 && (
                                  <Option
                                    key={`${employee.firstName} ${employee.lastName}`}
                                    value={employee.email}
                                  >
                                    <span>{employee.email}</span>
                                  </Option>
                                )
                            )
                        : ""}
                    </Select>
                  </div>
                </div>
              </div>
              <div
                className={`form-group col-12 ${
                  replyErrorMsg ? "input_error" : ""
                }`}
              >
                <label className="mt-4">Reason</label>
                <TextEditor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />
                <ErrorMessage errMsg={replyErrorMsg} />
              </div>
            </div>
            <div className="text-center mt-2">
              <button
                type="button"
                className="btn btn-success"
                onClick={handleApplyForCompOffSubmit}
              >
                Apply
              </button>
              {responseMessage && (
                <h3 className=" Registration_error mt-5">{responseMessage}</h3>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

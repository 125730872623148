import {
    EDIT_ASSIGN_CANDIDATE_REQUEST,
    EDIT_ASSIGN_CANDIDATE_SUCCESS,
    EDIT_ASSIGN_CANDIDATE_FAILURE,
} from "./actionType";

import { editAssignCandidate } from "../../config/services/employeeServices";
import { getAssignInterviews } from "./getAssignInterviews";

const editAssignCandidateByInterviewer = (data, query, setShowModal, setResMsg, setFailResMsg, setLoader, closeModal, getData) => {
    return (dispatch) => {
        dispatch(editAssignCandidateByInterviewerRequest());
        editAssignCandidate(data)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(editAssignCandidateByInterviewerFailure(res.data));
                    // setErrorText("Failed to edit");
                    setFailResMsg(true)
                    setResMsg(false)
                    setLoader(false)

                } else {
                    dispatch(editAssignCandidateByInterviewerSuccess(res.data));
                    setResMsg(true)
                    setFailResMsg(false)
                    setTimeout(() => (
                        closeModal(),
                        setLoader(false),
                        dispatch(getAssignInterviews(query)),
                        getData()
                    ), 3000);

                }
            })
        // .catch((error) => {
        //     dispatch(editAssignCandidateByInterviewerFailure(error));
        //     setErrorText("Failed to edit");
        //     setFailResMsg(true)
        //     // setLoader(false)
        // });
    };
};

const editAssignCandidateByInterviewerRequest = () => {
    return {
        type: EDIT_ASSIGN_CANDIDATE_REQUEST,
    };
};
const editAssignCandidateByInterviewerSuccess = (data) => {
    return {
        type: EDIT_ASSIGN_CANDIDATE_SUCCESS,
        payload: data,
    };
};
const editAssignCandidateByInterviewerFailure = (error) => {
    return {
        type: EDIT_ASSIGN_CANDIDATE_FAILURE,
        payload: error,
    };
};
export {
    editAssignCandidateByInterviewer,
    editAssignCandidateByInterviewerRequest,
    editAssignCandidateByInterviewerSuccess,
    editAssignCandidateByInterviewerFailure,
};

import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import AdminAddResModal from "./AdminAddResModal";
import AdminUpdateResumeModal from "./AdminUpdateResumeModal";
import { getResumeByEmpId, deleteEmpResumeByAdmin } from "../../redux/actions";
import { Loader } from "../svg";
import { updateResumeByEmpId } from "../../redux/actions/getResumeByEmpIdActionUpdate";
import { userImage, deleteIcon, downloadIcon } from "../../asset";
import "./ResumeSectionStyles.css";
import moment from "moment/moment";
import Pagination from "react-js-pagination";
import qs from 'qs'
import { useHistory } from "react-router-dom";
import ResumeDeleteModal from "./ResumeDeleteModal";
const AdminEmpResumeDetails = ({
  getResumeByEmpId,
  empResumeList,
  updateResumeByEmpId,
  employeeList,
  resumeByEmpId,
  deleteEmpResumeByAdmin,
  dataCount,

}) => {
  const count = 10
  const location = useLocation();
  const empId = location?.state?.empId ? location?.state?.empId : sessionStorage.getItem("empId");
  sessionStorage.setItem("empId",empId)
  const history = useHistory()
  const data = employeeList.filter((data) => data.employeeId === empId);
  const employeeData = data[0];
  const [showModal, setShowModal] = useState(false);
  const [updateResume, setUpdateResume] = useState(false);
  const [resumeId, setResumeId] = useState("")
  const [pageNo, setPageNo] = useState(1)
  const handleCloseUpdate = () => setUpdateResume(!updateResume);
  const [state, setState] = useState({
    resMsgDelete: false,
  })
  const [resumeIdForDelete, setResumeIdForDelete] = useState("")
  const [resumeDeleteModalOpen, setResumeDeleteModalOpen] = useState("")
  const [miniLoader, setMiniLoader] = useState(false)
  const params = `employeeId=${empId}&count=${count}&pageNo=${pageNo - 1}`
  const urlParams = `?pageNo=${pageNo}`

  useEffect(() => {
    getData();
  }, [pageNo]);

  const getData = async () => {
    history.push(urlParams)
    await getResumeByEmpId(params);
  };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1)

  }, [history.location.search]);


  // useEffect(() => {
  //   getDataUpdate();
  // }, [pageNo]);
  // const getDataUpdate = async () => {
  //   const params = `employeeId=${empId}&count=${count}&pageNo=${pageNo - 1}`

  //   await updateResumeByEmpId(params);
  // };


  const handleDeleteOnClick = async () => {
    setMiniLoader(true)
    const data = {
      data: {
        resumeId: resumeIdForDelete,
      },
    };
    await deleteEmpResumeByAdmin(data, empId, closeModal);
    setState({ resMsgDelete: true })
    setTimeout(() => setState({ resMsgDelete: false }), 3000);
  };
  const handleOnAddResumeClick = () => {
    setShowModal(true);
  };
  // ******************************************** //
  const handleOnUpdateResumeClick = (e) => {
    setUpdateResume(true);
    setResumeId(e)
  };
  //  ******************************************* //

  const handlePageChange = (pageNo) => {
    setPageNo(pageNo);
  };
  const closeModal = () => {
    setResumeDeleteModalOpen(false)
    setResumeIdForDelete("")
    setMiniLoader(false)

  }
  const handleOpenDeleteModal = (e) => {
    setResumeDeleteModalOpen(true)
    setResumeIdForDelete(e._id)
  }
  return (
    <div className="body_container cm_bold">
      {
        state.resMsgDelete ?
          <div className="alert alert-danger cm_top_fix">Resume deleted successfully</div> : ''
      }
      <div className="cm_breadcrumb">
        <ul>
          <li><Link to='/resumeSection'>RESUME  DETAIL</Link><div className="cm_breadcrumb_arrow"></div></li>
          <li style={{ textTransform: 'uppercase' }}>{employeeData.firstName + " " + employeeData.lastName}
          </li>
        </ul>
      </div>
      <AdminAddResModal showModal={showModal} setShowModal={setShowModal} employeeList={employeeList} empResumeId={empId} getData={getData} />
      <AdminUpdateResumeModal showModal={updateResume} setShowModal={setUpdateResume} resumeId={resumeId} empId={empId}/>

      <div className="employeeDetails-container">
        <div className="headersDiv">
          <ProfileImage
            src={
              employeeData.profileImage ? employeeData.profileImage : userImage
            }
            alt="profilePic"
          />
          <div className="employeeDetailsDiv">
            <h2 className="userInfo">
              <Link to={`/resumeSection/employee-detail/${empId}`}>
                {employeeData.firstName + " " + employeeData.lastName}
              </Link>
            </h2>
            <h5 className="userInfo">{employeeData.empId}</h5>
          </div>
        </div>
        <div className="contentDiv">
          <div className="tableDescDiv">
            <h3 > RESUME </h3>
            <Button onClick={handleOnAddResumeClick}>+ Add Resume</Button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Title</th>
                  <th scope="col">Last Updated</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {resumeByEmpId ? (
                  resumeByEmpId.map((element, i) => {
                    return (
                      <tr>
                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                        <td>{element.title}</td>
                        <td>
                          {moment(element.updated).format("HH:mm") +
                            // ":" +
                            // new Date(element.updated).getMinutes() +
                            " on " +
                            new Date(element.updated).getDate() +
                            "/" +
                            (new Date(element.updated).getMonth() + 1) +
                            "/" +
                            new Date(element.updated).getFullYear()}
                        </td>
                        <td className="actionDiv">
                          <a href={element.resume} download={element.title}>
                            <ActionImage
                              src={downloadIcon}
                              alt="downloadIcon"
                            />
                          </a>

                          <span onClick={() => handleOnUpdateResumeClick(element._id)}><i className="fa fa-pencil mr-4 ml-2" aria-hidden="true" title="Edit appraisal"></i></span>
                          <ActionImage
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => {
                              handleOpenDeleteModal(element);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <Loader />
                )}
              </tbody>
            </table>
          </div>
          {dataCount > 10 ? (
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={dataCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <ResumeDeleteModal
        handleClose={closeModal}
        miniLoader={miniLoader}
        show={resumeDeleteModalOpen}
        handleDelete={handleDeleteOnClick}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    empResumeList: state.empResumeList.data.resumeList?.employeeProfile,
    employeeList: state.employeeList.data?.employeesList,
    resumeByEmpId: state.resumeByEmpId.data.resumeInfo?.allResume,
    dataCount: state.resumeByEmpId.data.resumeInfo?.totalResume

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getResumeByEmpId: (params) => {
      dispatch(getResumeByEmpId(params));
    },
    // updateResumeByEmpId: (params) => {
    //   dispatch(updateResumeByEmpId(params));
    // },

    deleteEmpResumeByAdmin: (resumeId, empId, closeModal) => {
      dispatch(deleteEmpResumeByAdmin(resumeId, empId, closeModal));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminEmpResumeDetails);

const ProfileImage = styled.img`
  width: 300px;
  height: 250px;
  border-radius: 10px;
`;
const ActionImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
const Button = styled.button`
  background-color: rgb(52, 123, 222);
  cursor: pointer;
  width: 200px;
  border: none;
  padding: 0.4rem;
  border-radius: 10px;
  color: white;
  font-size: medium;
`;

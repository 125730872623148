import ResponseFilter from "../../ResponseFilter";
import { decryptResponseData } from "../../components/salaryGeneration/encryptionAndDecryption";
import { exportEmployeesSalary } from "../../config/services/employeeServices";
import {
    EXPORT_EMPLOYEES_SALARY_REQUEST,
    EXPORT_EMPLOYEES_SALARY_SUCCESS,
    EXPORT_EMPLOYEES_SALARY_FAILURE,
} from "./actionType";

const exportEmployeesSalaryAction = (props) => {
    let { useDispatch, params, handleBonusClose } = props

    return (dispatch) => {
        dispatch(exportEmployeesSalaryRequest());
        exportEmployeesSalary(params)
            .then((res) => {
                let { status } = ResponseFilter(res)
                if (status) {
                    dispatch(exportEmployeesSalarySuccess(res.data.responseData));
                    useDispatch({
                        type: "typeCheckSet", payload: {
                            resMsg: true,
                            successMessage: "Salary Exported",
                            bonusEmployees: []
                        }
                    })
                    handleBonusClose()
                    window.location.href = decryptResponseData(res?.data?.responseData?.excelLink)
                } else {
                    dispatch(exportEmployeesSalaryFailure(res.data));
                    useDispatch({
                        type: "typeCheckSet", payload: {
                            resFailMsg: true,
                            responseMessage: res?.data?.error?.responseMessage
                        }
                    })
                }

                setTimeout(() => {
                    useDispatch({
                        type: "typeCheckSet", payload: {
                            resMsg: false,
                            resFailMsg: false,
                        }
                    })
                }, 10000)
            })
            .catch((error) => {
                dispatch(exportEmployeesSalaryFailure(error));
            });
    };
};

const exportEmployeesSalaryRequest = () => {
    return {
        type: EXPORT_EMPLOYEES_SALARY_REQUEST,
    };
};

const exportEmployeesSalarySuccess = (data) => {
    return {
        type: EXPORT_EMPLOYEES_SALARY_SUCCESS,
        payload: data,
    };
};

const exportEmployeesSalaryFailure = (error) => {
    return {
        type: EXPORT_EMPLOYEES_SALARY_FAILURE,
        payload: error,
    };
};
export {
    exportEmployeesSalaryAction,
    exportEmployeesSalaryRequest,
    exportEmployeesSalarySuccess,
    exportEmployeesSalaryFailure,
};

import React from "react";
import { Select } from "antd";
import { Modal } from "react-bootstrap";
import { ErrorMessage, setResponseAction, type } from "../../helper/Constant";
import _ from "lodash";
import {
  CATEGORY_ARRAY,
  onEditorStateChange,
  handleValidation,
} from "../Constants";
import TextEditor from "../../../hoc/textEditor/TextEditor";
import { editTicket } from "../../../config/services/employeeServices";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import ResponseFilter from "../../../ResponseFilter";
import { useSelector } from "react-redux";

const { Option } = Select;

const EditTicketModal = (props) => {
  const {
    openEditModal,
    categoryErrorMsg,
    subject,
    subjectErrorMsg,
    emailError,
    emails,
    handleEmailChange,
    employeeList,
    descriptionErrorMsg,
    editorState,
    dispatch,
    handleChange,
    closeModal,
    category,
    handleCategoryChange,
    isAdmin,
    employeeId,
    employeeIdErrorMsg,
    state,
    ticketId,
    getTicketList,
  } = props;
  const empId = useSelector(
    (state) => state.getEmpProfileByAdminReducer.data?.employeeProfile?.employeeId
  );

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (handleValidation({ state, dispatch })) {
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      let obj = {
        ticketId: ticketId,
        category: category,
        subject: subject,
        description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        emailInCC: emails.length !== 0 ? emails.toString() : [],
      };
      editTicket(obj)
        .then((res) => {
          dispatch({
            type: type,
            payload: {
              loader: false,
            },
          });
          let { status, resData } = ResponseFilter(res);
          if (status) {
            dispatch({
              type: type,
              payload: {
                successMessage: "Ticket Edited",
                resMsg: true,
              },
            });
            getTicketList();
            closeModal();
            setResponseAction({ dispatch });
          } else {
            dispatch({
              type: type,
              payload: {
                resFailMsg: true,
                responseMessage: resData,
              },
            });
            getTicketList();
            closeModal();
            setResponseAction({ dispatch });
          }
        })
        .catch((error) => {
          dispatch({
            type: type,
            payload: {
              resFailMsg: true,
              responseMessage: error,
            },
          });
        });
      getTicketList();
      closeModal();
      setResponseAction({ dispatch });
    }
  };
  return (
    <Modal
      size="md"
      show={openEditModal}
      onHide={closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <form className="cm_add_ticket" onSubmit={(e) => handleEditSubmit(e)}>
          <h3>EDIT TICKET</h3>
          <div className={`form-group ${categoryErrorMsg && "input_error"} `}>
            <div className="cm_category">
              <Select
                className=" cm_bold "
                style={{ width: 200 }}
                onChange={handleCategoryChange}
                defaultValue={
                  CATEGORY_ARRAY.find(
                    (item) => item.value === category.toString()
                  )?.label
                }
              >
                {CATEGORY_ARRAY.map((category) => (
                  <Option key={category.value} value={category.value}>
                    {category.label}
                  </Option>
                ))}
              </Select>
              <ErrorMessage errMsg={categoryErrorMsg} />
            </div>
          </div>
          {isAdmin && (
            <>
              <label className="sub mt-5">Employee</label>
              <div className="form-group">
                <Select
                  showSearch
                  name="employeeId"
                  value={empId? empId: employeeId}
                  disabled
                >
                  {!_.isEmpty(employeeList)
                    ? employeeList
                        .sort((a, b) => a.firstName.localeCompare(b.firstName))
                        .map(
                          (employee) =>
                            employee.status === 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}(
                                  {employee.empId})
                                </span>
                              </Option>
                            )
                        )
                    : ""}
                </Select>
                <ErrorMessage errMsg={employeeIdErrorMsg} />
              </div>
            </>
          )}
          <label className={`sub ${!isAdmin && "mt-5"}`}>Subject</label>
          <div className={`form-group ${subjectErrorMsg && "input_error"}`}>
            <input
              className="form-control cm_box"
              maxLength="30"
              type="text"
              placeholder="Subject"
              name="subject"
              value={subject}
              onChange={handleChange}
              autoComplete="off"
            />
            <ErrorMessage errMsg={subjectErrorMsg} />
          </div>
          <div className={`form-group`}>
            <label className="sub">Send To</label>
            <input
              className="form-control cm_box"
              value={
                category === 1 || category === 2
                  ? "system@mobcoder.com"
                  : "hr@mobcoder.com"
              }
              disabled
            />
          </div>
          <div className={`form-group ${emailError && "input_error"}`}>
            <label className="sub">Cc</label>
            <Select
              showSearch
              mode="multiple"
              name="employeeId"
              value={emails ? emails : "Select Cc"}
              placeholder={"Select Cc"}
              optionFilterProp="key"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleEmailChange}
            >
              {!_.isEmpty(employeeList)
                ? employeeList
                    .filter(
                      (data) =>
                        data.employeeId !== localStorage.getItem("employeeId")
                    )
                    .sort((a, b) => a.email.localeCompare(b.email))
                    .map(
                      (employee) =>
                        employee.status === 1 && (
                          <Option
                            key={`${employee.firstName} ${employee.lastName}`}
                            value={employee.email}
                          >
                            <span>{employee.email}</span>
                          </Option>
                        )
                    )
                : ""}
            </Select>
            <ErrorMessage errMsg={emailError} />
          </div>
          <div className={`form-group ${descriptionErrorMsg && "input_error"}`}>
            <label className="sub ">Description</label>
            <TextEditor
              editorState={editorState}
              onEditorStateChange={(e) => onEditorStateChange(e, dispatch)}
            />
            <ErrorMessage errMsg={descriptionErrorMsg} />
          </div>
          <div className="submit_button_margin">
            <button type="submit" className="btn btn-primary cm_btn3">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-primary cm_btn2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditTicketModal;

import {
  ADD_EMP_RESUME_BY_ADMIN_REQUEST,
  ADD_EMP_RESUME_BY_ADMIN_SUCCESS,
  ADD_EMP_RESUME_BY_ADMIN_FAILURE,
} from "./actionType";

import { addEmployeeResumeByAdmin } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
const addEmpResumeByAdmin = (data, empId, setState, handleCancelButtonClick, getData) => {
  return (dispatch) => {
    // let params = `employeeId=${empId}&pageNo=${0}&count=${10}`

    dispatch(addEmpResumeByAdminRequest());
    addEmployeeResumeByAdmin(data)
      .then((res) => {

        let { status } = ResponseFilter(res)
        if (status) {
          dispatch(addEmpResumeByAdminSuccess(res.data));
          // dispatch(getResumeByEmpId(params));
          getData()
          handleCancelButtonClick()
          setState({ resMsg: true, miniLoader: false })
          setTimeout(() => setState({ resMsg: false }), 3000);
        }
        else {
          setState({ miniLoader: false, resFailMessage: true, responseMessage: res?.data?.error?.responseMessage })
          setTimeout(() => setState({ resFailMessage: false }), 3000);
        }
      })
      .catch((error) => {
        dispatch(addEmpResumeByAdminFailure(error));
      });
  };
};

const addEmpResumeByAdminRequest = () => {
  return {
    type: ADD_EMP_RESUME_BY_ADMIN_REQUEST,
  };
};
const addEmpResumeByAdminSuccess = (data) => {
  return {
    type: ADD_EMP_RESUME_BY_ADMIN_SUCCESS,
    payload: data,
  };
};
const addEmpResumeByAdminFailure = (error) => {
  return {
    type: ADD_EMP_RESUME_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  addEmpResumeByAdmin,
  addEmpResumeByAdminRequest,
  addEmpResumeByAdminSuccess,
  addEmpResumeByAdminFailure,
};

import { type } from "../../components/helper/Constant";
import { deleteCourierDetailsByAdmin } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
  DELETE_COURIER_BY_ADMIN_REQUEST,
  DELETE_COURIER_BY_ADMIN_SUCCESS,
  DELETE_COURIER_BY_ADMIN_FAILURE,
} from "./actionType";

const deleteCourierDetailsByAdminAction =(props) => {
  let { useDispatch, params, getCourierList } = props;
  return (dispatch) => {
    dispatch(deleteCourierDetailsByAdminRequest());
    deleteCourierDetailsByAdmin(params)
      .then((res) => {
        let { status } = ResponseFilter(res);
        if (status) {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              resMsg: true,
              successMessage: "Courier Deleted",
            },
          });
          getCourierList();
          setTimeout(() => {
            useDispatch({
              type: type,
              payload: {
                resMsg: false,
              },
            });
          }, 3000);
        } else {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              resFailMsg: true,
              responseMessage: res?.data?.error?.responseMessage,
            },
          });
          getCourierList();
          setTimeout(() => {
            useDispatch({
              type: type,
              payload: {
                resFailMsg: false,
              },
            });
          }, 3000);
        }
      })
      .catch((error) => {
        dispatch(deleteCourierDetailsByAdminFailure(error));
      });
  };
};

const deleteCourierDetailsByAdminRequest = () => {
  return {
    type: DELETE_COURIER_BY_ADMIN_REQUEST,
  };
};
const deleteCourierDetailsByAdminSuccess = (data) => {
  return {
    type: DELETE_COURIER_BY_ADMIN_SUCCESS,
    payload: data,
  };
};
const deleteCourierDetailsByAdminFailure = (error) => {
  return {
    type: DELETE_COURIER_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  deleteCourierDetailsByAdminAction,
  deleteCourierDetailsByAdminRequest,
  deleteCourierDetailsByAdminSuccess,
  deleteCourierDetailsByAdminFailure,
};

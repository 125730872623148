import ResponseFilter from "../../../ResponseFilter";
import {
  addPrivacyPolicy,
  deletePrivacyPolicy,
  editPrivacyPolicy,
} from "../../../config/services/employeeServices";
import { type } from "../../helper/Constant";
import { handleValidation } from "./PolicyConstant";

export const handleAddEdit = (props) => {
  const {
    e,
    dispatch,
    toAddOrEdit,
    title,
    uploadFile,
    closeModal,
    getPolicyList,
    privacyId,
    editTitle,
    docLink,
  } = props;
  e.preventDefault();
  if (handleValidation({ title, dispatch, uploadFile, editTitle, docLink })) {
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    const params = new FormData();
    params.append("title", title);
    params.append("doc", uploadFile);

    if (toAddOrEdit === "Add") {
      addPrivacyPolicy(params)
        .then((res) => {
          dispatch({
            type: type,
            payload: {
              loader: false,
            },
          });
          const { status, resData } = ResponseFilter(res);
          if (status) {
            dispatch({
              type: type,
              payload: {
                resMsg: true,
                successMessage: "Privacy Policy Added",
              },
            });
            getPolicyList();
            closeModal();
          } else {
            dispatch({
              type: type,
              payload: {
                resFailMsg: true,
                responseMessage: resData.error?.responseMessage,
              },
            });
            getPolicyList();
            closeModal();
          }
          setTimeout(() => {
            dispatch({
              type: type,
              payload: { resFailMsg: false, resMsg: false },
            });
          }, 3000);
        })
        .catch((error) => {
          dispatch({
            type: type,
            payload: {
              resFailMsg: true,
              responseMessage: error,
              loader: false,
            },
          });
          getPolicyList();
          closeModal();
          setTimeout(() => {
            dispatch({
              type: type,
              payload: { resFailMsg: false, resMsg: false },
            });
          }, 3000);
        });
    } else {
      params.append("privacyId", privacyId);
      editPrivacyPolicy(params)
        .then((res) => {
          dispatch({
            type: type,
            payload: {
              loader: false,
            },
          });
          const { status, resData } = ResponseFilter(res);
          if (status) {
            dispatch({
              type: type,
              payload: {
                resMsg: true,
                successMessage: "Privacy Policy Edited",
              },
            });
            getPolicyList();
            closeModal();
          } else {
            dispatch({
              type: type,
              payload: {
                resFailMsg: true,
                responseMessage: resData?.error?.responseMessage,
              },
            });
            getPolicyList();
            closeModal();
          }
          setTimeout(() => {
            dispatch({
              type: type,
              payload: { resFailMsg: false, resMsg: false },
            });
          }, 3000);
        })
        .catch((error) => {
          dispatch({
            type: type,
            payload: {
              resFailMsg: true,
              responseMessage: error,
              loader: false,
            },
          });
          setTimeout(() => {
            dispatch({
              type: type,
              payload: { resFailMsg: false, resMsg: false },
            });
          }, 3000);
          getPolicyList();
          closeModal();
        });
    }
  }
};

export const handlePrivacyDelete = (props) => {
  const { e, privacyId, dispatch, closeModal, getPolicyList } = props;
  e.preventDefault();
  dispatch({
    type: type,
    payload: {
      loader: true,
    },
  });
  const params = { privacyId };
  deletePrivacyPolicy(params)
    .then((res) => {
      dispatch({
        type: type,
        payload: {
          loader: false,
        },
      });
      if (res.data.statusCode === 1) {
        dispatch({
          type: type,
          payload: {
            resMsg: true,
            successMessage: "Privacy Policy Deleted",
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            resFailMsg: true,
            responseMessage: res?.data?.error?.responseMessage,
          },
        });
      }
      getPolicyList();
      closeModal();
    })
    .catch((error) => {
      dispatch({
        type: type,
        payload: {
          resFailMsg: true,
          responseMessage: error,
          loader: false,
        },
      });
      getPolicyList();
      closeModal();
    });
  setTimeout(() => {
    dispatch({ type: type, payload: { resFailMsg: false, resMsg: false } });
  }, 3000);
};

import React from 'react'
import { Modal as AntModal } from "antd";
import Modal from "react-bootstrap/Modal";

import { Link } from 'react-router-dom';
import { Select } from 'antd';
import _ from 'lodash';
const { Option } = Select;
export default function AddTeamMembers(props) {
    return (
        <AntModal
            centered
            className="attend_modal"
            footer={false}
            visible={props.OpenAddMemberPopup}
            // onOk={this.handleOk}
            onCancel={props.closeModal}
        >
            <Modal.Body>
                <div className="assign_device">
                    <h3 className="mb-4">ADD NEW EMPLOYEE</h3>
                    <div className={`form-group selectHeight mb-5 ${props.employeeErrorMsg ? 'input_error' : ''}`}>
                        <Select
                            showSearch
                            mode="multiple"
                            value={props.employee}
                            style={{ width: "100%", height: "auto" }}
                            className="select_emp_leave mr-4 mb-2"
                            placeholder="Select Employee"
                            name="employeeId"
                            optionFilterProp="key"
                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                            onChange={props.handleChangeEmp}>
                            {
                                !_.isEmpty(props.employeeList) ?

                                    props.employeeList.sort(function (a, b) {
                                        if (a.firstName < b.firstName) { return -1; }
                                        if (a.firstName > b.firstName) { return 1; }
                                        return 0;
                                    }).filter(employee => ![...props.leadsArray].includes(employee.employeeId)).map((emp) => emp.status == 1 && (<Option key={`${emp.firstName} ${emp.lastName}`} value={emp.employeeId}>
                                        ({emp.empId}){emp.firstName} {emp.lastName}</Option>)) : ""
                            }
                        </Select>
                        {props.employeeErrorMsg ? <span className="error_msg">{props.employeeErrorMsg}</span> : null}

                    </div>
                    <div className='submit_button_margin mt-4'>

                        <button
                            type="submit"
                            onClick={props.handleSave}
                            className="btn btn-primary cm_btn3"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={props.closeModal}
                            className="btn btn-primary cm_btn2"
                        >
                            Cancel
                        </button>
                    </div>

                </div>
            </Modal.Body>
        </AntModal>
    )
}

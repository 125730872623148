import React from "react";
import { Modal } from "react-bootstrap";
import { createMarkup } from "../../helper/Constant";

const StatusInfoModal = (props) => {
  const {
    openStatusInfoPopup,
    closeModal,
    handleReopenSubmit,
    subject,
    description,
    reply,
    status,
  } = props;

  return (
    <Modal
      size="lg"
      className="cm_ticket_status"
      show={openStatusInfoPopup}
      onHide={closeModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <form className="cm_ticket_status" onSubmit={handleReopenSubmit}>
          <h3 className="mb-4">STATUS</h3>
          <div className="cm_overflow">
            <div className="emp_profile">
              <label>Subject</label>
              <p>{subject}</p>
              <label>Reason</label>
              <h5 dangerouslySetInnerHTML={createMarkup(description)}/>
              <p>{description}</p>
              {reply ? <label>Reply</label> : ""}
              <p>{reply ? reply : ""}</p>
            </div>
          </div>

          {status === 2 ? (
            <div className="submit_button_margin mt-4">
              <button type="submit" className="btn btn-primary cm_btn3">
                Reopen
              </button>
              <button
                type="button"
                className="btn btn-primary  cm_btn2"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          ) : (
            ""
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default StatusInfoModal;

import moment from "moment";
import { REQUIRED, STATUS_VALUE_ZERO } from "../attendance/Constants";
import {
  EndDate_Greater,
  HALF_CASUAL_LEAVE,
  Half_Casual_Leave,
  HALF_COMPENSATORY_OFF,
  Half_Compensatory_Off,
  HALF_EARNED_LEAVE,
  Half_Earned_Leave,
  HALF_LWP,
  Half_LWP,
  HALF_OTHER,
  Half_Other,
  HALF_SICK_LEAVE,
  Half_Sick_Leave,
  Max_Short_Day,
  Max_Short_Day_Timestamp,
  Min_Time,
  Min_Time_Timestamp,
  SHORT_DAY_LEAVE,
  Short_Day_Leave,
  compOffRegex,
  numberOfLeavesCount,
  Half_WFH,
  HALF_WFH,
  excludedLeaveTypes,
} from "./Constants";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { COMP_OFF_EXCEED, currentQuarter, type } from "../helper/Constant";

const now = new Date();
const startOfDay = new Date(
  now.getUTCFullYear(),
  now.getUTCMonth(),
  now.getUTCDate()
);
const secondsElapsed = (now.getTime() - startOfDay.getTime()) / 1000;
const secondsWithOffset = secondsElapsed + 5 * 60 * 60 + 30 * 60;

export const handleActionValidation = (props) => {
  let { reply, dispatch, type, editorState } = props;
  let k = convertToRaw(props.editorState.getCurrentContent());

  let validate = true;
  if (k.blocks.every((b) => b.text.trim() === "")) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        replyErrorMsg: REQUIRED,
      },
    });
  }
  return validate;
};

export const handleCompValidation = (props) => {
  let {
    modalValue,
    dispatch,
    type,
    compOffStartDate,
    compOffEndDate,
    compOffGivenReason,
    wantToCarryForwardCompOff,
  } = props;
  let validate = true;
  if (modalValue === "") {
    validate = false;
    dispatch({
      type: type,
      payload: {
        modalValueErrorMsg: REQUIRED,
      },
    });
  } else if (!compOffRegex.test(modalValue)) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        modalValueErrorMsg:
          "You can enter only number and only 0.5 decimal number is allowed",
      },
    });
  } else {
    dispatch({
      type: type,
      payload: {
        modalValueErrorMsg: "",
      },
    });
  }
  if (wantToCarryForwardCompOff == "1") {
    if (
      compOffStartDate == "" ||
      compOffStartDate == "undefined" ||
      compOffStartDate == null ||
      compOffStartDate == 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          compOffStartDateErrorMsg: REQUIRED,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          compOffStartDateErrorMsg: "",
        },
      });
    }
  }

  if (
    compOffGivenReason === "" ||
    compOffGivenReason == null ||
    compOffGivenReason == undefined
  ) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        compOffGivenReasonErrorMsg: REQUIRED,
      },
    });
  } else {
    dispatch({
      type: type,
      payload: {
        compOffGivenReasonErrorMsg: "",
      },
    });
  }
  if (
    wantToCarryForwardCompOff === "" ||
    wantToCarryForwardCompOff == null ||
    wantToCarryForwardCompOff == undefined
  ) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        wantToCarryForwardCompOffErrorMsg: REQUIRED,
      },
    });
  } else {
    dispatch({
      type: type,
      payload: {
        wantToCarryForwardCompOffErrorMsg: "",
      },
    });
  }
  return validate;
};

export const handleEditValidition = (props) => {
  let {
    endDate,
    startDate,
    shortDayFrom,
    shortDayTo,
    halfOfTheDay,
    leaveType,
    reply,
    dispatch,
    editorState,
    type,
  } = props;

  let validate = true;
  let k = convertToRaw(editorState.getCurrentContent());

  if (
    leaveType == Half_Casual_Leave ||
    leaveType == Half_Earned_Leave ||
    leaveType == Half_Sick_Leave ||
    leaveType == Half_LWP ||
    leaveType == Half_Compensatory_Off ||
    leaveType == Short_Day_Leave ||
    leaveType == Half_Other ||
    leaveType == Half_WFH
  ) {
    dispatch({
      type: type,
      payload: {
        endDateErrMsg: "",
      },
    });
  } else {
    if (endDate === "" || endDate === undefined) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          endDateErrMsg: REQUIRED,
        },
      });
    } else if (endDate < startDate) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          endDateErrMsg: EndDate_Greater,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          endDateErrMsg: "",
        },
      });
    }
  }
  if (leaveType == SHORT_DAY_LEAVE) {
    if (
      shortDayFrom === "" ||
      shortDayFrom === undefined ||
      shortDayFrom === null ||
      shortDayFrom === 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayFromErrorMsg: REQUIRED,
        },
      });
    } else if (startDate && shortDayFrom <= secondsWithOffset) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayFromErrorMsg: "Can not select previous time",
        },
      });
    }
    if (
      shortDayTo === "" ||
      shortDayTo === undefined ||
      shortDayTo === null ||
      shortDayTo === 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayToErrorMsg: REQUIRED,
        },
      });
    } else if (shortDayTo - shortDayFrom > 9000) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayToErrorMsg: "Maximum shortday hours is 2.5 hours",
        },
      });
    } else if (shortDayTo - shortDayFrom < 1800) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayToErrorMsg: "You can not select less then 30 min",
        },
      });
    }
  }
  if (
    leaveType == HALF_CASUAL_LEAVE ||
    leaveType == HALF_EARNED_LEAVE ||
    leaveType == HALF_SICK_LEAVE ||
    leaveType == HALF_LWP ||
    leaveType == HALF_COMPENSATORY_OFF ||
    leaveType == HALF_OTHER
  ) {
    if (
      halfOfTheDay === "" ||
      halfOfTheDay === undefined ||
      halfOfTheDay === 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          halfOfTheDayErrMsg: REQUIRED,
        },
      });
    }
  }
  if (k.blocks.every((b) => b.text.trim() === "")) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        replyErrorMsg: REQUIRED,
      },
    });
  }
  return validate;
};

export const handleleaveDeductValidation = (props) => {
  let {
    employeeId,
    endDate,
    startDate,
    shortDayFrom,
    shortDayTo,
    halfOfTheDay,
    deductLeaveType,
    reply,
    dispatch,
    editorState,
  } = props;
  let validate = true;
  let k = convertToRaw(props.editorState.getCurrentContent());

  if (
    deductLeaveType === "" ||
    deductLeaveType === undefined ||
    deductLeaveType === null
  ) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        leaveType1ErrorMsg: REQUIRED,
      },
    });
  }
  if (employeeId === "" || employeeId === "undefined" || employeeId === null) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        employeeIdErrorMsg: REQUIRED,
      },
    });
  }
  if (
    startDate === "" ||
    startDate === undefined ||
    startDate === null ||
    startDate === STATUS_VALUE_ZERO
  ) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        start1dateErrorMsg: REQUIRED,
      },
    });
  }
  if (
    deductLeaveType !== HALF_CASUAL_LEAVE &&
    deductLeaveType !== HALF_EARNED_LEAVE &&
    deductLeaveType !== HALF_SICK_LEAVE &&
    deductLeaveType !== HALF_LWP &&
    deductLeaveType !== HALF_COMPENSATORY_OFF &&
    deductLeaveType !== SHORT_DAY_LEAVE &&
    deductLeaveType !== HALF_OTHER &&
    deductLeaveType !== HALF_WFH
  ) {
    if (
      endDate === "" ||
      endDate === undefined ||
      endDate === null ||
      endDate === STATUS_VALUE_ZERO
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          enddate1ErrorMsg: REQUIRED,
        },
      });
    } else if (endDate < startDate) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          enddate1ErrorMsg: EndDate_Greater,
        },
      });
    }
  }
  if (deductLeaveType === SHORT_DAY_LEAVE) {
    if (
      shortDayFrom === "" ||
      shortDayFrom === undefined ||
      shortDayFrom === null ||
      shortDayFrom === STATUS_VALUE_ZERO
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayFromErrorMsg: REQUIRED,
        },
      });
    }
    if (shortDayTo === "" || shortDayTo === undefined || shortDayTo === null) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayToErrorMsg: REQUIRED,
        },
      });
    } else if (shortDayTo - shortDayFrom > Max_Short_Day_Timestamp) {
      validate = false;

      dispatch({
        type: type,
        payload: {
          shortDayToErrorMsg: Max_Short_Day,
        },
      });
    } else if (shortDayTo - shortDayFrom < Min_Time_Timestamp) {
      validate = false;

      dispatch({
        type: type,
        payload: {
          shortDayToErrorMsg: Min_Time,
        },
      });
    }
  }
  if (
    deductLeaveType === HALF_CASUAL_LEAVE ||
    deductLeaveType === HALF_EARNED_LEAVE ||
    deductLeaveType === HALF_SICK_LEAVE ||
    deductLeaveType === HALF_LWP ||
    deductLeaveType === HALF_COMPENSATORY_OFF ||
    deductLeaveType === HALF_OTHER ||
    deductLeaveType === HALF_WFH
  ) {
    if (
      halfOfTheDay === "" ||
      halfOfTheDay === undefined ||
      halfOfTheDay === 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          halfOfTheDayErrMsg: REQUIRED,
        },
      });
    }
  }
  if (k.blocks.every((b) => b.text.trim() === "")) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        replyErrorMsg: REQUIRED,
      },
    });
  }
  return validate;
};

export const handleValidation = (props) => {
  let {
    empDetails,
    emails,
    inputEmail,
    leaveMsg,
    leaveType,
    startDate,
    endDate,
    dispatch,
    halfOfTheDay,
    shortDayFrom,
    shortDayTo,
    editorState,
    totalRemainingLeave,
    uploadDocument,
    dashboardLeaveType,
    dashboardStartDate,
    assignedClientOff,
    assignedBereavementLeaves,
    assignedPaternalLeave,
  } = props;
  let validate = true;
  var date = new Date(empDetails.doj);
  var applyDate = new Date(startDate);
  let monthDoj = date.getMonth() + 1;
  let dayDoj = date.getDate();
  let quarter = currentQuarter + 1;
  let yearDoj = date.getFullYear();
  let monthApply = applyDate.getMonth() + 1;
  let yearApply = applyDate.getFullYear();
  let restrictedLeave = 4.5 * (4 - quarter);
  let k = convertToRaw(editorState.getCurrentContent());
  if (k.blocks.every((b) => b.text.trim() === "")) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        reasonErMsg: REQUIRED,
      },
    });
  }
  if (
    leaveType === "4" ||
    leaveType === "9" ||
    dashboardLeaveType === "4" ||
    dashboardLeaveType === "9"
  ) {
    dispatch({
      type: type,
      payload: {
        applyDateErrMsg: "",
      },
    });
  } else if (yearApply === yearDoj) {
    if (monthApply === monthDoj) {
      if (dayDoj > 15) {
        validate = false;
        dispatch({
          type: type,
          payload: {
            applyDateErrMsg: "You have not any leave in this month",
          },
        });
      }
    }
  }

  let sd1 = moment(startDate).format("YYYY-MM-DD");
  let sd2 = moment(endDate).format("YYYY-MM-DD");
  var date1 = new Date(sd1);
  var date2 = new Date(sd2);
  let diffTime = Math.abs(date2 - date1);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

  const startDate1 = moment(startDate);
  const endDate1 = moment(endDate);
  const daysDifference = endDate1.diff(startDate1, "days") + 1;

  if (!dashboardLeaveType && (leaveType === "" || leaveType === undefined)) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        leaveErMsg: REQUIRED,
      },
    });
  }
  if (
    !dashboardLeaveType &&
    (startDate === "" ||
      startDate === undefined ||
      startDate == NaN ||
      startDate == null)
  ) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        startDateErrMsg: REQUIRED,
      },
    });
  } else if (
    totalRemainingLeave <= restrictedLeave &&
    leaveType !== "16" &&
    leaveType !== "17" &&
    leaveType !== "18" &&
    leaveType !== "12"
  ) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        startDateErrMsg: `You have ${
          totalRemainingLeave - restrictedLeave >= 0
            ? totalRemainingLeave - restrictedLeave
            : 0
        } leave left for this quarter`,
      },
    });
  }
  if (
    leaveType === "15" ||
    dashboardLeaveType === "15" ||
    leaveType === " " ||
    dashboardLeaveType === "11"
  ) {
    validate = true;
    dispatch({
      type: type,
      payload: {
        startDateErrMsg: "",
        endDateErrMsg: "",
      },
    });
  }
  if (
    (leaveType && !excludedLeaveTypes.includes(leaveType)) ||
    (!leaveType &&
      dashboardLeaveType &&
      !excludedLeaveTypes.includes(dashboardLeaveType))
  ) {
    if (
      endDate === "" ||
      endDate === undefined ||
      endDate == NaN ||
      endDate == null
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          endDateErrMsg: REQUIRED,
        },
      });
    } else if (endDate < startDate) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          endDateErrMsg: EndDate_Greater,
        },
      });
    } else if (
      (daysDifference > 3 && leaveType == 1) ||
      (daysDifference > 3 && dashboardLeaveType == 1)
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          endDateErrMsg: "You can not apply more then 3 casual leave",
        },
      });
    }
    if (
      (leaveType && (leaveType == 4 || leaveType == 12 || leaveType == 11)) ||
      (dashboardLeaveType &&
        !leaveType &&
        (dashboardLeaveType == 4 ||
          dashboardLeaveType == 12 ||
          dashboardLeaveType == 11))
    ) {
    }
    if (
      daysDifference > totalRemainingLeave - restrictedLeave &&
      leaveType !== "16" &&
      leaveType !== "17" &&
      leaveType !== "18" &&
      leaveType !== "12"
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          endDateErrMsg: `You have ${
            totalRemainingLeave - restrictedLeave >= 0
              ? totalRemainingLeave - restrictedLeave
              : 0
          } leave left for this quarter`,
        },
      });
    }
  }
  if (
    (leaveType &&
      (leaveType === "6" ||
        leaveType === "7" ||
        leaveType === "8" ||
        leaveType === "9" ||
        leaveType === "10" ||
        leaveType === "15")) ||
    (!leaveType &&
      dashboardLeaveType &&
      (dashboardLeaveType === "6" ||
        dashboardLeaveType === "7" ||
        dashboardLeaveType === "8" ||
        dashboardLeaveType === "9" ||
        dashboardLeaveType === "10" ||
        dashboardLeaveType === "15"))
  ) {
    validate = true;
    dispatch({
      type: type,
      payload: {
        endDateErrMsg: "",
      },
    });

    if (halfOfTheDay === "" || halfOfTheDay === undefined) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          halfOfTheDayErrMsg: REQUIRED,
        },
      });
    }
  }
  if (leaveType === "12" || (dashboardLeaveType === "12" && !leaveType)) {
    dispatch({
      type: type,
      payload: {
        endDateErrMsg: "",
      },
    });
    if (
      shortDayFrom === "" ||
      shortDayFrom === undefined ||
      shortDayFrom == null ||
      shortDayFrom == 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayFromErrorMsg: REQUIRED,
        },
      });
    } else if (
      (startDate?.substring(8, 10) == new Date().getDate() &&
        shortDayFrom <=
          new Date().getHours() * 60 * 60 +
            new Date().getMinutes() * 60 +
            new Date().getSeconds()) ||
      (!startDate &&
        dashboardStartDate?.substring(8, 10) == new Date().getDate() &&
        shortDayFrom <=
          new Date().getHours() * 60 * 60 +
            new Date().getMinutes() * 60 +
            new Date().getSeconds())
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayFromErrorMsg: "Can not select previous time",
        },
      });
    }
    if (
      shortDayTo === "" ||
      shortDayTo === undefined ||
      shortDayTo === null ||
      shortDayTo == 0
    ) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayToErrorMsg: REQUIRED,
        },
      });
    } else if (shortDayTo - shortDayFrom > 9000) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayFromErrorMsg: Max_Short_Day,
        },
      });
    } else if (shortDayTo - shortDayFrom < 1800) {
      validate = false;
      dispatch({
        type: type,
        payload: {
          shortDayFromErrorMsg: Min_Time,
        },
      });
    }
  }
  if (endDate && leaveType === "16")
    dispatch({
      type: type,
      payload: {
        endDateErrMsg: "",
      },
    });
  if (endDate && leaveType === "17" && daysDifference > assignedPaternalLeave) {
    dispatch({
      type: type,
      payload: {
        endDateErrMsg: `You have ${assignedPaternalLeave} leave`,
      },
    });
  }
  if (
    endDate &&
    leaveType === "18" &&
    daysDifference > assignedBereavementLeaves
  ) {
    dispatch({
      type: type,
      payload: {
        endDateErrMsg: `You have ${assignedBereavementLeaves} leave`,
      },
    });
  }
  return validate;
};

export const handleNumberOfPartitionValidation = (props) => {
  let validate = true;
  const { numberOfPartition, numberOfLeaves, dispatch } = props;
  if (
    numberOfPartition == "" ||
    numberOfPartition == 0 ||
    numberOfPartition == null ||
    numberOfPartition == undefined
  ) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        numberOfPartitionErrorMessage: REQUIRED,
      },
    });
  } else if (numberOfPartition < 0) {
    dispatch({
      type: type,
      payload: {
        numberOfPartitionErrorMessage: "Partion should be greater then 0",
      },
    });
  } else if (numberOfPartition > numberOfLeaves * 2) {
    validate = false;
    dispatch({
      type: type,
      payload: {
        numberOfPartitionErrorMessage: `Partition can not be greater then ${
          numberOfLeaves * 2
        }`,
      },
    });
  }
  return validate;
};
export const handlePartialApproveValidation = (props) => {
  const { editLeaveArray, dispatch, numberOfPartition, numberOfLeaves } = props;
  let validate = true;
  const data = [...editLeaveArray];
  let daysSum = 0;
  let dateSum = 0;
  for (let index = 0; index < data.length; index++) {
    daysSum += parseInt(data[index].days);
    dateSum += parseInt(
      numberOfLeavesCount(data[index].startDate, data[index].endDate)
    );
    if (
      data[index].days == "" ||
      data[index].days == undefined ||
      data[index].days == 0 ||
      data[index].days == null
    ) {
      data[index].daysCheck = "Number Of Days Required";
      validate = false;
    } else if (data[index].days <= 0) {
      data[index].daysCheck = "Number Of Days can not be Negative OR 0";
      validate = false;
    } else if (parseInt(daysSum) > parseInt(numberOfPartition)) {
      data[index].daysCheck =
        "Number Of days can not be greater then number of partition";
      validate = false;
    } else {
      data[index].daysCheck = "";
    }
    if (
      data[index].leaveType == "" ||
      data[index].leaveType == undefined ||
      data[index].leaveType == 0 ||
      data[index].leaveType == null
    ) {
      data[index].leaveTypeCheck = "Leave type is required";
      validate = false;
    } else {
      data[index].leaveTypeCheck = "";
    }
    if (
      data[index].startDate == "" ||
      data[index].startDate == undefined ||
      data[index].startDate == NaN ||
      data[index].startDate == null
    ) {
      validate = false;
      data[index].startDateCheck = "Start Date is required";
    } else {
      data[index].startDateCheck = "";
    }
    if (
      data[index].leaveType != 6 &&
      data[index].leaveType != 7 &&
      data[index].leaveType != 8 &&
      data[index].leaveType != 9 &&
      data[index].leaveType != 10 &&
      data[index].leaveType != 12 &&
      data[index].leaveType != 14
    ) {
      if (
        data[index].endDate === "" ||
        data[index].endDate === undefined ||
        data[index].endDate == NaN ||
        data[index].endDate == null
      ) {
        validate = false;
        data[index].endDateCheck = "End  Date is required";
      } else if (data[index].endDate < data[index].startDate) {
        validate = false;
        data[index].endDateCheck = "End Date is less then start date";
      } else if (
        numberOfLeavesCount(data[index].startDate, data[index].endDate) >
        data[index].days
      ) {
        validate = false;
        data[
          index
        ].endDateCheck = `Please select date that number of leaves should be less then equal to ${data[index].days}`;
      } else if (parseInt(dateSum) > parseInt(numberOfLeaves)) {
        validate = false;
        data[
          index
        ].endDateCheck = `Please select date that number of leaves should be less then equal to ${numberOfLeaves}`;
      } else {
        data[index].endDateCheck = "";
      }
    }

    if (
      data[index].leaveType == 6 ||
      data[index].leaveType == 7 ||
      data[index].leaveType == 8 ||
      data[index].leaveType == 9 ||
      data[index].leaveType == 10 ||
      data[index].leaveType == 14
    ) {
      if (
        data[index].halfOfTheDay == "" ||
        data[index].halfOfTheDay == undefined ||
        data[index].halfOfTheDay == 0 ||
        data[index].halfOfTheDay == null
      ) {
        validate = false;
        data[index].halfOfTheDayCheck = "Half type is required";
      } else {
        data[index].halfOfTheDayCheck = "";
      }
    }
    if (data[index].leaveType == 12) {
      if (
        data[index].from === "" ||
        data[index].from === undefined ||
        data[index].from == null ||
        data[index].from == 0
      ) {
        validate = false;
        data[index].fromCheck = REQUIRED;
      } else if (
        data[index].startDate?.substring(8, 10) == new Date().getDate() &&
        data[index].from <=
          new Date().getHours() * 60 * 60 +
            new Date().getMinutes() * 60 +
            new Date().getSeconds()
      ) {
        validate = false;
        data[index].fromCheck = "Can not select previous time";
      }
      if (
        data[index].to === "" ||
        data[index].to === undefined ||
        data[index].to === null ||
        data[index].to == 0
      ) {
        validate = false;
        data[index].toCheck = REQUIRED;
      } else if (data[index].to - data[index].from > 9000) {
        validate = false;
        data[index].fromCheck = Max_Short_Day;
      } else if (data[index].to - data[index].from < 1800) {
        validate = false;
        data[index].fromCheck = Min_Time;
      }
    }

    let k = convertToRaw(data[index]?.approveReply?.getCurrentContent());
    if (k.blocks.every((b) => b.text.trim() == "")) {
      validate = false;
      data[index].replyCheck = REQUIRED;
    } else {
      data[index].replyCheck = "";
    }
  }
  for (let i = 0; i < data.length; i++) {
    for (let j = i + 1; j < data.length; j++) {
      if (data[i].endDate) {
        if (
          data[i].startDate === data[j].startDate ||
          data[i].endDate === data[j].endDate
        ) {
          validate = false;
          data[i].startDateCheck = "Please Select valid date";
        }
        if (
          (data[i].startDate >= data[j].startDate &&
            data[i].startDate <= data[j].endDate) ||
          (data[i].endDate >= data[j].startDate &&
            data[i].endDate <= data[j].endDate)
        ) {
          validate = false;
          data[i].startDateCheck = "Please select valid date";
        }
      }
    }
  }
  dispatch({
    type: "typeCheckSet",
    payload: {
      editLeaveArray: data,
    },
  });

  return validate;
};

export const calculateEditLeave = (
  editLeaveArray,
  editemployeeLeaveObject,
  leaveMsg
) => {
  let data = [...editLeaveArray];
  let data1 = editemployeeLeaveObject;
  for (let i = 0; i < data.length; i++) {
    if (
      data[i]?.leaveType == Half_Casual_Leave ||
      data[i]?.leaveType == Half_Earned_Leave ||
      data[i]?.leaveType == Half_Sick_Leave ||
      data[i]?.leaveType == Half_LWP ||
      data[i]?.leaveType == Half_Compensatory_Off ||
      data[i]?.leaveType == HALF_OTHER ||
      data[i]?.leaveType == HALF_WFH
    ) {
      data[i].halfOfTheDay = data.halfOfTheDay;
    } else {
      data[i].halfOfTheDay = 0;
    }

    data[i].startDate =
      new Date(data[i].startDate).getFullYear() +
      "-" +
      (new Date(data[i].startDate).getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      new Date(data[i].startDate).getDate().toString().padStart(2, "0");
    if (
      data[i].leaveType == Half_Casual_Leave ||
      data[i].leaveType == Half_Earned_Leave ||
      data[i].leaveType == Half_Sick_Leave ||
      data[i].leaveType == Half_LWP ||
      data[i].leaveType == Half_Compensatory_Off ||
      data[i]?.leaveType == HALF_OTHER ||
      data[i]?.leaveType == HALF_WFH
    ) {
      data[i].endDate =
        new Date(data[i].startDate).getFullYear() +
        "-" +
        (new Date(data[i].startDate).getMonth() + 1)
          .toString()
          .padStart(2, "0") +
        "-" +
        new Date(data[i].startDate).getDate().toString().padStart(2, "0");
    } else {
      data[i].endDate =
        new Date(data[i]?.endDate).getFullYear() +
        "-" +
        (new Date(data[i]?.endDate).getMonth() + 1)
          .toString()
          .padStart(2, "0") +
        "-" +
        new Date(data[i]?.endDate).getDate().toString().padStart(2, "0");
    }
    let leaveReason = data?.[i]?.approveReply;
    data[i].reply = draftToHtml(convertToRaw(leaveReason?.getCurrentContent()));
  }

  const dataObjectWithEmployee = data.map((obj) => ({
    ...obj,
    employeeId: data1.employeeId,
    emailId: data1.emailId,
    employeeName: data1.employeeName,
    mobId: data1.mobId,
    status: 2,
    leaveMsg: leaveMsg,
  }));
  return dataObjectWithEmployee;
};

export const tempCompDeleteValdiation = (props) => {
  const { isDelete, numberOfCompOff, dispatch, toalTempCompOff } = props;
  let valid = true;
  if (!isDelete && numberOfCompOff === "") {
    valid = false;
    dispatch({
      type: type,
      payload: {
        numberOfCompOffErrMsg: REQUIRED,
      },
    });
  }
  if (isDelete && numberOfCompOff > toalTempCompOff) {
    valid = false;
    dispatch({
      type: type,
      payload: {
        numberOfCompOffErrMsg: COMP_OFF_EXCEED,
      },
    });
  }
  return valid;
};

import ResponseFilter from "../../ResponseFilter";
import {
  getSecretKey,
  validateOtp,
} from "../../config/services/employeeServices";
import {
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILURE,
} from "./actionType";

export default function validateOtpAction(props) {
  let { useDispatch, params, handleClose, getEmployee, getEmployeeCtc } = props;

  return (dispatch) => {
    dispatch(validateOtpActionRequest());

    validateOtp(params)
      .then((res) => {
        let { status } = ResponseFilter(res);
        localStorage.setItem(
          "salaryToken",
          res?.data?.responseData.salaryToken
        );
        setTimeout(()=> {
          if (status) {
            getSecretKey().then((result) => {
              let { status } = ResponseFilter(result);
              if (status) {
                localStorage.setItem(
                  "secretKey",
                  result?.data?.responseData.secretKey
                );
                const secretKey = localStorage.getItem("secretKey")
                if(secretKey){
                  getEmployeeCtc();
                }
              }
              
  
            });
            useDispatch({
              type: "typeCheckSet",
              payload: {
                miniLoader: false,
                // resMsg: true,
                // successMessage: "OTP validate ",
              },
            });
            getEmployee();
            handleClose();
            setTimeout(() => {
              useDispatch({
                type: "typeCheckSet",
                payload: {
                  resMsg: false,
                },
              });
            });
          } else {
            useDispatch({
              type: "typeCheckSet",
              payload: {
                miniLoader: false,
                resFailMsg: true,
                responseMessage: res?.data?.error?.responseMessage,
              },
            });
  
            setTimeout(() => {
              useDispatch({
                type: "typeCheckSet",
                payload: {
                  resFailMsg: false,
                },
              });
            }, 4000);
          }
        })
        },1000)
      .catch((error) => {
        dispatch(validateOtpActionFailure(error));
      });
  };
}

// Rest of the code remains unchanged...

const validateOtpActionRequest = () => {
  return {
    type: VALIDATE_OTP_REQUEST,
  };
};

const validateOtpActionSuccess = (data) => {
  return {
    type: VALIDATE_OTP_SUCCESS,
    payload: data,
  };
};

const validateOtpActionFailure = (error) => {
  return {
    type: VALIDATE_OTP_FAILURE,
    payload: error,
  };
};
export {
  validateOtpAction,
  validateOtpActionRequest,
  validateOtpActionSuccess,
  validateOtpActionFailure,
};

import React from "react";
import "../Dashboard.css";
import partyImg from "../../../asset/imgs/party.png";
import { Link } from "react-router-dom";

const AdminDashboardFirstSection = (props) => {
  const { adminDashboardDetails } = props;
  const {
    pendingLeaves,
    viewTickets,
    onGoingProjects,
    completedProjects,
    pendingReimbursement,
  } = adminDashboardDetails;

  return (
    <div className="row dashboard_new_card_container mx-3 mb-4">
      <div className=" mt-3 ml-4 d-flex">
        <p className="emp_dashboard_card_title">New Updates</p>
      </div>
      <div className="card-body card_body d-flex row mx-1">
        <div className="dashboard_new_card dashboard_update justify-content-between col">
          <Link
            className="dashboard_update_link"
            to={{ pathname: "/admin-leave", state: 1 }}
          >
            Leave Applications
          </Link>
          <p className="dashboard_update_p">{pendingLeaves}</p>
        </div>
        <div className="dashboard_new_card dashboard_update justify-content-between col">
          <Link className="dashboard_update_link" to="/admin-ticket">
            View Tickets
          </Link>
          <p className="dashboard_update_p">{viewTickets}</p>
        </div>
        <div className="dashboard_new_card dashboard_update justify-content-between col">
          <Link
            className="dashboard_update_link"
            to={{ pathname: "/admin-projects", state: 1 }}
          >
            Ongoing Projects
          </Link>
          <p className="dashboard_update_p">{onGoingProjects}</p>
        </div>
        <div className="dashboard_new_card dashboard_update justify-content-between col">
          <Link
            className="dashboard_update_link"
            to={{ pathname: "/admin-projects", state: 2 }}
          >
            Completed Projects
          </Link>
          <p className="dashboard_update_p">{completedProjects}</p>
        </div>
        <div className="dashboard_new_card dashboard_update justify-content-between col">
          <Link
            className="dashboard_update_link"
            to={{ pathname: "/admin-reimbursement", state: 1 }}
          >
            Pending Reimbursement
          </Link>
          <p className="dashboard_update_p">{pendingReimbursement}</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardFirstSection;

import React from 'react';
import ResponseFilter from '../../ResponseFilter';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Loader } from '../svg';
import _ from 'lodash';
import Pagination from "react-js-pagination";
import { getAppraisalCycle } from '../../config/services/employeeServices';


class AppraisalCycle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AppraisalCycle: [],
      pageNo: 1,
      count: 10,
      loader: true,
      totalAppraisals: ''
    }
  }

  statusType = type => {
    switch (type) {
      case 1: return 'Upcoming';
        break;

      case 2: return 'Done';
        break;

      default: return 'Postpone';

    }
  }
  colorType = type => {
    switch (type) {
      case 1: return 'skyblue';
        break;

      case 2: return 'text-success';
        break;

      default: return 'red';

    }
  }
  handlePageChange = (pageNo) => {
 
    this.setState({ pageNo: pageNo, loader: true }, () => this.getAppraisal());

  }
  getAppraisal = () => {
    let { pageNo, count } = this.state
    let params = `count=${count}&pageNo=${pageNo - 1}&employeeId=${this.props.match.params.id}`;

    getAppraisalCycle(params).then(res => {

      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          employeeId: resData.employeeId,
          AppraisalCycle: resData.AppraisalCycle,
          totalAppraisals: resData.totalAppraisals

        }, () => this.setState({ loader: false }))
      }
      else if (res.data.statusCode === 0) {
        this.setState({ loader: false })
        this.closeModal();
      }
    })
  }
  componentDidMount() {
    this.getAppraisal();

  }
  render() {

    let { AppraisalCycle, loader, totalAppraisals, pageNo, count, } = this.state;
    return (
      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        <div className="cm_breadcrumb">
          <ul>
            <li><Link to='/admin-appraisal'>APPRAISALS</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li>APPRAISAL CYCLE</li>

          </ul>
        </div>

        <h3>APPRAISALS CYCLE</h3>

        <div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Year</th>
                  <th scope="col">Month</th>
                  <th scope="col">Date</th>
                  <th scope="col">Hike</th>
                  <th scope="col">Bonus</th>
                  <th scope="col">CTC</th>
                  <th scope="col">Variables</th>
                  <th scope="col">Action</th>

                </tr>
              </thead>
              <tbody>

                {
                  !_.isEmpty(AppraisalCycle) ?
                    AppraisalCycle.map((data, i) => (
                      <tr>
                       
                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                        <td>{data.dueAppraisal ? moment(data.dueAppraisal).format('YYYY') : '_'}</td>
                        <td>{data.dueAppraisal ? moment(data.dueAppraisal).format('MMMM') : '_'}</td>
                        <td>{data.dueAppraisal ? moment(data.dueAppraisal).format('DD/MM/YYYY') : '_'}</td>
                        <td><span className="cm_col">{data.hike ? data.hike : '_'}</span></td>
                        <td><span className="cm_col">{data.bonus ? data.bonus : '_'}</span></td>
                        <td><span className="cm_col">{data.currentCtc ? data.currentCtc : '_'}</span></td>
                        <td><span className="cm_col">{data.variables ? data.variables : '_'}</span></td>
                        <td className={this.colorType(data.status)}><div>{this.statusType(data.status)}</div></td>
                      </tr>
                    )) : ''
                }




              </tbody>
            </table>
            {AppraisalCycle.length ? '' : <h3 className="text-center">No data available</h3>}

          </div>
          {
            totalAppraisals > 10 ?
              <Pagination
                pageNo={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={totalAppraisals}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              /> : ''
          }


        </div>
      </div>


    );
  }
}
export default AppraisalCycle;

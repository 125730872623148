import { getTeam, getTeamCompareData } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
    GET_TEAM_COMPARE_DATA_REQUEST,
    GET_TEAM_COMPARE_DATA_SUCCESS,
    GET_TEAM_COMPARE_DATA_FAILURE,
} from "./actionType";


const getTeamCompareDataByAdminAction = (props) => {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getTeamCompareDataByAdminRequest());
        getTeamCompareData(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        loader: false,
                        teamCompareData: res?.data?.responseData?.comparedTeamList,
                        teamCompareCount: res?.data?.responseData?.totalCount
                    }
                })
            }
            else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        responseMessage: res.data.error.responseMessage,
                        errorCode: res.data.error.errorCode,
                        resTeamFailMsg: true,
                        loader: false,
                        teamCompareData: [],
                        teamCompareCount: []
                    }
                })
                setTimeout(() => useDispatch({
                    type: 'typeCheckSet', payload: {
                        resTeamFailMsg: false
                    }
                }), 3000);
            }
        })
            .catch((error) => {
                dispatch(getTeamCompareDataByAdminFailure(error));
                // setErrorText("Failed to add");
                // setFailResMsg(true);
            });
    };
};

const getTeamCompareDataByAdminRequest = () => {
    return {
        type: GET_TEAM_COMPARE_DATA_REQUEST,
    };
};
const getTeamCompareDataByAdminSuccess = (data) => {
    return {
        type: GET_TEAM_COMPARE_DATA_SUCCESS,
        payload: data,
    };
};
const getTeamCompareDataByAdminFailure = (error) => {
    return {
        type: GET_TEAM_COMPARE_DATA_FAILURE,
        payload: error,
    };
};
export {
    getTeamCompareDataByAdminAction,
    getTeamCompareDataByAdminRequest,
    getTeamCompareDataByAdminSuccess,
    getTeamCompareDataByAdminFailure,
};
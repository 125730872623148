import React from "react";
import { Calendar, DatePicker, Select, Modal as AntModal } from "antd";
import { Loader } from "../svg";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab } from "react-bootstrap";
import ResponseFilter from "../../ResponseFilter";
import {
  getHolidayList,
  addHoliday,
  editHoliday,
  deleteHoliday,
  getHoliday,
} from "../../config/services/employeeServices";
import _ from "lodash";
import { holidayValue, statusMon } from "../leaves/Constants";
const { Option } = Select;

class Holiday extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      holidaysList: [],
      holidayDetails: "",
      holidayName: "",
      holidayType: "",
      date: "",
      toggleView: false,
      activeTab: "1",
      holidayNameErMsg: "",
      responseMessage: "",
      holidayTypeErMsg: "",
      dateErMsg: "",
      openAddHolidayPopup: false,
      openEditHolidayPopup: false,
      openDeleteHolidayPopup: false,
      openPop: "",
      loader: true,
      resMsg: false,
      resMsgEdit: false,
      resMsgDelete: false,
    };
  }

  getListData = (dateData) => {
    let { holidaysList } = this.state;
    let listData;
    holidaysList.map(function (data, index) {
      data.holidays.map(
        function (value, i) {
          let dateobj = new Date(value.date);
          let dates = dateobj.getDate();
          let month = dateobj.getMonth();

          if (dateData.month() === month) {
            switch (dateData.date()) {
              case dates:
                let type = value.holidayType === 1 ? "error" : "success";

                listData = [
                  {
                    type: type,
                    holidayType: type,
                    content: value.holidayName,
                    date: value.date,
                  },
                ];
              default:
            }
          }
        },
        () => this.setState({ loader: false })
      );
    });

    return listData || [];
  };
  handleChange = (e) =>
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + "ErMsg"]: "",
    });

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    if (_.isEmpty(listData)) {
      return <div className="innerDiv"></div>;
    } else {
      return (
        <div className="abc">
          {listData.map((item) => (
            <div
              style={{ cursor: "auto" }}
              className={item.type == "success" ? "orange" : "greeen"}
            >
              <span className="bage">
                <p className="holiday_date">
                  {moment.unix(item.date / 1000).format("DD")}
                </p>
              </span>
              <div className="events">
                <p key={item.content}>{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  handleOpenAddHoildayPopup = () => {
    this.setState({ openAddHolidayPopup: !this.state.openAddHolidayPopup });
  };
  onSelect = (date, e, holiday) => {
    let self = this;
    let flagArray = [];

    holiday.map(function (val, i) {
      if (
        moment(val.date).format("MM-DD-YYYY") ===
        moment(date).format("MM-DD-YYYY")
      ) {
        flagArray.push(true);
      } else if (
        moment(val.date).format("MM-DD-YYYY") !==
        moment(date).format("MM-DD-YYYY")
      ) {
        flagArray.push(false);
      }

      // }
    });

    if (flagArray.includes(true)) {
      this.setState({ openAddHolidayPopup: false, date: date });
    } else {
      this.setState({ openAddHolidayPopup: true, date: date });
    }
  };

  onSelectEdit = (date) => {
    this.setState({
      openEditHolidayPopup: !this.state.openEditHolidayPopup,
      date: date,
    });
  };

  handleOpenEditHoildayPopup = (holidayId) => {
    this.setState({
      openEditHolidayPopup: !this.state.openEditHolidayPopup,
      holidayId,
    });

    getHoliday(holidayId).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        let { holidayId, holidayName, holidayType, date } =
          resData.holidayDetails;
        this.setState(
          {
            date,
            holidayType,
            holidayName,
            holidayId,
          },
          () => this.setState({ loader: false })
        );
      } else {
        this.setState({ loader: false });
      }
    });
  };

  closeModal = () =>
    this.setState({
      openAddHolidayPopup: false,
      openEditHolidayPopup: false,
      openDeleteHolidayPopup: false,
      holidayName: "",
      holidayNameErMsg: "",
      responseMessage: "",
      holidayTypeErMsg: "",
      dateErMsg: "",
      holidayType: "",
      date: "",
    });
  handleDate = (date) =>
    this.setState({
      date: date,
      dateErMsg: "",
      responseMessage: "",
    });
  handleSelectChange = (e) =>
    this.setState({ holidayType: parseInt(e), holidayTypeErMsg: "" });

  handleOpenDeleteHolidayPopup = (HolidayId) => {
    this.setState({
      openDeleteHolidayPopup: !this.state.openDeleteHolidayPopup,
      holidayId: HolidayId.holidayId,
    });
  };

  handleValidation = () => {
    let validate = true;
    const { holidayName, holidayType, date } = this.state;

    if (holidayName === "" || holidayName === undefined) {
      validate = false;
      this.setState({
        holidayNameErMsg: "Holiday name is required",
      });
    } else {
      this.setState({
        holidayNameErMsg: "",
      });
    }
    if (holidayType === "" || holidayType === undefined) {
      validate = false;
      this.setState({
        holidayTypeErMsg: "Holiday type is required",
      });
    } else {
      this.setState({
        holidayTypeErMsg: "",
      });
    }
    if (date === "" || date === undefined) {
      validate = false;
      this.setState({
        dateErMsg: "Holiday date is required",
      });
    } else {
      this.setState({
        dateErMsg: "",
      });
    }
    return validate;
  };

  componentDidMount() {
    this.setState({
      activeTab: localStorage.getItem("holidayTabStatusAdmin")
        ? localStorage.getItem("holidayTabStatusAdmin")
        : "1",
    });
    this.getList();
  }
  componentWillUnmount() {
    localStorage.removeItem("holidayTabStatusAdmin");
  }

  getList = () => {
    getHolidayList().then((res) => {
      let { status, resData } = ResponseFilter(res);

      this.setState({ loader: false });
      if (status) {
        this.setState(
          {
            holidaysList: resData.holidaysList,
            responseMessage: resData.responseMessage,
          },
          () => this.setState({ loader: false }, () => this.closeModal())
        );
      }
    });
  };

  getHolidayById = () => {};

  handleAddSubmit = (event) => {
    event.preventDefault();
    let self = this;
    if (this.handleValidation()) {
      let resObj = {
        holidayName: this.state.holidayName,
        holidayType: this.state.holidayType,
        date: new Date(this.state.date).setHours(6, 30, 0, 0),
        message: this.state.message,
      };
      this.setState({ loader: true });
      addHoliday(resObj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({ resMsg: true, loader: false });
          self.getList();
          self.closeModal();
        } else if (res.data.statusCode === 0) {
          self.setState({
            loader: false,
            responseMessage: res.data.error.responseMessage,
            errorCode: res.data.error.errorCode,
          });
        }
      });
    }
    setTimeout(() => this.setState({ resMsg: false }), 3000);
  };

  handleEditSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      let obj = {
        holidayId: this.state.holidayId,
        holidayName: this.state.holidayName,
        holidayType: this.state.holidayType,
        date: new Date(this.state.date).setHours(6, 30, 0, 0),
      };
      this.setState({ loader: true });
      editHoliday(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              loader: false,
              resMsgEdit: true,
            },
            () => this.getList()
          );
        }
      });
    } else {
      this.setState({ loader: false });
    }
    setTimeout(() => this.setState({ resMsgEdit: false }), 3000);
  };

  handleDeleteHoliday = () => {
    let obj = {
      data: {
        holidayId: this.state.holidayId,
        status: 2,
      },
    };
    this.setState({ loader: true });
    deleteHoliday(obj).then((res) => {
      this.closeModal();
      this.setState({ resMsgDelete: true, loader: false }, () =>
        this.getList()
      );
    });
    setTimeout(() => this.setState({ resMsgDelete: false }), 3000);
  };

  handleTabClick = (e) => {
    if (e == "1") {
      this.setState({ toggleView: false, activeTab: "1" });
      localStorage.setItem("holidayTabStatusAdmin", "1");
    } else {
      this.setState({ toggleView: true, activeTab: "2" });
      localStorage.setItem("holidayTabStatusAdmin", "2");
    }
  };

  disabledDate = (current) => {
    if (!current) {
      // allow empty select
      return false;
    }
    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);
    return current.valueOf() < date.valueOf(); // can not select days before today
  };

  render() {
    let {
      resMsg,
      resMsgDelete,
      resMsgEdit,
      openAddHolidayPopup,
      loader,
      openEditHolidayPopup,
      openDeleteHolidayPopup,
      holidaysList,
      holidayName,
      holidayType,
      holidayNameErMsg,
      responseMessage,
      holidayTypeErMsg,
      dateErMsg,
      date,
      message,
    } = this.state;
    return (
      <div className="body_container">
        {loader && <Loader />}
        {resMsg ? (
          <div className="alert alert-success cm_top_fix">
            Holiday Added successfully
          </div>
        ) : (
          ""
        )}
        {resMsgEdit ? (
          <div className="alert alert-warning cm_top_fix">
            Holiday Edited Successfully
          </div>
        ) : (
          ""
        )}
        {resMsgDelete ? (
          <div className="alert alert-danger cm_top_fix">
            Holiday Deleted Successfully
          </div>
        ) : (
          ""
        )}
        <h3>HOLIDAY LIST</h3>
        <Tabs
          className="holiday_tab"
          activeKey={this.state.activeTab}
          defaultActiveKey="1"
          id="uncontrolled-tab-example"
          onSelect={(e) => this.handleTabClick(e)}
        >
          <Tab eventKey="1" title="Grid View">
            <div className="cm_hoilday ">
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.handleOpenAddHoildayPopup}
                >
                  Add Holiday
                </button>
              </div>
              <div className="row">
                {holidaysList.map((holiday, i) => (
                  <div className="col-xl-4 col-lg-6 my_calendar">
                    <Calendar
                      className={holiday.holidays ? "abcd" : "redt"}
                      fullscreen={false}
                      mode="month"
                      defaultValue={moment(
                        `${i + 1}/${i + 1}/${new Date().getFullYear()}`
                      )}
                      headerRender={() => (
                        <h5>
                          {statusMon(holiday._id.month)}
                          {holiday.name} {new Date().getFullYear()}
                        </h5>
                      )}
                      onSelect={(e) =>
                        this.onSelect(e, i + 1, holiday.holidays)
                      }
                      disabledDate={this.disabledDate}
                      dateCellRender={(value) => this.dateCellRender(value)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Tab>
          <Tab eventKey="2" title="List View">
            <div className="cm_hoilday ">
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.handleOpenAddHoildayPopup}
                >
                  Add Holiday
                </button>
              </div>

              {holidaysList.map((holiday, i) => {
                return (
                  <div className="mt-4">
                    {<h3>{statusMon(holiday._id.month)}</h3>}
                    <ul
                      className="cm_card dashboard_info sm_date"
                      style={{ maxWidth: 900 }}
                    >
                      {holiday.holidays.length ? (
                        holiday.holidays.map((item) => (
                          <li>
                            <div
                              className="edit_holiday"
                              style={{ marginLeft: 272 }}
                              onClick={() =>
                                this.handleOpenEditHoildayPopup(item.holidayId)
                              }
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                                title="Edit holiday"
                              ></i>
                            </div>

                            <div
                              className="delete_holiday"
                              style={{ marginLeft: 280 }}
                              onClick={() =>
                                this.handleOpenDeleteHolidayPopup(item)
                              }
                            >
                              <i
                                className="fa fa-trash red"
                                aria-hidden="true"
                                title="Delete holiday"
                              ></i>
                            </div>
                            <span>
                              {moment(item.date).format("D")}-
                              {statusMon(holiday._id.month).slice(0, 3)}{" "}
                              {`(${moment(item.date).format("dddd")})`}
                            </span>
                            <p className="text-center">
                              {item.holidayName.charAt(0).toUpperCase() +
                                item.holidayName.slice(1)}
                            </p>
                          </li>
                        ))
                      ) : (
                        <li>
                          {" "}
                          <p className="text-center">NO HOLIDAY</p>
                        </li>
                      )}
                    </ul>
                  </div>
                );
              })}
            </div>
          </Tab>
        </Tabs>
        <AntModal
          title=""
          footer={false}
          centered
          className="holiday-popup"
          visible={openAddHolidayPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form onSubmit={this.handleAddSubmit}>
              <h3>ADD HOLIDAY</h3>

              <div
                className={`form-group ${
                  holidayNameErMsg ? "input_error" : ""
                }`}
              >
                <label className="sub mt-4">Holiday Name</label>
                <input
                  maxLength="50"
                  placeholder="Holiday name"
                  className="form-control cm_box"
                  type="text"
                  name="holidayName"
                  value={holidayName}
                  onChange={this.handleChange}
                />

                {holidayNameErMsg ? (
                  <span className="error_msg">{holidayNameErMsg}</span>
                ) : null}
              </div>
              <div
                className={`form-group ${
                  holidayTypeErMsg ? "input_error" : ""
                }`}
              >
                <label className="sub">Holiday Type</label>
                <Select
                  value={holidayType ? holidayValue(holidayType) : "Select"}
                  onChange={(e) => this.handleSelectChange(e)}
                >
                  <Option key="1">Event holiday</Option>
                  <Option key="2">Special holiday</Option>
                </Select>
                {holidayTypeErMsg ? (
                  <span className="error_msg">{holidayTypeErMsg}</span>
                ) : null}
              </div>
              {/* {this.state.toggleView && ( */}
                <div
                  className={`form-group ${
                    dateErMsg || responseMessage ? "input_error" : ""
                  }`}
                >
                  <label className="sub">Date</label>
                  <DatePicker
                    allowClear={false}
                    className="date-border"
                    onChange={this.handleDate}
                    value={date ? moment(date) : ""}
                    // disabledDate={this.disabledDate}
                    placeholder="Select date"
                  />
                  {responseMessage ? (
                    <span className="error_msg">{responseMessage}</span>
                  ) : null}
                  {dateErMsg ? (
                    <span className="error_msg">{dateErMsg}</span>
                  ) : null}
                </div>
              {/* )} */}
              <div className="submit_button_margin mt-4">
                <button type="submit" className="btn btn-primary cm_btn3">
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        <AntModal
          title=""
          footer={false}
          centered
          className="holiday-popup"
          visible={openEditHolidayPopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form onSubmit={this.handleEditSubmit}>
              <h3>EDIT HOLIDAY</h3>
              {responseMessage ? (
                <span className="error_msg mt-3 mb-2">{responseMessage}</span>
              ) : null}
              <label className="sub mt-4">Holiday Name</label>
              <input
                maxLength="50"
                className="form-control cm_box"
                type="text"
                name="holidayName"
                value={holidayName}
                onChange={this.handleChange}
              />
              {holidayNameErMsg ? (
                <span className="error_msg">{holidayNameErMsg}</span>
              ) : null}
              <br />
              <label className="sub">Holiday Type</label>
              <Select
                value={holidayType ? holidayValue(holidayType) : "Select"}
                onChange={(e) => this.handleSelectChange(e)}
              >
                <Option key="1">Event holiday</Option>
                <Option key="2">Special holiday</Option>
              </Select>

              {holidayTypeErMsg ? (
                <span className="error_msg">{holidayTypeErMsg}</span>
              ) : null}

              {this.state.toggleView && (
                <div
                  className={`form-group mt-3 ${
                    dateErMsg || responseMessage ? "input_error" : ""
                  }`}
                >
                  <label className="sub">Date</label>
                  <DatePicker
                    allowClear={false}
                    className="date-border"
                    value={date ? moment(date) : ""}
                    // disabledDate={this.disabledDate}
                    onChange={this.handleDate}
                  />

                  {dateErMsg ? (
                    <span className="error_msg">{dateErMsg}</span>
                  ) : null}
                </div>
              )}
              <div className="submit_button_margin mt-4">
                <button type="submit" className="btn btn-primary cm_btn3">
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>

        <Modal
          size="md"
          className="holiday-popup"
          show={openDeleteHolidayPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Body className="text-center confirmation">
            <p className="mb-4 cm_bold cnfrm_msg">
              Are you sure, you want to delete Holiday?
            </p>

            <div className="submit_button_margin mt-4 edit_appraisal ">
              <span
                className="btn btn-danger cm_btn3 btn-sm"
                onClick={this.handleDeleteHoliday}
              >
                Delete
              </span>
              <button
                onClick={this.closeModal}
                className="btn btn-primary cm_btn2 btn-sm"
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Holiday;

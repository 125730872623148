import React from "react";
import { Modal as AntModal, DatePicker, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import { Form, Button } from "react-bootstrap";
import { DocIcon, ExcelIcon, PdfIcon } from "../../svg";
import { IoMdClose } from "react-icons/io";
import moment from "moment";

const { Option } = Select;

const EditReimbursementModal = (props) => {
  const {
    show,
    handleShow,
    handleClose,
    disabledDate,
    month,
    handleDateChange,
    editReimbursementType,
    handleEditReimbursementType,
    editTitle,
    handleEditTitle,
    titleErrorMsg,
    uploadBillErrorMsg,
    expense,
    expenseErrorMsg,
    amount,
    handleChange,
    amountErrorMsg,
    handleFileChange,
    editUploadFiles,
    loader,
    handleSubmit,
    handleDeleteIconClick,
    setState
  } = props;

  return (
    <AntModal
      centered
      className="reimbur_ino"
      title=""
      footer={false}
      visible={show}
      onOk={handleShow}
      onCancel={handleClose}
    >
      <Modal.Header className="text-bold">
        <Modal.Title id="example-modal-sizes-title-lg">
          Edit Reimbursement Claim
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <label className="sub">Expense Date</label>
          <DatePicker
            disabledDate={disabledDate}
            allowClear={false}
            className="date-border"
            placeholder="Select Date"
            name="expenseDate"
            value={month ? moment(month) : ""}
            onChange={handleDateChange}
          />
          <div className="d-flex justify-content-between">
            <Form.Group
              className="mt-2"
              style={{ width: "15rem" }}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Reimbursement Type</Form.Label>
              <Select
                value={
                  editReimbursementType
                    ? editReimbursementType.toString()
                    : "Select Reimbursement Type"
                }
                defaultValue="Select Type"
                className=""
                name="reimbursementType"
                onChange={(e) => handleEditReimbursementType(e)}
              >
                <Option value="1">Regular</Option>
                <Option value="2">Tax Saving</Option>
              </Select>
            </Form.Group>
            <Form.Group
              className="mt-2"
              style={{ width: "15rem" }}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Title</Form.Label>
              <input
                maxLength="30"
                type="text"
                className="form-control"
                name="title"
                value={editTitle}
                onChange={handleEditTitle}
              />
              {titleErrorMsg ? (
                <span className="error_msg">{titleErrorMsg}</span>
              ) : null}
            </Form.Group>
          </div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Expense Description</Form.Label>
            <Form.Control
              type="text"
              maxLength={500}
              value={expense}
              onChange={(e) => {
                const value = e.target.value;
                setState({
                  expense: /^\s/.test(value) ? "" : value,
                });
              }}
              autoFocus
            />
            {expenseErrorMsg ? (
              <span className="error_msg">{expenseErrorMsg}</span>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Amount Claimed</Form.Label>
            <input
              inputMode="numeric"
              type="number"
              className="form-control"
              name="amount"
              value={amount}
              onChange={handleChange}
            />
            {amountErrorMsg ? (
              <span className="error_msg">{amountErrorMsg}</span>
            ) : null}
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Upload Bill</Form.Label>
            <div
              className={`form-group${uploadBillErrorMsg ? "input_error" : ""}`}
            >
              <span className="form-control cm_hide_upload">
                <i className="fa fa-upload" aria-hidden="true"></i>
                <span className="cm_upload_name">Upload File</span>
                <input
                  type="File"
                  accept=".png, .jpg, .jpeg, .pdf"
                  className=""
                  title=""
                  multiple
                  id="upload-files"
                  onChange={handleFileChange}
                />
              </span>
              {uploadBillErrorMsg ? (
                <span className="error_msg">{uploadBillErrorMsg}</span>
              ) : null}
            </div>
            <div>
              <div className="row">
                <div className="form-group col-12">
                  {editUploadFiles && editUploadFiles.length > 0 ? (
                    <>
                      <label className="sub">Selected Files: </label>
                      <div
                        className="date-border"
                        style={{
                          borderRadius: "8px",
                          borderWidth: "1px",
                          padding: "10px",
                          overflow: "auto",
                          minHeight: "200px",
                          maxHeight: "500px",
                        }}
                      >
                        {editUploadFiles.map((item, index) => {
                          const isFile = item instanceof File;
                          const itemName = isFile ? item.name : item;

                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#edfbff",
                                borderRadius: "8px",
                                padding: "10px",
                                height: "3.5rem",
                                margin: "5px",
                              }}
                            >
                              {itemName.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/) ? (
                                <img
                                  src={isFile ? URL.createObjectURL(item) : item}
                                  className="image-size"
                                  alt="upload"
                                />
                              ) : itemName.match(/\.(doc|docx|odt|dotm|dotm|dotx|docm)$/) ? (
                                <div className="image-size">
                                  <DocIcon />
                                </div>
                              ) : itemName.match(/\.(xlx|xlsx|xls)$/) ? (
                                <div className="image-size">
                                  <ExcelIcon />
                                </div>
                              ) : itemName.match(/\.(pdf|PDF)$/) ? (
                                <div className="image-size">
                                  <PdfIcon />
                                </div>
                              ) : (
                                <div className="image-size">
                                  <DocIcon />
                                </div>
                              )}
                              <label style={{ width: "80%" }}>{itemName}</label>
                              <div onClick={() => handleDeleteIconClick(index)}>
                                <IoMdClose
                                  style={{
                                    color: "#347bde",
                                    cursor: "pointer",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No files selected!
                    </label>
                  )}
                </div>
              </div>
            </div>
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loader}>
          Submit
        </Button>
      </Modal.Footer>
    </AntModal>
  );
};

export default EditReimbursementModal;

import React from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import { DatePicker, Modal as AntModal } from "antd";
import moment from "moment";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import ResponseFilter from "../../ResponseFilter";
import { DocIcon, Loader, PdfIcon, ExcelIcon } from "../svg";
import Pagination from "react-js-pagination";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoMdClose } from "react-icons/io";
import { Tabs, Tab } from "react-bootstrap";

import {
  getEmployeeList,
  addDevice,
  getDeviceList,
  editDevice,
  deleteDevice,
  getDeviceDetails,
  getAdminDeviceList,
  deleteDeviceByManager,
  getDeviceRequestList,
  exportDeviceExcel,
  addUnderRepairingDevice

} from "../../config/services/employeeServices";
import DeviceFullScreenImage from "./DeviceFullScreenImage";
import { REQUIRED, createSelectItems, currentMonth } from "../helper/Constant";
import { MONTH_ARRAY, currentYear, statusMon } from "../leaves/Constants";
import { deviceWarrentyStatus, getFileNameFromUrl } from "../../config/constants/Constants";
import AddDeviceUnderRepairingModal from "./AddDeviceUnderRepairingModal";
// import settings from "../../config/settings";
const { Option } = Select;
function disabledDate(current) {
  return current && current < moment().startOf("day");
}

function disabledPurchaseDate(current) {
  return current && current > moment().endOf("day");
}
let regex = /^[a-zA-Z]+$/;
class DeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddDevicePopup: false,
      openDeviceAction: false,
      openDeleteDevicePopup: false,
      openEditDevicePopup: false,
      openDeviceInfoPopup: false,
      resMsg: false,
      resFailMsg: false,
      resEditMsg: false,
      resInfoMsg: false,
      deviceList: [],
      deviceDetails: [],
      deviceRequestDetails: [],
      assignedTo: "",
      // deviceManager: "",
      employeeList: [],
      employeeId: "",
      // deviceManagerId: "",
      deviceType: "",
      deviceStatus: "",
      operatingSystem: "",
      brand: "",
      imei: "",
      acessories: [],
      ram: "",
      hdd: "",
      dateAdded: "",
      assignedDate: "",
      assignedTill: "",
      status: "",
      brandErrorMsg: "",
      ramErrorMsg: "",
      hddErrorMsg: "",
      dateErrorMsg: "",
      dateAssignErrorMsg: "",
      deviceModel: "",
      // deviceModalErrorMsg:"",
      // imeiErrorMsg:"",
      // passwordErrorMsg:"",
      // operatingSystemErrorMsg:"",
      password: "",
      editDivceObj: "",
      deviceId: "",
      totalDevices: "",
      dataCount: "",
      phones: "",
      // desktops: "",
      laptops: "",
      deleteDevice: "",
      firstName: "",
      lastName: "",
      devId: "",
      search: "",
      loader: true,
      sortType: 2,
      sortField: "created",
      pageNo: 1,
      count: 50,
      Addition: [],
      deviceRequestDetail: "",
      assignDeviceRequestId: "",
      mouse: "",
      connectors: "",
      ups: '',
      routers: "",
      headphone: "",
      wifiAdaptor: "",
      webCamera: "",
      keyboard: "",
      cpu: "",
      pendrive: "",
      hardDisk: "",
      ram1: "",
      comment: "",
      uploadPicture: [],
      uploadFileName: [],
      uploadPictureErrorMsg: "",
      picturePreview: [],

      uploadDocument: [],
      uploadDocumentName: [],
      uploadDocumentErrorMsg: "",
      DocumentPreview: [],

      cpu: "",
      monitor: "",
      macMini: "",
      devicePictures: [],
      deviceDocs: [],
      dateErrorMsg: "",
      assignedFrom: "",
      // dateTillErrorMsg: "",
      officeLocation: 0,
      deviceOficeLocationErrorMsg: "",
      responseMessage: "",
      editFiles: [],
      editUploadPicture: [],
      editUploadPictureErrorMsg: [],
      deviceFilterType: "",
      preview: false,
      fullImage: "",
      searchYear: "",
      searchMonth: "",
      miniloader: false,
      tabStatus: "",
      deviceVersion: "",
      deviceRegion: "",
      deviceRegionErrorMsg: "",

      openAddUnderRepairingModal: false,

      // devuce repairing state
      addDeviceRepairingID: "",
      addDeviceRepairingIDErrorMsg: "",
      sendingAmount: 0,
      sendingAmountErrorMsg: "",
      recevingAmount: 0,
      recevingAmountErrorMsg: "",
      sendingDate: "",
      sendingDateErrorMsg: "",
      issueDescription: "",
      issueDescriptionErrorMsg: "",
      recevingDate: "",
      recevingDateErrorMsg: "",
      deviceListToAddUnderRepairing: [],
      systemCost: 0,
      isInWarrenty: 0,
      warrentyExpireDate: "",
      systemCostErrorMsg: 0,
      isInWarrentyErrorMsg: false,
      warrentyExpireDateErrorMsg: ""

    };
    this.debounceSearch = null
  }

  urlToFile = async (url) => {
    const document = []
    this.setState({ miniloader: true })
    for (let i = 0; i < url?.length; i++) {
      const response = await fetch(url[i]);
      const blob = await response?.blob();
      const filename = url[i]?.substring(url[i]?.lastIndexOf('/') + 1);
      const file = new File([blob], filename, { type: blob.type });
      document.push(file);
    }
    this.setState({ miniloader: false })
    return document
  }

  slideHandler = (e) => {
    const box = document.querySelectorAll('.col-md-3')
    for (const item of box) {
      item.style.transform = 'translateX(-100%)';
    }
  };

  handleFullImage = (e) => {
    this.setState({
      preview: true,
      fullImage: e
    })
  }

  handleOpenDeleteDevicePopup = (deviceUniqueId) => {
    this.setState({
      openDeleteDevicePopup: !this.state.openDeleteDevicePopup,
      deviceUniqueId,
    });
  };
  handleSearch = (e) => {
    this.setState({ search: e.target.value, pageNo: 1 }
    );
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.getDevice()
    }, 2000);
  };

  submitSearch = (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    this.getDevice();
  };

  handleOpenEditDevicePopup = ({
    deviceId,
    deviceModel,
    deviceType,
    operatingSystem,
    imei,
    acessories,
    ram,
    hdd,
    password,
    brand,
    dateAdded,
    assignedDate,
    status,
    resEditMsg,
    firstName,
    lastName,
    employeeId,
    assignedTo,
    assignedTill,
    devId,
    deviceStatus,
    // deviceManagerId,
    deviceUniqueId,
    // resEditMsg
    // deviceManager,
    comment,
    assignedFrom,
    assigndeviceDetails,
    officeLocation,
    devicePictures,
    deviceDocs,
    docs,
    deviceVersion,
    deviceRegion,
    systemCost,
    isInWarrenty,
    warrentyExpireDate,
    purchasedDate
  }) => {
    this.setState({
      openEditDevicePopup: !this.state.openEditDevicePopup,
      // resEditMsg:true,
      deviceId,
      deviceModel,
      deviceUniqueId,
      assignedTo: assignedTo?._id,
      // // deviceManager: deviceManagerId,
      deviceType,
      operatingSystem,
      brand,
      imei,
      // acessories,
      ram,
      hdd,
      deviceStatus: status,
      password,
      dateAdded: purchasedDate,
      assignedDate,
      status,
      devId,
      comment,
      assignedTill,
      // firstName,
      // lastName,
      assignedFrom,
      officeLocation,
      deviceDocs: docs,
      deviceVersion,
      deviceRegion,
      systemCost,
      isInWarrenty,
      warrentyExpireDate,
    });
    const changeUrl = async () => {
      this.setState({
        uploadDocument: await this.urlToFile(docs),
        editUploadPicture: await this.urlToFile(devicePictures)
      })
    }
    changeUrl()
  }

  handleOpenDeviceInfoPopup = ({
    deviceId,
    deviceModel,
    assignedTo,
    // deviceManager,
    deviceType,
    operatingSystem,
    imei,
    acessories,
    ram,
    hdd,
    password,
    brand,
    dateAdded,
    assignedFrom,
    status,
    firstName,
    lastName,
    devId,
    purchasedDate,
    resInfoMsg,
    assignedTill,
    devicePictures,
    docs,
    comment,
    assigndeviceDetails,
    assignedDate,
    deviceVersion,
    deviceRegion,
    systemCost,
    isInWarrenty,
    warrentyExpireDate,
  }) => {
    this.setState({
      openDeviceInfoPopup: !this.state.openDeviceInfoPopup,
      editDivceObj: deviceId,
      deviceModel,
      // assignedTo:assignedTo[0]?._id,
      // // deviceManager,
      deviceType,
      operatingSystem,
      brand,
      imei,
      // acessories,
      ram,
      hdd,
      password,
      // dateAdded,
      // status,
      firstName: assignedTo?.firstName,
      devId,
      lastName: assignedTo?.lastName,
      purchasedDate,
      assignedDate,
      assignedFrom,
      assignedTill,
      devicePictures,
      resInfoMsg: true,
      deviceDocs: docs,
      comment,
      deviceVersion,
      deviceRegion,
      systemCost,
      isInWarrenty,
      warrentyExpireDate,
    });
    setTimeout(() => this.setState({ resInfoMsg: false }), 4000)
  }

  handleOpenAddDevicePopup = () => {
    this.setState({ openAddDevicePopup: !this.state.openAddDevicePopup, });
    // this.setState({resMsg:true})
    setTimeout(() => this.setState({ resMsg: false }), 4000)
  }
  handdleOpenDeviceAction = () =>
    this.setState({ openDeviceAction: !this.state.openDeviceAction });

  closeModal = () =>
    this.setState({
      openAddDevicePopup: false,
      openDeleteDevicePopup: false,
      openEditDevicePopup: false,
      openDeviceInfoPopup: false,
      openDeviceAction: false,
      deleteDevice: "",
      editDevice: "",
      deviceId: "",
      deviceModel: "",
      devId: "",
      deviceType: "",
      deviceStatus: "",
      operatingSystem: "",
      imei: "",
      acessories: "",
      ram: "",
      hdd: "",
      password: "",
      brand: "",
      dateAdded: "",
      assignedDate: "",
      assignedTill: "",
      status: "",
      firstName: "",
      lastName: "",
      assignedTo: "",
      // deviceManager: "",
      brandErrorMsg: "",
      ramErrorMsg: "",
      deviceTypeErrorMsg: "",
      deviceStatusErrorMsg: "",
      // assignedToErrorMsg: "",
      // deviceManagerErrorMsg: "",
      devIdErrorMsg: "",
      hddErrorMsg: "",
      dateErrorMsg: "",
      dateAssignErrorMsg: "",
      uploadPicture: [],
      uploadPictureErrorMsg: "",
      uploadFileName: [],

      uploadDocument: [],
      uploadDocumentName: [],
      uploadDocumentErrorMsg: "",
      DocumentPreview: [],

      deviceStatusType: "",
      deviceModelErrorMsg: "",
      officeLocation: 0,
      deviceOficeLocationErrorMsg: "",
      editUploadPicture: [],
      editUploadPictureErrorMsg: "",
      editFiles: [],
      comment: "",
      assignedFrom: "",
      deviceVersion: "",
      deviceRegion: "",
      deviceRegionErrorMsg: "",
      // deviceStatus:"",


      openAddUnderRepairingModal: false,
      addDeviceRepairingID: "",
      addDeviceRepairingIDErrorMsg: "",
      sendingAmount: 0,
      sendingAmountErrorMsg: "",
      recevingAmount: 0,
      recevingAmountErrorMsg: "",
      sendingDate: "",
      sendingDateErrorMsg: "",
      issueDescription: "",
      issueDescriptionErrorMsg: "",
      recevingDate: "",
      recevingDateErrorMsg: "",
      assignedToErrorMsg: "",
      systemCost: 0,
      isInWarrenty: 0,
      warrentyExpireDate: "",
      systemCostErrorMsg: 0,
      isInWarrentyErrorMsg: false,
      warrentyExpireDateErrorMsg: ""
    });


  deviceValue = (type) => {
    switch (type) {
      case 1:
        return "Laptop";
        break;

      case 2:
        return "CPU";
        break;

      case 3:
        return "Phone";
        break;

      case 4:
        return 'Mouse';
        break;

      case 5:
        return 'Connectors';
        break;

      case 6:
        return 'UPS';
        break;

      case 7:
        return 'Router';
        break;

      case 8:
        return 'Headphone';
        break;

      case 9:
        return 'wifi-Adaptor';
        break;

      case 10:
        return 'web Camera';
        break;

      case 11:
        return 'keyboard';
        break;

      case 12:
        return 'Monitor';
        break;

      case 13:
        return 'Pendrive';
        break;

      case 14:
        return 'HardDisk';
        break;

      case 15:
        return 'RAM';
        break;

      case 16:
        return 'Mac Mini';
        break;
    }
  };
  deviceStatusType = (type) => {
    switch (type) {
      case 1:
        return "Occupied";
        break;

      case 2:
        return "Free";
        break;

      case 3:
        return "Under Repairing";
        break;

      case 4:
        return "Not Working";
        break

      case 5:
        return "Out Dated System";
        break

      case 6:
        return "Archive";
        break

    }
  };
  operatingSystemType = (type) => {
    switch (type) {
      case 1:
        return "windows";
        break;

      case 2:
        return "linux";
        break;
      case 3:
        return "MacOS";
        break;
      case 4:
        return "Android";

    }
  };


  deviceLocationType = (type) => {
    switch (type) {
      case 1:
        return "Noida";
        break;

      case 2:
        return "Lucknow";
        break;
    }
  };
  handleSelectChange = () => {
    this.setState({ search: "" }, () => this.getDevice());
  };
  handleSort = (e) => {
    this.setState({ loader: true });
    this.setState(
      { sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 },
      () => this.getDevice()
    );
  };
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () => this.getDevice());
  };

  getDeviceListForAddRepairing = () => {
    getAdminDeviceList(``).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        const filteredDeviceList = resData.deviceList.filter(device => device.status === 2);
        this.setState(
          {
            deviceListToAddUnderRepairing: filteredDeviceList,
          },
          () => this.setState({ loader: false }, () => this.closeModal())
        );
      } else {
        this.setState({ loader: false });
        this.closeModal();
      }
    });
  }



  getDevice = () => {
    let { search, status, sortType, sortField, pageNo, count, deviceFilterType, searchMonth, searchYear, tabStatus } = this.state;
    let params = `search=${search.trim()}&status=${deviceFilterType}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}&deviceType=${tabStatus}&searchMonth=${searchMonth}&searchYear=${searchYear}`;



    this.props.history.push(`?search=${search}&status=${deviceFilterType}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo}&deviceType=${tabStatus}&searchMonth=${searchMonth}&searchYear=${searchYear}`)
    this.setState({ loader: true })


    getAdminDeviceList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState(
          {
            deviceList: resData.deviceList,
            totalDevices: resData.totalDevices,
            phones: resData.phones,
            cpu: resData.cpu,
            laptops: resData.laptops,
            connectors: resData.connector,
            headphone: resData.headphone,
            mouse: resData.mouse,
            routers: resData.router,
            ups: resData.ups,
            webCamera: resData.webCamera,
            wifiAdaptor: resData.wifiAdapter,
            dataCount: resData.datacount,
            keyboard: resData.keyboard,
            pendrive: resData.pendrive,
            ram1: resData.ram,
            hardDisk: resData.hardDisk,
            monitor: resData.monitor,
            macMini: resData.macMini,

          },
          () => this.setState({ loader: false }, () => this.closeModal())
        );
      } else {
        this.setState({ loader: false });
        this.closeModal();
      }
    });
  };

  // getDetails = () => {
  //   getDeviceDetails().then((res) => {
  //     let { status, resData } = ResponseFilter(res);

  //     if (status) {
  //       this.setState(
  //         {
  //           deviceDetails: resData.deviceDetails,
  //         },
  //         () => this.closeModal()
  //       );
  //     } else {
  //       this.setState({ loader: false });
  //       this.closeModal();
  //     }
  //   });
  // };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + "ErrorMsg"]: "",
    });
  }

  handleOperatingSystemChange = (e) => this.setState({ operatingSystem: e });

  handleDeviceChange = (e) =>
    this.setState({ deviceType: e, deviceTypeErrorMsg: "" });
  handleDeviceStatusChange = (e) => {
    this.setState({ deviceStatus: e, deviceStatusErrorMsg: "" });
    if (e != 1) {
      this.setState({ assignedFrom: "", assignedTill: "", assignedTo: "" })
    }
  }

  handleDeviceLocationChange = (e) => {
    this.setState({ officeLocation: e, deviceOficeLocationErrorMsg: "" });
  }

  handleDateChange = (date, dateString) =>
    this.setState({
      dateAdded: moment(date).toDate().getTime(),
      dateErrorMsg: "",
    });

  handleExpireWarrentyDateChange = (date, dateString) =>
    this.setState({
      warrentyExpireDate: moment(date).toDate().getTime(),
      warrentyExpireDateErrorMsg: "",
    });

  handleDateChangeAssign = (date, dateString) =>
    this.setState({
      assignedFrom: moment(date).toDate().getTime(),
      dateAssignErrorMsg: "",
    });
  handleDateChangeAssignTill = (date, dateString) =>
    this.setState({
      assignedTill: moment(date).toDate().getTime(),
    });
  handleChangeAssignedToChange = (e) =>
    this.setState({ assignedTo: e, assignedToErrorMsg: "" });

  handleIsInWarrentyChange = (e) =>
    this.setState({ isInWarrenty: e, isInWarrentyErrorMsg: "", warrentyExpireDate: e == 2 ? "" : this.state.warrentyExpireDate });


  // handleManagerChange = (e) =>
  //   this.setState({
  //     deviceManager: e,
  //     deviceManagerErrorMsg: "",
  //   });

  handleDelete = () => {
    let params = {
      data: {
        deviceId: this.state.deviceUniqueId,
        status: 2,
      },
    };

    deleteDeviceByManager(params).then((res) => {
      this.setState({}, () => this.getDevice());
    });
  };

  getEmployee = () => {
    getEmployeeList().then((res) => {
      if (res.data.statusCode === 1) {
        this.setState({
          employeeList: res.data.responseData.employeesList,
        });
      } else if (res.data.statusCode === 0) {
        this.setState({ loader: false });
      }

    });
  };

  getRequestList = () => {
    getDeviceRequestList().then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState(
          {
            deviceRequestDetails: resData.deviceDetails.result
          },
          () => this.closeModal()
        );
      } else {
        this.setState({ loader: false });
        this.closeModal();
      }
    })
  }

  componentDidMount() {

    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    let status = url.searchParams.get("status");
    let searchMonth = url.searchParams.get("searchMonth");
    let searchYear = url.searchParams.get("searchYear");
    let deviceType = url.searchParams.get("deviceType");

    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      search: search ? search : "",
      sortType: sortType ? sortType : 2,
      sortField: sortField ? sortField : "created",
      deviceFilterType: status ? status : "",
      searchMonth: searchMonth ? searchMonth : "",
      searchYear: searchYear ? searchYear : "",
      tabStatus: deviceType ? deviceType : ""
    }, () => {


      this.getEmployee();
      // this.getDetails();
      this.getDevice();
      this.getDeviceListForAddRepairing()
    })
    this.getRequestList();

  }

  handleValidetion = () => {
    let validate = true;
    const {
      deviceType,
      deviceStatus,
      // operatingSystem,
      devId,
      brand,
      ram,
      hdd,
      dateAdded,
      assignedTo,
      // deviceStatus,
      deviceModel,
      assignedDate,
      assignedTill,
      assignedFrom,
      uploadPicture, uploadFileName,
      officeLocation,
      uploadDocument,
      uploadDocumentName,
      deviceRegion,
      systemCost,
      isInWarrenty,
      warrentyExpireDate,
      // imei,
      // password,

      // deviceManager,
    } = this.state;
    let neg = [];
    for (let i in uploadFileName) {
      if (uploadFileName[i].match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|pdf|xls|xlsx|doc|docs|docx|odt|dox|html|ppt|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|PDF|HTML)$/))
        neg.push(uploadFileName[i]);
    }
    let neg1 = []
    if (uploadDocument.length > 0) {
      for (let i in uploadDocument) {
        if (uploadDocument[i]?.name?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|doc|docs|docx|odt|dox|ppt|xls|xlxs|xlsx|html|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|HTML)$/))
          neg1.push(uploadDocument[i]);
      }
    }
    if (devId === "") {
      validate = false;
      this.setState({ devIdErrorMsg: "Device ID is required" });
    } else {
      this.setState({ devIdErrorMsg: "" });
    }
    if (deviceModel === "") {
      validate = false;
      this.setState({ deviceModelErrorMsg: "Device Modal is required" });
    } else {
      this.setState({ deviceModelErrorMsg: "" });
    }

    if ((assignedTo === "" || assignedTo == undefined || assignedTo == null) && deviceStatus == 1) {
      validate = false;
      this.setState({ assignedToErrorMsg: "Assigned employee is required" });
    }
    else {
      this.setState({ assignedToErrorMsg: "" });
    }
    if (deviceType === "") {
      validate = false;
      this.setState({ deviceTypeErrorMsg: "Device Type is required" });
    } else {
      this.setState({ deviceTypeErrorMsg: "" });
    }
    if (deviceStatus === "") {
      validate = false;
      this.setState({ deviceStatusErrorMsg: "Device status is required" });
    } else {
      this.setState({ deviceStatusErrorMsg: "" });
    }
    if (officeLocation == "" || officeLocation == undefined || officeLocation == null || officeLocation == 0) {
      validate = false;
      this.setState({ deviceOficeLocationErrorMsg: "Device location is required" });
    } else {
      this.setState({ deviceOficeLocationErrorMsg: "" });
    }

    if (dateAdded === "" || isNaN(dateAdded)) {
      validate = false;
      this.setState({ dateErrorMsg: "Please Select Purchase Date" })
    }
    else {
      this.setState({ dateErrorMsg: "" });
    }
    if ((assignedFrom === "" || isNaN(assignedFrom)) && deviceStatus == 1) {
      validate = false;
      this.setState({ dateAssignErrorMsg: "Date is required" });
    }
    else if (assignedTill && assignedTill < assignedFrom) {
      validate = false;
      this.setState({ dateAssignErrorMsg: "Assigned date can not be greater then assigned till" })
    }
    else if (assignedFrom && assignedFrom < dateAdded == true) {
      validate = false
      this.setState({ dateAssignErrorMsg: "Assigned date can not be less then purchased date" })

    }
    else {
      this.setState({ dateAssignErrorMsg: "" });
    }
    if (uploadPicture === "" || uploadPicture === undefined || uploadPicture.length === 0) {
      validate = false;
      this.setState({ uploadPictureErrorMsg: "Please Upload Pictures" });
    } else if (neg.length !== 0) {
      validate = false;
      this.setState({ uploadPictureErrorMsg: "Please Provide Valid File" });
    }
    else {
      this.setState({ uploadPictureErrorMsg: "" });
    }
    if (neg1.length !== 0) {
      validate = false;
      this.setState({ uploadDocumentErrorMsg: "Only images and pdf are acceptable" });
    }
    else {
      this.setState({ uploadDocumentErrorMsg: "" });
    }
    if (deviceRegion) {
      if (!regex.test(deviceRegion)) {
        validate = false
        this.setState({ deviceRegionErrorMsg: "Numeric value and special charater is no allowed" });
      }
    }

    if (systemCost <= 0 || systemCost == "" || systemCost == undefined) {
      validate = false
      this.setState({ systemCostErrorMsg: "System cost is required" })
    }
    else if (isNaN(systemCost)) {
      validate = false
      this.setState({ systemCostErrorMsg: "Only number allowed" })
    }

    if (isInWarrenty) { }
    else {
      validate = false
      this.setState({ isInWarrentyErrorMsg: "Warrenty status is required" })
    }

    if (isInWarrenty == 1 && !warrentyExpireDate) {
      validate = false;
      console.log("hiii")
      this.setState({ warrentyExpireDateErrorMsg: "Warrenty expire date is Required" })
    }
    else if (warrentyExpireDate) {
      if (dateAdded > warrentyExpireDate) {
        validate = false;
        console.log("hello")
        this.setState({ warrentyExpireDateErrorMsg: "Warrenty expire date can not be less then purchase date" })
      }
      else {
        this.setState({ warrentyExpireDateErrorMsg: "" })

      }
    }
    else {
      this.setState({ warrentyExpireDateErrorMsg: "" })

    }
    return validate;
  };
  handleEditValidetion = () => {
    let validate = true;
    const {
      deviceType,
      deviceStatus,
      // operatingSystem,
      devId,
      brand,
      ram,
      hdd,
      dateAdded,
      assignedTo,
      // deviceStatus,
      deviceModel,
      assignedDate,
      assignedTill,
      assignedFrom,
      uploadPicture,
      officeLocation,
      editUploadPicture,
      // imei,
      // password,

      // deviceManager,
      uploadDocument
      , deviceRegion,
      systemCost,
      isInWarrenty,
      warrentyExpireDate,
    } = this.state;

    let neg = []
    for (let i in editUploadPicture) {
      if (editUploadPicture[i].name) {
        if (editUploadPicture[i]?.name?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|pdf|doc|docs|docx|odt|dox|ppt|xls|xlsx|html|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|PDF|HTML)$/))
          neg.push(editUploadPicture[i]);

      }
      else if (editUploadPicture[i]?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|pdf|xls|xlsx|doc|docs|docx|odt|dox|html|ppt|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|PDF|HTML)$/)) {
        neg.push(editUploadPicture[i]);
      }
    }

    let neg1 = []
    if (uploadDocument.length > 0) {
      for (let i in uploadDocument) {
        if (uploadDocument[i]?.name?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|doc|docs|docx|odt|dox|ppt|xls|xlxs|xlsx|html|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|HTML)$/)) {
          neg1.push(uploadDocument[i]);
        }
      }
    }
    if (devId === "") {
      validate = false;
      this.setState({ devIdErrorMsg: "Device ID is required" });
    } else {
      this.setState({ devIdErrorMsg: "" });
    }
    if (deviceModel === "") {
      validate = false;
      this.setState({ deviceModelErrorMsg: "Device Modal is required" });
    } else {
      this.setState({ deviceModelErrorMsg: "" });
    }
    if (assignedTo === "" && deviceStatus == 1) {
      validate = false;
      this.setState({ assignedToErrorMsg: "Assigned employee is required" });
    }
    else {
      this.setState({ assignedToErrorMsg: "" });
    }
    if (deviceType === "") {
      validate = false;
      this.setState({ deviceTypeErrorMsg: "Device Type is required" });
    } else {
      this.setState({ deviceTypeErrorMsg: "" });
    }
    if (deviceStatus === "") {
      validate = false;
      this.setState({ deviceStatusErrorMsg: "Device status is required" });
    } else {
      this.setState({ deviceStatusErrorMsg: "" });
    }

    if (officeLocation == "" || officeLocation == undefined || officeLocation == null || officeLocation == 0) {
      validate = false;
      this.setState({ deviceOficeLocationErrorMsg: "Device location is required" });
    } else {
      this.setState({ deviceOficeLocationErrorMsg: "" });
    }

    if (dateAdded === "" || isNaN(dateAdded)) {
      validate = false;
      this.setState({ dateErrorMsg: "Please Select Purchase Date" })
    }
    else {
      this.setState({ dateErrorMsg: "" });
    }

    if ((assignedFrom === "" || isNaN(assignedFrom)) && deviceStatus == 1) {
      validate = false;
      this.setState({ dateAssignErrorMsg: "Date is required" });
    }
    else if (assignedTill && assignedTill < assignedFrom) {
      validate = false;
      this.setState({ dateAssignErrorMsg: "Assigned date can not be greater then assigned till" })
    }
    else if (assignedFrom && assignedFrom < dateAdded == true) {
      validate = false
      this.setState({ dateAssignErrorMsg: "Assigned date can not be less then purchased date" })

    }
    else {
      this.setState({ dateAssignErrorMsg: "" });
    }
    if (editUploadPicture === "" || editUploadPicture === undefined || editUploadPicture.length === 0) {
      validate = false;
      this.setState({ editUploadPictureErrorMsg: "Please Upload Pictures" });
    } else if (neg.length !== 0) {
      validate = false;
      this.setState({ editUploadPictureErrorMsg: "Please Provide Valid File" });
    }
    else {
      this.setState({ uploadPictureErrorMsg: "" });
    }
    if (neg1.length !== 0) {
      validate = false;
      this.setState({ uploadDocumentErrorMsg: "Please Provide Valid File" });
    }
    else {
      this.setState({ uploadDocumentErrorMsg: "" });
    }
    if ((assignedTo === "" || assignedTo == undefined || assignedTo == null) && deviceStatus == 1) {
      validate = false;
      this.setState({ assignedToErrorMsg: "Assigned employee is required" });
    }
    else {
      this.setState({ assignedToErrorMsg: "" });
    }
    if (deviceRegion) {
      if (!regex.test(deviceRegion)) {
        validate = false
        this.setState({ deviceRegionErrorMsg: "Numeric value and special charater is no allowed" });
      }
    }
    if (systemCost <= 0 || systemCost == "" || systemCost == undefined) {
      validate = false
      this.setState({ systemCostErrorMsg: "System cost is required" })
    }
    else if (isNaN(systemCost)) {
      validate = false
      this.setState({ systemCostErrorMsg: "Only number allowed" })
    }
    if (isInWarrenty) { }
    else {
      validate = false
      this.setState({ isInWarrentyErrorMsg: "Warrenty status is required" })
    }

    if (isInWarrenty == 1 && !warrentyExpireDate) {
      validate = false;
      console.log("hiii")
      this.setState({ warrentyExpireDateErrorMsg: "Warrenty expire date is Required" })
    }
    else if (warrentyExpireDate) {
      if (dateAdded > warrentyExpireDate) {
        validate = false;
        console.log("hello")
        this.setState({ warrentyExpireDateErrorMsg: "Warrenty expire date can not be less then purchase date" })
      }
      else {
        this.setState({ warrentyExpireDateErrorMsg: "" })

      }
    }
    else {
      this.setState({ warrentyExpireDateErrorMsg: "" })

    }
    console.log("systemCost, dateAdded, warrentyExpireDate,isInWarrenty", systemCost, dateAdded, warrentyExpireDate, isInWarrenty)
    return validate;
  };


  handleEditSubmit = (event) => {
    event.preventDefault();

    let {
      deviceId,
      deviceUniqueId,
      deviceModel,
      assignedTo,
      // deviceManager,
      // empId,
      deviceType,
      deviceStatus,
      brand,
      operatingSystem,
      imei,
      acessories,
      ram,
      hdd,
      password,
      dateAdded,
      status,
      firstName,
      devId,
      assignDeviceRequestId,
      assignedDate,
      assignedTill,
      assignedFrom,
      comment,
      officeLocation,
      editUploadPicture,
      uploadDocument,
      deviceVersion,
      deviceRegion,
      systemCost,
      isInWarrenty,
      warrentyExpireDate,
      // resEditMsg
    } = this.state;

    if (this.handleEditValidetion()) {
      let obj = new FormData()
      obj.append("deviceId", deviceUniqueId)
      obj.append("deviceModel", deviceModel)
      obj.append("assignedTo", deviceStatus !== 1 ? '' : assignedTo)
      obj.append("deviceType", deviceType)
      obj.append("status", deviceStatus)
      obj.append("brand", brand)
      obj.append("operatingSystem", operatingSystem)
      obj.append("systemCost", systemCost)
      obj.append("isInWarrenty", isInWarrenty)
      obj.append("warrentyExpireDate", isInWarrenty !== 1 ? "" : warrentyExpireDate ? warrentyExpireDate : "")
      obj.append("imei", imei)
      obj.append("devId", devId)
      obj.append("ram", ram)
      obj.append("hdd", hdd)
      obj.append("password", password)
      obj.append("assignedFrom", deviceStatus !== 1 ? '' : assignedFrom)
      obj.append("assignedTill", deviceStatus !== 1 ? "" : isNaN(assignedTill) ? "" : assignedTill)
      obj.append("comment", comment)
      obj.append("officeLocation", officeLocation)
      obj.append("deviceVersion", deviceVersion == undefined || deviceVersion == "undefined" ? "" : deviceVersion)
      obj.append("deviceRegion", deviceRegion == undefined || deviceRegion == "undefined" ? "" : deviceRegion)

      if (editUploadPicture) {
        editUploadPicture.map((picture) => obj.append("devicePictures", picture))
      }
      uploadDocument.map((docs) => obj.append("docs", docs))

      this.setState({ loader: true })
      editDevice(obj, deviceUniqueId).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({}, () => this.getDevice());
          this.setState({ resEditMsg: true, loader: false })
          setTimeout(() => this.setState({ resEditMsg: false }), 4000)

        }
        else {
          this.setState({
            resFailMsg: true,
            loader: false,
            responseMessage: res?.data?.error?.responseMessage
          })
          setTimeout(() => this.setState({ resFailMsg: false }), 4000)
        }
      });
    }
  };

  //    openImg=(e)=>{
  //     e.preventDefault();
  //     var image = document.getElementById('image');
  //     var source = image.src;
  //     window.open(source);
  // }
  openInNewWindow = event => {
    event.preventDefault();
    const { href, target } = event.currentTarget;
    const features = "resizable";
    window.open(href, target, features);
  };

  // document.querySelector("a")
  // .addEventListener("click", openInNewWindow)



  handleFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    let files = [...this.state.uploadPicture];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);

    }


    this.setState(
      {
        uploadPicture: files,
        uploadPictureErrorMsg: "",
        uploadFileName: files.map((item) =>

          item.name),
      }, () => { }
    );

    reader.addEventListener(
      "load",
      () => {
        this.setState({
          picturePreview: reader.result
        });

      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };


  handleDocumentChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    let files = [...this.state.uploadDocument];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);

    }



    this.setState(
      {
        uploadDocument: files,
        uploadDocumentErrorMsg: "",
        uploadDocumentName: files.map((item) =>

          item.name),
      }, () => { }
    );

    reader.addEventListener(
      "load",
      () => {
        this.setState({
          DocumentPreview: reader.result
        });

      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };





  handleDeleteIconClick = (index) => {
    this.setState({
      uploadPicture: this.state.uploadPicture.filter(
        (item, itemIndex) => itemIndex !== index
      ),
      uploadFileName: this.state.uploadFileName.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    });
  };



  handleDeleteDocumentIconClick = (index) => {
    this.setState({
      uploadDocument: this.state.uploadDocument.filter(
        (item, itemIndex) => itemIndex !== index
      ),
      uploadDocumentName: this.state.uploadDocumentName.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    });
  };


  // disable past dates
  handleEmployeeSearch = (e) => {
    console.log("e : ", e);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let {
      password,
      devId,
      brand,
      ram,
      hdd,
      dateAdded,
      assignedDate,
      assignedTill,
      assignedTo,
      // deviceManager,
      deviceType,
      deviceStatus,
      operatingSystem,
      imei,
      acessories,
      deviceModel,
      comment,
      uploadPicture,
      assignedFrom,
      officeLocation,
      uploadDocument,
      deviceVersion,
      deviceRegion,
      systemCost,
      warrentyExpireDate,
      isInWarrenty
      // bill,
    } = this.state;

    if (this.handleValidetion()) {
      const obj = new FormData();
      obj.append("brand", brand);
      obj.append("ram", ram);
      obj.append("hdd", hdd);
      obj.append("purchasedDate", dateAdded);
      obj.append("assignedFrom", deviceStatus != 1 ? "" : assignedFrom);
      obj.append("assignedTill", deviceStatus != 1 ? "" : isNaN(assignedTill) ? "" : assignedTill);
      obj.append("operatingSystem", operatingSystem);
      obj.append("imei", imei);
      // acessories:
      // acessories.length != 0 ? this.state.acessories.toString() : [],
      obj.append("deviceType", deviceType);
      obj.append("status", deviceStatus);
      obj.append("assignedTo", deviceStatus != 1 ? '' : assignedTo)
      // deviceManager: deviceManager,
      obj.append("deviceModel", deviceModel)
      obj.append("password", password);
      obj.append("devId", devId)
      obj.append("comment", comment)
      obj.append("officeLocation", officeLocation)

      obj.append("systemCost", systemCost)
      obj.append("isInWarrenty", isInWarrenty)
      obj.append("warrentyExpireDate", isInWarrenty !== 1 ? "" : warrentyExpireDate ? warrentyExpireDate : "")

      obj.append("deviceVersion", deviceVersion == undefined || deviceVersion == "undefined" ? "" : deviceVersion)
      obj.append("deviceRegion", deviceRegion == undefined || deviceRegion == "undefined" ? "" : deviceRegion)
      uploadPicture.map((picture) => {
        obj.append("devicePictures", picture);
      });
      uploadDocument.map((docs) => {
        obj.append("docs", docs);
      });
      this.setState({ loader: true })
      addDevice(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({}, () => this.getDevice());
          this.setState({ resMsg: true, loader: false })
          // window.location.href = "/admin-device";

          setTimeout(() => this.setState({ resMsg: false }), 4000)
        }
        else {
          this.setState({
            resFailMsg: true, loader: false,
            responseMessage: res?.data?.error?.responseMessage
          })
          setTimeout(() => this.setState({ resFailMsg: false }), 4000)
        }


      });
    }
  };

  handleRequest = () => {
    window.location.href = "/admin-device/request-list";
  }

  handleEditFileChange = (e) => {
    let files2 = [...this.state.editUploadPicture];
    for (let i = 0; i < e.target.files.length; i++) {
      files2.push(e.target.files[i]);
    }



    this.setState(
      {
        editUploadPicture: files2,
        editUploadPictureErrorMsg: "",
      }, () => { [...this.state.editFiles] = [] }
    );
  };
  handleEditDeleteIconClick = (index) => {
    this.setState({
      editUploadPicture: this.state.editUploadPicture.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    });
    this.setState({
      editFiles: this.state.editFiles.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    })
  };
  handleStatusFilter = (e) => {
    if (e == undefined) {
      this.setState({ deviceFilterType: "", pageNo: 1 }, () => this.getDevice())
    }
    else {
      this.setState({ deviceFilterType: e, pageNo: 1 }, () => this.getDevice())

    }
  }
  closeFullimage = () => {
    this.setState({
      preview: false
    })
  }

  handleMonth = (month) => {

    if (month == undefined) {
      this.setState({ searchMonth: "", searchYear: "", pageNo: 1 }, () => this.getDevice())
    }
    else {
      this.setState({ searchMonth: month, searchYear: this.state.searchYear ? this.state.searchYear : currentYear, pageNo: 1 }, () => this.getDevice())
    }
  }
  handleYear = (year) => {
    if (year == undefined) {
      this.setState({ searchMonth: "", searchYear: "", pageNo: 1 }, () => this.getDevice())

    }
    else {
      this.setState({ searchMonth: currentMonth, searchYear: year, pageNo: 1 }, () => this.getDevice())
    }
  }


  handleExcel = () => {
    const { search, searchMonth, searchYear, status, sortField, sortType, deviceFilterType, tabStatus } = this.state
    // let query = `search=${search}&status=${status}&sortType=${sortType}&sortField=${sortField}&deviceType=${deviceFilterType}&searchMonth=${searchMonth}&searchYear=${searchYear}`;
    let query = `search=${search.trim()}&status=${deviceFilterType}&sortType=${sortType}&sortField=${sortField}&deviceType=${tabStatus}&searchMonth=${searchMonth}&searchYear=${searchYear}`
    exportDeviceExcel(query).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        window.location.href = resData.excelLink
      }
    })
  }

  handleTabChange = (e) => {
    this.setState({
      tabStatus: e,
      pageNo: 1,

    }, () => this.getDevice())
  }


  handleOpenAddUnderRepairingModal = () => {
    this.setState({
      openAddUnderRepairingModal: true
    })
  }

  handleAddUnderRepairingSendingDate = (date, dateString) =>
    this.setState({
      sendingDate: moment(date).toDate().getTime(),
      sendingDateErrorMsg: "",
    });


  handleAddUnderRepairingRecevingDate = (date, dateString) =>
    this.setState({
      recevingDate: moment(date).toDate().getTime(),
      recevingAmountErrorMsg: "",
    });

  handleAddDeviceRepairingID = (e) => {
    this.setState({
      addDeviceRepairingID: e,
      addDeviceRepairingIDErrorMsg: ""
    })
  }


  validation = (props) => {
    const { recevingAmount, recevingDate, sendingAmount, sendingDate, issueDescription, addDeviceRepairingID } = props
    let validate = true
    if (addDeviceRepairingID == "" || addDeviceRepairingID == null || addDeviceRepairingID == undefined) {
      validate = false
      this.setState({
        addDeviceRepairingIDErrorMsg: REQUIRED
      })
    }
    if (sendingDate === "" || sendingDate == undefined || sendingDate == null || isNaN(sendingDate)) {
      validate = false;
      this.setState({ sendingDateErrorMsg: "Date is required" });
    }
    else if (recevingDate < sendingDate) {
      validate = false;
      this.setState({ sendingDateErrorMsg: "Assigned date can not be greater then assigned till" })
    }
    else {
      this.setState({ sendingDateErrorMsg: "" });
    }
    if (recevingDate === "" || recevingDate == undefined || recevingDate == null || isNaN(recevingDate)) {
      validate = false;
      this.setState({ recevingDateErrorMsg: "Date is required" });
    }
    else {
      this.setState({ recevingDateErrorMsg: "" });
    }
    if (issueDescription == "" || issueDescription == null || issueDescription == undefined) {
      validate = false
      this.setState({
        issueDescriptionErrorMsg: REQUIRED
      })
    }
    if (sendingAmount == "") {
      validate = false;
      this.setState({ sendingAmountErrorMsg: REQUIRED })
    }
    else if (isNaN(sendingAmount) || sendingAmount < 0) {
      validate = false;
      this.setState({ sendingAmountErrorMsg: " Sending amount should be number and greater then 0" })
    }
    else {
      this.setState({ sendingAmountErrorMsg: "" })
    }


    if (recevingAmount == "") {
      validate = false;
      this.setState({ recevingAmountErrorMsg: REQUIRED })
    }
    else if (isNaN(recevingAmount) || recevingAmount < 0) {
      validate = false;
      this.setState({ recevingAmountErrorMsg: " Receiving amount should be number and greater then 0" })
    }
    else {
      this.setState({ recevingAmountErrorMsg: "" })
    }
    return validate
  }




  handleAddUnderRepairingSubmit = () => {
    const { recevingAmount, recevingDate, sendingAmount, sendingDate, issueDescription, addDeviceRepairingID } = this.state
    if (this.validation({
      addDeviceRepairingID: addDeviceRepairingID,
      recevingAmount: recevingAmount,
      recevingDate: recevingDate,
      sendingAmount: sendingAmount,
      sendingDate: sendingDate,
      issueDescription: issueDescription,
    })) {

      const obj = {
        devId: addDeviceRepairingID,
        receivedAmount: parseFloat(recevingAmount),
        receivingDate: recevingDate,
        sendingAmount: parseFloat(sendingAmount),
        sendingDate: sendingDate,
        issue: issueDescription,
      }
      addUnderRepairingDevice(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({}, () => this.getDevice());
          this.setState({ resMsg: true, loader: false })
          // window.location.href = "/admin-device";

          setTimeout(() => this.setState({ resMsg: false }), 4000)
        }
        else {
          this.setState({
            resFailMsg: true, loader: false,
            responseMessage: res?.data?.error?.responseMessage
          })
          this.closeModal()
          setTimeout(() => this.setState({ resFailMsg: false }), 4000)
        }


      });
    }
  }

  render() {
    let {
      openAddDevicePopup,
      openDeleteDevicePopup,
      openEditDevicePopup,
      openDeviceInfoPopup,
      openDeviceAction,
      deviceList,
      deviceRequestDetails,
      employeeList,
      brand,
      ram,
      hdd,
      assignedTo,
      // deviceManager,
      brandErrorMsg,
      ramErrorMsg,
      deviceTypeErrorMsg,
      deviceStatusErrorMsg,
      assignedToErrorMsg,
      // deviceManagerErrorMsg,
      devIdErrorMsg,
      deviceModelErrorMsg,
      // imeiErrorMsg,
      // passwordErrorMsg,
      hddErrorMsg,
      dateErrorMsg,
      dateAssignErrorMsg,
      // operatingSystemErrorMsg,
      imei,
      acessories,
      editDivceObj,
      deviceModel,
      deviceType,
      deviceStatus,
      operatingSystem,
      password,
      firstName,
      lastName,
      dateAdded,
      assignedDate,
      assignedTill,
      devId,
      phones,

      desktops,
      totalDevices,
      laptops,
      search,
      loader,
      pageNo,
      count,
      dataCount,
      purchasedDate,
      resMsg,
      resFailMsg,
      resEditMsg,
      resInfoMsg,
      mouse,
      connectors,
      ups,
      routers,
      headphone,
      wifiAdaptor,
      webCamera,
      keyboard,
      cpu,
      pendrive,
      hardDisk,
      ram1,
      comment,
      uploadPicture,
      uploadDocument,
      uploadDocumentName,
      uploadDocumentErrorMsg,
      DocumentPreview,
      uploadFileName,
      uploadPictureErrorMsg,
      picturePreview,
      monitor,
      macMini,
      devicePictures,
      assignedFrom,
      officeLocation, deviceOficeLocationErrorMsg,
      deviceLocationType,
      responseMessage,
      editFiles,
      editUploadPicture,
      editUploadPictureErrorMsg,
      status,
      deviceFilterType,
      preview,
      fullImage,
      searchMonth, searchYear,
      // assignedTill
      // dateTillErrorMsg,
      deviceDocs,
      tabStatus,
      deviceVersion,
      deviceRegion,
      deviceRegionErrorMsg,
      openAddUnderRepairingModal,

      recevingAmount,
      recevingAmountErrorMsg,
      recevingDate,
      recevingDateErrorMsg,
      sendingAmount,
      sendingAmountErrorMsg,
      sendingDate,
      sendingDateErrorMsg,
      issueDescription,
      issueDescriptionErrorMsg,
      addDeviceRepairingID,
      addDeviceRepairingIDErrorMsg,
      deviceListToAddUnderRepairing,
      systemCost,
      isInWarrenty,
      warrentyExpireDate,
      systemCostErrorMsg,
      isInWarrentyErrorMsg,
      warrentyExpireDateErrorMsg,

    } = this.state;

    return (
      <div className="body_container cm_bold">

        {loader && <Loader />}
        {
          resEditMsg ?
            <div className="alert alert-success cm_top_fix"> Device Edit Successfully </div> : ''
        }
        {
          resMsg ?
            <div className="alert alert-success cm_top_fix"> Device Added Successfully </div> : ''
        }
        {
          resFailMsg ?
            <div className="alert alert-danger cm_top_fix">{responseMessage} </div> : ''
        }
        <h3>DEVICE MANAGEMENT</h3>
        <div className="row d-flex justify-content-end mb-4">
          <div className="col-md-6 search_device_bar2" style={{ marginBottom: 10 }}>
            <span
              className="btn btn-primary add_empBtn"
              onClick={this.handleOpenAddDevicePopup}
            >
              Add new device
            </span>
            <input
              className="form-control mr-lg-2 cm_box"
              type="search"
              placeholder="Search by name/Device Id/Device Region"
              maxLength={40}
              aria-label="Search"
              value={search}
              onChange={this.handleSearch}
            // onKeyDown={(e) => { if (e.key === 'Enter') this.getDevice() }}
            />
            {search ? (
              <span className="cm_clear1" onClick={this.handleSelectChange}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span className="cm_clear1">
                <i className="fa fa-search" aria-hidden="true"></i>
              </span>
            )}
          </div>
        </div>

        <div className="filters justify-content-end  mb-5">
          <Select
            className="select_year_cal"

            value={deviceFilterType ? deviceFilterType : "Select"}
            onChange={this.handleStatusFilter}
            allowClear={deviceFilterType ? true : false}
          >
            <Option value="1">Occupied</Option>
            <Option value="2">Free</Option>
            <Option value="3">Under Repairing</Option>
            <Option value="4">Not Working</Option>
            <Option value="5">Out Dated System</Option>
            <Option value="6">Archive</Option>
          </Select>
          <Select
            className="select_year_cal"
            value={searchMonth ? statusMon(+searchMonth) : "Month"}
            defaultValue="Select Month"
            name="searchMonth"
            onChange={(e) => this.handleMonth(e)}
            allowClear={searchMonth ? true : false}
          >
            {MONTH_ARRAY.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
          </Select>

          <Select
            className="select_year_cal"
            value={searchYear ? searchYear : "Select Year"}
            defaultValue="Select Year"
            name="searchYear"
            onChange={this.handleYear}
            allowClear={searchYear ? true : false}
          >
            {createSelectItems()}
          </Select>
          <button className="btn btn-primary active" onClick={this.handleExcel}>
            Export Excel
          </button>
          <button className="btn btn-primary active"
            onClick={() => this.handleRequest()}>
            <span className="text-left">
              Pending Request
            </span>
          </button>
          <button className="btn btn-primary active"
            onClick={() => this.handleOpenAddUnderRepairingModal()}>
            <span className="text-left">
              Add Under Repairing
            </span>
          </button>
        </div>

        <Tabs activeKey={
          tabStatus == "" || tabStatus == undefined
            ? ""
            : tabStatus == "1"
              ? "1"
              : tabStatus == "2"
                ? "2"
                : tabStatus == "3"
                  ? "3"
                  : tabStatus == "4"
                    ? "4"
                    : tabStatus == "5"
                      ? "5"
                      : tabStatus == "6"
                        ? "6"
                        : tabStatus == "7"
                          ? "7"
                          : tabStatus == "8"
                            ? "8"
                            : tabStatus == "9"
                              ? "9"
                              : tabStatus == "10"
                                ? "10"
                                : tabStatus == "11"
                                  ? "11"
                                  : tabStatus == "12"
                                    ? "12"
                                    : tabStatus == "13"
                                      ? "13"
                                      : tabStatus == "14"
                                        ? "14"
                                        : tabStatus == "15"
                                          ? "15"
                                          : tabStatus == "16"
                                            ? "16"
                                            : "17"
        }
          id="uncontrolled-tab-example"
          onSelect={this.handleTabChange}
        >
          <Tab eventKey="" title={`All ${totalDevices}`}></Tab>
          <Tab eventKey="1" title={`Laptop ${laptops ? laptops : 0}`}></Tab>
          <Tab eventKey="2" title={`CPU ${cpu ? cpu : 0}`}></Tab>
          <Tab eventKey="3" title={`Phone ${phones ? phones : 0}`}></Tab>
          <Tab eventKey="4" title={`Mouse ${mouse ? mouse : 0}`}></Tab>
          <Tab eventKey="5" title={`Connectors ${connectors ? connectors : 0}`}></Tab>
          <Tab eventKey="6" title={`UPS ${ups ? ups : 0}`}></Tab>
          <Tab eventKey="7" title={`Routers ${routers ? routers : 0}`}></Tab>
          <Tab eventKey="8" title={`Headphone ${headphone ? headphone : 0}`}></Tab>
          <Tab eventKey="9" title={`Wifi-Adaptor ${wifiAdaptor ? wifiAdaptor : 0}`}></Tab>
          <Tab eventKey="10" title={`Web Camera ${webCamera ? webCamera : 0}`}></Tab>
          <Tab eventKey="11" title={`keyboard ${keyboard ? keyboard : 0}`}></Tab>
          <Tab eventKey="12" title={`Monitor ${monitor ? monitor : 0}`}></Tab>
          <Tab eventKey="13" title={`Pendrive ${pendrive ? pendrive : 0}`}></Tab>
          <Tab eventKey="14" title={`Hard Disk ${hardDisk ? hardDisk : 0}`}></Tab>
          <Tab eventKey="15" title={`RAM ${ram1 ? ram1 : 0}`}></Tab>
          <Tab eventKey="16" title={`Mac Mini ${macMini ? macMini : 0}`}></Tab>
        </Tabs>


        {/* {assigned device detail module start} */}
        <div className="assigned-list" style={{ marginTop: 10 }}>
          <h3 className="mt-3 cm_bold">Device List</h3>
          {/* {deviceList.length > 0 ? ( */}
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Device Type</th>
                  <th scope="col">Device Model</th>
                  <th scope="col">Device ID
                    <i
                      className={
                        this.state.sortType === 1 &&
                          this.state.sortField === "deviceId"
                          ? "fa fa-sort-alpha-asc ml-2"
                          : "fa fa-sort-alpha-desc ml-2"
                      }
                      onClick={() => this.handleSort("deviceId")}
                    ></i>
                  </th>
                  <th scope="col">Device RAM 
                    <i
                      className={
                        this.state.sortType === 1 &&
                          this.state.sortField === "ram"
                          ? "fa fa-sort-numeric-asc ml-2"
                          : "fa fa-sort-numeric-desc ml-2"
                      }
                      onClick={() => this.handleSort("ram")}
                    ></i>
                  </th>
                  <th scope="col">Device Storage
                  <i
                      className={
                        this.state.sortType === 1 &&
                          this.state.sortField === "hdd"
                          ? "fa fa-sort-numeric-asc ml-2"
                          : "fa fa-sort-numeric-desc ml-2"
                      }
                      onClick={() => this.handleSort("hdd")}
                    ></i>
                  </th>
                  <th scope="col">Device OS
                  <i
                      className={
                        this.state.sortType === 1 &&
                          this.state.sortField === "operatingSystem"
                          ? "fa fa-sort-alpha-asc ml-2"
                          : "fa fa-sort-alpha-desc ml-2"
                      }
                      onClick={() => this.handleSort("operatingSystem")}
                    ></i>
                  </th>
                  <th scope="col">Device Cost</th>
                  <th scope="col"> Device Warrenty Status</th>
                  <th scope="col">Warrenty Expire Date</th>

                  <th scope="col">Device Registered At</th>
                  <th scope="col"> Device Region</th>
                  <th scope="col">Device Status</th>

                  <th scope="col">
                    Assigned Date
                    <i
                      className={
                        this.state.sortType === 1 &&
                          this.state.sortField === "assignedFrom"
                          ? "fa fa-sort-numeric-asc ml-2"
                          : "fa fa-sort-numeric-desc ml-2"
                      }
                      onClick={() => this.handleSort("assignedFrom")}
                    ></i>
                  </th>
                  <th scope="col">
                    Assigned Till
                    <i
                      className={
                        this.state.sortType === 2 &&
                          this.state.sortField === "assignedTill"
                          ? "fa fa-sort-numeric-desc ml-2"
                          : "fa fa-sort-numeric-asc ml-2"
                      }
                      onClick={() => this.handleSort("assignedTill")}
                    ></i>
                  </th>
                  <th scope="col">
                    Assigned
                    <i
                      className={
                        this.state.sortType === 2 &&
                          this.state.sortField === "assigned"
                          ? "fa fa-sort-alpha-desc ml-2"
                          : "fa fa-sort-alpha-asc ml-2"
                      }
                      onClick={() => this.handleSort("assigned")}
                    ></i>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(deviceList)
                  ? deviceList.map((device, i) => (
                    <tr key={device.deviceId}>
                      <td>{i + 1 + (pageNo - 1) * count}</td>

                      <td>{this.deviceValue(device.deviceType)}</td>
                      <td>
                        <span className="cm_col">
                          <Link to={`/admin-device/device-detail/${device?.deviceUniqueId}`}>
                            {device.deviceModel ? device.deviceModel : "_"}
                          </Link>
                        </span>
                      </td>
                      <td
                        className="blue"
                        onClick={() =>
                          this.handleOpenDeviceInfoPopup(device)
                        }
                      >
                        <span className="cm_col">{device.devId}</span>
                      </td>
                      <td>
                        <span className="cm_col">{device?.ram}</span>
                      </td>
                      <td>
                        <span className="cm_col">{device?.hdd}</span>
                      </td>
                      <td>
                        <span className="cm_col">{this.operatingSystemType(device?.operatingSystem)}</span>
                      </td>
                      <td>
                        <span className="cm_col">{device?.systemCost}</span>
                      </td>

                      <td>
                        <span className="cm_col">{deviceWarrentyStatus(device.isInWarrenty)}</span>
                      </td> <td>
                        {device?.warrentyExpireDate && moment
                          .unix(device?.warrentyExpireDate / 1000)
                          .format("DD/MM/YYYY")}
                      </td>
                      <td
                      >
                        <span className="cm_col">{this.deviceLocationType(device.officeLocation)}</span>
                      </td>
                      <td
                      >
                        <span className="cm_col">{device?.deviceRegion}</span>
                      </td>
                      <td
                      >
                        <span className="cm_col">{this.deviceStatusType(device.status)}</span>
                      </td>
                      {device.assignedFrom == null || device.assignedFrom == 0 ? <td className="text-center"><strong>_</strong></td> : <td>
                        {moment
                          .unix(device.assignedFrom / 1000)
                          .format("DD/MM/YYYY")}
                      </td>}
                      {device.assignedTill == null || device.assignedTill == 0 ? <td className="text-center"><strong>_</strong></td> : <td>
                        {moment
                          .unix(device.assignedTill / 1000)
                          .format("DD/MM/YYYY")}
                      </td>}
                      <td>
                        <span className="cm_col">
                          <Link
                            to={`/employee-profile/device-detail/${device.assignedTo?._id}`}
                          >
                            {device.assignedTo?.firstName} {device.assignedTo?.lastName}
                          </Link>
                        </span>
                      </td>

                      <td>
                        <div className="cm_device">
                          <Link
                            onClick={() =>
                              this.handleOpenEditDevicePopup(device)
                            }
                          >
                            <i
                              className="fa fa-pencil"
                              aria-hidden="true"
                              title="Edit device"
                            ></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                  : ""}
              </tbody>
            </table>
            {deviceList && deviceList.length > 0 ? (
              ""
            ) : (
              <h3 className="text-center">No data available</h3>
            )}
          </div>
        </div>

        {/* {assigned device detail module end} */}

        {
          dataCount > 50 ? (
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={count}
              totalItemsCount={dataCount}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            />
          ) : (
            ""
          )
        }
        {/* {add device module start} */}
        <AntModal
          centered
          className="sm_modal1"
          footer={false}
          visible={openAddDevicePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="assign_device" onSubmit={this.handleSubmit}>
              <h3 className="mb-4">ADD DEVICE</h3>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6 ${devIdErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device ID</label>
                  <input
                    maxLength="50"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Device ID"
                    name="devId"
                    value={devId}
                    // defaultValue={devId}
                    onChange={this.handleChange}
                  />
                  {devIdErrorMsg ? (
                    <span className="error_msg">{devIdErrorMsg}</span>
                  ) : null}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${deviceTypeErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device Type</label>
                  <Select
                    value={deviceType ? deviceType : "Select"}
                    onChange={this.handleDeviceChange}
                  >
                    <Option value="1">Laptop</Option>
                    <Option value="2">CPU</Option>
                    <Option value="3">Phone</Option>
                    <Option value="4">Mouse</Option>
                    <Option value="5">Connectors</Option>
                    <Option value="6">UPS</Option>
                    <Option value="7">Routers</Option>
                    <Option value="8">Headphone</Option>
                    <Option value="9">WiFi-Adaptor</Option>
                    <Option value="10">Web Camera</Option>
                    <Option value="11">Keyboard</Option>
                    <Option value="12">Monitor</Option>
                    <Option value="13">Pendrive</Option>
                    <Option value="14">Hard Disk</Option>
                    <Option value="15">RAM</Option>
                    <Option value="16">Mac Mini</Option>

                  </Select>
                  {deviceTypeErrorMsg ? (
                    <span className="error_msg">{deviceTypeErrorMsg}</span>
                  ) : null}
                </div>

                {/* <div className="col-lg-4 col-md-6">
                  <label className="cm_bold">Acessories Name</label>
                  <Select
                  // value={accesoriesType ? accesoriesType : "Select"}
                  // onChange={this.handleDeviceChange}
                  >
                    <Option value="1">Pendrive</Option>
                    <Option value="2">heloooo</Option>
                    <Option value="3">hiiiiiii</Option>
                  </Select>
                </div> */}
                <div className={`form-group col-lg-4 col-md-6 ${deviceModelErrorMsg ? "input_error" : ""} `}>
                  <label className="cm_bold">Device Model</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Device model"
                    name="deviceModel"
                    value={deviceModel}
                    onChange={this.handleChange}
                  />
                  {deviceModelErrorMsg ? (
                    <span className="error_msg">{deviceModelErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6 ${brandErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Brand</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Brand"
                    name="brand"
                    value={brand}
                    onChange={this.handleChange}
                  />
                  {brandErrorMsg ? (
                    <span className="error_msg">{brandErrorMsg}</span>
                  ) : null}
                </div>
                <div className={"form-group col-lg-4 col-md-6 "}>
                  <label className="cm_bold">Service Tag(IMEI)</label>
                  <input
                    className="form-control cm_box"
                    type="text"
                    placeholder="Service tag(IMEI)"
                    name="imei"
                    value={imei}
                    onChange={this.handleChange}
                  />
                  {/* {imeiErrorMsg ? (
                    <span className="error_msg">{imeiErrorMsg}</span>
                  ) : null} */}
                </div>


                <div
                  className={`form-group col-lg-4 col-md-6 ${deviceStatusErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device Status</label>
                  <Select
                    value={deviceStatus ? deviceStatus : "Select"}
                    onChange={this.handleDeviceStatusChange}
                  >
                    <Option value={1}>Occupied</Option>
                    <Option value={2}>Free</Option>
                    {/* <Option value={3}> Under Repairing</Option> */}
                    <Option value={4}> Not Working</Option>
                    <Option value={5}>Out Dated System</Option>
                    <Option value={6}>Archive</Option>
                  </Select>
                  {deviceStatusErrorMsg ? (
                    <span className="error_msg">{deviceStatusErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6 ${ramErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">RAM</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="RAM"
                    name="ram"
                    value={ram}
                    onChange={this.handleChange}
                  />
                  {ramErrorMsg ? (
                    <span className="error_msg">{ramErrorMsg}</span>
                  ) : null}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${hddErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">HDD/SDD</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="HDD/SDD"
                    name="hdd"
                    value={hdd}
                    onChange={this.handleChange}
                  />
                  {hddErrorMsg ? (
                    <span className="error_msg">{hddErrorMsg}</span>
                  ) : null}
                </div>

                <div className={"form-group col-lg-4 col-md-6"}>
                  <label className="cm_bold">Operating System</label>
                  <Select
                    value={operatingSystem ? operatingSystem : "Select"}
                    onChange={this.handleOperatingSystemChange}
                  >
                    <Option value={1}>windows</Option>
                    <Option value={2}>linux</Option>
                    <Option value={3}>MacOS</Option>
                    <Option value={4}>Android</Option>
                  </Select>
                  {/* {operatingSystemErrorMsg ? (
                    <span className="error_msg">{operatingSystemErrorMsg}</span>
                  ) : null} */}
                </div>
              </div>
              <div className="row">
                <div className={"form-group col-lg-4 col-md-6"}>
                  <label className="cm_bold">Password</label>
                  <input
                    maxLength="50"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {/* {passwordErrorMsg ? (
                    <span className="error_msg">{passwordErrorMsg}</span>
                  ) : null} */}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${assignedToErrorMsg ? "input_error" : ""}`}
                >
                  <label className="cm_bold">Assign to Employee</label>
                  <div className=" sm_search">
                    {deviceStatus && deviceStatus == 1 ? <Select
                      showSearch
                      name="employeeId"
                      value={assignedTo ? assignedTo : "Assigned to"}
                      onSearch={this.handleEmployeeSearch}
                      optionFilterProp="key"
                      filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                      onChange={this.handleChangeAssignedToChange}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.middleName} {employee.lastName}
                                </span>
                              </Option>
                            )
                        )
                        : ""}
                    </Select> : <Select
                      name="employeeId"
                      value={assignedTo ? assignedTo : "Assigned to"}
                      onChange={this.handleChangeAssignedToChange}
                      disabled
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={employee.empId}
                                value={employee.empId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}
                                </span>
                              </Option>
                            )
                        )
                        : ""}
                    </Select>}
                    {assignedToErrorMsg ? (
                      <span className="error_msg">{assignedToErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${dateErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Purchased Date</label>
                  <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={dateAdded ? moment(dateAdded) : ""}
                      allowClear={false}
                      disabledDate={disabledPurchaseDate}
                      className="date-border"
                      onChange={this.handleDateChange}
                    />

                    {dateErrorMsg ? (
                      <span className="error_msg">{dateErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`form-group col-lg-4 col-md-6 ${systemCostErrorMsg ? "input_error" : ""}`}
                >
                  <label className="cm_bold">System Cost</label>
                  <input
                    maxLength="50"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="System Cost"
                    name="systemCost"
                    value={systemCost ? systemCost : ""}
                    onChange={this.handleChange}
                  />
                  {systemCostErrorMsg ? (
                    <span className="error_msg">{systemCostErrorMsg}</span>
                  ) : null}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${isInWarrentyErrorMsg ? "input_error" : ""}`}
                >
                  <label className="cm_bold">Warrenty Status</label>
                  <div className=" sm_search">
                    <Select
                      name="employeeId"
                      value={isInWarrenty ? isInWarrenty : "Select Warrenty Status"}
                      onChange={this.handleIsInWarrentyChange}
                    >
                      <Option value={1}>Yes</Option>
                      <Option value={2}>No</Option>

                    </Select>
                    {isInWarrentyErrorMsg ? (
                      <span className="error_msg">{isInWarrentyErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${warrentyExpireDateErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Warrenty Expire Date</label>
                  <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={warrentyExpireDate ? moment(warrentyExpireDate) : ""}
                      allowClear={false}
                      disabled={isInWarrenty == 1 ? false : true}
                      className="date-border"
                      onChange={this.handleExpireWarrentyDateChange}
                    />

                    {warrentyExpireDateErrorMsg ? (
                      <span className="error_msg">{warrentyExpireDateErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6 ${dateAssignErrorMsg ? "input_error" : ""}`}
                >
                  <label className="cm_bold">Date Assigned</label>
                  <div id="startDatePicker" onClick={this.hadleOpenDatePiker}>
                    {deviceStatus && deviceStatus == 1 ? <DatePicker
                      value={assignedFrom ? moment(assignedFrom) : ""}
                      allowClear={false}
                      // disabledDate={disabledDate}
                      className="date-border"
                      onChange={this.handleDateChangeAssign}
                    /> : <DatePicker
                      value={assignedDate ? moment(assignedDate) : ""}
                      allowClear={false}
                      disabledDate={disabledDate}
                      className="date-border"
                      onChange={this.handleDateChangeAssign}
                      disabled
                    />}

                    {dateAssignErrorMsg ? (
                      <span className="error_msg">{dateAssignErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 `}
                >
                  <label className="cm_bold">Assigned Till</label>
                  <div id="endDatePicker" onClick={this.hadleOpenDatePiker}>
                    {deviceStatus && deviceStatus == 1 ? <DatePicker
                      value={assignedTill ? moment(assignedTill) : ""}
                      allowClear={false}
                      minDate={moment().toDate()}
                      disabledDate={disabledDate}
                      className="date-border"
                      onChange={this.handleDateChangeAssignTill}
                    /> : <DatePicker
                      value={assignedTill ? moment(assignedTill) : ""}
                      allowClear={false}
                      disabledDate={disabledDate}
                      className="date-border"
                      onChange={this.handleDateChangeAssignTill}
                      disabled
                    />}

                    {/* {dateTillErrorMsg ? (
                      <span className="error_msg">{dateTillErrorMsg}</span>
                    ) : null} */}
                  </div>
                </div>
                <div className={"form-group col-lg-4 col-md-6"}>
                  <label className="cm_bold">Comment</label>
                  <textarea
                    maxLength={500}
                    className="form-control  cm_box"
                    type="text"
                    placeholder="comment"
                    name="comment"
                    value={comment}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">

                <div
                  className={`form-group col-lg-4 col-md-6 ${deviceOficeLocationErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device Location</label>
                  <Select
                    value={officeLocation ? officeLocation : "Select"}
                    onChange={this.handleDeviceLocationChange}
                  >
                    <Option value={1}>Noida</Option>
                    <Option value={2}>Lucknow</Option>
                  </Select>
                  {deviceOficeLocationErrorMsg ? (
                    <span className="error_msg">{deviceOficeLocationErrorMsg}</span>
                  ) : null}
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${uploadPictureErrorMsg} ? "input_error" : ""}`}
                >
                  <label className="sub" for="upload-files">
                    Upload Picture
                  </label>
                  <span className="form-control cm_hide_upload">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <span className="cm_upload_name">Upload File</span>
                    <input
                      type="File"
                      accept=".png, .jpg, .jpeg"
                      // type="file"
                      className=""
                      // accept=".png , .jpeg"
                      title=""
                      multiple
                      id="upload-files"
                      onChange={this.handleFileChange}
                    />
                  </span>
                  {uploadPictureErrorMsg ? (
                    <span className="error_msg">{uploadPictureErrorMsg}</span>
                  ) : null}
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${uploadDocumentErrorMsg} ? "input_error" : ""}`}
                >
                  <label className="sub" for="upload-files">
                    Upload Document
                  </label>
                  <span className="form-control cm_hide_upload">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <span className="cm_upload_name">Upload File</span>
                    <input
                      type="File"
                      // accept=".png, .jpg, .jpeg"
                      // type="file"
                      className=""
                      // accept=".png , .jpeg"
                      title=""
                      multiple
                      id="upload-files"
                      onChange={this.handleDocumentChange}
                    />
                  </span>
                  {uploadDocumentErrorMsg ? (
                    <span className="error_msg">{uploadDocumentErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className={"form-group col-lg-4 col-md-6"}>
                  <label className="cm_bold">Version</label>
                  <textarea
                    maxLength="500"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Version"
                    name="deviceVersion"
                    value={deviceVersion}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={`form-group col-lg-4 col-md-6 ${deviceRegionErrorMsg} ? "input_error" : ""}`}
                >
                  <label className="cm_bold">Device Region</label>
                  <input
                    maxLength="50"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Device Region"
                    name="deviceRegion"
                    value={deviceRegion}
                    onChange={this.handleChange}
                  />

                  {deviceRegionErrorMsg ? (
                    <span className="error_msg">{deviceRegionErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-10">

                <div className="row">

                  <div className="form-group col-12">

                    {uploadPicture.length > 0 ? (
                      <>
                        <label className="sub">Selected Images: </label>
                        <div
                          className="date-border"
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            padding: "10px",
                            overflow: "auto",
                            minHeight: "200px",
                            maxHeight: "500px",
                          }}
                        >


                          {uploadPicture.length > 0 ? (
                            uploadPicture.map((item, index) => (

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#edfbff",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  height: "3.5rem",
                                  margin: "5px",
                                }}
                              >


                                {item.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/) ?

                                  <img
                                    src={URL.createObjectURL(item)}
                                    className="image-size"
                                  /> : item.name.match(/\.(doc|docx|odt|dotm|dotm|dotx|docm)$/) ? <div className="image-size"><DocIcon /></div> : item.name.match(/\.(xlx|xlsx|xls)$/) ? <div className="image-size"><ExcelIcon /></div> : item.name.match(/\.(pdf|PDF)$/) ? <div className="image-size"><PdfIcon /></div> : <DocIcon />
                                }
                                <label style={{ width: "80%" }}>
                                  {item.name}
                                </label>
                                <div
                                  onClick={() =>
                                    this.handleDeleteIconClick(index)
                                  }
                                >
                                  <IoMdClose
                                    style={{
                                      color: "#347bde",
                                      cursor: "pointer",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <label
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              No files selected !
                            </label>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">

                  <div className="form-group col-12">

                    {uploadDocument.length > 0 ? (
                      <>
                        <label className="sub">Selected Document: </label>
                        <div
                          className="date-border"
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            padding: "10px",
                            overflow: "auto",
                            minHeight: "200px",
                            maxHeight: "500px",
                          }}
                        >
                          {uploadDocument.length > 0 ? (
                            uploadDocument.map((item, index) => (

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#edfbff",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  height: "3.5rem",
                                  margin: "5px",
                                }}
                              >


                                {item.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/) ?

                                  <img
                                    src={URL.createObjectURL(item)}
                                    className="image-size"
                                  /> : item.name.match(/\.(doc|docx|odt|dotm|dotm|dotx|docm)$/) ? <div className="image-size"><DocIcon /></div> : item.name.match(/\.(xlx|xlsx|xls)$/) ? <div className="image-size"><ExcelIcon /></div> : item.name.match(/\.(pdf|PDF)$/) ? <div className="image-size"><PdfIcon /></div> : <DocIcon />
                                }
                                <label style={{ width: "80%" }}>
                                  {item.name}
                                </label>
                                <div
                                  onClick={() =>
                                    this.handleDeleteDocumentIconClick(index)
                                  }
                                >
                                  <IoMdClose
                                    style={{
                                      color: "#347bde",
                                      cursor: "pointer",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <label
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              No files selected !
                            </label>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="submit_button_margin mt-4">
                <button type="submit" className="btn btn-primary cm_btn3">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-primary cm_btn2"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
              </div>
            </form >
          </Modal.Body >
        </AntModal >
        {/* {add device module end} */}

        {/* edit Device Module Start */}
        <AntModal
          centered
          className="sm_modal1"
          footer={false}
          visible={openEditDevicePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="assign_device" onSubmit={this.handleEditSubmit}>
              <h3 className="mb-4">EDIT DEVICE</h3>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6 ${devIdErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device ID</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Device ID"
                    name="devId"
                    value={devId}
                    defaultValue={devId}
                    onChange={this.handleChange}
                  />
                  {devIdErrorMsg ? (
                    <span className="error_msg">{devIdErrorMsg}</span>
                  ) : null}
                </div>
                <div className={`form-group col-lg-4 col-md-6 ${deviceTypeErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Device Type</label>
                  <Select
                    defaultValue={this.deviceValue(deviceType)}
                    onChange={this.handleDeviceChange}
                  >
                    <Option value="1">Laptop</Option>
                    <Option value="2">Desktop</Option>
                    <Option value="3">Phone</Option>
                    <Option value="4">Mouse</Option>
                    <Option value="5">Connectors</Option>
                    <Option value="6">UPS</Option>
                    <Option value="7">Routers</Option>
                    <Option value="8">Headphone</Option>
                    <Option value="9">WiFi-Adaptor</Option>
                    <Option value="10">Web Camera</Option>
                    <Option value="11">Keyboard</Option>
                    <Option value="12">Monitor</Option>
                    <Option value="13">Pendrive</Option>
                    <Option value="14">HardDisk</Option>
                    <Option value="15">RAM</Option>
                    <Option value="16">Mac Mini</Option>

                  </Select>
                  {deviceTypeErrorMsg ? (
                    <span className="error_msg">{deviceTypeErrorMsg}</span>
                  ) : null}
                </div>
                <div className={`form-group col-lg-4 col-md-6 ${deviceModelErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Device Model</label>
                  <input
                    maxLength="50"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Device model"
                    name="deviceModel"
                    value={deviceModel}
                    defaultValue={deviceModel}
                    onChange={this.handleChange}
                  />
                  {deviceModelErrorMsg ? (
                    <span className="error_msg">{deviceModelErrorMsg}</span>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div
                  className={"form-group col-lg-4 col-md-6 "}
                >
                  <label className="cm_bold">Brand</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    defaultValue={brand}
                    placeholder="Brand"
                    name="brand"
                    value={brand}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Service Tag(IMEI)</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Service Tag(IMEI)"
                    name="imei"
                    defaultValue={imei}
                    value={imei}
                    onChange={this.handleChange}
                  />
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${deviceStatusErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device Status</label>
                  <Select
                    value={deviceStatus ? this.deviceStatusType(deviceStatus) : "Select"}
                    onChange={this.handleDeviceStatusChange}
                  >
                    <Option value={1}>Occupied</Option>
                    <Option value={2}>Free</Option>
                    <Option value={4}> Not Working</Option>
                    <Option value={5}>Out Dated System</Option>
                    <Option value={6}>Archive</Option>
                  </Select>
                  {deviceStatusErrorMsg ? (
                    <span className="error_msg">{deviceStatusErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6`}
                >
                  <label className="cm_bold">RAM</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="RAM"
                    name="ram"
                    defaultValue={ram}
                    value={ram}
                    onChange={this.handleChange}
                  />
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 `}
                >
                  <label className="cm_bold">HDD/SDD</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="HDD/SDD"
                    name="hdd"
                    value={hdd}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Operating System</label>
                  <Select
                    //defaultValue={this.operatingSystemType(operatingSystem)}
                    value={
                      operatingSystem
                        ? this.operatingSystemType(operatingSystem)
                        : "Select"
                    }
                    onChange={this.handleOperatingSystemChange}
                  >
                    <Option value={1}>windows</Option>
                    <Option value={2}>linux</Option>
                    <Option value={3}>MacOS</Option>
                    <Option value={4}>Android</Option>
                  </Select>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Password</label>
                  <input
                    className="form-control cm_box"
                    type="text"
                    placeholder="Password"
                    name="password"
                    maxLength="50"
                    value={password}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={`form-group col-lg-4 col-md-6 ${assignedToErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Assign to Employee</label>
                  <div className="sm_search">

                    {deviceStatus && deviceStatus == 1 ? <Select
                      showSearch
                      name="employeeId"
                      placeholder="Select Employee"
                      value={assignedTo ? assignedTo : "Assigned to"}
                      // defaultValue={firstName}
                      onSearch={this.handleEmployeeSearch}
                      optionFilterProp="key"
                      filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                      onChange={this.handleChangeAssignedToChange}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.middleName} {employee.lastName}
                                </span>
                              </Option>

                            )
                        )
                        : ""}
                    </Select> : <Select
                      name="employeeId"
                      placeholder="Select Employee"
                      value={assignedTo ? assignedTo : "Assigned to"}
                      // defaultValue={firstName}
                      onChange={this.handleChangeAssignedToChange}
                      disabled
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={employee.employeeId}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}
                                </span>
                              </Option>

                            )
                        )
                        : ""}
                    </Select>}
                  </div>
                  {assignedToErrorMsg ? (
                    <span className="error_msg">{assignedToErrorMsg}</span>
                  ) : null}
                </div>
                <div className={`form-group col-lg-4 col-md-6 ${dateAssignErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Assigned Date</label>
                  {deviceStatus && deviceStatus == 1 ? <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedFrom ? moment(assignedFrom) : ""}
                      allowClear={false}
                      // disabledDate={disabledDate}
                      defaultValue={moment(assignedFrom)}
                      className="date-border"
                      onChange={this.handleDateChangeAssign}
                    /> </div> : <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedDate ? moment(assignedDate) : ""}
                      allowClear={false}
                      defaultValue={moment(assignedDate)}
                      className="date-border"
                      onChange={this.handleDateChange}
                      disabled
                    />
                  </div>}
                  {dateAssignErrorMsg ? (
                    <span className="error_msg">{dateAssignErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className={`form-group col-lg-4 col-md-6 ${systemCostErrorMsg ? "input_error" : ""}`}
                >
                  <label className="cm_bold">System Cost</label>
                  <input
                    maxLength="50"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="System Cost"
                    name="systemCost"
                    value={systemCost}
                    onChange={this.handleChange}
                  />
                  {systemCostErrorMsg ? (
                    <span className="error_msg">{systemCostErrorMsg}</span>
                  ) : null}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${isInWarrentyErrorMsg ? "input_error" : ""}`}
                >
                  <label className="cm_bold">Warrenty Status</label>
                  <div className=" sm_search">
                    <Select
                      name="warrentyStatus"
                      value={isInWarrenty ? isInWarrenty : "Select Warrenty Status"}
                      onChange={this.handleIsInWarrentyChange}
                    >
                      <Option value={1}>Yes</Option>
                      <Option value={2}>No</Option>

                    </Select>
                    {isInWarrentyErrorMsg ? (
                      <span className="error_msg">{isInWarrentyErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${warrentyExpireDateErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Warrenty Expire Date</label>
                  <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={warrentyExpireDate ? moment(warrentyExpireDate) : ""}
                      allowClear={false}
                      disabled={isInWarrenty == 1 ? false : true}
                      className="date-border"
                      onChange={this.handleExpireWarrentyDateChange}
                    />

                    {warrentyExpireDateErrorMsg ? (
                      <span className="error_msg">{warrentyExpireDateErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Assigned Till</label>
                  {deviceStatus && deviceStatus == 1 ? <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedTill ? moment(assignedTill) : ""}
                      allowClear={false}
                      disabledDate={disabledDate}
                      defaultValue={moment(assignedTill)}
                      className="date-border"
                      onChange={this.handleDateChangeAssignTill}
                    /> </div> : <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedTill ? moment(assignedTill) : ""}
                      allowClear={false}
                      defaultValue={moment(assignedTill)}
                      className="date-border"
                      onChange={this.handleDateChange}
                      disabled
                    />
                  </div>}
                </div>
                <div className={"form-group col-lg-4 col-md-6"}>
                  <label className="cm_bold">Comment</label>
                  <textarea
                    maxLength={500}
                    className="form-control  cm_box"
                    type="text"
                    placeholder="comment"
                    name="comment"
                    value={comment}
                    defaultValue={comment}
                    onChange={this.handleChange}
                  />
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${deviceOficeLocationErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device Location</label>
                  <Select
                    value={officeLocation ? this.deviceLocationType(officeLocation) : "Select"}
                    onChange={this.handleDeviceLocationChange}
                  >
                    <Option value={1}>Noida</Option>
                    <Option value={2}>Lucknow</Option>
                  </Select>
                  {deviceOficeLocationErrorMsg ? (
                    <span className="error_msg">{deviceOficeLocationErrorMsg}</span>
                  ) : null}
                </div>
                {/* <div */}
              </div>
              {/* </div> */}

              <div className='row'>
                <div className={"form-group col-lg-4 col-md-6"}>
                  <label className="cm_bold">Version</label>
                  <textarea
                    maxLength={500}
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Version"
                    name="deviceVersion"
                    value={deviceVersion}
                    onChange={this.handleChange}
                  />
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6`}
                >
                  <label className="sub" for="upload-files">
                    Upload Picture
                  </label>
                  <span className="form-control cm_hide_upload">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <span className="cm_upload_name">Upload File</span>
                    <input
                      type="File"
                      accept=".png, .jpg, .jpeg"
                      // type="file"
                      className=""
                      // accept=".png , .jpeg"
                      title=""
                      multiple
                      id="upload-files"
                      onChange={this.handleEditFileChange}
                    />
                    {this.state.miniloader && <div className="mini_loader_device"></div>}
                  </span>

                  {editUploadPictureErrorMsg ? (
                    <span className="error_msg">{editUploadPictureErrorMsg}</span>
                  ) : null}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${uploadDocumentErrorMsg} ? "input_error" : ""}`}
                >
                  <label className="sub" for="upload-files">
                    Upload Document
                  </label>
                  <span className="form-control cm_hide_upload">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <span className="cm_upload_name">Upload File</span>
                    <input
                      type="File"
                      // accept=".png, .jpg, .jpeg"
                      // type="file"
                      className=""
                      // accept=".png , .jpeg"
                      title=""
                      multiple
                      id="upload-files"
                      onChange={this.handleDocumentChange}
                    />
                    {this.state.miniloader && <div className="mini_loader_device"></div>}
                  </span>
                  {uploadDocumentErrorMsg ? (
                    <span className="error_msg">{uploadDocumentErrorMsg}</span>
                  ) : null}
                </div>

              </div>
              <div className='row'>
                <div className={`form-group col-lg-4 col-md-6 ${deviceRegionErrorMsg} ? "input_error" : ""}`}
                >
                  <label className="cm_bold">Device Region</label>
                  <input
                    maxLength={50}
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Revice Region"
                    name="deviceRegion"
                    value={deviceRegion}
                    onChange={this.handleChange}
                  />
                  {deviceRegionErrorMsg ? (
                    <span className="error_msg">{deviceRegionErrorMsg}</span>
                  ) : null}
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${dateErrorMsg ? "input_error" : ""
                    }`}
                >
                  {console.log("dateAdded", dateAdded)}
                  <label className="cm_bold">Purchased Date</label>
                  <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={dateAdded ? moment(dateAdded) : ""}
                      allowClear={false}
                      disabledDate={disabledPurchaseDate}
                      className="date-border"
                      onChange={this.handleDateChange}
                    />

                    {dateErrorMsg ? (
                      <span className="error_msg">{dateErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div className="row">

              </div> */}

              <div className="col-10">

                <div className="row">

                  <div className="form-group col-12">
                    {
                      editUploadPicture.length > 0 ? (
                        <>
                          <label className="sub">Selected Images: </label>
                          <div
                            className="date-border"
                            style={{
                              borderRadius: "8px",
                              borderWidth: "1px",
                              padding: "10px",
                              overflow: "auto",
                              minHeight: "200px",
                              maxHeight: "500px",
                            }}
                          >


                            {editUploadPicture.length > 0 ? (
                              editUploadPicture.map((item, index) => (

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#edfbff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "3.5rem",
                                    margin: "5px",
                                  }}
                                >
                                  {item.name ?
                                    item.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/) ?

                                      <img
                                        src={URL.createObjectURL(item)}
                                        className="image-size"
                                      />
                                      : item.name.match(/\.(doc|docx|odt|dotm|dotm|dotx|docm)$/) ?
                                        <div className="image-size"><DocIcon /></div> : item.name.match(/\.(xlx|xlsx|xls)$/) ? <div className="image-size"><ExcelIcon /></div> : item.name.match(/\.(pdf|PDF)$/) ? <div className="image-size"><PdfIcon /></div> : <div className='image-size'><DocIcon /></div>
                                    : <img src={item}
                                      className='image-size'
                                    >
                                    </img>}
                                  <label style={{ width: "80%" }}>
                                    {item.name ? item.name : item}
                                  </label>
                                  <div
                                    onClick={() =>
                                      this.handleEditDeleteIconClick(index)
                                    }
                                  >
                                    <IoMdClose
                                      style={{
                                        color: "#347bde",
                                        cursor: "pointer",
                                        width: "24px",
                                        height: "24px",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            ) : (
                              <label
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                No files selected !
                              </label>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="col-10">
                <div className="row">

                  <div className="form-group col-12">

                    {uploadDocument.length > 0 ? (
                      <>
                        <label className="sub">Selected Document: </label>
                        <div
                          className="date-border"
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            padding: "10px",
                            overflow: "auto",
                            minHeight: "200px",
                            maxHeight: "500px",
                          }}
                        >
                          {uploadDocument.length > 0 ? (
                            uploadDocument.map((item, index) => (

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#edfbff",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  height: "3.5rem",
                                  margin: "5px",
                                }}
                              >


                                {item.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/) ?

                                  <img
                                    src={URL.createObjectURL(item)}
                                    className="image-size"
                                  /> : item.name.match(/\.(doc|docx|odt|dotm|dotm|dotx|docm)$/) ? <div className="image-size"><DocIcon /></div> : item.name.match(/\.(xlx|xlsx|xls)$/) ? <div className="image-size"><ExcelIcon /></div> : item.name.match(/\.(pdf|PDF)$/) ? <div className="image-size"><PdfIcon /></div> : <div className="image-size"> <DocIcon /></div>
                                }
                                <label style={{ width: "80%" }}>
                                  {item.name}
                                </label>
                                <div
                                  onClick={() =>
                                    this.handleDeleteDocumentIconClick(index)
                                  }
                                >
                                  <IoMdClose
                                    style={{
                                      color: "#347bde",
                                      cursor: "pointer",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <label
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              No files selected !
                            </label>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>


              <div className="row mt-3">
              </div>

              <div className="submit_button_margin mt-4">
                <button className="btn btn-primary cm_btn3">
                  Save
                </button>

                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        {/* edit Device Module End */}

        {/* {device delete module start} */}
        <Modal
          size="md"
          show={openDeleteDevicePopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="confirmation">
            <p className="cm_bold cnfrm_msg">
              Are you sure, you want to delete this assign device?
            </p>

            <div className="submit_button_margin">
              <button
                className="btn btn-danger cm_btn3"
                onClick={this.handleDelete}
                type="submit"
              >
                Delete
              </button>
              <button
                type="button"
                onClick={this.closeModal}
                className="btn btn-primary cm_btn2"
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* {device delete module end} */}

        {/* Device Detail Modal open */}
        <Modal
          className="cm_deviceInfo"
          size="md"
          show={openDeviceInfoPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <form className="assign_device">
              {/* {
                resInfoMsg ?
                  <div className="alert alert-success cm_top_fix"> Device DETAIL </div> : ''
              } */}
              <h3 className="mb-5">DEVICE DETAIL</h3>
              <div className="cm_overflow_device">
                <div className="row cm_row">
                  <div className="col-6 cm_bold col1">Device ID</div>
                  <div className="col-6 cm_bold col2">{devId}</div>
                  <div className="col-6 cm_bold col1">Device Type</div>
                  <div className="col-6 cm_bold col2">
                    {this.deviceValue(deviceType)}
                  </div>
                  <div className="col-6 cm_bold col1">Device Model</div>
                  <div className="col-6 cm_bold col2">
                    {deviceModel ? deviceModel : "-"}
                  </div>
                  <div className="col-6 cm_bold col1">Device Version</div>
                  <div className="col-6 cm_bold col2">{deviceVersion}</div>
                  <div className="col-6 cm_bold col1">Device Region</div>
                  <div className="col-6 cm_bold col2">{deviceRegion}</div>
                  <div className="col-6 cm_bold col1">Brand</div>
                  <div className="col-6 cm_bold col2">{brand}</div>
                  <div className="col-6 cm_bold col1">Service Tag(IMEI)</div>
                  <div className="col-6 cm_bold col2">{imei ? imei : "-"}</div>
                  <div className="col-6 cm_bold col1">RAM</div>
                  <div className="col-6 cm_bold col2">{ram}</div>

                  <div className="col-6 cm_bold col1">HDD/SDD</div>
                  <div className="col-6 cm_bold col2">{hdd}</div>
                  <div className="col-6 cm_bold col1">Operating System</div>
                  <div className="col-6 cm_bold col2">
                    {operatingSystem
                      ? this.operatingSystemType(operatingSystem)
                      : "-"}
                  </div>
                  <div className="col-6 cm_bold col1">Password</div>
                  <div className="col-6 cm_bold col2">
                    {password ? password : "-"}
                  </div>
                  <div className="col-6 cm_bold col1">Assign to Employee</div>
                  <div className="col-6 cm_bold col2">
                    {firstName ? firstName : "-"} {lastName ? lastName : ""}
                  </div>
                  <div className="col-6 cm_bold col1">Purchased Date</div>
                  <div className="col-6 cm_bold col2">
                    {moment.unix(purchasedDate / 1000).format("DD/MM/YYYY")}
                  </div>
                  <div className="col-6 cm_bold col1">Assigned Date</div>
                  <div className="col-6 cm_bold col2">
                    {assignedFrom ? moment.unix(assignedFrom / 1000).format("DD/MM/YYYY") : ""}
                  </div>
                  <div className="col-6 cm_bold col1">Assigned Till</div>
                  <div className="col-6 cm_bold col2">
                    {assignedTill == null || assignedTill == 0 ? "" : moment.unix(assignedTill / 1000).format("DD/MM/YYYY")}
                  </div>
                  <div className="col-6 cm_bold col1">Comment</div>
                  <div className="col-6 cm_bold col2">
                    {comment ? comment : "-"}
                  </div>
                  <div className="col-6 cm_bold col1">Device Pictures</div>
                  <div className="container">
                    <div className="customer-content-ul image-container" >
                      {/* {devicePictures ? devicePictures.map((devicePictures) =>
                        // <a href={devicePictures} target="_blank">
                        <img className="image" src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>
                        // </a>

                      ) : ""} */}


                      {devicePictures ? devicePictures.map((devicePictures) => (
                        devicePictures.includes(".pdf") == false ?
                          <div
                            className="date-border"
                            style={{
                              borderRadius: "8px",
                              borderWidth: "1px",
                              padding: "10px",
                              overflow: "auto",
                              minHeight: "100px",
                              maxHeight: "500px",
                              marginBottom: "10px"
                            }}
                            onClick={() => this.handleFullImage(devicePictures)}
                          >

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#edfbff",
                                borderRadius: "8px",
                                padding: "10px",
                                height: "3.5rem",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={devicePictures}
                                className="image-size mr-2"
                              />
                              <label style={{ width: "80%" }}>
                                {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                              </label>
                            </div>
                          </div>
                          :
                          <div onClick={() => this.handleFullImage(devicePictures)} >
                            {/* <PDFReader url={devicePictures}>
                            </PDFReader> */}
                            {/* <PdfIcon /> */}
                            <>
                              <div
                                className="date-border"
                                style={{
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  padding: "10px",
                                  overflow: "auto",
                                  minHeight: "100px",
                                  maxHeight: "500px",
                                  marginBottom: "10px"

                                }}
                              >

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#edfbff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "3.5rem",
                                    margin: "5px",
                                  }}
                                >
                                  <div className="image-size"><PdfIcon /></div>
                                  <label style={{ width: "80%" }}>
                                    {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                                  </label>
                                </div>
                              </div>
                            </>
                          </div>
                      )

                      ) : ""}

                    </div>
                    {/* <div className="popup-image">
                    <span>&times;</span>
                    <img src={devicePictures} alt="" />
                  </div> */}
                  </div>

                  <div className="col-6 cm_bold col1">Device Documents</div>
                  <div className="container">
                    <div className="customer-content-ul image-container">
                      {deviceDocs ? deviceDocs.map((deviceDocs) => (
                        deviceDocs.includes(".pdf") == false ?
                          <div
                            className="date-border"
                            style={{
                              borderRadius: "8px",
                              borderWidth: "1px",
                              padding: "10px",
                              overflow: "auto",
                              minHeight: "100px",
                              maxHeight: "500px",
                              marginBottom: "10px"

                            }}
                            onClick={() => this.handleFullImage(deviceDocs)}
                          >

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#edfbff",
                                borderRadius: "8px",
                                padding: "10px",
                                height: "3.5rem",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={deviceDocs}
                                className="image-size mr-2"
                              />
                              <label style={{ width: "80%" }}>
                                {devicePictures ? getFileNameFromUrl(deviceDocs) : ""}
                              </label>
                            </div>
                          </div>
                          :
                          <div onClick={() => this.handleFullImage(deviceDocs)} >
                            {/* <PDFReader url={deviceDocs}>
                            </PDFReader> */}
                            {/* <PdfIcon /> */}
                            <>
                              <div
                                className="date-border"
                                style={{
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  padding: "10px",
                                  overflow: "auto",
                                  minHeight: "100px",
                                  maxHeight: "500px",
                                  marginBottom: "10px"

                                }}
                              >

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#edfbff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "3.5rem",
                                    margin: "5px",
                                  }}
                                >
                                  <div className="image-size"><PdfIcon /></div>
                                  <label style={{ width: "80%" }}>
                                    {devicePictures ? getFileNameFromUrl(deviceDocs) : ""}
                                  </label>
                                </div>
                              </div>
                            </>
                          </div>
                      )

                      ) : ""}
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Device Detail Modal End */}
        {/* full screen Image */}
        <DeviceFullScreenImage
          preview={preview}
          fullImage={fullImage}
          closeModal={this.closeFullimage}
        />
        {/* under repairing modal */}

        <AddDeviceUnderRepairingModal
          show={openAddUnderRepairingModal}
          handleClose={this.closeModal}
          addDeviceRepairingID={addDeviceRepairingID}
          addDeviceRepairingIDErrorMsg={addDeviceRepairingIDErrorMsg}
          sendingAmount={sendingAmount}
          sendingAmountErrorMsg={sendingAmountErrorMsg}
          recevingAmount={recevingAmount}
          recevingAmountErrorMsg={recevingAmountErrorMsg}
          sendingDate={sendingDate}
          sendingDateErrorMsg={sendingDateErrorMsg}
          issueDescription={issueDescription}
          issueDescriptionErrorMsg={issueDescriptionErrorMsg}
          recevingDate={recevingDate}
          recevingDateErrorMsg={recevingDateErrorMsg}
          handleChange={this.handleChange}
          handleAddUnderRepairingSendingDate={this.handleAddUnderRepairingSendingDate}
          handleAddUnderRepairingRecevingDate={this.handleAddUnderRepairingRecevingDate}
          deviceListToAddUnderRepairing={deviceListToAddUnderRepairing}
          handleAddDeviceRepairingID={this.handleAddDeviceRepairingID}
          handleSubmit={this.handleAddUnderRepairingSubmit}
        />
      </div >
    );
  }
}
export default DeviceList;

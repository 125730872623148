import axios from "axios";
import API from "../Api";

const accessToken = localStorage.getItem("accessToken");
const salaryToken = localStorage.getItem("salaryToken");
axios.defaults.headers.common["accessToken"] = accessToken;
axios.defaults.headers.common["salaryToken"] = salaryToken;
axios.defaults.headers.common[
  "Authorization"
] = `${"Basic aHJtX2FkbWluOmFkbWluQGhybQ=="}`;

export const loginEmployee = (params) => axios.post(API.EMPLOYEE_LOGIN, params);

export const logoutEmployee = (params) =>
  axios.post(API.EMPLOYEE_LOGOUT, params);

export const forgotPassword = (params) =>
  axios.post(API.FORGOT_PASSWORD, params);

export const resetPassword = (params) =>
  axios({
    method: "post",
    data: params,
    url: API.RESET_PASSWORD,
    headers: {
      "Content-Type": "application/json",
      accessToken: params.accessToken,
    },
  });

export const getAdminProfile = (params) =>
  axios.get(`${API.GET_ADMIN_PROFILE}/?empId=${params ? params : ""}`);
export const getProject = (params) =>
  axios.get(`${API.GET_PROJECT}/?${params}`);

export const getDesignation = (params) =>
  axios.get(`${API.GET_DESIGNATION}/?${params}`);
export const getSkill = (params) => axios.get(`${API.GET_SKILL}/?${params}`);

export const getProjectId = (params) =>
  axios.get(`${API.GET_PROJECT_ID}/${params}`);
export const getProjectByManager = (params) =>
  axios.get(`${API.GET_PROJECT_BY_MANAGER}/${params}`);
export const getDepartmentList = (params) =>
  axios.get(`${API.DEPARTMENT_LIST}/?${params}`);
export const addParticipent = (params) =>
  axios.get(`${API.ADD_PARTICIPENT}/${params}`);
export const getDeviceList = (params) =>
  axios.get(`${API.GET_DEVICE_LIST}/?${params}`);

export const getEmployeeDeviceList = (params) =>
  axios.get(`${API.GET_EMPLOYEE_DEVICE_LIST}/?${params}`);

export const getDeviceListByManager = (params) =>
  axios.get(`${API.GET_DEVICE_LIST_BY_MANAGER}/?${params}`);
export const getDeviceListByEmp = (params) =>
  axios.get(`${API.GET_DEVICE_LIST_BY_EMPLOYEE}/?${params}`);
export const getDeviceListByEmployee = (params) =>
  axios.get(`${API.GET_DEVICE_LIST_BY_EMPLOYEES}/?${params}`);
export const getDeviceDetails = (params) =>
  axios.get(`${API.GET_DEVICE_DETAILS}/?${params}`);
export const addDeviceRequest = (params) =>
  axios.post(API.ADD_DEVICE_REQUEST, params);
export const updateDeviceRequest = (params) =>
  axios.put(
    `${API.UPDATE_DEVICE_REQUEST}/?assignDeviceRequestId=${params.assignDeviceRequestId}`,
    params
  );
export const getDeviceRequestList = (params) =>
  axios.get(`${API.GET_DEVICE_REQUEST_LIST}?${params}`);
export const getAdminDeviceList = (params) =>
  axios.get(`${API.GET_ADMIN_DEVICE_LIST}/?${params}`);
export const getEmployeeList = (params) =>
  axios.get(`${API.GET_EMPLOYEE_LIST}/?${params}`);
export const getEmployeeListByManager = (params) =>
  axios.get(`${API.GET_EMPLOYEE_LIST_BY_MANAGER}/?${params}`);

export const getEmployeeIdList = (params) =>
  axios.get(`${API.GET_EMPLOYEE_ID_LIST}/?${params}`);
export const getEmployeeDetail = (params) =>
  axios.get(`${API.GET_EMPLOYEE_DETAIL}/?${params}`);
export const projectList = (params) =>
  axios.get(`${API.PROJECT_LIST}/?${params}`);
export const projectListByManager = (params) =>
  axios.get(`${API.PROJECT_LIST_BY_MANAGER}/?${params}`);

export const getDesignationView = (params) =>
  axios.get(`${API.GET_VIEW_DESIGNATION}/?${params}`);
export const getSkillView = (params) =>
  axios.get(`${API.GET_VIEW_SKILL}/?${params}`);

export const getDepartmentView = (params) =>
  axios.get(`${API.GET_VIEW_DEPARTMENT}/?${params}`);
export const getLeaveList = (params) => {
  const url = new URL(`${API.GET_LEAVE_LIST}?`);
  const searchParams = url.searchParams;
  params.search && searchParams.set("search", params.search);
  params.sortType !== undefined &&
    searchParams.set("sortType", params.sortType);
  params.count && searchParams.set("count", params.count);
  params.pageNo !== undefined && searchParams.set("pageNo", params.pageNo);
  params.status && searchParams.set("status", params.status);
  params.leaveType && searchParams.set("leaveType", params.leaveType);
  params.searchDay && searchParams.set("searchDay", params.searchDay);
  params.searchMonth && searchParams.set("searchMonth", params.searchMonth);
  params.searchYear && searchParams.set("searchYear", params.searchYear);
  params.sortField !== undefined &&
    searchParams.set("sortField", params.sortField);
  params.uniqueLate && searchParams.set("uniqueLate", params.uniqueLate);

  return axios.get(`${url}`);
};
export const getTicket = (params) => axios.get(`${API.GET_TICKET}/?${params}`);

export const getAttendanceList = (params) => {
  const url = new URL(`${API.GET_ATTENDANCE_LIST}?`);
  const searchParams = url.searchParams;
  params.search && searchParams.set("search", params.search);
  params.sortType !== undefined &&
    searchParams.set("sortType", params.sortType);
  params.count && searchParams.set("count", params.count);
  params.pageNo !== undefined && searchParams.set("pageNo", params.pageNo);
  params.status && searchParams.set("status", params.status);
  params.searchMonth && searchParams.set("searchMonth", params.searchMonth);
  params.searchDate && searchParams.set("searchDate", params.searchDate);
  params.searchYear && searchParams.set("searchYear", params.searchYear);
  params.sortField !== undefined &&
    searchParams.set("sortField", params.sortField);
  params.startDate && searchParams.set("startDate", params.startDate);
  params.endDate && searchParams.set("endDate", params.endDate);
  return axios.get(`${url}`);
};

export const getAppraisalList = (params) =>
  axios.get(`${API.GET_APPRAISAL_LIST}/?${params}`);
export const getAppraisalCycle = (params) =>
  axios.get(`${API.GET_APPRAISAL_CYCLE}/?${params}`);
export const dailyWorkList = (params) =>
  axios.get(`${API.GET_ACTIVITY_LOGS}/?${params}`);

export const reimbursementList = (params) =>
  axios.get(`${API.GET_REIMBURSE_LIST}/?${params}`);

export const getReimburseByEmpId = (params) =>
  axios.get(`${API.GET_REIMBURSE_BY_EMPID}?${params}`);
export const reimburseMonthlyRecord = (params) =>
  axios.get(`${API.REIMBURSE_MONTHLY_RECORD}/?${params}`);
export const reimburseMonthlyRecordByManager = (params) =>
  axios.get(`${API.GET_REIMBURSERMENT_MONTHLY_BY_MANAGER}/?${params}`);
export const reimburseHistoryRecordByManager = (params) =>
  axios.get(`${API.GET_REIMBURSERMENT_HISTORY_BY_MANAGER}/?${params}`);
export const dailyWorkByProjectId = (params) =>
  axios.get(`${API.GET_ACTIVITY_LOGS}/?${params}`);
export const dailyWork = (params) =>
  axios.get(`${API.GET_ACTIVITY}/?${params}`);
export const getAttendance = (params) =>
  axios.get(`${API.GET_ATTENDENCE_EMPID}/?${params}`);
export const getAttendanceMonthly = (params) =>
  axios.get(`${API.GET_ATTENDENCE_MONTHLY_EMPID}/?${params}`);
export const viewAttendanceByEmp = (params) =>
  axios.get(`${API.GET_ATTENDENCE_BY_EMP}/?${params}`);
export const viewAttendanceEmp = (params) =>
  axios.get(`${API.GET_ATTENDENCE_EMP}/?${params}`);
export const dailyWorkByEmpId = (params) =>
  axios.get(`${API.GET_ACTIVITY_EMPID}/?${params}`);
export const getHolidayList = (params) =>
  axios.get(`${API.GET_HOLIDAY_LIST}/?${params}`);
export const holidayListByEmp = (params) =>
  axios.get(`${API.HOLIDAY_LIST_EMP}/?${params}`);
export const getDashboardDetails = () => axios.get(API.GET_DASHBOARD_DETAILS);
export const getBirthday = () => axios.get(API.GET_BIRTHDAY);
export const getWorkAnniversary = () => axios.get(API.GET_WORK_ANNIVERSARY);
export const getDashboardAppraisal = () =>
  axios.get(API.GET_DASHBOARD_APPRAISAL);
export const getEmployeeDashboard = (params) =>
  axios.get(API.GET_EMPLOYEE_DASHBOARD);
export const getAdminDetails = (params) =>
  axios.get(`${API.GET_ADMIN_DETAILS}/?${params}`);
export const getProjectView = (params) =>
  axios.get(`${API.GET_PROJECT_VIEW}/${params}`);
export const getEmployeeLeaves = (params) => {
  const url = new URL(`${API.GET_EMPLOYEE_LEAVES}?`);
  const searchParams = url.searchParams;
  params.sortType !== undefined &&
    searchParams.set("sortType", params.sortType);
  params.count && searchParams.set("count", params.count);
  params.pageNo !== undefined && searchParams.set("pageNo", params.pageNo);
  params.status && searchParams.set("status", params.status);
  params.year && searchParams.set("year", params.year);
  params.sortField !== undefined &&
    searchParams.set("sortField", params.sortField);
  params.year !== undefined && searchParams.set("year", params.year);
  params.month !== undefined && searchParams.set("month", params.month);
  params.quarter !== undefined && searchParams.set("quarter", params.quarter);
  params.userId !== undefined && searchParams.set("userId", params.userId);
  params.uniqueLate !== undefined &&
    searchParams.set("uniqueLate", params.uniqueLate);
  params.leaveType !== undefined &&
    searchParams.set("leaveType", params.leaveType);

  return axios.get(`${url}`);
};

export const investmentform = (params) =>
  axios.post(`${API.INVESTEMENT_FORM_SUBMIT}`, params);
export const editInvestmentForm = (params) =>
  axios.put(`${API.EDIT_INVESTMENT_FORM}`, params);

export const getInvestmentDetails = (query) =>
  axios.get(`${API.GET_INVESTMENT_DETAILS}/?${query}`);

export const getInvestmentDetailsEmp = (query) =>
  axios.get(`${API.GET_INVESTMENT_DETAILS_EMP}/?${query}`);

export const deleteInvestment = (params) =>
  axios.delete(
    `${API.DELETE_INVESTMENT_FORM}/?investmentId=${params.investmentId}`
  );

export const uploadRentSlip = (params) =>
  axios.post(`${API.UPLOAD_RENT_SLIP}`, params);

export const getEmployeeReimbursement = (params) =>
  axios.get(`${API.GET_EMPLOYEE_REIMBURSEMENT}/?${params}`);
export const getReimbursementPrintDetail = (params) =>
  axios.get(`${API.REIMBURSEMENT_PRINT}?${params}`);
export const getHoliday = (params) =>
  axios.get(`${API.GET_HOLIDAY}/?holidayId=${params}`);
export const employeeViewTicket = (params) =>
  axios.get(`${API.EMPLOYEE_VIEW_TICKET}/?${params}`);
export const employeeLeaveStatus = (params) =>
  axios.get(`${API.GET_EMPLOYEE_LEAVES_STATUS}/?${params}`);
export const getTotalLeave = (params) =>
  axios.get(`${API.GET_TOTAL_LEAVES}/?${params}`);
export const getTotalLeaveEmp = (params) =>
  axios.get(`${API.GET_TOTAL_LEAVES_EMP}/?${params}`);

export const employeeAdminLeaveStatus = (params) => {
  const url = new URL(`${API.GET_ADMIN_EMPLOYEE_LEAVES_STATUS}?`);
  const searchParams = url.searchParams;
  params.employeeId && searchParams.set("employeeId", params.employeeId);
  params.searchQuarter !== undefined &&
    searchParams.set("searchQuarter", params.searchQuarter);
  params.searchMonth !== undefined &&
    searchParams.set("searchMonth", params.searchMonth);
  params.searchYear !== undefined &&
    searchParams.set("searchYear", params.searchYear);
  return axios.get(`${url}`);
};
export const markPaidLeave = (params) =>
  axios.put(
    !params.expireDate
      ? `${API.MARK_PAID_LEAVE_EMP}`
      : `${API.TEMP_COMPOFF_LEAVE_GIVEN_BY_ADMIN}`,
    params
  );
export const editMarkPaidLeave = (params) =>
  axios.put(
    `${API.EDIT_MARK_PAID_LEAVE}/?employeeId=${params.employeeId}&totalLeavesId=${params.totalLeavesId}`,
    params
  );
export const carryForward = (params) => axios.post(API.CARRY_FORWARD, params);
export const deleteApplyLeave = (params) => {
  const url = new URL(`${API.DELETE_LEAVE}?`);
  const searchParams = url.searchParams;
  params.leaveId && searchParams.set("leaveId", params.leaveId);
  return axios.delete(`${url}`);
};

export const sendWishes = (params) => axios.post(API.SEND_WISHES, params);
export const addEmployee = (params) => axios.post(API.ADD_EMPLOYEE, params);
export const addDevice = (params) => axios.post(API.ADD_DEVICE, params);
export const addDeviceByManager = (params) =>
  axios.post(API.ADD_DEVICE_MANAGER, params);
export const addDepartment = (params) => axios.post(API.ADD_DEPARTMENT, params);
export const deleteDepartmentMember = (param) =>
  axios.put(
    `${API.DELETE_DEPARTMENT_MEMBER}?departmentId=${param.departmentId}`,
    param
  );

export const addDesignation = (params) =>
  axios.post(API.ADD_DESIGNATION, params);
export const addSkill = (params) => axios.post(API.ADD_SKILL, params);

export const addEmployeeEducation = (params) =>
  axios.post(API.ADD_EMPLOYEE_EDUCATION, params);
export const addSkillEmp = (params) => axios.post(API.ADD_SKILL_EMP, params);
export const addOtherSkillEmp = (params) =>
  axios.post(API.ADD_OTHER_SKILL_EMP, params);
export const addAdditionalQualification = (params) =>
  axios.post(API.ADD_ADDITIONAL_QUALIFICATION, params);
export const addEmployeeDocuments = (params) =>
  axios.post(API.ADD_EMPLOYEE_DOCUMENT, params);
export const addProject = (params) => axios.post(API.ADD_PROJECT, params);
export const addProjectByManager = (params) =>
  axios.post(API.ADD_PROJECT_BY_MANAGER, params);
export const applyLeaveByEmployee = (params) =>
  axios.post(API.APPLY_LEAVE, params);
export const addAppraisal = (params) => axios.post(API.ADD_APPRAISAL, params);
export const attendenceSendMail = (params) =>
  axios.post(`${API.ATTENDANCE_SEND_MAIL}/?${params}`);
export const addHoliday = (params) => axios.post(API.ADD_HOLIDAY, params);
export const addNewStandup = (params) =>
  axios.post(API.ADD_NEW_STANDUP, params);
export const claimReimbursement = (params) =>
  axios.post(API.CLAIM_REIMBURSEMENT, params);
export const addNewTicket = (params) => axios.post(API.ADD_TICKET, params);
export const editTicket = (params) =>
  axios.put(`${API.EDIT_TICKET}/?ticketId=${params.ticketId}`, params);

export const dailyAttendance = (params) =>
  axios.post(API.DAILY_ATTENDANCE, params);
export const dailyAttendancebyCsv = (params) =>
  axios.post(API.DAILY_ATTENDANCE_BY_CSV, params);
export const dailyAttendancebyExcel = (params) =>
  axios.post(API.DAILY_ATTENDANCE_BY_EXCEL, params);
export const sendWishesTemplate = (params) =>
  axios.post(API.SEND_WISHES_TEMPLATE, params);
export const sendWishesDriveTemplate = (params) =>
  axios.post(API.SEND_WISHES_DRIVE_TEMPLATE, params);

export const addCompOffLeave = (params) => axios.post(API.ADD_COMP_OFF, params);

export const editNewStandup = (params) =>
  axios.post(API.EDIT_NEW_STANDUP, params);
export const editAttendance = (params) =>
  axios.put(
    `${API.EDIT_ATTENDANCE}/?attendenceId=${params.attendenceId}`,
    params
  );
export const editDepartment = (params) =>
  axios.put(API.EDIT_DEPARTMENT, params);
export const editCompOffCount = (params) =>
  axios.put(API.EDIT_TEMP_COMP_OFF_COUNT_BY_ADMIN, params);

export const editDesignation = (params) =>
  axios.put(API.EDIT_DESIGNATION, params);
export const editSkill = (params) => axios.put(API.EDIT_SKILL, params);
export const editSkillEmp = (params) => axios.put(API.EDIT_SKILL_EMP, params);
export const addEmployeeInSkill = (params) =>
  axios.put(API.ADD_EMP_TO_SKILL, params);
export const addEmployeeInDesignation = (params) =>
  axios.put(API.ADD_EMP_TO_DESIGNATION, params);

export const editDevice = (params, query) =>
  axios.put(`${API.EDIT_DEVICE}?deviceId=${query}`, params);
export const editDeviceByManager = (params) =>
  axios.put(`${API.EDIT_DEVICE_MANAGER}/?deviceId=${params.deviceId}`, params);
export const editEmployeeProfile = (params) =>
  axios.put(
    `${API.EDIT_EMPLOYEE_PROFILE}/?employeeId=${params.employeeId}`,
    params
  );
export const editEmployeeProfileImage = (params) =>
  axios.put(
    `${API.EDIT_PROFILE_PIC}/?employeeId=${params.employeeId}`,
    params.data
  );
export const editEmployeeProfileImageByEmp = (params) =>
  axios.put(
    `${API.EDIT_PROFILE_PIC_BY_EMP}/?employeeId=${params.employeeId}`,
    params.data
  );
export const employeeActive = (params) =>
  axios.put(`${API.EMPLOYEE_ACTIVE}/?employeeId=${params.employeeId}`, params);
export const editEmployeeEducation = (params) =>
  axios.put(
    `${API.EDIT_EMPLOYEE_EDUCATION}/?employeeId=${params.employeeId}&educationId=${params.educationId}`,
    params
  );
export const editEmployeeQualification = (params) =>
  axios.put(
    `${API.EDIT_EMPLOYEE_QUALIFICATION}/?employeeId=${params.employeeId}&qualificationId=${params.qualificationId}`,
    params
  );

export const editTempCompOffByAdmin = (params) =>
  axios.put(API.EDIT_TEMP_COMP_OFF_BY_ADMIN, params);
export const changePassword = (params) =>
  axios.put(API.CHANGE_PASSWORD, params);
export const leaveAction = (params) => {
  const url = new URL(`${API.LEAVE_ACTION}?`);
  const searchParams = url.searchParams;
  params.status && searchParams.set("status", params.status);
  params.leaveId && searchParams.set("leaveId", params.leaveId);
  params.reply && searchParams.set("reply", params.reply);
  return axios.put(`${url}`);
};
export const editLeaveByAdmin = (params) => {
  return axios.put(API.EDIT_LEAVE_BY_ADMIN, params);
};

export const editApprovedShortDayLeaveByAdmin = (params) => {
  const url = new URL(`${API.EDIT_APPROVED_SHORT_LEAVE_BY_ADMIN}?`);
  const searchParams = url.searchParams;
  params.leaveId && searchParams.set("leaveId", params.leaveId);
  params.leaveType && searchParams.set("leaveType", params.leaveType);
  params.halfOfTheDay && searchParams.set("halfOfTheDay", params.halfOfTheDay);
  params.to && searchParams.set("to", params.to);
  params.from && searchParams.set("from", params.from);
  return axios.put(`${url}`);
};

export const leaveDeductByAdmin = (params) =>
  axios.post(API.DEDUCT_LEAVE_BY_ADMIN, params);
export const editProject = (params) => axios.put(API.EDIT_PROJECT, params);
export const editProjectByManager = (params) =>
  axios.put(API.EDIT_PROJECT_BY_MANAGER, params);
export const projectAction = (params) => axios.put(API.PROJECT_ACTION, params);
export const projectActionByManager = (params) =>
  axios.put(API.PROJECT_ACTION_BY_MANAGER, params);
export const resolveTicket = (params) =>
  axios.put(`${API.RESOLVE_TICKET}/?ticketId=${params.ticketId}`, params);
export const deleteTicket = (params) =>
  axios.put(`${API.DELELTE_TICKET}/?ticketId=${params.ticketId}`);
export const grantAccess = (params) => axios.put(API.GRANT_ACCESS, params);
export const editAppraisal = (params) =>
  axios.put(
    `${API.EDIT_APPRAISAL}/?employeeId=${params.employeeId}&appraisalId=${params.appraisalId}`,
    params
  );
export const postponeAppraisal = (params) =>
  axios.put(
    `${API.POSTPONE_APPRAISAL}/?employeeId=${params.employeeId}&appraisalId=${params.appraisalId}`,
    params
  );
export const reimbursementAction = (params) =>
  axios.put(API.REIMBURSEMENT_ACTION, params);
export const reopenTicket = (params) =>
  axios.put(`${API.REOPEN_TICKET}/?ticketId=${params.ticketId}`, params);
export const removeGrantAccess = (params) =>
  axios.put(API.REMOVE_GRANT_ACCESS, params);
export const editHoliday = (params) =>
  axios.put(`${API.EDIT_HOLIDAY}/?holidayId=${params.holidayId}`, params);
export const editRequest = (params) =>
  axios.put(`${API.EDIT_REQUEST}/?employeeId=${params.employeeId}`, params);

export const deleteDepartment = (params) =>
  axios.delete(API.DELETE_DEPARTMENT, params);
export const deleteDesignation = (params) =>
  axios.delete(API.DELETE_DESIGNATION, params);
export const deleteSkill = (params) => axios.delete(API.DELETE_SKILL, params);

export const deleteDevice = (params) => axios.delete(API.DELETE_DEVICE, params);
export const deleteDeviceByManager = (params) =>
  axios.delete(API.DELETE_DEVICE_MANAGER, params);
export const deleteAttendance = (params) =>
  axios.delete(API.DELETE_ATTENDANCE, params);
export const deleteEmployeeEducation = (params) =>
  axios.delete(
    `${API.DELETE_EMPLOYEE_EDUCAION}/?employeeId=${params.employeeId}&educationId=${params.educationId}`,
    { data: params }
  );
export const deleteEmployeeQualification = (params) =>
  axios.delete(
    `${API.DELETE_EMPLOYEE_QUALIFICATION}/?employeeId=${params.employeeId}&qualificationId=${params.qualificationId}`,
    { data: params }
  );
export const deleteEmployeeDocument = (params) =>
  axios.delete(
    `${API.DELETE_EMPLOYEE_DOCUMENT}/?employeeId=${params.employeeId}&documentId=${params.documentId}`,
    { data: params }
  );
export const deleteEmployeeProfile = (params) =>
  axios.delete(API.DELETE_EMPLOYEE_PROFILE, params);
export const deleteProject = (params) =>
  axios.delete(API.DELETE_PROJECT, params);
export const deleteProjectByManager = (params) =>
  axios.delete(API.DELETE_PROJECT_BY_MANAGER, params);
export const deleteHoliday = (params) =>
  axios.delete(API.DELETE_HOLIDAY, params);

export const creditAmountInWallet = (params) =>
  axios.post(`${API.EMPLOYEE_WALLET_UPDATE}`, params);

// Reimbursement-Module

export const editReimburse = (params) =>
  axios.put(`${API.EDIT_REIMBURSEMENT}`, params);
  export const deleteReimbursement = (params) =>
  axios.delete(`${API.DELETE_REIMBURSEMENT}/?reimbursementId=${params.reimbursementId}`);

//TRANSACTION MODULE
export const getTransactionExcelSheetService = (excelSheetData) =>
  axios.get(`${API.GET_TRANSACTION_EXCEL_SHEET}/?${excelSheetData}`);

// resumeSection services

export const getEmployeeResume = (query) =>
  axios.get(`${API.GET_EMPLOYEE_RESUME_LIST}/?${query}`);
export const deleteEmployeeResume = (data) =>
  axios.delete(API.EMPLOYEE_RESUME_DELETE, data);
export const getResumeByEmployeeId = (params) =>
  axios.get(`${API.GET_RESUME_BY_EMPLOYEE_ID}/?${params}`);
export const addEmployeeResume = (data) =>
  axios.post(API.ADD_EMPLOYEE_RESUME, data);
export const updateEmployeeResume = (data) =>
  axios.put(API.UPDATE_EMPLOYEE_RESUME, data);

export const addEmployeeResumeByAdmin = (data) =>
  axios.post(API.ADD_EMPLOYEE_RESUME_BY_ADMIN, data);

//foodOrder Services

export const getVendorListService = () => axios.get(`${API.GET_VENDORS_LIST}`);
export const getOrderListService = (query) =>
  axios.get(`${API.GET_ORDER_LIST}/?${query}`);
export const getTransactionListService = (query) =>
  axios.get(`${API.GET_TRANSACTION_LIST}/?${query}`);

//INTERVIEW MODULE

export const getInterviewListService = (query) =>
  axios.get(`${API.GET_INTERVIEW_LIST}/?${query}`);

export const getContactListService = (query) =>
  axios.get(`${API.GET_CONTACTLIST}?${query}`);

export const getInterviewerListService = (query) =>
  axios.get(`${API.GET_INTERVIEWER_LIST}?${query}`);
export const addCandidateByAdminService = (data) =>
  axios.post(
    `${API.ADD_CANDIDATE_BY_ADMIN}?employeeId=${localStorage.getItem(
      "employeeId"
    )}`,
    data
  );

export const addInterviewerByAdminService = (data) =>
  axios.post(API.ADD_INTERVIEWER_BY_ADMIN, data);
export const getCandidateByIdService = (query) =>
  axios.get(`${API.GET_CANDIDATE_BY_ID}/?${query}`);
export const deleteCandidateByAdminService = (params) =>
  axios.delete(API.INTERVIEW_DELETE, params);
export const deleteInterviewerByAdminService = (params) =>
  axios.delete(
    `${API.DELETE_INTERVIEWER_BY_ID}?interviewerId=${params.data.interviewId}`
  );

export const editCandidateByAdminService = (params) =>
  axios.put(API.EDIT_INTERVIEW_BY_ADMIN, params);

export const editInterviewerByAdminService = (params) =>
  axios.put(
    `${API.EDIT_INTERVIEWER_BY_ADMIN}?interviewerId=${params.interviewerid}`,
    params
  );

export const getAdminActivityList = (query) =>
  axios.get(`${API.ACTIVITY_BY_ADMIN}?${query}`);

export const getInterviewerStatus = () =>
  axios.get(`${API.GET_INTERVIEWER_STATUS}`);

export const editInterviewerStatus = (query) =>
  axios.put(
    `${API.CHANGE_INTERVIEWER_STATUS_BY_ID}?isAvailable=${query.status}`
  );

export const addContactBySalesService = (params, employeeId) =>
  axios.post(
    `${API.ADD_CONTACT}?employeeId=${localStorage.getItem("employeeId")}`,
    params
  );

export const editContactBySalesService = (params) =>
  axios.put(API.EDIT_CONTACT, params);

export const getAssignCandidate = (params) =>
  axios.get(API.GET_ASSIGN_CANDIDATE_LIST, params);

export const getAssignInterviewer = (params) =>
  axios.get(API.GET_ASSIGN_INTERVIEWER_LIST, params);
export const assignInterviewerCandidate = (params) =>
  axios.post(API.ASSIGN_INTERVIEW_CANDIDATE, params);

export const getInterviews = (params) =>
  axios.get(`${API.GET_INTERVIEWS_BY_INTERVIEWER}?interviewerId=${params}`);

export const editAssignCandidate = (params) =>
  axios.put(API.EDIT_ASSIGN_CANDIDATE, params);
export const assignContactToEmployee = (params) =>
  axios.put(API.ASSIGN_CONTACT_TO_EMPLOYEE, params);
export const addTeam = (params) => axios.post(API.ADD_TEAM_BY_ADMIN, params);

export const editTeam = (params) => axios.put(API.EDIT_TEAM_BY_ADMIN, params);

export const getTeam = (params) => axios.get(API.GET_TEAM_BY_ADMIN, params);

export const deleteTeam = (params) =>
  axios.delete(API.DELETE_TEAM_BY_ADMIN, params);

export const getTeamMembers = (params) =>
  axios.get(`${API.GET_TEAM_MEMBERS_BY_ADMIN}?${params}`);

export const getTeamMemberDetails = (params) =>
  axios.get(`${API.GET_TEAM_MEMBERS_DETAILS_BY_ADMIN}?${params}`);

export const deleteMembers = (params) =>
  axios.put(API.DELETE_TEAM_MEMBERS, params);

export const addMembers = (params) =>
  axios.put(API.ADD_TEAM_MEMBER_BY_ADMIN, params);

export const getProjectExcelSheet = (params) =>
  axios.get(`${API.GET_PROJECT_ACTIVITY_EXCEL}?${params}`);
export const getProjectLeave = (params) =>
  axios.get(`${API.PROJECT_LEAVE}?projectId=${params}`);

export const deleteContact = (params) =>
  axios.delete(API.DELETE_CONTACT, params);
export const getDeviceDetils = (params) =>
  axios.get(`${API.DEVICE_DETAILS}?${params}`);

export const getCandidateFeedBack = (params) =>
  axios.get(`${API.GET_CANDIDATE_FEEDBACK}?${params}`);

export const addCandidateFeedBack = (params) =>
  axios.post(API.ADD_CANDIDATE_FEEDBACK, params);

export const editCandidateFeedBack = (params) =>
  axios.put(API.EDIT_CANDIDATE_FEEDBACK, params);

export const deleteCandidateFeedBack = (params) =>
  axios.delete(
    `${API.DELETE_CANDIDATE_FEEDBACK}?clientId=${params?.data?.clientId}`
  );
export const exportContactExcel = (params) =>
  axios.get(`${API.EXPORT_CONTACT_EXCEL}?${params}`);

export const exportInterviewCandidateExcel = (params) =>
  axios.get(`${API.EXPORT_INTERVIEW_CANDIDATE_EXCEL}?${params}`);

export const exportCandidateFeedbackExcel = (params) =>
  axios.get(`${API.EXPORT_CANDIDATE_FEEDBACK_EXCEL}?${params}`);

export const exportDeviceExcel = (params) =>
  axios.get(`${API.EXPORT_DEVICE_DETAILS}?${params}`);

export const getEmployeeCtc = (params) =>
  axios({
    method: "get",
    url: `${API.GET_EMPLOYEE_CTC}?${params}`,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const getEmployeeMonthlySalaryStats = (params) =>
  axios({
    method: "get",
    // data: params,
    url: `${API.GET_EMPLOYEE_MONTHLY_SALARY_STATS}?${params}`,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const addEmployeeCtc = (params) =>
  axios({
    method: "post",
    data: params,
    url: API.ADD_EMPLOYEE_CTC,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const editEmployeeCtc = (params) =>
  axios({
    method: "put",
    data: params,
    url: API.EDIT_EMPLOYEE_CTC,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const exportEmployeesSalary = (params) =>
  axios({
    method: "post",
    data: params,
    url: API.EXPORT_EMPLOYEES_SALARY,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const exportEmployeesReimbursement = (params) =>
  axios({
    method: "post",
    data: params,
    url: API.EXPORT_EMPLOYEES_REIMBURSEMENT,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const getEmployeeMonthlyReimbursementStats = (params) =>
  axios({
    method: "get",
    // data: params,
    url: `${API.GET_EMPLOYEES_MONTHLY_REIMBURSEMENT_STATS}?${params}`,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const getProjectMembersProjectDuration = (params) =>
  axios.get(`${API.GET_PROJECT_MEMBERS_PROJECT_WORKING_DURATION}?${params}`);

export const getProjectActivityByEmployeeId = (params) =>
  axios.get(`${API.GET_PROJECT_ACTIVITY_BY_EMPLOYEE_ID}?${params}`);

export const getDataForTemplates = (params) =>
  axios.get(`${API.GET_DATA_FOR_TEMPLATES}?${params}`);

export const getAwardList = (params) => axios.get(`${API.GET_AWARD}?${params}`);

export const addAward = (params) => axios.post(API.ADD_AWARD, params);

export const editAward = (params) => axios.put(API.EDIT_AWARD, params);

export const deleteAward = (params) =>
  axios.delete(`${API.DELETE_AWARD}?awardId=${params}`);
export const getTeamCompareData = (params) =>
  axios.get(`${API.GET_TEAM_COMPARE_DATA}?${params}`);

export const getTlAndAtl = (params) =>
  axios.get(`${API.GET_TL_AND_ATL}/?${params}`);

export const getEmployeeOnHold = (params) =>
  axios({
    method: "get",
    // data: params,
    url: `${API.GET_EMPLOYEE_ON_HOLD}?${params}`,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

// axios.get(`${API.GET_EMPLOYEE_ON_HOLD}?${params}`);

export const addEmployeeOnHold = (params) =>
  axios({
    method: "post",
    data: params,
    url: API.ADD_EMPLOYEE_ON_HOLD,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const editEmployeeOnHold = (params) =>
  axios({
    method: "put",
    data: params,
    url: API.EDIT_EMPLOYEE_ON_HOLD,
    headers: {
      "Content-Type": "application/json",
      salaryToken: localStorage.getItem("salaryToken"),
    },
  });

export const getOtp = (param) => axios.post(API.GET_OTP, param);

export const validateOtp = (param) => axios.post(API.VALIDATE_OTP, param);

export const editOnHoldReleaseDate = (params) => {
  const salaryToken = localStorage.getItem("salaryToken");
  const headers = salaryToken
    ? {
        accessToken: accessToken,
        salaryToken: salaryToken,
      }
    : {
        accessToken: accessToken,
      };
  return axios({
    method: "put",
    url: API.EDIT_RELEASE_DATE,
    headers: headers,
    data: params,
  });
};

export const getSecretKey = () => {
  const accessToken = localStorage.getItem("accessToken");
  const salaryToken = localStorage.getItem("salaryToken");
  const headers = salaryToken
    ? {
        accessToken: accessToken,
        salaryToken: salaryToken,
      }
    : {
        accessToken: accessToken,
      };
  return axios({
    method: "get",
    url: API.GET_SECRET_KEY,
    headers: headers,
  });
};

export const getAwardNameList = (params) =>
  axios.get(`${API.GET_AWARD_NAME}?${params}`);

export const addAwardName = (params) => axios.post(API.ADD_AWARD_NAME, params);

export const deleteAwardName = (params) =>
  axios.delete(`${API.DELETE_AWARD_NAME}?awardNameId=${params}`);

export const addUnderRepairingDevice = (params) =>
  axios.post(API.ADD_UNDER_REPAIR_DEVICE, params);

export const editUnderRepairingDevice = (params) =>
  axios.put(API.EDIT_UNDER_REPAIR_DEVICE, params);

export const getCourierListByAdmin = (params) =>
  axios.get(`${API.GET_COURIER_LIST_BY_ADMIN}/?${params}`);

export const addCourierDetailsByAdmin = (params) =>
  axios.post(API.ADD_COURIER_DETAILS_BY_ADMIN, params);

export const editCourierDetailsByAdmin = (params, courierId) =>
  axios.put(
    `${API.EDIT_COURIER_DETAILS_BY_ADMIN}/?courierId=${courierId}`,
    params
  );

export const deleteCourierDetailsByAdmin = (params) =>
  axios.delete(
    `${API.DELETE_COURIER_DETAILS_BY_ADMIN}/?courierId=${params.courierId}`
  );
export const addEmployeeDataInOrganization = (params) =>
  axios.post(API.ADD_EMP_IN_ORG, params);

export const editEmployeeDataInOrganization = (params) =>
  axios.put(API.EDIT_EMP_IN_ORG, params);

export const updateEmployeeInOrganization = (params) =>
  axios.put(API.UPDATE_EMP_IN_ORG, params);

export const deleteEmployeeDataInOrganization = (params) =>
  axios.delete(API.DELETE_EMP_IN_ORG, params);

export const getEmployeeDataInOrganization = (params) =>
  axios.get(
    `${API.GET_EMP_IN_ORG}/?searchYear=${params.searchYear}&searchMonth=${params.searchMonth}`
  );

export const getPrivacyPolicy = (params) => axios.get(API.GET_POLICIES, params);
export const addPrivacyPolicy = (params) =>
  axios.post(API.ADD_POLICIES, params);
export const editPrivacyPolicy = (params) =>
  axios.put(API.EDIT_POLICIES, params);
export const deletePrivacyPolicy = (params) =>
  axios.delete(`${API.DELETE_POLICIES}/?privacyId=${params.privacyId}`);
export const uploadFilesInvestment = (params) =>
  axios.post(API.UPLOAD_FILES, params);

import { getTeamMembers } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
  GET_TEAM_MEMBERS_REQUEST,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
} from "./actionType";

const getTeamMembersByAdminAction = (props) => {
  let { useDispatch, params } = props;
  return (dispatch) => {
    dispatch(getTeamMembersByAdminRequest());
    getTeamMembers(params)
      .then((res) => {
        let { status } = ResponseFilter(res);
        if (status) {
          let teamHead = [];
          teamHead.push(res.data.responseData?.teamHead?.teamHead);
          useDispatch({
            type: "typeCheckSet",
            payload: {
              loader: false,
              teamMembersList: res.data.responseData?.teamDetails?.result,
              dataCount: res.data.responseData?.teamDetails?.totalCount,
              attendanceData: res.data.responseData?.attendanceData,
              avgHours: res.data.responseData?.avgHours,
              projectData: res.data.responseData?.projectData,
              leaveData: res.data.responseData?.leaveData,
              totalAttendanceCount: res.data.responseData?.totalAttendanceCount,
              leaveCount: res.data.responseData?.leaveCount,
              attendanceList: res.data.responseData?.attendanceList?.result,
              attendanceListCount:
                res.data.responseData?.attendanceList?.totalCount,
              leaveList: res.data.responseData?.leaveList?.result,
              leaveListCount: res.data.responseData?.leaveList?.totalCount,
              teamName: res.data.responseData?.teamHead?.teamName,
              leadsArray:
                Array.isArray(res.data.responseData?.teamHead?.teamHead)
                  ? res.data.responseData?.teamHead?.teamHead
                  : teamHead,
            },
          });
        }
      })
      .catch((error) => {
        dispatch(getTeamMembersByAdminFailure(error));
        // setErrorText("Failed to add");
        // setFailResMsg(true);
      });
  };
};

const getTeamMembersByAdminRequest = () => {
  return {
    type: GET_TEAM_MEMBERS_REQUEST,
  };
};
const getTeamMembersByAdminSuccess = (data) => {
  return {
    type: GET_TEAM_MEMBERS_SUCCESS,
    payload: data,
  };
};
const getTeamMembersByAdminFailure = (error) => {
  return {
    type: GET_TEAM_MEMBERS_FAILURE,
    payload: error,
  };
};
export {
  getTeamMembersByAdminAction,
  getTeamMembersByAdminRequest,
  getTeamMembersByAdminSuccess,
  getTeamMembersByAdminFailure,
};

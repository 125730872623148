import {
  ADD_CANDIDATE_BY_ADMIN_REQUEST,
  ADD_CANDIDATE_BY_ADMIN_SUCCESS,
  ADD_CANDIDATE_BY_ADMIN_FAILURE,
} from "./actionType";

import { addCandidateByAdminService } from "../../config/services/employeeServices";
import { getInterviewList } from "./getInterviewListAction";

const addCandidateByAdmin = (data, query, setShowModal, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader) => {
  return (dispatch) => {
    dispatch(addCandidateByAdminRequest());
    addCandidateByAdminService(data)
      .then((res) => {
        if (res.data.statusCode !== 1) {
          dispatch(addCandidateByAdminFailure(res.data));
          setFailResMsg(true)
          setLoader(false)
        } else {
          dispatch(addCandidateByAdminSuccess(res.data));
          setResMsg(true)
          setTimeout(() => (
            handleCancelButtonClick(),
            setLoader(false),
            dispatch(getInterviewList(query))
          ), 3000);

        }
      })
      .catch((error) => {
        dispatch(addCandidateByAdminFailure(error));
        setFailResMsg(true);
        // setLoader(false)
      });
  };
};

const addCandidateByAdminRequest = () => {
  return {
    type: ADD_CANDIDATE_BY_ADMIN_REQUEST,
  };
};
const addCandidateByAdminSuccess = (data) => {
  return {
    type: ADD_CANDIDATE_BY_ADMIN_SUCCESS,
    payload: data,
  };
};
const addCandidateByAdminFailure = (error) => {
  return {
    type: ADD_CANDIDATE_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  addCandidateByAdmin,
  addCandidateByAdminRequest,
  addCandidateByAdminSuccess,
  addCandidateByAdminFailure,
};

import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import ResponseFilter from '../../ResponseFilter';
import { Loader, PdfIcon } from '../svg';
import { getEmployeeDeviceList, addDeviceRequest } from '../../config/services/employeeServices';
import DeviceFullScreenImage from './DeviceFullScreenImage';
import { getFileNameFromUrl } from '../../config/constants/Constants';


class EmployeeDeviceListing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openAddDevicePopup: false,
            openDeleteDevicePopup: false,
            openEditDevicePopup: false,
            openDeviceInfoPopup: false,
            resDeleteMsg: false,
            resEditMsg: false,
            resRequestMsg: false,
            resFailMsg: false,
            deviceList: [],
            deviceDetails: [],
            assignedTo: '',
            employeeList: [],
            employeeId: '',
            deviceType: '',
            operatingSystem: '',
            brand: '',
            imei: '',
            acessories: [],
            ram: '',
            hdd: '',
            dateAdded: '',
            status: '',
            brandErrorMsg: '',
            ramErrorMsg: '',
            hddErrorMsg: '',
            dateErrorMsg: '',
            deviceTypeErrorMsg: '',
            deviceStatusErrorMsg: "",
            deviceStatus: "",
            devIdErrorMsg: '',
            deviceModel: '',
            password: '',
            editDivceObj: '',
            deviceId: '',
            totalDevices: '',
            phones: '',
            desktops: '',
            laptops: '',
            deleteDevice: '',
            firstName: '',
            lastName: '',
            devId: '',
            search: '',
            loader: true,
            sortType: 2,
            sortField: "created",
            pageNo: 1,
            count: 50,
            isAdmin: "",
            purchasedDate: "",
            deviceStatus: "",
            assignedDate: "",
            assignedToErrorMsg: "",
            dateAssignErrorMsg: "",
            cpu: "",
            monitor: "",
            macMini: "",
            comment: "",
            uploadBillErrorMsg: "",
            uploadBill: "",
            devicePictures: [],
            comment: "",
            assignedTill: "",
            assignedFrom: "",
            deviceModalErrorMsg: '',
            search: "",
            officeLocation: 0,
            deviceOfficeLocationErrorMsg: "",
            preview: false,
            fullImage: "",
            responseMessage: "",
            deviceVersion: ""

        }
        this.debounceSearch = null
    }
    handleSearch = e => {
        this.setState({ search: e.target.value, pageNo: 1 })
        clearTimeout(this.debouncedSearch);
        this.debounceSearch = setTimeout(() => {
            this.getDevice()
        }, 2000);
    }
    handleSort = e => {
        this.setState({ loader: true });
        this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getDevice());
    }

    submitSearch = e => {
        e.preventDefault();
        this.setState({ loader: true })
        this.getDevice()
    }




    handleOpenDeviceInfoPopup = ({
        deviceModel,
        assignedTo,
        deviceType,
        operatingSystem,
        imei,
        ram,
        hdd,
        password,
        brand,
        devId,
        purchasedDate,
        devicePictures,
        comment,
        deviceVersion,
    }) => this.setState({
        openDeviceInfoPopup: !this.state.openDeviceInfoPopup,
        editDivceObj: devId,
        deviceModel,
        assignedTo,
        deviceType,
        operatingSystem,
        brand,
        imei,
        ram,
        hdd,
        password,
        devId,
        purchasedDate,
        devicePictures,
        comment,
        deviceVersion
    });



    closeModal = () => this.setState({
        openAddDevicePopup: false,
        openDeleteDevicePopup: false,
        openEditDevicePopup: false,
        openDeviceInfoPopup: false,
        deleteDevice: '',
        editDevice: '',
        deviceId: '',
        deviceModel: '',
        devId: '',
        deviceType: '',
        operatingSystem: '',
        imei: '',
        acessories: '',
        ram: '',
        hdd: '',
        password: '',
        brand: '',
        dateAdded: '',
        status: '',
        firstName: '',
        lastName: '',
        assignedTo: '',
        brandErrorMsg: '',
        ramErrorMsg: '',
        deviceTypeErrorMsg: '',
        devIdErrorMsg: '',
        hddErrorMsg: '',
        dateErrorMsg: '',
        dateAssignErrorMsg: "",
        deviceType: '',
        officeLocation: 0,
        deviceOfficeLocationErrorMsg: "",
        assignedToErrorMsg: "",
        deviceVersion: ""
    });

    deviceValue = type => {
        switch (type) {
            case 1:
                return "Laptop";
                break;

            case 2:
                return "CPU";
                break;

            case 3:
                return "Phone";
                break;

            case 4:
                return 'Mouse';
                break;

            case 5:
                return 'Connectors';
                break;

            case 6:
                return 'Ups';
                break;

            case 7:
                return 'Router';
                break;

            case 8:
                return 'Headphone';
                break;

            case 9:
                return 'wifi-Adaptor';
                break;

            case 10:
                return 'web Camera';
                break;

            case 11:
                return 'keyboard';
                break;

            case 12:
                return 'Monitor';
                break;

            case 13:
                return 'Pendrive';
                break;

            case 14:
                return 'Hard Disk';
                break;

            case 15:
                return 'RAM';
                break;

            case 16:
                return 'Mac Mini';
                break;


        }
    }
    operatingSystemType = type => {
        switch (type) {
            case 1: return 'windows';
                break;

            case 2: return 'linux';
                break;
            case 3: return 'MacOS'
                break;


            default: return 'Android';

        }
    }

    deviceLocationType = type => {
        switch (type) {
            case 1: return 'Noida';
                break;

            case 2: return 'Lucknow';
                break;

        }
    }

    deviceStatusType = (type) => {
        switch (type) {
            case 1:
                return "Occupied";
                break;

            case 2:
                return "Free";
                break;

            case 3:
                return "Under Repairing";
                break;
            case 4:
                return "Not Working";
        }
    };

    deviceType = type => {
        switch (type) {
            case 'laptop': return 1;
                break;

            case 'cpu': return 2;
                break;

            case 'phone': return 3;
                break;

            case 'mouse': return 4;
                break;

            case 'connectors': return 5;
                break;

            case 'ups': return 6;
                break;

            case 'router': return 7;
                break;

            case 'headphone': return 8;
                break;

            case 'wifiAdaptor': return 9;
                break;

            case 'webCamera': return 10;
                break;

            case 'keyboard': return 11;
                break;

            case 'monitor': return 12;
                break;

            case 'pendrive': return 13;
                break;

            case 'hardDisk': return 14;
                break;

            case 'ram1': return 15;
                break;

            case 'macMini': return 16;
                break;
        }
    }


    getDevice = () => {

        let { search, status, sortType, sortField, pageNo, count } = this.state
        let params = `search=${search.trim()}&status=${status}&count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&deviceType=${this.deviceType(this.props.match.params.deviceType)}`;


        this.props.history.push(`?search=${search}&status=${status}&count=${count}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&deviceType=${this.deviceType(this.props.match.params.deviceType)}`);

        getEmployeeDeviceList(params).then(res => {

            let { status, resData } = ResponseFilter(res);


            if (status) {
                this.setState({
                    deviceList: resData.deviceList,
                    dataCount: resData.dataCount,
                }, () => this.setState({ loader: false }, () => this.closeModal()))
            }
            else {
                this.setState({ loader: false })
                this.closeModal();
            }
        })
    }

    // componentDidMount() {
    //     this.getDevice();
    // }



    componentDidMount() {

        let url_string = window.location.href
        let url = new URL(url_string);
        let pageNo = url.searchParams.get("pageNo");
        let search = url.searchParams.get("search");
        let sortType = url.searchParams.get("sortType");
        let sortField = url.searchParams.get("sortField");
        this.setState({
            pageNo: pageNo ? +(pageNo) : 1,
            search: search ? search : "",
            sortType: sortType ? sortType : 2,
            sortField: sortField ? sortField : "created",
        }, () => {

            this.getDevice();
        })

    }

    handleEmployeeSearch = (e) => {
        console.log("e : ", e);
    }
    handlePageChange = (pageNo) => {

        this.setState({ pageNo: pageNo, loader: true }, () => this.getDevice());
    }

    handleDeviceRequest = (devId) => {
        // event.preventDefault()
        let obj = {
            deviceUniqueId: devId,
        }
        addDeviceRequest(obj).then(res => {
            if (res.data.statusCode === 1) {
                this.setState({

                }, () => this.getDevice())
                this.setState({ resRequestMsg: true })
            }
            else {
                this.setState({ resFailMsg: true, responseMessage: res?.data?.error?.responseMessage })
            }
        })
        setTimeout(() => this.setState({ resRequestMsg: false }), 4000)
        setTimeout(() => this.setState({ resFailMsg: false }), 4000)
    }
    handleSelectChange = () => {
        this.setState({ search: "" }, () => this.getDevice());
    };

    handleFullImage = (e) => {
        this.setState({
            preview: true,
            fullImage: e
        })
    }
    closeFullimage = () => {
        this.setState({
            preview: false
        })
    }
    render() {
        let {
            openDeviceInfoPopup,
            deviceList,
            brand,
            ram,
            hdd,
            password,
            devId,
            imei,
            deviceModel,
            deviceType,
            operatingSystem,
            loader,
            pageNo,
            count,
            dataCount,
            resRequestMsg,
            resFailMsg,
            purchasedDate,
            comment,
            devicePictures,
            search,
            fullImage,
            preview,
            responseMessage,
            deviceVersion,
        } = this.state;


        return (

            <div className="body_container cm_bold">
                {
                    loader && <Loader />
                }
                {
                    resRequestMsg ?
                        <div className="alert alert-success cm_top_fix"> Device Requested Successfully </div> : ''
                }
                {
                    resFailMsg ?
                        <div className="alert alert-danger cm_top_fix">{responseMessage}</div> : ''
                }

                <div className="cm_breadcrumb">
                    <ul>
                        <li><Link to='/employee-device'>DEVICE MANAGEMENT</Link><div className="cm_breadcrumb_arrow"></div></li>

                        <li>DEVICE LIST</li>

                    </ul>
                </div>

                {/* {device list module start} */}


                <div className="row d-flex justify-content-end mb-4 ">

                    {/* <h3>DEVICE MANAGEMENT</h3> */}

                    <div className="col-md-6 search_device_bar select__date" style={{ marginTop: 10, }}>
                        <input
                            className="form-control mr-lg-2 cm_box"
                            type="search"
                            placeholder="Search by Device Id"
                            aria-label="Search"
                            maxLength={40}
                            value={search}
                            onChange={this.handleSearch}
                            onKeyDown={(e) => { if (e.key === 'Enter') this.getDevice() }}
                        />
                        {search ? (
                            <span className="cm_clear" onClick={this.handleSelectChange}>
                                <i className="fa fa-times-circle" aria-hidden="true"></i>
                            </span>
                        ) : (
                            <span className="cm_clear">
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </span>
                        )}
                    </div>
                </div>
                <h3 className="mb-4 mt-4 cm_bold">Device Listing</h3>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="white bg_blue">
                            <tr>
                                <th scope="col">S.No</th>

                                <th scope="col">Device Type</th>
                                <th scope="col">Device Modal</th>
                                <th scope="col">Device ID</th>
                                <th scope="col">RAM</th>
                                <th scope="col">Status</th>
                                <th scope="col">Device Brand</th>
                                <th scope="col">Device Location</th>

                                <th scope="col">Purchased Date<i
                                    className={this.state.sortType === 1 && this.state.sortField === "dateAdded" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                                    onClick={() => this.handleSort('purchasedDate')}></i></th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                !_.isEmpty(deviceList) && deviceList.map((device, i) => (
                                    <tr key={device.deviceId}>
                                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                        <td>{this.deviceValue(device.deviceType)}</td>
                                        <td><span className="cm_col">
                                            {device.deviceModel ? device.deviceModel : "_"}
                                        </span></td>
                                        <td className="blue" onClick={() => this.handleOpenDeviceInfoPopup(device)}><span className="cm_col">{device.devId}</span></td>
                                        <td><span className="cm_col">{device.ram}</span></td>
                                        <td><span className="cm_col">{device.status ? this.deviceStatusType(device.status) : '_'}</span></td>
                                        <td><span className="cm_col">{device.brand}</span></td>
                                        <td><span className="cm_col">{this.deviceLocationType(device.officeLocation)}</span></td>
                                        <td>{moment.unix(device.purchasedDate / 1000).format("DD/MM/YYYY")}</td>
                                        <td>
                                            <div className="cm_device">
                                                {device.status == 2 ?
                                                    <Link onClick={() => this.handleDeviceRequest(device.deviceId)}>Request</Link> : ""}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {deviceList.length ? '' : <h3 className="text-center">No data available</h3>}
                </div>
                {dataCount > 50 ? <Pagination
                    activePage={pageNo}
                    itemsCountPerPage={count}
                    totalItemsCount={dataCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                />
                    : ''}

                {/* {device list module end} */}

                {/* {device detail module start} */}
                <Modal
                    className="cm_deviceInfo"
                    size="md"
                    show={openDeviceInfoPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="assign_device">

                            <h3 className="mb-5">DEVICE DETAIL</h3>
                            <div className="cm_overflow_device">
                                <div className="row cm_row">
                                    <div className="col-6 cm_bold col1">Device ID</div>
                                    <div className="col-6 cm_bold col2">{devId}</div>
                                    <div className="col-6 cm_bold col1">Device Type</div>
                                    <div className="col-6 cm_bold col2">{this.deviceValue(deviceType)}</div>
                                    <div className="col-6 cm_bold col1">Device Model</div>
                                    <div className="col-6 cm_bold col2">{deviceModel ? deviceModel : '-'}</div>
                                    <div className="col-6 cm_bold col1">Device Version</div>

                                    <div className="col-6 cm_bold col2">{deviceVersion ? deviceVersion : '-'}</div>
                                    <div className="col-6 cm_bold col1">Brand</div>
                                    <div className="col-6 cm_bold col2">{brand}</div>
                                    <div className="col-6 cm_bold col1">Service Tag(IMEI)</div>
                                    <div className="col-6 cm_bold col2">{imei ? imei : '-'}</div>
                                    <div className="col-6 cm_bold col1">RAM</div>
                                    <div className="col-6 cm_bold col2">{ram}</div>
                                    <div className="col-6 cm_bold col1">HDD/SDD</div>
                                    <div className="col-6 cm_bold col2">{hdd}</div>
                                    <div className="col-6 cm_bold col1">Operating System</div>
                                    <div className="col-6 cm_bold col2">{operatingSystem ? this.operatingSystemType(operatingSystem) : '-'}</div>
                                    <div className="col-6 cm_bold col1">Password</div>
                                    <div className="col-6 cm_bold col2">{password ? password : '-'}</div>
                                    <div className="col-6 cm_bold col1">Purchased Date</div>
                                    <div className="col-6 cm_bold col2">{purchasedDate ? moment.unix(purchasedDate / 1000).format("DD/MM/YYYY") : "-"}</div>
                                    <div className="col-6 cm_bold col1">Comment</div>
                                    <div className="col-6 cm_bold col2">{comment ? comment : "-"}</div>
                                    {/* <div className="col-6 cm_bold col1">Device Pictures</div>
                                    <div className="customer-content-ul ">
                                        {devicePictures ? devicePictures.map((devicePictures) =>
                                            <img src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>

                                        ) : ""}</div> */}


                                    <div className="col-6 cm_bold col1">Device Pictures</div>
                                    <div className="container">
                                        <div className="customer-content-ul image-container" >
                                            {/* {devicePictures ? devicePictures.map((devicePictures) =>
                        // <a href={devicePictures} target="_blank">
                        <img className="image" src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>
                        // </a>

                      ) : ""} */}


                                            {devicePictures ? devicePictures.map((devicePictures) => (
                                                devicePictures.includes(".pdf") == false ?
                                                    <div
                                                        className="date-border"
                                                        style={{
                                                            borderRadius: "8px",
                                                            borderWidth: "1px",
                                                            padding: "10px",
                                                            overflow: "auto",
                                                            minHeight: "100px",
                                                            maxHeight: "500px",
                                                            marginBottom: "10px"
                                                        }}
                                                        onClick={() => this.handleFullImage(devicePictures)}
                                                    >

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                backgroundColor: "#edfbff",
                                                                borderRadius: "8px",
                                                                padding: "10px",
                                                                height: "3.5rem",
                                                                margin: "5px",
                                                            }}
                                                        >
                                                            <img
                                                                src={devicePictures}
                                                                className="image-size mr-2"
                                                            />
                                                            <label style={{ width: "80%" }}>
                                                                {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div onClick={() => this.handleFullImage(devicePictures)} >
                                                        <>
                                                            <div
                                                                className="date-border"
                                                                style={{
                                                                    borderRadius: "8px",
                                                                    borderWidth: "1px",
                                                                    padding: "10px",
                                                                    overflow: "auto",
                                                                    minHeight: "100px",
                                                                    maxHeight: "500px",
                                                                    marginBottom: "10px"

                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        backgroundColor: "#edfbff",
                                                                        borderRadius: "8px",
                                                                        padding: "10px",
                                                                        height: "3.5rem",
                                                                        margin: "5px",
                                                                    }}
                                                                >
                                                                    <div className="image-size"><PdfIcon /></div>
                                                                    <label style={{ width: "80%" }}>
                                                                        {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                            )

                                            ) : ""}

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </form>
                    </Modal.Body>
                </Modal>
                {/* {device detail module end} */}
                {/* full screen Image */}
                <DeviceFullScreenImage
                    preview={preview}
                    fullImage={fullImage}
                    closeModal={this.closeFullimage}
                />
            </div>

        );
    }
}
export default EmployeeDeviceListing;

import React, { useEffect, useRef } from "react";
import "../investmentForm/investmentForm.css";
import { useParams } from "react-router-dom";
import {
  getInvestmentDetailsEmp,
  getAdminProfile,
} from "../../config/services/employeeServices";
import { useHistory } from "react-router-dom";
import UploadRentModal from "./InvestmentModal/UploadRentModal";
import CreateInvestmentTable from "./InvestmentFormUtils/CreateInvestmentTable";
import CreateInvestmentData from "./InvestmentFormUtils/CreateInvestmentData";
import UploadDeduction from "./InvestmentModal/UploadDeduction";
import {
  handleOpenUpload,
  handleUploadFiles,
} from "./InvestmentFormUtils/InvestmentFormConstants";
import { Loader } from "../svg";
import ViewUploadedDocument from "../helper/ViewUploadedDocument";
import { Toastify } from "../helper/Constant";

const CreateInvestment = (props) => {
  const history = useHistory();
  let { id } = useParams();
  const [state, setState] = React.useState({
    employeeCode: "",
    employeeCodeErr: "",
    year1: "",
    employeeName: "",
    employeeNameErr: "",
    employeeDesignation: "",
    employeeDesignationErr: "",
    employeePAN: "",
    employeePANErr: "",
    employeeDOB: "",
    employeeDOBErr: "",
    gender: "",
    genderErr: "",
    scheme: "",
    schemeErr: "",
    metro: "",
    metroErr: "",
    houseRentAmount: "",
    deductionAmount_1: "",
    deductionAmount_2: "",
    deductionAmount_3: "",
    cd_Amount18: "",
    cd_Amount19: "",
    cd_Amount20: "",
    cd_Amount21: "",
    produceBilllAmount: "",
    produceBillChecked_1: "",
    produceBillChecked_2: "",
    claimCheckedYes18: "",
    claimCheckedYes19: "",
    claimCheckedYes20: "",
    lifeInsurancePolicy: "",
    lifeInsuranceAmount: "",
    pf_Amount: "",
    pf_Policy: "",
    ppf_Policy: "",
    ppf_Amount: "",
    vpf_Policy: "",
    vpf_Amount: "",
    nsc_Policy: "",
    nsc_Amount: "",
    reInvested_Policy: "",
    reInvested_Amount: "",
    ulip_Policy: "",
    ulip_Amount: "",
    elss_Policy: "",
    elss_Amount: "",
    tuition_Policy: "",
    tuition_Amount: "",
    housing_Loan_Policy: "",
    housing_Loan_Amount: "",
    stamp_Duty_Policy: "",
    stamp_Duty_Amount: "",
    infrastructure_Bonds_Policy: "",
    infrastructure_Bonds_Amount: "",
    bank_Fixed_Policy: "",
    bank_Fixed_Amount: "",
    postOffice_Policy: "",
    postOffice_Amount: "",
    savingsScheme_Policy: "",
    savingsScheme_Amount: "",
    accountScheme_Policy: "",
    accountScheme_Amount: "",
    others_Policy: "",
    others_Amount: "",
    d80CCC_Amount: "",
    d80CCD_1_Amount: "",
    d80CCD_1B_Amount: "",
    medicalInsuranceChildren_Amount: "",
    medicalInsuranceParent_Amount: "",
    preventive_Health_Amount: "",
    interest_Amount: "",
    d80TTA_Amount: "",
    specify_policy: "",
    other_specify_policy: "",
    specify_amount: "",
    employeeDOB2: "",
    claimCheckedYes21: "",
    incomeExemptions: "",
    providentFund: "",
    professionalTax: "",
    taxDeductedSource: "",
    employeeData: "",
    declaration: 1,
    uID: "",
    uploadFile: "",
    uploadFileErr: "",
    uploadFileName: "",
    uploadFileSize: "",
    filePreview: "",
    openUploadFilesModal: false,
    uploadFileKeyName: "",
    rentAgreement: null,
    landlordPanCard: null,
    deductionAmountFile: "",
    deductionAmountFile1: null,
    deductionAmountFile2: null,
    deductionAmountFile3: null,
    deductionIntraCertificate1: null,
    deductionIntraCertificate2: null,
    deductionIntraCertificate: null,
    LIC: null,
    PPF: null,
    VPF: null,
    NSC: null,
    others: null,
    mutual_fund: null,
    tutionFee: null,
    housingLoan: null,
    stampDutyPolicy: null,
    bankFixedPolicy: null,
    postOffice: null,
    SSAS: null,
    pensionFund: null,
    pensionScheme: null,
    nationalPensionScheme: null,
    pensionFromInsurance: null,
    medicalInsurance: null,
    medicalInsuranceParents: null,
    interestForEducation: null,
    deductionOthers: null,
    uploadRentFileErr: "",
    deductionText: "",
    houseRentAmountErr: "",
    deductionAmountFile1Err: "",
    deductionAmountFile2Err: "",
    deductionAmountFile3Err: "",
    loader: false,
    data: "",
    openDownload: false,
    link: [],
    hideIcon: false,
    resMsg: false,
    resFailMsg: false,
    successMessage: "",
    responseMessage: "",
    deduction_eligibilty: "",
    policyNameErrMsg: "",
    pfErrMsg: "",
    otherSpecifyPolicyErrMsg: "",
    PPFErrMsg: "",
    VPFErrMsg: "",
    NSCErrMsg: "",
    stampDutyPolicyErrMsg: "",
    bankFixedPolicyErrMsg: "",
    pensionSchemeErrMsg: "",
    nationalPensionSchemeErrMsg: "",
    pensionFromInsuranceErrMsg: "",
    medicalInsuranceErrMsg: "",
    medicalInsuranceParentsErrMsg: "",
    deductionOthersErrMsg: "",
    interestForEducationErrMsg: "",
  });
  const {
    uploadFileErr,
    openUploadFilesModal,
    uploadFileKeyName,
    deductionAmountFile,
    deductionText,
    loader,
    data,
    link,
    openDownload,
    hideIcon,
    resMsg,
    resFailMsg,
    successMessage,
    responseMessage,
  } = state;

  useEffect(() => {
    if (id.length == 9) {
      getAdminProfile().then((res) => {
        let employeefirstName = res.data.responseData.employeeProfile.firstName;
        let employeelastName = res.data.responseData.employeeProfile.lastName;
        let fullName = `${employeefirstName} ${employeelastName}`;
        let empCode = res.data.responseData.employeeProfile.empId;
        let empDesigntion =
          res.data.responseData.designationDetails[0].designationName;
        let empG = res.data.responseData.employeeProfile.gender;
        var timestamp = res.data.responseData.employeeProfile.dob;
        var date = new Date(timestamp);
        var finaldate =
          date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
        var investmentYear = id;

        setState({
          employeeName: fullName,
          employeeCode: empCode,
          employeeDesignation: empDesigntion,
          gender: empG,
          employeeDOB: finaldate,
          year1: investmentYear,
          // employeePAN: res.data.responseData.investment.employee_Pan,
        });
      });
    }
  }, []);
  useEffect(() => {
    let param = `investmentId=${id}`;
    if (id.length > 9) {
      getInvestmentDetailsEmp(param).then((res) => {
        var timestamp = res.data.responseData.investment.employee_Dob;
        var date = new Date(timestamp);
        var finaldate =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();
        let jsonData = res.data.responseData.investment.info;
        var parsedData = JSON.parse(jsonData);
        let genderInfo = res.data.responseData.investment.gender;
        let schemeinfo = res.data.responseData.investment.scheme;
        let uniqueId = res.data.responseData.investment.employeeId;
        setState({
          employeeCode: res.data.responseData.investment.employee_Code,
          employeeName: res.data.responseData.investment.employee_Name,
          employeePAN: res.data.responseData.investment.employee_Pan,
          uploadFile: parsedData.uploadFile,
          year1: parsedData.year1,
          data: parsedData,
          employeeDesignation:
            res.data.responseData.investment.employee_Designation,
          employeeDOB2: finaldate,
          houseRentAmount: parsedData.houseRentAmount,
          accountScheme_Amount: parsedData.accountScheme_Amount,
          bank_Fixed_Amount: parsedData.bank_Fixed_Amount,
          cd_Amount18: parsedData.cd_Amount18,
          cd_Amount19: parsedData.cd_Amount19,
          cd_Amount20: parsedData.cd_Amount20,
          cd_Amount21: parsedData.cd_Amount21,
          d80CCC_Amount: parsedData.d80CCC_Amount,
          d80CCD_1B_Amount: parsedData.d80CCD_1B_Amount,
          d80CCD_1_Amount: parsedData.d80CCD_1_Amount,
          d80TTA_Amount: parsedData.d80TTA_Amount,
          deductionAmount_1: parsedData.deductionAmount_1,
          deductionAmount_2: parsedData.deductionAmount_2,
          deductionAmount_3: parsedData.deductionAmount_3,
          housing_Loan_Amount: parsedData.housing_Loan_Amount,
          interest_Amount: parsedData.interest_Amount,
          lifeInsuranceAmount: parsedData.lifeInsuranceAmount,
          elss_Policy: parsedData.elss_Policy,
          medicalInsuranceChildren_Amount:
            parsedData.medicalInsuranceChildren_Amount,
          medicalInsuranceParent_Amount:
            parsedData.medicalInsuranceParent_Amount,
          nsc_Amount: parsedData.nsc_Amount,
          others_Amount: parsedData.others_Amount,
          pf_Amount: parsedData.pf_Amount,
          postOffice_Amount: parsedData.postOffice_Amount,
          ppf_Amount: parsedData.ppf_Amount,
          preventive_Health_Amount: parsedData.preventive_Health_Amount,
          produceBilllAmount: parsedData.produceBilllAmount,
          reInvested_Amount: parsedData.reInvested_Amount,
          savingsScheme_Amount: parsedData.savingsScheme_Amount,
          specify_amount: parsedData.specify_amount,
          stamp_Duty_Amount: parsedData.stamp_Duty_Amount,
          tuition_Amount: parsedData.tuition_Amount,
          ulip_Amount: parsedData.ulip_Amount,
          vpf_Amount: parsedData.vpf_Amount,
          accountScheme_Policy: parsedData.accountScheme_Policy,
          bank_Fixed_Policy: parsedData.bank_Fixed_Policy,
          housing_Loan_Policy: parsedData.housing_Loan_Policy,
          lifeInsurancePolicy: parsedData.lifeInsurancePolicy,
          nsc_Policy: parsedData.nsc_Policy,
          others_Policy: parsedData.others_Policy,
          pf_Policy: parsedData.pf_Policy,
          postOffice_Policy: parsedData.postOffice_Policy,
          ppf_Policy: parsedData.ppf_Policy,
          reInvested_Policy: parsedData.reInvested_Policy,
          savingsScheme_Policy: parsedData.savingsScheme_Policy,
          specify_policy: parsedData.specify_policy,
          other_specify_policy: parsedData.other_specify_policy,
          stamp_Duty_Policy: parsedData.stamp_Duty_Policy,
          tuition_Policy: parsedData.tuition_Policy,
          ulip_Policy: parsedData.ulip_Policy,
          vpf_Policy: parsedData.vpf_Policy,
          gender: genderInfo,
          scheme: schemeinfo,
          metro: parsedData.metro,
          deduction_eligibilty: parsedData.deduction_eligibilty,
          claimCheckedYes18: parsedData.claimCheckedYes18,
          claimCheckedYes19: parsedData.claimCheckedYes19,
          claimCheckedYes20: parsedData.claimCheckedYes20,
          claimCheckedYes21: parsedData.claimCheckedYes21,
          produceBillChecked_1: parsedData.produceBillChecked_1,
          incomeExemptions: parsedData.incomeExemptions,
          providentFund: parsedData.providentFund,
          professionalTax: parsedData.professionalTax,
          taxDeductedSource: parsedData.taxDeductedSource,
          uID: uniqueId,
          elss_Amount: parsedData.elss_Amount,
          infrastructure_Bonds_Amount: parsedData.infrastructure_Bonds_Amount,
          rentAgreement: parsedData.rentAgreement,
          landlordPanCard: parsedData.landlordPanCard,
          deductionAmountFile1: parsedData.deductionAmountFile1,
          deductionAmountFile2: parsedData.deductionAmountFile2,
          deductionAmountFile3: parsedData.deductionAmountFile3,
          LIC: parsedData.LIC,
          PPF: parsedData.PPF,
          VPF: parsedData.VPF,
          NSC: parsedData.NSC,
          others: parsedData.others,
          mutual_fund: parsedData.mutual_fund,
          tutionFee: parsedData.tutionFee,
          housingLoan: parsedData.housingLoan,
          stampDutyPolicy: parsedData.stampDutyPolicy,
          bankFixedPolicy: parsedData.bankFixedPolicy,
          postOffice: parsedData.postOffice,
          SSAS: parsedData.SSAS,
          pensionFund: parsedData.pensionFund,
          pensionScheme: parsedData.pensionScheme,
          nationalPensionScheme: parsedData.nationalPensionScheme,
          pensionFromInsurance: parsedData.pensionFromInsurance,
          medicalInsurance: parsedData.medicalInsurance,
          medicalInsuranceParents: parsedData.medicalInsuranceParents,
          interestForEducation: parsedData.interestForEducation,
          deductionOthers: parsedData.deductionOthers,
        });
      });
    }
  }, []);
  const handleGender = (e) => {
    setState({ ...state, gender: e });
  };

  const handleTick = (e) => {
    setState({
      ...state,
      scheme: e,
    });
  };
  const handleMetro = (e) => {
    setState({
      ...state,
      metro: e,
      metroErr: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const handleInputChange = (newValue) => {
      setState((prevState) => ({
        ...prevState,
        [name]: newValue,
        employeeNameErr: "",
        employeeCodeErr: "",
        employeeDOBErr: "",
        employeeDesignationErr: "",
        employeePANErr: "",
        genderErr: "",
        schemeErr: "",
        houseRentAmountErr:
          name === "houseRentAmount" && newValue.length > 0
            ? ""
            : prevState.houseRentAmountErr,
        deductionAmountFile1Err:
          name === "deductionAmount_1" && newValue.length > 0
            ? ""
            : prevState.deductionAmountFile1Err,
        deductionAmountFile2Err:
          name === "deductionAmount_2" && newValue.length > 0
            ? ""
            : prevState.deductionAmountFile2Err,
        deductionAmountFile3Err:
          name === "deductionAmount_3" && newValue.length > 0
            ? ""
            : prevState.deductionAmountFile3Err,
        LICErrMsg:
          name === "lifeInsuranceAmount" && newValue.length > 0
            ? ""
            : prevState.LICErrMsg,
        PPFErrMsg:
          name === "ppf_Amount" && newValue.length > 0
            ? ""
            : prevState.PPFErrMsg,
        VPFErrMsg:
          name === "vpf_Amount" && newValue.length > 0
            ? ""
            : prevState.VPFErrMsg,
        NSCErrMsg:
          name === "nsc_Amount" && newValue.length > 0
            ? ""
            : prevState.NSCErrMsg,
        mutualFundErrMsg:
          name === "elss_Amount" && newValue.length > 0
            ? ""
            : prevState.mutualFundErrMsg,
        tutionFeeErrMsg:
          name === "tuition_Amount" && newValue.length > 0
            ? ""
            : prevState.tutionFeeErrMsg,
        housingLoanErrMsg:
          name === "housing_Loan_Amount" && newValue.length > 0
            ? ""
            : prevState.housingLoanErrMsg,
        stampDutyPolicyErrMsg:
          name === "stamp_Duty_Amount" && newValue.length > 0
            ? ""
            : prevState.stampDutyPolicyErrMsg,
        bankFixedPolicyErrMsg:
          name === "bank_Fixed_Amount" && newValue.length > 0
            ? ""
            : prevState.bankFixedPolicyErrMsg,
        postOfficeErrMsg:
          name === "postOffice_Amount" && newValue.length > 0
            ? ""
            : prevState.postOfficeErrMsg,
        SSASErrMsg:
          name === "accountScheme_Amount" && newValue.length > 0
            ? ""
            : prevState.SSASErrMsg,
        othersErrMsg:
          name === "others_Amount" && newValue.length > 0
            ? ""
            : prevState.othersErrMsg,
        pensionFundErrMsg:
          name === "d80CCC_Amount" && newValue.length > 0
            ? ""
            : prevState.pensionFundErrMsg,
        pensionSchemeErrMsg:
          name === "d80CCD_1_Amount" && newValue.length > 0
            ? ""
            : prevState.pensionSchemeErrMsg,
        nationalPensionSchemeErrMsg:
          name === "d80CCD_1B_Amount" && newValue.length > 0
            ? ""
            : prevState.nationalPensionSchemeErrMsg,
        pensionFromInsuranceErrMsg:
          name === "preventive_Health_Amount" && newValue.length > 0
            ? ""
            : prevState.pensionFromInsuranceErrMsg,
        medicalInsuranceErrMsg:
          name === "medicalInsuranceChildren_Amount" && newValue.length > 0
            ? ""
            : prevState.medicalInsuranceErrMsg,
        medicalInsuranceParentsErrMsg:
          name === "medicalInsuranceParent_Amount" && newValue.length > 0
            ? ""
            : prevState.medicalInsuranceParentsErrMsg,
        deductionOthersErrMsg:
          name === "specify_amount" && newValue.length > 0
            ? ""
            : prevState.deductionOthersErrMsg,
        interestForEducationErrMsg:
          name === "interest_Amount" && newValue.length > 0
            ? ""
            : prevState.interestForEducationErrMsg,
        policyNameErrMsg: "",
        otherSpecifyPolicyErrMsg: "",
        lifeInsurancePolicyErrMsg: "",
        pf_PolicyErrMsg: "",
        ppf_PolicyErrMsg: "",
        vpf_PolicyErrMsg: "",
        nsc_PolicyErrMsg: "",
        elss_PolicyErrMsg: "",
        tuition_PolicyErrMsg: "",
        housing_Loan_PolicyErrMsg: "",
        stamp_Duty_PolicyErrMsg: "",
        bank_Fixed_PolicyErrMsg: "",
        postOffice_PolicyErrMsg: "",
        accountScheme_PolicyErrMsg: "",
        others_PolicyErrMsg: "",
        specify_policyErrMsg: "",
      }));
    };
    if (type === "number") {
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        if (value.length < 16) {
          handleInputChange(value);
        } else {
          e.target.value = value.slice(0, 15);
        }
      } else if (value === "" || /^\.$/.test(value)) {
        handleInputChange(value);
      } else {
        e.target.value = "";
      }
    } else if (type === "text") {
      if (value.length < 70) {
        handleInputChange(value);
      } else {
        e.target.value = value.slice(0, 15);
      }
    } else if (value === "" || /^\.$/.test(value)) {
      handleInputChange(value);
    } else {
      e.target.value = "";
    }
  };

  const handlePrint = () => {
    setState({
      ...state,
      hideIcon: true,
    });
    setTimeout(() => {
      window.print();
      window.location.href = `/investment-form/${id}`;
    }, 500);
  };
  const handleBack = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("isadmin")) {
      history.push("/admin-investment");
    } else {
      history.push("/investment");
    }
  };

  const handleUploadFileChange = (e, fileType) => {
    const file = e.target.files[0];
    const acceptedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];
    if (file && !acceptedTypes.includes(file.type)) {
      setState((prevState) => ({
        ...prevState,
        uploadFileErr: "Only PDF, PNG, JPG files are accepted",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [fileType]: file,
        uploadFileErr: "",
        houseRentAmountErr:
          fileType === "rentAgreement" || fileType === "landlordPanCard"
            ? ""
            : prevState.houseRentAmountErr,
        deductionAmountFile1Err:
          fileType === "deductionAmountFile1"
            ? ""
            : prevState.deductionAmountFile1Err,
        deductionAmountFile2Err:
          fileType === "deductionAmountFile2"
            ? ""
            : prevState.deductionAmountFile2Err,

        deductionAmountFile3Err:
          fileType === "deductionAmountFile3"
            ? ""
            : prevState.deductionAmountFile3Err,
        LICErrMsg: fileType === "LIC" ? "" : prevState.LICErrMsg,
        PPFErrMsg: fileType === "PPF" ? "" : prevState.PPFErrMsg,
        VPFErrMsg: fileType === "VPF" ? "" : prevState.VPFErrMsg,
        NSCErrMsg: fileType === "NSC" ? "" : prevState.NSCErrMsg,
        mutualFundErrMsg:
          fileType === "mutual_fund" ? "" : prevState.mutualFundErrMsg,
        tutionFeeErrMsg:
          fileType === "tutionFee" ? "" : prevState.tutionFeeErrMsg,
        housingLoanErrMsg:
          fileType === "housingLoan" ? "" : prevState.housingLoanErrMsg,
        stampDutyPolicyErrMsg:
          fileType === "stampDutyPolicy" ? "" : prevState.stampDutyPolicyErrMsg,
        bankFixedPolicyErrMsg:
          fileType === "bankFixedPolicy" ? "" : prevState.bankFixedPolicyErrMsg,
        postOfficeErrMsg:
          fileType === "postOffice" ? "" : prevState.postOfficeErrMsg,
        SSASErrMsg: fileType === "SSAS" ? "" : prevState.SSASErrMsg,
        othersErrMsg: fileType === "others" ? "" : prevState.othersErrMsg,
        pensionFundErrMsg:
          fileType === "pensionFund" ? "" : prevState.pensionFundErrMsg,
        pensionSchemeErrMsg:
          fileType === "pensionScheme" ? "" : prevState.pensionSchemeErrMsg,
        nationalPensionSchemeErrMsg:
          fileType === "nationalPensionScheme"
            ? ""
            : prevState.nationalPensionSchemeErrMsg,
        pensionFromInsuranceErrMsg:
          fileType === "pensionFromInsurance"
            ? ""
            : prevState.pensionFromInsuranceErrMsg,
        medicalInsuranceErrMsg:
          fileType === "medicalInsurance"
            ? ""
            : prevState.medicalInsuranceErrMsg,
        medicalInsuranceParentsErrMsg:
          fileType === "medicalInsuranceParents"
            ? ""
            : prevState.medicalInsuranceParentsErrMsg,
        interestForEducationErrMsg:
          fileType === "interestForEducation"
            ? ""
            : prevState.interestForEducationErrMsg,
        deductionOthersErrMsg:
          fileType === "deductionOthers" ? "" : prevState.deductionOthersErrMsg,
      }));
    }
  };
  const closeModal = () => {
    setState({
      ...state,
      openUploadFilesModal: false,
      uploadFileErr: "",
      deductionAmountFile: "",
      openDownload: false,
    });
  };
  const handleOpenDownload = ({ link }) => {
    setState({
      ...state,
      openDownload: true,
      link: link,
    });
  };
  return (
    <div className="body_container cm_bold print-container">
      {loader && <Loader />}
      <Toastify
        resMsg={resMsg}
        resMsgFail={resFailMsg}
        text={successMessage}
        responseMessage={responseMessage}
      />
      {uploadFileKeyName === "houseRent" && (
        <UploadRentModal
          openUploadModal={openUploadFilesModal}
          closeModal={closeModal}
          uploadFileKeyName={uploadFileKeyName}
          handleUploadFileChange={handleUploadFileChange}
          uploadFileErr={uploadFileErr}
          handleUploadFiles={handleUploadFiles}
          state={state}
          setState={setState}
        />
      )}
      {(uploadFileKeyName === "housingLoan" ||
        uploadFileKeyName === "proof") && (
        <UploadDeduction
          openUploadModal={openUploadFilesModal}
          closeModal={closeModal}
          uploadFileKeyName={uploadFileKeyName}
          handleUploadFileChange={handleUploadFileChange}
          uploadFileErr={uploadFileErr}
          handleUploadFiles={handleUploadFiles}
          state={state}
          setState={setState}
          deductionText={deductionText}
          deductionAmountFile={deductionAmountFile}
        />
      )}
      <button type="button" class="btn btn-outline-dark" onClick={handleBack}>
        Back
      </button>
      <h3 className="text-center">Investment Form</h3>
      <CreateInvestmentTable
        handleChange={handleChange}
        state={state}
        handleGender={handleGender}
        id={id}
      />
      <CreateInvestmentData
        id={id}
        handleChange={handleChange}
        state={state}
        handlePrint={handlePrint}
        history={history}
        handleTick={handleTick}
        handleMetro={handleMetro}
        handleOpenUpload={handleOpenUpload}
        setState={setState}
        data={data}
        hideIcon={hideIcon}
        handleOpenDownload={handleOpenDownload}
      />
      <ViewUploadedDocument
        show={openDownload}
        data={link}
        handleClose={closeModal}
      />
    </div>
  );
};
export default CreateInvestment;

import { Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
const { Option } = Select;

export default function AddAwardName(props) {
    return (
        <Modal centered show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title><b>Add Award Name </b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className={`assign_device ${props.miniLoader ? "admin_attendance" : ""}`}>
                    <div className='row'>
                        <div className={`form-group col-12 ${props.awardNameTypeErrorMessage ? "input_error" : ""
                            }`}>
                            <label className="cm_bold">Award Name</label>
                            <input
                                maxLength="50"
                                className="form-control  cm_box"
                                type="text"
                                placeholder="Award Name"
                                name="awardNameType"
                                value={props?.awardNameType ? props?.awardNameType : ""}
                                onChange={props.handleChange}
                            />

                            {props.awardNameTypeErrorMessage ? (
                                <span className="error_msg">{props.awardNameTypeErrorMessage}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="submit_button_margin mt-4">
                        {props.miniLoader ?
                            <div className="cover-spin1"></div>
                            : ''}
                        <button
                            type="button"
                            onClick={props.handleSubmit}
                            className="btn btn-primary cm_btn3"
                            disabled={props.miniLoader}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={props.handleClose}
                            className="btn btn-primary cm_btn2"
                            disabled={props.miniLoader}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    )
}

import {
    GET_TRANSACTION_EXCEL_REQUEST,
    GET_TRANSACTION_EXCEL_SUCCESS,
    GET_TRANSACTION_EXCEL_FAILURE,
    
} from "../actions";

const initialState = {
    loading : false,
    data:[],
    error : "",
};

const getTransactionExcelSheetReducer = (state = initialState, action)=>{
   
    switch (action.type) {
       
        case GET_TRANSACTION_EXCEL_REQUEST:
          return {
            error:"",
            loading: true,
             data:[],
          };
    
        case GET_TRANSACTION_EXCEL_SUCCESS:
          return {
            loading: false,
            data:action.payload,
            error: "",
          };
        case GET_TRANSACTION_EXCEL_FAILURE:
          return {
            loading: false,
            data:[],
            error: action.payload,
          };
        default:
          return state;
      }
    };
    export default getTransactionExcelSheetReducer;

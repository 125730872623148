import React from "react";
import "./DotLoader.css";

const DotLoader = () => {
  return (
    <>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default DotLoader;

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import Pagination from "react-js-pagination";
import ResponseFilter from '../../ResponseFilter';
import { Loader } from '../svg';
import { getEmployeeList, getSkillView, addEmployeeInSkill } from '../../config/services/employeeServices';
import Modal from "react-bootstrap/Modal";
import { Modal as AntModal, Select } from "antd";
const { Option } = Select

class ViewSkillEmp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      employeesProfile: [],
      skillId: '',
      loader: true,
      sortType: 2,
      sortField: "created",
      pageNo: 1,
      count: 10,
      totalEmployees: '',
      skillDetails: [],
      skillName: '',
      openAddEmployeePopup: false,
      employeeList: [],
      employeeIdList: [],
      employee: [],
      resMsg: false,
      resMsgDelete: false,
      resMsgDeleteMultiple: false,
      editEmployee: [],
      employeeErrorMsg: "",
      miniLoader: false,
      reId: [],
      multiValue: [],
      resFailMessage: false,
      deleteEmployeeErrorMsg: "",
      failResponseMessage: ""

    }
    this.debounceSearch = null;
  }

  componentDidMount() {

    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let status = url.searchParams.get("status");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      search: search ? search : "",
      sortType: sortType ? sortType : 2,
      sortField: sortField ? sortField : "created",
    }, () => {

      this.getView();
    })
    this.getEmployee();
  }
  handleOpenAddEmployeeSkillPopup = () => {
    this.setState({
      openAddEmployeePopup: !this.state.openAddEmployeePopup,
    });
  };

  handleValidation = () => {
    let validate = true;
    const { employee } = this.state;
    if (employee === '' || employee === undefined || employee.length == 0) {
      validate = false;
      this.setState({ employeeErrorMsg: 'Please select employee !' })
    }
    else {
      this.setState({ employeeErrorMsg: '' })
    }
    return validate;
  }

  closeModal = () =>
    this.setState({
      employee: [],
      openAddEmployeePopup: false,
      employeeIdList: this.state.employeeIdList,
      employeeErrorMsg: "",
      miniLoader: false,
    });


  handleSelectOtherSkill = (a) => {
    this.setState({ employeeIdList: a });
  };


  handleChangeEmp = e => this.setState({ employee: e, employeeErrorMsg: "" })


  getEmployee = () => {

    getEmployeeList().then(res => {

      if (res.data.statusCode === 1) {
        this.setState({
          employeeList: res.data.responseData.employeesList
        })
      }
      else if (res.data.statusCode === 0) {
        this.setState({ loader: false })
      }
    })
  }


  handleSave = (e) => {
    e.preventDefault();
    let self = this;
    if (this.handleValidation()) {
      this.setState({ miniLoader: true });
      let obj = {
        skillId: this.props.match.params.id,
        employeesId: this.state.employee.length != 0
          ? this.state.employee.toString()
          : [],
      };
      addEmployeeInSkill(obj).then((res) => {
        if (res.data.statusCode === 1) {
          self.setState({ miniLoader: false, resMsg: true, openAddEmployeePopup: false, employee: [] }, this.getView());
          self.closeModal();
        } else {
          self.setState({
            miniLoader: false, resFailMessage: true,
            failResponseMessage: res?.data?.error?.responseMessage
          });
        }
      });
      setTimeout(() => this.setState({ miniLoader: false, resMsg: false, resFailMessage: false }), 3000);

    }
  }

  handleEmployeeSearch = e => console.log("Search : ", e)

  deleteEmployee = (e) => {
    let self = this;
    let obj = {
      skillId: this.props.match.params.id,
      employeesId: e.employeeId,
      isDelete: 1,
    };
    this.setState({ loader: true, deleteEmployeeErrorMsg: "" })
    addEmployeeInSkill(obj).then((res) => {
      if (res.data.statusCode === 1) {
        this.setState({}, () => this.getView());
        this.setState({ loader: false, resMsgDelete: true, });
        this.closeModal();
      } else {
        self.setState({
          loader: false, resFailMessage: true, failResponseMessage: res?.data?.error?.responseMessage
        });
      }
    });
    setTimeout(() => this.setState({ resMsgDelete: false }), 3000);
    setTimeout(() => this.setState({ resFailMessage: false }), 3000);

  }


  handleInputChange = async (val) => {
    let { multiValue, reId } = this.state;
    multiValue = [];
    this.setState({ deleteEmployeeErrorMsg: "" })

    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1);
          if (reId.length === 0) {
            this.setState({ approve: false, reject: false, pending: false })
          }
        }
      }
    } else {
      this.state.reId.push(val);
    }
    this.setState({ multiValue: reId });
  }

  handleDeleteValidetion = () => {
    let validate = true;
    const { multiValue } = this.state;

    if (multiValue === '' || multiValue === undefined || multiValue.length == 0) {
      validate = false;
      this.setState({ deleteEmployeeErrorMsg: 'Please Select Employees to Delete' })
    }
    else {
      this.setState({ deleteEmployeeErrorMsg: '' })
    }
    return validate;
  }

  deleteAllMember = event => {
    if (this.handleDeleteValidetion()) {
      let { skillId, editEmployee, multiValue } = this.state;
      this.setState({ loader: true });
      let obj = {
        employeesId: multiValue.toString(),
        skillId: this.props.match.params.id,
        isDelete: 1,

      }
      addEmployeeInSkill(obj).then(res => {

        if (res.data.statusCode === 1) {
          this.setState({ loader: true, checked: "", editEmployee: "", resMsgDeleteMultiple: true, reId: [], multiValue: [] }, () => this.getView())
        } else {
          this.setState({ loader: false, resFailMessage: true, failResponseMessage: res?.data?.error?.responseMessage })
        }
      })
    }
    setTimeout(() => this.setState({ resMsgDeleteMultiple: false }), 3000);
    setTimeout(() => this.setState({ resFailMessage: false }), 3000);
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value, pageNo: 1, deleteEmployeeErrorMsg: "" });
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.getView();
    }, 2000);
  };

  handleSelectChange = () => {
    this.setState({ search: "" }, () => {
      this.getView();
    });
  };


  handleSort = e => {
    this.setState({ loader: true });
    this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getView());
  }

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true, deleteEmployeeErrorMsg: "" }, () => this.getView());
  }

  getView = () => {
    let { sortType, sortField, pageNo, count, search } = this.state
    let params = `search=${search.trim()}&skillId=${this.props.match.params.id}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}`;
    this.props.history.push(`${this.props.match.params.id}?sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo}&search=${search}`)
    let skillIdDetail = this.props.match.params.id
    this.setState({ loader: true })

    getSkillView(params).then(res => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        let {
          skillName
        } = resData.skillDetails
        this.setState({
          employeesProfile: resData.employeesProfile,
          totalEmployees: resData.totalEmployees,
          skillName: skillName,

        }, () => this.setState({ loader: false }))
      }
      else {
        this.setState({ loader: false })
      }
    })
  }



  render() {
    let { employeeErrorMsg, search, resMsgDeleteMultiple, editEmployee, resMsgDelete, resMsg, employeeList, openAddEmployeePopup, employeesProfile, loader, totalEmployees, pageNo, count, skillName, miniLoader, resFailMessage, deleteEmployeeErrorMsg, failResponseMessage } = this.state;
    return (


      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        {
          resMsg ?
            <div className="alert alert-success cm_top_fix">Employees added successfully</div> : ''
        }
        {
          resMsgDelete ?
            <div className="alert alert-danger cm_top_fix">Employee deleted successfully</div> : ''
        }
        {
          resMsgDeleteMultiple ?
            <div className="alert alert-danger cm_top_fix">Employees deleted successfully</div> : ''
        }
        {
          resFailMessage ?
            <div className="alert alert-danger cm_top_fix">{failResponseMessage}</div> : ''
        }
        <div className="cm_breadcrumb">
          <ul>
            <li><Link to='/admin-skill'>SKILL  MANAGEMENT</Link><div className="cm_breadcrumb_arrow"></div></li>
            <li style={{ textTransform: 'uppercase' }}>{skillName}</li>
          </ul>
        </div>
        <div className="row d-flex justify-content-end mb-4 mt-4">
          <div className="col-md-6 search_device_bar3">
            <span
              className="btn btn-primary add_empBtn ml-4"
              onClick={this.handleOpenAddEmployeeSkillPopup}
            >
              Add New Employee
            </span>

            <input
              maxLength={25}
              className="form-control mr-lg-2 cm_box"
              type="search"
              placeholder="Search by Name/E.Id"
              aria-label="Search"
              value={search}
              onChange={this.handleSearch}
              autoComplete="off"
              // onKeyDown={(e) => { if (e.key === 'Enter') this.getView() }}
            />
            {search ? (
              <span className="cm_clear4" onClick={this.handleSelectChange}>
                <i class="fa fa-times-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span className="cm_clear4">
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            )}
          </div>
        </div>
        {/* <div className="row d-flex justify-content-end mb-4 mt-5 mr-2" >
          <button className='btn btn-primary' onClick={this.handleOpenAddEmployeeSkillPopup}>
            Add New Employee
          </button>
        </div> */}
        <AntModal
          centered
          className="attend_modal"
          footer={false}
          visible={openAddEmployeePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <div className="assign_device">
              <h3 className="mb-4">ADD NEW EMPLOYEE</h3>
              <div className="form-group selectHeight mb-5">
                <Select
                  showSearch
                  style={{ width: "100%", height: "auto" }}
                  mode="multiple"
                  value={this.state.employee}
                  className="select_emp_leave mr-4 mb-2"
                  placeholder="Select Employee"
                  name="employeeId"
                  onSearch={this.handleEmployeeSearch}
                  optionFilterProp="key"
                  showArrow
                  filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                  onChange={this.handleChangeEmp}>

                  {
                    !_.isEmpty(employeeList) ?

                      employeeList.sort(function (a, b) {
                        if (a.firstName < b.firstName) { return -1; }
                        if (a.firstName > b.firstName) { return 1; }
                        return 0;
                      }).map((emp) => emp.status == 1 && (<Option key={`${emp.firstName} ${emp.lastName}`} value={emp.employeeId}>
                        ({emp.empId}){emp.firstName} {emp.lastName}</Option>)) : ""
                  }
                </Select>
                {employeeErrorMsg ? <span className="error_msg ml-2">{employeeErrorMsg}</span> : null}

              </div>
              <div>

              </div>
              <div className='submit_button_margin mt-4'>
                {miniLoader ? <div className="mini_loader_dept"></div> : ''}
                <button
                  type="submit"
                  onClick={this.handleSave}
                  className="btn btn-primary cm_btn3"
                  disabled={miniLoader}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>

            </div>
          </Modal.Body>
        </AntModal>
        <div>
          <div className="table-responsive mt-5">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Employee ID<i className="fa fa-sort-numeric-asc ml-2" onClick={() => this.handleSort('empId')}></i></th>
                  <th>Name<i className="fa fa-sort-alpha-asc ml-2" onClick={() => this.handleSort('firstName')}></i></th>
                  <th>Joined<i className="fa fa-sort-numeric-asc ml-2" onClick={() => this.handleSort('dateOfJoining')}></i></th>
                  <th scope="col">Department</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {

                  !_.isEmpty(employeesProfile) ?
                    employeesProfile.map((profile, i) => (

                      <tr key={profile.skillId}>
                        <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                        <td><span className="cm_col">{profile.empId}</span></td>
                        <td><span className="cm_col"><Link to={`/admin-skill/employee-detail/${profile.employeeId}`}>{profile.firstName} {profile.lastName}</Link></span></td>

                        <td>{moment.unix(profile.dateOfJoining / 1000).format("DD/MM/YYYY")}</td>


                        <td style={{ width: 380 }}><span className="cm_col_desig">
                          {
                            profile.departmentName.map((department, i) => (


                              <span className="cm_col_dept">
                                {department.departmentName ? `${department.departmentName}` : ''}

                              </span>



                            ))}

                        </span></td>
                        <td style={{ width: 380 }}><span className="cm_col_desig">
                          {
                            profile.designationName.map((department, i) => (


                              <span className="cm_col_dept">
                                {department.designationName ? `${department.designationName}` : ''}

                              </span>



                            ))}

                        </span></td>

                        <td className={`${profile.status === 1 ? 'green' : "red"}`}>{profile.status === 1 ? "Active" : "Inactive"}
                        </td>
                        <td >
                          <span onClick={() => this.deleteEmployee(profile)} >
                            <i
                              className="fa fa-trash-o red ml-2 mr-3 fa-2x"
                              title="Delete skill"
                            />
                          </span>
                          <input
                            type="checkbox"
                            name='editEmployee'
                            className='input_skill ml-2 department-checkbox'
                            checked={
                              this.state.reId.includes(
                                profile.employeeId
                              )
                                ? true
                                : false
                            }
                            value={profile.employeeId}
                            onChange={() => this.handleInputChange(profile.employeeId)}
                          />
                        </td>
                      </tr>

                    )) : ''

                }

              </tbody>
            </table>
            {
              loader ? null :
                employeesProfile.length > 0 ? '' : <h3 className="text-center">No data available</h3>

            }
          </div>

        </div>
        {
          totalEmployees > 10 ? <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={totalEmployees}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
            : ''}
        {deleteEmployeeErrorMsg ? <span className="error_msg">{deleteEmployeeErrorMsg}</span> : null}
        <div>
          {employeesProfile.length > 0 ?
            <button type="submit" class="btn btn-danger mt-3 " onClick={() => this.deleteAllMember()}>Delete</button>
            :
            ''}
        </div>
      </div>

    );
  }
}
export default ViewSkillEmp;
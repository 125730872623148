import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart } from "d3-org-chart";
import "../OrganizationChart.css";
import EmployeeDetailsCard from "./EmployeeDetailCard";
import CustomExpandButton from "./CustomExpandButton";
import CustomNodeContent from "./CustomNodeComponent";

const OrgChartEmployee = (props) => {
  const {
    data,
    setResponse,
    setResponseMessage,
    setSuccess,
    setSuccessMessage,
    fetchData,
    employeeList,
    rootId,
    setLoader,
  } = props;
  
  const d3Container = useRef(null);
  const chartRef = useRef(null);
  
  const [cardShow, setCardShow] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [empIdDelete, setEmpIdDelete] = useState(null);
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleShow = () => setCardShow(true);
  const handleClose = () => {
    setCardShow(false);
    setOpenDeleteModal(false);
    setEmpIdDelete(null);
  };

  const handleOpenDeleteModal = (id, employeeDetail) => {
    setEmpIdDelete(id);
    setOpenDeleteModal(true);
    setEmployeeDetail(employeeDetail);
    if (employeeDetail._directSubordinates) {
      setOpenModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setEmpIdDelete(null);
  };

  useLayoutEffect(() => {
    if (data && d3Container.current) {
      if (!chartRef.current) {
        chartRef.current = new OrgChart();
      }

      chartRef.current
        .container(d3Container.current)
        .data(data)
        .nodeWidth(() => 300)
        .nodeHeight(() => 150)
        .compactMarginBetween(() => 80)
        .onNodeClick((node) => {
          const { id } = node.data;
          setEmployeeId(id);
          handleShow();
        })
        .buttonContent((params) => {
          const { node } = params;
          return ReactDOMServer.renderToStaticMarkup(
            <CustomExpandButton node={node.data} children={node.children} />
          );
        })
        .nodeContent((params) => {
          return ReactDOMServer.renderToStaticMarkup(
            <CustomNodeContent data={params.data} />
          );
        })
        .render();
    }
  }, [data]);

  return (
    <div className="orgChart" ref={d3Container}>
      {cardShow && (
        <EmployeeDetailsCard
          setLoader={setLoader}
          rootId={rootId}
          employees={data}
          employee={data.find((employee) => employee.id === employeeId)}
          handleClose={handleClose}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          empIdDelete={empIdDelete}
          setEmpIdDelete={setEmpIdDelete}
          openDeleteModal={openDeleteModal}
          employeeDetail={employeeDetail}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setResponse={setResponse}
          setResponseMessage={setResponseMessage}
          setSuccess={setSuccess}
          setSuccessMessage={setSuccessMessage}
          fetchData={fetchData}
          data={data}
          employeeList={employeeList}
        />
      )}
    </div>
  );
};

export default OrgChartEmployee;

import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { DatePicker, Modal as AntModal } from 'antd';
import moment from 'moment';
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import ResponseFilter from '../../ResponseFilter';
import { DocIcon, ExcelIcon, Loader, PdfIcon } from '../svg';
import { getEmployeeList, addDevice, getDeviceList, editDevice, getDeviceDetails, getAdminProfile, addDeviceRequest, deleteDeviceByManager } from '../../config/services/employeeServices';
import { IoMdClose } from 'react-icons/io';
import DeviceFullScreenImage from './DeviceFullScreenImage';
import { PDFReader } from 'reactjs-pdf-reader-custom';
import { getFileNameFromUrl } from '../../config/constants/Constants';

// import { IoMdClose } from 'react-icons/io';
// import { timeStamp } from 'console';
const { Option } = Select;
function disabledDate(current) {

  return current && current < moment().startOf('day');
}


class DeviceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openAddDevicePopup: false,
      openDeleteDevicePopup: false,
      openEditDevicePopup: false,
      openDeviceInfoPopup: false,
      resDeleteMsg: false,
      resEditMsg: false,
      resRequestMsg: false,
      resFailMsg: false,
      deviceList: [],
      deviceDetails: [],
      assignedTo: '',
      // deviceManager: '',
      employeeList: [],
      employeeId: '',
      // deviceManagerId:'',
      deviceType: '',
      operatingSystem: '',
      brand: '',
      imei: '',
      acessories: [],
      ram: '',
      hdd: '',
      dateAdded: '',
      status: '',
      brandErrorMsg: '',
      ramErrorMsg: '',
      hddErrorMsg: '',
      dateErrorMsg: '',
      deviceTypeErrorMsg: '',
      // deviceManagerErrorMsg: ''
      deviceStatusErrorMsg: "",
      deviceStatus: "",
      devIdErrorMsg: '',
      deviceModel: '',
      password: '',
      editDivceObj: '',
      deviceId: '',
      totalDevices: '',
      phones: '',
      desktops: '',
      laptops: '',
      deleteDevice: '',
      firstName: '',
      lastName: '',
      devId: '',
      search: '',
      loader: true,
      sortType: 2,
      sortField: "created",
      pageNo: 1,
      count: 50,
      isAdmin: "",
      purchasedDate: "",
      // deviceStatus: "",
      assignedDate: "",
      assignedToErrorMsg: "",
      dateAssignErrorMsg: "",
      cpu: "",
      monitor: "",
      macMini: "",
      comment: "",
      uploadBillErrorMsg: "",
      uploadBill: "",
      devicePictures: [],
      comment: "",
      assignedTill: "",
      assignedFrom: "",
      deviceModalErrorMsg: '',
      search: "",
      officeLocation: 0,
      deviceOfficeLocationErrorMsg: "",
      uploadPicture: [],
      uploadFileName: "",
      uploadPictureErrorMsg: "",

      uploadDocument: [],
      uploadDocumentName: [],
      uploadDocumentErrorMsg: "",

      files: [],
      responseMessage: '',
      preview: false,
      fullImage: "",
      miniLoader: false,
      deviceDocs: []

    }
    this.debounceSearch = null
  }
  handleOpenDeleteDevicePopup = deviceId => {

    this.setState({ openDeleteDevicePopup: !this.state.openDeleteDevicePopup, deviceId });

  }
  handleSearch = e => {
    this.setState({ search: e.target.value, pageNo: 1 })
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.getDevice()
    }, 2000);
  }
  handleSort = e => {
    this.setState({ loader: true });
    this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getDevice());
  }

  submitSearch = e => {
    e.preventDefault();
    this.setState({ loader: true })
    this.getDevice()
  }

  urlToFile = async (url) => {
    const document = []
    this.setState({ miniloader: true })
    for (let i = 0; i < url?.length; i++) {
      const response = await fetch(url[i]);
      const blob = await response?.blob();
      const filename = url[i]?.substring(url[i]?.lastIndexOf('/') + 1);
      const file = new File([blob], filename, { type: blob.type });
      document.push(file);
    }
    this.setState({ miniloader: false })
    return document
  }


  handleOpenEditDevicePopup = ({
    deviceId,
    deviceModel,
    deviceType,
    operatingSystem,
    imei,
    ram,
    hdd,
    password,
    brand,
    status, devId,
    comment,
    officeLocation,
    devicePictures, docs
  }) => {
    this.setState({
      openEditDevicePopup: !this.state.openEditDevicePopup,
      deviceId,
      deviceModel,
      deviceType,
      operatingSystem,
      brand,
      imei,
      // acessories,
      ram,
      hdd,
      devId,
      password,
      deviceStatus: status,
      comment,
      officeLocation,
    });
    const changeUrl = async () => {
      this.setState({
        uploadDocument: await this.urlToFile(docs),
        uploadPicture: await this.urlToFile(devicePictures)
      })
    }
    changeUrl()
  }

  handleOpenDeviceInfoPopup = ({
    deviceModel,
    assignedTo,
    // deviceManager,
    deviceType,
    operatingSystem,
    imei,
    ram,
    hdd,
    password,
    brand,
    // assignedTo,
    devId,
    purchasedDate,
    devicePictures,
    comment,
    docs,
  }) => this.setState({
    openDeviceInfoPopup: !this.state.openDeviceInfoPopup,
    editDivceObj: devId,
    deviceModel,
    assignedTo,
    // deviceManager,
    deviceType,
    operatingSystem,
    brand,
    imei,
    // acessories,
    ram,
    hdd,
    password,
    // dateAdded,
    // status,
    // firstName,
    devId,
    // lastName
    purchasedDate,
    devicePictures,
    comment,
    deviceDocs: docs
  });


  handleOpenAddDevicePopup = () => this.setState({ openAddDevicePopup: !this.state.openAddDevicePopup });

  closeModal = () => {
    this.setState({
      openAddDevicePopup: false,
      openDeleteDevicePopup: false,
      openEditDevicePopup: false,
      openDeviceInfoPopup: false,
      deleteDevice: '',
      editDevice: '',
      deviceId: '',
      deviceModel: '',
      devId: '',
      deviceType: '',
      operatingSystem: '',
      imei: '',
      acessories: '',
      ram: '',
      hdd: '',
      password: '',
      brand: '',
      dateAdded: '',
      status: '',
      firstName: '',
      lastName: '',
      assignedTo: '',
      // deviceManager: '',
      brandErrorMsg: '',
      ramErrorMsg: '',
      deviceTypeErrorMsg: '',
      // deviceManagerErrorMsg: '',
      devIdErrorMsg: '',
      hddErrorMsg: '',
      dateErrorMsg: '',
      dateAssignErrorMsg: "",
      deviceType: '',
      officeLocation: 0,
      deviceOfficeLocationErrorMsg: "",
      assignedToErrorMsg: "",
      uploadPictureErrorMsg: '',
      uploadPicture: [],
      uploadDocument: [],
      uploadDocumentErrorMsg: "",
      uploadDocumentName: [],
      files: [],
      deviceStatus: "",
    });
  }

  deviceValue = type => {
    switch (type) {
      case 1:
        return "Laptop";
        break;

      case 2:
        return "CPU";
        break;

      case 3:
        return "Phone";
        break;

      case 4:
        return 'Mouse';
        break;

      case 5:
        return 'Connectors';
        break;

      case 6:
        return 'Ups';
        break;

      case 7:
        return 'Router';
        break;

      case 8:
        return 'Headphone';
        break;

      case 9:
        return 'wifi-Adaptor';
        break;

      case 10:
        return 'web Camera';
        break;

      case 11:
        return 'keyboard';
        break;

      case 12:
        return 'Monitor';
        break;

      case 13:
        return 'Pendrive';
        break;

      case 14:
        return 'Hard Disk';
        break;

      case 15:
        return 'RAM';
        break;
      case 16:
        return "Mac Mini";
        break;


    }
  }
  operatingSystemType = type => {
    switch (type) {
      case 1: return 'windows';
        break;

      case 2: return 'linux';
        break;
      case 3: return 'MacOS'
        break;


      default: return 'Android';

    }
  }

  deviceLocationType = type => {
    switch (type) {
      case 1: return 'Noida';
        break;

      case 2: return 'Lucknow';
        break;

    }
  }

  deviceStatusType = (type) => {
    switch (type) {
      case 1:
        return "Occupied";
        break;

      case 2:
        return "Free";
        break;

      case 3:
        return "Under Repairing";
        break;
      case 4:
        return "Not Working";
    }
  };

  deviceType = type => {
    switch (type) {
      case 'laptop': return 1;
        break;

      case 'cpu': return 2;
        break;

      case 'phone': return 3;
        break;

      case 'mouse': return 4;
        break;

      case 'connectors': return 5;
        break;

      case 'ups': return 6;
        break;

      case 'router': return 7;
        break;

      case 'headphone': return 8;
        break;

      case 'wifiAdaptor': return 9;
        break;

      case 'webCamera': return 10;
        break;

      case 'keyboard': return 11;
        break;

      case 'monitor': return 12;
        break;

      case 'pendrive': return 13;
        break;

      case 'hardDisk': return 14;
        break;

      case 'ram1': return 15;
        break;

      case 'macMini': return 16;
        break;
    }
  }

  handleDeviceStatusChange = (e) => {
    this.setState({ deviceStatus: e, deviceStatusErrorMsg: "" });
  }
  getDevice = () => {

    let { search, status, sortType, sortField, pageNo, count } = this.state
    let params = `search=${search.trim()}&status=${status}&count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&deviceType=${this.deviceType(this.props.match.params.deviceType)}`;
    this.props.history.push(`?search=${search}&status=${status}&count=${count}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&deviceType=${this.deviceType(this.props.match.params.deviceType)}`)
    getDeviceList(params).then(res => {

      let { status, resData } = ResponseFilter(res);


      if (status) {
        this.setState({
          deviceList: resData.deviceList,
          dataCount: resData?.dataCount,
        }, () => this.setState({ loader: false }, () => this.closeModal()))
      }
      else {
        this.setState({ loader: false })
        this.closeModal();
      }
    })
  }

  getDetails = () => {

    getDeviceDetails().then(res => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          deviceDetails: resData.deviceDetails,
        }, () => this.closeModal())
      }
    })
  }


  handleChange = e => this.setState({
    [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
    [e.target.name + 'ErrorMsg']: ''
  });

  handleOperatingSystemChange = e => this.setState({ operatingSystem: e })

  handleDeviceChange = e => this.setState({ deviceType: e, deviceTypeErrorMsg: '' })

  handleDateChange = (date, dateString) => this.setState({ assignedDate: moment(date).toDate().getTime(), dateAssignErrorMsg: '' })

  handleChangeAssignedToChange = e => this.setState({ assignedTo: e, assignedToErrorMsg: "" })

  handleDelete = () => {

    let params = {
      data: {
        deviceId: this.state.deviceId,
        status: 2
      }
    }
    this.setState({ miniLoader: true })
    deleteDeviceByManager(params)
      .then(res => {
        this.setState({ miniLoader: false }, () => this.getDevice())
        this.setState({ resDeleteMsg: true })
      })
    setTimeout(() => this.setState({ resDeleteMsg: false }), 4000)
  }

  getEmployee = () => {

    getEmployeeList().then(res => {
      if (res.data.statusCode === 1) {
        this.setState({
          employeeList: res.data.responseData.employeesList
        })
      }
      else if (res.data.statusCode === 0) {
        this.setState({ loader: false })

      }
    })
  }

  getDetail = () => {
    getAdminProfile().then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState(
          {
            isAdmin: resData.employeeProfile.isAdmin,
          },
          () => this.setState({ loader: false })
        );
      }
    });
    // setTimeout(() => this.setState({ resMsg: false }), 4000);
  };

  componentDidMount() {

    let url_string = window.location.href
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    this.setState({
      pageNo: pageNo ? +(pageNo) : 1,
      search: search ? search : "",
      sortType: sortType ? sortType : 2,
      sortField: sortField ? sortField : "created",
    }, () => {

      this.getDevice();
    })

    this.getEmployee();
    this.getDetails();
    this.getDetail()
  }

  handleValidetion = () => {
    let validate = true;
    const {
      deviceType,
      deviceStatus,
      // operatingSystem,
      devId,
      assignedTo,
      // deviceStatus,
      deviceModel,
      assignedFrom,
      officeLocation,
      assignedTill,
      uploadPicture,
      uploadDocument
      // imei,
      // password,

      // deviceManager,
    } = this.state;
    let neg = []
    for (let i in uploadPicture) {
      if (uploadPicture[i].name) {
        if (uploadPicture[i]?.name?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|pdf|xls|xlsx|doc|docs|docx|odt|dox|ppt|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|PDF)$/))
          neg.push(uploadPicture[i]);

      }
      else if (uploadPicture[i]?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|pdf|xls|xlsx|doc|docs|docx|odt|dox|ppt|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX|PDF)$/)) {
        neg.push(uploadPicture[i]);
      }
    }

    let neg1 = []
    if (uploadDocument.length > 0) {
      for (let i in uploadDocument) {
        if (uploadDocument[i]?.name?.match(/\.(mp4|mov|wmv|avi|avchd|flv|f4v|swf|mkv|webm|html5|mpeg-2|zip|doc|docs|docx|odt|dox|ppt|xls|xlxs|xlsx|MP4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5|MPEG-2|ZIP|DOC|DOCS|DOCX|ODT|DOX|PPT|XLS|XLXS|XLSX)$/))
          neg1.push(uploadDocument[i]);
      }
    }

    if (devId === "") {
      validate = false;
      this.setState({ devIdErrorMsg: "Device ID is required" });
    } else {
      this.setState({ devIdErrorMsg: "" });
    }
    if (deviceModel === "") {
      validate = false;
      this.setState({ deviceModalErrorMsg: "Device Modal is required" });
    } else {
      this.setState({ deviceModalErrorMsg: "" });
    }
    if (assignedTo.length === 0 && deviceStatus == 1) {
      validate = false;
      this.setState({ assignedToErrorMsg: "Assigned employee is required" });
    }
    else {
      this.setState({ assignedToErrorMsg: "" });
    }
    if (deviceType === "") {
      validate = false;
      this.setState({ deviceTypeErrorMsg: "Device Type is required" });
    } else {
      this.setState({ deviceTypeErrorMsg: "" });
    }
    if (deviceStatus === "") {
      validate = false;
      this.setState({ deviceStatusErrorMsg: "Device status is required" });
    } else {
      this.setState({ deviceStatusErrorMsg: "" });
    }
    if (officeLocation == "" || officeLocation == null || officeLocation == 0 || officeLocation == undefined) {
      validate = false;
      this.setState({ deviceOfficeLocationErrorMsg: "Device Location is required" });
    } else {
      this.setState({ deviceOfficeLocationErrorMsg: "" });
    }
    if (assignedFrom === "" && deviceStatus == 1) {
      validate = false;
      this.setState({ dateAssignErrorMsg: "Date is required" });
    }
    else if (assignedTill && assignedTill < assignedFrom) {
      validate = false;
      this.setState({ dateAssignErrorMsg: "Assigned date can not be greater then assigned till" })
    }
    else {
      this.setState({ dateAssignErrorMsg: "" });
    }

    if (uploadPicture === "" || uploadPicture === undefined || uploadPicture.length === 0) {
      validate = false;
      this.setState({ uploadPictureErrorMsg: "Please Upload Pictures" });
    } else if (neg.length !== 0) {
      validate = false;
      this.setState({ uploadPictureErrorMsg: "Please Provide Valid File" });
    }
    else {
      this.setState({ uploadPictureErrorMsg: "" });
    }

    if (neg1.length !== 0) {
      validate = false;
      this.setState({ uploadDocumentErrorMsg: "Please Provide Valid File" });
    }
    else {
      this.setState({ uploadDocumentErrorMsg: "" });
    }
    return validate;
  };

  handleEmployeeSearch = (e) => {
    console.log("e : ", e);
  }
  handlePageChange = (pageNo) => {

    this.setState({ pageNo: pageNo, loader: true }, () => this.getDevice());
  }
  handleDateChangeAssign = (date, dateString) =>
    this.setState({
      assignedFrom: moment(date).toDate().getTime(),
      dateAssignErrorMsg: "",
    });
  handleDateChangeAssignTill = (date, dateString) =>
    this.setState({
      assignedTill: moment(date).toDate().getTime(),
      dateAssignErrorMsg: "",
    });
  handleEditSubmit = event => {
    event.preventDefault();

    let {
      deviceId,
      deviceModel,
      assignedTo,
      // deviceManager,
      deviceType,
      brand,
      operatingSystem,
      imei,
      // acessories,
      ram,
      hdd,
      password,
      deviceStatus,
      devId, assignedTill,
      assignedFrom,
      comment,
      officeLocation,
      uploadPicture,
      uploadDocument
    } = this.state;

    if (this.handleValidetion()) {
      let obj = new FormData()
      obj.append("deviceId", deviceId)
      obj.append("deviceModel", deviceModel)
      obj.append("assignedTo", deviceStatus != 1 ? '' : assignedTo)
      obj.append("deviceType", deviceType)
      obj.append("status", deviceStatus)
      obj.append("brand", brand)
      obj.append("operatingSystem", operatingSystem)
      obj.append("imei", imei)
      obj.append("devId", devId)
      obj.append("ram", ram)
      obj.append("hdd", hdd)
      obj.append("password", password)
      obj.append("assignedFrom", deviceStatus != 1 ? '' : assignedFrom)
      obj.append("assignedTill", deviceStatus != 1 ? "" : assignedTill)
      obj.append("comment", comment)
      obj.append("officeLocation", officeLocation)
      if (uploadPicture) {
        uploadPicture.map((picture) => obj.append("devicePictures", picture))
      }
      uploadDocument.map((docs) => obj.append("docs", docs))

      this.setState({ loader: true })
      editDevice(obj, deviceId).then(res => {

        if (res.data.statusCode === 1) {
          this.setState({
            resEditMsg: true, loader: false
          }, () => this.getDevice())
          setTimeout(() => this.setState({ resEditMsg: false }), 4000)
        }
        else {
          this.setState({
            resFailMsg: true, loader: false,
            responseMessage: res?.data?.error?.responseMessage
          })
        }
      })
      setTimeout(() => this.setState({ resFailMsg: false }), 4000)
    }
  }

  handleSubmit = event => {
    event.preventDefault();


    let { password, devId, brand, ram, hdd, dateAdded, assignedTo, deviceManager, deviceType, operatingSystem, imei, acessories, deviceModel } = this.state;

    if (this.handleValidetion()) {


      let obj = {
        'brand': brand,
        'ram': ram,
        'hdd': hdd,
        'password': password,
        'devId': devId,
        'dateAdded': dateAdded,
        'operatingSystem': operatingSystem,
        'imei': imei,
        'acessories': acessories.length != 0 ? this.state.acessories.toString() : [],
        'deviceType': deviceType,
        'assignedTo': assignedTo,
        'deviceModel': deviceModel
      }


      addDevice(obj).then(res => {
        if (res.data.statusCode === 1) {
          window.location.href = '/admin-device';

        }

      })
    }

  }
  handleManagerChange = e => this.setState({
    // deviceManager: e, deviceManagerErrorMsg: ''
  })


  handleDeviceRequest = (devId) => {
    // event.preventDefault()
    let obj = {
      deviceUniqueId: devId,
    }
    addDeviceRequest(obj).then(res => {
      if (res.data.statusCode === 1) {
        this.setState({

        }, () => this.getDevice())
        this.setState({ resRequestMsg: true })
      }
      else {
        this.setState({ resFailMsg: true })
      }
    })
    setTimeout(() => this.setState({ resRequestMsg: false }), 4000)
    setTimeout(() => this.setState({ resFailMsg: false }), 4000)
  }
  handleSelectChange = () => {
    this.setState({ search: "" }, () => this.getDevice());
  };

  handleDeviceLocationChange = (e) => {
    this.setState({ officeLocation: e, deviceOficeLocationErrorMsg: "" });
  }

  handleFileChange = (e) => {
    let files2 = [...this.state.uploadPicture];
    for (let i = 0; i < e.target.files.length; i++) {
      files2.push(e.target.files[i]);
    }

    this.setState(
      {
        uploadPicture: files2,
        uploadPictureErrorMsg: "",
      }, () => { }
    );
  };


  handleDocumentChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    let files = [...this.state.uploadDocument];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);

    }



    this.setState(
      {
        uploadDocument: files,
        uploadDocumentErrorMsg: "",
        uploadDocumentName: files.map((item) =>

          item.name),
      }, () => { }
    );

    reader.addEventListener(
      "load",
      () => {
        this.setState({
          DocumentPreview: reader.result
        });

      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };


  handleDeleteIconClick = (index) => {
    this.setState({
      uploadPicture: this.state.uploadPicture.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    });
    this.setState({
      files: this.state.files.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    })
  };

  handleDeleteDocumentIconClick = (index) => {
    this.setState({
      uploadDocument: this.state.uploadDocument.filter(
        (item, itemIndex) => itemIndex !== index
      ),
      uploadDocumentName: this.state.uploadDocumentName.filter(
        (item, itemIndex) => itemIndex !== index
      ),
    });
  };

  handleFullImage = (e) => {
    this.setState({
      preview: true,
      fullImage: e
    })
  }
  closeFullimage = () => {
    this.setState({
      preview: false,
      fullImage: ""
    })
  }
  render() {
    let {
      openDeleteDevicePopup,
      openEditDevicePopup,
      openDeviceInfoPopup,
      deviceList,
      employeeList,
      brand,
      ram,
      hdd,
      password,
      devId,
      assignedTo,
      deviceTypeErrorMsg,
      devIdErrorMsg,
      imei,
      deviceModel,
      deviceType,
      operatingSystem,
      miniLoader,
      loader,
      pageNo,
      count,
      dataCount,
      isAdmin,
      resDeleteMsg,
      resEditMsg,
      resRequestMsg,
      resFailMsg,
      purchasedDate,
      deviceStatusErrorMsg,
      deviceStatus,
      assignedDate,
      assignedToErrorMsg,
      dateAssignErrorMsg,
      comment,
      devicePictures,
      assignedFrom,
      assignedTill,
      deviceModalErrorMsg,
      search,
      officeLocation,
      deviceOfficeLocationErrorMsg,
      uploadPicture,
      uploadPictureErrorMsg, responseMessage,
      preview,
      fullImage,
      uploadDocument, uploadDocumentErrorMsg, uploadDocumentName,
      deviceDocs

    } = this.state;


    return (

      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        {
          resDeleteMsg ?
            <div className="alert alert-success cm_top_fix"> Device Delete Successfully </div> : ''
        }
        {
          resEditMsg ?
            <div className="alert alert-success cm_top_fix"> Device Edited Successfully </div> : ''
        }
        {
          resRequestMsg ?
            <div className="alert alert-success cm_top_fix"> Device Requested Successfully </div> : ''
        }
        {
          resFailMsg ?
            <div className="alert alert-danger cm_top_fix"> {responseMessage} </div> : ''
        }

        <div className="cm_breadcrumb">
          <ul>{isAdmin == 1 || isAdmin == 4 || isAdmin == 7 ?
            <li><Link to='/admin-device'>DEVICE MANAGEMENT</Link><div className="cm_breadcrumb_arrow"></div></li> :
            <li><Link to='/employee-device'>DEVICE MANAGEMENT</Link><div className="cm_breadcrumb_arrow"></div></li>
          }
            <li>DEVICE LIST</li>

          </ul>
        </div>

        {/* {device list module start} */}
        {/* <div> */}

        <div className="row d-flex justify-content-end mb-4 ">
          {/* <h3>DEVICE MANAGEMENT</h3> */}
          <div className="col-md-6 search_device_bar select__date" style={{ marginTop: 10, }}>
            <input
              className="form-control mr-lg-2 cm_box"
              type="search"
              placeholder="Search by Device Id"
              aria-label="Search"
              maxLength={40}
              value={search}
              onChange={this.handleSearch}
            // onKeyDown={(e) => { if (e.key === 'Enter') this.getDevice() }}
            />
            {search ? (
              <span className="cm_clear" onClick={this.handleSelectChange}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span className="cm_clear">
                <i className="fa fa-search" aria-hidden="true"></i>
              </span>
            )}
          </div>
        </div>
        <h3 className="mb-4 mt-4 cm_bold">Device Listing</h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No</th>

                <th scope="col">Device Type</th>
                <th scope="col">Device Modal</th>
                <th scope="col">Device ID</th>
                <th scope="col">RAM</th>
                <th scope="col">Status</th>
                <th scope="col">Device Brand</th>
                <th scope="col">Device Location</th>

                <th scope="col">Purchased Date<i
                  className={this.state.sortType === 1 && this.state.sortField === "dateAdded" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                  onClick={() => this.handleSort('purchasedDate')}></i></th>
                {/* <th scope="col">Device Manager</th> */}
                {/* <th scope="col">Assigned</th> */}
                <th scope="col">Action</th>

              </tr>
            </thead>
            <tbody>
              {
                !_.isEmpty(deviceList) && isAdmin === 1 || isAdmin == 7 || isAdmin == 4 ?
                  deviceList.map((device, i) => (
                    <tr key={device.deviceId}>
                      <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                      <td>{this.deviceValue(device.deviceType)}</td>
                      <td><span className="cm_col">
                        <Link to={`/admin-device/device-detail/${device?.deviceId}`}>
                          {device.deviceModel ? device.deviceModel : "_"}
                        </Link>
                      </span></td>
                      <td className="blue" onClick={() => this.handleOpenDeviceInfoPopup(device)}><span className="cm_col">{device.devId}</span></td>
                      <td><span className="cm_col">{device.ram}</span></td>
                      <td><span className="cm_col">{device.status ? this.deviceStatusType(device.status) : '_'}</span></td>
                      <td><span className="cm_col">{device.brand}</span></td>
                      <td><span className="cm_col">{this.deviceLocationType(device.officeLocation)}</span></td>
                      <td>{moment.unix(device.purchasedDate / 1000).format("DD/MM/YYYY")}</td>
                      {/* <td>{device.deviceManager}</td> */}
                      {/* <td>{device.firstName} {device.lastName}</td> */}
                      <td>
                        <div className="cm_device">

                          <Link onClick={() => this.handleOpenEditDevicePopup(device)}>
                            <i className="fa fa-pencil" aria-hidden="true" title="Edit device" style={{ cursor: "pointer" }}></i></Link>
                          <Link onClick={() => this.handleOpenDeleteDevicePopup(device.deviceId)}><i className="fa fa-trash-o ml-4 red" title="Delete device" style={{ cursor: "pointer" }}></i></Link>

                        </div>
                      </td>
                    </tr>
                  )) : deviceList.map((device, i) => (
                    <tr key={device.deviceId}>
                      <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                      <td>{this.deviceValue(device.deviceType)}</td>
                      <td><span className="cm_col">
                        <Link to={`/admin-device/device-detail/${device?.deviceId}`}>
                          {device.deviceModel ? device.deviceModel : "_"}
                        </Link>
                      </span></td>
                      <td className="blue" onClick={() => this.handleOpenDeviceInfoPopup(device)}><span className="cm_col">{device.devId}</span></td>
                      <td><span className="cm_col">{device.ram}</span></td>
                      <td><span className="cm_col">{device.status ? this.deviceStatusType(device.status) : '_'}</span></td>
                      <td><span className="cm_col">{device.brand}</span></td>
                      <td><span className="cm_col">{this.deviceLocationType(device.officeLocation)}</span></td>

                      <td>{moment.unix(device.purchasedDate / 1000).format("DD/MM/YYYY")}</td>
                      {/* <td>{device.deviceManager}</td> */}
                      {/* <td>{device.firstName} {device.lastName}</td> */}
                      <td>
                        <div className="cm_device">
                          {device.status == 2 ?
                            <Link onClick={() => this.handleDeviceRequest(device.deviceId)}>Request</Link> : ""}
                          {/* <Link onClick={() => this.handleOpenDeleteDevicePopup(device.deviceId)}><i className="fa fa-trash-o ml-4 red" title="Delete device"></i></Link> */}

                        </div>
                      </td>
                    </tr>
                  ))
              }

            </tbody>
          </table>
          {deviceList.length ? '' : <h3 className="text-center">No data available</h3>}
        </div>
        {dataCount > 50 ? <Pagination
          activePage={pageNo}
          itemsCountPerPage={count}
          totalItemsCount={dataCount}
          pageRangeDisplayed={3}
          onChange={this.handlePageChange}
        />
          : ''}

        {/* </div> */}
        {/* {device list module end} */}

        {/* {edit device module start} */}
        <AntModal
          centered
          className="sm_modal1"
          footer={false}
          visible={openEditDevicePopup}
          onOk={this.handleOk}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="assign_device" onSubmit={this.handleEditSubmit}>
              {/* {
                resEditMsg ?
                  <div className="alert alert-success cm_top_fix">Edit Device </div> : ''
              } */}
              <h3 className="mb-4">EDIT DEVICE</h3>
              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6 ${devIdErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device ID</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Device ID"
                    name="devId"
                    value={devId}
                    defaultValue={devId}
                    onChange={this.handleChange}
                  />
                  {devIdErrorMsg ? (
                    <span className="error_msg">{devIdErrorMsg}</span>
                  ) : null}
                </div>
                <div className={`form-group col-lg-4 col-md-6 ${deviceTypeErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Device type</label>
                  <Select
                    defaultValue={this.deviceValue(deviceType)}
                    onChange={this.handleDeviceChange}
                  >
                    <Option value="1">Laptop</Option>
                    <Option value="2">Desktop</Option>
                    <Option value="3">Phone</Option>
                    <Option value="4">Mouse</Option>
                    <Option value="5">Connectors</Option>
                    <Option value="6">UPS</Option>
                    <Option value="7">Routers</Option>
                    <Option value="8">Headphone</Option>
                    <Option value="9">WiFi-Adaptor</Option>
                    <Option value="10">Web Camera</Option>
                    <Option value="11">Keyboard</Option>
                    <Option value="12">Monitor</Option>
                    <Option value="13">Pendrive</Option>
                    <Option value="14">Hard Disk</Option>
                    <Option value="15">RAM</Option>
                    <Option value="16">Mac Mini</Option>

                  </Select>
                  {deviceTypeErrorMsg ? (
                    <span className="error_msg">{deviceTypeErrorMsg}</span>
                  ) : null}
                </div>

                <div className={`form-group col-lg-4 col-md-6 ${deviceModalErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Device Model</label>
                  <input
                    maxLength="50"
                    className="form-control  cm_box"
                    type="text"
                    placeholder="Device model"
                    name="deviceModel"
                    value={deviceModel}
                    defaultValue={deviceModel}
                    onChange={this.handleChange}
                  />
                  {deviceModalErrorMsg ? (
                    <span className="error_msg">{deviceModalErrorMsg}</span>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div
                  className={"form-group col-lg-4 col-md-6 "}
                >
                  <label className="cm_bold">Brand</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    defaultValue={brand}
                    placeholder="Brand"
                    name="brand"
                    value={brand}
                    onChange={this.handleChange}
                  />
                  {/* {brandErrorMsg ? (
                    <span className="error_msg">{brandErrorMsg}</span>
                  ) : null} */}
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Service Tag(IMEI)</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Service Tag(IMEI)"
                    name="imei"
                    defaultValue={imei}
                    value={imei}
                    onChange={this.handleChange}
                  />
                </div>

                {/* <div className="col-4">
                  <label className="cm_bold">Acessories Name</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="Acessories name"
                    name="acessories"
                    value={acessories}
                    onChange={this.handleChange}
                  />
                </div> */}
                <div
                  className={`form-group col-lg-4 col-md-6 ${deviceStatusErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device status</label>
                  <Select
                    // value={deviceStatus ? deviceStatus : "Select"}
                    value={this.deviceStatusType(parseInt(deviceStatus))}
                    onChange={this.handleDeviceStatusChange}
                  >
                    <Option value="1">Occupied</Option>
                    <Option value="2">Free</Option>
                    <Option value="3"> Under Repairing</Option>
                    <Option value="4"> Not Working</Option>
                  </Select>
                  {deviceStatusErrorMsg ? (
                    <span className="error_msg">{deviceStatusErrorMsg}</span>
                  ) : null}hdd
                </div>
              </div>

              <div className="row">
                <div
                  className={`form-group col-lg-4 col-md-6`}
                >
                  <label className="cm_bold">RAM</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="RAM"
                    name="ram"
                    defaultValue={ram}
                    value={ram}
                    onChange={this.handleChange}
                  />
                  {/* {ramErrorMsg ? (
                    <span className="error_msg">{ramErrorMsg}</span>
                  ) : null} */}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6`}
                >
                  <label className="cm_bold">HDD/SDD</label>
                  <input
                    maxLength="50"
                    className="form-control cm_box"
                    type="text"
                    placeholder="HDD/SDD"
                    name="hdd"
                    value={hdd}
                    onChange={this.handleChange}
                  />
                  {/* {hddErrorMsg ? (
                    <span className="error_msg">{hddErrorMsg}</span>
                  ) : null} */}
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Operating System</label>
                  <Select
                    //defaultValue={this.operatingSystemType(operatingSystem)}
                    value={
                      operatingSystem
                        ? this.operatingSystemType(operatingSystem)
                        : "Select"
                    }
                    onChange={this.handleOperatingSystemChange}
                  >
                    <Option value="1">windows</Option>
                    <Option value="2">linux</Option>
                    <Option value="3">MacOS</Option>
                    <Option value="4">Android</Option>
                  </Select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Password</label>
                  <input
                    className="form-control cm_box"
                    type="text"
                    placeholder="Password"
                    name="password"
                    maxLength="50"
                    value={password}
                    onChange={this.handleChange}
                  />
                </div>

                <div className={`form-group col-lg-4 col-md-6 ${assignedToErrorMsg ? "input_error" : ""
                  }`}>
                  <label className="cm_bold">Assign to Employee</label>
                  <div className="sm_search">
                    {deviceStatus && deviceStatus == 1 ? <Select
                      showSearch
                      name="employeeId"
                      placeholder="Select Employee"
                      value={assignedTo ? assignedTo : "Assigned to"}
                      // defaultValue={firstName}
                      onSearch={this.handleEmployeeSearch}
                      optionFilterProp="key"
                      filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                      onChange={this.handleChangeAssignedToChange}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}
                                </span>
                              </Option>

                            )
                        )
                        : ""}
                    </Select> : <Select
                      name="employeeId"
                      placeholder="Select Employee"
                      value={assignedTo ? assignedTo : "Assigned to"}
                      // defaultValue={firstName}
                      onChange={this.handleChangeAssignedToChange}
                      disabled
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={employee.employeeId}
                                value={employee.employeeId}
                              >
                                <span>
                                  {employee.firstName} {employee.lastName}
                                </span>
                              </Option>

                            )
                        )
                        : ""}
                    </Select>}
                    {assignedToErrorMsg ? (
                      <span className="error_msg">{assignedToErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-4">
                  <label className="cm_bold">Assigned Date</label>
                  {deviceStatus && deviceStatus == 1 ? <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedDate ? moment(assignedDate) : ""}
                      allowClear={false}
                      // defaultValue={moment(assignedDate)}
                      className="date-border"
                      onChange={this.handleDateChange}
                      /> </div> : <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedDate ? moment(assignedDate) : ""}
                      allowClear={false}
                      defaultValue={moment(assignedDate)}
                      className="date-border"
                      onChange={this.handleDateChange}
                      disabled
                      />
                  </div>}
                </div> */}


                <div
                  className={`form-group col-lg-4 col-md-6 ${dateAssignErrorMsg ? "input_error" : ""}`}
                >
                  <label className="cm_bold">Date Assigned</label>
                  <div onClick={this.hadleOpenDatePiker}>
                    {deviceStatus && deviceStatus == 1 ? <DatePicker
                      value={assignedFrom ? moment(assignedFrom) : ""}
                      allowClear={false}
                      disabledDate={disabledDate}
                      className="date-border"
                      onChange={this.handleDateChangeAssign}
                    /> : <DatePicker
                      value={assignedDate ? moment(assignedDate) : ""}
                      allowClear={false}
                      disabledDate={disabledDate}
                      className="date-border"
                      onChange={this.handleDateChange}
                      disabled
                    />}

                    {dateAssignErrorMsg ? (
                      <span className="error_msg">{dateAssignErrorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div className="row mt-3"> */}
              {/* <div
                  className={`form-group col-lg-4 col-md-6 ${
                    deviceManagerErrorMsg ? "input_error" : ""
                  }`}
                >
                  <label className="cm_bold">Device Manager</label>
                  <div className=" sm_search">
                    <Select
                      name="employeeId"
                      value={deviceManager ? deviceManager : "Select"}
                      onChange={this.handleManagerChange}
                    >
                      {!_.isEmpty(employeeList)
                        ? employeeList.map(
                            (employee) =>
                              employee.status == 1 && (
                                <Option
                                  key={employee.employeeId}
                                  value={employee.employeeId}
                                >
                                  <span>
                                    {employee.firstName} {employee.lastName}
                                  </span>
                                </Option>
                              )
                          )
                        : ""}
                    </Select>
                    {deviceManagerErrorMsg ? (
                      <span className="error_msg">{deviceManagerErrorMsg}</span>
                    ) : null}
                  </div>
                </div> */}
              {/* </div> */}
              <div className="row">

                <div className="form-group col-lg-4 col-md-6">
                  <label className="cm_bold">Assigned Till</label>
                  {deviceStatus && deviceStatus == 2 ? <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedTill ? moment(assignedTill) : ""}
                      allowClear={false}
                      // defaultValue={moment(assignedDate)}
                      className="date-border"
                      disabledDate={disabledDate}
                      onChange={this.handleDateChangeAssignTill}
                    /> </div> : <div onClick={this.hadleOpenDatePiker}>
                    <DatePicker
                      value={assignedDate ? moment(assignedDate) : ""}
                      allowClear={false}
                      defaultValue={moment(assignedDate)}
                      className="date-border"
                      onChange={this.handleDateChange}
                      disabled
                    />
                  </div>}
                </div>
                <div className={"form-group col-lg-4 col-md-6"}>
                  <label className="cm_bold">Comment</label>
                  <textarea
                    maxLength={500}
                    className="form-control  cm_box"
                    type="text"
                    placeholder="comment"
                    name="comment"
                    value={comment}
                    onChange={this.handleChange}
                  />
                </div>
                <div
                  className={`form-group col-lg-4 col-md-6 ${deviceOfficeLocationErrorMsg ? "input_error" : ""
                    }`}
                >
                  <label className="cm_bold">Device Location</label>
                  <Select
                    value={officeLocation ? this.deviceLocationType(officeLocation) : "Select"}
                    onChange={this.handleDeviceLocationChange}
                  >
                    <Option value={1}>Noida</Option>
                    <Option value={2}>Lucknow</Option>
                  </Select>
                  {deviceOfficeLocationErrorMsg ? (
                    <span className="error_msg">{deviceOfficeLocationErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className='row'>
                <div
                  className={`form-group col-lg-4 col-md-6`}
                >
                  <label className="sub" for="upload-files">
                    Upload Picture
                  </label>
                  <span className="form-control cm_hide_upload">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <span className="cm_upload_name">Upload File</span>
                    <input
                      type="File"
                      accept=".png, .jpg, .jpeg"
                      // type="file"
                      className=""
                      // accept=".png , .jpeg"
                      title=""
                      multiple
                      id="upload-files"
                      onChange={this.handleFileChange}
                    />
                    {this.state.miniloader && <div className="mini_loader_device"></div>}

                  </span>

                  {uploadPictureErrorMsg ? (
                    <span className="error_msg">{uploadPictureErrorMsg}</span>
                  ) : null}
                </div>

                <div
                  className={`form-group col-lg-4 col-md-6 ${uploadDocumentErrorMsg} ? "input_error" : ""}`}
                >
                  <label className="sub" for="upload-files">
                    Upload Document
                  </label>
                  <span className="form-control cm_hide_upload">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <span className="cm_upload_name">Upload File</span>
                    <input
                      type="File"
                      // accept=".png, .jpg, .jpeg"
                      // type="file"
                      className=""
                      // accept=".png , .jpeg"
                      title=""
                      multiple
                      id="upload-files"
                      onChange={this.handleDocumentChange}
                    />
                    {this.state.miniloader && <div className="mini_loader_device"></div>}

                  </span>
                  {uploadDocumentErrorMsg ? (
                    <span className="error_msg">{uploadDocumentErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-10">

                <div className="row">

                  <div className="form-group col-12">
                    {uploadPicture.length > 0 ? (
                      <>
                        <label className="sub">Selected Images: </label>
                        <div
                          className="date-border"
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            padding: "10px",
                            overflow: "auto",
                            minHeight: "200px",
                            maxHeight: "500px",
                          }}
                        >


                          {uploadPicture.length > 0 ? (
                            uploadPicture.map((item, index) => (

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#edfbff",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  height: "3.5rem",
                                  margin: "5px",
                                }}
                              >
                                {item.name ?
                                  item.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/) ?

                                    <img
                                      src={URL.createObjectURL(item)}
                                      className="image-size"
                                    />
                                    : item.name.match(/\.(doc|docx|odt|dotm|dotm|dotx|docm)$/) ? <div className="image-size"><DocIcon /></div> : item.name.match(/\.(xlx|xlsx|xls)$/) ? <div className="image-size"><ExcelIcon /></div> : item.name.match(/\.(pdf|PDF)$/) ? <div className="image-size"><PdfIcon /></div> : <div className='image-size'><DocIcon /></div>
                                  : <img src={item}
                                    className='image-size'
                                  >
                                  </img>}
                                <label style={{ width: "80%" }}>
                                  {item.name ? item.name : item}

                                </label>
                                <div
                                  onClick={() =>
                                    this.handleDeleteIconClick(index)
                                  }
                                >
                                  <IoMdClose
                                    style={{
                                      color: "#347bde",
                                      cursor: "pointer",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <label
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              No files selected !
                            </label>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-10">
                <div className="row">

                  <div className="form-group col-12">

                    {uploadDocument.length > 0 ? (
                      <>
                        <label className="sub">Selected Document: </label>
                        <div
                          className="date-border"
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            padding: "10px",
                            overflow: "auto",
                            minHeight: "200px",
                            maxHeight: "500px",
                          }}
                        >
                          {uploadDocument.length > 0 ? (
                            uploadDocument.map((item, index) => (

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#edfbff",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  height: "3.5rem",
                                  margin: "5px",
                                }}
                              >


                                {item.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/) ?

                                  <img
                                    src={URL.createObjectURL(item)}
                                    className="image-size"
                                  /> : item.name.match(/\.(doc|docx|odt|dotm|dotm|dotx|docm)$/) ? <div className="image-size"><DocIcon /></div> : item.name.match(/\.(xlx|xlsx|xls)$/) ? <div className="image-size"><ExcelIcon /></div> : item.name.match(/\.(pdf|PDF)$/) ? <div className="image-size"><PdfIcon /></div> : <div className='image-size'><DocIcon /></div>
                                }
                                <label style={{ width: "80%" }}>
                                  {item.name}
                                </label>
                                <div
                                  onClick={() =>
                                    this.handleDeleteDocumentIconClick(index)
                                  }
                                >
                                  <IoMdClose
                                    style={{
                                      color: "#347bde",
                                      cursor: "pointer",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <label
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              No files selected !
                            </label>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="submit_button_margin mt-4">
                <button className="btn btn-primary cm_btn3">
                  Save
                </button>

                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary cm_btn2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </AntModal>

        {/* {edit device module end} */}

        {/* {delete device module start} */}
        <Modal
          size="md"
          show={openDeleteDevicePopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="submit_button_margin mt-4 confirmation">

            <p className="cm_bold cnfrm_msg">Are you sure, you want to delete this assign device?</p>
            {miniLoader ? <div className="mini_loader_dept"></div> : ""}
            <div className='submit_button_margin mt-4'>

              <button className="btn btn-danger cm_btn3" onClick={this.handleDelete} type="submit">Delete</button>
              <button type="button" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>
            </div>

          </Modal.Body>
        </Modal>
        {/* {delete device module end} */}
        {/* {device detail module start} */}
        <Modal
          className="cm_deviceInfo"
          size="md"
          show={openDeviceInfoPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <form className="assign_device">

              <h3 className="mb-5">DEVICE DETAIL</h3>
              <div className="cm_overflow_device">
                <div className="row cm_row">
                  <div className="col-6 cm_bold col1">Device ID</div>
                  <div className="col-6 cm_bold col2">{devId}</div>
                  <div className="col-6 cm_bold col1">Device Type</div>
                  <div className="col-6 cm_bold col2">{this.deviceValue(deviceType)}</div>
                  <div className="col-6 cm_bold col1">Device Model</div>
                  <div className="col-6 cm_bold col2">{deviceModel ? deviceModel : '-'}</div>
                  <div className="col-6 cm_bold col1">Brand</div>
                  <div className="col-6 cm_bold col2">{brand}</div>
                  <div className="col-6 cm_bold col1">Service Tag(IMEI)</div>
                  <div className="col-6 cm_bold col2">{imei ? imei : '-'}</div>
                  {/* <div className="col-6 cm_bold col1">Acessories Name</div>
                  <div className="col-6 cm_bold col2">{acessories.length === 0 ? '-' : ` ${acessories}`}</div> */}
                  <div className="col-6 cm_bold col1">RAM</div>
                  <div className="col-6 cm_bold col2">{ram}</div>
                  <div className="col-6 cm_bold col1">HDD/SDD</div>
                  <div className="col-6 cm_bold col2">{hdd}</div>
                  <div className="col-6 cm_bold col1">Operating System</div>
                  <div className="col-6 cm_bold col2">{operatingSystem ? this.operatingSystemType(operatingSystem) : '-'}</div>
                  <div className="col-6 cm_bold col1">Password</div>
                  <div className="col-6 cm_bold col2">{password ? password : '-'}</div>
                  {/* <div className="col-6 cm_bold col1">Assign to Employee</div>
                  <div className="col-6 cm_bold col2">{firstName ? firstName : '-'} {lastName ? lastName : ''}</div> */}
                  <div className="col-6 cm_bold col1">Purchased Date</div>
                  <div className="col-6 cm_bold col2">{purchasedDate ? moment.unix(purchasedDate / 1000).format("DD/MM/YYYY") : "-"}</div>
                  <div className="col-6 cm_bold col1">Comment</div>
                  <div className="col-6 cm_bold col2">{comment ? comment : "-"}</div>
                  {/* <div className="col-6 cm_bold col1">Device Pictures</div>
                  <div className="customer-content-ul ">
                    {devicePictures ? devicePictures.map((devicePictures) =>
                      <img src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>

                    ) : ""}</div>
                  <div className="col-6 cm_bold col1">Device Documents</div>
                  <div className="customer-content-ul">
                    {deviceDocs ? deviceDocs.map((deviceDocs) => (
                      deviceDocs.includes(".pdf") == false ?
                        <img
                          src={deviceDocs}
                          className="image-size mr-2"
                          onClick={() => this.handleFullImage(deviceDocs)}
                        /> :
                        <div onClick={() => this.handleFullImage(deviceDocs)} style={{ width: "100px" }}>
                         
                          <PdfIcon />

                        </div>
                    )

                    ) : ""}
                  </div> */}



                  <div className="col-6 cm_bold col1">Device Pictures</div>
                  <div className="container">
                    <div className="customer-content-ul image-container" >
                      {/* {devicePictures ? devicePictures.map((devicePictures) =>
                        // <a href={devicePictures} target="_blank">
                        <img className="image" src={devicePictures} onClick={() => this.handleFullImage(devicePictures)}></img>
                        // </a>

                      ) : ""} */}


                      {devicePictures ? devicePictures.map((devicePictures) => (
                        devicePictures.includes(".pdf") == false ?
                          <div
                            className="date-border"
                            style={{
                              borderRadius: "8px",
                              borderWidth: "1px",
                              padding: "10px",
                              overflow: "auto",
                              minHeight: "100px",
                              maxHeight: "500px",
                              marginBottom: "10px"
                            }}
                            onClick={() => this.handleFullImage(devicePictures)}
                          >

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#edfbff",
                                borderRadius: "8px",
                                padding: "10px",
                                height: "3.5rem",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={devicePictures}
                                className="image-size mr-2"
                              />
                              <label style={{ width: "80%" }}>
                                {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                              </label>
                            </div>
                          </div>
                          :
                          <div onClick={() => this.handleFullImage(devicePictures)} >
                            {/* <PDFReader url={devicePictures}>
                            </PDFReader> */}
                            {/* <PdfIcon /> */}
                            <>
                              <div
                                className="date-border"
                                style={{
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  padding: "10px",
                                  overflow: "auto",
                                  minHeight: "100px",
                                  maxHeight: "500px",
                                  marginBottom: "10px"

                                }}
                              >

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#edfbff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "3.5rem",
                                    margin: "5px",
                                  }}
                                >
                                  <div className="image-size"><PdfIcon /></div>
                                  <label style={{ width: "80%" }}>
                                    {devicePictures ? getFileNameFromUrl(devicePictures) : ""}
                                  </label>
                                </div>
                              </div>
                            </>
                          </div>
                      )

                      ) : ""}

                    </div>
                    {/* <div className="popup-image">
                    <span>&times;</span>
                    <img src={devicePictures} alt="" />
                  </div> */}
                  </div>

                  <div className="col-6 cm_bold col1">Device Documents</div>
                  <div className="container">
                    <div className="customer-content-ul image-container">
                      {deviceDocs ? deviceDocs.map((deviceDocs) => (
                        deviceDocs.includes(".pdf") == false ?
                          <div
                            className="date-border"
                            style={{
                              borderRadius: "8px",
                              borderWidth: "1px",
                              padding: "10px",
                              overflow: "auto",
                              minHeight: "100px",
                              maxHeight: "500px",
                              marginBottom: "10px"

                            }}
                            onClick={() => this.handleFullImage(deviceDocs)}
                          >

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#edfbff",
                                borderRadius: "8px",
                                padding: "10px",
                                height: "3.5rem",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={deviceDocs}
                                className="image-size mr-2"
                              />
                              <label style={{ width: "80%" }}>
                                {devicePictures ? getFileNameFromUrl(deviceDocs) : ""}
                              </label>
                            </div>
                          </div>
                          :
                          <div onClick={() => this.handleFullImage(deviceDocs)} >
                            {/* <PDFReader url={deviceDocs}>
                            </PDFReader> */}
                            {/* <PdfIcon /> */}
                            <>
                              <div
                                className="date-border"
                                style={{
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  padding: "10px",
                                  overflow: "auto",
                                  minHeight: "100px",
                                  maxHeight: "500px",
                                  marginBottom: "10px"

                                }}
                              >

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#edfbff",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "3.5rem",
                                    margin: "5px",
                                  }}
                                >
                                  <div className="image-size"><PdfIcon /></div>
                                  <label style={{ width: "80%" }}>
                                    {devicePictures ? getFileNameFromUrl(deviceDocs) : ""}
                                  </label>
                                </div>
                              </div>
                            </>
                          </div>
                      )

                      ) : ""}
                    </div>
                  </div>
                </div>
              </div>



            </form>
          </Modal.Body>
        </Modal>
        {/* {device detail module end} */}

        {/* full screen Image */}
        <DeviceFullScreenImage
          preview={preview}
          fullImage={fullImage}
          closeModal={this.closeFullimage}
        />
      </div>

    );
  }
}
export default DeviceList;

import React from 'react'
import "./team.css"
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import { isArray } from 'lodash';
import { statusValue } from '../attendance/Constants';
import { attendanceDrillDownFunction, dataSorting, leaveDrillDownFunction, leaveValueColor, removeKeys, statusValueColor } from './teamFunction';
import { leaveValue } from '../leaves/Constants';
drilldown(Highcharts)
export default function Team(props) {
    const { attendance, project, avgHours, totalAttendanceCount, leave, leaveCount } = props
    let attendance1 = dataSorting(attendance)
    const attendanceData = isArray(attendance1) ? attendance1?.map(item => ({
        "name": statusValue(item._id),
        "y": (item.data.length / totalAttendanceCount) * 100,
        "color": statusValueColor(item._id),
        "drilldown": statusValue(item._id),
    })) : "";

    const atteendanceDrilldownSeries = isArray(attendance1) ? attendance1.map(data => ({
        "name": statusValue(data._id),
        "id": statusValue(data._id),
        "data": attendanceDrillDownFunction(data?.data),
    })) : "";
    let leave1 = dataSorting(leave)

    const leaveData = isArray(leave1) ? leave1?.map(item => ({
        "name": leaveValue(item.leaveType),
        "color": leaveValueColor(item.leaveType),
        "y": (item.total / leaveCount) * 100,
        "drilldown": leaveValue(item.leaveType)
    })) : "";

    const leaveDrilldownSeries = isArray(leave1) ? leave1.map(data => ({
        "name": leaveValue(data.leaveType),
        "id": leaveValue(data.leaveType),
        "data": leaveDrillDownFunction(data)
    })) : "";

    const attendanceChartOptions = {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Attendance Stats',
            align: 'center'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },
        series: [
            {
                name: 'Attendance Stats',
                colorByPoint: true,

                plotOptions: {
                    series: {
                        borderRadius: 5,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: {point.y:.1f}%'
                        },
                        startAngle: 90
                    }
                },
                tooltip: {
                    enabled: true,
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b><br/>'
                },
                data:
                    attendanceData.length > 0 ? attendanceData : [{
                        name: 'No data available',
                        color: "black",
                        y: 0,
                        // z: 92
                    }]
                ,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.point.name + ': ' + this.y.toFixed(2) + '%';
                    }
                },
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, '#003399'],
                        [1, '#3366AA']
                    ]
                }, color: {
                    radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
                    stops: [
                        [0, '#003399'],
                        [1, '#3366AA']
                    ]
                }
            }
        ],
        drilldown: {
            plotOptions: {
                series: {
                    borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.1f}%'
                    },
                    startAngle: 90
                }
            },
            tooltip: {
                enabled: true,
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b><br/>'
            },
            series:
                atteendanceDrilldownSeries
        }
    };

    const leaveChartOptions = {

        chart: {
            type: 'pie'
        },
        title: {
            text: 'Leave Stats',
            align: 'center'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },
        series: [
            {
                name: 'Leave Stats',
                colorByPoint: true,

                plotOptions: {
                    series: {
                        borderRadius: 5,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: {point.y:.1f}%'
                        },
                        startAngle: 90
                    }
                },
                tooltip: {
                    enabled: true,
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b><br/>'
                },
                data:
                    leaveData.length > 0 ? leaveData : [{
                        name: 'No data available',
                        color: "black",
                        y: 0,
                        // z: 92
                    }]
                ,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.point.name + ': ' + this.y.toFixed(2) + '%';
                    }
                },
            }
        ],
        drilldown: {

            plotOptions: {
                series: {
                    borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.1f}'
                    }
                }
            },
            tooltip: {
                enabled: true,
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}</b><br/>'
            },
            series:
                leaveDrilldownSeries
        }
    };

    const projectChartOptions = {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Project Stats',
            align: 'center'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },

        plotOptions: {
            series: {
                borderRadius: 5,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.1f}%'
                }
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.drilldown}</b><br/>'
        },
        series: [
            {
                name: 'Project Stats',
                colorByPoint: true,
                data: project.length > 0 ? [
                    {
                        name: 'In Project',
                        y: Number(((project[0]?.inProjectCount / project[0]?.totalMembersInTeam[0]) * 100).toFixed(2)),
                        color: "#47be7e",
                        drilldown: project[0]?.inProject,
                    },
                    {
                        name: 'Not In Project',
                        y: Number(((project[0]?.notInProjectCount / project[0]?.totalMembersInTeam[0]) * 100).toFixed(2)),
                        color: "#f1524f",
                        drilldown: project[0]?.notInProject
                    },
                ] : [{
                    name: 'No data available',
                    color: "black",
                    y: 0,
                    // z: 92
                }],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.point.name + ': ' + this.y + '%';
                    }
                },
            }
        ],
    };

    return (
        <div>
            <h3>Team Stats</h3>
            <div>
                <div className="col-md-12 mt-5">
                    <div
                        className={`${moment
                            .utc(avgHours * 1000)
                            .format("HH:mm") <
                            moment.utc(32400 * 1000).format("HH:mm") &&
                            moment
                                .utc(avgHours * 1000)
                                .format("HH:mm") >=
                            moment.utc(28800 * 1000).format("HH:mm")
                            ? "cm_card cm_avghouryello"
                            : moment
                                .utc(avgHours * 1000)
                                .format("HH:mm") <
                                moment.utc(32400 * 1000).format("HH:mm")
                                ? "cm_card cm_avghours"
                                : "cm_card cm_avghourgreen"
                            }`}
                    >
                        <div className="text-center">
                            <p className="sm_hours">Avg/Hours</p>
                            <span className="emp_count cm_bold">
                                {moment
                                    .utc(avgHours * 1000)
                                    .format("HH:mm") !== "Invalid date"
                                    ? moment
                                        .utc(avgHours * 1000)
                                        .format("HH:mm")
                                    : moment(0, "minutes").format("HH:mm")}
                                /09:00
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='teamchart_main_div mt-4'>
                <div className='attendance_team_chart'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={attendanceChartOptions}
                    />
                </div>
                <div className='project_team_chart'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={projectChartOptions}
                    />
                </div>
                <div className='leave_team_chart'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={leaveChartOptions}
                    />
                </div>
            </div>
        </div>
    )
}

import {
    ASSIGN_INTERVIEW_CANDIDATE_REQUEST,
    ASSIGN_INTERVIEW_CANDIDATE_SUCCESS,
    ASSIGN_INTERVIEW_CANDIDATE_FAILURE,
} from "./actionType";

import { assignInterviewerCandidate } from "../../config/services/employeeServices";
import { getCandidateById } from "./getCandidateByIdAction";

const assignInterviewCandidateByAdmin = (data1, query, setAssign, setResMsg, setFailResMsg, setLoader, closeModal) => {
    return (dispatch) => {
        dispatch(assignCandidateByAdminRequest());
        assignInterviewerCandidate(data1)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    dispatch(assignCandidateByAdminSuccess(res.data));
                    // setAssign(false);
                    setResMsg(true)
                    setTimeout(() => (
                        closeModal(),
                        dispatch(getCandidateById(query)),
                        setLoader(false)
                    ), 3000);

                } else {
                    dispatch(assignCandidateByAdminFailure(res.data));
                    setLoader(false)
                    setFailResMsg(true)
                }
            })
        // .catch((error) => {
        //     dispatch(assignCandidateByAdminFailure(error));
        //     // setErrorText("Failed to add");
        //     // setFailResMsg(true);
        // });
    };
};

const assignCandidateByAdminRequest = () => {
    return {
        type: ASSIGN_INTERVIEW_CANDIDATE_REQUEST,
    };
};
const assignCandidateByAdminSuccess = (data) => {
    return {
        type: ASSIGN_INTERVIEW_CANDIDATE_SUCCESS,
        payload: data,
    };
};
const assignCandidateByAdminFailure = (error) => {
    return {
        type: ASSIGN_INTERVIEW_CANDIDATE_FAILURE,
        payload: error,
    };
};
export {
    assignInterviewCandidateByAdmin,
    assignCandidateByAdminRequest,
    assignCandidateByAdminSuccess,
    assignCandidateByAdminFailure,
};

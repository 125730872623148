import { handleEmployeeName } from './SalaryFunctions'
import React from 'react'
export default function CombineList(props) {
  return (
    <div>
      <div>
        {props?.bonusEmployees && props?.bonusEmployees?.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="white bg_blue">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Number Of LWP</th>
                  <th scope="col">Arrear</th>
                  <th scope="col">Bonus</th>
                </tr>
              </thead>
              <tbody>
                {props.bonusEmployees.map((data, i) => (
                  <tr key={data.employeeId}>
                    <td>
                      <span className="cm_col">
                        {i + 1}
                      </span>
                    </td>
                    <td>
                      <span className="cm_col">
                        {handleEmployeeName(data?.employeeId, props.employeeList)}
                      </span>
                    </td>
                    <td>
                      <span className="cm_col">
                        {data?.numberOfLwp}
                      </span>
                    </td>
                    <td>
                      <span className="cm_col">
                        {data.arrear}
                      </span>
                    </td>
                    <td>
                      <span className="cm_col">{data?.bonus}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="loaderDiv">
            <h3>No data available</h3>
          </div>
        )}

      </div>
      <button className='btn btn-primary' onClick={props.handleExportSalary}>Export Excel</button>
    </div>
  )
}

import React from "react";
import { Modal } from "react-bootstrap";
export default function DeleteAward(props) {
  const { show, handleClose, handleDelete } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center confirmation">
          <p className="mb-4 cm_bold cnfrm_msg">
            Are you sure you want to delete the Reimbursement?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          <span
            className="btn btn-danger cm_btn3 btn-sm"
            onClick={handleDelete}
          >
            Delete
          </span>
          <button
            onClick={handleClose}
            className="btn btn-primary cm_btn2 btn-sm"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import {
    UPDATE_EMP_RESUME_BY_ADMIN_REQUEST,
    UPDATE_EMP_RESUME_BY_ADMIN_SUCCESS,
    UPDATE_EMP_RESUME_BY_ADMIN_FAILURE,
} from "./actionType";

import { updateEmployeeResume } from "../../config/services/employeeServices";
import { updateResumeByEmpId } from "./getResumeByEmpIdActionUpdate";
import ResponseFilter from "../../ResponseFilter";

const updateEmpResumeByAdmin = (data, empId, setState, handleCancelButtonClick) => {
    return (dispatch) => {
        let params = `employeeId=${empId}&pageNo=${0}&count=${10}`

        dispatch(updateEmpResumeByAdminRequest());
        updateEmployeeResume(data)
            .then((res) => {
                let { status } = ResponseFilter(res)
                if (status) {
                    dispatch(updateEmpResumeByAdminSuccess(res.data))
                    handleCancelButtonClick()
                    setState({ resMsg: true, miniLoader: false })
                    setTimeout(() => setState({ resMsg: false }), 3000);
                    dispatch(updateResumeByEmpId(params));
                    setTimeout(() => window.location.href = `/resumeSection/employeeResumeDetails/${empId}`, 1000)
                }
                else {
                    setState({ loader: false, resFailMessage: true, responseMessage: res?.data?.error?.responseMessage })
                    setTimeout(() => setState({ resFailMessage: false }), 3000);
                }
            })
            .catch((error) => {
                dispatch(updateEmpResumeByAdminFailure(error));
            });
    };
};

const updateEmpResumeByAdminRequest = () => {
    return {
        type: UPDATE_EMP_RESUME_BY_ADMIN_REQUEST,
    };
};
const updateEmpResumeByAdminSuccess = (data) => {
    return {
        type: UPDATE_EMP_RESUME_BY_ADMIN_SUCCESS,
        payload: data,
    };
};
const updateEmpResumeByAdminFailure = (error) => {
    return {
        type: UPDATE_EMP_RESUME_BY_ADMIN_FAILURE,
        payload: error,
    };
};
export {
    updateEmpResumeByAdmin,
    updateEmpResumeByAdminRequest,
    updateEmpResumeByAdminSuccess,
    updateEmpResumeByAdminFailure,
};

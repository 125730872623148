import React, { useState } from "react";
import { connect } from "react-redux";
import { DatePicker, Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

import { editCandidateByAdmin } from "../../redux/actions";
import { CONSTANTS } from "../../config";
import DotLoader from "../../hoc/DotLoader";
import { verifyMail } from "../../utils/loginAuth";
import { useEffect } from "react";

const { Option } = Select;
const skillRatingArray = CONSTANTS.INTERVIEW_SECTION.SKILL_RATING;
const statusArray = CONSTANTS.INTERVIEW_SECTION.INTERVIEW_STATUS;

const EditCandidateModel = ({
  showEditModal,
  setEditShowModal,
  addCandidateByAdmin,
  editCandidateByAdmin,
  loading,
  search,
  pageNo,
  count,
  state,
  sortField,
  sortType, error
}) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [position, setPosition] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [technicalSkill, setTechnicalSkill] = useState("");
  const [totalExperience, setTotalExperience] = useState("");
  const [previousResume, setPreviousResume] = useState("")
  const [resume, setResume] = useState("");
  const [errorText, setErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [interviewId, setInterviewId] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("")
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("")
  const [interviewerNameErrorMessage, setInterewerNameErrorMessage] = useState("")
  const [dobErrorMessage, setDobErrorMessage] = useState("")
  const [positionErrorMessage, setPositionErrorMessage] = useState("")
  const [noticePeriodErrorMessage, setNoticePeriodErrorMessage] = useState("")
  const [technicalSkillErrorMessage, setTechnicalSkillErrorMessage] = useState("")
  const [totalExperienceErrorMessage, setTotalExperienceErrorMessage] = useState("")
  const [resumeErrorMessage, setResumeErrorMessage] = useState("")
  const [resMsg, setResMsg] = useState(false);
  const [resFailMsg, setFailResMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [contactNumber, setContactNumber] = useState()
  const [contactNumberErrorMsg, setContactNumberMessage] = useState("")
  const phoneRegx = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[7896543210]\d{9}$/;
  const handleCancelButtonClick = () => {
    setEmailErrorText("");
    setFirstNameErrorMessage("");
    setLastNameErrorMessage("");
    setInterewerNameErrorMessage("");
    setDobErrorMessage("");
    setPositionErrorMessage("");
    setNoticePeriodErrorMessage("");
    setTechnicalSkillErrorMessage("");
    setTotalExperienceErrorMessage("");
    setResumeErrorMessage("")
    setResume("")
    setErrorText("");
    setContactNumber()
    setContactNumberMessage("")
    setEditShowModal(false);
  };

  const handleLastNameValidation = () => {
    let validate = true;
    if (lastName === "") {
      validate = false;
    }
    else {
    }
    return validate;
  }
  const handleDobValidation = () => {
    let validate = true;
    if (dob === "") {
      validate = false;
    }
    else if (new Date().getTime() - dob < 567993608783) {
      validate = false
    }
    else {
    }
    return validate;
  }

  const handleFirstNameValidation = () => {
    let validate = true;
    if (firstName === "") {
      validate = false;
    }
    else {
    }
    return validate
  }
  const handlePositionValidation = () => {
    let validate = true;
    if (position === "") {
      validate = false;
    }
    else {
    }
    return validate
  }
  const handleNoticePeriodValidation = () => {
    let validate = true;
    if (noticePeriod == "" && noticePeriod !== 0) {
      validate = false;

    }
    else if (Number(noticePeriod) > 90 || Number(noticePeriod) < 0) {
      validate = false

    }
    else {


    }
    return validate
  }


  const handleContactNumberValidation = () => {
    let validate = true;
    if (contactNumber == "") {
      validate = false;
    }
    else if (contactNumber == undefined) {
      validate = false
    }
    else if (contactNumber == null) {
      validate = false
    }
    else if (phoneRegx.test(contactNumber) == false) {
      validate = false
    }
    else {

    }
    return validate
  }

  const handleTechnicalSkillValidation = () => {
    let validate = true;
    if (technicalSkill === "") {
      validate = false;
    }
    else {
    }
    return validate
  }

  const handleTotalExperienceValidation = () => {
    let validate = true;
    if (totalExperience == "" && totalExperience !== 0) {
      validate = false;
    }
    else if (Number(totalExperience) > 50 || Number(totalExperience) < 0) {
      validate = false
    }
    else {

    }
    return validate
  }
  const handleResumeValidation = () => {
    let validate = true;
    if (previousResume && resume == "") {
      validate = true;
    }
    else {
      if (resume === "" && previousResume === "") {
        validate = false;
      }
      else if (!resume?.name.match(/\.(pdf|doc|docx|PDF|DOC|DOCX)$/)) {
        validate = false;

      }
      else if (resume?.size >= 10485760) {
        validate = false;
        // setResumeErrorMessage("please insert File of less then 10 mb")

      }
      else {
      }
    }
    return validate
  }
  const handleUploadButtonClick = async () => {
    if (
      verifyMail(email) === 0 &&
      handleFirstNameValidation(firstName) &&
      handleLastNameValidation(lastName) &&
      handleDobValidation(dob) &&
      handleContactNumberValidation(contactNumber) &&
      handlePositionValidation(position) &&
      handleNoticePeriodValidation(noticePeriod) &&
      handleTechnicalSkillValidation(technicalSkill) &&
      handleTotalExperienceValidation(totalExperience) &&
      handleResumeValidation(resume)
    ) {
      setLoader(true)
      const data = new FormData();
      data.append("interviewId", interviewId);
      data.append("email", email.toLocaleLowerCase());
      data.append("FirstName", firstName.charAt(0).toUpperCase() + firstName.slice(1));
      data.append("LastName", lastName.charAt(0).toUpperCase() + lastName.slice(1));
      data.append("dob", dob);
      data.append("mobile", contactNumber)
      data.append("position", position);
      data.append("noticePeriod", noticePeriod);
      data.append("technicalSkill", technicalSkill.charAt(0).toUpperCase() + technicalSkill.slice(1));
      data.append("totalExperience", totalExperience);
      data.append("resume", resume ? resume : previousResume);
      const query = `search=${search}&pageNo=${pageNo}&count=${count}&sortType=${sortType}&sortField=${sortField}`;
      await editCandidateByAdmin(data, query, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader);
    }
    else {
      // setErrorText("All fields required !");
    }
    setTimeout(() => setResMsg(false), 4000)
    setTimeout(() => setFailResMsg(false), 4000)
  };
  useEffect(() => {
    setFirstName(state?.FirstName);
    setEmail(state?.email);
    setLastName(state?.LastName);
    setDob(state?.dob);
    setPosition(state?.position);
    setNoticePeriod(state?.noticePeriod);
    setTechnicalSkill(state?.technicalSkill);
    setTotalExperience(state?.totalExperience);
    setPreviousResume(state?.resume)
    setInterviewId(state?._id)
    setContactNumber(Number(state?.mobile))
    setResMsg(false)
    setErrorText();

  }, [showEditModal, state])

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const handleFirstNameChange = (param) => {
    setFirstName((param.target.value).trim());
    setFirstNameErrorMessage("");
  };
  const handleLastNameChange = (param) => {
    setLastName((param.target.value).trim());
    setLastNameErrorMessage("");
  };
  const handleEmailChange = (param) => {
    setEmail(/^\s/.test(param.target.value) ? "" : param.target.value);
    setEmailErrorText("");
  };
  const handleDOBChange = (date) => {
    setDob(new Date(date).getTime());
    setDobErrorMessage("")
  };
  const handlePostionAppliedChange = (param) => {
    setPosition(/^\s/.test(param.target.value) ? "" : param.target.value);
    setPositionErrorMessage("");
  };
  const handleNoticePeriodChange = (param) => {
    setNoticePeriod(/^\s/.test(param.target.value) ? "" : param.target.value);
    setNoticePeriodErrorMessage("");
  };
  const handleTotalExperienceChange = (param) => {
    setTotalExperience(/^\s/.test(param.target.value) ? "" : param.target.value);
    setTotalExperienceErrorMessage("");
  };


  const handleContactNumber = (param) => {
    setContactNumber((param.target.value).trim());
    setContactNumberMessage("");

  };
  const handleResumeChange = (param) => {
    setResume(param.target.files[0]);
    setResumeErrorMessage("");
  };
  const handleTechnicalSkillChange = (param) => {
    setTechnicalSkill(/^\s/.test(param.target.value) ? "" : param.target.value);
    setTechnicalSkillErrorMessage("");
  };
  const onSubmitClick = () => {
    handleUploadButtonClick();
    if (verifyMail(email) !== 0) {
      setEmailErrorText("Enter a valid email");
    } else {
      setEmailErrorText("");
    }
    if (handleFirstNameValidation(firstName) == "") {
      setFirstNameErrorMessage("Enter First Name")
    }
    else {
      setFirstNameErrorMessage("")
    }
    if (handleLastNameValidation(lastName) == "") {
      setLastNameErrorMessage("Enter Last Name")
    }
    else {
      setLastNameErrorMessage("")
    }
    if (handleDobValidation(dob) == "") {
      setDobErrorMessage("please Select D.O.B and Candidate Age Should be Greater then 18")
    }
    else if (new Date().getTime() - dob < 567993608783) {
      setDobErrorMessage("Please Select D.O.B and Candidate Age Should be Greater then 18")
    }
    else {
      setDobErrorMessage("")
    }
    if (handlePositionValidation(position) == "") {
      setPositionErrorMessage("Please Enter Position");
    }
    else {
      setPositionErrorMessage("")
    }
    if (handleNoticePeriodValidation(noticePeriod) == "" && noticePeriod !== 0) {

      setNoticePeriodErrorMessage("Notice Period is Required and Should be Positive and Less Then or Equal to 90 Days");
    }
    else if (Number(noticePeriod) > 90 || Number(noticePeriod) < 0) {


      setNoticePeriodErrorMessage("Notice Period is Required and Should be Positive and Less Then or Equal to 90 Days");
    }
    else {

      setNoticePeriodErrorMessage("")
    }

    if (handleTechnicalSkillValidation(technicalSkill) == "") {
      setTechnicalSkillErrorMessage("Please Enter Technical Skill");
    }
    else {
      setTechnicalSkillErrorMessage("")
    }
    if (handleTotalExperienceValidation(totalExperience) == "") {
      setTotalExperienceErrorMessage("Total Experience  is Required and Should be Positive and Less Then or Equal to 50 Years");
    }
    else if (Number(totalExperience) > 90 || Number(totalExperience) < 0 && totalExperience !== 0) {

      setTotalExperienceErrorMessage("Total Experience  is Required and Should be Positive and Less Then or Equal to 50 Years");
    }
    else {

      setTotalExperienceErrorMessage("")
    }
    if (previousResume && resume == "") {
      setResumeErrorMessage("")
    }
    else {
      if (handleResumeValidation(resume) == "") {
        setResumeErrorMessage("Please Upload Resume");
      }
      else if (!resume?.name.match(/\.(pdf|doc|docx|PDF|DOC|DOCX)$/)) {
        setResumeErrorMessage("please Enter Valid File")

      }
      else if (resume?.size >= 10485760) {
        setResumeErrorMessage("please insert File of less then 10 mb")

      }
      else {
        setResumeErrorMessage("")
      }
    }

    if (contactNumber == "") {
      setContactNumberMessage("Please Enter Contact Number")
    }
    else if (contactNumber == undefined) {
      setContactNumberMessage("Please Enter Contact Number")

    }
    else if (contactNumber == null) {
      setContactNumberMessage("Please Enter Contact Number")

    }
    else if (phoneRegx.test(contactNumber) == false) {
      setContactNumberMessage("Please Enter Valid Contact Number")
    }
  };
  return (

    <AntModal
      centered
      className="attend_modal"
      footer={false}
      visible={showEditModal}
      onCancel={handleCancelButtonClick}
    >
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitClick();
          }}
        >
          <h3 className="mb-4">EDIT CANDIDATE</h3>
          {
            resMsg ?
              <div className="alert alert-success cm_top_fix"> Candidate Edited Successfully </div> : ''
          }
          {
            resFailMsg ?
              <div className="alert alert-danger cm_top_fix">{error} </div> : ''
          }
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">First Name</label>
              <input
                placeholder="Enter First Name"
                maxLength="20"
                className="form-control cm_box"
                type="text"
                value={firstName}
                onChange={handleFirstNameChange}
              />
              <span className="errorText">{firstNameErrorMessage}</span>

            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Last Name</label>
              <input
                maxLength="20"
                className="form-control cm_box"
                placeholder="Enter Last Name"
                type="text"
                value={lastName}
                onChange={handleLastNameChange}
              />
              <span className="errorText">{lastNameErrorMessage}</span>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Email</label>
              <input
                maxLength="50"
                className="form-control cm_box"
                placeholder="Please Enter Email"
                type="text"
                value={email}
                onChange={handleEmailChange}
              />
              <span className="errorText">{emailErrorText}</span>
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">D.O.B</label>
              <DatePicker
                className="date-border"
                allowClear={false}
                disabledDate={disabledDate}
                value={dob ? moment(dob) : ""}
                onChange={handleDOBChange}
              />
              <span className="errorText">{dobErrorMessage}</span>

            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Contact Number</label>
              <input
                maxLength="10"
                className="form-control cm_box"
                type="text"
                placeholder="Contact Number"
                value={contactNumber}
                onChange={handleContactNumber}
              />
              <span className="errorText">{contactNumberErrorMsg}</span>

            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Total Experience</label>
              <input
                maxLength="3"
                className="form-control cm_box"
                type="number"
                placeholder="In Years"
                value={totalExperience}
                onChange={handleTotalExperienceChange}
              />
              <span className="errorText">{totalExperienceErrorMessage}</span>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Position Applied</label>
              <input
                maxLength="30"
                className="form-control cm_box"
                type="text"
                placeholder="Enter Position Applied For"
                value={position}
                onChange={handlePostionAppliedChange}
              />
              <span className="errorText">{positionErrorMessage}</span>

            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Notice Period</label>
              <input
                maxLength="30"
                className="form-control cm_box"
                type="number"
                placeholder="In Days"
                value={noticePeriod}
                onChange={handleNoticePeriodChange}
              />
              <span className="errorText">{noticePeriodErrorMessage}</span>

            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label className="cm_bold">Technical Skills</label>
              <textarea
                maxLength={250}
                className="form-control cm_box"
                placeholder="Enter Technical Skill"
                type="text"
                rows={4}
                value={technicalSkill}
                onChange={handleTechnicalSkillChange}
              />
              <span className="errorText">{technicalSkillErrorMessage}</span>

            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <span className="cm_upload_name">
                {/* {resume == "" &&  previousResume ? previousResume : ""} */}
              </span>
              <label className="sub" for="upload-files">
                Upload Resume
              </label>
              <span className="form-control cm_hide_upload">
                <i className="fa fa-upload" aria-hidden="true"></i>
                <span className="cm_upload_name">
                  {resume == "" && previousResume ? previousResume.slice(37) : resume ? resume.name : "Upload File"}
                </span>
                <input
                  type="file"
                  id="file"
                  placeholder="Upload File"
                  accept="application/pdf,.doc,.docx"
                  onChange={handleResumeChange}
                />
              </span>
              <span className="errorText">{resumeErrorMessage}</span>

            </div>

            <div className="addCandidateButtonsDiv">
              <button
                onClick={handleCancelButtonClick}
                type="button"
                className="cancel_btn btn  buttonWidth"
              >
                Cancel
              </button>
              {loader ? <DotLoader /> :
                <button className="btn btn-primary  buttonWidth" type="submit">
                  Submit
                </button>}
            </div>
          </div>
        </form>
      </Modal.Body>
    </AntModal>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.addCandidateByAdmin.loading,
    error: state.editCandidateByAdmin?.error?.error?.responseMessage

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editCandidateByAdmin: (data, query, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader) => {
      dispatch(editCandidateByAdmin(data, query, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCandidateModel);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Modal as AntModal, Select } from "antd";
import ResponseFilter from "../../ResponseFilter";
import {
  deleteReimbursement,
  editReimburse,
  reimburseMonthlyRecord,
} from "../../config/services/employeeServices";
import { Loader } from "../svg";
import styled from "styled-components";
import { CONSTANTS } from "../../config";
import { statusValue } from "../leaves/Constants";
import { ReimbursementTypeConstant, colorType } from "./ReimbursementConstant";
import ViewUploadedDocument from "../helper/ViewUploadedDocument";
import EditReimbursementModal from "./ReimbursementModal/EditReimbursementModal";
import DeleteReimbursementModal from "./ReimbursementModal/DeleteReimbursementModal";
import { Toastify } from "../helper/Constant";
import { param } from "jquery";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
const { Option } = Select;

function disabledDate(current) {
  return current && current > moment().endOf("day");
}

class ReimbursementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reimbursementDetails: [],
      searchYear: "",
      searchMonth: "",
      loader: true,
      pageNo: 1,
      sortType: SORT_TYPE_CONSTANTS.Descending,
      sortField: "created",
      count: 10,
      totalReimburseCount: "",
      openRembInfoPopup: false,
      expenseDescription: "",
      reqMonth: props?.location?.state?.reqMonth || "",
      reqYear: props?.location?.state?.reqYear || "",
      show: false,
      month: "",
      expense: "",
      amount: "",
      expenseDate: "",
      id: "",
      resMsg: false,
      amountErrorMsg: "",
      openRembBillPopup: false,
      resFailMsg: false,
      reimbursementType: "",
      bill: [],
      editReimbursementType: "",
      editTitle: "",
      editUploadFiles: [],
      uploadFileName: [],
      openDeleteModal: false,
      delReimbursementId: "",
      successMessage: "",
      responseMessage: "",
    };
  }

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let pageNo = url.searchParams.get("pageNo");
    let searchMonth = url.searchParams.get("searchMonth");
    let searchYear = url.searchParams.get("searchYear");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");
    let reimburseType = url.searchParams.get("reimburseType");
    let defaultReqMonth = this.props?.location.state?.reqMonth || "";
    let defaultReqYear = this.props?.location.state?.reqYear || "";
    this.setState(
      {
        pageNo: pageNo ? Number(pageNo) : 1,
        sortType: sortType ? sortType : SORT_TYPE_CONSTANTS.Descending,
        sortField: sortField ? sortField : "created",
        searchYear: searchYear || defaultReqYear,
        searchMonth: searchMonth || defaultReqMonth,
        reqYear: searchYear || defaultReqYear,
        reqMonth: searchMonth || defaultReqMonth,
        reimburseType: reimburseType || "",
      },
      () => {
        this.getReimburseMonthlyRecords();
      }
    );
  }

  handleChangeReimbursementType = (e) => {
    if (e === undefined) {
      this.setState({ reimbursementType: "" }, () =>
        this.getReimburseMonthlyRecords()
      );
    } else {
      this.setState({ reimbursementType: e, pageNo: 1 }, () =>
        this.getReimburseMonthlyRecords()
      );
    }
  };

  handleEditReimbursementType = (e) => {
    if (e === undefined) {
      this.setState({ editReimbursementType: "" });
    } else {
      this.setState({ editReimbursementType: e.toString() });
    }
  };
  getReimburseMonthlyRecords = () => {
    let {
      reqYear,
      reqMonth,
      pageNo,
      count,
      sortType,
      sortField,
      reimbursementType,
      searchMonth,
      searchYear,
    } = this.state;
    let params = `searchYear=${searchYear}&searchMonth=${searchMonth}&count=${count}&pageNo=${
      pageNo - 1
    }&sortType=${sortType}&sortField=${sortField}&reimburseType=${reimbursementType}`;
    this.props.history.push(
      `?searchYear=${searchYear}&searchMonth=${searchMonth}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&reimburseType=${reimbursementType}`
    );
    reimburseMonthlyRecord(params).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState(
          {
            reimbursementDetails: resData.reimbursementDetails,
            totalReimburseCount: resData.totalReimburseCount,
            loader: false,
          },
          () => {
            this.handleClose();
          }
        );
      }
    });
  };

  handleStatus = (a) =>
    this.setState({ status: a }, () => this.getReimbursement());

  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo, loader: true }, () =>
      this.getReimburseMonthlyRecords()
    );
  };

  handleShow = (e) => {
    this.setState({
      show: true,
      expenseDate: "",
      amount: "",
      expenseDescription: "",
    });
  };
  handleClose = () => {
    this.setState({
      show: false,
      expenseDate: "",
      amount: "",
      expenseDescription: "",
      amountErrorMsg: "",
      titleErrorMsg: "",
      editUploadFiles: [],
      editReimbursementType: "",
      uploadFileName: "",
      uploadBillErrorMsg: "",
      expenseErrorMsg: "",
      openDeleteModal: false,
      delReimbursementId: "",
    });
  };
  handleValidation = () => {
    let validate = true;
    const { amount, editTitle, expense, editUploadFiles } = this.state;
    const regMatch = /^[^0-9][a-zA-Z0-9_ ]+$/.test(editTitle);
    // if (editUploadFiles.length === 0 || editUploadFiles === undefined) {
    //   validate = false;
    //   this.setState({ uploadBillErrorMsg: "Bill is required" });
    // }
    if (editTitle === "" || editTitle === undefined) {
      validate = false;
      this.setState({ titleErrorMsg: "Title is required" });
    } else if (!regMatch) {
      validate = false;
      this.setState({
        titleErrorMsg:
          "Title cannot start with a number and cannot have special characters",
      });
    }
    if (expense === "" || expense === undefined) {
      validate = false;
      this.setState({ expenseErrorMsg: "Expense description is required" });
    }
    if (amount === "" || amount === undefined) {
      validate = false;
      this.setState({ amountErrorMsg: "Claim amount is required" });
    } else if (amount <= 1) {
      validate = false;
      this.setState({ amountErrorMsg: "Please enter valid amount value" });
    } else if (amount > 200000) {
      validate = false;
      this.setState({ amountErrorMsg: "Amount cannot be more than 2 Lakh" });
    } else {
      this.setState({ amountErrorMsg: "" });
    }
    return validate;
  };
  handleChange = (e) => {
    if (e.target.name === "amount" && e.target.value > 200000) {
      if (e.target.value.length <= 6) {
        this.setState({
          amount: e.target.value,
          amountErrorMsg: "Amount cannot be more than Two Lakh",
        });
      }
    } else {
      this.setState({
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
        [e.target.name + "ErrorMsg"]: "",
      });
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const {
      id,
      month,
      amount,
      expense,
      editTitle,
      editReimbursementType,
      editUploadFiles,
    } = this.state;
    if (this.handleValidation()) {
      this.setState({ loader: true });
      let params = new FormData();
      params.append("reimbursementId", id);
      params.append("expenseDate", month);
      params.append("amount", amount);
      params.append("title", editTitle);
      params.append("reimburseType", editReimbursementType);
      params.append("expenseDescription", expense)
      editUploadFiles.forEach((file, index) => {
          params.append("uploadBill", file);
      });
        editReimburse(params).then((res) => {
        this.setState({ loader: false });
        if (res.data.statusCode === 1) {
          this.getReimburseMonthlyRecords();
          this.setState({
            resMsg: true,
            successMessage: "Reimbursement Edited",
          });
          setTimeout(
            () => this.setState({ resMsg: false, successMessage: "" }),
            3000
          );
        } else {
          this.setState({
            resFailMsg: true,
            responseMessage: res?.data?.error?.responseMessage,
          });
          setTimeout(
            () => this.setState({ resFailMsg: false, responseMessage: "" }),
            3000
          );
        }
      });
    }
  };

  handleDelete = (e) => {
    this.setState({ loader: true });
    e.preventDefault();
    const { delReimbursementId } = this.state;
    const params = {
      reimbursementId: delReimbursementId,
    };
    deleteReimbursement(params)
      .then((res) => {
        this.setState({ loader: false });
        const { status } = ResponseFilter(res);
        if (status) {
          this.getReimburseMonthlyRecords();
          this.setState({
            loader: false,
            resMsg: true,
            successMessage: "Reimbursement Deleted",
          });
          setTimeout(
            () => this.setState({ resMsg: false, successMessage: "" }),
            3000
          );
        } else {
          this.setState({
            loader: false,
            resFailMsg: true,
            responseMessage: res?.data?.error?.responseMessage,
          });
          setTimeout(
            () => this.setState({ resFailMsg: false, responseMessage: "" }),
            3000
          );
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          resFailMsg: true,
          responseMessage: error,
        });
        setTimeout(
          () => this.setState({ resFailMsg: false, responseMessage: "" }),
          3000
        );
      });
  };

  handleOpenRembInfoPopup = (data) => {
    let { expenseDescription, reimbursementId } = data;
    this.setState({
      openRembInfoPopup: !this.state.openRembInfoPopup,
      expenseDescription,
      reimbursementId,
    });
  };

  handleEdit = (e) => {
    this.setState({
      show: true,
      amount: e.amount,
      expense: e.expenseDescription,
      month: moment(e.expenseDate).format("YYYY-MM-DD"),
      id: e.reimbursementId,
      editReimbursementType: e.reimburseType,
      editTitle: e.title,
      editUploadFiles: e.uploadBill,
    });
  };

  handleOpenDeleteModal = (e) => {
    this.setState({
      openDeleteModal: true,
      delReimbursementId: e,
    });
  };
  handleDateChange = (month) => {
    this.setState({
      month: new Date(month),
    });
  };
  closeModal = () =>
    this.setState({
      openRembInfoPopup: false,
      openRembBillPopup: false,
      cill: [],
    });

  handleDownload = (files) => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex === files.length - 1) clearInterval(intervalId);
      window.location.href = files[currentIndex];
      currentIndex++;
    }, 1000 + 1000 * currentIndex);
  };

  handleOpenReimbursementBill = (data) => {
    this.setState({
      openRembBillPopup: !this.state.openRembBillPopup,
      bill: data.uploadBill,
    });
  };

  handleEditTitle = (e) => {
    this.setState({
      editTitle: e.target.value,
    });
  };

  handleDeleteIconClick = (index) => {
    this.setState({
      editUploadFiles: this.state.editUploadFiles.filter(
        (item, itemIndex) => itemIndex !== index
      ),
      // uploadFileName: this.state.uploadFileName.filter(
      //   (item, itemIndex) => itemIndex !== index
      // ),
    });
  };

  handleFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let files = [...this.state.editUploadFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }
    this.setState(
      {
        editUploadFiles: files,
        editUploadFilesErrorMsg: "",
        // uploadFileName: files.map((item) => item.name),
        uploadFileSize: files.map((item) => item.size),
      },
      () => {}
    );
    reader.addEventListener(
      "load",
      () => {
        this.setState({
          billPreview: reader.result,
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  render() {
    let {
      reimbursementDetails,
      loader,
      pageNo,
      count,
      totalReimburseCount,
      reqMonth,
      openRembInfoPopup,
      expenseDescription,
      show,
      amount,
      expense,
      month,
      amountErrorMsg,
      openRembBillPopup,
      bill,
      resMsg,
      resFailMsg,
      reimbursementType,
      editReimbursementType,
      editTitle,
      editUploadFiles,
      titleErrorMsg,
      expenseErrorMsg,
      uploadBillErrorMsg,
      openDeleteModal,
      successMessage,
      responseMessage,
    } = this.state;
    return (
      <div className="body_container cm_bold">
        {loader && <Loader />}
        <Toastify
          resMsg={resMsg}
          resMsgFail={resFailMsg}
          text={successMessage}
          responseMessage={responseMessage}
        />
        <div className="cm_breadcrumb">
          <ul>
            <li>
              <Link to="/reimbursement">REIMBURSEMENT</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li style={{ textTransform: "uppercase" }}>CLAIMS</li>
          </ul>
        </div>
        <div className="d-flex justify-content-end">
          <Select
            value={
              reimbursementType
                ? reimbursementType
                : "Select Reimbursement Type"
            }
            defaultValue="Select Type"
            className="select_year_cal col-md-4"
            name="reimbursementType"
            allowClear={reimbursementType ? true : false}
            onChange={(e) => this.handleChangeReimbursementType(e)}
          >
            <Option value="1">Regular </Option>
            <Option value="2">Tax Saving</Option>
          </Select>
        </div>
        {reqMonth ? (
          <h5 className="mt-5 mb-4 cm_bold">
            {moment(reqMonth, "M/D/YYYY").format("MMMM")}'s Records
          </h5>
        ) : (
          ""
        )}

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Title</th>
                <th>Expense Description</th>
                <th>Amount Claimed</th>
                <th>Amount Approved</th>
                <th>Reimbursement Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(reimbursementDetails)
                ? reimbursementDetails.map((data, i) => (
                    <tr key={data.employeeId}>
                      <td>{i + 1 + (pageNo - 1) * count}</td>
                      <td>{moment(data.expenseDate).format("DD/MM/YYYY")}</td>
                      <td>{data.title}</td>
                      <td
                        className="blue"
                        onClick={() => this.handleOpenRembInfoPopup(data)}
                      >
                        <span className="cm_col">
                          {data.expenseDescription}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {data.amount} {data.currency}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="cm_col">
                          {data.status == 2 ? data.approvedAmount : "_"}
                        </span>
                      </td>
                      <td>{ReimbursementTypeConstant(data.reimburseType)}</td>
                      <td className={colorType(data.status)}>
                        {statusValue(data.status)}
                      </td>
                      <td className="text-center">
                        <div className="d-flex">
                          <i
                            role="button"
                            class="fa fa-eye mr-2"
                            aria-hidden="true"
                            onClick={() =>
                              this.handleOpenReimbursementBill(data)
                            }
                          ></i>
                          {data.status == 1 && (
                            <i
                              role="button"
                              className="fa fa-pencil mr-2"
                              aria-hidden="true"
                              onClick={() => this.handleEdit(data)}
                            ></i>
                          )}
                          {data.status == 1 && (
                            <i
                              role="button"
                              className="fa fa-trash red"
                              aria-hidden="true"
                              onClick={() =>
                                this.handleOpenDeleteModal(data.reimbursementId)
                              }
                            ></i>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
        {reimbursementDetails && reimbursementDetails?.length > 0 ? (
          ""
        ) : (
          <h3 className="text-center"> No data available</h3>
        )}
        {totalReimburseCount > 10 ||
        (totalReimburseCount < 11 &&
          totalReimburseCount > 0 &&
          pageNo === 2) ? (
          <Pagination
            activePage={pageNo}
            itemsCountPerPage={count}
            totalItemsCount={totalReimburseCount}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
        ) : (
          ""
        )}
        <AntModal
          centered
          className="reimbur_info"
          title=""
          footer={false}
          visible={openRembInfoPopup}
          onOk={this.handlePageChange}
          onCancel={this.closeModal}
        >
          <Modal.Body>
            <form className="due_appraisal">
              <h3 className="mb-2 mt-4">EXPENSE DESCRIPTION</h3>
              <div className="cm_overflow_reimbur">
                <p>{expenseDescription}</p>
              </div>
            </form>
          </Modal.Body>
        </AntModal>
        {/* {bill Show modal} */}
        <ViewUploadedDocument
          show={openRembBillPopup}
          data={bill}
          handleClose={this.closeModal}
        />
        {/*  Edit modal------------>*/}
        <EditReimbursementModal
          show={show}
          handleShow={this.handleShow}
          handleClose={this.handleClose}
          disabledDate={disabledDate}
          month={month}
          handleDateChange={this.handleDateChange}
          editReimbursementType={editReimbursementType}
          handleEditReimbursementType={this.handleEditReimbursementType}
          editTitle={editTitle}
          handleEditTitle={this.handleEditTitle}
          titleErrorMsg={titleErrorMsg}
          expense={expense}
          expenseErrorMsg={expenseErrorMsg}
          amount={amount}
          handleChange={this.handleChange}
          amountErrorMsg={amountErrorMsg}
          uploadBillErrorMsg={uploadBillErrorMsg}
          handleFileChange={this.handleFileChange}
          editUploadFiles={editUploadFiles}
          loader={loader}
          handleSubmit={this.handleSubmit}
          handleDeleteIconClick={this.handleDeleteIconClick}
          setState={this.setState.bind(this)}
        />
        <DeleteReimbursementModal
          show={openDeleteModal}
          handleClose={this.handleClose}
          handleDelete={this.handleDelete}
        />
      </div>
    );
  }
}
export default ReimbursementView;
const ActionImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

import {
    ADD_EMP_RESUME_REQUEST,
    ADD_EMP_RESUME_SUCCESS,
    ADD_EMP_RESUME_FAILURE
} from "../actions";

const initialState = {
    loading: false,
    data: [],
    error: "",
  };

const addEmpResumeReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_EMP_RESUME_REQUEST:
        return {
          ...state,
          loading: true,
           data:[],
        };
  
      case ADD_EMP_RESUME_SUCCESS:
        return {
          loading: false,
          data:action.payload,
          error: "",
        };
      case ADD_EMP_RESUME_FAILURE:
        return {
          loading: false,
          data:[],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default addEmpResumeReducer;
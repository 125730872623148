import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import "../resumeSection/ResumeSectionStyles.css";
import { Button, Modal } from "react-bootstrap";
import {
  deleteInvestment,
  getInvestmentDetails,
  getInvestmentDetailsEmp,
} from "../../config/services/employeeServices";
import { Loader } from "../svg";
import Pagination from "react-js-pagination";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import InvestmentFormTable from "./InvestmentFormUtils/InvestmentFormTable";
import { Toastify, isAdmin } from "../helper/Constant";
import { CONSTANTS } from "../../config";
import { Select } from "antd";
import { useDebounce } from "../attendance/Constants";
import _ from "lodash";
import DeleteInvestmentModal from "./InvestmentModal/DeleteInvesmentModal";
import ResponseFilter from "../../ResponseFilter";

const { Option } = Select;
let currentYear = new Date().getFullYear();
const Investment = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [option1, setOption1] = useState("");
  const [data, setData] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalInvest, setInvest] = useState("");
  const [searchName, setSearchName] = useState("");
  const [loader, setLoader] = useState(false);
  const [url, setUrl] = useState(false);
  const [sortType, setSortType] = useState(2);
  const [sortField, setSortField] = useState("created");
  const [taxScheme, setTaxScheme] = useState("");
  const [state, setState] = useState({
    selectYear: "",
    selectYearErr: "",
  });
  const [openDeleteInvestment, setOpenDeleteInvestment] = useState(false);
  const [investmentId, setInvestmentId] = useState("");
  const [investmentYear, setInvestmentYear] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [resMsg, setResMsg] = useState(false);
  const [resMsgFail, setResMsgFail] = useState(false);
  const debouncedSearchTerm = useDebounce(searchName, 1000);
  const SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
  const count = 10;
  const history = useHistory();
  const location = useLocation();
  const id = localStorage.getItem("employeeId");

  const prevUrl = useRef(location.pathname);
  const handleSort = (e) => {
    setSortField(e);
    if (sortType == SORT_TYPE_CONSTANTS.Asscending) {
      setSortType(SORT_TYPE_CONSTANTS.Descending);
    } else {
      setSortType(SORT_TYPE_CONSTANTS.Asscending);
    }
  };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    setSortType(
      filtersFromParams.sortType
        ? filtersFromParams.sortType
        : SORT_TYPE_CONSTANTS.Descending
    );
    setSortField(
      filtersFromParams.sortField ? filtersFromParams.sortField : "created"
    );
    setPageNo(filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1);
    setSearchName(filtersFromParams.search ? filtersFromParams.search : "");
    setTaxScheme(filtersFromParams.scheme ? filtersFromParams.scheme : "");
    setInvestmentYear(
      filtersFromParams.investementYear ? filtersFromParams.investementYear : ""
    );
  }, [history.location.search]);

  const getData = useCallback(() => {
    const baseQuery = `&search=${searchName.trim()}&pageNo=${
      pageNo - 1
    }&count=${count}&sortType=${sortType}&sortField=${sortField}&scheme=${taxScheme}&investementYear=${investmentYear}`;
    let query = baseQuery;
    if (
      window.location.pathname.includes("/investment") &&
      !window.location.pathname.includes("/admin-investment")
    ) {
      history.push(
        `investment?search=${searchName.trim()}&pageNo=${pageNo}&scheme=${taxScheme}`
      );
      setUrl(true);
      query += `&employeeId=${id}`;
    } else {
      history.push(
        `admin-investment?search=${searchName.trim()}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&scheme=${taxScheme}&investementYear=${investmentYear}`
      );
    }
    setLoader(true);
    getInvestmentDetails(query).then((res) => {
      setLoader(false);
      const details = res.data?.responseData?.investments
        ? res.data.responseData.investments.investments
        : [];
      setData(details);
      setInvest(res.data?.responseData?.investments?.totalInvestments);
    });
  }, [
    searchName,
    pageNo,
    sortType,
    sortField,
    url,
    taxScheme,
    id,
    history,
    investmentYear,
  ]);

  useEffect(() => {
    getData();
  }, [
    debouncedSearchTerm,
    pageNo,
    sortType,
    sortField,
    url,
    taxScheme,
    investmentYear,
  ]);

  useEffect(() => {
    const currentUrl = location.pathname;
    if (
      (prevUrl.current.includes("/investment") &&
        !currentUrl.includes("/investment")) ||
      (!prevUrl.current.includes("/investment") &&
        currentUrl.includes("/investment"))
    ) {
      window.location.reload();
    }
    prevUrl.current = currentUrl;
  }, [location.pathname]);

  const setYear = (e) => {
    setOption1(e.target.value);
  };

  const handlePageChange = (pageNo) => {
    setPageNo(pageNo);
  };

  const handlePage = (id, admin) => {
    const currentUrl = location.pathname;
    let param = `investmentId=${id}`;
    if (id.length > 9) {
      getInvestmentDetailsEmp(param).then((res) => {
        let jsonData = JSON.parse(res.data.responseData.investment.info);
        const d = new Date(res.data.time);
        let year = d.getFullYear();
        localStorage.setItem("currentYear", year);
        if (
          prevUrl.current.includes("/investment") &&
          currentUrl.includes("/investment")
        ) {
          history.push(`/investment-form/${id}`);
        } else {
          history.push(`/investment-form/${id}?isadmin=${admin}`);
        }
      });
    }
  };

  const closeModal = () => {
    handleClose();
    setInvestmentId("");
    setOpenDeleteInvestment(false);
    setState((prevState) => ({ ...prevState, selectYearErr: "" }));
  };

  const validation = () => {
    let validate = true;
    if (option1 === "") {
      validate = false;
      setState((prevState) => ({
        ...prevState,
        selectYearErr: "Select Investment Year * ",
      }));
    } else {
      window.location.href = `/investment-form/${option1}`;
      setState((prevState) => ({ ...prevState, selectYearErr: "" }));
    }
    return validate;
  };

  const handleAction = (e) => {
    setTaxScheme(e);
    setPageNo(1);
    if (e === "" || e === undefined) {
      setTaxScheme("");
    }
  };

  const handleSearch = (e) => {
    setSearchName(e.target.value);
    setPageNo(1);
    if (e.target.value === "" || e.target.value === undefined) {
      setSearchName("");
    }
  };

  const handleSelectChange = (e) => {
    setSearchName("");
  };

  function createSelectItems() {
    let items = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2022; i--) {
      let startYear = i;
      let endYear = startYear + 1;
      items.push(
        <Option key={startYear} value={`${startYear}-${endYear}`}>
          {startYear}-{endYear}
        </Option>
      );
    }
    return items;
  }

  const handleInvestmentYear = (e) => {
    setPageNo(1);
    setInvestmentYear(e);
    if (e === "" || e === undefined) {
      setInvestmentYear("");
    }
  };
  const handleDelete = () => {
    setLoader(true);
    let params = {
      investmentId: investmentId,
    };
    deleteInvestment(params)
      .then((res) => {
        setLoader(false);
        let { status } = ResponseFilter(res);
        if (status) {
          setSuccessMessage("Investment form deleted");
          setResMsg(true);
          setTimeout(() => {
            setResMsg(false);
            setSuccessMessage("");
          }, 3000);
          getData();
          closeModal();
        } else {
          setResponseMessage(res?.data?.error?.responseMessage);
          setResMsgFail(true);
          setTimeout(() => {
            setResMsgFail(false);
            setResponseMessage("");
          }, 3000);
          getData();
          closeModal();
        }
      })
      .catch((error) => {
        setResponseMessage(error);
        setResMsgFail(true);
        setTimeout(() => {
          setResMsgFail(false);
          setResponseMessage("");
        }, 3000);
        getData();
        closeModal();
      });
  };

  const handleOpenDelete = (id) => {
    setOpenDeleteInvestment(true);
    setInvestmentId(id);
  };

  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <Toastify
        resMsg={resMsg}
        resMsgFail={resMsgFail}
        text={successMessage}
        responseMessage={responseMessage}
      />
      <h3> INVESTMENT FORM </h3>
      <div className="employeeDetails-container">
        <div className="contentDiv">
          <div className="d-flex justify-content-end mb-4 month_year align-items-center">
            {isAdmin === "5" && !url && (
              <Select
                style={{ width: "12rem" }}
                value={investmentYear ? investmentYear : "Search Year"}
                onChange={handleInvestmentYear}
                allowClear
              >
                {createSelectItems()}
              </Select>
            )}
            <Select
              placeholder="Select Tax Scheme"
              value={taxScheme ? taxScheme : "Select Tax Scheme"}
              onChange={handleAction}
              allowClear
              style={{ width: "12rem" }}
            >
              <Option value="2">Old Tax Scheme</Option>
              <Option value="1">New Tax Scheme</Option>
            </Select>
            {isAdmin === "5" && !url ? (
              <div className="w-25 position-relative">
                <input
                  maxLength={20}
                  className="form-control mr-lg-2 cm_box"
                  type="search"
                  placeholder="Search by Name/Emp Id"
                  aria-label="Search"
                  value={searchName}
                  onChange={handleSearch}
                  autoComplete="off"
                />
                {searchName ? (
                  <span
                    className="position-absolute search_icon"
                    onClick={handleSelectChange}
                  >
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span className="position-absolute search_icon">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                )}
              </div>
            ) : (
              <Button onClick={handleShow}>+ Create Form</Button>
            )}
          </div>
          <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Investment Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <select className="form-control" onChange={setYear}>
                <option value="-">Select Year</option>
                <option value={`${currentYear}-${currentYear + 1}`}>
                  {currentYear}-{currentYear + 1}
                </option>
              </select>
              <div className="d-flex mt-2 ml-2">
                <span className="error mt-2">
                  <b> {state.selectYearErr ? state.selectYearErr : ""}</b>
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
              <Button variant="primary" onClick={validation}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          <>
            <DeleteInvestmentModal
              closeModal={closeModal}
              openDeleteInvestment={openDeleteInvestment}
              handleDelete={handleDelete}
            />
            <InvestmentFormTable
              data={data}
              handlePage={handlePage}
              sortType={sortType}
              sortField={sortField}
              handleSort={handleSort}
              url={url}
              pageNo={pageNo}
              count={count}
              handleOpenDelete={handleOpenDelete}
              setLoader={setLoader}
              getData={getData}
              setResMsg={setResMsg}
              setSuccessMessage={setSuccessMessage}
              setResMsgFail={setResMsgFail}
              setResponseMessage={setResponseMessage}
            />
            {totalInvest > count ? (
              <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={totalInvest}
                pageRangeDisplayed={3}
                onChange={handlePageChange}
              />
            ) : (
              " "
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Investment;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ErrorMessage, InputField } from "../../helper/Constant";
import { PdfIcon } from "../../svg";
import { getFileNameFromUrl } from "../../../config/constants/Constants";

const formatKeyName = (keyName) => {
  if (!keyName) return "";
  return keyName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

const UploadRentModal = (props) => {
  const {
    openUploadModal,
    closeModal,
    uploadFileKeyName,
    handleUploadFileChange,
    uploadFileErr,
    handleUploadFiles,
    setState,
    state,
  } = props;
  let { rentAgreement, landlordPanCard } = state;
  const formattedKeyName = formatKeyName(uploadFileKeyName);
  const [loadImg, setLoadImg] = useState(false);
  return (
    <Modal show={openUploadModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload {formattedKeyName} Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="mb-3">
            <h5 className="mb-2">Rent Agreement</h5>
            <InputField
              type="file"
              id="rent-agreement"
              accept="application/pdf,.png, .jpeg, .jpg"
              placeholder="Enter Title"
              onChange={(e) => handleUploadFileChange(e, "rentAgreement")}
            />
          </div>
          <div className="mt-3 mb-3">
            <h5 className="mb-2">Landlord PanCard</h5>
            <InputField
              type="file"
              id="landlord-pancard"
              accept="application/pdf,.png, .jpeg, .jpg"
              placeholder="Enter Title"
              onChange={(e) => handleUploadFileChange(e, "landlordPanCard")}
            />
          </div>
          <div className="mt-3 mb-3">
            {(rentAgreement || landlordPanCard) && (
              <div className="upload_container">
                <span className="mt-2 mb-2">Uploaded File:</span>
                <div className="ml-1">
                  {[rentAgreement, landlordPanCard].map(
                    (file, index) =>
                      file && (
                        <div className="ml-1" key={index}>
                          {(typeof file === "string" &&
                            file.toLowerCase().endsWith(".pdf")) ||
                          (typeof file !== "string" &&
                            file.name.toLowerCase().endsWith(".pdf")) ? (
                            <div className="d-flex align-items-center mt-2">
                              <div
                                style={{ width: "59px", height: "50px" }}
                                className="d-flex justify-content-center"
                              >
                                <PdfIcon />
                              </div>
                              <span>
                                {typeof file === "string"
                                  ? getFileNameFromUrl(file)
                                  : file.name}
                              </span>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center mt-2">
                              <img
                                alt={
                                  typeof file === "string" ? file : file.name
                                }
                                src={
                                  typeof file === "string"
                                    ? file
                                    : URL.createObjectURL(file)
                                }
                                className="mr-2"
                                style={{ width: "50px", height: "50px" }}
                              />
                              <span>
                                {typeof file === "string"
                                  ? getFileNameFromUrl(file)
                                  : file.name}
                              </span>
                            </div>
                          )}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center flex-column">
        {loadImg ? (
          <div class="snippet" data-title="dot-elastic">
            <div class="stage position-relative">
              <div class="dot-elastic"></div>
            </div>
          </div>
        ) : (
          <div className="submit_button_margin mt-4">
            <button
              type="submit"
              onClick={() =>
                handleUploadFiles({
                  state,
                  setState,
                  closeModal,
                  setLoadImg,
                  newUpload:
                    rentAgreement &&
                    !rentAgreement.name &&
                    landlordPanCard &&
                    !landlordPanCard.name,
                })
              }
              className="btn btn-primary cm_btn3"
              disabled={loadImg || uploadFileErr}
            >
              Upload
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-primary cm_btn2"
              disabled={loadImg}
            >
              Cancel
            </button>
          </div>
        )}
        <div>{uploadFileErr && <ErrorMessage errMsg={uploadFileErr} />}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadRentModal;

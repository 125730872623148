
export const attendanceDrillDownFunction = (employeeCounts) => {

    const nameCounts = {};
    employeeCounts.forEach((item) => {
        const name = item.employeeName
        nameCounts[name] = (nameCounts[name] || 0) + 1;
    });
    const result = Object.entries(nameCounts).map(([name, count]) => [name, count]);
    return result
}


export const leaveDrillDownFunction = (a) => {
    let k = []
    const newObj = { ...a };
    delete newObj["leaveType"]
    delete newObj["total"];
    k = Object.entries(newObj).map(([key, value]) => [key, value]);
    return k;

}

export const findNameById = (employeeList, idToFind) => {
    const foundPerson = employeeList.find(employee => employee.employeeId === idToFind);
    if (foundPerson) {
        return foundPerson.firstName + " " + foundPerson.lastName;

    } else {
        return null; 
    }
}

export const statusValueColor = (type) => {
    switch (type) {
        case 1:
            return "#47be7e";
            break;
        case 2:
            return "#f1524f";
            break;
        case 3:
            return "grey";
            break;
        case 4:
            return "#ffa500";
            break;
        case 5:
            return "#47be7e";
            break;
        case 6:
            return "#cc8500";
            break;
        case 7:
            return "#90EE90";
            break;
        case 8:
            return "#000ff";
            break;
        case 9:
            return "#add8e6";
            break;
        case 10:
            return "#800000";
            break;
        case 11:
            return "#ffc266";
            break;
    }
};


export const leaveValueColor = (type) => {
    switch (type) {
        case 1:
            return "#00ffff";
            break;
        case 2:
            return "#7460ee";
            break;
        case 3:
            return "#ff0000";
            break;
        case 4:
            return "#800000";
            break;
        case 5:
            return "#ff9900";
            break;
        case 6:
            return "#e6ffff";
            break;
        case 7:
            return "#eae8fc";
            break;
        case 8:
            return "#ffe6e6";
            break;
        case 9:
            return "#ffffcc";
            break;
        case 10:
            return "#fdedce";
            break;
        case 11:
            return "grey";
            break;

        case 12:
            return "brown";
            break;
        case 13:
            return "#000000";
            break;
        case 14:
            return "#999999";
            break;
    }
};



export const dataSorting = (attendance) => {
    let k = attendance ? attendance.sort(function (a, b) { return a?._id - b?._id }) : ""
    return k
}
import { Select } from "antd";
import React from "react";
import { Modal } from "react-bootstrap";
import DatePick from "../datePicker/DatePicker";
import { ErrorMessage } from "../../helper/Constant";
const { Option } = Select;

export function OpenUploadAttendanceExcel(props) {
  const {
    show,
    handleClose,
    excelSheetUploadError,
    miniLoader,
    getExcelSheet,
    handleDateChange,
    dateErrorMsg,
    errorCode,
    responseMessage,
    attendanceLocationErrorMsg,
    attendanceLocation,
    handleAttendanceLocation,
    wantSendMailErrorMsg,
    wantSendMail,
    handleWantSendmail,
    handleUploadAttendanceSubmit,
  } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Upload Attendance </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="assign_device">
          <div
            className={`form-group col-8 ${
              excelSheetUploadError ? "input_error" : ""
            }`}
          >
            <label className="cm_bold">ExcelSheet</label>
            <input
              className="input-excel"
              type="file"
              id="uploadExcel"
              accept=".xlx,.xlsx,.xls"
              disabled={miniLoader}
              onChange={(e) => getExcelSheet(e)}
            />
              <ErrorMessage errMsg={excelSheetUploadError} />
          </div>

          <div
            className={`form-group col-8 ${dateErrorMsg ? "input_error" : ""}`}
          >
            <label className="cm_bold">Attendance Date</label>
            <DatePick handleDateChange={handleDateChange} />
            <ErrorMessage errMsg={dateErrorMsg} />
            {errorCode === 24 ? (
              <h3 className="cm_response mt-2">{responseMessage}</h3>
            ) : (
              ""
            )}
          </div>
          <div
            className={`form-group col-8 ${
              attendanceLocationErrorMsg ? "input_error" : ""
            }`}
          >
            <label className="cm_bold"> Please Select Office Location </label>
            <Select
              value={
                attendanceLocation ? attendanceLocation : "Select Location"
              }
              defaultValue="Select Suggestion"
              name="wantSendMail"
              onChange={(e) => handleAttendanceLocation(e)}
              disabled={miniLoader}
            >
              <Option value="1">Noida</Option>
              <Option value="2">Lucknow</Option>
              <Option value="3">All</Option>
            </Select>
            <ErrorMessage errMsg={attendanceLocationErrorMsg} />
          </div>
          <div
            className={`form-group col-8 ${
              wantSendMailErrorMsg ? "input_error" : ""
            }`}
          >
            <label className="cm_bold mt-2"> Want to Send Mail </label>
            <Select
              value={wantSendMail ? wantSendMail : "Select status"}
              defaultValue="Select Suggestion"
              name="wantSendMail"
              onChange={(e) => handleWantSendmail(e)}
              disabled={miniLoader}
            >
              <Option value="1">Yes</Option>
              <Option value="0">No</Option>
            </Select>
            <ErrorMessage errMsg={wantSendMailErrorMsg} />
            {errorCode === 24 ? (
              <h3 className="cm_response mt-2">{responseMessage}</h3>
            ) : (
              ""
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          {miniLoader && <div className="cover-spin1"></div> }
          <button
            type="submit"
            onClick={handleUploadAttendanceSubmit}
            className="btn btn-primary  cm_btn3"
            disabled={miniLoader}
          >
            Upload
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-primary cm_btn2"
            disabled={miniLoader}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

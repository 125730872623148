import {
    ASSIGN_INTERVIEW_REQUEST,
    ASSIGN_INTERVIEW_SUCCESS,
    ASSIGN_INTERVIEW_FAILURE,
} from "./actionType";

import { getInterviews } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getAssignInterviews = (query, data) => {
    return (dispatch) => {
        dispatch(getAssignInterviewsRequest());
        getInterviews(query, data)
            .then((res) => {
                let { status, resData } = ResponseFilter(res);
                if (status) {
                    dispatch(getAssignInterviewsSuccess(res.data.responseData));
                } else {
                    dispatch(getAssignInterviewsFailure(res.data));
                }
            })
            .catch((error) => {
                dispatch(getAssignInterviewsFailure(error));
            });
    };
};

const getAssignInterviewsRequest = () => {
    return {
        type: ASSIGN_INTERVIEW_REQUEST,
    };
};

const getAssignInterviewsSuccess = (data) => {
    return {
        type: ASSIGN_INTERVIEW_SUCCESS,
        payload: data,
    };
};

const getAssignInterviewsFailure = (error) => {
    return {
        type: ASSIGN_INTERVIEW_FAILURE,
        payload: error,
    };
};
export {
    getAssignInterviews,
    getAssignInterviewsRequest,
    getAssignInterviewsSuccess,
    getAssignInterviewsFailure,
};

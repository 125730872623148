import React from "react";
import { Modal as AntModal, DatePicker, Upload } from "antd";
import { Modal } from "react-bootstrap";
import moment from "moment";
import {
  ErrorMessage,
  disabledDate,
  handleChange,
  handleDate,
  type,
} from "../helper/Constant";
import { handleSubmit } from "./CourierConstants";

const EditCourierModal = (props) => {
  const {
    show,
    handleClose,
    name,
    dateSent,
    tentative_receiving_date,
    dateSentErrMsg,
    dispatch,
    cost,
    tracking_id,
    courier_company,
    item_description,
    getCourierList,
    dispatchToAction,
    courier_id,
    courierFile,
    editCourier,
    nameErrMsg,
    tentative_receiving_dateErrMsg,
    costErrMsg,
    tracking_idErrMsg,
    courier_companyErrMsg,
    item_descriptionErrMsg,
    courierFileErrMsg,
  } = props;
  const uploadProps = {
    onRemove: (fileToRemove) => {
      const index = courierFile.findIndex((file) => file === fileToRemove.url);
      const updatedUploadDocument = courierFile.filter(
        (_, itemIndex) => itemIndex !== index
      );
      dispatch({
        type: type,
        payload: {
          courierFile: updatedUploadDocument,
        },
      });
    },
    fileList:
      editCourier && editCourier.length
        ? editCourier
        : courierFile && Array.isArray(courierFile) && courierFile.length >= 1
        ? courierFile
            .filter((url) => typeof url === "string" && !url.endsWith(".zip"))
            .map((url, index) => {
              if (typeof url === "string") {
                return {
                  uid: `-${index}`,
                  name: url.substring(url.lastIndexOf("/") + 1),
                  status: "done",
                  url: url,
                };
              }
              return null;
            })
            .filter(Boolean)
        : [],
    onChange: (info) => {
      if (info.fileList.length) {
        dispatch({
          type: type,
          payload: {
            editCourier: info.fileList,
            courierFileErrMsg: "",
            courierFile: info.fileList,
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            editCourier: [],
            courierFile: [],
          },
        });
      }
    },
    beforeUpload: () => false,
  };

  return (
    <AntModal visible={show} onCancel={handleClose} centered footer={false}>
      <Modal.Header>
        <p className="mb-4 cm_bold cnfrm_msg">EDIT COURIER DETAIL</p>
      </Modal.Header>
      <Modal.Body>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Receiver's Name</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              onChange={(e) => handleChange(e, dispatch, "name")}
            />
            <ErrorMessage errMsg={nameErrMsg} />
          </div>
          <div class="form-group col-6">
            <label className="sub">Date Sent</label>
            <DatePicker
              allowClear={false}
              className="date-border"
              defaultValue={moment(dateSent)}
              onChange={(date) => handleDate(date, dispatch, "dateSent")}
              value={moment(dateSent) ? moment(dateSent) : ""}
            />
            <ErrorMessage errMsg={dateSentErrMsg} />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Cost</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              placeholder="Cost"
              name="cost"
              value={cost}
              onChange={(e) => handleChange(e, dispatch, "cost")}
            />
            <ErrorMessage errMsg={costErrMsg} />
          </div>
          <div class="form-group col-6">
            <label className="sub">Receiving Date</label>
            <DatePicker
              disabledDate={(current) =>
                disabledDate(current, moment(dateSent))
              }
              allowClear={false}
              className=" date-border"
              defaultValue={moment(tentative_receiving_date)}
              onChange={(date) =>
                handleDate(date, dispatch, "tentative_receiving_date")
              }
              value={
                moment(tentative_receiving_date)
                  ? moment(tentative_receiving_date)
                  : ""
              }
            />
            <ErrorMessage errMsg={tentative_receiving_dateErrMsg} />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Tracking ID</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              name="tracking_id"
              placeholder="Tracking id"
              value={tracking_id}
              onChange={(e) => handleChange(e, dispatch, "tracking_id")}
            />
            <ErrorMessage errMsg={tracking_idErrMsg} />
          </div>
          <div class="form-group col-6 ">
            <label className="sub">Courier Company</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              name="courier_company"
              placeholder="Courier company"
              value={courier_company}
              onChange={(e) => handleChange(e, dispatch, "courier_company")}
            />
            <ErrorMessage errMsg={courier_companyErrMsg} />
          </div>
        </div>
        <div className="row cm_bold">
          <div class="form-group col-6">
            <label className="sub">Item Description</label>
            <input
              maxLength="50"
              className="form-control cm_box"
              type="text"
              name="item_description"
              placeholder="Item description"
              value={item_description}
              onChange={(e) => handleChange(e, dispatch, "item_description")}
            />
            <ErrorMessage errMsg={item_descriptionErrMsg} />
          </div>
          <div class="form-group col-12">
            <label className="sub">Upload File</label>
            <Upload
              multiple
              listType="picture"
              {...uploadProps}
              showUploadList={{ showRemoveIcon: false, showPreviewIcon: true }}
            >
              <button className="btn btn-outline-secondary ml-4">Upload</button>
            </Upload>
            <ErrorMessage errMsg={courierFileErrMsg} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div className="submit_button_margin">
          <button
            className="btn btn-primary cm_btn3"
            onClick={(e) => {
              handleSubmit({
                e: e,
                dispatch: dispatch,
                actionType: "edit",
                data: props,
                getCourierList: getCourierList,
                dispatchToAction: dispatchToAction,
                courier_id: courier_id,
              });
            }}
          >
            Edit
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-primary cm_btn2"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </AntModal>
  );
};

export default EditCourierModal;

import {
  GET_EMPLOYEE_RESUME_LIST_REQUEST,
  GET_EMPLOYEE_RESUME_LIST_SUCCESS,
  GET_EMPLOYEE_RESUME_LIST_FAILURE,
} from "../actions";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const getEmpResumeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_RESUME_LIST_REQUEST:
      return {
        ...state,
        loading: true,
         data:[],
      };

    case GET_EMPLOYEE_RESUME_LIST_SUCCESS:
      return {
        loading: false,
        data:action.payload,
        error: "",
      };
    case GET_EMPLOYEE_RESUME_LIST_FAILURE:
      return {
        loading: false,
        data:[],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default getEmpResumeListReducer;
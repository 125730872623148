import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import AdminPendinRequestList from "./device/AdminPendinRequestList";

import {
  ActivityLog,
  ActivityLogEmp,
  AddTicket,
  AddProject,
  AddProjectByManager,
  AdminAttendance,
  AdminDepartment,
  AdminDesignation,
  AdminEmpResumeDetails,
  AdminHome,
  AdminLeave,
  AdminProfile,
  AdminReimbursement,
  AdminSkill,
  AdminTicket,
  Appraisal,
  AppraisalCycle,
  AllActivityLog,
  AllActivityLogByManager,
  AllActivityLogEmp,
  Attendance,
  ChangePassword,
  ChangePasswordEmp,
  ClaimReimbursement,
  CreateEmp,
  DeviceList,
  DeviceListing,
  EmployeeDeviceList,
  EmployeeList,
  EmployeeProfile,
  EmployeeProfileView,
  EmployeeResumeDetails,
  EmployeeViewProject,
  EmpHoliday,
  FoodOrdersListAdmin,
  GetReimbursementByEmp,
  Header,
  Holiday,
  Home,
  Leaves,
  LeaveStatus,
  LeaveView,
  ManagerDevice,
  ManagerDeviceList,
  ManagerViewProject,
  ManagerActivityLog,
  OrderListByVendor,
  Projects,
  ProjectsOfManager,
  ProjectList,
  Reimbursement,
  ReimbursementView,
  reimbursementAdminAction,
  ResumeSection,
  SideBar,
  TransactionList,
  ViewDailyAttendance,
  ViewDepartment,
  ViewDesignation,
  ViewProject,
  ViewSkillEmp,
  InterviewList,
  InterviewerModule,
  CandidateDetails,
  CreateInvestment,
  Templates,
  AnniversaryTemplate,
  BirthdayTemplate,
  WelcomeTemplate,
  ManagerReimbursement,
  ReimbursementManagerAction,
  HrActivity,
  EmployeeHrActivity,
  Contact,
  InterviewerInterviewStatus,
  AdminTeam,
  Team,
} from "./index";
import Investment from "./investmentForm/investmentForm";
import ReimbursementForm from "./reimbursement/ReimbursementForm";
import CompOffLeaveModel from "./leaves/leaveModal/CompOffLeaveModel";
import TeamDetail from "./teamManagement/TeamDetail";
import TeamPersonDetail from "./teamManagement/TeamPersonDetail";
import DeviceDetails from "./device/DeviceDetails";
import EmployeeDeviceListing from "./device/employeeDeviceListing";
import EmployeeDeviceHistory from "./employee/EmployeeDeviceHistory";
import Salary from "./salaryGeneration/Salary";
import AwardList from "./awardManagemnt/AwardList";
import Salaryslip from "./employeeSalary/Salaryslip";
import Courier from "./courier/Courier";
import OrganizationChart from "./organizationChart/OrganizationChart";
import Policies from "./privacy/Privacy";
import Template from "./projects/Templates/Template";

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <SideBar />

        <Switch>
          <Route path="/attendance" component={Attendance} />
          <Route path="/policies" component={Policies} />
          <Route path="/addTicket" component={AddTicket} />
          <Route exact path="/reimbursement" component={Reimbursement} />

          <Route path="/investment-form/:id" component={CreateInvestment} />
          <Route path="/admin-investment" component={Investment} />
          <Route
            exact
            path="/templates"
            component={Templates}
          />
          <Route
            exact
            path="/templates/anniversary-template"
            component={AnniversaryTemplate}
          />
          <Route
            exact
            path="/templates/birthday-template"
            // render={props => <BirthdayTemplate {...props} />}
            component={BirthdayTemplate}
          />
          <Route
            exact
            path="/templates/welcome-template"
            // render={props => <WelcomeTemplate {...props} />}
            component={WelcomeTemplate}
          />

          <Route exact path="/investment" component={Investment} />

          <Route
            exact
            path="/reimbursement/reimbursement-view"
            component={ReimbursementView}
          />
          <Route
            exact
            path="/reimbursement/manager-reimbursement-action"
            component={ReimbursementManagerAction}
          />
          <Route
            exact
            path="/reimbursement/reimbursement-form-print"
            component={ReimbursementForm}
          />
          <Route
            exact
            path="/reimbursement/reimbursement-claim"
            component={ClaimReimbursement}
          />
          <Route
            exact
            path="/reimbursement/manager-reimbursement"
            component={ManagerReimbursement}
          />
          <Route exact path="/admin-employee" component={EmployeeList} />
          <Route
            exact
            path="/admin-employee/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route
            exact
            path="/employee-device/manager-device"
            component={ManagerDevice}
          />
          <Route exact path="/employee-device" component={EmployeeDeviceList} />

          <Route exact path="/admin-device" component={DeviceList} />
          <Route
            exact
            path="/admin-device/request-list"
            component={AdminPendinRequestList}
          />
          <Route
            exact
            path="/admin-device/employee-detail/:id"
            component={EmployeeProfile}
          />
          <Route
            exact
            path="/admin-device/device-detail/:id"
            component={DeviceDetails}
          />

          <Route
            exact
            path="/employee-profile/device-detail/:id"
            component={EmployeeDeviceHistory}
          />

          <Route
            exact
            path="/employee-device/manager-device/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route
            exact
            path="/admin-device/:deviceType"
            component={DeviceListing}
          />
          <Route
            exact
            path="/employee-device/:deviceType"
            component={EmployeeDeviceListing}
          />
          <Route
            exact
            path="/employee-device/manager-device/:deviceType"
            component={ManagerDeviceList}
          />

          <Route path="/admin-employee/create" component={CreateEmp} />
          <Route exact path="/projects" component={Projects} />
          <Route
            exact
            path="/projects/manager-projects"
            component={ProjectsOfManager}
          />
          <Route
            exact
            path="/projects/admin-projects"
            component={ProjectList}
          />

          <Route exact path="/projects/:id" component={EmployeeViewProject} />
          <Route exact path="/admin-projects" component={ProjectList} />

          <Route
            exact
            path="/admin-projects/project-detail/:id"
            component={ViewProject}
          />
          <Route
            exact
            path="/projects/manager-projects/project-detail/:id"
            component={ManagerViewProject}
          />
          <Route
            exact
            path="/admin-projects/employee-detail/:id"
            component={EmployeeProfile}
          />
          <Route
            exact
            path="/projects/manager-projects/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route path="/leaves" component={Leaves} />
          <Route path="/leave-status" component={LeaveStatus} />
          <Route path="/admin-holiday" component={Holiday} />
          <Route path="/holiday" component={EmpHoliday} />
          <Route path="/profile" component={AdminProfile} />
          <Route path="/employee-profile" component={EmployeeProfileView} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/change-password-emp" component={ChangePasswordEmp} />

          <Route
            exact
            path="/admin-reimbursement"
            component={AdminReimbursement}
          />
          <Route
            exact
            path="/admin-reimbursement/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route
            exact
            path="/admin-reimbursement/:id"
            component={GetReimbursementByEmp}
          />
          <Route
            exact
            path="/admin-reimbursement/admin-reimbursement-action/:id"
            component={reimbursementAdminAction}
          />

          <Route exact path="/admin-appraisal" component={Appraisal} />

          <Route
            exact
            path="/admin-appraisal/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route exact path="/admin-appraisal/:id" component={AppraisalCycle} />

          <Route exact path="/admin-ticket" component={AdminTicket} />
          <Route
            exact
            path="/admin-ticket/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route exact path="/admin-leave" component={AdminLeave} />

          <Route
            exact
            path="/admin-leave/leave_view/:id"
            component={LeaveView}
          />
          <Route
            exact
            path="/leave/compoff-leave/:id?/:byAdmin?/:isAdmin"
            component={CompOffLeaveModel}
          />
          <Route
            exact
            path="/admin-leave/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route exact path="/admin-attendance" component={AdminAttendance} />
          <Route
            exact
            path="/admin-attendance/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route
            exact
            path="/admin-attendance/:id"
            component={ViewDailyAttendance}
          />
          <Route exact path="/admin-department" component={AdminDepartment} />
          <Route exact path="/admin-designation" component={AdminDesignation} />

          <Route
            exact
            path="/admin-department/:id"
            component={ViewDepartment}
          />
          <Route
            exact
            path="/admin-department/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route exact path="/admin-skill" component={AdminSkill} />
          <Route exact path="/admin-hrActivity" component={HrActivity} />
          <Route exact path="/user-hrActivity" component={EmployeeHrActivity} />

          <Route exact path="/admin-skill/:id" component={ViewSkillEmp} />
          <Route
            exact
            path="/admin-skill/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route
            exact
            path="/admin-designation/:id"
            component={ViewDesignation}
          />
          <Route
            exact
            path="/admin-designation/employee-detail/:id"
            component={EmployeeProfile}
          />

          <Route exact path="/admin-projects/add" component={AddProject} />
          <Route
            exact
            path="/projects/manager-projects/add"
            component={AddProjectByManager}
          />
          <Route
            exact
            path="/admin-projects/:id/:empId"
            component={ActivityLog}
          />
          <Route
            exact
            path="/projects/manager-projects/:id/:empId"
            component={ManagerActivityLog}
          />
          <Route exact path="/projects/:id/:empId" component={ActivityLogEmp} />

          <Route
            exact
            path="/admin-projects/project-detail/:id/all-activity-log/:id"
            component={AllActivityLog}
          />
          <Route
            exact
            path="/projects/manager-projects/project-detail/:id/all-activity-log/:id"
            component={AllActivityLogByManager}
          />

          <Route
            exact
            path="/projects/:id/all-activity-log-emp/:id"
            component={AllActivityLogEmp}
          />
          <Route
            path="/view-daily-attendance"
            component={ViewDailyAttendance}
          />
          <Route exact path="/" component={AdminHome} />
          <Route exact path="/employee" component={Home} />

          <Route exact path="/resumeSection" component={ResumeSection} />
          <Route
            exact
            path="/resumeSection/employeeResumeDetails/:id"
            component={AdminEmpResumeDetails}
          />
          <Route
            exact
            path="/resumeDetails"
            component={EmployeeResumeDetails}
          />

          <Route
            exact
            path="/resumeSection/employee-detail/:id"
            component={EmployeeProfile}
          />
          <Route exact path="/vendors" component={FoodOrdersListAdmin} />
          <Route
            exact
            path="/vendors/vendorOrders/:id"
            component={OrderListByVendor}
          />
          <Route exact path="/transactions" component={TransactionList} />
          <Route exact path="/interview" component={InterviewList} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/admin-team" component={AdminTeam} />
          <Route exact path="/salary" component={Salary} />
          <Route exact path="/employee-salary-slip" component={Salaryslip} />

          <Route exact path="/admin-team/:id" component={TeamDetail} />
          <Route
            exact
            path="/admin-team/person-detail/:id"
            component={TeamPersonDetail}
          />

          <Route exact path="/team" component={Team} />

          <Route exact path="/interviewer" component={InterviewerModule} />

          {/* <Route exact path="/EditCandidateModel" component={EditCandidateModel}/> */}
          <Route
            exact
            path="/interview/candidateDetails/:id"
            component={CandidateDetails}
          />

          <Route
            exact
            path="/interview/InterviewerStatus/:id"
            component={InterviewerInterviewStatus}
          />
          <Route exact path="/awards" component={AwardList} />

          <Route exact path="/courier" component={Courier} />
          <Route exact path="/organization-chart" component={OrganizationChart} />
          <Route exact path="/project/template" component={Template} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Layout;

import {
    GET_EMPLOYEE_MONTHLY_SALARY_STATS_REQUEST,
    GET_EMPLOYEE_MONTHLY_SALARY_STATS_SUCCESS,
    GET_EMPLOYEE_MONTHLY_SALARY_STATS_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { getEmployeeMonthlySalaryStats } from "../../config/services/employeeServices";
import { decryptResponseData } from "../../components/salaryGeneration/encryptionAndDecryption";

const getEmployeeMonthlySalaryStatsAction = (props) => {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getEmployeeMonthlySalaryStatsActionRequest());
        getEmployeeMonthlySalaryStats(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                let employeesMonthlySalaryStats = decryptResponseData(res?.data?.responseData?.salaryData)
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        employeesMonthlySalaryStats: employeesMonthlySalaryStats,
                        monthlyStatsDataCount: res?.data?.responseData?.dataCount,
                    }
                })
                let exportExcel = decryptResponseData(res?.data?.responseData?.excelLink)
                if (exportExcel !== "") {
                    window.location.href = exportExcel
                }
            } else {

                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resFailMsg: true,
                        responseMessage: res?.data?.error?.responseMessage
                    }
                })

                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resFailMsg: false,
                        }
                    })
                }, 4000)
            }
        })
            .catch((error) => {
                dispatch(getEmployeeMonthlySalaryStatsActionFailure(error));
            });
    };
};

const getEmployeeMonthlySalaryStatsActionRequest = () => {
    return {
        type: GET_EMPLOYEE_MONTHLY_SALARY_STATS_REQUEST,
    };
};

const getEmployeeMonthlySalaryStatsActionSuccess = (data) => {
    return {
        type: GET_EMPLOYEE_MONTHLY_SALARY_STATS_SUCCESS,
        payload: data,
    };
};

const getEmployeeMonthlySalaryStatsActionFailure = (error) => {
    return {
        type: GET_EMPLOYEE_MONTHLY_SALARY_STATS_FAILURE,
        payload: error,
    };
};
export {
    getEmployeeMonthlySalaryStatsAction,
    getEmployeeMonthlySalaryStatsActionRequest,
    getEmployeeMonthlySalaryStatsActionSuccess,
    getEmployeeMonthlySalaryStatsActionFailure,
};

import {
    ADD_EMPLOYEE_CTC_REQUEST,
    ADD_EMPLOYEE_CTC_SUCCESS,
    ADD_EMPLOYEE_CTC_FAILURE,
} from "../actions";

const initialState = {
    loading: false,
    data: [],
    error: "",
};

const addEmployeeCtcReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_EMPLOYEE_CTC_REQUEST:
            return {
                ...state,
                loading: true,
                data: [],
            };

        case ADD_EMPLOYEE_CTC_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: "",
            };
        case ADD_EMPLOYEE_CTC_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.payload,
            };
        default:
            return state;
    }
};
export default addEmployeeCtcReducer;

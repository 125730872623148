
import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Select, Modal as AntModal } from "antd";
import { CONSTANTS } from "../../config";
import ResponseFilter from "../../ResponseFilter";
import { getEmployeeReimbursement, getReimbursementPrintDetail } from "../../config/services/employeeServices";
import { Loader } from "../svg";
import { remLogo } from "../../asset";
const { Option } = Select;

const appConstants = CONSTANTS.TRANSACTION_LIST_CONSTANTS.ARRAY_OF_MONTHS;
const colorType = (type) => {
    switch (type) {
        case 1:
            return "yellow";

        case 2:
            return "text-success";

        default:
            return "text-danger ";
    }
};

const arrayOfYear = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i < 4; i++) {
    arrayOfYear.push(currentYear - i);
}
const statusMon = (type) => {
    switch (type) {
        case 1:
            return "January";
            break;
        case 2:
            return "February";
            break;
        case 3:
            return "March";
            break;
        case 4:
            return "April";
            break;
        case 5:
            return "May";
            break;
        case 6:
            return "June";
            break;

        case 7:
            return "July";
            break;
        case 8:
            return "August";
            break;
        case 9:
            return "September";
            break;
        case 10:
            return "October";
            break;
        case 11:
            return "November";
            break;
        case 12:
            return "December";
            break;
    }
};
const statusWeek = (type) => {
    switch (type) {
        case 1:
            return "Sunday";
            break;
        case 2:
            return "Monday";
            break;
        case 3:
            return "Tuesday";
            break;
        case 4:
            return "Wednesday";
            break;
        case 5:
            return "Thursday";
            break;
        case 6:
            return "Friday";
            break;

        case 7:
            return "Saturday";
            break;
    }
};


class Reimbursement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expenseDescription: "",
            reimbursementList: [],
            searchYear: currentYear,
            reimbursementId: props.location.state.reimbursementId,
            id: props.location.state.employeeId,
            reimbursementMonth: props.location.state.month,
            reimbursementYear: props.location.state.year,
            loader: true,
            rejected: "",
            pending: "",
            approved: "",
            month: "",
            year: "",
            pageNo: 1,
            count: 10,
            openRembInfoPopup: false,
            claimReimbursementMessage: false,
            employeeId: "",
            employeeName: "",
            department: "",
            designation: "",
            amount: "",
            manager: "",
            amountAlreadyGiven: "",
            finalAmount: "",
            approvedAmount: "",
            currency: "",
            hideButton: false,
        };
        this.GoBack = this.GoBack.bind(this);
    }

    componentDidMount() {
        this.getReimbursement();
    }

    getReimbursement = (year) => {
        let { searchYear, pageNo, count, reimbursementId, reimbursementMonth, reimbursementYear, id } = this.state;
        let params = `employeeId=${id}&year=${reimbursementYear}&month=${reimbursementMonth}&reimbursementId=${reimbursementId}`;

        getReimbursementPrintDetail(params).then((res) => {
            let { status, resData } = ResponseFilter(res);

            if (status) {
                this.setState(
                    {
                        reimbursementList: resData.reimbursementDetails,
                        employeeId: resData.empId
                        ,
                        employeeName: resData.empName,
                        month: resData.month,
                        department: resData.department,
                        designation: resData.designation,
                        amount: resData.totalAmount,
                        manager: resData.projectManager,
                        amountAlreadyGiven: resData.amountAlreadyGiven,
                        finalAmount: resData.finalAmount,
                        approvedAmount: resData.approvedAmount,
                        currency: resData.currency,
                    },
                    () => {
                        this.setState({ loader: false });
                    }
                );
            } else if (res.data.statusCode === 0) {
                this.setState({ loader: false });
                this.closeModal();
            }
        });
    };
    handleSelectChange = (e) =>
        this.setState({ year: e }, () => this.getReimbursement(this.state.year));
    handleOpenRembInfoPopup = (data) => {
        let { expenseDescription, reimbursementId } = data;
        this.setState({
            openRembInfoPopup: !this.state.openRembInfoPopup,
            expenseDescription,
            reimbursementId,
        });
    };
    closeModal = () =>
        this.setState({
            openRembInfoPopup: false,
        });
    calcAmountClaimed = (data) => {
        let amountClaimed = 0;
        data.map((item) => console.log(item.amount));
        return amountClaimed;
    };
    calcAmountApproved = (data) => {
        let amountApproved = 0;
        data.map((item) =>
            item.status === 2 ? (amountApproved += item.amount) : null
        );
        return amountApproved;
    };
    compensentoryLeaveDetailShow = (value) => {
        this.setState({ claimReimbursementMessage: true })
    }

    compensentoryLeaveDetailHide = (value) => {
        this.setState({ claimReimbursementMessage: false })
    }
    handleManagerPage = () => {
        window.location.href = "/admin-reimbursement"
    }
    handlePrint = () => {
        this.setState({hideButton: true})
        setTimeout(() => {
            window.print()
            window.location.href = "/reimbursement/reimbursement-form-print"
        }, 500);
    }
    GoBack() {
        let self = this;
        this.props.history.goBack(); // self.props.history.push(`/user-strategy-list/${localStorage.getItem('userId')}`);
    }
    render() {
        let {
            reimbursementList,
            loader,
            year,
            openRembInfoPopup,
            expenseDescription,
            claimReimbursementMessage,
            reimbursementId
            , employeeId, employeeName, designation, department, month, manager, amount,
            reimbursementMonth, reimbursementYear,
            amountAlreadyGiven,
            finalAmount,
            approvedAmount,
            currency,
            hideButton
        } = this.state;

        return (
            <div className="body_container cm_bold re-from-print-container">
                <div className="due_appraisal">
                    <>
                        <section>
                            <div class="custom_container">
                                <div class="main_div">
                                    <img src={remLogo} alt="no image" class="title-image"></img>
                                    <p class="title mb-4">{"Mobc<>der Technologies Pvt.Ltd"}</p>
                                    <p class="Reimbursement_head">Reimbursement Form</p>
                                </div>
                            </div>
                        </section>
                    </>
                    <section>
                        <div class="custom_container">
                            <div class="form_detail">
                                <ul>
                                    <li><h5 class="Reimbursement_detail">Employee ID:-  <span>{employeeId}</span></h5></li>
                                    <li><h5 class="Reimbursement_detail">Employee Name:-  <span>{employeeName}</span></h5></li>
                                    <li><h5 class="Reimbursement_detail">Month:- <span></span>{statusMon(month)}</h5></li>
                                    <li><h5 class="Reimbursement_detail">Department:-  <span>{department}</span></h5></li>
                                    <li><h5 class="Reimbursement_detail">Designation:-  <span>{designation}</span></h5></li>
                                </ul>
                                <table width="100%" border="1" className="mt-4">
                                    <thead>
                                        <tr>
                                            <td width="5%" className="reimbursement-form-detail">S.no.</td>
                                            <td width="15%" className="reimbursement-form-detail">Date</td>
                                            <td width="15%" className="reimbursement-form-detail">Day</td>
                                            <td width="45%" className="reimbursement-form-detail">Description</td>
                                            <td width="20%" className="reimbursement-form-detail">Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!_.isEmpty(reimbursementList)
                                            ? reimbursementList.map((data, i) => (
                                                <tr>
                                                    <td className="reimbursement-form-detail">{i + 1}</td>
                                                    <td className="reimbursement-form-detail">{data.date}</td>
                                                    <td className="reimbursement-form-detail">{statusWeek(data.day)}</td>
                                                    <td className="reimbursement-form-detail">{data.description}</td>
                                                    <td className="reimbursement-form-detail">{data.amount} {currency}</td>
                                                </tr>
                                            )) : ""}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td> <h5 class="Table-bottom-detail">Total</h5></td>
                                            <td><h5 class="Table-bottom-detail">{amount} {currency}</h5></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="table_note">Note : Do not forget to attach the Invoices & Payment receipts without fulfillment of these we will not allow Not be able to process the same.</p>
                                <ul className="mt-3">
                                    <li><h5 class="Reimbursement_detail">Subtotal :- <span>{amount} {currency}</span></h5></li>
                                    <li><h5 class="Reimbursement_detail">Less Amount Given (if any):-  <span>{amountAlreadyGiven} {currency}</span></h5></li>
                                    <li><h5 class="Reimbursement_detail">Amount Approved:- <span>{approvedAmount} {currency}</span></h5></li>
                                    <li><h5 class="Reimbursement_detail">Final Amount :- <span>{finalAmount} {currency}</span></h5></li>
                                </ul>
                                <div class="last_content mt-5">
                                    <div className="d-block">
                                    <p class="last_content_left">Employee Signature</p>
                                    <p className="d-flex justify-content-center">{employeeName}</p>
                                    </div>
                                    <div className="d-block">
                                        <p class="last_content_left">Approval / Reporting Manager's Signature</p>
                                        <p className=" d-flex justify-content-center">{manager}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {!hideButton && 
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary mt-5" onClick={() => this.handlePrint()}>Print</button>
                        <button className="btn btn-secondary mt-5 ml-4" onClick={this.GoBack.bind(this)}>Cancel</button>
                    </div>
                }
                </div>
            </div>
        );
    }
}
export default Reimbursement;

import {
    DELETE_INTERVIEWER_BY_ADMIN_REQUEST,
    DELETE_INTERVIEWER_BY_ADMIN_SUCCESS,
    DELETE_INTERVIEWER_BY_ADMIN_FAILURE,
} from "./actionType";

import { deleteInterviewerByAdminService } from "../../config/services/employeeServices";
import { getInterviewerList } from "./getInterviewerActionList";

const deleteInterviewerByAdmin = (interviewId, query, setResDeleteMsg, setFailResDeleteMsg, setFailResponseMessage) => {
    return (dispatch) => {
        dispatch(deleteInterviewerByAdminRequest());
        deleteInterviewerByAdminService(interviewId).then((res) => {
            if (res.data.statusCode == 1) {
                dispatch(deleteInterviewerByAdminSuccess(res.data));
                setResDeleteMsg(true)
                dispatch(getInterviewerList(query));
            } else {
                dispatch(deleteInterviewerByAdminFailure(res.data));
                setFailResponseMessage(res.data.error.responseMessage)
                setFailResDeleteMsg(true)
            }
        })
            .catch((error) => {
                dispatch(deleteInterviewerByAdminFailure(error));
            });
    };
};

const deleteInterviewerByAdminRequest = () => {
    return {
        type: DELETE_INTERVIEWER_BY_ADMIN_REQUEST,
    };
};
const deleteInterviewerByAdminSuccess = (data) => {
    return {
        type: DELETE_INTERVIEWER_BY_ADMIN_SUCCESS,
        payload: data,
    };
};
const deleteInterviewerByAdminFailure = (error) => {
    return {
        type: DELETE_INTERVIEWER_BY_ADMIN_FAILURE,
        payload: error,
    };
};
export {
    deleteInterviewerByAdmin,
    deleteInterviewerByAdminRequest,
    deleteInterviewerByAdminSuccess,
    deleteInterviewerByAdminFailure,
};

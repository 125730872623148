import {
  GET_ORDERS_LIST_REQUEST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
} from "./actionType";

import { getOrderListService } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getOrderList = (query) => {
  return (dispatch) => {
    dispatch(getOrderListRequest());
    getOrderListService(query)
      .then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch(getOrderListSuccess(res.data.responseData));
        } else {
          dispatch(getOrderListFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(getOrderListFailure(error));
      });
  };
};

const getOrderListRequest = () => {
  return {
    type: GET_ORDERS_LIST_REQUEST,
  };
};

const getOrderListSuccess = (data) => {
  return {
    type: GET_ORDERS_LIST_SUCCESS,
    payload: data,
  };
};

const getOrderListFailure = (error) => {
  return {
    type: GET_ORDERS_LIST_FAILURE,
    payload: error,
  };
};
export {
  getOrderList,
  getOrderListRequest,
  getOrderListSuccess,
  getOrderListFailure,
};

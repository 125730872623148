import React from "react";
import { Modal } from "react-bootstrap";

export function DeleteAttendanceModal(props) {
  const { show, handleClose, miniLoader, handleDelete } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          <b>Delete Attendance </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`assign_device ${miniLoader ? "admin_attendance" : ""}`}
      >
        <div className="text-center confirmation">
          <p className="mb-4 cm_bold cnfrm_msg">
            Are you sure you want to delete Attendance?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="submit_button_margin mt-4">
          {miniLoader ? <div className="cover-spin1"></div> : ""}
          <span
            className="btn btn-danger cm_btn3 btn-sm"
            onClick={handleDelete}
            disabled={miniLoader}
          >
            Delete
          </span>
          <button
            onClick={handleClose}
            className="btn btn-primary cm_btn2 btn-sm"
            disabled={miniLoader}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

export function disabledDate(current) {
  return current && current > moment().endOf("day");
}

export const format = "HH:mm";

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const statusValue = (type) => {
  switch (type) {
    case 1:
      return "Present";
    case 2:
      return "Late";
    case 3:
      return "Absent";
    case 4:
      return "Missed Check Out";
    case 5:
      return "On Leave";
    case 6:
      return "Early Check Out";
    case 7:
      return "Work From Home";
    case 8:
      return "Others";
    case 9:
      return "Half Others";
    case 10:
      return "Late and Total Hours Not Completed";
    case 11:
      return "Missed Check In";
    case 12:
      return "Half Work From Home";
    case 13:
      return "Half WFH and Half Day Leave";
    case 14:
      return "Half Others and Half Day Leave";
    case 15:
      return "Half Others and Half WFH";
    case 16:
      return "Half WFH and Half Others";
    case 17:
      return "Half Day Leave and Half WFH";
    case 18:
      return "Half Day Leave And  s";
    case 19:
      return "Client-side Off";
    case 20:
      return "Parenting Leave";
    case 21:
      return "Bereavement Leave";
    default:
      return "Unknown";
  }
};

export const Toast = (props) => {
  return (
    <>
      {props.resMsg ? (
        <div className="alert alert-success cm_top_fix">
          Attendance added successfully
        </div>
      ) : (
        ""
      )}
      {props.resLeaveMsg ? (
        <div className="alert alert-success cm_top_fix">{props.text}</div>
      ) : (
        ""
      )}
      {props.resMsgEdit ? (
        <div className="alert alert-warning cm_top_fix">
          Attendance edited successfully
        </div>
      ) : (
        ""
      )}
      {props.resMsgDelete ? (
        <div className="alert alert-danger cm_top_fix">
          Attendance deleted successfully
        </div>
      ) : (
        ""
      )}
      {props.resMsgUpload ? (
        <div className="alert alert-success cm_top_fix">
          Attendance uploaded successfully. Please wait while data is processing
        </div>
      ) : (
        ""
      )}
      {props.resMsgFail ? (
        <div className="alert alert-danger cm_top_fix">
          {props.responseMessage}
        </div>
      ) : (
        ""
      )}
      {props.resMsgFailEdit ? (
        <div className="alert alert-danger cm_top_fix">
          {props.responseMessageEdit}
        </div>
      ) : (
        ""
      )}
      {props.resMsgFailAdd ? (
        <div className="alert alert-danger cm_top_fix">
          {props.responseMessageAdd}
        </div>
      ) : (
        ""
      )}
      {props.resMsgExcelError ? (
        <div className="alert alert-danger cm_top_fix">
          {props.responseMessageExcel}
        </div>
      ) : (
        ""
      )}
      {props.resMsgMail ? (
        <div className="alert alert-success cm_top_fix">
          Mail successfully sent
        </div>
      ) : (
        ""
      )}
      {props.resMsgMailError ? (
        <div className="alert alert-danger cm_top_fix">
          {props.responseMessageMail}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export const STATUS_ZERO = "0";
export const STATUS_VALUE_ZERO = 0;
export const STATUS_SUCCESS = 1;
export const REQUIRED = "Field is required";
export const VALID_EXCEL = "Valid ExcelSheet is required(.xlx|.xlsx)";
export const regxExcel = /\.(xlx|xlsx|xls)$/;

export const tabs = [
  { eventKey: "1", title: "Yearly" },
  { eventKey: "2", title: "Quarterly" },
  { eventKey: "3", title: "Monthly" },
];

export const TabRender = (props) => {
  const {
    present,
    late,
    absent,
    Others,
    halfOthers,
    earlyCheckOutTab,
    workFromHome,
    halfWfh,
    missedCheckOut,
    missedCheckIn,
    lateAndTotalHoursNotComplete,
    onLeave,
    tabStatus,
    totalAttendance,
    handleTabChange,
    bereavementLeaves,
    parentingLeave,
    clientOff,
  } = props;

  const generateTabTitle = (data, defaultTitle) => {
    return data[0]?.totalCountByStatus
      ? data[0]?.totalCountByStatus
      : defaultTitle;
  };

  return (
    <>
      <Tabs
        activeKey={tabStatus || "0"}
        id="uncontrolled-tab-example"
        onSelect={(e) => handleTabChange(e)}
      >
        <Tab eventKey="0" title={`All ${totalAttendance}`}></Tab>
        <Tab
          eventKey="1"
          title={`Present ${generateTabTitle(present, 0)}`}
        ></Tab>
        <Tab eventKey="2" title={`Late ${generateTabTitle(late, 0)}`}></Tab>
        <Tab eventKey="3" title={`Absent ${generateTabTitle(absent, 0)}`}></Tab>
        <Tab
          eventKey="8"
          title={`Other's ${generateTabTitle(Others, 0)}`}
        ></Tab>
        <Tab
          eventKey="9"
          title={`Half Other's ${generateTabTitle(halfOthers, 0)}`}
        ></Tab>
        <Tab
          eventKey="6"
          title={`Early Checkout ${generateTabTitle(earlyCheckOutTab, 0)}`}
        ></Tab>
        <Tab
          eventKey="7"
          title={`Work From Home ${generateTabTitle(workFromHome, 0)}`}
        ></Tab>
        <Tab
          eventKey="12"
          title={`Half Work From Home ${generateTabTitle(halfWfh, 0)}`}
        ></Tab>
        <Tab
          eventKey="4"
          title={`Missed Check Out ${generateTabTitle(missedCheckOut, 0)}`}
        ></Tab>
        <Tab
          eventKey="11"
          title={`Missed Check In ${generateTabTitle(missedCheckIn, 0)}`}
        ></Tab>
        <Tab
          eventKey="10"
          title={`Late and Total Hours Not Completed ${generateTabTitle(
            lateAndTotalHoursNotComplete,
            0
          )}`}
        ></Tab>
        <Tab
          eventKey="5"
          title={`On Leave ${generateTabTitle(onLeave, 0)}`}
        ></Tab>
        <Tab
          eventKey="19"
          title={`Client-side Off ${generateTabTitle(clientOff, 0)}`}
        ></Tab>
        <Tab
          eventKey="20"
          title={`Parenting Leave ${generateTabTitle(parentingLeave, 0)}`}
        ></Tab>
        <Tab
          eventKey="21"
          title={`Bereavement Leave ${generateTabTitle(bereavementLeaves, 0)}`}
        ></Tab>
      </Tabs>
    </>
  );
};

import {
  GET_ATTENDANCE_LIST_BY_ADMIN_REQUEST,
  GET_ATTENDANCE_LIST_BY_ADMIN_SUCCESS,
  GET_ATTENDANCE_LIST_BY_ADMIN_FAILURE,
} from "./actionType";

import { getAttendanceList } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
  STATUS_VALUE_ZERO,
  STATUS_ZERO,
} from "../../components/attendance/Constants";
import { type } from "../../components/helper/Constant";

const getAttendanceByAdmin = (props) => {
  let { useDispatch, tabStatus, params, dataCount } = props;
  return (dispatch) => {
    dispatch(getAttendanceByAdminRequest());
    getAttendanceList(params)
      .then((res) => {
        useDispatch({
          type: type,
          payload: {
            miniLoader: false,
          },
        });
        let { status, resData } = ResponseFilter(res);
        if (status) {
          useDispatch({
            type: type,
            payload: {
              avgOfficeHours: resData.avgOfficeHours,
              AttendanceDetails: resData.AttendenceDetails,
              pageNo: params.pageNo + 1,
              dataCount:
                tabStatus === STATUS_ZERO || tabStatus === ""
                  ? resData.totalAttendence
                  : dataCount,
              totalAttendance: resData.totalAttendence,
              present:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 1)
                  : 0,
              late:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 2)
                  : 0,
              absent:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 3)
                  : 0,
              missedCheckOut:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 4)
                  : 0,
              missedCheckIn:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 11)
                  : 0,
              lateAndTotalHoursNotComplete:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 10)
                  : 0,
              earlyCheckOutTab:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 6)
                  : 0,
              onLeave:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 5)
                  : 0,
              workFromHome:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 7)
                  : 0,
              Others:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 8)
                  : 0,
              halfOthers:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 9)
                  : 0,
              statusByType:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData.statusByType
                  : [],
              miniloader: false,
              halfWfh:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 12)
                  : 0,
              bereavementLeaves:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 21)
                  : 0,
              parentingLeave:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 20)
                  : 0,
              clientOff:
                resData.statusByType !== STATUS_VALUE_ZERO
                  ? resData?.statusByType.filter((data) => data._id === 19)
                  : 0,
            },
          });
        } else {
          useDispatch({
            type: type,
            payload: {
              responseMessage: res.data.error.errors[0].message
                ? res.data.error.errors[0].message
                : res.data.error.responseMessage,
              errorCode: res.data.error.errorCode,
              resMsgFail: true,
            },
          });
          setTimeout(
            () =>
              useDispatch({
                type: type,
                payload: {
                  resMsgFail: false,
                },
              }),
            3000
          );
        }
      })
      .catch((error) => {
        dispatch(getAttendanceByAdminFailure(error));
      });
  };
};

const getAttendanceByAdminRequest = () => {
  return {
    type: GET_ATTENDANCE_LIST_BY_ADMIN_REQUEST,
  };
};

const getAttendanceByAdminSuccess = (data) => {
  return {
    type: GET_ATTENDANCE_LIST_BY_ADMIN_SUCCESS,
    payload: data,
  };
};

const getAttendanceByAdminFailure = (error) => {
  return {
    type: GET_ATTENDANCE_LIST_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  getAttendanceByAdmin,
  getAttendanceByAdminRequest,
  getAttendanceByAdminSuccess,
  getAttendanceByAdminFailure,
};

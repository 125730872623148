import React, { useEffect, useRef, useState } from 'react'
import { Loader } from '../svg';
import ribbon from '../emailTemplate/img/ribbon.svg'
import round_shadow from '../emailTemplate/img/round-shadow.svg'
import cake from '../emailTemplate/img/cake.png'
import '../emailTemplate/style.css'
import { Link } from 'react-router-dom';
import { Descriptions, Select } from 'antd';
import { sendWishesTemplate } from '../../config/services/employeeServices';
const { Option } = Select;



let arr = [4, 5]
const WelcomeTemplate = (props) => {
    var image1 = "https://hrm-mob-dev.s3.amazonaws.com/20230311T134147512Z0.11962313857465978.png"
    var image2 = 'https://hrm-mob-dev.s3.amazonaws.com/20230313T050110708Z0.5118954223936392.png'
    const [loader, setLoader] = useState(true);
    const [empDetail, setEmpDetail] = useState([])
    const [resmessage, setResmessage] = useState(false)
    const [resMsgError, setResMsgError] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [sumbit, setSumbit] = useState(false)
    const refIndex = useRef([])
    const data = props.location.state;
    const countRef = useRef(0)
    let count = countRef.current
    const refEmp = useRef('')
    const [welcomeError, setWelcomeError] = useState('')
    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 5000);
    }, []);
    const loadImage=()=> {
       
      
        }
    const employeeWelcome = () => {
        for (let i = 0; i < data.length; i++) {
            function urltoFile(url, filename, mimeType) {
                return (fetch(url)
                    .then(function (res) { return res.arrayBuffer(); })
                    .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
                );
            }
            urltoFile(data[i].file, 'image.jpeg', 'image/jpeg')
                .then(function (file) {
                    let obj = {
                        templateNumber:arr[Math.floor(Math.random() * arr.length)],
                        employeeId: data[i].empId,
                        image: file,
                        showImage: data[i].file,
                        empName: data[i].empName,
                        empLastName:data[i].empLastName,
                        description: data[i].description.trim(),
                        designation:data[i].designation.trim()

                    }   
                    empDetail.push(obj)


                });
        }
    }
    useEffect(() => {
        employeeWelcome()
    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault()
        let promises = [];
        setLoader(true)
        for (let i = 0; i < empDetail.length; i++) {
            let params = new FormData();
            params.append("templateNumber", empDetail[i].templateNumber)
            params.append("employeeId", empDetail[i].employeeId)
            params.append("image", empDetail[i].image)
            params.append("empName", empDetail[i].empName)
            params.append("empLastName" ,empDetail[i].empLastName)
            params.append("designation", empDetail[i].designation)
            params.append("description", empDetail[i].description)
            promises.push(await sendWishesTemplate(params))
        }
        return Promise.all(promises)
            .then(responses => {
                let data = [];
                for (let i = 0; i < responses.length; i++) {
                    data.push(responses[i].data);
                }
                return data;
            })
            .then(data => {
                if (data[0].statusCode === 1) {
                   window.location.href = '/templates';
                    setLoader(true)
                    localStorage.setItem("responseMsg", true)
                }
                else {
                    setResMsgError(true)
                    setResponseMessage(data?.error?.responseMessage)
                }
            

            })
            .catch(error => {
            }).finally(() =>console.log(false),
            );
    }
    return (
        <>
            <div className="body_container cm_bold">
                {loader && <Loader />}
                {
                    resmessage ?
                        <div className="alert alert-success cm_top_fix">Mail Sent SuccessFully</div> : ''
                }
                {resMsgError ?
                    <div className="alert alert-danger cm_top_fix">{responseMessage}</div> : ''
                }
                <h4 className="mb-4 cm_bold">WELCOME TEMPLATE</h4>

                {empDetail.map((element, index) => (
                    element.templateNumber === 4 ?
                        <div className="template-container mt-3">
                            <div className="card-container">
                                <div className="card-box">
                                    <img className="card-bg" src={image1}  alt=""  />
                                    <div className="ribbon-box">
                                        <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                                    </div>
                                    {{image1}?
                                    <div className="center-content">
                                        <div className="hidden-box-1"></div>
                                        <div className="person-img-box" >
                                            <img className="person-img" src={element.showImage} alt=""  style={{ height: "180px", width: "180px", marginTop: "341px", borderRadius: '50%',
                         border: '7px solid #fec92e' }} />
                                            <img className="round-shadow" alt="" />
                                            <img className="cake" alt="" />
                                            <div className="person-name-box1" style={{  color: '#25c5d9', textTransform: 'uppercase' }} >
                                                <span className="person-name person-name2">{element.empName}{" "}{element.empLastName}</span>
                                            </div>
                                            <div className="person-name-box2" >
                                                <span className="person-name-desg" style={{ color: 'black' }}>{element.designation}</span>
                                            </div>
                                            <div className="person-name-box2" >
                                                <span className="person-name-desc" style={{ color: 'black' }}>{element.description}</span>
                                            </div>
                                        </div>
                                        <div className="hidden-box-2"></div>
                                    </div>
                                    :""}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-11">
                                    <div className="text-center mt-3">
                                    </div>
                                </div>
                            </div>
                        </div> :
                       <div className="template-container mt-3">
                       <div className="card-container">
                           <div className="card-box">
                               <img className="card-bg" src={image2}   onLoad={loadImage} alt="" />
                               <div className="ribbon-box">
                                   <img className="ribbon" src={ribbon} alt="" style={{ display: "none" }} />
                               </div>
                               {image2 ? 
                               <div className="center-content">
                                   <div className="hidden-box-1"></div>
                                   <div className="person-img-box" >
                                       <img className="person-img" src={element.showImage} alt="" style={{ height: "214px", width: "202px", marginTop: "231px", borderRadius: '50%',
                         border: '7px solid #fec92e' }} />
                                       <img className="round-shadow" alt="" />
                                       <img className="cake" alt="" />
                                       <div className="person-name-box1" style={{ color: 'red', }} >
                                           <span className="person-name">{element.empName}{" "}{element.empLastName}</span>
                                       </div>
                                       <div className="person-name-box2" >
                                           <span className="person-name" style={{ color: 'black'  ,fontWeight:'800' , textTransform:'uppercase'}}>{element.designation}</span>
                                       </div>
                                       <div className="person-name-box2" >
                                           <span className="person-name" style={{ color: 'black' , fontStyle:'oblique' ,   fontWeight:'300'}}>{element.description}</span>
                                       </div>
                                    
                                   </div>
                                   <div className="hidden-box-2"></div>
                               </div>
                               :""}
                           </div>
                       </div>

                       <div className="row">
                           <div className="col-11">
                               <div className="text-center mt-3">
                               </div>
                           </div>
                       </div>
                   </div>
                ))}
                <div className="row">
                    <div className="col-11">
                        <div className="submit_button_margin mt-4">
                            {
                              
                                    <button className="btn btn-primary cm_btn3" onClick={(e) => handleSubmit(e)} >Submit</button>
                            }
                            <Link to="/templates" className="cancel_btn btn btn-default cm_btn2">
                            Cancel
                            </Link>
                            
                        </div>
                       
                    </div>

                </div>
            </div>

        </>
    )
}

export default WelcomeTemplate
import React, { useEffect, useMemo, useReducer } from "react";
import {
  getEmployee,
  handlePageChange,
  reducer,
  type,
} from "../helper/Constant";
import { useDebounce } from "../attendance/Constants";
import { useDispatch } from "react-redux";
import { getCourierListByAdminAction } from "../../redux/actions/getCourierListByAdminAction";
import { Loader } from "../svg";
import Pagination from "react-js-pagination";
import CourierList from "./CourierList";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AddCourierModal from "./AddCourierModal";
import { handleClose } from "./CourierConstants";
import ComponentHeader from "../helper/ComponentHeader";
import { CONSTANTS } from "../../config";
import qs from "qs";

const initialState = {
  search: "",
  pageNo: 1,
  limit: 10,
  loader: false,
  courierList: [],
  resMsg: false,
  resMsgFail: false,
  responseMessage: "",
  successMessage: "",
  dataCount: 0,
  addPopup: false,
  editPopup: false,
  deletePopup: false,
  name: "",
  dateSent: "",
  tentative_receiving_date: "",
  cost: "",
  tracking_id: "",
  courier_company: "",
  item_description: "",
  courierFile: [],
  nameErrMsg: "",
  dateSentErrMsg: "",
  tentative_receiving_dateErrMsg: "",
  costErrMsg: "",
  courierFileErrMsg: "",
  tracking_idErrMsg: "",
  courier_companyErrMsg: "",
  item_descriptionErrMsg: "",
  employeeList: [],
  employeeId: "",
  courier_id: "",
  count: 0,
  sortType: CONSTANTS.SORT_TYPE_CONSTANTS.Descending,
  sortField: "",
  editCourier: [],
};
const Courier = () => {
  const dispatchToAction = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    search,
    pageNo,
    limit,
    loader,
    courierList,
    resMsg,
    resMsgFail,
    responseMessage,
    successMessage,
    dataCount,
    addPopup,
    editPopup,
    deletePopup,
    dateSent,
    tentative_receiving_date,
    name,
    cost,
    tracking_id,
    courier_company,
    item_description,
    courierFile,
    nameErrMsg,
    dateSentErrMsg,
    courierFileErrMsg,
    tentative_receiving_dateErrMsg,
    costErrMsg,
    tracking_idErrMsg,
    courier_companyErrMsg,
    item_descriptionErrMsg,
    employeeList,
    employeeId,
    courier_id,
    sortType,
    sortField,
    editCourier,
  } = state;

  const history = useHistory();
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    getCourierList();
    // eslint-disable-next-line
  }, [pageNo, limit, debouncedSearchTerm, sortType, sortField]);

  useEffect(() => {
    getEmployee(dispatch);
    // eslint-disable-next-line
  }, []);

  const getCourierList = () => {
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    let params = `search=${search.trim()}&limit=${limit}&page=${pageNo}&sortField=${sortField}&sortType=${sortType}`;
    history.push(
      `?search=${search.trim()}&limit=${limit}&page=${pageNo}&sortField=${sortField}&sortType=${sortType}`
    );
    dispatchToAction(
      getCourierListByAdminAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        pageNo: filtersFromParams.page ? Number(filtersFromParams.page) : 1,
        search: filtersFromParams.search ? filtersFromParams.search : "",
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : CONSTANTS.SORT_TYPE_CONSTANTS.Descending,
        limit: filtersFromParams.limit ? Number(filtersFromParams.limit) : 10,
      },
    });
  }, [history.location.search]);

  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <ComponentHeader
        resMsg={resMsg}
        resMsgFail={resMsgFail}
        successMessage={successMessage}
        responseMessage={responseMessage}
        dispatch={dispatch}
        search={search}
      />
      <CourierList
        courierList={courierList}
        pageNo={pageNo - 1}
        limit={limit}
        dispatch={dispatch}
        editPopup={editPopup}
        deletePopup={deletePopup}
        handleClose={handleClose}
        courierFile={courierFile}
        dateSent={dateSent}
        tentative_receiving_date={tentative_receiving_date}
        dateSentErrMsg={dateSentErrMsg}
        name={name}
        cost={cost}
        tracking_id={tracking_id}
        courier_company={courier_company}
        item_description={item_description}
        courier_id={courier_id}
        getCourierList={getCourierList}
        dispatchToAction={dispatchToAction}
        sortType={sortType}
        sortField={sortField}
        editCourier={editCourier}
        courierFileErrMsg={courierFileErrMsg}
        nameErrMsg={nameErrMsg}
        tentative_receiving_dateErrMsg={tentative_receiving_dateErrMsg}
        costErrMsg={costErrMsg}
        tracking_idErrMsg={tracking_idErrMsg}
        courier_companyErrMsg={courier_companyErrMsg}
        item_descriptionErrMsg={item_descriptionErrMsg}
      />
      <AddCourierModal
        show={addPopup}
        handleClose={() => handleClose(dispatch)}
        dispatch={dispatch}
        name={name}
        dateSent={dateSent}
        cost={cost}
        tentative_receiving_date={tentative_receiving_date}
        tracking_id={tracking_id}
        courier_company={courier_company}
        item_description={item_description}
        courierFileErrMsg={courierFileErrMsg}
        nameErrMsg={nameErrMsg}
        dateSentErrMsg={dateSentErrMsg}
        tentative_receiving_dateErrMsg={tentative_receiving_dateErrMsg}
        costErrMsg={costErrMsg}
        tracking_idErrMsg={tracking_idErrMsg}
        courier_companyErrMsg={courier_companyErrMsg}
        item_descriptionErrMsg={item_descriptionErrMsg}
        employeeList={employeeList}
        employeeId={employeeId}
        getCourierList={getCourierList}
        dispatchToAction={dispatchToAction}
        courierFile={courierFile}
      />
      {(dataCount > 10 ||
        (dataCount < 11 && dataCount > 0 && pageNo === 2)) && (
        <Pagination
          activePage={pageNo}
          itemsCountPerPage={limit}
          totalItemsCount={dataCount}
          pageRangeDisplayed={3}
          onChange={(page) => handlePageChange(dispatch, page, getCourierList)}
        />
      )}
    </div>
  );
};

export default Courier;

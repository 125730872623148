import {
    EDIT_EMPLOYEE_CTC_REQUEST,
    EDIT_EMPLOYEE_CTC_SUCCESS,
    EDIT_EMPLOYEE_CTC_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { editEmployeeCtc } from "../../config/services/employeeServices";

const editEmployeeCtcAction = (props) => {
    let { useDispatch, params, handleClose, getEmployeeCtc } = props
    return (dispatch) => {
        dispatch(editEmployeeCtcActionRequest());
        editEmployeeCtc(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resMsg: true,
                        successMessage: "CTC Edited",
                    }
                })
                handleClose();
                getEmployeeCtc();
                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resMsg: false,
                        }
                    })
                }, 4000)
            } else {
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resFailMsg: true,
                        responseMessage: res?.data?.error?.responseMessage
                    }
                })

                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resFailMsg: false,
                        }
                    })
                }, 4000)
            }
        })
            .catch((error) => {
                dispatch(editEmployeeCtcActionFailure(error));
            });
    };
};

const editEmployeeCtcActionRequest = () => {
    return {
        type: EDIT_EMPLOYEE_CTC_REQUEST,
    };
};

const editEmployeeCtcActionSuccess = (data) => {
    return {
        type: EDIT_EMPLOYEE_CTC_SUCCESS,
        payload: data,
    };
};

const editEmployeeCtcActionFailure = (error) => {
    return {
        type: EDIT_EMPLOYEE_CTC_FAILURE,
        payload: error,
    };
};
export {
    editEmployeeCtcAction,
    editEmployeeCtcActionRequest,
    editEmployeeCtcActionSuccess,
    editEmployeeCtcActionFailure,
};

import {
    EDIT_COMP_OFF_BY_ADMIN_REQUEST,
    EDIT_COMP_OFF_BY_ADMIN_SUCCESS,
    EDIT_COMP_OFF_BY_ADMIN_FAILURE,
  } from "./actionType";
  
  import { editCompOffCount } from "../../config/services/employeeServices";
  
  const editCompOffCountByAdminAction = (props) => {
    const { params, useDispatch, handleClose, getData } = props;
    return (dispatch) => {
      dispatch(editCompOffByAdminRequest());
      editCompOffCount(params)
        .then((res) => {
          useDispatch({
            type: "typeCheckSet",
            payload: {
              loader: false,
            },
          });
  
          if (res.data.statusCode === 1) {
            dispatch(editCompOffByAdminSuccess(res.data));
            useDispatch({
              type: "typeCheckSet",
              payload: {
                resMsg: true,
                successMessage: "Comp Off Deleted",
              },
            });
            handleClose();
            setTimeout(
              () =>
                useDispatch({
                  type: "typeCheckSet",
                  payload: {
                    resMsg: false,
                  },
                }),
              3000
            );
          } else {
            dispatch(editCompOffByAdminFailure(res.data));
            useDispatch({
              type: "typeCheckSet",
              payload: {
                resMsgFail: true,
                responseMessage: res.data.error.responseMessage,
              },
            });
            handleClose();
            setTimeout(
              () =>
                useDispatch({
                  type: "typeCheckSet",
                  payload: {
                    resMsgFail: false,
                  },
                }),
              3000
            );
          }
          getData()
        })
        .catch((error) => {
          dispatch(editCompOffByAdminFailure(error));
        });
    };
  };
  
  const editCompOffByAdminRequest = () => {
    return {
      type: EDIT_COMP_OFF_BY_ADMIN_REQUEST,
    };
  };
  const editCompOffByAdminSuccess = (data) => {
    return {
      type: EDIT_COMP_OFF_BY_ADMIN_SUCCESS,
      payload: data,
    };
  };
  const editCompOffByAdminFailure = (error) => {
    return {
      type: EDIT_COMP_OFF_BY_ADMIN_FAILURE,
      payload: error,
    };
  };
  
  export {
    editCompOffCountByAdminAction,
    editCompOffByAdminRequest,
    editCompOffByAdminSuccess,
    editCompOffByAdminFailure,
  };
  
import React, { useReducer, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import ResponseFilter from "../../ResponseFilter";
import { Loader, LateComingIcon, EarlyLeaveIcon } from "../svg";
import Pagination from "react-js-pagination";
import { getAttendanceMonthly } from "../../config/services/employeeServices";
import { CONSTANTS } from "../../config";
import { statusValue, tabs } from "./Constants";
import { useHistory } from "react-router-dom";
import qs from "qs";
import {
  arrOfMonths,
  arrOfQuarters,
  arrOfYears,
  colorType,
  monthNames,
} from "../helper/Constant";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;
var numberDate = [];
let url = "/admin-attendance";
for (let i = 0; i <= 30; i++) {
  numberDate.push(i);
}

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const reducer = (state, action) => {
  switch (action.type) {
    case "type":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  activeTab: 3,
  AttendenceDetails: [],
  late: "",
  attendanceListData: [],
  pageNo: 1,
  loader: false,
  count: 10,
  empDetails: "",
  currentDay: "",
  searchDay: "",
  searchMonth: currentMonth,
  sortType: "",
  sortField: "",
  searchYear: currentYear,
  searchQuatName: "",
  month: "",
  year: "",
  dataCount: "",
  yearUserDataSet: [],
  currentQuarter: Math.ceil((new Date().getMonth() + 1) / 3),
  currentMonth: new Date().getMonth() + 1,
  earlyCheckout: "",
  employeeName: "",
};

const ViewDailyAttendence = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    activeTab,
    pageNo,
    loader,
    count,
    currentDay,
    searchDay,
    searchMonth,
    sortType,
    sortField,
    searchYear,
    searchQuatName,
    month,
    year,
    currentQuarter,
    currentMonth,
    employeeName,
  } = state;

  useEffect(() => {
    getAttendanceByAdmin();
  }, []);

  useEffect(() => {
    getAttendanceByAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNo,
    count,
    sortType,
    sortField,
    searchDay,
    searchMonth,
    searchYear,
    searchQuatName,
    month,
    year,
    currentQuarter,
    currentMonth,
  ]);

  const history = useHistory();

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: "type",
      payload: {
        pageNo: filtersFromParams.pageNo ? filtersFromParams.pageNo : 1,
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : "",
        searchMonth: filtersFromParams.searchMonth
          ? Number(filtersFromParams.searchMonth)
          : currentMonth,
        searchYear: filtersFromParams.searchYear
          ? filtersFromParams.searchYear
          : currentYear,
        searchQuatName: filtersFromParams.searchQuatName
          ? filtersFromParams.searchQuatName
          : "",
        activeTab: filtersFromParams.activeTab
          ? filtersFromParams.activeTab
          : 3,
      },
    });
  }, [history.location.search]);

  const getAttendanceByAdmin = async () => {
    let params = `employeeId=${
      props.match.params.id
    }&searchYear=${searchYear}&sortType=${sortType}&sortField=${sortField}&searchDay=${searchDay}&count=${count}&pageNo=${
      pageNo - 1
    }&searchMonth=${activeTab == 3 ? searchMonth : ""}&searchQuarter=${
      activeTab == 2 ? searchQuatName : ""
    }`;
    history.push(
      `?&searchYear=${searchYear}&searchMonth=${searchMonth}&sortType=${sortType}&sortField=${sortField}&pageNo=${pageNo}&activeTab=${activeTab}&searchQuatName=${searchQuatName}`
    );
    dispatch({ type: "type", payload: { loader: true } });
    getAttendanceMonthly(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: "type",
          payload: {
            loader: false,
            AttendenceDetails: resData?.AttendenceDetails,
            totalAttendence: resData?.totalAttendence,
            late: resData?.late !== 0 ? resData.late[0].lateCount : 0,
            earlyCheckout:
              resData.earlyCheckoutCount !== 0
                ? resData?.earlyCheckoutCount[0].earlyCount
                : 0,
            avgHours: resData?.avgHours,
            empDetails: resData?.empDetails,
            dataCount: resData?.totalAttendence,
            employeeName: resData?.employeeName,
            day: currentDay,
          },
        });
      }
    });
  };

  const handleTabChange = (e) => {
    dispatch({
      type: "type",
      payload: { activeTab: parseInt(e), pageNo: 1, selectLeave: e },
    });

    switch (e) {
      case "1":
        dispatch({
          type: "type",
          payload: {
            searchYear: new Date().getFullYear(),
            searchMonth: "",
            searchQuatName: "",
          },
        });
        break;
      case "2":
        dispatch({
          type: "type",
          payload: {
            searchQuatName: currentQuarter,
            searchMonth: "",
          },
        });
        break;
      case "3":
        dispatch({
          type: "type",
          payload: {
            searchQuatName: "",
            searchMonth: state.currentMonth,
          },
        });
        break;
      default:
        break;
    }
  };

  const filterAddenceyearLi = () => {
    let { searchYear } = state;

    return (
      <ul className="cm_calender">
        {arrOfYears().map((yearName, i) => (
          <li
            className={`${
              yearName === (searchYear ? Number(searchYear) : currentYear)
                ? "activeMon"
                : ""
            }`}
            onClick={() =>
              dispatch({
                type: "type",
                payload: {
                  searchYear: yearName,
                },
              })
            }
          >
            <p>{yearName}</p>

            <span
              className={`${
                yearName === (searchYear ? Number(searchYear) : currentYear)
                  ? "activeMon"
                  : ""
              }`}
            ></span>
          </li>
        ))}
      </ul>
    );
  };
  const handleSort = (e) => {
    dispatch({ type: "typeCheckSet", payload: { loader: false } });
    dispatch({
      type: "type",
      payload: {
        sortField: e,
        sortType:
          sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
    });
  };
  const filterAddenceQuarterLi = () => {
    let { searchQuatName } = state;
    return (
      <ul className="cm_quarterly">
        {arrOfQuarters.map((quartrName, i) => (
          <li
            className={`${i === searchQuatName - 1 ? "activeMon" : " "}`}
            onClick={() =>
              dispatch({ type: "type", payload: { searchQuatName: i + 1 } })
            }
          >
            <p>{quartrName}</p>
            <span
              className={`${i === searchQuatName - 1 ? "activeMon" : ""}`}
            ></span>
          </li>
        ))}
      </ul>
    );
  };

  const filterAddenceMonthLi = () => {
    let { searchMonth } = state;

    return (
      <ul className="cm_monthly">
        {arrOfMonths.map((monthName, i) => (
          <li
            className={`${i === searchMonth - 1 ? "activeMon" : ""}`}
            onClick={() =>
              dispatch({ type: "type", payload: { searchMonth: i + 1 } })
            }
          >
            <p>{monthName}</p>
            <span
              className={`${i === searchMonth - 1 ? "activeMon" : ""}`}
            ></span>
          </li>
        ))}
      </ul>
    );
  };

  const attendance = () => {
    let {
      activeTab,
      AttendenceDetails,
      count,
      pageNo,
      late,
      earlyCheckout,
      avgHours,
      sortField,
      sortType,
    } = state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-9">
            {activeTab == 1 && filterAddenceyearLi()}
            {activeTab == 2 && filterAddenceQuarterLi()}
            {activeTab == 3 && filterAddenceMonthLi()}
          </div>
          <div className="col-3 cm_w_100"></div>
        </div>
        <div className="row attendance_status mt-4">
          <div className="col-md-3 ">
            <div className="cm_card">
              <span className="late_comings">
                <LateComingIcon />
              </span>
              <div className="cm_style">
                <span className="emp_count cm_bold">{late}</span>
                <p className="mb-4">Late Check In</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="cm_card">
              <span className="late_comings early_leave">
                <EarlyLeaveIcon />
              </span>
              <div className="cm_style">
                <span className="emp_count cm_bold">{earlyCheckout}</span>
                <p className="mb-4">Early Check Out</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`${
                moment.utc(avgHours * 1000).format("HH:mm") <
                  moment.utc(32400 * 1000).format("HH:mm") &&
                moment.utc(avgHours * 1000).format("HH:mm") >=
                  moment.utc(28800 * 1000).format("HH:mm")
                  ? "cm_card cm_avghryellow"
                  : moment.utc(avgHours * 1000).format("HH:mm") <
                    moment.utc(32400 * 1000).format("HH:mm")
                  ? "cm_card cm_avghours"
                  : "cm_card cm_avghourgreen"
              }`}
            >
              <div className="text-center">
                <p className="sm_hours">Avg/Hours</p>
                <span className="hour_count cm_bold">
                  {avgHours != 0
                    ? moment.utc(avgHours * 1000).format("H.mm")
                    : "00.00"}
                  /09.00
                </span>
              </div>
            </div>
          </div>
        </div>

        {!_.isEmpty(AttendenceDetails)
          ? AttendenceDetails.map((month, i) => {
              return (
                <div>
                  <div>{monthNames[month._id.month - 1]}</div>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="white bg_blue">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Check IN</th>
                          <th scope="col">Late Check IN</th>
                          <th scope="col">Early Check Out</th>
                          <th scope="col">Check Out</th>
                          <th scope="col">
                            Total Hours
                            <i
                              className={
                                sortType === SORT_TYPE_CONSTANTS.Descending &&
                                sortField === "totalHours"
                                  ? "fa fa-sort-numeric-desc ml-2"
                                  : "fa fa-sort-numeric-asc ml-2"
                              }
                              onClick={() => handleSort("totalHours")}
                            ></i>
                          </th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!_.isEmpty(month.attendanceList)
                          ? month.attendanceList?.map((attendance, i) => (
                              <tr>
                                <td>{i + 1 + (pageNo - 1) * count}</td>
                                <td>
                                  {new Date(attendance.date)
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0") +
                                    "/" +
                                    (new Date(attendance.date).getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0") +
                                    "/" +
                                    new Date(attendance.date)
                                      .getFullYear()
                                      .toString()
                                      .padStart(2, "0")}
                                </td>
                                <td>
                                  {attendance.checkIn > "0" &&
                                  (attendance.checkIn.includes(".")
                                    ? parseInt(
                                        +attendance.checkIn.split(".")[0] *
                                          60 *
                                          60 +
                                          +attendance.checkIn.split(".")[1] * 60
                                      )
                                    : parseInt(attendance.checkIn * 60 * 60)) <=
                                    attendance.reportingTime + 1800
                                    ? attendance.checkIn.indexOf(".") !== -1
                                      ? attendance.checkIn
                                          .toString()
                                          .split(".")[1].length <= 1
                                        ? attendance.checkIn
                                            .toString()
                                            .split(".")[0] +
                                          ":" +
                                          attendance.checkIn
                                            .toString()
                                            .split(".")[1] +
                                          "0 AM"
                                        : attendance.checkIn
                                            .toString()
                                            .split(".")[0] +
                                          ":" +
                                          attendance.checkIn
                                            .toString()
                                            .split(".")[1] +
                                          " AM"
                                      : attendance.checkIn + ":00 AM"
                                    : "_"}
                                </td>
                                <td className="text-danger">
                                  <time>
                                    {attendance.checkIn > "0"
                                      ? (attendance.checkIn.includes(".")
                                          ? parseInt(
                                              +attendance.checkIn.split(
                                                "."
                                              )[0] *
                                                60 *
                                                60 +
                                                +attendance.checkIn.split(
                                                  "."
                                                )[1] *
                                                  60
                                            )
                                          : parseInt(
                                              attendance.checkIn * 60 * 60
                                            )) >
                                          attendance.reportingTime + 1800 &&
                                        attendance.checkIn
                                          .toString()
                                          .split(".")[0] < 12
                                        ? attendance.checkIn.indexOf(".") !== -1
                                          ? attendance.checkIn
                                              .toString()
                                              .split(".")[1].length <= 1
                                            ? attendance.checkIn
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkIn
                                                .toString()
                                                .split(".")[1] +
                                              "0 AM"
                                            : attendance.checkIn
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkIn
                                                .toString()
                                                .split(".")[1] +
                                              " AM"
                                          : attendance.checkIn + ":00 AM"
                                        : (attendance.checkIn.includes(".")
                                            ? parseInt(
                                                +attendance.checkIn.split(
                                                  "."
                                                )[0] *
                                                  60 *
                                                  60 +
                                                  +attendance.checkIn.split(
                                                    "."
                                                  )[1] *
                                                    60
                                              )
                                            : parseInt(
                                                attendance.checkIn * 60 * 60
                                              )) >
                                            attendance.reportingTime + 1800 &&
                                          attendance.checkIn
                                            .toString()
                                            .split(".")[0] >= 12
                                        ? attendance.checkIn.indexOf(".") !== -1
                                          ? attendance.checkIn
                                              .toString()
                                              .split(".")[1].length <= 1
                                            ? attendance.checkIn
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkIn
                                                .toString()
                                                .split(".")[1] +
                                              "0 PM"
                                            : attendance.checkIn
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkIn
                                                .toString()
                                                .split(".")[1] +
                                              " PM"
                                          : attendance.checkIn + ":00 PM"
                                        : "_"
                                      : "_"}
                                  </time>
                                </td>
                                <td>
                                  <span className="text-danger">
                                    <time>
                                      {attendance.checkOut > "0" &&
                                      attendance.totalHours < 32400 &&
                                      attendance.checkIn !== "0" &&
                                      attendance.checkOut
                                        .toString()
                                        .split(".")[0] <= 12
                                        ? attendance.checkOut.indexOf(".") !==
                                          -1
                                          ? attendance.checkOut
                                              .toString()
                                              .split(".")[1].length <= 1
                                            ? attendance.checkOut
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkOut
                                                .toString()
                                                .split(".")[1] +
                                              "0 AM"
                                            : attendance.checkOut
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkOut
                                                .toString()
                                                .split(".")[1] +
                                              " AM"
                                          : attendance.checkOut + ":00 AM"
                                        : attendance.checkOut > "0" &&
                                          attendance.totalHours < 32400 &&
                                          attendance.checkIn !== "0" &&
                                          attendance.checkOut
                                            .toString()
                                            .split(".")[0] > 12
                                        ? attendance.checkOut.indexOf(".") !==
                                          -1
                                          ? attendance.checkOut
                                              .toString()
                                              .split(".")[1].length <= 1
                                            ? attendance.checkOut
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkOut
                                                .toString()
                                                .split(".")[1] +
                                              "0 PM"
                                            : attendance.checkOut
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkOut
                                                .toString()
                                                .split(".")[1] +
                                              " PM"
                                          : attendance.checkOut + ":00 PM"
                                        : "_"}
                                    </time>
                                  </span>
                                </td>
                                <td>
                                  {attendance.checkOut > "0" ? (
                                    attendance.totalHours === 0 ? (
                                      <time>
                                        {attendance.checkOut.indexOf(".") !== -1
                                          ? attendance.checkOut
                                              .toString()
                                              .split(".")[1].length <= 1
                                            ? attendance.checkOut
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkOut
                                                .toString()
                                                .split(".")[1] +
                                              "0 PM"
                                            : attendance.checkOut
                                                .toString()
                                                .split(".")[0] +
                                              ":" +
                                              attendance.checkOut
                                                .toString()
                                                .split(".")[1] +
                                              " PM"
                                          : attendance.checkOut + ":00 PM"}
                                      </time>
                                    ) : attendance.totalHours >= 32400 ? (
                                      <span className="green">
                                        <time>
                                          {attendance.checkOut
                                            .toString()
                                            .split(".")[0] < 12
                                            ? attendance.checkOut.indexOf(
                                                "."
                                              ) !== -1
                                              ? attendance.checkOut
                                                  .toString()
                                                  .split(".")[1].length <= 1
                                                ? attendance.checkOut
                                                    .toString()
                                                    .split(".")[0] +
                                                  ":" +
                                                  attendance.checkOut
                                                    .toString()
                                                    .split(".")[1] +
                                                  "0 AM"
                                                : attendance.checkOut
                                                    .toString()
                                                    .split(".")[0] +
                                                  ":" +
                                                  attendance.checkOut
                                                    .toString()
                                                    .split(".")[1] +
                                                  " AM"
                                              : attendance.checkOut + ":00 AM"
                                            : attendance.checkOut.indexOf(
                                                "."
                                              ) !== -1
                                            ? attendance.checkOut
                                                .toString()
                                                .split(".")[1].length <= 1
                                              ? attendance.checkOut
                                                  .toString()
                                                  .split(".")[0] +
                                                ":" +
                                                attendance.checkOut
                                                  .toString()
                                                  .split(".")[1] +
                                                "0 PM"
                                              : attendance.checkOut
                                                  .toString()
                                                  .split(".")[0] +
                                                ":" +
                                                attendance.checkOut
                                                  .toString()
                                                  .split(".")[1] +
                                                " PM"
                                            : attendance.checkOut + ":00 PM"}
                                        </time>
                                      </span>
                                    ) : (
                                      "_"
                                    )
                                  ) : (
                                    "_"
                                  )}
                                </td>
                                <td>
                                  {moment
                                    .utc(attendance.totalHours * 1000)
                                    .format("HH:mm") <
                                    moment.utc(32400 * 1000).format("HH:mm") &&
                                  moment
                                    .utc(attendance.totalHours * 1000)
                                    .format("HH:mm") >=
                                    moment.utc(28800 * 1000).format("HH:mm") ? (
                                    <span
                                      className="yellow"
                                      to={`/admin-attendance/${attendance.employeeId}`}
                                    >
                                      {attendance.totalHours
                                        ? moment
                                            .utc(attendance.totalHours * 1000)
                                            .format("HH.mm")
                                        : "00.00"}
                                    </span>
                                  ) : moment
                                      .utc(attendance.totalHours * 1000)
                                      .format("HH:mm") <
                                    moment.utc(32400 * 1000).format("HH:mm") ? (
                                    <span
                                      className="text-danger"
                                      to={`/admin-attendance/${attendance.employeeId}`}
                                    >
                                      {attendance.totalHours
                                        ? moment
                                            .utc(attendance.totalHours * 1000)
                                            .format("HH.mm")
                                        : "00.00"}
                                    </span>
                                  ) : (
                                    <span
                                      className="green"
                                      to={`/admin-attendance/${attendance.employeeId}`}
                                    >
                                      {attendance.totalHours
                                        ? moment
                                            .utc(attendance.totalHours * 1000)
                                            .format("HH.mm")
                                        : "00.00"}
                                    </span>
                                  )}
                                </td>

                                <td className={colorType(attendance.status)}>
                                  {statusValue(attendance.status)}
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })
          : " "}
        {!_.isEmpty(AttendenceDetails) ? (
          ""
        ) : (
          <h3 className="text-center no-data">No data available</h3>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="body_container cm_bold">
      {loader && <Loader />}
      <div className="cm_breadcrumb">
        <ul>
          <li>
            <Link to={url}>DAILY ATTENDANCE</Link>
            <div className="cm_breadcrumb_arrow"></div>
          </li>
          <li style={{ textTransform: "uppercase" }}>
            {employeeName}'S RECORD
          </li>
        </ul>
      </div>

      <div className="attendance_tab">
        <Tabs
          className="m-0"
          activeKey={activeTab}
          id=" "
          onSelect={(e) => handleTabChange(e)}
        >
          {tabs.map((tab) => (
            <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title}>
              {attendance()}
            </Tab>
          ))}
        </Tabs>
        {!_.isEmpty(state.AttendanceDetails)
          ? state.AttendanceDetails.map((page, i) => (
              <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={page.dataCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            ))
          : ""}
      </div>
    </div>
  );
};

export default ViewDailyAttendence;

import React, { useState } from "react";
import _ from "lodash";
import { CONSTANTS } from "../../../config";
import { schemeValue } from "./InvestmentFormConstants";
import UploadRentSlipModal from "../InvestmentModal/UploadRentSlip";
import { handleRentUploadValidation } from "./Validations";
import {
  uploadFilesInvestment,
  uploadRentSlip,
} from "../../../config/services/employeeServices";
import ResponseFilter from "../../../ResponseFilter";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;

const InvestmentFormTable = (props) => {
  const {
    data,
    handlePage,
    sortType,
    sortField,
    handleSort,
    url,
    pageNo,
    count,
    handleOpenDelete,
    setLoader,
    getData,
    setResMsg,
    setSuccessMessage,
    setResMsgFail,
    setResponseMessage,
  } = props;
  const [openUploadRentSlip, setOpenUploadRentSlip] = useState(false);
  const [rentMonth, setRentMonth] = useState("");
  const [rentFile, setRentFile] = useState("");
  const [uploadErr, setUploadErr] = useState("");
  const [rentFileData, setRentFileData] = useState([]);
  const [investementYear, setInvestementYear] = useState("");
  const [upload, setUpload] = useState(false);
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const handleEdit = (id) => {
    window.location.href = `/investment-form/${id}?toEdit`;
  };
  const handleOpenUploadRentSlip = (investementYear, rentSlip, upload) => {
    setOpenUploadRentSlip(true);
    setInvestementYear(investementYear);
    setRentFileData(rentSlip);
    setUpload(upload);
  };
  const closeModal = () => {
    setOpenUploadRentSlip(false);
    setRentMonth("");
    setRentFile("");
    setUploadErr("");
    setRentFileData([]);
    setUpload(false);
  };
  const handleMonth = (e) => {
    setRentMonth(e);
    setUploadErr("");
  };

  const handleRentFile = (e) => {
    const acceptedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];
    const file = e.target.files[0];
    if (file && !acceptedTypes.includes(file.type)) {
      setUploadErr("*Only PDF, PNG, JPG files are accepted");
    } else {
      setRentFile(e.target.files[0]);
      setUploadErr("");
    }
  };
  const handleUploadRentSlip = () => {
    if (
      handleRentUploadValidation(rentMonth, rentFile, setUploadErr) &&
      !uploadErr
    ) {
      setLoader(true);
      let params = new FormData();
      params.append("files", rentFile);
      uploadFilesInvestment(params)
        .then((res) => {
          let { status } = ResponseFilter(res);
          if (status) {
            let params1 = {
              month: +rentMonth,
              file: res.data.responseData.links[0],
              investementYear: investementYear,
            };
            uploadRentSlip(params1)
              .then((res) => {
                setLoader(false);
                let { status } = ResponseFilter(res);
                if (status) {
                  setSuccessMessage("Rent slip uploaded");
                  setResMsg(true);
                  setTimeout(() => {
                    setResMsg(false);
                    setSuccessMessage("");
                  }, 3000);
                  getData();
                  closeModal();
                } else {
                  closeModal();
                  setResponseMessage(res?.data?.error?.responseMessage);
                  setResMsgFail(true);
                  setTimeout(() => {
                    setResMsgFail(false);
                    setResponseMessage("");
                  }, 3000);
                }
              })
              .catch((error) => {
                setResponseMessage(error);
                setResMsgFail(true);
                setTimeout(() => {
                  setResMsgFail(false);
                  setResponseMessage("");
                }, 3000);
                getData();
                closeModal();
              });
          } else {
            setLoader(false);
            setResponseMessage(res?.data?.error?.responseMessage);
            setResMsgFail(true);
            setTimeout(() => {
              setResMsgFail(false);
              setResponseMessage("");
            }, 3000);
            getData();
            closeModal();
          }
        })
        .catch((error) => {
          setLoader(false);
          setResponseMessage(error);
          setResMsgFail(true);
          setTimeout(() => {
            setResMsgFail(false);
            setResponseMessage("");
          }, 3000);
          getData();
          closeModal();
        });
    }
  };

  return (
    <div>
      {!_.isEmpty(data) && data.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="white bg_blue">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">
                  Date
                  <i
                    className={
                      sortType === SORT_TYPE_CONSTANTS.Asscending &&
                      sortField === "created"
                        ? "fa fa-sort-numeric-asc ml-2"
                        : "fa fa-sort-numeric-desc ml-2"
                    }
                    onClick={() => handleSort("created")}
                  ></i>
                </th>
                {!url && (
                  <>
                    <th scope="col">
                      Employee Id
                      <i
                        className={
                          sortType === SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "employee_Code"
                            ? "fa fa-sort-alpha-asc ml-2"
                            : "fa fa-sort-alpha-desc ml-2"
                        }
                        onClick={() => handleSort("employee_Code")}
                      ></i>
                    </th>
                    <th scope="col">
                      Employee Name
                      <i
                        className={
                          sortType === SORT_TYPE_CONSTANTS.Asscending &&
                          sortField === "employee_Name"
                            ? "fa fa-sort-alpha-asc ml-2"
                            : "fa fa-sort-alpha-desc ml-2"
                        }
                        onClick={() => handleSort("employee_Name")}
                      ></i>
                    </th>
                  </>
                )}
                <th scope="col">Scheme</th>
                <th scope="col">Investment Year</th>
                <th scope="col">Rent Slip</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <span className="cm_col">
                      {index + 1 + (pageNo - 1) * count}
                    </span>
                  </td>
                  <td>
                    <span className="cm_col">
                      {new Date(item.created)
                        .getDate()
                        .toString()
                        .padStart(2, "0") +
                        "/" +
                        (new Date(item.created).getMonth() + 1)
                          .toString()
                          .padStart(2, "0") +
                        "/" +
                        new Date(item.created)
                          .getFullYear()
                          .toString()
                          .slice(-2)}
                    </span>
                  </td>
                  {!url && (
                    <>
                      <td>{item.employee_Code.toUpperCase()}</td>
                      <td>{item.employee_Name}</td>
                    </>
                  )}
                  <td>{schemeValue(item.scheme)}</td>
                  <td>{item.investementYear}</td>
                  <td>
                    {url && (
                      <button
                        onClick={() =>
                          handleOpenUploadRentSlip(
                            item.investementYear,
                            item.rentSlip,
                            true
                          )
                        }
                        className="btn btn-primary btn-sm upload_view_btn ml-2"
                      >
                        upload
                      </button>
                    )}
                    {item.rentSlip && item.rentSlip.length ? (
                      <button
                        onClick={() =>
                          handleOpenUploadRentSlip(
                            item.investementYear,
                            item.rentSlip,
                            false
                          )
                        }
                        className="btn btn-primary btn-sm upload_view_btn ml-2"
                      >
                        view
                      </button>
                    ) : (
                      !url && (
                        <div className="d-flex justify-content-center">
                          {"_"}
                        </div>
                      )
                    )}
                  </td>
                  <td>
                    <span className="table_name border-top-0 p-0">
                      <i
                        className="fa fa-eye blue"
                        aria-hidden="true"
                        title="View Investment"
                        onClick={() => handlePage(item._id, "true")}
                      ></i>
                    </span>
                    {url &&
                      formattedDate !== "01-08-2024" &&
                      currentDate <= new Date("2024-08-01") && (
                        <span
                          role="button"
                          className="table_name border-top-0 p-0 ml-3"
                        >
                          <i
                            className="fa fa-pencil"
                            aria-hidden="true"
                            title="Edit Investment"
                            onClick={() => handleEdit(item._id)}
                          ></i>
                        </span>
                      )}
                    {!url && (
                      <span
                        role="button"
                        className="table_name border-top-0 p-0 ml-3"
                      >
                        <i
                          className="fa fa-trash red"
                          aria-hidden="true"
                          title="Delete Investment"
                          onClick={() => handleOpenDelete(item._id)}
                        ></i>
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="loaderDiv">
          <h3>No data available</h3>
        </div>
      )}
      <UploadRentSlipModal
        openUploadRentSlip={openUploadRentSlip}
        closeModal={closeModal}
        handleMonth={handleMonth}
        handleRentFile={handleRentFile}
        rentMonth={rentMonth}
        rentFile={rentFile}
        handleUploadRentSlip={handleUploadRentSlip}
        uploadErr={uploadErr}
        rentFileData={rentFileData}
        upload={upload}
      />
    </div>
  );
};

export default InvestmentFormTable;

import React, { useEffect, useReducer } from "react";
import {
  applyLeaveByEmployee,
  getEmployeeListByManager,
} from "../../config/services/employeeServices";
import { DatePicker, Select, TimePicker } from "antd";
import moment from "moment";
import "./ApplyLeave.css";
import {
  disabledLeaveEndDate,
  disabledStartDate,
  format,
  Half_Casual_Leave,
  Half_Compensatory_Off,
  Half_Earned_Leave,
  HALF_LEAVE_ARRAY,
  Half_Leave_Short_Leave,
  Half_LWP,
  Half_Other,
  Half_Sick_Leave,
  Half_WFH,
  halfLeaveValues,
  LEAVE_ARRAY,
  leaveValue,
  removeSessionStorage,
  SHORT_DAY_LEAVE,
  Short_Day_Leave,
} from "./Constants";
import { ErrorMessage, reducer, Toastify, type } from "../helper/Constant";
import { handleValidation } from "./LeaveValidation";
import { Loader } from "../svg";
import TextEditor from "../../hoc/textEditor/TextEditor";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ResponseFilter from "../../ResponseFilter";
import _ from "lodash";
import UploadDocument from "./uploadDocument";
const { Option } = Select;
const dateFormat = "DD-MM-YYYY";
const initialState = {
  miniLoader: false,
  leaveMsg: "",
  reasonErMsg: "",
  leaveErMsg: "",
  exceedMsg: "",
  startDate: "",
  endDate: "",
  startDateErrMsg: "",
  applyDateErrMsg: "",
  endDateErrMsg: "",
  endOpen: false,
  empDetails: [],
  value: "",
  emails: [],
  emailError: "",
  inputEmail: "",
  halfOfTheDay: "",
  halfOfTheDayErrMsg: "",
  resLeaveMsg: false,
  resFailMsg: false,
  shortDayTo: "",
  shortDayFrom: "",
  shortDayToErrorMsg: "",
  shortDayFromErrorMsg: "",
  leaveType: "",
  responseMessage: "",
  editorState: EditorState.createEmpty(),
  employeeList: [],
  uploadDocument: [],
  uploadDocumentName: [],
  uploadDocumentErrMsg: "",
};

function ApplyLeave(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dashboardStartDate = sessionStorage.getItem(
    "dashboard_leave_startDate"
  );
  const dashboardLeaveType = sessionStorage.getItem("dashboard_leaveType");
  const {
    assignedClientOff,
    assignedBereavementLeaves,
    assignedPaternalLeave,
  } = props;
  const {
    miniLoader,
    leaveMsg,
    reasonErMsg,
    leaveErMsg,
    exceedMsg,
    startDate,
    endDate,
    startDateErrMsg,
    applyDateErrMsg,
    endDateErrMsg,
    endOpen,
    empDetails,
    emails,
    inputEmail,
    halfOfTheDay,
    halfOfTheDayErrMsg,
    resLeaveMsg,
    resFailMsg,
    shortDayTo,
    shortDayFrom,
    shortDayToErrorMsg,
    shortDayFromErrorMsg,
    leaveType,
    responseMessage,
    editorState,
    employeeList,
    uploadDocument,
    uploadDocumentName,
    uploadDocumentErrMsg,
  } = state;

  const onEditorStateChange = (editorState) => {
    dispatch({
      type: type,
      payload: {
        editorState: editorState,
        reasonErMsg: "",
      },
    });
  };

  useEffect(() => {
    getEmployeeListByManager("").then((res) => {
      let { status } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            employeeList: res.data.responseData.employeesList,
          },
        });
      }
    });
  }, []);

  const handleSubmit = (e) => {
    dispatch({
      type: type,
      payload: {
        uploadDocumentErrMsg: "",
      },
    });
    e.preventDefault();
    if (
      handleValidation({
        empDetails: empDetails,
        emails: emails,
        inputEmail: inputEmail,
        leaveMsg: leaveMsg,
        editorState: editorState,
        leaveType: leaveType,
        startDate: startDate,
        endDate: endDate,
        dispatch: dispatch,
        halfOfTheDay: halfOfTheDay,
        shortDayFrom: shortDayFrom,
        shortDayTo: shortDayTo,
        totalRemainingLeave: props?.totalRemainingLeave,
        dashboardLeaveType: dashboardLeaveType,
        dashboardStartDate: dashboardStartDate,    
        assignedClientOff,
        assignedBereavementLeaves,
        assignedPaternalLeave,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          miniLoader: true,
        },
      });
      let startD = new Date(startDate);
      let endD = new Date(endDate);
      let formattedDate1 =
        startD.getFullYear() +
        "-" +
        (startD.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        startD.getDate().toString().padStart(2, "0");
      let formattedDate2 =
        endD.getFullYear() +
        "-" +
        (endD.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        endD.getDate().toString().padStart(2, "0");
      let leaveReason = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      let formData = new FormData();
      formData.append("leaveMsg", leaveReason);
      formData.append(
        "leaveType",
        leaveType && !dashboardLeaveType
          ? +leaveType
          : leaveType
          ? +leaveType
          : +dashboardLeaveType
      );
      formData.append("from", shortDayFrom);
      formData.append("to", shortDayTo);
      formData.append(
        "startDate",
        !leaveType && dashboardStartDate
          ? dashboardStartDate
          : leaveType && !startDate && dashboardStartDate
          ? dashboardStartDate
          : formattedDate1
      );
      formData.append(
        "endDate",
        leaveType &&
          !dashboardStartDate &&
          Half_Leave_Short_Leave.includes(leaveType)
          ? formattedDate1
          : leaveType &&
            dashboardLeaveType &&
            startDate === "" &&
            Half_Leave_Short_Leave.includes(leaveType)
          ? dashboardStartDate
          : !leaveType &&
            dashboardLeaveType &&
            startDate === "" &&
            Half_Leave_Short_Leave.includes(dashboardLeaveType)
          ? dashboardStartDate
          : leaveType && dashboardLeaveType && startDate
          ? formattedDate1
          : formattedDate2
      );
      formData.append("emailInCC", JSON.stringify(emails));
      formData.append(
        "halfOfTheDay",
        leaveType === Half_Casual_Leave ||
          leaveType === Half_Earned_Leave ||
          leaveType === Half_Sick_Leave ||
          leaveType === Half_LWP ||
          leaveType === Half_Compensatory_Off ||
          leaveType === Half_WFH ||
          dashboardLeaveType === Half_Casual_Leave ||
          dashboardLeaveType === Half_Earned_Leave ||
          dashboardLeaveType === Half_Sick_Leave ||
          dashboardLeaveType === Half_LWP ||
          dashboardLeaveType === Half_Compensatory_Off ||
          dashboardLeaveType === Half_WFH
          ? halfOfTheDay
          : 0
      );
      uploadDocument.forEach((file) => {
        formData.append("leaveDoc", file);
      });
      applyLeaveByEmployee(formData).then((res) => {
        if (dashboardStartDate) {
          dispatch({
            type: type,
            payload: {
              endDate: "",
              emails: [],
              editorState: EditorState.createEmpty(),
              startDate: "",
            },
          });
        }
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              resLeaveMsg: true,
              miniLoader: false,
            },
          });
        } else {
          dispatch({
            type: type,
            payload: {
              loader: false,
              miniLoader: false,
              resFailMsg: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
        }
        if (res.data.statusCode === 1) {
          setTimeout(() => {
            dispatch({
              type: type,
              payload: {
                resLeaveMsg: false,
              },
            });
            window.location.href = "/leaves";
          }, 2000);
        } else {
          setTimeout(() => {
            dispatch({
              type: type,
              payload: {
                resFailMsg: false,
                responseMessage: "",
              },
            });
          }, 2000);
        }
        removeSessionStorage();
      });
    }
  };

  const handleSelectChange = (e) => {
    dispatch({
      type: type,
      payload: {
        leaveType: e,
        leaveErMsg: "",
        exceedMsg: "",
      },
    });
  };

  const handleCheckInChange = (checkIn, checkInString) => {
    dispatch({
      type: type,
      payload: {
        shortDayFrom: moment.duration(checkInString).asSeconds(),
        shortDayFromErrorMsg: "",
      },
    });
  };

  const handleCheckOutChange = (checkOut, checkOutString) => {
    dispatch({
      type: type,
      payload: {
        shortDayTo: moment.duration(checkOutString).asSeconds(),
        shortDayToErrorMsg: "",
      },
    });
  };

  const onStartChange = (value) => {
    onChangeDate("startDate", moment(value).toISOString());
  };

  const onChangeDate = (field, value) => {
    dispatch({
      type: type,
      payload: {
        [field]: value,
        startDateErrMsg: "",
        applyDateErrMsg: "",
        endDateErrMsg: "",
        exceedMsg: "",
      },
    });
  };

  const handleSelectHalfChange = (e) => {
    dispatch({
      type: type,
      payload: {
        halfOfTheDay: e,
        halfOfTheDayErrMsg: "",
      },
    });
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      dispatch({
        type: type,
        payload: {
          endOpen: true,
        },
      });
    }
  };

  const handleEndOpenChange = (open) => {
    dispatch({
      type: type,
      payload: {
        endOpen: open,
      },
    });
  };

  const handleCancel = (e) => {
    if (dashboardLeaveType) {
      removeSessionStorage();
      window.location.href = "/leaves";
    }
    props.reverseView();
  };

  const onEndChange = (value) => {
    onChangeDate("endDate", moment(value).toISOString());
  };

  const handleEmailChange = (e) => {
    dispatch({
      type: type,
      payload: {
        emailError: null,
        emails: e,
      },
    });
  };

  const getDisabledHoursAndMinutes = (fromTime, toTime) => {
    if (!fromTime) return { disabledHours: [], disabledMinutes: [] };

    const fromMoment = moment.utc(fromTime * 1000);
    const toMoment = moment.utc(toTime * 1000);

    const maxTime = fromMoment.clone().add(2, "hours").add(30, "minutes");

    const disabledHours = [];
    const disabledMinutes = [];
    for (let i = 0; i <= 23; i++) {
      if (
        i < fromMoment.hour() ||
        (i === fromMoment.hour() && i < fromMoment.minute())
      ) {
        disabledHours.push(i);
      }
      if (i > maxTime.hour()) {
        disabledHours.push(i);
      }
    }
    if (maxTime.hour() == toMoment.hour()) {
      for (let i = maxTime.minute() + 1; i < 60; i++) {
        disabledMinutes.push(i);
      }
    }
    return { disabledHours, disabledMinutes };
  };
  return (
    <div>
      <div>
        <Toastify
          resMsg={resLeaveMsg}
          text={
            (leaveType && !dashboardLeaveType
              ? leaveValue(+leaveType)
              : !leaveType && dashboardLeaveType
              ? leaveValue(+dashboardLeaveType)
              : leaveValue(+leaveType)) + " Applied"
          }
          resMsgFail={resFailMsg}
          responseMessage={responseMessage}
        />
      </div>
      <div className="cm_breadcrumb cm_bold">
        <ul>
          <li
            style={{
              textTransform: "uppercase",
              color: "black",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            LEAVES RECORD
            <div className="cm_breadcrumb_arrow"></div>
          </li>

          <li>Apply Leave</li>
        </ul>
      </div>
      {miniLoader ? (
        <Loader />
      ) : (
        <>
          <form className="">
            <h3 className="mt-5 mb-5">Apply Leave </h3>
            <div
              className={`row  ${
                !dashboardLeaveType && leaveErMsg ? "input_error" : ""
              }`}
            >
              <div className="form-group col-md-4 col-sm-12">
                <label className=" sub">Leave Type</label>
                <Select
                  className="cm_leaves "
                  placeholder="Select Leave"
                  value={
                    leaveType
                      ? leaveType
                      : dashboardLeaveType
                      ? dashboardLeaveType
                      : "Select Leave"
                  }
                  onChange={handleSelectChange}
                >
                  {LEAVE_ARRAY.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <label className="sub"> Send To</label>
                <main className="">
                  <input
                    className={`email-input email-input-height`}
                    value={"hr@mobcoder.com"}
                    disabled
                  />
                </main>
              </div>
            </div>
            {!dashboardLeaveType && leaveErMsg ? (
              <span className="error_msg">{leaveErMsg}</span>
            ) : null}
            {((leaveType &&
              (leaveType === "6" ||
                leaveType === "7" ||
                leaveType === "8" ||
                leaveType === "9" ||
                leaveType === "10" ||
                leaveType === "15")) ||
              (dashboardLeaveType &&
                !leaveType &&
                dashboardLeaveType === "6") ||
              dashboardLeaveType === "7" ||
              dashboardLeaveType === "8" ||
              dashboardLeaveType === "9" ||
              dashboardLeaveType === "10" ||
              dashboardLeaveType === "15") && (
              <>
                <div
                  className={`row  ${halfOfTheDayErrMsg ? "input_error" : ""}`}
                >
                  <div className="col-md-4 col-sm-12 mt-4">
                    <label className=" sub">Half Type</label>
                    <Select
                      placeholder="Select Half Type"
                      value={halfOfTheDay ? halfOfTheDay : "Select Half Type"}
                      onChange={handleSelectHalfChange}
                    >
                      <Option value={1}>First Half Leave</Option>
                      <Option value={2}>Second Half Leave</Option>
                    </Select>
                  </div>
                </div>{" "}
                {halfOfTheDayErrMsg ? (
                  <span className="error_msg">{halfOfTheDayErrMsg}</span>
                ) : null}
              </>
            )}
            <br /> <br />
            <div className={`row  ${exceedMsg ? "input_error" : ""}`}>
              <div
                className={`form-group col-md-4 col-sm-12 ${
                  startDateErrMsg || applyDateErrMsg ? "input_error" : ""
                }`}
              >
                <label className="sub">Start Date</label>
                <DatePicker
                  className="date-border"
                  allowClear={false}
                  disabledDate={disabledStartDate}
                  value={
                    !startDate && dashboardStartDate
                      ? moment(dashboardStartDate)
                      : startDate
                      ? moment(startDate)
                      : startDate
                  }
                  placeholder="Start date"
                  format={dateFormat}
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
                {startDateErrMsg && <ErrorMessage errMsg={startDateErrMsg} />}
                {applyDateErrMsg && <ErrorMessage errMsg={applyDateErrMsg} />}
                {exceedMsg && <ErrorMessage errMsg={exceedMsg} />}
              </div>
              {halfLeaveValues.includes(leaveType) ||
              (!leaveType && halfLeaveValues.includes(dashboardLeaveType)) ||
              (!leaveType && dashboardLeaveType === Short_Day_Leave) ||
              leaveType === Short_Day_Leave ? (
                ""
              ) : (
                <div
                  className={`form-group col-md-4 col-sm-12 ${
                    endDateErrMsg ? "input_error" : ""
                  }`}
                >
                  <label className="sub">End Date</label>
                  <DatePicker
                    className="date-border"
                    allowClear={false}
                    format={dateFormat}
                    disabledDate={(e) =>
                      disabledLeaveEndDate(
                        e,
                        dashboardLeaveType && !leaveType
                          ? dashboardLeaveType
                          : leaveType,
                        dashboardStartDate && !startDate
                          ? dashboardStartDate
                          : startDate,
                        props?.totalRemainingLeave,
                        assignedClientOff,
                        assignedBereavementLeaves,
                        assignedPaternalLeave,
                    
                      )
                    }
                    value={!endDate ? endDate : moment(endDate)}
                    placeholder="End date"
                    onChange={onEndChange}
                    open={endOpen}
                    onOpenChange={handleEndOpenChange}
                  />
                  {endDateErrMsg ? (
                    <span className="error_msg">{endDateErrMsg}</span>
                  ) : null}
                </div>
              )}
            </div>
            {leaveType === Short_Day_Leave ||
            (dashboardLeaveType === Short_Day_Leave && !leaveType) ? (
              <div className="row">
                <div
                  className={`form-group col-md-4 col-sm-12 ${
                    shortDayFromErrorMsg ? "input_error" : ""
                  }`}
                >
                  <label className="sub"> From</label>
                  <TimePicker
                    className="form-control"
                    allowClear={false}
                    disabledHours={() => [
                      0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23,
                    ]}
                    format={format}
                    value={shortDayFrom ? moment.utc(shortDayFrom * 1000) : ""}
                    onChange={handleCheckInChange}
                  />
                  {shortDayFromErrorMsg ? (
                    <span className="error_msg">{shortDayFromErrorMsg}</span>
                  ) : null}
                </div>
                <div
                  className={`form-group col-md-4 col-sm-12 ${
                    shortDayToErrorMsg ? "input_error" : ""
                  }`}
                >
                  <label className="sub">To</label>
                  <TimePicker
                    className="form-control"
                    allowClear={false}
                    format={format}
                    value={shortDayTo ? moment.utc(shortDayTo * 1000) : ""}
                    onChange={handleCheckOutChange}
                    disabledHours={() =>
                      getDisabledHoursAndMinutes(shortDayFrom, shortDayTo)
                        .disabledHours
                    }
                    disabledMinutes={() =>
                      getDisabledHoursAndMinutes(shortDayFrom, shortDayTo)
                        .disabledMinutes
                    }
                  />
                  {shortDayToErrorMsg ? (
                    <span className="error_msg">{shortDayToErrorMsg}</span>
                  ) : null}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label className="mt-5 sub">Cc</label>
                <br />
                {emails.length > 0 ? (
                  ""
                ) : (
                  <label className="red sub">
                    * Please mention your project manager and teamlead in Cc
                  </label>
                )}
                <Select
                  showSearch
                  mode="multiple"
                  name="employeeId"
                  value={emails ? emails : "Select Cc"}
                  placeholder={"Select Cc"}
                  optionFilterProp="key"
                  filterOption={(input, option) =>
                    option.key.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={handleEmailChange}
                >
                  {!_.isEmpty(employeeList)
                    ? employeeList
                        .filter(
                          (data) =>
                            data.employeeId !==
                            localStorage.getItem("employeeId")
                        )
                        .map(
                          (employee) =>
                            employee.status == 1 && (
                              <Option
                                key={`${employee.firstName} ${employee.lastName}`}
                                value={employee.email}
                              >
                                <span>{employee.email}</span>
                              </Option>
                            )
                        )
                    : ""}
                </Select>
              </div>
            </div>
            <UploadDocument
              dispatch={dispatch}
              uploadDocument={uploadDocument}
              uploadDocumentName={uploadDocumentName}
              uploadDocumentErrMsg={uploadDocumentErrMsg}
            />
            <div className="row">
              <div
                className={`form-group col-md-10 col-sm-12${
                  reasonErMsg ? "input_error" : ""
                }`}
              >
                <label className="mt-5 sub">Reason</label>
                <TextEditor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />
                {reasonErMsg ? (
                  <span className="error_msg">{reasonErMsg}</span>
                ) : null}
              </div>
            </div>
            <div className=" submit_button_margin mb-3 mt-4">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary cm_btn3"
                disabled={miniLoader}
              >
                Submit
              </button>
              {miniLoader ? (
                <div className="mini_loader_dept leave_loader"></div>
              ) : (
                ""
              )}
              {(miniLoader || !dashboardLeaveType) && (
                <button
                  type="button"
                  className="btn btn-primary cm_btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default ApplyLeave;

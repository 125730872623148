import React, { useEffect, useMemo, useReducer } from "react";
import {
  Toastify,
  currentMonth,
  isAdmin,
  reducer,
  type,
} from "../helper/Constant";
import { useDispatch } from "react-redux";
import AddTeam from "./teamManagementModal/AddTeam";
import EditTeamModal from "./teamManagementModal/EditTeamModal";
import {
  getEmployeeList,
  getTlAndAtl,
} from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import _ from "lodash";
import { Link } from "react-router-dom";
import { teamAddValidation, teamEditValidation } from "./TeamValidation";
import { addTeamByAdminAction } from "../../redux/actions/addTeamByAdminAction";
import { getTeamByAdminAction } from "../../redux/actions/getTeamByAdminAction";
import { Loader } from "../svg";
import { deleteTeamByAdminAction } from "../../redux/actions/deleteTeamByAdminAction";
import { Dropdown, Button, Menu } from "antd";
import { editTeamByAdminAction } from "../../redux/actions/editTeamByAdminAction";
import { DeleteTeamModal } from "./teamManagementModal/DeleteTeam";
import CompareTeam from "./CompareTeam";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { CONSTANTS } from "../../config";
import { getTeamCompareDataByAdminAction } from "../../redux/actions/getTeamCompareDataByAdminAction";
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS;

const initialState = {
  openAddTeamModal: false,
  openEditTeamModal: false,
  loader: false,
  employeeList: [],
  teamList: [],
  teamName: "",
  teamLead: [],
  associateTeamLead: [],
  teamIcon: "",
  editTeamIcon: "",
  teamMembers: [],
  teamNameErrorMsg: "",
  resTeamMsg: false,
  resTeamFailMsg: false,
  teamIconErrorMessage: "",
  teamLeadErrorMessage: "",
  associateTeamLeadErrorMessage: "",
  teamMembersErrorMessage: "",
  teamIconPreview: "",
  responseMessage: "",
  successMessage: "",
  teamId: "",
  previousTeamIcon: "",
  previousTeamIconPreview: "",
  openDeleteteamModal: false,
  openCompareDetail: false,
  count: 10,
  pageNo: 1,
  sortField: "teamName",
  sortType: SORT_TYPE_CONSTANTS.Asscending,
  teamCompareData: [],
  teamCompareCount: 0,
  searchMonth: currentMonth,
  searchYear: new Date().getFullYear(),
  teamLeadEdit: [],
};
export default function AdminTeam() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const {
    openAddTeamModal,
    employeeList,
    teamList,
    loader,
    teamName,
    teamIcon,
    teamLead,
    teamMembers,
    associateTeamLead,
    associateTeamLeadErrorMessage,
    resTeamMsg,
    resTeamFailMsg,
    responseMessage,
    teamIconErrorMessage,
    teamLeadErrorMessage,
    teamMembersErrorMessage,
    teamNameErrorMsg,
    teamIconPreview,
    successMessage,
    openEditTeamModal,
    teamId,
    editTeamIcon,
    previousTeamIcon,
    previousTeamIconPreview,
    openDeleteTeamModal,
    openCompareDetail,
    count,
    pageNo,
    sortField,
    sortType,
    teamCompareData,
    teamCompareCount,
    searchMonth,
    searchYear,
    teamLeadEdit,
  } = state;

  const dispatchToAction = useDispatch();
  const urlParams = `?openCompareDetail=${openCompareDetail}`;
  useEffect(() => {
    if (isAdmin === "4" ||  isAdmin === "1") {
      getEmployee();
      if (openCompareDetail == true) {
        getTeamCompareData();
      }
    }
    // getLeadsEmployee()
    getTeam();
  }, [openCompareDetail, searchMonth, searchYear, sortType, pageNo]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        openCompareDetail:
          filtersFromParams.openCompareDetail == "true"
            ? Boolean(filtersFromParams.openCompareDetail)
            : false,
        searchMonth: filtersFromParams.searchMonth
          ? filtersFromParams.searchMonth
          : currentMonth,
        searchYear: filtersFromParams.searchYear
          ? filtersFromParams.searchYear
          : new Date().getFullYear(),
        pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "teamName",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : SORT_TYPE_CONSTANTS.Asscending,
      },
    });
  }, [history.location.search]);

  const getEmployee = () => {
    let params = ``;
    getEmployeeList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            employeeList: resData.employeesList,
          },
        });
      }
    });
  };

  const getLeadsEmployee = () => {
    let params = ``;
    getTlAndAtl(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            // employeeList: resData.employeesList,
          },
        });
      }
    });
  };

  const getTeam = () => {
    history.push(urlParams);

    let params = {};
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    dispatchToAction(
      getTeamByAdminAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  const getTeamCompareData = () => {
    const additonalUrlParam = `&pageNo=${pageNo}&sortField=${sortField}&sortType=${sortType}&searchMonth=${searchMonth}&searchYear=${searchYear}`;

    history.push(urlParams + additonalUrlParam);

    let params = `sortField=${sortField}&sortType=${sortType}&searchMonth=${searchMonth}&searchYear=${searchYear}`;
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    dispatchToAction(
      getTeamCompareDataByAdminAction({
        useDispatch: dispatch,
        params: params,
      })
    );
  };

  const handleOpenAddTeam = () => {
    dispatch({
      type: type,
      payload: {
        openAddTeamModal: true,
      },
    });
  };

  const handleFileChange = (droppedFile) => {
    let reader = new FileReader();

    let file = droppedFile[0];

    dispatch({
      type: type,
      payload: {
        teamIcon: droppedFile[0],
        teamIconErrorMessage: "",
        docErrorMsg: "",
        prev: false,
      },
    });

    reader.addEventListener(
      "load",
      () => {
        dispatch({
          type: type,
          payload: {
            teamIconPreview: reader.result,
            teamIcon: droppedFile[0],
            departmentIconErrorMsg: "",
          },
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleEditFileChange = (droppedFile) => {
    // this.handleIconEditValidation()
    let reader = new FileReader();

    let file = droppedFile[0];

    dispatch({
      type: type,
      payload: {
        editTeamIcon: droppedFile[0],
        teamIconErrorMessage: "",
        docErrorMsg: "",
        prev: false,
      },
    });

    reader.addEventListener(
      "load",
      () => {
        dispatch({
          type: type,
          payload: {
            previousTeamIconPreview: reader.result,
            editTeamIcon: droppedFile[0],
            teamIconErrorMsg: "",
          },
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        openAddTeamModal: false,
        openEditTeamModal: false,
        teamName: "",
        teamNameErrorMsg: "",
        teamIcon: "",
        teamIconErrorMessage: "",
        teamIconPreview: "",
        teamLead: [],
        teamLeadErrorMessage: "",
        teamMembers: [],
        teamMembersErrorMessage: "",
        associateTeamLead: [],
        associateTeamLeadErrorMessage: "",
        teamId: "",
        openDeleteTeamModal: false,
        teamLeadEdit: [],
      },
    });
  };

  const handleTeamNameChange = (e) => {
    dispatch({
      type: type,
      payload: {
        teamName: /^\s/.test(e.target.value) ? "" : e.target.value,
        teamNameErrorMsg: "",
      },
    });
  };

  const handleSelectTeamLeadChange = (e) => {
    const Emp = state.employeeList.filter((ele) => {
      return ele.employeeId === e;
    });
    dispatch({
      type: type,
      payload: {
        teamLead: e,
        teamLeadEdit:[],
        teamLeadErrorMessage: "",
      },
    });
  };
  const handleSelectAssociateTeamLeadChange = (e) => {
    const Emp = state.employeeList.filter((ele) => {
      return ele.employeeId === e;
    });
    dispatch({
      type: type,
      payload: {
        associateTeamLead: e,
        associateTeamLeadErrorMessage: "",
      },
    });
  };

  const handleSelectTeamMembersChange = (e) => {
    const Emp = state.employeeList.filter((ele) => {
      return ele.employeeId === e;
    });
    dispatch({
      type: type,
      payload: {
        teamMembers: e,
        teamMembersErrorMessage: "",
      },
    });
  };

  const handleSubmit = () => {
    if (
      teamAddValidation({
        teamIcon: teamIcon,
        teamName: teamName,
        teamLead: teamLead.toString(),
        teamMembers: teamMembers.toString(),
        dispatch: dispatch,
      })
    ) {
      let obj = new FormData();
      obj.append("teamName", teamName.trim());
      obj.append("teamHead", JSON.stringify(teamLead));
      obj.append("teamMembers", JSON.stringify(teamMembers));
      obj.append("teamLogo", teamIcon);
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      dispatchToAction(
        addTeamByAdminAction({
          useDispatch: dispatch,
          obj: obj,
          getTeam: getTeam,
          closeModal: closeModal,
        })
      );
    }
  };

  const handleDeleteTeam = (team) => {
    dispatch({
      type: type,
      payload: {
        openDeleteTeamModal: true,
        teamId: team?._id?.teamId,
      },
    });
  };
  const handleEditClick = (team) => {
    dispatch({
      type: type,
      payload: {
        openEditTeamModal: true,
        employeeList: employeeList,
        teamLeadEdit: team?.teamHead,
        teamName: team?._id.teamName,
        teamIcon: team?.teamLogo,
        teamIconPreview: team?.teamLogo,
        previousTeamIcon: team?.teamLogo,
        previousTeamIconPreview: team?.teamLogo,
        teamId: team?._id?.teamId,
        teamMembers: team?.teamMembers,
      },
    });
  };
  const handleEditSubmit = () => {
    if (
      teamEditValidation({
        teamIcon: editTeamIcon,
        previousTeamIcon: previousTeamIcon,
        teamName: teamName,
        teamLead: teamLead.toString(),
        dispatch: dispatch,
      })
    ) {
      let obj = new FormData();
      obj.append("teamId", teamId);
      obj.append("teamName", teamName.trim());
      obj.append("teamHead", JSON.stringify(teamLead));
      obj.append("teamLogo", editTeamIcon ? editTeamIcon : previousTeamIcon);

      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      dispatchToAction(
        editTeamByAdminAction({
          useDispatch: dispatch,
          obj: obj,
          getTeam: getTeam,
          closeModal: closeModal,
        })
      );
    }
  };

  const handleDelete = () => {
    let data = {
      data: {
        teamId: teamId,
      },
    };
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    dispatchToAction(
      deleteTeamByAdminAction({
        useDispatch: dispatch,
        params: data,
        getTeam: getTeam,
      })
    );
  };
  const handleOpenCompareView = () => {
    dispatch({
      type: type,
      payload: {
        openCompareDetail: true,
      },
    });
  };

  const reverseView = () => {
    dispatch({
      type: type,
      payload: {
        openCompareDetail: false,
      },
    });
  };
  const handlePageChange = (pageNo) => {
    dispatch({
      type: type,
      payload: {
        pageNo: pageNo,
      },
    });
  };
  const handleSort = (e) => {
    dispatch({
      type: type,
      payload: {
        loader: false,
        sortField: e,
        sortType:
          sortType === SORT_TYPE_CONSTANTS.Asscending
            ? SORT_TYPE_CONSTANTS.Descending
            : SORT_TYPE_CONSTANTS.Asscending,
      },
    });
  };
  const handleMonth = (month) => {
    if (month === undefined) {
      dispatch({
        type: type,
        payload: {
          searchMonth: new Date().getMonth() + 1,
          pageNo: 1,
          month: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchMonth: month,
          searchYear: searchYear,
          searchDate: "",
          searchTimeStamp: "",
          pageNo: 1,
          month: true,
        },
      });
    }
  };

  const handleYear = (year) => {
    if (year === undefined) {
      dispatch({
        type: type,
        payload: {
          searchYear: new Date().getFullYear(),
          pageNo: 1,
          year: false,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          searchYear: year,
          pageNo: 1,
          year: true,
        },
      });
    }
  };
  return (
    <div className="body_container cm_bold ">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div>
            <Toastify
              resMsg={resTeamMsg}
              text={successMessage}
              resMsgFail={resTeamFailMsg}
              responseMessage={responseMessage}
            />
          </div>
          <h3 className="mb-2 mt-4">TEAM MANAGEMENT</h3>
          {openCompareDetail
            ? ""
            : (isAdmin === "4" || isAdmin === "1") && (
                <div className="filters justify-content-end mb-4">
                  <button
                    className="btn btn-primary add1"
                    onClick={handleOpenAddTeam}
                  >
                    Add New Team
                  </button>
                  <button
                    className="btn btn-primary add1"
                    onClick={handleOpenCompareView}
                  >
                    Compare Teams
                  </button>
                </div>
              )}
          {openCompareDetail ? (
            <CompareTeam
              reverseView={reverseView}
              count={count}
              dataCount={teamCompareCount}
              sortField={sortField}
              sortType={sortType}
              pageNo={pageNo}
              handlePageChange={handlePageChange}
              handleSort={handleSort}
              teamCompareData={teamCompareData}
              searchMonth={searchMonth}
              searchYear={searchYear}
              handleMonth={handleMonth}
              handleYear={handleYear}
            />
          ) : (
            <div className="row mt-5 add_team_div">
              {!_.isEmpty(teamList) ? (
                teamList.map((team) => (
                  <div className="col-12" key={team.teamId}>
                    <Link
                      title="View team"
                      to={`/admin-team/${team._id.teamId}`}
                    >
                      <div className="cm_card cm_card_dept">
                        <img
                          className="department_img"
                          src={`${
                            team.teamLogo
                              ? team.teamLogo
                              : require("../../asset/imgs/logo.png")
                          }`}
                          alt=""
                        />

                        <div className="text-right department_btn">
                          <Dropdown
                            overlay={
                              <Menu>
                                {team?.teamHead.map((team, i) => (
                                  <Menu.Item disabled key={i}>
                                    {team.firstName + " " + team.lastName}
                                  </Menu.Item>
                                ))}
                              </Menu>
                            }
                            placement="bottomLeft"
                            arrow
                          >
                            <Button>Team Head</Button>
                          </Dropdown>
                        </div>
                        <div className="cm_style">
                          <span className="emp_count cm_bold">
                            {team?.teamMembers?.length + team?.teamHead?.length}
                          </span>
                          <h5 className="mb-4 cm_dept_dots">
                            {team?._id?.teamName}
                          </h5>
                        </div>
                      </div>
                    </Link>
                    <div className="text-left department_btn"></div>
                    {(isAdmin === "4" ||  isAdmin === "1") && (
                      <div className="text-right department_btn">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm mr-4"
                          onClick={() => handleEditClick(team)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteTeam(team)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <h3 className="text-center txt_adjust">No data available</h3>
              )}
            </div>
          )}
          <AddTeam
            openAddTeamModal={openAddTeamModal}
            employeeList={employeeList}
            teamName={teamName}
            teamIcon={teamIcon}
            teamLead={teamLead}
            teamMembers={teamMembers}
            teamIconErrorMessage={teamIconErrorMessage}
            teamLeadErrorMessage={teamLeadErrorMessage}
            teamMembersErrorMessage={teamMembersErrorMessage}
            teamNameErrorMsg={teamNameErrorMsg}
            teamIconPreview={teamIconPreview}
            handleFileChange={handleFileChange}
            handleTeamNameChange={handleTeamNameChange}
            handleSelectTeamLeadChange={handleSelectTeamLeadChange}
            handleSelectAssociateTeamLeadChange={
              handleSelectAssociateTeamLeadChange
            }
            handleSelectTeamMembersChange={handleSelectTeamMembersChange}
            closeModal={closeModal}
            handleSubmit={handleSubmit}
          />
          <EditTeamModal
            openEditTeamModal={openEditTeamModal}
            employeeList={employeeList}
            teamName={teamName}
            teamLead={teamLead}
            associateTeamLead={associateTeamLead}
            associateTeamLeadErrorMessage={associateTeamLeadErrorMessage}
            teamIconErrorMessage={teamIconErrorMessage}
            teamLeadErrorMessage={teamLeadErrorMessage}
            teamNameErrorMsg={teamNameErrorMsg}
            editTeamIcon={editTeamIcon}
            handleFileChange={handleEditFileChange}
            handleTeamNameChange={handleTeamNameChange}
            handleSelectTeamLeadChange={handleSelectTeamLeadChange}
            handleSelectAssociateTeamLeadChange={
              handleSelectAssociateTeamLeadChange
            }
            closeModal={closeModal}
            previousTeamIconPreview={previousTeamIconPreview}
            handleEditSubmit={handleEditSubmit}
            teamMembers={teamMembers}
            teamLeadEdit={teamLeadEdit}
          />
          <DeleteTeamModal
            handleClose={closeModal}
            miniLoader={loader}
            header="Delete Team"
            subHeader="Are you sure you want to delete Team?"
            show={openDeleteTeamModal}
            handleDelete={handleDelete}
          />
        </>
      )}
    </div>
  );
}

import { type } from "../../components/helper/Constant";
import { getCourierListByAdmin } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
import {
  GET_COURIER_BY_ADMIN_REQUEST,
  GET_COURIER_BY_ADMIN_SUCCESS,
  GET_COURIER_BY_ADMIN_FAILURE,
} from "./actionType";

const getCourierListByAdminAction = (props) => {
  let { useDispatch, params } = props;
  return (dispatch) => {
    dispatch(getCourierListByAdminRequest());
    getCourierListByAdmin(params)
      .then((res) => {
        let { status } = ResponseFilter(res);
        if (status) {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              courierList: res.data.responseData.result?.data,
              dataCount: res.data.responseData.result?.totalCount,
            },
          });
        } else {
          useDispatch({
            type: type,
            payload: {
              loader: false,
              resMsgFail: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
          setTimeout(
            () =>
              dispatch({
                type: type,
                payload: {
                  resAwardFailMsg: false,
                },
              }),
            3000
          );
        }
      })
      .catch((error) => {
        dispatch(getCourierListByAdminFailure(error));
      });
  };
};

const getCourierListByAdminRequest = () => {
  return {
    type: GET_COURIER_BY_ADMIN_REQUEST,
  };
};
const getCourierListByAdminSuccess = (data) => {
  return {
    type: GET_COURIER_BY_ADMIN_SUCCESS,
    payload: data,
  };
};
const getCourierListByAdminFailure = (error) => {
  return {
    type: GET_COURIER_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  getCourierListByAdminAction,
  getCourierListByAdminRequest,
  getCourierListByAdminSuccess,
  getCourierListByAdminFailure,
};

import React, { Component } from 'react';
import ResponseFilter from '../../ResponseFilter';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Loader } from '../svg';
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import { Collapse, Icon, DatePicker, Modal as AntModal, TimePicker } from 'antd';
import { dailyWorkByEmpId, addNewStandup, editNewStandup } from '../../config/services/employeeServices';
import { format } from '../leaves/Constants';

const { Panel } = Collapse;


const colors = ['red', 'green', 'yellow', 'black', 'red', 'green', 'yellow', 'black', 'red', 'green', 'yellow', 'black'];

function disabledDate(current) {

    return current && current > moment().endOf('day');
}

class ActivityLogEmp extends Component {
    constructor(props) {

        super(props);

        this.state = {
            miniLoader: false,
            loader: true,
            openAddNewStandupPopup: false,
            openEditLogPopup: false,
            dailyWorkDetails: [],
            profileImage: '',
            firstName: '',
            projectId: '',
            hourErrorMsg: '',
            taskErrorMsg: '',
            dateErrorMsg: '',
            dataCount: '',
            sortType: 2,
            sortField: "created",
            pageNo: 1,
            count: 10,
            startTime: 0,
            startTimeErrorMsg: "",
            overTime: 0,
            ticketId:"",
            endTime: 0,
            endTimeErrorMsg: ""
        }

    }
    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: ''
    });
    handleOpenAddNewStandupPopup = () => this.setState({ openAddNewStandupPopup: !this.state.openAddNewStandupPopup });
    handleOpenEditLogPopup = ({ task, date, hour, blocker, dailyWorkId, projectId, startTime, endTime }) => this.setState({ openEditLogPopup: !this.state.openEditLogPopup, task, date, hour, blocker, dailyWorkId, projectId, startTime, endTime });
    closeModal = () => this.setState({
        openAddNewStandupPopup: false,
        openEditLogPopup: false,
        hourErrorMsg: '',
        taskErrorMsg: '',
        dateErrorMsg: '',
        task: '',
        date: '',
        hour: '',
        blocker: '',
        startTime: 0,
        endTime: 0,
        startTimeErrorMsg: "",
        endTimeErrorMsg: ""

    })
    handleDateChange = (date, dateString) => this.setState({ date: moment(date).toDate().getTime(), dateErrorMsg: '' });

    handleValidetion = () => {
        let validate = true;
        const { task, date, hour, startTime, endTime } = this.state;

        if (task === '' || task === undefined) {
            validate = false;
            this.setState({ taskErrorMsg: 'Task is required' })
        }
        else {
            this.setState({ taskErrorMsg: '' })
        }

        if (date === '' || date === undefined) {
            validate = false;
            this.setState({ dateErrorMsg: 'Date is required' })
        }
        else {
            this.setState({ dateErrorMsg: '' })
        }
        if (hour === '' || hour === undefined) {
            validate = false;
            this.setState({ hourErrorMsg: 'Hours is required' })
        }
        else {
            this.setState({ hourErrorMsg: '' })
        }

        if (startTime == null || startTime == 0 | startTime == undefined || startTime == "") {
            validate = false
            this.setState({ startTimeErrorMsg: "Start time is required" })
        }
        else {
            this.setState({ startTimeErrorMsg: "" })

        }

        if (endTime == null || endTime == 0 | endTime == undefined || endTime == "") {
            validate = false
            this.setState({ endTimeErrorMsg: "end time is required" })
        }
        else if (endTime > endTime) {
            this.setState({ endTimeErrorMsg: "End tims should be greater then end time" })

        }
        else {
            this.setState({ endTimeErrorMsg: "" })

        }

        return validate;
    }


    handleSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidetion()) {
            this.setState({ miniLoader: true });

            let obj = {
                'projectId': this.props.match.params.id,
                'task': this.state.task,
                'blocker': this.state.blocker,
                'date': this.state.date,
                'startTime': this.state.startTime,
                'endTime': this.state.endTime,
                'hour': this.state.startTime && this.state.endTime ? (this.state.endTime - this.state.startTime) + 3600 : ''


            }
            addNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getActivityLogsByEmp())
                    self.closeModal();
                    self.setState({ miniLoader: false })

                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        loader: false,

                    })
                    self.setState({ miniLoader: false })
                    // self.closeModal();
                }
            })
        }
    }
    handleEditSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidetion()) {

            let obj = {
                'dailyWorkId': this.state.dailyWorkId,
                'projectId': this.props.match.params.id,
                'task': this.state.task,
                'blocker': this.state.blocker,
                'date': this.state.date,
                'hour': this.state.startTime && this.state.endTime ? (this.state.endTime - this.state.startTime) + 3600 : '',
                'startTime': this.state.startTime,
                'endTime': this.state.endTime

            }
            editNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getActivityLogsByEmp())
                    self.closeModal();


                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        loader: false,

                    })

                    // self.closeModal();
                }
            })
        }
    }

    handlePageChange = (pageNo) => {
        this.setState({ pageNo: pageNo, loader: true }, () => this.getActivityLogsByEmp());
    }

    getActivityLogsByEmp() {
        let { sortType, sortField, pageNo, count } = this.state
        let params = `count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&employeeId=${this.props.match.params.empId}`;
        dailyWorkByEmpId(params).then(res => {

            let { status, resData } = ResponseFilter(res);
            if (status) {
                let {
                    firstName,
                    lastName,
                    profileImage,
                    empId,
                    departmentName,
                    designationName,

                } = resData.employeeDetails;

                this.setState({
                    loader: false,
                    firstName: firstName,
                    lastName: lastName,
                    empId: empId,
                    profileImage: profileImage,
                    departmentName: departmentName,
                    designationName: designationName,
                    dailyWorkDetails: resData.dailyWorkDetails,
                    dataCount: resData.dataCount,
                    projectId: resData.dailyWorkDetails[0].projectId
                }
                )
            }
            else {
                this.setState({
                    loader: false,
                })

            }
        }, () => this.closeModal())
    }
    componentDidMount() {
        this.getActivityLogsByEmp();

    }


    handleCheckInChange = (checkIn, checkInString) => {
        this.setState({

            startTime: moment.duration(checkInString).asSeconds(),
            startTimeErrorMsg: "",
        })

    }

    handleCheckOutChange = (checkOut, checkOutString) => {
        this.setState({

            endTime: moment.duration(checkOutString).asSeconds(),
            endTimeErrorMsg: "",
        })
    }

    render() {

        let { openEditLogPopup, pageNo, loader, miniLoader, count, dailyWorkDetails, profileImage, dataCount, firstName, lastName, empId, date, departmentName, designationName, openAddNewStandupPopup, task, blocker, hour, projectId, blockerErrorMsg, hourErrorMsg, taskErrorMsg, dateErrorMsg, startTime, startTimeErrorMsg, endTime, endTimeErrorMsg } = this.state;
        return (
            <div className="body_container">
                {

                    loader && <Loader />
                }
                <div className="cm_breadcrumb cm_bold">
                    <ul>
                        <li><Link to='/projects'>PROJECT</Link><div className="cm_breadcrumb_arrow"></div></li>
                        <li><Link to={`/projects/${projectId}`}>PROJECT DETAILS</Link><div className="cm_breadcrumb_arrow"></div></li>
                        <li><Link to={`/projects/${projectId}/all-activity-log-emp/${projectId}`}>ACTIVITY LOGS</Link><div className="cm_breadcrumb_arrow"></div></li>
                        <li>{firstName.toUpperCase()}'S LOGS</li>
                    </ul>
                </div>
                <div className="text-right"><button className="btn btn-primary edit_appraisal" type="submit" onClick={this.handleOpenAddNewStandupPopup}>New Standup</button></div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-3 cm_user_img">
                                <img className="" src={`${profileImage ? profileImage : require('../../asset/imgs/user-img.png')}`} alt="" />
                            </div>


                            <div className="col-4 activity_user">
                                <h1 className="blue">{firstName} {lastName}</h1>
                                <h4>{departmentName}</h4>
                                <h3>{empId}</h3>
                                <h3>{designationName}</h3>

                            </div>
                        </div>
                        <div>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['1']}

                            >

                                {
                                    !_.isEmpty(dailyWorkDetails) &&
                                    dailyWorkDetails.map((dailyWork, i) => (


                                        localStorage.getItem('employeeId') === (dailyWork.employeeId) ?
                                            <Panel
                                                className={colors[i]}
                                                header={moment(dailyWork.date).format('DD/MM/YYYY')}
                                                key={dailyWork.dailyWorkId}>
                                                <div className="col mt-4 cm_break">



                                                    <span className="edit_log_view mr-5" onClick={() => this.handleOpenEditLogPopup(dailyWork)}><i className="fa fa-pencil" aria-hidden="true" title="Edit activity log"></i></span>
                                                    <label>Project</label>
                                                    <p>{dailyWork.projectName}</p>
                                                    <label>What did you do today?</label>
                                                    <p>{dailyWork.task}</p>
                                                    <label>What problems are you facing?</label>
                                                    <p>{dailyWork.blocker ? dailyWork.blocker : 'Nothing'}</p>
                                                    <label>No. Of hours workinghhhhhhhhh?</label>
                                                    <p>{moment.utc(dailyWork.hour * 1000).format("HH:mm")}</p>
                                                </div>
                                            </Panel>
                                            : ''


                                    ))

                                }
                            </Collapse>
                            <div className="mt-5">
                                {dataCount > 10 ? <Pagination
                                    activePage={pageNo}
                                    itemsCountPerPage={count}
                                    totalItemsCount={dataCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                                /> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openAddNewStandupPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >
                    <Modal.Body>
                        <form onSubmit={this.handleSubmit}>
                            <h3>NEW STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false}
                                        />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours Working</label>
                                    <input
                                        className="form-control cm_box"
                                        type="number"
                                        step="any"
                                        placeholder="Hours"
                                        name="hour"
                                        value={startTime && endTime ? moment.utc(((endTime - startTime) + 3600) * 1000).format("HH:mm") : ''}
                                    // onChange={this.handleChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className={`form-group col-6 ${startTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub"> From</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={startTime ? moment.utc(startTime * 1000) : ""}
                                        onChange={this.handleCheckInChange}
                                    />
                                    {startTimeErrorMsg ? (
                                        <span className="error_msg">{startTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                                <div
                                    className={`form-group col-6 ${endTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub">To</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={endTime ? moment.utc(endTime * 1000) : ""}
                                        onChange={this.handleCheckOutChange}
                                    />
                                    {endTimeErrorMsg ? (
                                        <span className="error_msg">{endTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>

                                <textarea
                                    className="form-control cm-des cm_box"
                                    type="text"
                                    maxLength={500}
                                    name="task"
                                    placeholder="Today's task"
                                    value={task}
                                    onChange={this.handleChange}
                                >
                                </textarea>
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>

                            <label className="sub mt-4 mb-4">What problem are you facing?</label>
                            <textarea
                                className="form-control cm-des cm_box"
                                type="text"
                                maxLength={500}
                                placeholder="Today's blocker"
                                name="blocker"
                                value={blocker}
                                onChange={this.handleChange}
                            >
                            </textarea>

                            <div className="text-center mt-4"><button type="submit" className="btn btn-primary mt-2 cm_btn3" disabled={miniLoader}>Submit</button>
                                {miniLoader ? <div className="mini_loader_dept"></div> : ''}
                                {miniLoader ? '' : <button type="button" className="btn btn-primary mt-2  ml-4 cm_btn2" onClick={this.closeModal}>Cancel</button>}

                            </div>


                        </form>
                    </Modal.Body>
                </AntModal>
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openEditLogPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >

                    <Modal.Body>
                        <form onSubmit={this.handleEditSubmit}>
                            <h3>EDIT STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false} />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours Working</label>
                                    <input
                                        className="form-control cm_box"
                                        type="number"
                                        step="any"
                                        name="hour"
                                        placeholder="Hours"
                                        value={startTime && endTime ? moment.utc(((endTime - startTime) + 3600) * 1000).format("HH:mm") : ''}
                                    // onChange={this.handleChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className={`form-group col-6 ${startTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub"> From</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={startTime ? moment.utc(startTime * 1000) : ""}
                                        onChange={this.handleCheckInChange}
                                    />
                                    {startTimeErrorMsg ? (
                                        <span className="error_msg">{startTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                                <div
                                    className={`form-group col-6 ${endTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub">To</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={endTime ? moment.utc(endTime * 1000) : ""}
                                        onChange={this.handleCheckOutChange}
                                    />
                                    {endTimeErrorMsg ? (
                                        <span className="error_msg">{endTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>

                                <textarea
                                    className="form-control cm-des  cm_box"
                                    type="text"
                                    maxLength={500}
                                    placeholder="Today's task"
                                    name="task"
                                    value={task}
                                    onChange={this.handleChange}
                                >
                                </textarea>
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${blockerErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What problem are you facing?</label>
                                <textarea
                                    className="form-control cm-des cm_box"
                                    type="text"
                                    name="blocker"
                                    maxLength={500}
                                    placeholder="Today's blocker"
                                    value={blocker}
                                    onChange={this.handleChange}
                                >
                                </textarea>

                            </div>
                            <div className="text-center"><button type="submit" className="btn btn-primary mt-2 cm_btn3">Submit</button>

                                <button type="button" className="btn btn-primary mt-2  ml-4 cm_btn2" onClick={this.closeModal}>Cancel</button>

                            </div>

                        </form>
                    </Modal.Body>
                </AntModal>
            </div>

        )
    }
}
export default ActivityLogEmp;

import {
    GET_CONTACT_LIST_REQUEST,
    GET_CONTACT_LIST_SUCCESS,
    GET_CONTACT_LIST_FAILURE,
} from "./actionType";

import { getContactListService } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getContactList = (query) => {
    return (dispatch) => {
        dispatch(getContactListRequest());
        getContactListService(query)
            .then((res) => {
                let { status, resData } = ResponseFilter(res);
                if (status) {
                    dispatch(getContactListSuccess(res.data.responseData));
                } else {
                    dispatch(getContactListFailure(res.data));
                }
            })
            .catch((error) => {
                dispatch(getContactListFailure(error));
            });
    };
};

const getContactListRequest = () => {
    return {
        type: GET_CONTACT_LIST_REQUEST,
    };
};

const getContactListSuccess = (data) => {
    return {
        type: GET_CONTACT_LIST_SUCCESS,
        payload: data,
    };
};

const getContactListFailure = (error) => {
    return {
        type: GET_CONTACT_LIST_FAILURE,
        payload: error,
    };
};
export {
    getContactList,
    getContactListRequest,
    getContactListSuccess,
    getContactListFailure,
};

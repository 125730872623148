import React from 'react'
import { Modal } from "react-bootstrap"
import _ from "lodash";
import { disabledStartDate, halfTypeValue, leaveValue } from '../Constants';
import { DatePicker, Select } from 'antd';
import Calendar from "react-calendar";
import moment from 'moment';
const { Option } = Select;


export function OpenLeaveModal(props) {
    const {
        compOffStartDateErrorMsg,
        endOpen,
        onCompOffStartChange,
        handleChange,
        compOffGivenReason,
        compOffGivenReasonErrorMsg,
        handleTypeCompOffChange,
        wantToCarryForwardCompOff,
        wantToCarryForwardCompOffErrorMsg,
        handleCompOffGivenReasonChange,
        compOffStartDate

    } = props

    return (
        <Modal
            size="md"
            show={props.open}
            onHide={props.closeModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="text-center confirmation">
                <p className="mb-4 cm_bold cnfrm_msg">{props.heading}</p>

                <div className={`form-group ${props.modalValueErrorMsg ? 'input_error' : ''}`}>
                    <input
                        className="form-control cm_box"
                        type="text"
                        maxLength={1}
                        step="any"
                        placeholder=" Enter no. of day"
                        name={props.name}
                        value={props.value}
                        onChange={props.handleChange}
                    />
                    {props.modalValueErrorMsg ? <span className="" style={{
                        paddingLeft: "",
                        color: "red",
                        bottom: "-22"
                    }}>{props.modalValueErrorMsg}</span> : null}
                </div>
                <h3 className="mt-2">Temporary CompOff Leave</h3>
                <div className={`row  ${wantToCarryForwardCompOffErrorMsg ? "input_error" : ""}`} >
                    <div className="col-12">
                        <Select
                            className="cm_leaves "
                            name={wantToCarryForwardCompOff}
                            placeholder="Select Leave"
                            value={wantToCarryForwardCompOff ? wantToCarryForwardCompOff : "Select Type"}
                            onChange={handleTypeCompOffChange}
                        >
                            <Option key="1">Yes</Option>
                            <Option key="2">No</Option>
                        </Select>
                        {wantToCarryForwardCompOffErrorMsg ? (
                            <span className="error_msg">{wantToCarryForwardCompOffErrorMsg}</span>
                        ) : null}
                    </div>
                </div>
                {wantToCarryForwardCompOff == "1" ?
                    <div className='row mt-2'>
                        <div
                            className={`form-group col-12 ${compOffStartDateErrorMsg ? "input_error" : ""
                                }`}
                        >
                            <label className="sub">Expiry Date</label>
                            <Calendar onChange={onCompOffStartChange}
                                value={compOffStartDate ? new Date(compOffStartDate) : ""}
                                minDate={new Date()} />
                            {compOffStartDateErrorMsg ? (
                                <span className="error_msg">{compOffStartDateErrorMsg}</span>
                            ) : null}
                        </div>
                    </div> : ""}


                <h3 className="mt-2">Reason</h3>
                <div
                    className={`form-group col-12 ${compOffGivenReasonErrorMsg ? "input_error" : ""}`}
                >
                    <textarea
                        name="compOffGivenReason"
                        placeholder="Leave reason"
                        maxLength={500}
                        value={compOffGivenReason}
                        onChange={handleCompOffGivenReasonChange}
                        className="form-control cm_input"
                    ></textarea>
                    {/* <TextEditor
                  className="form-control cm_input cm_textbox"
                /> */}
                    {compOffGivenReasonErrorMsg ? (
                        <span className="error_msg">{compOffGivenReasonErrorMsg}</span>
                    ) : null}
                </div>

                <div className="text-center mt-5">
                    <button className="btn btn-primary  mr-2 btn-sm cm_btn3"
                        onClick={props.handleSubmit}>{props.heading == "Add Compensatory Off" ? "Add" : "Edit"}
                    </button>

                </div>
            </Modal.Body>
        </Modal>
    )
}
import { REQUIRED, letterNumberRegex, type } from "../../helper/Constant";
import { invalid } from "../../helper/String";

export const handleValidation = (props) => {
  const { title, dispatch, uploadFile, editTitle, docLink } = props;
  let valid = true;
  if (editTitle === title && docLink && !uploadFile) {
    valid = false;
    dispatch({
      type: type,
      payload: {
        titleErrorMsg: "No change",
        uploadFileErrorMsg: "No change",
      },
    });
  }

  if (
    (docLink && docLink.size > 1 * 5000 * 1024) ||
    (uploadFile && uploadFile.size > 1 * 5000 * 1024)
  ) {
    valid = false;
    dispatch({
      type: type,
      payload: {
        uploadFileErrorMsg: "File with maximum size of 1MB is allowed",
      },
    });
  }
  if (title === "" || title === undefined) {
    valid = false;
    dispatch({
      type: type,
      payload: {
        titleErrorMsg: REQUIRED,
      },
    });
  } else if (letterNumberRegex.test(title)) {
    valid = false;
    dispatch({
      type: type,
      payload: {
        titleErrorMsg: invalid,
      },
    });
  }
  if (!docLink && uploadFile.length === 0) {
    valid = false;
    dispatch({
      type: type,
      payload: {
        uploadFileErrorMsg: REQUIRED,
      },
    });
  }
  const extension =
    uploadFile && uploadFile.name.split(".").pop().toLowerCase();
  if (!docLink && uploadFile && !["pdf"].includes(extension)) {
    valid = false;
    dispatch({
      type: type,
      payload: {
        uploadFileErrorMsg: "Upload only pdf",
      },
    });
  }

  return valid;
};

export const extractFilename = (url) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ResponseFilter from '../../ResponseFilter';
import _, { isArray } from 'lodash';
import { Loader } from '../svg';
import Pagination from "react-js-pagination";
import { getProjectView, dailyWork, addNewStandup, editNewStandup, dailyWorkByProjectId, getProjectLeave } from '../../config/services/employeeServices';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { DatePicker, Modal as AntModal, TimePicker, Select } from 'antd';
import { disabledEndDate, format, leaveValue } from '../leaves/Constants';
import { Toastify, dateFormat } from '../helper/Constant';
import { disabledDate } from '../attendance/Constants';
import { CONSTANTS } from '../../config';
import { projectTypeValue } from '../../config/constants/Constants';
import TextEditor from "../../hoc/textEditor/TextEditor"
import { ContentState, EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import DOMPurify from 'dompurify';
import htmlToDraft from 'html-to-draftjs';
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
const technologyList = CONSTANTS.INTERVIEW_SECTION.TECHNICAL_SKILL

const { RangePicker } = DatePicker
const { Option } = Select
class EmployeeViewProject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            openAddNewStandupPopup: false,
            openEditLogPopup: false,
            dailyWorkDetails: [],
            profileImage: '',
            firstName: '',
            projectId: '',
            hourErrorMsg: '',
            taskErrorMsg: '',
            dateErrorMsg: '',
            projectDetails: '',
            assignedTo: [],
            membersDetails: [],
            dailyWork: [],
            dataCount: '',
            sortType: 2,
            sortField: "created",
            pageNo: 1,
            count: 10,
            overTime: 0,
            ticketId: "",
            startTime: 0,
            startTimeErrorMsg: "",
            endTime: 0,
            endTimeErrorMsg: "",
            tabStatus: "",
            projectMemberLeave: [],
            memberLeaveCount: "",
            filterEmployee: "",
            startDateStamp: "",
            endDateStamp: '',
            startDateRange: "",
            endDateRange: "",
            resActivityMsg: false,
            resActivityFailMsg: false,
            successMessage: "",
            responseMessage: "",
            sortType: "",
            sortField: "created",
            task: EditorState.createEmpty(),
            blocker: EditorState.createEmpty(),
            hour: "",
            actualHours: "",
            modalLoader: false
        }
    }


    catValue = type => {
        switch (type) {
            case 1:
                return 'Web';
                break;
            case 2:
                return 'Android';
                break;
            case 3:
                return 'ios';
                break;
            default:
                return 'all';

        }
    }
    // componentDidMount() {
    //     this.getView();
    //     this.getActivityLogs();
    // }
    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: ''
    });
    handleDateChange = (date, dateString) => this.setState({ date: new Date(date).setHours(6, 30, 0, 0), dateErrorMsg: '' })
    getView = () => {

        let params = this.props.match.params.id;
        this.setState({ loader: true })
        this.props.history.push(`?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`)

        getProjectView(params).then(res => {
            let { status, resData } = ResponseFilter(res);

            if (status) {
                this.setState({
                    projectDetails: resData.projectDetails,
                    membersDetails: resData.membersDetails,

                }, () => this.setState({ loader: false }))
            }
            else {
                this.setState({ loader: false })
            }
        })
    }

    handlePageChange = (pageNo) => {
        this.setState({ pageNo: pageNo }, () => {
            this.getActivityLogs()
            this.getView()
            this.getProjectMemberLeave()
        })
    }



    handleSubmit = event => {
        event.preventDefault();
        let self = this;
        const { task, blocker, date, hour, actualHours, overTime, ticketId } = this.state
        if (this.handleValidetion()) {
            this.setState({ modalLoader: true })
            let obj = {
                'projectId': this.props.match.params.id,
                'task': draftToHtml(convertToRaw(task.getCurrentContent())),
                'blocker': blocker ? draftToHtml(convertToRaw(blocker.getCurrentContent())) : "",
                'date': date,
                'hour': hour,
                'actualHours': actualHours,
                "ticketId": ticketId,
                "overTime": overTime,

            }
            addNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getActivityLogs())
                    self.closeModal();
                    self.setState({ resActivityMsg: true });
                    self.setState({ successMessage: "Activity addeded " })

                    setTimeout(() => {
                        this.setState({
                            resActivityMsg: false,
                        })
                    }, 4000)

                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        resActivityFailMsg: true,
                        responseMessage: res.data.error.responseMessage

                    })
                    // self.closeModal();

                    setTimeout(() => {
                        this.setState({
                            resActivityFailMsg: false,
                        })
                    }, 4000)
                }
            })

            setTimeout(() => {
                this.setState({
                    resActivityMsg: false,
                    resActivityFailMsg: false,
                })
            }, 2000)
        }
    }

    handleEditSubmit = event => {
        event.preventDefault();
        let self = this;
        const { dailyWorkId, task, blocker, date, hour, actualHours, overTime, ticketId } = this.state
        if (this.handleValidetion()) {
            self.setState({ modalLoader: true })
            let obj = {
                'dailyWorkId': dailyWorkId,
                'projectId': this.props.match.params.id,
                'task': draftToHtml(convertToRaw(task.getCurrentContent())),
                'blocker': draftToHtml(convertToRaw(blocker.getCurrentContent())),
                'date': date,
                'hour': hour,
                'actualHours': actualHours,
                "ticketId": ticketId,
                "overTime": overTime,
            }
            editNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getActivityLogs())
                    self.closeModal();
                    self.setState({ resActivityMsg: true })
                    self.setState({ successMessage: "Activity edited " })


                    setTimeout(() => {
                        this.setState({
                            resActivityMsg: false,
                            resActivityFailMsg: false
                        })
                    }, 4000)
                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        resActivityFailMsg: true,
                        responseMessage: res.data.error.responseMessage


                    })

                    setTimeout(() => {
                        this.setState({
                            resActivityMsg: false,
                            resActivityFailMsg: false
                        })
                    }, 4000)
                    // self.closeModal();
                }
            })
        }
    }

    handleValidetion = () => {
        let validate = true;
        const { task, date, hour, startTime, endTime } = this.state;
        let k = convertToRaw(task.getCurrentContent())

        if (k.blocks.every(b => b.text.trim() === '')) {
            validate = false
            this.setState({ taskErrorMsg: "Task is required" })

        }
        else {
            this.setState({ taskErrorMsg: '' })
        }

        if (date === '' || date === undefined) {
            validate = false;
            this.setState({ dateErrorMsg: 'Date is required' })
        }
        else {
            this.setState({ dateErrorMsg: '' })
        }
        if (hour === '' || hour === undefined) {
            validate = false;
            this.setState({ hourErrorMsg: 'Hours is required' })
        }
        else {
            this.setState({ hourErrorMsg: '' })
        }
        // if (startTime == null || startTime == 0 || startTime == undefined || startTime == "") {
        //     validate = false
        //     this.setState({ startTimeErrorMsg: "Start time is required" })
        // }
        // else {
        //     this.setState({ startTimeErrorMsg: "" })

        // }

        // if (endTime == null || endTime == 0 || endTime == undefined || endTime == "") {
        //     validate = false
        //     this.setState({ endTimeErrorMsg: "end time is required" })
        // }
        // else if (endTime > endTime) {
        //     validate = false
        //     this.setState({ endTimeErrorMsg: "End tims should be greater then end time" })

        // }
        // else {
        //     this.setState({ endTimeErrorMsg: "" })

        // }
        return validate;
    }
    handleValidetionEdit = () => {
        let validate = true
        const { task, date, hour } = this.state;

        if (task === '' || task === undefined) {
            validate = false;
            this.setState({ taskErrorMsg: 'Task is required' })
        }
        else {
            this.setState({ taskErrorMsg: '' })
        }

        if (date === '' || date === undefined) {
            validate = false;
            this.setState({ dateErrorMsg: 'Date is required' })
        }
        else {
            this.setState({ dateErrorMsg: '' })
        }
        if (hour === '' || hour === undefined) {
            validate = false;
            this.setState({ hourErrorMsg: 'Hours is required' })
        }
        else {
            this.setState({ hourErrorMsg: '' })
        }

        return validate;
    }

    getActivityLogs = () => {
        let { sortType, sortField, pageNo, count, startDateRange, endDateRange } = this.state

        let params = `count=${count}&pageNo=${pageNo - 1
            }&sortType=${sortType}&sortField=${sortField}&projectId=${this.props.match.params.id
            }&startDate=${startDateRange}&endDate=${endDateRange}&employeeId=${this.state.filterEmployee}`;
        dailyWorkByProjectId(params).then(res => {
            this.setState({ loader: true })
            let { status, resData } = ResponseFilter(res);
            if (status) {
                let {
                    firstName,
                    lastName,
                    profileImage,
                    empId,
                    departmentName,
                    designationName,

                } = resData;

                this.setState({
                    loader: false,
                    firstName: firstName,
                    lastName: lastName,
                    empId: empId,
                    profileImage: profileImage,
                    departmentName: departmentName,
                    designationName: designationName,
                    dailyWorkDetails: resData.dailyWorkDetails,
                    dataCount: resData.dataCount,
                    projectId: resData.dailyWorkDetails[0]?.projectId
                }
                )
            }
            else {
                this.setState({
                    loader: false,
                })

            }
        })
    }
    handleDraftToHtml = (props) => {
        let htmlContent = props
        let wrappedHtmlContent = htmlContent.toString().includes("<p>") ? htmlContent : `<p>${htmlContent}</p>`
        const contentBlock = htmlToDraft(wrappedHtmlContent)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const _editorState = EditorState.createWithContent(contentState)
        return _editorState
    }
    handleOpenEditLogPopup = ({ task, date, hour, actualHours, blocker, dailyWorkId, projectId, startTime, endTime, overTime, ticketId }) => this.setState({ openEditLogPopup: !this.state.openEditLogPopup, task: this.handleDraftToHtml(task), date, hour, actualHours, blocker: this.handleDraftToHtml(blocker), dailyWorkId, projectId, startTime, endTime, overTime, ticketId });

    handleOpenAddNewStandupPopup = () => this.setState({ openAddNewStandupPopup: !this.state.openAddNewStandupPopup });
    closeModal = () => this.setState({
        openAddNewStandupPopup: false,
        openEditLogPopup: false,
        hourErrorMsg: '',
        taskErrorMsg: '',
        dateErrorMsg: '',
        task: EditorState.createEmpty(),
        date: '',
        hour: '',
        actualHours: "",
        blocker: EditorState.createEmpty(),

        startTime: 0,
        endTime: 0,
        startTimeErrorMsg: "",
        endTimeErrorMsg: "",
        modalLoader: false,
    })


    handleCheckInChange = (checkIn, checkInString) => {
        this.setState({

            startTime: moment.duration(checkInString).asSeconds(),
            startTimeErrorMsg: "",
        })

    }

    handleCheckOutChange = (checkOut, checkOutString) => {
        this.setState({

            endTime: moment.duration(checkOutString).asSeconds(),
            endTimeErrorMsg: "",
        })
    }


    handleHourChange = (checkIn, checkInString) => {
        this.setState({
            hour: checkInString ? moment.duration(checkInString).asSeconds() : "",
            hourErrorMsg: "",

        })
    }

    handleActualHourChange = (checkIn, checkInString) => {
        this.setState({
            actualHours: checkInString ? moment.duration(checkInString).asSeconds() : "",
        })
    }

    handleOvertime = (checkIn, checkInString) => {
        this.setState({
            overTime: checkInString ? moment.duration(checkInString).asSeconds() : "",
        })
    }
    handleTicketId = (e) => {
        this.setState({
            ticketId: e.target.value,
        });
    }
    disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    handleFilter = (e) => {
        this.setState({ loader: true, tabStatus: e == 0 ? "" : parseInt(e), pageNo: 1 }, () => { this.getView(); this.getActivityLogs(); this.getProjectMemberLeave() })

    }
    componentDidMount() {
        let url_string = window.location.href
        let url = new URL(url_string);
        let pageNo = url.searchParams.get("pageNo");
        let tabStatus = url.searchParams.get("tabStatus");
        let search = url.searchParams.get("search");
        let sortType = url.searchParams.get("sortType");
        let sortField = url.searchParams.get("sortField");
        let startDateRange = url.searchParams.get("startDateRange")
        let endDateRange = url.searchParams.get("endDateRange")
        let startDateStamp = url.searchParams.get("startDateStamp")
        let endDateStamp = url.searchParams.get("endDateStamp")
        let filterEmployee = url.searchParams.get("filterEmployee")
        const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";

        this.setState({
            pageNo: pageNo ? +(pageNo) : 1,
            // search: search ? search : "",
            sortType: sortType ? sortType : SORT_TYPE_CONSTANTS.Descending,
            sortField: sortField ? sortField : "created",

            startDateStamp: startDateStamp ? moment(startDateStamp, format) : "",
            endDateStamp: endDateStamp ? moment(endDateStamp, format) : "",
            startDateRange: startDateRange ? startDateRange : "",
            endDateRange: endDateRange ? endDateRange : "",
            tabStatus: tabStatus ? tabStatus : this.state.tabStatus,
            filterEmployee: filterEmployee ? filterEmployee : ""
        }, () => {
            this.getActivityLogs();
            this.getView();
            this.getProjectMemberLeave()

        })
    }
    handleTab = (e) => {
        if (e == undefined) {
            this.setState({ tabStatus: 1, filterEmployee: "", pageNo: 1 }, () => {
                this.getActivityLogs();
                this.getView();
                this.getProjectMemberLeave()
            })
        }
        else {
            this.setState({ tabStatus: 1, filterEmployee: e, pageNo: 1 }, () => {
                this.getActivityLogs();
                this.getView();
                this.getProjectMemberLeave()
            })
        }
    }

    handleRangePicker = (date) => {
        if (date.length === 0) {
            this.setState(
                {
                    pageNo: 1,
                    startDateStamp: '',
                    endDateStamp: '',
                    startDateRange: !_.isEmpty(date)
                        ? new Date(date[0]).setHours(6, 30, 0, 0)
                        : "",
                    endDateRange: !_.isEmpty(date)
                        ? new Date(date[1]).setHours(6, 30, 0, 0)
                        : "",
                    dateRange: false,
                },
                () => {
                    this.getActivityLogs();
                    this.getView();
                }
            );
        } else {
            this.setState(
                {
                    pageNo: 1,
                    startDateStamp: date[0],
                    endDateStamp: date[1],
                    startDateRange: !_.isEmpty(date)
                        ? new Date(date[0]).setHours(6, 30, 0, 0)
                        : "",
                    endDateRange: !_.isEmpty(date)
                        ? new Date(date[1]).setHours(6, 30, 0, 0)
                        : "",
                    dateRange: true,
                },
                () => {
                    this.getActivityLogs();
                    this.getView();

                }
            );
        }
    };

    getProjectMemberLeave = () => {
        let params = `${this.props.match.params.id}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&count=${this.state.count}&pageNo=${this.state.pageNo - 1}`;
        this.setState({ loader: true });
        getProjectLeave(params).then((res) => {
            if (res.data.statusCode == 1) {
                let { status, resData } = ResponseFilter(res);
                this.setState({ loader: false });
                if (status) {
                    this.setState({
                        loader: false,
                        projectMemberLeave: resData?.leaveList?.result,
                        memberLeaveCount: resData.dataCount
                    })
                } else {
                    this.setState({ loader: false });
                }
            }
        });
    }
    handleSort = e => {
        this.setState({ loader: false, sortField: e, sortType: this.state.sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending }, () => this.getActivityLogs(), this.getView(), this.getProjectMemberLeave());
    }

    millisecondsToHoursAndMinutes = (seconds) => {

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        const paddedHours = hours < 10 ? `0${hours}` : hours;
        const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${paddedHours}:${paddedMinutes}`;
    }


    onEditorTaskStateChange = (editorState) => {
        this.setState({ task: editorState, taskErrorMsg: "" })

    }
    onEditorBlockerStateChange = (editorState) => {
        this.setState({ blocker: editorState })

    }
    createMarkup = (props) => {
        return {
            __html: DOMPurify.sanitize(props)
        }
    }

    render() {
        let {
            pageNo, dataCount, count,
            loader,
            projectDetails,
            membersDetails,
            dailyWorkDetails,
            openAddNewStandupPopup, task, blocker, hour, actualHours, date, projectId, openEditLogPopup, taskErrorMsg, blockerErrorMsg, dateErrorMsg, hourErrorMsg, firstName,
            startTime, startTimeErrorMsg, endTime, endTimeErrorMsg, tabStatus,
            projectMemberLeave, filterEmployee, startDateStamp, endDateRange, endDateStamp, startDateRange, resActivityFailMsg, resActivityMsg, responseMessage, successMessage, sortField, sortType, memberLeaveCount
            , overTime, ticketId, modalLoader } = this.state;

        return (

            <div className="body_container proj_detail cm_bold">
                <Toastify

                    resMsg={resActivityMsg}
                    text={successMessage}
                    resMsgFail={resActivityFailMsg}
                    responseMessage={responseMessage}
                />
                {/* {loader && <Loader />} */}

                <div className="cm_breadcrumb cm_bold">
                    <ul>
                        <li><Link to='/projects'>PROJECT</Link><div className="cm_breadcrumb_arrow"></div></li>
                        <li>PROJECT DETAILS</li>

                    </ul>
                </div>
                {tabStatus == 1 ?
                    <>
                        <div className="filters justify-content-end align-items-center">

                            <div className={`select_year_cal`}>
                                <Select
                                    showSearch
                                    name="employeeId"
                                    value={
                                        filterEmployee
                                            ? filterEmployee
                                            : "Select employee "
                                    }
                                    onSearch={this.handleEmployeeSearch}
                                    optionFilterProp="key"
                                    filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                                    onChange={this.handleTab}
                                    allowClear={filterEmployee ? true : false}
                                >
                                    {!_.isEmpty(membersDetails)
                                        ? membersDetails.map(
                                            (employee) => (
                                                <Option
                                                    key={`${employee.firstName} ${employee.lastName}`}
                                                    value={employee.employeeId}
                                                >
                                                    <span>{employee.firstName + " " + employee.lastName}</span>

                                                </Option>
                                            )
                                        )
                                        : ""}
                                </Select>
                            </div>
                            <div>
                                <RangePicker
                                    id='daterangepicker'
                                    onChange={this.handleRangePicker}
                                    disabledDate={this.disabledDate}

                                    className="rang select_year_cal rang_pick mr-3"
                                    value={[startDateStamp ? moment(startDateStamp) : "", endDateStamp ? moment(endDateStamp) : ""]}
                                />
                            </div>
                            {projectDetails?.inProject == 2 ?
                                // <div className="text-right">
                                <button className="btn btn-primary" type="submit" onClick={this.handleOpenAddNewStandupPopup}>New standup</button>
                                // </div> 
                                : ""}
                        </div>
                    </>
                    : ""}

                <div className="text-left cm_ticket_btn cm_leave_btn mt-4">
                    <button
                        className={`btn btn-primary ${tabStatus == '' ? "active" : ''
                            }`}
                        // style={{ width: 140 }}
                        onClick={() => this.handleFilter('')}
                    >
                        <span className="tabtext">General Info</span>
                    </button>
                    <button
                        className={`btn btn-primary ${tabStatus == 1 ? "active" : ''
                            }`}
                        // style={{ width: 140 }}
                        onClick={() => this.handleFilter(1)}
                    >
                        <span className="tabtext">Activity</span>
                    </button>
                    <button
                        className={`btn btn-primary ${tabStatus == 2 ? "active" : ''
                            }`}
                        // style={{ width: 140 }}
                        onClick={() => this.handleFilter(2)}
                    >
                        <span className="tabtext">Leave</span>
                    </button>
                </div>{tabStatus == 2 ? "" :
                    <div className=" text-left mb-2 grey">
                        <span> * If there is ( "__" ) that means that you can not see the hours of other project member</span>
                    </div>}
                {
                    tabStatus == "" ? (
                        !loader ? <>
                            <div className="project-information ">
                                <div className="project-summary">
                                    <h4 className="cm_bold mb-2">Project Summary</h4>
                                    <form className="project-summary-detail">
                                        <div className="cm_overflow_project">
                                            <div className="row cm_row">
                                                <div className="col-6 cm_bold col2">Name</div>
                                                <div className="col-6 cm_bold col2">{projectDetails?.projectName}</div>

                                                <div className="col-6 cm_bold col2">Category</div>
                                                <div className="col-6 cm_bold col2">{
                                                    isArray(projectDetails?.category) ? projectDetails?.category.map((categories, index) => (
                                                        categories ? <span> {this.catValue(categories)}
                                                            {index < projectDetails?.category?.length - 1 && ', '}

                                                        </span> : ""
                                                    ))
                                                        : projectDetails?.category ? this.catValue(projectDetails?.category) : ""}</div>
                                                <div className="col-6 cm_bold col2">Technology</div>
                                                <div className="col-6 cm_bold col2">{
                                                    projectDetails?.technology ? projectDetails?.technology.map((technologies, index) => (
                                                        <span>
                                                            {technologyList[technologies]}
                                                            {index < projectDetails?.technology?.length - 1 && ', '}
                                                        </span>
                                                    ))
                                                        : ""}</div>
                                                <div className="col-6 cm_bold col2">Project Type</div>
                                                <div className="col-6 cm_bold col2">{projectDetails?.projectType ? projectTypeValue(projectDetails?.projectType) : ""}</div>
                                                <div className="col-6 cm_bold col2">Status</div>
                                                <div className="col-6 cm_bold col2">
                                                    {projectDetails?.status === 1
                                                        ? "Ongoing"
                                                        : projectDetails?.status === 2
                                                            ? "Completed"
                                                            : projectDetails?.status == 3
                                                                ? "Incomplete"
                                                                : projectDetails?.status == 4
                                                                    ? "Maintenance"
                                                                    : "On Hold"}
                                                </div>
                                                <div className="col-6 cm_bold col2">Start Date</div>
                                                <div className="col-6 cm_bold col2">
                                                    {moment.unix(projectDetails?.startDate / 1000).format("DD/MM/YYYY")}
                                                </div>
                                                <div className="col-6 cm_bold col2">End Date</div>
                                                <div className="col-6 cm_bold col2">
                                                    {moment.unix(projectDetails?.endDate / 1000).format("DD/MM/YYYY")}
                                                </div>
                                                <div className="col-6 cm_bold col2">Client Name</div>
                                                <div className="col-6 cm_bold col2">{projectDetails?.clientName ? projectDetails?.clientName : "-"}</div>
                                                <div className="col-6 cm_bold col2">Mail Id</div>
                                                <div className="col-6 cm_bold col2">{projectDetails?.mailId ? projectDetails?.mailId : "-"}</div>
                                                <div className="col-6 cm_bold col2">Project Manager</div>
                                                <div className="col-6 cm_bold col2">{projectDetails?.projectManager}</div>
                                                <div className="col-6 cm_bold col2">Account Manager</div>
                                                <div className="col-6 cm_bold col2">{projectDetails?.accountManagerFirst + " " + projectDetails?.accountManagerLast}</div>
                                                <div className="col-6 cm_bold col2">Project Hours</div>
                                                <div className="col-6 cm_bold col2">{projectDetails.projectHours}</div>
                                                <div className="col-6 cm_bold col2">Project Description</div>
                                                <div className="col-12 cm_bold col2">{projectDetails?.projectDescription}</div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="project-summary">
                                    <h4 className="cm_bold mb-2">Team Members</h4>
                                    <form className="project-summary-detail">
                                        <div className="cm_overflow_project_employee">
                                            <div className="row cm_row">
                                                <div className="col-6 cm_bold col2">Name</div>
                                                <div className="col-6 cm_bold col2">Total Hours</div>
                                                {membersDetails.length ? membersDetails.map((membersDetails) => (
                                                    <>
                                                        <div className="col-6 cm_bold col2">{membersDetails.firstName} {membersDetails.lastName}</div>
                                                        <div className="col-6 cm_bold col2">
                                                            {membersDetails?.employeeId == localStorage.getItem("employeeId") ? this.millisecondsToHoursAndMinutes(membersDetails?.totalHours) : "__"}
                                                        </div>
                                                    </>
                                                ))
                                                    : (
                                                        <p>No data</p>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                        </> : <Loader small={true} />)

                        : tabStatus == 1 ? (
                            !loader ? <>
                                < div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead className="white bg_blue">
                                            <tr>
                                                <th>S.No</th>
                                                <th>
                                                    Employee Name

                                                    <i
                                                        className={sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "projectName" ? "fa fa-sort-alpha-asc ml-2" : "fa fa-sort-alpha-desc ml-2"}
                                                        onClick={() => this.handleSort('empName')}></i>
                                                </th>
                                                <th>Ticket Id</th>
                                                <th>Task </th>
                                                <th>Blocker</th>
                                                <th>Date

                                                    <i
                                                        className={sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "startDate" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                                                        onClick={() => this.handleSort('startDate')}></i>
                                                </th>
                                                <th>Hour </th>
                                                <th>Actual Hour</th>
                                                <th>Overtime</th>
                                                <th>Updated On</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!_.isEmpty(dailyWorkDetails)
                                                ? dailyWorkDetails.map((data, i) => (
                                                    <tr key={data.leaveId}>
                                                        <td>{i + 1 + (pageNo - 1) * count}</td>
                                                        <td>{data.firstName + " " + data.lastName}</td>
                                                        <td>{data.ticketId ? data.ticketId : "__"}</td>
                                                        <td>{<div style={{ color: "black" }}
                                                            dangerouslySetInnerHTML={this.createMarkup(data.task)}>
                                                        </div>}</td>
                                                        <td>{<div style={{ color: "black" }}
                                                            dangerouslySetInnerHTML={this.createMarkup(data.blocker)}>
                                                        </div>}</td>
                                                        <td>
                                                            {moment(data.date).format(dateFormat).substring(0, 10)}
                                                        </td>
                                                        <td>{data?.employeeId == localStorage.getItem("employeeId") ? moment.utc(data.hour * 1000).format("HH.mm") : "__"}</td>

                                                        <td>{data?.employeeId == localStorage.getItem("employeeId") ? data.actualHours ? moment.utc(data.actualHours * 1000).format("HH.mm") : "__" : "__"}</td>
                                                        <td>{data?.employeeId == localStorage.getItem("employeeId") ? data.overTime ? moment.utc(data.overTime * 1000).format("HH.mm") : "__" : "__"}</td>
                                                        <td>{data?.employeeId == localStorage.getItem("employeeId") ? data.updated ? moment(data.updated).format('HH:mm') + " " + moment(data.updated).format("DD/MM/YYYY") : "__" : "__"}</td>
                                                        <td>
                                                            {data.employeeId == localStorage.getItem('employeeId') && projectDetails?.inProject == 2 ?
                                                                < span onClick={() => this.handleOpenEditLogPopup(data)}><i className="fa fa-pencil fa-2x" aria-hidden="true" title="Edit activity log"></i></span>
                                                                : ""}
                                                        </td>

                                                    </tr>

                                                ))
                                                : ""
                                            }
                                        </tbody>
                                    </table >

                                    {dailyWorkDetails && dailyWorkDetails.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                                </div > </> : <Loader small={true} />) :
                            (
                                !loader ? <>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="white bg_blue">
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>
                                                        Employee Name
                                                        <i
                                                            className={sortType === 1 && sortField === "empName" ? "fa fa-sort-alpha-asc ml-2" : "fa fa-sort-alpha-desc ml-2"}
                                                            onClick={() => this.handleSort('empName')}></i>
                                                    </th>

                                                    <th>Start Date
                                                        <i
                                                            className={sortType === 1 && sortField === "startDate" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                                                            onClick={() => this.handleSort('startDate')}></i> </th>
                                                    <th>End Date</th>
                                                    <th>Leave Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!_.isEmpty(projectMemberLeave) || projectDetails?.inProject == 2
                                                    ? projectMemberLeave.map((data, i) => (
                                                        <tr key={data.leaveId}>
                                                            <td>{i + 1 + (pageNo - 1) * count}</td>
                                                            <td>{data.empName}</td>
                                                            <td>{moment(data?.startDate).format(dateFormat).substring(0, 10)}</td>
                                                            <td>{moment(data?.endDate).format(dateFormat).substring(0, 10)}</td>
                                                            <td>{leaveValue(data.leaveType)}</td>
                                                        </tr>

                                                    ))
                                                    :
                                                    ""
                                                }
                                            </tbody>
                                        </table >
                                        {projectMemberLeave && projectMemberLeave.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                                    </div > </> : <Loader small={true} />)
                }
                {
                    dataCount > 10 && tabStatus == 1 && loader == false ?
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={dataCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        />
                        :
                        ""
                }

                {
                    memberLeaveCount > 10 && tabStatus == 2 && loader == false ?
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={memberLeaveCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        />
                        :
                        ""
                }
                {/* {
                        !_.isEmpty(dailyWorkDetails) ?
    
    
                            <h5 className="mb-5 cm_bold">Activity Log
                                <div className="text-right cm_activity">
                                    <Link to={`/projects/${this.props.match.params.id}/all-activity-log-emp/${this.props.match.params.id}`} className="btn btn-primary btn-sm" style={{ width: 90 }}>View All</Link>
    
                                </div></h5> : ''} */}
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openAddNewStandupPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >
                    {modalLoader && <Loader />}

                    <Modal.Body>
                        <form onSubmit={this.handleSubmit}>
                            <h3>NEW STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false}
                                            disabledDate={d => !d || d.isSameOrBefore(moment.unix(projectDetails.startDate / 1000).format("YYYY-MM-DD")) || d.isAfter(moment.unix(new Date().setDate(new Date().getDate() + 1) / 1000).format("YYYY-MM-DD"))}

                                        />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        format={format}
                                        value={hour ? moment.utc(hour * 1000) : ""}
                                        onChange={this.handleHourChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>
                            <div className='row'>
                                <div className={`form-group col-6 `}>
                                    <label className="sub">No.of actual hours</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        format={format}
                                        value={actualHours ? moment.utc(actualHours * 1000) : ""}
                                        onChange={this.handleActualHourChange}
                                    />
                                </div>
                                <div className={`form-group col-6 `}>
                                    <label className="sub">Overtime</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        format={format}
                                        value={overTime ? moment.utc(overTime * 1000) : ""}
                                        onChange={this.handleOvertime}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={`form-group col-6 `}>
                                    <label className="sub">Ticket Id</label>
                                    <input
                                        placeholder="Ticket Id"
                                        type="text"
                                        step="any"
                                        className="form-control cm_box"
                                        name="ticketId"
                                        value={ticketId}
                                        onChange={this.handleTicketId} />
                                </div>
                            </div>
                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>
                                <TextEditor
                                    editorState={task}
                                    onEditorStateChange={this.onEditorTaskStateChange}
                                />
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${blockerErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What problem are you facing?</label>
                                <TextEditor
                                    editorState={blocker}
                                    onEditorStateChange={this.onEditorBlockerStateChange}
                                />
                            </div>
                            <div className="submit_button_margin mt-4"><button type="submit" className="btn btn-primary cm_btn3">Submit</button>
                                <button type="button" className="btn btn-primary cm_btn2" onClick={this.closeModal}>Cancel</button></div>

                        </form>
                    </Modal.Body>
                </AntModal>
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openEditLogPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >
                    {modalLoader && <Loader />}
                    <Modal.Body>
                        <form onSubmit={this.handleEditSubmit}>
                            <h3>EDIT STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false}
                                            disabledDate={d => !d || d.isSameOrBefore(moment.unix(projectDetails.startDate / 1000).format("YYYY-MM-DD")) || d.isAfter(moment.unix(new Date().setDate(new Date().getDate() + 1) / 1000).format("YYYY-MM-DD"))}
                                        />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        format={format}
                                        value={hour ? moment.utc(hour * 1000) : ""}
                                        onChange={this.handleHourChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>


                            <div className='row'>
                                <div className={`form-group col-6 `}>
                                    <label className="sub">No.of actual hours</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        format={format}
                                        value={actualHours ? moment.utc(actualHours * 1000) : ""}
                                        onChange={this.handleActualHourChange}
                                    />
                                </div>
                                <div className={`form-group col-6 `}>
                                    <label className="sub">Overtime</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        format={format}
                                        value={overTime ? moment.utc(overTime * 1000) : ""}
                                        onChange={this.handleOvertime}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={`form-group col-6 `}>
                                    <label className="sub">Ticket Id</label>
                                    <input
                                        placeholder="Ticket Id"
                                        type="text"
                                        step="any"
                                        className="form-control cm_box"
                                        name="ticketId"
                                        value={ticketId}
                                        onChange={this.handleTicketId} />
                                </div>
                            </div>

                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>
                                <TextEditor
                                    editorState={task}
                                    onEditorStateChange={this.onEditorTaskStateChange}
                                />
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${blockerErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What problem are you facing?</label>
                                <TextEditor
                                    editorState={blocker}
                                    onEditorStateChange={this.onEditorBlockerStateChange}
                                />

                            </div>
                            <div className="submit_button_margin mt-4"><button type="submit" className="btn btn-primary cm_btn3">Submit</button>
                                <button type="button" className="btn btn-primary cm_btn2" onClick={this.closeModal}>Cancel</button></div>

                        </form>
                    </Modal.Body>
                </AntModal>
            </div >




        )
    }
}
export default EmployeeViewProject;

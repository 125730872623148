import React, { useEffect, useMemo, useReducer } from "react";
import ResponseFilter from "../../ResponseFilter";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import {
  employeeAdminLeaveStatus,
  getEmployeeLeaves,
  getEmployeeList,
  leaveDeductByAdmin,
  markPaidLeave,
} from "../../config/services/employeeServices";
import { Select } from "antd";
import {
  REMAINING_COMP_EXCEED,
  Toastify,
  checkLeaveArray,
  reducer,
  type,
} from "../helper/Constant";
import {
  chartValue,
  currentYear,
  HALF_CASUAL_LEAVE,
  HALF_COMPENSATORY_OFF,
  HALF_EARNED_LEAVE,
  HALF_LWP,
  HALF_OTHER,
  HALF_SICK_LEAVE,
  HALF_WFH,
  handleSelectLeave,
  SHORT_DAY_LEAVE,
  Toast,
} from "./Constants";
import { OpenLeaveModal } from "./leaveModal/LeaveViewModal";
import LeaveStatus from "./LeaveStatus";
import { getEmpProfileByAdmin } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { Loader } from "../svg";
import {
  handleCompValidation,
  handleleaveDeductValidation,
} from "./LeaveValidation";
import qs from "qs";
import moment from "moment";
import EmployeeLeaveData from "./EmployeeLeaveData";
import { ViewLeaveModal } from "./leaveModal/ViewLeaveModal";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import DeleteCompOffModal from "./leaveModal/DeleteCompOffModal";
import { editCompOffCountByAdminAction } from "../../redux/actions/editCompOffByAdminAction";
import { CompOffAdd, invalid } from "../helper/String";
let url = "/admin-leave";
const { Option } = Select;

const initialState = {
  selectLeave: "1",
  totalpaidComOff: 0,
  totalpaidEarnedLeaves: 0,
  carryfwCompOff: 0,
  carryfwEarnedLeaves: 0,
  mesg: "",
  resMsg: false,
  totalComp: 0,
  searchYear: currentYear,
  searchMonth: "",
  searchQuatName: "",
  openModal: false,
  modalValue: "",
  modalValueErrorMsg: "",
  modalName: "",
  index: "",
  heading: "",
  loader: false,
  isAdmin: true,
  resMsgFail: false,
  responseMessage: "",
  employeeType: "",
  employeeName: "",
  replyErrorMsg: "",
  compOff: "",
  compOffStartDate: "",
  compOffStartDateErrorMsg: "",
  wantToCarryForwardCompOff: "",
  wantToCarryForwardCompOffErrorMsg: "",
  endOpen: false,
  compOffGivenReason: "",
  compOffGivenReasonErrorMsg: "",
  dataCount: 0,
  pendingCount: 0,
  lateCount: 0,
  approvedCount: 0,
  rejectedCount: 0,
  pageNo: 1,
  count: 10,
  sortField: "createdAt",
  sortType: 2,
  leaveDetails: [],
  status1: "",
  status: "",
  openLeaveApprovePopup: false,
  startDate: "",
  endDate: "",
  leaveMsg: "",
  reply: "",
  leaveStatus: "",
  halfOfTheDayStatus: "",
  shortDayFrom: 0,
  shortDayTo: 0,
  leaveType: "",
  totalLeaves: 0,
  leaveTypeSelect: "",
  earnedLeaves: "",
  casualLeaves: "",
  sickLeaves: "",
  Lwp: "",
  Wfh: "",
  shortDayLeave: "",
  assignedStatusCl: "",
  assignedStatusEl: "",
  assignedStatusSl: "",
  totalCompOff: 0,
  totalCompOffTaken: 0,
  tempCompOff: [],
  tempCompOffCount: 0,
  employeeList: [],
  editorState: EditorState.createEmpty(),
  openDeductLeavePopup: false,
  deductLeaveType: "",
  employeeIdErrorMsg: "",
  shortDayToErrorMsg: "",
  shortDayFromErrorMsg: "",
  halfOfTheDay: "",
  halfOfTheDayErrMsg: "",
  enddate1ErrorMsg: "",
  start1dateErrorMsg: "",
  startDate1: "",
  endDate1: "",
  leaveType1ErrorMsg: "",
  deductLeave: "",
  openLeaveApprove_RejectPopup: false,
  openLeaveLwpApprovePopup: false,
  openApprovedShortLeavePopup: false,
  leaveId: "",
  isApproved: false,
  leaveType1: "",
  searchDate: "",
  search: "",
  dateRange: false,
  openDeleteCompOff: false,
  deleteCompOffNo: "",
  deleteCompOffNoErrMsg: "",
  successMessage: "",
  viewDocumentPopup: false,
  viewDocument: [],
  bereavementLeaves: "",
  paternalLeave: "",
  assignedClientOff: "",
  assignedBereavementLeaves: "",
  assignedPaternalLeave: "",
};
function LeaveView() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const userName = location?.state?.employeeName;
  const history = useHistory();
  let { id } = useParams();
  const {
    searchYear,
    searchMonth,
    searchQuatName,
    totalComp,
    totalpaidComOff,
    totalpaidEarnedLeaves,
    resMsg,
    carryfwCompOff,
    carryfwEarnedLeaves,
    openModal,
    modalValue,
    modalName,
    index,
    heading,
    totalOtherLeaves,
    totalLeavesTaken,
    selectLeave,
    isAdmin,
    employeeType,
    resMsgFail,
    responseMessage,
    loader,
    employeeName,
    modalValueErrorMsg,
    replyErrorMsg,
    compOffEndDate,
    compOffStartDate,
    compOffStartDateErrorMsg,
    compOffGivenReason,
    compOffGivenReasonErrorMsg,
    wantToCarryForwardCompOff,
    wantToCarryForwardCompOffErrorMsg,
    dataCount,
    pendingCount,
    lateCount,
    approvedCount,
    rejectedCount,
    pageNo,
    count,
    sortField,
    sortType,
    leaveDetails,
    status1,
    status,
    openLeaveApprovePopup,
    startDate,
    endDate,
    leaveMsg,
    reply,
    leaveStatus,
    halfOfTheDayStatus,
    shortDayFrom,
    shortDayTo,
    leaveType,
    totalLeaves,
    leaveTypeSelect,
    earnedLeaves,
    casualLeaves,
    sickLeaves,
    compOff,
    Lwp,
    Wfh,
    shortDayLeave,
    assignedStatusCl,
    assignedStatusEl,
    assignedStatusSl,
    totalCompOff,
    totalCompOffTaken,
    tempCompOff,
    tempCompOffCount,
    employeeList,
    editorState,
    openDeductLeavePopup,
    deductLeaveType,
    employeeIdErrorMsg,
    shortDayToErrorMsg,
    shortDayFromErrorMsg,
    halfOfTheDay,
    halfOfTheDayErrMsg,
    enddate1ErrorMsg,
    start1dateErrorMsg,
    startDate1,
    endDate1,
    leaveType1ErrorMsg,
    deductLeave,
    searchDate,
    search,
    dateRange,
    openLeaveApprove_RejectPopup,
    openLeaveLwpApprovePopup,
    openApprovedShortLeavePopup,
    leaveId,
    isApproved,
    openDeleteCompOff,
    deleteCompOffNo,
    deleteCompOffNoErrMsg,
    successMessage,
    viewDocumentPopup,
    viewDocument,
    bereavementLeaves,
    paternalLeave,
    assignedClientOff,
    assignedBereavementLeaves,
    assignedPaternalLeave,
  } = state;
  const byAdmin = 1;
  const onEditorStateChange = (editorState) => {
    dispatch({
      type: type,
      payload: {
        editorState: editorState,
        replyErrorMsg: "",
      },
    });
  };

  const handlePageChange = (pageNo) => {
    dispatch({
      type: type,
      payload: {
        pageNo: pageNo,
      },
    });
  };
  const handleFilter = (e) => {
    dispatch({
      type: type,
      payload: {
        status: e,
        pageNo: 1,
        status1: e,
      },
    });
  };

  const handleSort = (e) => {
    dispatch({
      type: type,
      payload: {
        sortField: e,
        sortType: sortType === 1 ? 2 : 1,
      },
    });
  };

  const dispatchToAction = useDispatch();

  useEffect(() => {
    getEmployee();
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    dispatchToAction(
      getEmpProfileByAdmin({
        useDispatch: dispatch,
        id: id,
      })
    );
  };

  useEffect(() => {
    getStatus();
    getEmployeeLeavesList();
    // eslint-disable-next-line
  }, [
    searchMonth,
    searchQuatName,
    searchYear,
    sortType,
    status1,
    status,
    pageNo,
    leaveTypeSelect,
  ]);

  useMemo(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    dispatch({
      type: type,
      payload: {
        searchMonth: filtersFromParams.searchMonth
          ? Number(filtersFromParams.searchMonth)
          : "",
        searchYear: filtersFromParams.searchYear
          ? Number(filtersFromParams.searchYear)
          : currentYear,
        searchQuatName: filtersFromParams.searchQuarter
          ? Number(filtersFromParams.searchQuarter)
          : "",
        selectLeave: filtersFromParams.selectLeave
          ? filtersFromParams.selectLeave
          : "1",
        pageNo: filtersFromParams.pageNo ? Number(filtersFromParams.pageNo) : 1,
        status1: filtersFromParams.status1 ? filtersFromParams.status1 : "",
        status: filtersFromParams.status ? filtersFromParams.status : "",
        sortField: filtersFromParams.sortField
          ? filtersFromParams.sortField
          : "createdAt",
        sortType: filtersFromParams.sortType
          ? Number(filtersFromParams.sortType)
          : 2,
        leaveTypeSelect: filtersFromParams.leaveTypeSelect
          ? filtersFromParams.leaveTypeSelect
          : "",
      },
    });
  }, [history.location.search]);

  const getStatus = () => {
    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    history.push(
      `?&searchMonth=${searchMonth}&searchYear=${searchYear}&searchQuarter=${searchQuatName}&selectLeave=${selectLeave}&status=${status}&status1=${status1}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&leaveTypeSelect=${leaveTypeSelect}`
    );
    const params = {
      employeeId: id,
      searchQuarter:
        searchQuatName !== "" && searchMonth === "" ? searchQuatName + 1 : "",
      searchMonth:
        searchMonth !== "" && searchQuatName === "" ? searchMonth + 1 : "",
      searchYear: searchYear,
    };
    employeeAdminLeaveStatus(params).then((res) => {
      if (res.data.statusCode == 1) {
        let { status, resData } = ResponseFilter(res);
        let totalLeaveDetail = resData.leaveDetails;
        if (status) {
          dispatch({
            type: type,
            payload: {
              // loader: false,
              earnedLeaves: checkLeaveArray(
                totalLeaveDetail.totalEarnedLeaves[0]?.totalEarnedLeaves,
                totalLeaveDetail?.totalHalfEarnedLeaves[0]
                  ?.totalHalfEarnedLeaves
              ),
              casualLeaves: checkLeaveArray(
                totalLeaveDetail.totalCasualLeaves[0]?.totalCasualLeaves,
                totalLeaveDetail?.totalHalfCasualLeaves[0]
                  ?.totalHalfCasualLeaves
              ),
              sickLeaves: checkLeaveArray(
                totalLeaveDetail.totalSickLeaves[0]?.totalSickLeaves,
                totalLeaveDetail?.totalHalfSickLeaves[0]?.totalHalfSickLeaves
              ),
              compOff: checkLeaveArray(
                totalLeaveDetail.totalCompOffLeaves[0]?.totalCompOffLeaves,
                totalLeaveDetail?.totalHalfCompOffLeaves[0]
                  ?.totalHalfCompOffLeaves
              ),
              Lwp: checkLeaveArray(
                totalLeaveDetail.totalLWPLeaves[0]?.totalLWPLeaves,
                totalLeaveDetail?.totalHalfLWPLeaves[0]?.totalHalfLWPLeaves
              ),
              Wfh: checkLeaveArray(
                totalLeaveDetail.totalWFHLeaves[0]?.totalWFHLeaves,
                totalLeaveDetail?.totalHalfWFH[0]?.totalHalfWFHLeaves
              ),
              totalOtherLeaves: checkLeaveArray(
                totalLeaveDetail.totalOtherLeaves[0]?.totalOtherLeaves,
                totalLeaveDetail?.totalHalfOtherLeaves[0]?.totalHalfOtherLeaves
              ),
              totalLeavesTaken: totalLeaveDetail?.totalLeaves[0]
                ? totalLeaveDetail.totalLeaves[0].totalLeaves
                : 0,
              shortDayLeave: totalLeaveDetail?.totalShortDayLeaves[0]
                ? totalLeaveDetail.totalShortDayLeaves[0].totalShortDayLeaves
                : 0,
              assignedStatusCl: resData.employeeTotalLeave?.totalCl
                ? resData.employeeTotalLeave?.totalCl
                : 0,
              assignedStatusEl: resData.employeeTotalLeave?.totalEarnedLeave
                ? resData.employeeTotalLeave?.totalEarnedLeave
                : 0,
              assignedStatusSl: resData.employeeTotalLeave?.totalSl
                ? resData.employeeTotalLeave?.totalSl
                : 0,
              bereavementLeaves: totalLeaveDetail?.bereavementLeave[0]
                ? totalLeaveDetail?.bereavementLeave[0].bereavementLeave
                : 0,
              paternalLeave: totalLeaveDetail?.paternalLeave[0]
                ? totalLeaveDetail?.paternalLeave[0].paternalLeave
                : 0,
              totalClientOff: totalLeaveDetail?.totalClientOff[0]
                ? totalLeaveDetail?.totalClientOff[0].totalClientOff
                : 0,
              employeeType: resData.employeeType,
              employeeName: resData.employeeName,
            },
          });
        }
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
      }
    });
    // setTimeout(() => this.setState({ resMsg: false }), 4000)
  };

  const handleLeaveDeductSubmit = (e) => {
    e.preventDefault();
    let formattedDate1 = "";
    if (startDate1 !== "") {
      formattedDate1 = new Date(startDate1);
    }
    let formattedDate2 = "";
    if (endDate1 !== "") {
      formattedDate2 = new Date(endDate1);
    }

    if (
      handleleaveDeductValidation({
        employeeId: id,
        endDate: endDate1,
        startDate: startDate1,
        shortDayFrom: shortDayFrom,
        shortDayTo: shortDayTo,
        halfOfTheDay: halfOfTheDay,
        deductLeaveType: deductLeaveType,
        reply: reply,
        editorState: editorState,
        dispatch: dispatch,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      let leaveMsg = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      let params = {
        employeeId: id,
        status: 2,
        leaveMsg: leaveMsg,
        leaveType: deductLeaveType, //today
        startDate:
          formattedDate1.getFullYear() +
          "-" +
          (formattedDate1.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          formattedDate1.getDate().toString().padStart(2, "0"),
        endDate:
          deductLeaveType === HALF_CASUAL_LEAVE ||
          deductLeaveType === HALF_EARNED_LEAVE ||
          deductLeaveType === HALF_SICK_LEAVE ||
          deductLeaveType === HALF_LWP ||
          deductLeaveType === HALF_COMPENSATORY_OFF ||
          deductLeaveType === HALF_OTHER ||
          deductLeaveType === HALF_WFH ||
          deductLeaveType === SHORT_DAY_LEAVE
            ? formattedDate1.getFullYear() +
              "-" +
              (formattedDate1.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              formattedDate1.getDate().toString().padStart(2, "0")
            : formattedDate2.getFullYear() +
              "-" +
              (formattedDate2.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              formattedDate2.getDate().toString().padStart(2, "0"),
        halfOfTheDay:
          deductLeaveType === HALF_CASUAL_LEAVE ||
          deductLeaveType === HALF_EARNED_LEAVE ||
          deductLeaveType === HALF_SICK_LEAVE ||
          deductLeaveType === HALF_LWP ||
          deductLeaveType === HALF_COMPENSATORY_OFF ||
          deductLeaveType === HALF_OTHER ||
          deductLeaveType === HALF_WFH
            ? halfOfTheDay
            : 0,
        to: shortDayTo,
        from: shortDayFrom,
      };
      leaveDeductByAdmin(params).then((res) => {
        let { status, resData } = ResponseFilter(res);
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
        if (status) {
          dispatch({
            type: type,
            payload: {
              miniLoader: false,
              resDeductMessage: true,
              deductLeaveType: "",
              employeeId: "",
              resleaveStatus: res.data.responseData.leaveDetails.leaveType,
              openLeaveApprove_RejectPopup: false,
              openLeaveLwpApprovePopup: false,
              openDeductLeavePopup: false,
              successMessage: "Leave Deducted",
              resMsg: true,
              editorState: EditorState.createEmpty(),
            },
          });
        } else {
          dispatch({
            type: type,
            payload: {
              resMsgFail: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
        }
        getStatus();
        responseAction();
      });
      closeModal();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      handleCompValidation({
        modalValue: modalValue,
        compOffEndDate: compOffEndDate,
        compOffStartDate: compOffStartDate,
        compOffGivenReason: compOffGivenReason,
        dispatch: dispatch,
        type: type,
        replyErrorMsg: replyErrorMsg,
        wantToCarryForwardCompOff: wantToCarryForwardCompOff,
      })
    ) {
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      let startD = new Date(compOffStartDate);
      let formattedDate1 =
        startD.getFullYear() +
        "-" +
        (startD.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        startD.getDate().toString().padStart(2, "0");
      let obj =
        wantToCarryForwardCompOff == "1"
          ? {
              employeeId: id,
              days: parseFloat(modalValue),
              expireDate: formattedDate1,
              leaveMsg: compOffGivenReason,
            }
          : {
              employeeId: id,
              days: parseFloat(modalValue),
              leaveMsg: compOffGivenReason,
            };
      markPaidLeave(obj).then((res) => {
        getStatus();
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
        if (res.data.statusCode === 1) {
          dispatch({
            type: type,
            payload: {
              resMsg: true,
              successMessage: CompOffAdd,
            },
          });
        } else if (res.data.statusCode === 0) {
          dispatch({
            type: type,
            payload: {
              resMsgFail: true,
              responseMessage: res.data.error.responseMessage,
            },
          });
        }
        setTimeout(() => {
          dispatch({
            type: type,
            payload: {
              resMsg: false,
              resMsgFail: false,
              responseMessage: "",
            },
          });
        }, 3000);
      });
      getData();
      closeModal();
    }
  };

  const handleOpenModal = (index, name, heading) => {
    dispatch({
      type: type,
      payload: {
        openModal: true,
        modalName: name,
        index: index,
        heading: heading,
        submitFunction: handleSubmit,
      },
    });
  };

  const handleChange = (e) => {
    dispatch({
      type: type,
      payload: {
        [e.target.name]: e.target.value,
        modalValue: e.target.value,
        modalName: e.target.name,
        assignedCompOffErMsg: "",
        EditCompOffErMsg: "",
        paidCompOffErMsg: "",
        paidElErMsg: "",
        carryCompOffErMsg: "",
        carryElErMsg: "",
        reply: e.target.value,
        responseMessage: "",
        replyErrorMsg: "",
      },
    });
  };

  const handleTypeCompOffChange = (e) => {
    dispatch({
      type: type,
      payload: {
        wantToCarryForwardCompOff: e,
      },
    });
  };
  const handleCompOffGivenReasonChange = (e) => {
    dispatch({
      type: type,
      payload: {
        compOffGivenReason: e.target.value,
      },
    });
  };
  const onCompOffStartChange = (e) => {
    dispatch({
      type: type,
      payload: {
        compOffStartDate: moment(e).toISOString(),
        compOffStartDateErrorMsg: "",
      },
    });
  };
  const closeModal = () => {
    dispatch({
      type: type,
      payload: {
        openDeductLeavePopup: false,
        openModal: false,
        openLeaveMarkPopup: false,
        openCompOffPopup: false,
        openCompOffEditPopup: false,
        openEditCfEarnLeavePopup: false,
        openEditCfCompOffPopup: false,
        openEditPaidCompOffPopup: false,
        openEditPaidEarnLeavePopup: false,
        openCompMarkPopup: false,
        openCompCarryPopup: false,
        openElCarryPopup: false,
        assignedCompOffErMsg: "",
        EditCompOffErMsg: "",
        paidCompOffErMsg: "",
        paidElErMsg: "",
        CompLeave: "",
        totalComp1: "",
        carryCompOffErMsg: "",
        carryfwCompOff1: carryfwCompOff,
        carryfwEarnedLeaves1: carryfwEarnedLeaves,
        totalpaidEarnedLeaves1: totalpaidEarnedLeaves,
        totalpaidComOff1: totalpaidComOff,
        carryElErMsg: "",
        paidEl: "",
        paidCompOff: "",
        carryCompOff: "",
        carryEL: "",
        modalName: "",
        modalValue: "",
        index: "",
        compOffEndDate: "",
        compOffEndDateErrorMsg: "",
        compOffGivenReason: "",
        compOffGivenReasonErrorMsg: "",
        compOffStartDate: "",
        compOffStartDateErrorMsg: "",
        modalValueErrorMsg: "",
        wantToCarryForwardCompOffErrorMsg: "",
        wantToCarryForwardCompOff: "",
        replyErrorMsg: "",
        openLeaveApprovePopup: false,
        openDeleteLeavePopup: false,
        openApplyForClientOffice: false,
        reply: "",
        leaveType1: "",
        leaveType1ErrorMsg: "",
        halfOfTheDay: "",
        halfOfTheDayErrMsg: "",
        startDate1: "",
        start1dateErrorMsg: "",
        endDate1: "",
        enddate1ErrorMsg: "",
        email: "",
        emails: [],
        emailInCC: "",
        inputEmail: "",
        emailError: "",
        leaveId: "",
        startDate: "",
        endDate: "",
        openLeaveApprove_RejectPopup: false,
        openLeaveLwpApprovePopup: false,
        openApprovedShortLeavePopup: false,
        openDeleteCompOff: false,
        deleteCompOffNo: "",
        deleteCompOffNoErrMsg: "",
        viewDocumentPopup: false,
        editorState: EditorState.createEmpty(),
      },
    });
  };

  const handleOpenLeavePopup = (data) => {
    let {
      leaveId,
      leaveType,
      startDate,
      endDate,
      leaveMsg,
      reply,
      halfOfTheDay,
      status,
      from,
      to,
    } = data;
    dispatch({
      type: type,
      payload: {
        openLeaveApprovePopup: true,
        leaveId: leaveId,
        leaveType: leaveType,
        startDate: new Date(startDate).toLocaleDateString(),
        endDate: new Date(endDate).toLocaleDateString(),
        leaveMsg: leaveMsg,
        reply: reply,
        halfOfTheDayStatus: halfOfTheDay,
        leaveStatus: status,
        shortDayFrom: from,
        shortDayTo: to,
      },
    });
  };
  const getEmployeeLeavesList = () => {
    history.push(
      `?&searchMonth=${searchMonth}&searchYear=${searchYear}&searchQuarter=${searchQuatName}&selectLeave=${selectLeave}&status=${status}&status1=${status1}&pageNo=${pageNo}&sortType=${sortType}&sortField=${sortField}&leaveTypeSelect=${leaveTypeSelect}`
    );
    const params = {
      sortType: sortType,
      sortField: sortField,
      count: count,
      pageNo: pageNo - 1,
      status: status == "4" ? "" : status,
      year: searchYear,
      month: searchMonth !== "" && searchQuatName === "" ? searchMonth + 1 : "",
      quarter:
        searchQuatName !== "" && searchMonth === "" ? searchQuatName + 1 : "",
      userId: id,
      uniqueLate: status == "4" ? "1" : "0",
      leaveType: leaveTypeSelect,
    };

    dispatch({
      type: type,
      payload: {
        loader: true,
      },
    });
    getEmployeeLeaves(params).then((res) => {
      dispatch({
        type: type,
        payload: {
          loader: false,
        },
      });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        dispatch({
          type: type,
          payload: {
            ResData: resData,
          },
        });
        let leaveData = resData.leavesDetails;
        dispatch({
          type: type,
          payload: {
            rejectedCount: leaveData.rejected,
            approvedCount: leaveData.approved,
            pendingCount: leaveData.pending,
            lateCount: leaveData.lateCount,
            leaveDetails: leaveData.leaveList,
            totalLeaves: leaveData.totalLeaves,
            dataCount:
              status1 == 1
                ? leaveData.pending
                : status1 == 2
                ? leaveData.approved
                : status1 == 3
                ? leaveData.rejected
                : status1 == 4
                ? leaveData.lateCount
                : leaveData.totalLeaves,
          },
        });
      } else if (res.data.statusCode === 0) {
        dispatch({
          type: type,
          payload: {
            loader: false,
            resMsgFail: true,
            responseMessage: res.data.error.responseMessage,
          },
        });
        closeModal();
      }
    });
  };

  const handleLeaveFilter = (e) => {
    if (e !== undefined) {
      dispatch({
        type: type,
        payload: {
          pageNo: 1,
          // loader: true,
          leaveTypeSelect: e,
        },
      });
    } else {
      dispatch({
        type: type,
        payload: {
          pageNo: 1,
          // loader: true,
          leaveTypeSelect: "",
        },
      });
    }
  };
  const getEmployee = () => {
    let params = ``;
    getEmployeeList(params).then((res) => {
      if (res.data.statusCode === 1) {
        dispatch({
          type: type,
          payload: {
            employeeList: res.data.responseData.employeesList,
          },
        });
      } else if (res.data.statusCode === 0) {
        dispatch({
          type: type,
          payload: {
            loader: false,
          },
        });
      }
    });
  };

  const responseAction = () => {
    setTimeout(
      () =>
        dispatch({
          type: type,
          payload: {
            resMsg: false,
            resMsgFail: false,
            responseMessage: "",
          },
        }),
      3000
    );
  };

  const handleSelectHalfChange = (e) => {
    dispatch({
      type: type,
      payload: {
        halfOfTheDay: e,
        halfOfTheDayErrMsg: "",
      },
    });
  };

  const onStartChange = (e) => {
    dispatch({
      type: type,
      payload: {
        startDate1: moment(e).toISOString(),
        start1dateErrorMsg: "",
      },
    });
  };

  const onEndChange = (e) => {
    dispatch({
      type: type,
      payload: {
        endDate1: moment(e).toISOString(),
        enddate1ErrorMsg: "",
      },
    });
  };

  const handleCheckInChange = (checkIn, checkInString) => {
    dispatch({
      type: type,
      payload: {
        shortDayFrom: moment.duration(checkInString).asSeconds(),
        shortDayFromErrorMsg: "",
      },
    });
  };

  const handleCheckOutChange = (checkOut, checkOutString) => {
    dispatch({
      type: type,
      payload: {
        shortDayTo: moment.duration(checkOutString).asSeconds(),
        shortDayToErrorMsg: "",
      },
    });
  };

  const handleOpenShowCompOff = () => {
    history.push(`/leave/compoff-leave/${id}/${byAdmin}/${isAdmin}`);
    dispatch({
      type: type,
      payload: {
        leaveTypeSelect: "",
      },
    });
  };

  const handleOpenDeleteCompOff = () => {
    dispatch({
      type: type,
      payload: {
        openDeleteCompOff: true,
      },
    });
  };

  const handleDeleteCompOffChange = (e) => {
    dispatch({
      type: type,
      payload: {
        deleteCompOffNo: e.target.value,
        deleteCompOffNoErrMsg: "",
      },
    });
  };

  const handleDeleteCompOff = (e) => {
    e.preventDefault();
    if (
      deleteCompOffNo % 0.5 === 0 &&
      deleteCompOffNo > 0 &&
      deleteCompOffNo <= totalCompOff - compOff
    ) {
      dispatch({
        type: type,
        payload: {
          loader: true,
        },
      });
      const params = {
        count: deleteCompOffNo,
        employeeId: id,
      };
      dispatchToAction(
        editCompOffCountByAdminAction({
          useDispatch: dispatch,
          params: params,
          handleClose: closeModal,
          getData: getData,
        })
      );
    } else {
      if (deleteCompOffNo > totalCompOff - compOff) {
        dispatch({
          type: type,
          payload: {
            deleteCompOffNoErrMsg: REMAINING_COMP_EXCEED,
          },
        });
      } else {
        dispatch({
          type: type,
          payload: {
            deleteCompOffNoErrMsg: invalid,
          },
        });
      }
    }
  };
  const handleOpenViewDocPopup = (viewDocument) => {
    dispatch({
      type: type,
      payload: {
        viewDocumentPopup: true,
        viewDocument: viewDocument,
      },
    });
  };
  return (
    <div className="body_container cm_bold">
      <Toastify
        resMsg={resMsg}
        text={successMessage}
        resMsgFail={resMsgFail}
        responseMessage={responseMessage}
      />
      {loader && <Loader />}
      <div className="cm_breadcrumb cm_bold">
        <ul>
          <li>
            <Link to={url}>LEAVES MANAGEMENT</Link>
            <div className="cm_breadcrumb_arrow"></div>
          </li>

          <li style={{ textTransform: "uppercase" }}>
            {employeeName}'s leave record
            <div className="cm_breadcrumb_arrow"></div>
          </li>

          <li>{chartValue(selectLeave)}</li>
        </ul>
      </div>
      <h3 className="mt-5">TAKEN LEAVES</h3>
      <div className="text-right">
        <div className="form-group text-right sm_search">
          <Select
            className="cm_list cm_list_mb list_black"
            defaultValue={selectLeave ? selectLeave : "Leaves"}
            style={{ width: 120 }}
            onChange={(e) =>
              handleSelectLeave({
                e: e,
                searchYear: searchYear,
                searchQuatName: searchQuatName,
                searchMonth: searchMonth,
                selectLeave: selectLeave,
                dispatch: dispatch,
                type: type,
              })
            }
          >
            <Option value="1">Yearly</Option>
            <Option value="2">Quarterly</Option>
            <Option value="3">Monthly</Option>
          </Select>
        </div>
      </div>

      <LeaveStatus
        shortDayFrom={shortDayFrom}
        shortDayTo={shortDayTo}
        halfOfTheDay={halfOfTheDay}
        responseMessage={responseMessage}
        responseAction={responseAction}
        handleSelectHalfChange={handleSelectHalfChange}
        onStartChange={onStartChange}
        onEndChange={onEndChange}
        handleCheckInChange={handleCheckInChange}
        handleCheckOutChange={handleCheckOutChange}
        closeModal={closeModal}
        disabled="true"
        employeeList={employeeList}
        reply={reply}
        editorState={editorState}
        deductLeaveType={deductLeaveType}
        onEditorStateChange={onEditorStateChange}
        openDeductLeavePopup={openDeductLeavePopup}
        leaveType1ErrorMsg={leaveType1ErrorMsg}
        handleChange={handleChange}
        halfOfTheDayErrMsg={halfOfTheDayErrMsg}
        start1dateErrorMsg={start1dateErrorMsg}
        enddate1ErrorMsg={enddate1ErrorMsg}
        shortDayFromErrorMsg={shortDayFromErrorMsg}
        shortDayToErrorMsg={shortDayToErrorMsg}
        replyErrorMsg={replyErrorMsg}
        startDate1={startDate1}
        endDate1={endDate1}
        isAdmin={isAdmin}
        year="searchYear"
        currentYear={currentYear}
        handleOpenModal={handleOpenModal}
        selectLeave={selectLeave}
        id={id}
        totalEl={earnedLeaves}
        totalCl={casualLeaves}
        totalSl={sickLeaves}
        totalCompOffLeaves={compOff}
        totalLWPLeaves={Lwp}
        totalWFHLeaves={Wfh}
        totalOtherLeaves={totalOtherLeaves}
        totalLeavesTaken={totalLeavesTaken}
        shortLeave={shortDayLeave}
        dispatch={dispatch}
        searchYear={searchYear}
        searchQuatName={searchQuatName}
        searchMonth={searchMonth}
        employeeType={employeeType}
        assignedCl={assignedStatusCl}
        assignedEl={assignedStatusEl}
        assignedSl={assignedStatusSl}
        totalComp={totalCompOff}
        compOff={totalCompOffTaken}
        tempCompOff={tempCompOff}
        tempCompOffCount={tempCompOffCount}
        handleLeaveDeductSubmit={handleLeaveDeductSubmit}
        bereavementLeaves={bereavementLeaves}
        paternalLeave={paternalLeave}
        totalClientOff={assignedClientOff}
        assignedBereavementLeaves={assignedBereavementLeaves}
        assignedPaternalLeave={assignedPaternalLeave}
      />
      <OpenLeaveModal
        open={openModal}
        closeModal={closeModal}
        value={modalValue}
        name={modalName}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        index={index}
        modalValueErrorMsg={modalValueErrorMsg}
        heading={heading}
        compOffStartDate={compOffStartDate}
        compOffStartDateErrorMsg={compOffStartDateErrorMsg}
        onCompOffStartChange={onCompOffStartChange}
        compOffGivenReason={compOffGivenReason}
        compOffGivenReasonErrorMsg={compOffGivenReasonErrorMsg}
        handleCompOffGivenReasonChange={handleCompOffGivenReasonChange}
        handleTypeCompOffChange={handleTypeCompOffChange}
        wantToCarryForwardCompOff={wantToCarryForwardCompOff}
        wantToCarryForwardCompOffErrorMsg={wantToCarryForwardCompOffErrorMsg}
      />

      <EmployeeLeaveData
        isApproved={isApproved}
        startDate={startDate}
        endDate={endDate}
        totalLeaves={totalLeaves}
        pendingCount={pendingCount}
        lateCount={lateCount}
        approvedCount={approvedCount}
        rejectedCount={rejectedCount}
        leaveDetails={leaveDetails}
        pageNo={pageNo}
        count={count}
        handlePageChange={handlePageChange}
        sortType={sortType}
        sortField={sortField}
        handleSort={handleSort}
        employeeType={employeeType}
        handleOpenLeavePopup={handleOpenLeavePopup}
        handleFilter={handleFilter}
        searchYear={searchYear}
        statusFilter={status1}
        status={status}
        dataCount={dataCount}
        leavePage={1}
        leaveTypeSelect={leaveTypeSelect}
        handleLeaveFilter={handleLeaveFilter}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        replyErrorMsg={replyErrorMsg}
        handleChange={handleChange}
        responseAction={responseAction}
        searchDate={searchDate}
        reply={reply}
        search={search}
        searchMonth={searchMonth}
        leaveType={leaveType}
        dateRange={dateRange}
        openLeaveApprove_RejectPopup={openLeaveApprove_RejectPopup}
        openLeaveLwpApprovePopup={openLeaveLwpApprovePopup}
        openApprovedShortLeavePopup={openApprovedShortLeavePopup}
        dispatched={dispatch}
        closeModal={closeModal}
        leaveId={leaveId}
        startDate1={startDate1}
        endDate1={endDate1}
        totalLeave={totalLeaves}
        shortDayFrom={shortDayFrom}
        shortDayTo={shortDayTo}
        shortDayToErrorMsg={shortDayToErrorMsg}
        shortDayFromErrorMsg={shortDayFromErrorMsg}
        handleOpenShowCompOff={handleOpenShowCompOff}
        handleOpenDeleteCompOff={handleOpenDeleteCompOff}
        openDeleteCompOff={openDeleteCompOff}
        isAdmin={isAdmin}
        deleteCompOffNo={deleteCompOffNo}
        handleDeleteCompOffChange={handleDeleteCompOffChange}
        deleteCompOffNoErrMsg={deleteCompOffNoErrMsg}
        handleDeleteCompOff={handleDeleteCompOff}
        responseMessage={responseMessage}
        useDispatch={dispatch}
        handleOpenViewDocPopup={handleOpenViewDocPopup}
        viewDocumentPopup={viewDocumentPopup}
        viewDocument={viewDocument}
        getEmployeeLeavesList={getEmployeeLeavesList}
      />

      <ViewLeaveModal
        show={openLeaveApprovePopup}
        handleClose={closeModal}
        startDate={startDate}
        endDate={endDate}
        leaveMsg={leaveMsg}
        reply={reply}
        status={leaveStatus}
        halfOfTheDay={halfOfTheDayStatus}
        shortDayFrom={shortDayFrom}
        shortDayTo={shortDayTo}
        leaveType={leaveType}
      />
    </div>
  );
}

export default LeaveView;

import React from "react";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Collapse } from "antd";
import { Modal as AntModal } from "antd";
import { Link } from "react-router-dom";
import ResponseFilter from "../../ResponseFilter";
import {
  getAdminProfile,
  editRequest,
  editEmployeeProfileImageByEmp,
} from "../../config/services/employeeServices";
import { ImageIcon, PdfIcon, DocIcon, Loader, PngIcon } from "../svg";
import { clientSideValue } from "../../config/constants/Constants";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const { Panel } = Collapse;

const returnFileIcon = (type) => {
  switch (type) {
    case "docx":
      return <DocIcon />;
      break;
    case "odt":
      return <DocIcon />;
      break;
    case "dotm":
      return <DocIcon />;
      break;
    case "dotx":
      return <DocIcon />;
      break;
    case "docm":
      return <DocIcon />;
      break;
    case "dot":
      return <DocIcon />;
      break;
    case "png":
      return <PngIcon />;
      break;
    case "doc":
      return <DocIcon />;
      break;
    case "pdf":
      return <PdfIcon />;

    default:
      return <ImageIcon />;
  }
};

const employeeRoleValue = (type) => {
  switch (type) {
    case 1:
      return "Admin";
      break;
    case 2:
      return "Employee";
      break;
    case 3:
      return "Vendor";
      break;
    case 4:
      return "HR";
      break;
    case 5:
      return "Accountant";
      break;
    case 6:
      return "Sales";
      break;
      case 7:
        return "It";
        case 8:
          return "Office Admin";
            break;
  }
};

class EmployeeProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      designationDetails: [],
      deptDetails: [],
      otherSkillDetails: [],
      skillDetails: [],
      projectManager: [],
      imageLoader: false,
      resMsg: false,
      loader: true,
      editRequestDesc: "",
      employeeId: "",
      employeeProfile: "",
      currentAddress: "",
      permanentAddress: "",
      education: [],
      editRequest: 2,
      qualification: [],
      profileImage: "",
      projectDetails: [],
      previousAddress: "",
      editProfileImage: "",
      openEditRequestPopup: false,
      employeeProfileErrorMsg: "",
      resProfileUpdate: false,
      resFailProfileUpdate: false,
      miniLoader: false,
      isAdmin: 0,
      src: null,
      crop: {
        unit: "%",
        x: 50,
        y: 50,
        width: 50,
        height: 50,
      },
    };
  }
  handleRequestValidetion = () => {
    let validate = true;
    const { editRequestDesc } = this.state;

    if (editRequestDesc === "" || editRequestDesc === undefined) {
      validate = false;
      this.setState({
        editRequestDescErrorMsg: "Please enter what you want to edit?",
      });
    } else {
      this.setState({ editRequestDescErrorMsg: "" });
    }

    return validate;
  };

  closeModal = () =>
    this.setState({
      openEditRequestPopup: false,
      editRequestDesc: "",
      editRequestDescErrorMsg: "",
      // openProfileChangePopup: false
    });

  closeModal1 = () => this.setState({ openProfileChangePopup: false });

  handleOpenEditRequestPopup = (employeeId, editRequest) =>
    this.setState({
      openEditRequestPopup: !this.state.openEditRequestPopup,
      employeeId,
      editRequest,
    });
  handleOpenProfileChangePopup = () =>
    this.setState({
      openProfileChangePopup: !this.state.openProfileChangePopup,
    });

  handleChallengeImage = (droppedFile) => {
    let reader = new FileReader();
    let file = droppedFile[0];

    this.setState({ uploadFile: droppedFile[0], challengeImageErrorMsg: "" });

    reader.addEventListener(
      "load",
      () => {
        this.setState({
          docImageView: reader.result,
          challengeImageErrorMsg: "",
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  genderValue = (type) => {
    switch (type) {
      case 1:
        return "Male";
        break;
      case 2:
        return "Female";
        break;
      case 3:
        return "Other";
    }
  };

  ////////////////MODIFICATION/////////////////
  employeeStatusValue = (type) => {
    switch (type) {
      case "1":
        return "Fresher";
        break;
      case "2":
        return "Probation";
      case "3":
        return "Experienced";
      case "4":
        return "Intern";
    }
  };

  bloodGroupValue = (type) => {
    switch (type) {
      case 1:
        return "A+";
        break;
      case 2:
        return "B+";
        break;
      case 3:
        return "O+";
        break;
      case 4:
        return "AB+";
        break;
      case 5:
        return "A-";
        break;
      case 6:
        return "B-";
        break;
      case 7:
        return "O-";
        break;
      case 8:
        return "AB-";
    }
  };

  officeLocationValue = (type) => {
    switch (type) {
      case 1:
        return "Noida";
        break;
      case 2:
        return "Lucknow";
    }
  };
  ////////////////MODIFICATION ENDS/////////////////

  degreeType = (type) => {
    switch (type) {
      case 1:
        return "UG";
        break;

      case 2:
        return "Graduation";
        break;

      default:
        return "PG";
    }
  };

  componentDidMount() {
    this.getDetail();
  }

  handleRequest = () => {
    if (this.handleRequestValidetion()) {
      let obj = {
        employeeId: this.state.employeeId,
        editRequest: 2,
        editRequestDesc: this.state.editRequestDesc,
      };

      editRequest(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState(
            {
              openEditRequestPopup: false,
              resMsg: true,
              editRequestDesc: "",
            },
            () => this.getDetail()
          );
          window.scrollTo(0, 0);
        } else {
          this.closeModal();
        }
        window.scrollTo(0, 0);
      });
    }
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + "ErrorMsg"]: "",
    });

  getDetail = () => {
    getAdminProfile().then(
      (res) => {
        let { status, resData } = ResponseFilter(res);

        if (status) {
          this.setState(
            {
              imageLoader: false,
              employeeProfile: resData.employeeProfile,
              employeeId: resData.employeeProfile.employeeId,
              currentAddress: resData.employeeProfile.currentAddress,
              permanentAddress: resData.employeeProfile.permanentAddress,
              previousAddress: resData.employeeProfile.previousAddress,
              education: resData.employeeProfile.education,
              qualification: resData.employeeProfile.qualification,
              profileImage: resData.employeeProfile.profileImage,
              projectDetails: resData.projectDetails,
              projectManager: resData.projectsOfManager,
              skillDetails: resData.skillDetails,
              otherSkillDetails: resData.otherSkillDetails,
              designationDetails: resData.designationDetails,
              deptDetails: resData.deptDetails,
              profileImagePreview: resData.employeeProfile.profileImage
                ? resData.employeeProfile.profileImage
                : require("../../asset/imgs/user-img.png"),
              isAdmin: resData.employeeProfile.isAdmin,
            },
            () => this.setState({ loader: false }),
            () => this.closeModal()
          );
        }
      },
      () => this.closeModal()
    );
    setTimeout(() => this.setState({ resMsg: false }), 4000);
  };

  handleprofileValidation = () => {
    const { editProfileImage } = this.state;
    let validate = true;
    if (editProfileImage == "") {
      validate = false;
      this.setState({ employeeProfileErrorMsg: "Please Enter Image" });
    } else if (
      !editProfileImage?.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)
    ) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg:
          "Please Enter jpg|jpeg|png|JPEG|JPG|PNG format file only",
      });
    } else if (editProfileImage?.size >= 10485760) {
      validate = false;
      this.setState({
        employeeProfileErrorMsg: "please insert image of less then 10 mb",
      });
    } else {
      this.setState({ employeeProfileErrorMsg: "" });
      // validate = false;
    }
    return validate;
  };
  handleProfileSubmit = (e) => {
    if (this.handleprofileValidation()) {
      this.setState({ miniLoader: true });
      let data = new FormData();
      data.append("profileImage", this.state.editProfileImage);

      let params = {
        employeeId: this.props.match.params.id,
        data: data,
      };

      editEmployeeProfileImageByEmp(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.getDetail();
          this.handleCancel();
          this.setState({
            miniLoader: false,
            openProfileChangePopup: false,
            resProfileUpdate: true,
          });
          setTimeout(() => this.setState({ resProfileUpdate: false }), 2000);
        } else {
          this.setState({ resFailProfileUpdate: true });
          this.setState({ openProfileChangePopup: false, miniLoader: false });
        }
      });
    }
  };

  handleCancel = (event) => {
    this.closeModal();
    this.setState(this.getDetail());
  };

  handleCancel1 = (e) => {
    var fileInput = document.getElementById("profile");
    fileInput.value = null;
    this.closeModal1();
    this.setState({
      employeeProfileErrorMsg: "",
      editEmployeeProfile: "",
      src: "",
      crop: {
        unit: "%",
        x: 50,
        y: 50,
        width: 50,
        height: 50,
      },
    });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      this.setState({
        editProfileImage: e.target.files && e.target.files[0],
        employeeProfileErrorMsg: "",
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    let {
      skillDetails,
      otherSkillDetails,
      designationDetails,
      deptDetails,
      projectManager,
      employeeProfile,
      imageLoader,
      profileImagePreview,
      projectDetails,
      loader,
      employeeId,
      resMsg,
      editRequestDesc,
      openEditRequestPopup,
      editRequestDescErrorMsg,
      permanentAddress,
      currentAddress,
      previousAddress,
      openProfileChangePopup,
      employeeProfileErrorMsg,
      resFailProfileUpdate,
      resProfileUpdate,
      miniLoader,
      isAdmin,
      crop,
      croppedImageUrl,
      src,
    } = this.state;

    return (
      <div
        className={`body_container cm_bold ${
          miniLoader ? "admin_attendance" : ""
        }`}
      >
        {loader && <Loader />}

        {resMsg ? (
          <div className="alert alert-success cm_top_fix">
            Request has been sent
          </div>
        ) : (
          ""
        )}
        {resProfileUpdate ? (
          <div className="alert alert-success cm_top_fix">
            Profile Updated Successfully
          </div>
        ) : (
          ""
        )}
        {resFailProfileUpdate ? (
          <div className="alert alert-danger cm_top_fix">Please Try Again</div>
        ) : (
          ""
        )}

        <div className="cm_breadcrumb ml-5">
          <ul>
            <li>
              <Link to="/employee">DASHBOARD</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li>PROFILE</li>
          </ul>
        </div>

        {/* <div className="row">
          <div className="col"> */}
        <div className="row user_employee_profile">
          <div className="col-md-3 cm_user_img mr-3">
            <div className="image-upload">
              <img
                className=""
                src={profileImagePreview}
                disabled={imageLoader}
                alt=""
              />
              {imageLoader ? (
                <div className="cm_img_loader">
                  <img
                    className="image_loader"
                    src={require("../../asset/svg/imageLoader.svg")}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="img-edit">
                <span onClick={this.handleOpenProfileChangePopup}>
                  <i className="fa fa-camera pic-upload"></i>
                </span>
              </div>
              <div className="text-center">
                <span
                  className="btn btn-primary"
                  onClick={() => this.handleOpenEditRequestPopup(employeeId)}
                >
                  Edit Request
                </span>
              </div>
            </div>
            {imageLoader ? <h1>test</h1> : ""}
          </div>

          <div className="col-md-12 col-xl-7 emp_profile ml-3 mt-5">
            <h3 className="mb-4">General Information</h3>
            <form>
              <div className="row mb-4">
                <div className="col-6">
                  <label>First Name</label>
                  <p>{employeeProfile.firstName}</p>
                </div>
                <div className="col-6">
                  <label>Middle Name</label>
                  {!_.isEmpty(employeeProfile.middleName) ? (
                    <p>{employeeProfile.middleName}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <label>Last Name</label>
                  <p>{employeeProfile.lastName}</p>
                </div>
                <div className="col-6">
                  <label>Employee ID</label>
                  {employeeProfile.empId ? (
                    <p>{employeeProfile.empId}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <label>Gender</label>

                  <p>
                    {employeeProfile.gender ? (
                      this.genderValue(employeeProfile.gender)
                    ) : (
                      <span>No data</span>
                    )}
                  </p>
                </div>
                <div className="col-6">
                  <label>D.O.B</label>

                  <p>
                    {employeeProfile.dob
                      ? moment
                          .unix(employeeProfile.dob / 1000)
                          .format("DD/MM/YYYY")
                      : "No data"}
                  </p>
                </div>
              </div>

              {/* **************** MODIFCATION STARTS **************** */}

              <div className="row mb-4">
                <div className="col-6">
                  <label>Blood Group</label>
                  <p>
                    {employeeProfile.bloodGroup ? (
                      this.bloodGroupValue(parseInt(employeeProfile.bloodGroup))
                    ) : (
                      <span>No data</span>
                    )}
                  </p>
                </div>
                <div className="col-6">
                  <label>Reporting Time</label>
                  <p>
                    {employeeProfile.arrivalTime
                      ? moment
                          .utc(employeeProfile.arrivalTime * 1000)
                          .format("HH:mm")
                      : "No data"}
                  </p>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <label>Employee Status</label>
                  <p>
                    {employeeProfile.employeeType ? (
                      this.employeeStatusValue(employeeProfile.employeeType)
                    ) : (
                      <span>No data</span>
                    )}
                  </p>
                </div>
                <div className="col-6">
                  <label>Office Location</label>
                  <p>
                    {employeeProfile.officeLocation ? (
                      this.officeLocationValue(employeeProfile.officeLocation)
                    ) : (
                      <span>No data</span>
                    )}
                  </p>
                </div>
              </div>

              {/* **************** MODIICATION ENDS **************** */}
              <div className="row mb-4">
                <div className="col-6">
                  <label>Mobile Number</label>
                  {employeeProfile.phone && employeeProfile.phone === "91" ? (
                    <p>No data</p>
                  ) : (
                    <p>
                      {employeeProfile.phone ? ` ${employeeProfile.phone}` : ""}
                    </p>
                  )}
                </div>
                <div className="col-6">
                  <label>Alternate Number</label>
                  {employeeProfile.alternateMob &&
                  employeeProfile.alternateMob === "91" ? (
                    <p>No data</p>
                  ) : (
                    <p>
                      {employeeProfile.alternateMob
                        ? ` +${employeeProfile.alternateMob}`
                        : ""}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <label>Official Mail</label>
                  {employeeProfile.email ? (
                    <p>{employeeProfile.email}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
                <div className="col-6">
                  <label>Personal Mail</label>
                  {employeeProfile.personalEmail ? (
                    <p>{employeeProfile.personalEmail}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>

                {/* MODIFICATION */}
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label>LinkedIn </label>
                  {employeeProfile.linkdinId ? (
                    <p>{employeeProfile.linkdinId}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
                <div className="col-6">
                  <label>Skype</label>
                  {employeeProfile.skypeId ? (
                    <p>{employeeProfile.skypeId}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>

              {/* MODIFICATION  ENDS */}

              <div className="row mb-4">
                <div className="col-6">
                  <label>Date of Joining</label>

                  <p>
                    {employeeProfile.dateOfJoining
                      ? moment
                          .unix(employeeProfile.dateOfJoining / 1000)
                          .format("DD/MM/YYYY")
                      : "No data"}
                  </p>
                </div>
                <div className="col-6">
                  <label>Previous Experience</label>
                  <p>
                    {employeeProfile.experienceYear
                      ? employeeProfile.experienceYear
                      : 0}
                    .
                    {employeeProfile.experienceMonth
                      ? employeeProfile.experienceMonth
                      : 0}
                  </p>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <label>Due Appraisal</label>

                  <p>
                    {employeeProfile.dueAppraisal
                      ? moment
                          .unix(employeeProfile.dueAppraisal / 1000)
                          .format("DD/MM/YYYY")
                      : "No data"}
                  </p>
                </div>

                <div className="col-6">
                  <label>Permanent Address</label>

                  {permanentAddress.permanentAddressOne ||
                  permanentAddress.permanentAddressTwo ||
                  permanentAddress.permanentState ||
                  permanentAddress.permanentZip ? (
                    <p>
                      {permanentAddress.permanentAddressOne
                        ? `${permanentAddress.permanentAddressOne}`
                        : ""}
                      {permanentAddress.permanentAddressOne
                        ? permanentAddress.permanentAddressTwo ||
                          permanentAddress.permanentState ||
                          permanentAddress.permanentZip
                          ? ","
                          : ""
                        : ""}

                      {permanentAddress.permanentAddressTwo
                        ? ` ${permanentAddress.permanentAddressTwo}`
                        : ""}
                      {permanentAddress.permanentAddressTwo
                        ? permanentAddress.permanentState ||
                          permanentAddress.permanentZip
                          ? ","
                          : ""
                        : ""}

                      {permanentAddress.permanentState
                        ? ` ${permanentAddress.permanentState}`
                        : ""}
                      {permanentAddress.permanentState
                        ? permanentAddress.permanentZip
                          ? ","
                          : ""
                        : ""}

                      {permanentAddress.permanentZip
                        ? `${permanentAddress.permanentZip}`
                        : ""}
                    </p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label>Current Address</label>

                  {currentAddress.currentAddressOn ||
                  currentAddress.currentAddressTwo ||
                  currentAddress.currentState ||
                  currentAddress.currentZip ? (
                    <p>
                      {currentAddress.currentAddressOne
                        ? `${currentAddress.currentAddressOne}`
                        : ""}
                      {currentAddress.currentAddressOne
                        ? currentAddress.currentAddressTwo ||
                          currentAddress.currentState ||
                          currentAddress.currentZip
                          ? ","
                          : ""
                        : ""}

                      {currentAddress.currentAddressTwo
                        ? ` ${currentAddress.currentAddressTwo}`
                        : ""}
                      {currentAddress.currentAddressTwo
                        ? currentAddress.currentState ||
                          currentAddress.currentZip
                          ? ","
                          : ""
                        : ""}

                      {currentAddress.currentState
                        ? ` ${currentAddress.currentState}`
                        : ""}
                      {currentAddress.currentState
                        ? currentAddress.currentZip
                          ? ","
                          : ""
                        : ""}

                      {currentAddress.currentZip
                        ? `${currentAddress.currentZip}`
                        : ""}
                    </p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
                <div className="col-6">
                  <label>Previous Address</label>
                  {previousAddress.previousAddressOne ||
                  previousAddress.previousAddressTwo ||
                  previousAddress.previousState ||
                  previousAddress.previousZip ? (
                    <p>
                      {previousAddress.previousAddressOne
                        ? `${previousAddress.previousAddressOne}`
                        : ""}
                      {previousAddress.previousAddressOne
                        ? previousAddress.previousAddressTwo ||
                          previousAddress.previousState ||
                          previousAddress.previousZip
                          ? ","
                          : ""
                        : ""}

                      {previousAddress.previousAddressTwo
                        ? ` ${previousAddress.previousAddressTwo}`
                        : ""}
                      {previousAddress.previousAddressTwo
                        ? previousAddress.previousState ||
                          previousAddress.previousZip
                          ? ","
                          : ""
                        : ""}

                      {previousAddress.previousState
                        ? ` ${previousAddress.previousState}`
                        : ""}
                      {previousAddress.previousState
                        ? previousAddress.previousZip
                          ? ","
                          : ""
                        : ""}

                      {previousAddress.previousZip
                        ? ` ${previousAddress.previousZip}`
                        : ""}
                    </p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label>Role</label>
                  {isAdmin ? (
                    <p>{employeeRoleValue(isAdmin)}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>

                <div className="col-6">
                  <label>Client Side</label>

                  {employeeProfile?.clientSide ? (
                    <p>{clientSideValue(employeeProfile?.clientSide)}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <label>Client Location</label>

                  {employeeProfile?.clientLocation ? (
                    <p>{employeeProfile?.clientLocation}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
                <div className="col-6">
                  <label>Client Name</label>

                  {employeeProfile?.clientName ? (
                    <p>{employeeProfile?.clientName}</p>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>
            </form>
            <div className="row mb-4">
              <div className="col-12">
                <label>Department</label>

                <Collapse bordered={false} defaultActiveKey={["1"]}>
                  <Panel header="View Department">
                    {deptDetails.map((department) => (
                      <p>
                        {department.departmentName
                          ? department.departmentName
                          : ""}
                      </p>
                    ))}
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                <label>Designation</label>

                <Collapse bordered={false} defaultActiveKey={["1"]}>
                  <Panel header="View Designation">
                    {designationDetails.map((department, i) => (
                      <p>
                        {department.designationName
                          ? `${department.designationName}`
                          : ""}
                      </p>
                    ))}
                  </Panel>
                </Collapse>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <label>Skill</label>
                {!_.isEmpty(skillDetails) ? (
                  <Collapse bordered={false} defaultActiveKey={["1"]}>
                    <Panel header="View Skill">
                      {skillDetails.map((department, i) => (
                        <p>
                          {department.skillName ? department.skillName : ""}
                        </p>
                      ))}

                      {otherSkillDetails.length !== 0 ? otherSkillDetails : ""}
                    </Panel>
                  </Collapse>
                ) : (
                  <p>No data</p>
                )}
              </div>
            </div>
            <div className="form-group mb-4">
              <label>Attached Documents</label>
              {!_.isEmpty(employeeProfile.attachedDocs) ? (
                <ul className="cm_proj cm_doc">
                  {employeeProfile.attachedDocs.map((data) => (
                    <li id={data._id} key={data._id}>
                      <span>
                        <a href={data.uploadFile} download>
                          <i
                            className="fa fa-download cm_doc_download"
                            aria-hidden="true"
                            title="Download document"
                          ></i>
                        </a>
                      </span>
                      {returnFileIcon(data.fileType)}
                      <p className="cm_three_dots">
                        {data.documentName}.{data.fileType}
                      </p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No data</p>
              )}
            </div>

            <div className="form-group mb-5 edu_details">
              <label>Education Qualification</label>

              {!_.isEmpty(employeeProfile.education) ? (
                employeeProfile.education.map((data) => (
                  <p>
                    {this.degreeType(data.degree)
                      ? `${this.degreeType(data.degree)}`
                      : ""}
                    {data.collegeName ? `, ${data.collegeName}` : ""}
                    {data.startDate
                      ? `, ${moment
                          .unix(data.startDate / 1000)
                          .format("DD/MM/YYYY")}`
                      : ""}
                    {data.endDate
                      ? `- ${moment
                          .unix(data.endDate / 1000)
                          .format("DD/MM/YYYY")}`
                      : ""}
                    {data.description ? `, ${data.description}` : ""}
                  </p>
                ))
              ) : (
                <p>No data</p>
              )}
            </div>

            <div className="form-group mb-5 edu_details">
              <label>Additional Qualification</label>

              {!_.isEmpty(employeeProfile.qualification) ? (
                employeeProfile.qualification.map((data) => (
                  <p>
                    {this.degreeType(data.degree)
                      ? `${this.degreeType(data.degree)} `
                      : ""}
                    {data.collegeName ? `,${data.collegeName} ` : ""}
                    {data.startDate
                      ? `,${moment
                          .unix(data.startDate / 1000)
                          .format("DD/MM/YYYY")} `
                      : ""}
                    {data.endDate
                      ? `-${moment
                          .unix(data.endDate / 1000)
                          .format("DD/MM/YYYY")} `
                      : ""}
                    {data.description ? `,${data.description} ` : ""}
                  </p>
                ))
              ) : (
                <p>No data</p>
              )}
            </div>
            <div className="form-group">
              <label>Assigned Project</label>
              <span className="edit_btn"></span>
              {!_.isEmpty(projectDetails) ? (
                <ul className="cm_proj">
                  {projectDetails.map((data) => (
                    <li>
                      <div className="proj_name blue">{data.projectName}</div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No data</p>
              )}
            </div>
            <div className="form-group">
              <label>Assigned Project As Project Manager</label>
              <span className="edit_btn"></span>
              {!_.isEmpty(projectManager) ? (
                <ul className="cm_proj">
                  {projectManager.map((data) => (
                    <li>
                      <div className="proj_name blue">{data.projectName}</div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No data</p>
              )}
            </div>
          </div>
        </div>

        {/* </div></div> */}
        <Modal
          className="cm_req_modal"
          size="md"
          show={openEditRequestPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="text-center confirmation">
            <p className="mb-4 cm_bold cnfrm_msg">
              Are you sure, you want to send edit request?
            </p>

            <div
              className={`form-group ${
                editRequestDescErrorMsg ? "input_error" : ""
              }`}
            >
              <textarea
                className="form-control cm-des cm_bold cm_box"
                type="text"
                maxLength={150}
                name="editRequestDesc"
                placeholder="What you want to edit?"
                value={editRequestDesc}
                onChange={this.handleChange}
              ></textarea>
              {editRequestDescErrorMsg ? (
                <span className="error_msg">{editRequestDescErrorMsg}</span>
              ) : null}{" "}
            </div>
            <div className="submit_button_margin mt-4 edit_appraisal">
              <button
                onClick={this.handleRequest}
                className="btn btn-primary btn-sm cm_btn3"
              >
                Yes
              </button>
              <button
                type="submit"
                onClick={this.closeModal}
                className="btn btn-primary  cm_btn2 btn-sm"
              >
                No
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <AntModal
          title=""
          centered
          className="sm_modal"
          footer={false}
          visible={openProfileChangePopup}
          onOk={this.handleOk}
          onCancel={this.handleCancel1}
        >
          <Modal.Body>
            <div
              className={`assign_device ${
                miniLoader ? "admin_attendance" : ""
              }`}
            >
              <h3 className="mb-4">EDIT PROFILE PICTURE</h3>
              <div className="row">
                <div
                  className={`form-group ${
                    employeeProfileErrorMsg ? "input_error" : ""
                  }`}
                >
                  <label className="sub">Upload Picture</label>
                  <input
                    className="form-control cm_bold cm_box "
                    type="file"
                    id="profile"
                    placeholder="Upload image"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.onSelectFile}
                    disabled={miniLoader}
                  />
                  <div className="mt-2">
                    {src && (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        aspect={1}
                        ruleOfThirds
                        circularCrop
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        style={{ width: "14rem", height: "14rem" }}
                      />
                    )}
                    {croppedImageUrl && src && (
                      <img
                        alt="Crop"
                        style={{
                          width: "12rem",
                          position: "absolute",
                          bottom: "5px",
                          marginLeft: "12px",
                          borderRadius: "50%",
                        }}
                        src={croppedImageUrl}
                      />
                    )}
                  </div>
                  {employeeProfileErrorMsg ? (
                    <span className="error_msg">{employeeProfileErrorMsg}</span>
                  ) : null}
                </div>
              </div>
              <div className="submit_button_margin mt-4">
                {miniLoader ? <div className="cover-spin1"></div> : ""}
                <button
                  type="submit"
                  className="btn btn-primary cm_btn3"
                  onClick={this.handleProfileSubmit}
                  disabled={miniLoader}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-primary cm_btn2"
                  onClick={this.handleCancel1}
                  disabled={miniLoader}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </AntModal>
      </div>
    );
  }
}
export default EmployeeProfileView;

import {
    GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_REQUEST,
    GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_SUCCESS,
    GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_FAILURE,
} from "./actionType";
import ResponseFilter from "../../ResponseFilter";
import { decryptResponseData } from "../../components/salaryGeneration/encryptionAndDecryption";
import { getEmployeeMonthlyReimbursementStats } from "../../config/services/employeeServices";

const getEmployeeMonthlyReimbursementStatsAction = (props) => {
    let { useDispatch, params } = props
    return (dispatch) => {
        dispatch(getEmployeeMonthlyReimbursementStatsActionRequest());
        getEmployeeMonthlyReimbursementStats(params).then((res) => {
            let { status } = ResponseFilter(res)
            if (status) {
                let employeesMonthlyReimbursementStats = decryptResponseData(res?.data?.responseData?.reimbursementData)
                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        employeesMonthlyReimbursementStats: employeesMonthlyReimbursementStats,
                        monthlyReimbursementStatsDataCount: res?.data?.responseData?.dataCount,
                    }
                })
                let exportExcel = decryptResponseData(res?.data?.responseData?.excelLink)
                if (exportExcel !== "") {
                    window.location.href = exportExcel
                }
            } else {

                useDispatch({
                    type: 'typeCheckSet', payload: {
                        miniLoader: false,
                        resFailMsg: true,
                        responseMessage: res?.data?.error?.responseMessage
                    }
                })

                setTimeout(() => {
                    useDispatch({
                        type: 'typeCheckSet', payload: {
                            resFailMsg: false,
                        }
                    })
                }, 4000)
            }
        })
            .catch((error) => {
                dispatch(getEmployeeMonthlyReimbursementStatsActionFailure(error));
            });
    };
};

const getEmployeeMonthlyReimbursementStatsActionRequest = () => {
    return {
        type: GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_REQUEST,
    };
};

const getEmployeeMonthlyReimbursementStatsActionSuccess = (data) => {
    return {
        type: GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_SUCCESS,
        payload: data,
    };
};

const getEmployeeMonthlyReimbursementStatsActionFailure = (error) => {
    return {
        type: GET_EMPLOYEE_MONTHLY_REIMBURSEMENT_STATS_FAILURE,
        payload: error,
    };
};
export {
    getEmployeeMonthlyReimbursementStatsAction,
    getEmployeeMonthlyReimbursementStatsActionRequest,
    getEmployeeMonthlyReimbursementStatsActionSuccess,
    getEmployeeMonthlyReimbursementStatsActionFailure,
};

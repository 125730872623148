import {
  UPDATE_RESUME_BY_EMP_ID_REQUEST,
  UPDATE_RESUME_BY_EMP_ID_SUCCESS,
  UPDATE_RESUME_BY_EMP_ID_FAILURE,
} from "./actionType";

import { getResumeByEmployeeId } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";
// import { updateEmployeeResume } from "../../config/services/employeeServices";
// const empId = window.localStorage.getItem("employeeId");
// const x = window.localStorage.getItem("employeeId")
const updateResumeByEmpId = (params) => {
  return (dispatch) => {
    dispatch(updateResumeByEmpIdRequest());
    getResumeByEmployeeId(params)
      .then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          dispatch(updateResumeByEmpIdSuccess(res.data.responseData));
        } else {
          // updateEmployeeResume(data)
          dispatch(updateResumeByEmpIdFailure(res.data));
        }
      })
      .catch((error) => {
        dispatch(updateResumeByEmpIdFailure(error));
      });
  };
};



const updateResumeByEmpIdRequest = () => {
  return {
    type: UPDATE_RESUME_BY_EMP_ID_REQUEST,
  };
};

const updateResumeByEmpIdSuccess = (data) => {
  return {
    type: UPDATE_RESUME_BY_EMP_ID_SUCCESS,
    payload: data,
  };
};

const updateResumeByEmpIdFailure = (error) => {
  return {
    type: UPDATE_RESUME_BY_EMP_ID_FAILURE,
    payload: error,
  };
};
export {
  updateResumeByEmpId,
  updateResumeByEmpIdRequest,
  updateResumeByEmpIdSuccess,
  updateResumeByEmpIdFailure,
};

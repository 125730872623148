import {
    EDIT_ASSIGN_CANDIDATE_REQUEST,
    EDIT_ASSIGN_CANDIDATE_SUCCESS,
    EDIT_ASSIGN_CANDIDATE_FAILURE,
} from "./actionType";

import { editAssignCandidate } from "../../config/services/employeeServices";
const editAssignCandidateByHr = (data, setShowModal, setResHrRating, setResHrFailRating, setLoader, closeModal, getData) => {
    return (dispatch) => {
        dispatch(editAssignCandidateByHrRequest());
        editAssignCandidate(data)
            .then((res) => {
                if (res.data.statusCode !== 1) {
                    dispatch(editAssignCandidateByHrFailure(res.data));
                    // setErrorText("Failed to edit");
                    setResHrFailRating(true)
                    setResHrRating(false)
                    setLoader(false)

                } else {
                    dispatch(editAssignCandidateByHrSuccess(res.data));
                    setResHrRating(true)
                    setResHrFailRating(false)
                    setTimeout(() => (
                        closeModal(),
                        setLoader(false),
                        getData()
                    ), 3000);

                }
            })
        // .catch((error) => {
        //     dispatch(editAssignCandidateByHrFailure(error));
        //     setErrorText("Failed to edit");
        //     setFailResMsg(true)
        //     // setLoader(false)
        // });
    };
};

const editAssignCandidateByHrRequest = () => {
    return {
        type: EDIT_ASSIGN_CANDIDATE_REQUEST,
    };
};
const editAssignCandidateByHrSuccess = (data) => {
    return {
        type: EDIT_ASSIGN_CANDIDATE_SUCCESS,
        payload: data,
    };
};
const editAssignCandidateByHrFailure = (error) => {
    return {
        type: EDIT_ASSIGN_CANDIDATE_FAILURE,
        payload: error,
    };
};
export {
    editAssignCandidateByHr,
    editAssignCandidateByHrRequest,
    editAssignCandidateByHrSuccess,
    editAssignCandidateByHrFailure,
};

import React from "react";
import { REQUIRED } from "../../helper/Constant";

export const handleDownloadValidation = (props) => {
  const { state, setState } = props;
  const { month, year, downloadEmployee, selectTemplate } = state;
  let valid = true;
  if (month === "" || month === undefined) {
    setState({
      monthErr: REQUIRED,
    });
    valid = false;
  }
  if (year === "" || year === undefined) {
    setState({
      yearErr: REQUIRED,
    });
    valid = false;
  }
  if (downloadEmployee === "" || downloadEmployee === undefined) {
    setState({
      downloadEmployeeErr: REQUIRED,
    });
    valid = false;
  }
  if (selectTemplate === "" || selectTemplate === undefined) {
    setState({
      selectTemplateErr: REQUIRED,
    });
    valid = false;
  }
  return valid;
};

export const PrintButton = (props) => {
  const { handlePrint, goBack, hideIcon } = props;
  return (
    !hideIcon && (
      <div className="d-flex justify-content-end month_year mt-5 mb-5">
        <button className="btn btn-secondary cm_btn3" onClick={handlePrint}>
          Print
        </button>
        <button
          className="btn btn-secondary cm_btn2 text-dark"
          onClick={() => goBack()}
        >
          Back
        </button>
      </div>
    )
  );
};

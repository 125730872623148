import {
    GET_INTERVIEWER_LIST_REQUEST,
    GET_INTERVIEWER_LIST_SUCCESS,
    GET_INTERVIEWER_LIST_FAILURE,
} from "./actionType";

import { getInterviewerListService } from "../../config/services/employeeServices";
import ResponseFilter from "../../ResponseFilter";

const getInterviewerList = (query) => {
    return (dispatch) => {
        dispatch(getInterviewerListRequest());
        getInterviewerListService(query)
            .then((res) => {
                let { status, resData } = ResponseFilter(res);
                if (status) {
                    dispatch(getInterviewerListSuccess(res.data.responseData));
                } else {
                    dispatch(getInterviewerListFailure(res.data));
                }
            })
            .catch((error) => {
                dispatch(getInterviewerListFailure(error));
            });
    };
};

const getInterviewerListRequest = () => {
    return {
        type: GET_INTERVIEWER_LIST_REQUEST,
    };
};

const getInterviewerListSuccess = (data) => {
    return {
        type: GET_INTERVIEWER_LIST_SUCCESS,
        payload: data,
    };
};

const getInterviewerListFailure = (error) => {
    return {
        type: GET_INTERVIEWER_LIST_FAILURE,
        payload: error,
    };
};
export {
    getInterviewerList,
    getInterviewerListRequest,
    getInterviewerListSuccess,
    getInterviewerListFailure,
};

import React from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Loader } from '../svg';

import { addDepartment, getDepartmentList, editDepartment, deleteDepartment } from '../../config/services/employeeServices';
import { debounce } from '../helper/Constant';
import ResponseFilter from '../../ResponseFilter';

class AdminDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditDepartmentPopup: false,
      openDeleteDepartmentPopup: false,
      openAddDepartmentPopup: false,
      departmentName: '',
      departmentIcon: '',
      departmentNameErrorMsg: '',
      departmentIconErrorMsg: '',
      miniLoader: false,
      departmentList: [],
      departmentEdit: '',
      departmentId: '',
      editDepartmentName: '',
      deleteDepartment: '',
      departmentIconPreview: '',
      search: '',
      loader: true,
      editresMsg: '',
      resMsg: '',
      sortType: 2,
      sortField: "created",
      resAdd: false,
      resEdit: false,
      resDelete: false,
      editDepartmentIcon: "",
      previousDepartmentIcon: "",
      previousDepartmentIconPreview: "",
      editDepartmentIconPreview: "",
      resFailMsg: false,
      resFailMessage: "",
    }
    this.debounceSearch = null
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value,
      [e.target.name + 'ErrorMsg']: '',
      resMsg: ''
    });
  }
  handleSearch = e => {
    this.setState({ search: e.target.value })
    clearTimeout(this.debouncedSearch);
    this.debounceSearch = setTimeout(() => {
      this.setState({ loader: true })
      this.getDepartment();
    }, 2000);
  }
  handleSelectChange = () => {
    this.setState({ search: '' }, () => this.getDepartment())
  }
  submitSearch = e => {
    e.preventDefault();
    this.setState({ loader: true })
    this.getDepartment()
  }

  handleFileChange = droppedFile => {
    let reader = new FileReader();

    let file = droppedFile[0];

    this.setState({ departmentIcon: droppedFile[0], departmentIconErrorMsg: '', docErrorMsg: '', prev: false })


    reader.addEventListener("load", () => {
      this.setState({ departmentIconPreview: reader.result, departmentIcon: droppedFile[0], departmentIconErrorMsg: '' })
    }, false);
    if (file) { reader.readAsDataURL(file) }
  }
  handleEditFileChange = droppedFile => {
    // this.handleIconEditValidation()
    let reader = new FileReader();

    let file = droppedFile[0];

    this.setState({ editDepartmentIcon: droppedFile[0], departmentIconErrorMsg: '', docErrorMsg: '', prev: false })


    reader.addEventListener("load", () => {
      this.setState({ previousDepartmentIconPreview: reader.result, departmentIcon: droppedFile[0], departmentIconErrorMsg: '' })
    }, false);
    if (file) { reader.readAsDataURL(file) }
  }
  getDepartment = () => {

    let { search, sortType, sortField } = this.state


    let params = `search=${search.trim()}&sortType=${sortType}&sortField=${sortField}`;
    this.props.history.push(`admin-department?search=${search}&sortType=${sortType}&sortField=${sortField}`)
    this.setState({ loader: true })
    getDepartmentList(params).then(res => {
      let { status, resData } = ResponseFilter(res);
      if (status) {

        this.setState({

          departmentList: res.data.responseData.departmentList
        }, () => this.setState({ loader: false }, () => this.closeModal()))
      }

    })
  }

  componentDidMount() {

    let url_string = window.location.href
    let url = new URL(url_string);
    let search = url.searchParams.get("search");
    let sortType = url.searchParams.get("sortType");
    let sortField = url.searchParams.get("sortField");

    this.setState({
      search: search ? search : "",
      sortType: sortType ? sortType : 1,
      sortField: sortField ? sortField : "",
    }, () => {
      this.getDepartment();
    })
  }

  handleOpenEditDepartmentPopup = ({ departmentId, departmentName, departmentIcon }) => this.setState({ openEditDepartmentPopup: !this.state.openEditDepartmentPopup, departmentId, previousDepartmentIconPreview: departmentIcon, previousDepartmentIcon: departmentIcon, departmentName, });
  handleOpenDeleteDepartmentPopup = ({ departmentId }) => this.setState({ openDeleteDepartmentPopup: !this.state.openDeleteDepartmentPopup, departmentId });
  handleOpenAddDepartmentPopup = () => this.setState({ openAddDepartmentPopup: !this.state.openAddDepartmentPopup });

  closeModal = () => this.setState({
    openEditDepartmentPopup: false,
    openDeleteDepartmentPopup: false,
    openAddDepartmentPopup: false,
    departmentName: '',
    departmentNameErrorMsg: '',
    departmentIconErrorMsg: '',
    miniLoader: false,
    departmentEdit: '',
    deleteDepartment: '',
    departmentId: '',
    numOfEmployees: '',
    departmentIcon: '',
    resMsg: '',
    editresMsg: '',
    resFailMsg: false,
    departmentIconPreview: ''
  });

  handleValidetion = () => {
    let validate = true;
    const { departmentName, departmentIcon } = this.state;

    if (departmentName === '' || departmentName === undefined) {
      validate = false;
      this.setState({ departmentNameErrorMsg: 'Department name is required' })
    }
    else {
      this.setState({ departmentNameErrorMsg: '' })
    }
    if (departmentIcon === '' || departmentIcon === undefined) {
      validate = false;
      this.setState({ departmentIconErrorMsg: 'Department icon is required' })
    }
    else if (!departmentIcon?.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
      validate = false;
      this.setState({ departmentIconErrorMsg: 'Please Enter Images Only' })
    }
    else if (departmentIcon?.size >= 10485760) {
      validate = false;
      this.setState({ departmentIconErrorMsg: 'please insert image of less then 10 mb' })
    }
    else {
      this.setState({ departmentIconErrorMsg: '' })
    }

    return validate;
  }

  handleEditValidetion = () => {
    let validate = true;
    const { departmentName, previousDepartmentIcon, editDepartmentIcon } = this.state;
    if (departmentName === '' || departmentName === undefined) {
      validate = false;
      this.setState({ departmentNameErrorMsg: 'Department name is required' })
    }
    else {
      this.setState({ departmentNameErrorMsg: '' })
    }
    if (previousDepartmentIcon && editDepartmentIcon === "") {
      this.setState({ departmentIconErrorMsg: '' })
    }
    else {
      if (editDepartmentIcon === '' && editDepartmentIcon === undefined && previousDepartmentIcon === "") {
        validate = false;
        this.setState({ departmentIconErrorMsg: 'Department icon is required' })
      }
      else if (!editDepartmentIcon?.name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
        validate = false;
        this.setState({ departmentIconErrorMsg: 'Please Enter Images Only' })
      }
      else if (editDepartmentIcon?.size >= 10485760) {
        validate = false;
        this.setState({ departmentIconErrorMsg: 'please insert image of less then 10 mb' })
      }
      else {
        this.setState({ departmentIconErrorMsg: '' })
      }

    }
    return validate
  }




  handleSubmit = event => {
    event.preventDefault();

    let { departmentName, departmentEdit, departmentIcon } = this.state;
    if (this.handleValidetion()) {
      this.setState({ miniLoader: true });

      let params = new FormData()
      params.append('departmentName', departmentName);
      params.append('departmentEdit', departmentEdit);
      params.append('departmentIcon', departmentIcon);

      addDepartment(params).then(res => {

        if (res.data.statusCode === 1) {

          this.setState({ miniLoader: false, resAdd: true }, () => this.getDepartment())
          setTimeout(() => this.setState({ resAdd: false }), 3000);
        } else {


          this.setState({ miniLoader: false, resFailMessage: res.data.error.responseMessage, openAddDepartmentPopup: true, resFailMsg: true })
          setTimeout(() => this.setState({ resFailMsg: false }), 3000);
        }
      })
    }

  }

  handleEditSubmit = event => {
    event.preventDefault();

    let { departmentName, departmentId, departmentIcon, previousDepartmentIcon, editDepartmentIcon } = this.state;
    if (this.handleEditValidetion()) {
      this.setState({ miniLoader: true });
      let params = new FormData()
      params.append('departmentName', departmentName);
      params.append('departmentId', departmentId);
      params.append('departmentIcon', editDepartmentIcon ? editDepartmentIcon : previousDepartmentIcon);

      editDepartment(params).then(res => {

        if (res.data.statusCode === 1) {
          this.setState({ miniLoader: false, resEdit: true }, () => this.getDepartment())
        } else {


          this.setState({ miniLoader: false, resFailMessage: res.data.error.responseMessage, openEditDepartmentPopup: true, resFailMsg: true })
        }
      })
    }
    setTimeout(() => this.setState({ resEdit: false }), 3000);
    setTimeout(() => this.setState({ resFailMsg: false }), 3000);

  }


  handleDelete = () => {

    let obj = {
      data: {
        departmentId: this.state.departmentId,
        status: 2
      }
    }
    this.setState({ miniLoader: true })
    deleteDepartment(obj)
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({ miniLoader: false, resDelete: true }, () => this.getDepartment())
        }
        else {
          this.setState({ miniLoader: false, openDeleteDepartmentPopup: true, resFailMsg: true, resFailMessage: res.data.error.responseMessage })
        }
      })
    setTimeout(() => this.setState({ resDelete: false }), 3000);
    setTimeout(() => this.setState({ resFailMsg: false }), 3000);

  }




  render() {
    let {
      openEditDepartmentPopup,
      openDeleteDepartmentPopup,
      openAddDepartmentPopup,
      departmentName,
      departmentNameErrorMsg,
      departmentIconErrorMsg,
      miniLoader,
      departmentList,
      search,
      loader,
      departmentIconPreview,
      resMsg,
      editresMsg,
      resAdd,
      resDelete,
      resEdit,
      previousDepartmentIconPreview,
      resFailMsg,
      resFailMessage,
      debounceSearchTerm

    } = this.state;




    return (
      <div className="body_container cm_bold">
        {
          loader && <Loader />
        }
        {
          resAdd ?
            <div className="alert alert-success cm_top_fix">New Department Added Successfully</div> : ''
        }
        {
          resDelete ?
            <div className="alert alert-danger cm_top_fix">Department Deleted Successfully</div> : ''
        }
        {
          resEdit ?
            <div className="alert alert-warning cm_top_fix">Department Edited Successfully</div> : ''
        }
        {
          resFailMsg ?
            <div className="alert alert-danger cm_top_fix">{resFailMessage}</div> : ''

        }

        <h3 className="mb-2">DEPARTMENT MANAGEMENT</h3>
        <div className="row d-flex justify-content-end mb-4">
          <div className="col-md-6 search_device_bar1">
            <span className="btn btn-primary add_empBtn add1" onClick={this.handleOpenAddDepartmentPopup}>Add New Department</span>
            <input className="form-control mr-lg-2 cm_box"
              type="search"
              placeholder="Search by name"
              aria-label="Search"
              maxLength={25}
              value={search}
              onChange={this.handleSearch}
            // onKeyDown={(e) => { if (e.key === 'Enter') this.getDepartment() }}
            />
            {search ? <span className="cm_clear3" onClick={this.handleSelectChange}><i className="fa fa-times-circle" aria-hidden="true"></i></span> : <span className="cm_clear3"><i className="fa fa-search" aria-hidden="true" ></i></span>}

          </div>
        </div>


        <div className={` ${departmentList.length > 0 ? "department-modal" : ""}`}>
          {
            !_.isEmpty(departmentList) ?
              departmentList.map(department => (

                <div className="col-12" key={department.departmentId}>
                  <Link title="View department" to={`/admin-department/${department.departmentId}`}>
                    <div className="cm_card cm_card_dept">
                      <img className="department_img" src={`${department.departmentIcon ? department.departmentIcon : require('../../asset/imgs/logo.png')}`} alt="" />

                      <div className="cm_style">

                        <span className="emp_count cm_bold">{department.numOfEmployees}</span>
                        <p className="mb-4 cm_dept_dots">{department.departmentName}</p>


                      </div>



                    </div>
                  </Link>
                  <div className="text-right department_btn">
                    <button type="button" className="btn btn-primary btn-sm mr-4" onClick={() => this.handleOpenEditDepartmentPopup(department)}>Edit</button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleOpenDeleteDepartmentPopup(department)}>Delete</button>

                  </div>
                </div>

              )) : <h3 className="text-center ">No data available</h3>

          }

        </div>
        <Modal
          size="md"
          show={openAddDepartmentPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <form className="add-new-dept" onSubmit={this.handleSubmit}>
              <h3>ADD NEW DEPARTMENT</h3>



              <div className={`form-group ${departmentNameErrorMsg || resMsg ? 'input_error' : ''}`}>
                <label className="cm_bold">Department</label>
                <input
                  maxLength="75"
                  className="form-control cm_box"
                  type="text"
                  placeholder="Enter department"
                  name="departmentName"
                  value={departmentName}
                  onChange={this.handleChange}
                />
                {departmentNameErrorMsg ? <span className="error_msg">{departmentNameErrorMsg}</span> : null}
                {<span className="res_error_msg">{resMsg ? resMsg : ''}</span>}
              </div>




              <div className={`form-group ${departmentIconErrorMsg ? 'input_error' : ''}`}>
                <label className="mb-3 cm_bold">Department Icon</label>



                <Dropzone onDrop={acceptedFile => this.handleFileChange(acceptedFile)}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <section>
                      <div className="upload_doc upload_doc_1" {...getRootProps()}
                        style={{ background: `url(${departmentIconPreview})` }}>
                        <input {...getInputProps()} accept=".png, .jpg, .jpeg" />

                        {
                          departmentIconPreview ? '' : <p><span className="blue">Upload Icon</span></p>
                        }

                      </div>
                    </section>
                  )}
                </Dropzone>
                {departmentIconErrorMsg ?
                  <span className="error_msg">
                    {departmentIconErrorMsg}</span>
                  : null}
              </div>


              {miniLoader ? <div className="mini_loader_dept"></div> : ''}
              <div className="submit_button_margin mt-4 ml-5">
                <button type="submit" className="btn btn-primary  cm_btn3" disabled={miniLoader}>Save</button>
                <button type="button" className="btn btn-primary  cm_btn2" onClick={this.closeModal}>Cancel</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>



        <Modal
          size="md"
          show={openEditDepartmentPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <form className="add-new-dept" onSubmit={this.handleEditSubmit}>

              <div className={`form-group ${departmentNameErrorMsg || editresMsg ? 'input_error' : ''}`}>
                <h3>EDIT DEPARTMENT</h3>
                <label className="cm_bold">Department</label>
                <input
                  maxLength="75"
                  className="form-control cm_box"
                  type="text"
                  placeholder="Enter Department"
                  name="departmentName"
                  value={departmentName}
                  onChange={this.handleChange}
                />
                {departmentNameErrorMsg ? <span className="error_msg">{departmentNameErrorMsg}</span> : null}
                {<span className="res_error_msg">{editresMsg ? editresMsg : ''}</span>}
              </div>



              <div className={`form-group ${departmentIconErrorMsg ? 'input_error' : ''}`}>
                <label className="mb-3 cm_bold">Department Icon</label>



                <Dropzone onDrop={acceptedFile => this.handleEditFileChange(acceptedFile)}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <section>
                      <div className="upload_doc  upload_doc_1" {...getRootProps()}
                        style={{ background: `url(${previousDepartmentIconPreview})` }}>
                        <input {...getInputProps()} accept=".png, .jpg, .jpeg" />

                        {
                          previousDepartmentIconPreview ? "" : <p><span className="blue">Upload Icon</span></p>
                        }

                      </div>
                    </section>
                  )}
                </Dropzone>
                {departmentIconErrorMsg ?
                  <span className="error_msg">
                    {departmentIconErrorMsg}</span>
                  : null}
              </div>



              {miniLoader ? <div className="mini_loader_dept"></div> : ''}
              <div className="submit_button_margin mt-4 ml-5">
                <button className="btn btn-primary   cm_btn3">Save</button>
                <button type="button" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>

              </div>

            </form>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          show={openDeleteDepartmentPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="submit_button_margin mt-4 confirmation">
            <p className="mb-4 cm_bold cnfrm_msg">Are you sure, you want to delete?</p>
            {miniLoader ? <div className="mini_loader_dept"></div> : ""}

            <button className="btn btn-danger  cm_btn3" onClick={this.handleDelete}>Delete</button>
            <button type="button" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>


          </Modal.Body>
        </Modal>
      </div>

    );
  }
}
export default AdminDepartment;

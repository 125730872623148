import React, { useState } from "react";
import { Link } from "react-router-dom";
import { headingAddCompOff } from "../helper/String";
import {
  LeaveInfo,
  chartValue,
  filterLeaveMonthLi,
  filterLeaveQuarterLi,
  filterLeaveYearLi,
} from "./Constants";
import { handleIsoDate, type } from "../helper/Constant";
import DeductLeaveModal from "./leaveModal/DeductLeaveModal";
import moment from "moment";

function LeaveStatus(props) {
  let {
    cancel,
    selectLeave,
    isAdmin,
    totalCl,
    totalCompOffLeaves,
    totalEl,
    totalLWPLeaves,
    totalOtherLeaves,
    totalSl,
    totalWFHLeaves,
    searchYear,
    searchQuatName,
    searchMonth,
    currentYear,
    handleOpenModal,
    dispatch,
    shortLeave,
    assignedCl,
    assignedEl,
    assignedSl,
    tempCompOff,
    totalComp,
    id,
    deductLeaveType,
    endDate1,
    startDate1,
    shortDayFrom,
    shortDayTo,
    halfOfTheDay,
    editorState,
    leaveType1ErrorMsg,
    halfOfTheDayErrMsg,
    start1dateErrorMsg,
    enddate1ErrorMsg,
    shortDayFromErrorMsg,
    shortDayToErrorMsg,
    replyErrorMsg,
    responseMessage,
    handleChange,
    employeeList,
    onEditorStateChange,
    disabled,
    responseAction,
    handleSelectHalfChange,
    onStartChange,
    onEndChange,
    handleCheckInChange,
    handleCheckOutChange,
    openDeductLeavePopup,
    handleLeaveDeductSubmit,
    closeModal,
    totalClientOff,
    bereavementLeaves,
    paternalLeave,
    assignedBereavementLeaves,
    assignedPaternalLeave,
  } = props;
  const [openLeaveDetail, setOpenLeaveDetail] = useState(false);
  const temporaryCompOff = tempCompOff
    ? tempCompOff
        .filter((record) => record.expireDate >= handleIsoDate(new Date()))
        .map((record) => record.numberOFCompOff)
        .reduce((acc, curr) => acc + curr, 0)
    : 0;

  const updateMonth = (i) => {
    dispatch({
      type: type,
      payload: {
        searchMonth: i,
      },
    });
  };
  const updateYear = (i) => {
    dispatch({
      type: type,
      payload: {
        searchYear: i,
      },
    });
  };
  const updateQuarter = (i) => {
    dispatch({
      type: type,
      payload: {
        searchQuatName: i,
      },
    });
  };

  const openLeave = () => {
    setOpenLeaveDetail(true);
  };

  const handleDeductLeave = () => {
    dispatch({
      type: type,
      payload: {
        openDeductLeavePopup: true,
      },
    });
  };

  const handleDeductleaveTypeChange = (e) => {
    dispatch({
      type: type,
      payload: {
        deductLeaveType: parseInt(e),
        leaveType1ErrorMsg: "",
      },
    });
  };

  const handlediff = (startDate, endDate) => {
    let sd1 = moment(startDate).format("YYYY-MM-DD");
    let sd2 = moment(endDate).format("YYYY-MM-DD");
    var date1 = new Date(sd1);
    var date2 = new Date(sd2);
    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  return (
    <>
      {!isAdmin && (
        <div className="cm_breadcrumb cm_bold">
          <ul>
            <li>
              <Link onClick={cancel}>LEAVES RECORDS</Link>
              <div className="cm_breadcrumb_arrow"></div>
            </li>
            <li>{chartValue(selectLeave)}</li>
          </ul>
        </div>
      )}
      <div style={{ marginTop: "5rem" }}>
        <div className="col-md-9 col-sm-12 cm_w_100">
          {selectLeave == "1" ? filterLeaveYearLi(searchYear, updateYear) : ""}
          {selectLeave == "2"
            ? filterLeaveQuarterLi(searchQuatName, updateQuarter)
            : ""}
          {selectLeave == "3"
            ? filterLeaveMonthLi(searchMonth, updateMonth)
            : ""}
        </div>
        <div></div>
      </div>
      {isAdmin && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            {currentYear === parseInt(searchYear) ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm comp_btn"
                  onClick={(e) =>
                    handleOpenModal(2, "CompLeave", headingAddCompOff)
                  }
                >
                  Add Compensatory Off
                </button>
                <button
                  className="btn btn-sm deduct-button"
                  onClick={handleDeductLeave}
                >
                  Deduct Leave
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      <div className="row leave_cm_card card_mt">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card1 cm_cardDashbord">
                <p className="skyblue">Casual Leaves</p>
                <span className="card_leave_no">
                  <span>{totalCl ? totalCl : 0}</span>
                  {chartValue(selectLeave) === "YEARLY RECORDS"
                    ? "/" + assignedCl
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card2 cm_cardDashbord">
                <p className="purple">Earned Leaves</p>
                <span className="card_leave_no">
                  <span> {totalEl ? totalEl : 0}</span>
                  {chartValue(selectLeave) === "YEARLY RECORDS"
                    ? "/" + assignedEl
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card3 cm_cardDashbord">
                <p className="blue">Sick Leaves</p>
                <span className="card_leave_no">
                  <span>{totalSl ? totalSl : 0}</span>
                  {chartValue(selectLeave) === "YEARLY RECORDS"
                    ? "/" + assignedSl
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card4 cm_cardDashbord">
                <p className="d-flex justify-content-end compOff-admin">
                  <i
                    class="fa fa-info-circle fa-lg"
                    aria-hidden="true"
                    onMouseEnter={openLeave}
                    onMouseOut={() => setOpenLeaveDetail(false)}
                  ></i>
                </p>
                <p className="yellow">Compensatory Off</p>
                <span className="card_leave_no">
                  {isNaN(totalCompOffLeaves) ? 0 : totalCompOffLeaves}/
                  {totalComp && totalComp > 0 ? totalComp : 0}/
                  {temporaryCompOff}
                </span>
                {
                  <LeaveInfo
                    openLeaveDetail={openLeaveDetail}
                    index={7}
                    leaveTitle={"Comp Off"}
                  />
                }
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card5 cm_cardDashbord">
                <p className="red">LWP</p>
                <span className="card_leave_no">
                  {totalLWPLeaves ? totalLWPLeaves : 0}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card7 cm_cardDashbord">
                <p className="grey">Work From Home</p>
                <span className="card_leave_no">
                  {totalWFHLeaves ? totalWFHLeaves : 0}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card6 cm_cardDashbord">
                <p className="black">Other's Leaves</p>
                <span className="card_leave_no">
                  {totalOtherLeaves ? totalOtherLeaves : 0}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card11 cm_cardDashbord">
                <p className="brown">Short Leave</p>
                <span className="card_leave_no">
                  {shortLeave ? shortLeave : 0}{" "}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card16 cm_cardDashbord">
                <p className="purple">Client-side Off</p>
                <span className="card_leave_no">
                  {totalClientOff ? totalClientOff : 0}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card17 cm_cardDashbord">
                <p className="green">Parenting Leave</p>
                <span className="card_leave_no">
                  <span> {paternalLeave ? paternalLeave : 0}</span>
                  {chartValue(selectLeave) === "YEARLY RECORDS"
                    ? "/" + assignedPaternalLeave
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="cm_card cm_pr cm_bold card18 cm_cardDashbord">
                <p className="violet">Bereavement Leave</p>
                <span className="card_leave_no">
                  <span> {bereavementLeaves ? bereavementLeaves : 0}</span>
                  {chartValue(selectLeave) === "YEARLY RECORDS"
                    ? "/" + assignedBereavementLeaves
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeductLeaveModal
        disabled={disabled}
        show={openDeductLeavePopup}
        handleClose={closeModal}
        employeeId={id}
        employeeList={employeeList}
        deductLeaveType={deductLeaveType}
        handleDeductleaveTypeChange={handleDeductleaveTypeChange}
        handlediff={handlediff}
        startDate={startDate1}
        endDate={endDate1}
        leaveType1ErrorMsg={leaveType1ErrorMsg}
        halfOfTheDayErrMsg={halfOfTheDayErrMsg}
        start1dateErrorMsg={start1dateErrorMsg}
        enddate1ErrorMsg={enddate1ErrorMsg}
        shortDayFromErrorMsg={shortDayFromErrorMsg}
        shortDayToErrorMsg={shortDayToErrorMsg}
        replyErrorMsg={replyErrorMsg}
        handleChange={handleChange}
        handleLeaveDeductSubmit={handleLeaveDeductSubmit}
        responseMessage={responseMessage}
        handleSelectHalfChange={handleSelectHalfChange}
        onStartChange={onStartChange}
        onEndChange={onEndChange}
        handleCheckInChange={handleCheckInChange}
        handleCheckOutChange={handleCheckOutChange}
        shortDayFrom={shortDayFrom}
        shortDayTo={shortDayTo}
        halfOfTheDay={halfOfTheDay}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </>
  );
}

export default LeaveStatus;

import { applyMiddleware,createStore } from "redux";

import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import logger from "redux-logger";
import rootReducer from "./reducers";

const store = createStore(rootReducer,applyMiddleware(thunk));
const persistor = persistStore(store);

export {store,persistor};
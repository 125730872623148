import {
  GET_LEAVE_LIST_BY_ADMIN_REQUEST,
  GET_LEAVE_LIST_BY_ADMIN_SUCCESS,
  GET_LEAVE_LIST_BY_ADMIN_FAILURE,
} from "./actionType";

import ResponseFilter from "../../ResponseFilter";
import { STATUS_ZERO } from "../../components/attendance/Constants";
import { getLeaveList } from "../../config/services/employeeServices";

const getLeaveListByAdmin = (props) => {
  let { useDispatch, params, dataCount, tabStatus } = props
  return (dispatch) => {
    dispatch(getLeaveListByAdminRequest());
    getLeaveList(params).then(res => {
      useDispatch({
        type: 'typeCheckSet', payload: {
          loader: false
        }
      })
      let { status, resData } = ResponseFilter(res);
      let statusCount = resData.statusCount
      if (status) {
        useDispatch({
          type: 'typeCheckSet', payload: {
            leaveList: resData.leaveList,
            totalLeave: resData.totalLeaves,
            all: resData.dataCount ? resData.dataCount : 0,
            lateCount: resData.lateCount,
            dataCount: tabStatus == STATUS_ZERO || tabStatus == '' || tabStatus == undefined ? resData?.dataCount : tabStatus == 4 ? resData.lateCount : resData?.paginationCount,
            pending: statusCount.filter(data => data._id === 1).map(data => data.totalCountByStatus),
            approved: statusCount.filter(data => data._id === 2)?.map(data => data.totalCountByStatus),
            rejected: statusCount.filter(data => data._id === 3)?.map(data => data.totalCountByStatus),
          }
        })
      }
      else {
        useDispatch({
          type: 'typeCheckSet', payload: {
            resMsgFail: true,
            responseMessage: res.data.error.responseMessage,
          }
        })
      }
    })
      .catch((error) => {
        dispatch(getLeaveListByAdminFailure(error));
      });
  };
};

const getLeaveListByAdminRequest = () => {
  return {
    type: GET_LEAVE_LIST_BY_ADMIN_REQUEST,
  };
};

const getLeaveListByAdminSuccess = (data) => {
  return {
    type: GET_LEAVE_LIST_BY_ADMIN_SUCCESS,
    payload: data,
  };
};

const getLeaveListByAdminFailure = (error) => {
  return {
    type: GET_LEAVE_LIST_BY_ADMIN_FAILURE,
    payload: error,
  };
};
export {
  getLeaveListByAdmin,
  getLeaveListByAdminRequest,
  getLeaveListByAdminSuccess,
  getLeaveListByAdminFailure,
};

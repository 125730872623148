import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../svg';
import Modal from 'react-bootstrap/Modal';
import Pagination from "react-js-pagination";
import ResponseFilter from '../../ResponseFilter';
import _, { isArray } from 'lodash';
import { addNewStandup, editNewStandup, getProjectByManager, editProjectByManager, getEmployeeListByManager, deleteProjectByManager, dailyWorkByProjectId, getProjectLeave, getProjectExcelSheet, getProjectMembersProjectDuration } from '../../config/services/employeeServices';
import { DatePicker, Select, Modal as AntModal, TimePicker } from 'antd';
import moment from 'moment';
import { format, leaveValue } from '../leaves/Constants';
import { CONSTANTS } from '../../config';
import { Toastify, dateFormat, statusMap} from '../helper/Constant';
import { projectTypeValue } from '../../config/constants/Constants';
import DownloadEmpDataModal from "./ProjectModal/DownloadEmpDataModal";
import { handleDownloadValidation } from "./ProjectUtils/Constants";
import { getDataForTemplates } from "../../config/services/employeeServices";
import DOMPurify from 'dompurify';
import ViewTemplateModal from "./ViewTemplateModal";
const { Option } = Select;
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS
let technologyList = CONSTANTS.INTERVIEW_SECTION.TECHNICAL_SKILL
const { RangePicker } = DatePicker
function disabledDate(current) {

    return current && current > moment().endOf('day');
}
class ManagerViewProject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewProject: [],
            openEditLogPopup: false,
            openAddNewStandupPopup: false,
            openEditProjectPopup: false,
            openAddParticipentPopup: false,
            openCompleteProjectPopup: false,
            openDeleteProjectPopup: false,
            projectDetails: '',
            members: [],
            category: [],
            projectCategoryErrorMessage: "",
            errorrMsg: '',
            assignedTo: [],
            projectName: '',
            mailId1: '',
            clientName1: '',
            projectName1: '',
            projectManager1: '',
            accountManagerFirstName: '',
            accountManagerLastName: '',
            category1: [],
            endDate1: '',
            startDate1: '',
            projectDescription1: '',
            startDate: '',
            endDate: '',
            clientName: '',
            projectManager: '',
            mailId: '',
            projectDescription: '',
            projectNameErMsg: '',
            dateErMsg: '',
            endDateErMsg: '',
            projectManagerNameErMsg: '',
            blocker: '',
            hour: '',
            employeeList: [],
            dailyWorkByProjectId: [],
            membersDetails: [],
            status: 1,
            loader: false,
            projectManagerId: '',
            accountManagerId: "",
            dataCount: '',
            sortType: 2,
            sortField: "created",
            pageNo: 1,
            count: 10,
            hourErrorMsg: '',
            taskErrorMsg: '',
            dateErrorMsg: '',
            clientNameErrorMessage: "",
            clientMailErrorMeaasge: "",


            startTime: 0,
            startTimeErrorMsg: "",

            endTime: 0,
            endTimeErrorMsg: "",
            tabStatus: '',
            projectMemberLeave: [],
            memberLeaveCount: '',
            filterEmployee: "",
            startDateStamp: "",
            endDateStamp: "",
            startDateRange: "",
            endDateRange: "",
            sortField: "created",
            sortType: SORT_TYPE_CONSTANTS.Descending,
            projectHours: 0,
            projectHoursErMsg: "",
            resMsg: false,
            successMessage: "",
            responseMessage: false,
            employeeTotalHours: 0,
            projectType: 0,
            technology: [],
            technologyErrorMessage: "",
            exMembersDetails: [],
            exProjectMembers: [],
            exProjectMembersDataCount: 0,
            search: "",
            openDownloadEmpData: false,
            modalLoader: false,
            viewModal: false,
        }
        this.debounceSearch = null
    }


    catValue = type => {
        switch (type) {
            case 1:
                return 'Web';
                break;
            case 2:
                return 'Android';
                break;
            case 3:
                return 'iOS';
                break;
            default:
                return 'all';

        }
    }
    handleOpenEditLogPopup = ({ task, date, hour, blocker, dailyWorkId, projectId, startTime, endTime }) => this.setState({ openEditLogPopup: !this.state.openEditLogPopup, task, date, hour, blocker, dailyWorkId, projectId, startTime, endTime });

    getViewProject = () => {

        let params = this.props.match.params.id;
        this.setState({ loader: true })
        this.props.history.push(`?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`)

        getProjectByManager(params).then(res => {
            //if (res.data.statusCode == 1) {
            let { status, resData } = ResponseFilter(res);
            this.setState({ loader: false })
            if (status) {
                let {
                    projectId,
                    projectName,
                    category,
                    startDate,
                    endDate,
                    clientName,
                    projectManager,
                    mailId,
                    projectDescription,
                    blocker,
                    hour, status, projectManagerId, accountManagerId, accountManagerFirst, accountManagerLast,
                    projectHours, projectType, technology
                } = resData.projectDetails;

                let LineUserDataSet = [];
                // let assignedTo = [];

                let userline = [];

                let membersDetails = resData.membersDetails;
                for (var i = 0; i < membersDetails.length; i++) {
                    userline.push(membersDetails[i].employeeId);
                }
                this.setState({ assignedTo: userline })

                let categories = []
                categories = isArray(category) ? category : categories.push(category)
                this.setState({
                    projectId,
                    mailId1: mailId,
                    clientName1: clientName,
                    projectManager1: projectManager,
                    accountManagerFirstName: accountManagerFirst,
                    accountManagerLastName: accountManagerLast,
                    endDate1: endDate,
                    projectName1: projectName,
                    category1: categories,
                    startDate1: startDate,
                    projectDescription1: projectDescription,
                    projectName,
                    category: categories,
                    startDate,
                    endDate,
                    clientName,
                    projectManager,
                    mailId,
                    projectManagerId,
                    accountManagerId,
                    projectDescription,
                    blocker,
                    hour,
                    status,
                    projectHours,
                    projectType, technology,
                    membersDetails: resData.membersDetails,
                    exMembersDetails: resData?.exMembersDetails
                })
            }
            else {
                this.setState({ loader: false })
            }
            //}
        })
    }

    closeModal = () => {
        this.setState({
            openEditProjectPopup: false,
            openAddParticipentPopup: false,
            openCompleteProjectPopup: false,
            openDeleteProjectPopup: false,
            errorrMsg: '',
            projectManagerNameErMsg: '',
            projectNameErMsg: '',
            dateErMsg: '',
            endDateErMsg: '',
            openAddNewStandupPopup: false,
            openEditLogPopup: false,
            hourErrorMsg: '',
            taskErrorMsg: '',
            dateErrorMsg: '',
            task: '',
            date: '',
            hour: '',
            blocker: '',
            clientMailErrorMeaasge: '',
            clientNameErrorMessage: "",
            projectManagerId: "",
            startTime: 0,
            endTime: 0,
            startTimeErrorMsg: "",
            endTimeErrorMsg: "",
            projectManagerId: "",
            accountManagerId: "",
            projectName: "",
            mailId: "",
            clientName: "",
            projectDescription: "",
            assignedTo: [],
            category: [],
            projectCategoryErrorMessage: "",
            startDate: "",
            endDate: "",
            projectHours: 0,
            projectType: 0,
            category1: [],
            technology: [],
            technologyErrorMessage: "",
            openDownloadEmpData: false,
            modalLoader: false,
            viewModal: false,
        }, () => this.getViewProject());
    }
    handleChangeAssignedToChange = e => {

        this.setState({ projectManagerId: e })
    }

    handleChangeAccountManager = e => {

        this.setState({ accountManagerId: e })
    }

    // handleChange = e => this.setState({
    //     [e.target.name]: e.target.value
    // });

    handleSelectChange = e => this.setState({ category: e, projectCategoryErrorMessage: "" });

    handleOpenEditProjectPopup = () => this.setState({ openEditProjectPopup: !this.state.openEditProjectPopup });

    handleOpenCompleteProjectPopup = () => this.setState({ openCompleteProjectPopup: !this.state.openCompleteProjectPopup });

    handleMembersChange = e => this.setState({ assignedTo: e })

    handleOpenAddParticipentPopup = ({ employeesList }) => this.setState({ openAddParticipentPopup: !this.state.openAddParticipentPopup, employeesList });

    handleOpenDeleteProjectPopup = ({ projectId }) => this.setState({ openDeleteProjectPopup: !this.state.openDeleteProjectPopup, projectId });

    onChangeDate = (date, dateString) => {
        this.setState({
            startDate: date[0],
            endDate: date[1]
        })
    }



    handleEditSubmit = event => {
        event.preventDefault();
        if (this.handleValidation()) {


            let obj = {
                'projectId': this.props.match.params.id,
                'projectName': this.state.projectName,
                'category': this.state.category,
                'assignedTo': this.state.assignedTo == "" ? null : this.state.assignedTo.toString(),
                'startDate': new Date(this.state.startDate).setHours(0, 0, 0, 0),
                'endDate': new Date(this.state.endDate).setHours(0, 0, 0, 0),
                'clientName': this.state.clientName,
                'projectManager': this.state.projectManagerId,
                'accountManager': this.state.accountManagerId ? this.state.accountManagerId : this.state.projectManagerId,
                'mailId': this.state.mailId,
                'projectDescription': this.state.projectDescription,
                'projectHours': this.state.projectHours,
                'projectType': parseInt(this.state.projectType),
                'technology': this.state.technology


            }

            editProjectByManager(obj).then(res => {
                if (res.data.statusCode) {
                    this.setState({
                        resMsg: true,
                        successMessage: "Project Edited"
                    }, () => this.getViewProject(), this.closeModal())

                }

                else {
                    this.setState({
                        responseMessage: true,
                        errorrMsg: res.data.error.responseMessage
                    })

                }

                setTimeout(() => {
                    this.setState({
                        resMsg: false,
                        responseMessage: false,
                    })
                }, 4000)
            })
        }
    }

    getEmployeeListByManager = () => {

        getEmployeeListByManager('').then(res => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({
                    projectManager: res.data.responseData.employeesList.employeeId,
                    employeeList: res.data.responseData.employeesList
                },)
            }
        })
    }


    handleComplete = () => {
        let params = {
            'projectId': this.props.match.params.id,
            'status': 2
        }

        editProjectByManager(params)
            .then(res => {
                this.setState({ miniLoader: false }, () => {
                    window.location.href = '/projects/manager-projects';
                })
            })
    }


    handleDelete = () => {
        let obj = {
            data: {
                projectId: this.props.match.params.id,
                isDeleted: 2
            }
        }
        this.setState({ miniLoader: true })
        deleteProjectByManager(obj).then((res) => {
            if (res.data.statusCode === 1) {

                this.setState({ resMsg: true, miniLoader: false, successMessage: "Project Deleted" })
            }
            else {
                this.setState({ resFailMsg: true, miniLoader: false })
                this.setState({ errorrMsg: res.data.error.responseMessage })


            }
            setTimeout(() => window.location.href = `/projects/manager-projects`, 2000)
            setTimeout(() => this.setState({ resMsg: false, responseMessage: false }), 3000);
        });
    }
    handlePageChange = (pageNo) => {
        this.setState({ pageNo: pageNo }, () => {
            this.getActivityLogs()
            this.getViewProject()
            this.getProjectMemberLeave()
            this.getExProjectMambers()
        });
    }
    getActivityLogs = () => {

        let { sortType, sortField, pageNo, count, startDateRange, endDateRange } = this.state
        let params = `count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}&projectId=${this.props.match.params.id}&startDate=${startDateRange}&endDate=${endDateRange}&employeeId=${this.state.filterEmployee}`;
        this.props.history.push(`?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`)
        dailyWorkByProjectId(params).then(res => {
            //if (res.data.statusCode == 1) {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                let {
                    projectId
                } = resData.dailyWorkDetails
                this.setState({
                    projectId: projectId,
                    dailyWorkDetails: resData.dailyWorkDetails,
                    dataCount: resData.dataCount,
                    employeeTotalHours: resData?.totalHours,

                })
                //}
            }
        })
    }


    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }
    disabledStartDate = startDate => {
        const { endDate } = this.state;
        if (!startDate || !endDate) {
            return false;
        }
        return startDate.valueOf() > endDate.valueOf();
    };

    disabledEndDate = endDate => {
        const { startDate } = this.state;
        if (!endDate || !startDate) {
            return false;
        }
        return endDate.valueOf() <= startDate.valueOf();
    };

    onChangeDate = (field, value) => {
        this.setState({
            [field]: value,
        });
    };
    onStartChange = value => {
        this.onChangeDate('startDate', value);
    };

    onEndChange = value => {
        this.onChangeDate('endDate', value);
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };
    handleChange = e => this.setState({
        [e.target.name]: /^\s/.test(e.target.value) ? "" : e.target.value
    });

    handleOpenAddNewStandupPopup = () => this.setState({ openAddNewStandupPopup: !this.state.openAddNewStandupPopup });

    handleSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidetion()) {

            let obj = {
                'projectId': this.props.match.params.id,
                'task': this.state.task,
                'blocker': this.state.blocker,
                'date': this.state.date,
                'startTime': this.state.startTime,
                'endTime': this.state.endTime,
                'hour': this.state.startTime && this.state.endTime ? (this.state.endTime - this.state.startTime) + 3600 : ''

            }
            addNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getActivityLogs())
                    self.closeModal();

                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        loader: false,

                    })
                    // self.closeModal();
                }
            })
        }
    }

    handleEditSubmitLog = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidetion()) {

            let obj = {
                'dailyWorkId': this.state.dailyWorkId,
                'projectId': this.props.match.params.id,
                'task': this.state.task,
                'blocker': this.state.blocker,
                'date': this.state.date,
                'hour': this.state.startTime && this.state.endTime ? (this.state.endTime - this.state.startTime) + 3600 : '',
                'startTime': this.state.startTime,
                'endTime': this.state.endTime

            }
            editNewStandup(obj).then(res => {
                if (res.data.statusCode === 1) {

                    self.setState({}, () => self.getActivityLogs())
                    self.closeModal();

                }
                else if (res.data.statusCode === 0) {
                    self.setState({
                        loader: false,

                    })
                    // self.closeModal();
                }
            })
        }
    }

    handleValidetion = () => {
        let validate = true;
        const { task, date, hour, startTime, endTime } = this.state;

        if (task === '' || task === undefined) {
            validate = false;
            this.setState({ taskErrorMsg: 'Task is required' })
        }
        else {
            this.setState({ taskErrorMsg: '' })
        }

        if (date === '' || date === undefined) {
            validate = false;
            this.setState({ dateErrorMsg: 'Date is required' })
        }
        else {
            this.setState({ dateErrorMsg: '' })
        }
        // if (hour === '' || hour === undefined) {
        //     validate = false;
        //     this.setState({ hourErrorMsg: 'Hours is required' })
        // }
        // else {
        //     this.setState({ hourErrorMsg: '' })
        // }


        if (startTime == null || startTime == 0 | startTime == undefined || startTime == "") {
            validate = false
            this.setState({ startTimeErrorMsg: "Start time is required" })
        }
        else {
            this.setState({ startTimeErrorMsg: "" })

        }

        if (endTime == null || endTime == 0 | endTime == undefined || endTime == "") {
            validate = false
            this.setState({ endTimeErrorMsg: "end time is required" })
        }
        else if (endTime > endTime) {
            this.setState({ endTimeErrorMsg: "End tims should be greater then end time" })

        }
        else {
            this.setState({ endTimeErrorMsg: "" })

        }
        return validate;
    }
    handleDateChange = (date, dateString) => this.setState({ date: moment(date).toDate().getTime(), dateErrorMsg: '' });

    handleValidation = () => {
        let validate = true;
        const { projectName, startDate, endDate, projectManagerId, clientName, mailId, projectHours, technology, category } = this.state


        if (projectName === '' || projectName === undefined) {
            validate = false;
            this.setState({
                projectNameErMsg: 'Project name is required'
            })
        } else {
            this.setState({
                projectNameErMsg: ''
            })
        }
        if (startDate === '' || startDate === undefined) {
            validate = false;
            this.setState({
                dateErMsg: 'Start date and end date is required'
            })
        } else {
            this.setState({
                dateErMsg: ''
            })
        }
        if (endDate === '' || endDate === undefined) {
            validate = false;
            this.setState({
                endDateErMsg: 'Start date and end date is required'
            })
        } else {
            this.setState({
                endDateErMsg: ''
            })
        }

        if (projectHours) {
            if (isNaN(projectHours)) {
                validate = false
                this.setState({
                    projectHoursErMsg: "Please Enter Valid Total Hours"
                })
            }
            else if (projectHours < 1) {
                validate = false
                this.setState({
                    projectHoursErMsg: "Please Enter Hours Greater then 0"
                })
            }
            else {
                this.setState({
                    projectHoursErMsg: ""
                })
            }
        }

        if (category === "" || category === undefined || category.length <= 0) {
            validate = false;
            this.setState({
                projectCategoryErrorMessage: "Please Select Project Category",
            });
        } else {
            this.setState({
                projectCategoryErrorMessage: "",
            });
        }
        // if (clientName === "" || clientName === undefined) {
        //     validate = false;
        //     this.setState({
        //         clientNameErrorMessage: "Client Name is required",
        //     });
        // } else {
        //     this.setState({
        //         clientNameErrorMessage: "",
        //     });
        // }
        // if (mailId === "" || mailId === undefined) {
        //     validate = false;
        //     this.setState({
        //         clientMailErrorMeaasge: "Client Mail-Id is required",
        //     });
        // } else
        if (mailId) {
            if (!(/^(?!.{41})[a-z0-9]+(?:\.[a-z0-9]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-zA-Z]{2,6}$/.test(mailId))) {
                validate = false;
                this.setState({
                    clientMailErrorMeaasge: "Client Mail-Id should be Valid",
                });
            } else {
                this.setState({
                    clientMailErrorMeaasge: "",
                });
            }
        }
        if (projectManagerId === '' || projectManagerId === undefined) {
            validate = false;
            this.setState({
                projectManagerNameErMsg: 'Project manager is required'
            })
        } else {
            this.setState({
                projectManagerNameErMsg: ''
            })
        }

        if (technology == [] || technology === undefined || technology.length <= 0) {
            validate = false
            this.setState({
                technologyErrorMessage: "Please Select Technology"
            })
        }
        else {
            this.setState({
                technologyErrorMessage: ""
            })
        }
        return validate
    }
    handleEmployeeSearch = (e) => {
        console.log("e : ", e);
    }

    handleCheckInChange = (checkIn, checkInString) => {
        this.setState({

            startTime: moment.duration(checkInString).asSeconds(),
            startTimeErrorMsg: "",
        })

    }

    handleCheckOutChange = (checkOut, checkOutString) => {
        this.setState({

            endTime: moment.duration(checkOutString).asSeconds(),
            endTimeErrorMsg: "",
        })
    }

    handleProjectType = (e) => {
        this.setState({
            projectType: e
        })
    }
    handleFilter = (e) => {
        this.setState({ loader: true, tabStatus: e == 0 ? "" : parseInt(e), pageNo: 1, sortType: "created", sortType: 2 }, () => { this.getViewProject(); this.getActivityLogs(); this.getProjectMemberLeave(); this.getExProjectMambers() })

    }
    getProjectMemberLeave = () => {
        let params = `${this.props.match.params.id}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&count=${this.state.count}&pageNo=${this.state.pageNo - 1}`;
        this.props.history.push(`?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`)
        this.setState({ loader: true });
        getProjectLeave(params).then((res) => {
            if (res.data.statusCode == 1) {
                let { status, resData } = ResponseFilter(res);
                this.setState({ loader: false });
                if (status) {
                    this.setState({
                        loader: false,
                        projectMemberLeave: resData?.leaveList?.result,
                        memberLeaveCount: resData.dataCount,
                    })
                } else {
                    this.setState({ loader: false });
                }
            }
        });
    }


    getExProjectMambers = () => {
        let { pageNo } = this.state
        let params = `projectId=${this.props.match.params.id}&sortType=${this.state.sortType}&sortField=${this.state.sortField == "created" ? "startDate" : this.state.sortField}&pageNo=${this.state.pageNo - 1}&count=${this.state.count}&search=${this.state.search.trim()}`;
        this.props.history.push(`?tabStatus=${this.state.tabStatus}&startDateStamp=${this.state.startDateStamp}&endDateStamp=${this.state.endDateStamp}&startDateRange=${this.state.startDateRange}&endDateRange=${this.state.endDateRange}&filterEmployee=${this.state.filterEmployee}&sortType=${this.state.sortType}&sortField=${this.state.sortField}&pageNo=${this.state.pageNo}`)
        this.setState({ loader: true, exProjectMembers: [] });
        getProjectMembersProjectDuration(params).then((res) => {
            if (res.data.statusCode == 1) {
                let { status, resData } = ResponseFilter(res);
                this.setState({ loader: false });
                if (status) {
                    this.setState({
                        loader: false,
                        exProjectMembers: resData?.durationByMember,
                        exProjectMembersDataCount: resData.dataCount
                    })
                } else {
                    this.setState({ loader: false });
                }
            }
        });
    }


    componentDidMount() {

        let url_string = window.location.href
        let url = new URL(url_string);
        let pageNo = url.searchParams.get("pageNo");
        let tabStatus = url.searchParams.get("tabStatus");
        let search = url.searchParams.get("search");
        let sortType = url.searchParams.get("sortType");
        let sortField = url.searchParams.get("sortField");
        let startDateRange = url.searchParams.get("startDateRange")
        let endDateRange = url.searchParams.get("endDateRange")
        let startDateStamp = url.searchParams.get("startDateStamp")
        let endDateStamp = url.searchParams.get("endDateStamp")
        let filterEmployee = url.searchParams.get("filterEmployee")
        const format = "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ";
        let data = sessionStorage.getItem("activityData")
        this.setState({
            pageNo: pageNo ? +(pageNo) : 1,
            search: search ? search : "",
            sortType: sortType ? sortType : SORT_TYPE_CONSTANTS.Descending,
            sortField: sortField ? sortField : "created",
            startDateStamp: startDateStamp ? moment(startDateStamp, format) : "",
            endDateStamp: endDateStamp ? moment(endDateStamp, format) : "",
            startDateRange: startDateRange ? startDateRange : "",
            endDateRange: endDateRange ? endDateRange : "",
            tabStatus: tabStatus ? tabStatus : this.state.tabStatus,
            filterEmployee: filterEmployee ? filterEmployee : ""

        }, () => {

            this.getViewProject();
            this.getEmployeeListByManager();
            this.getProjectMemberLeave()
            this.getExProjectMambers()
            if (data) {
                let activityData = JSON.parse(data)
                this.setState({
                  projectId: activityData?.projectId,
                  dailyWorkDetails: activityData?.dailyWorkDetails,
                  dataCount: activityData?.dataCount,
                  employeeTotalHours: activityData?.employeeTotalHours,
                  loader: activityData?.false,
                })
                sessionStorage.removeItem("activityData")
              }
              else {
                this.getActivityLogs();
              }
        })
    }

    handleSort = e => {
        this.setState({ loader: false, sortField: e, sortType: this.state.sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending }, () => this.getActivityLogs(), this.getViewProject(), this.getProjectMemberLeave(), this.getExProjectMambers());
    }

    handleSearch = e => {
        this.setState({ search: e.target.value, pageNo: 1, })
        clearTimeout(this.debouncedSearch);
        this.debounceSearch = setTimeout(() => {
            this.getExProjectMambers()
        }, 2000);
    }

    handleClearSearchChange = () => {
        this.setState({ search: '' }, () => this.getExProjectMambers())
    }
    handleRangePicker = (date) => {
        if (date.length === 0) {
            this.setState(
                {
                    pageNo: 1,
                    startDateStamp: '',
                    endDateStamp: '',
                    startDateRange: !_.isEmpty(date)
                        ? new Date(date[0]).setHours(6, 30, 0, 0)
                        : "",
                    endDateRange: !_.isEmpty(date)
                        ? new Date(date[1]).setHours(6, 30, 0, 0)
                        : "",
                    dateRange: false,
                },
                () => {
                    this.getActivityLogs();
                    this.getViewProject();
                }
            );
        } else {
            this.setState(
                {
                    pageNo: 1,
                    startDateStamp: date[0],
                    endDateStamp: date[1],
                    startDateRange: !_.isEmpty(date)
                        ? new Date(date[0]).setHours(6, 30, 0, 0)
                        : "",
                    endDateRange: !_.isEmpty(date)
                        ? new Date(date[1]).setHours(6, 30, 0, 0)
                        : "",
                    dateRange: true,
                },
                () => {
                    this.getActivityLogs();
                    this.getViewProject();

                }
            );
        }
    };

    handleTab = (e) => {
        if (e == undefined) {
            this.setState({ tabStatus: 1, filterEmployee: "", pageNo: 1 }, () => {
                this.getActivityLogs();
                this.getViewProject();
                this.getProjectMemberLeave()
                this.getExProjectMambers()
            })
        }
        else {
            this.setState({ tabStatus: 1, filterEmployee: e, pageNo: 1 }, () => {
                this.getActivityLogs();
                this.getViewProject();
                this.getProjectMemberLeave()
                this.getExProjectMambers()

            })
        }
    }

    exportExcel = () => {
        // let params = `projectId=${this.props.match.params.id}&employeeId=${this.state.filterEmployee}`
        // getProjectExcelSheet(params).then((res) => {
        //     let { status, resData } = ResponseFilter(res);
        //     if (status) {
        //         window.location.href = resData.transactionSheet
        //     }
        // })
        this.setState({ openDownloadEmpData: true });
    }


    millisecondsToHoursAndMinutes = (seconds) => {

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        const paddedHours = hours < 10 ? `0${hours}` : hours;
        const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${paddedHours}:${paddedMinutes}`;
    }

    handleActualHours = (membersDetails) => {
        const totalHoursArray = Object.values(membersDetails).map((employee) => employee.totalHours);

        const sumTotalHours = this.millisecondsToHoursAndMinutes(totalHoursArray.reduce((sum, hours) => sum + hours, 0));

        return sumTotalHours;

    }
    handleProjectHoursChange = (e) => {
        this.setState({
            projectHours: /^\s/.test(e.target.value) ? "" : e.target.value,
            projectHoursErMsg: ''
        })
    }

    handleTechnologyChange = (e) => {
        this.setState({
            technology: e,
            technologyErrorMessage: ""
        })
    }
    createMarkup = (props) => {
        return {
            __html: DOMPurify.sanitize(props)
        }
    }

    handleDownload = () => {
        const { downloadEmployee, month, year, selectTemplate, empName } =
          this.state;
        const projectId = this.props.match.params.id;
        this.setState({ modalLoader: true });
        if (
          handleDownloadValidation({
            state: this.state,
            setState: this.setState.bind(this),
          })
        ) {
          let employeeId = downloadEmployee.trim();
          const params = `projectId=${this.props.match.params.id}&employeeId=${employeeId}&searchMonth=${month}&searchYear=${year}`;
          getDataForTemplates(params).then((res) => {
            this.setState({ loader: false });
            const { status, resData } = ResponseFilter(res);
            if (status) {
              const data = resData.attendenceData.length && resData.attendenceData;
              const projectDesc = resData.projectDesc;
              const dailyWorks = resData.dailyWorks;
              const getStatus = (status) => {
                if (statusMap.present.includes(status)) return "present";
                if (statusMap.absent.includes(status)) return "absent";
                if (statusMap.holiday.includes(status)) return "holiday";
                if (statusMap.leave.includes(status)) return "leave";
                if (statusMap.halfLeave.includes(status)) return "halfLeave";
                return "unknown";
              };
    
              const attendanceData =
                resData.attendenceData.length > 0
                  ? resData.attendenceData.reduce((acc, entry) => {
                      const dateKey = `${entry.year}-${entry.month}-${entry.day}`;
                      acc[dateKey] = getStatus(entry.status);
                      return acc;
                    }, {})
                  : {};
    
              const holidays = resData.holidayList || [];
              const filteredHolidays = holidays.filter(
                (holiday) =>
                  holiday.year.toString() === year &&
                  holiday.month.toString() === month
              );
              filteredHolidays.forEach((holiday) => {
                const holidayDate = new Date(holiday.date);
                const year = holidayDate.getFullYear();
                const month = holidayDate.getMonth() + 1;
                const day = holidayDate.getDate();
                const dateKey = `${year}-${month}-${day}`;
                attendanceData[dateKey] = "holiday";
              });
              const params = {
                projectId: this.state.projectId,
                dailyWorkDetails: this.state.dailyWorkDetails,
                dataCount: this.state.dataCount,
                employeeTotalHours: this.state.employeeTotalHours,
                loader: false,
              }
              sessionStorage.setItem("activityData", JSON.stringify(params))
              this.props.history.push("/project/template", {
                attendanceData,
                selectTemplate,
                month,
                year,
                projectId,
                data,
                dailyWorks,
                projectDesc,
                empName,
              });
            }
          });
        }
      };
    
      handleMonth = (e) => {
        this.setState({ monthErr: "" });
        if (e === undefined) {
          this.setState({ month: "" });
        } else {
          this.setState({ month: e });
        }
      };
    
      handleYear = (e) => {
        this.setState({ yearErr: "" });
        if (e === undefined) {
          this.setState({ year: "" });
        } else {
          this.setState({ year: e });
        }
      };
    
      handleDownloadEmployee = async (e) => {
        this.setState({ downloadEmployeeErr: "" });
        if (e === undefined) {
          this.setState({ downloadEmployee: "" });
        } else {
          try {
            const res = await getEmployeeListByManager();
            const { status, resData } = ResponseFilter(res);
            if (status) {
              const employee = resData.employeesList.find(
                (employee) => employee.employeeId === e
              );
              if (employee) {
                const employeeName = `${employee.firstName} ${employee.lastName}`;
                this.setState({ empName: employeeName });
              } else {
                this.setState({ downloadEmployeeErr: "Employee not found" });
              }
            } else {
              this.setState({
                downloadEmployeeErr: "Failed to fetch employee list",
              });
            }
          } catch (error) {
            console.error("Error fetching employee list:", error);
            this.setState({ downloadEmployeeErr: "Error fetching employee list" });
          }
          this.setState({ downloadEmployee: e });
        }
      };
    
      handleSelectTemplate = (e) => {
        this.setState({ selectTemplateErr: "" });
        if (e === undefined) {
          this.setState({ selectTemplate: "" });
        } else {
          this.setState({ selectTemplate: e });
        }
      };
      handleViewModal = () => {
        this.setState({ viewModal: true })
      }
    render() {
        let {
            openEditLogPopup, taskErrorMsg, blockerErrorMsg, dateErrorMsg, hourErrorMsg,
            pageNo, dataCount, count,
            loader, openAddNewStandupPopup,
            membersDetails,
            members, status,
            endOpen,
            employeeList,
            openEditProjectPopup,
            openAddParticipentPopup,
            openCompleteProjectPopup,
            openDeleteProjectPopup,
            projectId,
            projectName,
            clientName1,
            projectManager1,
            accountManagerFirstName,
            accountManagerLastName,
            projectName1,
            mailId1,
            category1,
            endDate1,
            startDate1,
            projectDescription1,
            category,
            startDate,
            endDate,
            assignedTo,
            clientName,
            projectManager,
            mailId, errorrMsg,
            projectDescription,
            dateErMsg,
            projectNameErMsg,
            projectManagerNameErMsg,
            endDateErMsg,
            // dateFormat,
            dailyWorkDetails,
            blocker,
            modalLoader,
            hour, task, date,
            clientMailErrorMeaasge, clientNameErrorMessage,
            startTime, startTimeErrorMsg, endTime, endTimeErrorMsg,
            tabStatus, projectMemberLeave,
            openDownloadEmpData,
            projectManagerId, accountManagerId, startDateStamp, endDateStamp, filterEmployee, sortField, sortType, memberLeaveCount, projectHours, projectHoursErMsg, resMsg, successMessage, responseMessage, miniLoader, employeeTotalHours, projectType, technology, technologyErrorMessage, search, exMembersDetails, exProjectMembers, exProjectMembersDataCount, projectCategoryErrorMessage, viewModal } = this.state;
        return (

            <div className="body_container cm_bold proj_detail">
                {/* {loader && <Loader />} */}
                <Toastify
                    resMsg={resMsg}
                    text={successMessage}
                    resMsgFail={responseMessage}
                    responseMessage={errorrMsg}
                />
                <div className="cm_breadcrumb">

                    <ul>
                        <li><Link to='/projects/manager-projects'>PROJECT LIST</Link><div className="cm_breadcrumb_arrow"></div></li>
                        <li>{projectName1.toUpperCase()}</li>

                    </ul>
                </div>

                {tabStatus == 1 ?
                    <>
                        <div className=" row d-flex justify-content-end align-items-center">
                            <div className={`select_year_cal mr-2`}>
                                <Select
                                    showSearch
                                    name="employeeId"
                                    value={
                                        filterEmployee
                                            ? filterEmployee
                                            : "Select employee "
                                    }
                                    onSearch={this.handleEmployeeSearch}
                                    optionFilterProp="key"
                                    filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                                    onChange={this.handleTab}
                                    allowClear={filterEmployee ? true : false}
                                >
                                    {!_.isEmpty(membersDetails)
                                        ? membersDetails.map(
                                            (employee) => (
                                                <Option
                                                    key={`${employee.firstName} ${employee.lastName}`}
                                                    value={employee.employeeId}
                                                >
                                                    <span>{employee.firstName + " " + employee.lastName}</span>

                                                </Option>
                                            )
                                        )
                                        : ""}
                                </Select>
                            </div>
                            <div>
                                <RangePicker
                                    id='daterangepicker'
                                    onChange={this.handleRangePicker}
                                    disabledDate={this.disabledDate}

                                    className="rang select_year_cal rang_pick mt-2 mr-3 mb-2"
                                    value={[startDateStamp ? moment(startDateStamp) : "", endDateStamp ? moment(endDateStamp) : ""]}
                                />
                            </div>
                            <button className="btn btn-primary mr-4"
                                onClick={this.exportExcel}
                            >Download Employee Timesheet
                            </button>
                            <button className="btn btn-primary" onClick={this.handleViewModal}> <i
                                className="fa fa-eye mr-2"
                                aria-hidden="true"
                            ></i>Sample Template</button>
                        </div>

                    </> : ""}

                {tabStatus == 3 ?
                    <div className="row d-flex justify-content-end">
                        <div className="col-md-6 search_device_bar select__date">
                            <input className="form-control mr-lg-2 cm_box"
                                type="search"
                                maxLength={25}
                                placeholder="Search by name/E.Id"
                                aria-label="Search"
                                value={search}
                                onChange={this.handleSearch}
                            // onKeyDown={(e) => { if (e.key === 'Enter') this.getExProjectMambers() }}
                            />
                            {search ? <span className="cm_clear" onClick={this.handleClearSearchChange}>
                                <i className="fa fa-times-circle" aria-hidden="true"></i>
                            </span>
                                :
                                <span className="cm_clear">
                                    <i className="fa fa-search" aria-hidden="true" ></i>
                                </span>}
                        </div>
                    </div>
                    : ""}

                <div className="text-left cm_ticket_btn cm_leave_btn mt-4">
                    <button
                        className={`btn btn-primary ${tabStatus == '' ? "active" : ''
                            }`}
                        // style={{ width: 140 }}
                        onClick={() => this.handleFilter('')}
                    >
                        <span className="tabtext">General Info</span>
                    </button>
                    <button
                        className={`btn btn-primary ${tabStatus == 1 ? "active" : ''
                            }`}
                        // style={{ width: 140 }}
                        onClick={() => this.handleFilter(1)}
                    >
                        <span className="tabtext">Activity</span>
                    </button>
                    <button
                        className={`btn btn-primary ${tabStatus == 2 ? "active" : ''
                            }`}
                        // style={{ width: 140 }}
                        onClick={() => this.handleFilter(2)}
                    >
                        <span className="tabtext">Leave</span>
                    </button>
                    <button
                        className={`btn btn-primary ${tabStatus == 3 ? "active" : ''
                            }`}
                        // style={{ width: 140 }}
                        onClick={() => this.handleFilter(3)}
                    >
                        <span className="tabtext">Duration</span>
                    </button>
                </div>
                {tabStatus == "" ?
                   (
                        !loader ? <>
                        <div className="project-information mb-5">
                            <div className="project-summary">
                                <h4 className="cm_bold mb-2">Project Summary</h4>
                                <form className="project-summary-detail">
                                    <div className="cm_overflow_project">
                                        <div className="row cm_row">
                                            <div className="col-6 cm_bold col2">Name</div>
                                            <div className="col-6 cm_bold col2">{projectName1}</div>

                                            <div className="col-6 cm_bold col2">Category</div>
                                            <div className="col-6 cm_bold col2">{
                                                isArray(category1) ? category1.map((category, index) => (
                                                    category1 ? <span> {this.catValue(category)}
                                                        {index < category1?.length - 1 && ', '}
                                                    </span> : ""

                                                ))
                                                    : category1 ? this.catValue(category1) : ""}</div>
                                            <div className="col-6 cm_bold col2">Technology</div>
                                            <div className="col-6 cm_bold col2">{
                                                technology ? technology.map((technologies, index) => (
                                                    <span> {technologyList[technologies]}
                                                        {index < technology?.length - 1 && ', '}

                                                    </span>
                                                ))
                                                    : ""}</div>
                                            <div className="col-6 cm_bold col2">Project Type</div>
                                            <div className="col-6 cm_bold col2">{projectType ? projectTypeValue(projectType) : ""}</div>
                                            <div className="col-6 cm_bold col2">Status</div>
                                            <div className="col-6 cm_bold col2">
                                                {status === 1
                                                    ? "Ongoing"
                                                    : status === 2
                                                        ? "Completed"
                                                        : status == 3
                                                            ? "Incomplete"
                                                            : status == 4
                                                                ? "Maintenance"
                                                                : "On Hold"}
                                            </div>
                                            <div className="col-6 cm_bold col2">Start Date</div>
                                            <div className="col-6 cm_bold col2">
                                                {moment.unix(startDate1 / 1000).format("DD/MM/YYYY")}
                                            </div>
                                            <div className="col-6 cm_bold col2">End Date</div>
                                            <div className="col-6 cm_bold col2">
                                                {moment.unix(endDate1 / 1000).format("DD/MM/YYYY")}
                                            </div>
                                            <div className="col-6 cm_bold col2">Client Name</div>
                                            <div className="col-6 cm_bold col2">{clientName1 ? clientName1 : "-"}</div>
                                            <div className="col-6 cm_bold col2">Mail Id</div>
                                            <div className="col-6 cm_bold col2">{mailId1 ? mailId1 : "-"}</div>
                                            <div className="col-6 cm_bold col2">Project Manager</div>
                                            <div className="col-6 cm_bold col2">{projectManager1}</div>
                                            <div className="col-6 cm_bold col2">Account Manager</div>
                                            <div className="col-6 cm_bold col2">{accountManagerFirstName + " " + accountManagerLastName}</div>
                                            <div className="col-6 cm_bold col2">Project Hours</div>
                                            <div className="col-6 cm_bold col2">{projectHours}</div>
                                            <div className="col-6 cm_bold col2">Actual Hours</div>
                                            <div className="col-6 cm_bold col2">
                                                {this.handleActualHours(membersDetails)}
                                            </div>
                                            <div className="col-6 cm_bold col2">Project Description</div>
                                            <div className="col-12 cm_bold col2">{projectDescription1}</div>
                                        </div>
                                    </div>
                                    <div className="row cm_row">
                                        <div className="col-6 cm_bold col3">Actions</div>
                                        <div>
                                            <div className="col-12 cm_bold col3">
                                                <button className="mr-5 btn btn btn-primary" type="button" onClick={this.handleOpenEditProjectPopup}>Edit</button>
                                                <button className="btn btn-danger" type="button" onClick={this.handleOpenDeleteProjectPopup}>Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="project-summary">
                                <h4 className="cm_bold mb-2">Team Members</h4>
                                <form className="project-summary-detail">
                                    <div className="cm_overflow_project_members">
                                        <div className="row cm_row">
                                            <div className="col-6 cm_bold col2">Name</div>
                                            <div className="col-6 cm_bold col2">Total Hours</div>
                                            {membersDetails.length ? membersDetails.map((membersDetails) => (
                                                <>
                                                    <div className="col-6 cm_bold col2">{membersDetails.firstName} {membersDetails.lastName}</div>
                                                    <div className="col-6 cm_bold col2">
                                                        {this.millisecondsToHoursAndMinutes(membersDetails?.totalHours)}
                                                    </div>
                                                </>
                                            ))
                                                : (
                                                    <p>No data</p>
                                                )}
                                        </div>

                                        {membersDetails.length <= 0 ?
                                            <div className="col-10 cm_bold col2 text-center" style={{ fontSize: "20px", color: "rgba(80, 77, 77, 0.747)" }}>No data available</div>
                                            : ""}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="project-information mt-4 mb-4">
                            <div className="project-summary">
                                <h4 className="cm_bold mb-2">Ex Project Members</h4>
                                <form className="project-summary-detail">
                                    <div className="cm_overflow_project_members">
                                        <div className="row cm_row">
                                            <div className="col-6 cm_bold col2">Name</div>
                                            <div className="col-6 cm_bold col2">Total Hours</div>
                                            {exMembersDetails.length ? exMembersDetails.map((membersDetails) => (
                                                <>
                                                    <div className="col-6 cm_bold col2">{membersDetails.firstName} {membersDetails.lastName}</div>
                                                    <div className="col-6 cm_bold col2">
                                                        {this.millisecondsToHoursAndMinutes(membersDetails?.totalHours)}
                                                    </div>
                                                </>
                                            ))
                                                : (
                                                    ""
                                                )}
                                        </div>

                                        {exMembersDetails.length <= 0 ?
                                            <div className="col-10 cm_bold col2 text-center" style={{ fontSize: "20px", color: "rgba(80, 77, 77, 0.747)" }}>No data available</div>
                                            : ""}
                                    </div>
                                </form>
                            </div>
                        </div>

                    </> : <Loader small={true} />) : tabStatus == 1 ? (
                        !loader ? <>
                            <div className="table-responsive mb-5">

                                <table className="table table-bordered">
                                    <thead className="white bg_blue">
                                        <tr>
                                            <th>S.No</th>
                                            <th>
                                                Employee Name
                                                <i
                                                    className={
                                                        sortType === SORT_TYPE_CONSTANTS.Asscending &&
                                                            sortField === "projectName"
                                                            ? "fa fa-sort-alpha-asc ml-2"
                                                            : "fa fa-sort-alpha-desc ml-2"
                                                    }
                                                    onClick={() => this.handleSort("empName")}
                                                ></i>
                                            </th>
                                            <th>Ticket Id</th>
                                            <th>Task </th>
                                            <th>Blocker</th>
                                            <th>Date <i
                                                className={
                                                    sortType === SORT_TYPE_CONSTANTS.Asscending &&
                                                        sortField === "projectName"
                                                        ? "fa fa-sort-numeric-asc ml-2"
                                                        : "fa fa-sort-numeric-desc ml-2"
                                                }
                                                onClick={() => this.handleSort("startDate")}
                                            ></i></th>
                                            <th>Hour </th>
                                            <th>Actual Hours</th>
                                            <th>Overtime</th>
                                            <th>Updated On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!_.isEmpty(dailyWorkDetails)
                                            ? dailyWorkDetails.map((data, i) => (
                                                <tr key={data.leaveId}>
                                                    <td>{i + 1 + (pageNo - 1) * count}</td>
                                                    <td>{data.firstName + " " + data.lastName}</td>
                                                    <td>{data.ticketId?data.ticketId: "__"}</td>
                                                    <td>{<div style={{ color: "black" }}
                                                        dangerouslySetInnerHTML={this.createMarkup(data.task)}>
                                                    </div>}</td>
                                                    <td>{<div style={{ color: "black" }}
                                                        dangerouslySetInnerHTML={this.createMarkup(data.blocker)}>
                                                    </div>}</td>
                                                    <td>
                                                        {moment(data.date).format(dateFormat).substring(0, 10)}
                                                    </td>
                                                    <td>{moment.utc(data.hour * 1000).format("HH:mm")}</td>
                                                    <td>{moment.utc(data?.actualHours * 1000).format("HH:mm")}</td>
                                                    <td>{data.overTime ? moment.utc(data.overTime * 1000).format("HH.mm") : "__" }</td>
                                                    <td>{moment(data?.updated).format('HH:mm')+ " " + moment(data?.updated).format("DD/MM/YYYY")}</td>
                                                </tr>

                                            ))
                                            : ""
                                        }
                                    </tbody>
                                </table >
                                {dailyWorkDetails && dailyWorkDetails.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                            </div>
                            <div className="text-right">
                                <h3> Total Hours :- {this.millisecondsToHoursAndMinutes(employeeTotalHours)}</h3>
                            </div>
                        </> : <Loader small={true} />) : tabStatus == 2 ? (
                        !loader ? <>
                        < div className="table-responsive mb-5">
                            <table className="table table-bordered">
                                <thead className="white bg_blue">
                                    <tr>
                                        <th>S.No</th>
                                        <th>
                                            Employee Name
                                            <i
                                                className={sortType === 1 && sortField === "empName" ? "fa fa-sort-alpha-asc ml-2" : "fa fa-sort-alpha-desc ml-2"}
                                                onClick={() => this.handleSort('empName')}></i>
                                        </th>

                                        <th>Start Date<i
                                            className={sortType === 1 && sortField === "startDate" ? "fa fa-sort-alpha-asc ml-2" : "fa fa-sort-alpha-desc ml-2"}
                                            onClick={() => this.handleSort('startDate')}></i> </th>
                                        <th>End Date</th>
                                        <th>Leave Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!_.isEmpty(projectMemberLeave)
                                        ? projectMemberLeave.map((data, i) => (
                                            <tr key={data.leaveId}>
                                                <td>{i + 1 + (pageNo - 1) * count}</td>
                                                <td>{data.empName}</td>
                                                <td>{moment(data?.startDate).format(dateFormat).substring(0, 10)}</td>
                                                <td>{moment(data?.endDate).format(dateFormat).substring(0, 10)}</td>
                                                <td>{leaveValue(data.leaveType)}</td>
                                            </tr>

                                        ))
                                        : ""
                                    }
                                </tbody>
                            </table >

                            {projectMemberLeave && projectMemberLeave.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                        </div >
                        </> : <Loader small={true} />
        )
                            : (
                                !loader ? <>
                            <div className="table-responsive mb-5">
                                <table className="table table-bordered">
                                    <thead className="white bg_blue">
                                        <tr>
                                            <th>S.No</th>
                                            <th>
                                                Empployee Id
                                                <i
                                                    className={sortType === 1 && sortField === "empId" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                                                    onClick={() => this.handleSort('empId')}></i>
                                            </th>
                                            <th>
                                                Employee Name
                                                <i
                                                    className={sortType === 1 && sortField === "empName" ? "fa fa-sort-alpha-asc ml-2" : "fa fa-sort-alpha-desc ml-2"}
                                                    onClick={() => this.handleSort('firstName')}></i>
                                            </th>

                                            <th>Start Date
                                                <i
                                                    className={sortType === 1 && sortField === "startDate" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                                                    onClick={() => this.handleSort('startDate')}></i>
                                            </th>
                                            <th>End Date</th>
                                            <th>Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!_.isEmpty(exProjectMembers)
                                            ? exProjectMembers.map((data, i) => (
                                                <tr key={data.employeeId}>
                                                    <td>{i + 1 + (pageNo - 1) * count}</td>
                                                    <td>{data.empId}</td>
                                                    <td>{data?.firstName + " " + data?.middleName + " " + data?.lastName}</td>
                                                    <td>{moment(data?.startDate).format(dateFormat).substring(0, 10)}</td>
                                                    <td>{data?.endDate ? moment(data?.endDate).format(dateFormat).substring(0, 10) : "-"}</td>
                                                    <td>{data?.endDate ? ((new Date(moment(data?.endDate).format("YYYY-MM-DD").substring(0, 10))) - (new Date(moment(data?.startDate).format("YYYY-MM-DD").substring(0, 10)))) / (1000 * 60 * 60 * 24) : "-"}</td>
                                                </tr>

                                            ))
                                            : ""

                                        }
                                    </tbody>
                                </table >
                                {exProjectMembers && exProjectMembers.length > 0 ? "" : <h3 className='text-center'>No data available</h3>}
                            </div ></> : <Loader small={true} />
        )}
                {
                    dataCount > 10 && tabStatus == 1 && loader == false ?
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={dataCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        />
                        :
                        ""
                }
                {
                    memberLeaveCount > 10 && tabStatus == 2 && loader == false?
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={memberLeaveCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        />
                        :
                        ""
                }
                {
                    exProjectMembersDataCount > 10 && tabStatus == 3 && loader == false?
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={exProjectMembersDataCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        />
                        :
                        ""
                }
                <DownloadEmpDataModal
          openDownloadEmpData={openDownloadEmpData}
          closeModal={this.closeModal}
          membersDetails={membersDetails}
          handleDownloadEmployee={this.handleDownloadEmployee}
          handleSelectTemplate={this.handleSelectTemplate}
          handleDownload={this.handleDownload}
          handleMonth={this.handleMonth}
          handleYear={this.handleYear}
          state={this.state}
          modalLoader={modalLoader}
        />
                <AntModal
                    className="sm_modal1"
                    size="lg"
                    centered
                    title=""
                    footer={false}
                    visible={openEditProjectPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}
                >


                    <Modal.Body>

                        <form className="assign_device" onSubmit={this.handleEditSubmit}>
                            <h3 className="mb-4">EDIT PROJECT</h3>
                            <div className="row">
                                <div className="col-6">
                                    <div className={`form-group ${projectNameErMsg ? 'input_error' : ''}`}>
                                        <label className="sub">Project Name</label>
                                        <input
                                            maxLength="50"
                                            className="form-control cm_box"
                                            type="text"
                                            placeholder="Project Name"
                                            name="projectName"
                                            value={projectName}
                                            onChange={this.handleChange}
                                        />
                                        {/* {errorrMsg ? <span className="error_msg">{errorrMsg}</span> : null} */}

                                        {projectNameErMsg ? <span className="error_msg">{projectNameErMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${projectCategoryErrorMessage ? "input_error" : ""
                                    }`}>
                                    <label className="sub">Category</label>

                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        value={category}
                                        onChange={this.handleSelectChange}
                                    >
                                        <Option value={1}>Web</Option>
                                        <Option value={2}>Android</Option>
                                        <Option value={3}>iOS</Option>
                                        <Option value={4}>all</Option>
                                    </Select>

                                    {projectCategoryErrorMessage ? (
                                        <span className="error_msg">{projectCategoryErrorMessage}</span>
                                    ) : null}
                                </div>

                            </div>

                            <div className="row">
                                <div className={`form-group col-3 ${dateErMsg ? 'input_error' : ''}`}>
                                    <label className="sub">Start Date</label>
                                    <DatePicker
                                        allowClear={false}
                                        className="date-border"
                                        disabledDate={this.disabledStartDate}
                                        //showTime
                                        //format="DD-MM-YYYY"
                                        value={!startDate ? startDate : moment(startDate)}
                                        placeholder="Start date"
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                    />
                                    {dateErMsg ? <span className="error_msg">{dateErMsg}</span> : null}
                                </div>
                                <div className={`form-group col-3 ${endDateErMsg ? 'input_error' : ''}`}>
                                    <label className="sub">End Date</label>
                                    <DatePicker
                                        allowClear={false}
                                        className="date-border"
                                        disabledDate={this.disabledEndDate}
                                        //showTime
                                        //format="DD-MM-YYYY"
                                        value={!endDate ? endDate : moment(endDate)}
                                        placeholder="End date"
                                        onChange={this.onEndChange}
                                        open={endOpen}
                                        onOpenChange={this.handleEndOpenChange}
                                    />
                                    {endDateErMsg ? <span className="error_msg">{endDateErMsg}</span> : null}
                                    {/* <input className="form-control cm_bold cm_box" type="number" placeholder="End year" name="endDate" value={endDate} onChange={this.handleChange} /> */}
                                </div>
                                <div className={`form-group col-6 ${projectManagerNameErMsg ? 'input_error' : ''}`}>
                                    <label className="sub">Project Manager</label>
                                    <div className="sm_search">
                                        <Select
                                            showSearch name="employeeId" value={projectManagerId ? projectManagerId : 'Select by employee name'}
                                            onSearch={this.handleEmployeeSearch}
                                            optionFilterProp="key"
                                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())} onChange={this.handleChangeAssignedToChange}>

                                            {
                                                !_.isEmpty(employeeList) ?

                                                    employeeList.map(employee => (employee.status == 1 &&
                                                        <Option key={`${employee.firstName} ${employee.lastName}`}
                                                            value={employee.employeeId}>
                                                            <span>{employee.firstName} {employee.lastName} {employee.empId}</span>
                                                        </Option>
                                                    )) : ''
                                            }
                                        </Select>
                                    </div>

                                    {projectManagerNameErMsg ? <span className="error_msg">{projectManagerNameErMsg}</span> : null}
                                </div>
                            </div>
                            <div className='row'>

                                <div className={`form-group col-6`}>
                                    <label className="sub">Account Manager</label>
                                    <div className="sm_search">
                                        <Select
                                            showSearch name="employeeId" value={accountManagerId ? accountManagerId : 'Select by employee name'}
                                            onSearch={this.handleEmployeeSearch}
                                            optionFilterProp="key"
                                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())} onChange={this.handleChangeAccountManager}>

                                            {
                                                !_.isEmpty(employeeList) ?

                                                    employeeList.map(employee => (employee.status == 1 &&
                                                        <Option key={`${employee.firstName} ${employee.lastName}`}
                                                            value={employee.employeeId}>
                                                            <span>{employee.firstName} {employee.lastName} {employee.empId}</span>
                                                        </Option>
                                                    )) : ''
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className={`form-group ${projectHoursErMsg ? 'input_error' : ''}`}>
                                        <label className="sub">Project Hours</label>
                                        <input
                                            maxLength="50"
                                            className="form-control cm_box"
                                            type="text"
                                            placeholder="Project Name"
                                            name="projectHours"
                                            value={projectHours}
                                            onChange={this.handleProjectHoursChange}
                                        />
                                        {projectHoursErMsg ? <span className="error_msg">{projectHoursErMsg}</span> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className={`form-group col-6 `}>
                                    <label className="sub">Project Type</label>

                                    <Select
                                        style={{ width: "100%" }}
                                        name="category"
                                        placeholder="Project Type"
                                        value={projectType ? projectTypeValue(projectType) : "Project Type"}
                                        onChange={this.handleProjectType}
                                    >
                                        <Option value={1}>ManMonth</Option>
                                        <Option value={2}>Hourly</Option>
                                        <Option value={3}>Delivery Based</Option>
                                    </Select>
                                </div>
                                <div className={`form-group col-6 ${technologyErrorMessage ? "input_error" : ""
                                    }`}>
                                    <label className="sub">Technology</label>
                                    <Select
                                        mode="multiple"
                                        placeholder="Select Technologies"
                                        value={technology ? technology : "Select Technologies"}
                                        onChange={this.handleTechnologyChange}
                                        isSearchable={true}
                                        optionFilterProp="key"
                                        filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {!_.isEmpty(technologyList)
                                            ? technologyList.map(
                                                (technology, i) => (
                                                    <Option
                                                        key={`${technology}`}
                                                        value={i}
                                                    >
                                                        <span>
                                                            {technology}
                                                        </span>
                                                    </Option>
                                                )
                                            )
                                            : ""}
                                    </Select>

                                    {technologyErrorMessage ? (
                                        <span className="error_msg">{technologyErrorMessage}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-6">
                                    <div className="row">
                                        <div className={`form-group col-12 ${clientNameErrorMessage ? 'input_error' : ''}`}>
                                            <label className="sub">Client Name</label>
                                            <input
                                                maxLength="50"
                                                className="form-control  cm_box"
                                                type="text"
                                                placeholder="Client Name"
                                                name="clientName" projectManagerNameErMsg
                                                value={clientName}
                                                onChange={this.handleChange}
                                            />
                                            {clientNameErrorMessage ? <span className="error_msg">{clientNameErrorMessage}</span> : null}
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className={`form-group col-12 ${clientMailErrorMeaasge ? 'input_error' : ''}`}>
                                            <label className="sub">Mail Id</label>
                                            <input
                                                maxLength="50"
                                                className="form-control  cm_box"
                                                type="text"
                                                placeholder="Mail Id"
                                                name="mailId"
                                                value={mailId}
                                                onChange={this.handleChange}
                                            />
                                            {clientMailErrorMeaasge ? <span className="error_msg">{clientMailErrorMeaasge}</span> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label className="sub">Project Description</label>
                                    <textarea
                                        className="form-control  cm_box cm_descrip"
                                        type="text"
                                        placeholder="Project Description"
                                        name="projectDescription"
                                        maxLength={500}
                                        value={projectDescription}
                                        onChange={this.handleChange}></textarea>
                                </div>
                                <div className="col-6">
                                    <label className="sub mt-4">Project Members</label>
                                    <div className="form-group selectHeight">

                                        <Select mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Select employee"
                                            value={this.state.assignedTo}
                                            onChange={this.handleMembersChange}
                                            filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {
                                                !_.isEmpty(employeeList) ?

                                                    employeeList.map(employee => (employee.status == 1 &&
                                                        <Option
                                                            key={`${employee.firstName} ${employee.lastName}`}

                                                            value={employee.employeeId}><span>{employee.firstName} {employee.lastName} {employee.empId}</span>
                                                        </Option>
                                                    )) : ''
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="submit_button_margin mt-4">
                                <button type="submit" className="btn btn-primary cm_btn3">Save</button>
                                <button type="button" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>
                            </div>

                        </form>
                    </Modal.Body>
                </AntModal>


                <Modal
                    size="md"
                    show={openAddParticipentPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                    className="grant_access"

                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <form className="grant_access">

                            <h3 className="mb-4">ADD PARTICIPENT</h3>
                            <h4 className="mb-3 cm_bold">Select Employee</h4>
                            <div>
                                <div className="sm_search">
                                    <Select mode="tags" style={{ width: '100%' }} placeholder="Select by Employee Name" value={this.state.assignedTo} onDeselect={(i) => this.handleOnDSelect(i)} >

                                        {
                                            employeeList.map(employee => {
                                                if (employee.status == 1)
                                                    return <Option key={employee.employeeId} onClick={() => this.handleOnSelect(employee)}><span className="">{employee.email}</span></Option>
                                            })

                                        }

                                    </Select>
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <button className="btn btn-primary  mr-2 btn-sm cm_btn3" onClick={this.handleParticipant} >Add</button>

                            </div>
                        </form>

                    </Modal.Body>
                </Modal>

                <Modal
                    size="md"
                    show={openCompleteProjectPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >

                    <Modal.Body className="text-center confirmation">

                        <p className="mb-4 cm_bold cnfrm_msg">Are you sure, that this project is Completed?</p>
                        <div className="submit_button_margin mt-4">
                            <button type="submit" onClick={this.handleComplete} className="btn btn-primary cm_btn3">Yes</button>
                            <button type="submit" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>
                        </div>


                    </Modal.Body>
                </Modal>

                <Modal
                    size="md"
                    show={openDeleteProjectPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                    centered
                >

                    <Modal.Body className="text-center confirmation">

                        <p className="mb-4 cm_bold cnfrm_msg">Are you sure, you want to delete this Project?</p>
                        <div className="submit_button_margin mt-4">
                            {miniLoader ? <div className="mini_loader_dept"></div> : ""}

                            <button type="submit" onClick={this.handleDelete} className="btn btn-danger cm_btn3">Delete</button>
                            <button type="submit" onClick={this.closeModal} className="btn btn-primary cm_btn2">Cancel</button>
                        </div>


                    </Modal.Body>
                </Modal>
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openAddNewStandupPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >

                    <Modal.Body>
                        <form onSubmit={this.handleSubmit}>
                            <h3>NEW STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false} />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours Working</label>
                                    <input
                                        className="form-control cm_box"
                                        type="number"
                                        step="any"
                                        name="hour"
                                        placeholder="Hours"
                                        value={startTime && endTime ? moment.utc(((endTime - startTime) + 3600) * 1000).format("HH:mm") : ''}
                                    // onChange={this.handleChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className={`form-group col-6 ${startTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub"> From</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={startTime ? moment.utc(startTime * 1000) : ""}
                                        onChange={this.handleCheckInChange}
                                    />
                                    {startTimeErrorMsg ? (
                                        <span className="error_msg">{startTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                                <div
                                    className={`form-group col-6 ${endTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub">To</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={endTime ? moment.utc(endTime * 1000) : ""}
                                        onChange={this.handleCheckOutChange}
                                    />
                                    {endTimeErrorMsg ? (
                                        <span className="error_msg">{endTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>

                                <textarea
                                    className="form-control cm-des  cm_box"
                                    type="text"
                                    maxLength={500}
                                    placeholder="Today's task"
                                    name="task"
                                    value={task}
                                    onChange={this.handleChange}
                                >
                                </textarea>
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${blockerErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What problem are you facing?</label>
                                <textarea
                                    className="form-control cm-des cm_box"
                                    type="text"
                                    maxLength={500}
                                    name="blocker"
                                    placeholder="Today's blocker"
                                    value={blocker}
                                    onChange={this.handleChange}
                                >
                                </textarea>

                            </div>
                            <div className="submit_button_margin mt-4"><button type="submit" className="btn btn-primary cm_btn3">Submit</button>
                                <button type="button" className="btn btn-primary cm_btn2" onClick={this.closeModal}>Cancel</button></div>

                        </form>
                    </Modal.Body>
                </AntModal>
                <AntModal
                    title=""
                    centered
                    className="sm_modal1"
                    footer={false}
                    visible={openEditLogPopup}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}

                >

                    <Modal.Body>
                        <form onSubmit={this.handleEditSubmitLog}>
                            <h3>EDIT STANDUP</h3>

                            <div className="row">
                                <div className={`form-group col-6 ${dateErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">When was this?</label>
                                    <div>
                                        <DatePicker
                                            className="date-border"
                                            onChange={this.handleDateChange}
                                            value={date ? moment(date) : ''}
                                            allowClear={false} />
                                        {dateErrorMsg ? <span className="error_msg">{dateErrorMsg}</span> : null}
                                    </div>
                                </div>
                                <div className={`form-group col-6 ${hourErrorMsg ? 'input_error' : ''}`}>
                                    <label className="sub">No.of hours Working</label>
                                    <input
                                        className="form-control cm_box"
                                        type="number"
                                        step="any"
                                        name="hour"
                                        placeholder="Hours"
                                        value={startTime && endTime ? moment.utc(((endTime - startTime) + 3600) * 1000).format("HH:mm") : ''}
                                    // onChange={this.handleChange}
                                    />
                                    {hourErrorMsg ? <span className="error_msg">{hourErrorMsg}</span> : null}
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className={`form-group col-6 ${startTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub"> From</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={startTime ? moment.utc(startTime * 1000) : ""}
                                        onChange={this.handleCheckInChange}
                                    />
                                    {startTimeErrorMsg ? (
                                        <span className="error_msg">{startTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                                <div
                                    className={`form-group col-6 ${endTimeErrorMsg ? "input_error" : ""
                                        }`}
                                >
                                    <label className="sub">To</label>
                                    <TimePicker
                                        className="form-control"
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                        format={format}
                                        value={endTime ? moment.utc(endTime * 1000) : ""}
                                        onChange={this.handleCheckOutChange}
                                    />
                                    {endTimeErrorMsg ? (
                                        <span className="error_msg">{endTimeErrorMsg}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className={`form-group ${taskErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What will you do today?</label>

                                <textarea
                                    className="form-control cm-des  cm_box"
                                    type="text"
                                    maxLength={500}
                                    placeholder="Today's task"
                                    name="task"
                                    value={task}
                                    onChange={this.handleChange}
                                >
                                </textarea>
                                {taskErrorMsg ? <span className="error_msg">{taskErrorMsg}</span> : null}
                            </div>
                            <div className={`form-group ${blockerErrorMsg ? 'input_error' : ''}`}>
                                <label className="sub mt-4 mb-4">What problem are you facing?</label>
                                <textarea
                                    className="form-control cm-des cm_box"
                                    type="text"
                                    name="blocker"
                                    maxLength={500}
                                    placeholder="Today's blocker"
                                    value={blocker}
                                    onChange={this.handleChange}
                                >
                                </textarea>

                            </div>
                            <div className="submit_button_margin mt-4"><button type="submit" className="btn btn-primary mt-2 cm_btn3">Submit</button>
                                <button type="button" className="btn btn-primary cm_btn2" onClick={this.closeModal}>Cancel</button></div>

                        </form>
                    </Modal.Body>
                </AntModal>
                <ViewTemplateModal
                    viewModal={viewModal}
                    handleClose={this.closeModal}
                />
            </div >




        )
    }
}
export default ManagerViewProject;

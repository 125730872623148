import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { monthNames } from "../../helper/Constant";

const StyledInput = styled.input`
  height: 3rem;
  border-radius: 10px;
  border-color: #e0dede;
`;

const CustomHeader = ({
  date,
  changeMonth,
  changeYear,
}) => {
  const years = Array.from({ length: 20 }, (_, i) => date.getFullYear() - 10 + i);

  return (
    <div className="custom-header">
      <select
        value={date.getMonth()}
        onChange={e => changeMonth(e.target.value)}
      >
        {monthNames.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select value={date.getFullYear()} onChange={e => changeYear(e.target.value)}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

const DatePick = (props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <div>
      <DatePicker
        customInput={<StyledInput />}
        selected={selectedDate}
        dateFormat="dd/MM/yyyy"
        className="form-control"
        maxDate={new Date()}
        onChange={date => {
          props.handleDateChange(date)
          setSelectedDate(date)
        }}
        renderCustomHeader={({
          date,
          changeMonth,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <CustomHeader
            date={date}
            changeMonth={changeMonth}
            changeYear={changeYear}
            decrementMonth={decreaseMonth}
            incrementMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
      />
    </div>
  );
};

export default DatePick;

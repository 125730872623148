
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import ResponseFilter from '../../ResponseFilter';
import { Tabs, Tab, NavItem, Nav } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { Loader } from '../svg';
import { getProject, projectListByManager } from '../../config/services/employeeServices';
import { CONSTANTS } from '../../config';
let SORT_TYPE_CONSTANTS = CONSTANTS.SORT_TYPE_CONSTANTS


const statusValue = type => {
    switch (type) {
        case 1:
            return 'Ongoing';
            break;
        case 2:
            return 'Completed';
            break;
        case 3:
            return 'Incomplete';
        case 4:
            return 'Maintenance';
        case 5:
            return "On Hold";
    }
}
const colorType = type => {
    switch (type) {
        case 1: return 'skyblue';
            break;

        case 2: return 'text-success';
            break;
        case 4: return 'brown';
            break;
        case 5: return 'orange';

        default: return 'text-danger ';

    }
}

const handleDelayTime = (data) => {
    var delayedStatus;
    var date = new Date().setHours(0, 0, 0, 0);

    if (data >= new Date(date).setHours(0, 0, 0, 0)) {
        delayedStatus = `On Track`
    }

    else {
        var delay = (new Date(date).setHours(0, 0, 0, 0) - data) / 86400000
        delayedStatus = `${Math.floor(delay)} days`
    }

    return delayedStatus;
}




class Project extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectDetails: [],
            projectDetailsManger: [],
            search: '',
            status: props.location?.state ? props.location.state : '',
            projectId: '',
            projectStatus: '',
            totalProjects: '',
            OnGoingProjects: '',
            CompletedProjects: '',
            onHoldProjects: "",
            delayedStatus: '',
            pageNo: 1,
            count: 10,
            loader: true,
            dataCount: '',
            sortType: SORT_TYPE_CONSTANTS.Descending,
            sortField: "created"
        }
        this.debounceSearch = null
    }

    handleSearch = e => {
        this.setState({ search: e.target.value, pageNo: 1 });
        clearTimeout(this.debouncedSearch);
        this.debounceSearch = setTimeout(() => {
            this.setState({ loader: true })

            this.getProjectList();
            this.getProjectManager()
        }, 2000);

    }
    handleSelectChange = () => {
        this.setState({ search: '', pageNo: 1 }, () => this.getProjectList());
    }
    submitSearch = e => {
        e.preventDefault();
        this.setState({ loader: true })
        this.getProjectList()
    }
    handlePageChange = (pageNo) => {
        this.setState({ pageNo: pageNo, loader: true }, () => this.getProjectList());
    }
    handleTabChange = e => this.setState({ loader: true, status: e == '0' ? '' : parseInt(e), pageNo: 1 }, () => this.getProjectList())

    getProjectList = () => {

        let { search, status, count, pageNo, sortField, sortType } = this.state
        let params = `search=${search.trim()}&status=${status}&count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}`;
        this.props.history.push(`projects?search=${search}&pageNo=${pageNo}&status=${status}&sortType=${sortType}&sortField=${sortField}`)
        getProject(params).then(res => {

            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({
                    dataCount: resData.dataCount,
                    projectDetails: resData.projectDetails,
                    totalProjects: resData.totalProjects,
                    onGoingProjects: resData.onGoingProjects,
                    completedProjects: resData.completedProjects,
                    inCompleteProjects: resData.inCompleteProjects,
                    maintenanceProjects: resData.maintenanceProjects,
                    onHoldProjects: resData.onHoldProjects

                }, () => {
                    this.setState({ loader: false })
                })
            }
        })
    }
    handleSort = e => {
        this.setState({ loader: true, sortField: e, sortType: this.state.sortType === SORT_TYPE_CONSTANTS.Asscending ? SORT_TYPE_CONSTANTS.Descending : SORT_TYPE_CONSTANTS.Asscending }, () => this.getProjectList());
    }
    getProjectManager = () => {
        let { search, status, sortType, sortField, pageNo, count } = this.state;

        let params = `search=${search.trim()}&status=${status}&sortType=${sortType}&sortField=${sortField}&count=${count}&pageNo=${pageNo - 1}`;


        projectListByManager(params).then(res => {

            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({
                    projectDetailsManger: resData.projectList,
                    totalProjectsManger: resData.totalProjects,
                }, () => this.setState({ loader: false }))
            }
            else {
                this.setState({ loader: false })
            }
        })
    }

    componentDidMount() {
        let url_string = window.location.href
        let url = new URL(url_string);
        let pageNo = url.searchParams.get("pageNo");
        let status1 = url.searchParams.get("status");
        let search = url.searchParams.get("search");
        let sortType = url.searchParams.get("sortTypr");
        let sortField = url.searchParams.get("sortField");

        this.setState({
            pageNo: pageNo ? +(pageNo) : 1,
            search: search ? search : "",
            sortType: sortType ? sortType : SORT_TYPE_CONSTANTS.Descending,
            sortField: sortField ? sortField : "created",
            status: status1 ? status1 : this.state.status,
        }, () => {

            this.getProjectList();
        })
        this.getProjectManager();

    }
    handleFilter = (e) => {
        this.setState({ status: e, pageNo: 1, loader: true }, () => this.getProjectList());
    }
    render() {
        let { date, dataCount, status, search, loader, projectDetails, projectDetailsManger, totalProjects, totalProjectsManger, pageNo, count, onGoingProjects, completedProjects, inCompleteProjects, maintenanceProjects, sortField, sortType, onHoldProjects } = this.state;
        return (
            <div className="body_container cm_bold">
                {
                    loader && <Loader />
                }


                <h3 className="mb-5">PROJECT LIST</h3>


                {/* <div className="row d-flex justify-content-start row-btn mt-2"> */}
                    <div className="text-left cm_ticket_btn cm_leave_btn mt-4">
                        <button className={`btn btn-primary  ${status == '' ? "active" : ""}`} onClick={() => this.handleFilter('')} ><span className="tabtext">All</span><br />
                            <span>{totalProjects}</span></button>
                        <button className={`btn btn-primary  ${status == 1 ? "active" : ""}`} onClick={() => this.handleFilter(1)} ><span className="tabtext">Ongoing </span><br />
                            <span>{onGoingProjects}</span></button>
                        <button className={`btn btn-primary  ${status == 2 ? "active" : ""}`} onClick={() => this.handleFilter(2)}><span className="tabtext">Completed</span><br />
                            <span> {completedProjects}</span></button>
                        <button className={`btn btn-primary  ${status == 3 ? "active" : ""}`} onClick={() => this.handleFilter(3)}><span className="tabtext">Incomplete </span><br />
                            <span>{inCompleteProjects}</span></button>
                        <button className={`btn btn-primary  ${status == 4 ? "active" : ""}`} onClick={() => this.handleFilter(4)}><span className="tabtext">Maintenance</span><br />
                            <span> {maintenanceProjects}</span>
                        </button>

                        <button className={`btn btn-primary  ${status == 5 ? "active" : ""}`} onClick={() => this.handleFilter(5)}><span className="tabtext">On Hold</span><br />
                            <span> {onHoldProjects}</span>
                        </button>
                        {!_.isEmpty(projectDetailsManger) ? <span className="tabtext"><Link className="btn btn-primary  " to='/projects/manager-projects'> <b>As manager</b><br /><span> {totalProjectsManger}</span></Link></span> : ''}</div>
                {/* </div> */}

                <div className="row d-flex justify-content-end mb-4 mt-2">

                    <div className="col-md-6 search_device_bar select__date">

                        <input className="form-control mr-lg-2 cm_box"

                            type="search"
                            placeholder="Search by Project Name"
                            maxLength={40}
                            aria-label="Search"
                            value={search}
                            onChange={this.handleSearch}
                            // onKeyDown={(e) => { if (e.key === 'Enter') this.getProjectList() }}
                        />
                        {search ? <span className="cm_clear" onClick={this.handleSelectChange}><i className="fa fa-times-circle" aria-hidden="true"></i></span> : <span className="cm_clear"><i className="fa fa-search" aria-hidden="true" ></i></span>}

                    </div>

                </div>


                <div>

                    <div>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead className="white bg_blue">
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col"> Project Name
                                            <i
                                                className={sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "projectName" ? "fa fa-sort-alpha-asc ml-2" : "fa fa-sort-alpha-desc ml-2"}
                                                onClick={() => this.handleSort('projectName')}></i>
                                        </th>
                                        <th scope="col">Start Date <i
                                            className={sortType === SORT_TYPE_CONSTANTS.Asscending && sortField === "projectName" ? "fa fa-sort-numeric-asc ml-2" : "fa fa-sort-numeric-desc ml-2"}
                                            onClick={() => this.handleSort('startDate')}></i></th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">Project Manager</th>
                                        <th scope="col"> Account Manager</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Delayed Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !_.isEmpty(projectDetails) ?
                                            projectDetails.map((project, i) => (
                                                <tr>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_col"><Link to={`/projects/${project.projectId}`}>{project.projectName.charAt(0).toUpperCase() + project.projectName.slice(1)}</Link></span></td>
                                                    <td>{moment.unix(project.startDate / 1000).format("DD/MM/YYYY")}</td>
                                                    <td>{moment.unix(project.endDate / 1000).format("DD/MM/YYYY")}</td>
                                                    <td style={{ textTransform: 'capitalize' }}><span className="cm_col">{project.clientName}</span></td>
                                                    <td><span className="cm_col">{project.projectManager.charAt(0).toUpperCase() + project.projectManager.slice(1)}</span></td>
                                                    <td>{project?.accountManagerFirst + " " + project?.accountManagerLast}</td>

                                                    <td className={colorType(project.status)}>{statusValue(project.status)}</td>
                                                    <td>{
                                                        project.status == 2 ? <span className="green">Completed</span> : (project.status == 3 ? <span className="red">Incomplete</span> : <span className={project.endDate >= new Date(date).setHours(0, 0, 0, 0) ? "ontrack" : "yellow"}>{handleDelayTime(project.endDate)}</span>)
                                                    } </td>
                                                </tr>
                                            )) : ''
                                    }
                                </tbody>
                            </table>

                            {!_.isEmpty(projectDetails) ? '' : <h3 className="text-center">No data available</h3>}
                        </div>
                        {dataCount > 10 ? <Pagination
                            pageNo={pageNo}
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={totalProjects}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        /> : ''}
                    </div>
                </div>


                {/* <div className="row">
                    <div className="col-12">

                <Tabs defaultActiveKey="0" id="uncontrolled-tab-example" onSelect={(e) => this.handleTabChange(e)}>
                    <Tab eventKey="0" title={`All ${totalProjects}`}>
                        <ProjectTable {...this.state}
                            handleAction={this.handleAction}
                            delayedStatus={this.state.delayedStatus} />
                        {
                            totalProjects > 10 ?
                                <Pagination
                                    pageNo={pageNo}
                                    itemsCountPerPage={count}
                                    totalItemsCount={totalProjects}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                                /> : ''
                        }
                    </Tab>
                    <Tab eventKey="1" title={`Ongoing ${onGoingProjects}`}>
                        <ProjectTable {...this.state}
                            handleAction={this.handleAction}
                            delayedStatus={this.state.delayedStatus} />
                        {onGoingProjects > 10 ?
                            <Pagination
                                pageNo={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={onGoingProjects}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            /> : ''
                        }

                    </Tab>

                    <Tab eventKey="2" title={`Completed ${completedProjects}`}>
                        <ProjectTable {...this.state}
                            handleAction={this.handleAction}
                            delayedStatus={this.state.delayedStatus} />

                        {completedProjects > 10 ?
                            <Pagination
                                pageNo={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={completedProjects}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            /> : ''}

                    </Tab>

                    <Tab eventKey="3" title={`Incomplete ${inCompleteProjects}`}>
                        <ProjectTable {...this.state}
                            handleAction={this.handleAction}
                            delayedStatus={this.state.delayedStatus} />
                        {inCompleteProjects > 10 ?
                            <Pagination
                                pageNo={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={inCompleteProjects}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            /> : ''
                        }


                    </Tab>

                </Tabs>

</div></div> */}

            </div>
        );
    }
}
export default Project;
